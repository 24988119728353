import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import theme from 'theme'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '48px',
    background: '#ffffff',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.sys_trad?.border.main}`,
    padding: '0 17px',
    boxSizing: 'border-box',
    '& button': {
      marginRight: '13px',
      border: 'none',
      background: 'none',
      cursor: 'pointer'
    }
  }
}))

export const Container = ({ children }) => {
  const classes = useStyles(theme)
  return (
    <Grid container className={classes.container}>
      {children}
    </Grid>
  )
}
