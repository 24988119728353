import React, { useContext, useState } from 'react'
import * as S from '../materialityComponents/index.jsx'
import PropTypes from 'prop-types'
import MaterialityItem from './MaterialityItem'
import LoadingMask from 'utils/loadingMask'
import { PrimaryModal } from 'components'
import MaterialityDriverHeaderSector from '../driverInfo/MaterialityDriverHeaderSector'
import MaterialityDriverInfoSector from '../driverInfo/MaterialityDriverInfoSector'
import themeColorPalette from 'theme/theme'
import getColor, { hexToRGB } from 'utils/color'
import { EntityService } from 'services'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON } from 'constant/constants'

const MaterialityDriverList = ({
  items,
  isLoading,
  opacity,
  companyScoredBy,
  colorCategory,
  driverName,
  isControversyEnabled,
  entitlements,
  isCompanyHasOnDemand
}) => {
  const [openModal, setOpenModal] = React.useState(false)
  const [driverItem, setDriverItem] = React.useState()

  // eslint-disable-next-line no-unsafe-optional-chaining
  const [entityHeaderState] = useContext(GlobalContext)?.globalEntityHeader
  const [modalLoading, setModalLoading] = useState(false)
  const [row, setRow] = useState(null)

  const handleRowClick = async (row) => {
    if (!modalLoading) {
      if (!row) return

      setRow(row)
      setOpenModal(true)
      setModalLoading(true)
      const { orbis_id } = entityHeaderState.globalEntityHeader
      const criteria = row.criteria

      const newDriverItemData = {
        label: row.label,
        title: row.title,
        criteria: row.criteria
      }

      setDriverItem(newDriverItemData)

      try {
        const data = await EntityService.getDriverInfo({ orbis_id, criteria })

        setDriverItem({
          ...newDriverItemData,
          apiData: data,
          dataStatus: PAGE_CON.SUCCESS,
          subTitle: data?.sub_category_detailed_description || ''
        })

        setModalLoading(false)
      } catch (err) {
        setDriverItem({
          ...newDriverItemData,
          dataStatus: PAGE_CON.ERROR
        })
        setModalLoading(false)
      }
    }
  }

  const handleCloseDialog = () => {
    setOpenModal(false)
  }

  const handleKeyDown = (x) => {
    if (x.keyCode === 27) if (handleCloseDialog) handleCloseDialog()
  }

  const hasOpacityRange = opacity !== undefined && opacity.length > 1

  function getIntervals(range, parts) {
    var l = (range[1] - range[0]) / parts,
      i = range[0]
    return Array.from({ length: parts }, () => [i, (i += l)])
  }

  const intervals = hasOpacityRange ? getIntervals(opacity, items.length) : ''
  let opacities = []

  for (let i = 0; i <= intervals.length - 1; i++) {
    opacities.push(intervals[i][0].toFixed(2))
  }

  return (
    <S.Container>
      {isLoading ? (
        <LoadingMask />
      ) : (
        <ul>
          <PrimaryModal
            id="subcategory-modal-test-id"
            modalOpen={openModal}
            onModalClose={handleCloseDialog}
            modalScroll={'paper'}
            modalClass="minorModal"
            modalMaxWidth={isControversyEnabled ? 'lg' : 'md'}
            showCloseIcon={false}
            headerBgColor={themeColorPalette.palette.sys_trad.white.main}
            showDivider={true}
            onKeyDown={handleKeyDown}
            modalHeaderContentComponent={
              <MaterialityDriverHeaderSector
                isLoading={modalLoading}
                data={driverItem}
                isControversyEnabled={isControversyEnabled}
              ></MaterialityDriverHeaderSector>
            }
            modalContentComponent={
              <MaterialityDriverInfoSector
                isControversyEnabled={isControversyEnabled}
                isLoading={modalLoading}
                data={driverItem}
                reloadComponent={() => handleRowClick(row)}
              />
            }
            backdropstyle={true}
          />
          {items.length > 0 ? (
            items.map((item, index) => {
              let itemOpacity =
                opacity === undefined
                  ? 1
                  : hasOpacityRange
                  ? opacities[index]
                  : opacity
              let color = hexToRGB(
                getColor(colorCategory, item.label),
                itemOpacity
              )
              return (
                <MaterialityItem
                  key={'company_score_by'}
                  companyScoredBy={companyScoredBy}
                  driverName={driverName}
                  color={color}
                  opacity={
                    opacity === undefined
                      ? 1
                      : hasOpacityRange
                      ? opacities[index]
                      : opacity
                  }
                  {...item}
                  itemKey={item.key}
                  onItemClick={handleRowClick}
                  entitlements={entitlements}
                  isCompanyHasOnDemand={isCompanyHasOnDemand}
                />
              )
            })
          ) : (
            <MaterialityItem color={'transparent'} empty={items.length === 0} />
          )}
        </ul>
      )}
    </S.Container>
  )
}

MaterialityDriverList.propTypes = {
  items: PropTypes.array,
  isLoading: PropTypes.bool,
  colorCategory: PropTypes.string
}

MaterialityDriverList.defaultProps = {
  colorCategory: 'CATEGORY'
}

export default MaterialityDriverList
