import { COMMON, EXPORT, PARAMS } from 'constant/APIconstants'
import axios from 'axios'
import { getMonthFromFilter, getYearFromFilter } from 'utils/Date'
import { fakeApiCall, getRandomInt } from 'utils/helper'
import Constant from 'constant'
import heatmapMock from './heatmapMock.json' //TODO: Using mock data, should be cleaned after integration
import { API_DEFAULT_PAYLOAD, PAGE_CON } from 'constant/constants'

const doubleEncode = (uri) => encodeURI(encodeURI(uri))

const researchLineScoreStructure = (data) => ({
  carbonfootprint: {
    value:
      data &&
      data[0] &&
      data[0].portfolio_score?.find(({ name }) => name === 'Carbon Footprint')
  },
  temperaturealgmt: {
    value:
      data &&
      data[0] &&
      data[0].portfolio_score?.find(
        ({ name }) => name === 'Temperature_Alignment'
      )
  },
  physicalriskmgmt: {
    value:
      data &&
      data[0] &&
      data[0].portfolio_score?.find(({ name }) => name === 'Total')
  },
  esgasmt: {
    value:
      data &&
      data[0] &&
      data[0].portfolio_score?.find(({ name }) => name === 'Esg_Assessment')
  },
  physicalriskhazard: { value: data && data[0] && data[0] },
  greenshareasmt: {
    value:
      data && data[0] && data[0].portfolio_score && data[0].portfolio_score[0]
  },
  brownshareasmt: {
    value:
      data && data[0] && data[0].portfolio_score && data[0].portfolio_score[0]
  },
  default: { value: data && data[0] && data[0] }
})

export const getTableData = async ({
  portfolio = PAGE_CON.SAMPLE_PORTFOLIO,
  regions = PAGE_CON.ALL_FILTER,
  sectors = PAGE_CON.ALL_FILTER,
  asOfDate,
  research_line,
  table_type = 'largest_holdings',
  limit = 10
}) => {
  const month = getMonthFromFilter(asOfDate)
  const year = getYearFromFilter(asOfDate)
  regions = regions ? regions : PAGE_CON.ALL_FILTER
  sectors = sectors ? sectors : PAGE_CON.ALL_FILTER

  const { researchLines } = Constant.Dashboard
  research_line =
    table_type === 'largest_holdings'
      ? undefined
      : researchLines.find(({ underscore }) => underscore === research_line)
          ?.key || research_line

  const apiUrl = doubleEncode(
    `${COMMON.BASE_URL}${portfolio}${COMMON.PORTFOLIO_RESEARCHLINES}`
  )
  const { data } = await axios.post(apiUrl, {
    [PARAMS.REGION]: regions,
    [PARAMS.SECTOR]: sectors,
    [PARAMS.MONTH]: month,
    [PARAMS.YEAR]: year,
    [PARAMS.RESEARCHLINE]: research_line,
    [PARAMS.TABLE_TYPE]: table_type,
    [PARAMS.LIMIT]: limit
  })

  return data
}

export const getResearchLinesCoverage = async ({
  portfolio = PAGE_CON.SAMPLE_PORTFOLIO,
  research_line = PAGE_CON.ALL_FILTER,
  regions = PAGE_CON.ALL_FILTER,
  sectors = PAGE_CON.ALL_FILTER,
  asOfDate,
  benchmark = ''
}) => {
  //TODO: Using mock data, should be cleaned after integration
  if (research_line === 'controversies') {
    await fakeApiCall(getRandomInt(500, 1000))
    return heatmapMock.controversy_coverage[0]
  }

  const month = getMonthFromFilter(asOfDate)
  const year = getYearFromFilter(asOfDate)

  regions = regions ? regions : 'all'
  sectors = sectors ? sectors : 'all'
  const apiUrl = `${COMMON.BASE_URL}${portfolio}/${research_line}${COMMON.COVERAGE}`
  const { data } = await axios.post(apiUrl, {
    [PARAMS.REGION]: regions,
    [PARAMS.SECTOR]: sectors,
    [PARAMS.MONTH]: month,
    [PARAMS.YEAR]: year,
    [PARAMS.BENCHMARK_ID]: benchmark
  })

  return data[0]
}

const getSummaryHeadersData = async ({
  portfolio = PAGE_CON.SAMPLE_PORTFOLIO,
  regions = PAGE_CON.ALL_FILTER,
  sectors = PAGE_CON.ALL_FILTER,
  asOfDate,
  benchmark = '',
  researchLines = []
}) => {
  const summaryHeadersData = await Promise.allSettled(
    researchLines.map(async (researchLine) => {
      const data = await getResearchLineScore({
        portfolio,
        regions,
        sectors,
        asOfDate,
        research_line: researchLine,
        benchmark
      })
      return data
    })
  )

  const mappedSummaryHeaders = summaryHeadersData.map((response, index) => {
    const { value = [], status = '' } = response
    const data = researchLineScoreStructure(value)[researchLines[index]]

    return {
      status,
      researchLine: researchLines[index],
      ...data
    }
  })

  return mappedSummaryHeaders
}

export const getResearchLines = async ({
  portfolio = PAGE_CON.SAMPLE_PORTFOLIO,
  regions = PAGE_CON.ALL_FILTER,
  sectors = PAGE_CON.ALL_FILTER,
  asOfDate,
  researchLines
}) => {
  const researchLinesData = await getSummaryHeadersData({
    portfolio,
    regions,
    sectors,
    asOfDate,
    researchLines
  })

  return researchLinesData
}

export const getMapData = async ({
  portfolio,
  research_line,
  regions = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_REGIONS],
  sectors = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_SECTORS],
  asOfDate,
  benchmark
}) => {
  const month = getMonthFromFilter(asOfDate)
  const year = getYearFromFilter(asOfDate)
  regions = regions ? regions : 'all'
  sectors = sectors ? sectors : 'all'
  const apiUrl = `${COMMON.BASE_URL}${portfolio}/${research_line}${COMMON.MAP}`
  const { data } = await axios.post(apiUrl, {
    [PARAMS.REGION]: regions,
    [PARAMS.SECTOR]: sectors,
    [PARAMS.MONTH]: month,
    [PARAMS.YEAR]: year,
    [PARAMS.BENCHMARK_ID]: benchmark
  })

  return data.filter(({ country_code }) => country_code)
}

export const getPorfolioMonitoringControversiesList = async ({
  portfolio,
  regions = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_REGIONS],
  sectors = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_SECTORS],
  asOfDate,
  last60Days = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_LAST60DAYS]
}) => {
  regions = regions ? regions : PAGE_CON.ALL_FILTER
  sectors = sectors ? sectors : PAGE_CON.ALL_FILTER
  const month = last60Days ? 'latest' : getMonthFromFilter(asOfDate)
  const year = last60Days ? 'latest' : getYearFromFilter(asOfDate)
  const apiUrl = `${COMMON.BASE_URL}${portfolio}${COMMON.PORTFOLIO_CONTROVERSIES}`
  const { data } = await axios.post(apiUrl, {
    [PARAMS.REGION]: regions,
    [PARAMS.SECTOR]: sectors,
    [PARAMS.MONTH]: month,
    [PARAMS.YEAR]: year
  })
  return data
}

export const getResearchLineScore = async ({
  portfolio,
  regions = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_REGIONS],
  sectors = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_SECTORS],
  asOfDate,
  research_line,
  benchmark
}) => {
  //TODO: Using mock data, should be cleaned after integration
  if (research_line === 'controversies') {
    await fakeApiCall(getRandomInt(500, 1000))
    return heatmapMock.controversy_score
  }

  regions = regions ? regions : PAGE_CON.ALL_FILTER
  sectors = sectors ? sectors : PAGE_CON.ALL_FILTER
  const month = getMonthFromFilter(asOfDate)
  const year = getYearFromFilter(asOfDate)

  const apiUrl = `${COMMON.BASE_URL}${portfolio}/${research_line}${COMMON.SCORE}`
  const { data } = await axios.post(apiUrl, {
    [PARAMS.REGION]: regions,
    [PARAMS.SECTOR]: sectors,
    [PARAMS.MONTH]: month,
    [PARAMS.YEAR]: year,
    [PARAMS.BENCHMARK_ID]: benchmark
  })
  return data
}

export const getHeatmapTable = async ({
  portfolio,
  regions = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_REGIONS],
  sectors = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_SECTORS],
  asOfDate,
  research_line_1,
  research_line_2
}) => {
  //TODO: Using mock data, should be cleaned after integration
  if (research_line_1 === 'controversies') {
    await fakeApiCall(getRandomInt(500, 1000))
    return heatmapMock.research_line1_data
  } else if (research_line_2 === 'controversies') {
    await fakeApiCall(getRandomInt(500, 1000))
    return heatmapMock.research_line2_data
  }

  regions = regions ? regions : PAGE_CON.ALL_FILTER
  sectors = sectors ? sectors : PAGE_CON.ALL_FILTER
  const month = getMonthFromFilter(asOfDate)
  const year = getYearFromFilter(asOfDate)
  const apiUrl = `${COMMON.BASE_URL}${portfolio}${COMMON.HEAT_MAP}`
  const { data } = await axios.post(apiUrl, {
    [PARAMS.REGION]: regions,
    [PARAMS.SECTOR]: sectors,
    [PARAMS.MONTH]: month,
    [PARAMS.YEAR]: year,
    [PARAMS.RESEARCHLINE_1]: research_line_1,
    [PARAMS.RESEARCHLINE_2]: research_line_2 ? research_line_2 : undefined
  })
  return data
}

const cleanResearchlineScore = (researchLine, data) => {
  const researchLines = researchLineScoreStructure(data)
  return researchLines[researchLine] || researchLines['default']
}

export const getHeatmapDescription = async ({
  portfolio,
  regions = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_REGIONS],
  sectors = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_SECTORS],
  asOfDate,
  research_line,
  benchmark
}) => {
  const researchLineScore = await getResearchLineScore({
    portfolio,
    research_line,
    regions,
    sectors,
    asOfDate,
    benchmark
  })

  const researchLineCoverage = await getResearchLinesCoverage({
    portfolio,
    research_line,
    regions,
    sectors,
    asOfDate,
    benchmark
  })

  let score = ''
  let score_range = ''
  if (typeof researchLineScore[0].portfolio_score === 'object') {
    const scoreData = cleanResearchlineScore(research_line, researchLineScore)
    score = scoreData.value?.ranking
    score_range =
      research_line === 'esgasmt'
        ? scoreData.value?.category
        : scoreData.value?.score_range
  } else {
    score = researchLineScore[0]?.ranking
    score_range = researchLineScore[0]?.score_range
  }

  const data = {
    score,
    score_range,
    coverage: researchLineCoverage.portfolio_coverage.companies
  }

  return data
}

export const getHeatmapEntity = async ({
  portfolio,
  regions = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_REGIONS],
  sectors = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_SECTORS],
  asOfDate,
  research_line_1,
  research_line_2,
  category_1 = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_CATEGORY_1],
  category_2 = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_CATEGORY_2]
}) => {
  if (
    research_line_1 === 'controversies' ||
    research_line_2 === 'controversies'
  ) {
    await fakeApiCall(getRandomInt(500, 1000))
    return heatmapMock.heat_map_entity_list
  }

  regions = regions ? regions : PAGE_CON.ALL_FILTER
  sectors = sectors ? sectors : PAGE_CON.ALL_FILTER
  const month = getMonthFromFilter(asOfDate)
  const year = getYearFromFilter(asOfDate)
  const apiUrl = `${COMMON.BASE_URL}${portfolio}${COMMON.HEAT_MAP_ENTITY_LIST}`
  const { data } = await axios.post(apiUrl, {
    [PARAMS.REGION]: regions,
    [PARAMS.SECTOR]: sectors,
    [PARAMS.MONTH]: month,
    [PARAMS.YEAR]: year,
    [PARAMS.RESEARCHLINE_1]: research_line_1,
    [PARAMS.RESEARCHLINE_2]: research_line_2 ? research_line_2 : undefined,
    [PARAMS.CATEGORY_1]: category_1,
    [PARAMS.CATEGORY_2]: category_2
  })
  return data
}

export const getCompaniesTableData = async ({
  portfolio,
  regions = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_REGIONS],
  sectors = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_SECTORS],
  asOfDate,
  viewBy = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_VIEWBY],
  limit = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_LIMIT]
}) => {
  const month = getMonthFromFilter(asOfDate)
  const year = getYearFromFilter(asOfDate)
  regions = regions ? regions : PAGE_CON.ALL_FILTER
  sectors = sectors ? sectors : PAGE_CON.ALL_FILTER

  const apiUrl = doubleEncode(
    `${COMMON.BASE_URL}${portfolio}${COMMON.PORTFOLIO_SUMMARY_COMPANIES}`
  )
  const { data } = await axios.post(apiUrl, {
    [PARAMS.REGION]: regions,
    [PARAMS.SECTOR]: sectors,
    [PARAMS.MONTH]: month,
    [PARAMS.YEAR]: year,
    [PARAMS.VIEW_BY]: viewBy,
    [PARAMS.LIMIT]: limit
  })

  return data
}

export const getDashboardCoverage = async ({
  portfolio = PAGE_CON.SAMPLE_PORTFOLIO,
  regions = PAGE_CON.ALL_FILTER,
  sectors = PAGE_CON.ALL_FILTER,
  asOfDate
}) => {
  const month = getMonthFromFilter(asOfDate)
  const year = getYearFromFilter(asOfDate)
  regions = regions ? regions : PAGE_CON.ALL_FILTER
  sectors = sectors ? sectors : PAGE_CON.ALL_FILTER

  const apiUrl = `${COMMON.BASE_URL}${portfolio}${COMMON.DASHBOARD_COVERAGE}`
  const { data } = await axios.post(apiUrl, {
    [PARAMS.REGION]: regions,
    [PARAMS.SECTOR]: sectors,
    [PARAMS.MONTH]: month,
    [PARAMS.YEAR]: year
  })

  return data
}

export const exportData = async ({
  portfolio,
  regions = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_REGIONS],
  sectors = API_DEFAULT_PAYLOAD[PAGE_CON.FILTER_SECTORS],
  asOfDate,
  portfolioName = 'Sample Portfolio'
}) => {
  const month = getMonthFromFilter(asOfDate)
  const year = getYearFromFilter(asOfDate)
  regions = regions ? regions : PAGE_CON.ALL_FILTER
  sectors = sectors ? sectors : PAGE_CON.ALL_FILTER

  const apiUrl = `${COMMON.BASE_URL}${portfolio}${EXPORT.EXPORT_CLIMATE_DATA}`
  const { data } = await axios.post(apiUrl, {
    [PARAMS.REGION]: regions,
    [PARAMS.SECTOR]: sectors,
    [PARAMS.MONTH]: month,
    [PARAMS.YEAR]: year,
    [PARAMS.PORTFOLIO_NAME]: portfolioName
  })

  return data
}
