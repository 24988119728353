import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import theme from 'theme'
import clsx from 'clsx'

export const useStyles = makeStyles(() => ({
  paper: {
    color: theme.palette.text.secondary,
    border: '0px',
    background: '#FFFFFF',
    boxShadow: 'none',
    height: (props) => props.height && props.height
  },
  container: {
    margin: (props) => (props.noMargin ? 0 : theme.spacing(4)),
    color: theme.palette.text.secondary,
    border: '0px',
    background: '#FFFFFF',
    boxShadow: 'none',
    marginTop: '10px',
    maxWidth: (props) =>
      !props.noMargin && `calc( 100% - ${theme.spacing(4) * 2}px )`,
    marginBottom: (props) => props.marginBottom && props.marginBottom,
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  box: {
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.12)',
    padding: theme.spacing(2)
  },
  main: {
    display: 'flex'
  }
}))

export const Content = ({ children, fullWidth = false }) => (
  <Grid item xs={fullWidth ? 12 : 8}>
    {children}
  </Grid>
)

export const SideBar = ({ children }) => {
  return (
    <Grid item xs={4}>
      {children}
    </Grid>
  )
}

export const Item = ({ children, height }) => {
  const classes = useStyles({ height })
  return <div className={classes.paper}>{children}</div>
}

export const Container = ({
  children,
  box,
  marginBottom,
  noMargin,
  id,
  onClick
}) => {
  const classes = useStyles({ marginBottom, noMargin })
  return (
    <div
      id={id}
      aria-hidden={true}
      className={clsx(classes.container, box && classes.box)}
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export const Header = ({ children }) => <div>{children}</div>

export const Main = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.main}>{children}</div>
}

Item.propTypes = {
  children: PropTypes.node,
  height: PropTypes.any
}

Container.propTypes = {
  children: PropTypes.node,
  box: PropTypes.bool
}
