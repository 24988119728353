let refinedScoreArr = [],
  refinedLocationArr = [
    { name: 'All Locations', company_count: 0, investment_pct: 0 }
  ],
  refinedSectorArr = [
    { name: 'All Sectors', company_count: 0, investment_pct: 0 }
  ],
  refinedSizeArr = [{ name: 'All Sizes', company_count: 0, investment_pct: 0 }]
const desiredSizeOrder = ['1-49', '50-249', '250-4999', '5000+', 'undefined']

const reset = () => {
  refinedScoreArr = []
  refinedLocationArr = [
    { name: 'All Locations', company_count: 0, investment_pct: 0 }
  ]
  refinedSectorArr = [
    { name: 'All Sectors', company_count: 0, investment_pct: 0, companies: [] }
  ]
  refinedSizeArr = [
    { name: 'All Sizes', company_count: 0, investment_pct: 0, companies: [] }
  ]
}

const customSort = (data, desiredOrder) =>
  data?.sort((a, b) => {
    return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name)
  })

  export const formatSectorOption = (option) => {
    if (option!==('No Sector'))
    return option
  }
  
  export const formatSizeOption = (option) => {
  if (option?.includes(' Employees')) return option
  let size = ''
  if (option?.includes('-')) {
    size = option
      ?.split('-')
      .map((element) => Number(element).toLocaleString('en-US'))
      .join(' - ')
  } else if (option?.includes('+')) {
    size = Number(option?.split('+')[0]).toLocaleString('en-US').concat('+')
  } else {
    return option
  }
  size += ' Employees'
  return size
}

const formatSizeArr = (arr) => {
  return arr.map((size) => {
    if (size.name?.includes('-')) {
      size.name = size.name
        ?.split('-')
        .map((element) => Number(element).toLocaleString('en-US'))
        .join(' - ')
    } else if (size.name?.includes('+')) {
      size.name = Number(size.name?.split('+')[0])
        .toLocaleString('en-US')
        .concat('+')
    } else {
      return size.name
    }
    size.name += ' Employees'
    return size.name
  })
}

const groupBy = (objectArray, property) => {
  return objectArray.reduce((acc, obj) => {
    let key = obj[property]
    if (property === 'region') key = key[0]
    const curGroup = acc[key] ?? []

    return { ...acc, [key]: [...curGroup, obj] }
  }, {})
}

const getTotalInvestmentPct = (arr, isSorted = false) => {
  let refinedArr = []
  let tempArr = isSorted ? Object.keys(arr) : Object.keys(arr).sort()
  tempArr.forEach((key) => {
    let summedInvestmentPct = 0,
      companies = [],
      score = [],
      sector = [],
      size = [],
      region_code,
      region = [],
      country = []
    arr[key].forEach((entry) => {
      if (Array.isArray(entry.score)) {
        score.push(...entry.score)
      } else {
        score.push(entry.score)
      }

      sector.push(entry.sector)
      size.push(formatSizeOption(entry.size))
      region.push(entry.region)
      country.push(entry.country)
      region_code = entry.region_code
      summedInvestmentPct += Number(entry.investment_pct)
      companies.push(entry.orbis_id)
    })

    refinedArr.push({
      name: key,
      score: score,
      investment_pct: Number(summedInvestmentPct),
      company_count: arr[key].length,
      region: region,
      region_code: region_code,
      country: country,
      sector: sector,
      size: size,
      companies: [...new Set(companies)]
    })
  })

  return refinedArr
}

const retrieveCompanies = (region, country, companies) => {
  if (region?.region_code === country.region_code) {
    companies.push(...country.companies)
    return country
  }
}
const getOverallValue = (arr, isLocation = false) => {
  if (isLocation) {
    let investment_pct,
      total = 0
    investment_pct = arr.reduce((prev, curr) => {
      total += curr?.country?.length
      return (prev += Number(curr.investment_pct))
    }, 0)
    return { investment_pct, total }
  } else {
    return arr.reduce((prev, curr) => (prev += Number(curr.investment_pct)), 0)
  }
}

const getRegionName = (entity) => {
  switch (entity.region_code?.toUpperCase()) {
    case 'AMER':
      return 'All Americas'
    case 'APAC':
      return 'All Asia Pacific'
    case 'EMEA':
      return 'All EMEA'
    default:
      return entity.region_name
  }
}

export const extractCardInfo = (criteriaFilterData = []) => {
  let score = [],
    location = [],
    sector = [],
    size = [],
    countryArr = [],
    regionArr = []

  criteriaFilterData?.entities?.forEach((entity) => {
    score.push({
      score: Number(entity.score),
      investment_pct: entity.investment_pct,
      orbis_id: entity.bvd9_number,
      region_code: entity.region_code,
      region: getRegionName(entity),
      country: entity.country_name,
      sector: entity.sector,
      size: entity.size
    })
    location.push({
      score: Number(entity.score),
      investment_pct: entity.investment_pct,
      orbis_id: entity.bvd9_number,
      country: entity.country_name,
      region_code: entity.region_code,
      region: getRegionName(entity),
      sector: entity.sector,
      size: entity.size
    })
    sector.push({
      score: Number(entity.score),
      investment_pct: entity.investment_pct,
      orbis_id: entity.bvd9_number,
      region_code: entity.region_code,
      region: getRegionName(entity),
      country: entity.country_name,
      sector: entity.sector === 'No Sector' ? undefined : entity.sector,
      size: entity.size,
      company_count: 0
    })
    size.push({
      score: Number(entity.score),
      investment_pct: entity.investment_pct,
      orbis_id: entity.bvd9_number,
      region_code: entity.region_code,
      region: getRegionName(entity),
      country: entity.country_name,
      sector: entity.sector,
      size: entity.size,
      company_count: 0
    })
  })

  const groupedScore = groupBy(score, 'score')
  const groupedCountry = groupBy(location, 'country')
  const groupedSector = groupBy(sector, 'sector')
  const groupedSize = groupBy(size, 'size')

  reset()

  refinedScoreArr = getTotalInvestmentPct(groupedScore, true)
  countryArr = getTotalInvestmentPct(groupedCountry)
  regionArr = getTotalInvestmentPct(groupBy(countryArr, 'region'))
  refinedSectorArr.push(...getTotalInvestmentPct(groupedSector))
  refinedSizeArr.push(...getTotalInvestmentPct(groupedSize))

  let tempSizeArr = refinedSizeArr.filter((size) => size.name !== 'undefined')
  tempSizeArr.forEach((size, i) => {
    if (i === 0) {
      size.investment_pct = getOverallValue(tempSizeArr)
      size.total = tempSizeArr.length - 1
    }
  })

  let tempSectorArr = refinedSectorArr.filter(
    (sector) => sector.name !== 'undefined'
  )
  tempSectorArr.forEach((sector, i) => {
    if (i === 0) {
      sector.investment_pct = getOverallValue(tempSectorArr)
      sector.total = tempSectorArr.length - 1
    }
  })

  refinedLocationArr.push(...regionArr)
  refinedLocationArr.forEach((region, i) => {
    if (i === 0) {
      let overallValue = getOverallValue(regionArr, true)
      region.investment_pct = overallValue['investment_pct']
      region.total = overallValue['total']
    }
    let companies = []
    region.countries = countryArr.filter((country) =>
      retrieveCompanies(region, country, companies)
    )
    region.companies = [...companies]
  })

  formatSizeArr(customSort(refinedSizeArr, desiredSizeOrder))

  return {
    pristineData: criteriaFilterData,
    scoreArr: refinedScoreArr,
    locationArr: refinedLocationArr,
    sectorArr: refinedSectorArr,
    sizeArr: refinedSizeArr
  }
}

export const formattingRules = (
  number,
  applyFormatting = false,
  min = 1,
  max = 99,
  decimals = 0,
  prepend = '',
  threshold
) => {
  let result
  if (number > 0 && number <= min) {
    result = `${prepend}${threshold || min}`
  } else if (number >= max && number < 100) {
    result = max
  } else {
    result = number.toFixed(decimals)
  }
  return applyFormatting ? `(${result}%)` : `${result}%`
}
