import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    marginBottom: '15px',
    flexWrap: 'wrap'
  },
  item: {
    marginBottom: '5px',
    ...theme.typography.p1,
    borderRadius: '2px',
    padding: '4px 8px',
    color: theme.palette.sys_trad?.text.main,
    backgroundColor: theme.palette.sys_trad?.bg.main,
    cursor: (props) => (props.disabled ? 'not-allowed' : 'pointer'),
    marginRight: '4px',
    '&:last-child': {
      marginRight: '0px'
    },
    height: 'fit-content',
    '&:hover': {
      background: theme.palette.sys_trad?.hover2.main
    },
    '&.active': {
      color: theme.palette.sys_trad?.white.main,
      backgroundColor: theme.palette.sys_trad?.selected.main
    }
  }
}))

export const Container = ({ children, heap_id }) => {
  const classes = useStyles()
  return (
    <div
      {...(heap_id ? { heap_id: heap_id } : {})}
      className={classes.container}
    >
      {children}
    </div>
  )
}

export const Item = ({
  children,
  active = false,
  action,
  disabled = false,
  heap_id
}) => {
  const classes = useStyles({ disabled })
  return (
    <div
      {...(heap_id && heap_id === 'heatmap'
        ? { heap_heatmap_id: 'researchline' }
        : {})}
      onClick={action}
      aria-hidden={true}
      className={`${classes.item} ${active ? 'active' : ''}`}
    >
      {children}
    </div>
  )
}
