import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import themeColorPalette from 'theme/theme'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { styled } from '@material-ui/core/styles'
import { useStyles } from './styles'

/**
 * @visibleName Toggle Button
 */

const ToggleButton = (props) => {
  const classes = useStyles(props)
  const disbledColor = 'rgba(0,0,0,.25)'
  const activeColor = themeColorPalette.palette.sys_trad.selected.main
  const hoverColor = themeColorPalette.palette.sys_trad.hover.main
  const {
    id,
    toggleLabelText,
    disabled,
    labelClass,
    labelPlacement,
    isChecked
  } = props
  const [toggleColor, setToggleColor] = useState(
    isChecked ? activeColor : disbledColor
  )

  useEffect(() => {
    setToggleColor(isChecked ? activeColor : disbledColor)
  }, [isChecked])

  const AntToggle = styled(Switch)(({ theme }) => ({
    width: 24,
    height: 14,
    padding: 0,
    display: 'flex',
    '&:hover': {
      '.MuiSwitch-track': {
        backgroundColor: `${hoverColor} !important`
      }
    },
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 13
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)'
      }
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(10px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: toggleColor
        }
      },
      '&.Mui-disabled': {
        color: '#fafafa',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: disbledColor
        }
      }
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 10,
      height: 10,
      borderRadius: 6,
      transition: theme.transitions.create(['width'], {
        duration: 200
      })
    },
    '& .MuiSwitch-track': {
      borderRadius: 14 / 2,
      opacity: 1,
      backgroundColor: toggleColor,
      boxSizing: 'border-box'
    }
  }))

  const onChange = (event) => {
    if (props.onChange)
      props.onChange({ id: id, checked: event.target.checked })
  }

  return (
    <FormControl id={id} component="fieldset">
      <FormControlLabel
        className={classes.root}
        control={
          <AntToggle
            inputProps={{ 'aria-label': 'ant design' }}
            disabled={disabled}
          />
        }
        label={
          <span className={classes[labelClass]}>
            {toggleLabelText ? toggleLabelText : ''}
          </span>
        }
        labelPlacement={labelPlacement}
        checked={isChecked}
        onChange={onChange}
      />
    </FormControl>
  )
}

ToggleButton.propTypes = {
  /** Label for Toggle */
  toggleLabelText: PropTypes.string,
  /** isChecked */
  isChecked: PropTypes.bool,
  /** disabled */
  disabled: PropTypes.bool,
  /** Gets called when the input changes */
  handleSwitchChange: PropTypes.func,
  /** Label position for Toggle */
  labelPlacement: PropTypes.oneOf(['bottom', 'end', 'start', 'top']),
  /** Label class name for Toggle */
  labelClass: PropTypes.string,
  /** Label class name for Toggle */
  onChange: PropTypes.func
}

ToggleButton.defaultProps = {
  labelPlacement: 'start',
  labelClass: 'label',
  disabled: false
}

export default ToggleButton
