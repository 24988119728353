import React from 'react'

const ArrowDown = ({ color = '#0F997D' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="12"
      fill="none"
      viewBox="0 0 8 12"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M4.354 11.854a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L3.5 10.293V.5a.5.5 0 011 0v9.793l2.646-2.647a.5.5 0 11.708.708l-3.5 3.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default ArrowDown
