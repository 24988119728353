import React, { useContext, useState } from 'react'
import { GlobalContext } from 'context/GlobalStateProvider'
import { REDUCER_TYPE } from 'constant/constants'
import { Grid, List, ListItem, ListItemText, Toolbar } from '@material-ui/core'
import ReportTemplate from 'views/portfolioanalysis/ReportTemplate'
import { PrimaryButton } from 'components'
import AddMissingDocuments from './AddMissingDocuments'
import themeColorPalette from 'theme/theme'
import { hexToRGB } from 'utils/color'
import * as S from './styles'

const BANNER_BULLETS = [
  {
    text: 'Understand the proposed methodology to access your company',
    highlighted: false
  },
  {
    text: 'View data collected and provide missed documents',
    highlighted: true
  },
  { text: 'View your company\'s scorecard', highlighted: false }
]

const decorateBullet = (text) => `• ${text}`

const BannerList = () => {
  const classes = S.useStyles()

  return (
    <List dense disablePadding>
      {BANNER_BULLETS.map((bullet) => (
        <ListItem key={bullet.text} className={classes.listItem}>
          <ListItemText
            disableTypography
            style={{
              color: bullet.highlighted
                ? themeColorPalette.palette.sys_trad.status.main
                : 'inherit'
            }}
            className={classes.textBullet}
            primary={decorateBullet(bullet.text)}
          />
        </ListItem>
      ))}
    </List>
  )
}

const IssuerBanner = (props) => {
  const classes = S.useStyles()
  const [entityHeaderState] = useContext(GlobalContext).globalEntityHeader
  const issuerUrlDispatch = useContext(GlobalContext).globalIssuerUrl[1]
  const [open, setOpen] = useState(false)

  const onUploadUrlClickOpen = () => {
    issuerUrlDispatch({
      type: REDUCER_TYPE.RESET
    })
    setOpen(true)
  }

  const onUploadUrlClickClose = () => {
    issuerUrlDispatch({
      type: REDUCER_TYPE.RESET
    })
    setOpen(false)
  }

  return (
    <div id="issuer-banner-id" className={classes.imageClass}>
      <div style={{ backgroundColor: hexToRGB('#26415e', 0.8) }}>
        <ReportTemplate
          report={
            <Toolbar disableGutters className={classes.issuer_banner}>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Grid item container direction="column" xs={12} sm={12}>
                  <Grid item className={classes.textTop}>
                    {
                      'Welcome to Moody’s Analytics: the portal connecting you to the Moody’s Analytics ESG Assessment process.'
                    }{' '}
                    <br />
                  </Grid>
                  <Grid item className={classes.text}>
                    {'We will be conducting an ESG Assessment on ' +
                      `${entityHeaderState.globalEntityHeader?.company_name}` +
                      ' which has been assigned to the ' +
                      `${entityHeaderState.globalEntityHeader?.mesg_sector}` +
                      ' industry.'}
                  </Grid>
                  <Grid item className={classes.text}>
                    {'This portal allows you to:'} <br />
                  </Grid>
                  <BannerList classes={classes} />
                  <Grid item className={classes.textSecondBottom}>
                    {
                      'You will receive an email to review information when the assessment proceeds to the next stage.'
                    }
                  </Grid>
                  <Grid item className={classes.textBottom}>
                    {'Please contact us if you have questions.'}
                  </Grid>
                  <Grid item>
                    <PrimaryButton
                      id="contact-button"
                      buttonText="Contact Us"
                      buttonClass="buttonAltGhost"
                      buttonWidth="155px"
                      onClick={() => {
                        window.location = 'mailto:ESG360@moodys.com'
                      }}
                    ></PrimaryButton>
                    <PrimaryButton
                      id="missing-docs-button"
                      buttonText="Add Information"
                      buttonClass="buttonAltPrimary"
                      buttonWidth="155px"
                      buttonMarginLeft="10px"
                      onClick={onUploadUrlClickOpen}
                    ></PrimaryButton>
                  </Grid>
                </Grid>
                <AddMissingDocuments
                  open={open}
                  onClose={onUploadUrlClickClose}
                  onOpen={onUploadUrlClickOpen}
                  showDisclosureYear={props.apiEntitlements?.includes(
                    'issuerp3'
                  )}
                />
              </Grid>
            </Toolbar>
          }
          maxWidth={'1280px'}
        />
      </div>
    </div>
  )
}

export default IssuerBanner
