const componentToHex = (c) => {
  const hex = c.toString(16)
  return hex.length === 1 ? `0${hex}` : hex
}

const colorChannelMixer = (colorA, colorB) => {
  const channelA = colorA * 0.5
  const channelB = colorB * 0.5
  return parseInt(channelA + channelB, 10)
}

export const colorMix = (rgbA, rgbB) => {
  const r = colorChannelMixer(rgbA[0], rgbB[0])
  const g = colorChannelMixer(rgbA[1], rgbB[1])
  const b = colorChannelMixer(rgbA[2], rgbB[2])
  return [r, g, b]
}

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null
}

export const rgbToHex = (rgb) =>
  `#${componentToHex(rgb[0])}${componentToHex(rgb[1])}${componentToHex(rgb[2])}`
