import { COMMON, PARAMS } from 'constant/APIconstants'
import { PAGE_CON } from 'constant/constants'
import { getMonthFromFilter, getYearFromFilter } from 'utils/Date'
import axios from 'axios'

export const retrieveResearchLineApi = async ({
  portfolio = '00000000-0000-0000-0000-000000000000',
  regions = 'all',
  sectors = 'all',
  asOfDate = '202106',
  benchmark = '',
  riskLevel = '',
  riskCategory = '',
  checkForEntitlements = false,
  isEntitled = false,
  researchLine,
  token,
  endPoint
}) => {
  const month = getMonthFromFilter(asOfDate)
  const year = getYearFromFilter(asOfDate)

  if (checkForEntitlements) {
    return isEntitled
      ? await axios.post(
          COMMON.ENTITY_URL + portfolio + researchLine + endPoint
        )
      : Promise.resolve({ data: [''] })
  }

  return await axios.post(
    COMMON.BASE_URL + portfolio + researchLine + endPoint,
    {
      [PARAMS.REGION]: regions,
      [PARAMS.SECTOR]: sectors,
      [PARAMS.MONTH]: month,
      [PARAMS.YEAR]: year,
      [PARAMS.BENCHMARK_ID]: benchmark,
      [PARAMS.RISK_LEVEL]: riskLevel,
      [PARAMS.RISK_CATEGORY]: riskCategory
    },
    { cancelToken: token }
  )
}

export const getEntityList = async ({
  portfolio = '00000000-0000-0000-0000-000000000000',
  research_line = 'all',
  regions = 'all',
  sectors = 'all',
  asOfDate = '202106',
  country_code
}) => {
  const month = getMonthFromFilter(asOfDate)
  const year = getYearFromFilter(asOfDate)
  regions = regions === '' ? PAGE_CON.ALL_FILTER : regions
  sectors = sectors === '' ? PAGE_CON.ALL_FILTER : sectors
  return await axios.post(
    `${COMMON.BASE_URL}${portfolio}/${research_line}${COMMON.ENTITYLIST}`,
    {
      [PARAMS.REGION]: regions,
      [PARAMS.SECTOR]: sectors,
      [PARAMS.MONTH]: month,
      [PARAMS.YEAR]: year,
      [PARAMS.COUNTRY_CODE]: country_code
    }
  )
}
