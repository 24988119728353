import DriverDisclosure from './DriverDisclosure'
import { SourceDocuments } from './DriverInfo.json'
import { Grid } from '@material-ui/core'
import React from 'react'
import { PAGE_CON } from 'constant/constants'
import Error from 'utils/error'
import LoadingMask from 'utils/loadingMask'
import { SourceLink } from 'components'
import clsx from 'clsx'
import * as S from './styles'
import Trend from './Trend'

const DriverInfoSector = (props) => {
  const classes = S.useStyles(props)

  const { reloadComponent } = props

  const disclosure = {
    isLoading: props.isLoading,
    dataStatus: props.data.dataStatus,
    data: props.data.apiData || []
  }

  const isDataAvailable = () => {
    let hasData = false
    if (isDisclosureHasData() || isTrendHasData()) hasData = true
    return hasData
  }

  const isDisclosureHasData = () => {
    let hasData = false
    if (
      disclosure.data?.item?.length > 0 ||
      disclosure.data?.trends?.length > 0
    )
      hasData = true
    return hasData
  }

  const isTrendHasData = () => {
    let hasData = false
    if (disclosure.data?.trends?.length > 0) hasData = true
    return hasData
  }

  let sourceDocComp = []
  const link = 'https://www.moodys.com/Pages/contactus.aspx'

  const handleOpenSourceDocument = (reportLink) => {
    // TODO Replace with correct path for link to report
    const newWindow = window.open(reportLink, '_blank')
    if (newWindow) newWindow.opener = null
  }

  if (SourceDocuments && SourceDocuments.length > 0) {
    let lengthOfSourceDoc = SourceDocuments.length - 1
    SourceDocuments.map((sourceDoc, index) => {
      return sourceDocComp.push(
        <div
          className={clsx(
            classes.sourceDocStyle,
            index === 0 && classes.sourceDocTopStyle,
            index !== 0 && classes.sourceDocMiddleStyle,
            index === lengthOfSourceDoc && classes.sourceDocLastStyle
          )}
        >
          <SourceLink
            id={`${index} + ${sourceDoc}`}
            key={`${index} + ${sourceDoc}`}
            link={link}
            title={sourceDoc}
            onLinkClick={handleOpenSourceDocument}
          />
        </div>
      )
    })
  }

  return (
    <Grid container spacing={3} className={classes.gridStyle}>
      <Grid item xs={12} sm={12} md={12}>
        {disclosure.isLoading ? (
          <LoadingMask />
        ) : !isDataAvailable() ? (
          <div className={classes.errorStyle}>
            <Error
              errorClass={'centerAlign'}
              id={'driverError'}
              margin={'0px'}
              dataStatus={disclosure.dataStatus}
              onErrorClick={reloadComponent}
              errorOccuredOn={PAGE_CON.ENTITY_DRIVER_SUMMARY}
              hideBoxShadow={true}
            />
          </div>
        ) : (
          <Grid container spacing={3}>
            {isDisclosureHasData() && (
              <Grid item xs={12} sm={12} md={12}>
                <DriverDisclosure disclosureData={disclosure.data} />
              </Grid>
            )}
            {isTrendHasData() && (
              <Grid item xs={12} sm={12} md={12}>
                <Trend
                  id={'trend_id'}
                  trendData={disclosure?.data?.trends}
                ></Trend>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default DriverInfoSector
