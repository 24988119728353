import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import { IconClose } from 'components'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { Backdrop } from '@material-ui/core'
import { useStyles } from './styles'

const PrimaryPopover = (props) => {
  const classes = useStyles(props)
  const vertical = 'top'
  const horizontal = props.alertPosition

  const onClickAwayAlert = () => {
    return
  }

  const SnackBar = (
    <Snackbar
      className={classes.snackbar}
      anchorOrigin={{ vertical, horizontal }}
      open={props.alertOpen}
      ClickAwayListenerProps={
        props.popoverClickAway ? {} : { onClickAway: onClickAwayAlert }
      }
      onClose={props.onAlertClose}
    >
      <Alert
        id={props.id}
        icon={false}
        severity={props.severity}
        className={clsx(classes.popover, classes[props.popoverClass])}
      >
        <Box display="flex">
          <span className={classes.alertIcon}> {props.alertIcon} </span>
          <Box className={classes.alertTextBox}> {props.alertText} </Box>
          {!props.hideClose && (
            <Box flexShrink={1}>
              <IconButton
                className={classes.popoverCloseIcon}
                disableRipple
                disableFocusRipple
                onClick={props.onAlertClose}
              >
                <IconClose className={classes.closeIcon} />
              </IconButton>
            </Box>
          )}
        </Box>
        {props.alertContentComponent}
      </Alert>
    </Snackbar>
  )

  return !props.hideBackdrop ? (
    <Backdrop className={classes.backdrop} open={props.alertOpen}>
      {SnackBar}
    </Backdrop>
  ) : (
    SnackBar
  )
}

PrimaryPopover.propTypes = {
  /** Popover id */
  id: PropTypes.string,
  /** The icon for the Popover */
  alertIcon: PropTypes.object,
  /** Popover text */
  alertText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** padding top optional */
  alertTextTopPadding: PropTypes.string,
  /** Determines Error or Success alert*/
  alertError: PropTypes.bool,
  /** Additional component to include in alert*/
  alertContentComponent: PropTypes.any,
  /** Alert is open*/
  alertOpen: PropTypes.bool,
  /** if true popover will close the popover when you click away*/
  popoverClickAway: PropTypes.bool,
  /** Popover position */
  alertPosition: PropTypes.string,
  /** Popover vertical position */
  alertTop: PropTypes.any,
  /** Popover right position */
  alertRight: PropTypes.any,
  /** Popover horizontal position */
  alertLeft: PropTypes.any,
  /** Popover width */
  alertWidth: PropTypes.any,
  /** Gets called when the user clicks on the button */
  onAlertClose: PropTypes.func,
  /** Determines Popover Alert or Popover Menu Class */
  popoverClass: PropTypes.string,
  /** Determines Popover Alert Height */
  alertHeight: PropTypes.string,
  /** Time alert is open for (seconds) must be passed along with timeOut prop */
  alertOpenTime: PropTypes.number,
  /** Time alert is open for (seconds) must be passed along with timeOut prop */
  alertTimeOut: PropTypes.number,
  /** margin left optional */
  marginLeft: PropTypes.string,
  /** True if there are multiple alerts on one page */
  duplicates: PropTypes.any,
  /** True if the alert is an upload error */
  uploadError: PropTypes.bool,
  /** True if backdrop should be hidden */
  hideBackdrop: PropTypes.bool,
  /** True if close icon should be hidden */
  hideClose: PropTypes.bool,
  /** padding top optional */
  paddingTop: PropTypes.string,
  /** padding bottom optional */
  paddingBottom: PropTypes.string,
  /** True if popover has a box shadow */
  popoverShadow: PropTypes.bool,
  /** Popover background color */
  popoverBgColor: PropTypes.string,
  /** Popover overflowY optional */
  overflowY: PropTypes.string
}

PrimaryPopover.defaultProps = {
  hideClose: false,
  popoverShadow: true
}

export default PrimaryPopover
