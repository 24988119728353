import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'
import React from 'react'
import Select from '@material-ui/core/Select'
import { CustomInput, useStyles } from './styles'

/**
 * @visibleName Dropdown container
 */
const DropdownContainer = (props) => {
  const classes = useStyles({ ...props })
  const [selectOpen, setSelectOpen] = React.useState(false)

  React.useEffect(() => {
    onClose()
  }, [props.containerClick])

  function onClose() {
    if (props.preCloseValidation) {
      if (props.preCloseValidation() === false) return
    }
    setSelectOpen(false)
    if (props.onClose) {
      props.onClose()
    }
  }

  function onOpen() {
    setSelectOpen(true)
    if (props.onOpen) {
      props.onOpen()
    }
  }

  return (
    <FormControl className={classes.formControl}>
      <Select
        disabled={props.disabled}
        autoWidth
        id={props.id}
        title={props.displayText}
        open={selectOpen}
        value={['']}
        multiple={true}
        input={<CustomInput />}
        inputProps={{
          classes: { icon: classes.icon },
          // eslint-disable-next-line no-unused-vars
          renderValue: (val) => props.displayText
        }}
        className={classes.dropdownStyle}
        onClose={onClose}
        onOpen={onOpen}
        defaultValue=""
        MenuProps={{
          classes: {
            list: classes[props.dropDownMenuClass],
            paper: classes.dropdownPaperStyle
          },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }}
      >
        {props.containerComponent}
      </Select>
    </FormControl>
  )
}

DropdownContainer.propTypes = {
  /** Dropdown id */
  id: PropTypes.string,
  /** The Key of the default selected option for dropdown (must match a key in dropdownOptions object ) */
  dropdownDefaultSelction: PropTypes.any,
  /** Max width for dropdown */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** call onClose function */
  onClose: PropTypes.func,
  /** container component to display inside dropdown*/
  containerComponent: PropTypes.any,
  /*Display text of container*/
  displayText: PropTypes.string,
  /*class name for dropdown container*/
  dropDownMenuClass: PropTypes.string,
  /*It will have unique value to let DropdownCantainer that containerComponent is clicked*/
  containerClick: PropTypes.number,
  /*Disable select*/
  disabled: PropTypes.bool
}

DropdownContainer.defaultProps = {
  multiSelect: false,
  dropDownMenuClass: 'dropdownMenuStyle'
}

export default DropdownContainer
