import { useLocation } from 'react-router-dom'
import { DropdownContainer, ReportNavigation } from 'components'
import React from 'react'

const NavComp = (props) => {
  function getRoutePath(pathname) {
    let pathTitle = ''
    let path = props.sideBarItems.filter((route) =>
      pathname.includes(route.href)
    )
    if (path.length) {
      pathTitle = path[0].title
    }

    return pathTitle
  }

  const [itemClick, setItemClick] = React.useState(0)

  const onItemClick = () => {
    setItemClick((prev) => prev + 1)
  }

  let pathname = useLocation().pathname

  return (
    <DropdownContainer
      id="Nav-test-id-1"
      displayText={getRoutePath(pathname)}
      isNotDefaultFilter={true}
      dropDownMenuClass={'navDropdownMenuStyle'}
      containerClick={itemClick}
      containerComponent={
        <ReportNavigation
          id={'portfolioanalysis-reportnavigation-test-id'}
          sideBarItems={props.sideBarItems}
          section={props.section}
          entitlements={props.entitlements}
          onNavClick={onItemClick}
        />
      }
    />
  )
}

export default NavComp
