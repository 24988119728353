import { makeStyles } from '@material-ui/core/styles'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'

export const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: (props) =>
      props.whiteBg
        ? themeColorPalette.palette.sys_trad.white.main
        : themeColorPalette.palette.sys_trad.bg.main,
    color: themeColorPalette.palette.sys_trad.text.main,
    boxShadow: 'none',
    marginBottom: '6px'
  },
  drawerPaper: {
    backgroundColor: (props) =>
      props.whiteBg
        ? themeColorPalette.palette.sys_trad.white.main
        : themeColorPalette.palette.sys_trad.bg.main,
    top: (props) => (props.topNum !== undefined ? props.topNum : 167),
    right: (props) => (props.rightNum !== undefined ? props.rightNum : 0),
    height: (props) =>
      props.topNum !== undefined
        ? 'calc(100% - ' + props.topNum + 'px'
        : 'calc(100% - 167px)',
    boxShadow: 'none',
    overflowX: 'hidden',
    autoWidth: true
  },
  drawerHeader: {
    display: 'flex',
    ...textTheme.typography.h1,
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  drawerHeaderTitle: {
    padding: (props) => props.titlePadding
  },
  drawersubheaderTitle: {
    paddingTop: '10px',
    ...textTheme.typography.p2
  },
  drawerHeaderHide: {
    paddingRight: (props) => props.titlePadding,
    bottom: '13px',
    position: 'relative'
  },
  panelScroller: {
    overflow: 'auto',
    autoWidth: true
  },
  closeSymbol: {
    display: 'inline-block',
    cursor: 'pointer',
    verticalAlign: 'bottom',
    marginTop: '30px',
    height: '19px',
    width: '19px'
  }
}))
