import { AppBar, Toolbar } from '@material-ui/core'
import { GlobalContext } from 'context/GlobalStateProvider'
import { makeStyles } from '@material-ui/core/styles'
import { multiColumnSort } from 'utils/Sort'
import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'
import { Card, MiniCardInfoSection, PrimaryLink, Textbox } from 'components'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import React, { useContext } from 'react'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

const useStyles = makeStyles(() => ({
  pageHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    margin: 0,
    boxShadow: 'none'
  },
  toolbarStyle: {
    marginLeft: '0px',
    marginTop: '16px',
    minHeight: 'auto'
  },
  uploadGridStyle: {
    paddingTop: 10,
    paddingBottom: 8,
    width: 350
  },
  notFound: {
    ...textTheme.typography.p3,
    fontWeight: '500',
    lineHeight: '16px',
    color: themeColorPalette.palette.sys_trad.texthelp.main,
    textAlign: 'center'
  },
  itemStyle: {
    marginTop: '120px'
  },
  paddingStyle: {
    marginBottom: '16px'
  },
  highlight: {
    backgroundColor: themeColorPalette.palette.sys_trad.highlight.main
  },
  gridItem: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 0,
    paddingLeft: 0,
    width: 350
  }
}))

const PortfolioSearch = (props) => {
  const classes = useStyles(props)
  const context = useContext(GlobalContext)
  const [filterState, filterDispatch] = context.globalFilter

  let searchPlaceHolder = `Search in ${filterState.filterData.portfolioList.length} results`
  const getSortedProfileData = () => {
    let portfoliolist = filterState.filterData.portfolioList.map(
      (portfolio) => {
        let selected =
          portfolio.key === filterState.globalPortfolioFilter.portfolio ? 1 : 0
        return { ...portfolio, isSelected: selected, portfolioDate: '' }
      }
    )

    let columnsToBeSorted = [
      ['isSelected', 'DESC'],
      [PAGE_CON.UPLOAD_DATE_UTC, 'DESC'],
      [[PAGE_CON.DROPDOWN_DISPLAY_COLUMN], 'ASC', 'string', true]
    ]
    return multiColumnSort(portfoliolist, columnsToBeSorted)
  }

  const [searchText, setSearchText] = React.useState('')
  const [selectedCardId, setSelectedCardId] = React.useState(
    filterState.globalPortfolioFilter.portfolio
  )
  const [originalData] = React.useState(getSortedProfileData())
  const [searchResult, setSearchResult] = React.useState('')

  function escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&')
  }

  React.useEffect(() => {
    if (searchText === '') {
      setSearchResult(originalData)
    } else {
      let regSerachText =
        '(' +
        escapeRegExp(searchText)
          .split(' ')
          .filter((x) => x !== '')
          .join('|') +
        ')'
      let filterRegSerachText =
        escapeRegExp(searchText)
          .split(' ')
          .filter((x) => x !== '')
          .map((x) => '(?=.*' + x + ')')
          .join('') + '.+'
      let rData = originalData
        .filter((portfolio) =>
          new RegExp(filterRegSerachText, 'gi').test(
            portfolio[PAGE_CON.DROPDOWN_DISPLAY_COLUMN]
          )
        )
        .map((portfolio) => {
          let portfolioName = portfolio[
            PAGE_CON.DROPDOWN_DISPLAY_COLUMN
          ].replace(
            new RegExp(regSerachText, 'gi'),
            (match) =>
              `${PAGE_CON.LESS_THAN_MASK}mark class=${classes.highlight}>${match}${PAGE_CON.LESS_THAN_MASK}/mark>`
          )
          return {
            ...portfolio,
            [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: portfolioName
          }
        })

      setSearchResult(rData)
    }
  }, [searchText])

  const onSearchChange = (inputText) => {
    setSearchText(inputText)
  }

  const portfolioCardSelect = (id) => {
    setSelectedCardId(id)
    if (props.onPortfolioClick) props.onPortfolioClick(id)
  }

  const onUpload = (uploadType) => {
    if (props.onUploadClick) props.onUploadClick()
    filterDispatch({
      type: REDUCER_TYPE.UPLOAD_PORTFOLIO_MODAL,
      payload: true,
      uploadType,
      uploadInitiatedFrom: PAGE_CON.FILTER_PORTFOLIO
    })
  }

  const contentInfo = [
    {
      labelType: 'Radio'
    },
    {
      labelType: 'Text',
      className: 'portfolio',
      dataField: 'portfolioName',
      showToolTip: true
    },
    {
      labelType: 'Date',
      dataField: PAGE_CON.UPLOAD_DATE_UTC
    }
  ]

  return (
    <Grid container direction="column" spacing={2} id={props.id} key={props.id}>
      <Grid item>
        <AppBar id={props.id} position="sticky" className={classes.pageHeader}>
          <Toolbar disableGutters className={classes.toolbarStyle}>
            <Textbox
              id="textbox-search-test-id-2"
              textboxDefaultPlaceholder={searchPlaceHolder}
              textboxClass="searchTextbox"
              showSearchIcon={true}
              textboxMargin={'8'}
              textboxError={false}
              multiline={false}
              onChange={onSearchChange}
              key={'textbox-search-test-id-2'}
              iconSize={35}
              textboxHeight={'32px'}
              textboxWidth={'350px'}
              autoCompleteOff={true}
            />
          </Toolbar>
        </AppBar>
        {searchResult.length > 0 ? (
          <Grid container direction="column">
            <Grid item>
              <Grid
                container
                direction="column"
                className={classes.uploadGridStyle}
                alignItems={'flex-end'}
              >
                <Grid item>
                  <PrimaryLink
                    id="link-upload"
                    key="link-upload"
                    linkSelected={true}
                    linkText="Upload Portfolio"
                    linkClass="uploadLink"
                    onClick={() => onUpload(PAGE_CON.FILTER_PORTFOLIO)}
                    linkMargin={'12px'}
                  />
                </Grid>
              </Grid>
            </Grid>
            {searchResult.map((portfolio, index) => {
              let cardInfoData = {
                portfolioName: portfolio[PAGE_CON.DROPDOWN_DISPLAY_COLUMN],
                [PAGE_CON.UPLOAD_DATE_UTC]: portfolio[PAGE_CON.UPLOAD_DATE_UTC]
              }
              let comp = [
                <MiniCardInfoSection
                  id={`mini-${index}`}
                  key={`mini-${index}`}
                  heap_id="portfolio-selection"
                  heap_portfolio_title={`${cardInfoData.portfolioName}_${cardInfoData.update_date}`}
                  contentInfo={contentInfo}
                  data={cardInfoData}
                />
              ]

              return (
                <Grid
                  item
                  key={`gridPort-${index}`}
                  className={classes.gridItem}
                >
                  <Card
                    cardClass="miniCard"
                    maxWidth={'auto'}
                    components={comp}
                    id={portfolio.key}
                    selectedId={selectedCardId}
                    onCardSelect={portfolioCardSelect}
                    selectionType={'single'}
                    key={`portfoliocard-test-id-${index}`}
                    isCardClickable={true}
                  />
                </Grid>
              )
            })}
          </Grid>
        ) : (
          <Grid container direction="column" spacing={1} alignItems="center">
            <Grid item>
              <div className={clsx(classes.notFound, classes.itemStyle)}>
                No results found
              </div>
              <div className={clsx(classes.notFound, classes.paddingStyle)}>
                Upload a portfolio to start an analysis
              </div>
              <div className={clsx(classes.notFound)}>
                <PrimaryLink
                  id="notfound-link-upload"
                  key="notfound-link-upload"
                  linkSelected={true}
                  linkText="Upload Portfolio"
                  linkClass="uploadLink"
                  onClick={() => onUpload(PAGE_CON.FILTER_PORTFOLIO)}
                />
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default PortfolioSearch
