import { Box, makeStyles } from '@material-ui/core'
import bullet from 'highcharts/modules/bullet.js'
import clsx from 'clsx'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import React from 'react'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'

const Donut = ({
  donutTitle,
  donutTitleMargin,
  donutDataLabelPosition,
  donutDataLabelXCoord,
  donutDataLabelYCoord,
  donutDataLabel,
  donutData,
  donutColors,
  donutMarginLeft,
  donutMarginTop,
  donutChartWidth,
  donutChartHeight,
  donutChartPosition,
  donutBackground,
  donutBorderWidth,
  donutInnerSize,
  className,
  donutChartDiameter
}) => {
  Highcharts.setOptions({
    colors: donutColors || [
      themeColorPalette.palette.genrang_a.four.main,
      themeColorPalette.palette.genrang_a.seven.main
    ]
  })

  bullet(Highcharts)
  const options = {
    credits: {
      enabled: false
    },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height: donutChartHeight,
      width: donutChartWidth,
      backgroundColor: donutBackground
        ? themeColorPalette.palette.sys_trad.bg.main
        : '',
      spacingTop: 15,
      spacingBottom: 8
    },
    title: {
      style:
        donutTitle === '.'
          ? {
              color: themeColorPalette.palette.white.main,
              ...textTheme.typography.p2
            }
          : {
              ...textTheme.typography.p2,
              fontSize: '10 !important'
            },
      text: donutTitle || '',
      align: 'left',
      margin: donutTitleMargin || 15
    },
    subtitle: {
      text: donutDataLabel,
      align:
        donutDataLabelPosition === 'bottom'
          ? 'center'
          : donutDataLabelPosition === 'left'
          ? 'left'
          : 'right',
      verticalAlign:
        donutDataLabelPosition === 'bottom' || donutDataLabelPosition === 'left'
          ? 'bottom'
          : 'middle',
      style: {
        ...textTheme.typography.p3,
        color: themeColorPalette.palette.sys_trad.text.main
      },
      x: donutDataLabelXCoord || 0,
      y: donutDataLabelYCoord || 12
    },
    tooltip: {
      enabled: false
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            opacity: 1
          }
        }
      },
      pie: {
        borderWidth: donutBorderWidth || 0,
        colors: donutColors || [
          themeColorPalette.palette.genrang_a.four.main,
          themeColorPalette.palette.genrang_a.seven.main
        ],
        size: donutChartDiameter,
        center: donutChartPosition || [null, null],
        dataLabels: {
          enabled: false,
          style: {
            fontWeight: 'bold',
            color: themeColorPalette.palette.white.main
          }
        }
      }
    },
    series: [
      {
        type: 'pie',
        innerSize: donutInnerSize || '45%',
        data: donutData
      }
    ]
  }

  const useStyles = makeStyles(() => ({
    root: {
      height: '100%',
      marginLeft: donutMarginLeft,
      marginTop: donutMarginTop || 0
    }
  }))

  const classes = useStyles()

  return (
    <Box position="relative" className={clsx(classes.root, className)}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  )
}

Donut.propTypes = {
  /** DonutChart id */
  id: PropTypes.string,
  /** DonutChart classname */
  className: PropTypes.string,
  /** DonutChart data label position: can be "bottom" or "right" */
  donutDataLabelPosition: PropTypes.string,
  /** DonutChart data label */
  donutDataLabel: PropTypes.string,
  /** DonutChart data label x coord */
  donutDataLabelXCoord: PropTypes.number,
  /** DonutChart data label y coord */
  donutDataLabelYCoord: PropTypes.number,
  /** DonutChart Title */
  donutTitle: PropTypes.string,
  /** DonutChart Title Margin */
  donutTitleMargin: PropTypes.number,
  /** DonutChart Data */
  donutData: PropTypes.array,
  /** DonutChart Margin Left */
  donutMarginLeft: PropTypes.string,
  /** DonutChart Margin Top */
  donutMarginTop: PropTypes.string,
  /** DonutChart Width */
  donutChartWidth: PropTypes.any,
  /** DonutChart Height */
  donutChartHeight: PropTypes.any,
  /** DonutChart Position */
  donutChartPosition: PropTypes.array,
  /** DonutChart Background */
  donutBackground: PropTypes.bool,
  /** DonutChart Diameter */
  donutChartDiameter: PropTypes.string,
  /** DonutChart Color Array */
  donutColors: PropTypes.array,
  /** DonutChart Pie Border Width */
  donutBorderWidth: PropTypes.number,
  /** DonutChart Inner Diameter */
  donutInnerSize: PropTypes.string
}

export default React.memo(Donut)
