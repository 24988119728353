import '../App.css'
import { colors, createMuiTheme } from '@material-ui/core'
import WhitneyNarr from '../Fonts/WhitneyNarrSSm-Semibold.otf'
import WhitneyNarrBook from '../Fonts/WhitneyNarrSSm-Book.otf'
import WhitneyNarrMedium from '../Fonts/WhitneyNarrSSm-Medium.otf'

const whitneyNarr = {
  fontFamily: 'WhitneyNarrSemiBold',
  fontStyle: 'semibold',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('WhitneyNarr'),
    local('WhitneyNarr-SemiBold'),
    url(${WhitneyNarr}) format('opentype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
}

const whitneyNarrBook = {
  fontFamily: 'WhitneyNarrBook',
  fontStyle: 'book',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('WhitneyNarr'),
    local('WhitneyNarr-Book'),
    url(${WhitneyNarrBook}) format('opentype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
}

const whitneyNarrMedium = {
  fontFamily: 'WhitneyNarrMedium',
  fontStyle: 'medium',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('WhitneyNarr'),
    local('WhitneyNarr-Medium'),
    url(${WhitneyNarrMedium}) format('opentype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
}

const theme = createMuiTheme({
  typography: {
    h1: {
      fontFamily: ['WhitneyNarrSemiBold', 'Roboto', 'Arial', 'sans-serif'],
      fontWeight: 400,
      fontSize: 18,
      letterSpacing: '-0.24px'
    },
    h2: {
      fontFamily: ['WhitneyNarrSemiBold', 'Roboto', 'Arial', 'sans-serif'],
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: '-0.24px'
    },
    h3: {
      fontFamily: ['WhitneyNarrSemiBold', 'Roboto', 'Arial', 'sans-serif'],
      fontWeight: 400,
      fontSize: 13,
      letterSpacing: '-0.06px'
    },
    h4: {
      fontFamily: ['WhitneyNarrBook', 'Roboto', 'Arial', 'sans-serif'],
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: '-0.06px'
    },
    p1: {
      fontFamily: ['WhitneyNarrMedium', 'Roboto', 'Arial', 'sans-serif'],
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: '-0.24px'
    },
    p2: {
      fontFamily: ['WhitneyNarrSemiBold', 'Roboto', 'Arial', 'sans-serif'],
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: '-0.24px'
    },
    p3: {
      fontFamily: ['WhitneyNarrBook', 'Roboto', 'Arial', 'sans-serif'],
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: '-0.06px'
    },
    p4: {
      fontFamily: ['WhitneyNarrBook', 'Roboto', 'Arial', 'sans-serif'],
      fontWeight: 400,
      fontSize: 11,
      letterSpacing: '-0.06px'
    },
    p5: {
      fontFamily: ['WhitneyNarrMedium', 'Roboto', 'Arial', 'sans-serif'],
      fontWeight: 400,
      fontSize: 10,
      letterSpacing: '-0.05px'
    },
    bold: {
      fontWeight: 'bold'
    },
    overline: {
      fontWeight: 400
    },
    fontFamily: [
      'WhitneyNarrSemiBold',
      'WhitneyNarrMedium',
      'WhitneyNarrBook',
      'Roboto',
      'Arial',
      'sans-serif'
    ].join(',')
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [whitneyNarr, whitneyNarrBook, whitneyNarrMedium]
      }
    }
  },
  palette: {
    sys_trad: {
      main: {
        main: '#26415e'
      },
      predicted_score: {
        main: '#f5f7f7'
      },
      border: {
        main: '#dce1e6'
      },
      bg: {
        main: '#f5f7f7'
      },
      bg2: {
        main: '#E5F3F2'
      },
      white: {
        main: '#FFFFFF'
      },
      selected: {
        main: '#355b85'
      },
      hover: {
        main: '#5379a3'
      },
      selected2: {
        main: '#d7edfa'
      },
      hover2: {
        main: '#EBF4FA'
      },
      focus: {
        main: '#b2ceed'
      },
      disabled: {
        main: '#c1c6cc'
      },
      link: {
        main: '#a38355'
      },
      linkpress: {
        main: '#806845'
      },
      linkhover: {
        main: '#D4B07D'
      },
      error: {
        main: '#b31717'
      },
      success: {
        main: '#0f997d'
      },
      successlight: {
        main: '#58dbc0'
      },
      text: {
        main: '#333333'
      },
      text2: {
        main: '#999999'
      },
      texthelp: {
        main: '#b8b8b8'
      },
      status: {
        main: '#1F8CFF'
      },
      highlight: {
        main: '#E5FF7E'
      },
      warning: {
        main: '#F2994A'
      }
    },
    adv_wk: {
      advanced: {
        main: '#229595'
      },
      robust: {
        main: '#5A9772'
      },
      limited: {
        main: '#AF9D3F'
      },
      weak: {
        main: '#DFA124'
      }
    },
    carbfoot: {
      moderate: {
        main: '#87C097'
      },
      significant: {
        main: '#8B9F80'
      },
      high: {
        main: '#A37863'
      },
      intense: {
        main: '#D02C2C'
      }
    },
    noflag: {
      norsk: {
        main: '#4FA3CD'
      },
      lowrsk: {
        main: '#8DA3B7'
      },
      medrsk: {
        main: '#A9898E'
      },
      hirsk: {
        main: '#C06960'
      },
      redflgrsk: {
        main: '#DA4930'
      }
    },
    genrang_a: {
      one: {
        main: '#f1f6f6'
      },
      two: {
        main: '#deebea'
      },
      three: {
        main: '#c8dddd'
      },
      four: {
        main: '#b6d1d1'
      },
      five: {
        main: '#98bfbe'
      },
      six: {
        main: '#82b1b0'
      },
      seven: {
        main: '#609b9a'
      },
      eight: {
        main: '#458b8a'
      },
      nine: {
        main: '#1b7170'
      },
      ten: {
        main: '#104f4f'
      }
    },
    genrang_b: {
      one: {
        main: '#6FA0AD'
      },
      two: {
        main: '#81B6C4'
      },
      three: {
        main: '#A1C8D3'
      },
      four: {
        main: '#C0DBE2'
      },
      five: {
        main: '#D7DEE0'
      },
      six: {
        main: '#F2CCB0'
      },
      seven: {
        main: '#E6B493'
      },
      eight: {
        main: '#E19E7C'
      },
      nine: {
        main: '#E08163'
      },
      ten: {
        main: '#E06E4F'
      }
    },
    genrang_c: {
      one: {
        main: '#DBE5A3'
      },
      two: {
        main: '#eac550'
      },
      three: {
        main: '#e8951c'
      },
      four: {
        main: '#dd581d'
      },
      five: {
        main: '#d63229'
      }
    },
    major_no: {
      brown_no_green_major: {
        main: '#60A96C'
      },
      brown_minor_green_minor: {
        main: '#A1A158'
      },
      brown_major_green_no: {
        main: '#A0794B'
      },
      green_major: {
        main: '#39A885'
      },
      green_signif: {
        main: '#6FB24B'
      },
      green_minor: {
        main: '#BFB43A'
      },
      green_no: {
        main: '#B28559'
      }
    },
    proxy: {
      proxy: {
        main: '#FDF7DA'
      }
    },
    sdgcol: {
      goal1: {
        main: '#E91C2C'
      },
      goal2: {
        main: '#D09E29'
      },
      goal3: {
        main: '#2D9947'
      },
      goal4: {
        main: '#C11F34'
      },
      goal5: {
        main: '#EE412A'
      },
      goal6: {
        main: '#01ACD7'
      },
      goal7: {
        main: '#FCB613'
      },
      goal8: {
        main: '#8E1737'
      },
      goal9: {
        main: '#F26D22'
      },
      goal10: {
        main: '#DF1A83'
      },
      goal11: {
        main: '#F89C24'
      },
      goal12: {
        main: '#CD8B29'
      },
      goal13: {
        main: '#48763D'
      },
      goal14: {
        main: '#007CBA'
      },
      goal15: {
        main: '#40AE4A'
      },
      goal16: {
        main: '#005489'
      },
      goal17: {
        main: '#19486A'
      }
    },
    reservedcolors: {
      brand_mco: {
        main: '#0028a0'
      },
      brand_mis: {
        main: '#003469'
      },
      brand_ma: {
        main: '#00a4d8'
      }
    },
    background: {
      dark: '#ffffff',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: colors.indigo[500]
    },
    secondary: {
      main: colors.indigo[500]
    },
    white: {
      main: '#FFFFFF'
    },
    gray: {
      main: '#F5F7F7'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    },
    map: {
      nullColor: '#f7f7f7'
    }
  }
})

export default theme
