const methodologiesFileCollection = [
  {
    fileName: 'Analyst',
    entitlement: true,
    file: `${process.env.PUBLIC_URL}/assets/methodologies/Methodology_1.0_ESG_Assessment.pdf`
  },
  {
    fileName: 'Predicted',
    entitlement: true,
    file: `${process.env.PUBLIC_URL}/assets/methodologies/ESG_Score_Predictor_Methodology.pdf`
  }
]

export default methodologiesFileCollection
