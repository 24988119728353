import React from 'react'
import { Grid } from '@material-ui/core'
import clsx from 'clsx'
import theme from 'theme'
import themeColorPalette from 'theme/theme'
import ButtonMaterial from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import { useStyles } from './styles'

export const Container = ({ children, small = false }) => {
  const classes = useStyles(theme)
  return (
    <Grid
      container
      className={clsx(classes.container, small ? classes.smallContainer : null)}
    >
      {children}
    </Grid>
  )
}

export const Button = withStyles(() => ({
  root: {
    height: (props) => (props.height ? props.height : '48px'),
    color: theme.palette?.sys_trad?.main?.main,
    ...theme.typography.p2,
    boxSizing: 'border-box',
    borderBottom: '1px solid transparent',
    borderRadius: '0px',
    opacity: 0.75,
    '&.active': {
      opacity: 1,
      borderBottom: `1px solid ${theme.palette?.sys_trad?.main?.main}`
    },
    '&.fontStyleBig_color': {
      ...theme.typography.h2,
      color: themeColorPalette.palette.sys_trad.selected.main,
      lineHeight: '20px'
    },
    textTransform: 'unset'
  }
}))(ButtonMaterial)
