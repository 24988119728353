import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styles'

const Section = ({
  children,
  sidebar,
  box = false,
  marginBottom,
  sidebarHeight,
  header,
  noMargin,
  id,
  onClick
}) => {
  const fullWidth = sidebar ? false : true
  return (
    <S.Container
      id={id}
      box={box}
      marginBottom={marginBottom}
      noMargin={noMargin}
      onClick={onClick}
    >
      {header && <S.Header>{header}</S.Header>}

      <S.Main>
        <S.Content fullWidth={fullWidth}>
          <S.Item>{children}</S.Item>
        </S.Content>

        {sidebar && (
          <S.SideBar>
            <S.Item height={sidebarHeight}>{sidebar}</S.Item>
          </S.SideBar>
        )}
      </S.Main>
    </S.Container>
  )
}

Section.propTypes = {
  children: PropTypes.node,
  sidebar: PropTypes.node,
  box: PropTypes.bool,
  marginBottom: PropTypes.number,
  sidebarHeight: PropTypes.any,
  header: PropTypes.node,
  noMargin: PropTypes.bool,
  onClick: PropTypes.func
}

export default Section
