import './App.css'
import React, { useEffect } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import GlobalStyles from './GlobalStyles'
import GlobalStateProvider from './context/GlobalStateProvider'
import theme from './theme/theme'
import { ThemeProvider } from '@material-ui/core'
import AppWithRouterAccess from './views/login/AppWithRouterAccess'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogConfig } from 'config/datadog'
import { datadogRum } from '@datadog/browser-rum'
import { v4 as uuidv4 } from 'uuid'

let trackingId

const App = () => {
  const emptyCache = () => {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // console.log("cache names ",names);
        const isCacheAvailable = names.length
        names.forEach((name) => {
          caches.delete(name)
        })
        if (isCacheAvailable) {
          window.location.reload(true)
        }
      })
      // Makes sure the page reloads. Changes are only visible after you refresh.
    }
  }

  useEffect(() => {
    emptyCache()
  }, [])

  useEffect(() => {
    trackingId = uuidv4()
    datadogLogs.init(datadogConfig)
    datadogRum.init(datadogConfig)
    datadogLogs.addLoggerGlobalContext('tracking-id', trackingId)
    datadogLogs.addLoggerGlobalContext('env', process.env.REACT_APP_ENV)
    datadogLogs.addLoggerGlobalContext('host', window.location.host)
    datadogLogs.logger.info(`${trackingId} Logging initialized.`)
    datadogRum.startSessionReplayRecording()
  })
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <CssBaseline />
      <GlobalStateProvider>
        <AppWithRouterAccess />
      </GlobalStateProvider>
    </ThemeProvider>
  )
}
export default App
