import { COMMON, PARAMS } from 'constant/APIconstants'
import axios from 'axios'

export const getSourceDocuments = async (entityHeaderState) => {
  const data = await axios.post(
    COMMON.ENTITY_URL +
      entityHeaderState?.globalEntityHeader.orbis_id +
      COMMON.ENTITY_EXPORT_SOURCE_DOCUMENTS
  )
  return data
}

export const getPDFExport = async (orbisId, getFullFileName) => {
  const response = await axios.post(
    COMMON.ENTITY_URL + orbisId + COMMON.ENTITY_PDF_EXPORT,
    {
      [PARAMS.FILE_NAME]: getFullFileName,
      [PARAMS.ORBIS_ID]: orbisId
    }
  )
  return response
}
