import React from 'react'
import { ResearchLine, ResearchLineCategory } from 'components'
import PropTypes from 'prop-types'
import theme from 'theme'
import Error from 'utils/error'
import { PAGE_CON } from 'constant/constants'
import * as S from './summaryHeaderComponents/index.jsx'

const PhysicalRisk = ({ data, minify, reloadComponent }) => {
  const physicalriskhazard = data.find(
    ({ researchLine }) => researchLine === 'physicalriskhazard'
  )
  const physicalriskhazardValue = physicalriskhazard?.value
  // should not pass in benchmark actually instead of checking below const
  const existsPortfolioFacilitiesExposed =
    physicalriskhazard?.value?.facilities_exposed
  const exitsPhysicalriskhazardValue =
    physicalriskhazardValue && Object.keys(physicalriskhazardValue).length !== 0
  const facilitiesExposedColor =
    physicalriskhazardValue?.facilities_exposed > 1
      ? theme.palette.sys_trad.error.main
      : ''
  const facilitiesExposedPerc =
    physicalriskhazardValue?.facilities_exposed === '0.00'
      ? 0
      : physicalriskhazardValue?.facilities_exposed < 1
      ? Number(physicalriskhazardValue?.facilities_exposed).toFixed(2)
      : Math.round(physicalriskhazardValue?.facilities_exposed)

  return (
    <S.ResearchLineContainer minify={minify}>
      <ResearchLineCategory minify={minify} title="Physical Risk">
        {(typeof physicalriskhazard == 'undefined' ||
          physicalriskhazard?.status !== 'fulfilled') &&
        !minify ? (
          <Error
            id={'physicalriskhazardError'}
            dataStatus={PAGE_CON.ERROR}
            onErrorClick={reloadComponent}
            errorOccuredOn={PAGE_CON.DASHBOARD_RESEARCH_LINES}
            width={170}
          />
        ) : (
          <>
            <ResearchLine
              title="Highest Risk Hazard"
              label={physicalriskhazardValue?.hrh_risk_category ? ' ' : '-'}
              value={
                exitsPhysicalriskhazardValue &&
                physicalriskhazardValue?.highest_risk_hazard
              }
              research_line="highest_risk_hazard"
              minify={minify}
            />
            <ResearchLine
              title={`Facilities Exposed to ${
                physicalriskhazardValue?.highest_risk_hazard || '-'
              }`}
              label={
                exitsPhysicalriskhazardValue && existsPortfolioFacilitiesExposed
                  ? `${facilitiesExposedPerc}%`
                  : '-'
              }
              value={exitsPhysicalriskhazardValue && 'High Risk/Red Flag'}
              research_line="facilities_exposed"
              color={facilitiesExposedColor}
              minify={minify}
            />
          </>
        )}
      </ResearchLineCategory>
    </S.ResearchLineContainer>
  )
}

PhysicalRisk.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  minify: PropTypes.bool
}

export default PhysicalRisk
