import { COMMON } from 'constant/APIconstants'
import axios from 'axios'

const mockResponse = {
  entities: 93,
  investments: [
    {
      climate: 'Yes',
      company_name: 'Farr Better Enterprises Inc',
      investment_pct: 4.03,
      orbis_id: '214928592',
      order: 1,
      rank: 1,
      score_quality: 'Predicted'
    },
    {
      climate: 'Yes',
      company_name: 'Perry Enterprises',
      investment_pct: 4.03,
      orbis_id: '214879803',
      order: 2,
      rank: 1,
      score_quality: 'Predicted'
    },
    {
      climate: 'Yes',
      company_name: 'Bob Wilcox Photography',
      investment_pct: 1.44,
      orbis_id: '205767817',
      order: 3,
      rank: 2,
      score_quality: 'Predicted'
    },
    {
      climate: 'Yes',
      company_name: 'Hytech Enterprises Inc',
      investment_pct: 1.43,
      orbis_id: '205766788',
      order: 4,
      rank: 3,
      score_quality: 'Predicted'
    },
    {
      climate: 'Yes',
      company_name: 'Corina Promotions Inc',
      investment_pct: 1.41,
      orbis_id: '205765078',
      order: 5,
      rank: 4,
      score_quality: 'Predicted'
    },
    {
      climate: 'Yes',
      company_name: 'Marieann Green Interior Design',
      investment_pct: 1.4,
      orbis_id: '205775922',
      order: 6,
      rank: 5,
      score_quality: 'Predicted'
    },
    {
      company_name: 'Saccos Transport Vincent T Sacco',
      investment_pct: 1.39,
      orbis_id: '211474174',
      order: 7,
      rank: 6,
      score_quality: 'Predicted'
    },
    {
      company_name: 'Cajn Enterprises',
      investment_pct: 1.37,
      orbis_id: '211462580',
      order: 8,
      rank: 7
    },
    {
      company_name: 'Spherion Corp',
      investment_pct: 1.36,
      orbis_id: '202467727',
      order: 9,
      rank: 8
    },
    {
      company_name: 'Peoplease Corp',
      investment_pct: 1.35,
      orbis_id: '202531587',
      order: 10,
      rank: 9
    },
    {
      company_name: 'Jmc Investments Lllp',
      investment_pct: 1.34,
      orbis_id: '202522563',
      order: 11,
      rank: 10
    },
    {
      company_name: 'Rvictory 2 Llc',
      investment_pct: 1.32,
      orbis_id: '530805557',
      order: 12,
      rank: 11
    },
    {
      climate: 'Yes',
      company_name: '1stdibs.com, Inc.',
      investment_pct: 20,
      orbis_id: '074628690',
      order: 1,
      rank: 1
    },
    {
      climate: 'Yes',
      company_name: 'Province of Qu\u00e9bec',
      investment_pct: 20,
      orbis_id: '073436382',
      order: 2,
      rank: 1
    },
    {
      climate: 'No',
      company_name: '7-Eleven, Inc.',
      investment_pct: 14,
      orbis_id: '219926045',
      order: 3,
      parent_company_name: 'Seven & i Holdings Co., Ltd.',
      parent_orbis_id: '051059660',
      rank: 2
    },
    {
      company_name: 'Wolfe Group Llc',
      investment_pct: 1.27,
      orbis_id: '214962498',
      order: 16,
      rank: 15
    },
    {
      company_name: 'K & S Coffee Enterprises Llc',
      investment_pct: 1.25,
      orbis_id: '214951283',
      order: 17,
      rank: 16
    },
    {
      company_name: 'Labbe & Associates Ltd',
      investment_pct: 1.25,
      orbis_id: '037667905',
      order: 18,
      rank: 16
    },
    {
      company_name: 'Kt Enterprises Inc',
      investment_pct: 1.24,
      orbis_id: '214281078',
      order: 19,
      rank: 17
    },
    {
      company_name: 'Alan John Design Ltd',
      investment_pct: 1.23,
      orbis_id: '022976081',
      order: 20,
      rank: 18
    },
    {
      company_name: 'Rick Watters Enterprises Llc',
      investment_pct: 1.23,
      orbis_id: '070853624',
      order: 21,
      rank: 18
    },
    {
      company_name: 'Haberl Enterprises Inc',
      investment_pct: 1.22,
      orbis_id: '214331639',
      order: 22,
      rank: 19
    },
    {
      company_name: 'Lrk Enterprises Inc',
      investment_pct: 1.2,
      orbis_id: '214330161',
      order: 23,
      rank: 20
    },
    {
      company_name: 'Dd Walker Enterprises Inc',
      investment_pct: 1.19,
      orbis_id: '214330477',
      order: 24,
      rank: 21
    },
    {
      company_name: 'Ted Brazelton Cpa',
      investment_pct: 1.18,
      orbis_id: '214330532',
      order: 25,
      rank: 22
    },
    {
      company_name: 'Jnd Enterprises',
      investment_pct: 1.17,
      orbis_id: '214329269',
      order: 26,
      rank: 23
    },
    {
      company_name: 'Jds Enterprises Llc',
      investment_pct: 1.16,
      orbis_id: '214328464',
      order: 27,
      rank: 24
    },
    {
      company_name: 'Taylormaurer Plc',
      investment_pct: 1.15,
      orbis_id: '051311829',
      order: 28,
      rank: 25
    },
    {
      company_name: 'Ladell Andrea Interiors Llc',
      investment_pct: 1.14,
      orbis_id: '138512632',
      order: 29,
      rank: 26
    },
    {
      company_name: 'Cyclops Enterprises',
      investment_pct: 1.13,
      orbis_id: '205472968',
      order: 30,
      rank: 27
    },
    {
      company_name: 'Thomas K Frazier Cpa Pc',
      investment_pct: 1.13,
      orbis_id: '214330704',
      order: 31,
      rank: 27
    },
    {
      company_name: 'Allbest Enterprises Llc',
      investment_pct: 1.12,
      orbis_id: '215691163',
      order: 32,
      rank: 28
    },
    {
      company_name: 'Lewis Interiors',
      investment_pct: 1.11,
      orbis_id: '215651774',
      order: 33,
      rank: 29
    },
    {
      company_name: 'Cfo Tech',
      investment_pct: 1.1,
      orbis_id: '215638208',
      order: 34,
      rank: 30
    },
    {
      company_name: 'Milco Enterprises Inc',
      investment_pct: 1.09,
      orbis_id: '213112489',
      order: 35,
      rank: 31
    },
    {
      company_name: 'Phelps Enterprises Inc',
      investment_pct: 1.08,
      orbis_id: '213114972',
      order: 36,
      rank: 32
    },
    {
      company_name: 'Keystone Interiors Llc',
      investment_pct: 1.07,
      orbis_id: '213105045',
      order: 37,
      rank: 33
    },
    {
      company_name: 'Key Campbell Enterprises Llc',
      investment_pct: 1.06,
      orbis_id: '059399062',
      order: 38,
      rank: 34
    },
    {
      company_name: 'Careerstaff-Indianapolis',
      investment_pct: 1.05,
      orbis_id: '213125033',
      order: 39,
      rank: 35
    },
    {
      company_name: 'Mcgraw Enterprises',
      investment_pct: 1.04,
      orbis_id: '213125088',
      order: 40,
      rank: 36
    },
    {
      company_name: 'Cavecreek Photographic Workshops',
      investment_pct: 1.03,
      orbis_id: '213123189',
      order: 41,
      rank: 37
    },
    {
      company_name: 'Podraza Cpa Pllc',
      investment_pct: 1.02,
      orbis_id: '213119027',
      order: 42,
      rank: 38
    },
    {
      company_name: 'A & D Accounting',
      investment_pct: 1.01,
      orbis_id: '213117505',
      order: 43,
      rank: 39
    },
    {
      company_name: 'Marmor Enterprises Llc',
      investment_pct: 1,
      orbis_id: '213123173',
      order: 44,
      rank: 40
    },
    {
      company_name: 'Dartco Inc',
      investment_pct: 0.99,
      orbis_id: '213100845',
      order: 45,
      rank: 41
    },
    {
      company_name: 'Encore Promotions Inc',
      investment_pct: 0.98,
      orbis_id: '213041281',
      order: 46,
      rank: 42
    },
    {
      company_name: 'Heartland Of Martinsburg',
      investment_pct: 0.97,
      orbis_id: '213043144',
      order: 47,
      rank: 43
    },
    {
      company_name: 'Bryo Media Llc',
      investment_pct: 0.96,
      orbis_id: '390161676',
      order: 48,
      rank: 44
    },
    {
      company_name: 'Phrma',
      investment_pct: 0.95,
      orbis_id: '216750047',
      order: 49,
      rank: 45
    },
    {
      company_name: 'Staff Works Employment Solutions',
      investment_pct: 0.94,
      orbis_id: '216744448',
      order: 50,
      rank: 46
    },
    {
      company_name: 'Atlantis Enterprises Inc',
      investment_pct: 0.93,
      orbis_id: '216757180',
      order: 51,
      rank: 47
    },
    {
      company_name: 'Laura Segall Photography Llc',
      investment_pct: 0.92,
      orbis_id: '314076715',
      order: 52,
      rank: 48
    },
    {
      company_name: 'Desert Vista Counseling Services',
      investment_pct: 0.91,
      orbis_id: '076503928',
      order: 53,
      rank: 49
    },
    {
      company_name: 'Kentuckiana Rehabilitation Services',
      investment_pct: 0.9,
      orbis_id: '262914152',
      order: 54,
      rank: 50
    },
    {
      company_name: 'Behive Answering Service',
      investment_pct: 0.89,
      orbis_id: '205638515',
      order: 55,
      rank: 51
    },
    {
      company_name: 'Montana Sawmill & Manufacturing',
      investment_pct: 0.88,
      orbis_id: '205630802',
      order: 56,
      rank: 52
    },
    {
      company_name: 'Jgp Enterprises',
      investment_pct: 0.87,
      orbis_id: '205630949',
      order: 57,
      rank: 53
    },
    {
      company_name: 'Betancourt Enterprises Llc',
      investment_pct: 0.86,
      orbis_id: '218113136',
      order: 58,
      rank: 54
    },
    {
      company_name: 'Kolton Consulting Llc',
      investment_pct: 0.85,
      orbis_id: '218104157',
      order: 59,
      rank: 55
    },
    {
      company_name: 'Countryside Assisted Living',
      investment_pct: 0.84,
      orbis_id: '218153063',
      order: 60,
      rank: 56
    },
    {
      company_name: 'Pwj Enterprises Llc',
      investment_pct: 0.82,
      orbis_id: '115197789',
      order: 61,
      rank: 57
    },
    {
      company_name: 'V & W Staffing Llc',
      investment_pct: 0.81,
      orbis_id: '218125109',
      order: 62,
      rank: 58
    },
    {
      company_name: 'Shamoon Enterprises Inc',
      investment_pct: 0.8,
      orbis_id: '218091363',
      order: 63,
      rank: 59
    },
    {
      company_name: 'Concordia Napa Auto Parts Inc',
      investment_pct: 0.79,
      orbis_id: '218089883',
      order: 64,
      rank: 60
    },
    {
      company_name: 'Lasercraft Incdba America',
      investment_pct: 0.78,
      orbis_id: '218079735',
      order: 65,
      rank: 61
    },
    {
      company_name: 'Lead Dog Enterprises',
      investment_pct: 0.77,
      orbis_id: '211668948',
      order: 66,
      rank: 62
    },
    {
      company_name: 'Tower Personnel Staffing',
      investment_pct: 0.75,
      orbis_id: '019529650',
      order: 67,
      rank: 63
    },
    {
      company_name: 'O Mark Enterprises',
      investment_pct: 0.74,
      orbis_id: '205538586',
      order: 68,
      rank: 64
    },
    {
      company_name: 'Corona Promotions Inc',
      investment_pct: 0.73,
      orbis_id: '024828562',
      order: 69,
      rank: 65
    },
    {
      company_name: 'Home Watchers Of Tucson Llc',
      investment_pct: 0.71,
      orbis_id: '365079235',
      order: 70,
      rank: 66
    },
    {
      company_name: 'Fesler',
      investment_pct: 0.7,
      orbis_id: '215740710',
      order: 71,
      rank: 67
    },
    {
      company_name: 'Badal Enterprises Inc',
      investment_pct: 0.69,
      orbis_id: '215744369',
      order: 72,
      rank: 68
    },
    {
      company_name: 'Dillon Enterprises Inc',
      investment_pct: 0.68,
      orbis_id: '205656507',
      order: 73,
      rank: 69
    },
    {
      company_name: 'Heritage Nursing Home',
      investment_pct: 0.67,
      orbis_id: '209507141',
      order: 74,
      rank: 70
    },
    {
      company_name: 'West Virginia Assisted Living Associates',
      investment_pct: 0.66,
      orbis_id: '209505902',
      order: 75,
      rank: 71
    },
    {
      company_name: 'Inventiv Clinical',
      investment_pct: 0.65,
      orbis_id: '217492403',
      order: 76,
      rank: 72
    },
    {
      company_name: 'Michael Stahl',
      investment_pct: 0.64,
      orbis_id: '217465738',
      order: 77,
      rank: 73
    },
    {
      company_name: 'Ready Worker Temp Agency Inc',
      investment_pct: 0.63,
      orbis_id: '366684467',
      order: 78,
      rank: 74
    },
    {
      company_name: 'Frank A Pappa',
      investment_pct: 0.62,
      orbis_id: '218904332',
      order: 79,
      rank: 75
    },
    {
      company_name: 'Agona Chiropractic Clinic',
      investment_pct: 0.61,
      orbis_id: '022279415',
      order: 80,
      rank: 76
    },
    {
      company_name: 'Domaki Leathers',
      investment_pct: 0.6,
      orbis_id: '212970680',
      order: 81,
      rank: 77
    },
    {
      company_name: 'Quail Run Pottery',
      investment_pct: 0.59,
      orbis_id: '081703588',
      order: 82,
      rank: 78
    },
    {
      company_name: 'Barrister Personnel',
      investment_pct: 0.58,
      orbis_id: '212901565',
      order: 83,
      rank: 79
    },
    {
      company_name: 'Island Properties',
      investment_pct: 0.57,
      orbis_id: '145249632',
      order: 84,
      rank: 80
    },
    {
      company_name: 'Pegasus Translations',
      investment_pct: 0.56,
      orbis_id: '021121002',
      order: 85,
      rank: 81
    },
    {
      company_name: 'Century Financial Group I Inc',
      investment_pct: 0.55,
      orbis_id: '205470181',
      order: 86,
      rank: 82
    },
    {
      company_name: 'Munir Enterprises Inc',
      investment_pct: 0.55,
      orbis_id: '205474145',
      order: 87,
      rank: 82
    },
    {
      company_name: 'Beals & Associates',
      investment_pct: 0.4,
      orbis_id: '214981664',
      order: 88,
      rank: 83
    },
    {
      company_name: 'Lmv Enterprises Llc',
      investment_pct: 0.4,
      orbis_id: '023608974',
      order: 89,
      rank: 83
    },
    {
      company_name: 'Owens & Bondell Pllc',
      investment_pct: 0.4,
      orbis_id: '124512899',
      order: 90,
      rank: 83
    },
    {
      company_name: 'Dsb Llc',
      investment_pct: 0.04,
      orbis_id: '060030684',
      order: 91,
      rank: 84
    },
    {
      company_name: 'Lewis C Tebbe Cpa Llc',
      investment_pct: 0.04,
      orbis_id: '214967875',
      order: 92,
      rank: 84
    },
    {
      company_name: 'Scottsdale Casa De Paradise Ll',
      investment_pct: 0.04,
      orbis_id: '214931455',
      order: 93,
      rank: 84
    }
  ],
  portfolio_id: '917e7b65-6a9e-4756-9ea1-f9d282297d0c',
  portfolio_percent: 92.96,
  total_matched_companies: 93,
  total_unique_companies: 100,
  total_unmatched_companies: 7,
  unmatched_portfolio_percent: 7.04
}

export const getPortfolioEntityDetail = async ({
  portfolioID,
  cancelToken,
  mockData = false
}) => {
  const { data } = await axios.post(
    COMMON.BASE_URL + portfolioID + COMMON.PORTFOLIO_ENTITY_DETAIL,
    {},
    { cancelToken: cancelToken }
  )

  return mockData ? mockResponse : data
}
