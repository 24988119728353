import { PAGE_CON, REDUCER_TYPE } from '../../constant/constants'

const driverData = {
  isLoading: true,
  dataStatus: '',
  data: []
}

export function sectorInfoInitialState() {
  let obj = {}
  obj.driverData = Object.assign({}, { ...driverData, data: [] })
  return obj
}

const SectorInfoReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.LOAD_DATA_START:
      currentState[action.sectionName].isLoading = true
      currentState[action.sectionName].dataStatus = PAGE_CON.LOADING
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_ERROR:
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.ERROR
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_COMPLETED:
      currentState[action.sectionName].data = action.data
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.SUCCESS
      return { ...currentState }
    case REDUCER_TYPE.RESET:
      return sectorInfoInitialState()
    default:
      return currentState
  }
}

export default SectorInfoReducer
