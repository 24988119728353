import { makeStyles } from '@material-ui/core/styles'

export const useStyles = (props) =>
  makeStyles((theme) => ({
    container: {
      '& ul': {
        overflowY: 'auto',
        padding: '0px 5px'
      }
    },
    listItem: {
      ...theme.typography.p5,
      display: 'flex',
      minHeight: props?.height || '40px',
      width: props?.width || '100%',
      border: props?.listItemBorder,
      backgroundColor: props?.listItemColor,
      background: '#FFFFFF',
      borderRadius: '2px',
      marginBottom: '4px',
      padding: '4px',
      justifyContent: 'space-between',
      cursor: props?.clickable ? 'pointer' : 'default',
      '& div:first-child': {
        '& h3': {
          margin: 0,
          lineHeight: '16px'
        },
        padding: '4px',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& h4': {
          margin: 0,
          lineHeight: '16px',
          ...theme.typography.p4
        }
      },
      '& div:last-child': {
        ...theme.typography.p2,
        width: '62px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: '#ffffff',
        borderRadius: '2px'
      }
    }
  }))
