import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconScenario = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 4.00003C3.11929 4.00003 2 5.11932 2 6.50003V14.5C2 14.7762 1.77614 15 1.5 15C1.22386 15 1 14.7762 1 14.5V6.50003C1 4.56703 2.567 3.00003 4.5 3.00003H13.5C13.7761 3.00003 14 3.22389 14 3.50003C14 3.77617 13.7761 4.00003 13.5 4.00003H4.5Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1 1.70003C12.2657 1.47912 12.5791 1.43434 12.8 1.60003L14.8 3.10003C14.9259 3.19446 15 3.34265 15 3.50003C15 3.65741 14.9259 3.8056 14.8 3.90003L12.8 5.40003C12.5791 5.56571 12.2657 5.52094 12.1 5.30003C11.9343 5.07912 11.9791 4.76571 12.2 4.60003L13.6667 3.50003L12.2 2.40003C11.9791 2.23434 11.9343 1.92094 12.1 1.70003Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.1 6.70003C6.26569 6.47912 6.57909 6.43434 6.8 6.60003L8.8 8.10003C8.9259 8.19446 9 8.34265 9 8.50003C9 8.65741 8.9259 8.8056 8.8 8.90003L6.8 10.4C6.57909 10.5657 6.26569 10.5209 6.1 10.3C5.93431 10.0791 5.97909 9.76571 6.2 9.60003L7.66667 8.50003L6.2 7.40003C5.97909 7.23434 5.93431 6.92094 6.1 6.70003Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 8.50003C3 8.22389 3.22386 8.00003 3.5 8.00003H8.5C8.77614 8.00003 9 8.22389 9 8.50003C9 8.77617 8.77614 9.00003 8.5 9.00003H3.5C3.22386 9.00003 3 8.77617 3 8.50003Z"
      fill="#26415E"
    />
    <path
      d="M13.5 13.5C13.5 13.2239 13.2761 13 13 13C12.7239 13 12.5 13.2239 12.5 13.5C12.5 13.7762 12.7239 14 13 14C13.2761 14 13.5 13.7762 13.5 13.5Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 9.50003C11 8.39546 11.8954 7.50003 13 7.50003C14.1046 7.50003 15 8.39546 15 9.50003C15 10.4319 14.3626 11.215 13.5 11.437V12C13.5 12.2762 13.2761 12.5 13 12.5C12.7239 12.5 12.5 12.2762 12.5 12V11C12.5 10.7239 12.7239 10.5 13 10.5C13.5523 10.5 14 10.0523 14 9.50003C14 8.94774 13.5523 8.50003 13 8.50003C12.4477 8.50003 12 8.94774 12 9.50003C12 9.77617 11.7761 10 11.5 10C11.2239 10 11 9.77617 11 9.50003Z"
      fill="#26415E"
    />{' '}
  </SvgIcon>
)
IconScenario.displayName = 'IconScenario'
IconScenario.muiName = 'SvgIcon'

export default IconScenario
