import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import axios from 'axios'
import { AnalysisServices } from 'services'

export const sectorTreeMapHasError = (esgAssessmentsState) => {
  return (
    !esgAssessmentsState?.esgSectorTreeMap.isLoading &&
    (esgAssessmentsState?.esgSectorTreeMap.dataStatus === PAGE_CON.ERROR ||
      (esgAssessmentsState?.esgSectorTreeMap.dataStatus === PAGE_CON.SUCCESS &&
        !isSectorTreeMapHasData(esgAssessmentsState)))
  )
}

export const getLeadersLaggardsData = async (
  esgAssessmentsState,
  esgAssessmentsDispatch,
  sectors,
  regions,
  apiCallId,
  filterState,
  isErrorReload = false
) => {
  if (
    (esgAssessmentsState?.portfolioLeaderLaggard?.data.leaders &&
      esgAssessmentsState?.portfolioLeaderLaggard?.data.laggards &&
      esgAssessmentsState?.portfolioLeaderLaggard?.data.leaders.length > 0 &&
      esgAssessmentsState?.portfolioLeaderLaggard?.data.laggards.length > 0) ||
    (esgAssessmentsState?.portfolioLeaderLaggard?.dataStatus !== '' &&
      isErrorReload === false)
  )
    return

  esgAssessmentsDispatch &&
    esgAssessmentsDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS
    })
  let leadersLaggardsData = { leaders: [], laggards: [] }
  const isFilterStateAvailable =
    filterState.filterData.regionsList.length > 0 ||
    filterState.filterData.sectorsList.length > 0
  try {
    if (isFilterStateAvailable) {
      const { data } = await AnalysisServices.getLeadersLaggardsData({
        portfolio: filterState.globalPortfolioFilter.portfolio,
        asOfDate: filterState.globalPortfolioFilter.asOfDate,
        regions,
        sectors,
        token: esgAssessmentsState.cancelToken.token
      })
      leadersLaggardsData.leaders = data.leaders
      leadersLaggardsData.laggards = data.laggards
    }
    esgAssessmentsDispatch &&
      esgAssessmentsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS,
        data: leadersLaggardsData,
        apiCallId
      })
  } catch (err) {
    if (!axios.isCancel(err)) {
      esgAssessmentsDispatch &&
        esgAssessmentsDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS,
          apiCallId
        })
    }
  }
}

export const getSectorTreeMapData = async (
  esgAssessmentsState,
  esgAssessmentsDispatch,
  sectors,
  regions,
  apiCallId,
  filterState,
  isErrorReload = false
) => {
  if (
    (esgAssessmentsState?.esgSectorTreeMap?.data.summary.length > 0 &&
      esgAssessmentsState?.esgSectorTreeMap?.data.details.length > 0 &&
      esgAssessmentsState?.esgSectorTreeMap?.data.coverage.length > 0) ||
    (esgAssessmentsState?.esgSectorTreeMap?.dataStatus !== '' &&
      isErrorReload === false)
  )
    return

  esgAssessmentsDispatch &&
    esgAssessmentsDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.ESG_SECTOR_TREE_MAP
    })
  let esgSectorData = { summary: [], details: [], coverage: [] }
  const isFilterStateAvailable =
    filterState?.filterData.regionsList.length > 0 ||
    filterState?.filterData.sectorsList.length > 0
  try {
    if (isFilterStateAvailable) {
      const [summary, details, coverage] =
        await AnalysisServices.getCoverageSectorDetailsSectorSummary({
          portfolio: filterState.globalPortfolioFilter.portfolio,
          asOfDate: filterState.globalPortfolioFilter.asOfDate,
          benchmark: filterState.globalPortfolioFilter.benchmark,
          regions,
          sectors,
          token: esgAssessmentsState.cancelToken.token
        })

      esgSectorData.summary = summary.data
      esgSectorData.details = details.data
      esgSectorData.coverage = coverage.data[0]
    }
    esgAssessmentsDispatch &&
      esgAssessmentsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.ESG_SECTOR_TREE_MAP,
        data: esgSectorData,
        apiCallId
      })
  } catch (err) {
    if (!axios.isCancel(err)) {
      esgAssessmentsDispatch &&
        esgAssessmentsDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.ESG_SECTOR_TREE_MAP,
          apiCallId
        })
    } else console.log(err)
  }
}

export const reloadComponent = (
  errorOccuredOn,
  filterState,
  esgAssessmentsState,
  esgAssessmentsDispatch,
  apiCallId
) => {
  let filters = filterState?.globalPortfolioFilter
  let sectors = filters?.sectors === '' ? PAGE_CON.ALL_FILTER : filters?.sectors
  let regions = filters?.regions === '' ? PAGE_CON.ALL_FILTER : filters?.regions

  switch (errorOccuredOn) {
    case PAGE_CON.LEADERS_TITLE:
    case PAGE_CON.LAGGARDS_TITLE:
      getLeadersLaggardsData(
        esgAssessmentsState,
        esgAssessmentsDispatch,
        sectors,
        regions,
        apiCallId,
        filterState,
        true
      )
      break
    case PAGE_CON.SECTORS_TITLE:
      getSectorTreeMapData(
        esgAssessmentsState,
        esgAssessmentsDispatch,
        sectors,
        regions,
        apiCallId,
        filterState,
        true
      )
      break
    default:
      break
  }
}

export const isSectorTreeMapHasData = (esgAssessmentsState) => {
  let hasData = false
  if (
    esgAssessmentsState?.esgSectorTreeMap?.data?.summary?.length > 0 &&
    esgAssessmentsState?.esgSectorTreeMap?.data?.details?.length > 0 &&
    esgAssessmentsState?.esgSectorTreeMap?.data?.coverage?.portfolio_coverage
  )
    hasData = true
  return hasData
}
