import React from 'react'
import { Grid } from '@material-ui/core'
import LoadingMask from 'utils/loadingMask'
import * as S from './styles'

const MaterialityDriverHeaderSector = ({
  data,
  isLoading,
  isControversyEnabled
}) => {
  const classes = S.useStyles()
  const item = data

  return (
    <Grid container direction="column">
      <Grid item className={classes.titleLeft}>
        {item.title}
      </Grid>
      <Grid
        className={isControversyEnabled ? classes.headerDescriptionStyle : ''}
        item
      >
        {isLoading ? <LoadingMask height={10} /> : item.subTitle}
      </Grid>
    </Grid>
  )
}

export default MaterialityDriverHeaderSector
