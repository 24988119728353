import { Textbox } from 'components'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { validateDomain } from '../../utils/validations'
import { useStyles } from './styles'
import { PAGE_CON } from 'constant/constants'

const RequestDetailRow = ({
  name,
  orbis_id,
  score_quality,
  predicted_score,
  info,
  invested,
  status,
  cancel_reason,
  email,
  readOnly,
  removeRow,
  setEmail,
  isDuplicateEmail
}) => {
  const classes = useStyles()
  const status_response = cancel_reason
    ? `${PAGE_CON.CANCELLED} - ${cancel_reason}`
    : status.label

  return (
    <TableRow>
      {!readOnly && (
        <TableCell width={80}>
          {status.value === 'not_sent' ? (
            <div
              data-testid="remove-entity"
              onClick={() => removeRow(orbis_id)}
              className={classes.ReqDetailRemove}
              aria-hidden={true}
            >
              Remove
            </div>
          ) : null}
        </TableCell>
      )}
      <TableCell>
        <div>
          <div>{name}</div>
          <div className={classes.p3Typography}>
            {score_quality ? score_quality : 'Predicted'}: {predicted_score} -{' '}
            {info.filter((info) => !!info).join(', ')}
          </div>
        </div>
      </TableCell>
      <TableCell width={150}>
        <div>
          <div className={classes.ReqDetailLabel}>Invested</div>
          <div className={classes.p3Typography} style={{ marginTop: 2 }}>
            {invested.join(', ')}
          </div>
        </div>
      </TableCell>
      <TableCell width={cancel_reason ? 230 : 150}>
        <div>
          <div className={classes.ReqDetailLabel}>Status</div>
          <div className={classes.p3Typography} style={{ marginTop: 2 }}>
            {status_response}
          </div>
        </div>
      </TableCell>
      {!readOnly && (
        <TableCell width={160}>
          <div className={classes.ReqDetailEmail}>
            {status.value === 'not_sent' ? (
              <>
                <div className={classes.ReqDetailLabel}>Send to:</div>
                <div
                  data-testid="input-email"
                  className={`${classes.ReqDetailEmailInput} ${
                    validateDomain(email) ? '' : classes.ReqDetailEmailError
                  }`}
                >
                  <Textbox
                    id={orbis_id}
                    textboxDefaultPlaceholder={'Enter email address'}
                    textboxClass="noBoxShadowInput"
                    minWidthFalse={true}
                    textboxWidth={'100%'}
                    textboxError={false}
                    showSearchIcon={false}
                    textboxMargin={'0'}
                    multiline={false}
                    textboxHeight={'15px'}
                    textBoxPadding={'0 0 3px'}
                    defaultText={email}
                    autoCompleteOff={true}
                    //eslint-disable-next-line
                    autoFocus={false}
                    onChange={(email) => setEmail(orbis_id, email)}
                    inputTextError={isDuplicateEmail}
                  />
                  {isDuplicateEmail && (
                    <span className={classes.errorMessage}>
                      {'Duplicate email'}
                    </span>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </TableCell>
      )}
    </TableRow>
  )
}
export default RequestDetailRow
