const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const isValidDate = (d) => d instanceof Date && !isNaN(d)

export default function getMonthYear(filterState) {
  let monthYear = ''
  filterState.filterData.asOfDateList.map((item) => {
    if (item.key === filterState.globalPortfolioFilter.asOfDate) {
      monthYear = item.displayText
    }
    return ''
  })
  return monthYear
}

export const getMonthFromFilter = (asOfDate) => asOfDate.substr(4, 2)
export const getYearFromFilter = (asOfDate) => asOfDate.substr(0, 4)

export const getMonthDayYear = (d, formatStyle = 'month day, year') => {
  if (d) {
    const cleanDate = d.split(' ').join('T').replace(/\//g, '-')
    const dateWithOffset = new Date(cleanDate)
    if (isValidDate(dateWithOffset)) {
      const date = new Date(
        dateWithOffset.valueOf() + dateWithOffset.getTimezoneOffset() * 60000
      )
      const monthIndex = date.getMonth()
      const monthName = months[monthIndex]
      return formatStyle
        .replace(/month/gi, monthName)
        .replace(/day/gi, date.getDate())
        .replace(/year/gi, date.getFullYear())
    }
  }
  return null
}

export const reportTimeStamp = (withTime = false) => {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const yyyy = today.getFullYear()
  const time = today.valueOf()
  return `${mm}_${dd}_${yyyy}${withTime ? `_${time}` : ''}`
}
