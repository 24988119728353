import axios from 'axios'
import PortfolioAnalysisColumns from '../../views/portfolioanalysis/PortfolioAnalysisColumns.json'
import TransitionRiskColumns from '../../views/portfolioanalysis/transitionrisk/TransitionRiskColumns.json'
import { v4 as uuidv4 } from 'uuid'
import { PAGE_CON, REDUCER_TYPE } from '../../constant/constants'

const portfolioScore = {
  title: PAGE_CON.PORTFOLIO_SCORE_TITLE,
  columns: TransitionRiskColumns.physicalRiskManagement_Portfolio_Score_Columns,
  isLoading: true,
  dataStatus: '',
  data: []
}

const portfolioESGScore = {
  isLoading: true,
  dataStatus: '',
  data: { score: [], distribution: [], coverage: {} }
}

const portfolioDistribution = {
  title: PAGE_CON.PORTFOLIO_DISTRIBUTION_TITLE,
  columns: TransitionRiskColumns.transitionRisk_Portfolio_Distribution_Columns,
  isLoading: true,
  dataStatus: '',
  data: []
}

const portfolioCoverage = {
  title: PAGE_CON.PORTFOLIO_COVERAGE_TITLE,
  isLoading: true,
  dataStatus: '',
  data: {}
}

const portfolioLeaderLaggard = {
  title: `${PAGE_CON.LEADERS_TITLE} and ${PAGE_CON.LAGGARDS_TITLE}`,
  columns: PortfolioAnalysisColumns.leaders_Laggards_Panel_Columns,
  isLoading: true,
  dataStatus: '',
  data: {
    leaders: [],
    laggards: []
  }
}

const portfolioRegions = {
  title: PAGE_CON.REGIONS_TITLE,
  columns: PortfolioAnalysisColumns.region_Sector_Minimal_Table_Columns,
  panelcolumns: PortfolioAnalysisColumns.region_Sector_Panel_Columns,
  isLoading: true,
  dataStatus: '',
  data: {
    summary: [],
    details: [],
    mapData: [],
    sectorsummary: [],
    sectordetails: []
  }
}

const portfolioSectors = {
  title: PAGE_CON.SECTORS_TITLE,
  columns: PortfolioAnalysisColumns.region_Sector_Minimal_Table_Columns,
  panelcolumns: PortfolioAnalysisColumns.region_Sector_Panel_Columns,
  isLoading: true,
  dataStatus: '',
  data: { summary: [], details: [] }
}

const portfolioRiskCategories = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const portfolioUpdates = {
  title: PAGE_CON.UPDATES_TITLE,
  columns: PortfolioAnalysisColumns.updates_Columns,
  isLoading: true,
  dataStatus: '',
  data: []
}

const portfolioImpact = {
  title: PAGE_CON.IMPACT_TITLE,
  filter: PAGE_CON.IMPACT_KEY_TOP_5,
  isLoading: true,
  dataStatus: '',
  data: {}
}

const portfolioUnderlyingDataMetrics = {
  title: PAGE_CON.ENR_TRN_MGM_TITLE,
  isUnderlyingDataMetrics: true,
  subtitle: [
    `${PAGE_CON.INVESTMENT_CATEGORY}`,
    `${PAGE_CON.COMPANIES_CATEGORY}`
  ],
  isLoading: true,
  dataStatus: '',
  data: []
}

const portfolioHistory = {
  title: PAGE_CON.PORTFOLIO_HISTORY_TITLE,
  isLoading: true,
  dataStatus: '',
  data: {}
}

export function energyTransitionInitialState() {
  let obj = {}
  obj.apiCallId = uuidv4()
  obj.cancelToken = axios.CancelToken.source()
  // Re-ordering elements for JSON formation to be in the sequence:
  // Score, Distribution, Coverage, Updates, Leader & Laggards, Regions, Sectors.
  obj.portfolioScore = Object.assign({}, { ...portfolioScore })
  obj.portfolioDistribution = Object.assign({}, { ...portfolioDistribution })
  obj.portfolioCoverage = Object.assign({}, { ...portfolioCoverage, data: {} })
  obj.portfolioESGScore = Object.assign(
    {},
    {
      ...portfolioESGScore,
      data: { score: [], distribution: [], coverage: {} }
    }
  )
  obj.portfolioUpdates = Object.assign({}, { ...portfolioUpdates })
  obj.portfolioImpact = Object.assign({}, { ...portfolioImpact, data: {} })
  obj.portfolioLeaderLaggard = Object.assign(
    {},
    { ...portfolioLeaderLaggard, data: { leaders: [], laggards: [] } }
  )
  obj.portfolioRegions = Object.assign(
    {},
    {
      ...portfolioRegions,
      data: {
        summary: [],
        details: [],
        mapData: [],
        sectorsummary: [],
        sectordetails: []
      }
    }
  )
  obj.portfolioSectors = Object.assign(
    {},
    { ...portfolioSectors, data: { summary: [], details: [] } }
  )
  // why are there two for underlying data metrics here??
  obj.portfolioRiskCategories = Object.assign(
    {},
    { ...portfolioRiskCategories, data: {} }
  )
  obj.portfolioUnderlyingDataMetrics = Object.assign(
    {},
    { ...portfolioUnderlyingDataMetrics, data: [] }
  )
  obj.portfolioHistory = Object.assign({}, { ...portfolioHistory, data: {} })
  return obj
}

const EnergyTransitionReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.LOAD_DATA_START:
      currentState[action.sectionName].isLoading = true
      currentState[action.sectionName].dataStatus = PAGE_CON.LOADING
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_ERROR:
      if (action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.ERROR
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_COMPLETED:
      if (action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState[action.sectionName].data = action.data
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.SUCCESS
      return { ...currentState }
    case REDUCER_TYPE.UPDATE_IMPACT_FILTER:
      currentState.portfolioImpact.filter = action.data
      return { ...currentState }
    case REDUCER_TYPE.BENCHMARK_CHANGED:
    case REDUCER_TYPE.RESET:
      currentState.cancelToken.cancel('cancelling operation')
      return energyTransitionInitialState()
    default:
      return currentState
  }
}

export default EnergyTransitionReducer
