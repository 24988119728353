import { COMMON, PARAMS } from 'constant/APIconstants'
import axios from 'axios'

export const getUpdates = (
  portfolioID,
  researchLineURL,
  regions,
  sectors,
  month,
  year,
  cancelToken
) => {
  const { data } = axios.post(
    COMMON.BASE_URL + portfolioID + researchLineURL + COMMON.UPDATES,
    {
      [PARAMS.REGION]: regions,
      [PARAMS.SECTOR]: sectors,
      [PARAMS.MONTH]: month,
      [PARAMS.YEAR]: year
    },
    { cancelToken: cancelToken }
  )

  return data
}
