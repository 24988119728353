import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import numberData from 'utils/numberFormat'
import { Panel, PrimaryLink, RegularTable } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import textTheme from 'theme/theme'
import { PAGE_CON } from 'constant/constants'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  tableLink: {
    paddingTop: 8
  },
  tableTitle: {
    paddingBottom: '8px',
    ...textTheme.typography.h1
  },
  cardHeader: {
    ...textTheme.typography.p2,
    lineHeight: '16px',
    marginBottom: '8px'
  },
  sumCount: {
    width: '335px',
    textAlign: 'right'
  }
}))

const ImpactTable = (props) => {
  const classes = useStyles(props)

  const [open, setOpen] = React.useState(false)

  const openDrawer = () => {
    setOpen(true)
  }
  const handleCloseDrawer = () => {
    setOpen(false)
  }

  const sumOfInvestment = (data) => {
    if (data) {
      let sum = 0
      data.map((value) => {
        sum = sum + Number(value['investment_pct'])
        return sum.toFixed(2)
      })
      return sum.toFixed(2)
    }
    return
  }

  const sortedData = props.totalData
  const cutOffSortedData = props.totalData.slice(0, props.tableLength)

  let showLink = props.totalData.length > props.tableLength
  let recordLength =
    props.totalData && props.totalData.length - props.tableLength

  let totalData = [...sortedData]

  if (props.totalData && props.totalData.length > 0) {
    totalData.push({
      company_name: '',
      score: '',
      score_category: '',
      investment_pct: sumOfInvestment(sortedData),
      score_quality: ''
    })
  }

  let cutOffData = [...cutOffSortedData]

  if (props.totalData && props.totalData.length > 0) {
    cutOffData.push({
      company_name: '',
      score: '',
      score_category: '',
      investment_pct: sumOfInvestment(cutOffSortedData),
      score_quality: ''
    })
  }

  const linkText = () => {
    if (
      props.filter === PAGE_CON.IMPACT_KEY_TOP_10_PCT ||
      props.filter === PAGE_CON.IMPACT_KEY_BOTTOM_10_PCT
    ) {
      if (props.tableType === 'Positive') {
        return ' more companies for Positive Impact'
      } else {
        return ' more companies for Negative Impact'
      }
    } else {
      return ' more companies with same investment %'
    }
  }

  return (
    <div>
      <Grid item xs={12} id={props.id}>
        <div
          className={'impactTableWrapper'}
          // eslint-disable-next-line react/no-unknown-property
          heap_id="impacts"
          // eslint-disable-next-line react/no-unknown-property
          heap_impacts_id={props.tableType}
        >
          <div className={classes.cardHeader}>{props.title}</div>
          <RegularTable
            heap_id="impacts"
            tableEditable={false}
            columns={props.colInfo}
            totalData={cutOffData}
            horScroll="hidden"
            isApplyShadow={false}
            sumRow={props.sumRow}
            isRowHighlighted={true}
          ></RegularTable>
        </div>

        {recordLength && recordLength > 0 && showLink ? (
          <div className={classes.tableLink}>
            <PrimaryLink
              linkText={
                numberData(recordLength, { isDecimal: false }) + linkText()
              }
              linkClass="linkInline"
              onClick={openDrawer}
            ></PrimaryLink>
          </div>
        ) : null}
        <Panel
          key={'impact-panel'}
          title={props.title}
          openPanel={open}
          titlePadding="12px"
          onPanelClose={handleCloseDrawer}
          panelContentComponent={[
            <div key="impactdrilldown-id">
              <RegularTable
                heap_id="impacts"
                id="impact-table"
                columns={
                  props.impactDrawerColumns
                    ? props.impactDrawerColumns
                    : props.colInfo
                }
                totalData={totalData}
                horScroll="hidden"
                tablePadding="24px"
                tableEditable={false}
                inPanel={true}
                sumRow={props.sumRow}
                isRowHighlighted={true}
              ></RegularTable>
            </div>
          ]}
        ></Panel>
      </Grid>
    </div>
  )
}

ImpactTable.propTypes = {
  /** Leaders id */
  id: PropTypes.string,
  /** Number of records to display */
  tableLength: PropTypes.number,
  /** Dropdown filter determines sort order: top = desc, bottom = asc */
  filter: PropTypes.string,
  /** Table Type (positive or negative) */
  tableType: PropTypes.string
}

export default ImpactTable
