import { Grid } from '@material-ui/core'
import { PAGE_CON } from 'constant/constants'
import { IconInfo, PrimaryButton } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import { useStyles } from './styles'

/**
 * @visibleName Error Component
 */
const ErrorComponent = (props) => {
  const classes = useStyles(props)
  let errorText = 'There is no data on which to base our calculation.'
  let hasCustErrMsg = false

  if (props.dataStatus === PAGE_CON.SUCCESS) {
    if (props.noDataText1 !== '' || props.noDataText2 !== '')
      hasCustErrMsg = true
  }
  if (props.dataStatus === PAGE_CON.ERROR) {
    errorText = 'The content could not be loaded because of an issue.'
  }

  return (
    <Grid
      container
      id={props.id}
      direction="column"
      spacing={2}
      className={classes[props.errorClass]}
    >
      {hasCustErrMsg ? (
        <Grid
          container
          alignItems={'center'}
          direction="column"
          className={classes.updateErrorClass}
        >
          <Grid item className={classes.textStyle}>
            <div className={classes[props.noDataText1ClassName]}>
              {props.noDataText1}
            </div>
            <div className={classes[props.noDataText2ClassName]}>
              {props.noDataText2}
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          alignItems={'center'}
          direction="column"
          id={props.id}
          spacing={2}
          className={classes[props.errorClass]}
        >
          <Grid item>
            <IconInfo className={classes.errorIconClass} fontSize="large" />
          </Grid>
          <Grid item className={classes.textStyle}>
            {errorText}
          </Grid>
          {props.dataStatus === PAGE_CON.ERROR ? (
            <Grid item>
              <PrimaryButton
                id="error-button-test-id"
                buttonText="Reload"
                onClick={() => props.onErrorClick(props.errorOccuredOn)}
                buttonClass="buttonPrimary"
              />
            </Grid>
          ) : (
            <Grid item></Grid>
          )}
        </Grid>
      )}
    </Grid>
  )
}

ErrorComponent.propTypes = {
  /** ErrorComponent id */
  id: PropTypes.string,
  /** onErrorClick will have parent component function reference */
  onErrorClick: PropTypes.func,
  /** onUpload will have parent component function reference */
  onUpload: PropTypes.func,
  /** errorOccuredOn will have name of the component where error occured*/
  errorOccuredOn: PropTypes.string,
  /** width for error component */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** dataStatus to toggle CTA */
  dataStatus: PropTypes.string,
  /** Class for style */
  errorClass: PropTypes.string,
  /** height for error component */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** justifyContent for error component */
  justifyContent: PropTypes.string,

  noDataText1: PropTypes.string,
  noDataText2: PropTypes.string,
  noDataText1ClassName: PropTypes.string,
  noDataText2ClassName: PropTypes.string
}

ErrorComponent.defaultProps = {
  errorClass: 'gridStyle',
  noDataText1ClassName: 'noDataText1Style',
  noDataText2ClassName: 'noDataText2Style'
}

export default ErrorComponent
