import React from 'react'
import PropTypes from 'prop-types'
import { researchLineCategory as useStyles } from './styles'
import clsx from 'clsx'

const ResearchLineCategory = ({
  title,
  children,
  minify = false,
  background = false
}) => {
  const classes = useStyles({ minify })
  return (
    <div className={clsx(classes.container, background && classes.background)}>
      {!minify && <div className={classes.title}>{title}</div>}
      <div className={classes.content}>{children}</div>
    </div>
  )
}

ResearchLineCategory.propTypes = {
  title: PropTypes.string,
  minify: PropTypes.bool
}

export default ResearchLineCategory
