import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import { hexToRGB } from 'utils/color'

export const useStyles = makeStyles(() => ({
  button: {
    ...textTheme.typography.p1,
    borderRadius: '2px',
    boxShadow: 'none',
    height: (props) => (props.buttonHeight ? props.buttonHeight : '32px'),
    boxSizing: 'border-box',
    textAlign: 'center',
    lineHeight: '16px',
    textTransform: 'none',
    outline: 'none',
    marginLeft: (props) => props.buttonMarginLeft,
    marginRight: (props) => props.buttonMarginRight,
    marginTop: (props) => props.buttonMarginTop,
    marginBottom: (props) => props.buttonMarginBotom,
    width: (props) => props.buttonWidth,
    maxWidth: (props) => props.buttonMaxWidth && props.buttonMaxWidth,
    paddingTop: (props) => props.buttonPaddingTop,
    verticalAlign: '',
    '&:focus': {
      outline: '2px solid ' + themeColorPalette.palette.sys_trad.focus.main,
      backgroundColor: themeColorPalette.palette.white.main,
      color: themeColorPalette.palette.sys_trad.main.main,
      boxShadow: 'none'
    },
    '&.MuiButton-root': {
      minWidth: (props) => props.buttonMinWidth,
      padding: (props) => props.buttonPadding
    }
  },
  buttonGhostClicked: {
    backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
    color: themeColorPalette.palette.white.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
      color: themeColorPalette.palette.sys_trad.hover.main,
      outline: 'none',
      border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      color: themeColorPalette.palette.white.main,
      boxShadow: 'none'
    }
  },
  buttonGhost: {
    border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    color: themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
      boxShadow: 'none',
      border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
      color: themeColorPalette.palette.sys_trad.hover.main,
      outline: 'none'
    },
    '&:focus': {
      border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
      backgroundColor: 'rgba(255, 255, 255, 0)',
      color: themeColorPalette.palette.sys_trad.main.main,
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
        boxShadow: 'none',
        border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
        color: themeColorPalette.palette.sys_trad.hover.main,
        outline: 'none'
      }
    },
    '&.Mui-selected': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      color: themeColorPalette.palette.white.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
        color: themeColorPalette.palette.sys_trad.hover.main,
        outline: 'none',
        border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
        color: themeColorPalette.palette.white.main,
        border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
        boxShadow: 'none'
      }
    },
    '&.Mui-disabled': {
      backgroundColor: themeColorPalette.palette.white.main,
      color: themeColorPalette.palette.sys_trad.disabled.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.disabled.main,
      boxShadow: 'none',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.white.main,
        color: themeColorPalette.palette.sys_trad.disabled.main,
        border: '1px solid ' + themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.white.main,
        color: themeColorPalette.palette.sys_trad.disabled.main,
        outline: 'none',
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      }
    }
  },
  buttonDeleteClicked: {
    backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
    color: themeColorPalette.palette.white.main,
    border: '1px solid ' + themeColorPalette.palette.sys_trad.error.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
      color: themeColorPalette.palette.sys_trad.error.main,
      outline: 'none',
      border: '1px solid ' + themeColorPalette.palette.sys_trad.error.main,
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      color: themeColorPalette.palette.error.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.error.main,
      boxShadow: 'none'
    }
  },
  buttonDelete: {
    border: '1px solid ' + themeColorPalette.palette.sys_trad.error.main,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    color: themeColorPalette.palette.sys_trad.error.main,
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
      boxShadow: 'none',
      border: '1px solid ' + themeColorPalette.palette.sys_trad.error.main,
      color: themeColorPalette.palette.sys_trad.error.main,
      outline: 'none'
    },
    '&:focus': {
      border: '1px solid ' + themeColorPalette.palette.sys_trad.error.main,
      backgroundColor: 'rgba(255, 255, 255, 0)',
      color: themeColorPalette.palette.sys_trad.error.main,
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
        boxShadow: 'none',
        border: '1px solid ' + themeColorPalette.palette.sys_trad.error.main,
        color: themeColorPalette.palette.sys_trad.error.main,
        outline: 'none'
      }
    },
    '&.Mui-selected': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      color: themeColorPalette.palette.white.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
        color: themeColorPalette.palette.sys_trad.hover.main,
        outline: 'none',
        border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
        color: themeColorPalette.palette.white.main,
        border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
        boxShadow: 'none'
      }
    },
    '&.Mui-disabled': {
      backgroundColor: themeColorPalette.palette.white.main,
      color: themeColorPalette.palette.sys_trad.disabled.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.error.main,
      boxShadow: 'none',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.white.main,
        color: themeColorPalette.palette.sys_trad.disabled.main,
        border: '1px solid ' + themeColorPalette.palette.sys_trad.error.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.white.main,
        color: themeColorPalette.palette.sys_trad.disabled.main,
        outline: 'none',
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      }
    }
  },
  buttonAltGhost: {
    border: '1px solid ' + themeColorPalette.palette.white.main,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: themeColorPalette.palette.white.main,
    '&:hover': {
      boxShadow: 'none',
      border: '1px solid ' + themeColorPalette.palette.white.main,
      color: themeColorPalette.palette.white.main,
      outline: 'none',
      backgroundColor: 'rgba(255, 255, 255, 0.2)'
    },
    '&:focus': {
      border: '1px solid ' + themeColorPalette.palette.sys_trad.focus,
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      color: themeColorPalette.palette.white.main,
      '&:hover': {
        boxShadow: 'none',
        border: '1px solid ' + themeColorPalette.palette.white.main,
        color: themeColorPalette.palette.white.main,
        outline: 'none',
        backgroundColor: 'rgba(255, 255, 255, 0.2)'
      }
    },
    '&:active': {
      border: '1px solid ' + themeColorPalette.palette.sys_trad.selected.main,
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      color: themeColorPalette.palette.white.main
    },
    '&.Mui-selected': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      color: themeColorPalette.palette.white.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
        border: '1px solid ' + themeColorPalette.palette.white.main,
        color: themeColorPalette.palette.white.main,
        outline: 'none',
        backgroundColor: 'rgba(255, 255, 255, 0.3)'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
        color: themeColorPalette.palette.white.main,
        border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
        boxShadow: 'none'
      }
    },
    '&.Mui-disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      color: themeColorPalette.palette.sys_trad.disabled.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.disabled.main,
      boxShadow: 'none',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: themeColorPalette.palette.sys_trad.disabled.main,
        border: '1px solid ' + themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: themeColorPalette.palette.sys_trad.disabled.main,
        outline: 'none',
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      }
    }
  },
  buttonAltGhostClicked: {
    backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
    color: themeColorPalette.palette.white.main,
    border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      border: '1px solid ' + themeColorPalette.palette.white.main,
      color: themeColorPalette.palette.white.main,
      outline: 'none',
      backgroundColor: 'rgba(255, 255, 255, 0.3)'
    },
    '&:focus': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      color: themeColorPalette.palette.white.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
      boxShadow: 'none'
    },
    '&:active': {
      border: '1px solid ' + themeColorPalette.palette.sys_trad.selected.main,
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      color: themeColorPalette.palette.white.main
    },
    '&.Mui-selected': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      color: themeColorPalette.palette.white.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
        color: themeColorPalette.palette.sys_trad.hover.main,
        outline: 'none',
        border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
        color: themeColorPalette.palette.white.main,
        border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
        boxShadow: 'none'
      }
    },
    '&.Mui-disabled': {
      backgroundColor: themeColorPalette.palette.white.main,
      color: themeColorPalette.palette.sys_trad.disabled.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.disabled.main,
      boxShadow: 'none',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.white.main,
        color: themeColorPalette.palette.sys_trad.disabled.main,
        border: '1px solid ' + themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.white.main,
        color: themeColorPalette.palette.sys_trad.disabled.main,
        outline: 'none',
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      }
    }
  },
  buttonPrimaryClicked: {
    backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
    color: themeColorPalette.palette.white.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
      color: themeColorPalette.palette.sys_trad.hover.main,
      outline: 'none',
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      color: themeColorPalette.palette.white.main,
      boxShadow: 'none'
    },
    '& .MuiButton-label': {
      display: (props) => props.buttonIconPosition === 'top' && 'flex',
      flexDirection: (props) => props.buttonIconPosition === 'top' && 'column',
      '& .MuiButton-startIcon': {
        margin: (props) => props.buttonIconPosition === 'top' && 0,
        marginLeft: (props) => props.buttonIcon && props.buttonText && 0,
        marginRight: (props) => props.buttonIcon && props.buttonText && 0
      }
    }
  },
  buttonPrimary: {
    backgroundColor: themeColorPalette.palette.sys_trad.main.main,
    color: themeColorPalette.palette.white.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover.main,
      outline: 'none',
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: themeColorPalette.palette.sys_trad.main.main,
      color: themeColorPalette.palette.white.main,
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover.main,
        outline: 'none',
        boxShadow: 'none'
      }
    },
    '&.Mui-selected': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover.main,
        outline: 'none',
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
        boxShadow: 'none'
      }
    },
    '&.Mui-disabled': {
      backgroundColor: themeColorPalette.palette.sys_trad.disabled.main,
      color: themeColorPalette.palette.white.main,
      boxShadow: 'none',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto',
        outline: 'none'
      }
    },
    '& .MuiButton-label': {
      display: (props) => props.buttonIconPosition === 'top' && 'flex',
      flexDirection: (props) => props.buttonIconPosition === 'top' && 'column',
      '& .MuiButton-startIcon': {
        margin: (props) => props.buttonIconPosition === 'top' && 0,
        marginLeft: (props) => props.buttonIcon && props.buttonText && 0,
        marginRight: (props) => props.buttonIcon && props.buttonText && 0
      }
    }
  },
  buttonSlimClicked: {
    height: (props) => (props.buttonHeight ? props.buttonHeight : '24px'),
    padding: (props) => props.buttonPadding && props.buttonPadding,
    backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
    border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
    color: themeColorPalette.palette.sys_trad.main.main,
    boxShadow: 'none',
    '&:hover': {
      outline: 'none',
      border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
      color: themeColorPalette.palette.sys_trad.hover.main,
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
      color: themeColorPalette.palette.sys_trad.main.main,
      boxShadow: 'none'
    },
    '& .MuiButton-startIcon': {
      margin: (props) => props.buttonIconPosition === 'top' && 0,
      marginLeft: (props) => props.buttonIcon && props.buttonText && 0,
      marginRight: (props) => props.buttonIcon && props.buttonText && 0
    }
  },
  buttonSlimControversiesClicked: {
    height: (props) => (props.buttonHeight ? props.buttonHeight : '24px'),
    padding: (props) => props.buttonPadding && props.buttonPadding,
    backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
    border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
    color: themeColorPalette.palette.sys_trad.main.main,
    boxShadow: 'none',
    '&:hover': {
      outline: 'none',
      border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
      color: themeColorPalette.palette.sys_trad.hover.main,
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
      color: themeColorPalette.palette.sys_trad.main.main,
      boxShadow: 'none'
    }
  },
  buttonAltPrimary: {
    ...textTheme.typography.p2,
    backgroundColor: themeColorPalette.palette.sys_trad.status.main,
    color: themeColorPalette.palette.white.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#5DABFF', // Color doesn't exist in styleguide // themeColorPalette.palette.sys_trad.hover.main,
      outline: 'none',
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: themeColorPalette.palette.sys_trad.status.main,
      color: themeColorPalette.palette.white.main,
      '&:hover': {
        backgroundColor: '#5DABFF', // Color doesn't exist in styleguide // themeColorPalette.palette.sys_trad.hover.main,
        outline: 'none',
        boxShadow: 'none'
      }
    },
    '&.Mui-selected': {
      backgroundColor: '#046BD9', // Color doesn't exist in styleguide // themeColorPalette.palette.sys_trad.selected.main,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#5DABFF', // Color doesn't exist in styleguide // themeColorPalette.palette.sys_trad.hover.main,
        outline: 'none',
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: '#046BD9', // Color doesn't exist in styleguide // themeColorPalette.palette.sys_trad.selected.main,
        boxShadow: 'none'
      }
    },
    '&.Mui-disabled': {
      backgroundColor: themeColorPalette.palette.sys_trad.disabled.main,
      color: themeColorPalette.palette.white.main,
      boxShadow: 'none',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto',
        outline: 'none'
      }
    },
    '& .MuiButton-label': {
      display: (props) => props.buttonIconPosition === 'top' && 'flex',
      flexDirection: (props) => props.buttonIconPosition === 'top' && 'column',
      '& .MuiButton-startIcon': {
        margin: (props) => props.buttonIconPosition === 'top' && 0,
        marginLeft: (props) => props.buttonIcon && props.buttonText && 0,
        marginRight: (props) => props.buttonIcon && props.buttonText && 0
      }
    }
  },
  buttonAltPrimaryClicked: {
    ...textTheme.typography.p2,
    backgroundColor: '#046BD9', // Color doesn't exist in styleguide // themeColorPalette.palette.sys_trad.selected.main,
    color: themeColorPalette.palette.white.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#5DABFF', // Color doesn't exist in styleguide // themeColorPalette.palette.sys_trad.hover2.main,
      color: themeColorPalette.palette.white.main,
      outline: 'none',
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: '#046BD9', // Color doesn't exist in styleguide // themeColorPalette.palette.sys_trad.selected.main,
      color: themeColorPalette.palette.white.main,
      outline: 'none',
      boxShadow: 'none'
    },
    '& .MuiButton-label': {
      display: (props) => props.buttonIconPosition === 'top' && 'flex',
      flexDirection: (props) => props.buttonIconPosition === 'top' && 'column',
      '& .MuiButton-startIcon': {
        margin: (props) => props.buttonIconPosition === 'top' && 0,
        marginLeft: (props) => props.buttonIcon && props.buttonText && 0,
        marginRight: (props) => props.buttonIcon && props.buttonText && 0
      }
    }
  },
  buttonContact: {
    ...textTheme.typography.p2,
    backgroundColor: themeColorPalette.palette.sys_trad.status.main,
    color: themeColorPalette.palette.white.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover.main,
      outline: 'none',
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: themeColorPalette.palette.sys_trad.main.main,
      color: themeColorPalette.palette.white.main,
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover.main,
        outline: 'none',
        boxShadow: 'none'
      }
    },
    '&.Mui-selected': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover.main,
        outline: 'none',
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
        boxShadow: 'none'
      }
    },
    '&.Mui-disabled': {
      backgroundColor: themeColorPalette.palette.sys_trad.disabled.main,
      color: themeColorPalette.palette.white.main,
      boxShadow: 'none',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto',
        outline: 'none'
      }
    }
  },
  buttonContactClicked: {
    ...textTheme.typography.p2,
    backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
    color: themeColorPalette.palette.white.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
      color: themeColorPalette.palette.sys_trad.hover.main,
      outline: 'none',
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      color: themeColorPalette.palette.white.main,
      boxShadow: 'none'
    }
  },
  buttonSlim: {
    height: (props) => (props.buttonHeight ? props.buttonHeight : '24px'),
    padding: (props) => props.buttonPadding && props.buttonPadding,
    border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
    backgroundColor: themeColorPalette.palette.white.main,
    boxShadow: 'none',
    color: themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      outline: 'none',
      border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
      color: themeColorPalette.palette.sys_trad.hover.main,
      boxShadow: 'none'
    },
    '&:focus': {
      border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
      backgroundColor: themeColorPalette.palette.white.main,
      boxShadow: 'none',
      color: themeColorPalette.palette.sys_trad.main.main,
      '&:hover': {
        outline: 'none',
        border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
        backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
        color: themeColorPalette.palette.sys_trad.hover.main,
        boxShadow: 'none'
      }
    },
    '&.Mui-selected': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
      color: themeColorPalette.palette.sys_trad.main.main,
      boxShadow: 'none',
      '&:hover': {
        outline: 'none',
        border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
        backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
        color: themeColorPalette.palette.sys_trad.hover.main,
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
        border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
        color: themeColorPalette.palette.sys_trad.main.main,
        boxShadow: 'none'
      }
    },
    '&.Mui-disabled': {
      backgroundColor: themeColorPalette.palette.white.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
      color: themeColorPalette.palette.sys_trad.disabled.main,
      boxShadow: 'none',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.white.main,
        border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
        color: themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.white.main,
        outline: 'none',
        color: themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      }
    },
    '& .MuiButton-startIcon': {
      margin: (props) => props.buttonIconPosition === 'top' && 0,
      marginLeft: (props) => props.buttonIcon && props.buttonText && 0,
      marginRight: (props) => props.buttonIcon && props.buttonText && 0
    }
  },
  buttonSlimControversies: {
    height: (props) => (props.buttonHeight ? props.buttonHeight : '24px'),
    padding: (props) => props.buttonPadding && props.buttonPadding,
    border: '1px solid transparent',
    backgroundColor: themeColorPalette.palette.gray.main,
    boxShadow: 'none',
    color: themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      outline: 'none',
      border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
      color: themeColorPalette.palette.sys_trad.hover.main,
      boxShadow: 'none'
    },
    '&:focus': {
      border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
      backgroundColor: themeColorPalette.palette.white.main,
      boxShadow: 'none',
      color: themeColorPalette.palette.sys_trad.main.main,
      '&:hover': {
        outline: 'none',
        border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
        backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
        color: themeColorPalette.palette.sys_trad.hover.main,
        boxShadow: 'none'
      }
    },
    '&.Mui-selected': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
      color: themeColorPalette.palette.sys_trad.main.main,
      boxShadow: 'none',
      '&:hover': {
        outline: 'none',
        border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
        backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
        color: themeColorPalette.palette.sys_trad.hover.main,
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
        border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
        color: themeColorPalette.palette.sys_trad.main.main,
        boxShadow: 'none'
      }
    },
    '&.Mui-disabled': {
      backgroundColor: themeColorPalette.palette.white.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
      color: themeColorPalette.palette.sys_trad.disabled.main,
      boxShadow: 'none',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.white.main,
        border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
        color: themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.white.main,
        outline: 'none',
        color: themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      }
    }
  },
  buttonLink: {
    height: '24px',
    border: 'none',
    backgroundColor: hexToRGB(
      themeColorPalette.palette.sys_trad.link.main,
      0.1
    ),
    boxShadow: 'none',
    color: themeColorPalette.palette.sys_trad.link.main,
    outline: 'none',
    padding: (props) => props.buttonPadding && props.buttonPadding,
    '&:hover': {
      outline: 'none',
      border: 'none',
      backgroundColor: hexToRGB(
        themeColorPalette.palette.sys_trad.link.main,
        0.2
      ),
      color: themeColorPalette.palette.sys_trad.link.main,
      boxShadow: 'none'
    },
    '&:focus': {
      border: 'none',
      backgroundColor: hexToRGB(
        themeColorPalette.palette.sys_trad.link.main,
        0.1
      ),
      boxShadow: 'none',
      color: themeColorPalette.palette.sys_trad.link.main,
      outline: 'none',
      '&:hover': {
        outline: 'none',
        border: 'none',
        backgroundColor: hexToRGB(
          themeColorPalette.palette.sys_trad.link.main,
          0.2
        ),
        color: themeColorPalette.palette.sys_trad.link.main,
        boxShadow: 'none'
      }
    },
    '&.Mui-selected': {
      backgroundColor: hexToRGB(
        themeColorPalette.palette.sys_trad.link.main,
        0.1
      ),
      border: 'none',
      color: themeColorPalette.palette.sys_trad.link.main,
      boxShadow: 'none',
      outline: 'none',
      '&:hover': {
        outline: 'none',
        border: 'none',
        backgroundColor: hexToRGB(
          themeColorPalette.palette.sys_trad.link.main,
          0.2
        ),
        color: themeColorPalette.palette.sys_trad.link.main,
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: hexToRGB(
          themeColorPalette.palette.sys_trad.link.main,
          0.1
        ),
        border: 'none',
        color: themeColorPalette.palette.sys_trad.link.main,
        boxShadow: 'none'
      }
    },
    '&.Mui-disabled': {
      backgroundColor: hexToRGB(
        themeColorPalette.palette.sys_trad.link.main,
        0.1
      ),
      border: 'none',
      color: themeColorPalette.palette.sys_trad.disabled.main,
      boxShadow: 'none',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: hexToRGB(
          themeColorPalette.palette.sys_trad.link.main,
          0.2
        ),
        border: 'none',
        color: themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        backgroundColor: hexToRGB(
          themeColorPalette.palette.sys_trad.link.main,
          0.1
        ),
        outline: 'none',
        color: themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      }
    }
  },
  buttonLinkClicked: {
    height: '24px',
    backgroundColor: hexToRGB(
      themeColorPalette.palette.sys_trad.link.main,
      0.1
    ),
    border: 'none',
    color: themeColorPalette.palette.sys_trad.link.main,
    boxShadow: 'none',
    outline: 'none',
    padding: (props) => props.buttonPadding && props.buttonPadding,
    '&:hover': {
      outline: 'none',
      border: 'none',
      backgroundColor: hexToRGB(
        themeColorPalette.palette.sys_trad.link.main,
        0.2
      ),
      color: themeColorPalette.palette.sys_trad.link.main,
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: hexToRGB(
        themeColorPalette.palette.sys_trad.link.main,
        0.1
      ),
      border: 'none',
      color: themeColorPalette.palette.sys_trad.link.main,
      boxShadow: 'none',
      outline: 'none'
    }
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  borderLessButtonClicked: {
    ...textTheme.typography.p1,
    height: (props) => (props.buttonHeight ? props.buttonHeight : '24px'),
    backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
    border: 'none',
    color: themeColorPalette.palette.sys_trad.text.main,
    boxShadow: 'none',
    padding: (props) => props.buttonPadding && props.buttonPadding,
    minWidth: 'auto',
    borderRadius: '2px',
    '&:hover': {
      outline: 'none',
      border: 'none',
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      color: themeColorPalette.palette.sys_trad.text.main,
      boxShadow: 'none'
    },
    '&:focus': {
      outline: 'none',
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      border: 'none',
      color: themeColorPalette.palette.sys_trad.text.main,
      boxShadow: 'none'
    }
  },
  borderLessButton: {
    ...textTheme.typography.p1,
    height: (props) => (props.buttonHeight ? props.buttonHeight : '24px'),
    border: 'none',
    backgroundColor: themeColorPalette.palette.white.main,
    boxShadow: 'none',
    color: themeColorPalette.palette.sys_trad.text.main,
    padding: (props) => props.buttonPadding && props.buttonPadding,
    minWidth: 'auto',
    borderRadius: '2px',
    '&:hover': {
      outline: 'none',
      border: 'none',
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      color: themeColorPalette.palette.sys_trad.text.main,
      boxShadow: 'none'
    },
    '&:focus': {
      border: 'none',
      backgroundColor: themeColorPalette.palette.white.main,
      boxShadow: 'none',
      color: themeColorPalette.palette.sys_trad.main.main,
      outline: 'none',
      '&:hover': {
        outline: 'none',
        border: 'none',
        backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
        color: themeColorPalette.palette.sys_trad.text.main,
        boxShadow: 'none'
      }
    },
    '&.Mui-selected': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      border: 'none',
      color: themeColorPalette.palette.sys_trad.text.main,
      boxShadow: 'none',
      '&:hover': {
        outline: 'none',
        border: 'none',
        backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
        color: themeColorPalette.palette.sys_trad.text.main,
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
        border: 'none',
        color: themeColorPalette.palette.sys_trad.text.main,
        boxShadow: 'none',
        outline: 'none'
      }
    },
    '&.Mui-disabled': {
      backgroundColor: themeColorPalette.palette.white.main,
      border: 'none',
      color: themeColorPalette.palette.sys_trad.disabled.main,
      boxShadow: 'none',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.white.main,
        border: 'none',
        color: themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.white.main,
        outline: 'none',
        color: themeColorPalette.palette.sys_trad.disabled.main,
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      }
    }
  },
  reportingTextStyle: {
    ...textTheme.typography.p1,
    fontWeight: 350
  },
  borderLessButtonNoHoverClicked: {
    ...textTheme.typography.p1,
    height: (props) => (props.buttonHeight ? props.buttonHeight : '24px'),
    border: 'none',
    outline: 'none',
    color: themeColorPalette.palette.sys_trad.text.main,
    boxShadow: 'none',
    padding: (props) => props.buttonPadding && props.buttonPadding,
    minWidth: 'auto',
    borderRadius: '2px',
    '&:focus': {
      outline: 'none',
      border: 'none',
      backgroundColor: themeColorPalette.palette.sys_trad.white.main,
      boxShadow: 'none'
    },
    '&:hover': {
      outline: 'none',
      border: 'none',
      backgroundColor: themeColorPalette.palette.sys_trad.white.main,
      boxShadow: 'none'
    }
  },
  borderLessButtonNoHover: {
    ...textTheme.typography.p1,
    height: (props) => (props.buttonHeight ? props.buttonHeight : '24px'),
    border: 'none',
    outline: 'none',
    backgroundColor: themeColorPalette.palette.white.main,
    boxShadow: 'none',
    color: themeColorPalette.palette.sys_trad.text.main,
    padding: (props) => props.buttonPadding && props.buttonPadding,
    minWidth: 'auto',
    borderRadius: '2px',
    '&:focus': {
      outline: 'none',
      border: 'none',
      backgroundColor: themeColorPalette.palette.sys_trad.white.main,
      boxShadow: 'none'
    },
    '&:hover': {
      outline: 'none',
      border: 'none',
      backgroundColor: themeColorPalette.palette.sys_trad.white.main,
      boxShadow: 'none'
    }
  },
  greyText: {
    ...textTheme.typography.p1,
    fontWeight: 350,
    color: themeColorPalette.palette.sys_trad.text2.main
  }
}))
