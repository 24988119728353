import React, { useEffect, useState } from 'react'
import Slider from '@material-ui/core/Slider'
import { Textbox } from 'components'
import theme from 'theme'
import './styles.css'
import { useStyles } from './styles'

const SliderChart = ({
  chart = [],
  onChange = () => {},
  showInput = true,
  height = 29,
  width = 256,
  min = 0,
  max = 100
}) => {
  const classes = useStyles(theme)
  const [range, setRange] = useState({ min, max })
  const [external, setExternal] = useState(false)
  const filledChart = Array.from(
    { length: 100 },
    (investment_pct = 0, score) => ({ investment_pct, score })
  ).map(({ investment_pct, score }) => ({
    score,
    investment_pct:
      chart.find((current) => `${current.score}` === `${score}`)
        ?.investment_pct || investment_pct
  }))

  useEffect(() => {
    setRange({ min, max })
  }, [min, max])

  const maxValue = (chart, min, max) =>
    chart
      .filter(({ score }) => score >= min && score <= max)
      .map(({ investment_pct }) => investment_pct)
      .reduce((a, b) => Math.max(a, b), 0)

  const calcHeight = (itemHeight) => {
    const max = maxValue(chart, 0, 100)
    const scaleFactor = itemHeight / (max > 0 ? max : 1)
    return height * scaleFactor > 0 ? height * scaleFactor + 3 : 0
  }

  const inputValidation = (inputText, type) => {
    let { min, max } = range
    switch (type) {
      case 'min':
        min = inputText === '' ? 0 : Number(inputText)
        break
      case 'max':
        max = inputText === '' || inputText > 100 ? 100 : Number(inputText)
        break
      default:
        break
    }
    setRange({ min, max })
    onChange({ min, max, maxValue: maxValue(chart, min, max) })
  }

  const updateRange = (inputText, type) => {
    setExternal(false)
    inputValidation(inputText, type)
  }

  const getSliderValue = () => {
    let sliderValue = Object.values(range)
    if (sliderValue[0] === '') {
      sliderValue[0] = 0
    }
    if (sliderValue[1] === '') {
      sliderValue[1] = 100
    }
    return sliderValue
  }

  const validateVal = ({ type }) => {
    let val
    if (type === 'min') {
      val = range.min === '' || Number(max) < 0 ? 0 : range.min
    } else {
      val = range.max === '' || Number(max) > 100 ? 100 : range.max
    }
    return val
  }

  const onBlur = () => {
    if (range.max < range.min) {
      let { min } = range
      let max = min + 1
      setRange({ min, max })
      setExternal(true)
      onChange({ min, max, maxValue: maxValue(chart, min, max) })
    }
  }

  return (
    <div style={{ width }}>
      {showInput && (
        <div>
          <Textbox
            type="min"
            textboxDefaultPlaceholder={'0'}
            showSearchIcon={false}
            textboxMargin={'0'}
            textboxError={false}
            textboxClass={'noBoxShadowInput'}
            multiline={false}
            textboxHeight={'32px'}
            textboxWidth={'40px'}
            defaultText={`${range.min}`}
            autoCompleteOff={true}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={false}
            textType={'number'}
            externalInput={external}
            thresholdValue={range.max}
            onChange={updateRange}
          />
          <div className={classes.toRange}>to</div>
          <Textbox
            type="max"
            textboxDefaultPlaceholder={'100'}
            showSearchIcon={false}
            textboxMargin={'0'}
            textboxClass={'noBoxShadowInput'}
            textboxError={false}
            multiline={false}
            textboxHeight={'32px'}
            textboxWidth={'40px'}
            defaultText={`${range.max}`}
            externalInput={external}
            autoCompleteOff={true}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={false}
            thresholdValue={100}
            onBlur={onBlur}
            textType={'number'}
            onChange={updateRange}
          />
        </div>
      )}
      <div style={{ marginTop: showInput ? 0 : 32 }}>
        <div className={classes.lineContainer}>
          <hr className={classes.line} />
          <div className={classes.lineValue}>
            {' '}
            {maxValue(chart, range.min, range.max)}% invested
          </div>
        </div>
        <ul className={classes.barRange}>
          {filledChart.map(({ score, investment_pct }, i) => (
            <li
              key={score}
              className={classes.bar}
              style={{
                background:
                  i + 1 > range.min && i <= range.max ? '#b2ceed' : '#dadae5',
                height: calcHeight(investment_pct)
              }}
            >
              {' '}
            </li>
          ))}
        </ul>
      </div>
      <Slider
        value={getSliderValue()}
        onChange={(e, [min, max]) => {
          setRange({ min, max })
          setExternal(true)
          onChange({
            min,
            max,
            maxValue: maxValue(chart, min, max)
          })
        }}
      />
      <ul className={classes.bottomRange}>
        <li>{validateVal({ type: 'min' })}</li>
        <li>{validateVal({ type: 'max' })}</li>
      </ul>
    </div>
  )
}

export default SliderChart
