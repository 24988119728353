import React from 'react'
import PropTypes from 'prop-types'
import { Arrows } from 'components'
import * as S from './styles'
import { LabelColors } from 'config'

const ResearchLine = ({
  title,
  value,
  label,
  onClick,
  data,
  research_line,
  arrow = false,
  color,
  minify
}) => {
  const previous = data?.previous
  const current = data?.current

  const newValue =
    research_line === 'carbon_footprint'
      ? value > 0
        ? `+${value}`
        : value
      : value

  const researchLineLabelColor = color ? color : LabelColors[label]?.color

  return (
    <div
      id={title ? `${title.replace(/\s/g, '_')}_id` : 'undefined'}
      aria-hidden={true}
      // eslint-disable-next-line react/no-unknown-property
      heap_id_title={title ? `${title.replace(/\s/g, '_')}_id}` : 'undefined'}
      // eslint-disable-next-line react/no-unknown-property
      heap_id_label={label ? `${label.replace(/\s/g, '_')}_id}` : 'undefined'}
      onClick={onClick}
    >
      <S.Container
        color={researchLineLabelColor}
        minify={minify}
        clickAble={onClick}
      >
        <S.Title minify={minify}>{title}</S.Title>
        <S.ValueRow>
          {arrow && !minify && value && (
            <S.ValueIcon>
              {value !== 0 && current !== null && previous !== null && (
                <Arrows
                  prev={previous || 0}
                  curr={current || 0}
                  researchLine={research_line}
                />
              )}
            </S.ValueIcon>
          )}
          <S.Value color={researchLineLabelColor} minify={minify}>
            <span>{label} </span>
            <span>
              {research_line === 'carbon_footprint'
                ? current !== null && previous !== null && newValue
                : newValue}
            </span>
          </S.Value>
        </S.ValueRow>
      </S.Container>
    </div>
  )
}

ResearchLine.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any,
  label: PropTypes.string,
  onClick: PropTypes.func,
  data: PropTypes.object,
  research_line: PropTypes.string.isRequired,
  arrow: PropTypes.bool,
  color: PropTypes.string
}

export default ResearchLine
