import { REPORTING_SERVICE } from 'constant/APIconstants'
import axios from 'axios'

export const getReportingPortfolios = async (cancelToken) => {
  const { data } = await axios.get(
    REPORTING_SERVICE.BASE_URL + REPORTING_SERVICE.REPORTING_PORTFOLIOS,
    { cancelToken: cancelToken }
  )

  return data
}

export const generateReport = async ({
  report_name,
  regulation,
  report_type,
  use_latest_data,
  portfolios,
  reporting_year
}) => {
  const { data } = await axios.post(
    REPORTING_SERVICE.BASE_URL + REPORTING_SERVICE.REPORTING_GENERATION,
    {
      report_name,
      regulation,
      report_type,
      use_latest_data,
      portfolios,
      reporting_year
    }
  )

  return data
}

export const checkStatus = async ({ request_id }) => {
  const { data } = await axios.post(
    REPORTING_SERVICE.BASE_URL + REPORTING_SERVICE.REPORTING_STATUS,
    {
      request_id
    }
  )

  return data[0]
}

export const getReportUrl = async ({ request_id }) => {
  const { data } = await axios.post(
    REPORTING_SERVICE.BASE_URL + REPORTING_SERVICE.REPORTING_DOWNLOAD,
    {
      request_id
    }
  )

  return data
}

export const updatePortfolios = async ({ portfolios }) => {
  const { data } = await axios.post(
    REPORTING_SERVICE.BASE_URL + REPORTING_SERVICE.REPORTING_PORTFOLIO_OPTIONS,
    {
      portfolios
    }
  )

  return data
}

export const getReportHistory = async () => {
  const { data } = await axios.get(
    REPORTING_SERVICE.BASE_URL + REPORTING_SERVICE.DOWNLOAD_HISTORY
  )
  return data
}

export const getReportingPortfolioCoverageDetails = async (
  portfolio,
  cancelToken
) => {
  const { data } = await axios.get(
    `${REPORTING_SERVICE.PORTFOLIO_URL}/${portfolio}${REPORTING_SERVICE.REPORTING_PORTFOLIO_COVERAGE}`,
    { cancelToken: cancelToken }
  )

  return data
}
