import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'

const useStyles = (props) =>
  makeStyles((theme) => ({
    container: {
      '& ul': {
        overflowY: 'auto',
        padding: props?.listPadding ? props.listPadding : '0px 5px'
      }
    },
    listItem: {
      ...theme.typography.p5,
      display: 'flex',
      height: '40px',
      width: props?.listItemWidth ? props.listItemWidth : '380px',
      backgroundColor: props?.listItemColor,
      background: '#FFFFFF',
      border: props?.listItemBorder && props.listItemBorder,
      borderRadius: '2px',
      marginBottom: '4px',
      padding: '4px',
      justifyContent: 'space-between',
      cursor: 'pointer',
      '& div:first-child': {
        '& h3': {
          margin: 0,
          lineHeight: '16px'
        },
        padding: '4px',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& h4': {
          margin: 0,
          lineHeight: '16px',
          ...theme.typography.p4
        }
      },
      '& div:last-child': {
        ...theme.typography.p2,
        width: '62px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: '#ffffff',
        borderRadius: '2px'
      },
      [theme.breakpoints.down(1000)]: {
        height: 'auto'
      }
    }
  }))

export const Container = ({ children, listPadding }) => {
  const classes = useStyles({ listPadding })(theme)
  return <div className={classes.container}>{children}</div>
}

export const ListItem = ({
  children,
  value,
  opacity,
  onItemClick,
  itemColor,
  listItemWidth,
  listItemBorder
}) => {
  const mapRange = [
    { color: `rgba(223, 161, 36, ${opacity})`, from: 0, to: 50 }, //TODO change these color codes
    { color: `rgba(175, 157, 63, ${opacity})`, from: 51, to: 70 },
    { color: `rgba(90, 151, 114, ${opacity})`, from: 71, to: 90 },
    { color: `rgba(34, 149, 149, ${opacity})`, from: 90, to: 100 }
  ]

  const { color } = mapRange.find(
    ({ from, to }) => value >= from && value <= to
  )

  const listItemColor = itemColor && itemColor !== '' ? itemColor : color
  const classes = useStyles({
    listItemColor,
    opacity,
    listItemWidth,
    listItemBorder
  })(theme)
  return (
    <li aria-hidden={true} className={classes.listItem} onClick={onItemClick}>
      {children}
    </li>
  )
}

export const titleStyle = {
  ...theme.typography.p2,
  color: theme.palette.sys_trad.text.main
}
export const subTitleStyle = {
  ...theme.typography.p4,
  fontSize: '10px !important',
  color: theme.palette.sys_trad.text.main
}
