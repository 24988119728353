import MaterialityDriverDisclosure from './MaterialityDriverDisclosure'
import { SourceDocuments } from './MaterialityDriverInfo.json'
import { Grid } from '@material-ui/core'
import { GlobalContext } from 'context/GlobalStateProvider'
import React, { useCallback, useContext, useEffect } from 'react'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import Error from 'utils/error'
import LoadingMask from 'utils/loadingMask'
import { SourceLink } from 'components'
import clsx from 'clsx'
import { COMMON, PARAMS } from 'constant/APIconstants'
import axios from 'axios'
import MaterialityTrend from './MaterialityTrend'
import MaterialityRelatedControversy from './MaterialRelatedControversy'
import * as S from './styles'

const MaterialityDriverInfoSector = (props) => {
  const classes = S.useStyles(props)
  const { reloadComponent } = props
  const [entityHeaderState] = useContext(GlobalContext).globalEntityHeader
  const [climateState, climateDispatch] =
    useContext(GlobalContext).globalClimate
  let apiCallId = climateState.apiCallId

  const disclosure = {
    isLoading: props.isLoading,
    dataStatus: props.data.dataStatus,
    data: props.data.apiData || []
  }

  const isDataAvailable = () => {
    let hasData = false
    if (isDisclosureHasData() || isTrendHasData()) hasData = true
    return hasData
  }

  const getControversies = useCallback(async () => {
    climateDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionNames: [REDUCER_STATE.CLIMATE_MTLTY_CONTROVERSY]
    })
    let controversyData = {}

    try {
      const { data } = await axios.post(
        COMMON.ENTITY_URL +
          entityHeaderState.globalEntityHeader.orbis_id +
          COMMON.RELATED_CONTROVERSY,
        {
          [PARAMS.ORBIS_ID]: entityHeaderState.globalEntityHeader.orbis_id,
          [PARAMS.CRITERIA]: props.data.criteria
        }
      )

      controversyData = data
      climateDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionNames: [
          { [REDUCER_STATE.CLIMATE_MTLTY_CONTROVERSY]: controversyData || {} }
        ],
        apiCallId
      })
    } catch (error) {
      climateDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        sectionNames: [REDUCER_STATE.CLIMATE_MTLTY_CONTROVERSY],
        apiCallId
      })
    }
  }, [climateDispatch])

  const isDisclosureHasData = () => {
    let hasData = false
    if (
      disclosure.data?.item?.length > 0 ||
      disclosure.data?.trends?.length > 0
    )
      hasData = true
    return hasData
  }

  const isTrendHasData = () => {
    let hasData = false
    if (disclosure.data?.trends?.length > 0) hasData = true
    return hasData
  }

  let sourceDocComp = []
  const link = 'https://www.moodys.com/Pages/contactus.aspx'

  const handleOpenSourceDocument = (reportLink) => {
    // TODO Replace with correct path for link to report
    const newWindow = window.open(reportLink, '_blank')
    if (newWindow) newWindow.opener = null
  }

  if (SourceDocuments && SourceDocuments.length > 0) {
    let lengthOfSourceDoc = SourceDocuments.length - 1
    SourceDocuments.map((sourceDoc, index) => {
      return sourceDocComp.push(
        <div
          className={clsx(
            classes.sourceDocStyle,
            index === 0 && classes.sourceDocTopStyle,
            index !== 0 && classes.sourceDocMiddleStyle,
            index === lengthOfSourceDoc && classes.sourceDocLastStyle
          )}
        >
          <SourceLink
            id={`${index} + ${sourceDoc}`}
            key={`${index} + ${sourceDoc}`}
            link={link}
            title={sourceDoc}
            onLinkClick={handleOpenSourceDocument}
          />
        </div>
      )
    })
  }

  const isControversyHasData = () => {
    let hasData = false
    if (
      climateState.climateMaterialityControversy.data &&
      Array.isArray(
        climateState.climateMaterialityControversy.data.controversies_list
      ) &&
      climateState.climateMaterialityControversy.data.controversies_list
        .length > 0
    )
      hasData = true
    return hasData
  }
  const reloadRelatedControversy = (errorOccuredOn) => {
    switch (errorOccuredOn) {
      case PAGE_CON.ENTITY_CONTROVERSY:
        getControversies(true)
        break
      case PAGE_CON.ENTITY_CLIMATE_PAGE_CONTROVERSIES_LIST:
        getControversies(true)
        break
      default:
        break
    }
  }

  const getEntityOverviewData = useCallback(async () => {
    if (
      props.isControversyEnabled &&
      entityHeaderState.globalEntityHeader.orbis_id !== '' &&
      !entityHeaderState.isLoading
    ) {
      getControversies()
    }
  }, [entityHeaderState.globalEntityHeader.orbis_id])

  useEffect(() => {
    if (entityHeaderState.globalEntityHeader.orbis_id !== '') {
      getEntityOverviewData()
    }
  }, [entityHeaderState.globalEntityHeader.orbis_id])

  return (
    <Grid container spacing={3} className={classes.gridStyle}>
      <Grid className={classes.disclosureStyle} item xs={12} sm={12} md={9}>
        {disclosure.isLoading ? (
          <LoadingMask />
        ) : !isDataAvailable() ? (
          <div className={classes.errorStyle}>
            <Error
              errorClass={'centerAlign'}
              id={'driverError'}
              margin={'0px'}
              dataStatus={disclosure.dataStatus}
              onErrorClick={reloadComponent}
              errorOccuredOn={PAGE_CON.ENTITY_DRIVER_SUMMARY}
              hideBoxShadow={true}
            />
          </div>
        ) : (
          <Grid container spacing={3}>
            {isDisclosureHasData() && (
              <Grid item xs={12} sm={12} md={12}>
                <MaterialityDriverDisclosure disclosureData={disclosure.data} />
              </Grid>
            )}
            {isTrendHasData() && (
              <Grid item xs={12} sm={12} md={12}>
                <MaterialityTrend
                  id={'trend_id'}
                  trendData={disclosure?.data?.trends}
                ></MaterialityTrend>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      {props.isControversyEnabled && (
        <Grid item xs={12} sm={12} md={3}>
          <>
            {climateState.climateMaterialityControversy.isLoading ? (
              <div className={classes.loadingControversies}>
                <LoadingMask />
              </div>
            ) : climateState.climateMaterialityControversy.dataStatus ===
                PAGE_CON.ERROR ||
              (climateState.climateMaterialityControversy.dataStatus ===
                PAGE_CON.SUCCESS &&
                !isControversyHasData()) ? (
              <>
                <h3 className={classes.rightPanelTitle}>
                  Related Controversies
                </h3>
                <section className={classes.recentControversies}>
                  <Error
                    errorClass={'centerAlign'}
                    margin={'0px'}
                    hideBoxShadow={true}
                    id={'controversyError'}
                    dataStatus={
                      climateState.climateMaterialityControversy.dataStatus
                    }
                    onErrorClick={reloadRelatedControversy}
                    errorOccuredOn={
                      PAGE_CON.ENTITY_CLIMATE_PAGE_CONTROVERSIES_LIST
                    }
                  />
                </section>
              </>
            ) : (
              <>
                <h3 className={classes.rightPanelTitle}>
                  Related Controversies
                </h3>
                {climateState.climateMaterialityControversy.data.controversies_list.map(
                  (controversy, index) => (
                    <MaterialityRelatedControversy
                      key={index}
                      controversy={controversy}
                    />
                  )
                )}
              </>
            )}
          </>
        </Grid>
      )}
    </Grid>
  )
}

export default MaterialityDriverInfoSector
