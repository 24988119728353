import React from 'react'
import { IconCopy, PrimaryLink } from 'components'
import PropTypes from 'prop-types'
import { DocumentTooltip, useStyles } from './styles'

const SourceLink = (props) => {
  const classes = useStyles()

  const copyText = (link) => {
    navigator.clipboard.writeText(link)
  }

  return (
    <div style={{ display: 'flex' }} key={props.id}>
      <DocumentTooltip
        className={classes.tooltip}
        interactive
        title={
          <div aria-hidden={true} onClick={() => copyText(props.link)}>
            <>
              <IconCopy className={classes.copyIcon} /> Copy URL
            </>
          </div>
        }
        placement="right"
      >
        <span className={classes.documents}>
          <PrimaryLink
            linkFontWeight={400}
            linkText={props.title}
            linkClass="linkInlineEntity"
            onClick={() => props.onLinkClick(props.link)}
          />
        </span>
      </DocumentTooltip>
    </div>
  )
}

SourceLink.propType = {
  /** source link id */
  id: PropTypes.string,
  /** source link title */
  title: PropTypes.string,
  /** source link url */
  link: PropTypes.string,
  /** source link url */
  onLinkClick: PropTypes.func
}
export default SourceLink
