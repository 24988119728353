import React, { useEffect, useState } from 'react'
import * as S from './styles'
import PropTypes from 'prop-types'
import Item from './item'
import LoadingMask from 'utils/loadingMask'
import Constant from 'constant'
import { PAGE_CON } from 'constant/constants'

const CountryList = ({
  items,
  isLoading,
  action,
  selectedFromMap,
  countrySelected,
  entityList,
  researchLineSelected,
  entitlements,
  handleEntityClick
}) => {
  const [entityLoading, setEntityLoading] = useState(true)

  const researchLines = Constant.Dashboard.getResearchLines(
    entitlements
  ).filter((researchline) => researchline.isEntitled)

  const defaultResearchLine = researchLines.filter(({ header }) => !header)[0]
  const researchLine =
    researchLines.find((r) => r.key === researchLineSelected) ||
    defaultResearchLine

  const mapRange = Constant.Scores.scores(researchLine?.underscore)?.map(
    ({ min, max, color }) => ({ color, from: min, to: max })
  )

  const clickHandler = (key) => {
    setEntityLoading(true)
    return action(key)
  }

  const refs = items.reduce((acc, value) => {
    acc[value.key] = React.createRef()
    return acc
  }, {})

  useEffect(() => {
    if (selectedFromMap) {
      if (refs[selectedFromMap]?.current) {
        var target = refs[selectedFromMap].current
        target.parentNode.scrollTo({
          top: target.offsetTop - 923,
          behavior: 'smooth'
        })
      }
    }
  }, [selectedFromMap])

  const selectedCountry = items.find(({ key }) => key === countrySelected)

  useEffect(() => {
    if (entityList.isLoading === false) {
      setEntityLoading(false)
    }
  }, [entityList.isLoading])

  const itemClickHandler = (key) => {
    if (handleEntityClick) handleEntityClick(key)
  }

  const isTransitionRiskRLorPRM = (researchLineSelected) =>
    PAGE_CON.CATEGORY_RL_GEO_RISK.includes(researchLineSelected)

  return (
    <S.Container>
      {isLoading ? (
        <LoadingMask />
      ) : selectedCountry ? (
        <S.EntityContainer>
          {entityList.isLoading || entityLoading ? (
            <LoadingMask />
          ) : (
            <>
              <ul>
                <li className="selectedCountry">
                  <Item
                    {...selectedCountry}
                    isEntity={false}
                    key={`selectedEntity-${selectedCountry.key}`}
                    selected={true}
                    mapRange={mapRange}
                    researchLineSelected={researchLineSelected}
                    researchLineSelectedUnserscore={researchLine.underscore}
                  />
                </li>
              </ul>
              <span className="backButton" aria-hidden={true} onClick={() => clickHandler('')}>
                {'<'} back to all countries
              </span>
              <p className="companiesTitle">{`Investments in ${selectedCountry.title} by Company`}</p>
              <ul className="entityList">
                {entityList.data &&
                  entityList.data.length > 0 &&
                  entityList.data.map((item, index) => (
                    <li key={`entityList-${index}-${item.bvd9_number}`}>
                      <Item
                        isEntity={true}
                        entityName={
                          <S.CompanyNameItem className="CompanyNameWrapper">
                            <S.CompanyNameItem
                              onClick={() =>
                                itemClickHandler(
                                  item.parent_bvd9_number || item.bvd9_number
                                )
                              }
                              className={'ellipsis'}
                            >
                              <S.CompanyNameItem
                                title={item.company_name}
                                className="entity"
                              >
                                {item.company_name}
                              </S.CompanyNameItem>
                            </S.CompanyNameItem>
                          </S.CompanyNameItem>
                        }
                        title={item.company_name}
                        subTitle={`${
                          item.holdings < 1 ? '<1' : item.holdings
                        }% investment`}
                        value={item.score}
                        label={
                          isTransitionRiskRLorPRM(researchLineSelected)
                            ? item.score_category
                            : item.score_range
                        }
                        itemKey={item.bvd9_number}
                        key={item.bvd9_number}
                        selected={false}
                        mapRange={mapRange}
                        researchLineSelected={researchLineSelected}
                        researchLineSelectedUnserscore={researchLine.underscore}
                      />
                    </li>
                  ))}
              </ul>
            </>
          )}
        </S.EntityContainer>
      ) : (
        <ul className="countryList">
          {items.map((item, index) => (
            <li key={`${index}-${item.key}`} ref={refs[item.key]}>
              <Item
                isEntity={false}
                {...item}
                itemKey={item.key}
                clickHandler={clickHandler}
                selected={item.key === selectedFromMap}
                mapRange={mapRange}
                researchLineSelected={researchLineSelected}
                researchLineSelectedUnserscore={researchLine.underscore}
              />
            </li>
          ))}
        </ul>
      )}
    </S.Container>
  )
}

CountryList.propTypes = {
  items: PropTypes.array,
  isLoading: PropTypes.bool,
  action: PropTypes.func,
  selectedFromMap: PropTypes.string,
  countrySelected: PropTypes.string,
  entityList: PropTypes.object,
  researchLineSelected: PropTypes.string,
  entitlements: PropTypes.object,
  handleEntityClick: PropTypes.func
}

export default CountryList
