import clsx from 'clsx'
import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import React from 'react'
import { useStyles } from './styles'

/**
 * @visibleName Radio Button
 */

const RadioButton = (props) => {
  const classes = useStyles(props)
  const {
    id,
    value,
    isChecked,
    radioLabelText,
    isDisabled,
    inputClass,
    handleRadioChange,
    labelClass
  } = props

  const handleChange = (event) => {
    if (event.target.checked && handleRadioChange) {
      handleRadioChange({ id: props.id, checked: event.target.checked })
    }
  }

  return (
    <FormControl
      id={id}
      component="fieldset"
      className={classes.formControlStyle}
    >
      <FormControlLabel
        className={classes.formControlLabelStyle}
        control={
          <Radio
            disableRipple
            color="default"
            checkedIcon={
              <span className={clsx(classes.icon, classes.checkedIcon)} />
            }
            icon={<span className={classes.icon} />}
            value={value}
            onClick={() => props.onClick && props.onClick}
            disabled={isDisabled ? isDisabled : false}
            className={clsx(
              classes.root,
              inputClass ? inputClass : '',
              isDisabled ? classes.disabled : ''
            )}
            checked={isChecked ? isChecked : false}
            onChange={handleRadioChange ? handleChange : () => {}}
          />
        }
        label={
          <span
            title={radioLabelText}
            className={
              isChecked ? classes.selectedLabelStyle : classes[labelClass]
            }
          >
            {radioLabelText}
          </span>
        }
      />
    </FormControl>
  )
}

RadioButton.propTypes = {
  /** id for Radio */
  id: PropTypes.string,
  /** Value for Radio */
  value: PropTypes.string,
  /** Label for Radio */
  radioLabelText: PropTypes.string,
  /** isChecked */
  isChecked: PropTypes.bool,
  /** isDisabled */
  isDisabled: PropTypes.bool,
  /** Class for Radio style  */
  inputClass: PropTypes.string,
  /** Gets called when the input changes */
  handleRadioChange: PropTypes.func,
  /** Label class name for Radio */
  labelClass: PropTypes.string,
  /** Radio label width */
  labelWidth: PropTypes.string
}

RadioButton.defaultProps = {
  disabled: false,
  labelClass: 'labelClass'
}

export default RadioButton
