import React from 'react'
import { ResearchLineCategory } from 'components'
import PropTypes from 'prop-types'
import * as S from './summaryHeaderComponents/index.jsx'
import Error from 'utils/error'
import { PAGE_CON } from 'constant/constants'
import Constant from 'constant'

const Esg = ({ data, minify, reloadComponent }) => {
  const esgData =
    data.find(({ researchLine }) => researchLine === 'esgasmt') || {}

  const esgDataCategory = esgData && esgData.value && esgData.value.category

  const { researchLineScoreByLabel } = Constant.Scores

  const esgCategoryColor =
    esgDataCategory &&
    researchLineScoreByLabel('summaryEsg', esgDataCategory)?.color

  return (
    <S.ResearchLineContainer minify={minify} width={!minify && '180px'}>
      <ResearchLineCategory minify={minify} title="ESG score">
        {esgData?.status !== 'fulfilled' && !minify ? (
          <Error
            id={'esgasmtError'}
            dataStatus={PAGE_CON.ERROR}
            onErrorClick={reloadComponent}
            errorOccuredOn={PAGE_CON.DASHBOARD_RESEARCH_LINES}
            width={270}
          />
        ) : minify ? (
          <S.EsgMinify>
            <span>ESG score</span>
            <span>{esgDataCategory || '-'}</span>
          </S.EsgMinify>
        ) : (
          <>
            {esgData?.value ? (
              <S.EsgBox color={esgCategoryColor}>{esgDataCategory}</S.EsgBox>
            ) : (
              <Error
                id={'pmError'}
                onErrorClick={reloadComponent}
                dataStatus={PAGE_CON.SUCCESS}
                height={'100px'}
              />
            )}
          </>
        )}
      </ResearchLineCategory>
    </S.ResearchLineContainer>
  )
}

Esg.propTypes = {
  data: PropTypes.array,
  minify: PropTypes.bool
}

export default Esg
