import { PAGE_CON, REDUCER_TYPE } from '../../constant/constants'
import { v4 as uuidv4 } from 'uuid'

const summaryTempAlignment = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const summaryCarbonFootprint = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const summaryPhyRiskHarz = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const summaryPhyRiskMgm = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const summaryGreenShare = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const summaryBrownShare = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const phyOperationRisk = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const phyMarketRisk = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const phySupplyChainRisk = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const phyOperationalRiskChart = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const transCarbonFootPrintChart = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const transBrownShareFootPrintChart = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const transTempAlign = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const transCarbonFootPrint = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const transBrownShare = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const transGreenShare = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const tempAlignmentProjectionGraph = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const phyMarketRiskChart = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const phySupplyChainChart = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const overallESGScore = {
  isLoading: true,
  dataStatus: '',
  data: {}
}
const climateControversy = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const climateMaterialityControversy = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const esgMateriality = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const entitySourceDocuments = {
  isLoading: true,
  dataStatus: '',
  data: []
}
const PhyRiskMgmUDM = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

export function climateInitialState() {
  let obj = {}
  obj.apiCallId = uuidv4()
  obj.summaryTempAlignment = Object.assign(
    {},
    { ...summaryTempAlignment, data: {} }
  )
  obj.summaryCarbonFootprint = Object.assign(
    {},
    { ...summaryCarbonFootprint, data: {} }
  )
  obj.summaryPhyRiskHarz = Object.assign(
    {},
    { ...summaryPhyRiskHarz, data: {} }
  )
  obj.summaryPhyRiskMgm = Object.assign({}, { ...summaryPhyRiskMgm, data: {} })
  obj.PhyRiskMgmUDM = Object.assign({}, { ...PhyRiskMgmUDM, data: {} })
  obj.summaryGreenShare = Object.assign({}, { ...summaryGreenShare, data: {} })
  obj.summaryBrownShare = Object.assign({}, { ...summaryBrownShare, data: {} })
  obj.phyOperationRisk = Object.assign({}, { ...phyOperationRisk, data: {} })
  obj.phyMarketRisk = Object.assign({}, { ...phyMarketRisk, data: {} })
  obj.phySupplyChainRisk = Object.assign(
    {},
    { ...phySupplyChainRisk, data: {} }
  )
  obj.phyOperationalRiskChart = Object.assign(
    {},
    { ...phyOperationalRiskChart, data: {} }
  )
  obj.transCarbonFootPrintChart = Object.assign(
    {},
    { ...transCarbonFootPrintChart, data: {} }
  )
  obj.transBrownShareFootPrintChart = Object.assign(
    {},
    { ...transBrownShareFootPrintChart, data: {} }
  )
  obj.transTempAlign = Object.assign({}, { ...transTempAlign, data: {} })
  obj.transCarbonFootPrint = Object.assign(
    {},
    { ...transCarbonFootPrint, data: {} }
  )
  obj.transBrownShare = Object.assign({}, { ...transBrownShare, data: {} })
  obj.transGreenShare = Object.assign({}, { ...transGreenShare, data: {} })
  obj.tempAlignmentProjectionGraph = Object.assign(
    {},
    { ...tempAlignmentProjectionGraph, data: [] }
  )
  obj.phyMarketRiskChart = Object.assign(
    {},
    { ...phyMarketRiskChart, data: [] }
  )
  obj.phySupplyChainChart = Object.assign(
    {},
    { ...phySupplyChainChart, data: [] }
  )
  obj.overallESGScore = Object.assign({}, { ...overallESGScore, data: {} })
  obj.climateControversy = Object.assign(
    {},
    { ...climateControversy, data: {} }
  )
  obj.climateMaterialityControversy = Object.assign(
    {},
    { ...climateMaterialityControversy, data: {} }
  )
  obj.esgMateriality = Object.assign({}, { ...esgMateriality, data: {} })
  obj.entitySourceDocuments = Object.assign({}, { ...entitySourceDocuments })

  return obj
}

const ClimateReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.LOAD_DATA_START:
      action.sectionNames.map((sectionName) => {
        currentState[sectionName].isLoading = true
        currentState[sectionName].dataStatus = PAGE_CON.LOADING
        return ''
      })
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_ERROR:
      if (action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      action.sectionNames.map((sectionName) => {
        currentState[sectionName].isLoading = false
        currentState[sectionName].dataStatus = PAGE_CON.ERROR
        return ''
      })
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_COMPLETED:
      if (action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      action.sectionNames.map((section) => {
        let sectionName = Object.keys(section)[0]
        currentState[sectionName].isLoading = false
        currentState[sectionName].data = section[sectionName]
        currentState[sectionName].dataStatus = PAGE_CON.SUCCESS
        return ''
      })
      return { ...currentState }
    case REDUCER_TYPE.RESET:
      return climateInitialState()
    default:
      return currentState
  }
}

export default ClimateReducer
