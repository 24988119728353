const productionFlag = process.env.REACT_APP_ENV === 'prd' ? true : false
const uatFlag = process.env.REACT_APP_ENV === 'uat' ? true : false
const perfFlag = process.env.REACT_APP_ENV === 'perf' ? true : false
const demoFlag = process.env.REACT_APP_ENV === 'demo' ? true : false

export const PAGE_CON = {
  APP_NAME: 'MESG Platform',
  SAMPLE_PORTFOLIO_ID: '00000000-0000-0000-0000-000000000000',
  PRODUCTION_ENABLED: productionFlag,
  UAT_ENV: uatFlag,
  PERF_ENV: perfFlag,
  DEMO_ENV: demoFlag,
  SUCCESS: 'success',
  LOADING: 'loading',
  ERROR: 'error',
  CANCELLED: 'Cancelled',
  SCORE: 'score',
  PORT_REV_TITLE: 'Moody\'s ESG360',
  PAGE_TITLE: 'Portfolio Analysis',
  CACULATIONS_TITLE: 'Calculations',
  CACULATIONS_DRAWER_TITLE: 'Portfolio Calculations',
  WEIGHTED_AVERAGE_CALCULATIONS_TITLE: 'Weighted Average Calculations',
  PREDICTED_SCORE_AND_DATA_TITLE: 'Predicted Scores & Data',
  DISPLAY_ESG_PREDICTED_SCORES_LABEL: 'Display ESG Predicted Scores',
  CARBON_FOOTPRINT_PREDICTED_DATA_LABEL: 'Carbon Footprint Predicted Data',
  PORTFOLIO_SCORE_TITLE: 'Portfolio Score',
  PORTFOLIO_ESGSCORE_TITLE: 'Portfolio Score',
  BENCHMARK_ESG_TITLE: 'Benchmark Score',
  BENCHMARK_SCORE_TITLE: 'Benchmark Score',
  PORTFOLIO_COVERAGE_TITLE: 'Portfolio Coverage',
  PORTFOLIO_HISTORY_TITLE: 'Portfolio History',
  BENCHMARK_HISTORY_TITLE: 'Benchmark History',
  COMPANIES_TITLE: 'Companies',
  INVESTMENT_TITLE: 'Investment',
  BENCHMARK_COVERAGE_TITLE: 'Benchmark Coverage',
  PORTFOLIO_DISTRIBUTION_TITLE: 'Portfolio Distribution',
  BENCHMARK_DISTRIBUTION_TITLE: 'Benchmark Distribution',
  EMISSIONS_TITLE: 'Emissions',
  UPDATES_TITLE: 'Portfolio Updates',
  UPDATESIN_TITLE: 'Updates in',
  IMPACT_TITLE: 'Impact',
  POS_IMPACT_TABLE_TITLE: 'Positive impact based on investment and score',
  POS_IMPACT_BAR_TITLE: 'Positive Impact Distribution',
  NEG_IMPACT_TABLE_TITLE: 'Negative impact based on investment and score',
  NEG_IMPACT_BAR_TITLE: 'Negative Impact Distribution',
  UPDATESIN_PHSYICALRISK_TITLE: 'Updates as of',
  LEADERS_TITLE: 'Leaders',
  LAGGARDS_TITLE: 'Laggards',
  REGIONS_TITLE: 'Regions',
  REGION: 'region',
  SECTORS_TITLE: 'Sectors',
  SECTOR: 'sector',
  MONTH: 'month',
  YEAR: 'year',
  INITIAL_DATA: 'initialData',
  BENCHMARK_PERC: '% Benchmark',
  BENCHMARK: 'Benchmark',
  COMPANIES_DEFAULT_LIMIT_FILTER: 20,
  ALL_FILTER: 'all',
  NONE_FILTER: 'none',
  FILTER_USER: 'user',
  FILTER_PORTFOLIO: 'portfolio',
  FILTER_REGIONS: 'regions',
  FILTER_SECTORS: 'sectors',
  FILTER_ASOFDATE: 'asOfDate',
  FILTER_BENCHMARK: 'benchmark',
  FILTER_LAST60DAYS: 'last60Days',
  FILTER_CATEGORY_1: 'category_1',
  FILTER_CATEGORY_2: 'category_2',
  FILTER_VIEWBY: 'viewBy',
  FILTER_LIMIT: 'limit',
  UPLOAD_PORTFOLIO: 'uploadPortfolio',
  UPLOAD_BENCHMARK: 'uploadBenchmark',
  FOOTER_COMPONENT: 'footerComponent',
  USER_ID_UNKNOWN: 'useridUnknown',
  MEASURING: 'Measuring',
  DISCLOSURES: 'Disclosures',
  ABOUT: 'About',
  CLIMATE_ASSESSMENT: 'Climate Assessment',
  ALL_REGIONS: 'All Regions',
  ALL_SECTORS: 'All Sectors',
  INDUSTRIES: 'Industries',
  REGION_CARD_BOTTOM_LINE: 'changes this month',
  REGION_CARD_BOTTOM_LINE_SINGLE: 'change this month',
  REGION_CARD_BOTTOM_LINE_QUARTER: 'changes this quarter',
  REGION_CARD_BOTTOM_LINE_QUARTER_SINGLE: 'change this quarter',
  FILTER_REGIONS_SECTORS_ASOFDATE: 'regions_sectors_asOfDate',
  WEAK: 'Weak',
  LIMITED: 'Limited',
  ROBUST: 'Robust',
  ADVANCED: 'Advanced',
  NONE: 'None',
  MINOR: 'Minor',
  MODERATE: 'Moderate',
  HIGH: 'High',
  INTENSE: 'Intense',
  SIGNIFICANT: 'Significant',
  MAJOR: 'Major',
  NO_INVOLVEMENT: 'No Involvement',
  MINOR_INVOLVEMENT: 'Minor Involvement',
  MAJOR_INVOLVEMENT: 'Major Involvement',
  AS_OF_DATE_CHANGED_FROM: 'As of Date changed from ',
  NO_BENCHMARK: 'No Benchmark',
  SELECT_BENCHMARK: 'Select Benchmark',
  EXPORT_FAILED_ISSUE:
    'Export failed because of an issue, please try again later.',
  EMISSION_TITLE: 'Emissions',
  OUT_OF_100: '/100',
  ENTITY_SCORE: 'entityScore',
  ENTITY_DRIVER_SUMMARY: 'entityDriverSummary',
  ISSUER_HEADER: 'issuerHeader',
  ISSUER_SOURCE_DOC: 'issuerSourceDoc',
  CLIMATE_SECTOR_COMPARISON: 'climateSectorComparision',
  CARBON_FOOT_PRINT_COMPARISON: 'carbonFootPrintComparison',
  ENTITY_DRIVER_DETAIL: 'entityDriverDetail',
  ENTITY_CONTROVERSY: 'entityControversy',
  ENTITY_POSITION_IN_SECTOR: 'entityPositionInSector',
  PORTFOLIO_ESG_SCORE: 'Portfolio Score',
  NO_RISK: 'No Risk',
  LOW_RISK: 'Low Risk',
  MEDIUM_RISK: 'Medium Risk',
  HIGH_RISK: 'High Risk',
  RED_FLAG_RISK: 'Red Flag Risk',
  DASHBOARD_MAP: 'dashboardMap',
  DASHBOARD_PORTFOLIOMONITORING_CONTROVERSIES_LIST:
    'dashboardPortfolioMonitoringControversiesList',
  ENTITY_CLIMATE_PAGE_CONTROVERSIES_LIST: 'entityClimatePageControversiesList',
  ISSUER_CONTROVERSIES_LIST: 'issuerControversiesList',
  DASHBOARD_RESEARCH_LINES: 'dashboardResearchLines',
  COMPANIES_AND_INVESTMENT_TABLE: 'CompaniesAndInvestmentsTable',
  '0_19': '0-19',
  '20_39': '20-39',
  '40_59': '40-59',
  '60_79': '60-79',
  '80_100': '80-100',
  UPLOAD_DATE_SUBTITLE: 'Portfolio uploaded on',
  WELL_BELOW_2: 'Well Below 2°C',
  BELOW_2: 'Below 2°C',
  ABOVE_2: 'Above 2°C',
  '2_DEGREES': '2°C',
  NO_INFO: 'No Info',
  ESG_A1: 'a1.esg',
  ESG_A2: 'a2.esg',
  ESG_A3: 'a3.esg',
  ESG_B1: 'b1.esg',
  ESG_B2: 'b2.esg',
  ESG_B3: 'b3.esg',
  ESG_C1: 'c1.esg',
  ESG_C2: 'c2.esg',
  ESG_C3: 'c3.esg',
  ESG_E: 'e.esg',
  CONTENT_TYPE_CLIMATE: 'climate',
  CONTENT_TYPE_ESG: 'esg',
  CONTENT_TYPE_REG_REPORTING: 'regrpt',
  CLIMATE_SUMMARY: 'CLIMATE_SUMMARY',
  CLIMATE_PHY_RISK: 'CLIMATE_PHY_RISK',
  CLIMATE_MTLTY_MATRIX: 'CLIMATE_MTLTY_MATRIX',
  CLIMATE_TRAN_RISK: 'CLIMATE_TRAN_RISK',
  CLIMATE_ESG_SCORE: 'CLIMATE_ESG_SCORE',
  CLIMATE_PHY_OPERATIONAL_RISK_CHART: 'phyOperationalRiskChart',
  CLIMATE_PHY_MARKET_RISK_CHART: 'phyMarketRiskChart',
  CLIMATE_PHY_SUPPLY_CHAIN_RISK_CHART: 'phySupplyChainChart',
  CLIMATE_TRANS_CARBON_FOOT_PRINT_CHART: 'transCarbonFootPrintChart',
  CLIMATE_TRANS_BROWNSHARE_FOOT_PRINT_CHART: 'transBrownShareFootPrintChart',
  ISSUER_SOURCE_DOCUMENTS: 'ISSUER_SOURCE_DOCUMENTS',
  CLIMATE_ENTITY_PAGE_PROJECTION_GRAPH: 'CLIMATE_ENTITY_PAGE_PROJECTION_GRAPH',
  SECTOR_COMPARISON: 'SECTOR_COMPARISON',
  EXPORT_EXCEL: 'Exporting',
  EXPORT_PDF: 'Exporting',
  LOADING_SCREEN: 'Loading',
  CLIMATE_CONTROVERSY: 'climateControversy',
  CLIMATE_MTLTY_CONTROVERSY: 'climateControversy',
  LESS_THAN_MASK:
    'a_1_0_r_g_7_ghy_lkreqw_wbdfjke_mask_to_be_used_for_mark_for_portfolio_search_12f3_23gmjv_9873_njvfhskelvbjwerb_125398vgh_9088723_hgfdvhj',
  PORTFOLIO_MANAGEMENT: 'PORTFOLIO_MANAGEMENT',
  PORTFOLIO_MANAGEMENT_REUPLOAD: 'PORTFOLIO_MANAGEMENT_REUPLOAD',
  REPORTING_SERVICE: 'REPORTING_SERVICE',
  ESG_ASSESSMENT: 'ESG_ASSESSMENT',
  PREDICTED_SCORE: 'Predicted Score',
  PREDICTED: 'Predicted',
  ON_DEMAND_ASMNT_TITLE: 'On-Demand Assessment Request',
  ON_DEMAND_ASMNT_BATCH_REVIEW: 'BATCH_REVIEW',
  ON_DEMAND_ASMNT_CRITERIA_FILTER_VIEW: 'CRITERIA_FILTER_VIEW',
  ON_DEMAND_ASMNT_REQUEST_DETAIL: 'REQUEST_DETAIL',
  ON_DEMAND_ASMNT_LIMIT: 'ON_DEMAND_ASMNT_LIMIT',
  SAMPLE_PORTFOLIO: '00000000-0000-0000-0000-000000000000',
  PAGE_NOT_FOUND: 'Page Not Found',
  BACKDROP_CLICK: 'backdropClick',
  ESCAPE_KEY_DOWN: 'escapeKeyDown',

  PAGE_LEFT_MARGIN: 10,

  RISK_CAT_TITLE: ' - Risk Categories',
  SCOPE_TITLE: 'Scope',
  GS_UDM_TITLE: 'Overview of the Products and Technologies',
  DROPDOWN_LOADING_TEXT: 'Loading...',

  INITIAL_LOAD: 'InitialLoad',
  USER_CHANGE: 'UserChange',
  PROFILE_CHANGE: 'ProfileChange',
  FILTER_CHANGE: 'FilterChange',

  DELETE_PORTFOLIO: 'Deleting Portfolio',

  DROPDOWN_DISPLAY_COLUMN: 'displayText',
  KEY: 'key',
  CREATED_DATE: 'as_of_date',
  UPDATED_DATE: 'update_date',
  UPLOAD_DATE_UTC: 'upload_date_utc',

  COMMUNICATIONS: 'Communications',
  HEALTH_CARE: 'Health Care',
  MATERIALS: 'Materials',
  ENERGY: 'Energy',
  FINANCIALS: 'Financials',

  HEAT_STRESS: 'Heat Stress',
  WATER_STRESS: 'Water Stress',
  SEA_LEVEL_RISE: 'Sea Level Rise',
  SOCIO_ECONOMIC_RISK: 'Socioeconomic Risk',
  HURRICANES_TYPHOONS: 'Hurricanes & Typhoons',
  FLOODS: 'Floods',

  UNDERLYING_DATA_METRICS: 'Underlying Data Metrics',
  FACILITIES_EXPOSED: '% Facilities Exposed',
  COMPANY_DIST: 'Company Distribution',
  INVESTMENT_CATEGORY: 'Investment in Category',
  COMPANIES_CATEGORY: 'Companies in Category',
  PCT_INVESTMENT: '% Investment',
  COMPANIES_HOLDINGS: 'Companies in Holdings',

  // routes
  ROUTES_404: '/404',
  ROUTES_PORTFOLIO: '/portfolio',
  DASHBOARD: '/dashboard',
  PORTFOLIO_OVERVIEW: '/portfolioanalysis',
  SCENARIOS: '/scenarios',
  ON_DEMAND_SCORE: '/ondemandscores',
  SCREENING: '/screening',
  OPERATIONS_RISK: '/operationsrisk',
  OPERATIONSRISK: 'operationsRisk',
  PHYSICALRISKHZRD: 'physicalRiskHazards',
  MARKET_RISK: '/marketrisk',
  MARKETRISK: 'marketRisk',
  SUPPLY_CHAIN_RISK: '/supplychainrisk',
  SUPPLYCHAINRISK: 'supplyChainRisk',
  PHYSICAL_RISK_MANGMT: '/physicalriskmanagement',
  BROWN_SHARE_ASSESMENT: '/brownshareassessment',
  ENERGY_TRANSTION_MANGMT: '/energytransitionmanagement',
  TEMPERATURE_ALIGNMENT: '/temperaturealignment',
  CARBON_FOOTPRINT: '/carbonfootprint',
  TCFD_STRAGTEGY: '/tcfdstrategy',
  CLIMATE_CONTROVERSIES: '/climatecontroversies',
  GREEN_SHARE_ASSESMENT: '/greenshareassessment',
  EU_TAXONOMY: '/eutaxonomy',
  TRANSITION_CLIMATE_RISK_STESSED_PDS: '/transitionclimateriskstressedpds',
  PHYSICAL_CLIMATE_STRESSED_PDS: '/physicalclimatestressedpds',

  DASHBOARD_TITLE: 'Dashboard',
  SCENARIOS_TITLE: 'Scenarios',
  ON_DEMAND_SCORE_TITLE: 'On Demand Scores',
  SCREENING_TITLE: 'Screening',
  PORTFOLIO_ANALYSIS: 'PortfolioAnalysis',

  // Phisical Risk
  PHY_OVE_TEXT_TITLE: 'Portfolio Analysis',
  PHY_RSK_TITLE: 'Physical Risk',
  ESG_MTLTY_TITLE: 'ESG Materiality',
  PHYRSK_MGM_TITLE: 'Physical Risk Management',
  MRK_RSK_TITLE: 'Market Risk',
  OPR_RSK_TITLE: 'Operations Risk',
  SUPP_CHN_RISK_TITLE: 'Supply Chain Risk',
  PHY_RSK_HZRD_TITLE: 'Physical Risk Hazards',
  ESG_ASSESSMENT_TITLE: 'ESG Assessments',

  // Transiton Risk
  TRN_RSK_TITLE: 'Transition Risk',
  CRBN_FT_PRT_TITLE: 'Carbon Footprint',
  BRWN_SHR_ASM_TITLE: 'Brown Share Assessment',
  ESG_ASM_TITLE: 'ESG Assessment',
  TMP_ALM_TITLE: 'Temperature Alignment',
  TMP_ALM_LGND_TITLE: 'Temperature Alignment Score:',
  ENR_TRN_MGM_TITLE: 'Energy Transition Management',
  BROWN_SHARE_UDM_TITLE: 'Fossil Fuel Disclosures',

  // Climate Governance & Starategy
  CLM_GVN_STR_TITLE: 'Climate Governance & Strategy',
  TCFD_STR_TITLE: 'TCFD Strategy',
  EU_TXNM_TITLE: 'EU Taxonomy',
  GRN_SHR_ASM_TITLE: 'Green Share Assessment',
  CLM_CNTRV_TITLE: 'Climate Controversies',
  TCFD_TRN_MGM_TITLE: 'TCFD Disclosures',

  // Econmic Qualification
  ECO_QLF_TITLE: 'Economic Quantification',
  PHY_CLM_STR_PD_TITLE: 'Physical Climate Stressed PDs',
  TRN_CLM_RSK_STR_PD_TITLE: 'Transition Climate Risk Stressed PDs',

  // MESG Entitlements Product Keys
  MESG_ESG_MTLTY_ID: 'mesg-platform-esg-materiality',
  MESG_PHY_RSK_ID: 'mesg-platform-physical-risk',
  MESG_TRN_RSK_ID: 'mesg-platform-transition-risk',
  MESG_CONTROVERSIES_ID: 'mesg-platform-controversies-data',
  MESG_CLM_GVN_STR_ID: 'mesg-platform-climate-governance-and-strategy',
  MESG_EXPORT: 'mesg-platform-export-data',
  MESG_ESG_ID: 'mesg-platform-corporate-esg-data',
  MESG_SFDR_ID: 'mesg-platform-sfdr-data',
  MESG_EUTAXONOMY_ID: 'mesg-platform-eu-taxonomy',
  MESG_ON_DEMAND_ASMT_ID: 'mesg-platform-ondemand-assessment',
  MESG_ESG_PREDICTOR_SCORE_ID: 'mesg-platform-sp-esg',
  MESG_CLIMATE_PREDICTOR_SCORE_ID: 'mesg-platform-sp-climate',

  // UI Section title
  UPDATES_AND_CURRENT_LEADERS_LAGGARDS: 'updates_and_current_leaders_laggards',
  REGIONS_INDUSTRIES: 'regions_industries',
  OVERVIEW_OF_PRODUCTS_TECHNOLOGIES: 'overview_of_products_and_technologies',
  OVERVIEW_OF_PRODUCTS_TECHNOLOGIES_TITLES:
    'Overview of the Products and Technologies',
  TCFD_DISCLOSURES_TITLE: 'TCFD Disclosures',
  TCFD_DISCLOSURES: 'TCFD_disclosures',
  ENERGY_TRANSITION_ASSESSMENT_TITLE: 'Energy Transition Management',
  ENERGY_TRANSITION_ASSESSMENT: 'energy_transition_management',
  FOSSIL_FUEL_DISCLOSURES_TITLE: 'Fossil Fuel Disclosures',
  FOSSIL_FUEL_DISCLOSURES: 'fossil_fuel_disclosures',
  CARBON_FOOTPRINT_RISK_CATEGORIES: 'carbon_footprint_risk_categories',
  SUPPLY_CHAIN_RISK_CATEGORIES: 'supply_chain_risk_risk_categories',
  OPERATIONS_RISK_CATEGORIES: 'operations_risk_risk_categories',
  PHY_RISK_HZRD_CATEGORIES: 'physical_risk_hazards_risk_categories',
  MARKET_RISK_CATEGORIES: 'market_risk_risk_categories',

  // Climate Risk
  CLM_RISK_TITLE: 'Climate Risk',

  IMPACT_KEY_TOP_5: 'top5',
  IMPACT_KEY_TOP_10: 'top10',
  IMPACT_KEY_BOTTOM_5: 'bottom5',
  IMPACT_KEY_BOTTOM_10: 'bottom10',

  ENTITY_KEY_TOP_10: 'top10',
  ENTITY_KEY_TOP_20: 'top20',

  PORTFOLIO_HISTORY_TABLE_TITLE: 'Changes in portfolio entities over time',
  BENCHMARK_HISTORY_TABLE_TITLE: 'Changes in benchmark entities over time',

  // Sector Overview Constants
  SECTOR_ALLOCATION_TITLE: 'Sector Allocation',
  SOURCES_OF_INFO_TITLE: 'Sources of Information',
  QUESTIONING_FRAMEWORK_TITLE: 'Questioning Framework',
  ASSESSMENT_FRAMEWORK_TITLE: 'Assessment Framework',
  GRADES_AND_SCORES_TITLE: 'Scoring Methodology', // "Grades and Scores",

  PORTFOLIO_LIST: 'PORTFOLIO_LIST',
  ODA_PORTFOLIO_LIST: 'ODA_PORTFOLIO_LIST',
  PORTFOLIO_HISTORY: 'PORTFOLIO_HISTORY',
  REPORTING_STATUS: 'REPORTING_STATUS',
  PORTFOLIO_ENTITY: 'PORTFOLIO_ENTITY',

  defaultClimateTabs: [
    { label: 'ESG Materiality', key: 'esgMateriality', active: true },
    { label: 'Physical Risk', key: 'physicalRisk', active: false },
    { label: 'Transition Risk', key: 'transitionRisk', active: false }
  ],

  // Portfolio Analysis Page Constants
  PORTFOLIO_LEADERS_BY_SCORE: 'Leaders by Score',
  PORTFOLIO_LAGGARDS_BY_SCORE: 'Laggards by Score',
  SEC_GEO_DISTRIBUTION: 'Sector and Geographic Distribution',

  // ESG Score Entity Page Constants
  EMERGING: 'Emerging',
  PROGRESSING: 'Progressing',
  DEMONSTRATING: 'Demonstrating',
  LEADING: 'Leading',
  OVERALL_ESG_SCORE_HEADER: 'Overall ESG Score',
  SUB_CATEGORY: 'ESG Score',
  DATA_ENRICHED: 'data enriched',
  DATA_ENRICHED_UNDER_REVIEW: 'data enriched under review',
  RESEARCHED: 'researched',
  RESEARCHED_UNDER_REVIEW: 'researched under review',
  PUBLISHED: 'published',
  PUBLISHED_UNDER_REVIEW: 'published under review',
  REF_METH_BUTTON_TEXT: 'Reference and Methodologies',
  EXPRT_SRCS_DOC_BTN_TEXT: 'Export',
  ENTITY_SOURCE_DOCUMENTS: 'entitySourceDocuments',
  METHODOLOGIES_MODAL_HEADER_TEXT: 'Methodologies',
  EXPRT_SRC_DOC_MODAL_SUB_HDR: 'Source Documents',
  CONTROVERSY: 'Controversy',
  ESG: 'ESG',

  ESG_DOUBLE_MATERIALITY: 'Dual',
  ESG_BUSINESS_MATERIALITY: 'Business',
  ESG_STAKEHOLDER_MATERIALITY: 'Stakeholder',

  ESG_RISK_DISTRIBUTION: 'esgRiskDistribution',
  ESG_ENTITY_LIST: 'esgEntityList',
  ESG__TREEMAPENTITY_LIST: 'esgTreemapEntityList',

  SCORE_QUALITY: {
    LEVEL1: { TITLE: 'ESG Score Level 1', DESCRIPTION: 'Analyst Verified' },
    LEVEL2: { TITLE: 'Score Level 2', DESCRIPTION: 'Subsidiary' },
    LEVEL3: { TITLE: 'Score Level 3', DESCRIPTION: 'On-Demand' },
    LEVEL4: { TITLE: 'Score Level 4', DESCRIPTION: 'Predicted Score' }
  },

  NOT_APPLICABLE: 'NA',
  NON_SOVEREIGN_DERIVATIVES: 'non_sovereign_derivatives',
  CASH_AND_LIQUIDITIES: 'cash_and_liquidities',

  CATEGORY_RL_GEO_RISK: [
    'carbonfootprint',
    'temperaturealgmt',
    'greenshareasmt',
    'brownshareasmt',
    'physicalriskmgmt'
  ],
  ESG_REPORTING_PORTAL: 'ESG Reporting Portal'
}
