import React from 'react'
import * as S from './styles'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts/highstock'
import highchartmap from 'highcharts/modules/map'
import HighchartsReact from 'highcharts-react-official'
import { MapConfig } from 'config'
import theme from 'theme'
import LoadingMask from 'utils/loadingMask'
import Constant from 'constant'
;(function (H) {
  H.Legend.prototype.setItemEvents = null
})(Highcharts)

highchartmap(Highcharts)

const Map = ({
  countries,
  isLoading,
  data,
  mapDataClasses,
  height = '100%',
  title = '',
  showLegend = false,
  tooltip = 'common',
  onSeriesClickAction,
  setMapRef,
  portfolioName,
  researchLineSelected,
  researchLineSelectedUnserscore
}) => {
  const tooltipsWithHTML = ['dashboard', 'esg']

  const researchLineScores = Constant.Scores.scores(
    researchLineSelectedUnserscore
  )

  const findScoreTo = (to) => researchLineScores?.find(({ max }) => max === to)

  const findColorByScoreCategory = (score_category) => {
    if (researchLineSelectedUnserscore && score_category) {
      const researchLineByLabel = Constant.Scores.researchLineScoreByLabel(
        researchLineSelectedUnserscore,
        score_category
      )
      return researchLineByLabel?.color || undefined
    } else {
      return undefined
    }
  }

  const mapData = data
    ? data
    : countries &&
      countries.map((country) => ({
        ...country,
        key: country.country_code.toLowerCase(),
        value: country.score,
        color:
          researchLineSelected === 'controversies'
            ? findColorByScoreCategory(
                country.controversies_critical_high > 0 ? 'High' : 'Low'
              )
            : findColorByScoreCategory(country.score_category || country.score)
      }))

  const classes = S.useStyles(theme)

  const tooltipFormatter = {
    common: function () {
      if (this.point.carbonFootPrintScore !== undefined) {
        return (
          '<b> Country: </b>' +
          this.point.name +
          '<br/><b>Carbon Footprint Score: </b>' +
          this.point.carbonFootPrintScore +
          ' tCO2eq <br/><b>Carbon Footprint Grade: </b>' +
          this.point.category
        )
      } else if (
        this.point.isBrownShare &&
        this.point.ScoreRangeScore !== undefined
      ) {
        return (
          '<b> Country: </b>' +
          this.point.name +
          '<br/><b>% of Overall Revenues derived from Fossil Fuel Activities: </b>' +
          this.point.ScoreRangeScore +
          '% <br/><b>Brown Share Score: </b>' +
          this.point.category
        )
      } else if (this.point.ScoreRangeScore !== undefined) {
        return (
          '<b> Country: </b>' +
          this.point.name +
          '<br/><b>Score: </b>' +
          this.point.ScoreRangeScore +
          '<br/><b>Score Range: </b>' +
          this.point.category
        )
      } else if (
        this.point.isGreenShare &&
        this.point.ScoreCategoryScore !== undefined
      ) {
        return (
          '<b> Country: </b>' +
          this.point.name +
          '<br/><b>% of Investments in Commercial Activities linked to Green Solutions: </b>' +
          this.point.ScoreCategoryScore +
          '% <br/><b>Green Share Score: </b>' +
          this.point.category
        )
      } else if (this.point.ScoreCategoryScore !== undefined) {
        return (
          '<b> Country: </b>' +
          this.point.name +
          '<br/><b>Score: </b>' +
          this.point.ScoreCategoryScore +
          '<br/><b>Category: </b>' +
          this.point.category
        )
      } else return this.point.name + '<br/>' + this.point.value
    },
    dashboard: function () {
      if (researchLineSelected === 'controversies') {
        return `
        <b>${this.point.name} Companies in Portfolio ${portfolioName}</b>
        <ul>
        <li><span>Number of Companies with critical or high controversies:</span> <strong>${this.point.number_of_companies}</strong></li>
        <li><span>Percentage of holding with critical or high controversies:</span> <strong>${this.point.holdings}%</strong></li>
        <li><span>Critical/High Controversies out of total controversies in that country:</span> <strong>${this.point.controversies_critical_high}/${this.point.total_controversies}</strong></li>
        </ul>
        `
      } else {
        return `
        <b>${this.point.name} Companies in Portfolio ${portfolioName}</b>
        <ul>
        <li><span>Investments</span><strong>${
          this.point.holdings
        }%</strong></li>
        <li><span>Companies</span><strong>${
          this.point.number_of_companies
        }</strong></li>
        <li><span>Average Score</span><strong>${
          this.point.score_range || this.point.score_category
        }</strong></li>
        </ul>
        `
      }
    },
    esg: function () {
      return `
        <b>${this.point.name} Companies in Portfolio ${portfolioName}</b>
        <ul>
          <li><span>Investments</span><strong>${
            this.point.holdings
          }%</strong></li>
          <li><span>Companies</span><strong>${
            this.point.number_of_companies || this.point.companies
          }</strong></li>
          <li><span>Average Score</span><strong>${
            this.point.score
          }</strong></li>
        </ul>
      `
    }
  }[tooltip]

  const options = {
    title: {
      text: title,
      verticalAlign: 'bottom',
      style: S.mapMessageStyle
    },
    colorAxis: {
      visible: showLegend,
      min: 0,
      dataClasses: mapDataClasses
    },
    legend: {
      align: 'left',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      valueDecimals: 0,
      backgroundColor: 'rgba(255,255,255)',
      symbolRadius: 0,
      squareSymbol: false,
      symbolHeight: 12,
      symbolWidth: 16,
      itemDistance: 10,
      useHTML: true,
      x: 30,
      y: 1,
      labelFormatter: function () {
        return researchLineSelected === 'controversies'
          ? '<div class="container"><span>Location of Critical or High Severity Controversies</span></div>'
          : `<div class="container">
          <span>${findScoreTo(this.to)?.score}</span >
          </div>`
      }
    },
    credits: {
      enabled: false
    },
    chart: {
      height: height || '100%',
      margin: 20,
      spacing: 0,
      marginBottom: 80,
      className: classes.mapCotnainer,
      events: {
        load() {
          setMapRef && setMapRef(this)
        },
        // eslint-disable-next-line no-unused-vars
        click: function (e) {
          onSeriesClickAction && onSeriesClickAction('')
        },
        render() {
          const chart = this

          chart.series[0].points.forEach((point) => {
            if (point.graphic) {
              point.graphic.element.setAttribute('heap_id', 'grd')
              if (point.country_name) {
                point.graphic.element.setAttribute(
                  'heap_grd_id',
                  point.country_name
                )
              }
            }
          })
        }
      }
    },
    tooltip: {
      useHTML: tooltipsWithHTML.includes(tooltip), //set flag to true for advanced formatting in tooltip, like <ul>s/<li>s, images or tables, etc.
      className: tooltipsWithHTML.includes(tooltip)
        ? classes.mapTooltipContaiener
        : '',
      headerFormat: '',
      formatter: tooltipFormatter
    },
    series: [
      {
        mapData: MapConfig.mapDataWorld,
        name: '',
        data: mapData
      }
    ],
    plotOptions: {
      series: {
        events: {
          click: function (e) {
            onSeriesClickAction && onSeriesClickAction(e.point.key)
          }
        }
      },
      map: {
        nullColor:
          researchLineSelected === 'controversies'
            ? theme.palette.genrang_b.five.main
            : theme.palette.map.nullColor
      }
    }
  }

  if (countries) {
    options.series[0].joinBy = ['hc-key', 'key']
  }

  return (
    <>
      {isLoading ? (
        <LoadingMask />
      ) : (
        <>
          <HighchartsReact
            constructorType={'mapChart'}
            highcharts={Highcharts}
            options={options}
          />
        </>
      )}
    </>
  )
}

Map.propTypes = {
  countries: PropTypes.array,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.any,
  height: PropTypes.any,
  mapDataClasses: PropTypes.any,
  title: PropTypes.string,
  showLegend: PropTypes.bool,
  tooltip: PropTypes.oneOf(['common', 'dashboard', 'esg']),
  onSeriesClickAction: PropTypes.func,
  portfolioName: PropTypes.string,
  researchLineSelected: PropTypes.string,
  researchLineSelectedUnserscore: PropTypes.string
}

export default Map
