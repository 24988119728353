import { makeStyles } from '@material-ui/core'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  pageEditor: {
    width: '2.5em',
    'margin-left': '0.5em'
  },
  labelDriver: {
    width: '100%',
    display: 'flex',
    'justify-content': 'space-between',
    'align-items': 'center'
  },
  formControl: {
    width: '100%',
    '& > .MuiFormControlLabel-label': {
      width: '100%'
    }
  },
  inputPage: {
    padding: '2px',
    ...themeColorPalette.typography.fontWeightLight,
    backgroundColor: themeColorPalette.palette.white.main,
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.4)',
    borderRadius: '2px 2px 0px 0px'
  },
  inputRoot: {
    'font-family': '"Roboto","Helvetica","Arial",sans-serif',
    'font-weight': 'normal',
    '&:before, &:after': {
      border: 'none'
    },
    [`&:after,
              &.Mui-focused,
              &:hover,&.MuiInput-underline:hover:not(.Mui-disabled):before`]: {
      border: 'none'
    }
  },
  pageContainer: {
    width: 110
  },
  driverName: {
    width: 'calc(100% - 100px)'
  }
}))
