import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import * as S from '../../dashboard/dashboardComponents/index.jsx'
import { GlobalContext } from 'context/GlobalStateProvider'
import {
  IconError,
  InfiniteList,
  PrimaryButton,
  PrimaryModal
} from 'components'
import { getMonthDayYear } from 'utils/Date'
import PortfolioMonitoringModal from '../../dashboard/PortfolioMonitoring/Modal'
import LoadingMask from 'utils/loadingMask'
import * as St from './styles'

const ControversyDetails = (props) => {
  const classes = St.useStyles()

  const [listPage, setListPage] = useState(1)
  const [tableRows, setTableRows] = useState([])
  const [tableRowsLoading, setTableRowsLoading] = useState(false)
  const [, setControversyCount] = useState(0)
  const [, setCriticalCount] = useState(0)
  const [, setTotalEvents] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [popupData, setPopupData] = useState()
  const [selectedCatogoryFilter, setSelectedCatogoryFilter] = useState([])
  const [scrollFlag, setScrollFlag] = useState(true)

  const context = useContext(GlobalContext)
  const [climateState, climateDispatch] = context.globalClimate

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
  const getControversiesList = async (data, page = 1) => {
    if (data) {
      const controversiesRowsPerPage = 15
      await sleep(500)
      const newData = [...data]
      const EOL =
        controversiesRowsPerPage * page >= newData.length
          ? newData.length
          : controversiesRowsPerPage * page
      const dataSpliced = newData.splice(0, EOL)
      return dataSpliced
    }
  }

  const getControversies = useCallback(
    async (data) => {
      setTotalEvents(data.length)

      const controversiesTotal =
        data.filter(({ type }) => type === 'Controversy').length || 0
      const controversiesCritical =
        data.filter(({ severity }) => severity === 'Critical').length || 0

      setControversyCount(controversiesTotal)
      setCriticalCount(controversiesCritical)

      const initialData = await getControversiesList(data)
      setTableRows(initialData)
    },
    [climateDispatch]
  )

  useEffect(() => {
    getControversies(climateState.climateControversy.data.controversies_list)
  }, [climateState.climateControversy.dataStatus])

  useEffect(async () => {
    const newLoadedData = await getControversiesList(
      climateState.climateControversy.data.controversies_list,
      listPage
    )
    setTableRows(newLoadedData)
    setTableRowsLoading(false)
  }, [listPage])

  const scrollEndHandler = () => {
    if (scrollFlag) {
      const newListPage = listPage + 1
      setListPage(newListPage)
    }
  }

  const handleControvercyKeyDown = (e) => {
    if (e.keyCode === 27) setShowModal(false)
  }

  const isSelected = (categoryName) => {
    return selectedCatogoryFilter.filter((x) => x === categoryName).length > 0
  }

  const handleClickFilter = (categoryName) => {
    setTableRowsLoading(true)

    let newSelectedFilter = []
    let newList = []

    if (selectedCatogoryFilter.filter((x) => x === categoryName).length <= 0)
      newSelectedFilter.push(categoryName)

    for (let index = 0; index < selectedCatogoryFilter.length; index++) {
      if (selectedCatogoryFilter[index] !== categoryName)
        newSelectedFilter.push(selectedCatogoryFilter[index])
    }
    setSelectedCatogoryFilter(newSelectedFilter)

    if (newSelectedFilter.length === 0)
      newList = climateState.climateControversy.data.controversies_list
    else {
      newList = climateState.climateControversy.data.controversies_list.filter(
        (entity) =>
          entity.related_domain.some((domain) =>
            newSelectedFilter.includes(domain)
          )
      )
    }
    setTableRows(newList)
    setScrollFlag(false)
    setTableRowsLoading(false)
  }
  const openModal = (key) => {
    setPopupData(tableRows[key])
    setShowModal(true)
  }

  const filterList = climateState.climateControversy.data.sub_categories.map(
    (entity, index) => {
      return (
        <Box p={0.3} key={index}>
          <PrimaryButton
            id="btn"
            key={index}
            buttonSelected={isSelected(entity.sub_category)}
            onClick={() => handleClickFilter(entity.sub_category)}
            buttonText={`${entity.sub_category} (${entity.sub_category_cnt})`}
            buttonClass="buttonSlimControversies"
            buttonMarginLeft="9px"
          />
        </Box>
      )
    }
  )

  const mappedListData = (data) =>
    data.map((row) => {
      const { severity, controversy_title, controversy_events } = row

      return [
        <S.ListCellContainer
          className={['date', classes.date].join(' ')}
          key={'controversy'}
        >
          {getMonthDayYear(controversy_events)}
        </S.ListCellContainer>,
        severity === 'Critical' ? (
          <S.ListCellContainer className={classes.errorIcon}>
            <IconError style={{ marginTop: '5px' }} />
          </S.ListCellContainer>
        ) : (
          ''
        ),
        <S.ListCellContainer className="description" key={'controversy_title'}>
          {controversy_title}
        </S.ListCellContainer>
      ]
    })
  const dataTableRows = mappedListData(tableRows)
  return (
    <div>
      {showModal && (
        <PrimaryModal
          id="methodologies_modal"
          modalOpen={showModal}
          modalClass="minorModal"
          modalHeaderContentComponent={<></>}
          modalContentComponent={
            <PortfolioMonitoringModal
              closeModalAction={() => setShowModal(false)}
              selectedControversy={popupData}
            />
          }
          onModalClose={() => setShowModal(false)}
          showCloseIcon={false}
          onKeyDown={handleControvercyKeyDown}
          modalWidth="0px"
          modalMaxWidth={false}
          backdropstyle={true}
        />
      )}
      <div>
        <div className={classes.controversiesTitle}>
          <span>ESG Incidents</span>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ paddingBottom: '12px' }}>
            <Box display={'flex'} flexWrap="wrap">
              {' '}
              <Box p={0.3} className={classes.subText}>
                Filter by most impacted categories of ESG:
              </Box>
              {filterList}
            </Box>
          </Grid>
        </Grid>
        {dataTableRows.length === 0 ? (
          <LoadingMask />
        ) : (
          <InfiniteList
            notClickable={false}
            isLoading={tableRowsLoading}
            data={dataTableRows}
            onScrollEnd={scrollEndHandler}
            height={props.listHeight}
            rowAction={(key) => openModal(key)}
          />
        )}
      </div>
    </div>
  )
}

ControversyDetails.propTypes = {}

export default ControversyDetails
