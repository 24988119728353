import React from 'react'
import { Redirect } from 'react-router-dom'
import OktaSignInWidget from './OktaSignInWidget'
import { useOktaAuth } from '@okta/okta-react'

const Login = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth()

  const onSuccess = (tokens) => {
    oktaAuth.handleLoginRedirect(tokens.tokens)
  }

  const onError = (err) => {
    console.log('error logging in', err)
  }

  if (!authState) return null

  if (authState && authState.isPending) return null

  return authState && authState.isAuthenticated ? (
    <Redirect to={{ pathname: '/' }} />
  ) : (
    <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />
  )
}
export default Login
