import CardActionArea from '@material-ui/core/CardActionArea'
import CardComp from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { useStyle } from './styles'

export const CardContext = React.createContext()

/**
 * @visibleName Card
 */
const Card = (props) => {
  const {
    headerTitle,
    subTitle,
    components,
    id,
    selectedId,
    onCardSelect,
    selectionType,
    cardClass,
    cardIcon,
    isCardClickable
  } = props

  const [selected, setSelected] = React.useState(false)
  let classes = useStyle({ selected, ...props })
  const values = { id, selectedId }

  React.useEffect(() => {
    if (id !== selectedId && selectionType === 'single') setSelected(false)
  }, [selectedId, id, selectionType])

  function onCardClick() {
    if (selectionType === 'none') return

    if (selectionType === 'multi') setSelected((prevSelected) => !prevSelected)
    else setSelected(true)

    if (onCardSelect && selectionType === 'single')
      onCardSelect(id, headerTitle)
  }

  return (
    <CardComp
      key={id}
      elevation={0}
      className={clsx(
        props.cardClass ? classes[cardClass] : classes.root,
        selected
          ? classes[cardClass]
            ? classes[cardClass + 'Selected']
            : classes.cardSelected
          : ''
      )}
      id={id}
      onClick={() => {
        if (isCardClickable) onCardClick()
      }}
    >
      {props.headerTitle && (
        <CardActionArea
          classes={{
            root: classes[cardClass]
              ? classes[cardClass + 'ActionArea']
              : classes.actionArea,
            focusHighlight: classes.focusHighlight
          }}
          TouchRippleProps={{ className: classes.ripple }}
          onClick={() => onCardClick()}
        >
          <CardHeader
            disableTypography
            classes={{
              root: classes[cardClass]
                ? classes[cardClass + 'ActionArea']
                : classes.actionArea,
              avatar: classes.cardAvatar,
              content: classes.cardContent
            }}
            avatar={cardIcon}
            title={headerTitle}
            subheader={subTitle}
            {...(props.heap_regsec_id
              ? { heap_regsec_id: props.heap_regsec_id }
              : {})}
          />
        </CardActionArea>
      )}
      {components &&
        components.length > 0 &&
        components.map((component, index) => {
          return (
            <CardContent key={index} className={classes.content}>
              <CardContext.Provider value={values}>
                {component}
              </CardContext.Provider>
            </CardContent>
          )
        })}
    </CardComp>
  )
}

Card.prototype = {
  /** card id */
  id: PropTypes.string,
  /** components that will be displayed inside the card */
  components: PropTypes.array,
  /** label for card */
  headerTitle: PropTypes.string,
  /** subtitle for card */
  subTitle: PropTypes.any,
  /** Max width for Card */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** selected card will call this function with its id so any
   * other card which is currently selected will make itself unselected  */
  onCardSelect: PropTypes.func,
  /** id of the card which is currently selected */
  selectedId: PropTypes.string,
  /** selectionType can have one out of 3 values (single, multi, none) */
  selectionType: PropTypes.oneOf(['single', 'multi', 'none']),
  /** cardClass can be shadow or left blank for original class or searchCard or platformSearchCard*/
  cardClass: PropTypes.string,
  /** optional Card Icon */
  cardIcon: PropTypes.any,
  /** optional Card click attribute for miniCards */
  isCardClickable: PropTypes.bool,
  /** optional no hover for card */
  noHover: PropTypes.bool,
  /** Padding for Card */
  cardPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Header Height for Card */
  cardHeaderHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Card.defaultProps = {
  selectionType: 'single',
  isCardClickable: false
}

export default Card
