import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { MinimalTable } from 'components'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  tableTitle: {
    paddingBottom: '8px'
  },
  grid: {
    textAlign: 'left',
    color: themeColorPalette.palette.sys_trad.main.main,
    paddingRight: '13px'
  },
  tableStyle: {
    marginLeft: -4
  },
  outerDivStyle: {
    minHeight: '130px'
  }
}))

const tableTextSubtitle = {
  ...textTheme.typography.h3,
  color: themeColorPalette.palette.sys_trad.text.main,
  textAlign: 'left'
}

const tableDescription = {
  ...textTheme.typography.p1,
  color: themeColorPalette.palette.sys_trad.text.main,
  textAlign: 'left'
}

const Emissions = (props) => {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <Grid item xs={12} id="carbonfootprint-emission-test-id">
        <Grid container direction={'row'} className={classes.paper}>
          {props.emissionTableTitles.map((title, index) => {
            let tableData = props.emissionData
              ? props.emissionData.filter(
                  (item) =>
                    item.category.toUpperCase() === title.title.toUpperCase()
                )
              : []

            const units = {
              'Total Financed Emissions': ' (tCO2eq)',
              'Financed Emissions per million Invested': ' (tCO2eq/M)',
              'Carbon Intensity per Sales': ' (tCO2eq/M)'
            }

            if (tableData && tableData.length > 0) tableData = tableData[0].data
            if (tableData) {
              tableData[0].units = units[title['title']]
                ? units[title['title']]
                : ''
              // quick fix for benchmark - should refactor this
              if (tableData[1]) {
                tableData[1].units = units[title['title']]
                  ? units[title['title']]
                  : ''
              }
            } else tableData = []

            return (
              <Grid
                className={classes.grid}
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
              >
                <div className={classes.outerDivStyle}>
                  <span style={tableTextSubtitle}> {title.title}</span> <br />
                  {title.description ? (
                    <div style={tableDescription}> {title.description}</div>
                  ) : (
                    <div style={{ paddingTop: 3 }}></div>
                  )}
                  {title.formula ? (
                    <div style={tableDescription}>
                      {' '}
                      <div style={{ marginTop: 4 }}> </div> {title.formula}
                    </div>
                  ) : (
                    <div style={{ paddingTop: 3 }}></div>
                  )}
                </div>
                <Grid item xs={12} className={classes.tableStyle}>
                  <MinimalTable
                    id="carbonfootprint-financial-emission-minimaltable-test-id"
                    hideHeaderBar={false}
                    columns={props.colInfo}
                    data={tableData}
                  ></MinimalTable>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </div>
  )
}

Emissions.propTypes = {
  /** Leaders id */
  id: PropTypes.string
  /** Number of records to display */
}

export default Emissions
