import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import theme from 'theme'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    maxHeight: (props) => (props.height ? props.height : '232px'),
    overflowY: 'auto',
    background: '#ffffff'
  },
  loadingContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: '50px !important'
    }
  },
  tableRow: {
    cursor: (props) => (props.notclickable === 'true' ? 'auto' : 'pointer'),
    ...theme.typography.p3,
    borderBottom: `1px solid ${theme?.palette?.sys_trad?.border.main}`,
    '& .clickable': {
      cursor: 'pointer'
    },
    '&:hover': {
      backgroundColor: (props) =>
        props.notclickable === 'true'
          ? 'transparent'
          : theme?.palette?.sys_trad?.hover2.main
    }
  },
  tableCell: {
    '&.cell': {
      ...theme.typography.p3,
      verticalAlign: 'top'
    },
    head: {
      color: theme.palette.common.white
    },
    body: {
      fontSize: 14
    }
  },
  tableBody: {
    borderTop: `1px solid ${theme?.palette?.sys_trad?.border.main}`,
    '& tr': {
      '& td:first-child': {
        paddingLeft: '10px'
      }
    },
    '& td': {
      verticalAlign: 'middle',
      height: '25px',
      padding: '8px 8px'
    }
  },
  table: {
    width: '100%',
    display: 'table',
    borderSpacing: '0',
    borderCollapse: 'collapse'
  }
}))

export const Container = ({ children, onScroll, height }) => {
  const classes = useStyles({ height })
  return (
    <div style={{ height: height ? height : '232px' }}>
      <Grid container className={classes.container} onScroll={onScroll}>
        {children}
      </Grid>
    </div>
  )
}

export const Table = ({ children, ...props }) => {
  const classes = useStyles({ props })
  return (
    <table className={classes.table} {...props}>
      {children}
    </table>
  )
}

export const TableBody = ({ children, ...props }) => {
  const classes = useStyles({ props })
  return (
    <tbody className={classes.tableBody} {...props}>
      {children}
    </tbody>
  )
}

export const TableRow = ({ children, ...props }) => {
  const classes = useStyles({ ...props })
  return (
    <tr className={classes.tableRow} {...props}>
      {children}
    </tr>
  )
}

export const TableCell = ({ children, ...props }) => {
  const classes = useStyles({ props })
  return (
    <td className={`${classes.tableCell} cell`} {...props}>
      {children}
    </td>
  )
}

export const LoadingContainer = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.loadingContainer}>{children}</div>
}
