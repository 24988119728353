import { makeStyles } from '@material-ui/core/styles'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles({
  BarCompStyle: {
    minWidth: (props) => (props.cellWidth ? props.cellWidth : 100),
    padding: 0,
    borderBottom: '4px solid ' + themeColorPalette.palette.white.main,
    borderRight: (ESGAssessment) =>
      ESGAssessment ? 0 : '4px solid ' + themeColorPalette.palette.white.main,
    backgroundColor: (props) => props.bgColor,
    height: (props) => (props.cellHeight ? props.cellHeight : 16),
    position: 'relative'
  },
  innerDivStyle: {
    backgroundColor: (props) => props.barColor,
    height: '100%',
    width: (props) => props.barWidth,
    borderRadius: (props) => (props.barCorner ? props.barCorner : '2px')
  },
  benchmarkStyle: {
    width: '3px',
    height: '100%',
    position: 'absolute',
    backgroundColor: themeColorPalette.palette.sys_trad.text.main,
    top: '0',
    right: (props) =>
      parseFloat(props.formattedBenchmarkValue) >= 20 &&
      100 - parseFloat(props.formattedBenchmarkValue) + '%',
    left: (props) =>
      parseFloat(props.formattedBenchmarkValue) < 20 &&
      parseFloat(props.formattedBenchmarkValue) + '%',
    display: (props) =>
      props.formattedBenchmarkValue === '' ? 'none' : 'block'
  }
})
