import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as S from '../dashboardComponents/index.jsx'
import OverViewText from 'views/portfolioanalysis/PortfolioAnalysisOverViewText.json'
import Constant from 'constant'
import { DashboardService } from 'services'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import LoadingMask from 'utils/loadingMask'
import Error from 'utils/error'

export const onErrorReload = (
  left = true,
  researchLines,
  getHeatmapDescription
) => {
  if (left) {
    getHeatmapDescription(true, researchLines[0])
  } else {
    getHeatmapDescription(false, researchLines[1])
  }
}

const Description = ({ researchLines, entitlements }) => {
  const context = useContext(GlobalContext)
  const [filterState] = context.globalFilter
  const [dashboardState, dashboardDispatch] = context.globalDashboard

  const isFilterStateAvailable =
    filterState.filterData.regionsList.length > 0 ||
    filterState.filterData.sectorsList.length > 0

  const searchOverview = (researchLine) => {
    if (researchLine) {
      const findOverview = Object.entries(OverViewText.OverViewText).find(
        (e) => e[0].toUpperCase() === researchLine.toUpperCase()
      )
      return findOverview ? findOverview[1] : ''
    } else {
      return ''
    }
  }
  const searchResearchLine = (researchLine) =>
    Constant.Dashboard.getResearchLines(entitlements).find(
      ({ key }) => key === researchLine
    )

  const researchLinesLoading = [
    dashboardState.dashboardHeatmapDescriptionLeft?.isLoading,
    dashboardState.dashboardHeatmapDescriptionRight?.isLoading
  ]

  const researchLinesData = [
    dashboardState.dashboardHeatmapDescriptionLeft?.data,
    dashboardState.dashboardHeatmapDescriptionRight?.data
  ]

  let apiCallId = dashboardState.apiCallId

  const getHeatmapDescription = async (left = true, research_line) => {
    if (research_line) {
      const sectionName = left
        ? REDUCER_STATE.DASHBOARD_HEATMAP_DESCRIPTION_LEFT
        : REDUCER_STATE.DASHBOARD_HEATMAP_DESCRIPTION_RIGHT
      if (filterState.globalPortfolioFilter?.portfolio) {
        let { regions } = filterState.globalPortfolioFilter
        const { portfolio, asOfDate, sectors } =
          filterState.globalPortfolioFilter
        regions = regions ? regions : PAGE_CON.ALL_FILTER
        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_START,
          sectionName
        })
        try {
          const data = isFilterStateAvailable
            ? await DashboardService.getHeatmapDescription({
                portfolio,
                regions,
                sectors,
                asOfDate,
                research_line
              })
            : {}
          const dispatchData = { ...data, researchLine: research_line }
          dashboardDispatch({
            type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
            sectionName,
            data: dispatchData,
            apiCallId
          })
        } catch (error) {
          dashboardDispatch({
            type: REDUCER_TYPE.LOAD_DATA_ERROR,
            sectionName,
            apiCallId
          })
        }
      }
    }
  }

  const resetDescriptions = () => {
    dashboardDispatch({
      type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
      sectionName: REDUCER_STATE.DASHBOARD_HEATMAP_DESCRIPTION_LEFT,
      data: {}
    })
    dashboardDispatch({
      type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
      sectionName: REDUCER_STATE.DASHBOARD_HEATMAP_DESCRIPTION_RIGHT,
      data: {}
    })
  }

  useEffect(() => {
    if (researchLines[0]) {
      getHeatmapDescription(true, researchLines[0])
    }
  }, [researchLines[0]])

  useEffect(() => {
    if (researchLines[1]) {
      getHeatmapDescription(false, researchLines[1])
    }
  }, [researchLines[1]])

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      resetDescriptions()
      if (researchLines[0]) {
        getHeatmapDescription(true, researchLines[0])
      }
      if (researchLines[1]) {
        getHeatmapDescription(false, researchLines[1])
      }
    }
  }, [filterState.actionData])

  return (
    <S.HeatmapSummary>
      {researchLines
        .filter((researchLine) => researchLine)
        .map((e, idx) => {
          const researchLine = researchLinesData.find(
            ({ researchLine }) => researchLine === e
          )
          const left = idx === 0
          const errorLeft =
            left &&
            dashboardState.dashboardHeatmapDescriptionLeft.dataStatus ===
              PAGE_CON.ERROR
          const errorRight =
            !left &&
            dashboardState.dashboardHeatmapDescriptionRight.dataStatus ===
              PAGE_CON.ERROR
          const error = errorLeft || errorRight
          const title =
            e?.toUpperCase() === 'CONTROVERSIES'
              ? searchResearchLine(e)?.display_heatmap_label
              : searchResearchLine(e)?.title
          return (
            <div key={e} data-testid={e}>
              <h3>{title || ''}</h3>
              {!error &&
                (researchLinesLoading[idx] ? (
                  <LoadingMask width={40} height={10} />
                ) : (
                  <span>
                    <h3>{`Coverage: ${researchLine?.coverage || '-'}`}</h3>
                    <h3>{`Portfolio Average: ${
                      researchLine?.score_range || researchLine?.score || '-'
                    }`}</h3>
                  </span>
                ))}
              <div className={error ? 'data' : null}>
                {errorLeft && (
                  <Error
                    id={'heatmapDescriptionErrorLeft'}
                    dataStatus={
                      dashboardState.dashboardHeatmapDescriptionLeft.dataStatus
                    }
                    onErrorClick={() =>
                      onErrorReload(true, researchLines, getHeatmapDescription)
                    }
                  />
                )}
                {errorRight && (
                  <Error
                    id={'heatmapDescriptionErrorRight'}
                    dataStatus={
                      dashboardState.dashboardHeatmapDescriptionRight.dataStatus
                    }
                    onErrorClick={() =>
                      onErrorReload(false, researchLines, getHeatmapDescription)
                    }
                  />
                )}
              </div>
              <p>{searchOverview(searchResearchLine(e).route)}</p>
            </div>
          )
        })}
    </S.HeatmapSummary>
  )
}

Description.propTypes = {
  researchLines: PropTypes.array,
  entitlements: PropTypes.object
}

export default Description
