import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'
import { PortfolioService } from 'services'
import React, { useContext } from 'react'
import RegionSectorDateFilter from '../portfolioanalysis/RegionSectorDateFilter'
import { DropdownContainer, PrimaryButton, PrimaryModal } from 'components'
import { v4 as uuidv4 } from 'uuid'
import Error from 'utils/error'

const RegionSectorDateComp = () => {
  const context = useContext(GlobalContext)
  const [filterState, filterDispatch] = context.globalFilter
  const [, globalDispatch] = context.globalManager

  const [isParentClosed, setIsParentClosed] = React.useState(false)
  const [isDateSelected, setDateSelected] = React.useState(true)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [selectedRegion, setRegions] = React.useState('')
  const [selectedSector, setSectors] = React.useState('')
  const [selectedAsOfDate, setAsOfDate] = React.useState('')
  const [selectionChange, setSelectionChange] = React.useState('')
  const [errorOccured, setErrorOccured] = React.useState(false)
  const [itemClick, setItemClick] = React.useState(0)

  const getFilterText = (filterType) => {
    let filterResult = []

    switch (filterType) {
      case PAGE_CON.FILTER_REGIONS:
        if (filterState.globalPortfolioFilter.regions === '') {
          filterResult.push({
            key: '',
            [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: PAGE_CON.ALL_REGIONS
          })
        } else
          filterResult = filterState.filterData.regionsList.filter(
            (x) => x.key === filterState.globalPortfolioFilter.regions
          )
        break
      case PAGE_CON.FILTER_SECTORS:
        if (filterState.globalPortfolioFilter.sectors === '') {
          filterResult.push({
            key: '',
            [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: PAGE_CON.ALL_SECTORS
          })
        } else
          filterResult = filterState.filterData.sectorsList.filter(
            (x) => x.key === filterState.globalPortfolioFilter.sectors
          )
        break
      case PAGE_CON.FILTER_ASOFDATE:
        filterResult = filterState.filterData.asOfDateList.filter(
          (x) => x.key === filterState.globalPortfolioFilter.asOfDate
        )
        break
      default:
        break
    }

    if (filterResult && filterResult.length > 0)
      return filterResult[0][PAGE_CON.DROPDOWN_DISPLAY_COLUMN]
    return ''
  }

  const filterChange = () => {
    if (
      filterState.globalPortfolioFilter.regions !== '' ||
      filterState.globalPortfolioFilter.sectors !== '' ||
      filterState.globalPortfolioFilter.asOfDate !==
        filterState.globalPortfolioFilter.asOfDateDefaultVal
    )
      return true

    return false
  }

  let displayText = `Viewing data in ${getFilterText(
    PAGE_CON.FILTER_REGIONS
  )}, ${getFilterText(PAGE_CON.FILTER_SECTORS)}, at the end of ${getFilterText(
    PAGE_CON.FILTER_ASOFDATE
  )}`

  const onClose = () => {
    setIsParentClosed(true)
  }

  const onOpen = () => {
    setIsParentClosed(false)
  }

  const onAsOfDateSelect = (val) => {
    setDateSelected(val)
  }

  const canClose = () => {
    if (isDateSelected) return true
    setModalOpen(true)
    return false
  }

  const handleCloseDialog = () => {
    setModalOpen(false)
  }

  function getUniqueId(actionData) {
    return actionData + '$$' + uuidv4()
  }

  const onChange = (changedOn, val) => {
    setItemClick((prev) => prev + 1)
    switch (changedOn) {
      case PAGE_CON.FILTER_REGIONS:
        setRegions(val)
        setSelectionChange(getUniqueId(PAGE_CON.FILTER_REGIONS))
        break
      case PAGE_CON.FILTER_SECTORS:
        setSectors(val)
        setSelectionChange(getUniqueId(PAGE_CON.FILTER_SECTORS))
        break
      case PAGE_CON.FILTER_ASOFDATE:
        setAsOfDate(val)
        globalDispatch({ type: REDUCER_TYPE.RESET_ALL, payload: context })

        filterDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          filterVal: { asOfDate: val }
        })

        filterDispatch({
          type: REDUCER_TYPE.UPDATED_ACTION,
          actionData: getUniqueId(PAGE_CON.FILTER_REGIONS_SECTORS_ASOFDATE)
        })
        break
      default:
        break
    }
  }

  const onErrorReload = () => {
    setErrorOccured(false)
    let actionPerformedOn = selectionChange.split('$$')[0]
    switch (actionPerformedOn) {
      case PAGE_CON.FILTER_REGIONS:
        setSelectionChange(getUniqueId(PAGE_CON.FILTER_REGIONS))
        break
      case PAGE_CON.FILTER_SECTORS:
        setSelectionChange(getUniqueId(PAGE_CON.FILTER_SECTORS))
        break
      default:
        break
    }
  }

  React.useEffect(() => {
    if (!filterState.isLoading) {
      setRegions(filterState.globalPortfolioFilter.regions)
      setSectors(filterState.globalPortfolioFilter.sectors)
      setAsOfDate(filterState.globalPortfolioFilter.asOfDate)
    }
  }, [filterState.isLoading])

  React.useEffect(() => {
    if (selectionChange === '') return

    onFilterChange(selectedRegion, selectedSector)
  }, [selectionChange])

  const getFilterData = async (
    portfolioId,
    selectedRegions = '',
    selectedSectors = ''
  ) => {
    let retData = { regions: [], sectors: [], asOfDate: [] }

    let regions = selectedRegions === '' ? PAGE_CON.ALL_FILTER : selectedRegions
    let sectors = selectedSectors === '' ? PAGE_CON.ALL_FILTER : selectedSectors

    const { data } = await PortfolioService.filterPortfolio({
      portfolio_id: portfolioId,
      regions: regions,
      sectors: sectors,
      token: filterState.cancelToken.token,
    })

    if (data.regions) {
      data.regions.map((item) => {
        return retData.regions.push({
          key: item.region_code,
          [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: item.region_name
        })
      })
    }

    if (data.sectors) {
      data.sectors.map((item) => {
        return retData.sectors.push({
          key: item.sector_code,
          [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: item.sector_name
        })
      })
    }

    if (data.as_of_dates) {
      data.as_of_dates.map((item) => {
        return retData.asOfDate.push({
          key: item.date_code,
          [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: item.display_date_name
        })
      })
    }

    return retData
  }

  const onFilterChange = async (regions, sectors) => {
    try {
      filterDispatch({
        type: REDUCER_TYPE.OPEN_BACKDROP,
        backdropText: PAGE_CON.LOADING_SCREEN
      })

      let filterData = await getFilterData(
        filterState.globalPortfolioFilter.portfolio,
        regions,
        sectors
      )

      const isDateSelected =
        filterData.asOfDate.filter((x) => x.key === selectedAsOfDate).length > 0
      let asOfDateDefaultVal =
        filterData.asOfDate && filterData.asOfDate.length > 0
          ? filterData.asOfDate[0].key
          : ''
      let asOfDate = selectedAsOfDate

      if (!isDateSelected) asOfDate = asOfDateDefaultVal

      globalDispatch({ type: REDUCER_TYPE.RESET_ALL, payload: context })

      filterDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        listData: {
          regionsList: filterData.regions,
          sectorsList: filterData.sectors,
          asOfDateList: filterData.asOfDate
        },
        filterVal: {
          regions: selectedRegion,
          sectors: selectedSector,
          asOfDate: asOfDate,
          asOfDateDefaultVal
        },
        asOfDateMessage: ''
      })

      filterDispatch({
        type: REDUCER_TYPE.UPDATED_ACTION,
        actionData: getUniqueId(PAGE_CON.FILTER_REGIONS_SECTORS_ASOFDATE)
      })

      filterDispatch({ type: REDUCER_TYPE.CLOSE_BACKDROP })
    } catch (err) {
      console.log(err.message)
      filterDispatch({ type: REDUCER_TYPE.CLOSE_BACKDROP })
      setErrorOccured(true)
    }
  }

  return (
    <>
      <PrimaryModal
        id="validation-test-id"
        modalOpen={modalOpen}
        onModalClose={handleCloseDialog}
        modalClass="smallModal"
        modalTitle="Input required"
        modalContentComponent={['Please select an "As Of Date"']}
        modalActionComponent={
          <PrimaryButton
            id="validation-button-test-id"
            buttonText="Ok"
            onClick={handleCloseDialog}
            buttonClass="buttonPrimary"
          />
        }
        modalNoCloseOnClick={true}
      />
      <PrimaryModal
        id="functionality-error-test-id"
        modalOpen={errorOccured}
        modalClass="errorModal"
        modalTitle=""
        modalContentComponent={
          <Error
            id={'updatesError'}
            dataStatus={PAGE_CON.ERROR}
            onErrorClick={onErrorReload}
            errorClass={'noMargin'}
          />
        }
        modalNoCloseOnClick={true}
        showCloseIcon={false}
      />

      <DropdownContainer
        id="RegSector-test-id-1"
        onClose={onClose}
        onOpen={onOpen}
        displayText={displayText}
        isNotDefaultFilter={filterChange()}
        containerClick={itemClick}
        containerComponent={
          <RegionSectorDateFilter
            isParentClosed={isParentClosed}
            setDateSelected={onAsOfDateSelect}
            onChange={onChange}
          ></RegionSectorDateFilter>
        }
        preCloseValidation={canClose}
      />
    </>
  )
}

export default RegionSectorDateComp
