import { makeStyles } from '@material-ui/core'
import theme from 'theme/theme'

export const useStyles = makeStyles(() => ({
  regionStyle: {
    marginTop: '18px'
  },
  root: {
    flexGrow: 1,
    background: '#ffff',
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.14)',
    borderRadius: '2px'
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.sys_trad.main.main,
    padding: '16px 16px 0px 20px'
  },
  cardInfoGrid: {
    minWidth: '295px'
  },
  linkStyle: {
    textAlign: 'left'
  }
}))

export const mapDataClasses = [
  {
    color: theme.palette.major_no.green_major.main,
    from: 0,
    to: 0
  },
  {
    color: theme.palette.major_no.green_signif.main,
    from: 1,
    to: 20
  },
  { color: theme.palette.major_no.green_no.main, from: 21, to: 100 }
]

export const barColorsBool = [
  theme.palette.sys_trad.border.main,
  theme.palette.major_no.green_no.main,
  theme.palette.major_no.green_major.main
]

export const barColors = [
  theme.palette.sys_trad.border.main,
  theme.palette.major_no.green_no.main,
  theme.palette.major_no.green_signif.main,
  theme.palette.major_no.green_major.main
]
