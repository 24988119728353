export function multiColumnSort(data, sortColumns) {
  let returnData = data
  if (data && data.length > 0) {
    returnData = data.sort((itemA, itemB) => sort(itemA, itemB, sortColumns))
  }
  return returnData
}

export default function sortData(data, sortKey, sortDirection = 'asc') {
  const sortColumns = [[sortKey, sortDirection, 'string']]
  const returnData = multiColumnSort(data, sortColumns)
  return returnData
}

/* Example :
    multiColumnSort(
            <ArrayTobeSorted>,
            [
              ['firstName', 'DESC', 'string',true],
              ['balance', 'ASC']
            ]
          )
*/

const sort = (itemA, itemB, columns) => {
  const [item, ...others] = columns
  const [column, orderBy, columnType, specialSort] = item
  if (orderBy) {
    const valueA =
      columnType && columnType.toUpperCase() === 'STRING'
        ? itemA[column]
          ? itemA[column].toUpperCase()
          : itemA[column]
        : itemA[column]
    const valueB =
      columnType && columnType.toUpperCase() === 'STRING'
        ? itemB[column]
          ? itemB[column].toUpperCase()
          : itemB[column]
        : itemB[column]

    if (specialSort === true) {
      const sortedVal = Compare(
        valueA,
        valueB,
        orderBy.toUpperCase() === 'ASC' ? 1 : -1
      )
      if (sortedVal !== 0) return sortedVal
      if (others.length) {
        return sort(itemA, itemB, others)
      }
      return 0
    }
    if (orderBy.toUpperCase() === 'ASC') {
      if (valueA > valueB) return 1
      if (valueA < valueB) return -1
      if (others.length) return sort(itemA, itemB, others)
      return 0
    }
    if (valueB > valueA) return 1
    if (valueB < valueA) return -1
    if (others.length) return sort(itemA, itemB, others)
    return 0
  } else {
    return 0
  }
}

const allChar = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  ' ',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '',
  '!',
  '"',
  '#',
  '$',
  '%',
  '&',
  '\'',
  '(',
  ')',
  '*',
  '+',
  ',',
  '-',
  '.',
  '/',
  ':',
  ';',
  '<',
  '=',
  '>',
  '?',
  '@',
  '[',
  '\\',
  ']',
  '^',
  '_',
  '`',
  '{',
  '|',
  '}',
  '~'
]

const Compare = (x, y, order) => {
  if (x && y) {
    const a = x.toLowerCase().trim()
    const b = y.toLowerCase().trim()
    const lenA = a.length
    const lenB = b.length
    const count = Math.min(lenA, lenB)

    for (let i = 0; i < count; i++) {
      if (a[i] !== b[i]) {
        const indA = allChar.indexOf(a[i])
        const indB = allChar.indexOf(b[i])

        if (indA >= 0 && indB >= 0) return (indA - indB) * order

        if (indA >= 0) return -1 * order

        if (indB >= 0) return 1 * order
        return (a[i] - b[i]) * order
      }
    }
    return (lenA - lenB) * order
  }
}
