import React, { useMemo, useState } from 'react'
import * as S from './styles'
import PropTypes from 'prop-types'
import LoadingMask from 'utils/loadingMask'
import { ErrorComponent } from 'components'

const InfiniteList = ({
  isLoading,
  data,
  onScrollEnd,
  rowAction,
  height,
  notClickable,
  maxDataLengthForScroll = 50
}) => {
  const [current, setCurrent] = useState(1)

  const slicedData = useMemo(
    () => data.slice(0, current * maxDataLengthForScroll),
    [data, current, maxDataLengthForScroll]
  )

  const handleScroll = (e) => {
    if (!isLoading) {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10
      if (bottom) {
        onScrollEnd && onScrollEnd()
        if (slicedData.length < data.length) {
          setCurrent(current + 1)
        }
      }
    }
  }

  const handleRowClick = (key) => {
    rowAction && rowAction(key)
  }

  return (
    <S.Container onScroll={handleScroll} height={height}>
      {!isLoading && data.length === 0 && (
        <ErrorComponent
          id={'infiniteListError'}
          hideErrorActionButton={true}
          errorText="There is no data on which to base our calculations"
        />
      )}
      <S.Table>
        <S.TableBody>
          {!isLoading &&
            slicedData?.map((row, rowKey) => (
              <S.TableRow
                heap_id="event"
                heap_event_id={`event_${row[3]?.props?.children}`}
                key={`tableRow${rowKey}`}
                notclickable={notClickable?.toString()}
                onClick={() => handleRowClick(rowKey)}
              >
                {row?.map((cell, cellKey) => (
                  <S.TableCell
                    key={`tableCell-${rowKey}-${cellKey}`}
                    className="cell"
                    {...(cell?.props?.className === 'name' &&
                    cell?.props?.children
                      ? { heap_entity: `${cell.props.children}` }
                      : {})}
                    {...(cell?.props?.className === 'description'
                      ? { heap_event: 'event-detail' }
                      : {})}
                  >
                    {cell}
                  </S.TableCell>
                ))}
              </S.TableRow>
            ))}
        </S.TableBody>
      </S.Table>
      {isLoading && (
        <S.LoadingContainer>
          <LoadingMask />
        </S.LoadingContainer>
      )}
    </S.Container>
  )
}

InfiniteList.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  onScrollEnd: PropTypes.func,
  height: PropTypes.string,
  notClickable: PropTypes.bool
}

export default InfiniteList
