import { useRef } from 'react'
import axios from 'axios'
import { Backdrop, Container } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import { useFilePicker } from 'use-file-picker'
import { PortfolioService } from 'services'
import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'
import DownloadTemplate from './PortfolioUpload.csv'
import { GlobalContext } from 'context/GlobalStateProvider'
import {
  COMMON,
  IMPORT_UPDATE_GET_PORTFOLIOS,
  PARAMS
} from 'constant/APIconstants'
import LoadingMask from 'utils/loadingMask'
import {
  PrimaryButton,
  PrimaryInput,
  PrimaryLink,
  PrimaryModal,
  PrimaryPopover
} from 'components'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
// hidden input is for qa team and test automation should always be false (not shown)

const uploadTextStyle = {
  ...textTheme.typography.p1,
  color: themeColorPalette.palette.sys_trad.text.main
}

const boxStyle = {
  width: '263px',
  height: '181px',
  margin: 'auto',
  paddingTop: '65px',
  display: 'table',
  borderRadius: '2px',
  verticalAlign: 'middle',
  border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
  backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
  boxSizing: 'borderBox',
  ...textTheme.typography.p1,
  color: themeColorPalette.palette.sys_trad.text.main,
  textAlign: 'center',
  lineHeight: '16px',
  alignContent: 'bottom'
}

const boxStyleWhite = {
  width: '263px',
  height: '181px',
  margin: 'auto',
  paddingTop: '120px',
  display: 'table',
  borderRadius: '2px',
  verticalAlign: 'middle',
  border: '1px dashed ' + themeColorPalette.palette.sys_trad.border.main,
  backgroundColor: themeColorPalette.palette.white.main,
  boxSizing: 'borderBox',
  ...textTheme.typography.p1,
  color: themeColorPalette.palette.sys_trad.text.main,
  textAlign: 'center',
  lineHeight: '16px'
}

const boxStyleError = {
  width: '270px',

  marginLeft: '24px',
  paddingTop: '15px',
  paddingBottom: '15px',
  display: 'table',
  borderRadius: '2px',
  verticalAlign: 'middle',
  border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
  backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
  boxSizing: 'borderBox',
  ...textTheme.typography.p1,
  color: themeColorPalette.palette.sys_trad.text.main,
  textAlign: 'center',
  lineHeight: '16px'
}

const divStyleError = {
  overflow: 'auto',
  overflowX: 'hidden',
  maxHeight: '250px'
}

const capitalize = (str) => {
  if (!str || str.length <= 0) return str
  return str[0].toUpperCase() + str.slice(1)
}

const backdrop = {
  zIndex: 1200 + 1,
  color: '#fff'
}

const UploadView = (props) => {
  const buttonRef = useRef(false)

  const [popoverOpen, setPopoverOpen] = React.useState(false)
  const [popoverErrorOpen, setPopoverErrorOpen] = React.useState(false)
  const [popoverErrorOpenDuplicates, setPopoverErrorOpenDuplicates] =
    React.useState(false)
  const [responseData, setResponseData] = React.useState(null)
  const [responseDataError, setResponseDataError] = React.useState(null)
  const [responseDataId, setResponseDataId] = React.useState(null)
  const [responseDataErrorId, setResponseDataErrorId] = React.useState(null)
  const [duplicates, setDuplicates] = React.useState(null)
  const [notFound, setNotFound] = React.useState(null)
  const [countDetail, setCountDetail] = React.useState('-/-')
  const [newPortfolioName, setNewPortfolioName] = React.useState('')
  const [inputEntry, setInputEntry] = React.useState(false)
  const [checkUploadDocumentDialogOpen, setCheckUploadDocumentDialogOpen] =
    React.useState(false)
  const [successAlertTimeOut, setSuccessAlertTimeOut] = React.useState(15000)
  const [alertTextValidation, setAlertTextValidation] = React.useState(
    'Something has happened. Please try again or upload a new portfolio.'
  )
  const [userClicked, setUserClicked] = React.useState(false)
  const [displayInputError, setDisplayInputError] = React.useState(false)
  const [uploadProcessStart, setUploadProcessStart] = React.useState(false)
  const [openFileSelector, { filesContent, errors }] = useFilePicker({
    multiple: false,
    accept: '.csv'
  })

  const [fileToUpload, setFileToUpload] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)

  const [filterState, filterDispatch] = useContext(GlobalContext)
    ?.globalFilter || ['', '']
  const [portfolioManagementState, portfolioManagementDispatch] = useContext(
    GlobalContext
  )?.globalPortfolioManagement || ['', '']
  const [, reportingServiceDispatch] = useContext(GlobalContext)
    .globalReportingService || ['', '']
  const uploadType = props.uploadType

  const portfolioNameLimit = 90

  useEffect(() => {
    if (props.modalOpen) {
      onPopoverErrorClose()
    }
    const timeoutID = window.setTimeout(() => {
      setPopoverOpen(false)
    }, successAlertTimeOut)
    return () => window.clearTimeout(timeoutID)
  }, [successAlertTimeOut, props])

  useEffect(() => {
    return () => setDisplayInputError(false)
  }, [props.modalOpen])

  const handleClose = (openPortfolioManagement = false) => {
    removeFile()
    props.onClose()

    if (
      (filterState.uploadInitiatedFrom === PAGE_CON.PORTFOLIO_MANAGEMENT ||
        filterState.uploadInitiatedFrom ===
          PAGE_CON.PORTFOLIO_MANAGEMENT_REUPLOAD) &&
      !uploadProcessStart &&
      openPortfolioManagement
    ) {
      portfolioManagementDispatch({
        type: REDUCER_TYPE.PORTFOLIO_MANAGEMENT,
        openPortfolioManagement: true
      })
    }
  }

  useEffect(() => {
    if (
      popoverOpen === false &&
      popoverErrorOpen === false &&
      popoverErrorOpenDuplicates === false &&
      uploadProcessStart === true &&
      props.modalOpen === false
    ) {
      if (
        filterState.uploadInitiatedFrom === PAGE_CON.PORTFOLIO_MANAGEMENT ||
        filterState.uploadInitiatedFrom ===
          PAGE_CON.PORTFOLIO_MANAGEMENT_REUPLOAD
      )
        portfolioManagementDispatch({
          type: REDUCER_TYPE.PORTFOLIO_MANAGEMENT,
          openPortfolioManagement: true
        })
      setUploadProcessStart(false)
      reportingServiceDispatch({ type: REDUCER_TYPE.RESET_REPORTING_SERVICE })
    }
  }, [popoverOpen, popoverErrorOpen, popoverErrorOpenDuplicates])

  const onPopoverClose = () => {
    setPopoverOpen(false)
  }

  const onPopoverErrorClose = () => {
    setPopoverErrorOpen(false)
  }

  const onPopoverErrorDuplicateClose = () => {
    setPopoverErrorOpenDuplicates(false)
  }

  const onPopoverUploadErrorClose = () => {
    setCheckUploadDocumentDialogOpen(false)
  }

  const onBrowseFile = () => {
    openFileSelector()
    setFileToUpload(true)
    setUserClicked(true)
  }

  const removeFile = () => {
    setCheckUploadDocumentDialogOpen(false)
    filesContent[0] = null
    setFileToUpload(false)
  }

  const onSavePortfolioName = async () => {
    if (newPortfolioName !== '') {
      await axios.put(
        IMPORT_UPDATE_GET_PORTFOLIOS + '/' + responseDataId ||
          responseDataErrorId,
        { [PARAMS.PORTFOLIO_NAME]: newPortfolioName }
      )
    }
    setResponseData(newPortfolioName)

    await updatePortfolios(true)

    setPopoverOpen(false)
  }

  const onPortfolioNameChange = (event) => {
    setNewPortfolioName(event.target.value)
    setInputEntry(true)
    setSuccessAlertTimeOut(999999999)
    if (event.target.value.length > portfolioNameLimit) {
      setDisplayInputError(true)
    } else {
      setDisplayInputError(false)
    }
  }

  const deletePortfolio = async () => {
    try {
      await axios.delete(COMMON.BASE_URL + portfolioManagementState.portfolioId)
    } catch (err) {
      console.log(err)
    }
  }

  const updatePortfolios = async (
    isNameChanged = false,
    portfolioID = null
  ) => {
    filterDispatch({ type: REDUCER_TYPE.LOAD_DATA_START })

    let portfolios = []
    try {
      const { data } = await PortfolioService.getPortfolioDetails({
        params: {},
        queryParams: ''
      })

      for (const [, value] of Object.entries(data.portfolios)) {
        portfolios.push({
          key: value.portfolio_id,
          [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: value.portfolio_name,
          [PAGE_CON.CREATED_DATE]: value[PAGE_CON.CREATED_DATE],
          [PAGE_CON.UPLOAD_DATE_UTC]: value[PAGE_CON.UPLOAD_DATE_UTC]
        })
      }

      if (isNameChanged) {
        filterDispatch({
          type: REDUCER_TYPE.UPDATE_PORTFOLIO_COMPLETED,
          portfolioList: portfolios
        })
      } else if (
        filterState.uploadInitiatedFrom === PAGE_CON.PORTFOLIO_MANAGEMENT ||
        filterState.uploadInitiatedFrom ===
          PAGE_CON.PORTFOLIO_MANAGEMENT_REUPLOAD ||
        filterState.uploadInitiatedFrom === PAGE_CON.REPORTING_SERVICE
      ) {
        if (
          filterState.uploadInitiatedFrom ===
            PAGE_CON.PORTFOLIO_MANAGEMENT_REUPLOAD &&
          filterState.globalPortfolioFilter.portfolio ===
            portfolioManagementState.portfolioId
        ) {
          filterDispatch({
            type: REDUCER_TYPE.UPDATE_PORTFOLIO,
            component: PAGE_CON.UPLOAD_PORTFOLIO,
            portfolioList: portfolios,
            portfolio: portfolioID
          })
        } else {
          filterDispatch({
            type: REDUCER_TYPE.UPDATE_PORTFOLIO_COMPLETED,
            portfolioList: portfolios
          })
        }

        portfolioManagementDispatch({
          type: REDUCER_TYPE.SET_PORTFOLIO,
          portfolioId: portfolioID
        })
      } else {
        if (uploadType === PAGE_CON.FILTER_BENCHMARK) {
          filterDispatch({
            type: REDUCER_TYPE.UPDATE_BENCHMARK,
            component: PAGE_CON.UPLOAD_BENCHMARK,
            portfolioList: portfolios,
            benchmark: portfolioID
          })
        } else {
          filterDispatch({
            type: REDUCER_TYPE.UPDATE_PORTFOLIO,
            component: PAGE_CON.UPLOAD_PORTFOLIO,
            portfolioList: portfolios,
            portfolio: portfolioID
          })
        }
      }
    } catch (err) {
      console.log(err.message)
      let errorOn =
        uploadType === PAGE_CON.FILTER_BENCHMARK
          ? PAGE_CON.UPLOAD_BENCHMARK
          : PAGE_CON.UPLOAD_PORTFOLIO
      filterDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        errorOccuredOn: errorOn
      })
    }
  }

  const uploadFile = async () => {
    if (!buttonRef?.current) {
      buttonRef.current = true
      setUploadProcessStart(true)
      setUserClicked(false)
      setIsLoading(true)
      const data = new FormData()

      setResponseData(null)
      setResponseDataError(null)
      setInputEntry(false)

      let timeOut = successAlertTimeOut
        ? successAlertTimeOut === 999999999
          ? 16000
          : successAlertTimeOut + 0.01
        : 16000
      setSuccessAlertTimeOut(timeOut)

      let user = filterState.globalPortfolioFilter.user
        ? filterState.globalPortfolioFilter.user
        : PAGE_CON.USER_ID_UNKNOWN
      data.append('userId', user)
      data.append(
        'file',
        new Blob([filesContent[0].content], { type: 'text/csv' })
      )
      data.append('filename', JSON.stringify(filesContent[0].name))

      let isError = false
      let newPortfolioID = null

      await PortfolioService.updatePortfolioDetails({ data })
        .then(function (response) {
          props.onClose()
          setResponseData(response.data.portfolio_name)
          setResponseDataId(response.data.portfolio_id)
          newPortfolioID = response.data.portfolio_id
          setIsLoading(false)
          setPopoverOpen(true)
          setUserClicked(true)
          handleClose()
          buttonRef.current = false
        })
        .catch(function (error) {
          buttonRef.current = false
          if (error?.response?.status === 500) {
            setAlertTextValidation(
              'Something has happened. Please try again or upload a new portfolio.'
            )
            window.heap.track('portfolio_upload_error_500', {
              portfolio_id: error.response.data.portfolio_id,
              user_id: filterState.globalPortfolioFilter.user,
              error_data: alertTextValidation
            })
            setCheckUploadDocumentDialogOpen(true)
            setIsLoading(false)
            setUserClicked(true)
            setUploadProcessStart(false)
            isError = true
          } else if (error?.response?.status === 413) {
            setAlertTextValidation(
              'Something has happened. Please try again or upload a new portfolio.'
            )
            window.heap.track('portfolio_upload_error_413', {
              portfolio_id: error?.response?.data?.portfolio_id,
              user_id: filterState.globalPortfolioFilter.user,
              error_data: alertTextValidation
            })
            setCheckUploadDocumentDialogOpen(true)
            setIsLoading(false)
            setUserClicked(true)
            setUploadProcessStart(false)
            isError = true
          } else if (error?.response?.status === 400) {
            setAlertTextValidation(error.response.data.errorMessage)
            window.heap.track('portfolio_upload_error_400', {
              portfolio_id: error.response.data.portfolio_id,
              user_id: filterState.globalPortfolioFilter.user,
              error_data: error.response.data.errorMessage
            })
            setCheckUploadDocumentDialogOpen(true)
            setIsLoading(false)
            setUserClicked(true)
            setUploadProcessStart(false)
            isError = true
          } else if (
            error.response &&
            error.response.data &&
            error.response.status !== 500
          ) {
            setNotFound(
              error.response.data.not_found &&
                error.response.data.not_found.map((s) => (
                  <React.Fragment key={s}>
                    {s}
                    <br />
                  </React.Fragment>
                ))
            )
            setDuplicates(
              error.response.data.duplicate_companies &&
                error.response.data.duplicate_companies.map((c, index) => (
                  <React.Fragment key={c.company_name}>
                    <b>
                      Company {c.company_name} mapped to {c.duplicate_count}{' '}
                      identifiers:{' '}
                    </b>{' '}
                    <br />
                    {c.duplicate_ids.map((id) => (
                      <React.Fragment key={id}>
                        {id}
                        <br />
                      </React.Fragment>
                    ))}
                    {error.response.data.duplicate_companies.length !==
                      index + 1 && <br />}
                  </React.Fragment>
                ))
            )
            setCountDetail(error.response.data.details)
            setResponseDataError(error.response.data.portfolio_name)
            window.heap.track('portfolio_upload_error', {
              portfolio_id: error.response.data.portfolio_id,
              user_id: filterState.globalPortfolioFilter.user,
              matched_identifiers: error.response.data.details
            })
            setResponseDataErrorId(error.response.data.portfolio_id)
            newPortfolioID = error.response.data.portfolio_id
            if (error.response.data?.not_found?.length > 0)
              setPopoverErrorOpen(true)
            if (error.response.data?.duplicate_companies?.length > 0)
              setPopoverErrorOpenDuplicates(true)
            setIsLoading(false)
            setUserClicked(true)
            handleClose()
          } else {
            setAlertTextValidation(
              'Something has happened. Please try again or upload a new portfolio.'
            )
            window.heap.track('portfolio_upload_error_unknown', {
              portfolio_id: error?.response?.data?.portfolio_id,
              user_id: filterState.globalPortfolioFilter.user,
              error_data: alertTextValidation
            })
            setCheckUploadDocumentDialogOpen(true)
            setUploadProcessStart(false)
            setIsLoading(false)
            setUserClicked(true)
            isError = true
          }
        })
      if (!isError) {
        window.heap.track('portfolio_upload_success', {
          portfolio_id: newPortfolioID,
          user_id: filterState.globalPortfolioFilter.user
        })
        if (
          filterState.uploadInitiatedFrom ===
          PAGE_CON.PORTFOLIO_MANAGEMENT_REUPLOAD
        ) {
          await deletePortfolio()
        }

        await updatePortfolios(false, newPortfolioID)
      }
      buttonRef.current = false
    }
  }

  const identifierText =
    (notFound?.length || 0) > 1 ? 'identifiers' : 'identifier'

  return (
    <Container maxWidth="sm">
      {isLoading && (
        <div>
          <Backdrop style={backdrop} open={props.modalOpen}>
            <LoadingMask loadText="Uploading" />
          </Backdrop>
        </div>
      )}
      {!isLoading && (
        <div>
          <PrimaryModal
            id="upload-modal-test-id"
            modalOpen={props.modalOpen}
            onModalClose={() => handleClose(true)}
            modalClass="smallModal"
            modalMaxWidth="xs"
            modalTitle={`Import ${capitalize(uploadType)}`}
            modalContentComponent={
              <span>
                <div style={uploadTextStyle}>
                  {`Upload a ${uploadType} in CSV format. Acceptable`}
                </div>
                <div style={uploadTextStyle}>
                  {'identifiers include: <ISIN, ORBIS_ID>'}
                </div>
                <br />
                <a href={DownloadTemplate} download="ESG portfolio import.csv">
                  <PrimaryLink
                    component="span"
                    id="upload-link-test-id"
                    linkText="Download Template"
                    linkClass="linkInline"
                  />
                  <br />
                  <br />
                </a>
                {filesContent[0] && fileToUpload && errors.length === 0 && (
                  <span style={boxStyle}>
                    {filesContent[0] &&
                      fileToUpload &&
                      JSON.stringify(filesContent[0].name)}
                    <br />
                    {filesContent[0] && fileToUpload && (
                      <PrimaryLink
                        component="span"
                        id="upload-link-test-id"
                        linkText="Remove"
                        linkClass="linkInline"
                        onClick={removeFile}
                      />
                    )}
                    {false && (
                      <input
                        id="upload-hidden-input-test-id"
                        name={JSON.stringify(filesContent[0].name)}
                      ></input>
                    )}
                  </span>
                )}
                {!filesContent[0] && (
                  <span style={boxStyleWhite}>
                    {filesContent[0] &&
                      fileToUpload &&
                      JSON.stringify(filesContent[0].name)}
                    <br />
                    {filesContent[0] && fileToUpload && (
                      <PrimaryLink
                        component="span"
                        id="upload-link-test-id"
                        linkText="Remove"
                        linkClass="linkInline"
                        onClick={removeFile}
                      />
                    )}
                    <PrimaryButton
                      id="upload-button-browsefile-test-id"
                      buttonWidth="90%"
                      buttonText="Browse File"
                      buttonClass="buttonGhost"
                      onClick={onBrowseFile}
                    />
                  </span>
                )}
              </span>
            }
            modalActionComponent={
              <PrimaryButton
                id="upload-button-upload-test-id"
                buttonDisabled={
                  buttonRef?.current ||
                  (filesContent[0] && userClicked ? false : true)
                }
                buttonClass="buttonPrimary"
                buttonText="Upload"
                buttonWidth="100%"
                onClick={uploadFile}
              />
            }
          />
          <PrimaryPopover
            alertOpen={popoverOpen}
            alertError={false}
            onAlertClose={onPopoverClose}
            id="upload-popover-success-test-id"
            marginLeft="60px"
            alertWidth="294px"
            alertText={`${capitalize(uploadType)} Upload Successfully Saved`}
            alertPosition="center"
            alertContentComponent={
              <Container style={{ padding: '0px', display: 'flex' }}>
                <PrimaryInput
                  autoCompleteOff={true}
                  id="upload-input-test-id-success"
                  inputDefaultValue={responseData}
                  inputClass="lightWeightInput"
                  inputError={displayInputError}
                  inputWidth="70%"
                  onChange={onPortfolioNameChange}
                  helperText="Portfolio name is too long."
                />
                {inputEntry && (
                  <PrimaryButton
                    id="upload-button-test-id-1"
                    buttonText="Save"
                    onClick={onSavePortfolioName}
                    buttonDisabled={
                      !newPortfolioName.trim() ||
                      newPortfolioName === '' ||
                      newPortfolioName.length > portfolioNameLimit
                        ? true
                        : false
                    }
                    buttonClass="buttonGhost"
                    alertPosition="center"
                    buttonMarginLeft="10px"
                  />
                )}
              </Container>
            }
          />
          <Backdrop
            style={backdrop}
            open={
              notFound &&
              notFound.length !== 0 &&
              duplicates &&
              duplicates.length !== 0
                ? popoverErrorOpen || popoverErrorOpenDuplicates
                : notFound && notFound.length !== 0
                ? popoverErrorOpen
                : popoverErrorOpenDuplicates
            }
          >
            {notFound && notFound.length !== 0 && (
              <PrimaryPopover
                id="upload-popover-unidentified-error-test-id"
                hideBackdrop={true}
                alertError={true}
                notFound={notFound}
                alertWidth={320}
                alertHeight="20%"
                alertText={
                  <p>
                    {countDetail +
                      ' unique identifiers matched; the system was unable to match ' +
                      (notFound ? notFound.length : '-') +
                      ` ${identifierText} for: `}
                    <br />
                    <span className="ellipsis" title={responseDataError}>
                      {responseDataError}
                    </span>
                  </p>
                }
                alertOpen={popoverErrorOpen}
                onAlertClose={onPopoverErrorClose}
                alertPosition="center"
                alertContentComponent={
                  <div style={divStyleError}>
                    <span style={boxStyleError}>
                      {notFound && notFound.length !== 0 && (
                        <span>
                          {' '}
                          <b> Unmatched {identifierText}: </b> <br />{' '}
                        </span>
                      )}
                      {notFound}
                    </span>
                  </div>
                }
              />
            )}
            {duplicates && duplicates.length !== 0 && (
              <PrimaryPopover
                id="upload-popover-duplicate-mapping-error-test-id"
                duplicates={duplicates}
                hideBackdrop={true}
                alertError={true}
                alertHeight="20%"
                alertTop={
                  notFound && notFound.length !== 0
                    ? notFound.length > 14
                      ? '380px'
                      : notFound.length * 15 + 180
                    : null
                }
                alertText={
                  'The following identifiers are matched to the same entity.'
                }
                alertWidth={320}
                alertOpen={popoverErrorOpenDuplicates}
                onAlertClose={onPopoverErrorDuplicateClose}
                alertPosition="center"
                alertContentComponent={
                  <div style={divStyleError}>
                    <span style={boxStyleError}>{duplicates}</span>
                  </div>
                }
              />
            )}
          </Backdrop>
          <PrimaryPopover
            id="upload-popover-error-upload-test-id"
            uploadError={true}
            alertError={true}
            alertHeight="20%"
            alertText={alertTextValidation}
            alertOpen={checkUploadDocumentDialogOpen}
            onAlertClose={onPopoverUploadErrorClose}
            alertPosition="center"
          />
        </div>
      )}
    </Container>
  )
}

export default UploadView
