import { PAGE_CON, REDUCER_TYPE } from '../../constant/constants'

const SourceDocument = {
  list: []
}

export function IssuerSourceDocumentInitialState() {
  let obj = {}
  obj.isLoading = false
  obj.actionPerformedOn = ''
  obj.actionData = PAGE_CON.INITIAL_DATA
  obj.dataStatus = ''
  obj.issuerSourceDocument = Object.assign({}, { ...SourceDocument })
  return obj
}

const IssuerSourceDocumentReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.LOAD_DATA_START:
      currentState.isLoading = true
      currentState.dataStatus = PAGE_CON.LOADING
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_END:
      currentState.isLoading = false
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_COMPLETED:
      action.SourceDocItems &&
        Object.keys(action.SourceDocItems).map((key) => {
          return (currentState.issuerSourceDocument[key] =
            action.SourceDocItems[key])
        })
      currentState.dataStatus = PAGE_CON.SUCCESS
      currentState.isLoading = false
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_ERROR:
      currentState.isLoading = false
      currentState.dataStatus = PAGE_CON.ERROR
      return { ...currentState }
    case REDUCER_TYPE.RESET_LOAD_DATA_ERROR:
      currentState.dataStatus = ''
      return { ...currentState }
    case REDUCER_TYPE.RESET:
      return IssuerSourceDocumentInitialState()
    default:
      return currentState
  }
}

export default IssuerSourceDocumentReducer
