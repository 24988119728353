import { Container, Grid } from '@material-ui/core'
import axios from 'axios'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import ReportingType from './ReportingType'
import SfdrPortfolios from './reportingPortfolios/SfdrPortfolios'
import {
  PAGE_CON,
  REDUCER_STATE,
  REDUCER_TYPE,
  REG_REPORTING_TYPE
} from 'constant/constants'
import Upload from 'views/upload/UploadComponent'
import { GlobalContext } from 'context/GlobalStateProvider'
import BackdropComp from 'views/globalComponent/BackdropComp'
import ReportingOptions from './ReportingOptions'
import { IconError, Page, PrimaryLink, PrimaryPopover } from 'components'
import LoadingMask from 'utils/loadingMask'
import { ReportingServices, SMEOnDemand } from 'services'
import Error from 'utils/error'
import { Backdrop } from '@material-ui/core'
import themeColorPalette from 'theme/theme'
import { reportTimeStamp } from 'utils/Date'
import EuTaxonomyPortfolios from './reportingPortfolios/EuTaxonomyPortfolios'
import OnDemandPortfolios from './reportingPortfolios/OnDemandPortfolios'
import {
  annual,
  defaultReportOptions,
  defaultReportTypeChecked,
  defaultReportTypes,
  interim,
  latest,
  onUpload
} from './ReportingServiceHelper'
import { useStyles } from './styles'
import ReportingActions from './ReportingActions'
import ReportingRequestAssessment from './ReportingRequestAssessment'
import {
  requestAssessment,
  viewAssessmentStatus
} from './ReportingServiceHelper'

const ReportingService = ({ entitlements }) => {
  const classes = useStyles()
  const [filterState, filterDispatch] = useContext(GlobalContext).globalFilter
  const [reportingServiceState, reportingServiceDispatch] =
    useContext(GlobalContext).globalReportingService
  const [, setOnDemandAsmntDispatch] =
    useContext(GlobalContext).onDemandAsmntService

  let apiCallId = reportingServiceState.apiCallId

  const { sfdr, euTaxonomy, onDemandAsmnt } = REG_REPORTING_TYPE

  const isExportEntitled = entitlements.export || false
  const isSFDREntitled = entitlements['ReportingService']['sfdr'] || false
  const isEuTaxonomyEntitled =
    entitlements['ReportingService']['eutaxonomy'] || false
  const isODAEntitled =
    entitlements['ReportingService']['ondemandassessment'] || false

  const [popUpOpen, setPopUpOpen] = useState(false)
  const [portfolioList, setPortfolioList] = useState([])
  const [ODAPortfolioList, setODAPortfolioList] = useState([])
  const [isInitialized, setIsInitialized] = useState(false)
  const [isODAInitialized, setIsODAInitialized] = useState(false)
  const [disableReportingYear, setDisableReportingYear] = useState(false)

  const emptyPortfolios = portfolioList.length === 0

  const [reportTypes, setReportTypes] = React.useState(
    defaultReportTypes({
      selectedItem: defaultReportTypeChecked(entitlements),
      entitlements
    })
  )
  const [reportOptions, setReportOptions] = React.useState(
    defaultReportOptions(defaultReportTypeChecked(entitlements))
  )
  const [errorPopoverMessage, setErrorPopoverMessage] = React.useState(null)
  const interimChecked = reportOptions.find(({ id }) => id === interim)?.checked
  const annualChecked = reportOptions.find(({ id }) => id === annual)?.checked
  const latestChecked = reportOptions.find(({ id }) => id === latest)?.checked
  const sfdrChecked = reportTypes.find(({ id }) => id === sfdr)?.checked
  const euTaxonomyChecked = reportTypes.find(
    ({ id }) => id === euTaxonomy
  )?.checked
  const onDemandAsmntChecked = reportTypes.find(
    ({ id }) => id === onDemandAsmnt
  )?.checked

  const isSFDRorEUTaxonomyChecked = sfdrChecked || euTaxonomyChecked

  const reportCounts = annualChecked
    ? portfolioList.filter(({ checked }) => checked).length /
        portfolioList.filter(({ checked }) => checked).length || 0
    : portfolioList.filter(({ checked }) => checked).length

  const buttonDisabled = () => {
    let isButtonDisabled = false
    if (reportCounts === 0) return (isButtonDisabled = true)
    if (popUpOpen) return (isButtonDisabled = true)
    if (sfdrChecked && !(interimChecked || annualChecked))
      return (isButtonDisabled = true)
    if (!isSFDRorEUTaxonomyChecked) return (isButtonDisabled = true)
    if (reportingServiceState.generateReport.isLoading)
      return (isButtonDisabled = true)
    if (emptyPortfolios) return (isButtonDisabled = true)
    return isButtonDisabled
  }

  const selectedODAPorfolio = ODAPortfolioList.find(
    (portfolio) => portfolio.checked
  )

  const isRequestAsmntCTAEnabled =
    !reportingServiceState.onDemandPortfolios?.isLoading &&
    reportingServiceState.assessmentLimit.data?.remaining_assessments &&
    selectedODAPorfolio?.on_demand_coverage > 0

  const onCloseUpload = () => {
    filterDispatch({
      type: REDUCER_TYPE.UPLOAD_PORTFOLIO_MODAL,
      payload: false,
      uploadType: filterState.uploadType,
      uploadInitiatedFrom: filterState.uploadInitiatedFrom
    })
  }

  const onReportingTypeUpdate = (selectedItem) => {
    let selTypes = [...reportTypes]

    selTypes.forEach((x) => (x.checked = false))

    let selectType = selTypes.find((x) => x.id === selectedItem.id) || {}
    if (Object.keys(selectType).length > 0) {
      selectType.checked = selectedItem.checked
      setReportTypes(selTypes)
    }
    setDisableReportingYear(false)
    setPortfolioList(
      getPortfolioCompData(reportingServiceState.portfolios.data)
    )
  }

  const onReportingOptionChange = (selectedItem) => {
    if (sfdrChecked) reportingOptionChangeForsfdr(selectedItem)
    else if (euTaxonomyChecked) return
  }

  const reportingOptionChangeForsfdr = (selectedItem) => {
    let repOptions = [...reportOptions]
    let selectedOption = repOptions.find((x) => x.id === selectedItem.id) || {}
    if (Object.keys(selectedOption).length > 0) {
      selectedOption.checked = selectedItem.checked
      if (selectedItem.id === latest) {
        if (selectedItem.checked) {
          setDisableReportingYear(true)
          let annualOption = repOptions.find((x) => x.id === annual)
          annualOption.disabled = false
        } else {
          setDisableReportingYear(false)
          manageReportingOption(portfolioList)
        }
      } else if (
        selectedItem.checked &&
        (selectedItem.id === interim || selectedItem.id === annual)
      ) {
        let otherOption = repOptions
          .filter((x) => x.id === interim || x.id === annual)
          .filter((y) => y.id !== selectedItem.id)[0]
        otherOption.checked = false
      }
      setReportOptions(repOptions)
    }
  }

  const onReportingYearChange = (portfolioKey, value) => {
    if (portfolioKey) {
      const newSelectedPortfolios = [...portfolioList]
      let selectedPortfolio =
        newSelectedPortfolios.find((x) => x.key === portfolioKey) || {}
      if (Object.keys(selectedPortfolio).length > 0) {
        selectedPortfolio.reportingYear = value
        manageReportingOption(newSelectedPortfolios)
        setPortfolioList(newSelectedPortfolios)
      }
    }
  }

  const manageReportingOption = (portfolioItems) => {
    let firstSelectedYear = portfolioItems.filter((x) => x.checked === true)
    if ((firstSelectedYear?.length || 0) > 0) {
      let diffReportingYears = portfolioItems.filter(
        (x) =>
          x.reportingYear !== firstSelectedYear[0].reportingYear &&
          x.checked === true
      )
      if ((diffReportingYears?.length || 0) > 0) {
        enableDisableAnnual(true)
      } else {
        enableDisableAnnual(false)
      }
    }
  }

  const enableDisableAnnual = (value) => {
    let repOptions = [...reportOptions]
    let annualOption = repOptions.find((x) => x.id === annual)
    annualOption.disabled = value
    if (value === true) annualOption.checked = false
    setReportOptions(repOptions)
  }

  const onPortfolioSelection = (selectedItem, checkBox = false) => {
    const portfolios = onDemandAsmntChecked ? ODAPortfolioList : portfolioList
    if (selectedItem.id) {
      const newSelectedPortfolios = [...portfolios].map((item) => ({
        ...item,
        checked: checkBox ? false : item.checked
      }))
      let selectedPortfolio =
        newSelectedPortfolios.find((x) => x.key === selectedItem.id) || {}
      if (Object.keys(selectedPortfolio).length > 0) {
        selectedPortfolio.checked = selectedItem.checked
        if (newSelectedPortfolios.filter((x) => x.checked).length === 4) {
          newSelectedPortfolios.forEach((portfolio) => {
            if (portfolio.checked === false) {
              portfolio.disabled = true
            }
          })
        } else if (newSelectedPortfolios.filter((x) => x.checked).length < 4) {
          newSelectedPortfolios.forEach((portfolio) => {
            if (portfolio.checked === false) {
              portfolio.disabled = false
            }
          })
        }

        if (!reportOptions.find((x) => x.id === latest)?.checked)
          manageReportingOption(newSelectedPortfolios)

        if (onDemandAsmntChecked) {
          setODAPortfolioList(newSelectedPortfolios)
        } else {
          setPortfolioList(newSelectedPortfolios)
        }
      }
    }
  }

  const onEUTaxonomyTextChange = (portfolioId, changedItem, val) => {
    if (portfolioId) {
      const newSelectedPortfolios = [...portfolioList]
      let selectedPortfolio =
        newSelectedPortfolios.find((x) => x.key === portfolioId) || {}
      if (Object.keys(selectedPortfolio).length > 0) {
        selectedPortfolio[changedItem] = val
        setPortfolioList(newSelectedPortfolios)
      }
    }
  }

  const getPortfolioCompData = (data) =>
    data.map((value) => {
      const {
        portfolio_id,
        portfolio_name,
        upload_date_utc,
        reporting_years,
        sfdr_coverage,
        coverage,
        on_demand_coverage,
        taxonomy_coverage,
        assessment_requested,
        currency
      } = value
      return {
        key: portfolio_id,
        portfolioName: portfolio_name,
        date: upload_date_utc,
        checked: false,
        disabled: false,
        reportingYear: reporting_years?.[0] || PAGE_CON.NOT_APPLICABLE,
        sfdr_coverage:
          sfdr_coverage !== undefined ? sfdr_coverage : PAGE_CON.NOT_APPLICABLE,
        coverage: coverage !== undefined ? coverage : PAGE_CON.NOT_APPLICABLE,
        assessment_requested: assessment_requested || false,
        on_demand_coverage:
          on_demand_coverage !== undefined
            ? on_demand_coverage
            : PAGE_CON.NOT_APPLICABLE,
        taxonomy_coverage:
          taxonomy_coverage !== undefined
            ? taxonomy_coverage
            : PAGE_CON.NOT_APPLICABLE,
        allReportingYears: reporting_years || [],
        [PAGE_CON.NON_SOVEREIGN_DERIVATIVES]: value[
          PAGE_CON.NON_SOVEREIGN_DERIVATIVES
        ]
          ? value[PAGE_CON.NON_SOVEREIGN_DERIVATIVES].toString()
          : '0',
        [PAGE_CON.CASH_AND_LIQUIDITIES]: value[PAGE_CON.CASH_AND_LIQUIDITIES]
          ? value[PAGE_CON.CASH_AND_LIQUIDITIES].toString()
          : '0',
        currency
      }
    })

  const getPortfolios = async (isErrorReload = false) => {
    const isSFDROrEUTaxonomy = isSFDREntitled || isEuTaxonomyEntitled

    if (!isSFDROrEUTaxonomy) {
      reportingServiceDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.REPORTING_PORTFOLIO,
        data: [],
        apiCallId
      })
      setIsInitialized(true)
      return
    }

    if (
      reportingServiceState.portfolios.data.length > 0 ||
      (reportingServiceState.portfolios.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    reportingServiceDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.REPORTING_PORTFOLIO
    })

    let portfolios = []

    try {
      const data = await ReportingServices.getReportingPortfolios(
        reportingServiceState.cancelToken.token
      )
      portfolios = data
      setPortfolioList(getPortfolioCompData(portfolios))
      reportingServiceDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.REPORTING_PORTFOLIO,
        data: portfolios,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        reportingServiceDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.REPORTING_PORTFOLIO,
          apiCallId
        })
      } else console.log(err)
    }

    setIsInitialized(true)
  }

  const getOnDemandPortfolios = async (isErrorReload = false) => {
    if (!isODAEntitled) {
      reportingServiceDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.REPORTING_ODA_PORTFOLIO,
        data: [],
        apiCallId
      })
      setIsODAInitialized(true)
      return
    }

    if (
      reportingServiceState.onDemandPortfolios.dataStatus !== '' &&
      isErrorReload === false
    )
      return

    reportingServiceDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.REPORTING_ODA_PORTFOLIO
    })

    let portfolios = []

    try {
      const data = await SMEOnDemand.getOnDemandPortfolios(
        reportingServiceState.cancelToken.token
      )

      portfolios = data
      setODAPortfolioList(getPortfolioCompData(portfolios))
      reportingServiceDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.REPORTING_ODA_PORTFOLIO,
        data: portfolios,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        reportingServiceDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.REPORTING_ODA_PORTFOLIO,
          apiCallId
        })
      } else console.log(err)
    }
    setIsODAInitialized(true)
  }

  const getOnDemandAsmntLimit = async (isErrorReload = false) => {
    if (!isODAEntitled) {
      reportingServiceDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.ON_DEMAND_ASMNT_LIMIT,
        data: {}
      })
      setIsODAInitialized(true)
      return
    }
    if (
      reportingServiceState.assessmentLimit?.dataStatus !== '' &&
      isErrorReload === false
    )
      return

    reportingServiceDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.ON_DEMAND_ASMNT_LIMIT
    })

    try {
      const data = await SMEOnDemand.getOnDemandAsmntLimit({
        cancelToken: reportingServiceState.cancelToken.token
      })

      reportingServiceDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.ON_DEMAND_ASMNT_LIMIT,
        data: data
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        reportingServiceDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.ON_DEMAND_ASMNT_LIMIT
        })
      } else console.log(err)
    }
  }

  const generateReport = async () => {
    reportingServiceDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.REPORTING_PORTFOLIO_GENERATE
    })

    const regulation = reportTypes.find(({ checked }) => checked)?.id || ''
    let payload = {}

    if (regulation === sfdr) payload = sfdrPayload()
    else if (regulation === euTaxonomy) payload = euTaxonomyPayload()

    try {
      const data = await ReportingServices.generateReport({
        ...payload,
        cancelToken: reportingServiceState.cancelToken.token
      })

      if (data.request_id && regulation === euTaxonomy) {
        const portfolios = portfolioList
          .filter(({ checked }) => checked)
          .map((item) => {
            return {
              portfolio_id: item.key,
              [PAGE_CON.NON_SOVEREIGN_DERIVATIVES]:
                item[PAGE_CON.NON_SOVEREIGN_DERIVATIVES] === ''
                  ? 0
                  : item[PAGE_CON.NON_SOVEREIGN_DERIVATIVES],
              [PAGE_CON.CASH_AND_LIQUIDITIES]:
                item[PAGE_CON.CASH_AND_LIQUIDITIES] === ''
                  ? 0
                  : item[PAGE_CON.CASH_AND_LIQUIDITIES]
            }
          })

        const updateStatus = await ReportingServices.updatePortfolios({
          portfolios
        })

        if (updateStatus === false)
          console.log('unable to save portfolio(s) details')
        else if (updateStatus === true)
          reportingServiceDispatch({
            type: REDUCER_TYPE.UPDATE_REPORTING_PORTFOLIOS,
            portfolios: portfolioList
          })
      }

      reportingServiceDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.REPORTING_PORTFOLIO_GENERATE,
        data: data,
        apiCallId
      })

      return data
    } catch (err) {
      if (!axios.isCancel(err)) {
        reportingServiceDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.REPORTING_PORTFOLIO_GENERATE,
          apiCallId
        })
      } else console.log(err)
    }
  }

  const sfdrPayload = () => {
    const regulation = sfdr
    const report_type = {
      ...(interimChecked ? { [`${interimChecked}`]: interim } : {}),
      ...(annualChecked ? { [`${annualChecked}`]: annual } : {})
    }['true']
    const use_latest_data = latestChecked ? '1' : '0'
    const portfolios = portfolioList
      .filter(({ checked }) => checked)
      .map(({ key, reportingYear }, index) => {
        const fileName = `${regulation.toUpperCase()}_${index}_${reportTimeStamp()}`
        return {
          portfolio_id: key,
          year: !annualChecked ? reportingYear : undefined,
          file_name: !annualChecked ? fileName : undefined
        }
      })

    const report_name = `${regulation.toUpperCase()}_${
      annualChecked ? 'Annual' : 'Interim'
    }_${reportTimeStamp(true)}`

    const payload = {
      report_name,
      regulation,
      report_type,
      use_latest_data,
      portfolios
    }

    if (annualChecked) {
      payload.reporting_year = portfolioList.find(
        ({ checked }) => checked
      ).reportingYear
    }

    return payload
  }

  const euTaxonomyPayload = () => {
    const regulation = euTaxonomy
    const use_latest_data = '1'
    const portfolios = portfolioList
      .filter(({ checked }) => checked)
      .map((item, index) => {
        const fileName = `${regulation.toUpperCase()}_${index}_${reportTimeStamp()}`
        return {
          portfolio_id: item.key,
          year: undefined,
          file_name: item.checked ? fileName : undefined,
          [PAGE_CON.NON_SOVEREIGN_DERIVATIVES]:
            item[PAGE_CON.NON_SOVEREIGN_DERIVATIVES] === ''
              ? 0
              : item[PAGE_CON.NON_SOVEREIGN_DERIVATIVES],
          [PAGE_CON.CASH_AND_LIQUIDITIES]:
            item[PAGE_CON.CASH_AND_LIQUIDITIES] === ''
              ? 0
              : item[PAGE_CON.CASH_AND_LIQUIDITIES],
          selected_for_reporting: item.checked,
          currency: item.currency
        }
      })

    const report_name = `${regulation.toUpperCase()}_${reportTimeStamp(true)}`

    const payload = {
      report_name,
      regulation,
      report_type: annual,
      use_latest_data,
      portfolios
    }

    return payload
  }

  const getODALimit = useCallback(async () => {
    if (reportingServiceState.assessmentLimit.dataStatus === '') {
      getOnDemandAsmntLimit()
    }
  }, [reportingServiceState.assessmentLimit.dataStatus])

  const getReportingData = useCallback(async () => {
    if (reportingServiceState.portfolios.dataStatus === '') {
      getPortfolios()
    }
  }, [reportingServiceState.portfolios.dataStatus])

  const loadReportTypes = () => {
    setReportTypes(
      defaultReportTypes({
        selectedItem: defaultReportTypeChecked(entitlements),
        entitlements
      })
    )

    const isSFDRorEUTaxonmy =
      !isInitialized &&
      portfolioList.length === 0 &&
      (isSFDREntitled || isEuTaxonomyEntitled)
    if (isSFDRorEUTaxonmy) {
      setReportOptions(
        defaultReportOptions(
          defaultReportTypeChecked(entitlements),
          reportingServiceState.portfolios.data.length === 0
        )
      )
      setPortfolioList(
        getPortfolioCompData(reportingServiceState.portfolios.data)
      )
      setIsInitialized(true)
    }

    const isODA =
      !isODAInitialized && ODAPortfolioList.length === 0 && isODAEntitled

    if (isODA) {
      setODAPortfolioList(
        getPortfolioCompData(reportingServiceState.onDemandPortfolios.data)
      )
      setIsODAInitialized(true)
    }
  }

  const setReportingOptions = () => {
    const selectedReportType =
      reportTypes.find((x) => x.checked)?.id ||
      defaultReportTypeChecked(entitlements)

    setReportOptions(
      defaultReportOptions(
        selectedReportType,
        reportingServiceState.portfolios.data.length === 0
      )
    )
  }

  useEffect(() => {
    setReportingOptions()
  }, [reportTypes, reportingServiceState.portfolios.dataStatus])

  useEffect(() => {
    if (reportingServiceState.assessmentLimit.dataStatus === '') {
      getODALimit()
    }
    if (reportingServiceState.onDemandPortfolios.dataStatus === '') {
      getOnDemandPortfolios()
    }
  }, [
    reportingServiceState.assessmentLimit.dataStatus,
    reportingServiceState.onDemandPortfolios.dataStatus
  ])

  useEffect(() => {
    if (reportingServiceState.portfolios.dataStatus === '') {
      getReportingData()
    }
  }, [reportingServiceState.portfolios.dataStatus])

  useEffect(() => {
    loadReportTypes()
  }, [isInitialized])

  const reloadComponent = (errorOccuredOn) => {
    switch (errorOccuredOn) {
      case PAGE_CON.PORTFOLIO_LIST:
        getPortfolios(true)
        break
      case PAGE_CON.ON_DEMAND_ASMNT_LIMIT:
        getOnDemandAsmntLimit(true)
        break
      case PAGE_CON.ODA_PORTFOLIO_LIST:
        getOnDemandPortfolios(true)
        break
      default:
        break
    }
  }

  const showErrorPopover = (message) => {
    if (message) {
      setErrorPopoverMessage(message)
    }
    setTimeout(() => {
      setErrorPopoverMessage(null)
    }, 4000)
  }

  const openReportDownload = async () => {
    const newWindow = window.open('/reportingservice/status/waiting', '_blank')
    if (newWindow) {
      const data = await generateReport()
      if (data?.request_id && data?.status === 'new') {
        newWindow.location.href = `/reportingservice/status/${data.request_id}`
        setPopUpOpen(false)
      } else {
        if (data?.status === 'pending') {
          showErrorPopover('Report generating. Try again later.')
        } else {
          showErrorPopover(
            'Export failed because of an issue, please try again later.'
          )
        }
        newWindow.close()
      }
    }
  }

  const openReportPreviously = () => {
    window.open('/reportingservice/history', '_blank')
  }

  const backdrop = {
    zIndex: 1201,
    color: '#fff'
  }

  useEffect(() => {
    if (reportingServiceState.generateReport.dataStatus === PAGE_CON.ERROR) {
      showErrorPopover(
        'Export failed because of an issue, please try again later.'
      )
      reportingServiceDispatch({ type: REDUCER_TYPE.RESET_GENERATE_REPORT })
    }
  }, [reportingServiceState.generateReport.dataStatus])

  const showUploadLink = () => {
    if (onDemandAsmntChecked) {
      return reportingServiceState.onDemandPortfolios.dataStatus ===
        PAGE_CON.ERROR || reportingServiceState.onDemandPortfolios.isLoading
        ? false
        : ODAPortfolioList.length
    } else {
      return (
        !reportingServiceState.portfolios.isLoading &&
        reportingServiceState.portfolios.dataStatus !== PAGE_CON.ERROR
      )
    }
  }

  const onRequestAssessment = () => {
    requestAssessment(setOnDemandAsmntDispatch, selectedODAPorfolio)
  }

  const onViewAssessmentStatus = () => {
    viewAssessmentStatus(setOnDemandAsmntDispatch, selectedODAPorfolio)
  }

  return (
    <Page className={classes.root}>
      <Upload
        modalOpen={filterState.isUploadOpen}
        uploadType={filterState.uploadType}
        onClose={onCloseUpload}
      >
        {' '}
      </Upload>
      <BackdropComp />
      <main className={classes.content}>
        <Container className={classes.container} maxWidth={false}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={6} sm={3} md={2}>
              <ReportingType
                ReportTypes={reportTypes}
                onReportingTypeUpdate={onReportingTypeUpdate}
                entitlements={entitlements}
              />
            </Grid>
            <Grid item xs={12} sm={10} md={7} style={{ minWidth: '700px' }}>
              {isSFDRorEUTaxonomyChecked ? (
                !isInitialized || reportingServiceState.portfolios.isLoading ? (
                  <LoadingMask />
                ) : reportingServiceState.portfolios.dataStatus ===
                  PAGE_CON.ERROR ? (
                  <Error
                    id={'reportingError'}
                    dataStatus={PAGE_CON.ERROR}
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.PORTFOLIO_LIST}
                  />
                ) : sfdrChecked ? (
                  <SfdrPortfolios
                    portfolios={portfolioList}
                    onReportingYearChange={onReportingYearChange}
                    onPortfolioSelection={onPortfolioSelection}
                    disableReportingYear={disableReportingYear}
                  />
                ) : (
                  euTaxonomyChecked && (
                    <EuTaxonomyPortfolios
                      portfolios={portfolioList}
                      onPortfolioSelection={onPortfolioSelection}
                      onTextChange={onEUTaxonomyTextChange}
                    />
                  )
                )
              ) : (
                ''
              )}
              {onDemandAsmntChecked ? (
                !isODAInitialized ||
                reportingServiceState.onDemandPortfolios.isLoading ? (
                  <LoadingMask />
                ) : reportingServiceState.onDemandPortfolios.dataStatus ===
                  PAGE_CON.ERROR ? (
                  <Error
                    id={'reportingODAError'}
                    dataStatus={PAGE_CON.ERROR}
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.ODA_PORTFOLIO_LIST}
                  />
                ) : (
                  <OnDemandPortfolios
                    portfolios={ODAPortfolioList}
                    onPortfolioSelection={onPortfolioSelection}
                    isExportEntitled={isExportEntitled}
                  />
                )
              ) : (
                ''
              )}
              {showUploadLink() ? (
                <PrimaryLink
                  id="link-upload"
                  key="link-upload"
                  linkSelected={true}
                  linkText="Upload Another Portfolio"
                  linkClass="portfolioUploadLink"
                  onClick={() =>
                    onUpload(PAGE_CON.FILTER_PORTFOLIO, filterDispatch)
                  }
                />
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={6} sm={2} md={3}>
              <Grid
                container
                direction="column"
                className={onDemandAsmntChecked ? classes.ActionGrid : null}
              >
                <Grid item xs={12}>
                  <ReportingActions
                    onReportingOptionChange={onReportingOptionChange}
                    ReportOptions={!onDemandAsmntChecked ? reportOptions : []}
                  >
                    {onDemandAsmntChecked ? (
                      <ReportingRequestAssessment
                        disableRequestAssessment={!isRequestAsmntCTAEnabled}
                        disableViewAssessmentStatus={
                          !selectedODAPorfolio?.assessment_requested
                        }
                        reloadComponent={reloadComponent}
                        onRequestAssessment={onRequestAssessment}
                        onViewAssessmentStatus={onViewAssessmentStatus}
                        remainingAssessments={
                          reportingServiceState.assessmentLimit?.data
                            ?.remaining_assessments || 0
                        }
                        portfolio={selectedODAPorfolio}
                      />
                    ) : (
                      <ReportingOptions
                        openReportDownload={openReportDownload}
                        buttonDisabled={buttonDisabled()}
                        openReportPreviously={openReportPreviously}
                        reportCounts={reportCounts}
                      />
                    )}
                  </ReportingActions>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>

      <PrimaryPopover
        alertOpen={errorPopoverMessage !== null}
        alertIcon={
          <IconError
            colorprimary={themeColorPalette.palette.sys_trad.text.main}
          />
        }
        alertText={<span>{errorPopoverMessage}</span>}
        alertPosition="center"
        alertWidth={'190px'}
        alertTop="55px"
        alertTextTopPadding={'1px'}
        paddingTop="4px"
        paddingBottom="4px"
        paddingLeft="8px"
        paddingRight="8px"
        overflowY="hidden"
        hideBackdrop={true}
        hideClose={true}
        popoverShadow={false}
        popoverBgColor={themeColorPalette.palette.sys_trad.warning.main}
      />

      {reportingServiceState.generateReport.isLoading && (
        <Backdrop
          style={backdrop}
          open={reportingServiceState.generateReport.isLoading}
        >
          <LoadingMask loadText="Loading" />
        </Backdrop>
      )}
    </Page>
  )
}

export default ReportingService
