import { makeStyles } from '@material-ui/core/styles'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'

const headerRowHeight = 24

export const cellRowHeight = 16
export const bgColor = themeColorPalette.palette.sys_trad.bg.main

export const useStyles = makeStyles({
  table: {
    maxWidth: (props) => props.maxWidth,
    cursor: 'default',
    marginTop: (props) => props.tableMarginTop
  },

  headerCell: {
    borderTop: '4px solid ' + themeColorPalette.palette.white.main,
    borderBottom: '4px solid ' + themeColorPalette.palette.white.main,
    borderLeft: '4px solid ' + themeColorPalette.palette.white.main,
    borderRight: '4px solid ' + themeColorPalette.palette.white.main,
    ...textTheme.typography.p2,
    color: textTheme.palette.sys_trad.text2.main,
    padding: (props) =>
      props.ESCScore ? '9px 0px 0px 3px' : props.ESGAssessment ? 0 : '0px 4px',
    backgroundColor: (props) =>
      props.headerBgColor ? props.headerBgColor : bgColor,
    minHeight: headerRowHeight
  },
  rowCell: {
    borderTop: '4px solid ' + themeColorPalette.palette.white.main,
    borderRight: '4px solid ' + themeColorPalette.palette.white.main,
    borderBottom: '4px solid ' + themeColorPalette.palette.white.main,
    borderLeft: (props) =>
      props.ESGAssessment
        ? 0
        : '4px solid ' + themeColorPalette.palette.white.main,
    ...textTheme.typography.p3,
    color: textTheme.palette.sys_trad.text.main,
    padding: '0px 4px',
    backgroundColor: bgColor,
    minHeight: cellRowHeight
  },
  emptyTableCell: {
    padding: '0px',
    borderBottom: 'none'
  }
})
