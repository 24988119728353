import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styles'
import LoadingMask from 'utils/loadingMask'

const Table = ({
  headers,
  data,
  footers,
  handleSort,
  isLoading,
  tablestyles,
  maxheight,
  custBorderTop = true,
  id,
  isPerformanceTable,
  showHeaders = true,
  isHighlighted,
  isStickyHeader
}) => {
  const sortByColumn = (column) => {
    handleSort(column)
  }

  const cleanContent = (text) => {
    const type = typeof text
    if (type === 'string' || type === 'number') {
      return text
    } else {
      const childText = text?.props?.children || text?.props?.label || ''
      const childTextType = typeof childText
      if (childTextType === 'string' || childTextType === 'number') {
        return childText
      } else {
        return childText?.props?.text || ''
      }
    }
  }

  return (
    <S.TableContainer maxheight={maxheight}>
      {isLoading ? (
        <LoadingMask />
      ) : (
        <S.Table tablestyles={tablestyles} id={id}>
          {showHeaders && (
            <S.TableHeader
              withborder={custBorderTop === true ? 'true' : ''}
              sticky_header={isStickyHeader === true ? 'true' : ''}
            >
              <S.TableRow>
                {headers?.map((header, key) => (
                  <S.TableCell
                    width={header.width}
                    key={`${id}-tableHeader-${key}`}
                    onClick={() => header.sortable && sortByColumn(header.key)}
                    className={header.sortable ? 'clickable' : ''}
                    style={header.columnStyles}
                    {...(isPerformanceTable
                      ? {
                          heap_id: 'perfchart',
                          heap_perfchart_id: 'columnsort'
                        }
                      : {})}
                  >
                    {header.label}
                  </S.TableCell>
                ))}
              </S.TableRow>
            </S.TableHeader>
          )}
          <S.TableBody>
            {data?.map((row, rowKey) => (
              <S.TableRow
                key={`${id}-tableRow${rowKey}`}
                className={isHighlighted?.[rowKey] ? 'predicted-score' : null}
              >
                {row?.map((cell, cellKey) => (
                  <S.TableCell
                    id={`${id}-tableCell-${rowKey}-${cellKey}`}
                    key={`${id}-tableCell-${rowKey}-${cellKey}`}
                    style={headers[cellKey].columnStyles}
                    title={cleanContent(cell)}
                    className="cell"
                    {...(isPerformanceTable && cellKey === 0
                      ? {
                          heap_id: 'perfchart',
                          heap_entity: cleanContent(cell)
                        }
                      : {})}
                  >
                    {cell}
                  </S.TableCell>
                ))}
              </S.TableRow>
            ))}
          </S.TableBody>
          {footers && (
            <S.TableFooter>
              <S.TableRow>
                {footers?.map((footer, key) => (
                  <S.TableCell
                    key={`${id}-tableFooter-${key}`}
                    style={headers[key].columnStyles}
                  >
                    {footer}
                  </S.TableCell>
                ))}
              </S.TableRow>
            </S.TableFooter>
          )}
        </S.Table>
      )}
    </S.TableContainer>
  )
}

Table.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array,
  footers: PropTypes.array,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  tableStyles: PropTypes.object,
  maxHeight: PropTypes.number,
  custBorderTop: PropTypes.bool,
  id: PropTypes.string,
  showHeaders: PropTypes.bool
}

export default Table
