import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  pageHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    margin: 0,
    boxShadow: 'none'
  },
  toolbarStyle: {
    marginLeft: '0px',
    marginTop: '16px',
    minHeight: 'auto'
  },
  uploadGridStyle: {
    paddingTop: 10,
    paddingBottom: 8,
    width: 350
  },
  notFound: {
    ...textTheme.typography.p3,
    fontWeight: '500',
    lineHeight: '16px',
    color: themeColorPalette.palette.sys_trad.texthelp.main,
    textAlign: 'center'
  },
  itemStyle: {
    marginTop: '120px'
  },
  paddingStyle: {
    marginBottom: '16px'
  },
  highlight: {
    backgroundColor: themeColorPalette.palette.sys_trad.highlight.main
  },
  gridItem: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 0,
    paddingLeft: 0,
    width: 402
  },
  gridHeaderItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
    width: 402
  },
  headerContainer: {
    marginTop: 30
  },
  border: {
    marginTop: 8,
    borderBottom: `1px solid ${themeColorPalette.palette.sys_trad.border.main}`,
    marginBottom: 2
  },
  parentContainer: {
    marginTop: 0,
    margin: 24
  },
  headerText: {
    ...textTheme.typography.h2,
    fontSize: '16px',
    lineHeight: '19.2px',
    minWidth: '372px',
    width: '372px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: '10px'
  },
  uploadStyle: {
    paddingTop: 4,
    paddingBottom: 4
  },
  shadowItem: {
    marginTop: 16,
    marginBottom: 20,
    padding: 12,
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.14)',
    boxSizing: 'border-box',
    borderRadius: '2px',
    border: `1px solid${themeColorPalette.palette.white.main}`
  },
  sectionOneNormalStyle: {
    ...textTheme.typography.p3,
    lineHeight: '14.4px',
    color: themeColorPalette.palette.sys_trad.text.main
  },
  sectionOneBoldStyle: {
    ...textTheme.typography.p2,
    lineHeight: '12px',
    color: themeColorPalette.palette.sys_trad.text.main
  },
  sectionTwoNormalStyle: {
    ...textTheme.typography.p3,
    lineHeight: '14.4px',
    color: themeColorPalette.palette.sys_trad.text2.main,
    marginBottom: 2
  },
  sectionTwoColorStyle: {
    color: themeColorPalette.palette.sys_trad.error.main,
    paddingLeft: '1px',
    width: '3.93px',
    height: '5px'
  },
  footerContainer: {
    direction: 'column',
    backgroundColor: themeColorPalette.palette.sys_trad.white.main,
    position: 'fixed',
    bottom: 0,
    width: '402px',
    paddingTop: '16px',
    paddingBottom: '16px'
  },
  footerBorder: {
    borderTop: `1px solid ${themeColorPalette.palette.sys_trad.border.main}`,
    marginBottom: 16
  },
  deleteStyle: {
    display: 'flex',
    float: 'right'
  },
  showMoreText: {
    ...textTheme.typography.p3,
    display: 'inline-block',
    cursor: 'pointer',
    margin: '14px 0 0 4px',
    textDecoration: 'underline'
  },
  retry: {
    paddingLeft: '0.5em',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  deleteModalButtonsStyle: {
    display: 'flex',
    float: 'right',
    marginTop: 16,
    marginBottom: 15
  },
  titleStyle: {
    ...textTheme.typography.h1,
    color: themeColorPalette.palette.sys_trad.text.main,
    backgroundColor: themeColorPalette.palette.sys_trad.white,
    marginTop: '-8px',
    marginBottom: '0px',
    padding: '0px 24px 12px 0px'
  }
}))
