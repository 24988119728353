import { Box, Container } from '@material-ui/core'
import clsx from 'clsx'
import { PAGE_CON } from 'constant/constants'
import PropTypes from 'prop-types'
import useStyles from './styles'

const ESGScore = ({
  data,
  overallGradeColor,
  gradeColor,
  horizontalBar,
  selectedScoreBox,
  selectedScoreColor,
  scoreBoxWidth,
  width,
  selectedScoreBoxWidth,
  fontColor,
  height,
  id
}) => {
  const classes = useStyles({
    data,
    overallGradeColor,
    gradeColor,
    selectedScoreColor,
    fontColor,
    scoreBoxWidth,
    width,
    selectedScoreBoxWidth
  })

  let selected_score_range = data?.score?.split('.')[0]
  let selected_score_qualifier = data?.qualifier
  let grades = data?.grades
    ? data.grades
    : ['a1', 'a2', 'a3', 'b1', 'b2', 'b3', 'c1', 'c2', 'c3', 'e']

  const isSelectedQualifierMetric = (horizontalBarMessage) => {
    return horizontalBarMessage === selected_score_qualifier
  }

  const isSelectedScore = (grade) => {
    return selected_score_range === grade
  }

  const isEmerging = (horizontalBarMessage) => {
    return horizontalBarMessage === PAGE_CON.EMERGING
  }

  const isEmergingSelected = (horizontalBarMessage) => {
    return (
      isEmerging(horizontalBarMessage) &&
      isSelectedQualifierMetric(horizontalBarMessage)
    )
  }
  const getScoreCards = (scoreArray, horizontalBarMessage) => {
    return (
      <div>
        <div>
          <div className={classes.container_for_cardScore}>
            {scoreArray.map((grade, i) => (
              <Box
                key={i}
                style={{ height: height }}
                className={clsx(
                  classes.cardScoreTemplate,
                  selectedScoreBox &&
                    isSelectedQualifierMetric(horizontalBarMessage)
                    ? classes.selectedScoreBox
                    : classes.cardScoreBox,
                  isSelectedScore(grade) ? classes.selectedScore : ''
                )}
              >
                {selected_score_range === grade ? data?.score : grade}
              </Box>
            ))}
          </div>
          {horizontalBar && (
            <div
              className={classes.horizontal_bar}
              style={{
                opacity: isSelectedQualifierMetric(horizontalBarMessage)
                  ? '1'
                  : '0.2'
              }}
            ></div>
          )}
        </div>
        {horizontalBar && (
          <div
            className={clsx(
              classes.hrzntlBrMsg,
              isSelectedQualifierMetric(horizontalBarMessage)
                ? classes.slctdScore
                : '',
              isEmerging(horizontalBarMessage) ? classes.emerging : '',
              isEmergingSelected(horizontalBarMessage)
                ? classes.emergingSelected
                : ''
            )}
          >
            {horizontalBarMessage}
          </div>
        )}
      </div>
    )
  }
  return (
    <Container className={classes.esgScore} {...(id ? (id = { id }) : {})}>
      {data && horizontalBar && (
        <Box className={clsx(classes.cardScore, classes.cardBox)}>
          {getScoreCards(grades.slice(0, 3), PAGE_CON.LEADING)}
          {getScoreCards(grades.slice(3, 6), PAGE_CON.DEMONSTRATING)}
          {getScoreCards(grades.slice(6, 9), PAGE_CON.PROGRESSING)}
          {getScoreCards(grades.slice(9, grades.length), PAGE_CON.EMERGING)}
        </Box>
      )}
      {data && !horizontalBar && (
        <Box className={clsx(classes.cardScore, classes.cardBox)}>
          {getScoreCards(grades, '')}
        </Box>
      )}
    </Container>
  )
}

ESGScore.propTypes = {
  /** Id */
  id: PropTypes.string,
  /** Data to be consumed by this component  */
  data: PropTypes.any,
  /** Custom grade color for this component */
  gradeColor: PropTypes.string,
  /** Custom overall grade color for this component */
  overallGradeColor: PropTypes.string,
  /** Custome horizontal bar */
  horizontalBar: PropTypes.bool,
  /** To highlight all the scores of same alphabet using a common background color*/
  selectedScoreBox: PropTypes.bool,
  /** Background color for the selected score */
  selectedScoreColor: PropTypes.string,
  /** Width of score component */
  width: PropTypes.string,
  /** Width of each score box */
  scoreBoxWidth: PropTypes.string,
  /** Width of selected score box */
  selectedScoreBoxWidth: PropTypes.string,
  /** Font color for the alphanumeric scores */
  fontColor: PropTypes.string,
  /** Height of score component */
  height: PropTypes.string
}

ESGScore.defaultProps = {
  selectedScoreBox: false,
  horizontalBar: false
}

export default ESGScore
