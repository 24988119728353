import React from 'react'
import clsx from 'clsx'
import { PAGE_CON } from 'constant/constants'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import NavItem from './ReportNavigationItem'
import PropTypes from 'prop-types'
import { useStyles } from './styles'

/**
 * @visibleName Report Navigation
 */

// eslint-disable-next-line react/display-name, no-unused-vars
const ReportNavigation = React.forwardRef((props, ref) => {
  const classes = useStyles(props)

  const PortfolioAnalysis = Object.assign(
    {},
    ...(function _flatten(o) {
      return [].concat(
        ...Object.keys(o).map((k) =>
          typeof o[k] === 'object' ? _flatten(o[k]) : { [k]: o[k] }
        )
      )
    })(props.entitlements.PortfolioAnalysis)
  )
  var entitlements = props.entitlements
  entitlements.PortfolioAnalysis = PortfolioAnalysis
  let subHeaderIndex = 0

  return (
    <List className={classes.list} id={props.id}>
      {props.sideBarItems.map((item) => {
        subHeaderIndex =
          item.header && entitlements[props.section][item.entitled]
            ? subHeaderIndex + 1
            : subHeaderIndex + 0
        return item.header
          ? entitlements[props.section][item.entitled] && (
              <ListSubheader
                key={item.title}
                disableSticky
                className={clsx(
                  subHeaderIndex > 1 && classes.subheaderMargin,
                  classes.subheader
                )}
              >
                {item.title}
              </ListSubheader>
            )
          : entitlements[props.section][item.subsection] &&
              entitlements[props.section][item.entitled] &&
              !item.portfolioAnalaysisHide && (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  styleguide={props.styleGuide}
                  lastItemInSection={item.lastItemInSection}
                  disabled={
                    PAGE_CON.PRODUCTION_ENABLED && item.productionDisabled
                      ? true
                      : false
                  }
                  onNavItemClick={props.onNavClick}
                />
              )
      })}
    </List>
  )
})

ReportNavigation.propTypes = {
  /** Report Navigation id */
  id: PropTypes.string,
  /** Style Guide should not use router (pass is true for style guide examples) */
  styleGuide: PropTypes.bool,
  /** Report Navigation items */
  sideBarItems: PropTypes.array,
  /** call parent function */
  onNavClick: PropTypes.func
}

export default ReportNavigation
