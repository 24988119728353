import React from 'react'
import theme from 'theme'
import * as S from './styles'

export const Container = ({ children }) => {
  const classes = S.useStyles()(theme)
  return <div className={classes.container}>{children}</div>
}

export const ListItem = ({
  children,
  value,
  opacity,
  onItemClick,
  itemColor,
  height,
  width,
  clickable = true,
  border
}) => {
  const mapRange = [
    { color: `rgba(223, 161, 36, ${opacity})`, from: 0, to: 50 }, //TODO change these color codes
    { color: `rgba(175, 157, 63, ${opacity})`, from: 51, to: 70 },
    { color: `rgba(90, 151, 114, ${opacity})`, from: 71, to: 90 },
    { color: `rgba(34, 149, 149, ${opacity})`, from: 90, to: 100 }
  ]

  let colorObj

  if (value) {
    colorObj = mapRange.find(({ from, to }) => value >= from && value <= to)
  }

  const listItemColor =
    itemColor && itemColor !== '' ? itemColor : colorObj.color
  const listItemBorder = border && border !== '' ? border : 'inherit'
  const classes = S.useStyles({
    listItemColor,
    opacity,
    height,
    width,
    clickable,
    listItemBorder
  })(theme)
  return (
    <li className={classes.listItem} aria-hidden={true} onClick={onItemClick}>
      {children}
    </li>
  )
}
