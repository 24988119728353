import React from 'react'
import * as S from './styles'
import PropTypes from 'prop-types'
import Item from './item'
import LoadingMask from 'utils/loadingMask'

const List = ({
  items,
  header,
  isLoading,
  action,
  isEntityListHeatmap,
}) => {
  const clickHandler = (key) => action(key)

  return (
    <S.Container isEntityListHeatmap>
      {isLoading ? (
        <LoadingMask />
      ) : (
        <>
          {header}
          <ul className="list">
            {items.map((item) => (
              <li
                key={`${item.key}`}
                style={{ listStyle: 'none' }}
                onClick={() => action && action(item.key)}
                aria-hidden={true}
              >
                <Item
                  isEntityListHeatmap={isEntityListHeatmap}
                  title={item.title}
                  titleValue={item.titleValue}
                  subTitle={item.subTitle}
                  value={item.value}
                  itemKey={item.key}
                  clickHandler={action && clickHandler}
                  predictedScore={item.predicted_score}
                />
              </li>
            ))}
          </ul>
        </>
      )}
    </S.Container>
  )
}

List.propTypes = {
  items: PropTypes.array,
  header: PropTypes.node,
  isLoading: PropTypes.bool,
  action: PropTypes.func
}

export default List
