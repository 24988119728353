import theme from 'theme'

const labelColors = {
  Intense: { color: theme.palette.sys_trad.error.main },
  Weak: { color: theme.palette.sys_trad.error.main },
  None: { color: theme.palette.sys_trad.error.main },
  'Worst in Class': { color: theme.palette.sys_trad.error.main },
  'Red Flag Risk': { color: theme.palette.sys_trad.error.main },
  '20-100%': { color: theme.palette.sys_trad.error.main },
  Critical: { color: theme.palette.sys_trad.error.main },
  'Above 2°C': { color: theme.palette.sys_trad.error.main },
  'Red Flag': { color: theme.palette.sys_trad.error.main },
  Moderate: { color: theme.palette.sys_trad.success.main },
  'Well Below 2°C': { color: theme.palette.sys_trad.success.main }
}

export default labelColors
