import React from 'react'
import { CardContext } from '../../card'
import { PAGE_CON } from 'constant/constants'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { RadioButton } from 'components'
import { useStyle } from './styles'

function getDisplayText(content, data) {
  let displayText = ''
  if (data) {
    displayText = data[content.dataField]
  }
  return displayText
}

/**
 * @visibleName Mini Card
 */
const MiniCardInfoSection = (props) => {
  const context = React.useContext(CardContext)
  const {
    id,
    contentInfo,
    data,
    heap_id,
    heap_portfolio_title,
    containerClass
  } = props
  let classes = useStyle(props)

  return (
    <Box
      id={id}
      key={id}
      className={classes[containerClass]}
      {...(heap_id ? { heap_id: heap_id } : {})}
      {...(heap_portfolio_title
        ? { heap_portfolio_title: heap_portfolio_title }
        : {})}
    >
      {contentInfo &&
        contentInfo.length > 0 &&
        contentInfo.map((listContent, index) => {
          let text = getDisplayText(listContent, data)
          let toolTipText = ''
          if (listContent.showToolTip) {
            toolTipText = text
              ?.replace(
                new RegExp(`${PAGE_CON.LESS_THAN_MASK}mark.*?>`, 'ig'),
                ''
              )
              .replace(new RegExp(`${PAGE_CON.LESS_THAN_MASK}/mark>`, 'ig'), '')
          }

          text = text && text.includes('<') ? text.replace(/</g, '&lt') : text
          text =
            text &&
            text
              .replace(
                new RegExp(`${PAGE_CON.LESS_THAN_MASK}mark`, 'ig'),
                '<mark'
              )
              .replace(
                new RegExp(`${PAGE_CON.LESS_THAN_MASK}/mark>`, 'ig'),
                '</mark>'
              )

          let markupText = { __html: text }

          return listContent.labelType.toUpperCase() === 'RADIO' ? (
            <RadioButton
              key={`radio-${index}`}
              isChecked={context ? context.id === context.selectedId : false}
            />
          ) : (
            <Box
              key={`innerBox${index}`}
              className={clsx(
                listContent.className
                  ? classes[listContent.className]
                  : classes.textStyle
              )}
            >
              <span
                title={toolTipText}
                dangerouslySetInnerHTML={markupText}
              ></span>
            </Box>
          )
        })}
    </Box>
  )
}

MiniCardInfoSection.propTypes = {
  /** contentInfo will have text and date details */
  contentInfo: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      dataField: PropTypes.string,
      labelType: PropTypes.oneOf(['Date', 'Radio', 'Text']),
      showToolTip: PropTypes.bool
    })
  ),
  /** Data to be consumed by this component  */
  data: PropTypes.any,
  /** id for Mini Card Info component */
  id: PropTypes.string,
  /** Max width for cardInfoSection */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** name of container class (default value is root) */
  containerClass: PropTypes.string
}

MiniCardInfoSection.defaultProps = {
  containerClass: 'root'
}

export default MiniCardInfoSection
