import { makeStyles } from '@material-ui/core'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'

export const useStyles = makeStyles(() => ({
  root: {
    width: (props) => (props.width ? props.width : 'inherit'),
    paddingLeft: 4,
    paddingRight: 4,
    paddingBottom: 4,
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main
  },
  title: {
    ...textTheme.typography.p1,
    padding: 6,
    paddingLeft: 0
  },
  highchartsSeries: {
    clipPath: 'inset(0% 0% 0% 0% round 8px)'
  }
}))

export const labelStyle = {
  ...textTheme.typography.p3,
  textOutline: 'none'
}
