import { Divider, Grid } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'
import numberData from 'utils/numberFormat'
import * as S from './styles'

const MaterialityTrend = (props) => {
  const classes = S.useStyles(props)
  const { trendData } = props
  let hasData = false

  function getSubText(item) {
    const subItems = []

    if (item.pillar && item.pillar !== '') subItems.push(item.pillar)

    if (item.angle && item.angle !== '') subItems.push(item.angle)

    return subItems.join(' - ')
  }

  function isNumber(val) {
    if (val === '') return false
    return val >= 0 || val < 0
  }

  return (
    <div className={classes.root} key={props.id} id={props.id}>
      <div className={classes.title}>Trends</div>
      <Grid container direction="column" spacing={1}>
        {trendData &&
          trendData.length > 0 &&
          trendData.map((trendItem, outerIndex) => {
            let trendItemLength = trendItem.trend_details.length
            if (trendItemLength > 0) hasData = true
            return (
              trendItemLength > 0 &&
              trendItem.trend_details.map((item, index) => {
                return (
                  <React.Fragment key={`sd-${outerIndex}-${index}`}>
                    {index === 0 && (
                      <Grid
                        item
                        xs={12}
                        className={clsx(classes.data, classes.subTitle)}
                      >
                        {trendItem.trend_name}
                        {(trendItem.pillar || trendItem.angle) && (
                          <span className={classes.innerText}>
                            {' ('}
                            {getSubText(trendItem)}
                            {')'}
                          </span>
                        )}
                      </Grid>
                    )}
                    <Grid item container direction="row" spacing={1}>
                      <Grid
                        item
                        xs={8}
                        sm={5}
                        className={clsx(classes.data, classes.dataColor)}
                      >
                        {isNumber(item?.item_value)
                          ? numberData(item?.item_value, {
                              isCommaSeparated: true,
                              isDecimal: true
                            })
                          : item?.item_value}{' '}
                        {item?.possible_unit}
                      </Grid>
                      <Grid item xs={4} className={clsx(classes.data)}>
                        {item?.item_year}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )
              })
            )
          })}
        {hasData && (
          <Grid item className={classes.text}>
            <Divider className={classes.trend_divider} />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default MaterialityTrend
