import { makeStyles } from '@material-ui/core/styles'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'

export const useStyles = makeStyles({
  formControlStyle: {
    flexDirection: 'row'
  },
  formControlLabelStyle: {
    margin: '0px'
  },
  root: {
    padding: 0,
    paddingRight: 9,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: '50%',
    width: 14,
    height: 14,
    backgroundColor: themeColorPalette.palette.sys_trad.white.main,
    border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,

    'input:hover ~ &': {
      borderColor: themeColorPalette.palette.sys_trad.hover.main
    },
    'input:disabled ~ &': {
      background: themeColorPalette.palette.sys_trad.disabled.main,
      border: '1px solid ' + themeColorPalette.palette.sys_trad.disabled.main
    }
  },
  checkedIcon: {
    border: '1px solid ' + themeColorPalette.palette.sys_trad.selected.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:before': {
      display: 'block',
      width: 8,
      height: 8,
      background: themeColorPalette.palette.sys_trad.selected.main,
      content: '""',
      borderRadius: '50%'
    },
    'input:hover ~ &': {
      border: '1px solid ' + themeColorPalette.palette.sys_trad.hover.main
    }
  },
  labelClass: {
    ...textTheme.typography.p3,
    lineHeight: '16px',
    fontWeight: 600,
    color: textTheme.palette.sys_trad.text.main,
    fontSize: '13px',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: (props) => (props.labelWidth ? props.labelWidth : 'inherit')
  },
  selectedLabelStyle: {
    ...textTheme.typography.p2,
    lineHeight: '16px',
    fontWeight: 375,
    color: textTheme.palette.sys_trad.text.main,
    fontSize: '14px',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: (props) => (props.labelWidth ? props.labelWidth : 'inherit')
  },
  portfolioLabel: {
    ...textTheme.typography.p3,
    lineHeight: '16px',
    fontWeight: 325,
    color: textTheme.palette.sys_trad.text.main,
    fontSize: '14px',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: (props) => (props.labelWidth ? props.labelWidth : 'inherit')
  }
})
