import { Grid } from '@material-ui/core'
import React, { useContext } from 'react'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'
import {
  Card,
  CardInfoSection,
  Chiclet,
  CustomTooltip,
  Pie,
  ProjectionGraph
} from 'components'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import {
  BRN_SHR_MGM,
  CAR_FOOT_PRNT,
  COMMON,
  GRN_SHR_ASMNT,
  PHY_RSK_HZRD,
  PHY_RSK_MGM,
  TEMP_ALMT
} from 'constant/APIconstants'
import axios from 'axios'
import LoadingMask from 'utils/loadingMask'
import Error from 'utils/error'
import numberData from 'utils/numberFormat'
import getColor from 'utils/color'
import { getMonthDayYear } from 'utils/Date'
import clsx from 'clsx'
import { ResearchLineService } from 'services'
import * as S from './styles'

const getZones = (name, scores) => {
  if (name === 'company') {
    return scores.map((zone) => {
      return {
        value: zone.year,
        // eslint-disable-next-line no-extra-boolean-cast
        dashStyle: !!zone.historcal ? 'solid' : 'ShortDash',
        color: themeColorPalette.palette.sys_trad.status.main
      }
    })
  } else if (name === 'Projected Company Temperature Targets') {
    return [
      {
        color: themeColorPalette.palette.sys_trad.status.main,
        dashStyle: 'ShortDash'
      }
    ]
  } else {
    return [
      {
        color: getLineGraphColor(name)
      }
    ]
  }
}

const getLineGraphColor = (name) => {
  if (name === 'company' || name === 'Projected Company Temperature Targets') {
    return themeColorPalette.palette.sys_trad.status.main
  }

  if (name === '1.5°C') {
    return themeColorPalette.palette.genrang_b.one.main
  } else if (name === '1.65°C') {
    return themeColorPalette.palette.genrang_b.three.main
  } else if (name === '2.7°C') {
    return themeColorPalette.palette.genrang_b.eight.main
  }
}

const ta_categoryInfo = [
  [
    {
      labelType: 'Data',
      dataField: 'aligned_temp',
      alignment: 'left',
      minHeight: '24px',
      borderRadius: '2px',
      className: 'climateStyle',
      padding: '4px 8px',
      bgColor: {
        colorGroup: 'TA_CATEGORY',
        colorDerivedByField: 'QualitativeScore'
      },
      color: {
        colorGroup: 'TA_CATEGORY_TEXT',
        colorDerivedByField: 'QualitativeScore'
      }
    }
  ]
]

const cf_categoryInfo = [
  [
    {
      labelType: 'Data',
      dataField: 'cf_ranking',
      alignment: 'left',
      minHeight: '24px',
      borderRadius: '2px',
      className: 'climateStyle',
      padding: '4px 8px',
      color: {
        colorGroup: 'CF_CATEGORY_TEXT',
        colorDerivedByField: 'ranking'
      },
      bgColor: {
        colorGroup: 'ranking',
        colorDerivedByField: 'ranking'
      }
    }
  ]
]

const phy_categoryInfo = [
  [
    {
      labelType: 'Data',
      dataField: 'phy_ranking',
      alignment: 'left',
      minHeight: '24px',
      borderRadius: '2px',
      className: 'climateStyle',
      padding: '4px 8px',
      color: {
        colorGroup: 'CATEGORY',
        colorDerivedByField: 'textColor'
      },
      bgColor: {
        colorGroup: 'risk',
        colorDerivedByField: 'phy_bgcolor'
      }
    }
  ]
]

const ClimateSummary = (props) => {
  const classes = S.useStyles()

  const [entityHeaderState] = useContext(GlobalContext).globalEntityHeader
  const [climateState, climateDispatch] =
    useContext(GlobalContext).globalClimate

  let apiCallId = climateState.apiCallId

  const getModifiedData = () => {
    const tempAlignmentProjectionGraph =
      climateState?.tempAlignmentProjectionGraph?.data

    return tempAlignmentProjectionGraph
  }

  const getProjectionGraphData = React.useMemo(() => {
    const graphData = getModifiedData()

    return (
      graphData.length > 0 &&
      graphData?.map(({ scores, scenario }) => {
        const data = scores?.map((projection) => [
          projection.year,
          projection.score
        ])
        const name =
          scenario === 'company'
            ? 'Company Target Emissions Projection'
            : `${scenario} IEA Scenario - ${graphData[0].mesg_sector_category}`
        return {
          name,
          data,
          score: scores,
          zoneAxis: 'x',
          dashStyle:
            scenario === 'Projected Company Temperature Targets'
              ? 'ShortDash'
              : 'solid',
          color: getLineGraphColor(scenario),
          zones: getZones(scenario, scores)
        }
      })
    )
  })

  const getProjectionBandWidh = () => {
    const lowTemp = climateState?.tempAlignmentProjectionGraph?.data[2].scores
    const mediumTemp =
      climateState?.tempAlignmentProjectionGraph?.data[3].scores
    const highTemp = climateState?.tempAlignmentProjectionGraph?.data[4]?.scores
    const companyTarget =
      climateState?.tempAlignmentProjectionGraph?.data[0].scores

    if (lowTemp && mediumTemp && highTemp) {
      const yearsListNew = [
        new Date().getFullYear(),
        companyTarget[companyTarget.length - 1]?.year
      ]

      return {
        lowBandWidth: Math.min(...yearsListNew),
        highBandWidth: Math.max(...yearsListNew) + 10
      }
    }

    return {}
  }

  const getSummaryData = async (isErrorReload = false) => {
    if (
      (climateState.summaryTempAlignment.dataStatus !== '' ||
        climateState.summaryCarbonFootprint.dataStatus !== '' ||
        climateState.summaryPhyRiskHarz.dataStatus !== '' ||
        climateState.summaryPhyRiskMgm.dataStatus !== '' ||
        climateState.summaryGreenShare.dataStatus !== '' ||
        climateState.summaryBrownShare.dataStatus !== '' ||
        climateState.tempAlignmentProjectionGraph.dataStatus !== '') &&
      isErrorReload === false
    )
      return

    climateDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionNames: [
        REDUCER_STATE.CLIMATE_SUMMARY_TEMPALIGNMENT,
        REDUCER_STATE.CLIMATE_SUMMARY_CARBONFOOTPRINT,
        REDUCER_STATE.CLIMATE_SUMMARY_PHYRISKHARZ,
        REDUCER_STATE.CLIMATE_SUMMARY_PHYRISKMGM,
        REDUCER_STATE.CLIMATE_SUMMARY_GREENSHARE,
        REDUCER_STATE.CLIMATE_SUMMARY_BROWNSHARE,
        REDUCER_STATE.CLIMATE_SUMMARY_TEMPALIGNMENT_PROJ_GRAPH
      ]
    })

    try {
      const [
        tempAlign,
        carbonFootprint,
        PhyHard,
        PhyRiskMgm,
        GreenShare,
        BrownShare,
        tempAlignProjection
      ] = await axios.all([
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: props.entitlements.PortfolioAnalysis.transitionrisk,
          researchLine: TEMP_ALMT.PAGE_URL,
          token: '',
          endPoint: COMMON.CLIMATE_SUMMARY
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: props.entitlements.PortfolioAnalysis.transitionrisk,
          researchLine: CAR_FOOT_PRNT.PAGE_URL,
          token: '',
          endPoint: COMMON.CLIMATE_SUMMARY
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: props.entitlements.PortfolioAnalysis.physicalrisk,
          researchLine: PHY_RSK_HZRD.PAGE_URL,
          token: '',
          endPoint: COMMON.CLIMATE_SUMMARY
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: props.entitlements.PortfolioAnalysis.physicalrisk,
          researchLine: PHY_RSK_MGM.PAGE_URL,
          token: '',
          endPoint: COMMON.CLIMATE_SUMMARY
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: props.entitlements.PortfolioAnalysis.transitionrisk,
          researchLine: GRN_SHR_ASMNT.PAGE_URL,
          token: '',
          endPoint: COMMON.CLIMATE_SUMMARY
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: props.entitlements.PortfolioAnalysis.transitionrisk,
          researchLine: BRN_SHR_MGM.PAGE_URL,
          token: '',
          endPoint: COMMON.CLIMATE_SUMMARY
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: props.entitlements.PortfolioAnalysis.transitionrisk,
          researchLine: TEMP_ALMT.PAGE_URL,
          token: '',
          endPoint: COMMON.ENTITY_CLIMATE_TEMPALGMT_PROJECTION_GRAPH
        })
      ])

      climateDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionNames: [
          {
            [REDUCER_STATE.CLIMATE_SUMMARY_TEMPALIGNMENT]:
              tempAlign?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_SUMMARY_CARBONFOOTPRINT]:
              carbonFootprint?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_SUMMARY_PHYRISKHARZ]: PhyHard?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_SUMMARY_PHYRISKMGM]:
              PhyRiskMgm?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_SUMMARY_GREENSHARE]:
              GreenShare?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_SUMMARY_BROWNSHARE]:
              BrownShare?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_SUMMARY_TEMPALIGNMENT_PROJ_GRAPH]:
              tempAlignProjection?.data || {}
          }
        ],
        apiCallId
      })
    } catch (err) {
      console.log(err)
      climateDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        sectionNames: [
          REDUCER_STATE.CLIMATE_SUMMARY_TEMPALIGNMENT,
          REDUCER_STATE.CLIMATE_SUMMARY_CARBONFOOTPRINT,
          REDUCER_STATE.CLIMATE_SUMMARY_PHYRISKHARZ,
          REDUCER_STATE.CLIMATE_SUMMARY_PHYRISKMGM,
          REDUCER_STATE.CLIMATE_SUMMARY_GREENSHARE,
          REDUCER_STATE.CLIMATE_SUMMARY_BROWNSHARE,
          REDUCER_STATE.CLIMATE_SUMMARY_TEMPALIGNMENT_PROJ_GRAPH
        ],
        apiCallId
      })
    }
  }

  const loadSummary = React.useCallback(async () => {
    if (
      entityHeaderState.globalEntityHeader.orbis_id !== '' &&
      !entityHeaderState.isLoading
    ) {
      getSummaryData()
    }
  }, [entityHeaderState.globalEntityHeader.orbis_id])

  React.useEffect(() => {
    if (entityHeaderState.globalEntityHeader.orbis_id !== '') {
      loadSummary()
    }
  }, [entityHeaderState.globalEntityHeader.orbis_id])

  const getTempAlignComp = () => {
    let tempAlignment =
      climateState.summaryTempAlignment.data?.temperature_alignment

    const tmp = {
      QualitativeScore: tempAlignment?.score_category.toUpperCase(),
      aligned_temp: tempAlignment?.score_category.toUpperCase()
    }

    return [
      <Grid
        container
        direction="column"
        className={classes.ta_gridStyle}
        key="temperature"
        spacing={0}
      >
        <Grid item className={classes.tempTextStyle}>
          Temperature Alignment
        </Grid>
        <Grid item style={{ paddingBottom: '4px' }}>
          {tempHasData() && (
            <CardInfoSection
              key={'climate-test-id'}
              id={'climate-from-summaryoverview-'}
              contentInfo={ta_categoryInfo}
              data={tmp}
              climateTable={true}
              outerBackgroundColor={themeColorPalette.palette.sys_trad.bg.main}
            />
          )}
        </Grid>
      </Grid>,
      !tempHasData() ? (
        <Error
          id={'tempAlignErr'}
          dataStatus={PAGE_CON.SUCCESS}
          errorOccuredOn={PAGE_CON.CLIMATE_SUMMARY}
        />
      ) : (
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.tempStyle}
        >
          {tempAlignment.implied_temperature_rise && (
            <Grid
              item
              xs={12}
              style={{ paddingLeft: '12px', paddingBottom: '6px' }}
            >
              {'Implied Temperature Rise:'}
              <span style={{ paddingLeft: '8px', ...textTheme.typography.p3 }}>
                {tempAlignment.implied_temperature_rise_msg
                  ? tempAlignment.implied_temperature_rise_msg
                  : `${tempAlignment.implied_temperature_rise} \xB0C`}
              </span>
            </Grid>
          )}
          {tempAlignment.emissions_reduction_target_year && (
            <Grid
              item
              xs={12}
              style={{ paddingLeft: '12px', paddingBottom: '6px' }}
            >
              {'Emissions Reduction Target Year:'}
              <span style={{ paddingLeft: '8px', ...textTheme.typography.p3 }}>
                {tempAlignment.emissions_reduction_target_year}
              </span>
            </Grid>
          )}
          {tempAlignment.target_description && (
            <Grid
              item
              xs={12}
              style={{ paddingLeft: '12px', paddingTop: '6px' }}
            >
              {'Target Description:'}
              <span style={{ paddingLeft: '8px', ...textTheme.typography.p3 }}>
                {tempAlignment.target_description}
              </span>
            </Grid>
          )}
          {tempAlignment.last_updated_date && (
            <Grid
              item
              xs={12}
              className={classes.dateStyle}
              style={{ paddingLeft: '12px', paddingTop: '6px' }}
            >
              {'Updated on '}
              {getMonthDayYear(
                tempAlignment.last_updated_date,
                'month day, year'
              )}
            </Grid>
          )}
        </Grid>
      )
    ]
  }

  const getCarbonFootprintComp = () => {
    let carbonFootPrint =
      climateState.summaryCarbonFootprint.data?.carbon_footprint

    const tmp = {
      cf_ranking:
        carbonFootPrint?.score_category === 'No Info'
          ? `${carbonFootPrint?.score_category?.toUpperCase()}`
          : `${carbonFootPrint?.score_category?.toUpperCase()}`,
      ranking: carbonFootPrint?.score_category,
      textColor: 'white'
    }

    return [
      <Grid
        container
        direction="column"
        key="grid"
        className={classes.ta_gridStyle}
      >
        <Grid item className={classes.tempTextStyle}>
          Carbon Footprint
        </Grid>
        <Grid item style={{ paddingBottom: '4px' }}>
          {cfHasData() && (
            <CardInfoSection
              key={'climate-test-id'}
              id={'climate-from-summaryoverview-'}
              contentInfo={cf_categoryInfo}
              data={tmp}
              climateTable={true}
              outerBackgroundColor={themeColorPalette.palette.sys_trad.bg.main}
            />
          )}
        </Grid>
      </Grid>,
      !cfHasData() ? (
        <Error
          id={'tempAlignErr'}
          dataStatus={PAGE_CON.SUCCESS}
          errorOccuredOn={PAGE_CON.CLIMATE_SUMMARY}
        />
      ) : (
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.tempStyle}
        >
          {(carbonFootPrint.carbon_footprint_value_total_msg ||
            carbonFootPrint.carbon_footprint_value_total) && (
            <CustomTooltip
              title={
                <ul>
                  <li>
                    {
                      'We evaluate a companies carbon footprint based on the direct (Scope 1) and indirect (Scope 2) emissions.'
                    }
                  </li>
                  <li>
                    {
                      'When emissions data is not publicly disclosed, we estimate Scope 1 and Scope 2 emissions using our own proprietary models.'
                    }
                  </li>
                  <li>
                    {
                      'Other indirect emissions (Scope 3), are also collected or estimated and provided but are not part of the carbon footprint grade.'
                    }
                  </li>
                </ul>
              }
              placement="top-end"
            >
              <Grid
                item
                xs={12}
                style={{ paddingLeft: '12px', paddingBottom: '6px' }}
              >
                {PAGE_CON.CRBN_FT_PRT_TITLE}
                <span className={classes.summaryValueStyle}>
                  {carbonFootPrint.carbon_footprint_value_total_msg
                    ? carbonFootPrint.carbon_footprint_value_total_msg
                    : numberData(carbonFootPrint.carbon_footprint_value_total, {
                        isCommaSeparated: true,
                        keepRawValue: true
                      })}
                </span>
                <span className={classes.eqStyle}>
                  {carbonFootPrint.carbon_footprint_value_total_msg
                    ? null
                    : 't CO2 eq.'}
                </span>
              </Grid>
            </CustomTooltip>
          )}
          <Grid
            item
            xs={12}
            style={{ paddingLeft: '12px', paddingBottom: '6px' }}
          >
            {'Scope 1'}
            <span className={classes.summaryValueStyle}>
              {carbonFootPrint.emissions_scope_1_msg
                ? carbonFootPrint.emissions_scope_1_msg
                : numberData(carbonFootPrint.emissions_scope_1, {
                    isCommaSeparated: true,
                    keepRawValue: true
                  })}
            </span>
            <span className={classes.eqStyle}>
              {carbonFootPrint.emissions_scope_1_msg ? null : 't CO2 eq.'}
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ paddingLeft: '12px', paddingBottom: '6px' }}
          >
            {'Scope 2'}
            <span className={classes.summaryValueStyle}>
              {carbonFootPrint.emissions_scope_2_msg
                ? carbonFootPrint.emissions_scope_2_msg
                : numberData(carbonFootPrint.emissions_scope_2, {
                    isCommaSeparated: true,
                    keepRawValue: true
                  })}
            </span>
            <span className={classes.eqStyle}>
              {carbonFootPrint.emissions_scope_2_msg ? null : 't CO2 eq.'}
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ paddingLeft: '12px', paddingBottom: '6px' }}
          >
            {'Scope 3'}
            <span className={classes.summaryValueStyle}>
              {carbonFootPrint.emissions_scope_3_msg
                ? carbonFootPrint.emissions_scope_3_msg
                : numberData(carbonFootPrint.emissions_scope_3, {
                    isCommaSeparated: true,
                    keepRawValue: true
                  })}
            </span>
            <span className={classes.eqStyle}>
              {carbonFootPrint.emissions_scope_3_msg ? null : 't CO2 eq.'}
            </span>
          </Grid>
          {(carbonFootPrint.estimated || carbonFootPrint.estimated_msg) && (
            <Grid
              item
              xs={12}
              style={{ paddingLeft: '12px', paddingBottom: '6px' }}
            >
              {'Estimated'}
              <span className={classes.summaryValueStyle}>
                {carbonFootPrint.estimated_msg
                  ? carbonFootPrint.estimated_msg
                  : carbonFootPrint.estimated}
              </span>
            </Grid>
          )}
          {carbonFootPrint.last_updated_date && (
            <Grid
              item
              xs={12}
              className={classes.dateStyle}
              style={{ paddingLeft: '12px', paddingTop: '6px' }}
            >
              {'Updated on '}
              {getMonthDayYear(
                carbonFootPrint.last_updated_date,
                'month day, year'
              )}
            </Grid>
          )}
        </Grid>
      )
    ]
  }

  const getPhyRiskComp = () => {
    let physicalHazard =
      climateState.summaryPhyRiskHarz.data?.physical_risk_hazard
    const risk =
      climateState.summaryPhyRiskHarz.data?.physical_risk_hazard
        ?.hrh_risk_category

    const tmp = {
      phy_ranking: `HIGHEST RISK HAZARD: ${physicalHazard?.highest_risk_hazard_category?.toUpperCase()}`,
      phy_bgcolor: risk,
      textColor: 'white'
    }
    return [
      <Grid
        container
        direction="column"
        key="physicalClimateHazards"
        className={classes.ta_gridStyle}
      >
        <Grid item className={classes.tempTextStyle}>
          Physical Climate Hazards
        </Grid>
        <Grid item style={{ paddingBottom: '4px' }}>
          {phyRiskHarzHasData() && (
            <CardInfoSection
              key={'climate-test-id'}
              id={'climate-from-summaryoverview-'}
              contentInfo={phy_categoryInfo}
              data={tmp}
              climateTable={true}
              outerBackgroundColor={themeColorPalette.palette.sys_trad.bg.main}
            />
          )}
        </Grid>
      </Grid>,
      !phyRiskHarzHasData() ? (
        <Error
          id={'tempAlignErr'}
          dataStatus={PAGE_CON.SUCCESS}
          errorOccuredOn={PAGE_CON.CLIMATE_SUMMARY}
        />
      ) : (
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.tempStyle}
        >
          <Grid
            item
            xs={12}
            style={{ paddingLeft: '12px', paddingBottom: '6px' }}
          >
            <span className={classes.phyHazardStyle}>
              {numberData(physicalHazard.highest_risk_hazard_fac_exp, {
                isPercentage: true,
                isLessThanOneFormat: true
              })}{' '}
              Facilities Exposed to High Risk and Red Flag Risk for{' '}
              {physicalHazard.highest_risk_hazard_category}
            </span>
          </Grid>
          {physicalHazard.last_updated_date && (
            <Grid
              item
              xs={12}
              className={clsx(classes.dateStyle, classes.physHazardDate)}
            >
              <span className={classes.physHazardDate}>
                {'Updated on '}
                {getMonthDayYear(
                  physicalHazard.last_updated_date,
                  'month day, year'
                )}
              </span>
            </Grid>
          )}
        </Grid>
      )
    ]
  }

  const getPhyRiskMgmComp = () => {
    let phyRskMgm = climateState.summaryPhyRiskMgm.data?.physical_risk_mgmt
    const valueColor = themeColorPalette.palette.sys_trad.white.main
    const valueBgColor = getColor('category', phyRskMgm?.score_category)

    return [
      <Grid
        container
        direction="row"
        key="PHYRSK_MGM_TITLE"
        spacing={2}
        className={classes.tempStyle}
      >
        <Grid item className={classes.shareStyle}>
          {PAGE_CON.PHYRSK_MGM_TITLE}
          <div className={classes.infoBlockStyle}>
            {!phyRiskMgmHasData() ? (
              <Error
                id={'prmErr'}
                xs={12}
                dataStatus={PAGE_CON.SUCCESS}
                errorOccuredOn={PAGE_CON.CLIMATE_SUMMARY}
              />
            ) : (
              <Chiclet
                id="phyRiskMgm_chiclet-id"
                valueClassName={'customDisplay'}
                value={phyRskMgm?.score_category?.toUpperCase()}
                valueColor={valueColor}
                valueBgColor={valueBgColor}
              />
            )}
            <span className={classes.infoStyle}>
              {phyRiskMgmHasData() && phyRskMgm?.name}
            </span>
          </div>
          {phyRiskMgmHasData() && (
            <div className={classes.prmSubTextStyle}>
              {phyRskMgm.last_updated_date && (
                <div className={clsx(classes.dateStyle, classes.datePosition)}>
                  {'Updated on '}
                  {getMonthDayYear(
                    phyRskMgm.last_updated_date,
                    'month day, year'
                  )}
                </div>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    ]
  }

  const GreenPiData = React.useMemo(() => {
    let greenShare = climateState.summaryGreenShare.data?.green_share
    const valueColor = themeColorPalette.palette.sys_trad.white.main
    const valueBgColorGS = themeColorPalette.palette.major_no.green_major.main
    const data = [greenShare?.score || 0, 100 - (greenShare?.score || 0)]
    return {
      data: data,
      pie: <Pie pieColors={[valueBgColorGS, valueColor]} data={data} />
    }
  }, [climateState.summaryBrownShare.dataStatus])

  const getGreenShareComp = () => {
    let greenShare = climateState.summaryGreenShare.data?.green_share
    const valueColor = themeColorPalette.palette.sys_trad.white.main
    const valueBgColorGS = themeColorPalette.palette.major_no.green_major.main

    return [
      <Grid
        container
        direction="row"
        key="greenShare"
        className={classes.tempStyle}
      >
        <Grid item xs={gsHasData() && greenShare?.score !== 0 ? 7 : 12}>
          <div className={classes.shareStyle}>Green Share</div>
          <div className={classes.infoBlockStyle}>
            {gsHasData() && (
              <span className={classes.chicletPrefix}>Company has </span>
            )}
            {!gsHasData() ? (
              <Grid item xs={12}>
                <Error
                  id={'gsErr'}
                  dataStatus={PAGE_CON.SUCCESS}
                  errorOccuredOn={PAGE_CON.CLIMATE_SUMMARY}
                />
              </Grid>
            ) : (
              <Chiclet
                id="grnShr_chiclet-id"
                valueClassName={'customDisplay'}
                value={
                  greenShare.score_range
                    ? greenShare.score_range
                    : `${greenShare.score}%`
                }
                valueColor={valueColor}
                valueBgColor={valueBgColorGS}
              />
            )}
            {gsHasData() && (
              <span className={classes.infoStyle}>
                of their investments in companies offering green solutions.{' '}
              </span>
            )}
          </div>
        </Grid>
        {gsHasData() ? (
          <Grid item xs={5} className={classes.alignCenter}>
            {greenShare?.score !== 0 && <Grid item>{GreenPiData.pie}</Grid>}
          </Grid>
        ) : null}
      </Grid>
    ]
  }

  const BrownPiData = React.useMemo(() => {
    let brownShare = climateState.summaryBrownShare.data?.brown_share
    const valueColor = themeColorPalette.palette.sys_trad.white.main
    const valueBgColorBS = themeColorPalette.palette.major_no.green_no.main
    const data = [brownShare?.score || 0, 100 - (brownShare?.score || 0)]
    return {
      data: data,
      pie: <Pie pieColors={[valueBgColorBS, valueColor]} data={data} />
    }
  }, [climateState.summaryBrownShare.dataStatus])

  const getBrownShareComp = () => {
    let brownShare = climateState.summaryBrownShare.data?.brown_share
    const valueColor = themeColorPalette.palette.sys_trad.white.main
    const valueBgColorBS = themeColorPalette.palette.major_no.green_no.main

    return [
      <Grid
        container
        direction="row"
        key="brownShareScore"
        className={classes.tempStyle}
      >
        <Grid item xs={bsHasData() && brownShare?.score !== 0 ? 7 : 12}>
          <div className={classes.shareStyle}>Brown Share</div>
          <div className={classes.infoBlockStyle}>
            {bsHasData() && (
              <span className={classes.chicletPrefix}>Company has </span>
            )}
            {!bsHasData() ? (
              <Grid item xs={12}>
                <Error
                  id={'gsErr'}
                  dataStatus={PAGE_CON.SUCCESS}
                  errorOccuredOn={PAGE_CON.CLIMATE_SUMMARY}
                />
              </Grid>
            ) : (
              <Chiclet
                id="brnShr_chiclet-id"
                valueClassName={'customDisplay'}
                value={
                  brownShare.score_range
                    ? brownShare.score_range
                    : `${brownShare.score}%`
                }
                valueColor={valueColor}
                valueBgColor={valueBgColorBS}
              />
            )}
            {bsHasData() && (
              <span className={classes.infoStyle}>
                of revenue from the fossil fuel industry{' '}
              </span>
            )}
          </div>
        </Grid>
        {bsHasData() ? (
          <Grid item xs={5} className={classes.alignCenter}>
            {brownShare?.score !== 0 && <Grid item>{BrownPiData.pie}</Grid>}
          </Grid>
        ) : null}
      </Grid>
    ]
  }

  function isLoading() {
    return climateState.summaryTempAlignment.isLoading
  }

  function isError() {
    return climateState.summaryTempAlignment.dataStatus === PAGE_CON.ERROR
  }

  const reloadComponent = (errorOccuredOn) => {
    switch (errorOccuredOn) {
      case PAGE_CON.CLIMATE_SUMMARY:
        getSummaryData(true)
        break
      default:
        break
    }
  }

  const tempHasData = () => {
    return (
      Object.keys(
        climateState.summaryTempAlignment.data?.temperature_alignment || {}
      ).length > 0
    )
  }

  const cfHasData = () => {
    return (
      Object.keys(
        climateState.summaryCarbonFootprint.data?.carbon_footprint || {}
      ).length > 0
    )
  }

  const phyRiskHarzHasData = () => {
    return (
      Object.keys(
        climateState.summaryPhyRiskHarz.data?.physical_risk_hazard || {}
      ).length > 0
    )
  }

  const phyRiskMgmHasData = () => {
    return (
      Object.keys(climateState.summaryPhyRiskMgm.data?.physical_risk_mgmt || {})
        .length > 0
    )
  }

  const gsHasData = () => {
    return (
      Object.keys(climateState.summaryGreenShare.data?.green_share || {})
        .length > 0 &&
      climateState.summaryGreenShare.data?.green_share?.score >= 0
    )
  }

  const bsHasData = () => {
    return (
      Object.keys(climateState.summaryBrownShare.data?.brown_share || {})
        .length > 0 &&
      climateState.summaryBrownShare.data?.brown_share?.score >= 0
    )
  }

  const tempAlignHasData = () => {
    const areScoresEmpty =
      climateState?.tempAlignmentProjectionGraph?.data[0]?.scores.length ===
        0 &&
      climateState?.tempAlignmentProjectionGraph?.data[1]?.scores.length ===
        0 &&
      climateState?.tempAlignmentProjectionGraph?.data[2]?.scores.length ===
        0 &&
      climateState?.tempAlignmentProjectionGraph?.data[3]?.scores.length === 0

    return (
      Object.keys(climateState.tempAlignmentProjectionGraph.data || {}).length >
        0 &&
      climateState.tempAlignmentProjectionGraph.data.length >= 0 &&
      !areScoresEmpty
    )
  }

  return (
    <>
      <Grid container direction="row">
        {isLoading() ? (
          <LoadingMask />
        ) : isError() ? (
          <Error
            id={'overviewError'}
            dataStatus={PAGE_CON.ERROR}
            onErrorClick={reloadComponent}
            errorOccuredOn={PAGE_CON.CLIMATE_SUMMARY}
          />
        ) : (
          <div className={classes.climateSummarySectionStyle}>
            {props.entitlements?.PortfolioAnalysis.transitionrisk && (
              <Grid
                item
                container
                direction="row"
                className={classes.gridStyle}
              >
                <Grid item xs={12} sm={'auto'}>
                  <Grid container direction="column">
                    <Grid item style={{ padding: '12px' }}>
                      <Card
                        components={getTempAlignComp()}
                        id="tempAlignclimate-test-id"
                        selectionType="none"
                        key="tempAlignclimate-test-id"
                        maxWidth={'368px'}
                      />
                    </Grid>
                    <Grid item style={{ padding: '12px' }}>
                      <Card
                        components={getCarbonFootprintComp()}
                        id="carbonClimate-test-id"
                        selectionType="none"
                        key="carbonClimate-test-id"
                        maxWidth={'368px'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  lg={8}
                  className={classes.tempAlignStyle}
                >
                  {!tempAlignHasData() ? (
                    <div className={classes.tempAlignErrorStyle}>
                      <Error
                        id={'tempAlignProjErr'}
                        dataStatus={PAGE_CON.SUCCESS}
                        errorOccuredOn={
                          PAGE_CON.CLIMATE_ENTITY_PAGE_PROJECTION_GRAPH
                        }
                      />{' '}
                    </div>
                  ) : (
                    <div id="temperature-alignment-chart-placeholder">
                      <ProjectionGraph
                        id="barchart-test-id"
                        title=""
                        header="Temperature Alignment"
                        footer="This extrapolation is a theoretical decarbonization trend based on the company’s targeted reduction between its stated base year and the target year. This trend, therefore, indicates the emissions reduction that the company aims to achieve and can be used to assess the ambitiousness of a company's target in comparison to the benchmark trajectories shown."
                        scores={getProjectionGraphData}
                        mesg_sector_category={
                          climateState.tempAlignmentProjectionGraph.data[0]
                            .mesg_sector_category
                        }
                        sector_measurement={
                          climateState.tempAlignmentProjectionGraph.data[0]
                            .sector_measurement
                        }
                        bandWidth={getProjectionBandWidh()}
                        width={830}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid item container>
              {props.entitlements.PortfolioAnalysis.physicalrisk && (
                <>
                  <Grid
                    item
                    xs={'auto'}
                    sm={'auto'}
                    className={classes.bottomSummaryStyle}
                  >
                    <Card
                      components={getPhyRiskComp()}
                      id="phyClimate-test-id"
                      selectionType="none"
                      key="phyClimate-test-id"
                      maxWidth={'368px'}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={'auto'}
                    sm={'auto'}
                    className={classes.bottomSummaryStyle}
                    style={{ maxWidth: '212px' }}
                  >
                    <Card
                      components={getPhyRiskMgmComp()}
                      id="phyRskMgmClimate-test-id"
                      selectionType="none"
                      key="phyRskMgmClimate-test-id"
                    />
                  </Grid>
                </>
              )}
              {props.entitlements.PortfolioAnalysis.transitionrisk && (
                <>
                  <Grid
                    item
                    xs={6}
                    className={classes.bottomSummaryStyle}
                    style={{ maxWidth: '212px' }}
                  >
                    <Card
                      components={getGreenShareComp()}
                      id="greenClimate-test-id"
                      selectionType="none"
                      key="greenClimate-test-id"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    className={classes.bottomSummaryStyle}
                    style={{ maxWidth: '212px' }}
                  >
                    <Card
                      components={getBrownShareComp()}
                      id="brownClimate-test-id"
                      selectionType="none"
                      key="brownClimate-test-id"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        )}
      </Grid>
    </>
  )
}

export default ClimateSummary
