import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'

const useStyles = makeStyles((theme) => ({
  overlay: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: '0px',
    left: '0px',
    background: theme.palette.sys_trad.main.main,
    opacity: '0.2'
  }
}))

export const Overlay = ({ onClick }) => {
  const classes = useStyles(theme)
  return (
    <div onClick={onClick} aria-hidden={true} className={classes.overlay}></div>
  )
}
