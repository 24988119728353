import React, { useContext } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import { CheckboxGroup, PrimaryButton, PrimaryModal } from 'components'
import { GlobalContext } from 'context/GlobalStateProvider'
import { REDUCER_TYPE } from 'constant/constants'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import { EntityService } from 'services'
import { useStyles } from './styles'
import { COLUMNS_FIELDS, DOCUMENT_TYPES, URL_REGEX } from './constants'

const prepareRowOptionsMap = (urls) => {
  let componentMap = new Map()

  for (const component of urls) {
    componentMap.set(component.indicator, new Map())
    componentMap.get(component.indicator).set('checked', false)
    componentMap.get(component.indicator).set('indeterminate', false)
    componentMap.get(component.indicator).set('childrens', new Map())
    for (const driver of component.drivers) {
      componentMap
        .get(component.indicator)
        .get('childrens')
        .set(`${driver.domain} / ${driver.criteria_name}`, {
          checked: false
        })
    }
  }

  return componentMap
}

const isDuplicatedURL = (newUrl, rowsMap) =>
  [...rowsMap.values()].some((row) => row[COLUMNS_FIELDS.URL] === newUrl)

const FullFeaturedCrudGrid = (props) => {
  const classes = useStyles({ ...props })
  const [issuerUrlState, issuerUrlDispatch] =
    useContext(GlobalContext).globalIssuerUrl
  const [rowId, setRowId] = React.useState(0)
  const [url, setUrl] = React.useState('')
  const [urlError, setUrlError] = React.useState(false)
  const [urlErrorMsg, setUrlErrorMsg] = React.useState('')
  const [documentType, setDocumentType] = React.useState('')
  const [disclosureYear, setDisclosureYear] = React.useState('')
  const [selectedRow, setSelectedRow] = React.useState(null)
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  let YEARS = []
  const START_YEAR = 2019

  const getYears = () => {
    let currDate = new Date()
    let year = START_YEAR

    while (year <= currDate.getFullYear()) {
      YEARS.push(year++)
    }

    return YEARS
  }

  const urlValidator = (url) => {
    if (url && !URL_REGEX.test(url)) {
      setUrlError(true)
      setUrlErrorMsg('URL invalid')
    } else if (isDuplicatedURL(url, issuerUrlState.urlInfo.urls)) {
      setUrlError(true)
      setUrlErrorMsg('URL already added')
    } else {
      setUrlError(false)
      setUrlErrorMsg('')
    }
  }

  const createRowFromCurrentComponentState = () => {
    const row = {
      [COLUMNS_FIELDS.ID]: rowId,
      [COLUMNS_FIELDS.URL]: url,
      [COLUMNS_FIELDS.DOCUMENT_TYPE]: documentType,
      [COLUMNS_FIELDS.DISCLOSURE_YEAR]: disclosureYear,
      options: prepareRowOptionsMap(issuerUrlState.urlInfo.options)
    }
    setRowId(rowId + 1)

    return row
  }

  const renderDocumentTypeEditCell = (params) => {
    const { id, value, api, field } = params

    const handleChange = async (event) => {
      api.setEditCellValue({ id, field, value: event.target.value }, event)
      if (event.nativeEvent.clientX !== 0 && event.nativeEvent.clientY !== 0) {
        const isValid = await api.commitCellChange({ id, field })
        if (isValid) {
          handleCellChange({ id, field, value: event.target.value })
          api.setCellMode(id, field, 'view')
        }
      }
    }

    const handleRef = (element) => {
      if (element) {
        element.querySelector(`input[value="${value.toString()}"]`).focus()
      }
    }

    return (
      <Select
        ref={handleRef}
        value={value}
        style={{
          width: '100%',
          height: '100%',
          ...textTheme.typography.p2
        }}
        label="DocumentType"
        onChange={handleChange}
      >
        {DOCUMENT_TYPES.map((docType) => (
          <MenuItem
            key={docType}
            style={{ ...textTheme.typography.p3 }}
            value={docType}
          >
            {docType}
          </MenuItem>
        ))}
      </Select>
    )
  }

  const columns = [
    {
      field: COLUMNS_FIELDS.ID,
      hide: true
    },
    {
      field: COLUMNS_FIELDS.URL,
      editable: false,
      flex: 1,
      sortable: false,
      headerClassName: classes.hideRightSeparator.replace
    },
    {
      field: COLUMNS_FIELDS.DOCUMENT_TYPE,
      headerName: 'Document Type',
      flex: 0.5,
      sortable: false,
      headerClassName: classes.hideRightSeparator,
      editable: false,
      renderEditCell: renderDocumentTypeEditCell
    },
    {
      field: COLUMNS_FIELDS.DELETE,
      headerName: '',
      width: 80,
      headerClassName: classes.hideRightSeparator,
      sortable: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation() // don't select this row after clicking\
          setSelectedRow(params.row)
          setShowDeleteModal(true)
        }

        return (
          <Button
            size="small"
            style={{
              ...textTheme.typography.p1,
              color: themeColorPalette.palette.sys_trad.main.main
            }}
            onClick={onClick}
          >
            Delete
          </Button>
        )
      }
    }
  ]

  const onChangeUrl = ({ target: { value } }) => {
    urlValidator(value)
    setUrl(value)
  }

  const onChangeDocType = ({ target: { value } }) => {
    setDocumentType(value)
  }

  const onChangeYear = ({ target: { value } }) => {
    setDisclosureYear(value)
  }

  const handleDeleteRow = (rowUrl) => {
    issuerUrlDispatch({
      type: REDUCER_TYPE.UPDATE_URL,
      data: {
        urls:
          issuerUrlState.urlInfo.urls.delete(rowUrl) &&
          issuerUrlState.urlInfo.urls
      }
    })

    urlValidator(url)
    setShowDeleteModal(false)
    setSelectedRow(null)
  }

  const validateURL = async () => {
    props.setIsURLValidating(true)
    await EntityService.urlValidator({ url })
      .then(function (response) {
        props.setIsURLValidating(false)
        if (!response?.data?.valid_url) {
          setUrlError(true)
          setUrlErrorMsg('URL should be valid and publicly accessible')
        } else {
          handleAddRow()
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch(function (error) {
        props.setIsURLValidating(false)
        setUrlError(true)
        setUrlErrorMsg(
          'URL validation failed because of an issue, please try again later.'
        )
      })
  }

  const handleAddRow = () => {
    const newRow = createRowFromCurrentComponentState()

    issuerUrlDispatch({
      type: REDUCER_TYPE.UPDATE_URL,
      data: {
        urls: issuerUrlState.urlInfo.urls.set(rowId, newRow)
      }
    })

    setUrl('')
    setDocumentType('')
    if (props.showDisclosureYear) {
      setDisclosureYear('')
    }
    setSelectedRow(issuerUrlState.urlInfo.urls.get(newRow.id))
  }

  const handleCellChange = (col) => {
    const { id, field, value: newFieldValue } = col

    if (issuerUrlState.urlInfo.urls.get(id)[field] !== newFieldValue) {
      issuerUrlDispatch({
        type: REDUCER_TYPE.UPDATE_URL,
        data: {
          urls: issuerUrlState.urlInfo.urls.set(id, {
            ...issuerUrlState.urlInfo.urls.get(id),
            [field]: newFieldValue
          })
        }
      })
    }
  }

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item style={{ width: '50%' }}>
        <Grid item xs={12}>
          <Stack
            sx={{ mb: 1, flexWrap: 'wrap' }}
            direction="row"
            alignItems="flex-start"
            columnGap={1}
          >
            <TextField
              error={urlError}
              helperText={urlErrorMsg}
              autoComplete="off"
              auto="true"
              style={{
                width: props.showDisclosureYear ? '27.5%' : '52%',
                marginTop: 16,
                marginRight: 10,
                ...textTheme.typography.p2
              }}
              id="name"
              type="url"
              variant="standard"
              value={url}
              placeholder={'URL'}
              onChange={onChangeUrl}
              InputLabelProps={{
                shrink: true,
                className: classes.inputLabelClass
              }}
              InputProps={{
                className: classes.textboxInput
              }}
              inputProps={{
                className: classes.urlInput2
              }}
            />
            <FormControl variant="standard" className={classes.docTypeStyle}>
              <InputLabel
                style={{
                  ...textTheme.typography.p2,
                  color: themeColorPalette.palette.sys_trad.text2.main
                }}
              >
                Document Type
              </InputLabel>
              <Select
                value={documentType}
                style={{
                  marginRight: '10px',
                  marginTop: '16px',
                  ...textTheme.typography.p2
                }}
                label="DocumentType"
                onChange={onChangeDocType}
              >
                {DOCUMENT_TYPES.map((docType) => (
                  <MenuItem
                    key={docType}
                    style={{ ...textTheme.typography.p3 }}
                    value={docType}
                  >
                    {docType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {props.showDisclosureYear && (
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120 }}
                style={{ minWidth: '140px' }}
              >
                <InputLabel
                  style={{
                    ...textTheme.typography.p2,
                    color: themeColorPalette.palette.sys_trad.text2.main
                  }}
                >
                  Select disclosure year
                </InputLabel>
                <Select
                  value={disclosureYear}
                  style={{
                    marginRight: '10px',
                    marginTop: '16px',
                    ...textTheme.typography.p2
                  }}
                  label="DisclosureYear"
                  onChange={onChangeYear}
                >
                  {getYears().map((year) => (
                    <MenuItem
                      key={year}
                      style={{ ...textTheme.typography.p3 }}
                      value={year}
                    >
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <PrimaryButton
              buttonMarginTop="12px"
              buttonWidth="100px"
              buttonPadding="0"
              onClick={validateURL}
              buttonDisabled={
                url.length === 0 ||
                !!urlError ||
                documentType.length === 0 ||
                (props.showDisclosureYear && disclosureYear === '')
              }
              size="small"
              buttonText="Add a URL"
              buttonClass="buttonGhost"
            />
          </Stack>
          <Box sx={{ height: 400, bgcolor: 'background.paper' }}>
            <DataGrid
              classeName={classes.root}
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              hideFooter
              onCellEditCommit={handleCellChange}
              selectionModel={selectedRow ? selectedRow.id : []}
              rows={[...issuerUrlState.urlInfo.urls.values()]}
              columns={columns}
              onRowClick={({ row }) => setSelectedRow(row)}
              components={{
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No URLs
                  </Stack>
                )
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {selectedRow && (
        <Grid
          item
          style={{
            width: '50%',
            marginTop: '20px',
            ...textTheme.typography.h2,
            color: themeColorPalette.palette.sys_trad.main.main
          }}
        >
          <div style={{ width: '100%' }}>
            <Stack
              sx={{ width: '50%', mb: 1 }}
              direction="row"
              alignItems="flex-start"
              columnGap={1}
            >
              Assigned Categories
            </Stack>
            <Box
              sx={{
                height: '400px',
                bgcolor: 'background.paper',
                overflow: 'auto'
              }}
            >
              <Box
                sx={{
                  height: 300,
                  bgcolor: 'background.paper'
                }}
              >
                <CheckboxGroup selectedRow={selectedRow} />
              </Box>
            </Box>
          </div>
        </Grid>
      )}
      <PrimaryModal
        modalOpen={showDeleteModal}
        onModalClose={() => setShowDeleteModal(false)}
        modalClass="smallModal"
        modalTitle="Delete Document"
        modalContentComponent={[
          'This document won\'t be sent to our analyst team'
        ]}
        modalActionComponent={
          <>
            <PrimaryButton
              buttonText="Yes, delete this document"
              onClick={() => handleDeleteRow(selectedRow?.id)}
              buttonClass="buttonPrimary"
            />
            <PrimaryButton
              buttonText="No, keep this document"
              buttonClass="buttonGhost"
              onClick={() => setShowDeleteModal(false)}
            />
          </>
        }
        modalNoCloseOnClick
      />
    </Grid>
  )
}

export default FullFeaturedCrudGrid
