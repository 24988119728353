import { AppBar } from '@material-ui/core'
import { Container, Grid, makeStyles } from '@material-ui/core'
import { GlobalContext } from 'context/GlobalStateProvider'
import { COMMON, OPR_RSK_MGM, PARAMS } from 'constant/APIconstants'
import { ResearchLineService } from 'services'
import {
  PAGE_CON,
  REDUCER_STATE,
  REDUCER_TYPE,
  filterOptions
} from 'constant/constants'
import axios from 'axios'
import Error from 'utils/error'
import getMonthYear from 'utils/Date'
import Laggards from '../../Laggards'
import Leaders from '../../Leaders'
import LoadingMask from 'utils/loadingMask'
import PhysicalRiskColumns from '../PhysicalRiskColumns.json'
import PhysicalRiskOverView from '../PhysicalRiskOverview'
import React, { useCallback, useContext, useEffect } from 'react'
import Regions from '../../Regions'
import scoreDict from 'utils/scoreDictionary'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import UnderlyingDataRiskMetrics from '../UnderlyingDataMetrics'
import Updates from '../../Updates'
import { DropdownField, IconArrowDown, Page } from 'components'
import Impact from '../../Impact'
import getColor from 'utils/color'
import { PortfolioAnalysisService } from 'services'

const colInfo = JSON.parse(
  JSON.stringify(PhysicalRiskColumns.physicalrisk_Portfolio_Score_Columns)
)
const UpdatesTableColInfo = PhysicalRiskColumns.physicalrisk_updates_Columns
const PanelUpdatesTableColInfo =
  PhysicalRiskColumns.physicalrisk_updates_Panel_Columns
const LeaderLaggardColInfo =
  PhysicalRiskColumns.physicalrisk_leaders_Laggards_Panel_Columns
const mimTabColInfo = JSON.parse(
  JSON.stringify(
    PhysicalRiskColumns.physicalrisk_region_Sector_Minimal_Table_Columns
  )
)
const panelTabColInfo =
  PhysicalRiskColumns.physicalrisk_region_Sector_Panel_Columns
// cardInfoContentInfo is for regions
const cardInfoContentInfo =
  PhysicalRiskColumns.physicalrisk_card_Info_Section_Columns
const cardInfoContentInfoSector =
  PhysicalRiskColumns.physicalrisk_card_Info_Section_Columns_Sector
const impactColumns = JSON.parse(
  JSON.stringify(PhysicalRiskColumns.impactColumns)
)

const drawerWidth = PAGE_CON.PAGE_LEFT_MARGIN

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  content: {
    flexGrow: 1
  },
  container: {
    marginLeft: drawerWidth - 20,
    paddingRight: 0,
    paddingLeft: '24px',
    marginTop: '22px'
  },
  drawerHeader: {
    display: 'flex',
    ...textTheme.typography.h1,
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  },
  tableTitle: {
    paddingBottom: '5px',
    ...textTheme.typography.h1
  },
  stickyHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    zIndex: 1,
    boxShadow: 'none'
  },
  overviewstickyHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    zIndex: 1,
    boxShadow: 'none'
  },
  containerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 10px 16px',
    marginTop: '24px'
  },
  impactContainerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 0px 16px',
    marginTop: '24px',
    paddingLeft: '16px'
  },
  impactDropdown: {
    marginTop: '16px',
    marginBottom: '12px'
  }
}))

const tableLength = 10

const linkPadding = {
  marginTop: '8px',
  marginLeft: '-4px'
}

const overviewLinkPadding = {
  marginTop: '16px',
  marginLeft: '-4px'
}

const linkPaddingRegionIndustry = {
  marginTop: '8px',
  marginLeft: '-4px'
}

const linkStyle = {
  ...textTheme.typography.p1,
  color: themeColorPalette.palette.sys_trad.link.main,
  lineHeight: '16px',
  verticalAlign: 'top'
}

const regionStyle = {
  paddingBottom: '10px',
  marginTop: '18px'
}

const regionTitleStyle = {
  top: '16px'
}

const updatesStyle = {
  paddingLeft: '2px',
  marginTop: '16px'
}

const UpdatesTableStyle = {
  marginTop: '12px',
  background: themeColorPalette.palette.sys_trad.white.main,
  boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.12)',
  boxRadius: '2px',
  boxSizing: 'border-box',
  padding: '12px',
  paddingTop: '0px',
  paddingBottom: '10px'
}

const cardHeader = {
  ...textTheme.typography.p2,
  paddingBottom: '4px',
  paddingTop: '16px',
  lineHeight: '16px'
}

const dataMetricbox = {
  marginTop: '12px',
  paddingTop: '10px',
  background: themeColorPalette.palette.sys_trad.white.main,
  boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.12)',
  boxRadius: '2px',
  boxSizing: 'border-box',
  padding: '17px'
}

const dataMetricTitle = {
  marginTop: '16px'
}

const mapDataClasses = [
  { color: themeColorPalette.palette.noflag.norsk.main, from: 0, to: 19 },
  { color: themeColorPalette.palette.noflag.lowrsk.main, from: 20, to: 39 },
  { color: themeColorPalette.palette.noflag.medrsk.main, from: 40, to: 59 },
  { color: themeColorPalette.palette.noflag.hirsk.main, from: 60, to: 79 },
  { color: themeColorPalette.palette.noflag.redflgrsk.main, from: 80, to: 100 }
]

const OperationsRisk = (props) => {
  const classes = useStyles()
  const [selectedCardId, setSelectedCardId] = React.useState(null)
  const [filterState] = useContext(GlobalContext).globalFilter
  const [operationsRiskState, operationsRiskDispatch] =
    useContext(GlobalContext).globalOperationsRisk

  let apiCallId = operationsRiskState.apiCallId
  const pageConstant = OPR_RSK_MGM.PAGE_URL

  let showBenchMark =
    filterState.globalPortfolioFilter.benchmark !== '' ? true : false

  let benchmarckCol = colInfo.filter(
    (col) => col.HeaderLabel === PAGE_CON.BENCHMARK
  )
  benchmarckCol[0].HideColumn = !showBenchMark

  let benchmarkPctCol = mimTabColInfo.filter(
    (col) => col.HeaderLabel === PAGE_CON.BENCHMARK_PERC
  )
  benchmarkPctCol[0].HideColumn = !showBenchMark

  let monthYear = getMonthYear(filterState)

  function selectedCard(selectCardInfo) {
    setSelectedCardId(selectCardInfo.cardId)
  }

  const onImpactFilterChange = (event) => {
    operationsRiskDispatch({
      type: REDUCER_TYPE.UPDATE_IMPACT_FILTER,
      data: event.target.value
    })
  }

  const isRegionHasData = () => {
    let hasData = false
    if (
      operationsRiskState.portfolioRegions.data.summary.length > 0 &&
      operationsRiskState.portfolioRegions.data.details.length > 0 &&
      operationsRiskState.portfolioRegions.data.mapData.length > 0
    )
      hasData = true
    return hasData
  }

  const getLeadersLaggardsData = async (
    sectors,
    regions,
    isErrorReload = false
  ) => {
    if (
      (operationsRiskState.portfolioLeaderLaggard.data.leaders &&
        operationsRiskState.portfolioLeaderLaggard.data.laggards &&
        operationsRiskState.portfolioLeaderLaggard.data.leaders.length > 0 &&
        operationsRiskState.portfolioLeaderLaggard.data.laggards.length > 0) ||
      (operationsRiskState.portfolioLeaderLaggard.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    operationsRiskDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS
    })
    let leadersLaggardsData = { leaders: [], laggards: [] }

    try {
      const { data } = await axios.post(
        COMMON.BASE_URL +
          filterState.globalPortfolioFilter.portfolio +
          OPR_RSK_MGM.PAGE_URL +
          COMMON.LEADERS_LAGGARDS,
        {
          [PARAMS.REGION]: regions,
          [PARAMS.SECTOR]: sectors,
          [PARAMS.MONTH]: filterState.globalPortfolioFilter.asOfDate.slice(4),
          [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(0, 4)
        },
        { cancelToken: operationsRiskState.cancelToken.token }
      )

      leadersLaggardsData.leaders = data.leaders
      leadersLaggardsData.laggards = data.laggards

      operationsRiskDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS,
        data: leadersLaggardsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        operationsRiskDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS,
          apiCallId
        })
      }
    }
  }

  const getUpdatesData = async (sectors, regions, isErrorReload = false) => {
    if (
      operationsRiskState.portfolioUpdates.data.length > 0 ||
      (operationsRiskState.portfolioUpdates.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    operationsRiskDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_UPDATES
    })
    let updatesData = []
    try {
      const { data } = PortfolioAnalysisService.getUpdates(
        filterState.globalPortfolioFilter.portfolio,
        OPR_RSK_MGM.PAGE_URL,
        regions,
        sectors,
        filterState.globalPortfolioFilter.asOfDate.slice(4),
        filterState.globalPortfolioFilter.asOfDate.slice(0, 4),
        operationsRiskState.cancelToken.token
      )

      updatesData = data

      operationsRiskDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_UPDATES,
        data: updatesData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        operationsRiskDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_UPDATES,
          apiCallId
        })
      }
    }
  }

  const getRegionsData = async (sectors, regions, isErrorReload = false) => {
    if (
      (operationsRiskState.portfolioRegions.data.summary.length > 0 &&
        operationsRiskState.portfolioRegions.data.details.length > 0 &&
        operationsRiskState.portfolioRegions.data.mapData.length > 0 &&
        operationsRiskState.portfolioSectors.data.summary.length > 0 &&
        operationsRiskState.portfolioSectors.data.details.length > 0) ||
      (operationsRiskState.portfolioRegions.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    operationsRiskDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_REGIONS
    })
    let regionsData = {
      summary: [],
      details: [],
      mapData: [],
      sectorsummary: [],
      sectordetails: []
    }
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter

    try {
      const [summary, details, mapData, sectorsummary, sectordetails] =
        await axios.all([
          ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: OPR_RSK_MGM.PAGE_URL,
            token: operationsRiskState.cancelToken.token,
            endPoint: COMMON.REGION_SUMMARY
          }),
          ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: OPR_RSK_MGM.PAGE_URL,
            token: operationsRiskState.cancelToken.token,
            endPoint: COMMON.REGION_DETAILS
          }),
          ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: OPR_RSK_MGM.PAGE_URL,
            token: operationsRiskState.cancelToken.token,
            endPoint: COMMON.MAP
          }),
          ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: OPR_RSK_MGM.PAGE_URL,
            token: operationsRiskState.cancelToken.token,
            endPoint: COMMON.SECTOR_SUMMARY
          }),
          ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: OPR_RSK_MGM.PAGE_URL,
            token: operationsRiskState.cancelToken.token,
            endPoint: COMMON.SECTOR_DETAILS
          })
        ])
      regionsData.summary = summary
      regionsData.details = details.data
      regionsData.sectorsummary = sectorsummary.data
      regionsData.sectordetails = sectordetails.data

      mapData.data
        .filter(
          (data) => !isNaN(data.score) && data.score >= 0 && data.score <= 100
        )
        .map((mdata) => {
          let range = scoreDict[mdata.score_category]
          return regionsData.mapData.push([
            mdata.country_code,
            mdata.score,
            range
          ])
        })

      operationsRiskDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_REGIONS,
        data: regionsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        operationsRiskDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_REGIONS,
          apiCallId
        })
      } else console.log(err)
    }
  }

  const getImpactCall = (filter, regions, sectors, month, year) => {
    return axios.post(
      COMMON.BASE_URL +
        filterState.globalPortfolioFilter.portfolio +
        OPR_RSK_MGM.IMPACT_PAGE_URL +
        COMMON.IMPACT,
      {
        [PARAMS.IMPACT_FILTER]: filter,
        [PARAMS.REGION]: regions,
        [PARAMS.SECTOR]: sectors,
        [PARAMS.MONTH]: month,
        [PARAMS.YEAR]: year
      },
      { cancelToken: operationsRiskState.cancelToken.token }
    )
  }

  const getImpactData = async (sectors, regions, isErrorReload = false) => {
    if (
      (operationsRiskState.portfolioImpact.data &&
        operationsRiskState.portfolioImpact.data.length > 0) ||
      (operationsRiskState.portfolioImpact.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    operationsRiskDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_IMPACT
    })
    let impactData = {}
    try {
      const month = filterState.globalPortfolioFilter.asOfDate.slice(4)
      const year = filterState.globalPortfolioFilter.asOfDate.slice(0, 4)

      const [top5, top10, bottom5, bottom10] = await axios.all([
        getImpactCall(PAGE_CON.IMPACT_KEY_TOP_5, regions, sectors, month, year),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_TOP_10,
          regions,
          sectors,
          month,
          year
        ),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_BOTTOM_5,
          regions,
          sectors,
          month,
          year
        ),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_BOTTOM_10,
          regions,
          sectors,
          month,
          year
        )
      ])

      impactData[PAGE_CON.IMPACT_KEY_TOP_5] = top5.data[0]
      impactData[PAGE_CON.IMPACT_KEY_TOP_10] = top10.data[0]
      impactData[PAGE_CON.IMPACT_KEY_BOTTOM_5] = bottom5.data[0]
      impactData[PAGE_CON.IMPACT_KEY_BOTTOM_10] = bottom10.data[0]

      operationsRiskDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_IMPACT,
        data: impactData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        operationsRiskDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_IMPACT,
          apiCallId
        })
      }
    }
  }

  const getUnderlyingDataMetrics = async (
    sectors,
    regions,
    isErrorReload = false
  ) => {
    if (
      Object.keys(operationsRiskState.portfolioUnderlyingDataMetrics.data)
        .length > 0 ||
      (operationsRiskState.portfolioUnderlyingDataMetrics.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    operationsRiskDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS
    })
    let metricsData = []
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter

    try {
      const { data } = await ResearchLineService.retrieveResearchLineApi({
        portfolio,
        regions,
        sectors,
        asOfDate,
        benchmark,
        checkForEntitlements: false,
        isEntitled: false,
        researchLine: OPR_RSK_MGM.PAGE_URL,
        token: operationsRiskState.cancelToken.token,
        endPoint: COMMON.DATA_METRICS
      })
      metricsData = data

      operationsRiskDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS,
        data: metricsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        operationsRiskDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS,
          apiCallId
        })
      }
    }
  }

  const reloadComponent = (errorOccuredOn) => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    switch (errorOccuredOn) {
      case PAGE_CON.LEADERS_TITLE:
      case PAGE_CON.LAGGARDS_TITLE:
        getLeadersLaggardsData(sectors, regions, true)
        break
      case PAGE_CON.REGIONS_TITLE:
        getRegionsData(sectors, regions, true)
        break
      case PAGE_CON.UPDATESIN_TITLE:
        getUpdatesData(sectors, regions, true)
        break
      case PAGE_CON.RISK_CAT_TITLE:
        getUnderlyingDataMetrics(sectors, regions, true)
        break
      case PAGE_CON.IMPACT_TITLE:
        getImpactData(sectors, regions, true)
        break
      default:
        break
    }
  }

  const getOperationRiskData = useCallback(async () => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    if (filters.portfolio !== '' && !filterState.isLoading) {
      getRegionsData(sectors, regions)
      getLeadersLaggardsData(sectors, regions)
      getUpdatesData(sectors, regions)
      getImpactData(sectors, regions)
      getUnderlyingDataMetrics(sectors, regions)
    }
  }, [filterState.actionData])

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      getOperationRiskData()
    }
  }, [filterState.actionData])

  const updateVal = React.useMemo(() => {
    return {
      data: operationsRiskState.portfolioUpdates.data,
      colInfo: UpdatesTableColInfo
    }
  }, [operationsRiskState.portfolioUpdates.dataStatus])

  const leadersData = React.useMemo(() => {
    return {
      data: operationsRiskState.portfolioLeaderLaggard.data.leaders,
      colInfo: LeaderLaggardColInfo
    }
  }, [operationsRiskState.portfolioLeaderLaggard.dataStatus])

  const operationsRiskMapData =
    operationsRiskState.portfolioRegions.data &&
    operationsRiskState.portfolioRegions.data.mapData &&
    operationsRiskState.portfolioRegions.data.mapData.map((item) => {
      return {
        'hc-key': item[0] || '-',
        value: item[1] !== undefined ? item[1] : '-',
        ScoreRangeScore: item[1] !== undefined ? item[1] : '-',
        category: item[2] || '-'
      }
    })

  const getPositiveBarData = () => {
    const positiveData = [
      {
        name: 'total',
        data: [],
        opacity: 0.6
      },
      {
        name: 'selection',
        data: [],
        opacity: 1
      }
    ]
    operationsRiskState.portfolioImpact.data[
      operationsRiskState.portfolioImpact.filter
    ].positive_impact.distribution.map((item) => {
      positiveData[0].data.push(item.total - item.selection)
      positiveData[1].data.push(item.selection)
      return ''
    })

    return positiveData
  }

  const getPositiveBarColor = () => {
    const positiveColor = []

    operationsRiskState.portfolioImpact.data[
      operationsRiskState.portfolioImpact.filter
    ].positive_impact.distribution.map((item) => {
      positiveColor.push(getColor('risk', item.category))
      return ''
    })

    return positiveColor
  }

  const getNegativeBarData = () => {
    const negativeData = [
      {
        name: 'total',
        data: [],
        opacity: 0.6
      },
      {
        name: 'selection',
        data: [],
        opacity: 1
      }
    ]
    operationsRiskState.portfolioImpact.data[
      operationsRiskState.portfolioImpact.filter
    ].negative_impact.distribution.map((item) => {
      negativeData[0].data.push(item.total - item.selection)
      negativeData[1].data.push(item.selection)
      return ''
    })

    return negativeData
  }

  const getNegativeBarColor = () => {
    const negativeColor = []

    operationsRiskState.portfolioImpact.data[
      operationsRiskState.portfolioImpact.filter
    ].negative_impact.distribution.map((item) => {
      negativeColor.push(getColor('risk', item.category))
      return ''
    })

    return negativeColor
  }

  return (
    <Page className={classes.root} title={PAGE_CON.PAGE_TITLE}>
      <main className={classes.content}>
        <Container className={classes.container} maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PhysicalRiskOverView
                showBenchMark={showBenchMark}
                colInfo={colInfo}
                pageTitle={props.pageHeaderTitle}
                overViewText={PAGE_CON.OPERATIONSRISK}
                coverageHeaderWidth={showBenchMark ? '49%' : '47%'}
                pageState={operationsRiskState}
                pageDispatch={operationsRiskDispatch}
                apiCallId={apiCallId}
                pageConstant={pageConstant}
                baseUrl={OPR_RSK_MGM.BASE_URL}
              />
            </Grid>
            <Grid item container>
              <a
                href={'#' + PAGE_CON.UPDATES_AND_CURRENT_LEADERS_LAGGARDS}
                style={overviewLinkPadding}
              >
                <IconArrowDown />
                <span style={linkStyle}>
                  {PAGE_CON.UPDATESIN_PHSYICALRISK_TITLE} {monthYear},{' '}
                  {PAGE_CON.IMPACT_TITLE}, and Current {PAGE_CON.LEADERS_TITLE}/
                  {PAGE_CON.LAGGARDS_TITLE}
                </span>
              </a>
            </Grid>

            <Grid item container>
              <AppBar
                id={props.id}
                position="sticky"
                className={classes.stickyHeader}
                style={{ top: '30px' }}
              >
                <div
                  id={PAGE_CON.UPDATES_AND_CURRENT_LEADERS_LAGGARDS}
                  style={{ paddingTop: '90px' }}
                >
                  <div className={classes.tableTitle} style={updatesStyle}>
                    {' '}
                    {PAGE_CON.UPDATESIN_PHSYICALRISK_TITLE} {monthYear},{' '}
                    {PAGE_CON.IMPACT_TITLE}, and Current{' '}
                    {PAGE_CON.LEADERS_TITLE}/{PAGE_CON.LAGGARDS_TITLE}{' '}
                  </div>
                </div>
              </AppBar>
              <Grid item xs={12} style={UpdatesTableStyle}>
                <h6 style={cardHeader}>Updates</h6>
                {operationsRiskState.portfolioUpdates.isLoading ? (
                  <LoadingMask />
                ) : operationsRiskState.portfolioUpdates.dataStatus ===
                    PAGE_CON.ERROR ||
                  (operationsRiskState.portfolioUpdates.dataStatus ===
                    PAGE_CON.SUCCESS &&
                    operationsRiskState.portfolioUpdates.data.length === 0) ? (
                  <Error
                    id={'updatesError'}
                    dataStatus={operationsRiskState.portfolioUpdates.dataStatus}
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.UPDATESIN_TITLE}
                    topMargin="5px"
                  />
                ) : (
                  <Updates
                    physicalRisk={true}
                    colInfo={updateVal.colInfo}
                    panelColInfo={PanelUpdatesTableColInfo}
                    tableLength={10}
                    totalData={updateVal.data}
                    tableEditable={false}
                    monthYear={monthYear}
                  />
                )}
              </Grid>

              <Grid
                item
                container
                className={classes.impactContainerWrapper}
                style={{ paddingLeft: '16px' }}
              >
                {operationsRiskState.portfolioImpact.isLoading ? (
                  <LoadingMask />
                ) : operationsRiskState.portfolioImpact.dataStatus ===
                    PAGE_CON.ERROR ||
                  (operationsRiskState.portfolioImpact.dataStatus ===
                    PAGE_CON.SUCCESS &&
                    operationsRiskState.portfolioImpact.data.length === 0) ? (
                  <Error
                    id={'impactError'}
                    dataStatus={operationsRiskState.portfolioImpact.dataStatus}
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.IMPACT_TITLE}
                    topMargin="5px"
                  />
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className={classes.impactDropdown}
                    >
                      <span
                        style={{
                          backgroundColor:
                            themeColorPalette.palette.sys_trad.selected2.main,
                          marginBottom: '12px',
                          paddingTop: 4
                        }}
                      >
                        <DropdownField
                          id="impact-filter"
                          dropdownDefaultSelction={
                            operationsRiskState.portfolioImpact.filter
                          }
                          saveValueUsingExternalState={true}
                          dropdownOptions={filterOptions}
                          onChange={onImpactFilterChange}
                          labelTextDisabled={true}
                          labelText=""
                          alternateLabelText="Top 5"
                          defaultData={''}
                          impactColor={
                            themeColorPalette.palette.sys_trad.selected2.main
                          }
                        />
                      </span>
                    </Grid>
                    <Impact
                      positiveImpactData={
                        operationsRiskState.portfolioImpact.data[
                          operationsRiskState.portfolioImpact.filter
                        ].positive_impact.investment_and_score
                      }
                      posBarChartData={getPositiveBarData()}
                      posBarColors={getPositiveBarColor()}
                      posBarTitle={`${
                        filterOptions.find(
                          (item) =>
                            item.key ===
                            operationsRiskState.portfolioImpact.filter
                        ).displayText
                      } vs. Total Investment %`}
                      negativeImpactData={
                        operationsRiskState.portfolioImpact.data[
                          operationsRiskState.portfolioImpact.filter
                        ].negative_impact.investment_and_score
                      }
                      negBarChartData={getNegativeBarData()}
                      negBarColors={getNegativeBarColor()}
                      negBarTitle={`${
                        filterOptions.find(
                          (item) =>
                            item.key ===
                            operationsRiskState.portfolioImpact.filter
                        ).displayText
                      } vs. Total Investment %`}
                      impactColumns={impactColumns}
                      impactFilter={operationsRiskState.portfolioImpact.filter}
                    />
                    <Grid />
                  </>
                )}
              </Grid>

              <Grid item container className={classes.containerWrapper}>
                <Grid item xs={12} sm={6} style={{ paddingRight: '12px' }}>
                  <h6 style={cardHeader}>
                    {PAGE_CON.PORTFOLIO_LEADERS_BY_SCORE}
                  </h6>
                  {operationsRiskState.portfolioLeaderLaggard.isLoading ? (
                    <LoadingMask />
                  ) : operationsRiskState.portfolioLeaderLaggard.dataStatus ===
                      PAGE_CON.ERROR ||
                    (operationsRiskState.portfolioLeaderLaggard.dataStatus ===
                      PAGE_CON.SUCCESS &&
                      operationsRiskState.portfolioLeaderLaggard.data.leaders &&
                      operationsRiskState.portfolioLeaderLaggard.data.leaders
                        .length === 0) ? (
                    <Error
                      id={'leadersError'}
                      dataStatus={
                        operationsRiskState.portfolioLeaderLaggard.dataStatus
                      }
                      onErrorClick={reloadComponent}
                      errorOccuredOn={PAGE_CON.LEADERS_TITLE}
                    />
                  ) : (
                    <Leaders
                      colInfo={leadersData.colInfo}
                      totalData={leadersData.data}
                      tableLength={tableLength}
                      tableEditable={false}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingLeft: '12px' }}>
                  <h6 style={cardHeader}>
                    {PAGE_CON.PORTFOLIO_LAGGARDS_BY_SCORE}
                  </h6>
                  {operationsRiskState.portfolioLeaderLaggard.isLoading ? (
                    <LoadingMask />
                  ) : operationsRiskState.portfolioLeaderLaggard.dataStatus ===
                      PAGE_CON.ERROR ||
                    (operationsRiskState.portfolioLeaderLaggard.dataStatus ===
                      PAGE_CON.SUCCESS &&
                      operationsRiskState.portfolioLeaderLaggard.data
                        .laggards &&
                      operationsRiskState.portfolioLeaderLaggard.data.laggards
                        .length === 0) ? (
                    <Error
                      id={'laggardsError'}
                      dataStatus={
                        operationsRiskState.portfolioLeaderLaggard.dataStatus
                      }
                      onErrorClick={reloadComponent}
                      errorOccuredOn={PAGE_CON.LAGGARDS_TITLE}
                    />
                  ) : (
                    <Laggards
                      colInfo={LeaderLaggardColInfo}
                      totalData={
                        operationsRiskState.portfolioLeaderLaggard.data.laggards
                      }
                      tableLength={tableLength}
                      tableEditable={false}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item container>
              <a
                href={'#' + PAGE_CON.REGIONS_INDUSTRIES}
                style={linkPaddingRegionIndustry}
              >
                <IconArrowDown />
                <span style={linkStyle}>
                  {PAGE_CON.REGIONS_TITLE} & {PAGE_CON.SECTORS_TITLE}
                </span>
              </a>
            </Grid>

            <Grid item container>
              <AppBar
                id={props.id}
                position="sticky"
                className={classes.stickyHeader}
                style={regionTitleStyle}
              >
                <div
                  id={PAGE_CON.REGIONS_INDUSTRIES}
                  style={{ paddingTop: '80px' }}
                >
                  <div
                    className={classes.tableTitle}
                    style={{ marginTop: '36px' }}
                  >
                    {' '}
                    {PAGE_CON.REGIONS_TITLE} & {PAGE_CON.SECTORS_TITLE}{' '}
                  </div>
                </div>
              </AppBar>
              <Grid item xs={12} style={regionStyle}>
                {operationsRiskState.portfolioRegions.isLoading ? (
                  <LoadingMask />
                ) : operationsRiskState.portfolioRegions.dataStatus ===
                    PAGE_CON.ERROR ||
                  (operationsRiskState.portfolioRegions.dataStatus ===
                    PAGE_CON.SUCCESS &&
                    !isRegionHasData()) ? (
                  <Error
                    id={'regionsError'}
                    dataStatus={operationsRiskState.portfolioRegions.dataStatus}
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.REGIONS_TITLE}
                  />
                ) : (
                  <Regions
                    hideMap={true}
                    key={'RegionKey'}
                    data={operationsRiskState.portfolioRegions.data}
                    displayRegions={[]}
                    selectedCardId={selectedCardId}
                    onRegionSelect={selectedCard}
                    mimTabColInfo={mimTabColInfo}
                    panelTabColInfo={panelTabColInfo}
                    cardInfoContentInfo={cardInfoContentInfo}
                    cardInfoContentInfoSector={cardInfoContentInfoSector}
                    mapData={operationsRiskMapData}
                    mapDataClasses={mapDataClasses}
                    currentPageState={'globalOperationsRisk'}
                    categoryFalse={true}
                    stateData={true}
                    physicalRiskQuarterly={true}
                  />
                )}
              </Grid>
            </Grid>

            <Grid item container>
              <a
                href={'#' + PAGE_CON.OPERATIONS_RISK_CATEGORIES}
                style={linkPadding}
              >
                <IconArrowDown />
                <span style={linkStyle}>
                  {PAGE_CON.OPR_RSK_TITLE} {PAGE_CON.RISK_CAT_TITLE}
                </span>
              </a>
            </Grid>
            <Grid item container>
              <AppBar
                id={props.id}
                position="sticky"
                className={classes.stickyHeader}
                style={{ top: '35px' }}
              >
                <div
                  id={PAGE_CON.OPERATIONS_RISK_CATEGORIES}
                  style={{ paddingTop: '83px' }}
                >
                  <div className={classes.tableTitle} style={dataMetricTitle}>
                    {' '}
                    {PAGE_CON.OPR_RSK_TITLE} {PAGE_CON.RISK_CAT_TITLE}{' '}
                  </div>
                </div>
              </AppBar>
              <Grid item xs={12} style={dataMetricbox}>
                {operationsRiskState.portfolioUnderlyingDataMetrics
                  .isLoading ? (
                  <LoadingMask />
                ) : operationsRiskState.portfolioUnderlyingDataMetrics
                    .dataStatus === PAGE_CON.ERROR ||
                  (operationsRiskState.portfolioUnderlyingDataMetrics
                    .dataStatus === PAGE_CON.SUCCESS &&
                    operationsRiskState.portfolioUnderlyingDataMetrics.data
                      .length === 0) ? (
                  <Error
                    id={'udmError'}
                    dataStatus={
                      operationsRiskState.portfolioUnderlyingDataMetrics
                        .dataStatus
                    }
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.RISK_CAT_TITLE}
                  />
                ) : (
                  <UnderlyingDataRiskMetrics
                    underlyingDataMetrics={
                      operationsRiskState.portfolioUnderlyingDataMetrics.data
                    }
                    dataStatus={
                      operationsRiskState.portfolioUnderlyingDataMetrics
                        .dataStatus
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <div style={{ height: '600px' }}></div>
        </Container>
      </main>
    </Page>
  )
}

export default OperationsRisk
