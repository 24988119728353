import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core'
import React from 'react'
import PortfolioComp from 'views/globalComponent/PortfolioComp'

const useStyles = makeStyles(() => ({
  reportContainer: {
    maxWidth: (props) => (props.maxWidth ? props.maxWidth : '1200px'),
    padding: 0
  }
}))

const ReportTemplate = (props) => {
  const classes = useStyles(props)
  return (
    <>
      {props.spanFullPageWidth && <div>{props.report}</div>}
      {!props.spanFullPageWidth && (
        <Container className={classes.reportContainer}>
          {props.loadPortfolio && <PortfolioComp showPortfolioComp={false} />}
          {props.report}
        </Container>
      )}
    </>
  )
}

export default ReportTemplate
