import PropTypes from 'prop-types'
import { useStyles } from './styles'

const ESGScoreBar = (props) => {
  const classes = useStyles(props)
  const { value } = props

  return (
    <div className={classes.esgScoreBar}>
      <div
        className={classes.thumb}
        style={{ left: value ? value + '%' : 0 }}
      ></div>
    </div>
  )
}

ESGScoreBar.propTypes = {
  /** Value for Slider */
  value: PropTypes.string
}

export default ESGScoreBar
