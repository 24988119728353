import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  esgScoreBar: {
    position: 'relative',
    display: 'inline-block',
    width: '80%',
    height: '1px',
    backgroundColor: 'rgb(193, 198, 204)',
    minWidth: '80px'
  },
  thumb: {
    position: 'absolute',
    display: 'inline-block',
    height: '8px',
    width: '3px',
    backgroundColor: 'rgb(229, 255, 126)',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  }
})
