import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconLeftArrow = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
      fill="#b8b8b8"
    />
  </SvgIcon>
)
IconLeftArrow.displayName = 'IconLeftArrow'
IconLeftArrow.muiName = 'SvgIcon'

export default IconLeftArrow
