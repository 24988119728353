import { Divider, Grid } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'
import numberData from 'utils/numberFormat'
import * as S from './styles'

const MaterialityDriverDisclosure = (props) => {
  const classes = S.useStyles(props)
  const { disclosureData } = props
  let hasData = false

  function getItemValue(item, item_value) {
    var newVal = item.item_value
    if (item_value === 'Not Indicated') newVal = 'No Info'

    if (item_value === 'Number') newVal = item.item_value

    // if % is the unit then need to show as percentage to 2 decimal points - special case
    if (item?.possible_unit === '%' && item_value === 'Number')
      newVal = item?.item_value
        ? (
            numberData(Number(item?.item_value), { isDecimal: true }) * 100
          ).toFixed(2)
        : '-'

    return newVal
  }

  function getSubText(item) {
    const subItems = []

    if (item.pillar && item.pillar !== '') subItems.push(item.pillar)

    if (item.angle && item.angle !== '') subItems.push(item.angle)

    return subItems.join(' - ')
  }

  return (
    <div className={classes.root} key={props.id} id={props.id}>
      <Grid container direction="column" spacing={2}>
        {disclosureData &&
          disclosureData.item.length > 0 &&
          disclosureData.item.map((disclosureItem, outerIndex) => {
            let disclosureItemLength = disclosureItem.item_details.length

            if (disclosureItemLength > 0) hasData = true

            return (
              disclosureItemLength > 0 &&
              disclosureItem.item_details.map((item, index) => {
                return (
                  <React.Fragment key={`sd-${outerIndex}-${index}`}>
                    <Grid item container direction="row" spacing={2}>
                      <Grid item xs={2} sm={2} className={clsx(classes.data)}>
                        {getItemValue(item, disclosureItem.item_value)}
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={10}
                        className={classes.textContainer}
                      >
                        <div className={classes.text}>
                          {item.item_descripton ? item.item_descripton : '-'}
                        </div>
                        {(item.pillar || item.angle) && (
                          <div className={classes.innerText}>
                            {getSubText(item)}
                          </div>
                        )}
                        {item.possible_unit &&
                          disclosureItem.item_value === 'Number' && (
                            <div className={classes.innerText}>
                              {item.possible_unit}
                            </div>
                          )}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )
              })
            )
          })}
        {hasData && (
          <Grid item className={classes.text}>
            <Divider className={classes.divider} />
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default MaterialityDriverDisclosure
