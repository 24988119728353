import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import theme from 'theme/theme'

export const useStyles = makeStyles({
  gridStyle: {
    width: (props) => (props.width ? props.width : 'inherit'),
    padding: '10px',
    marginLeft: (props) => (props.marginLeft ? props.marginLeft : '0px'),
    marginTop: (props) => (props.topMargin ? props.topMargin : '2px'),
    height: (props) => (props.height ? props.height : 'inherit'),
    marginRight: (props) => (props.marginRight ? props.marginRight : 'inherit'),
    justifyContent: (props) =>
      props.justifyContent ? props.justifyContent : 'inherit'
  },
  noDataStyle: {
    width: (props) => (props.width ? props.width : 'inherit'),
    marginLeft: (props) => (props.marginLeft ? props.marginLeft : '0px'),
    marginTop: (props) => (props.topMargin ? props.topMargin : '2px'),
    height: (props) => (props.height ? props.height : 'inherit'),
    marginRight: (props) => (props.marginRight ? props.marginRight : 'inherit'),
    justifyContent: (props) =>
      props.justifyContent ? props.justifyContent : 'inherit'
  },
  noMargin: {
    width: (props) => (props.width ? props.width : 'inherit'),
    padding: '10px',
    height: (props) => (props.height ? props.height : 'inherit'),
    justifyContent: (props) =>
      props.justifyContent ? props.justifyContent : 'inherit'
  },
  centerAlign: {
    width: (props) => (props.width ? props.width : 'inherit'),
    padding: '10px',
    boxShadow: (props) =>
      props.hideBoxShadow ? 'none' : '0px 0px 12px rgba(38, 65, 94, 0.1)',
    margin: (props) => (props.margin ? props.margin : 'inherit'),
    marginLeft: '0px',
    marginTop: (props) => (props.topMargin ? props.topMargin : '2px'),
    height: (props) => (props.height ? props.height : 'inherit'),
    justifyContent: 'center'
  },
  textStyle: {
    ...textTheme.typography.p3,
    fontWeight: 500,
    color: themeColorPalette.palette.sys_trad.text2.main,
    textAlign: 'center'
  },
  noDataText1Style: {
    ...textTheme.typography.p3,
    fontWeight: 500,
    color: themeColorPalette.palette.sys_trad.text2.main,
    textAlign: 'center'
  },
  noDataText2Style: {
    ...textTheme.typography.p3,
    color: themeColorPalette.palette.sys_trad.text2.main,
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '14.4px'
  },

  blueColorStyle: {
    ...textTheme.typography.h2,
    color: theme?.palette?.sys_trad?.main.main,
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '19.2px',
    paddingBottom: '5px'
  },
  redColorStyle: {
    ...textTheme.typography.h2,
    color: theme?.palette?.sys_trad?.error.main,
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '19.2px',
    paddingBottom: '5px'
  },
  greenColorStyle: {
    ...textTheme.typography.h2,
    color: theme?.palette?.sys_trad?.success.main,
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '19.2px',
    paddingBottom: '5px'
  },

  errorIconClass: {
    color: themeColorPalette.palette.sys_trad.text2.main,
    marginBottom: '-8px'
  },
  updateErrorClass: {
    ...theme.typography.p2,
    color: theme?.palette?.sys_trad?.text2.main,
    backgroundColor: '#F5F7F7',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '10px',
    minHeight: (props) => (props.height ? props.height : '160px')
  },
  haderErrorClass: {
    ...textTheme.typography.h2,
    textAlign: 'center',
    color: theme?.palette?.sys_trad?.error.main
  },
  container: {
    height: '100%'
  }
})
