/* eslint-disable no-nested-ternary */
export default function numberData(numbersData, numberSpec) {
  let data = numbersData
  let isLessThanOne = false
  if (data === '') {
    return data
  }
  if (
    data !== 0 &&
    data !== '0' &&
    data < 1 &&
    !numberSpec.keepRawValue &&
    numberSpec.isPercentage
  ) {
    isLessThanOne = true
  }
  if (!numberSpec.isDecimal) {
    data = parseFloat(data).toLocaleString('en-US', {
      maximumFractionDigits: 0
    })
  }
  if (numberSpec.isLessThanOneFormat) {
    data =
      // eslint-disable-next-line no-nested-ternary
      data >= 1
        ? data
        : data === '0.00' || data === 0 || data === '0'
        ? data
        : '<1'
  }
  if (numberSpec.isDecimal) {
    const maxFraction = numberSpec.maxFraction || 2
    data = numberSpec.isDegree
      ? `${Number(data).toFixed(1).replace(/\.0+$/, '').toString()}\xB0C`
      : numberSpec.unitsRequired
      ? data.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: maxFraction
        }) + numberSpec.units
      : numberSpec.maxFraction
      ? Number(data).toFixed(maxFraction).replace(/\.0+$/, '').toString()
      : data.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: maxFraction
        })
  }
  if (numberSpec.isPercentage) {
    data = `${data.toString()}%`
  }
  if (parseFloat(data) < 0) {
    data = `${data.toString().replace('-', '(')})`
  }
  if (numberSpec.isCommaSeparated) {
    data = data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  if (isLessThanOne) {
    data = '<1%'
  }
  return data
}

export function exportNumberData(numbersData, numberSpec) {
  let data = numbersData
  if (!numberSpec.isDecimal) {
    data =
      data &&
      parseFloat(data).toLocaleString('en-US', {
        maximumFractionDigits: 0
      })
  }
  if (numberSpec.isDecimal) {
    data =
      data &&
      data.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
  }
  if (numberSpec.isCommaSeparated) {
    data = data && data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return data
}

export function carbonFootPrintDataFormat(cfpNumber) {
  let number = cfpNumber
  // 2 decimal places => 100, 3 => 1000, etc
  const decPlaces = 10 ** 0

  // Enumerate number abbreviations
  const abbrev = ['K', 'MM', 'B', 'T']

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i -= 1) {
    // Convert array index to "1000", "1000000", etc
    const size = 10 ** ((i + 1) * 3)

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      number = (number * decPlaces) / size / decPlaces
      // eslint-disable-next-line prefer-destructuring
      number = number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1
        i += 1
      }
      number += abbrev[i]

      // We are done... stop
      break
    }
  }
  return number
}

export function numberOfCompanies(companies, capital = false) {
  const capitalize = (string) =>
    string.charAt(0).toUpperCase() + string.slice(1)
  const companyText = companies === 1 ? 'company' : 'companies'
  return `${companies} ${capital ? capitalize(companyText) : companyText}`
}
