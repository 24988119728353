import { IconClose } from 'components'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { Divider } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import themeColorPalette from 'theme/theme'
import Box from '@material-ui/core/Box'
import { useStyles } from './styles'
import { PAGE_CON } from 'constant/constants'

const PrimaryModal = (props) => {
  const classes = useStyles(props)
  if (process.env.REACT_APP_DEV_ENV && !process.env.TEST) {
    return <></>
  }
  const handleModalClose = (event, reason) => {
    if (event.disableBackdropClick && reason === PAGE_CON.BACKDROP_CLICK) {
      return false
    }

    if (typeof props.onModalClose === 'function') {
      props.onModalClose()
    }
  }

  return (
    <Dialog
      id={props.id}
      open={props.modalOpen}
      classes={{ paper: classes[props.modalClass] }}
      className={props.modalClass}
      onClose={handleModalClose}
      scroll={props.modalScroll}
      BackdropProps={
        props.backdropstyle
          ? {
              style: {
                backgroundColor: themeColorPalette.palette.sys_trad.main.main,
                opacity: 0.2
              }
            }
          : {}
      }
      disableEscapeKeyDown={true}
      onKeyDown={props.onKeyDown}
      maxWidth={props.modalMaxWidth}
      fullScreen={props.modalClass === 'fullScreenModal' ? true : false}
    >
      {props.modalHeaderContentComponent && (
        <Box className={classes.minorDialogHeader}>
          <DialogTitle className={classes.minorDialogTitle}>
            {' '}
            {props.modalTitle}
            {!props.modalNoCloseOnClick && props.showCloseIcon && (
              <IconButton
                disableRipple
                disableFocusRipple
                className={classes.closeButton}
                onClick={props.onModalClose}
              >
                <IconClose className={classes.closeButton} />
              </IconButton>
            )}
            <DialogContent className={classes.minorDialogHeaderContent}>
              {props.modalHeaderContentComponent}
            </DialogContent>
          </DialogTitle>
          {props.showDivider && <Divider className={classes.dividerStyle} />}
        </Box>
      )}
      {!props.modalHeaderContentComponent && props.showCloseIcon && (
        <DialogTitle className={classes.dialogTitle}>
          {' '}
          {props.modalTitle}
          {!props.modalNoCloseOnClick && (
            <IconButton
              disableRipple
              disableFocusRipple
              className={classes.closeButton}
              onClick={props.onModalClose}
            >
              <IconClose className={classes.closeButton} />
            </IconButton>
          )}
        </DialogTitle>
      )}
      {props.modalContentComponent && (
        <DialogContent className={classes.dialogContent}>
          {props.modalContentComponent}
        </DialogContent>
      )}
      {props.modalActionComponent && (
        <DialogActions
          className={`${props.dialogActionCustomStyle} ${classes.dialogAction}`}
        >
          {props.modalActionComponent}
        </DialogActions>
      )}
    </Dialog>
  )
}

PrimaryModal.propTypes = {
  /** modal id */
  id: PropTypes.string,
  /** Class for modal style: smallModal, minorModal, fullScreenModal */
  modalClass: PropTypes.string,
  /** modal title */
  modalTitle: PropTypes.string,
  /** modal scroll property */
  modalScroll: PropTypes.string,
  /** hide model x scroll */
  hideModalXScroll: PropTypes.bool,
  /** modal max width property: xs, sm, md, lg, xl, false*/
  modalMaxWidth: PropTypes.any,
  /** modal open */
  modalOpen: PropTypes.bool,
  /** modal close on click */
  modalNoCloseOnClick: PropTypes.bool,
  /** modal content */
  modalContentComponent: PropTypes.any,
  /** modal action content */
  modalActionComponent: PropTypes.any,
  /** Gets called when the user clicks on the button */
  onModalClose: PropTypes.func,
  /** modal header content components */
  modalHeaderContentComponent: PropTypes.any,
  /** close icon flag on modal */
  showCloseIcon: PropTypes.bool,
  /** modal width */
  modalWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** modal height */
  modalMinHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** divider flag on modal */
  showDivider: PropTypes.bool,
  /** header background color */
  headerBgColor: PropTypes.string,
  /** apply style to backdrop */
  backdropstyle: PropTypes.bool,
  /** dialog action custom style */
  dialogActionCustomStyle: PropTypes.string,
  /** dialog custom position (relative is the default, it can be changed to fixed, absolute...) */
  modalPosition: PropTypes.string,
  /** dialog top position custom style when modal position is used, this property can be used to set the top position*/
  topPosition: PropTypes.string
}

PrimaryModal.defaultProps = {
  showCloseIcon: true,
  modalClass: 'minorModal',
  showDivider: false
}

export default PrimaryModal
