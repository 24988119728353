import React, { useContext, useEffect, useMemo, useState } from 'react'
import * as S from '../dashboardComponents/index.jsx'
import { DashboardService } from 'services'
import {
  FEATURE_FLAGS,
  PAGE_CON,
  REDUCER_STATE,
  REDUCER_TYPE
} from 'constant/constants'
import { GlobalContext } from 'context/GlobalStateProvider'
import { ESGScores, GroupButton, IconError, InfiniteList } from 'components'
import PortfolioMonitoringModal from './Modal'
import getMonthYear, { getMonthDayYear } from 'utils/Date'
import Error from 'utils/error'
import LoadingMask from 'utils/loadingMask'

const PortfolioMonitoring = ({ id, entitlements }) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedControversy, setSelectedControversy] = useState(null)
  const context = useContext(GlobalContext)

  const [dashboardState, dashboardDispatch] = context.globalDashboard
  const [filterState] = context.globalFilter
  const [, entityHeaderDispatch] = useContext(GlobalContext)
    ?.globalEntityHeader || ['', '']

  const [last60Days, setLast60Days] = useState(undefined)
  const [criticalFilter, setCriticalFilter] = useState(1)

  const isFilterStateAvailable =
    filterState.filterData.regionsList.length > 0 ||
    filterState.filterData.sectorsList.length > 0

  let apiCallId = dashboardState.apiCallId

  useEffect(() => {
    if (
      last60Days !==
      dashboardState.dashboardPortfolioMonitoringControversiesList.data
        .last60Days
    ) {
      setLast60Days(
        dashboardState.dashboardPortfolioMonitoringControversiesList.data
          .last60Days
      )
    }
  }, [
    dashboardState.dashboardPortfolioMonitoringControversiesList.data.last60Days
  ])

  const getControversiesList = async (
    last60Days = true,
    forceReload = false
  ) => {
    if (
      dashboardState.dashboardPortfolioMonitoringControversiesList
        .dataStatus !== '' &&
      forceReload === false
    )
      return

    if (filterState.globalPortfolioFilter?.portfolio) {
      const { regions, sectors, portfolio, asOfDate } =
        filterState.globalPortfolioFilter
      dashboardDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName:
          REDUCER_STATE.DASHBOARD_PORTFOLIOMONITORING_CONTROVERSIES_LIST
      })
      try {
        const data = isFilterStateAvailable
          ? await DashboardService.getPorfolioMonitoringControversiesList({
              portfolio,
              regions,
              sectors,
              asOfDate,
              last60Days
            })
          : []

        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          sectionName:
            REDUCER_STATE.DASHBOARD_PORTFOLIOMONITORING_CONTROVERSIES_LIST,
          data: {
            last60Days,
            list: data
          },
          apiCallId
        })
      } catch (error) {
        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName:
            REDUCER_STATE.DASHBOARD_PORTFOLIOMONITORING_CONTROVERSIES_LIST,
          apiCallId
        })
      }
    }
  }

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      let filters = filterState.globalPortfolioFilter
      if (filters.portfolio !== '' && !filterState.isLoading) {
        getControversiesList(last60Days)
      }
    }
  }, [filterState.actionData])

  const openModal = (row) => {
    setSelectedControversy(row)
    setShowModal(true)
  }

  const openEntityModal = (row) => {
    const selectedBVD9_Id = row?.parent_orbis_id || row?.bvd9_number
    if ((selectedBVD9_Id || '') !== '') {
      entityHeaderDispatch({
        type: REDUCER_TYPE.SET_ORBIB_ID,
        selectedOrbisId: selectedBVD9_Id
      })
    }
  }

  const manageTextOverflow = (controversy_location) => {
    const charLength = 75
    return controversy_location?.length > charLength
      ? controversy_location.substring(0, charLength) + '...'
      : controversy_location
  }

  const mappedListData = (data) => {
    return (
      data
        .filter(
          ({ severity }) =>
            ({
              1: true,
              2: severity === 'Critical',
              3: severity !== 'Critical'
            }[criticalFilter])
        )
        .map((row) => {
          const {
            controversy_title,
            controversy_events,
            severity,
            title,
            controversy_location,
            sector
          } = row
          return [
            <S.ListCellContainer key="date" className="date">
              {getMonthDayYear(controversy_events)}
            </S.ListCellContainer>,
            <S.ListCellContainer key="severity" className="critical">
              {severity === 'Critical' && <IconError />}
            </S.ListCellContainer>,
            <S.ListCellContainer
              key="description"
              className="description"
              onClick={() => openModal(row)}
            >
              {controversy_title}
            </S.ListCellContainer>,
            <S.ListCellContainer
              key="companyName"
              className="CompanyNameWrapper Portfolio"
            >
              <S.ListCellContainer
                className="ellipsis"
                title={title}
                onClick={() => openEntityModal(row)}
              >
                <S.ListCellContainer className="entity">
                  {title}
                </S.ListCellContainer>
              </S.ListCellContainer>
            </S.ListCellContainer>,
            <S.ListCellContainer key="sector" className="sector">
              {sector}
            </S.ListCellContainer>,
            <S.ListCellContainer
              key="country"
              className="country"
              title={controversy_location}
            >
              {manageTextOverflow(controversy_location)}
            </S.ListCellContainer>
          ]
        }) || []
    )
  }

  const memoizedMappedListData = useMemo(
    () =>
      mappedListData(
        dashboardState.dashboardPortfolioMonitoringControversiesList.data
          .list || []
      ),
    [
      dashboardState.dashboardPortfolioMonitoringControversiesList.data.list,
      criticalFilter
    ]
  )

  const contentInfo = [
    {
      buttonKey: 1,
      labelText: 'Last 60 Days',
      buttonDisabled:
        dashboardState.dashboardPortfolioMonitoringControversiesList.isLoading
    }
  ]

  if (isFilterStateAvailable && getMonthYear(filterState)) {
    contentInfo.push({
      buttonKey: 2,
      labelText: getMonthYear(filterState),
      buttonDisabled:
        dashboardState.dashboardPortfolioMonitoringControversiesList.isLoading
    })
  }

  const criticalFilterButtons = [
    {
      buttonKey: 1,
      labelText: 'All',
      buttonDisabled:
        dashboardState.dashboardPortfolioMonitoringControversiesList.isLoading
    },
    {
      buttonKey: 2,
      labelText: 'Critical',
      buttonDisabled:
        dashboardState.dashboardPortfolioMonitoringControversiesList.isLoading
    },
    {
      buttonKey: 3,
      labelText: 'Not Critical',
      buttonDisabled:
        dashboardState.dashboardPortfolioMonitoringControversiesList.isLoading
    }
  ]

  const handleButtonChange = (event) => {
    getControversiesList(event === 1, true)
    setLast60Days(event === 1)
  }

  const handleCriticalChange = (event) => {
    setCriticalFilter(event)
  }

  const buttonHidden =
    filterState.isLoading ||
    (filterState.actionData === PAGE_CON.INITIAL_DATA &&
      dashboardState.dashboardPortfolioMonitoringControversiesList.isLoading)

  const reloadComponent = () => {
    getControversiesList(last60Days, true)
  }

  const listData = mappedListData(
    dashboardState.dashboardPortfolioMonitoringControversiesList.data.list || []
  )

  const hideFilter =
    (filterState.actionData === PAGE_CON.INITIAL_DATA &&
      dashboardState.dashboardPortfolioMonitoringControversiesList.isLoading) ||
    (
      dashboardState.dashboardPortfolioMonitoringControversiesList.data.list ||
      []
    ).length === 0

  return (
    <>
      {showModal && (
        <PortfolioMonitoringModal
          closeModalAction={() => setShowModal(false)}
          selectedControversy={selectedControversy}
        />
      )}
      <div id={id}>
        <S.SectionTitle>
          <span>Portfolio Monitoring</span>
          {!buttonHidden && (
            <GroupButton
              id="button-controversy-test-id-1"
              buttonClass="buttonPrimary"
              groupButtonContentComponent={contentInfo}
              defaultButton={last60Days ? 1 : 2}
              groupButtonWidth="320px"
              onChange={handleButtonChange}
              buttonWidth="auto"
            />
          )}
        </S.SectionTitle>
        {FEATURE_FLAGS.ESG_MAP_SECTOR && entitlements.PortfolioAnalysis.esg && (
          <ESGScores
            e={{ score: '77', controversy: '15' }}
            s={{ score: '24', controversy: '78' }}
            g={{ score: '49', controversy: '111' }}
          />
        )}
        {!hideFilter && (
          <GroupButton
            id="button-critical-test-id-1"
            buttonClass="buttonPrimary"
            groupButtonContentComponent={criticalFilterButtons}
            defaultButton={criticalFilter}
            groupButtonWidth="320px"
            onChange={handleCriticalChange}
            buttonWidth="auto"
            buttonMarginBotom="15px"
          />
        )}

        {dashboardState.dashboardPortfolioMonitoringControversiesList
          .dataStatus === PAGE_CON.ERROR ||
        (dashboardState.dashboardPortfolioMonitoringControversiesList
          .dataStatus === PAGE_CON.SUCCESS &&
          listData.length === 0) ? (
          <Error
            id={'pmError'}
            dataStatus={
              dashboardState.dashboardPortfolioMonitoringControversiesList
                .dataStatus
            }
            onErrorClick={reloadComponent}
            errorOccuredOn={
              PAGE_CON.DASHBOARD_PORTFOLIOMONITORING_CONTROVERSIES_LIST
            }
          />
        ) : dashboardState.dashboardPortfolioMonitoringControversiesList
            .isLoading ? (
          <LoadingMask />
        ) : (
          <InfiniteList data={memoizedMappedListData} notClickable={true} />
        )}
      </div>
    </>
  )
}

PortfolioMonitoring.propTypes = {}

export default PortfolioMonitoring
