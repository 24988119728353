import React from 'react'
import { Box, Container } from '@material-ui/core'
import numberData from 'utils/numberFormat'
import PropTypes from 'prop-types'
import { ESGScoreBar } from 'components'
import * as S from './styles'

const IssuerScore = (props) => {
  const classes = S.useStyles(props)
  const { data } = props

  return (
    <>
      {data && data.score_categories && (
        <>
          <Container className={classes.esgScore}>
            <Box className={classes.cardEsgScore}>
              <Box className={classes.cardEsgScoreBottom}>
                <Box>
                  {numberData(data?.score_categories[0]?.score, {
                    isDecimal: false
                  }) + '/100'}
                </Box>
              </Box>
            </Box>
            <Box className={classes.cardScore}>
              <Box className={classes.cardScoreValues}>
                <Box
                  className={classes.cardScoreValuesTitle}
                >{`${data?.score_categories?.[1]?.criteria}`}</Box>
                <Box className={classes.cardScoreNumber}>
                  {numberData(data?.score_categories?.[1]?.score, {
                    isDecimal: false
                  }) + '/100'}
                </Box>
                <Box>
                  <ESGScoreBar
                    value={numberData(data?.score_categories?.[1]?.score, {
                      isDecimal: false
                    })}
                  />
                </Box>
              </Box>
              <Box className={classes.cardScoreValues}>
                <Box>
                  <Box
                    className={classes.cardScoreValuesTitle}
                  >{`${data?.score_categories?.[2]?.criteria}`}</Box>
                  <Box className={classes.cardScoreNumber}>
                    {numberData(data?.score_categories?.[2]?.score, {
                      isDecimal: false
                    }) + '/100'}
                  </Box>
                  <Box>
                    <ESGScoreBar
                      value={numberData(data?.score_categories?.[2]?.score, {
                        isDecimal: false
                      })}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className={classes.cardScoreValues}>
                <Box>
                  <Box
                    className={classes.cardScoreValuesTitle}
                  >{`${data?.score_categories?.[3]?.criteria}`}</Box>
                  <Box className={classes.cardScoreNumber}>
                    {numberData(data?.score_categories?.[3]?.score, {
                      isDecimal: false
                    }) + '/100'}
                  </Box>
                  <Box>
                    <ESGScoreBar
                      value={numberData(data?.score_categories?.[3]?.score, {
                        isDecimal: false
                      })}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              className={classes.updated}
            >{`Updated on ${data?.last_updated}`}</Box>
          </Container>
        </>
      )}
    </>
  )
}

IssuerScore.prototype = {
  /** issuer score data */
  data: PropTypes.any,
  /** component showed for Issuer page, true or false */
  isIssuer: PropTypes.bool
}

export default IssuerScore
