/* eslint-disable*/
import {
  AppBar,
  Drawer,
  Link,
  ListItemIcon,
  MenuItem,
  Toolbar
} from '@material-ui/core'
import { Lock as LockIcon } from 'react-feather'
import { Link as RouterLink } from 'react-router-dom'
import React, { useState, useEffect, useContext } from 'react'
import themeColorPalette from 'theme/theme'
import {
  PrimaryLink,
  PrimaryButton,
  IconClose,
  IconMenu,
  IconReview,
  IconDashboard,
  ScenarioIcon,
  IconEye
} from 'components'
import { GlobalContext } from 'context/GlobalStateProvider'
import { useOktaAuth } from '@okta/okta-react'
import getEntitlements from 'entitlements'
import EntitySearch from 'entitySearch/index.jsx'
import { hexToRGB } from 'utils/color'
import {
  FEATURE_FLAGS,
  PAGE_CON,
  REDUCER_STATE,
  REDUCER_TYPE,
  ROUTE_PATHS
} from 'constant/constants'
import EntityModal from 'views/entityProfile/entity/EntityModal'
import OnDemandAssessmentModal from 'views/ondemandassessment/OnDemandAssessmentModal'
import PortfolioManagement from 'portfolioManagement/PortfolioManagement'
import PortfolioDelete from 'portfolioManagement/PortfolioDelete'
import PortfolioCalculations from './Drawers/PortfolioCalculations'
import { formattingRules } from 'views/ondemandassessment/CriteriaFilterData'
import { v4 as uuidv4 } from 'uuid'
import { clsx } from 'clsx'
import * as S from './styles'

export default function Header(props) {
  const { authState } = useOktaAuth()
  const context = useContext(GlobalContext)
  const [, globalDispatch] = context.globalManager
  const [portfolioReviewState] = useContext(GlobalContext).globalPortfolioReview
  const [entityHeaderState, entityHeaderDispatch] =
    useContext(GlobalContext).globalEntityHeader
  const [, climateDispatch] = useContext(GlobalContext).globalClimate
  const [filterState, filterDispatch] = useContext(GlobalContext).globalFilter
  const [portfolioManagementState, portfolioManagementDispatch] =
    useContext(GlobalContext).globalPortfolioManagement
  const [onDemandAsmntState, setOnDemandAsmntDispatch] =
    useContext(GlobalContext).onDemandAsmntService
  const [dashboardState] = useContext(GlobalContext).globalDashboard
  const [userSettingState, userSettingDispatch] =
    useContext(GlobalContext)?.userSettingService
  const {
    header,
    drawerContainer,
    menuItem,
    menuTitle,
    listItemIcon,
    entityTempPage,
    dialogToolbar,
    drawerToggle,
    drawerToggleIssuer,
    drawerToggleSearch,
    subheader,
    globalHeaderDrawer,
    drawerIcon,
    portfolioDrawer,
    borderStyle,
    menuDivision,
    menuItemAlignment
  } = S.useStyles()
  const entitlements = getEntitlements(
    authState,
    props.apiEntitlements,
    props.contentTypeEntitlements
  )
  const isPredictedScore =
    props.apiEntitlements &&
    props.apiEntitlements.includes(PAGE_CON.MESG_ESG_PREDICTOR_SCORE_ID)

  const path =
    window.location.pathname === '/' && entitlements['issuer']
      ? '/issuerworkspace'
      : window.location.pathname === '/'
      ? '/dashboard'
      : window.location.pathname
  const [tab, setTab] = React.useState(path)
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
    calcDrawerOpen: false
  })
  const [entityOpen, setEntityOpen] = React.useState(false)
  const [portfolioSettings, setPortfolioSettings] = React.useState(false)
  const [calcSettings, setCalcSettings] = React.useState(false)
  const [displayESGVisible, setDisplayESGVisible] = React.useState(
    window.localStorage.displayESGVisible !== undefined
      ? JSON.parse(window.localStorage.displayESGVisible)
      : 1
  )
  const { drawerOpen, calcDrawerOpen } = state
  const [isDisplayEsgCheckboxChanged, setIsDisplayEsgCheckboxChanged] =
    React.useState(false)

  const getPageView = {
    dashboard: entitlements['dashboard']
      ? PAGE_CON.DASHBOARD_TITLE
      : PAGE_CON.PAGE_NOT_FOUND,
    portfolioanalysis: entitlements['portfolioanalysis']
      ? PAGE_CON.PAGE_TITLE
      : PAGE_CON.PAGE_NOT_FOUND,
    scenarios: entitlements['scenarios']
      ? 'Scenario Analysis'
      : PAGE_CON.PAGE_NOT_FOUND,
    screening: entitlements['screening']
      ? 'Advanced Screening'
      : PAGE_CON.PAGE_NOT_FOUND,
    issuerworkspace: entitlements['entityprofile']
      ? `ESG360`
      : PAGE_CON.PAGE_NOT_FOUND,
    sector: entitlements['issuerp3'] ? `ESG360` : PAGE_CON.PAGE_NOT_FOUND,
    reportingservice: entitlements['reportingservice']
      ? PAGE_CON.ESG_REPORTING_PORTAL
      : PAGE_CON.PAGE_NOT_FOUND
  }
  const assessmentRequested =
    !!dashboardState?.dashboardResearchLines?.data?.coverageData
      ?.assessment_requested
  const percAvailForAssessment = formattingRules(
    100 *
      dashboardState?.dashboardResearchLines?.data?.coverageData
        ?.perc_avail_for_assessment || 0
  )
  const hideEntitySearch = window.location.pathname.includes(
    ROUTE_PATHS.PORTFOLIO_ANALYSIS_STATUS.replace('/:id', '')
  )

  const handleSideBarChange = (event, newTab) => {
    if (pageView === event.currentTarget.innerText) {
      setState((prevState) => ({ ...prevState, drawerOpen: false }))
    } else if (
      event.currentTarget.innerText === PAGE_CON.ON_DEMAND_ASMNT_TITLE
    ) {
      setOnDemandAsmntDispatch({
        type: REDUCER_TYPE.ON_DEMAND_ASMNT_MODAL,
        modalOpen: true,
        assessmentRequested,
        portfolio: filterState?.globalPortfolioFilter.portfolio
      })
    } else {
      if (newTab !== null) {
        setTab(newTab)
        setPageView(event.currentTarget.innerText)
      }
    }
    setState((prevState) => ({ ...prevState, drawerOpen: false }))
    setPortfolioSettings(false)
    setCalcSettings(false)
  }

  const [pageView, setPageView] = React.useState(
    getPageView[path.split('/')[1]] || PAGE_CON.PAGE_NOT_FOUND
  )
  const headersData = [
    {
      label: 'Climate Dashboard',
      entitled: 'dashboard',
      href: '/dashboard',
      icon: <IconDashboard />
    },
    {
      label: 'Climate Portfolio Analysis',
      entitled: 'portfolioanalysis',
      href: `/portfolioanalysis/${portfolioReviewState.portfolioReviewPage}`,
      icon: <IconReview />
    },
    {
      label: 'On-Demand Assessment Request',
      entitled: 'ondemandassessment',
      disabled: percAvailForAssessment === '0%'
    },
    {
      label: 'Scenario Analysis',
      entitled: 'scenarios',
      href: '/scenarios',
      icon: <ScenarioIcon />
    },
    {
      label: 'On Demand Scores',
      entitled: 'ondemandscores',
      href: '/ondemandscores',
      icon: <LockIcon />
    },
    {
      label: 'Advanced Screening',
      entitled: 'screening',
      href: '/screening',
      icon: <IconEye />
    }
  ]

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1100
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }))
    }

    setResponsiveness()
    window.addEventListener('resize', setResponsiveness, true)
    return () => window.removeEventListener('resize', setResponsiveness, true)
  }, [path, entityHeaderState.globalEntityHeader.company_name])

  useEffect(() => {
    if (entityHeaderState.selectedOrbisId !== '') setEntityOpen(true)
  }, [entityHeaderState.selectedOrbisId])

  useEffect(() => {
    setTab(path)
    setPageView(getPageView[path.split('/')[1]] || PAGE_CON.PAGE_NOT_FOUND)
  }, [path, pageView])

  const handleEntityPopupClose = () => {
    setEntityOpen(false)
    entityHeaderDispatch({ type: REDUCER_TYPE.RESET })
    climateDispatch({ type: REDUCER_TYPE.RESET })
  }

  const displayMobile = () => {
    const handleDrawerOpen = () => {
      setState((prevState) => ({ ...prevState, drawerOpen: true }))
      setTab(window.location.pathname)
    }
    const handleDrawerClose = (
      selectPortfolioSettings = false,
      selectCalcSettings = false
    ) => {
      setState((prevState) => ({ ...prevState, drawerOpen: false }))
      setTab(window.location.pathname)
      setPortfolioSettings(selectPortfolioSettings)
      setCalcSettings(selectCalcSettings)
    }

    const handleCalcDrawerOpen = () => {
      setState((prevState) => ({
        ...prevState,
        drawerOpen: false,
        calcDrawerOpen: true
      }))
    }

    const handleCalcDrawerClose = (selectCal = false) => {
      setCalcSettings(selectCal)
      userSettingDispatch({
        type: REDUCER_TYPE.USER_SETTING_MANAGEMENT,
        openCalculation: false
      })
      if (userSettingState.userSetting.changed) {
        userSettingDispatch({
          type: REDUCER_TYPE.USER_SETTING_CHANGED,
          sectionName: REDUCER_STATE.USER_SETTING,
          changed: false
        })
        const uniqueVal = PAGE_CON.CACULATIONS_TITLE + '$$' + uuidv4()
        globalDispatch({ type: REDUCER_TYPE.RESET_ALL, payload: context })
        filterDispatch({
          type: REDUCER_TYPE.UPDATED_ACTION,
          actionData: uniqueVal
        })
      }
    }

    const onCalculationClick = () => {
      handleDrawerClose(false, true)
      userSettingDispatch({
        type: REDUCER_TYPE.USER_SETTING_MANAGEMENT,
        openCalculation: true
      })
    }

    const handleDisplayESGVisibleToggle = (event) => {
      window.localStorage.displayESGVisible = event?.target?.checked ? 1 : 0
      setDisplayESGVisible(JSON.parse(window.localStorage.displayESGVisible))
      setIsDisplayEsgCheckboxChanged(true)
    }

    const onContactUsClick = () => {
      window.open('https://www.moodys.com/Pages/contactus.aspx')
    }
    const onTnCClick = () => {
      window.open('/terms')
    }
    const onClimateOnDemandClick = () => {
      window.open('https://climate-on-demand.moodys.com/AppsServices/')
    }
    const onCompanyPortalClick = () => {
      window.open('https://veconnect.vigeo.com/login.html')
    }
    const onDataLabClick = () => {
      window.open('https://ve-datalab.com/')
    }

    const onPortSettingsClick = () => {
      handleDrawerClose(true)
      portfolioManagementDispatch({
        type: REDUCER_TYPE.PORTFOLIO_MANAGEMENT,
        openPortfolioManagement: true,
        portfolioManagementView: PAGE_CON.PORTFOLIO_LIST
      })
    }

    const handlePortfolioClose = () => {
      setPortfolioSettings(false)
      portfolioManagementDispatch({
        type: REDUCER_TYPE.PORTFOLIO_MANAGEMENT,
        openPortfolioManagement: false
      })
    }

    const onMovePrevClick = () => {
      setState((prevState) => ({
        ...prevState,
        drawerOpen: true,
        calcDrawerOpen: false
      }))
      setTab(window.location.pathname)
      setPortfolioSettings(true)
      portfolioManagementDispatch({
        type: REDUCER_TYPE.PORTFOLIO_MANAGEMENT,
        openPortfolioManagement: false
      })
    }

    const onMoveCalcPrevClick = () => {
      setState((prevState) => ({
        ...prevState,
        drawerOpen: true,
        calcDrawerOpen: false
      }))
      setTab(window.location.pathname)
      handleCalcDrawerClose(true)
    }

    const isPredictedScore =
      props.apiEntitlements &&
      props.apiEntitlements.includes(PAGE_CON.MESG_ESG_PREDICTOR_SCORE_ID)
    const isTransitionRisk =
      props.apiEntitlements &&
      props.apiEntitlements.includes(PAGE_CON.MESG_TRN_RSK_ID)

    const getPageLabel = () => {
      if (pageView === PAGE_CON.PAGE_NOT_FOUND) return pageView
      if (entitlements['issuer']) {
        return "Moody's " + pageView
      }
      if (pageView === PAGE_CON.ESG_REPORTING_PORTAL) {
        return pageView
      }
      return 'Climate ' + pageView
    }

    const showCalculations =
      isTransitionRisk && FEATURE_FLAGS.CARBON_FOOTPRINT_WAC

    const getEntitlementBasedClassName =
      entitlements['portfoliosettings'] && entitlements.climate

    const universalTopBar = (
      <Toolbar
        disableGutters
        style={entitlements['issuer'] ? S.issuerToolBarStyle : S.toolbarStyle}
      >
        <MenuItem
          style={{
            fontSize: '14px !important',
            color: entitlements['issuer']
              ? themeColorPalette.palette.white.main
              : themeColorPalette.palette.sys_trad.main.main
          }}
          onClick={!entitlements.issuer ? handleDrawerOpen : undefined}
          className={
            !entitlements['issuer'] ? drawerToggle : drawerToggleIssuer
          }
          heap_id="menu"
          heap_menu={pageView ? pageView : ''}
        >
          {!entitlements['issuer'] && <IconMenu className={drawerIcon} />}
          {getPageLabel()}
        </MenuItem>
        <PortfolioDelete></PortfolioDelete>
        <Drawer
          {...{
            anchor: 'left',
            open: portfolioManagementState.openPortfolioManagement,
            onClose: handlePortfolioClose,
            id: 'portfolio-drawer-test-id',
            PaperProps: { className: portfolioDrawer },
            BackdropProps: {
              style: {
                backgroundColor: themeColorPalette.palette.sys_trad.main.main,
                opacity: 0.2
              }
            }
          }}
        >
          <PortfolioManagement
            onMovePrevClick={onMovePrevClick}
            handlePortfolioClose={handlePortfolioClose}
            entitlements={entitlements}
          ></PortfolioManagement>
        </Drawer>
        <Drawer
          {...{
            anchor: 'left',
            open: drawerOpen,
            onClose: () => handleDrawerClose(),
            id: 'topbar-drawer-test-id',
            PaperProps: { className: globalHeaderDrawer },
            BackdropProps: {
              style: {
                backgroundColor: themeColorPalette.palette.sys_trad.main.main,
                opacity: 0.2
              }
            }
          }}
        >
          <MenuItem className={menuTitle}>
            Navigate To
            <span style={{ marginTop: '9px' }}>
              <IconClose
                style={{ cursor: 'pointer', marginTop: 4 }}
                onClick={() => handleDrawerClose()}
              />
            </span>
          </MenuItem>
          <div id="topMenuItem" className={drawerContainer}>
            {getDrawerChoices()}
          </div>
          <div
            className={clsx(
              drawerContainer,
              getEntitlementBasedClassName ? menuDivision : ''
            )}
          >
            {entitlements['portfoliosettings'] && (
              <MenuItem
                className={menuItem}
                onClick={onPortSettingsClick}
                heap_id="portfolioSetting"
                heap_menu={'Portfolio Selection/Upload'}
                selected={portfolioSettings}
              >
                Portfolio Selection/Upload
              </MenuItem>
            )}
            {showCalculations && (
              <MenuItem
                className={menuItem}
                onClick={onCalculationClick}
                heap_id="menu"
                heap_menu={'Calculations'}
                selected={calcSettings}
              >
                {PAGE_CON.CACULATIONS_TITLE}
              </MenuItem>
            )}
            {entitlements['reportingservice'] && (
              <Link
                {...{
                  component: RouterLink,
                  to: '/reportingservice',
                  color: 'inherit',
                  style: { textDecoration: 'none' },
                  key: PAGE_CON.ESG_REPORTING_PORTAL
                }}
              >
                <MenuItem
                  className={menuItem}
                  selected={
                    path.startsWith('/' + '/reportingservice'.split('/')[1]) &&
                    !portfolioSettings &&
                    !calcSettings
                  }
                  onClick={handleSideBarChange}
                  id={'on-demand-reporting-topbar-menuitem'}
                  heap_id="menu"
                  heap_menu={PAGE_CON.ESG_REPORTING_PORTAL}
                >
                  {PAGE_CON.ESG_REPORTING_PORTAL}
                </MenuItem>
              </Link>
            )}
          </div>
          <MenuItem
            className={`${menuItem} ${menuDivision} ${menuItemAlignment}`}
            onClick={onContactUsClick}
            heap_id="menu"
            heap_menu={'Contact Us'}
          >
            Contact Us
          </MenuItem>
          <MenuItem
            className={`${menuItem} ${menuItemAlignment}`}
            onClick={onTnCClick}
            heap_id="menu"
            heap_menu={'Terms & Conditions'}
          >
            Terms &amp; Conditions
          </MenuItem>
          <MenuItem
            className={`${menuItem} ${menuItemAlignment}`}
            onClick={props.onLogout}
            heap_id="menu"
            heap_menu={'Log Out'}
          >
            Log Out
          </MenuItem>
        </Drawer>

        <PortfolioCalculations
          handleCalcDrawerClose={handleCalcDrawerClose}
          calcDrawerOpen={userSettingState.openCalculation}
          useStyles={S.useStyles}
          handleCalcDrawerOpen={handleCalcDrawerOpen}
          handleDisplayESGVisibleToggle={handleDisplayESGVisibleToggle}
          displayESGVisible={displayESGVisible}
          isPredictedScore={false}
          isTransitionRisk={isTransitionRisk}
          onMovePrevClick={onMoveCalcPrevClick}
          getUserSettings={props.getUserSettings}
        />
        <div style={{ display: 'flex' }}>
          {entitlements['issuer'] && (
            <>
              {!window.location.pathname.includes('/sector') && (
                <PrimaryLink
                  id="logout-header-link-id"
                  style={{
                    fontSize: '13px !important',
                    color: 'white !important'
                  }}
                  linkText="Log Out"
                  linkMarginTop={entitlements['issuerp3'] ? '4px' : '0px'}
                  reducedSize13={true}
                  linkMarginRight="16px"
                  onClick={props.onLogout}
                  linkClass="linkIssuer"
                />
              )}

              {entitlements['issuerp3'] ? (
                window.location.pathname.includes('/sector') ? (
                  <PrimaryButton
                    id="close-button"
                    buttonText="X"
                    buttonClass="buttonAltGhost"
                    buttonMinWidth="5px"
                    buttonPadding="8px"
                    buttonHeight="24px"
                    buttonMarginRight="16px"
                    onClick={(e) => {
                      window.close()
                    }}
                  />
                ) : (
                  <PrimaryButton
                    id="contact-button"
                    buttonText="Contact Us"
                    buttonClass="buttonAltGhost"
                    buttonWidth="76px"
                    buttonPadding="6px"
                    buttonHeight="24px"
                    buttonMarginRight="16px"
                    onClick={(e) => {
                      window.location = 'mailto:ESG360@moodys.com'
                    }}
                  />
                )
              ) : null}
            </>
          )}

          {!entitlements['issuer'] && !hideEntitySearch && (
            <EntitySearch entitlements={entitlements}></EntitySearch>
          )}
        </div>
        {entityOpen && (
          <EntityModal
            entityOpen={entityOpen}
            orbisId={entityHeaderState.selectedOrbisId}
            handleEntityPopupClose={handleEntityPopupClose}
            entitlements={entitlements}
          />
        )}
        {onDemandAsmntState.modalOpen ? <OnDemandAssessmentModal /> : null}
      </Toolbar>
    )

    return !entitlements['issuer']
      ? window.location.pathname.includes('/terms')
        ? null
        : universalTopBar
      : entitlements['issuer'] && (
          <div
            id="issuer-banner-id"
            style={{
              backgroundImage: 'url(/issuer_bg.png)',
              backgroundSize: '100% 1000%',
              height: '48px'
            }}
          >
            <div
              style={{
                height: '48px',
                backgroundColor: hexToRGB('#26415e', 0.8)
              }}
            >
              {universalTopBar}
            </div>
          </div>
        )
  }

  const getDrawerChoices = () => {
    return headersData.map(
      ({ label, href, icon, entitled, disabled = false }) => {
        return entitlements[entitled] ? (
          <Link
            {...{
              component: RouterLink,
              to: href || path,
              color: 'inherit',
              style: {
                textDecoration: 'none',
                cursor: disabled ? 'not-allowed' : 'inherit'
              },
              key: label
            }}
          >
            <MenuItem
              className={menuItem}
              disabled={disabled}
              selected={
                path.startsWith('/' + href?.split('/')[1]) &&
                !portfolioSettings &&
                !calcSettings
              }
              onClick={handleSideBarChange}
              id={label + 'topbar-menuitem-test-id'}
              heap_id="menu"
              heap_menu={label}
            >
              <ListItemIcon
                className={listItemIcon}
                style={{ marginRight: icon ? '13px' : 0 }}
              >
                {icon}
              </ListItemIcon>
              {label}
            </MenuItem>
          </Link>
        ) : null
      }
    )
  }

  return (
    <header>
      <AppBar className={header} id="topbar-appbar-test-id">
        {displayMobile()}
      </AppBar>
    </header>
  )
}
