import { makeStyles } from '@material-ui/core'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

const useStyles = makeStyles(() => ({
  container_for_cardScore: {
    display: 'inline-flex'
  },
  horizontal_bar: {
    height: '2px',
    backgroundColor: themeColorPalette.palette.sys_trad.white.main,
    borderLeft: '1.25px solid ' + themeColorPalette.palette.sys_trad.main.main,
    borderRight: '3px solid ' + themeColorPalette.palette.sys_trad.main.main,
    borderRadius: '10px'
  },
  hrzntlBrMsg: {
    display: 'flex',
    ...textTheme.typography.p5,
    color: themeColorPalette.palette.sys_trad.white.main,
    opacity: '0.3',
    paddingTop: '5px',
    fontSize: '10px'
  },
  emerging: {
    width: '1px',
    marginLeft: '-20px'
  },
  emergingSelected: {
    marginLeft: '0px'
  },
  slctdScore: {
    opacity: '1'
  },
  esg_header: {
    display: 'flex',
    justifyContent: 'start',
    ...textTheme.typography.h2,
    color: 'white',
    marginBottom: '5px'
  },
  esgScore: {
    padding: '0px',
    textAlign: 'center',
    minWidth: '150px',
    margin: '0px'
  },
  cardScore: {
    display: 'flex',
    color: (props) => (props.fontColor ? props.fontColor : 'white'),
    backgroundColor: (props) =>
      props.overallGradeColor ? props.overallGradeColor : 'white',
    width: (props) => (props.width ? props.width : 'auto')
  },
  cardScoreTemplate: {
    display: 'flex',
    width: (props) => (props.scoreBoxWidth ? props.scoreBoxWidth : '16px'),
    height: '50px',
    margin: '2px 2px 7px 0px',
    paddingTop: '2px',
    paddingBottom: '2px',
    ...textTheme.typography.p3,
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardScoreBox: {
    backgroundColor: (props) =>
      props.gradeColor
        ? props.gradeColor
        : themeColorPalette.palette.sys_trad.disabled.main
  },
  selectedScoreBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)'
  },
  selectedScore: {
    ...textTheme.typography.h1,
    backgroundColor: (props) =>
      props.selectedScoreColor
        ? props.selectedScoreColor
        : themeColorPalette.palette.sys_trad.main.main,
    width: (props) =>
      props.selectedScoreBoxWidth ? props.selectedScoreBoxWidth : '100px',
    color: 'white',
    fontSize: '24px'
  }
}))

export default useStyles
