import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'
import clsx from 'clsx'

const useStyles = makeStyles(() => ({
  container: {
    display: (props) => (props.hasError ? 'block' : 'flex'),
    flex: 1,
    justifyContent: 'center'
  },
  mapContainer: {
    width: '100%'
  },
  tableContainer: {
    maxHeight: '530px',
    overflowY: 'scroll'
  },
  tableCell: {
    display: 'inline-block',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  clickable: {
    cursor: 'pointer',
    textDecoration: (props) => props.underlined && 'underline'
  },
  panelContainer: {
    width: (props) => (props.width ? props.width : '500px'),
    padding: '0px 20px'
  },
  entityContainer: {
    padding: '10px 0px',
    '& table thead tr': {
      borderTop: 'none'
    },
    '& table thead th': {
      ...theme.typography.p3,
      height: '20px'
    }
  },
  entityScoreTitle: {
    ...theme.typography.h2,
    marginBottom: '0px',
    paddingBottom: '0px',
    marginTop: '10px',
    paddingLeft: '8px'
  }
}))

export const Container = ({ children, hasError }) => {
  const classes = useStyles({ hasError })
  return <div className={classes.container}>{children}</div>
}

export const MapContainer = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.mapContainer}>{children}</div>
}

export const TableContainer = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.tableContainer}>{children}</div>
}

export const TableCell = ({ children, onClick, underlined = false, title }) => {
  const classes = useStyles({ underlined })
  return (
    <span
      title={title}
      onClick={onClick}
      aria-hidden={true}
      className={clsx(classes.tableCell, onClick && classes.clickable)}
    >
      {children}
    </span>
  )
}

export const PanelContainer = ({ children, width }) => {
  const classes = useStyles({ width })
  return <div className={classes.panelContainer}>{children}</div>
}

export const EntityContainer = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.entityContainer}>{children}</div>
}

export const EntityScoreTitle = ({ children }) => {
  const classes = useStyles()
  return <h4 className={classes.entityScoreTitle}>{children}</h4>
}
