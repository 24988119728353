import { COMMON, SME_SERVICE } from 'constant/APIconstants'
import axios from 'axios'

export const submitAssessmentRequest = async ({ portfolio, body }) => {
  const { data } = await axios.post(
    `${COMMON.BASE_URL}${portfolio}${SME_SERVICE.ON_DEMAND_ASSESSMENT}`,
    { ...body }
  )
  return data
}

export const fetchCriteriaFilterData = async ({ portfolio, cancelToken }) => {
  const { data } = await axios.post(
    `${COMMON.BASE_URL}${portfolio}${SME_SERVICE.ON_DEMAND_ASSESSMENT}${SME_SERVICE.SME_FILTER}`,
    {},
    { cancelToken }
  )
  return data
}

export const fetchBatchRequest = async ({ portfolio, cancelToken }) => {
  const { data } = await axios.get(
    `${COMMON.BASE_URL}${portfolio}${SME_SERVICE.ON_DEMAND_ASSESSMENT}${SME_SERVICE.SME_BATCH_REQUEST}`,
    { cancelToken }
  )
  return data
}

export const getOnDemandAsmntLimit = async ({ cancelToken }) => {
  const { data } = await axios.get(
    `${SME_SERVICE.BASE_URL}${SME_SERVICE.ASSESSMENT_INFO}`,
    { cancelToken: cancelToken }
  )
  return data
}

export const getOnDemandPortfolios = async (cancelToken) => {
  const { data } = await axios.get(
    `${SME_SERVICE.BASE_URL}${SME_SERVICE.PORTFOLIOS}`,
    { cancelToken: cancelToken }
  )
  return data
}
