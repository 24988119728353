import React from 'react'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: '#333333',
    fontSize: theme.typography.pxToRem(12),
    padding: '10px 15px 10px 25px',
    boxShadow: '0px 0px 15px 5px rgba(211, 211, 211, 0.5)',
    maxWidth: '100%',
    borderRadius: '0',
    border: '1px solid rgba(211, 211, 211, 0.6)',
    lineHeight: '20px',
    margin: '0 !important'
  }
}))
