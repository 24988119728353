import { COMMON, PARAMS } from 'constant/APIconstants'
import axios from 'axios'

export const getRegionSummary = async (
  portfolioID,
  researchLineURL,
  regions,
  sectors,
  month,
  year,
  benchmarkID,
  cancelToken
) => {
  const { data } = await axios.post(
    COMMON.BASE_URL + portfolioID + researchLineURL + COMMON.REGION_SUMMARY,
    {
      [PARAMS.REGION]: regions,
      [PARAMS.SECTOR]: sectors,
      [PARAMS.MONTH]: month,
      [PARAMS.YEAR]: year,
      [PARAMS.BENCHMARK_ID]: benchmarkID
    },
    { cancelToken: cancelToken }
  )

  return data
}
