import { PAGE_CON, REDUCER_TYPE } from '../../constant/constants'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

const portfolioEntity = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

export function portfolioManagementInitialState() {
  let obj = {}
  obj.apiCallId = uuidv4()
  obj.cancelToken = axios.CancelToken.source()
  obj.openPortfolioManagement = false
  obj.openDeletePortfolioModal = false
  obj.portfolioManagementView = PAGE_CON.PORTFOLIO_LIST
  obj.portfolioId = ''
  obj.portfolioEntity = Object.assign({}, { ...portfolioEntity, data: {} })
  return obj
}

const PortfolioManagementReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.LOAD_DATA_START:
      currentState[action.sectionName].isLoading = true
      currentState[action.sectionName].dataStatus = PAGE_CON.LOADING
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_ERROR:
      if (action.apiCallId && action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.ERROR
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_COMPLETED:
      if (action.apiCallId && action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState[action.sectionName].data = action.data
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.SUCCESS
      return { ...currentState }
    case REDUCER_TYPE.PORTFOLIO_MANAGEMENT:
      currentState.openPortfolioManagement = action.openPortfolioManagement
      if (action.portfolioManagementView)
        currentState.portfolioManagementView = action.portfolioManagementView
      return { ...currentState }
    case REDUCER_TYPE.DELETE_MODAL:
      currentState.openDeletePortfolioModal = action.openDeletePortfolioModal
      return { ...currentState }
    case REDUCER_TYPE.SET_PORTFOLIO:
      currentState.portfolioId = action.portfolioId
      currentState.cancelToken.cancel('cancelling operation')
      currentState.apiCallId = uuidv4()
      currentState.cancelToken = axios.CancelToken.source()
      currentState.portfolioEntity = Object.assign(
        {},
        { ...portfolioEntity, data: {} }
      )
      if (action.portfolioManagementView)
        currentState.portfolioManagementView = action.portfolioManagementView
      return { ...currentState }
    default:
      return currentState
  }
}

export default PortfolioManagementReducer
