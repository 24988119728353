import React from 'react'
import Logo from './mlogo.svg'
import { Page, PageHeader, PrimaryLink } from 'components'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import { Box, Container, Divider, Typography } from '@material-ui/core'

const boxStyle = {
  color: themeColorPalette.palette.sys_trad.text.main,
  lineHeight: '16px',
  width: '72%',
  padding: '15px 20px',
  background: '#f1f1f1',
  marginBottom: '10px',
  borderRadius: '4px',
  listStyle: 'inside'
}

const boxTransparentStyle = {
  ...boxStyle,
  backgroundColor: 'transparent'
}

const boxHeaderStyle = {
  color: themeColorPalette.palette.sys_trad.text.main,
  lineHeight: '1.4',
  marginTop: '27px'
}

const titleStyle = {
  ...textTheme.typography.h1,
  margin: '15px 0'
}

const tableOfContentsListStyle = {
  ...textTheme.typography.p3,
  margin: '15px 15px'
}

const ulTextStyle = {
  ...textTheme.typography.p1,
  marginLeft: 5,
  marginBottom: 5
}

const dividerStyle = { width: '72%', margin: '5px 0' }

const boxListStyle = {
  ...boxTransparentStyle,
  ...textTheme.typography.p2
}

const Help = () => {
  return (
    <Page>
      <PageHeader
        pageHeaderContentComponent={
          <Container maxWidth="md">
            <img
              alt={'moodysHelp'}
              src={Logo}
              style={{ height: '40px', marginLeft: 20, marginTop: '12px' }}
            />
          </Container>
        }
      />
      <Container maxWidth="md">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="72%"
          component="div"
        >
          <Box component="div">
            <Typography style={titleStyle}>Sign-In Help</Typography>
          </Box>
          <Box component="div">
            <PrimaryLink
              id="linkBack"
              linkText="← Back to Sign-In Page"
              linkClass="link"
              onClick={() => {
                window.location.replace(`${window.location.origin}/login`)
              }}
            />
          </Box>
        </Box>

        <Box component="div" style={boxStyle}>
          <Typography style={textTheme.typography.p3}>
            Okta is an on-demand service that allows you to easily sign-in to
            all the applications your organization uses through a single login.
          </Typography>
        </Box>
        <Box component="div" style={boxHeaderStyle}>
          <Typography style={titleStyle}>Table of Contents</Typography>
        </Box>
        <Box component="div" style={boxStyle}>
          <Typography style={textTheme.typography.h4}>
            Frequently Asked Questions
          </Typography>
          <Typography style={tableOfContentsListStyle}>
            <ul>
              <li>
                <PrimaryLink
                  id="linkBack"
                  linkText="What should I do if I forget my username or password?"
                  linkClass="link"
                  onClick={(e) => {
                    window.location.href = '#fup'
                    e.preventDefault()
                  }}
                />
              </li>
            </ul>
          </Typography>
          <Typography style={textTheme.typography.h4}>How Tos</Typography>
          <Typography style={tableOfContentsListStyle}>
            <ul>
              <li>
                <PrimaryLink
                  id="linkBack"
                  linkText="Sign-in to your Organization"
                  linkClass="link"
                  onClick={(e) => {
                    window.location.href = '#sto'
                    e.preventDefault()
                  }}
                />
              </li>
            </ul>
          </Typography>
        </Box>
        <Box component="div" style={boxHeaderStyle}>
          <Typography style={textTheme.typography.h1}>
            Frequently Asked Questions
          </Typography>
        </Box>
        <Box component="div" style={boxTransparentStyle}>
          <Box display="flex" flexDirection="row">
            <Typography style={textTheme.typography.p2}>Q: </Typography>
            <Typography style={ulTextStyle} id="fup">
              What should I do if I forget my username or password?
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row">
            <Typography style={textTheme.typography.p2}>A: </Typography>
            <Typography style={ulTextStyle}>
              Click <b>I can't access my account</b> on your organization's
              sign-in page, enter the primary or secondary email address you
              listed in your user account settings, and click <b>Send Email</b>.
              Look in your inbox for the system generated email and follow the
              prompts.
            </Typography>
          </Box>
        </Box>
        <Box component="div" style={boxHeaderStyle}>
          <Typography style={textTheme.typography.h1}>How Tos</Typography>
        </Box>
        <Box component="div" style={boxHeaderStyle}>
          <Typography style={textTheme.typography.h2} id="sto">
            Sign-in to your Organization
          </Typography>
        </Box>
        <Divider style={dividerStyle} />
        <Box component="div" style={boxListStyle}>
          <ul style={{ listStyle: 'decimal' }}>
            <li>
              <Typography style={ulTextStyle}>
                Enter your username and password, and then select <b>Sign-in</b>
                . For example, if your username is{' '}
                <b>john.smith@mycompany.com</b>.
              </Typography>
            </li>
            <li>
              <Typography style={ulTextStyle}>
                If you see the error message Sign in failed! your username and
                password do not match those specified for your profile, or you
                do not have access permission. Please contact your system
                administrator.
              </Typography>
              <Typography style={ulTextStyle}>
                If you continue to experience difficulties accessing your
                account, please contact us at
                <span style={{ marginLeft: 3, color: '#0074b3' }}>
                  clientservices@moodys.com
                </span>
                .
              </Typography>
            </li>
          </ul>
        </Box>
      </Container>
    </Page>
  )
}
export default Help
