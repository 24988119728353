import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'

export const useStyles = makeStyles(() => ({
  container: {
    borderRadius: 3,
    marginTop: (props) => (props.minify ? 0 : 6),
    width: '100%',
    padding: (props) => props.minify && '0 35px'
  },
  content: {
    display: 'flex',
    padding: (props) => (props.minify ? '3px 0' : '10px 0')
  },
  title: {
    color: theme.palette.sys_trad.main.main,
    fontSize: 16,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    wordBreak: 'break-word'
  },
  categories: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: (props) => (props.minify ? '0' : '5px'),
    flex: 1
  },
  coverage: {
    ...theme.typography.p1,
    color: theme.palette.sys_trad.text.main,
    fontSize: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  titleContainer: {
    borderRight: `1px solid ${theme.palette.sys_trad.border.main}`,
    marginRight: 10,
    marginTop: 4.6,
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '10px',
    fontFamily: (props) => props.minify && theme.typography.p1.fontFamily,
    fontWeight: (props) => props.minify && theme.typography.p1.fontWeight,
    fontSize: (props) => props.minify && theme.typography.p1.fontSize,
    letterSpacing: (props) => props.minify && theme.typography.p1.letterSpacing,
    width: (props) => !props.minify && '202px'
  },
  aboutButton: {
    ...theme.typography.p1,
    fontSize: 10,
    display: 'inline-block',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginBottom: '5px'
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  researchLineContainer: {
    height: '100%',
    width: (props) => props.width && props.width,
    minWidth: (props) => props.width !== '100%' && props.width
  },
  esgDetailText: {
    ...theme.typography.p2,
    color: theme.palette.sys_trad.text2.main
  },
  esgMinify: {
    fontSize: 10,
    marginTop: 2,
    marginBottom: 2,
    color: theme.palette?.sys_trad?.main.main,
    paddingRight: 13,
    borderRadius: 2,
    paddingTop: 4,
    '& span:first-child': {
      marginRight: '5px'
    }
  },
  esgBox: {
    backgroundColor: (props) => props.color,
    color: theme.palette?.sys_trad?.white.main,
    padding: '10px 5px',
    width: '100%'
  }
}))
