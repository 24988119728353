import { REDUCER_TYPE } from '../../constant/constants'

const urlInfo = {
  userId: '',
  orbisId: '',
  companyName: '',
  sector: '',
  subSector: '',
  urls: new Map(),
  hasModifiedUrls: false,
  backendUrls: [],
  options: []
}

export function IssuerUrlInitialState() {
  let obj = {}
  obj.urlInfo = Object.assign(
    {},
    {
      ...urlInfo
    }
  )
  return obj
}

const IssuerUrlReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.UPDATE_URL_INFO:
      currentState.urlInfo = { ...currentState.urlInfo, ...action.data }

      return { ...currentState }
    case REDUCER_TYPE.UPDATE_URL:
      currentState.urlInfo.hasModifiedUrls = true
      if (action.data.urls) {
        currentState.urlInfo.urls = action.data.urls
        if (action.data.urls.size === 0) {
          currentState.urlInfo.hasModifiedUrls = false
        }
      }
      if (action.data.options) {
        currentState.urlInfo.urls.get(action.data.id).options =
          action.data.options
      }

      return { ...currentState }
    case REDUCER_TYPE.RESET:
      currentState.urlInfo.hasModifiedUrls = false
      currentState.urlInfo.urls = new Map()

      return { ...currentState }
    default:
      return currentState
  }
}

export default IssuerUrlReducer
