import React from 'react'
import { Grid } from '@material-ui/core'
import { PAGE_CON } from 'constant/constants'
import { useEffect, useState } from 'react'
import IssuerScore from 'views/common/issuerScore'
import * as S from './styles'

const Banner = (props) => {
  const classes = S.useStyles(props)
  const { data } = props
  const [, setState] = useState({ smallResize: false })

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1150
        ? setState((prevState) => ({ ...prevState, smallResize: true }))
        : setState((prevState) => ({ ...prevState, smallResize: false }))
    }
    setResponsiveness()
    window.addEventListener('resize', setResponsiveness, true)
    return () => window.removeEventListener('resize', setResponsiveness, true)
  }, [])

  return (
    <Grid container className={classes.banner}>
      <Grid
        id="portfolio_box"
        container
        item
        direction={'row'}
        spacing={3}
        className={classes.scoreGrid}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid
          item
          id="cardInfo_box"
          xs={2}
          md={4}
          className={classes.scoreCardInfo}
        >
          <div className={classes.esg_header}>{PAGE_CON.SUB_CATEGORY}</div>
          <IssuerScore data={data} />
        </Grid>
        <Grid
          item
          id="sector_comparison_chart_box"
          lg={8}
          className={classes.sectorComparisonGrid}
        >
          <div className={classes.sectorComparisonChart}>
            {props.isIssuer
              ? 'No sector comparison chart available'
              : 'Sector comparison chart coming soon'}
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Banner
