import { Grid } from '@material-ui/core'
import { RadioButton } from 'components'
import { useStyles } from './styles'
import React from 'react'


const ReportingType = ({ onReportingTypeUpdate, ReportTypes }) => {
  const classes = useStyles()

  const onChange = (selectedItem) => {
    if (onReportingTypeUpdate) onReportingTypeUpdate(selectedItem)
  }

  return (
    <Grid container direction="column" className={classes.parentContainer}>
      <Grid item className={classes.header}>
        Select Action
      </Grid>
      <Grid item className={classes.subHeader}>
        Service
      </Grid>
      {ReportTypes.filter((type) => type.visible).map((item, index) => {
        return (
          <Grid item className={classes.itemStyle} key={`item-${index}`}>
            <RadioButton
              id={item.id}
              isChecked={item.checked}
              isDisabled={item.disabled}
              radioLabelText={item.label}
              handleRadioChange={onChange}
              labelClass={'portfolioLabel'}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ReportingType
