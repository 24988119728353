import { PAGE_CON, REDUCER_TYPE } from '../../constant/constants'

const entityScore = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const entityControversy = {
  isLoading: true,
  dataStatus: '',
  data: []
}

const entityPositionInSector = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const entityDrivers = {
  isLoading: true,
  dataStatus: '',
  data: []
}

const entitySourceDocuments = {
  isLoading: true,
  dataStatus: '',
  data: []
}

export function entityInitialState() {
  let obj = {}
  obj.entityScore = Object.assign({}, { ...entityScore, data: {} })
  obj.entityControversy = Object.assign({}, { ...entityControversy })
  obj.entityPositionInSector = Object.assign({}, { ...entityPositionInSector })
  obj.entityDrivers = Object.assign({}, { ...entityDrivers })
  obj.entitySourceDocuments = Object.assign({}, { ...entitySourceDocuments })
  return obj
}

const EntityReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.LOAD_DATA_START:
      currentState[action.sectionName].isLoading = true
      currentState[action.sectionName].dataStatus = PAGE_CON.LOADING
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_ERROR:
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.ERROR
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_COMPLETED:
      currentState[action.sectionName].data = action.data
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.SUCCESS
      return { ...currentState }
    case REDUCER_TYPE.RESET:
      return entityInitialState()
    default:
      return currentState
  }
}

export default EntityReducer
