import React from 'react'
import { useStyles } from './styles'

const ESGScores = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.ESGScores}>
      <span>{'Critical Controversies in ESG'}</span>
      <div className="scores">
        <div className="score">
          <span>{props?.e.score}</span>
          <p>{'E (' + props?.e.controversy + ')'}</p>
        </div>
        <div className="score">
          <span>{props?.s.score}</span>
          <p>{'S (' + props?.s.controversy + ')'}</p>
        </div>
        <div className="score">
          <span>{props?.g.score}</span>
          <p>{'G (' + props?.g.controversy + ')'}</p>
        </div>
      </div>
    </div>
  )
}

export default ESGScores
