import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconChevronRight = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.12836 3.16552C5.31309 2.96027 5.62923 2.94363 5.83449 3.12836L10.8345 7.62836C10.9398 7.72318 11 7.85826 11 8C11 8.14175 10.9398 8.27683 10.8345 8.37165L5.83449 12.8717C5.62923 13.0564 5.31309 13.0397 5.12836 12.8345C4.94363 12.6292 4.96027 12.3131 5.16552 12.1284L9.75258 8L5.16552 3.87165C4.96027 3.68692 4.94363 3.37078 5.12836 3.16552Z"
      fill="#26415E"
    />
  </SvgIcon>
)
IconChevronRight.displayName = 'IconChevronRight'
IconChevronRight.muiName = 'SvgIcon'

export default IconChevronRight
