import { COMMON, GBL_FLT, PARAMS } from 'constant/APIconstants'
import axios from 'axios'

export const filterPortfolio = async ({
  portfolio_id = '00000000-0000-0000-0000-000000000000',
  regions = 'all',
  sectors = 'all',
  token
}) => {
  return await axios.post(
    COMMON.BASE_URL + portfolio_id + GBL_FLT.FILTER,
    {
      [PARAMS.REGION]: regions,
      [PARAMS.SECTOR]: sectors
    },
    { cancelToken: token }
  )
}
