import bullet from 'highcharts/modules/bullet.js'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import React from 'react'
import themeColorPalette from 'theme/theme'

bullet(Highcharts)

const Pie = ({ pieColors, data }) => {
  const options = {
    chart: {
      height: 90,
      width: 90
    },
    plotOptions: {
      series: {
        shadow: true,
        enableMouseTracking: false
      },
      pie: {
        size: '100%',
        borderColor: themeColorPalette.palette.sys_trad.border.main,
        borderWidth: 0.08,
        dataLabels: {
          enabled: false
        }
      }
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    series: [
      {
        type: 'pie',
        colors: pieColors || ['#6FA0AD', 'white'],
        data: data || [100, 33]
      }
    ]
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

Pie.propTypes = {
  /** Pie Color Array */
  pieColors: PropTypes.array,
  /** Pie Data Array */
  data: PropTypes.array
}

export default React.memo(Pie)
