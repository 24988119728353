import React, { forwardRef, useState } from 'react'
import { DropdownContainer, Map } from 'components'
import Constant from 'constant'
import MapCoverage from './MapCoverage'
import { ListItem } from '@material-ui/core'
import Error from 'utils/error'
import LoadingMask from 'utils/loadingMask'
import { PAGE_CON } from 'constant/constants'
import numberData from 'utils/numberFormat'
import * as S from '../dashboardComponents'
import { useStyles } from '../dashboardComponents/styles'

const GeographicRisk = ({
  dashboardMap,
  countryListSelectedKey,
  handleCountrySelected,
  setMapRef,
  portfolioName,
  entitlements,
  handleResearchLineSelected,
  researchLineSelected,
  onErrorReload
}) => {
  const researchLines = Constant.Dashboard.getResearchLines(entitlements)
    .filter((researchline) => researchline.isEntitled)
    .filter(({ geographicRisk }) => geographicRisk)

  const researchLine = researchLines.find((r) => r.key === researchLineSelected)

  const [selectedResearchLine, setSelectedResearchLine] = useState(
    researchLine || researchLines.filter(({ header }) => !header)[0]
  )

  const [itemClick, setItemClick] = useState(0)

  const onItemClick = () => {
    setItemClick((prev) => prev + 1)
  }

  const onResearchLineChange = (val) => {
    if (val !== selectedResearchLine) {
      handleResearchLineSelected(val.key)
      setSelectedResearchLine(val)
      onItemClick()
    }
  }

  // eslint-disable-next-line react/display-name
  const ResearchLinesItems = forwardRef(() => (
    <S.ResearchLinesList>
      {researchLines.map((item) =>
        item.header ? (
          <S.ListSubheaderItem
            key={`header-${item.entitled}`}
            itemKey={`header-${item.entitled}`}
          >
            {item.title}
          </S.ListSubheaderItem>
        ) : (
          <ListItem key={`item-${item.entitled}`}>
            <S.ListButtonItem
              active={selectedResearchLine.entitled === item.entitled}
            >
              <span
                aria-hidden={true}
                data-key={item.entitled}
                onClick={() => onResearchLineChange(item)}
              >
                {item.title}
              </span>
            </S.ListButtonItem>
          </ListItem>
        )
      )}
    </S.ResearchLinesList>
  ))

  const mapRange = Constant.Scores.scores(selectedResearchLine.underscore).map(
    ({ min, max, color }) => ({ color, from: min, to: max })
  )

  const isMapHasData = () => {
    let hasData = false
    if (
      dashboardMap.data &&
      dashboardMap.data.coverageData &&
      dashboardMap.data.mapData.length &&
      dashboardMap.data.coverageData.portfolio_coverage?.companies !== ''
    )
      hasData = true
    return hasData
  }
  const classes = useStyles()

  return (
    <>
      <S.GeographicRiskTitleContainer>
        <S.CoverageTitle>Geographic Risk Distribution</S.CoverageTitle>

        <div
          className={
            dashboardMap.isLoading
              ? `dropdownContainer ${classes.disabledDropDown}`
              : 'dropdownContainer'
          }
        >
          <DropdownContainer
            containerClick={itemClick}
            displayText={selectedResearchLine.title}
            isNotDefaultFilter={true}
            dropDownMenuClass={'navDropdownMenuStyle'}
            containerComponent={<ResearchLinesItems />}
          />
        </div>
      </S.GeographicRiskTitleContainer>
      {dashboardMap.isLoading ? (
        <LoadingMask />
      ) : dashboardMap.dataStatus === PAGE_CON.ERROR ||
        (dashboardMap.dataStatus === PAGE_CON.SUCCESS && !isMapHasData()) ? (
        <Error
          id={'mapError'}
          dataStatus={dashboardMap.dataStatus}
          onErrorClick={onErrorReload}
          topMargin={15}
        />
      ) : (
        <>
          <MapCoverage
            companies={
              dashboardMap.data.coverageData.portfolio_coverage?.companies
            }
            investment={numberData(
              dashboardMap.data.coverageData.portfolio_coverage?.investment,
              {
                isDecimal: false,
                keepRawValue: false,
                isLessThanOneFormat: true,
                isPercentage: true
              }
            )}
            portfolio={portfolioName}
            isLoading={dashboardMap.isLoading}
            selectedResearchLine={selectedResearchLine.key}
            entitlements={entitlements}
          />
          <Map
            countries={dashboardMap.data.mapData}
            isLoading={dashboardMap.isLoading}
            mapDataClasses={mapRange}
            height="530px"
            showLegend={true}
            tooltip="dashboard"
            onSeriesClickAction={(key) => handleCountrySelected(key)}
            countryListShow={countryListSelectedKey !== ''}
            setMapRef={(map) => setMapRef(map)}
            portfolioName={portfolioName}
            researchLineSelected={researchLineSelected}
            researchLineSelectedUnserscore={researchLine?.underscore}
          />
        </>
      )}
    </>
  )
}

GeographicRisk.propTypes = {}

export default GeographicRisk
