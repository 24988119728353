import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import { makeStyles } from '@material-ui/core'

const widthOffset = 50
export const useStyles = makeStyles((theme) => ({
  itemStyle: {
    boxSizing: 'border-box',
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
    borderRadius: '2px',
    margin: '0 auto',
    width: '1280px'
  },
  errorStyle: {
    width: '100%',
    maxWidth: '1280px'
  },
  controversiesGrid: {
    [theme.breakpoints.down(800)]: {
      minWidth: '450px !important'
    }
  },
  controversies: {
    boxShadow: 'none'
  },
  divControversies: {
    width: 'auto'
  },
  controversiesGridWidth: {
    width: '100%',
    padding: '0px 16px'
  },
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    flexGrow: 1
  },
  content: {
    flexGrow: 1
  },
  container: {
    boxSizing: 'border-box',
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
    borderRadius: '2px',
    border: '1px solid' + themeColorPalette.palette.white.main,
    width: 'auto',
    padding: '16px 12px 0',
    margin: '0 auto'
  },
  materialityStyle: {
    boxSizing: 'border-box',
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
    borderRadius: '2px',
    border: '1px solid' + themeColorPalette.palette.white.main,
    padding: '0',
    marginBottom: '16px',
    marginTop: '16px'
  },
  pageHeaderStyleTextTitle: {
    ...textTheme.typography.h1,
    color: themeColorPalette.palette.sys_trad.text.main,
    marginTop: '28px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'left',
    width: `${500 - (widthOffset + 30)}px`,
    height: '18.5px',
    left: '120px',
    top: '300px',

    [theme.breakpoints.up(500)]: {
      width: `${500 - widthOffset}px`
    },
    [theme.breakpoints.up(600)]: {
      width: `${600 - widthOffset}px`
    },
    [theme.breakpoints.up(700)]: {
      width: `${700 - widthOffset}px`
    },
    [theme.breakpoints.up(800)]: {
      width: `${800 - widthOffset}px`
    },
    [theme.breakpoints.up(900)]: {
      width: `${900 - widthOffset}px`
    },
    [theme.breakpoints.up(1000)]: {
      width: `${1000 - widthOffset}px`
    },
    [theme.breakpoints.up(1100)]: {
      width: `${1100 - widthOffset}px`
    },
    [theme.breakpoints.up(1200)]: {
      width: `${1200 - widthOffset}px`
    },
    [theme.breakpoints.up(1250)]: {
      width: `${1250 - widthOffset}px`
    }
  },
  backdrop: {
    zIndex: 1200 + 1,
    color: '#fff'
  },
  backdropVisible: {
    display: 'block'
  },
  backdropHidden: {
    display: 'none'
  },
  pageSubHeader: {
    marginRight: '16px',
    marginLeft: '16px'
  },
  pageHeaderHoverSpan: {
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
      outline: 'none',
      boxShadow: 'none'
    }
  },
  banner: {
    width: '100%',
    paddingBottom: '10px !important'
  },
  companySummaryItem: {
    ...textTheme.typography.p3,
    padding: '0 16px',
    paddingBottom: '2px'
  },
  companySummaryComingSoon: {
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '16px',
    marginTop: '50px',
    outline: '0.5px solid ' + themeColorPalette.palette.sys_trad.border.main
  },
  companySummaryLabel: {
    color: textTheme.palette.sys_trad.text2.main
  },
  subsidiariesSummaryContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '8px 24px 0 24px'
  },
  subsidiariesSummary: {
    ...textTheme.typography.p3,
    fontSize: '12px',
    lineHeight: '16px',
    width: '100%',
    maxWidth: 1280
  },
  subsidiariesAnchor: {
    ...textTheme.typography.h2,
    fontSize: '12px',
    lineHeight: '16px',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  subsidiariesTitle: {
    ...textTheme.typography.h2,
    color: themeColorPalette.palette.sys_trad.text.main
  },
  subsidiariesDisclosure: {
    color: textTheme.palette.sys_trad.text2.main,
    margin: '15px 0'
  },
  subsidiariesTable: {
    margin: '0 -8px',
    '& td, & th': {
      padding: '4px 0'
    }
  },
  climateSummarySection: {
    padding: '16px 0'
  },
  bannerWrapper: {
    width: '100%',
    marginLeft: '0',
    padding: '8px 24px'
  },
  containerMaxWidth: {
    maxWidth: '1280px',
    width: '100%'
  },
  summaryValueStyle: {
    ...textTheme.typography.p3,
    paddingLeft: '8px'
  },
  gridStyle: {
    boxSizing: 'border-box',
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
    borderRadius: '2px',
    border: '1px solid' + themeColorPalette.palette.white.main,
    marginBottom: '16px'
  },
  bottomSummaryStyle: {
    boxSizing: 'border-box',
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
    borderRadius: '2px',
    border: '1px solid' + themeColorPalette.palette.white.main,
    padding: '12px',
    marginTop: '16px',
    marginRight: '16px'
  },
  tempTextStyle: {
    color: themeColorPalette.palette.sys_trad.text.main,
    ...textTheme.typography.h2,
    fontSize: '16px',
    lineHeight: '19.2px',
    paddingLeft: '4px',
    paddingTop: '4px'
  },
  riskFlag: {
    fontSize: '12px',
    lineHeight: '16px'
  },
  ta_gridStyle: {
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    borderRadius: '2px',
    marginBottom: '12px'
  },
  tempStyle: {
    ...textTheme.typography.p1,
    fontSize: '12px',
    lineHeight: '16px'
  },
  dateStyle: {
    ...textTheme.typography.h2,
    fontSize: '10px',
    lineHeight: '12px',
    color: themeColorPalette.palette.sys_trad.text2.main,
    padding: '6px 0 0 0px'
  },
  datePosition: {
    paddingTop: '6px'
  },
  physHazardDate: {
    '& span': {
      paddingLeft: '6px'
    }
  },
  eqStyle: {
    ...textTheme.typography.p3,
    fontSize: '10px',
    lineHeight: '16px',
    paddingLeft: '4px'
  },
  phyHazardStyle: {
    ...textTheme.typography.p3,
    fontSize: '12px',
    lineHeight: '16px'
  },
  prmSubTextStyle: {
    width: '140px'
  },
  shareStyle: {
    ...textTheme.typography.h2,
    color: themeColorPalette.palette.sys_trad.text.main,
    fontSize: '12px',
    lineHeight: '16px',
    width: '90%'
  },
  tempAlignErrorStyle: {
    height: '100%',
    padding: '12px',
    width: '860px'
  },
  tempAlignStyle: {
    position: 'relative',
    height: '580px'
  },
  climateSummarySectionStyle: {
    minWidth: '1280px',
    margin: '0 auto'
  },
  infoBlockStyle: {
    padding: '15px 0 10px 0',
    fontFamily: 'WhitneyNarrMedium'
  },
  infoStyle: {
    width: '140px',
    lineHeight: '20px'
  },
  chicletPrefix: {
    lineHeight: '20px',
    paddingRight: '2px'
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center'
  },
  climateControversycontainer: {
    boxSizing: 'border-box',
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
    borderRadius: '2px',
    border: '1px solid' + themeColorPalette.palette.white.main,
    width: 'auto',
    padding: '16px 12px 0',
    margin: '0 auto'
  },
  climateControversymaterialityStyle: {
    boxSizing: 'border-box',
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
    borderRadius: '2px',
    border: '1px solid' + themeColorPalette.palette.white.main,
    padding: '0',
    marginBottom: '16px',
    marginTop: '16px'
  },
  loadingStyle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: '50px !important'
    }
  },
  errorIcon: {
    display: 'flex',
    whiteSpace: 'wrap',
    justifyContent: 'center'
  },
  date: {
    display: 'flex',
    whiteSpace: 'wrap',
    justifyContent: 'right',
    width: 'auto',
    fontFamily: 'WhitneyNarrSemiBold'
  },
  controversiesTitle: {
    color: theme?.palette?.sys_trad?.text.main,
    fontSize: 16,
    marginLeft: '15px',
    marginTop: '15px',
    marginBottom: '10px',
    '&>span': {
      marginRight: '15px'
    }
  },
  subText: {
    color: theme?.palette?.sys_trad?.text.main,
    fontSize: 14,
    marginLeft: '12px'
  },
  controversyChiclet: {
    paddingBottom: 4
  },
  parentContainer: {
    width: '368px'
  },
  PhyRiskMgmLabel: {
    ...textTheme.typography.p2,
    display: 'flex',
    lineHeight: '16px',
    paddingBottom: '12px',
    textTransform: 'uppercase',
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingTop: 20
  },
  overallRiskLabel: {
    ...textTheme.typography.p2,
    lineHeight: '16px',
    paddingTop: '24px',
    paddingBottom: '12px',
    textTransform: 'uppercase'
  },
  linkStyle: {
    textAlign: 'left',
    paddingTop: '10px',
    '& #link_box': {
      paddingTop: '10px'
    },
    '& #link_box_phys_rsk_mgmt': {
      paddingTop: '10px',
      paddingBottom: '10px'
    }
  },
  histogramChart: {
    width: '815px',
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    padding: '12px',
    [theme.breakpoints.up(1220)]: {
      marginTop: '12px'
    },
    [theme.breakpoints.up(1230)]: {
      marginTop: '12px'
    }
  },
  histogramChartNoDataError: {
    width: '815px',
    height: '237px',
    [theme.breakpoints.up(1220)]: {
      marginTop: '12px'
    },
    [theme.breakpoints.up(1230)]: {
      marginTop: '12px'
    }
  },
  mapChart: {
    margin: '12px 0 24px',
    height: '400px',
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    padding: '12px'
  },
  listButton: {
    lineHeight: '16px',
    paddingTop: '24px',
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.sys_trad.text.main
  },
  noDataErrorStyle: {
    paddingRight: '12px'
  },
  physicalRiskdateStyle: {
    ...textTheme.typography.h2,
    fontSize: '10px',
    lineHeight: '12px',
    color: themeColorPalette.palette.sys_trad.text2.main,
    paddingBottom: '10px'
  },
  containerMinWidth: {
    minWidth: '1220px',
    display: 'flex'
  },
  physicalRiskchicletPrefix: {
    paddingRight: '5px'
  },
  tableValueStyle: {
    ...textTheme.typography.p3,
    paddingLeft: '8px'
  },
  gridContainer: {
    paddingTop: '24px'
  },
  transitionRisklinkStyle: {
    textAlign: 'left',
    paddingBottom: '12px'
  },
  transitionRisktempStyle: {
    ...textTheme.typography.h2,
    fontSize: '12px',
    lineHeight: '16px'
  },
  chart: {
    width: '810px',
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    padding: '12px',
    marginBottom: '24px',
    [theme.breakpoints.up(1220)]: {
      marginLeft: 0
    },
    [theme.breakpoints.up(1230)]: {
      marginLeft: '12px'
    }
  },
  sectionGrid: {
    paddingBottom: 24
  },
  sectionChart: {
    marginBottom: 0
  },
  transitionRiskhistogramChartNoDataError: {
    height: '237px'
  },
  subsidiaryBox: { width: '100%' }
}))
