import { Box, Grid, Typography } from '@material-ui/core'
import bullet from 'highcharts/modules/bullet.js'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import React from 'react'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import numberData from 'utils/numberFormat'
import LoadingMask from 'utils/loadingMask'
import getColor from 'utils/color'
import * as S from './styles'
/**
 * @visibleName Sector Comparison Chart
 */
const graphTooltip = (graph, item, tooltipScoreLabel) => {
  let itemValue = item.y

  if (graph === 'carbon') {
    return `<b>${item.name} </b> <br>
    <b>Carbon Footprint</b> : ${numberData(itemValue, {
      isCommaSeparated: true,
      keepRawValue: true
    })} (t CO2eq)<br>
    <b>Carbon Footprint Grade</b> : ${item.category}`
  } else if (graph === 'temp') {
    return `<b>${item.name} </b> <br>
    <b>Temperature Alignment Category</b> : ${item.category}<br>
    <b>Implied Temperature Rise</b> : ${numberData(itemValue, {
      isCommaSeparated: true,
      keepRawValue: true,
      isDecimal: true
    })} (t CO2eq)`
  } else if (graph === 'greenshare') {
    return (
      `<b>${item.name} </b>` +
      '<br/><b>Green Share Score: </b>' +
      item.score_range +
      '<br/><b>Green Share Assessment: </b>' +
      item.category
    )
  } else if (graph === 'brownshare') {
    itemValue =
      item?.score_actual === null || item?.score_actual === undefined
        ? item.range
        : `${itemValue}%`
    return (
      `<b>${item.name} </b>` +
      '<br/><b>Brown Share Score: </b>' +
      itemValue +
      '<br/><b>Brown Share Assessment: </b>' +
      item.score_range
    )
  } else {
    return (
      '<b> Company Name: </b>' +
      item.name +
      '<br/><b>' +
      tooltipScoreLabel +
      ': </b>' +
      itemValue
    )
  }
}

const Histogram = ({
  id,
  title,
  data,
  categories,
  avg,
  graph,
  height,
  width,
  labels,
  legends,
  colorPalette,
  middleTopLabel,
  plotLineColor,
  itemColorCategory,
  graphColorCategory,
  tooltipScoreLabel
}) => {
  const classes = S.useStyles({ width })

  const categoryColor = data?.map((item) => {
    return item.isSelected === true
      ? themeColorPalette.palette.sys_trad.status.main
      : getColor(graphColorCategory, item[itemColorCategory])
  })

  const getLegendColor = (category) => {
    if (category === 'status') {
      return themeColorPalette.palette.sys_trad[category]?.main
    }
    return colorPalette[category]?.main
  }

  bullet(Highcharts)
  const options = {
    colors: categoryColor,
    credits: {
      enabled: false
    },
    chart: {
      type: 'column', //"algorithmic",
      height: height || 120,
      width: width || S.defaultChartWidth,
      style: {
        ...textTheme.typography.p4
      },
      margin: 0,
      padding: 0
    },
    events: {
      load: function () {
        var chart = this,
          barsLength = chart.series[0].data.length

        chart.update(
          {
            chart: {
              height: 100 + 50 * barsLength
            }
          },
          true,
          false,
          false
        )
      }
    },
    title: {
      style:
        title === '.'
          ? {
              color: themeColorPalette.palette.white.main,
              ...textTheme.typography.p2
            }
          : {
              ...textTheme.typography.p2,
              fontSize: '14 !important',
              fontWeight: 'bold'
            },
      text: title,
      align: 'left'
    },
    subtitle: {
      style:
        title === '.'
          ? {
              color: themeColorPalette.palette.white.main,
              ...textTheme.typography.p2
            }
          : {
              ...textTheme.typography.p2,
              fontSize: '14 !important',
              fontWeight: 'bold'
            },
      text: '',
      align: 'right'
    },
    xAxis: {
      lineColor: themeColorPalette.palette.sys_trad.border.main,
      labels: {
        style: {
          textOverflow: 'none',
          whiteSpace: 'nowrap'
        },
        rotation: 0,
        formatter: function () {
          return ''
        }
      },
      categories: categories,
      width: '99%'
    },
    yAxis: {
      type: 'logarithmic', //"category",
      title: {
        text: ''
      },
      labels: {
        align: 'left',
        enabled: true,
        formatter: function () {
          return ''
        }
      },
      visible: true,
      gridLineWidth: 0,
      plotLines: [
        {
          color: colorPalette[plotLineColor]?.main,
          width: 2,
          value: avg === 0 ? 0.2 : avg,
          dashStyle: 'line',
          zIndex: 4,
          label: {
            text: '',
            verticalAlign: 'bottom',
            textAlign: 'right'
          }
        }
      ]
    },
    legend: {
      reversed: true,
      enabled: false
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return graphTooltip(graph, this.point, tooltipScoreLabel)
      }
    },
    plotOptions: {
      column: {
        colorByPoint: true
      },
      series: {
        minPointLength: 5,
        pointWidth:
          width / data?.length >= 10
            ? 10
            : width / data?.length < 4
            ? 5
            : width / data?.length,
        pointPadding: 0.1,
        dataLabels: {
          enabled: true,
          style: {
            ...textTheme.typography.p4,
            color: themeColorPalette.palette.sys_trad.text.main,
            opacity: 1
          },
          formatter: function () {
            return this.point.isSelected === true ? this.key : ''
          }
        }
      },
      allowPointSelect: true
    },
    series: [
      {
        type: 'column',
        data
      }
    ]
  }

  return (
    <Grid container className={classes.containerStyle}>
      <Grid item container>
        <Grid item className={classes.firstColumn}></Grid>
        <Grid
          item
          container
          direction="column"
          className={classes.secondColumn}
        >
          <Grid item className={classes.middleTopLabel}>
            {middleTopLabel}
          </Grid>
          <Grid item className={classes.middleBottomLabel}>
            <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
              {legends?.map(
                (legend) =>
                  legend.name && (
                    <Box key={legend.name}>
                      <span className={classes.labelItem}>
                        <span
                          // eslint-disable-next-line react/no-unknown-property
                          variant="outlined"
                          style={{
                            background: getLegendColor(legend.category)
                          }}
                          className={classes.button}
                        />
                        <Typography className={classes.legendLabel}>
                          &nbsp;{legend.name}: {legend.risk}
                        </Typography>
                      </span>
                    </Box>
                  )
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item className={classes.thirdColumn}></Grid>
      </Grid>
      <Grid item container direction={'row'}>
        <Grid item className={classes.firstColumn}>
          <div className={classes.leftTopLabel}>{labels?.yTop}</div>
          <div className={classes.leftBottomLabel}>{labels?.yBottom}</div>
        </Grid>
        <Grid item className={classes.secondColumn}>
          {data?.length > 0 ? (
            <HighchartsReact
              id={id}
              highcharts={Highcharts}
              options={options}
            />
          ) : (
            <LoadingMask />
          )}
        </Grid>
        <Grid item className={classes.thirdColumn}></Grid>
      </Grid>
      <Grid item container>
        <Grid item className={classes.firstColumn}></Grid>
        <Grid item container className={classes.secondColumn}>
          <Grid item xs={6} sm={6} className={classes.bottomLeftLabel}>
            {labels?.xLeft}
          </Grid>
          <Grid item xs={6} sm={6} className={classes.bottomRightLabel}>
            {labels?.xRight}
          </Grid>
          <Grid item xs={6} sm={6} className={classes.bottomCenterLabel}>
            {labels?.xCenter}
          </Grid>
        </Grid>
        <Grid item className={classes.thirdColumn}></Grid>
      </Grid>
    </Grid>
  )
}
Histogram.propTypes = {
  /** BarChart id */
  id: PropTypes.string,
  /** BarChart title */
  title: PropTypes.string,
  /** BarChart data */
  data: PropTypes.array,
  /** BarChart selected */
  selected: PropTypes.object,
  /** BarChart categories */
  categories: PropTypes.array,
  /** BarChart totalCount */
  totalCount: PropTypes.number,
  /** BarChart avg */
  avg: PropTypes.number,
  /** BarChart to add middle top label */
  middleTopLabel: PropTypes.string,
  /** BarChart to graph type */
  graph: PropTypes.string,
  /** BarChart to add paddingLeft */
  padding: PropTypes.bool,
  /** BarChart to add plotLineColor */
  plotLineColor: PropTypes.string,
  /** Stops for colors */
  stops: PropTypes.array,
  /** Zones for column colors */
  zones: PropTypes.array,
  /** Zones for column labels */
  labels: PropTypes.object,
  /** Zones for column legends */
  legends: PropTypes.array,
  /** Which color palette to use for colors */
  colorPalette: PropTypes.object,
  /** Which color family to use for colors */
  graphColorCategory: PropTypes.string,
  /** Category to find color within color family */
  itemColorCategory: PropTypes.string
}

Histogram.defaultProps = {
  colorPalette: themeColorPalette.palette.noflag
}

export default React.memo(Histogram)
