import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

/**
 * @visibleName Info Icon
 */
let IconInfo = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 34 33">
      <path
        fill="#999"
        fillRule="evenodd"
        stroke="#999"
        strokeLinejoin="round"
        d="M17.963 3.775a1.087 1.087 0 00-1.926 0L3.312 27.891c-.386.73.14 1.61.963 1.61h25.45c.822 0 1.348-.88.963-1.61L17.963 3.775zM14.11 2.747c1.23-2.33 4.548-2.33 5.777 0l12.726 24.116c1.156 2.19-.423 4.83-2.889 4.83H4.275c-2.466 0-4.045-2.64-2.889-4.83L14.111 2.747z"
        clipRule="evenodd"
      />
      <path
        fill="#999"
        stroke="#999"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M17 21.898l-.315-8.817a.315.315 0 11.63 0L17 21.898z"
      />
      <path
        fill="#999"
        stroke="#999"
        d="M17.48 26.143a.48.48 0 11-.96 0 .48.48 0 01.96 0z"
      />
    </svg>
  </SvgIcon>
)
IconInfo.displayName = 'IconInfo'
IconInfo.muiName = 'SvgIcon'

export default IconInfo
