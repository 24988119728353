import React from 'react'
import { Grid } from '@material-ui/core'
import LoadingMask from 'utils/loadingMask'
import * as S from './styles'
import theme from 'theme/theme'

const DriverHeaderSector = ({ data, isLoading }) => {
  const classes = S.useStyles(theme)
  const item = data

  return (
    <Grid container direction="column">
      <Grid item className={classes.titleLeft}>
        {item.title}
      </Grid>
      <Grid item>
        {isLoading ? <LoadingMask height={10} /> : item.subTitle}
      </Grid>
    </Grid>
  )
}

export default DriverHeaderSector
