import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconEyeClosed = (props) => (
  <SvgIcon {...props}>
    <path
      d="M14.2831 2.878L15.0019 5.1792C15.0489 5.31963 15.0409 5.47586 14.98 5.60936C14.9192 5.74269 14.8109 5.84087 14.6824 5.87921C14.6326 5.8872 14.5819 5.8872 14.5321 5.87921C14.3216 5.89164 14.1296 5.74411 14.0624 5.51864L13.4893 3.62044V3.62026C12.7266 4.3059 11.874 4.85341 10.962 5.24291L11.2439 7.4008C11.2612 7.54177 11.2275 7.6845 11.1508 7.79706C11.0738 7.9098 10.9601 7.98294 10.8351 7.99998H10.7741C10.5367 8.00229 10.3351 7.80434 10.3043 7.53877L10.0741 5.57153C8.68782 5.97808 7.23264 5.97808 5.84632 5.57153L5.60678 7.53877C5.57595 7.80436 5.37431 8.0023 5.13696 7.99998H5.07594C4.9509 7.98294 4.83718 7.90979 4.76027 7.79706C4.68352 7.6845 4.64985 7.54177 4.66716 7.4008L4.92086 5.24273V5.2429C4.00894 4.8534 3.15629 4.30589 2.39359 3.62026L1.8486 5.51864C1.78144 5.74411 1.58939 5.89164 1.37895 5.87921C1.32909 5.8872 1.27844 5.8872 1.22858 5.87921C1.10999 5.83447 1.01216 5.73825 0.956637 5.61185C0.901116 5.48544 0.892463 5.33933 0.932573 5.20583L1.62779 2.90463L1.62794 2.90446C1.05054 2.2909 0.526778 1.61608 0.0635691 0.889632C-0.0567548 0.655822 -0.00186116 0.357202 0.190972 0.196723C0.383805 0.0360536 0.652771 0.0651725 0.81524 0.26401C1.16363 0.798939 1.54677 1.30419 1.96138 1.77517C2.20565 2.05621 2.45934 2.30547 2.71776 2.55989C3.42349 3.22334 4.21574 3.76 5.0665 4.15058C5.3622 4.28782 5.66496 4.40463 5.9731 4.5005C7.27934 4.9106 8.65987 4.9106 9.9661 4.5005C10.2742 4.40463 10.577 4.28782 10.8727 4.15058C11.7235 3.76001 12.5157 3.22334 13.2216 2.55989C13.48 2.31596 13.7336 2.05623 13.978 1.77517H13.9778C14.3924 1.30419 14.7756 0.798939 15.1241 0.26401C15.1911 0.1337 15.3041 0.0417389 15.4348 0.0110248C15.5656 -0.0195111 15.7017 0.0140423 15.8091 0.103521C15.9165 0.193 15.9853 0.329878 15.9979 0.47989C16.0106 0.629907 15.9662 0.778855 15.8756 0.889636C15.4024 1.60845 14.8692 2.27422 14.2831 2.87801L14.2831 2.878Z"
      fill={props.fillcolor || '#FFFFFF'}
      style={{ transform: 'translate(0px, 9px) scale(1.2)' }}
    />
  </SvgIcon>
)
IconEyeClosed.displayName = 'IconEyeClosed'
IconEyeClosed.muiName = 'SvgIcon'

export default IconEyeClosed
