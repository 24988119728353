import { Grid } from '@material-ui/core'
import { Checkbox, Textbox } from 'components'
import React from 'react'
import clsx from 'clsx'
import numberData from 'utils/numberFormat'
import { getMonthDayYear } from 'utils/Date'
import { PAGE_CON } from 'constant/constants'
import { useStyles } from './styles'
import EmptyPortfolios from './EmptyPortfolios'

const EuTaxonomyPortfolios = ({
  onPortfolioSelection,
  portfolios,
  onTextChange
}) => {
  const classes = useStyles({
    subHeaderAlignItems: 'end',
    itemPaddingTop: '12px',
    itemPaddingBottom: '10px',
    coverageWidth: 50,
    dateWidth: 115
  })

  const colSize = {
    portfolio: 4,
    uploaded: 3,
    coverage: 2,
    nonSovereign: 3,
    cash: 2
  }

  const onSelectPortfolio = (selectedItem) => {
    onPortfolioSelection(selectedItem)
  }

  return (
    <Grid container direction="column" style={{ marginBottom: 10 }}>
      <Grid item className={classes.header}>
        Select Portfolios
      </Grid>
      <Grid container>
        <Grid
          className={classes.subHeader}
          style={{ paddingLeft: '0px' }}
          item
          xs={colSize.portfolio}
        >
          Portfolio
        </Grid>
        <Grid item xs={colSize.uploaded} className={classes.subHeader}>
          <span className={classes.dateColumn}>Last Uploaded</span>
        </Grid>
        <Grid item xs={colSize.coverage} className={classes.subHeader}>
          <span className={classes.coverageColumn}>Coverage % Investment</span>
        </Grid>
        <Grid
          item
          xs={colSize.nonSovereign}
          className={clsx(classes.subHeader)}
        >
          <span className={classes.cashColumn}>Non-Sovereign Derivatives</span>
          <div style={{ width: '24px' }}></div>
          <span className={classes.cashColumn}>Cash and liquidities</span>
        </Grid>
      </Grid>
      {portfolios.length > 0 ? (
        portfolios.map((portfolio, index) => {
          const portfolioKey = portfolio.key
          const portfolioName = portfolio.portfolioName
          const portfolioDate = portfolio.date
          const portfolioDisabled =
            portfolio.disabled ||
            portfolio.taxonomy_coverage === PAGE_CON.NOT_APPLICABLE ||
            portfolio.taxonomy_coverage === 0
          const suffixClass = portfolio.checked
            ? 'regPortfolioEndTextActive'
            : 'regPortfolioEndTextDisabled'
          return (
            <Grid
              container
              className={classes.itemStyle}
              key={`portfolio-${index}=${portfolioKey}`}
            >
              <Grid
                className={clsx(classes.portfolioItem)}
                item
                xs={colSize.portfolio}
              >
                <Checkbox
                  labelWidth={'260px'}
                  id={portfolioKey}
                  checked={portfolio.checked}
                  onChange={onSelectPortfolio}
                  disabled={portfolioDisabled}
                  label={portfolioName}
                  labelClass={'portfolioLabel'}
                />
              </Grid>
              <Grid
                className={clsx(
                  classes.portfolioItem,
                  portfolio.checked && classes.isChecked
                )}
                item
                xs={colSize.uploaded}
              >
                <span className={classes.dateColumn}>
                  {getMonthDayYear(portfolioDate)}
                </span>
              </Grid>
              <Grid
                className={clsx(
                  classes.portfolioItem,
                  portfolio.checked && classes.isChecked
                )}
                item
                xs={colSize.coverage}
                title={
                  portfolio.taxonomy_coverage < 1
                    ? `${portfolio.taxonomy_coverage}%`
                    : undefined
                }
              >
                <span className={classes.coverageColumn}>
                  {portfolio.taxonomy_coverage !== PAGE_CON.NOT_APPLICABLE
                    ? numberData(portfolio.taxonomy_coverage, {
                        isDecimal: true,
                        isPercentage: true,
                        keepRawValue: true
                      })
                    : PAGE_CON.NOT_APPLICABLE}
                </span>
              </Grid>
              <Grid
                className={clsx(
                  classes.portfolioItem,
                  portfolio.checked && classes.isChecked
                )}
                item
                xs={colSize.nonSovereign}
              >
                <Textbox
                  textboxError={false}
                  multiline={false}
                  defaultText={portfolio[PAGE_CON.NON_SOVEREIGN_DERIVATIVES]}
                  onBlur={(val) =>
                    onTextChange(
                      portfolioKey,
                      PAGE_CON.NON_SOVEREIGN_DERIVATIVES,
                      val
                    )
                  }
                  disabled={!portfolio.checked}
                  textType={'number'}
                  textboxDefaultPlaceholder={'000000'}
                  inputPropsClass={'regPortfolio'}
                  textboxWidth={'70px'}
                  textboxCharLimit={26}
                  hideCharacterLimitError={true}
                  suffixText={portfolio.currency}
                  suffixTextClass={suffixClass}
                />
                <div style={{ width: '24px' }}></div>
                <Textbox
                  textboxError={false}
                  multiline={false}
                  defaultText={portfolio[PAGE_CON.CASH_AND_LIQUIDITIES]}
                  onBlur={(val) =>
                    onTextChange(
                      portfolioKey,
                      PAGE_CON.CASH_AND_LIQUIDITIES,
                      val
                    )
                  }
                  disabled={!portfolio.checked}
                  textType={'number'}
                  textboxDefaultPlaceholder={'000000'}
                  inputPropsClass={'regPortfolio'}
                  textboxWidth={'70px'}
                  textboxCharLimit={26}
                  hideCharacterLimitError={true}
                  suffixText={portfolio.currency}
                  suffixTextClass={suffixClass}
                />
              </Grid>
            </Grid>
          )
        })
      ) : (
        <EmptyPortfolios />
      )}
    </Grid>
  )
}

export default EuTaxonomyPortfolios
