import { PAGE_CON, REDUCER_TYPE, REG_REPORTING_TYPE } from 'constant/constants'
import { ExportService } from 'services'
export const { sfdr, euTaxonomy, onDemandAsmnt } = REG_REPORTING_TYPE

export const annual = 'annual'
export const interim = 'interim'
export const latest = 'latest'

export const defaultReportTypes = ({ selectedItem, entitlements }) => {
  const { sfdr, eutaxonomy, ondemandassessment } = entitlements.ReportingService
  const options = [
    {
      id: REG_REPORTING_TYPE.euTaxonomy,
      label: 'EU Taxonomy',
      checked: selectedItem === REG_REPORTING_TYPE.euTaxonomy ? true : false,
      disabled: !eutaxonomy,
      visible: sfdr || eutaxonomy
    },
    {
      id: REG_REPORTING_TYPE.sfdr,
      label: 'SFDR PAIs',
      checked: selectedItem === REG_REPORTING_TYPE.sfdr ? true : false,
      disabled: !sfdr,
      visible: sfdr || eutaxonomy
    },
    {
      id: REG_REPORTING_TYPE.onDemandAsmnt,
      label: 'On-Demand Assessment',
      checked: selectedItem === REG_REPORTING_TYPE.onDemandAsmnt ? true : false,
      disabled: !ondemandassessment,
      visible: ondemandassessment
    }
  ]
  return options
}

export const onUpload = (uploadType, filterDispatch) => {
  filterDispatch({
    type: REDUCER_TYPE.UPLOAD_PORTFOLIO_MODAL,
    payload: true,
    uploadType,
    uploadInitiatedFrom: PAGE_CON.REPORTING_SERVICE
  })
}

export const defaultReportOptions = (
  reportTypeChecked,
  isEmptyPortfolios = false
) => {
  return [
    {
      id: interim,
      buttonText: 'Interim Reports',
      subTitleText: 'Separate reports across selected portfolios',
      checked: reportTypeChecked === sfdr && !isEmptyPortfolios,
      disabled: reportTypeChecked === euTaxonomy || isEmptyPortfolios
    },
    {
      id: annual,
      buttonText: 'Annual Reports',
      subTitleText: 'Annual report averaging across selected portfolios',
      checked: false && !isEmptyPortfolios,
      disabled: reportTypeChecked === euTaxonomy || isEmptyPortfolios
    },
    {
      id: latest,
      buttonText: 'Use Latest Data',
      subTitleText:
        'Only use latest data as of today. Data will not align to report date.',
      checked: reportTypeChecked === euTaxonomy && !isEmptyPortfolios,
      disabled: false || isEmptyPortfolios
    }
  ]
}

export const defaultReportTypeChecked = (entitlements) => {
  const isSFDREntitled = entitlements['ReportingService']['sfdr'] || false
  const isEuTaxonomyEntitled =
    entitlements['ReportingService']['eutaxonomy'] || false
  const isODAEntitled =
    entitlements['ReportingService']['ondemandassessment'] || false
  switch (true) {
    case isODAEntitled:
      return onDemandAsmnt
    case isSFDREntitled:
      return sfdr
    case isEuTaxonomyEntitled:
      return euTaxonomy
  }
}

export const exportPortfolioData = async ({ key, portfolioName }) => {
  try {
    const asOfDate = `${String(new Date().getFullYear())}${String(
      new Date().getMonth() + 1
    ).padStart(2, '0')}`

    const exportURL = await ExportService.getExportClimateData({
      asOfDate,
      portfolio: key,
      portfolioName: portfolioName,
      ondemand_page: true
    })

    return exportURL
  } catch (error) {
    return error
  }
}

const handleODAClicks = (
  setOnDemandAsmntDispatch,
  portfolio,
  assessmentRequested
) => {
  setOnDemandAsmntDispatch({
    type: REDUCER_TYPE.ON_DEMAND_ASMNT_MODAL,
    modalOpen: true,
    assessmentRequested,
    portfolio
  })
}

export const requestAssessment = (setOnDemandAsmntDispatch, portfolio) => {
  handleODAClicks(setOnDemandAsmntDispatch, portfolio, false)
}

export const viewAssessmentStatus = (setOnDemandAsmntDispatch, portfolio) => {
  handleODAClicks(setOnDemandAsmntDispatch, portfolio, true)
}
