import {
  REDUCER_STATE,
  REDUCER_TYPE,
} from 'constant/constants'

export const showErrorPopover = (message, setErrorPopoverMessage, clearTimerRef, timeout = 3000) => {
  if (message) {
    setErrorPopoverMessage(message)
  }
  clearTimerRef.current = setTimeout(() => {
    setErrorPopoverMessage(null)
  }, timeout)
}

export const clearErrorPopover = (setErrorPopoverMessage, clearTimerRef) => {
  clearTimeout(clearTimerRef.current)
  setErrorPopoverMessage(null)
}

export const updateInitialUserSettingsDefaultFactory = (apiCallId, userSettingState, carbonFootPrintCalculationOptions, predictedScoresCalculationOptions, setCarbonFootprintOptions, setPredictedScoresOptions, userSettingDispatch) => () => {
  try {
    const selectedCarbonFootprint =
      userSettingState.userSetting.data.weighted_average_calculations
        .carbonfootprint
    const newCFDefaultOptions = [...carbonFootPrintCalculationOptions].map(
      (item) => ({
        ...item,
        checked: item.id === selectedCarbonFootprint
      })
    )
    setCarbonFootprintOptions(newCFDefaultOptions)

    const selectedPredictedScores =
      userSettingState.userSetting.data.predicted_scores_data
    const newPSOptions = [...predictedScoresCalculationOptions].map(
      (item) => ({
        ...item,
        checked: item.id === selectedPredictedScores[item.id]
      })
    )
    setPredictedScoresOptions(newPSOptions)
  } catch (error) {
    userSettingDispatch({
      type: REDUCER_TYPE.LOAD_DATA_ERROR,
      sectionName: REDUCER_STATE.USER_SETTING,
      apiCallId
    })
  }
}
