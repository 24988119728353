import Button from '@material-ui/core/Button'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { useStyles } from './styles'

/**
 * @visibleName Buttons
 */
const PrimaryButton = (props) => {
  const classes = useStyles(props)
  const [buttonClicked, setButtonClicked] = React.useState(false)

  const handleClick = (e) => {
    setButtonClicked(true)
    if (props.sync && props.onClick) {
      props.onClick(e)
      setButtonClicked(false)
    } else {
      setTimeout(function () {
        setButtonClicked(false)
        if (props.onClick) props.onClick(e)
      }, 300)
    }
  }

  return (
    <Button
      id={props.id}
      {...(props.heap_id ? { heap_id: props.heap_id } : {})}
      {...(props.heap_title ? { heap_title: props.heap_title } : {})}
      {...(props.heap_view_panel
        ? { heap_view_panel: props.heap_view_panel }
        : {})}
      disabled={props.buttonDisabled}
      disableRipple
      disableFocusRipple
      startIcon={
        props.buttonIconPosition === 'start' ||
        props.buttonIconPosition === 'top'
          ? props.buttonIcon
          : null
      }
      endIcon={props.buttonIconPosition === 'end' ? props.buttonIcon : null}
      variant="contained"
      padding="0px 8px 0px 8px"
      onClick={props.buttonDisabled ? null : handleClick}
      className={clsx(
        classes.button,
        buttonClicked
          ? classes[props.buttonClass + 'Clicked']
          : classes[props.buttonClass],
        {
          'Mui-disabled': props.buttonDisabled,
          'Mui-selected': props.buttonSelected
        }
      )}
      title={
        props.showTitle ? props.tooltipText || props.buttonText : undefined
      }
      onMouseOver={props.onMouseOver}
      onMouseLeave={props.onMouseLeave}
      onMouseUp={props.onMouseUp}
      onMouseDown={props.onMouseDown}
    >
      {props.buttonText && (
        <div
          className={clsx(
            props.showEllipsis && classes.textEllipsis,
            classes[props.buttonTextClass]
          )}
        >
          {props.buttonText}
        </div>
      )}
    </Button>
  )
}

PrimaryButton.propTypes = {
  /** Button id */
  id: PropTypes.string,
  /** The icon for the button */
  buttonIcon: PropTypes.any,
  /** The position for icon on the button */
  buttonIconPosition: PropTypes.any,
  /** Class for button style: buttonGhost, buttonPrimary, buttonSlim*/
  buttonClass: PropTypes.string,
  /** Button text */
  buttonText: PropTypes.string,
  /** Button margin left */
  buttonMarginLeft: PropTypes.string,
  /** Button margin right */
  buttonMarginRight: PropTypes.string,
  /** Button margin top */
  buttonMarginTop: PropTypes.string,
  /** Button margin bottom */
  buttonMarginBotom: PropTypes.string,
  /** Button width */
  buttonWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Button max width */
  buttonMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Button min width */
  buttonMinWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Button disabled */
  buttonDisabled: PropTypes.bool,
  /** Button selected */
  buttonSelected: PropTypes.bool,
  /** Gets called when the user clicks on the button */
  onClick: PropTypes.func,
  /** Button selected */
  buttonHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Button padding */
  buttonPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Button title flag */
  showTitle: PropTypes.bool,
  /** Button text ellipsis flag */
  showEllipsis: PropTypes.bool,
  /** Button tooltip text */
  tooltipText: PropTypes.string,
  /** Button text style class */
  buttonTextClass: PropTypes.string,
  /** Button onClick syncronous  */
  sync: PropTypes.bool
}

PrimaryButton.defaultProps = {
  buttonIconPosition: 'start',
  sync: false
}

export default PrimaryButton
