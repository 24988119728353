import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import MetricsTable from './MetricsTable'
import PropTypes from 'prop-types'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import { PAGE_CON } from 'constant/constants'
import Error from 'utils/error'

const borderColor = themeColorPalette.palette.sys_trad.border.main

const useStyles = makeStyles({
  parentContainer: {
    display: 'flex',
    overflowX: 'auto'
  },
  tableContainer: {
    minWidth: '190px',
    width: 'auto',
    overflowX: 'initial',
    paddingBottom: (props) => props.tablePadding,
    paddingLeft: (props) => props.tablePadding,
    paddingRight: (props) => props.tablePadding
  },
  table: {
    width: '100%',
    cursor: 'default',
    borderColor: borderColor
  },
  tableHead: {
    borderTop: `1px solid ${borderColor}`,
    borderBottom: `1px solid ${borderColor}`
  },
  headerCell: {
    ...textTheme.typography.h2,
    borderBottom: 'none',
    padding: '10px',
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    height: 64.5,
    verticalAlign: 'initial',
    color: textTheme.palette.sys_trad.text.main,
    width: 180
  },
  rowCell: {
    ...textTheme.typography.p3,
    color: textTheme.palette.sys_trad.text.main,
    padding: '4px 10px',
    minHeight: 24
  },
  label: {
    whiteSpace: 'nowrap',
    padding: '8px 10px 7px'
  }
})

const UnderlyingMetrics = (props) => {
  const {
    id,
    columns,
    underlyingDataMetrics,
    showDonut,
    desiredOrder,
    isFilterStateAvailable
  } = props

  const classes = useStyles()
  underlyingDataMetrics?.forEach((metric) => {
    metric.data[0] = metric.data[0].sort((a, b) => {
      return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name)
    })
  })

  const fetchMaxValues = (type) => {
    let riskCol = {}
    let maxValues = {}

    desiredOrder.forEach((risk, index) => {
      riskCol[index] = []
      maxValues[index] = []
    })

    if (type === 'investment_pct') {
      underlyingDataMetrics?.forEach((metric) => {
        desiredOrder.forEach((risk, index) => {
          riskCol[index].push(
            ...new Set(
              metric.data[0]
                .filter((category) => category.name === risk)
                .map(({ investment_pct }) => investment_pct)
            )
          )
        })
      })
    } else {
      underlyingDataMetrics?.forEach((metric) => {
        desiredOrder.forEach((risk, index) => {
          riskCol[index].push(
            ...new Set(
              metric.data[0]
                .filter((category) => category.name === risk)
                .map(({ fac_exposed }) => fac_exposed)
            )
          )
        })
      })
    }

    Object.keys(riskCol).forEach((key) => {
      let topValues = riskCol[key].sort((a, b) => b - a).slice(0, 3)
      topValues = [].concat.apply([], topValues)
      maxValues[key].push(topValues)
    })

    return maxValues
  }

  return (
    <div
      className={classes.parentContainer}
      style={{ overflowX: underlyingDataMetrics ? 'auto' : 'unset' }}
    >
      {underlyingDataMetrics ? (
        <>
          <TableContainer className={clsx(classes.tableContainer)}>
            <Table id={id} className={classes.table}>
              <TableHead className={classes.tableHead}>
                <TableRow key="headrow0">
                  <TableCell className={classes.headerCell}>
                    {columns[0].HeaderLabel}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {underlyingDataMetrics &&
                  underlyingDataMetrics.length > 0 &&
                  underlyingDataMetrics.map((row, rIndex) => (
                    <TableRow key={`bodyrow${rIndex}`}>
                      <TableCell
                        className={clsx(classes.rowCell, classes.label)}
                      >
                        {row.title}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <MetricsTable
            id="table-id-2"
            headerLabel="Investments"
            underlyingDataMetrics={underlyingDataMetrics}
            dataKey="investment_pct"
            hasDivider={false}
            maxValues={fetchMaxValues('investment_pct')}
            showDonut={showDonut}
          />
          <MetricsTable
            id="table-id-1"
            headerLabel="Facilities Exposed"
            underlyingDataMetrics={underlyingDataMetrics}
            dataKey="fac_exposed"
            hasDivider={false}
            maxValues={fetchMaxValues('fac_exposed')}
            showDonut={showDonut}
          />
        </>
      ) : (
        <Error
          id={'mtError'}
          dataStatus={
            isFilterStateAvailable ? PAGE_CON.ERROR : PAGE_CON.SUCCESS
          }
          errorOccuredOn={PAGE_CON.PORTFOLIO_UNDERLYING_DATAMETRICS}
          width="100%"
        />
      )}
    </div>
  )
}

UnderlyingMetrics.propTypes = {
  /** Table id */
  id: PropTypes.string,
  /** Column header and data field details */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      HeaderLabel: PropTypes.string,
      HideLabel: PropTypes.bool,
      ColumnSpan: PropTypes.number,
      DataField: PropTypes.arrayOf(
        PropTypes.shape({
          Name: PropTypes.string,
          Alignment: PropTypes.string,
          ChartType: PropTypes.string,
          ColorGroup: PropTypes.string,
          IsDivider: PropTypes.bool,
          Value: PropTypes.number,
          IsSorted: PropTypes.bool
        })
      )
    })
  ),
  /** Data for underlying data metrics */
  underlyingDataMetrics: PropTypes.any,
  /** Whether to show/hide Donut in the table */
  showDonut: PropTypes.bool,
  /** Category order in which table should be populated */
  desiredOrder: PropTypes.array
}

UnderlyingMetrics.defaultProps = {
  desiredOrder: [
    'No Risk',
    'Low Risk',
    'Medium Risk',
    'High Risk',
    'Red Flag Risk'
  ]
}

export default UnderlyingMetrics
