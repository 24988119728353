import { makeStyles } from '@material-ui/core'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1.8),
    '&:hover': {
      backgroundColor: (props) =>
        props.modalClass === 'smallModal'
          ? themeColorPalette.palette.white.main
          : themeColorPalette.palette.sys_trad.bg.main
    }
  },
  dialogContent: {
    ...textTheme.typography.p3,
    overflowY: (props) => (props.modalScroll === 'paper' ? null : 'unset'),
    overflowX: (props) => props.hideModalXScroll && 'hidden',
    paddingTop: (props) => (props.modalClass === 'smallModal' ? '0px' : '24px'),
    '&.MuiDialogContent-root': {
      padding: (props) => props.modalClass === 'smallModalWithHeight' && '16px'
    }
  },
  dialogAction: {
    ...textTheme.typography.h2,
    paddingRight: '24px',
    paddingLeft: '24px',
    paddingBottom: '20px'
  },
  dialogTitle: {
    ...textTheme.typography.h1,
    color: themeColorPalette.palette.sys_trad.text.main,
    marginTop: '5px',
    marginBottom: '0px',
    padding: '16px 0px 12px 24px'
  },
  smallModal: {
    boxShadow: '1px 3px 5px 0px rgba(0, 0, 0, 0.35)',
    width: (props) => props.modalWidth && props.modalWidth
  },
  smallModalWithHeight: {
    boxShadow: '1px 3px 5px 0px rgba(0, 0, 0, 0.35)',
    height: '400px',
    '& .MuiDialogContent-root:first-child': {
      paddingTop: 0
    },
    width: (props) => props.modalWidth && props.modalWidth
  },
  errorModal: {
    boxShadow: '1px 3px 5px 0px rgba(0, 0, 0, 0.35)',
    '& .MuiDialogContent-root:first-child': {
      padding: 12
    }
  },
  minorDialogTitle: {
    ...textTheme.typography.h1,
    color: themeColorPalette.palette.sys_trad.text.main,
    marginTop: '5px',
    marginBottom: '0px',
    padding: '0px 24px 12px 24px'
  },
  minorDialogHeader: {
    backgroundColor: (props) =>
      props.headerBgColor
        ? props.headerBgColor
        : themeColorPalette.palette.sys_trad.bg.main,
    paddingTop: '10px'
  },
  minorDialogHeaderContent: {
    ...textTheme.typography.p1,
    marginTop: '8px',
    '&.MuiDialogContent-root': {
      padding: '0px'
    }
  },
  minorModal: {
    boxShadow: '0px 2px 8px 0px rgba(38, 65, 94, 0.2)',
    width: (props) => props.modalWidth && props.modalWidth,
    minHeight: (props) =>
      props.modalMinHeight ? props.modalMinHeight : '500px',
    '&.MuiDialog-paper': {
      position: (props) => (props.modalPosition ? props.modalPosition : ''),
      top: (props) => (props.topPosition ? props.topPosition : '')
    }
  },
  dividerStyle: {
    backgroundColor: themeColorPalette.palette.sys_trad.border.main
  }
}))
