import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import getColor from 'utils/color.js'
import { useStyles } from './styles'

const Legend = (props) => {
  const colorOptions = props.colorOptions
  const colorGroup = props.colorGroup
  const classes = useStyles(props)
  return (
    <Paper
      className={
        props.boldTitle ? classes.esgScoreLegendBold : classes.esgScoreLegend
      }
    >
      {props.legendTitle}
      <div className={classes.legendDiv}>
        <Grid container id={props.id} className={classes.legend}>
          <Grid item sm={'auto'}>
            {props.leftItemText && (
              <span className={clsx(classes.legendItem, classes.itemTextStyle)}>
                {props.leftItemText}
              </span>
            )}
            {colorOptions.map((item, index) => {
              let itemObj = props.scoreRange ? item.label : item
              return (
                <span key={index} className={classes.legendItem}>
                  {props.labelFirst ? (
                    <>
                      {itemObj}
                      <div
                        className={classes.leftIconStyle}
                        style={{ background: getColor(colorGroup, itemObj) }}
                      />
                    </>
                  ) : (
                    <>
                      <div
                        className={classes.rightIconStyle}
                        style={{ background: getColor(colorGroup, itemObj) }}
                      />
                      {itemObj}
                    </>
                  )}
                </span>
              )
            })}
            {props.rightItemText && (
              <span className={classes.itemTextStyle}>
                {props.rightItemText}
              </span>
            )}
          </Grid>
        </Grid>
      </div>
    </Paper>
  )
}
Legend.propTypes = {
  id: PropTypes.string,
  colorOptions: PropTypes.any,
  legendTitle: PropTypes.string,
  boldTitle: PropTypes.bool,
  scoreRange: PropTypes.bool,
  labelFirst: PropTypes.bool,
  leftItemText: PropTypes.string,
  RightItemText: PropTypes.string
}

Legend.defaultProps = {
  labelFirst: false
}

export default Legend
