import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import { makeStyles } from '@material-ui/core'
import theme from 'theme'

export const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  grid: {
    textAlign: 'left',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  title: {
    ...textTheme.typography.h2,
    lineHeight: '16px',
    fontSize: 16,
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingBottom: 8
  },
  subTitle: {
    marginTop: '4px',
    marginBottom: '4px'
  },
  textContainer: {
    paddingLeft: '4px'
  },
  text: {
    ...textTheme.typography.h3,
    lineHeight: '16px',
    fontSize: '12px',
    color: themeColorPalette.palette.sys_trad.text.main
  },
  innerText: {
    ...textTheme.typography.h4,
    lineHeight: '16px',
    fontStyle: 'normal',
    fontSize: '12px',
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingTop: '4px'
  },
  data: {
    ...textTheme.typography.h3,
    lineHeight: '16px',
    fontSize: '12px'
  },
  dataColor: {
    color: themeColorPalette.palette.sys_trad.status.main
  },
  ItemValue_Number: {
    color: themeColorPalette.palette.sys_trad.error.main
  },
  ItemValue_No: {
    color: themeColorPalette.palette.sys_trad.error.main
  },
  ItemValue_Yes: {
    color: themeColorPalette.palette.sys_trad.success.main
  },
  ItemValue_NotIndicated: {
    color: themeColorPalette.palette.sys_trad.text2.main
  },
  divider: {
    backgroundColor: themeColorPalette.palette.sys_trad.border.main,
    marginTop: 8,
    marginBottom: 8
  },
  firstDivider: {
    backgroundColor: themeColorPalette.palette.sys_trad.border.main,
    marginTop: 8,
    marginBottom: 16
  },
  trend_divider: {
    backgroundColor: themeColorPalette.palette.sys_trad.border.main,
    marginTop: 16,
    marginBottom: 8
  },
  titleLeft: {
    ...textTheme.typography.h2,
    lineHeight: '24px',
    fontSize: 20,
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingBottom: 8
  },
  titleRight: {
    ...textTheme.typography.h2,
    lineHeight: '24px',
    fontSize: 20,
    color: themeColorPalette.palette.sys_trad.main.main,
    paddingBottom: 8
  },
  linkStyle: {
    color: themeColorPalette.palette.sys_trad.main.main,
    textDecoration: 'underline'
  },
  headerDescriptionStyle: {
    minWidth: '1100px'
  },
  sectionRight: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left'
    },
    paddingRight: 24
  },
  gridStyle: {
    minWidth: 397,
    paddingLeft: 75,
    paddingRight: 75
  },
  recentControversies: {
    ...textTheme.typography.p3,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
    borderRadius: '2px',
    border: '1px solid' + themeColorPalette.palette.white.main,
    marginRight: '16px',
    width: '282px',
    minHeight: '72px',
    cursor: 'pointer'
  },
  divRoot: {
    flexGrow: 1
  },
  subHeader: {
    ...textTheme.typography.h4,
    lineHeight: '16px',
    fontStyle: 'normal',
    fontSize: '12px',
    color: themeColorPalette.palette.sys_trad.main.main,
    paddingBottom: '24px',
    cursor: 'pointer'
  },
  openButton: {
    padding: 0,
    paddingLeft: 7,
    ...theme.typography.p3,
    paddingBottom: 4,
    color: themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      background: 'none'
    }
  },
  openIcon: {
    height: '16px',
    width: '16px'
  },
  ControversyStyle: {
    ...textTheme.typography.h3,
    lineHeight: '16px',
    fontSize: '14px',
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingBottom: 10
  },
  relatedDocStyle: {
    ...textTheme.typography.h3,
    lineHeight: '16px',
    fontSize: '14px',
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingBottom: 10,
    paddingTop: 24
  },
  sourceDocStyle: {
    paddingLeft: '11px'
  },
  sourceDocTopStyle: {
    paddingTop: 11
  },
  sourceDocMiddleStyle: {
    paddingTop: 8
  },
  sourceDocLastStyle: {
    paddingBottom: 11
  },
  errorStyle: {
    borderRadius: '2px',
    marginLeft: -75,
    minWidth: '845px',
    marginTop: -10,
    height: 370
  },
  rightPanelTitle: {
    ...theme.typography.h3,
    marginBottom: '10px'
  },
  recentControversies_material_related_con: {
    ...textTheme.typography.p3,
    boxSizing: 'border-box',
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
    borderRadius: '2px',
    border: '1px solid' + themeColorPalette.palette.white.main,
    padding: '12px',
    marginRight: '16px',
    width: '282px',
    minHeight: '72px',
    cursor: 'pointer',
    marginBottom: '8px'
  },
  controversyTitle: {
    ...textTheme.typography.p3,
    padding: '8px 0',
    display: 'block'
  },
  controversyDate: {
    ...textTheme.typography.p3,
    color: themeColorPalette.palette.sys_trad.text2.main
  },
  criticalLabel: {
    ...textTheme.typography.p3,
    backgroundColor: themeColorPalette.palette.sys_trad.error.main,
    color: '#FFFFFF',
    padding: '2px 8px',
    borderRadius: '3px'
  }
}))
