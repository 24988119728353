import React from 'react'
import clsx from 'clsx'
import * as S from './styles'
import PropTypes from 'prop-types'

const Item = ({
  titleValue,
  subTitle,
  value,
  clickHandler,
  isEntityListHeatmap,
  isMultiline,
  predictedScore
}) => {
  const classes = S.useStyles()()

  return (
    <S.ListItem
      clickable={clickHandler !== undefined}
      className={clsx(
        isMultiline ? 'multiline' : '',
        predictedScore ? classes.predictedScore : ''
      )}
    >
      <div>
        <h3
          {...(isEntityListHeatmap
            ? { heap_id: 'heatmap', heap_entity: titleValue.props.children }
            : {})}
        >
          {titleValue}
        </h3>
        <h4>{subTitle}</h4>
      </div>
      <div>{value}</div>
    </S.ListItem>
  )
}

Item.propTypes = {
  title: PropTypes.string,
  titleValue: PropTypes.any,
  subTitle: PropTypes.string,
  value: PropTypes.node,
  itemKey: PropTypes.any,
  clickHandler: PropTypes.func
}

export default Item
