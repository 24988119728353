import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

const selectedBgColor = themeColorPalette.palette.sys_trad.selected2.main
const hoverBgColor = themeColorPalette.palette.sys_trad.hover2.main

export const useStyle = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: (props) =>
        props.selectionType === 'none'
          ? 'none'
          : props.selected
          ? selectedBgColor
          : hoverBgColor
    },
    '&:hover :nth-child(1)': {
      textDecoration: (props) => (props.selectionType !== 'none' ? 'none' : '')
    },
    width: (props) => props.maxWidth,
    borderRadius: 0
  },
  shadow: {
    '&:hover': {
      border: (props) =>
        props.selectionType === 'none'
          ? 'none'
          : props.noHover
          ? '1px solid ' + themeColorPalette.palette.white.main
          : '1px solid ' + themeColorPalette.palette.sys_trad.hover.main
    },
    '&:hover :nth-child(1)': {
      textDecoration: (props) => (props.selectionType !== 'none' ? 'none' : '')
    },
    width: (props) => props.maxWidth,
    boxSizing: 'border-box',
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.14)',
    borderRadius: '2px',
    border: '1px solid' + themeColorPalette.palette.white.main,
    padding: (props) => props.cardPadding || '5px',
    height: (props) => props.height || 'auto'
  },
  shadowLight: {
    '&:hover :nth-child(1)': {
      textDecoration: (props) => (props.selectionType !== 'none' ? 'none' : '')
    },
    width: (props) => props.maxWidth,
    boxSizing: 'border-box',
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
    borderRadius: '2px',
    border: '1px solid' + themeColorPalette.palette.white.main,
    padding: '5px',
    height: (props) => props.height || 'auto'
  },
  miniCard: {
    '&:hover': {
      border: '1px solid ' + themeColorPalette.palette.sys_trad.hover.main,

      cursor: 'pointer'
    },
    width: (props) => props.maxWidth,
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.14)',
    boxSizing: 'border-box',
    borderRadius: '2px',
    border: '1px solid' + themeColorPalette.palette.white.main
  },
  searchCard: {
    '&:hover': {
      border: '1px solid ' + themeColorPalette.palette.sys_trad.hover.main,

      cursor: 'pointer'
    },
    width: (props) => props.maxWidth,
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.14)',
    boxSizing: 'border-box',
    borderRadius: '2px',
    border: '1px solid' + themeColorPalette.palette.white.main
  },
  platformSearchCardSelected: {
    backgroundColor: themeColorPalette.palette.sys_trad.selected2.main
  },
  platformSearchCard: {
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
      cursor: 'pointer'
    },
    width: (props) => props.maxWidth,
    boxSizing: 'border-box',
    borderRadius: '2px'
  },
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  actionArea: {
    padding: '0 4px',
    ...textTheme.typography.h2,
    height: 34,
    color: themeColorPalette.palette.sys_trad.main.main,
    textDecoration: (props) => (props.selected ? 'none' : 'underline'),
    '&:hover': {
      textDecoration: (props) =>
        props.selectionType === 'none' ? 'underline' : 'none',
      cursor: (props) =>
        props.selectionType === 'none' ? 'default' : 'pointer'
    }
  },
  shadowActionArea: {
    padding: '0px 4px 0px 2px',
    ...textTheme.typography.h2,
    height: (props) => props.cardHeaderHeight || 34,
    color: (props) =>
      props.headerColor || themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      cursor: (props) =>
        props.selectionType === 'none' ? 'default' : 'pointer'
    }
  },
  shadowLightActionArea: {
    padding: '0 4px',
    ...textTheme.typography.h2,
    height: 34,
    color: themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      cursor: (props) =>
        props.selectionType === 'none' ? 'default' : 'pointer'
    }
  },
  focusHighlight: {
    '&&&': {
      opacity: 0
    }
  },
  ripple: {
    opacity: 0
  },
  cardSelected: {
    backgroundColor: selectedBgColor
  },
  shadowSelected: {
    border: '1px solid' + themeColorPalette.palette.sys_trad.main.main
  },
  miniCardSelected: {
    border: '1px solid' + themeColorPalette.palette.sys_trad.selected.main
  },
  cardAvatar: {
    marginRight: 0,
    marginTop: '6px',
    paddingTop: '6px'
  },
  cardContent: {
    padding: '9px 0px 9px 0px',
    ...textTheme.typography.h2
  }
})
