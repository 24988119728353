import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconWeight = (props) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.73244 6C9.90261 5.70583 10 5.36429 10 5V4C10 2.89543 9.10457 2 8 2C6.89543 2 6 2.89543 6 4V5C6 5.36429 6.09739 5.70583 6.26756 6H4.78077C4.3219 6 3.92192 6.3123 3.81062 6.75746L2.31062 12.7575C2.15284 13.3886 2.63019 14 3.28077 14H12.7192C13.3698 14 13.8471 13.3886 13.6894 12.7575L12.1894 6.75746C12.0781 6.3123 11.6781 6 11.2192 6H9.73244ZM9 4V5C9 5.55228 8.55228 6 8 6C7.44772 6 7 5.55228 7 5V4C7 3.44772 7.44772 3 8 3C8.55228 3 9 3.44772 9 4Z"
    />
  </SvgIcon>
)
IconWeight.displayName = 'IconWeight'
IconWeight.muiName = 'SvgIcon'

export default IconWeight
