import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'

export const useStyles = makeStyles(() => ({
  contact: {
    textAlign: 'right',
    color: themeColorPalette.palette.sys_trad.texthelp.main,
    ...textTheme.typography.p3
  },
  disabledDropDown: {
    'pointer-events': 'none',
    opacity: 0.7
  },
  container: {
    marginTop: '50px',
    position: 'relative'
  },
  title: {
    ...theme.typography.h1,
    color: theme?.palette?.sys_trad?.text.main,
    fontSize: 16,
    fontWeight: 500
  },
  titleSidePanel: {
    ...theme.typography.h1,
    color: theme.palette.sys_trad.main.main,
    fontSize: 16,
    fontWeight: 500
  },
  coverageTitle: {
    ...theme.typography.p4,
    color: theme?.palette?.sys_trad?.main.main,
    fontSize: 12,
    marginTop: 5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& span': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  coverageContent: {
    ...theme.typography.p1,
    color: theme?.palette?.sys_trad?.text.main,
    fontSize: 10,
    marginBottom: 16
  },
  sectionTitle: {
    color: theme?.palette?.sys_trad?.text.main,
    fontSize: 16,
    marginBottom: '15px',
    '&>span': {
      marginRight: '15px'
    }
  },
  chicletsContainer: {
    display: 'flex',
    '&>div.loading': {
      width: 'fit-content !important',
      margin: '0 10px'
    }
  },
  critical: {
    color: theme?.palette?.sys_trad?.error.main
  },
  listCellContainer: {
    '&.CompanyNameWrapper': {
      display: 'flex'
    },
    '&.Portfolio': {
      flexDirection: 'column',
      ...theme.typography.p4,
      fontSize: 12,
      color: theme?.palette?.sys_trad?.text.main,
      maxWidth: '10vw'
    },
    '&.CompanyNamePortfolio': {
      display: 'block'
    },
    '&.ellipsis': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      float: 'left'
    },
    '&.heatmapEllipsis': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      float: 'left',
      paddingRight: '3px'
    },
    '&.clear': {
      clear: 'both'
    },
    '&.name': {
      ...theme.typography.p4,
      fontSize: 12,
      color: theme?.palette?.sys_trad?.text.main,
      textDecoration: 'underline',
      maxWidth: '10vw',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'block',
      cursor: 'pointer'
    },
    '&.date': {
      color: theme?.palette?.sys_trad?.text2.main,
      whiteSpace: 'nowrap'
    },
    '&.type': {
      ...theme.typography.p1,
      color: theme?.palette?.sys_trad?.text.main
    },
    '&.critical': {},
    '&.description': {
      ...theme.typography.p2,
      color: theme?.palette?.sys_trad?.text.main,
      cursor: 'pointer'
    },
    '&.sector': {
      ...theme.typography.p4,
      fontSize: 12,
      color: theme?.palette?.sys_trad?.text.main,
      whiteSpace: 'nowrap'
    },
    '&.country': {
      ...theme.typography.p4,
      fontSize: 12,
      color: theme?.palette?.sys_trad?.text.main
    },
    '&.entity': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  modalTitle: {
    ...theme.typography.h3
  },
  modalSubTitle: { ...theme.typography.p2, marginTop: '8px', fontSize: 16 },
  modalDate: { ...theme.typography.p3, marginTop: '8px' },
  modalContentParagraph: {
    ...theme.typography.p2,
    '&.list': {
      ...theme.typography.p3,
      padding: '0 70px',
      marginTop: '12px'
    },
    '&.footer': {
      ...theme.typography.p2,
      marginTop: '24px'
    },
    '&.link': {
      ...theme.typography.p2,
      marginTop: '24px',
      textDecoration: 'underline'
    }
  },
  modalContentParagraphContent: {
    marginTop: '24px'
  },
  filtersContainer: {
    display: 'flex',
    '&>div:first-child': {
      marginTop: '-3px'
    },
    '&>div': {
      marginRight: '8px',
      background: theme?.palette?.sys_trad?.selected2.main,
      borderRadius: '2px'
    }
  },
  panelContainer: {
    width: '300px',
    padding: '25px 16px 16px 16px',
    borderBottom: `1px solid ${theme?.palette?.sys_trad?.border.main}`,
    '&>h2': {
      ...theme.typography.h2,
      marginBottom: '12px'
    },
    '&>p': {
      ...theme.typography.p3,
      color: theme?.palette?.sys_trad?.text.main
    },
    '& ul': {
      marginLeft: '10px',
      '& ul': {
        margin: '10px 0 10px 20px'
      }
    },
    '& li': {
      ...theme.typography.p3,
      color: theme?.palette?.sys_trad?.text.main
    },
    '& div': {
      marginTop: '8px'
    }
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% + 4px)',
    margin: '0',
    marginLeft: '-4px',
    backgroundColor: theme?.palette?.sys_trad?.bg.main,
    padding: (props) => (props.minify ? '0px' : '24px 32px 0 32px')
  },
  performanceTableTitle: {
    marginBottom: '12px',
    display: 'inline-block'
  },
  performanceTableDropdown: {
    marginBottom: '12px',
    display: 'inline-block',
    marginLeft: '16px',
    verticalAlign: 'sub'
  },
  cellContainer: {
    '&.critical': {
      color: theme?.palette?.sys_trad?.error.main
    },
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '&>div.value': {
      minWidth: '20px'
    },
    '&>div.label': {
      marginRight: '5px'
    }
  },
  arrowContainer: {
    width: '10px',
    display: 'inline-block'
  },
  list: {
    '& .MuiListSubheader-root:first-child': {
      marginTop: 0
    },
    '& li': {
      padding: '0px 4px'
    }
  },
  listButton: {
    padding: 0,
    '& span': {
      display: 'flex',
      flex: 1,
      padding: '0 2px',
      alignItems: 'center',
      height: '32px'
    },
    ...theme.typography.p3,
    color: theme.palette?.sys_trad?.text?.main,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    borderRadius: 2,
    letterSpacing: 0,
    textTransform: 'none',
    lineHeight: '16px',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette?.sys_trad?.hover2.main,
      lineHeight: '16px',
      textDecoration: 'none !important'
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: theme.palette?.sys_trad?.hover2.main,
        lineHeight: '16px',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      }
    }
  },
  listButtonActive: {
    backgroundColor: theme.palette?.sys_trad?.selected2?.main,
    textDecoration: 'none !important',
    lineHeight: 'normal',
    '&:hover': {
      textDecoration: 'none !important',
      backgroundColor: theme.palette?.sys_trad?.selected2?.main,
      lineHeight: 'normal'
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette?.sys_trad?.white?.main,
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: theme.palette?.sys_trad?.hover2?.main,
        textDecoration: 'none !important',
        lineHeight: 'normal'
      }
    }
  },
  listSubheader: {
    ...theme.typography.p3,
    color: theme.palette?.sys_trad?.text2?.main,
    whiteSpace: 'normal',
    paddingLeft: 4,
    float: 'left',
    width: '100%',
    height: 'auto',
    wordWrap: 'break-word',
    lineHeight: '16px',
    marginTop: '16px'
  },
  heatmapSummary: {
    display: 'flex',
    flexDirection: 'column',
    '&>div': {
      flex: 1,
      textAlign: 'left',
      '&>span': {
        display: 'flex',
        '& h3': {
          ...theme.typography.p2,
          color: theme?.palette?.sys_trad?.text.main
        },
        '&>h3:last-child': {
          marginLeft: '35px'
        }
      },
      '&:first-child': {
        marginBottom: '20px'
      }
    },
    '& h3': {
      ...theme.typography.p2,
      color: theme?.palette?.sys_trad?.text.main
    },
    '& p': {
      ...theme.typography.p3,
      color: theme?.palette?.sys_trad?.text.main,
      marginTop: '5px'
    },
    '& div.data': {
      ...theme.typography.p2,
      color: theme?.palette?.sys_trad?.text.main,
      marginTop: '10px'
    }
  },
  heatmapSelector: {
    '&>p': {
      ...theme.typography.p2,
      marginBottom: '4px',
      color: theme?.palette?.sys_trad?.text2.main
    }
  },
  boxContainer: {
    minHeight: (props) => props.minHeight && props.minHeight,
    padding: (props) => props.padding && props.padding
  },
  heatmapEntityContainer: {
    height: '100%',
    padding: '0 0 0 30px',
    '& div.noSelectMsg': {
      ...theme.typography.p2,
      color: theme?.palette?.sys_trad?.text2.main,
      backgroundColor: '#F5F7F7',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: '10px'
    },
    '&>div.entityList': {
      overflowY: 'auto',
      overflowX: 'hidden',
      width: '100%',
      '&>div': {
        width: '100%',
        overflow: 'visible',
        boxSizing: 'border-box'
      }
    }
  },
  heatmapEntityItem: {
    display: 'flex',
    marginRight: '8px',
    '&>div': {
      flex: 1,
      textAlign: 'right'
    }
  },
  heatmapEntityHeader: {
    ...theme.typography.p2,
    position: 'sticky',
    top: 0,
    backgroundColor: theme?.palette?.sys_trad?.white.main,
    marginBottom: '5px',
    display: 'flex',
    textAlign: 'right',
    padding: '8px 16px 8px 8px',
    flex: 1,
    color: theme?.palette?.sys_trad?.text2.main,
    '&>div': {
      '&:first-child': {
        textAlign: 'left'
      },
      flex: 1,
      display: 'flex',
      '&>div': {
        flex: 1,
        '&:last-child': {}
      }
    }
  },
  heatmapHeader: {
    borderBottom: `1px solid ${theme?.palette?.sys_trad?.border.main}`,
    marginBottom: '16px'
  },
  backdrop: {
    zIndex: 1200 + 1,
    color: '#fff'
  },
  loaderBackdrop: {
    display: (props) => (props.visible ? 'block' : 'none'),
    zIndex: 999999999,
    position: 'fixed'
  },
  buttonContainer: {
    marginBottom: '14px'
  },
  listContainer: {
    paddingLeft: '12px'
  },
  geographicRiskTitleContainer: {
    display: 'flex',
    '&>.dropdownContainer': {
      marginLeft: 10
    }
  },
  portfolioAnalysisLink: {
    ...theme.typography.p4,
    color: theme?.palette?.sys_trad?.main.main,
    fontSize: 12,
    marginTop: 5,
    overflow: 'hidden',
    cursor: 'pointer',
    position: 'absolute',
    top: '-50px',
    right: 0
  }
}))
