import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconIndustry = (props) => (
  <SvgIcon {...props}>
    <mask id="path-1-inside-1" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.442501 1.48364C0.451328 1.21401 0.672459 1 0.942233 1H4.55795C4.82773 1 5.04886 1.21401 5.05769 1.48365L5.33195 9.86458L10.2183 6.53296C10.5502 6.30666 11 6.54436 11 6.94607V9.40909L15.2183 6.53296C15.5502 6.30666 16 6.54436 16 6.94607V14.5C16 14.7761 15.7761 15 15.5 15H11H10H5V14.9997L4.98337 15H0.516638C0.234083 15 0.00766013 14.766 0.0169055 14.4836L0.442501 1.48364Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.442501 1.48364C0.451328 1.21401 0.672459 1 0.942233 1H4.55795C4.82773 1 5.04886 1.21401 5.05769 1.48365L5.33195 9.86458L10.2183 6.53296C10.5502 6.30666 11 6.54436 11 6.94607V9.40909L15.2183 6.53296C15.5502 6.30666 16 6.54436 16 6.94607V14.5C16 14.7761 15.7761 15 15.5 15H11H10H5V14.9997L4.98337 15H0.516638C0.234083 15 0.00766013 14.766 0.0169055 14.4836L0.442501 1.48364Z"
      fill="#26415E"
    />
    <path
      d="M0.442501 1.48364L-0.556964 1.45092L0.442501 1.48364ZM5.05769 1.48365L4.05822 1.51635V1.51635L5.05769 1.48365ZM5.33195 9.86458L4.33248 9.89729L4.39199 11.7158L5.89528 10.6908L5.33195 9.86458ZM10.2183 6.53296L9.65499 5.70673V5.70673L10.2183 6.53296ZM11 9.40909H10V11.3012L11.5633 10.2353L11 9.40909ZM15.2183 6.53296L15.7817 7.35918L15.7817 7.35918L15.2183 6.53296ZM5 15H4V16H5V15ZM5 14.9997H6V13.9833L4.98372 13.9999L5 14.9997ZM4.98337 15V16H4.99151L4.99966 15.9999L4.98337 15ZM0.0169055 14.4836L1.01637 14.5164L0.0169055 14.4836ZM0.942233 0C0.132911 0 -0.530482 0.642033 -0.556964 1.45092L1.44197 1.51636C1.43314 1.78599 1.21201 2 0.942233 2V0ZM4.55795 0H0.942233V2H4.55795V0ZM6.05715 1.45094C6.03068 0.642045 5.36728 0 4.55795 0V2C4.28818 2 4.06704 1.78598 4.05822 1.51635L6.05715 1.45094ZM6.33141 9.83187L6.05715 1.45094L4.05822 1.51635L4.33248 9.89729L6.33141 9.83187ZM9.65499 5.70673L4.76861 9.03835L5.89528 10.6908L10.7817 7.35918L9.65499 5.70673ZM12 6.94607C12 5.74094 10.6507 5.02783 9.65499 5.70673L10.7817 7.35918C10.4498 7.58548 10 7.34778 10 6.94607H12ZM12 9.40909V6.94607H10V9.40909H12ZM11.5633 10.2353L15.7817 7.35918L14.655 5.70673L10.4367 8.58286L11.5633 10.2353ZM15.7817 7.35918C15.4498 7.58548 15 7.34778 15 6.94607H17C17 5.74094 15.6507 5.02783 14.655 5.70673L15.7817 7.35918ZM15 6.94607V14.5H17V6.94607H15ZM15 14.5C15 14.2239 15.2239 14 15.5 14V16C16.3284 16 17 15.3284 17 14.5H15ZM15.5 14H11V16H15.5V14ZM11 14H10V16H11V14ZM5 16H10V14H5V16ZM4 14.9997V15H6V14.9997H4ZM4.99966 15.9999L5.01629 15.9996L4.98372 13.9999L4.96709 14.0001L4.99966 15.9999ZM0.516638 16H4.98337V14H0.516638V16ZM-0.982559 14.4509C-1.0103 15.2981 -0.331026 16 0.516638 16V14C0.799192 14 1.02562 14.234 1.01637 14.5164L-0.982559 14.4509ZM-0.556964 1.45092L-0.982559 14.4509L1.01637 14.5164L1.44197 1.51636L-0.556964 1.45092Z"
      fill="#26415E"
      mask="url(#path-1-inside-1)"
    />
  </SvgIcon>
)
IconIndustry.displayName = 'IconIndustry'
IconIndustry.muiName = 'SvgIcon'

export default IconIndustry
