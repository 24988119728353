import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'
import { IconClose, PrimaryLink } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import { AppBar } from '@material-ui/core'
import { useStyles } from './styles'

const Panel = (props) => {
  const { backDropInvisible = true } = props
  const classes = useStyles(props)

  return (
    <Drawer
      id={props.id}
      variant="temporary"
      PaperProps={{ className: classes.drawerPaper }}
      BackdropProps={{ invisible: backDropInvisible }}
      anchor="right"
      open={props.openPanel}
      onClose={props.onPanelClose}
    >
      <AppBar className={classes.appBar} position="sticky">
        <div style={{ width: '100%' }}>
          <Box className={classes.drawerHeader} display="flex" p={1}>
            {props.panelHeaderComponent && (
              <Box p={1} width="100%">
                {props.panelHeaderComponent}
              </Box>
            )}
            {props.title && (
              <Box p={1} className={classes.drawerHeaderTitle} width="100%">
                {props.title}
                {props.subtitle && (
                  <div
                    // eslint-disable-next-line react/no-unknown-property
                    p={1}
                    className={classes.drawersubheaderTitle}
                    width="100%"
                  >
                    {props.subtitle}
                  </div>
                )}
              </Box>
            )}
            <Box p={1} className={classes.drawerHeaderHide} flexShrink={0}>
              {props.closeSymbol ? (
                <span
                  className={classes.closeSymbol}
                  onClick={props.onPanelClose}
                  aria-hidden={true}
                >
                  <IconClose />
                </span>
              ) : (
                <PrimaryLink
                  linkText="hide"
                  linkClass="linkInline"
                  onClick={props.onPanelClose}
                ></PrimaryLink>
              )}
            </Box>
          </Box>
        </div>
      </AppBar>
      {props.panelContentComponent}
    </Drawer>
  )
}

Panel.propTypes = {
  /** Panel id */
  id: PropTypes.string,
  /** Panel Title */
  title: PropTypes.string,
  /** Panel open value */
  openPanel: PropTypes.bool,
  /** Panel close value */
  onPanelClose: PropTypes.func,
  /** Panel content value */
  panelContentComponent: PropTypes.any,
  /** title Padding left optional*/
  titlePadding: PropTypes.string,
  /** top number for drawer height start */
  topNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** right number for drawer width start */
  rightNum: PropTypes.number,
  closeSymbol: PropTypes.any,
  /** Panel backdrop */
  backDropInvisible: PropTypes.bool
}

export default Panel
