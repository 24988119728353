import { AppBar } from '@material-ui/core'
import { Container, Grid, makeStyles } from '@material-ui/core'
import { DropdownField, IconArrowDown, Page } from 'components'
import getMonthYear from 'utils/Date'
import { GlobalContext } from 'context/GlobalStateProvider'
import { COMMON, PARAMS, PHY_RSK_MGM } from 'constant/APIconstants'
import { ResearchLineService } from 'services'
import {
  PAGE_CON,
  REDUCER_STATE,
  REDUCER_TYPE,
  filterOptions
} from 'constant/constants'
import axios from 'axios'
import Error from 'utils/error'
import Laggards from '../../Laggards'
import Leaders from '../../Leaders'
import LoadingMask from 'utils/loadingMask'
import PortfolioAnalysisColumns from '../../PortfolioAnalysisColumns.json'
import PhysicalRiskManagementOverView from './PhysicalRiskManagementOverview'
import React, { useCallback, useContext, useEffect } from 'react'
import Regions from '../../Regions'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import Updates from '../../Updates'
import Impact from '../../Impact'
import getColor from 'utils/color'

const UpdatesTableColInfo = PortfolioAnalysisColumns.updates_Columns
const PanelUpdatesTableColInfo = PortfolioAnalysisColumns.updates_Panel_Columns
const LeaderLaggardColInfo =
  PortfolioAnalysisColumns.leaders_Laggards_Panel_Columns
const mimTabColInfo = JSON.parse(
  JSON.stringify(PortfolioAnalysisColumns.region_Sector_Minimal_Table_Columns)
)
const panelTabColInfo = PortfolioAnalysisColumns.region_Sector_Panel_Columns
const cardInfoContentInfo = PortfolioAnalysisColumns.card_Info_Section_Columns
const cardInfoContentInfoSector =
  PortfolioAnalysisColumns.card_Info_Section_Columns_Sector

const impactColumns = JSON.parse(
  JSON.stringify(PortfolioAnalysisColumns.impactColumns)
)

const drawerWidth = PAGE_CON.PAGE_LEFT_MARGIN

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  content: {
    flexGrow: 1
  },
  container: {
    marginLeft: drawerWidth - 20,
    paddingRight: 0,
    paddingLeft: '20px',
    marginTop: '22px'
  },
  tableTitle: {
    paddingBottom: '5px',
    ...textTheme.typography.h1
  },
  stickyHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    zIndex: 1,
    boxShadow: 'none'
  },
  overviewstickyHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    zIndex: 1,
    boxShadow: 'none'
  },
  containerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 16px 16px',
    marginTop: '24px'
  },
  impactContainerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 0px 16px',
    marginTop: '24px',
    paddingLeft: '16px'
  },
  impactDropdown: {
    marginTop: '16px',
    marginBottom: '12px'
  }
}))

const tableLength = 10

const linkPadding = {
  marginTop: '8px',
  marginLeft: '-4px'
}

const linkPaddingRegionIndustry = {
  marginTop: '8px',
  marginLeft: '-4px'
}

const updatesStyle = {
  paddingLeft: '2px',
  marginTop: '20px'
}

const UpdatesTableStyle = {
  marginTop: '12px',
  background: themeColorPalette.palette.sys_trad.white.main,
  boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.12)',
  boxRadius: '2px',
  boxSizing: 'border-box',
  padding: '12px',
  paddingTop: '0px',
  paddingBottom: '15px'
}

const linkStyle = {
  ...textTheme.typography.p1,
  color: themeColorPalette.palette.sys_trad.link.main,
  lineHeight: '16px',
  verticalAlign: 'top'
}

const regionStyle = {
  marginTop: '18px',
  paddingBottom: '10px'
}

const regionTitleStyle = {
  top: '16px'
}

const cardHeader = {
  ...textTheme.typography.p2,
  paddingBottom: '4px',
  paddingTop: '16px',
  lineHeight: '16px'
}

const mapDataClasses = [
  {
    color: themeColorPalette.palette.adv_wk.weak.main,
    from: 0,
    to: 29,
    name: 'weak'
  },
  {
    color: themeColorPalette.palette.adv_wk.limited.main,
    from: 30,
    to: 49,
    name: 'Limited'
  },
  {
    color: themeColorPalette.palette.adv_wk.robust.main,
    from: 50,
    to: 59,
    name: 'Robust'
  },
  {
    color: themeColorPalette.palette.adv_wk.advanced.main,
    from: 60,
    to: 100,
    name: 'Advanced'
  }
]

const PhysicalRiskManagement = (props) => {
  const classes = useStyles()
  const [filterState] = useContext(GlobalContext).globalFilter
  const [physicalRiskState, physicalRiskDispatch] =
    useContext(GlobalContext).globalPhysicalRisk

  const isFilterStateAvailable =
    filterState.filterData.regionsList.length > 0 ||
    filterState.filterData.sectorsList.length > 0

  let monthYear = getMonthYear(filterState)

  const [updateSectionText, setUpdateSectionText] = React.useState(
    `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}, ${PAGE_CON.IMPACT_TITLE}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`
  )
  const [selectedCardId, setSelectedCardId] = React.useState(null)
  let showBenchMark =
    filterState.globalPortfolioFilter.benchmark !== '' ? true : false

  let apiCallId = physicalRiskState.apiCallId

  let benchmarkPctCol = mimTabColInfo.filter(
    (col) => col.HeaderLabel === PAGE_CON.BENCHMARK_PERC
  )
  benchmarkPctCol[0].HideColumn = !showBenchMark

  const onImpactFilterChange = (event) => {
    physicalRiskDispatch({
      type: REDUCER_TYPE.UPDATE_IMPACT_FILTER,
      data: event.target.value
    })
  }

  const isRegionHasData = () => {
    let hasData = false
    if (
      physicalRiskState.portfolioRegions.data &&
      physicalRiskState.portfolioRegions.data.summary &&
      physicalRiskState.portfolioRegions.data.summary.length > 0 &&
      physicalRiskState.portfolioRegions.data.details.length > 0 &&
      physicalRiskState.portfolioRegions.data.mapData.length > 0
    )
      hasData = true
    return hasData
  }

  const getLeadersLaggardsData = async (
    sectors,
    regions,
    isErrorReload = false
  ) => {
    if (
      (physicalRiskState.portfolioLeaderLaggard.data &&
        physicalRiskState.portfolioLeaderLaggard.data.leaders &&
        physicalRiskState.portfolioLeaderLaggard.data.laggards &&
        physicalRiskState.portfolioLeaderLaggard.data.leaders.length > 0 &&
        physicalRiskState.portfolioLeaderLaggard.data.laggards.length > 0) ||
      (physicalRiskState.portfolioLeaderLaggard.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    physicalRiskDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS
    })
    let leadersLaggardsData = { leaders: [], laggards: [] }
    try {
      if (isFilterStateAvailable) {
        const { data } = await axios.post(
          COMMON.BASE_URL +
            filterState.globalPortfolioFilter.portfolio +
            PHY_RSK_MGM.PAGE_URL +
            COMMON.LEADERS_LAGGARDS,
          {
            [PARAMS.REGION]: regions,
            [PARAMS.SECTOR]: sectors,
            [PARAMS.MONTH]: filterState.globalPortfolioFilter.asOfDate.slice(4),
            [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(
              0,
              4
            )
          },
          { cancelToken: physicalRiskState.cancelToken.token }
        )

        leadersLaggardsData.leaders = data.leaders
        leadersLaggardsData.laggards = data.laggards
      }

      physicalRiskDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS,
        data: leadersLaggardsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        physicalRiskDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS,
          apiCallId
        })
      }
    }
  }

  const getRegionsData = async (sectors, regions, isErrorReload = false) => {
    if (
      (physicalRiskState.portfolioRegions.data.summary.length > 0 &&
        physicalRiskState.portfolioRegions.data.details.length > 0 &&
        physicalRiskState.portfolioRegions.data.mapData.length > 0 &&
        physicalRiskState.portfolioRegions.data.sectorsummary.length > 0 &&
        physicalRiskState.portfolioRegions.data.sectordetails.length > 0) ||
      (physicalRiskState.portfolioRegions.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    physicalRiskDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_REGIONS
    })
    let regionsData = {
      summary: [],
      details: [],
      mapData: [],
      sectorsummary: [],
      sectordetails: []
    }
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter

    try {
      if (isFilterStateAvailable) {
        const [summary, details, mapData, sectorsummary, sectordetails] =
          await axios.all([
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              researchLine: PHY_RSK_MGM.PAGE_URL,
              token: physicalRiskState.cancelToken.token,
              endPoint: COMMON.REGION_SUMMARY
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              researchLine: PHY_RSK_MGM.PAGE_URL,
              token: physicalRiskState.cancelToken.token,
              endPoint: COMMON.REGION_DETAILS
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              researchLine: PHY_RSK_MGM.PAGE_URL,
              token: physicalRiskState.cancelToken.token,
              endPoint: COMMON.MAP
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              researchLine: PHY_RSK_MGM.PAGE_URL,
              token: physicalRiskState.cancelToken.token,
              endPoint: COMMON.SECTOR_SUMMARY
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              researchLine: PHY_RSK_MGM.PAGE_URL,
              token: physicalRiskState.cancelToken.token,
              endPoint: COMMON.SECTOR_DETAILS
            })
          ])

        regionsData.summary = summary.data
        regionsData.details = details.data
        regionsData.sectorsummary = sectorsummary.data
        regionsData.sectordetails = sectordetails.data
        mapData?.data
          .filter(
            (data) => !isNaN(data.score) && data.score >= 0 && data.score <= 100
          )
          .map((mdata) => {
            return regionsData.mapData.push([
              mdata.country_code,
              mdata.score,
              mdata.score_category
            ])
          })
      }

      physicalRiskDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_REGIONS,
        data: regionsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        physicalRiskDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_REGIONS,
          apiCallId
        })
      } else console.log(err)
    }
  }

  const getUpdatesData = async (sectors, regions, isErrorReload = false) => {
    if (
      physicalRiskState.portfolioUpdates.data?.length > 0 ||
      (physicalRiskState.portfolioUpdates.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    physicalRiskDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_UPDATES
    })
    let updatesData = []
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter
    try {
      const { data } = isFilterStateAvailable
        ? await ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: PHY_RSK_MGM.PAGE_URL,
            token: physicalRiskState.cancelToken.token,
            endPoint: COMMON.UPDATES
          })
        : []

      updatesData = data

      physicalRiskDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_UPDATES,
        data: updatesData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        physicalRiskDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_UPDATES,
          apiCallId
        })
      }
    }
  }

  const getImpactCall = (filter, regions, sectors, month, year) => {
    return isFilterStateAvailable
      ? axios.post(
          COMMON.BASE_URL +
            filterState.globalPortfolioFilter.portfolio +
            PHY_RSK_MGM.PAGE_URL +
            COMMON.IMPACT,
          {
            [PARAMS.IMPACT_FILTER]: filter,
            [PARAMS.REGION]: regions,
            [PARAMS.SECTOR]: sectors,
            [PARAMS.MONTH]: month,
            [PARAMS.YEAR]: year
          },
          { cancelToken: physicalRiskState.cancelToken.token }
        )
      : {}
  }

  const getImpactData = async (sectors, regions, isErrorReload = false) => {
    if (
      (physicalRiskState.portfolioImpact.data &&
        physicalRiskState.portfolioImpact.data.length > 0) ||
      (physicalRiskState.portfolioImpact.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    physicalRiskDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_IMPACT
    })
    let impactData = {}
    try {
      const month = filterState.globalPortfolioFilter.asOfDate.slice(4)
      const year = filterState.globalPortfolioFilter.asOfDate.slice(0, 4)

      const [top5, top10, bottom5, bottom10] = await axios.all([
        getImpactCall(PAGE_CON.IMPACT_KEY_TOP_5, regions, sectors, month, year),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_TOP_10,
          regions,
          sectors,
          month,
          year
        ),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_BOTTOM_5,
          regions,
          sectors,
          month,
          year
        ),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_BOTTOM_10,
          regions,
          sectors,
          month,
          year
        )
      ])

      impactData[PAGE_CON.IMPACT_KEY_TOP_5] = top5.data[0]
      impactData[PAGE_CON.IMPACT_KEY_TOP_10] = top10.data[0]
      impactData[PAGE_CON.IMPACT_KEY_BOTTOM_5] = bottom5.data[0]
      impactData[PAGE_CON.IMPACT_KEY_BOTTOM_10] = bottom10.data[0]

      physicalRiskDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_IMPACT,
        data: impactData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        physicalRiskDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_IMPACT,
          apiCallId
        })
      }
    }
  }

  const reloadComponent = (errorOccuredOn) => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    switch (errorOccuredOn) {
      case PAGE_CON.LEADERS_TITLE:
      case PAGE_CON.LAGGARDS_TITLE:
        getLeadersLaggardsData(sectors, regions, true)
        break
      case PAGE_CON.REGIONS_TITLE:
        getRegionsData(sectors, regions, true)
        break
      case PAGE_CON.UPDATESIN_TITLE:
        getUpdatesData(sectors, regions, true)
        break
      case PAGE_CON.IMPACT_TITLE:
        getImpactData(sectors, regions, true)
        break
      default:
        break
    }
  }

  const getPhysicalRiskManagmentData = useCallback(async () => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    if (filters.portfolio !== '' && !filterState.isLoading) {
      getLeadersLaggardsData(sectors, regions)
      getRegionsData(sectors, regions)
      getUpdatesData(sectors, regions)
      getImpactData(sectors, regions)
    }
  }, [filterState.actionData])

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      getPhysicalRiskManagmentData()
    }
  }, [filterState.actionData])

  useEffect(() => {
    let updateText = `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}, ${PAGE_CON.IMPACT_TITLE}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`

    if (
      !physicalRiskState.portfolioImpact.isLoading &&
      !physicalRiskState.portfolioLeaderLaggard.isLoading
    ) {
      if (canShowImpact())
        updateText = `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}, and ${PAGE_CON.IMPACT_TITLE}`
      else updateText = `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}`

      if (canShowLeadersLaggards()) {
        if (canShowImpact())
          updateText = `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}, ${PAGE_CON.IMPACT_TITLE}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`
        else
          updateText = `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`
      }
    }

    setUpdateSectionText(updateText)
  }, [
    physicalRiskState.portfolioLeaderLaggard.dataStatus,
    physicalRiskState.portfolioImpact.dataStatus
  ])

  function selectedCard(selectCardInfo) {
    setSelectedCardId(selectCardInfo.cardId)
  }

  const physicalRiskManagementMapData =
    physicalRiskState.portfolioRegions.data &&
    physicalRiskState.portfolioRegions.data.mapData &&
    physicalRiskState.portfolioRegions.data.mapData.map((item) => {
      return {
        'hc-key': item[0] || '-',
        value: item[1] !== undefined ? item[1] : '-',
        ScoreCategoryScore: item[1] !== undefined ? item[1] : '-',
        category: item[2] || '-'
      }
    })

  const getPositiveBarData = () => {
    const positiveData = [
      {
        name: 'total',
        data: [],
        opacity: 0.6
      },
      {
        name: 'selection',
        data: [],
        opacity: 1
      }
    ]
    physicalRiskState.portfolioImpact.data[
      physicalRiskState.portfolioImpact.filter
    ].positive_impact.distribution.map((item) => {
      positiveData[0].data.push(item.total - item.selection)
      positiveData[1].data.push(item.selection)
      return ''
    })

    return positiveData
  }

  const getPositiveBarColor = () => {
    const positiveColor = []

    physicalRiskState.portfolioImpact.data[
      physicalRiskState.portfolioImpact.filter
    ].positive_impact.distribution.map((item) => {
      positiveColor.push(getColor('CATEGORY', item.category))
      return ''
    })

    return positiveColor
  }

  const getNegativeBarData = () => {
    const negativeData = [
      {
        name: 'total',
        data: [],
        opacity: 0.6
      },
      {
        name: 'selection',
        data: [],
        opacity: 1
      }
    ]
    physicalRiskState.portfolioImpact.data[
      physicalRiskState.portfolioImpact.filter
    ].negative_impact.distribution.map((item) => {
      negativeData[0].data.push(item.total - item.selection)
      negativeData[1].data.push(item.selection)
      return ''
    })

    return negativeData
  }

  const getNegativeBarColor = () => {
    const negativeColor = []

    physicalRiskState.portfolioImpact.data[
      physicalRiskState.portfolioImpact.filter
    ].negative_impact.distribution.map((item) => {
      negativeColor.push(getColor('CATEGORY', item.category))
      return ''
    })

    return negativeColor
  }

  const canShowImpact = () => {
    let showImpact = false
    if (
      physicalRiskState.portfolioImpact.dataStatus === PAGE_CON.ERROR ||
      (physicalRiskState.portfolioImpact.dataStatus === PAGE_CON.SUCCESS &&
        filterOptions.some(
          (item) =>
            physicalRiskState.portfolioImpact.data[item.key]?.positive_impact
              ?.investment_and_score?.length > 0 ||
            physicalRiskState.portfolioImpact.data[item.key]?.negative_impact
              ?.investment_and_score?.length > 0
        ))
    ) {
      showImpact = true
    }

    return showImpact
  }

  const canShowLeadersLaggards = () => {
    let showLeadersLaggardsComp = false
    if (
      physicalRiskState.portfolioLeaderLaggard.dataStatus === PAGE_CON.ERROR ||
      (physicalRiskState.portfolioLeaderLaggard.dataStatus ===
        PAGE_CON.SUCCESS &&
        (physicalRiskState.portfolioLeaderLaggard.data?.leaders?.length > 0 ||
          physicalRiskState.portfolioLeaderLaggard.data?.laggards?.length > 0))
    ) {
      showLeadersLaggardsComp = true
    }

    return showLeadersLaggardsComp
  }

  const canShowRegionComp = () => {
    let showRegionComp = false
    if (
      physicalRiskState.portfolioRegions.isLoading ||
      physicalRiskState.portfolioRegions.dataStatus === PAGE_CON.ERROR ||
      (physicalRiskState.portfolioRegions.dataStatus === PAGE_CON.SUCCESS &&
        physicalRiskState.portfolioRegions.data?.summary?.length > 0 &&
        physicalRiskState.portfolioRegions.data?.details?.length > 0 &&
        physicalRiskState.portfolioRegions.data?.mapData?.length > 0)
    )
      showRegionComp = true
    return showRegionComp
  }

  return (
    <Page className={classes.root} title={PAGE_CON.PAGE_TITLE}>
      <main className={classes.content}>
        <Container className={classes.container} maxWidth={false}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ marginLeft: '9px', paddingLeft: '0px' }}
            >
              <PhysicalRiskManagementOverView
                pageTitle={props.pageHeaderTitle}
                showBenchMark={showBenchMark}
              />
            </Grid>
            <Grid item container>
              <a
                href={'#' + PAGE_CON.UPDATES_AND_CURRENT_LEADERS_LAGGARDS}
                style={linkPadding}
              >
                <IconArrowDown />
                <span style={linkStyle}>{updateSectionText}</span>
              </a>
            </Grid>
            <Grid item container>
              <AppBar
                id={props.id}
                position="sticky"
                className={classes.stickyHeader}
                style={{ top: '30px' }}
              >
                <div
                  id={PAGE_CON.UPDATES_AND_CURRENT_LEADERS_LAGGARDS}
                  style={{ paddingTop: '80px' }}
                >
                  <div className={classes.tableTitle} style={updatesStyle}>
                    {' '}
                    {updateSectionText}{' '}
                  </div>
                </div>
              </AppBar>
              <Grid item xs={12} style={UpdatesTableStyle}>
                <h6 style={cardHeader}>Updates</h6>
                {physicalRiskState.portfolioUpdates.isLoading ? (
                  <LoadingMask />
                ) : physicalRiskState.portfolioUpdates.dataStatus ===
                    PAGE_CON.ERROR ||
                  (physicalRiskState.portfolioUpdates.dataStatus ===
                    PAGE_CON.SUCCESS &&
                    physicalRiskState.portfolioUpdates.data?.length === 0) ? (
                  <Error
                    id={'updatesError'}
                    errorClass={'noMargin'}
                    dataStatus={
                      isFilterStateAvailable
                        ? physicalRiskState.portfolioUpdates.dataStatus
                        : PAGE_CON.SUCCESS
                    }
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.UPDATESIN_TITLE}
                  />
                ) : (
                  <Updates
                    colInfo={UpdatesTableColInfo}
                    tableLength={10}
                    panelColInfo={PanelUpdatesTableColInfo}
                    totalData={physicalRiskState.portfolioUpdates.data}
                    tableEditable={false}
                    monthYear={monthYear}
                  />
                )}
              </Grid>
              {physicalRiskState.portfolioImpact.isLoading ||
              canShowImpact() ? (
                <Grid
                  item
                  container
                  className={classes.impactContainerWrapper}
                  style={{ paddingLeft: '16px', paddingRight: '16px' }}
                >
                  {physicalRiskState.portfolioImpact.isLoading ? (
                    <LoadingMask />
                  ) : physicalRiskState.portfolioImpact.dataStatus ===
                      PAGE_CON.ERROR ||
                    (physicalRiskState.portfolioImpact.dataStatus ===
                      PAGE_CON.SUCCESS &&
                      physicalRiskState.portfolioImpact.data.length === 0) ? (
                    <Error
                      id={'impactError'}
                      dataStatus={
                        isFilterStateAvailable
                          ? physicalRiskState.portfolioImpact.dataStatus
                          : PAGE_CON.SUCCESS
                      }
                      onErrorClick={reloadComponent}
                      errorOccuredOn={PAGE_CON.IMPACT_TITLE}
                      topMargin="5px"
                    />
                  ) : (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className={classes.impactDropdown}
                      >
                        <span
                          style={{
                            backgroundColor:
                              themeColorPalette.palette.sys_trad.selected2.main,
                            marginBottom: '12px',
                            paddingTop: 4
                          }}
                        >
                          <DropdownField
                            id="impact-filter"
                            dropdownDefaultSelction={
                              physicalRiskState.portfolioImpact.filter
                            }
                            saveValueUsingExternalState={true}
                            dropdownOptions={filterOptions}
                            onChange={onImpactFilterChange}
                            labelTextDisabled={true}
                            labelText=""
                            alternateLabelText="Top 5"
                            defaultData={''}
                            impactColor={
                              themeColorPalette.palette.sys_trad.selected2.main
                            }
                          />
                        </span>
                      </Grid>
                      <Impact
                        positiveImpactData={
                          physicalRiskState.portfolioImpact.data[
                            physicalRiskState.portfolioImpact.filter
                          ].positive_impact.investment_and_score
                        }
                        posBarChartData={getPositiveBarData()}
                        posBarColors={getPositiveBarColor()}
                        posBarTitle={`${
                          filterOptions.find(
                            (item) =>
                              item.key ===
                              physicalRiskState.portfolioImpact.filter
                          ).displayText
                        } vs. Total Investment %`}
                        negativeImpactData={
                          physicalRiskState.portfolioImpact.data[
                            physicalRiskState.portfolioImpact.filter
                          ].negative_impact.investment_and_score
                        }
                        negBarChartData={getNegativeBarData()}
                        negBarColors={getNegativeBarColor()}
                        negBarTitle={`${
                          filterOptions.find(
                            (item) =>
                              item.key ===
                              physicalRiskState.portfolioImpact.filter
                          ).displayText
                        } vs. Total Investment %`}
                        impactColumns={impactColumns}
                        impactFilter={physicalRiskState.portfolioImpact.filter}
                      />
                      <Grid />
                    </>
                  )}
                </Grid>
              ) : null}
              {physicalRiskState.portfolioLeaderLaggard.isLoading ||
              canShowLeadersLaggards() ? (
                <Grid item container className={classes.containerWrapper}>
                  <Grid item xs={12} sm={6} style={{ paddingRight: '12px' }}>
                    <h6 style={cardHeader}>
                      {PAGE_CON.PORTFOLIO_LEADERS_BY_SCORE}
                    </h6>
                    {physicalRiskState.portfolioLeaderLaggard.isLoading ? (
                      <LoadingMask />
                    ) : physicalRiskState.portfolioLeaderLaggard.dataStatus ===
                        PAGE_CON.ERROR ||
                      (physicalRiskState.portfolioLeaderLaggard.dataStatus ===
                        PAGE_CON.SUCCESS &&
                        physicalRiskState.portfolioLeaderLaggard.data.leaders
                          .length === 0) ? (
                      <Error
                        id={'leadersError'}
                        dataStatus={
                          isFilterStateAvailable
                            ? physicalRiskState.portfolioLeaderLaggard
                                .dataStatus
                            : PAGE_CON.SUCCESS
                        }
                        onErrorClick={reloadComponent}
                        errorOccuredOn={PAGE_CON.LEADERS_TITLE}
                        height={'312px'}
                      />
                    ) : (
                      <Leaders
                        colInfo={LeaderLaggardColInfo}
                        totalData={
                          physicalRiskState.portfolioLeaderLaggard.data.leaders
                        }
                        tableLength={tableLength}
                        tableEditable={false}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingLeft: '12px' }}>
                    <h6 style={cardHeader}>
                      {PAGE_CON.PORTFOLIO_LAGGARDS_BY_SCORE}
                    </h6>
                    {physicalRiskState.portfolioLeaderLaggard.isLoading ? (
                      <LoadingMask />
                    ) : physicalRiskState.portfolioLeaderLaggard.dataStatus ===
                        PAGE_CON.ERROR ||
                      (physicalRiskState.portfolioLeaderLaggard.dataStatus ===
                        PAGE_CON.SUCCESS &&
                        physicalRiskState.portfolioLeaderLaggard.data.laggards
                          .length === 0) ? (
                      <div style={{ paddingBottom: 8, paddingRight: 8 }}>
                        <Error
                          id={'laggardsError'}
                          dataStatus={
                            isFilterStateAvailable
                              ? physicalRiskState.portfolioLeaderLaggard
                                  .dataStatus
                              : PAGE_CON.SUCCESS
                          }
                          onErrorClick={reloadComponent}
                          errorOccuredOn={PAGE_CON.LAGGARDS_TITLE}
                          height={'312px'}
                        />
                      </div>
                    ) : (
                      <Laggards
                        colInfo={LeaderLaggardColInfo}
                        totalData={
                          physicalRiskState.portfolioLeaderLaggard.data.laggards
                        }
                        tableLength={tableLength}
                        tableEditable={false}
                      />
                    )}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>

            {canShowRegionComp() ? (
              <>
                <Grid item container>
                  <a
                    href={'#' + PAGE_CON.REGIONS_INDUSTRIES}
                    style={linkPaddingRegionIndustry}
                  >
                    <IconArrowDown />
                    <span style={linkStyle}>
                      {PAGE_CON.REGIONS_TITLE} & {PAGE_CON.SECTORS_TITLE}
                    </span>
                  </a>
                </Grid>

                <Grid item container>
                  <AppBar
                    id={props.id}
                    position="sticky"
                    className={classes.overviewstickyHeader}
                    style={regionTitleStyle}
                  >
                    <div
                      id={PAGE_CON.REGIONS_INDUSTRIES}
                      style={{ paddingTop: '80px' }}
                    >
                      <div
                        className={classes.tableTitle}
                        style={{ marginTop: '36px' }}
                      >
                        {' '}
                        {PAGE_CON.REGIONS_TITLE} & {PAGE_CON.SECTORS_TITLE}{' '}
                      </div>
                    </div>
                  </AppBar>
                  <Grid item xs={12} style={regionStyle}>
                    {physicalRiskState.portfolioESGScore.isLoading &&
                    physicalRiskState.portfolioRegions.isLoading ? (
                      <LoadingMask />
                    ) : physicalRiskState.portfolioRegions.dataStatus ===
                        PAGE_CON.ERROR ||
                      (physicalRiskState.portfolioRegions.dataStatus ===
                        PAGE_CON.SUCCESS &&
                        !isRegionHasData()) ? (
                      <Error
                        id={'regionsError'}
                        dataStatus={
                          isFilterStateAvailable
                            ? physicalRiskState.portfolioRegions.dataStatus
                            : PAGE_CON.SUCCESS
                        }
                        onErrorClick={reloadComponent}
                        errorOccuredOn={PAGE_CON.REGIONS_TITLE}
                      />
                    ) : (
                      <Regions
                        hideMap={true}
                        key={'RegionKey'}
                        data={physicalRiskState.portfolioRegions.data}
                        displayRegions={[]}
                        selectedCardId={selectedCardId}
                        onRegionSelect={selectedCard}
                        mimTabColInfo={mimTabColInfo}
                        panelTabColInfo={panelTabColInfo}
                        cardInfoContentInfo={cardInfoContentInfo}
                        cardInfoContentInfoSector={cardInfoContentInfoSector}
                        currentPageState={'globalPhysicalRisk'}
                        stateData={true}
                        mapData={physicalRiskManagementMapData}
                        mapDataClasses={mapDataClasses}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Container>
      </main>
    </Page>
  )
}

export default PhysicalRiskManagement
