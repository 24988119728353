import Loader from 'react-loader-spinner'
import PropTypes from 'prop-types'
import React from 'react'
import themeColorPalette from '../theme/theme'

function LoadingMask(props) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <span
        style={{
          ...themeColorPalette.typography.h1,
          fontSize: '40px',
          paddingRight: '10px',
          paddingBottom: '10px',
          color: props.textColor
            ? props.textColor
            : themeColorPalette.palette.genrang_a.four.main
        }}
      >
        {props.loadText}{' '}
      </span>
      <Loader
        type="ThreeDots"
        color={
          props.loaderColor
            ? props.loaderColor
            : themeColorPalette.palette.genrang_a.four.main
        }
        height={props.height}
        width={props.width}
      />
    </div>
  )
}

LoadingMask.prototype = {
  /** loading mask id */
  id: PropTypes.string,
  /** loading mask text */
  loadText: PropTypes.string,
  /** loading mask height */
  height: PropTypes.number,
  /** loading mask width */
  width: PropTypes.number,
  /** loading mask id */
  loaderColor: PropTypes.string,
  /** loading mask id */
  textColor: PropTypes.string
}

LoadingMask.defaultProps = {
  height: 100,
  width: 100
}

export default LoadingMask
