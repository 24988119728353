/* eslint-disable import/no-unresolved */

import { AppBar } from '@material-ui/core'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'
import {
  Card,
  IconClose,
  IconLeftArrow,
  MiniCardInfoSection,
  PrimaryLink
} from 'components'
import Grid from '@material-ui/core/Grid'
import React, { useContext } from 'react'
import * as S from './styles'

function PortfolioList(props) {
  const classes = S.useStyles(props)
  const context = useContext(GlobalContext)
  const [filterState, filterDispatch] = context.globalFilter

  const portfoliolist = filterState.filterData.portfolioList.map(
    (portfolio) => {
      const selected =
        portfolio.key === filterState.globalPortfolioFilter.portfolio ? 1 : 0
      return { ...portfolio, isSelected: selected, portfolioDate: '' }
    }
  )

  const [selectedCardId, setSelectedCardId] = React.useState(
    filterState.globalPortfolioFilter.portfolio
  )

  const portfolioCardSelect = (id) => {
    setSelectedCardId(id)
    if (props.onPortfolioClick) {
      const portfolio = filterState.filterData.portfolioList.filter(
        (portfolio) => portfolio.key === id
      )
      if (portfolio?.length > 0) {
        const selPortfolio = portfolio[0]
        props.onPortfolioClick({
          id: selPortfolio.key,
          name: selPortfolio[PAGE_CON.DROPDOWN_DISPLAY_COLUMN]
        })
      }
    }
  }

  const onUpload = (uploadType) => {
    props.handlePortfolioClose()
    if (props.onUploadClick) props.onUploadClick()
    filterDispatch({
      type: REDUCER_TYPE.UPLOAD_PORTFOLIO_MODAL,
      payload: true,
      uploadType,
      uploadInitiatedFrom: PAGE_CON.PORTFOLIO_MANAGEMENT
    })
  }

  const contentHeaderInfo = [
    {
      labelType: 'Text',
      className: 'portfolioTextStyle',
      dataField: 'portfolioLabel'
    },
    {
      labelType: 'Text',
      className: 'uploadTextStyle',
      dataField: 'dateLabel'
    }
  ]

  const cardInfoHeaderData = {
    portfolioLabel: 'Portfolio Name',
    dateLabel: 'Upload Date'
  }

  const contentInfo = [
    {
      labelType: 'Text',
      className: 'portfolioList',
      dataField: 'portfolioName',
      showToolTip: true
    },
    {
      labelType: 'Date',
      dataField: PAGE_CON.UPLOAD_DATE_UTC
    }
  ]

  return (
    <Grid container direction="column" spacing={0} id={props.id} key={props.id}>
      <Grid item className={classes.parentContainer}>
        <AppBar id={props.id} position="sticky" className={classes.pageHeader}>
          <Grid container className={classes.headerContainer}>
            <Grid item xs="auto">
              <span style={{ margin: '0px', float: 'left' }}>
                <IconLeftArrow
                  style={{ cursor: 'pointer', marginTop: 2 }}
                  onClick={() => props.onMovePrevClick()}
                />
              </span>
            </Grid>
            <Grid item xs={10}>
              <Grid container direction="column">
                <Grid item className={classes.headerText}>
                  Portfolio Management
                </Grid>
                <Grid item className={classes.uploadStyle}>
                  <PrimaryLink
                    id="link-upload"
                    key="link-upload"
                    linkSelected
                    linkText="Upload New"
                    linkClass="portfolioUploadLink"
                    onClick={() => onUpload(PAGE_CON.FILTER_PORTFOLIO)}
                    linkMargin="0px"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <span
                style={{ margin: '0px', marginRight: '-18px', float: 'right' }}
              >
                <IconClose
                  style={{ cursor: 'pointer', marginTop: 2 }}
                  onClick={() => props.handlePortfolioClose()}
                />
              </span>
            </Grid>
            <Grid item xs={12} className={classes.border} />
          </Grid>
        </AppBar>
        <Grid container direction="column">
          <Grid item className={classes.gridHeaderItem}>
            <MiniCardInfoSection
              id={`mini-${0}`}
              key={`mini-${0}`}
              heap_id="portfolio-selection"
              contentInfo={contentHeaderInfo}
              data={cardInfoHeaderData}
              containerClass="portfolioHeader"
            />
          </Grid>
          {portfoliolist.map((portfolio, index) => {
            const cardInfoData = {
              portfolioName: portfolio[PAGE_CON.DROPDOWN_DISPLAY_COLUMN],
              [PAGE_CON.UPLOAD_DATE_UTC]: portfolio[PAGE_CON.UPLOAD_DATE_UTC]
            }
            const comp = [
              <MiniCardInfoSection
                id={`mini-${index}`}
                key={`mini-${index}`}
                heap_id="portfolio-selection"
                heap_portfolio_title={`${cardInfoData.portfolioName}_${cardInfoData.upload_date_utc}`}
                contentInfo={contentInfo}
                data={cardInfoData}
              />
            ]

            return (
              <Grid item key={`gridPort-${index}`} className={classes.gridItem}>
                <Card
                  cardClass="miniCard"
                  maxWidth="auto"
                  components={comp}
                  id={portfolio.key}
                  selectedId={selectedCardId}
                  onCardSelect={portfolioCardSelect}
                  selectionType="single"
                  key={`portfoliocard-test-id-${index}`}
                  isCardClickable
                />
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PortfolioList
