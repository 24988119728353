import { FEATURE_FLAGS, PAGE_CON } from './constant/constants'
const environmentName = process.env.REACT_APP_ENV

export default function getEntitlements(
  authState,
  apiEntitlements,
  contentTypeEntitlements
) {
  const isContentTypeClimate =
    contentTypeEntitlements &&
    contentTypeEntitlements.includes(PAGE_CON.CONTENT_TYPE_CLIMATE)
  const groupsExist =
    authState.accessToken &&
    authState.accessToken.claims &&
    authState.accessToken.claims.groups
  const groups = groupsExist
    ? authState.accessToken.claims.groups.filter((group) => {
        return group.includes(environmentName)
      })
    : []
  const platformEntitled =
    groups.filter((group) => {
      return group === `mesg-platform-investor-${environmentName}`
    }).length > 0
  const issuerEntitled =
    groups.filter((group) => {
      return group === `mesg-platform-issuer-${environmentName}`
    }).length > 0
  const isPhysicalRisk =
    apiEntitlements && apiEntitlements.includes(PAGE_CON.MESG_PHY_RSK_ID)
  const isTransitionRisk =
    apiEntitlements && apiEntitlements.includes(PAGE_CON.MESG_TRN_RSK_ID)
  const isESG =
    !FEATURE_FLAGS.HIDE_ESG &&
    apiEntitlements &&
    apiEntitlements.includes(PAGE_CON.MESG_ESG_ID)
  const isPredictedScore =
    !FEATURE_FLAGS.HIDE_ESG &&
    apiEntitlements &&
    apiEntitlements.includes(PAGE_CON.MESG_ESG_PREDICTOR_SCORE_ID)
  const isOnDemandESG =
    FEATURE_FLAGS.SME_ON_DEMAND &&
    apiEntitlements &&
    apiEntitlements.includes(PAGE_CON.MESG_ESG_ID)
  const isOnDemandPredictedScore =
    FEATURE_FLAGS.SME_ON_DEMAND &&
    apiEntitlements &&
    apiEntitlements.includes(PAGE_CON.MESG_ESG_PREDICTOR_SCORE_ID)
  const isESGORPredictedScore = isESG || isPredictedScore
  const isControversyEnabled =
    apiEntitlements && apiEntitlements.includes(PAGE_CON.MESG_CONTROVERSIES_ID)
  const issuerP3Entitled =
    apiEntitlements && apiEntitlements.includes('issuerp3')
  const exportEntitled =
    apiEntitlements && apiEntitlements.includes(PAGE_CON.MESG_EXPORT)
  const isSFDREntitled =
    apiEntitlements && apiEntitlements.includes(PAGE_CON.MESG_SFDR_ID)
  const isEuTaxonomyEntitled =
    apiEntitlements && apiEntitlements.includes(PAGE_CON.MESG_EUTAXONOMY_ID)
  const isOnDemandAssessmentEntitled =
    !FEATURE_FLAGS.HIDE_ESG &&
    FEATURE_FLAGS.SME_ON_DEMAND &&
    apiEntitlements &&
    apiEntitlements.includes(PAGE_CON.MESG_ON_DEMAND_ASMT_ID)
  const isOnDemandReporting =
    FEATURE_FLAGS.SME_ON_DEMAND &&
    apiEntitlements &&
    apiEntitlements.includes(PAGE_CON.MESG_ON_DEMAND_ASMT_ID)
  const reportingServiceEntitled =
    isSFDREntitled ||
    isEuTaxonomyEntitled ||
    (isOnDemandReporting && isOnDemandPredictedScore && isOnDemandESG)
  const isReportingOnlyEnabled =
    contentTypeEntitlements && !isContentTypeClimate && reportingServiceEntitled
  // const isClimateGovernance = apiEntitlements && apiEntitlements.includes(PAGE_CON.MESG_CLM_GVN_STR_ID);
  const hasValidEntitlement =
    (platformEntitled && (isContentTypeClimate || reportingServiceEntitled)) ||
    issuerEntitled
  return {
    issuer: issuerEntitled, //for entity light work set this to true,
    issuerp3: issuerP3Entitled,
    dashboard: isContentTypeClimate && platformEntitled,
    portfolioanalysis:
      isContentTypeClimate && (isESG || isPhysicalRisk || isTransitionRisk), //platformEntitled, //ESGCA-10974, PA option must be disabled for ESG only users
    portfoliosettings: platformEntitled,
    reportingservice: reportingServiceEntitled,
    ondemandassessment: isOnDemandAssessmentEntitled,
    scenarios: false,
    export: exportEntitled,
    climate: isContentTypeClimate,
    // ondemandscores: false,
    entityprofile: issuerEntitled, //for entity light work set this to true
    sector: issuerEntitled, //for entity light work set this to true
    screening: false,
    validEntitlement: hasValidEntitlement,
    Dashboard: {
      physicalrisk: isPhysicalRisk,
      transitionrisk: isTransitionRisk,
      controversy: isControversyEnabled
      // esg: isESGORPredictedScore
    },
    PortfolioAnalysis: {
      physicalrisk: isPhysicalRisk, //platformEntitled,
      physicalriskChildren: {
        operationsrisk: isPhysicalRisk, //isPhysicalRisk,
        marketrisk: isPhysicalRisk, //PhysicalRisk,
        supplychainrisk: isPhysicalRisk, //isPhysicalRisk,
        physicalriskmanagement: isPhysicalRisk,
        physicalriskhazards: isPhysicalRisk
      },
      transitionrisk: isTransitionRisk, //platformEntitled,
      transitionriskChildren: {
        temperaturealignment: isTransitionRisk, //isTransitionRisk && !PAGE_CON.PRODUCTION_ENABLED,
        carbonfootprint: isTransitionRisk,
        greenshareassessment: isTransitionRisk,
        brownshareassessment: isTransitionRisk,
        energytransitionmanagement: false //isTransitionRisk,
      },
      climategovernancestrategy: false, //isClimateGovernance, //platformEntitled || platformInternalEntitled,
      climategovernancestrategyChildren: {
        tcfdstrategy: false, //isClimateGovernance,
        eutaxonomy: false,
        //greenshareassessment: isClimateGovernance,
        climatecontroversies: false
      },
      economicquantification: false,
      economicquantificationChildren: {
        physicalclimatestressedpds: false,
        transitionclimateriskstressedpds: false
      },
      esg: isESGORPredictedScore,
      esgChildren: {
        esgassessments: isESGORPredictedScore
      },
      predicted: isPredictedScore
    },
    ReportingService: {
      sfdr: isSFDREntitled,
      eutaxonomy: isEuTaxonomyEntitled,
      ondemandassessment:
        isOnDemandReporting && isOnDemandPredictedScore && isOnDemandESG,
      isReportingOnlyEnabled
    }
  }
}
