import React, { useContext } from 'react'
import { GlobalContext } from 'context/GlobalStateProvider'
import { Grid } from '@material-ui/core'
import { onUpload } from '../ReportingServiceHelper'
import { PAGE_CON } from 'constant/constants'
import { PrimaryLink } from 'components'
import { useStyles } from './styles'
import clsx from 'clsx'
import themeColorPalette from 'theme/theme'

const EmptyODAPortfolios = () => {
  const classes = useStyles()
  const [, filterDispatch] = useContext(GlobalContext).globalFilter

  return (
    <Grid item className={clsx(classes.odaEmptyList, classes.emptyList)}>
      <div>
        <p className={classes.noPortfolioText}>No portfolio available.</p>
        <span className={classes.noPortfolioSubText}>
          <PrimaryLink
            id="link-upload"
            key="link-upload"
            linkSelected={true}
            linkText="Upload a portfolio"
            linkClass="portfolioUploadLink"
            linkBgColor={themeColorPalette?.palette?.sys_trad?.bg.main}
            onClick={() => onUpload(PAGE_CON.FILTER_PORTFOLIO, filterDispatch)}
          />{' '}
          in order to generate a report or request for an assessment
        </span>
      </div>
    </Grid>
  )
}
export default EmptyODAPortfolios
