import { GlobalContext } from 'context/GlobalStateProvider'
import { COMMON, PARAMS } from 'constant/APIconstants'
import { ResearchLineService } from 'services'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import {
  Card,
  CardInfoSection,
  Legend,
  MinimalTable,
  PrimaryLink,
  StackedVert
} from 'components'
import Error from 'utils/error'
import Grid from '@material-ui/core/Grid'
import LoadingMask from 'utils/loadingMask'
import { makeStyles } from '@material-ui/core/styles'
import OverViewText from '../PortfolioAnalysisOverViewText.json'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import PhysicalRiskColumns from './PhysicalRiskColumns.json'
import getColor from 'utils/color'
import { sortPortfolioHistoryChart } from 'utils/sortPortfolioHistoryChart'

const portfolioDistributionColumns = JSON.parse(
  JSON.stringify(
    PhysicalRiskColumns.physicalRisk_Portfolio_Distribution_Columns
  )
)

const portfolioDistributionColumns_benchmark = JSON.parse(
  JSON.stringify(
    PhysicalRiskColumns.physicalRisk_Portfolio_Distribution_Columns_benchmark
  )
)

const cardInfoContentInfo = JSON.parse(
  JSON.stringify(PhysicalRiskColumns.physicalrisk_ESG_score_new)
)

const categoryInfo = JSON.parse(
  JSON.stringify(PhysicalRiskColumns.categoryInfo)
)

const outerColor = themeColorPalette.palette.sys_trad.bg.main
const scoreHeader = themeColorPalette.palette.sys_trad.white.main

const overViewText = {
  ...textTheme.typography.p3,
  color: themeColorPalette.palette.sys_trad.text.main,
  marginTop: '12px',
  marginBottom: '12px'
}

const overViewTextSubtitleBenchmark = {
  ...textTheme.typography.p2,
  color: themeColorPalette.palette.sys_trad.text.main,
  textAlign: 'left',
  paddingLeft: '7px'
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    background: '#ffff',
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.14)',
    borderRadius: '2px'
  },
  verticalHeader: {
    ...textTheme.typography.h2,
    fontSize: '16px !important',
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    textAlign: 'right',
    paddingBottom: '16px',
    marginRight: '-12px'
  },
  paper: {
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  benchmarkGrid: {
    paddingBottom: 6,
    marginLeft: -8
  },
  grid: {
    textAlign: 'left',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  portfolioCoverageHeaders: {
    ...textTheme.typography.p4,
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.text2.main,
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    padding: '4px',
    marginBottom: '4px',
    minWidth: (props) => props.coverageHeaderWidth || '100%',
    width: (props) => props.coverageHeaderWidth || '100%'
  },
  portfolioCoverageHeadersRight: {
    ...textTheme.typography.p4,
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.text2.main,
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    marginLeft: '4px',
    marginBottom: '4px',
    padding: '4px',
    minWidth: (props) => props.coverageHeaderWidth || '100%',
    width: (props) => props.coverageHeaderWidth || '100%'
  },
  portfolioCoverageHeadersSmall: {
    ...textTheme.typography.p4,
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.text2.main,
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    marginBottom: '4px',
    padding: '4px',
    minWidth: '49%',
    width: '49%'
  },
  portfolioTable: {
    paddingRight: '24px',
    marginBottom: '22px',
    paddingBottom: '0px',
    width: '100%',
    minWidth: '290px'
  },
  benchmarkTable: {
    paddingRight: '24px',
    paddingBottom: '16px',
    width: '100%',
    minWidth: '290px'
  },
  coverageBenchmarkGrid: {
    paddingLeft: '8px',
    marginLeft: 0
  },
  fourBoxContainerStyle: {
    paddingLeft: '36px',
    paddingRight: '16px',
    paddingTop: '16px',
    paddingBottom: '0px'
  },

  cardInfoFiller: {
    minHeight: '148px',
    borderRadius: '2px',
    padding: '0px',
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main
  },
  chartBoxFiller: {
    minHeight: '182px',
    maxWidth: '282px',
    background: themeColorPalette.palette.sys_trad.bg.main
  },
  historyBoxFiller: {
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.sys_trad.text.main,
    minHeight: '190px',
    maxWidth: '282px',
    textAlign: 'left',
    background: themeColorPalette.palette.sys_trad.bg.main
  },
  historyLabel: {
    padding: '4px 4px 8px',
    boxDecorationBreak: 'clone'
  },

  distributionBoxFiller: {
    minHeight: '129px',
    background: themeColorPalette.palette.sys_trad.bg.main
  },

  benchmarkBoxStyle: {
    minHeight: '32px',
    maxWidth: '1148px',
    marginTop: '24px',
    marginBottom: '24px',
    padding: '0px',
    display: 'table',
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main
  },

  benchmarkTextStyle: {
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.sys_trad.texthelp.main,
    verticalAlign: 'middle',
    textAlign: 'center',
    display: 'table-cell'
  },
  infoBoxStyle: {
    marginLeft: '30px',
    padding: '0px',
    marginBottom: '16px'
  }
}))

const PhysicalRiskOverView = (props) => {
  const classes = useStyles(props)
  const [filterState] = useContext(GlobalContext).globalFilter
  const pageState = props.pageState
  const pageDispatch = props.pageDispatch
  let apiCallId = props.apiCallId
  const pageConstant = props.pageConstant
  const baseUrl = props.baseUrl

  const [state, setState] = useState({
    smallResize: false
  })

  const { smallResize } = state

  const getHistoryData = async (sectors, regions, isErrorReload = false) => {
    if (pageState.portfolioHistory.dataStatus !== '' && isErrorReload === false)
      return

    pageDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_HISTORY
    })
    let history = {}

    try {
      const { data } = await axios.post(
        COMMON.BASE_URL +
          filterState.globalPortfolioFilter.portfolio +
          `${baseUrl}` +
          COMMON.ENTITY_SCORE_HISTORY,
        {
          [PARAMS.REGION]: regions,
          [PARAMS.SECTOR]: sectors,
          [PARAMS.MONTH]: filterState.globalPortfolioFilter.asOfDate.slice(4),
          [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(0, 4),
          [PARAMS.BENCHMARK_ID]: filterState.globalPortfolioFilter.benchmark
        },
        { cancelToken: pageState.cancelToken.token }
      )

      history = data[0]

      pageDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_HISTORY,
        data: history,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        pageDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_HISTORY,
          apiCallId
        })
      }
    }
  }

  const getESGScoreData = async (sectors, regions, isErrorReload = false) => {
    if (
      (pageState.portfolioESGScore.data.score.length > 0 &&
        pageState.portfolioESGScore.data.coverage.length > 0 &&
        pageState.portfolioESGScore.data.distribution.length > 0) ||
      (pageState.portfolioESGScore.dataStatus !== '' && isErrorReload === false)
    )
      return

    pageDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_ESGSCORE
    })
    let ESGScore = { score: [], distribution: [], coverage: {} }
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter

    try {
      const [score, distribution, coverage] = await axios.all([
        ResearchLineService.retrieveResearchLineApi({
          portfolio,
          regions,
          sectors,
          asOfDate,
          benchmark,
          checkForEntitlements: false,
          isEntitled: false,
          researchLine: pageConstant,
          token: pageState.cancelToken.token,
          endPoint: COMMON.SCORE
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio,
          regions,
          sectors,
          asOfDate,
          benchmark,
          checkForEntitlements: false,
          isEntitled: false,
          researchLine: pageConstant,
          token: pageState.cancelToken.token,
          endPoint: COMMON.DISTRIBUTION
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio,
          regions,
          sectors,
          asOfDate,
          benchmark,
          checkForEntitlements: false,
          isEntitled: false,
          researchLine: pageConstant,
          token: pageState.cancelToken.token,
          endPoint: COMMON.COVERAGE
        })
      ])

      ESGScore.score = Object.keys(score.data).length > 0 ? score.data[0] : []
      ESGScore.distribution = distribution.data
      ESGScore.coverage = coverage.data.length > 0 ? coverage.data[0] : []

      pageDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_ESGSCORE,
        data: ESGScore,
        apiCallId
      })

      pageDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_SCORE,
        data: ESGScore.score,
        apiCallId
      })
      pageDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_DISTRIBUTION,
        data: ESGScore.distribution,
        apiCallId
      })
      pageDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_COVERAGE,
        data: ESGScore.coverage,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        pageDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_ESGSCORE,
          apiCallId
        })
      } else console.log(err)
    }
  }

  const reloadComponent = (errorOccuredOn) => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions

    switch (errorOccuredOn) {
      case PAGE_CON.PORTFOLIO_ESGSCORE_TITLE:
      case PAGE_CON.BENCHMARK_ESG_TITLE:
        getESGScoreData(sectors, regions, true)
        break
      case PAGE_CON.PORTFOLIO_HISTORY_TITLE:
        getHistoryData(sectors, regions, true)
        break
      default:
        break
    }
  }

  const getOperationRiskData = useCallback(async () => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    if (filters.portfolio !== '' && !filterState.isLoading) {
      getESGScoreData(sectors, regions)
      getHistoryData(sectors, regions)
    }
  }, [filterState.actionData])

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      getOperationRiskData()
    }
    const setResponsiveness = () => {
      return window.innerWidth < 1050
        ? setState((prevState) => ({ ...prevState, smallResize: true }))
        : setState((prevState) => ({ ...prevState, smallResize: false }))
    }
    setResponsiveness()

    window.addEventListener('resize', setResponsiveness, true)
    return () => window.removeEventListener('resize', setResponsiveness, true)
  }, [filterState.actionData])

  const openAboutLinkOpen = () => {
    let aboutPDF =
      process.env.PUBLIC_URL +
      '/assets/Moody\'s ESG360 - 427 Methodology - Physical Climate Risk - March 2021.pdf'
    window.open(aboutPDF, '_blank')
  }

  const colorLabel = () => {
    let legendData = []
    pageState.portfolioESGScore.dataStatus === PAGE_CON.SUCCESS &&
      pageState.portfolioESGScore &&
      pageState.portfolioESGScore.data &&
      pageState.portfolioESGScore.data.distribution &&
      pageState.portfolioESGScore.data.distribution.map((Cdata) => {
        return legendData.push(Cdata.score_range)
      })

    return legendData
  }

  let colorGroup =
    portfolioDistributionColumns[0].DataField[0].categoryColor.colorGroup
  let legendColor = colorLabel()
  //eslint-disable-next-line no-unused-vars
  const scoreCardESGData = (ranking, score, coverage, id) => {
    let tmp = {}

    tmp = {
      ranking: ranking,
      score: score && score.split('/')[0]
    }

    coverage.investment =
      Number(coverage.investment) >= 1
        ? `${coverage.investment}`
        : Number(coverage.investment) === 0
        ? `${coverage.investment}`
        : '<1'

    let comp = [
      <CardInfoSection
        key={'phy-risk-mgm-test-id'}
        id={'phy-risk-mgm-test-id-from-overview-'}
        contentInfo={categoryInfo}
        data={tmp}
        singleData={true}
        scoreCategory={true}
        outerBackgroundColor={outerColor}
      />,
      <CardInfoSection
        key={'phy-risk-mgm-test-id'}
        id={'phy-risk-mgm-test-id-from-overview-'}
        contentInfo={cardInfoContentInfo}
        data={coverage}
        scoreTable={true}
        minWidthCoverage={255}
        outerBackgroundColor={outerColor}
      />
    ]
    return comp
  }

  const isPortfolioHistoryHasData = () => {
    let hasData = false

    if (
      pageState.portfolioHistory.data?.portfolio?.categorydata?.length > 0 &&
      pageState.portfolioHistory.data?.portfolio?.yearlydata?.length > 0
    )
      hasData = true
    return hasData
  }

  const isBenchmarkHistoryHasData = () => {
    let hasData = false

    if (
      pageState.portfolioHistory.data?.benchmark?.categorydata?.length > 0 &&
      pageState.portfolioHistory.data?.benchmark?.yearlydata?.length > 0
    )
      hasData = true
    return hasData
  }

  const historyData = React.useMemo(() => {
    let colors = []
    let benchmarkColors = []

    pageState.portfolioHistory.data?.portfolio?.categorydata?.map((item) => {
      if (item.name === 'N/A')
        colors.push(themeColorPalette.palette.sys_trad.border.main)
      else colors.push(getColor('risk', item.name))
      return colors
    })

    pageState.portfolioHistory.data?.benchmark?.categorydata?.map((item) => {
      if (item.name === 'N/A')
        benchmarkColors.push(themeColorPalette.palette.sys_trad.border.main)
      else benchmarkColors.push(getColor('risk', item.name))
      return benchmarkColors
    })

    const newStackedBarData =
      pageState?.portfolioHistory?.data?.portfolio?.categorydata?.length > 0 &&
      sortPortfolioHistoryChart(
        pageState.portfolioHistory.data.portfolio.categorydata
      )

    const newBenchmarkStackedBarData =
      pageState?.portfolioHistory?.data?.benchmark?.categorydata?.length > 0 &&
      sortPortfolioHistoryChart(
        pageState.portfolioHistory.data.benchmark.categorydata
      )

    return {
      StackedBarData: newStackedBarData || [],
      YearsData: pageState.portfolioHistory.data?.portfolio?.yearlydata || [],
      BarColors: colors,
      BenchmarkStackedBarData: newBenchmarkStackedBarData || [],
      BenchmarkYearsData:
        pageState.portfolioHistory.data?.benchmark?.yearlydata || [],
      BenchmarkBarColors: benchmarkColors
    }
  }, [pageState.portfolioHistory.dataStatus])

  return (
    <div className={classes.root}>
      <Grid
        id={props.id}
        container
        direction={'column'}
        spacing={2}
        className={classes.paper}
        style={{
          paddingLeft: '20px',
          paddingRight: '16px',
          paddingTop: '16px',
          paddingBottom: '0px'
        }}
      >
        <Grid
          id="portfolio_4_box"
          container
          item
          direction={'row'}
          spacing={3}
          style={{
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '4px',
            paddingBottom: '0px'
          }}
        >
          <span className={classes.verticalHeader}> Portfolio </span>
          <Grid
            item
            id="cardInfo_box"
            sm={1}
            md={3}
            style={{ minWidth: '246px' }}
          >
            {pageState.portfolioESGScore.isLoading ? (
              <LoadingMask />
            ) : pageState.portfolioESGScore.dataStatus === PAGE_CON.ERROR ||
              (pageState.portfolioESGScore.dataStatus === PAGE_CON.SUCCESS &&
                pageState.portfolioESGScore.data &&
                pageState.portfolioESGScore.data.score &&
                pageState.portfolioESGScore.data.score.portfolio_score &&
                pageState.portfolioESGScore.data.score.portfolio_score
                  .length === 0) ||
              (pageState.portfolioESGScore.dataStatus === PAGE_CON.SUCCESS &&
                pageState.portfolioESGScore.data.coverage &&
                pageState.portfolioESGScore.data.coverage.length === 0) ? (
              <Error
                id={'distributionError'}
                dataStatus={pageState.portfolioESGScore.dataStatus}
                onErrorClick={reloadComponent}
                errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
              />
            ) : (
              <Card
                components={scoreCardESGData(
                  pageState.portfolioESGScore.data.score.ranking,
                  pageState.portfolioESGScore.data.score.portfolio_score,
                  pageState.portfolioESGScore.data.coverage.portfolio_coverage,
                  'PortfolioScoreESG-test-id'
                )}
                id="PortfolioScoreESG-test-id"
                selectionType="none"
                key="PortfolioScoreESG-test-id"
              ></Card>
            )}
          </Grid>
          {historyData.YearsData.length > 1 ? (
            <Grid
              item
              id="history_box_portfolio"
              sm={3}
              md={3}
              style={{
                minWidth: '282px',
                marginTop: '4px',
                marginRight: '-18px',
                paddingLeft: '2px'
              }}
            >
              <div className={classes.historyBoxFiller}>
                <span className={classes.historyLabel}>
                  {PAGE_CON.PORTFOLIO_HISTORY_TABLE_TITLE}
                </span>
                {pageState.portfolioHistory.isLoading ? (
                  <LoadingMask />
                ) : pageState.portfolioHistory.dataStatus === PAGE_CON.ERROR ||
                  (pageState.portfolioHistory.dataStatus === PAGE_CON.SUCCESS &&
                    !isPortfolioHistoryHasData()) ? (
                  <Error
                    id={'historyError'}
                    dataStatus={pageState.portfolioHistory.dataStatus}
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.PORTFOLIO_HISTORY_TITLE}
                  />
                ) : (
                  <div>
                    <StackedVert
                      data={historyData.StackedBarData}
                      xAxisTitles={historyData.YearsData}
                      barColors={historyData.BarColors}
                      chartWidth={270}
                      tooltipEnabled={true}
                    />
                  </div>
                )}
              </div>
            </Grid>
          ) : null}
          <Grid
            item
            id="distribution_box"
            sm={3}
            md={3}
            style={{ minWidth: '266px', marginTop: '4px', marginLeft: '8px' }}
          >
            <div style={overViewTextSubtitleBenchmark}>
              {' '}
              {filterState.globalPortfolioFilter.asOfDate.substring(0, 4)}{' '}
              {PAGE_CON.PORTFOLIO_DISTRIBUTION_TITLE}
            </div>
            {pageState.portfolioESGScore.isLoading ? (
              <LoadingMask />
            ) : pageState.portfolioESGScore.dataStatus === PAGE_CON.ERROR ||
              (pageState.portfolioESGScore.dataStatus === PAGE_CON.SUCCESS &&
                pageState.portfolioESGScore.data.distribution &&
                pageState.portfolioESGScore.data.distribution.length === 0) ? (
              <Error
                id={'distributionError'}
                dataStatus={pageState.portfolioESGScore.dataStatus}
                onErrorClick={reloadComponent}
                errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
              />
            ) : (
              <MinimalTable
                id="physical-risk-management-overview-portfolio-distribution-minimaltable-test-id"
                columns={portfolioDistributionColumns}
                headerBgColor={scoreHeader}
                data={pageState.portfolioESGScore.data.distribution}
                hideBenchmark={true}
                ESCScore={true}
              ></MinimalTable>
            )}
          </Grid>

          {historyData.YearsData.length == 1 && !smallResize ? (
            <Grid
              item
              id="legend_summary_link_box"
              sm={smallResize ? 7 : 5}
              className={classes.infoBoxStyle}
              style={{
                padding: '0px',
                marginTop: '20px',
                paddingLeft: smallResize ? '12px' : '0'
              }}
            >
              <div id="legend_box" style={{ textAlign: 'left' }}>
                {pageState.portfolioESGScore.isLoading ? (
                  <LoadingMask />
                ) : pageState.portfolioESGScore.dataStatus === PAGE_CON.ERROR ||
                  (pageState.portfolioESGScore.dataStatus ===
                    PAGE_CON.SUCCESS &&
                    pageState.portfolioESGScore.data.distribution &&
                    pageState.portfolioESGScore.data.distribution.length ===
                      0) ? (
                  <Error
                    id={'distributionError'}
                    dataStatus={pageState.portfolioESGScore.dataStatus}
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
                  />
                ) : (
                  <Legend
                    colorOptions={legendColor}
                    colorGroup={colorGroup}
                    legendTitle={`${props.pageTitle} Score:`}
                    boldTitle={true}
                  />
                )}
              </div>

              {props.overViewText === 'physicalRiskHazards' && (
                <div
                  id="summary_box"
                  className={classes.grid}
                  style={overViewText}
                >
                  {OverViewText.OverViewText[props.overViewText]}
                  <br></br>
                  <br></br>
                  {OverViewText.OverViewText.additionalNote}
                </div>
              )}
              {props.overViewText !== 'physicalRiskHazards' && (
                <div
                  id="summary_box"
                  className={classes.grid}
                  style={overViewText}
                >
                  {OverViewText.OverViewText[props.overViewText]}
                </div>
              )}

              <div id="link_box" style={{ textAlign: 'left' }}>
                <PrimaryLink
                  id="link-link-test-id-3"
                  linkText={`Read more about ${props.pageTitle} assessment methodology`}
                  onClick={openAboutLinkOpen}
                  linkClass="linkInline"
                  linkColorChange={true}
                />
              </div>
            </Grid>
          ) : null}
        </Grid>
        {props.showBenchMark ? (
          <Grid
            id="benchmark_4_box"
            container
            item
            direction={'row'}
            spacing={3}
            style={{
              paddingLeft: '16px',
              paddingRight: '16px',
              paddingTop: '0px',
              paddingBottom: '0px',
              marginTop: '12px'
            }}
          >
            <span className={classes.verticalHeader}> Benchmark </span>
            <Grid
              item
              id="cardInfo_box"
              sm={1}
              md={3}
              style={{ minWidth: '246px' }}
            >
              {pageState.portfolioESGScore.isLoading ? (
                <LoadingMask />
              ) : pageState.portfolioESGScore.dataStatus === PAGE_CON.ERROR ||
                (pageState.portfolioESGScore.dataStatus === PAGE_CON.SUCCESS &&
                  pageState.portfolioESGScore.data &&
                  pageState.portfolioESGScore.data.score &&
                  pageState.portfolioESGScore.data.score.benchmark_score &&
                  pageState.portfolioESGScore.data.score.benchmark_score
                    .length === 0) ||
                (pageState.portfolioESGScore.dataStatus === PAGE_CON.SUCCESS &&
                  pageState.portfolioESGScore.data.coverage &&
                  pageState.portfolioESGScore.data.coverage.length === 0) ? (
                <Error
                  id={'distributionError'}
                  dataStatus={pageState.portfolioESGScore.dataStatus}
                  onErrorClick={reloadComponent}
                  errorOccuredOn={PAGE_CON.BENCHMARK_ESG_TITLE}
                />
              ) : (
                <Card
                  components={scoreCardESGData(
                    pageState.portfolioESGScore.data.score.benchmark_ranking,
                    pageState.portfolioESGScore.data.score.benchmark_score,
                    pageState.portfolioESGScore.data.coverage
                      .benchmark_coverage,
                    'BenchmarkScoreESG-test-id'
                  )}
                  id="BenchmarkScoreESG-test-id"
                  selectionType="none"
                  key="BenchmarkScoreESG-test-id"
                ></Card>
              )}
            </Grid>
            {historyData.YearsData.length > 1 &&
            historyData.BenchmarkYearsData.length > 1 ? (
              <Grid
                item
                id="history_box_benchmark"
                sm={3}
                md={3}
                style={{
                  minWidth: '282px',
                  marginRight: '-18px',
                  marginTop: '4px',
                  paddingLeft: '2px'
                }}
              >
                <div className={classes.historyBoxFiller}>
                  <span className={classes.historyLabel}>
                    {PAGE_CON.BENCHMARK_HISTORY_TABLE_TITLE}
                  </span>
                  {pageState.portfolioHistory.isLoading ? (
                    <LoadingMask />
                  ) : pageState.portfolioHistory.dataStatus ===
                      PAGE_CON.ERROR ||
                    (pageState.portfolioHistory.dataStatus ===
                      PAGE_CON.SUCCESS &&
                      !isBenchmarkHistoryHasData()) ? (
                    <Error
                      id={'benchmarkHistoryError'}
                      dataStatus={pageState.portfolioHistory.dataStatus}
                      onErrorClick={reloadComponent}
                      errorOccuredOn={PAGE_CON.PORTFOLIO_HISTORY_TITLE}
                    />
                  ) : (
                    <div>
                      <StackedVert
                        data={historyData.BenchmarkStackedBarData}
                        xAxisTitles={historyData.BenchmarkYearsData}
                        barColors={historyData.BenchmarkBarColors}
                        chartWidth={270}
                        tooltipEnabled={true}
                      />
                    </div>
                  )}
                </div>
              </Grid>
            ) : null}
            <Grid
              item
              id="distribution_box"
              sm={3}
              md={3}
              style={{
                minWidth: '266px',
                marginBottom: '16px',
                marginTop: '4px',
                marginLeft: '8px'
              }}
            >
              <div style={overViewTextSubtitleBenchmark}>
                {' '}
                {filterState.globalPortfolioFilter.asOfDate.substring(
                  0,
                  4
                )}{' '}
                {PAGE_CON.BENCHMARK_DISTRIBUTION_TITLE}
              </div>
              {pageState.portfolioESGScore.isLoading ? (
                <LoadingMask />
              ) : pageState.portfolioESGScore.dataStatus === PAGE_CON.ERROR ||
                (pageState.portfolioESGScore.dataStatus === PAGE_CON.SUCCESS &&
                  pageState.portfolioESGScore.data.distribution &&
                  pageState.portfolioESGScore.data.distribution.length ===
                    0) ? (
                <Error
                  id={'distributionError'}
                  dataStatus={pageState.portfolioESGScore.dataStatus}
                  onErrorClick={reloadComponent}
                  errorOccuredOn={PAGE_CON.BENCHMARK_ESG_TITLE}
                />
              ) : (
                <MinimalTable
                  id="physical-risk-management-overview-portfolio-distribution-minimaltable-test-id"
                  columns={portfolioDistributionColumns_benchmark}
                  headerBgColor={scoreHeader}
                  data={pageState.portfolioESGScore.data.distribution}
                  hideBenchmark={true}
                  ESCScore={true}
                ></MinimalTable>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            id="benchmark_box"
            className={classes.benchmarkBoxStyle}
            style={{ marginLeft: '28px', marginRight: '8px', padding: '0px' }}
            md={6}
            lg={12}
          >
            <Box className={classes.benchmarkTextStyle}>
              {'No benchmark selected. Please select a benchmark'}
            </Box>
          </Grid>
        )}
        {historyData.YearsData.length > 1 ||
        (smallResize && historyData.YearsData.length == 1) ? (
          <Grid
            item
            id="legend_summary_link_box"
            sm={6}
            className={classes.infoBoxStyle}
            style={{ padding: '0px', marginLeft: '30px' }}
          >
            <div id="legend_box" style={{ textAlign: 'left' }}>
              {pageState.portfolioESGScore.isLoading ? (
                <LoadingMask />
              ) : pageState.portfolioESGScore.dataStatus === PAGE_CON.ERROR ||
                (pageState.portfolioESGScore.dataStatus === PAGE_CON.SUCCESS &&
                  pageState.portfolioESGScore.data.distribution &&
                  pageState.portfolioESGScore.data.distribution.length ===
                    0) ? (
                <Error
                  id={'distributionError'}
                  dataStatus={pageState.portfolioESGScore.dataStatus}
                  onErrorClick={reloadComponent}
                  errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
                />
              ) : (
                <Legend
                  colorOptions={legendColor}
                  colorGroup={colorGroup}
                  legendTitle={`${props.pageTitle} Score:`}
                  boldTitle={true}
                />
              )}
            </div>

            {props.overViewText === 'physicalRiskHazards' && (
              <div
                id="summary_box"
                className={classes.grid}
                style={overViewText}
              >
                {OverViewText.OverViewText[props.overViewText]}
                <br></br>
                <br></br>
                {OverViewText.OverViewText.additionalNote}
              </div>
            )}
            {props.overViewText !== 'physicalRiskHazards' && (
              <div
                id="summary_box"
                className={classes.grid}
                style={overViewText}
              >
                {OverViewText.OverViewText[props.overViewText]}
              </div>
            )}

            <div id="link_box" style={{ textAlign: 'left' }}>
              <PrimaryLink
                id="link-link-test-id-3"
                linkText={`Read more about ${props.pageTitle} assessment methodology`}
                onClick={openAboutLinkOpen}
                linkClass="linkInline"
                linkColorChange={true}
              />
            </div>
          </Grid>
        ) : null}
      </Grid>
    </div>
  )
}
export default PhysicalRiskOverView
