import { makeStyles } from '@material-ui/core/styles'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  pageHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    maxWidth: (props) =>
      props.pageHeaderWidth ? props.pageHeaderWidth : '1200px',

    paddingLeft: (props) => props.pageHeaderMarginLeft,
    marginRight: (props) => props.pageHeaderMarginRight,
    boxShadow: 'none',
    zIndex: 2,
    minWidth: (props) => (props.minWidth ? props.minWidth : ''),
    marginLeft: (props) => (props.marginLeft ? props.marginLeft : ''),
    height: (props) =>
      props.pageHeaderHeight ? props.pageHeaderHeight : '120px',
    width: (props) => props.minify && '100vw',
    top: (props) => props.minify && '48px',
    position: (props) => props.minify && 'absolute'
  },
  toolbarStyle: {
    minHeight: (props) => props.minify && 'auto',
    marginLeft: (props) =>
      props.toolbarMarginLeft ? props.toolbarMarginLeft : '4px',
    marginBottom: (props) => props.pageHeaderMarginBottom,
    marginTop: (props) =>
      props.toolbarMarginTop ? props.toolbarMarginTop : null
  }
}))
