const API_HOST_URL = process.env.REACT_APP_API_HOST

//Import Portfolio
export const IMPORT_UPDATE_GET_PORTFOLIOS = `${API_HOST_URL}/api/portfolio`
export const GET_ENTITLEMENTS = `${API_HOST_URL}/api/entitlement-handler`

export const COMMON = {
  BASE_URL: `${API_HOST_URL}/api/portfolio/`,
  BASE_SEARCH: `${API_HOST_URL}/api/`,
  ENTITY_URL: `${API_HOST_URL}/api/entity/`,
  LEADERS_LAGGARDS: '/leaders-laggards',
  COVERAGE: '/coverage',
  COVERAGE_DETAILS: '/coverage-details',
  MISSING_DOCS: '/documents',
  URL_VALIDATOR: '/validate-url',
  DISTRIBUTION: '/distribution',
  SCORE: '/score',
  UPDATES: '/updates',
  IMPACT: '/impact',
  EMISSIONS: '/emissions',
  ENTITYLIST: '/entity-list',
  REGION_SUMMARY: '/region-summary',
  REGION_DETAILS: '/region-details',
  SECTOR_SUMMARY: '/sector-summary',
  SECTOR_DETAILS: '/sector-details',
  MAP: '/region-map',
  DATA_METRICS: '/underlying-data-metrics',
  DATA_METRICS_DETAILS: '/underlying-data-metrics-details',
  EXCLUDED_URLS: ['.s3.amazonaws.com'],
  ISSUER_SCORE_STATUS: '/score-status',
  HEADER: '/header',
  ENTITY_SUMMARY: '/summary',
  ENTITY_CONTROVERSY: '/dual-materiality/controversies',
  RELATED_CONTROVERSY: '/controversies/related-controversies',
  SECTOR_ALLOCATION: '/sector-allocation',
  ENTITY_DRIVER_SUMMARY: '/dual-materiality/drivers-summary',
  ENTITY_DRIVER_DETAIL: '/dual-materiality/driver-details',
  ISSUER_SOURCE_DOC: '/source-documents',
  ENTITY_SECTOR_POSITION: '/sector-position',
  ENTITY_SCORE_HISTORY: '/entity-score-history',
  ENTITY_SEARCH: 'entity/search',
  CLIMATE_SUMMARY: '/climate-summary',
  ENTITY_UNDERLYING_DATA_METRICS: '/entity-underlying-data-metrics',
  CLIMATE_SECTOR_COMPARISON: '/climate-sector-comparison',
  ENTITY_EXPORT_SOURCE_DOCUMENTS: '/esgasmt/source-documents',
  ENTITY_CLIMATE_TEMPALGMT_PROJECTION_GRAPH: '/climate-temp-projection',
  ENTITY_PDF_EXPORT: '/climate-page-pdf-export',
  PORTFOLIO_ENTITY_DETAIL: '/portfolio-details',
  PORTFOLIO_RESEARCHLINES: '/portfolio-researchlines',
  PORTFOLIO_CONTROVERSIES: '/controversies/portfolio-controversies',
  PORTFOLIO_SUMMARY_COMPANIES: '/portfolio-summary-companies',
  DASHBOARD_COVERAGE: '/dashboard-coverage',
  HEAT_MAP: '/heat-map',
  HEAT_MAP_ENTITY_LIST: '/heat-map-entity-list'
}
export const PARAMS = {
  USER_ID: 'user_id',
  REGION: 'region',
  SECTOR: 'sector',
  YEAR: 'year',
  MONTH: 'month',
  PORTFOLIO_ID: 'portfolio_id',
  PORTFOLIO_NAME: 'portfolio_name',
  BENCHMARK_ID: 'benchmark',
  FNAME: 'first_name',
  LNAME: 'last_name',
  EMAIL: 'email',
  FILE_NAME: 'fileName',
  CRITERIA: 'criteria',
  COUNTRY_CODE: 'country_code',
  IMPACT_FILTER: 'filter',
  RESEARCHLINE: 'research_line',
  RESEARCHLINE_1: 'research_line_1',
  RESEARCHLINE_2: 'research_line_2',
  CATEGORY_1: 'category_1',
  CATEGORY_2: 'category_2',
  VIEW_BY: 'view_by',
  LIMIT: 'limit',
  TABLE_TYPE: 'table_type',
  ACTION_TYPE: 'action_type',
  SEARCH_TERM: 'search_term',
  ORBIS_ID: 'orbis_id',
  HTML: 'html',
  CLIENT_ID: 'client_id',
  RISK_LEVEL: 'risk_level',
  RISK_CATEGORY: 'risk_category',
  DISPLAY_CF_PREDICTED_SCORE: 'display_cf_predicted_score',
  CARBONFOOTPRINT: 'carbonfootprint',
  REPORT_TYPE: 'reportType',
  ONDEMAND_PAGE: 'ondemand_page'
}

export const GBL_FLT = {
  USER_PROFILE: `${API_HOST_URL}/api/user-profile`,
  FILTER: '/filter',
  ESG_ASSESSMENT: '/esgasmt',
  USER_SETTINGS: `${API_HOST_URL}/api/user-settings`
}

export const ENTITY = {
  BASE_URL: `${API_HOST_URL}/api/issuer`,
  ENTITY_BASE_URL: `${API_HOST_URL}/api/entity`,
  MATERIALITY: '/materiality',
  ENTITY_CONTROVERSIES: '/entity-controversies',
  HEADER: '/header'
}

export const ENTITY_CLIMATE_MTLTY_MATRIX = {
  BASE_URL: `${API_HOST_URL}/api/issuer/materiality`
}

export const PHY_RSK_MGM = {
  PAGE_URL: '/physicalriskmgmt'
}

export const PHY_RSK_HZRD = {
  PAGE_URL: '/physicalriskhazard'
}

export const OPR_RSK_MGM = {
  PAGE_URL: '/operationsrisk',
  IMPACT_PAGE_URL: '/operationsrisk',
  BASE_URL: '/operationsrisk'
}

export const MRK_RSK_MGM = {
  PAGE_URL: '/marketrisk',
  IMPACT_PAGE_URL: '/marketrisk',
  BASE_URL: '/marketrisk'
}

export const SPCH_RSK_MGM = {
  PAGE_URL: '/supplychainrisk',
  IMPACT_PAGE_URL: '/supplychainrisk',
  BASE_URL: '/supplychainrisk'
}

export const CAR_FOOT_PRNT = {
  PAGE_URL: '/carbonfootprint'
}
export const BRN_SHR_MGM = {
  PAGE_URL: '/brownshareasmt'
}

export const ENG_TRAN_MGM = {
  PAGE_URL: '/energytransmgmt'
}

export const GRN_SHR_ASMNT = {
  PAGE_URL: '/greenshareasmt'
}

export const TCFD_STGY = {
  PAGE_URL: '/tcfdstrategy'
}

export const TEMP_ALMT = {
  PAGE_URL: '/temperaturealgmt'
}

export const SECTOR_ALLOCATION = {
  PAGE_URL: '/sector-allocation'
}

export const ESG_ASMNT = {
  PAGE_URL: '/esgasmt'
}

export const EXPORT = {
  BASE_URL: `${API_HOST_URL}/api/portfolio/`,
  PDF_EXPORT: '/pdf-export',
  EXCEL_REPORT: '/excel-report',
  EXCEL_EXPORT: '/excel-export',
  UPLOAD_JSON_URL: '/upload-json-url',
  EXPORT_CLIMATE_DATA: '/export-climate-data',
  PAGE_URL: '/esgasmt',
  PDF_REPORT_STATUS: '/pdf-report-status',
  PDF_DOWNLOAD: '/pdf-download'
}

export const REPORTING_SERVICE = {
  BASE_URL: `${API_HOST_URL}/api/report`,
  PORTFOLIO_URL: `${API_HOST_URL}/api/portfolio`,
  REPORTING_PORTFOLIOS: '/portfolio-details',
  REPORTING_PORTFOLIO_COVERAGE: '/coverage-details',
  REPORTING_GENERATION: '/async-generation',
  REPORTING_DOWNLOAD: '/download',
  REPORTING_STATUS: '/status',
  REPORTING_PORTFOLIO_OPTIONS: '/user-options',
  DOWNLOAD_HISTORY: '/download-history '
}

export const SME_SERVICE = {
  BASE_URL: `${API_HOST_URL}/api/on-demand`,
  ON_DEMAND_ASSESSMENT: '/on-demand',
  SME_FILTER: '/filter',
  SME_BATCH_REQUEST: '/status',
  ASSESSMENT_INFO: '/info',
  PORTFOLIOS: '/coverage'
}
