import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.typography.p3
  }
}))

export const Container = ({ children, styles }) => {
  const classes = useStyles(theme)
  return (
    <span style={styles} className={classes.container}>
      {children}
    </span>
  )
}
