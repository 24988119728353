import React from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import themeColorPalette from 'theme/theme'
import { GlobalContext } from 'context/GlobalStateProvider'
import { REDUCER_TYPE } from 'constant/constants'
import { TextField } from '@material-ui/core'
import { useStyles } from './styles'

const CheckboxGroup = ({ selectedRow }) => {
  const classes = useStyles()
  const [issuerUrlState, issuerUrlDispatch] =
    React.useContext(GlobalContext).globalIssuerUrl

  const handleOnComponentCheck = ({ target: { checked } }, componentName) => {
    const newOptions = new Map(
      issuerUrlState.urlInfo.urls.get(selectedRow.id).options
    )
    newOptions.get(componentName).set('checked', checked)
    newOptions.get(componentName).set('indeterminate', false)
    ;[...newOptions.get(componentName).get('childrens').keys()].forEach((key) =>
      newOptions.get(componentName).get('childrens').set(key, { checked })
    )

    issuerUrlDispatch({
      type: REDUCER_TYPE.UPDATE_URL,
      data: {
        newOptions,
        id: selectedRow.id
      }
    })
  }

  const handleOnCategoryCheck = (
    { target: { checked } },
    componentName,
    categoryName
  ) => {
    const newOptions = new Map(
      issuerUrlState.urlInfo.urls.get(selectedRow.id).options
    )

    newOptions
      .get(componentName)
      .get('childrens')
      .set(categoryName, { checked })

    const categoriesChecks = [
      ...newOptions.get(componentName).get('childrens').values()
    ].map((value) => value.checked)

    const allCategoriesChecked = categoriesChecks.every((v) => v === true)

    const isIndeterminate =
      categoriesChecks.includes(true) && !allCategoriesChecked

    newOptions.get(componentName).set('indeterminate', isIndeterminate)

    newOptions.get(componentName).set('checked', allCategoriesChecked)

    issuerUrlDispatch({
      type: REDUCER_TYPE.UPDATE_URL,
      data: {
        newOptions,
        id: selectedRow.id
      }
    })
  }

  const handleOnCategoryPage = (
    { target: { value = '' } },
    componentName,
    categoryName
  ) => {
    const newOptions = new Map(
      issuerUrlState.urlInfo.urls.get(selectedRow.id).options
    )

    const categoryOptions = newOptions
      .get(componentName)
      .get('childrens')
      .get(categoryName)

    const page = value.replace(/[^\d]/g, '')
    if ((page.length < 4 && page > 0) || page === '') {
      newOptions
        .get(componentName)
        .get('childrens')
        .set(categoryName, { ...categoryOptions, page: page || null })

      issuerUrlDispatch({
        type: REDUCER_TYPE.UPDATE_URL,
        data: {
          newOptions,
          id: selectedRow.id
        }
      })
    }
  }

  if (!issuerUrlState?.urlInfo?.urls.get(selectedRow.id)?.options) {
    return null
  }

  return (
    <>
      {[
        ...issuerUrlState.urlInfo.urls.get(selectedRow.id).options.entries()
      ].map(([componentName, component] = []) => (
        <div key={componentName}>
          <FormControlLabel
            key={componentName}
            label={componentName}
            control={
              <Checkbox
                key={componentName}
                sx={{
                  color: themeColorPalette.palette.sys_trad.main.main,
                  '&.Mui-checked': {
                    color: themeColorPalette.palette.sys_trad.main.main
                  }
                }}
                name={componentName}
                indeterminate={component.get('indeterminate')}
                checked={component.get('checked')}
                onChange={(e) => handleOnComponentCheck(e, componentName)}
              />
            }
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              ml: 3
            }}
          >
            {[...component.get('childrens').entries()].map(
              ([driverName, driver]) => (
                <FormControlLabel
                  className={classes.formControl}
                  key={driverName}
                  label={
                    <Box className={classes.labelDriver}>
                      <span
                        className={driver.checked ? classes.driverName : ''}
                      >
                        {driverName}
                      </span>
                      {driver.checked ? (
                        <span className={classes.pageContainer}>
                          <span>Page #</span>
                          <TextField
                            className={classes.pageEditor}
                            autoComplete="off"
                            auto="true"
                            id={driverName}
                            variant="standard"
                            value={driver.page || ''}
                            onChange={(e) =>
                              handleOnCategoryPage(e, componentName, driverName)
                            }
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              classes: {
                                root: classes.inputRoot,
                                input: classes.inputPage
                              }
                            }}
                          />
                        </span>
                      ) : null}
                    </Box>
                  }
                  control={
                    <Checkbox
                      key={driverName}
                      sx={{
                        color: themeColorPalette.palette.sys_trad.main.main,
                        '&.Mui-checked': {
                          color: themeColorPalette.palette.sys_trad.main.main
                        }
                      }}
                      checked={driver.checked}
                      onChange={(e) =>
                        handleOnCategoryCheck(e, componentName, driverName)
                      }
                    />
                  }
                />
              )
            )}
          </Box>
        </div>
      ))}
    </>
  )
}

export default CheckboxGroup
