import { makeStyles } from '@material-ui/core/styles'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.main.main
  }
}))
