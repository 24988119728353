import { GlobalContext } from 'context/GlobalStateProvider'
import { Grid } from '@material-ui/core'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import { Tabs } from 'components'
import {
  BRN_SHR_MGM,
  CAR_FOOT_PRNT,
  COMMON,
  ENTITY_CLIMATE_MTLTY_MATRIX,
  GRN_SHR_ASMNT,
  MRK_RSK_MGM,
  OPR_RSK_MGM,
  PARAMS,
  PHY_RSK_MGM,
  SPCH_RSK_MGM,
  TEMP_ALMT
} from 'constant/APIconstants'
import LoadingMask from 'utils/loadingMask'
import axios from 'axios'
import PhysicalRiskUDM from './PhysicalRiskUDM'
import React, { useContext, useEffect, useState } from 'react'
import TransitionRiskUDM from './transitionRiskUDM'
import Drivers from 'views/common/materiality/drivers/MaterialityDrivers'
import { ResearchLineService } from 'services'
import * as S from './styles'

const ClimateUnderlying = ({ entitlements }) => {
  const classes = S.useStyles()
  const [selectedTab, setSelectedTab] = useState('')
  const [tabs, setTabs] = useState([])
  const [entityHeaderState] = useContext(GlobalContext).globalEntityHeader
  const [climateState, climateDispatch] =
    useContext(GlobalContext).globalClimate
  let apiCallId = climateState.apiCallId

  let items = []

  const isCompanyHasOnDemand =
    entityHeaderState?.globalEntityHeader?.score_quality === 'on-demand'

  useEffect(() => {
    if (entityHeaderState?.globalEntityHeader?.score_quality !== '') {
      if (entitlements.PortfolioAnalysis.esg) {
        items.push({
          label: PAGE_CON.ESG_MTLTY_TITLE,
          key: 'esgMateriality',
          active: true
        })
      }
      if (
        entitlements.PortfolioAnalysis.physicalrisk &&
        !(entitlements.ondemandassessment && isCompanyHasOnDemand)
      ) {
        items.push({
          label: PAGE_CON.PHY_RSK_TITLE,
          key: 'physicalRisk',
          active: false
        })
      }
      if (
        entitlements.PortfolioAnalysis.transitionrisk &&
        !(entitlements.ondemandassessment && isCompanyHasOnDemand)
      ) {
        items.push({
          label: PAGE_CON.TRN_RSK_TITLE,
          key: 'transitionRisk',
          active: false
        })
      }

      if (items.length) {
        items[0].active = true
      }

      setTabs(items)

      items.length && setSelectedTab(items[0].label)
    }
  }, [entityHeaderState])
  const loadUnderlyindData = React.useCallback(async () => {
    if (
      entityHeaderState.globalEntityHeader.orbis_id !== '' &&
      !entityHeaderState.isLoading
    ) {
      if (entitlements.PortfolioAnalysis.esg) getESGMaterialityData()
      if (entitlements.PortfolioAnalysis.physicalrisk) getPhyRiskData()
      if (entitlements.PortfolioAnalysis.transitionrisk) getTransitionRiskData()
    }
  }, [entityHeaderState.globalEntityHeader.orbis_id])

  React.useEffect(() => {
    if (entityHeaderState.globalEntityHeader.orbis_id !== '') {
      loadUnderlyindData()
    }
  }, [entityHeaderState.globalEntityHeader.orbis_id])

  const handleTabClick = (selectedKey) => {
    const newTabs = tabs.map((tab) => ({
      ...tab,
      active: tab.key === selectedKey
    }))

    setTabs(newTabs)
    if (selectedKey === 'physicalRisk') {
      setSelectedTab(PAGE_CON.PHY_RSK_TITLE)
    } else if (selectedKey === 'esgMateriality') {
      setSelectedTab(PAGE_CON.ESG_MTLTY_TITLE)
    } else {
      setSelectedTab(PAGE_CON.TRN_RSK_TITLE)
    }
  }

  const getESGMaterialityData = async (isErrorReload = false) => {
    if (
      climateState.esgMateriality.dataStatus !== '' &&
      isErrorReload === false
    )
      return

    climateDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionNames: [REDUCER_STATE.CLIMATE_ESG_MTLTY_MATRIX]
    })

    try {
      const esgMtlty = await axios.post(ENTITY_CLIMATE_MTLTY_MATRIX.BASE_URL, {
        [PARAMS.ORBIS_ID]: entityHeaderState.globalEntityHeader.orbis_id
      })

      climateDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionNames: [
          { [REDUCER_STATE.CLIMATE_ESG_MTLTY_MATRIX]: esgMtlty?.data || {} }
        ],
        apiCallId
      })
    } catch (err) {
      console.log(err)
      climateDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        sectionNames: [REDUCER_STATE.CLIMATE_ESG_MTLTY_MATRIX],
        apiCallId
      })
    }
  }

  const getPhyRiskData = async (isErrorReload = false) => {
    if (
      (climateState.phyOperationRisk.dataStatus !== '' ||
        climateState.phyMarketRisk.dataStatus !== '' ||
        climateState.phySupplyChainRisk.dataStatus !== '' ||
        climateState.phyOperationalRiskChart.dataStatus !== '' ||
        climateState.phyMarketRiskChart.dataStatus !== '' ||
        climateState.phySupplyChainChart.dataStatus !== '' ||
        climateState?.PhyRiskMgmUDM?.dataStatus !== '') &&
      isErrorReload === false
    )
      return

    climateDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionNames: [
        REDUCER_STATE.CLIMATE_PHY_OPERATION_RISK,
        REDUCER_STATE.CLIMATE_PHY_SUPPLYCHAIN_RISK,
        REDUCER_STATE.CLIMATE_PHY_OPERATIONAL_RISK_CHART,
        REDUCER_STATE.CLIMATE_PHY_MARKET_RISK_CHART,
        REDUCER_STATE.CLIMATE_PHY_SUPPLY_CHAIN_RISK_CHART,
        REDUCER_STATE.CLIMATE_PHY_RISK_MGM_UNDERLYING_DATA
      ]
    })

    try {
      const [
        op_risk,
        market_risk,
        sc_risk,
        op_risk_chart,
        market_risk_chart,
        supply_risk_chart,
        physical_risk_mgmt_UDM
      ] = await axios.all([
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: true,
          researchLine: OPR_RSK_MGM.PAGE_URL,
          token: '',
          endPoint: COMMON.ENTITY_UNDERLYING_DATA_METRICS
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: true,
          researchLine: MRK_RSK_MGM.PAGE_URL,
          token: '',
          endPoint: COMMON.ENTITY_UNDERLYING_DATA_METRICS
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: true,
          researchLine: SPCH_RSK_MGM.PAGE_URL,
          token: '',
          endPoint: COMMON.ENTITY_UNDERLYING_DATA_METRICS
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: true,
          researchLine: OPR_RSK_MGM.PAGE_URL,
          token: '',
          endPoint: COMMON.CLIMATE_SECTOR_COMPARISON
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: true,
          researchLine: MRK_RSK_MGM.PAGE_URL,
          token: '',
          endPoint: COMMON.CLIMATE_SECTOR_COMPARISON
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: true,
          researchLine: SPCH_RSK_MGM.PAGE_URL,
          token: '',
          endPoint: COMMON.CLIMATE_SECTOR_COMPARISON
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: true,
          researchLine: PHY_RSK_MGM.PAGE_URL,
          token: '',
          endPoint: COMMON.ENTITY_UNDERLYING_DATA_METRICS
        })
      ])

      climateDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionNames: [
          {
            [REDUCER_STATE.CLIMATE_PHY_OPERATION_RISK]: op_risk?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_PHY_MARKET_RISK]: market_risk?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_PHY_SUPPLYCHAIN_RISK]: sc_risk?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_PHY_OPERATIONAL_RISK_CHART]:
              op_risk_chart?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_PHY_MARKET_RISK_CHART]:
              market_risk_chart?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_PHY_SUPPLY_CHAIN_RISK_CHART]:
              supply_risk_chart?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_PHY_RISK_MGM_UNDERLYING_DATA]:
              physical_risk_mgmt_UDM?.data || {}
          }
        ],
        apiCallId
      })
    } catch (err) {
      console.log(err)
      climateDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        sectionNames: [
          REDUCER_STATE.CLIMATE_PHY_OPERATION_RISK,
          REDUCER_STATE.CLIMATE_PHY_MARKET_RISK_CHART,
          REDUCER_STATE.CLIMATE_PHY_SUPPLY_CHAIN_RISK_CHART,
          REDUCER_STATE.CLIMATE_PHY_SUPPLYCHAIN_RISK,
          REDUCER_STATE.CLIMATE_PHY_OPERATIONAL_RISK_CHART,
          REDUCER_STATE.CLIMATE_PHY_RISK_MGM_UNDERLYING_DATA
        ],
        apiCallId
      })
    }
  }

  const getTransitionRiskData = async (isErrorReload = false) => {
    if (
      (climateState.transTempAlign.dataStatus !== '' ||
        climateState.transCarbonFootPrint.dataStatus !== '' ||
        climateState.transBrownShare.dataStatus !== '' ||
        climateState.transGreenShare.dataStatus !== '' ||
        climateState.transCarbonFootPrintChart.dataStatus !== '' ||
        climateState.transBrownShareFootPrintChart.dataStatus !== '') &&
      isErrorReload === false
    )
      return

    climateDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionNames: [
        REDUCER_STATE.CLIMATE_TRANS_TEMP_ALIGN,
        REDUCER_STATE.CLIMATE_TRANS_CARBON_FOOT_PRINT,
        REDUCER_STATE.CLIMATE_TRANS_BROWN_SHARE,
        REDUCER_STATE.CLIMATE_TRANS_GREEN_SHARE,
        REDUCER_STATE.CLIMATE_TRANS_CARBON_FOOT_PRINT_CHART,
        REDUCER_STATE.CLIMATE_TRANS_BROWNSHARE_FOOT_PRINT_CHART
      ]
    })
    try {
      const [
        temp_align,
        carbon_fp,
        green_sh,
        brown_sh,
        carbon_fp_chart,
        brown_share_fp_chart
      ] = await axios.all([
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: true,
          researchLine: TEMP_ALMT.PAGE_URL,
          token: '',
          endPoint: COMMON.CLIMATE_SUMMARY
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: true,
          researchLine: CAR_FOOT_PRNT.PAGE_URL,
          token: '',
          endPoint: COMMON.ENTITY_UNDERLYING_DATA_METRICS
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: true,
          researchLine: GRN_SHR_ASMNT.PAGE_URL,
          token: '',
          endPoint: COMMON.ENTITY_UNDERLYING_DATA_METRICS
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: true,
          researchLine: BRN_SHR_MGM.PAGE_URL,
          token: '',
          endPoint: COMMON.ENTITY_UNDERLYING_DATA_METRICS
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: true,
          researchLine: CAR_FOOT_PRNT.PAGE_URL,
          token: '',
          endPoint: COMMON.CLIMATE_SECTOR_COMPARISON
        }),
        ResearchLineService.retrieveResearchLineApi({
          portfolio: entityHeaderState.globalEntityHeader.orbis_id,
          regions: '',
          sectors: '',
          asOfDate: '',
          benchmark: '',
          checkForEntitlements: true,
          isEntitled: true,
          researchLine: BRN_SHR_MGM.PAGE_URL,
          token: '',
          endPoint: COMMON.CLIMATE_SECTOR_COMPARISON
        })
      ])

      climateDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionNames: [
          {
            [REDUCER_STATE.CLIMATE_TRANS_TEMP_ALIGN]: temp_align?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_TRANS_CARBON_FOOT_PRINT]:
              carbon_fp?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_TRANS_GREEN_SHARE]: green_sh?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_TRANS_BROWN_SHARE]: brown_sh?.data[0] || {}
          },
          {
            [REDUCER_STATE.CLIMATE_TRANS_CARBON_FOOT_PRINT_CHART]:
              carbon_fp_chart?.data || {}
          },
          {
            [REDUCER_STATE.CLIMATE_TRANS_BROWNSHARE_FOOT_PRINT_CHART]:
              brown_share_fp_chart?.data || {}
          }
        ],
        apiCallId
      })
    } catch (err) {
      console.log(err)
      climateDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        sectionNames: [
          REDUCER_STATE.CLIMATE_TRANS_TEMP_ALIGN,
          REDUCER_STATE.CLIMATE_TRANS_CARBON_FOOT_PRINT,
          REDUCER_STATE.CLIMATE_TRANS_BROWN_SHARE,
          REDUCER_STATE.CLIMATE_TRANS_GREEN_SHARE,
          REDUCER_STATE.CLIMATE_TRANS_CARBON_FOOT_PRINT_CHART,
          REDUCER_STATE.CLIMATE_TRANS_BROWNSHARE_FOOT_PRINT_CHART
        ],
        apiCallId
      })
    }
  }

  const reloadComponent = (errorOccuredOn) => {
    switch (errorOccuredOn) {
      case PAGE_CON.CLIMATE_MTLTY_MATRIX:
        getESGMaterialityData(true)
        break
      case PAGE_CON.CLIMATE_PHY_RISK:
        getPhyRiskData(true)
        break
      case PAGE_CON.CLIMATE_TRAN_RISK:
        getTransitionRiskData(true)
        break
      default:
        break
    }
  }

  return (
    <>
      {entityHeaderState?.isLoading ? (
        <LoadingMask />
      ) : (
        <Grid className={classes.container}>
          <Tabs
            type={'small'}
            tabs={tabs}
            onClick={(key) => handleTabClick(key)}
          />
          {entitlements.PortfolioAnalysis.esg &&
            selectedTab === PAGE_CON.ESG_MTLTY_TITLE && (
              <Grid item>
                <Drivers
                  data={climateState?.esgMateriality}
                  reloadComponent={reloadComponent}
                  isControversyEnabled={entitlements.Dashboard.controversy}
                  entitlements={entitlements}
                  isCompanyHasOnDemand={isCompanyHasOnDemand}
                />
              </Grid>
            )}
          {entitlements.PortfolioAnalysis.physicalrisk &&
            selectedTab === PAGE_CON.PHY_RSK_TITLE && (
              <Grid item>
                <PhysicalRiskUDM reloadComponent={reloadComponent} />
              </Grid>
            )}
          {entitlements.PortfolioAnalysis.transitionrisk &&
            selectedTab === PAGE_CON.TRN_RSK_TITLE && (
              <Grid item>
                <TransitionRiskUDM reloadComponent={reloadComponent} />
              </Grid>
            )}
        </Grid>
      )}
    </>
  )
}

export default ClimateUnderlying
