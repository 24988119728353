import { makeStyles } from '@material-ui/core'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  drawerToggleSearch: {
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    height: '32px',
    borderRadius: '2px',
    fontSize: '16px !important',
    ...textTheme.typography.h1,
    marginLeft: 8,
    paddingLeft: 8,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    '&.MuiListItem-button': {
      fontSize: '16px !important',
      ...textTheme.typography.h1
    },
    color: themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      backgroundColor: 'transparent' //themeColorPalette.palette.sys_trad.hover2.main,
    }
  },
  text: {
    ...textTheme.typography.p3,
    fontSize: '14px !important',
    marginBottom: '2px'
  },
  bullet_list_style: {
    color: themeColorPalette.palette.sys_trad.main.main,
    padding: '0px 0px',
    marginLeft: '1.8em',
    ...textTheme.typography.p1,
    fontSize: '10px !important',
    marginBottom: '4px',
    marginTop: '0px'
  },
  doc_lang_message: {
    color: themeColorPalette.palette.sys_trad.main.main,
    padding: '0px 0px',
    marginLeft: '1.8em',
    ...textTheme.typography.p1,
    fontSize: '10px !important',
    marginBottom: '4px',
    marginTop: '4px'
  },
  menu_item_style: {
    fontSize: '16px !important',
    ...textTheme.typography.h1,
    marginLeft: '16px',
    cursor: 'auto'
  },
  dialogue_style: {
    display: 'flex',
    cursor: 'pointer',
    marginRight: '14px',
    marginTop: '8px'
  },
  dialogue_content_style: {
    minHeight: '120px'
  },
  loadStyle: {
    zIndex: 1301,
    position: 'absolute',
    top: '250%',
    left: '40%',
    transform: 'translate(-40%, -250%)'
  },
  backdrop: {
    zIndex: 1400 + 1,
    color: '#fff'
  },
  searchToolbarStyle: {
    minHeight: '48px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  urlInput: {
    ...textTheme.typography.p2
  },
  listItem: {
    padding: '0px 0px',
    marginLeft: '0.5em',
    '& .highlighted': {
      color: themeColorPalette.palette.sys_trad.status.main
    }
  },
  textBullet: {
    ...textTheme.typography.p3,
    fontSize: '14px !important',
    marginBottom: '4px',
    marginTop: '0px'
  },
  textBottom: {
    ...textTheme.typography.p3,
    fontSize: '14px !important',
    marginBottom: '20px'
  },
  textSecondBottom: {
    ...textTheme.typography.p3,
    fontSize: '14px !important',
    marginTop: '16px',
    marginBottom: '2px'
  },
  textTop: {
    ...textTheme.typography.h2,
    marginBottom: '16px',
    fontSize: '20px !important'
  },
  imageClass: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url(/issuer_bg.png)',
    backgroundPosition: '100% 48%'
  },
  issuer_banner: {
    paddingBottom: '34px',
    paddingTop: '12px',
    marginLeft: '8%',
    maxWidth: '1280px',
    color: themeColorPalette.palette.white.main
  },
  root: {
    flexGrow: 1
  },
  paper: {
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  grid: {
    textAlign: 'left',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  title: {
    ...textTheme.typography.h2,
    lineHeight: '16px',
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingBottom: 8
  },
  headerTitle: {
    ...textTheme.typography.h2,
    lineHeight: '16px',
    fontSize: '16px',
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingBottom: 24
  },
  tableStyle: {
    paddingTop: 12,
    paddingBottom: 0
  },
  root_sector_info: {
    flexGrow: 1,
    whiteSpace: 'pre-line',
    verticalAlign: 'bottom'
  },
  text_sector_info: {
    ...textTheme.typography.p3,
    lineHeight: '16px',
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingBottom: 32
  },
  headerTitle_sector_info: {
    ...textTheme.typography.h1,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    fontSize: '20px',
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingBottom: 24
  },
  gridStyle: {
    paddingTop: 12
  },
  bottomText: {
    ...textTheme.typography.p1,
    paddingLeft: '8px',
    paddingRight: '8px',
    color: textTheme.palette.sys_trad.text2.main
  },
  normalText: {
    ...textTheme.typography.p1,
    paddingLeft: '8px',
    paddingRight: '8px'
  }
}))
