import { Grid } from '@material-ui/core'
import { Checkbox, ComboBox } from 'components'
import React from 'react'
import clsx from 'clsx'
import numberData from 'utils/numberFormat'
import { getMonthDayYear } from 'utils/Date'
import { PAGE_CON } from 'constant/constants'
import { useStyles } from './styles'
import EmptyPortfolios from './EmptyPortfolios'

const SfdrPortfolios = ({
  onPortfolioSelection,
  onReportingYearChange,
  portfolios,
  disableReportingYear
}) => {
  const classes = useStyles({
    subHeaderAlignItems: 'end',
    itemPaddingTop: '12px',
    itemPaddingBottom: '10px',
    coverageWidth: 50,
    dateWidth: 115
  })

  const getAllReportingYears = (allReportingYears) => {
    let years = []
    allReportingYears.forEach((year) => {
      if (parseInt(year) >= 2019) {
        years.push({ key: year, displayText: year })
      }
    })

    return years
  }

  const onSelectPortfolio = (selectedItem) => {
    onPortfolioSelection(selectedItem)
  }

  const handleReportingYearChange = (portfolioKey, value) => {
    if (value) onReportingYearChange(portfolioKey, value.key)
  }

  return (
    <Grid container direction="column" style={{ marginBottom: 10 }}>
      <Grid item className={classes.header}>
        Select Portfolios
      </Grid>
      <Grid container>
        <Grid
          className={classes.subHeader}
          style={{ paddingLeft: '0px' }}
          item
          xs={5}
        >
          Portfolio
        </Grid>
        <Grid item xs={3} className={classes.subHeader}>
          <span className={classes.dateColumn}>Last Uploaded</span>
        </Grid>
        <Grid item xs={2} className={classes.subHeader}>
          <span className={classes.coverageColumn}>Coverage % Investment</span>
        </Grid>
        <Grid item xs={2} className={classes.subHeader}>
          Reporting for
        </Grid>
      </Grid>
      {portfolios.length > 0 ? (
        portfolios.map((portfolio, index) => {
          const portfolioKey = portfolio.key
          const portfolioName = portfolio.portfolioName
          const portfolioDate = portfolio.date
          const portfolioDisabled =
            portfolio.disabled ||
            portfolio.reportingYear === PAGE_CON.NOT_APPLICABLE ||
            portfolio.reportingYear.length === 0

          return (
            <Grid
              container
              className={classes.itemStyle}
              key={`portfolio-${index}=${portfolioKey}`}
            >
              <Grid className={clsx(classes.portfolioItem)} item xs={5}>
                <Checkbox
                  labelWidth={'250px'}
                  id={portfolioKey}
                  checked={portfolio.checked}
                  onChange={onSelectPortfolio}
                  disabled={portfolioDisabled}
                  label={portfolioName}
                  labelClass={'portfolioLabel'}
                />
              </Grid>
              <Grid
                className={clsx(
                  classes.portfolioItem,
                  portfolio.checked && classes.isChecked
                )}
                item
                xs={3}
              >
                <span className={classes.dateColumn}>
                  {getMonthDayYear(portfolioDate)}
                </span>
              </Grid>
              <Grid
                className={clsx(
                  classes.portfolioItem,
                  portfolio.checked && classes.isChecked
                )}
                item
                xs={2}
                title={
                  portfolio.sfdr_coverage < 1
                    ? `${portfolio.sfdr_coverage}%`
                    : undefined
                }
              >
                <span className={classes.coverageColumn}>
                  {portfolio.sfdr_coverage !== PAGE_CON.NOT_APPLICABLE
                    ? numberData(portfolio.sfdr_coverage, {
                        isDecimal: true,
                        isPercentage: true,
                        keepRawValue: true
                      })
                    : PAGE_CON.NOT_APPLICABLE}
                </span>
              </Grid>
              <Grid
                className={clsx(
                  classes.portfolioItem,
                  portfolio.checked && classes.isChecked
                )}
                item
                xs={2}
              >
                {portfolio.reportingYear === PAGE_CON.NOT_APPLICABLE ? (
                  <span className={classes.reportingYearNA}>
                    {PAGE_CON.NOT_APPLICABLE}
                  </span>
                ) : (
                  <ComboBox
                    value={portfolio.reportingYear}
                    onChange={(selectedObj) =>
                      handleReportingYearChange(portfolioKey, selectedObj)
                    }
                    width={'100%'}
                    disabled={disableReportingYear ? true : !portfolio.checked}
                    comboBoxOptions={getAllReportingYears(
                      portfolio.allReportingYears
                    )}
                    height={'29.63px'}
                    displayTextClassName={'portfolioTextStyle'}
                  />
                )}
              </Grid>
            </Grid>
          )
        })
      ) : (
        <EmptyPortfolios />
      )}
    </Grid>
  )
}

export default SfdrPortfolios
