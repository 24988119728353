import { AppBar } from '@material-ui/core'
import axios from 'axios'
import { Container, Grid, makeStyles } from '@material-ui/core'
import { DropdownField, IconArrowDown, Page } from 'components'
import Error from 'utils/error'
import getMonthYear from 'utils/Date'
import { GlobalContext } from 'context/GlobalStateProvider'
import { COMMON, PARAMS, TCFD_STGY } from 'constant/APIconstants'
import {
  PAGE_CON,
  REDUCER_STATE,
  REDUCER_TYPE,
  filterOptions
} from 'constant/constants'
import Laggards from '../../Laggards'
import Leaders from '../../Leaders'
import LoadingMask from 'utils/loadingMask'
import PortfolioAnalysisColumns from '../../PortfolioAnalysisColumns.json'
import TcfdStrategyOverview from './TcfdStrategyOverview'
import React, { useCallback, useContext, useEffect } from 'react'
import Regions from '../../Regions'
import Updates from '../../Updates'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'
import TcfdStrategyUnderlyingDataMetrics from './TcfdStrategyUnderlyingDataMetrics'
import Impact from '../../Impact'
import getColor from 'utils/color'
import { PortfolioAnalysisService, RegionService } from 'services'

const UpdatesTableColInfo = PortfolioAnalysisColumns.updates_Columns
const PanelUpdatesTableColInfo = PortfolioAnalysisColumns.updates_Panel_Columns
const LeaderLaggardColInfo =
  PortfolioAnalysisColumns.leaders_Laggards_Panel_Columns
const mimTabColInfo = JSON.parse(
  JSON.stringify(PortfolioAnalysisColumns.region_Sector_Minimal_Table_Columns)
)
const panelTabColInfo = PortfolioAnalysisColumns.region_Sector_Panel_Columns
const cardInfoContentInfo = PortfolioAnalysisColumns.card_Info_Section_Columns
const cardInfoContentInfoSector =
  PortfolioAnalysisColumns.card_Info_Section_Columns_Sector
const impactColumns = JSON.parse(
  JSON.stringify(PortfolioAnalysisColumns.impactColumns)
)

const drawerWidth = PAGE_CON.PAGE_LEFT_MARGIN

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  content: {
    flexGrow: 1
  },
  container: {
    marginLeft: drawerWidth - 20,
    paddingRight: 0,
    paddingLeft: '24px',
    marginTop: '22px'
  },
  tableTitle: {
    paddingBottom: '5px',
    ...textTheme.typography.h1
  },
  stickyHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    zIndex: 1,
    boxShadow: 'none'
  },
  containerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 10px 16px',
    marginTop: '24px',
    marginBottom: '10px'
  },
  impactContainerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 0px 16px',
    marginTop: '24px',
    paddingLeft: '16px'
  },
  impactDropdown: {
    marginTop: '16px',
    marginBottom: '12px'
  }
}))

const tableLength = 10

const overviewLinkPadding = {
  marginTop: '24px',
  marginLeft: '-4px'
}

const linkPadding = {
  marginTop: '16px',
  marginLeft: '-4px'
}

const linkPaddingRegionIndustry = {
  marginTop: '8px',
  marginLeft: '-4px'
}

const linkStyle = {
  ...textTheme.typography.p1,
  color: themeColorPalette.palette.sys_trad.link.main,
  lineHeight: '16px',
  verticalAlign: 'top'
}

const regionStyle = {
  paddingBottom: '10px',
  marginTop: '18px'
}

const updatesStyle = {
  paddingLeft: '2px',
  marginTop: '16px'
}

const UpdatesTableStyle = {
  marginTop: '12px',
  background: themeColorPalette.palette.sys_trad.white.main,
  boxShadow: '0px 0px 16px rgba(38, 65, 94, 0.12)',
  boxRadius: '2px',
  boxSizing: 'border-box',
  padding: '12px',
  paddingBottom: '15px',
  paddingTop: '0px'
}

const cardHeader = {
  ...textTheme.typography.p2,
  paddingBottom: '4px',
  paddingTop: '16px',

  lineHeight: '16px'
}

const regionTitleStyle = {
  top: '16px'
}

const dataMetricbox = {
  marginTop: '12px',
  paddingTop: '10px',

  background: themeColorPalette.palette.sys_trad.white.main,
  boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.12)',
  boxRadius: '2px',
  boxSizing: 'border-box',
  padding: '17px'
}

const dataMetricTitle = {
  marginTop: '16px',
  marginLeft: '3px'
}

const mapDataClasses = [
  {
    color: themeColorPalette.palette.adv_wk.weak.main,
    from: 0,
    to: 29,
    name: PAGE_CON.WEAK
  },
  {
    color: themeColorPalette.palette.adv_wk.limited.main,
    from: 30,
    to: 49,
    name: PAGE_CON.LIMITED
  },
  {
    color: themeColorPalette.palette.adv_wk.robust.main,
    from: 50,
    to: 59,
    name: PAGE_CON.ROBUST
  },
  {
    color: themeColorPalette.palette.adv_wk.advanced.main,
    from: 60,
    to: 100,
    name: PAGE_CON.ADVANCED
  }
]

const TcfdStrategy = (props) => {
  const classes = useStyles()
  const [filterState] = useContext(GlobalContext).globalFilter
  const [tcfdStrategyState, tcfdStrategyDispatch] =
    useContext(GlobalContext).globalTcfdStrategy
  const [selectedCardId, setSelectedCardId] = React.useState(null)

  let showBenchMark =
    filterState.globalPortfolioFilter.benchmark !== '' ? true : false

  let apiCallId = tcfdStrategyState.apiCallId

  let benchmarkPctCol = mimTabColInfo.filter(
    (col) => col.HeaderLabel === PAGE_CON.BENCHMARK_PERC
  )
  benchmarkPctCol[0].HideColumn = !showBenchMark

  let monthYear = getMonthYear(filterState)

  const onImpactFilterChange = (event) => {
    tcfdStrategyDispatch({
      type: REDUCER_TYPE.UPDATE_IMPACT_FILTER,
      data: event.target.value
    })
  }

  const isRegionHasData = () => {
    let hasData = false
    if (
      tcfdStrategyState.portfolioRegions.data.summary.length > 0 &&
      tcfdStrategyState.portfolioRegions.data.details.length > 0 &&
      tcfdStrategyState.portfolioRegions.data.mapData.length > 0
    )
      hasData = true
    return hasData
  }

  const getLeadersLaggardsData = async (
    sectors,
    regions,
    isErrorReload = false
  ) => {
    if (
      (tcfdStrategyState.portfolioLeaderLaggard.data.leaders &&
        tcfdStrategyState.portfolioLeaderLaggard.data.laggards &&
        tcfdStrategyState.portfolioLeaderLaggard.data.leaders.length > 0 &&
        tcfdStrategyState.portfolioLeaderLaggard.data.laggards.length > 0) ||
      (tcfdStrategyState.portfolioLeaderLaggard.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    tcfdStrategyDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS
    })
    let leadersLaggardsData = { leaders: [], laggards: [] }
    try {
      const { data } = await axios.post(
        COMMON.BASE_URL +
          filterState.globalPortfolioFilter.portfolio +
          TCFD_STGY.PAGE_URL +
          COMMON.LEADERS_LAGGARDS,
        {
          [PARAMS.REGION]: regions,
          [PARAMS.SECTOR]: sectors,
          [PARAMS.MONTH]: filterState.globalPortfolioFilter.asOfDate.slice(4),
          [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(0, 4)
        },
        { cancelToken: tcfdStrategyState.cancelToken.token }
      )

      leadersLaggardsData.leaders = data.leaders
      leadersLaggardsData.laggards = data.laggards
      tcfdStrategyDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS,
        data: leadersLaggardsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        tcfdStrategyDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS,
          apiCallId
        })
      }
    }
  }

  const getRegionsData = async (sectors, regions, isErrorReload = false) => {
    if (
      (tcfdStrategyState.portfolioRegions.data.summary.length > 0 &&
        tcfdStrategyState.portfolioRegions.data.details.length > 0 &&
        tcfdStrategyState.portfolioRegions.data.mapData.length > 0 &&
        tcfdStrategyState.portfolioSectors.data.summary.length > 0 &&
        tcfdStrategyState.portfolioSectors.data.details.length > 0) ||
      (tcfdStrategyState.portfolioRegions.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    tcfdStrategyDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_REGIONS
    })
    let regionsData = {
      summary: [],
      details: [],
      mapData: [],
      sectorsummary: [],
      sectordetails: []
    }

    try {
      const [summary, details, mapData, sectorsummary, sectordetails] =
        await axios.all([
          RegionService.getRegionSummary(
            filterState.globalPortfolioFilter.portfolio,
            TCFD_STGY.PAGE_URL,
            regions,
            sectors,
            filterState.globalPortfolioFilter.asOfDate.slice(4),
            filterState.globalPortfolioFilter.asOfDate.slice(0, 4),
            filterState.globalPortfolioFilter.benchmark,
            tcfdStrategyState.cancelToken.token
          ),
          axios.post(
            COMMON.BASE_URL +
              filterState.globalPortfolioFilter.portfolio +
              TCFD_STGY.PAGE_URL +
              COMMON.REGION_DETAILS,
            {
              [PARAMS.REGION]: regions,
              [PARAMS.SECTOR]: sectors,
              [PARAMS.MONTH]:
                filterState.globalPortfolioFilter.asOfDate.slice(4),
              [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(
                0,
                4
              )
            },
            { cancelToken: tcfdStrategyState.cancelToken.token }
          ),
          axios.post(
            COMMON.BASE_URL +
              filterState.globalPortfolioFilter.portfolio +
              TCFD_STGY.PAGE_URL +
              COMMON.MAP,
            {
              [PARAMS.REGION]: regions,
              [PARAMS.SECTOR]: sectors,
              [PARAMS.MONTH]:
                filterState.globalPortfolioFilter.asOfDate.slice(4),
              [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(
                0,
                4
              )
            },
            { cancelToken: tcfdStrategyState.cancelToken.token }
          ),
          axios.post(
            COMMON.BASE_URL +
              filterState.globalPortfolioFilter.portfolio +
              TCFD_STGY.PAGE_URL +
              COMMON.SECTOR_SUMMARY,
            {
              [PARAMS.REGION]: regions,
              [PARAMS.SECTOR]: sectors,
              [PARAMS.MONTH]:
                filterState.globalPortfolioFilter.asOfDate.slice(4),
              [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(
                0,
                4
              ),
              [PARAMS.BENCHMARK_ID]: filterState.globalPortfolioFilter.benchmark
            },
            { cancelToken: tcfdStrategyState.cancelToken.token }
          ),
          axios.post(
            COMMON.BASE_URL +
              filterState.globalPortfolioFilter.portfolio +
              TCFD_STGY.PAGE_URL +
              COMMON.SECTOR_DETAILS,
            {
              [PARAMS.REGION]: regions,
              [PARAMS.SECTOR]: sectors,
              [PARAMS.MONTH]:
                filterState.globalPortfolioFilter.asOfDate.slice(4),
              [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(
                0,
                4
              )
            },
            { cancelToken: tcfdStrategyState.cancelToken.token }
          )
        ])
      regionsData.summary = summary.data
      regionsData.details = details.data
      regionsData.summary = summary.data
      regionsData.sectorsummary = sectorsummary.data
      regionsData.sectordetails = sectordetails.data

      mapData.data
        .filter(
          (data) => !isNaN(data.score) && data.score >= 0 && data.score <= 100
        )
        .map((mdata) => {
          return regionsData.mapData.push([
            mdata.country_code,
            mdata.score,
            mdata.score_category
          ])
        })

      tcfdStrategyDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_REGIONS,
        data: regionsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        tcfdStrategyDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_REGIONS,
          apiCallId
        })
      } else console.log(err)
    }
  }

  const getUpdatesData = async (sectors, regions, isErrorReload = false) => {
    if (
      tcfdStrategyState.portfolioUpdates.data.length > 0 ||
      (tcfdStrategyState.portfolioUpdates.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    tcfdStrategyDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_UPDATES
    })
    let updatesData = []
    try {
      const { data } = PortfolioAnalysisService.getUpdates(
        filterState.globalPortfolioFilter.portfolio,
        TCFD_STGY.PAGE_URL,
        regions,
        sectors,
        filterState.globalPortfolioFilter.asOfDate.slice(4),
        filterState.globalPortfolioFilter.asOfDate.slice(0, 4),
        tcfdStrategyState.cancelToken.token
      )

      updatesData = data

      tcfdStrategyDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_UPDATES,
        data: updatesData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        tcfdStrategyDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_UPDATES,
          apiCallId
        })
      }
    }
  }

  const getImpactCall = (filter, regions, sectors, month, year) => {
    return axios.post(
      COMMON.BASE_URL +
        filterState.globalPortfolioFilter.portfolio +
        TCFD_STGY.PAGE_URL +
        COMMON.IMPACT,
      {
        [PARAMS.IMPACT_FILTER]: filter,
        [PARAMS.REGION]: regions,
        [PARAMS.SECTOR]: sectors,
        [PARAMS.MONTH]: month,
        [PARAMS.YEAR]: year
      },
      { cancelToken: tcfdStrategyState.cancelToken.token }
    )
  }

  const getImpactData = async (sectors, regions, isErrorReload = false) => {
    if (
      (tcfdStrategyState.portfolioImpact.data &&
        tcfdStrategyState.portfolioImpact.data.length > 0) ||
      (tcfdStrategyState.portfolioImpact.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    tcfdStrategyDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_IMPACT
    })
    let impactData = {}
    try {
      const month = filterState.globalPortfolioFilter.asOfDate.slice(4)
      const year = filterState.globalPortfolioFilter.asOfDate.slice(0, 4)

      const [top5, top10, bottom5, bottom10] = await axios.all([
        getImpactCall(PAGE_CON.IMPACT_KEY_TOP_5, regions, sectors, month, year),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_TOP_10,
          regions,
          sectors,
          month,
          year
        ),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_BOTTOM_5,
          regions,
          sectors,
          month,
          year
        ),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_BOTTOM_10,
          regions,
          sectors,
          month,
          year
        )
      ])

      impactData[PAGE_CON.IMPACT_KEY_TOP_5] = top5.data[0]
      impactData[PAGE_CON.IMPACT_KEY_TOP_10] = top10.data[0]
      impactData[PAGE_CON.IMPACT_KEY_BOTTOM_5] = bottom5.data[0]
      impactData[PAGE_CON.IMPACT_KEY_BOTTOM_10] = bottom10.data[0]

      tcfdStrategyDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_IMPACT,
        data: impactData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        tcfdStrategyDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_IMPACT,
          apiCallId
        })
      }
    }
  }

  const getUnderlyingDataMetrics = async (
    sectors,
    regions,
    isErrorReload = false
  ) => {
    if (
      Object.keys(tcfdStrategyState.portfolioUnderlyingDataMetrics.data)
        .length > 0 ||
      (tcfdStrategyState.portfolioUnderlyingDataMetrics.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    tcfdStrategyDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS
    })
    let metricsData = []
    try {
      const { data } = await axios.post(
        COMMON.BASE_URL +
          filterState.globalPortfolioFilter.portfolio +
          TCFD_STGY.PAGE_URL +
          COMMON.DATA_METRICS,
        {
          [PARAMS.REGION]: regions,
          [PARAMS.SECTOR]: sectors,
          [PARAMS.MONTH]: filterState.globalPortfolioFilter.asOfDate.slice(4),
          [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(0, 4)
        },
        { cancelToken: tcfdStrategyState.cancelToken.token }
      )

      metricsData = data
      tcfdStrategyDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS,
        data: metricsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        tcfdStrategyDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS,
          apiCallId
        })
      }
    }
  }

  const reloadComponent = (errorOccuredOn) => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    switch (errorOccuredOn) {
      case PAGE_CON.LEADERS_TITLE:
      case PAGE_CON.LAGGARDS_TITLE:
        getLeadersLaggardsData(sectors, regions, true)
        break
      case PAGE_CON.REGIONS_TITLE:
        getRegionsData(sectors, regions, true)
        break
      case PAGE_CON.UPDATESIN_TITLE:
        getUpdatesData(sectors, regions, true)
        break
      case PAGE_CON.TCFD_TRN_MGM_TITLE:
        getUnderlyingDataMetrics(sectors, regions, true)
        break
      case PAGE_CON.IMPACT_TITLE:
        getImpactData(sectors, regions, true)
        break
      default:
        break
    }
  }

  const gettcfdStrategyData = useCallback(async () => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    if (filters.portfolio !== '' && !filterState.isLoading) {
      getLeadersLaggardsData(sectors, regions)
      getRegionsData(sectors, regions)
      getUpdatesData(sectors, regions)
      getImpactData(sectors, regions)
      getUnderlyingDataMetrics(sectors, regions)
    }
  }, [filterState.actionData])

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      gettcfdStrategyData()
    }
  }, [filterState.actionData])

  function selectedCard(selectCardInfo) {
    setSelectedCardId(selectCardInfo.cardId)
  }

  const tcfdStrategyMapData =
    tcfdStrategyState.portfolioRegions.data &&
    tcfdStrategyState.portfolioRegions.data.mapData &&
    tcfdStrategyState.portfolioRegions.data.mapData.map((item) => {
      return {
        'hc-key': item[0] || '-',
        value: item[1] !== undefined ? item[1] : '-',
        ScoreCategoryScore: item[1] !== undefined ? item[1] : '-',
        category: item[2] || '-'
      }
    })

  const getPositiveBarData = () => {
    const positiveData = [
      {
        name: 'total',
        data: [],
        opacity: 0.6
      },
      {
        name: 'selection',
        data: [],
        opacity: 1
      }
    ]
    tcfdStrategyState.portfolioImpact.data[
      tcfdStrategyState.portfolioImpact.filter
    ].positive_impact.distribution.map((item) => {
      positiveData[0].data.push(item.total - item.selection)
      positiveData[1].data.push(item.selection)
      return ''
    })

    return positiveData
  }

  const getPositiveBarColor = () => {
    const positiveColor = []

    tcfdStrategyState.portfolioImpact.data[
      tcfdStrategyState.portfolioImpact.filter
    ].positive_impact.distribution.map((item) => {
      positiveColor.push(getColor('CATEGORY', item.category))
      return ''
    })

    return positiveColor
  }

  const getNegativeBarData = () => {
    const negativeData = [
      {
        name: 'total',
        data: [],
        opacity: 0.6
      },
      {
        name: 'selection',
        data: [],
        opacity: 1
      }
    ]
    tcfdStrategyState.portfolioImpact.data[
      tcfdStrategyState.portfolioImpact.filter
    ].negative_impact.distribution.map((item) => {
      negativeData[0].data.push(item.total - item.selection)
      negativeData[1].data.push(item.selection)
      return ''
    })

    return negativeData
  }

  const getNegativeBarColor = () => {
    const negativeColor = []

    tcfdStrategyState.portfolioImpact.data[
      tcfdStrategyState.portfolioImpact.filter
    ].negative_impact.distribution.map((item) => {
      negativeColor.push(getColor('CATEGORY', item.category))
      return ''
    })

    return negativeColor
  }

  return (
    <Page className={classes.root} title={PAGE_CON.PHY_OVE_TEXT_TITLE}>
      <main className={classes.content}>
        <Container className={classes.container} maxWidth={false}>
          <Grid item container>
            <Grid item xs={12}>
              <TcfdStrategyOverview
                pageTitle={props.pageHeaderTitle}
                showBenchMark={showBenchMark}
              />
            </Grid>

            <Grid container>
              <a
                href={'#' + PAGE_CON.UPDATES_AND_CURRENT_LEADERS_LAGGARDS}
                style={overviewLinkPadding}
              >
                <IconArrowDown />
                <span style={linkStyle}>
                  {PAGE_CON.UPDATESIN_TITLE} {monthYear},{' '}
                  {PAGE_CON.IMPACT_TITLE}, and Current {PAGE_CON.LEADERS_TITLE}/
                  {PAGE_CON.LAGGARDS_TITLE}
                </span>
              </a>
            </Grid>

            <Grid item container>
              <AppBar
                id={props.id}
                position="sticky"
                className={classes.stickyHeader}
                style={{ top: '30px' }}
              >
                <div
                  id={PAGE_CON.UPDATES_AND_CURRENT_LEADERS_LAGGARDS}
                  style={{ paddingTop: '90px' }}
                >
                  <div className={classes.tableTitle} style={updatesStyle}>
                    {' '}
                    {PAGE_CON.UPDATESIN_TITLE} {monthYear},{' '}
                    {PAGE_CON.IMPACT_TITLE}, and Current{' '}
                    {PAGE_CON.LEADERS_TITLE}/{PAGE_CON.LAGGARDS_TITLE}{' '}
                  </div>
                </div>
              </AppBar>
              <Grid item xs={12} style={UpdatesTableStyle}>
                <h6 style={cardHeader}>Updates</h6>
                {tcfdStrategyState.portfolioUpdates.isLoading ? (
                  <LoadingMask />
                ) : tcfdStrategyState.portfolioUpdates.dataStatus ===
                    PAGE_CON.ERROR ||
                  (tcfdStrategyState.portfolioUpdates.dataStatus ===
                    PAGE_CON.SUCCESS &&
                    tcfdStrategyState.portfolioUpdates.data.length === 0) ? (
                  <Error
                    id={'updatesError'}
                    dataStatus={tcfdStrategyState.portfolioUpdates.dataStatus}
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.UPDATESIN_TITLE}
                    topMargin="5px"
                  />
                ) : (
                  <Updates
                    colInfo={UpdatesTableColInfo}
                    tableLength={10}
                    panelColInfo={PanelUpdatesTableColInfo}
                    totalData={tcfdStrategyState.portfolioUpdates.data}
                    tableEditable={false}
                    monthYear={monthYear}
                  />
                )}
              </Grid>
              <Grid
                item
                container
                className={classes.impactContainerWrapper}
                style={{ paddingLeft: '16px' }}
              >
                {tcfdStrategyState.portfolioImpact.isLoading ? (
                  <LoadingMask />
                ) : tcfdStrategyState.portfolioImpact.dataStatus ===
                    PAGE_CON.ERROR ||
                  (tcfdStrategyState.portfolioImpact.dataStatus ===
                    PAGE_CON.SUCCESS &&
                    tcfdStrategyState.portfolioImpact.data.length === 0) ? (
                  <Error
                    id={'impactError'}
                    dataStatus={tcfdStrategyState.portfolioImpact.dataStatus}
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.IMPACT_TITLE}
                    topMargin="5px"
                  />
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      className={classes.impactDropdown}
                    >
                      <span
                        style={{
                          backgroundColor:
                            themeColorPalette.palette.sys_trad.selected2.main,
                          marginBottom: '12px',
                          paddingTop: 4
                        }}
                      >
                        <DropdownField
                          id="impact-filter"
                          dropdownDefaultSelction={
                            tcfdStrategyState.portfolioImpact.filter
                          }
                          saveValueUsingExternalState={true}
                          dropdownOptions={filterOptions}
                          onChange={onImpactFilterChange}
                          labelTextDisabled={true}
                          labelText=""
                          alternateLabelText="Top 5"
                          defaultData={''}
                          impactColor={
                            themeColorPalette.palette.sys_trad.selected2.main
                          }
                        />
                      </span>
                    </Grid>
                    <Impact
                      positiveImpactData={
                        tcfdStrategyState.portfolioImpact.data[
                          tcfdStrategyState.portfolioImpact.filter
                        ].positive_impact.investment_and_score
                      }
                      posBarChartData={getPositiveBarData()}
                      posBarColors={getPositiveBarColor()}
                      posBarTitle={`${
                        filterOptions.find(
                          (item) =>
                            item.key ===
                            tcfdStrategyState.portfolioImpact.filter
                        ).displayText
                      } vs. Total Investment %`}
                      negativeImpactData={
                        tcfdStrategyState.portfolioImpact.data[
                          tcfdStrategyState.portfolioImpact.filter
                        ].negative_impact.investment_and_score
                      }
                      negBarChartData={getNegativeBarData()}
                      negBarColors={getNegativeBarColor()}
                      negBarTitle={`${
                        filterOptions.find(
                          (item) =>
                            item.key ===
                            tcfdStrategyState.portfolioImpact.filter
                        ).displayText
                      } vs. Total Investment %`}
                      impactColumns={impactColumns}
                      impactFilter={tcfdStrategyState.portfolioImpact.filter}
                    />
                    <Grid />
                  </>
                )}
              </Grid>

              <Grid item container className={classes.containerWrapper}>
                <Grid item xs={12} sm={6} style={{ paddingRight: '12px' }}>
                  <h6 style={cardHeader}>
                    {PAGE_CON.PORTFOLIO_LEADERS_BY_SCORE}
                  </h6>
                  {tcfdStrategyState.portfolioLeaderLaggard.isLoading ? (
                    <LoadingMask />
                  ) : tcfdStrategyState.portfolioLeaderLaggard.dataStatus ===
                      PAGE_CON.ERROR ||
                    (tcfdStrategyState.portfolioLeaderLaggard.dataStatus ===
                      PAGE_CON.SUCCESS &&
                      tcfdStrategyState.portfolioLeaderLaggard.data.leaders &&
                      tcfdStrategyState.portfolioLeaderLaggard.data.leaders
                        .length === 0) ? (
                    <Error
                      id={'leadersError'}
                      dataStatus={
                        tcfdStrategyState.portfolioLeaderLaggard.dataStatus
                      }
                      onErrorClick={reloadComponent}
                      errorOccuredOn={PAGE_CON.LEADERS_TITLE}
                      topMargin="20px"
                    />
                  ) : (
                    <Leaders
                      colInfo={LeaderLaggardColInfo}
                      totalData={
                        tcfdStrategyState.portfolioLeaderLaggard.data.leaders
                      }
                      tableLength={tableLength}
                      tableEditable={false}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingLeft: '12px' }}>
                  <h6 style={cardHeader}>
                    {PAGE_CON.PORTFOLIO_LAGGARDS_BY_SCORE}
                  </h6>
                  {tcfdStrategyState.portfolioLeaderLaggard.isLoading ? (
                    <LoadingMask />
                  ) : tcfdStrategyState.portfolioLeaderLaggard.dataStatus ===
                      PAGE_CON.ERROR ||
                    (tcfdStrategyState.portfolioLeaderLaggard.dataStatus ===
                      PAGE_CON.SUCCESS &&
                      tcfdStrategyState.portfolioLeaderLaggard.data.laggards &&
                      tcfdStrategyState.portfolioLeaderLaggard.data.laggards
                        .length === 0) ? (
                    <Error
                      id={'laggardsError'}
                      dataStatus={
                        tcfdStrategyState.portfolioLeaderLaggard.dataStatus
                      }
                      onErrorClick={reloadComponent}
                      errorOccuredOn={PAGE_CON.LAGGARDS_TITLE}
                    />
                  ) : (
                    <Laggards
                      colInfo={LeaderLaggardColInfo}
                      totalData={
                        tcfdStrategyState.portfolioLeaderLaggard.data.laggards
                      }
                      tableLength={tableLength}
                      tableEditable={false}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item container>
              <a
                href={'#' + PAGE_CON.REGIONS_INDUSTRIES}
                style={linkPaddingRegionIndustry}
              >
                <IconArrowDown />
                <span style={linkStyle}>
                  {PAGE_CON.REGIONS_TITLE} & {PAGE_CON.SECTORS_TITLE}
                </span>
              </a>{' '}
            </Grid>

            <Grid item container>
              <AppBar
                id={props.id}
                position="sticky"
                className={classes.stickyHeader}
                style={regionTitleStyle}
              >
                <div
                  id={PAGE_CON.REGIONS_INDUSTRIES}
                  style={{ paddingTop: '80px' }}
                >
                  <div
                    className={classes.tableTitle}
                    style={{ marginTop: '36px' }}
                  >
                    {' '}
                    {PAGE_CON.REGIONS_TITLE} & {PAGE_CON.SECTORS_TITLE}{' '}
                  </div>
                </div>
              </AppBar>
              <Grid item xs={12} style={regionStyle}>
                {tcfdStrategyState.portfolioRegions.isLoading ? (
                  <LoadingMask />
                ) : tcfdStrategyState.portfolioRegions.dataStatus ===
                    PAGE_CON.ERROR ||
                  (tcfdStrategyState.portfolioRegions.dataStatus ===
                    PAGE_CON.SUCCESS &&
                    !isRegionHasData()) ? (
                  <Error
                    id={'regionsError'}
                    dataStatus={tcfdStrategyState.portfolioRegions.dataStatus}
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.REGIONS_TITLE}
                  />
                ) : (
                  <Regions
                    hideMap={true}
                    key={'RegionKey'}
                    data={tcfdStrategyState.portfolioRegions.data}
                    displayRegions={[]}
                    selectedCardId={selectedCardId}
                    onRegionSelect={selectedCard}
                    mimTabColInfo={mimTabColInfo}
                    panelTabColInfo={panelTabColInfo}
                    cardInfoContentInfo={cardInfoContentInfo}
                    cardInfoContentInfoSector={cardInfoContentInfoSector}
                    currentPageState={'globalTcfdStrategy'}
                    mapData={tcfdStrategyMapData}
                    mapDataClasses={mapDataClasses}
                    stateData={true}
                    height={
                      tcfdStrategyState.portfolioRegions.data &&
                      tcfdStrategyState.portfolioRegions.data.summary.length ===
                        1
                        ? '50%'
                        : tcfdStrategyState.portfolioRegions.data &&
                          tcfdStrategyState.portfolioRegions.data.summary
                            .length === 4
                        ? '139.5%'
                        : tcfdStrategyState.portfolioRegions.data &&
                          tcfdStrategyState.portfolioRegions.data.summary
                            .length > 4
                        ? '179.5%'
                        : tcfdStrategyState.portfolioRegions.data &&
                          tcfdStrategyState.portfolioRegions.data.summary
                            .length === 3
                        ? '105%'
                        : '100%'
                    }
                  />
                )}
              </Grid>
            </Grid>

            <Grid item container>
              <a href={'#' + PAGE_CON.TCFD_DISCLOSURES} style={linkPadding}>
                <IconArrowDown />
                <span style={linkStyle}>
                  {PAGE_CON.TCFD_DISCLOSURES_TITLE}{' '}
                </span>
              </a>
            </Grid>

            <Grid item container>
              <AppBar
                id={props.id}
                position="sticky"
                className={classes.stickyHeader}
                style={{ top: '35px' }}
              >
                <div
                  id={PAGE_CON.TCFD_DISCLOSURES}
                  style={{ paddingTop: '83px' }}
                >
                  <div className={classes.tableTitle} style={dataMetricTitle}>
                    {' '}
                    {PAGE_CON.TCFD_DISCLOSURES_TITLE} <br />{' '}
                  </div>
                </div>
              </AppBar>
              <Grid item xs={12} style={dataMetricbox}>
                {tcfdStrategyState.portfolioUnderlyingDataMetrics.isLoading ? (
                  <LoadingMask />
                ) : tcfdStrategyState.portfolioUnderlyingDataMetrics
                    .dataStatus === PAGE_CON.ERROR ||
                  (tcfdStrategyState.portfolioUnderlyingDataMetrics
                    .dataStatus === PAGE_CON.SUCCESS &&
                    tcfdStrategyState.portfolioUnderlyingDataMetrics.data &&
                    tcfdStrategyState.portfolioUnderlyingDataMetrics.data
                      .length === 0) ? (
                  <Error
                    id={'udmError'}
                    dataStatus={
                      tcfdStrategyState.portfolioUnderlyingDataMetrics
                        .dataStatus
                    }
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.TCFD_TRN_MGM_TITLE}
                  />
                ) : (
                  <TcfdStrategyUnderlyingDataMetrics
                    pageTitle={'TCFD Disclosures'}
                    underlyingDataMetrics={
                      tcfdStrategyState.portfolioUnderlyingDataMetrics.data
                    }
                    dataStatus={
                      tcfdStrategyState.portfolioUnderlyingDataMetrics
                        .dataStatus
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </Page>
  )
}

export default TcfdStrategy
