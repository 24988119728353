import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import themeColorPalette from 'theme/theme'
import { PrimaryLink } from 'components'
import { FEATURE_FLAGS, PAGE_CON } from 'constant/constants'
import * as S from './styles'

const MaterialityDriverFilter = (props) => {
  let classes = S.useStyles(props)
  const { id, onClick, methodology, entitlements, isCompanyHasOnDemand } = props
  const [selectedItem, setSelectedItem] = useState({
    materiality: PAGE_CON.ESG_DOUBLE_MATERIALITY,
    esgOption: 'ESG'
  })

  const onMaterialityClick = (itemClicked) => {
    if (selectedItem.materiality === itemClicked) return
    setSelectedItem((prev) => ({ ...prev, materiality: itemClicked }))
    if (onClick) {
      let esgOpt = []
      if (selectedItem.esgOption === 'ESG')
        esgOpt = ['Environmental', 'Social', 'Governance']
      else esgOpt.push(selectedItem.esgOption)
      onClick({ materiality: itemClicked, esgOption: esgOpt })
    }
  }

  const onEsgClick = (itemClicked) => {
    if (selectedItem.esgOption === itemClicked) return
    setSelectedItem((prev) => ({ ...prev, esgOption: itemClicked }))
    if (onClick) {
      let esgOpt = []
      if (itemClicked === 'ESG')
        esgOpt = ['Environmental', 'Social', 'Governance']
      else esgOpt.push(itemClicked)
      onClick({ materiality: selectedItem.materiality, esgOption: esgOpt })
    }
  }

  const showViewByOption = () => {
    let result = true
    if (FEATURE_FLAGS.ESG_SCORE_ENHANCEMENT) {
      result = !entitlements ? true : false
    }
    return result
  }

  return (
    <Box id={id} key={id} className={classes.root}>
      <Box key={'innerBox'} className={classes.root}>
        <span
          className={showViewByOption() ? classes.displayBox : classes.hideBox}
        >
          <Box marginRight={1} marginLeft={1} className={classes.labelStyle}>
            {'View by'}
          </Box>
          <Box key={'Holdings'} m={0} marginRight={1} marginLeft={1}>
            <PrimaryLink
              id="link-link-test-id-1"
              linkText={'Double Materiality'}
              onClick={() =>
                onMaterialityClick(PAGE_CON.ESG_DOUBLE_MATERIALITY)
              }
              linkClass={
                selectedItem.materiality === PAGE_CON.ESG_DOUBLE_MATERIALITY
                  ? 'linkMateriality'
                  : 'linkInline'
              }
              linkColorChange={true}
            />
          </Box>

          <Box m={0} marginRight={1} marginLeft={1}>
            {!(entitlements?.ondemandassessment && isCompanyHasOnDemand) &&
            methodology !== 'VE' ? (
              <PrimaryLink
                id="link-link-test-id-2"
                linkText={'Business Materiality'}
                onClick={() =>
                  onMaterialityClick(PAGE_CON.ESG_BUSINESS_MATERIALITY)
                }
                linkClass={
                  selectedItem.materiality === PAGE_CON.ESG_BUSINESS_MATERIALITY
                    ? 'linkMateriality'
                    : 'linkInline'
                }
                linkColorChange={true}
              />
            ) : (
              <span className={classes.labelStyle}>
                {'Business Materiality'}
              </span>
            )}
          </Box>
          <Box m={0} marginRight={1} marginLeft={1}>
            {!(entitlements?.ondemandassessment && isCompanyHasOnDemand) &&
            methodology !== 'VE' ? (
              <PrimaryLink
                id="link-link-test-id-3"
                linkText={'Stakeholder Materiality'}
                onClick={() =>
                  onMaterialityClick(PAGE_CON.ESG_STAKEHOLDER_MATERIALITY)
                }
                linkClass={
                  selectedItem.materiality ===
                  PAGE_CON.ESG_STAKEHOLDER_MATERIALITY
                    ? 'linkMateriality'
                    : 'linkInline'
                }
                linkColorChange={true}
              />
            ) : (
              <span className={classes.labelStyle}>
                {'Stakeholder Materiality'}
              </span>
            )}
          </Box>
          <Box
            marginRight={1}
            marginLeft={1}
            color={themeColorPalette.palette.sys_trad.border.main}
          >
            {'|'}
          </Box>
        </span>
        <Box m={0} marginRight={1} marginLeft={1}>
          <PrimaryLink
            id="link-link-test-id-4"
            linkText={'ESG'}
            onClick={() => onEsgClick('ESG')}
            linkClass={
              selectedItem.esgOption === 'ESG'
                ? 'linkMateriality'
                : 'linkInline'
            }
            linkColorChange={true}
          />
        </Box>
        <Box m={0} marginBottom={1} marginRight={1} marginLeft={1}>
          <PrimaryLink
            id="link-link-test-id-5"
            linkText={'Environmental'}
            onClick={() => onEsgClick('Environmental')}
            linkClass={
              selectedItem.esgOption === 'Environmental'
                ? 'linkMateriality'
                : 'linkInline'
            }
            linkColorChange={true}
          />
        </Box>
        <Box m={0} marginBottom={1} marginRight={1} marginLeft={1}>
          <PrimaryLink
            id="link-link-test-id-6"
            linkText={'Social'}
            onClick={() => onEsgClick('Social')}
            linkClass={
              selectedItem.esgOption === 'Social'
                ? 'linkMateriality'
                : 'linkInline'
            }
            linkColorChange={true}
          />
        </Box>
        <Box m={0} marginBottom={1} marginRight={1} marginLeft={1}>
          <PrimaryLink
            id="link-link-test-id-7"
            linkText={'Governance'}
            onClick={() => onEsgClick('Governance')}
            linkClass={
              selectedItem.esgOption === 'Governance'
                ? 'linkMateriality'
                : 'linkInline'
            }
            linkColorChange={true}
          />
        </Box>
      </Box>
    </Box>
  )
}

MaterialityDriverFilter.propTypes = {
  /** Link id */
  id: PropTypes.string,
  /** Gets called when the user clicks on the Link */
  onClick: PropTypes.func,
  /** methodology type like "mesg" or "ve" */
  methodology: PropTypes.string
}

export default MaterialityDriverFilter
