import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { LoginCallback, Security } from '@okta/okta-react'
import { toRelativeUrl } from '@okta/okta-auth-js'
import Login from './Login'
import Help from './Help'
import { oktaSignInConfig } from './config'
import TabLayout from '../../layouts/TabLayout/TabLayout'
import { oktaAuthClient } from '../../services/authentication'

const AppWithRouterAccess = () => {
  const history = useHistory()

  const customAuthHandler = () => {
    history.push('/login')
  }

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }

  const onAuthResume = async () => {
    history.push('/login')
  }

  return (
    <Security
      onAuthRequired={customAuthHandler}
      oktaAuth={oktaAuthClient}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Switch>
        <Route
          exact
          path="/login"
          render={() => <Login config={oktaSignInConfig} />}
        />
        <Route
          path="/callback"
          render={(props) => (
            <LoginCallback {...props} onAuthResume={onAuthResume} />
          )}
        />
        <Route path="/help" render={(props) => <Help {...props} />} />
        <Route path="/" render={() => <TabLayout />}></Route>
      </Switch>
    </Security>
  )
}

export default AppWithRouterAccess
