import { ErrorComponent } from 'components'
import { PAGE_CON } from 'constant/constants'
import getColor from 'utils/color'
import PropTypes from 'prop-types'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableCellBar from '../tablecells/tablecellbar/TableCellBar'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableNumberCell from '../tablecellformats/tablenumbercell/TableNumberCell'
import TableRow from '@material-ui/core/TableRow'
import textTheme from 'theme/theme'
import { bgColor, cellRowHeight, useStyles } from './styles'

function getCellBgColor(field, dataRow) {
  let color = bgColor
  if (
    field.cellBackgroundColor &&
    field.cellBackgroundColor.colorGroup &&
    field.cellBackgroundColor.colorDerivedByField
  ) {
    color = getColor(
      field.cellBackgroundColor.colorGroup,
      dataRow[field.cellBackgroundColor.colorDerivedByField],
      field.cellBackgroundColor.greenshare
    )
  }

  return color
}

function getDegreeString(data) {
  data = data.toString() + '\xB0C'
  return data
}

function getCellTextColor(field, dataRow) {
  let color = textTheme.palette.sys_trad.text.main
  if (field.cellTextColor && field.cellTextColor.colorGroup) {
    let val = ''
    if (
      field.cellTextColor.colorDerivedByField &&
      field.cellTextColor.colorDerivedByField !== ''
    ) {
      val = dataRow[field.cellTextColor.colorDerivedByField]
    }
    color = getColor(field.cellTextColor.colorGroup, val)
  }

  return color
}

function getBarColor(field, dataRow) {
  let color = bgColor
  if (
    field.barColor &&
    field.barColor.colorGroup &&
    field.barColor.colorDerivedByField
  ) {
    color = getColor(
      field.barColor.colorGroup,
      dataRow[field.barColor.colorDerivedByField],
      field.barColor.greenshare
    )
  }

  return color
}

/**
 * @visibleName Minimal Table
 */
const MinimalTable = ({
  id,
  hideHeaderBar,
  hideBenchmark,
  columns,
  data,
  cellBarColor,
  ...props
}) => {
  const classes = useStyles(props)

  return (
    <span>
      {data && data.length > 0 ? (
        <TableContainer>
          <Table id={id} className={classes.table}>
            {!hideHeaderBar && columns && columns.length > 0 && (
              <TableHead>
                <TableRow key="headrow1">
                  {columns
                    .filter((col) => col.HideColumn !== true)
                    .map((column, hIndex) => {
                      let colLabel = column.HideLabel ? '' : column.HeaderLabel
                      let colAlign = column.Alignment
                        ? column.Alignment
                        : 'left'
                      let columnSpan = column.DataField.length

                      return (
                        <TableCell
                          className={classes.headerCell}
                          align={colAlign}
                          colSpan={columnSpan}
                          key={`headrowcell${hIndex}`}
                        >
                          {colLabel}
                        </TableCell>
                      )
                    })}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {data.map((row, rowIndex) => (
                <TableRow key={`bodyrow${rowIndex}`}>
                  {columns
                    .filter((col) => col.HideColumn !== true)
                    .map((column, colIndex) =>
                      column.DataField.map((field, findex) => {
                        let data = row[field.Name]
                        let cellAlignment = field.Alignment
                          ? field.Alignment
                          : 'right'
                        let cellBackgroundColor = getCellBgColor(field, row)
                        let cellTextColor = getCellTextColor(field, row)
                        let barColor = cellBarColor
                          ? cellBarColor
                          : getBarColor(field, row)
                        let cellWidth = field.width ? field.width : 'inherit'
                        let cellRadius = field.cellRadius ? field.cellRadius : 0
                        let numberType = field.NumberType ? true : false
                        let numberSpec = field.NumberType && field.NumberType
                        let benchmarkValue = row.benchmarkPct
                        let cellHeight = field.height ? field.height : 'auto'
                        data = field.isDegreeString
                          ? getDegreeString(data)
                          : data

                        if (
                          field.NumberType &&
                          field.NumberType['unitsRequired']
                        ) {
                          field.NumberType['units'] = row.units
                        }

                        return data !== 0 && !data ? (
                          <TableCell
                            className={classes.rowCell}
                            align={`${cellAlignment}`}
                            key={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                            style={
                              field.noWrap
                                ? { whiteSpace: 'nowrap', width: cellWidth }
                                : { width: cellWidth }
                            }
                          >
                            {'-'}
                          </TableCell>
                        ) : field.showBar ? (
                          <TableCellBar
                            barValue={data}
                            key={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                            bgColor={bgColor}
                            cellHeight={cellRowHeight}
                            cellWidth={cellWidth}
                            barColor={barColor}
                            benchmarkValue={benchmarkValue}
                            hideBenchmark={hideBenchmark}
                            barCorner={props.barCorner}
                            ESGAssessment
                          />
                        ) : numberType ? (
                          <TableNumberCell
                            key={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                            rowdata={Number(data)}
                            numberSpecs={numberSpec}
                            cellclass={classes.rowCell}
                            cellWidth={cellWidth}
                            backgroundColor={cellBackgroundColor}
                            borderRadius={cellRadius}
                          />
                        ) : (
                          <TableCell
                            className={classes.rowCell}
                            align={`${cellAlignment}`}
                            key={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                            id={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                            style={{
                              minWidth: cellWidth,
                              color: cellTextColor,
                              backgroundColor: cellBackgroundColor,
                              borderRadius: cellRadius,
                              height: cellHeight
                            }}
                          >
                            {data}
                          </TableCell>
                        )
                      })
                    )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <ErrorComponent id={'updatesError'} dataStatus={PAGE_CON.SUCCESS} />
      )}
    </span>
  )
}

MinimalTable.propTypes = {
  /** Minimal Table id */
  id: PropTypes.string,
  /** Hide Column header */
  hideHeaderBar: PropTypes.bool,
  /** Column header and data field details */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      HeaderLabel: PropTypes.string,
      HideLabel: PropTypes.bool,
      Alignment: PropTypes.string,
      DataField: PropTypes.arrayOf(
        PropTypes.shape({
          Name: PropTypes.string,
          Alignment: PropTypes.string,
          width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          cellRadius: PropTypes.number,
          showBar: PropTypes.bool,
          barColorFieldName: PropTypes.string,
          barColor: PropTypes.shape({
            colorGroup: PropTypes.string,
            colorDerivedByField: PropTypes.string
          }),
          cellBackgroundColor: PropTypes.shape({
            colorGroup: PropTypes.string,
            colorDerivedByField: PropTypes.string
          }),
          cellTextColor: PropTypes.shape({
            colorGroup: PropTypes.string,
            colorDerivedByField: PropTypes.string
          })
        })
      )
    })
  ),
  /** Data to be dispalyed in table */
  data: PropTypes.any,
  /** Max width for table */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Hide Benchmark */
  hideBenchmark: PropTypes.bool,
  /** Top Margin for table */
  tableMarginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default MinimalTable
