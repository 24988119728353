import React from 'react'
import PhysicalRisk from './PhysicalRisk'
import TransitionRisk from './TransitionRisk'
import EsgAssessment from './EsgAssessment'

const SummarySidePanel = ({ entitlements, onLinkClick }) => {
  return (
    <>
      {entitlements.PortfolioAnalysis.esg && (
        <EsgAssessment onClick={onLinkClick} entitlements={entitlements} />
      )}
      {entitlements.Dashboard.physicalrisk && (
        <PhysicalRisk onClick={onLinkClick} />
      )}
      {entitlements.Dashboard.transitionrisk && (
        <TransitionRisk onClick={onLinkClick} />
      )}
    </>
  )
}

export default SummarySidePanel
