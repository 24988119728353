import React from 'react'
import * as S from './styles'
import PropTypes from 'prop-types'

const CustomCell = ({ text = '', styles, children }) => {
  const style = text !== '' && styles !== undefined ? styles[text] : {}
  return <S.Container styles={style}>{children}</S.Container>
}

CustomCell.propTypes = {
  text: PropTypes.string,
  children: PropTypes.any,
  styles: PropTypes.object
}

export default CustomCell
