import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, Paper } from '@material-ui/core'
import clsx from 'clsx'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import AddMissingDocuments from './sector/AddMissingDocuments'
import Drivers from 'views/common/materiality/drivers/MaterialityDrivers'
import IssuerOverview from './IssuerOverview'
import Error from 'utils/error'
import LoadingMask from 'utils/loadingMask'
import numberData from 'utils/numberFormat'
import themeColorPalette from 'theme/theme'
import {
  IconRedirect,
  Page,
  PageHeader,
  PrimaryButton,
  PrimaryModal,
  Tabs
} from 'components'
import ListSourceDocument from './ListSourceDocument'
import { EntityService } from 'services'
import * as S from './styles'

const P3LandingPage = (props) => {
  const classes = S.useStyles()

  const [entityHeaderState, entityHeaderDispatch] =
    useContext(GlobalContext).globalEntityHeader
  const [issuerSourceDocumentState, issuerSourceDocumentDispatch] =
    // eslint-disable-next-line no-unsafe-optional-chaining
    useContext(GlobalContext)?.issuerSourceDocument
  const [entityState, entityDispatch] = useContext(GlobalContext).globalEntity
  const issuerUrlDispatch = useContext(GlobalContext).globalIssuerUrl[1]
  const [driverState, driverDispatch] =
    useContext(GlobalContext).globalSectorInfoState
  const [open, setOpen] = useState(false)
  const tabs = [
    { label: 'ESG Materiality', key: 'esgMateriality', active: true }
  ]

  const openMethodologyPage = () => {
    const sectorPath =
      entityHeaderState.globalEntityHeader.orbis_id &&
      entityHeaderState.globalEntityHeader.orbis_id !== ''
        ? '/sector'
        : '/'
    const newWindow = window.open(sectorPath, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const loadSourceDoc = async (isErrorReload = false) => {
    const isOrbisId =
      entityHeaderState?.globalEntityHeader?.orbis_id?.length > 0
    if (
      (isOrbisId && issuerSourceDocumentState?.dataStatus === '') ||
      (isOrbisId && isErrorReload === true)
    ) {
      try {
        issuerSourceDocumentDispatch({
          type: REDUCER_TYPE.LOAD_DATA_START
        })
        const list = await EntityService.getSourceDocuments()
        issuerSourceDocumentDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          SourceDocItems: { list }
        })
      } catch (err) {
        issuerSourceDocumentDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR
        })
      }
    }
  }

  const loadHeaderData = async (isErrorReload = false) => {
    if (entityHeaderState.dataStatus === '' || isErrorReload === true) {
      try {
        entityHeaderDispatch({ type: REDUCER_TYPE.LOAD_DATA_START })

        const data = await EntityService.getHeaderInfo()

        let company_name,
          country,
          lei,
          primary_isin,
          orbis_id,
          region,
          mesg_sector,
          sector_l2,
          overall_disclosure_score = ''

        if (Object.keys(data).length > 0) {
          company_name = data?.company_name || ''
          country = data?.country || ''
          lei = data?.lei || ''
          primary_isin = data?.primary_isin || ''
          orbis_id = data?.orbis_id || ''
          region = data?.region || ''
          mesg_sector = data?.mesg_sector || ''
          sector_l2 = data?.sector_l2 || ''
          overall_disclosure_score = data?.overall_disclosure_score || ''
        }

        entityHeaderDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          headerItems: {
            company_name,
            country,
            lei,
            primary_isin,
            orbis_id,
            region,
            mesg_sector,
            sector_l2,
            overall_disclosure_score
          }
        })
      } catch (err) {
        entityHeaderDispatch({ type: REDUCER_TYPE.LOAD_DATA_ERROR })
      }
    }
  }

  const loadDrivers = async (isErrorReload = false) => {
    if (
      entityState?.entityDrivers?.dataStatus !== '' &&
      isErrorReload === false
    )
      return

    entityDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.ENTITY_DRIVER
    })
    let driverData = {}
    try {
      driverData = await EntityService.getDriverSummary()

      entityDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.ENTITY_DRIVER,
        data: driverData
      })
    } catch (err) {
      entityDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        sectionName: REDUCER_STATE.ENTITY_DRIVER
      })
    }
  }

  const loadDriverCriteriaData = async (isErrorReload = false) => {
    if (
      entityHeaderState?.globalEntityHeader?.orbis_id ||
      driverState.dataStatus === '' ||
      isErrorReload === true
    ) {
      driverDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName: REDUCER_STATE.DRIVER_DATA
      })
      try {
        driverDispatch({
          type: REDUCER_TYPE.LOAD_DATA_START,
          sectionName: REDUCER_STATE.DRIVER_DATA
        })
        const data = await EntityService.getDriverCriteria()

        let driver_criteria = data?.driver_details || []

        driverDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          sectionName: REDUCER_STATE.DRIVER_DATA,
          data: { driver_criteria }
        })
      } catch (err) {
        driverDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.DRIVER_DATA
        })
      }
    }
  }

  useEffect(() => {
    loadHeaderData()
  }, [])
  useEffect(() => {
    loadDrivers()
    loadSourceDoc()
    loadDriverCriteriaData()
  }, [entityHeaderState?.globalEntityHeader?.orbis_id])

  const reloadComponent = (errorOccuredOn) => {
    switch (errorOccuredOn) {
      case PAGE_CON.ISSUER_HEADER:
        loadHeaderData(true)
        break
      case PAGE_CON.ENTITY_DRIVER_SUMMARY:
        loadDrivers(true)
        break
      case PAGE_CON.ISSUER_SOURCE_DOC:
        loadSourceDoc(true)
        break
      default:
        break
    }
  }

  const isDriverHasData = () => {
    let hasData = false
    if (entityState.entityDrivers?.data?.drivers?.length > 0) {
      hasData = true
    }
    return hasData
  }

  const onUploadUrlClickOpen = () => {
    issuerUrlDispatch({
      type: REDUCER_TYPE.RESET
    })
    setOpen(true)
  }

  const onUploadUrlClickClose = () => {
    issuerUrlDispatch({
      type: REDUCER_TYPE.RESET
    })
    setOpen(false)
  }

  return (
    <Page className={classes.root_p3_landing_page}>
      <PrimaryModal
        id="functionality-error-test-id"
        modalOpen={
          entityHeaderState.dataStatus === PAGE_CON.ERROR ? true : false
        }
        modalClass="errorModal"
        modalTitle=""
        modalContentComponent={
          <Error
            errorClass={'noMargin'}
            id={'issuerHeaderError'}
            topMargin={'0px'}
            dataStatus={PAGE_CON.ERROR}
            onErrorClick={() => loadDrivers(true)}
            errorOccuredOn={PAGE_CON.ENTITY_DRIVER_SUMMARY}
          />
        }
        modalNoCloseOnClick={true}
        showCloseIcon={false}
        onKeyDown={props.onKeyDown}
      />

      <Grid container spacing={3} style={{ margin: 0, width: 'auto' }}>
        <Grid item xs={12}>
          <Grid
            container
            direction="column"
            spacing={3}
            className={classes.parentGrid}
          >
            <Grid item>
              <Paper
                className={clsx(classes.paper, classes.header_p3_landing_page)}
              >
                <PageHeader
                  toolbarMarginTop="0px"
                  toolbarMarginLeft="0px"
                  pageHeaderMarginBottom="0px"
                  pageHeaderHeight="auto"
                  showPageHeaderBorder={false}
                  minWidth="100px"
                  pageHeaderContentComponent={
                    <Box style={{ width: '100%' }}>
                      {entityHeaderState.isLoading ||
                      entityHeaderState.dataStatus === PAGE_CON.ERROR ? (
                        <LoadingMask />
                      ) : (
                        <div className={classes.divContainer}>
                          <div>
                            <div className={classes.pageHeaderStyleTextTitle}>
                              {
                                entityHeaderState.globalEntityHeader
                                  .company_name
                              }
                            </div>
                            <div className={classes.subHeaderStyleText}>
                              <span>
                                {entityHeaderState.globalEntityHeader
                                  .primary_isin &&
                                  entityHeaderState.globalEntityHeader
                                    .primary_isin !== '' && (
                                    <span>
                                      {'ISIN: '}
                                      {
                                        entityHeaderState.globalEntityHeader
                                          .primary_isin
                                      }
                                      ,{' '}
                                    </span>
                                  )}
                                {entityHeaderState.globalEntityHeader.lei &&
                                  entityHeaderState.globalEntityHeader.lei !==
                                    '' && (
                                    <span>
                                      {'LEI: '}
                                      {entityHeaderState.globalEntityHeader.lei}
                                      ,{' '}
                                    </span>
                                  )}
                                <span>
                                  {'Orbis ID: '}
                                  {
                                    entityHeaderState.globalEntityHeader
                                      .orbis_id
                                  }{' '}
                                </span>
                                <span className={classes.pageSubHeader}>|</span>
                              </span>
                              <span
                                style={{
                                  display:
                                    entityHeaderState.globalEntityHeader
                                      ?.overall_disclosure_score &&
                                    entityHeaderState.globalEntityHeader
                                      ?.overall_disclosure_score !== ''
                                      ? 'inherit'
                                      : 'none'
                                }}
                              >
                                <span>
                                  {'Overall Disclosure Ratio: '}
                                  {numberData(
                                    Number(
                                      entityHeaderState.globalEntityHeader
                                        ?.overall_disclosure_score
                                    ),
                                    { isDecimal: false, isPercentage: true }
                                  )}
                                </span>
                                <span className={classes.pageSubHeader}>|</span>
                              </span>
                              <span
                                style={{
                                  display:
                                    entityHeaderState.globalEntityHeader
                                      ?.country !== ''
                                      ? 'inherit'
                                      : 'none'
                                }}
                              >
                                <span>
                                  {'Region: '}
                                  {entityHeaderState.globalEntityHeader.country}
                                </span>
                                <span className={classes.pageSubHeader}>
                                  {entityHeaderState.globalEntityHeader
                                    ?.sector !== ''
                                    ? '|'
                                    : ''}
                                </span>
                              </span>
                              <span
                                style={{
                                  display:
                                    entityHeaderState.globalEntityHeader
                                      ?.mesg_sector !== ''
                                      ? 'inherit'
                                      : 'none'
                                }}
                                aria-hidden={true}
                                onClick={openMethodologyPage}
                              >
                                <span className={classes.pageSubHeaderSector}>
                                  {'Industry: '}
                                  {
                                    entityHeaderState.globalEntityHeader
                                      .mesg_sector
                                  }
                                </span>
                                <IconRedirect
                                  fillcolor={
                                    themeColorPalette.palette.sys_trad.status
                                      .main
                                  }
                                  className={classes.redirectIcon}
                                />
                              </span>
                            </div>
                          </div>
                          <span className={classes.missingDocsContainer}>
                            <PrimaryButton
                              id="missing-docs-button"
                              buttonText="Add Information"
                              buttonClass="buttonAltPrimary"
                              buttonWidth="155px"
                              buttonHeight="36px"
                              onClick={onUploadUrlClickOpen}
                            ></PrimaryButton>
                          </span>
                        </div>
                      )}
                    </Box>
                  }
                  scrollWithPage={true}
                ></PageHeader>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <AddMissingDocuments
          open={open}
          onClose={onUploadUrlClickClose}
          onOpen={onUploadUrlClickOpen}
          showDisclosureYear={props.entitlements['issuerp3']}
        />
      </Grid>

      <IssuerOverview />

      <Grid
        container
        spacing={3}
        style={{ margin: '16px 0px 0px 0px', width: 'auto' }}
      >
        <Grid item xs={12}>
          <Grid
            container
            direction="column"
            spacing={3}
            className={classes.parentGrid}
          >
            <Grid item>
              <Paper className={clsx(classes.paper, classes.driverContainer)}>
                <div className={classes.drivers}>
                  {entityState?.entityDrivers?.isLoading ? (
                    <LoadingMask />
                  ) : entityState?.entityDrivers?.dataStatus ===
                      PAGE_CON.ERROR ||
                    (entityState?.entityDrivers?.dataStatus ===
                      PAGE_CON.SUCCESS &&
                      !isDriverHasData()) ? (
                    <div style={{ padding: '0px 10px 0px 10px' }}>
                      <Error
                        errorClass={'noMargin'}
                        id={'driverError'}
                        topMargin={'0px'}
                        dataStatus={entityState?.entityDrivers?.dataStatus}
                        onErrorClick={() =>
                          reloadComponent(PAGE_CON.ENTITY_DRIVER_SUMMARY)
                        }
                        errorOccuredOn={PAGE_CON.ENTITY_DRIVER_SUMMARY}
                      />
                    </div>
                  ) : (
                    <>
                      <Grid style={{ padding: '16px 12px 0' }}>
                        <Tabs type={'small'} tabs={tabs} />
                      </Grid>

                      <Grid item>
                        <Drivers
                          data={entityState?.entityDrivers}
                          isControversyEnabled={false}
                        />
                      </Grid>
                    </>
                  )}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ margin: 0, width: 'auto' }}>
        <Grid item xs={12}>
          <Grid
            container
            direction="column"
            spacing={3}
            className={classes.parentGrid}
          >
            <Grid item xs={5}>
              <Paper className={classes.documentsHeader}>
                All Source Documents
              </Paper>
              {issuerSourceDocumentState?.dataStatus === '' ||
              issuerSourceDocumentState?.isLoading ? (
                <LoadingMask />
              ) : (
                <div className={classes.card}>
                  <div className={classes.cardStacked}>
                    <div className={classes.cardReport}>
                      <ListSourceDocument
                        list={
                          issuerSourceDocumentState?.issuerSourceDocument?.list
                        }
                        dataStatus={issuerSourceDocumentState?.dataStatus}
                        reloadComponent={() =>
                          reloadComponent(PAGE_CON.ISSUER_SOURCE_DOC)
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  )
}

export default P3LandingPage
