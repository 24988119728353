import React from 'react'
import * as S from '../materialityComponents/index.jsx'
import * as Style from './styles'
import PropTypes from 'prop-types'
import themeColorPalette from 'theme/theme'
import { IconError, ProgressBar } from 'components'

const MaterialityItem = (props) => {
  const classes = Style.useStyles()
  const {
    title,
    subTitle,
    value,
    ratio,
    label,
    description,
    opacity,
    onItemClick,
    color,
    warning,
    showValue,
    companyScoredBy,
    empty,
    driverName,
    entitlements,
    isCompanyHasOnDemand
  } = props

  const onListItemClick = () => {
    !(entitlements?.ondemandassessment && isCompanyHasOnDemand) &&
      companyScoredBy !== 've' &&
      onItemClick({ ...props })
  }

  const blueColorCategegoryList = ['ADVANCED', 'ROBUST']

  const getContentForMateriality = (companyScoredBy) => {
    if (companyScoredBy === 've' || companyScoredBy === 'Self-Assessed') {
      return (
        <section className={classes.VEdescription} title={description}>
          <span className={classes.VEdescriptionTitleStyleWrapper}>
            {!!title && (
              <span
                className={
                  blueColorCategegoryList.includes(label?.toUpperCase()) ||
                  driverName === 'Low'
                    ? classes.titleStyleVE
                    : classes.titleWhiteStyleVE
                }
              >
                {title}
              </span>
            )}
            {(!!value || value === 0) && (
              <span
                className={
                  blueColorCategegoryList.includes(label?.toUpperCase()) ||
                  driverName === 'Low'
                    ? classes.titleStyleVE
                    : classes.titleWhiteStyleVE
                }
              >
                {value}
              </span>
            )}
          </span>
          {!!warning && <IconError className={classes.iconError} />}
        </section>
      )
    } else if (companyScoredBy === 'mesg') {
      return (
        <section className={classes.MESGContent} title={description}>
          {!!title && (
            <span
              className={
                blueColorCategegoryList.includes(label?.toUpperCase())
                  ? classes.titleStyle
                  : classes.titleWhiteStyle
              }
            >
              {title}
            </span>
          )}
          <span
            className={
              blueColorCategegoryList.includes(label?.toUpperCase())
                ? classes.subTitleStyleMESG
                : classes.subTitleWhiteStyleMESG
            }
          >
            {`Disclosure Ratio ${ratio}%`}
            <aside
              className={
                warning
                  ? classes.progressStyleWithWarning
                  : classes.progresStyleWithoutWarning
              }
            >
              <ProgressBar
                progressColour={
                  blueColorCategegoryList.includes(label?.toUpperCase())
                    ? themeColorPalette.palette.sys_trad.main.main
                    : themeColorPalette.palette.sys_trad.white.main
                }
                primaryColor={'rgba(255, 255, 255, 0.6)'}
                value={ratio}
                height={8}
                width="75px"
              />
              {!!warning && <IconError className={classes.MESGErrorIcon} />}
            </aside>
          </span>
        </section>
      )
    } else {
      return (
        <section className={classes.defaultItem} title={description}>
          <span className={classes.titleStyle}>{title}</span>
          <span className={classes.subTitleStyle}>
            {subTitle}
            {showValue ? `: ${label}` : ''}
          </span>
        </section>
      )
    }
  }

  const isItemClickable =
    !(entitlements?.ondemandassessment && isCompanyHasOnDemand) &&
    companyScoredBy !== 've'

  return (
    <>
      {empty ? (
        <S.ListItem
          clickable={false}
          height="45px"
          width="260px"
          itemColor={color}
          value={value}
          opacity={opacity}
          border={`1px solid ${themeColorPalette.palette.sys_trad.border.main}`}
        >
          <section title={description} className={classes.emptyDescription}>
            <span className={classes.extremeCaseMaterialityTitle}>
              None for the sector
            </span>
          </section>
        </S.ListItem>
      ) : (
        <S.ListItem
          clickable={isItemClickable}
          height="45px"
          width="260px"
          itemColor={
            companyScoredBy === 've' && driverName === 'Low'
              ? themeColorPalette.palette.white.main
              : color
          }
          value={value}
          opacity={opacity}
          onItemClick={onListItemClick}
        >
          {getContentForMateriality(companyScoredBy)}
        </S.ListItem>
      )}
    </>
  )
}

MaterialityItem.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  listType: PropTypes.string,
  opacity: PropTypes.any,
  Icon: PropTypes.any
}

export default MaterialityItem
