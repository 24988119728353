import { sectorOverviewLeft, sectorOverviewRight } from './sectorInfoData.json'
import { GlobalContext } from '../../../context/GlobalStateProvider'
import Grid from '@material-ui/core/Grid'
import React, { useContext } from 'react'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import { EntityService } from 'services'
import * as S from './styles'

const SectorInfo = (props) => {
  const classes = S.useStyles(props)

  const [entityHeaderState] = useContext(GlobalContext).globalEntityHeader
  const [driverState, driverDispatch] =
    useContext(GlobalContext).globalSectorInfoState

  const loadDriverCriteriaData = async (isErrorReload = false) => {
    if (driverState.driverData.dataStatus !== '' && isErrorReload === false)
      return
    driverDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.DRIVER_DATA
    })
    try {
      driverDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName: REDUCER_STATE.DRIVER_DATA
      })
      const data = await EntityService.getDriverCriteria()

      let driver_criteria = data?.driver_details || []
      let driver_count = data?.driver_count || 0
      let sector_peers = data?.sector_peers || []

      driverDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.DRIVER_DATA,
        data: { driver_criteria, driver_count, sector_peers }
      })
    } catch (err) {
      driverDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        sectionName: REDUCER_STATE.DRIVER_DATA
      })
    }
  }

  const getIssuerData = React.useCallback(async () => {
    if (
      entityHeaderState.globalEntityHeader.orbis_id !== '' &&
      !entityHeaderState.isLoading
    ) {
      loadDriverCriteriaData()
    }
  }, [entityHeaderState.globalEntityHeader.orbis_id])

  React.useEffect(() => {
    if (entityHeaderState.globalEntityHeader.orbis_id !== '') {
      getIssuerData()
    }
  }, [entityHeaderState.globalEntityHeader.orbis_id])

  const getTextInfo = (item) => {
    switch (item.title) {
      case PAGE_CON.SECTOR_ALLOCATION_TITLE: {
        const sectorAllocationInfo =
          entityHeaderState.globalEntityHeader.company_name +
          ' is assessed as part of the ' +
          entityHeaderState.globalEntityHeader.mesg_sector +
          `industry within the ${entityHeaderState.globalEntityHeader.sector_l2} sector. ${entityHeaderState.globalEntityHeader.mesg_sector_detail_description}`
        return sectorAllocationInfo
      }
      case PAGE_CON.SOURCES_OF_INFO_TITLE:
      case PAGE_CON.QUESTIONING_FRAMEWORK_TITLE:
      case PAGE_CON.GRADES_AND_SCORES_TITLE:
        return item.text
      case PAGE_CON.ASSESSMENT_FRAMEWORK_TITLE:
        return `Our ${entityHeaderState.globalEntityHeader.mesg_sector} industry framework is composed of categories and sub-categories. Each sub-category has been assigned a weight from low to very high depending on their level of materiality. Sub-categories identified as Very High will contribute most significantly to the overall score. Sub-categories identified as moderate have a lower level of materiality but still, contribute to the overall score of a company. More information on Moody’s ESG materiality methodology can be found in the methodology guide. `
      default:
        break
    }
  }

  return (
    <div className={classes.root_sector_info}>
      <div className={classes.headerTitle_sector_info}>
        Proposed ESG Score Methodology for{' '}
        {entityHeaderState.globalEntityHeader.mesg_sector}
      </div>
      <Grid id={props.id} container spacing={3} key={'0'}>
        <Grid item container direction="column" xs={12} sm={6} key={'1'}>
          {sectorOverviewLeft &&
            sectorOverviewLeft.length > 0 &&
            sectorOverviewLeft.map((item, index) => {
              return (
                <div key={`left-grid-${index}`}>
                  <Grid item className={classes.title} key={`left-${index}`}>
                    {item.title}
                  </Grid>
                  <Grid
                    item
                    className={classes.text_sector_info}
                    key={`left-text-${index}`}
                  >
                    {getTextInfo(item)}
                  </Grid>
                </div>
              )
            })}
        </Grid>
        <Grid item container direction="column" xs={12} sm={6} key={'2'}>
          {sectorOverviewRight &&
            sectorOverviewRight.length > 0 &&
            sectorOverviewRight.map((item, index) => {
              return (
                <div key={`right-grid-${index}`}>
                  <Grid item className={classes.title} key={`right-${index}`}>
                    {item.title}
                  </Grid>
                  <Grid
                    item
                    className={classes.text_sector_info}
                    key={`right-text-${index}`}
                  >
                    {getTextInfo(item)}
                  </Grid>
                </div>
              )
            })}
        </Grid>
      </Grid>
    </div>
  )
}

export default SectorInfo
