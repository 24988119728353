import { DashboardService, ResearchLineService } from 'services'
import { COMMON, ESG_ASMNT, PARAMS } from 'constant/APIconstants'
import axios from 'axios'
import { getMonthFromFilter, getYearFromFilter } from 'utils/Date'

export const getEsgMapData = async ({
  portfolio,
  regions,
  sectors,
  asOfDate,
  benchmark
}) => {
  return DashboardService.getMapData({
    portfolio,
    research_line: 'esgasmt',
    regions,
    sectors,
    asOfDate,
    benchmark
  })
}

export const getEsgEntityListData = async ({
  portfolio,
  regions,
  sectors,
  asOfDate,
  country_code
}) => {
  return ResearchLineService.getEntityList({
    portfolio,
    research_line: 'esgasmt',
    regions,
    sectors,
    asOfDate,
    country_code
  })
}

export const getEsgCoverage = async ({
  portfolio,
  regions,
  sectors,
  asOfDate
}) => {
  return DashboardService.getResearchLinesCoverage({
    portfolio,
    research_line: 'esgasmt',
    regions,
    sectors,
    asOfDate
  })
}

export const getLeadersLaggardsData = async ({
  portfolio,
  asOfDate,
  regions,
  sectors,
  token
}) => {
  const month = getMonthFromFilter(asOfDate)
  const year = getYearFromFilter(asOfDate)

  return await axios.post(
    COMMON.BASE_URL + portfolio + ESG_ASMNT.PAGE_URL + COMMON.LEADERS_LAGGARDS,
    {
      [PARAMS.REGION]: regions,
      [PARAMS.SECTOR]: sectors,
      [PARAMS.MONTH]: month,
      [PARAMS.YEAR]: year
    },
    { cancelToken: token }
  )
}

export const getCoverageSectorDetailsSectorSummary = async ({
  portfolio,
  asOfDate,
  benchmark,
  regions,
  sectors,
  token
}) => {
  return axios.all([
    ResearchLineService.retrieveResearchLineApi({
      portfolio,
      regions,
      sectors,
      asOfDate,
      benchmark,
      checkForEntitlements: false,
      isEntitled: false,
      researchLine: ESG_ASMNT.PAGE_URL,
      token,
      endPoint: COMMON.SECTOR_SUMMARY
    }),
    ResearchLineService.retrieveResearchLineApi({
      portfolio,
      regions,
      sectors,
      asOfDate,
      benchmark,
      checkForEntitlements: false,
      isEntitled: false,
      researchLine: ESG_ASMNT.PAGE_URL,
      token,
      endPoint: COMMON.SECTOR_DETAILS
    }),
    ResearchLineService.retrieveResearchLineApi({
      portfolio,
      regions,
      sectors,
      asOfDate,
      benchmark,
      checkForEntitlements: false,
      isEntitled: false,
      researchLine: ESG_ASMNT.PAGE_URL,
      token,
      endPoint: COMMON.COVERAGE
    })
  ])
}
