import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import { Donut } from 'components'
import Grid from '@material-ui/core/Grid'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: themeColorPalette.palette.sys_trad.border.main
  },
  sectionTitle: {
    ...textTheme.typography.p2,
    textDecoration: 'underline'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  grid: {
    ...textTheme.typography.p3
  }
}))

const TcfdStrategyUnderlyingDataMetrics = (props) => {
  const UnderlyingData = props.underlyingDataMetrics
  const pageTitle = props.pageTitle
  const classes = useStyles(props)
  const underlyingDataMetrics = React.useMemo(() => {
    return {
      data: UnderlyingData,
      pageTitle: pageTitle,
      donut: (
        <Grid className={classes.grid} item xs={12}>
          {UnderlyingData &&
            UnderlyingData.map((item, index) => {
              return (
                <span key={`${item + index}`}>
                  <Grid
                    container
                    className={classes.grid}
                    direction={'row'}
                    spacing={2}
                  >
                    <Grid item className={classes.grid} xs={4}>
                      <span className={classes.sectionTitle}></span>
                      <br />
                      {item.description}
                    </Grid>
                    <Grid className={classes.grid} item xs={8}>
                      <Grid
                        container
                        direction={'row'}
                        spacing={2}
                        className={classes.grid}
                      >
                        <Donut
                          donutTitle={index === 0 ? '% Investment' : '.'}
                          donutTitleMargin={10}
                          donutDataLabel={
                            item &&
                            item.data[0] &&
                            item.data[0].portfolio_coverage &&
                            item.data[0].portfolio_coverage.investment
                              ? Math.trunc(
                                  parseInt(
                                    item.data[0].portfolio_coverage.investment
                                  )
                                ) + '%'
                              : 'N/A'
                          }
                          donutChartDiameter="30px"
                          donutDataLabelXCoord={-90}
                          donutDataLabelYCoord={22}
                          donutData={[
                            [
                              'A',
                              item &&
                              item.data[0] &&
                              item.data[0].portfolio_coverage &&
                              item.data[0].portfolio_coverage.investment
                                ? 100 -
                                  Number(
                                    item.data[0].portfolio_coverage.investment
                                  )
                                : 10
                            ],
                            [
                              'B',
                              item &&
                              item.data[0] &&
                              item.data[0].portfolio_coverage &&
                              item.data[0].portfolio_coverage.investment
                                ? Number(
                                    item.data[0].portfolio_coverage.investment
                                  )
                                : 0
                            ]
                          ]}
                          donutChartHeight={90}
                          donutChartWidth={180}
                          donutChartPosition={['-5%', '0%']}
                        />
                        <Donut
                          donutTitle={
                            index === 0 ? 'Companies in Holdings' : '.'
                          }
                          donutTitleMargin={10}
                          donutDataLabel={
                            item &&
                            item.data[0] &&
                            item.data[0].portfolio_coverage &&
                            item.data[0].portfolio_coverage.companies
                              ? Number(
                                  item.data[0].portfolio_coverage.companies.split(
                                    '/'
                                  )[0]
                                ).toLocaleString() +
                                '/' +
                                Number(
                                  item.data[0].portfolio_coverage.companies.split(
                                    '/'
                                  )[1]
                                ).toLocaleString() +
                                ' companies'
                              : 'N/A'
                          }
                          donutChartDiameter="30px"
                          donutDataLabelXCoord={-100}
                          donutDataLabelYCoord={22}
                          donutData={[
                            [
                              'A',
                              item &&
                              item.data[0] &&
                              item.data[0].portfolio_coverage &&
                              item.data[0].portfolio_coverage.companies !==
                                '0/0'
                                ? 100 -
                                  (parseInt(
                                    item.data[0].portfolio_coverage.companies.split(
                                      '/'
                                    )[0]
                                  ) /
                                    parseInt(
                                      item.data[0].portfolio_coverage.companies.split(
                                        '/'
                                      )[1]
                                    )) *
                                    100
                                : 10
                            ],
                            [
                              'B',
                              item &&
                              item.data[0] &&
                              item.data[0].portfolio_coverage &&
                              item.data[0].portfolio_coverage.companies !==
                                '0/0'
                                ? (parseInt(
                                    item.data[0].portfolio_coverage.companies.split(
                                      '/'
                                    )[0]
                                  ) /
                                    parseInt(
                                      item.data[0].portfolio_coverage.companies.split(
                                        '/'
                                      )[1]
                                    )) *
                                  100
                                : 0
                            ]
                          ]}
                          donutChartHeight={90}
                          donutChartWidth={260}
                          donutChartPosition={['-3%', '0%']}
                          donutColors={[
                            themeColorPalette.palette.genrang_a.four.main,
                            themeColorPalette.palette.genrang_a.seven.main
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>{' '}
                  <br></br>
                  {index + 1 !== UnderlyingData.length && (
                    <Divider className={classes.divider} />
                  )}
                  <br></br>
                </span>
              )
            })}
        </Grid>
      )
    }
  }, [])
  return underlyingDataMetrics.data ? (
    <Grid className={classes.grid} item xs={12} sm={12}>
      {underlyingDataMetrics.donut}
    </Grid>
  ) : (
    <span></span>
  )
}

export default TcfdStrategyUnderlyingDataMetrics
