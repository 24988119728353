import React, { useCallback, useContext, useEffect } from 'react'
import { Container, Grid } from '@material-ui/core'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON } from 'constant/constants'
import Error from 'utils/error'
import LoadingMask from 'utils/loadingMask'
import EsgAssessmentsOverview from './EsgAssessmentsOverview'
import GeographicRisk from './GeographicRisk'
import Laggards from '../../Laggards'
import Leaders from '../../Leaders'
import EsgAssessmentsColumns from './EsgAssessmentsColumns.json'
import EegSectorTreeMap from './EegSectorTreeMap'
import { Legend, Page } from 'components'
import { cardHeader, useStyles } from './ESG_Assessments_Styles'
import {
  getLeadersLaggardsData,
  getSectorTreeMapData,
  isSectorTreeMapHasData,
  reloadComponent,
  sectorTreeMapHasError
} from './ESG_Assessments_Helper'

const LeaderLaggardColInfo =
  EsgAssessmentsColumns.leaders_Laggards_Panel_Columns

const EsgAssessments = () => {
  const classes = useStyles()
  const [esgAssessmentsState, esgAssessmentsDispatch] =
    useContext(GlobalContext).globalEsgAssessments
  let apiCallId = esgAssessmentsState.apiCallId
  const [filterState] = useContext(GlobalContext).globalFilter
  const [scoreQualityState] = useContext(GlobalContext).globalFilter
  let showBenchMark =
    filterState.globalPortfolioFilter.benchmark !== '' ? true : false

  const isFilterStateAvailable =
    filterState.filterData.regionsList.length > 0 ||
    filterState.filterData.sectorsList.length > 0
  const tableLength = 10
  const legend = ['A1-A3', 'B1-B3', 'C1-C3', 'E']
  const getESGAssessmentsData = useCallback(async () => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    if (filters.portfolio !== '') {
      getLeadersLaggardsData(
        esgAssessmentsState,
        esgAssessmentsDispatch,
        sectors,
        regions,
        apiCallId,
        filterState
      )
      getSectorTreeMapData(
        esgAssessmentsState,
        esgAssessmentsDispatch,
        sectors,
        regions,
        apiCallId,
        filterState
      )
    }
  }, [filterState.actionData])

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      getESGAssessmentsData()
    }
  }, [filterState.actionData])

  return (
    <Page className={classes.root} title={PAGE_CON.PAGE_TITLE}>
      <main className={classes.content}>
        <Container className={classes.container} maxWidth={false}>
          <Grid container>
            <Grid item xs={12}>
              <EsgAssessmentsOverview showBenchMark={showBenchMark} />
            </Grid>

            <Grid item container className={classes.shadowStyle}>
              <Grid item xs={12} sm={6} style={{ paddingRight: '12px' }}>
                <h6 style={cardHeader}>
                  {PAGE_CON.PORTFOLIO_LEADERS_BY_SCORE}
                </h6>
                {esgAssessmentsState.portfolioLeaderLaggard.isLoading ? (
                  <LoadingMask />
                ) : esgAssessmentsState.portfolioLeaderLaggard.dataStatus ===
                    PAGE_CON.ERROR ||
                  (esgAssessmentsState.portfolioLeaderLaggard.dataStatus ===
                    PAGE_CON.SUCCESS &&
                    esgAssessmentsState.portfolioLeaderLaggard.data.leaders &&
                    esgAssessmentsState.portfolioLeaderLaggard.data.leaders
                      .length === 0) ? (
                  <Error
                    id={'leadersError'}
                    dataStatus={
                      isFilterStateAvailable
                        ? esgAssessmentsState.portfolioLeaderLaggard.dataStatus
                        : PAGE_CON.SUCCESS
                    }
                    onErrorClick={() =>
                      reloadComponent(
                        PAGE_CON.LEADERS_TITLE,
                        filterState,
                        esgAssessmentsState,
                        esgAssessmentsDispatch,
                        apiCallId
                      )
                    }
                    errorOccuredOn={PAGE_CON.LEADERS_TITLE}
                    height={'312px'}
                  />
                ) : (
                  <Leaders
                    colInfo={LeaderLaggardColInfo}
                    totalData={
                      esgAssessmentsState.portfolioLeaderLaggard.data.leaders
                    }
                    tableLength={tableLength}
                    tableEditable={false}
                    showScoreQuality={scoreQualityState.enableScoreQuality}
                    research_line={PAGE_CON.ESG_ASSESSMENT}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6} style={{ paddingLeft: '12px' }}>
                <h6 style={cardHeader}>
                  {PAGE_CON.PORTFOLIO_LAGGARDS_BY_SCORE}
                </h6>
                {esgAssessmentsState.portfolioLeaderLaggard.isLoading ? (
                  <LoadingMask />
                ) : esgAssessmentsState.portfolioLeaderLaggard.dataStatus ===
                    PAGE_CON.ERROR ||
                  (esgAssessmentsState.portfolioLeaderLaggard.dataStatus ===
                    PAGE_CON.SUCCESS &&
                    esgAssessmentsState.portfolioLeaderLaggard.data.laggards &&
                    esgAssessmentsState.portfolioLeaderLaggard.data.laggards
                      .length === 0) ? (
                  <div style={{ paddingBottom: 8, paddingRight: 8 }}>
                    <Error
                      id={'laggardsError'}
                      dataStatus={
                        isFilterStateAvailable
                          ? esgAssessmentsState.portfolioLeaderLaggard
                              .dataStatus
                          : PAGE_CON.SUCCESS
                      }
                      onErrorClick={() =>
                        reloadComponent(
                          PAGE_CON.LAGGARDS_TITLE,
                          filterState,
                          esgAssessmentsState,
                          esgAssessmentsDispatch,
                          apiCallId
                        )
                      }
                      errorOccuredOn={PAGE_CON.LAGGARDS_TITLE}
                      height={'312px'}
                    />
                  </div>
                ) : (
                  <Laggards
                    colInfo={LeaderLaggardColInfo}
                    totalData={
                      esgAssessmentsState.portfolioLeaderLaggard.data.laggards
                    }
                    tableLength={tableLength}
                    tableEditable={false}
                    showScoreQuality={scoreQualityState.enableScoreQuality}
                    research_line={PAGE_CON.ESG_ASSESSMENT}
                  />
                )}
              </Grid>
            </Grid>

            {!PAGE_CON.PRODUCTION_ENABLED && (
              <Grid item container className={classes.shadowStyle}>
                <Grid item xs={12} className={classes.treeTitleStyle}>
                  <div style={{ float: 'left' }}>
                    {PAGE_CON.SEC_GEO_DISTRIBUTION}
                  </div>
                  <div style={{ float: 'right', marginTop: '4px' }}>
                    {esgAssessmentsState.esgSectorTreeMap
                      .isLoading ? null : esgAssessmentsState.esgSectorTreeMap
                        .dataStatus === PAGE_CON.ERROR ||
                      (esgAssessmentsState.esgSectorTreeMap.dataStatus ===
                        PAGE_CON.SUCCESS &&
                        !isSectorTreeMapHasData(esgAssessmentsState)) ? null : (
                      <Legend
                        colorOptions={legend}
                        labelFirst={true}
                        leftItemText={'Lower Risk'}
                        rightItemText={'Higher Risk'}
                        colorGroup={'ESG_ASSESSMENT'}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {esgAssessmentsState.esgSectorTreeMap.isLoading ? (
                    <LoadingMask />
                  ) : esgAssessmentsState.esgSectorTreeMap.dataStatus ===
                      PAGE_CON.ERROR ||
                    (esgAssessmentsState.esgSectorTreeMap.dataStatus ===
                      PAGE_CON.SUCCESS &&
                      !isSectorTreeMapHasData(esgAssessmentsState)) ? (
                    <Error
                      id={'sectorError'}
                      dataStatus={
                        isFilterStateAvailable
                          ? esgAssessmentsState.esgSectorTreeMap.dataStatus
                          : PAGE_CON.SUCCESS
                      }
                      onErrorClick={reloadComponent}
                      errorOccuredOn={PAGE_CON.SECTORS_TITLE}
                    />
                  ) : (
                    <EegSectorTreeMap
                      data={esgAssessmentsState.esgSectorTreeMap.data}
                    />
                  )}
                </Grid>
                {!PAGE_CON.PRODUCTION_ENABLED && (
                  <Grid item xs={12}>
                    <GeographicRisk
                      sectorTreeMapHasError={sectorTreeMapHasError(
                        esgAssessmentsState
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Container>
      </main>
    </Page>
  )
}

export default EsgAssessments
