import React from 'react'
import * as S from './styles'

export const Container = ({ children, minify }) => {
  const classes = S.useStyles({ minify })
  return <div className={classes.container}>{children}</div>
}

export const Content = ({ children, minify }) => {
  const classes = S.useStyles({ minify })
  return <div className={classes.content}>{children}</div>
}

export const Title = ({ children }) => {
  const classes = S.useStyles()
  return (
    <div title={children} className={classes.title}>
      {children}
    </div>
  )
}

export const CategoriesContainer = ({ children, minify }) => {
  const classes = S.useStyles({ minify })
  return <div className={classes.categories}>{children}</div>
}

export const CoverageContainer = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.coverage}>{children}</div>
}

export const TitleContainer = ({ children, minify }) => {
  const classes = S.useStyles({ minify })
  return <div className={classes.titleContainer}>{children}</div>
}

export const AboutButton = ({ children, onClick, heap_id }) => {
  const classes = S.useStyles()
  return (
    <span
      // eslint-disable-next-line react/no-unknown-property
      heap_id={heap_id}
      aria-hidden={true}
      onClick={onClick}
      className={classes.aboutButton}
    >
      {children}
    </span>
  )
}

export const ButtonContainer = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.buttonContainer}>{children}</div>
}

export const ResearchLineContainer = ({ children, width, minify }) => {
  const newWidth = !minify && (width ? width : 'auto')
  const classes = S.useStyles({ width: newWidth })
  return <div className={classes.researchLineContainer}>{children}</div>
}

export const EsgDetailText = ({ children }) => {
  const classes = S.useStyles()
  return <p className={classes.esgDetailText}>{children}</p>
}

export const EsgMinify = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.esgMinify}>{children}</div>
}

export const EsgBox = ({ children, color }) => {
  const classes = S.useStyles({ color })
  return <div className={classes.esgBox}>{children}</div>
}
