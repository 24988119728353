import { useContext } from 'react'
import { GlobalContext } from '../context/GlobalStateProvider'
import { PAGE_CON } from '../constant/constants'

/**
 * Custom React Hook, to fetch Application State for the page user is currently on.
 */

const useAppState = (pageHeaderTitle) => {
  const context = useContext(GlobalContext)
  let currAppState
  switch (pageHeaderTitle) {
    case PAGE_CON.PHY_RSK_HZRD_TITLE:
      currAppState = context.globalPhysicalRiskHazards
      break
    case PAGE_CON.OPR_RSK_TITLE:
      currAppState = context.globalOperationsRisk
      break
    case PAGE_CON.MRK_RSK_TITLE:
      currAppState = context.globalMarketRisk
      break
    case PAGE_CON.SUPP_CHN_RISK_TITLE:
      currAppState = context.globalSupplyChainRisk
      break
    case PAGE_CON.PHYRSK_MGM_TITLE:
      currAppState = context.globalPhysicalRisk
      break
    case PAGE_CON.TMP_ALM_TITLE:
      currAppState = context.globalTemperatureAlignment
      break
    case PAGE_CON.CRBN_FT_PRT_TITLE:
      currAppState = context.globalCarbonFootPrint
      break
    case PAGE_CON.BRWN_SHR_ASM_TITLE:
      currAppState = context.globalBrownShareAssessment
      break
    case PAGE_CON.ENR_TRN_MGM_TITLE:
      currAppState = context.globalEnergyTransition
      break
    case PAGE_CON.TCFD_STR_TITLE:
      currAppState = context.globalTcfdStrategy
      break
    case PAGE_CON.GRN_SHR_ASM_TITLE:
      currAppState = context.globalGreenShareAssessment
      break
    case PAGE_CON.ESG_ASSESSMENT_TITLE:
      currAppState = context.globalEsgAssessments
      break
    default:
      currAppState = null
  }
  return currAppState || []
}

export default useAppState
