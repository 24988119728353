import { makeStyles } from '@material-ui/core'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles((theme) => ({
  header: {
    ...textTheme.typography.p2,
    lineHeight: '14.4px',
    fontWeight: 375,
    color: themeColorPalette.palette.sys_trad.text.main,
    fontSize: '12px',
    paddingBottom: (props) =>
      props.headerPaddingBottom && props.headerPaddingBottom
  },
  subHeader: {
    ...textTheme.typography.p3,
    lineHeight: '12px',
    fontWeight: 350,
    color: themeColorPalette.palette.sys_trad.text2.main,
    fontSize: '10px',
    padding: '0 5px',
    paddingBottom: '5px',
    borderBottom: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
    width: '100%',
    display: 'flex',
    alignItems: (props) =>
      props.subHeaderAlignItems && props.subHeaderAlignItems,
    marginTop: (props) => props.subHeaderMarginTop && props.subHeaderMarginTop,
    height: 28
  },
  itemStyle: {
    paddingTop: (props) => props.itemPaddingTop || 12,
    paddingBottom: (props) => props.itemPaddingBottom || 12,
    borderBottom: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
    height: (props) => props.itemHeight || 'auto',
    width: '100%',
    alignContent: 'center'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    ...textTheme.typography.p3,
    fontSize: 10,
    paddingBottom: 10,
    color: textTheme.palette.sys_trad.text2.main
  },
  infoBox: {
    display: 'inline-block',
    marginRight: 4,
    width: 12,
    height: 12,
    background: themeColorPalette.palette.proxy.proxy.main,
    border: `1px solid ${textTheme.palette.sys_trad.white.main}`
  },
  infoContent: {
    ...textTheme.typography.p3,
    fontSize: 10,
    color: textTheme.palette.sys_trad.text2.main,
    display: 'inline'
  },
  portfolioItem: {
    ...textTheme.typography.p3,
    fontSize: '14px',
    fontWeight: 325,
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: '0 5px',
    display: 'flex',
    alignItems: 'center',
    '&>div': {
      width: '100%'
    },
    '& label': {
      marginRight: 0,
      paddingRight: 10,
      width: '100%'
    },
    '& .MuiFormControlLabel-label': {
      marginRight: 0,
      paddingRight: 10,
      width: '100%'
    },
    '& .portfolioName': {
      display: 'inline-block',
      width: '90%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  coverageColumn: {
    display: 'flex',
    justifyContent: 'end',
    width: (props) => props.coverageWidth && props.coverageWidth
  },
  onDemandColumn: {
    textAlign: 'right',
    width: '100%'
  },
  dateColumn: {
    display: 'flex',
    justifyContent: 'end',
    width: (props) => props.dateWidth && props.dateWidth
  },
  isChecked: {
    ...textTheme.typography.p2,
    fontSize: '14px',
    fontWeight: 375
  },
  cashColumn: {
    display: 'flex',
    justifyContent: 'end'
  },
  tableCaption: {
    ...theme.typography.p1
  },
  reportingYearNA: {
    ...textTheme.typography.p3,
    color: textTheme.palette.sys_trad.text2.main,
    fontSize: '14px',
    fontWeight: 325,
    paddingLeft: 4
  },
  emptyList: {
    ...textTheme.typography.p1,
    lineHeight: '14.4px',
    color: theme?.palette?.sys_trad?.main.main,
    backgroundColor: '#F5F7F7',
    fontSize: '14px',
    textAlign: 'center',
    padding: '20px 0'
  },
  oDActionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 10,
    '& svg': {
      marginBottom: 0
    }
  },
  downloadIcon: {
    width: 16,
    height: 16,
    marginBottom: 6
  },
  odaEmptyList: {
    width: 690,
    height: 112,
    marginTop: 12
  },
  noPortfolioText: {
    ...textTheme.typography.h2,
    paddingTop: 16
  },
  noPortfolioSubText: {
    ...textTheme.typography.p3,
    paddingTop: 4,
    color: theme?.palette?.sys_trad?.text2.main,
    marginBottom: 0
  },
  loaderBackdrop: {
    display: (props) => (props.visible ? 'block' : 'none'),
    zIndex: 999999999,
    position: 'fixed'
  },
  customODAPortfolioName: {
    maxWidth: '30%',
    flexBasis: '30%'
  },
  customODADate: {
    maxWidth: '20%',
    flexBasis: '20%'
  },
  panelContainer: {
    backgroundColor: textTheme.palette.sys_trad.white.main,
    width: 670,
    padding: '0 24px',
    height: '100%',
    maxHeight: 'calc(100% - 102px)',
    '& > .MuiGrid-container': {
      width: '100%',
      height: 'calc(100% - 2px)'
    }
  },
  sectionName: {
    fontWeight: 375,
    fontSize: 16,
    color: textTheme.palette.sys_trad.text.main,
    paddingBottom: 8,
    marginTop: 24
  },
  panelCompanyName: {
    float: 'left',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingRight: 7,
    textOverflow: 'ellipsis',
    width: '90%'
  },
  panelBoldCol: {
    fontWeight: 600
  },
  numberCol: {
    width: 25
  },
  regionCol: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  sectionContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  tablesContainer: {
    flex: 1,
    overflowY: 'scroll'
  },
  downloadContainer: {
    height: 139,
    background: textTheme.palette.sys_trad.bg.main,
    borderRadius: 2,
    marginBottom: 24,
    marginTop: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '18px',
    fontSize: 14,
    textAlign: 'center'
  },
  downloadContent: {
    ...theme.typography.p3,
    width: '100%'
  },
  exportButton: {
    marginTop: 32
  }
}))

export const ODActionContainer = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.oDActionContainer}>{children}</div>
}

export const LoaderBackdrop = ({ children, visible }) => {
  const classes = useStyles({ visible })
  return <div className={classes.loaderBackdrop}>{children}</div>
}
