import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconRegion = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2377 4.5874C13.957 1.87599 11.1976 0 8 0C7.67456 0 7.35365 0.0194327 7.03839 0.057201C6.94823 0.068002 6.85853 0.0803024 6.76932 0.0940768C6.32773 0.162258 5.89806 0.266552 5.48343 0.403846C3.96381 0.907023 2.64613 1.85346 1.68355 3.08997C0.628436 4.44537 0 6.14929 0 8C0 12.4183 3.58172 16 8 16C11.8157 16 15.0075 13.3286 15.8071 9.75407C15.9334 9.18958 16 8.60257 16 8C16 6.77931 15.7266 5.62248 15.2377 4.5874ZM14.8978 9.19869C14.965 8.8092 15 8.40869 15 8C15 6.88937 14.7413 5.83915 14.281 4.9063C13.4154 5.19482 13.3808 5.42883 13 6C12.7761 6.3359 12.7761 7.1641 13 7.5C13.257 7.88549 13.5294 8.34314 14 8.5C14.5393 8.67975 14.3886 8.94411 14.8978 9.19869ZM7.67195 1.00755C7.43181 1.01863 7.19482 1.04181 6.96158 1.07649L7.25319 1.7569C7.50809 2.35168 7.07878 2.78939 6.5 2.5C6.30827 2.40414 6.17058 2 6.09003 1.7569C6.00949 1.51379 5.77257 1.36184 5.77257 1.36184C4.0412 1.94256 2.61187 3.18167 1.78203 4.78174L2.43017 5.42988C2.84116 5.84087 3.12433 6.43611 3.44526 6.91751C3.69972 7.29921 4.1077 7.60741 4.43017 7.92988C4.86313 8.36285 5.49289 8.6617 6.00019 8.9999L6.4474 9.22351C6.78618 9.3929 7.00019 9.73916 7.00019 10.1179C7.00019 10.6643 6.86672 11.3664 7.29308 11.7928C7.64202 12.1417 8.00019 12.74 8.00019 13.236V15C8.43707 15 8.8646 14.96 9.27932 14.8834C9.27932 14.8834 9.5 14 10 13.5C10.5 13 11.2107 11.868 11.5 11C11.6826 10.4522 11.4387 9.95894 10.8647 9.57622C10.5122 9.34122 10.1447 8.9999 9.69741 8.9999H8.11822C7.69089 8.9999 6.95261 8.86658 6.76932 8.5C6.65071 8.26278 6.00019 8.06179 6.00019 7.5C6.00019 6.93821 6.66898 7 7 7C7.87767 7 7.86448 6.59278 7.65427 5.96216C7.55984 5.67886 7.63357 5.36651 7.84474 5.15535L8.27498 4.7251C8.41918 4.58091 8.50019 4.38534 8.50019 4.18141C8.50019 4.06204 8.52798 3.94432 8.58136 3.83755L8.74066 3.51896C8.90114 3.19798 8.87734 2.81563 8.67828 2.51704L7.67195 1.00755Z"
      fill="#26415E"
    />
  </SvgIcon>
)
IconRegion.displayName = 'IconRegion'
IconRegion.muiName = 'SvgIcon'

export default IconRegion
