import React, { useContext, useEffect } from 'react'
import axios from 'axios'
import { PrimaryButton, Table } from 'components'
import { Grid } from '@material-ui/core'
import { useStyles } from './styles'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import Error from 'utils/error'
import LoadingMask from 'utils/loadingMask'
import formatDecimalValues from 'utils/formatDecimalValues'
import numberData from 'utils/numberFormat'
import { ReportingServices } from 'services'

const PortfolioDetailPanelTable = ({
  portfolio,
  currentView,
  onExport,
  isExportEntitled
}) => {
  const classes = useStyles()
  const [reportingServiceState, reportingServiceDispatch] =
    useContext(GlobalContext).globalReportingService

  const { apiCallId } = reportingServiceState
  const [coverageDetails] = reportingServiceState?.coverageDetails?.data
  const tables = Object.keys(
    coverageDetails ? coverageDetails[currentView] : {}
  ).map((key) => {
    const data = coverageDetails[currentView][key]

    return {
      name: key,
      isHighlighted: data?.entities?.map(
        ({ score_quality }) => score_quality === 'Predicted'
      ),
      remaining: data?.remaining_entities,
      data: data?.entities?.map(
        ({
          company_name,
          esg_score,
          perc_investment,
          region_country,
          sector
        }) => [
          <div key={company_name} className={classes.panelCompanyName}>
            {company_name}
          </div>,
          <div
            key={esg_score}
            className={`esgScore ${classes.panelBoldCol} ${classes.numberCol}`}
          >
            {Math.round(esg_score)}
          </div>,
          <div
            key={perc_investment}
            className={`${classes.panelBoldCol} ${classes.numberCol}`}
          >
            {formatDecimalValues(
              perc_investment,
              {
                isDecimal: true,
                isPercentage: true,
                keepRawValue: true,
                maxFraction: 1
              },
              0.1,
              '<0.1%'
            )}
          </div>,
          <div key={region_country} className={classes.regionCol}>
            {currentView === 'region' ? sector : region_country}
          </div>
        ]
      )
    }
  })
  const hasData = tables.filter(({ data }) => data?.length).length
  const tableHeaders = [
    {
      label: <span>Entity</span>,
      columnStyles: {
        overflow: 'hidden',
        textAlign: 'left',
        border: '0px',
        width: '45%',
        padding: 4,
        height: 23
      },
      sortable: false
    },
    {
      label: <span>ESG Score</span>,
      columnStyles: {
        overflow: 'hidden',
        textAlign: 'left',
        border: '0px',
        width: '15%',
        padding: 4,
        height: 23
      },
      sortable: false
    },
    {
      label: <span>% Investment</span>,
      columnStyles: {
        overflow: 'hidden',
        textAlign: 'left',
        border: '0px',
        width: '15%',
        padding: 4,
        height: 23
      },
      sortable: false
    },
    {
      label: (
        <span>{currentView === 'region' ? 'Sector' : 'Region/Country'}</span>
      ),
      columnStyles: {
        overflow: 'hidden',
        textAlign: 'left',
        border: '0px',
        width: '25%',
        padding: 4,
        height: 23
      },
      sortable: false
    }
  ]

  const companiesCount = tables.reduce(
    (acc, { data }) => {
      return {
        display: acc.display + data?.length,
        total: coverageDetails['portfolio_total_entities'] || 0
      }
    },
    { display: 0, total: 0 }
  )

  const getPortfolioCoverageDetails = async (isErrorReload = false) => {
    if (
      reportingServiceState?.coverageDetails?.dataStatus !== '' &&
      isErrorReload === false
    )
      return
    reportingServiceDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.REPORTING_COVERAGE_DETAILS
    })

    try {
      const data = await ReportingServices.getReportingPortfolioCoverageDetails(
        portfolio.key,
        reportingServiceState.cancelToken.token
      )
      reportingServiceDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.REPORTING_COVERAGE_DETAILS,
        data,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        reportingServiceDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.REPORTING_COVERAGE_DETAILS,
          apiCallId
        })
      }
    }
  }

  const reloadComponent = () => getPortfolioCoverageDetails(true)
  const onClickExport = () => {
    onExport({ ...portfolio, portfolioName: portfolio.name })
  }
  useEffect(() => {
    if (portfolio.key) getPortfolioCoverageDetails()
  }, [portfolio.key])

  return (
    <Grid item className={classes.panelContainer}>
      {reportingServiceState?.coverageDetails?.isLoading ? (
        <LoadingMask />
      ) : reportingServiceState.coverageDetails.dataStatus ===
        PAGE_CON.ERROR ? (
        <Error
          id={'reportingError'}
          dataStatus={PAGE_CON.ERROR}
          onErrorClick={reloadComponent}
          errorOccuredOn={PAGE_CON.REPORTING_SERVICE}
        />
      ) : hasData ? (
        <div className={classes.sectionContainer}>
          <div className={classes.tablesContainer}>
            {tables.map(({ name, data, isHighlighted, remaining }) => (
              <div key={name}>
                <div className={classes.sectionName}>{name}</div>
                <Table
                  isLoading={false}
                  id={'viewcompanies'}
                  custBorderTop={false}
                  headers={tableHeaders}
                  data={data}
                  isHighlighted={isHighlighted}
                />
                {remaining > 0 && (
                  <div className={classes.tableCaption}>
                    {numberData(remaining, {
                      isDecimal: false,
                      isPercentage: false,
                      isCommaSeparated: true
                    })}{' '}
                    more companies in this {currentView?.replace('_', ' ')}
                  </div>
                )}
              </div>
            ))}
          </div>
          {isExportEntitled && (
            <div className={classes.downloadContainer}>
              <div className={classes.downloadContent}>
                <p>
                  Showing{' '}
                  {numberData(companiesCount.display, {
                    isDecimal: false,
                    isPercentage: false,
                    isCommaSeparated: true
                  })}{' '}
                  out{' '}
                  {numberData(companiesCount.total, {
                    isDecimal: false,
                    isPercentage: false,
                    isCommaSeparated: true
                  })}{' '}
                  in {portfolio.name}.
                </p>
                <p>
                  Export to view all data for{' '}
                  {numberData(companiesCount.total, {
                    isDecimal: false,
                    isPercentage: false,
                    isCommaSeparated: true
                  })}{' '}
                  companies.
                </p>
              </div>
              <div className={classes.exportButton}>
                <PrimaryButton
                  id="dashboard-export-button-test-id"
                  buttonText="Export To Excel"
                  heap_id="view-panel"
                  heap_view_panel="export"
                  onClick={onClickExport}
                  buttonClass="buttonPrimary"
                  buttonDisabled={false}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        reportingServiceState.coverageDetails.dataStatus === 'success' && (
          <div>
            <Error
              id={'reportingError'}
              dataStatus={PAGE_CON.SUCCESS}
              errorOccuredOn={PAGE_CON.REPORTING_SERVICE}
            />
          </div>
        )
      )}
    </Grid>
  )
}
export default PortfolioDetailPanelTable
