import React, { useContext } from 'react'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'
import PortfolioEntity from './PortfolioEntity'
import PortfolioList from './PortfolioList'

function PortfolioManagement(props) {
  const [portfolioManagementState, portfolioManagementDispatch] =
    useContext(GlobalContext).globalPortfolioManagement

  const onPortfolioSelect = (selPortfolio) => {
    portfolioManagementDispatch({
      type: REDUCER_TYPE.SET_PORTFOLIO,
      portfolioId: selPortfolio.id,
      portfolioManagementView: PAGE_CON.PORTFOLIO_ENTITY
    })
  }

  const moveToPortfolioList = () => {
    portfolioManagementDispatch({
      type: REDUCER_TYPE.SET_PORTFOLIO,
      portfolioId: '',
      portfolioManagementView: PAGE_CON.PORTFOLIO_LIST
    })
  }

  return portfolioManagementState.portfolioManagementView ===
    PAGE_CON.PORTFOLIO_LIST ? (
    <PortfolioList
      onMovePrevClick={props.onMovePrevClick}
      handlePortfolioClose={props.handlePortfolioClose}
      onPortfolioClick={onPortfolioSelect}
    />
  ) : portfolioManagementState.portfolioManagementView ===
    PAGE_CON.PORTFOLIO_ENTITY ? (
    <PortfolioEntity
      onMovePrevClick={moveToPortfolioList}
      handlePortfolioClose={props.handlePortfolioClose}
      isEntityClickable={
        !props.entitlements.ReportingService.isReportingOnlyEnabled
      }
    />
  ) : null
}

export default PortfolioManagement
