import { styled } from '@mui/material/styles'
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress'
import themeColorPalette from 'theme/theme'

export const BorderLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) =>
    prop !== 'height' && prop !== 'progressColour' && prop !== 'primaryColor'
})(
  ({
    theme,
    height,
    progressColour,
    primaryColor = theme.palette.grey,
    customstyle = {}
  }) => ({
    ...customstyle,
    height: height || 10,
    borderRadius: customstyle?.borderRadius || 3,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: primaryColor
    },
    [`& .${linearProgressClasses.bar}`]: {
      ...customstyle,
      borderRadius: customstyle?.borderRadius || 5,
      backgroundColor:
        theme.palette.mode === 'light'
          ? progressColour
          : themeColorPalette.palette.white.main
    }
  })
)
