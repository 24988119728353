import { IMPORT_UPDATE_GET_PORTFOLIOS } from 'constant/APIconstants'
import axios from 'axios'

export const updatePortfolioDetails = async ({ data }) => {
  return await axios.put(IMPORT_UPDATE_GET_PORTFOLIOS, data)
}

export const getPortfolioDetails = async ({ params, queryParams }) => {
  return await axios.get(IMPORT_UPDATE_GET_PORTFOLIOS + queryParams, params)
}

export * from './export'
export * from './researchLine'
export * from './common'
