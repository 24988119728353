import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconSuccessTable = (props) => (
  <SvgIcon {...props}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8 5.60003C11.0209 5.76571 11.0657 6.07912 10.9 6.30003L7.9 10.3C7.73431 10.5209 7.42091 10.5657 7.2 10.4L5.2 8.90003C4.97909 8.73434 4.93431 8.42094 5.1 8.20003C5.26568 7.97912 5.57908 7.93434 5.8 8.10003L7.4 9.30003L10.1 5.70003C10.2657 5.47912 10.5791 5.43434 10.8 5.60003Z"
        fill="#0F997D"
      />
      <circle cx="8" cy="8" r="5.5" stroke="#0F997D" />
    </svg>
  </SvgIcon>
)
IconSuccessTable.displayName = 'IconSuccessTable'
IconSuccessTable.muiName = 'SvgIcon'

export default IconSuccessTable
