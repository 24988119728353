import { makeStyles } from '@material-ui/core'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'

export const useStyles = makeStyles(() => ({
  toRange: {
    ...textTheme.typography.p4,
    textAlign: 'left',
    padding: '8px',
    display: 'inline-block'
  },
  inputRange: {
    ...textTheme.typography.p2,
    width: '32px'
  },
  barRange: {
    display: 'flex',
    alignItems: 'flex-end',
    padding: 0,
    margin: 0,
    marginBottom: -13
  },
  bar: {
    listStyle: 'none',
    padding: 0,
    width: '1%',
    color: 'transparent',
    display: 'inline-block'
  },
  lineContainer: {
    ...textTheme.typography.p2,
    display: 'flex',
    alignContent: 'center',
    color: themeColorPalette.palette.sys_trad.text2.main,
    marginTop: 16,
    position: 'relative'
  },
  line: {
    width: '100%',
    border: 'none',
    borderBottom:
      '1px dashed ' + themeColorPalette.palette.sys_trad.border.main,
    flexGrow: 1,
    flexBasis: 'fit-content',
    alignItems: 'center'
  },
  lineValue: {
    paddingLeft: 3,
    textAlign: 'right',
    zIndex: 100,
    background: themeColorPalette.palette.sys_trad.white.main,
    position: 'absolute',
    right: 0,
    top: -9
  },
  bottomRange: {
    ...textTheme.typography.p2,
    padding: 0,
    margin: '-11px 0 0',
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    color: themeColorPalette.palette.sys_trad.status.main
  }
}))
