import React, { useState } from 'react'
import MaterialityDriverList from './MaterialityDriverList'
import Grid from '@material-ui/core/Grid'
import theme from 'theme'
import Box from '@material-ui/core/Box'
import { PAGE_CON } from 'constant/constants'
import { EntityService } from 'services'
import MaterialityDriverHeaderSector from '../driverInfo/MaterialityDriverHeaderSector'
import MaterialityDriverInfoSector from '../driverInfo/MaterialityDriverInfoSector'
import themeColorPalette from 'theme/theme'
import { IconError, Legend, PrimaryModal } from 'components'
import LoadingMask from 'utils/loadingMask'
import Error from 'utils/error'
import MaterialityDriverFilter from './MaterialityDriverFilter'
import * as S from './styles'

const driversList = (list) => {
  if (list?.length > 0) {
    return list
      .map(
        (
          {
            criteria_id,
            criteria_name = '',
            criteria_score,
            score_category,
            indicator,
            domain,
            sub_category_detailed_description,
            critical_controversy_exists_flag,
            disclosure_ratio
          },
          index
        ) => ({
          title: criteria_name,
          subTitle: `${indicator} / ${domain}`,
          category: indicator,
          key: criteria_id + '_' + index,
          value: Math.round(criteria_score),
          label: score_category,
          criteria: criteria_id,
          warning: critical_controversy_exists_flag,
          ratio: disclosure_ratio,
          description: sub_category_detailed_description
        })
      )
      .sort((a, b) => b.value - a.value)
  } else return []
}

const ORDERED_MENU = ['Very High', 'High', 'Moderate', 'Low']

const Drivers = (props) => {
  const classes = S.useStyles(theme)
  const [drivers, setDrivers] = useState(new Map())
  const [dataLoaded, setDataLoaded] = useState(false)
  const [openModal, setOpenModal] = React.useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [row, setRow] = useState(null)
  const [driverItem, setDriverItem] = useState(null)
  const [companyScoredBy, setCompanyScoredBy] = useState('mesg')

  // esc key
  const handleKeyDown = (x) => {
    if (x.keyCode === 27) if (handleCloseDialog) handleCloseDialog()
  }

  const handleCloseDialog = () => {
    setOpenModal(false)
  }

  const handleRowClick = async (row) => {
    if (!modalLoading) {
      setRow(row)
      setOpenModal(true)
      setModalLoading(true)
      const { orbis_id } = props
      const criteria = row.criteria

      const newDriverItemData = {
        label: '',
        title: row.title,
        criteria: row.criteria
      }

      setDriverItem(newDriverItemData)

      try {
        const data = await EntityService.getDriverInfo({ orbis_id, criteria })

        setDriverItem({
          ...newDriverItemData,
          apiData: data,
          dataStatus: PAGE_CON.SUCCESS,
          subTitle: data?.sub_category_detailed_description || ''
        })

        setModalLoading(false)
      } catch (err) {
        setDriverItem({
          ...newDriverItemData,
          dataStatus: PAGE_CON.ERROR
        })
        setModalLoading(false)
      }
    }
  }

  const isError = () => props.data.dataStatus === PAGE_CON.ERROR

  const loadData = (filterOption) => {
    const newDrivers = new Map()

    const filterDrivers = (materialityWeight, materialityType, indicator) =>
      props.data?.data?.drivers?.filter((driver) => {
        return (
          indicator.some((x) => x === driver.indicator) &&
          driver.driver_weights.filter(
            (weight) =>
              weight.materiality_type === materialityType &&
              weight.materiality_weight === materialityWeight
          ).length > 0
        )
      })

    const data = [
      {
        drivers: filterDrivers(
          'Low',
          filterOption.materiality,
          filterOption.esgOption
        ),
        weight_category: 'Low'
      },
      {
        drivers: filterDrivers(
          'Moderate',
          filterOption.materiality,
          filterOption.esgOption
        ),
        weight_category: 'Moderate'
      },
      {
        drivers: filterDrivers(
          'High',
          filterOption.materiality,
          filterOption.esgOption
        ),
        weight_category: 'High'
      },
      {
        drivers: filterDrivers(
          'Very High',
          filterOption.materiality,
          filterOption.esgOption
        ),
        weight_category: 'Very High'
      }
    ]

    data.forEach((driver) => {
      newDrivers.set(driver.weight_category, driversList(driver.drivers))
    })

    setDrivers(newDrivers)
  }

  const filterMethodology = (option) => {
    loadData(option)
  }

  React.useEffect(() => {
    if (
      !dataLoaded &&
      (props.data.dataStatus === PAGE_CON.SUCCESS ||
        props.data.dataStatus === PAGE_CON.ERROR)
    ) {
      if (props.data?.data?.drivers?.length > 0) {
        const filterOption = {
          materiality: PAGE_CON.ESG_DOUBLE_MATERIALITY,
          esgOption: ['Environmental', 'Social', 'Governance']
        }
        loadData(filterOption)
        setCompanyScoredBy(props.data.data.methodology)
      }
      setDataLoaded(true)
    }
  }, [dataLoaded, props?.data?.dataStatus])

  const reloadComponent = (errorOccuredOn) => {
    setDataLoaded(false)
    props.reloadComponent(errorOccuredOn)
  }

  return (
    <Grid container className={classes.driversContainer}>
      {!dataLoaded ? (
        <LoadingMask />
      ) : isError() ? (
        <Error
          id={'mtltyError'}
          dataStatus={PAGE_CON.ERROR}
          onErrorClick={reloadComponent}
          errorOccuredOn={PAGE_CON.CLIMATE_MTLTY_MATRIX}
        />
      ) : (
        <div className={classes.driversContainerMinWidth}>
          <Grid item xs={12} className={classes.header}>
            <div className={classes.leftHeader}>
              <Legend
                colorOptions={['Weak', 'Limited', 'Robust', 'Advanced']}
                colorGroup={'ESG_CATEGORY'}
              />
            </div>
            <div className={classes.rightHeader}>
              <IconError />
              Critical controversies
            </div>
          </Grid>
          <Grid container className={classes.subcategoriesContainer}>
            <div className={classes.subCategoriesDiv}>
              <section className={classes.leftScale}>
                <div className={classes.leftTopLabel}>Higher Score</div>
                <div className={classes.arrowUp}> {'<'}</div>
                <div className={classes.arrow}></div>
                <div className={classes.arrowDown}>{'>'}</div>
                <div className={classes.leftBottomLabel}>Lower Score</div>
              </section>
              {ORDERED_MENU.map((driverName) => {
                const driverCategories = drivers.get(driverName)

                return (
                  <section key={driverName}>
                    <PrimaryModal
                      id="materiality-driver-id"
                      modalOpen={openModal}
                      onModalClose={handleCloseDialog}
                      modalScroll={'paper'}
                      modalClass="minorModal"
                      modalMaxWidth={props.isControversyEnabled ? 'lg' : 'md'}
                      showCloseIcon={false}
                      headerBgColor={
                        themeColorPalette.palette.sys_trad.white.main
                      }
                      showDivider={true}
                      onKeyDown={handleKeyDown}
                      modalHeaderContentComponent={
                        <MaterialityDriverHeaderSector
                          isLoading={modalLoading}
                          data={driverItem}
                          isControversyEnabled={props.isControversyEnabled}
                        ></MaterialityDriverHeaderSector>
                      }
                      modalContentComponent={
                        <MaterialityDriverInfoSector
                          isLoading={modalLoading}
                          isControversyEnabled={props.isControversyEnabled}
                          data={driverItem}
                          reloadComponent={() => handleRowClick(row)}
                        />
                      }
                      backdropstyle={true}
                    />

                    <Box>
                      <Box className={classes.driverListHeaderContainer}>
                        <p
                          className={classes.driverListHeaderTitle}
                        >{`Materiality: ${driverName}`}</p>
                      </Box>

                      {driverCategories?.length > 0 ? (
                        <MaterialityDriverList
                          items={driverCategories}
                          noColor
                          listItemBorder={`1px solid ${theme.palette.sys_trad.border.main}`}
                          listItemWidth="100%"
                          isControversyEnabled={props.isControversyEnabled}
                          opacity={[1]}
                          isLoading={false}
                          listPadding="0px"
                          companyScoredBy={companyScoredBy}
                          colorCategory={'ESG_CATEGORY'}
                          driverName={driverName}
                          showValue
                          simpleBanner
                          handleRowClick={handleRowClick}
                          entitlements={props?.entitlements}
                          isCompanyHasOnDemand={props?.isCompanyHasOnDemand}
                        />
                      ) : (
                        <MaterialityDriverList
                          isControversyEnabled={props.isControversyEnabled}
                          items={[]}
                          listItemBorder={`1px solid ${theme.palette.sys_trad.border.main}`}
                        />
                      )}
                    </Box>
                  </section>
                )
              })}
            </div>
          </Grid>
          <Grid item xs={12} className={classes.footer}>
            <MaterialityDriverFilter
              id="driverFilter"
              onClick={filterMethodology}
              methodology={companyScoredBy?.toUpperCase()}
              entitlements={props?.entitlements}
              isCompanyHasOnDemand={props?.isCompanyHasOnDemand}
            ></MaterialityDriverFilter>
          </Grid>
        </div>
      )}
    </Grid>
  )
}

export default Drivers
