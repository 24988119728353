import { makeStyles } from '@material-ui/core'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyle = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: (props) =>
      props.scoreTable || props.climateTable
        ? props.outerBackgroundColor
        : themeColorPalette.palette.sys_trad.white.main,
    width: (props) => (props.maxWidth ? props.maxWidth : 'auto'),
    paddingBottom: (props) => (props.scoreTable ? 0 : 0), //2
    margin: 4,
    marginBottom: 0,
    borderRadius: (props) => props.outerRadius && props.outerRadius,
    minWidth: (props) => (props.minWidthCoverage ? props.minWidthCoverage : '')
  },
  outerBox: {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  outerBoxFirstChild: {
    '&:first-child': {
      flex: '0 1 100%'
    }
  },
  textStyle: {
    ...textTheme.typography.p3,
    height: 16,
    zIndex: 0
  },
  labelStyle: {
    ...textTheme.typography.p2,
    color: (props) =>
      props.labelStyle
        ? props.labelStyle.color
        : textTheme.palette.sys_trad.text2.main,
    height: 16,
    zIndex: 0,
    paddingRight: '23px',
    marginLeft: -2
  },
  weightedAvgStyle: {
    paddingRight: '0'
  },
  dataStyle: {
    ...textTheme.typography.p2,
    height: (props) => (props.singleData ? 24 : 16),
    zIndex: 0,
    fontSize: (props) => props.singleData && '24px',
    lineHeight: (props) => props.singleData && '24px'
  },
  dataStyleRedesignTitle: {
    ...textTheme.typography.p2,
    fontWeight: 'bold',
    letterSpacing: '1px',
    height: (props) => (props.singleData ? 24 : 16),
    zIndex: 0,
    fontSize: (props) => props.singleData && '24px',
    lineHeight: (props) => props.singleData && '24px'
  },
  climateStyle: {
    ...textTheme.typography.h1,
    height: '16px',
    fontSize: '12px',
    lineHeight: '16px'
  },

  scoreStyle: {
    fontSize: (props) =>
      props.data && props.data.category && props.data.category.length >= 8
        ? '21px'
        : '28px',
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: '-0.05px',
    marginTop: '14px',
    color: textTheme.palette.sys_trad.text.main,
    textAlign: 'justify'
  },
  scoreFirstLineStyle: {
    ...textTheme.typography.p2,
    textAlign: 'left',
    paddingRight: '4px',
    paddingBottom: '8px',
    paddingLeft: '2px'
  },
  scoreEmptyRow: {
    ...textTheme.typography.h4,
    color: themeColorPalette.palette.sys_trad.bg.main
  },
  scoreSecondRow: {
    ...textTheme.typography.h2,
    color: textTheme.palette.sys_trad.text.main,
    textAlign: 'left',
    marginLeft: '4px'
  },
  carbonBrownGreenFirstLine: {
    color: textTheme.palette.sys_trad.text.main
  },
  scoreSecondRowDenom: {
    ...textTheme.typography.h4,
    color: textTheme.palette.sys_trad.text.main,
    textAlign: 'left'
  },
  otherscoreSecondRow: {
    ...textTheme.typography.h2,
    color: textTheme.palette.sys_trad.text.main,
    textAlign: 'left',
    marginTop: '8px',
    fontWeight: 'bold'
  },
  scoreThirdRow: {
    whiteSpace: 'pre-wrap',
    ...textTheme.typography.p4,
    fontSize: '10px !important',
    color: textTheme.palette.sys_trad.text.main,
    textAlign: 'left',
    marginBottom: '2px',
    paddingLeft: '2px'
  },

  scoreTitleStyle: {
    ...textTheme.typography.p2,
    color: textTheme.palette.sys_trad.text.main,
    textAlign: 'left'
  },
  scoreDataStyle: {
    ...textTheme.typography.h2,
    color: textTheme.palette.sys_trad.text.main,
    textAlign: 'left',
    paddingTop: '8px',
    fontWeight: 'bold'
  },
  cardHeader: {
    fontSize: '14px',
    color: textTheme.palette.sys_trad.text.main,
    textAlign: 'left',
    marginLeft: '2px'
  },
  cardHeaderSecondRow: {
    fontSize: '14px',
    color: textTheme.palette.sys_trad.text.main,
    textAlign: 'left',
    marginTop: '4px',
    paddingLeft: '2px'
  }
})

export const displayPadding = '2px 4px 0px'
export const defaultTextColor = textTheme.palette.sys_trad.text2.main
export const defaultDataTextColor = textTheme.palette.sys_trad.text.main
