import React from 'react'
import { Dialog, DialogContent, Toolbar } from '@material-ui/core'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'
import { useContext } from 'react'
import { useStyles } from './styles'
import BatchReviewView from './BatchReviewView'
import CriteriaFilterView from './CriteriaFilterView'
import { IconClose } from 'components'
import ReportTemplate from 'views/portfolioanalysis/ReportTemplate'
import RequestDetail from './RequestDetail'
import numberData from 'utils/numberFormat'
import formatDecimalValues from 'utils/formatDecimalValues'
import { formattingRules } from './CriteriaFilterData'

const OnDemandAssessmentModal = () => {
  const classes = useStyles()
  const [onDemandAsmntState, onDemandAsmntDispatch] =
    useContext(GlobalContext).onDemandAsmntService
  const portfolio = onDemandAsmntState.portfolio
  const { location, sector, scoreRange } =
    onDemandAsmntState?.reqDetailInfo?.data?.info || ''

  const portfolioName = portfolio?.portfolioName || ''

  const filters = [
    { key: 'Portfolio', value: portfolioName },
    {
      key: 'Predicted ESG Score',
      value: `${scoreRange?.min}-${scoreRange?.max}`
    },
    { key: 'Location', value: location === 'all' ? 'All' : location },
    { key: 'Sector', value: sector === 'all' ? 'All' : sector }
  ]

  const statuses = {
    'PENDING ACTIVATION': { label: 'Pending Activation', value: 'pending' },
    CANCELLED: { label: 'Cancelled', value: 'cancelled' },
    'IN PROGRESS': { label: 'In Progress', value: 'in-progress' },
    COMPLETED: { label: 'Completed', value: 'completed' }
  }

  const entities = onDemandAsmntState?.reqDetailInfo?.data?.entities || []
  const companies = entities.map((entity) => ({
    name: entity.company_name,
    orbis_id: entity.bvd9_number,
    status: statuses[entity.state] || {
      label: 'No Request Sent',
      value: 'not_sent'
    },
    cancel_reason: entity.cancel_reason,
    score_quality: entity.score_quality,
    predicted_score: entity.score,
    country_code: entity.country_code,
    invested: [
      formatDecimalValues(
        entity?.investment_pct || 0,
        {
          isDecimal: true,
          isPercentage: true,
          keepRawValue: true
        },
        0.01,
        '<0.01%'
      ),
      numberData(entity?.current_investment || 0, {
        isCommaSeparated: true,
        isDecimal: false,
        keepRawValue: true
      })
    ],
    info: [
      entity.country_name,
      entity.sector,
      entity.size ? `Employee Count: ${entity.size}` : null
    ],
    sector_code: entity.sector_code || '',
    size: entity.actual_size || ''
  }))

  const getODAFormattedValue = () => {
    const investedSum =
      entities?.reduce((acc, { investment_pct }) => acc + investment_pct, 0) ||
      0
    return formattingRules(
      investedSum,
      false,
      0.04,
      99.9,
      1,
      '<',
      '0.1'
    ).replace('.0%', '%')
  }

  const onDemandAssessmentModalClose = () => {
    onDemandAsmntDispatch({ type: REDUCER_TYPE.RESET_API_CALLS })
    if (!onDemandAsmntState.hasErrorPopUP) {
      if (onDemandAsmntState.prevStep) {
        onDemandAsmntDispatch({
          type: REDUCER_TYPE.ON_DEMAND_ASMNT_SET_STEP,
          step: onDemandAsmntState.prevStep,
          initiatedFrom: onDemandAsmntState.initiatedFrom
        })
      } else if (onDemandAsmntState.initiatedFrom) {
        onDemandAsmntDispatch({
          type: REDUCER_TYPE.ON_DEMAND_ASMNT_SET_STEP,
          step: onDemandAsmntState.initiatedFrom
        })
      } else {
        onDemandAsmntDispatch({
          type: REDUCER_TYPE.ON_DEMAND_ASMNT_MODAL,
          modalOpen: false
        })
        onDemandAsmntDispatch({ type: REDUCER_TYPE.RESET })
      }
    }
  }

  const onDemandAssessmentReport = () => {
    const step = onDemandAsmntState?.step[portfolio?.key]
    const readOnly = onDemandAsmntState?.reqDetailReadOnly

    if (step === PAGE_CON.ON_DEMAND_ASMNT_BATCH_REVIEW) {
      return <BatchReviewView companies={companies} />
    } else if (step === PAGE_CON.ON_DEMAND_ASMNT_REQUEST_DETAIL) {
      return <RequestDetail readOnly={readOnly} companies={companies} />
    } else {
      return (
        <CriteriaFilterView onCancelRequest={onDemandAssessmentModalClose} />
      )
    }
  }
  return (
    <Dialog
      open={onDemandAsmntState.modalOpen}
      onClose={onDemandAssessmentModalClose}
      fullWidth={true}
      fullScreen={true}
      maxWidth={'xl'}
    >
      <Toolbar disableGutters className={classes.ODAModalToolbarStyle}>
        {onDemandAsmntState.step[portfolio?.key] ===
        PAGE_CON.ON_DEMAND_ASMNT_REQUEST_DETAIL ? (
          <div className={classes.ODAModalRequestTitle}>
            <div>
              On-Demand Assessment Request for {companies.length}{' '}
              {companies.length > 1 ? 'Companies' : 'Company'} (
              {getODAFormattedValue() || 0} Investments)
            </div>
            <ul className={classes.ODAModalFilterList}>
              {filters.map(({ key, value }) => (
                <li className={classes.ODAModalFilterItem} key={key}>
                  {key}: <span className={classes.p2Typography}>{value}</span>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className={classes.ODAModalTitle}>
            Moody's Analytics: Request On-Demand Assessment
          </div>
        )}
        <div
          onClick={onDemandAssessmentModalClose}
          className={classes.ODAModalCloseStyle}
          aria-hidden={true}
        >
          <div className={classes.ODAModalEscapeStyle}> ESC </div>
          <IconClose />
        </div>
      </Toolbar>
      <DialogContent id="div-mainlayout">
        <ReportTemplate
          report={onDemandAssessmentReport()}
          maxWidth={'1280px'}
        />
      </DialogContent>
    </Dialog>
  )
}
export default OnDemandAssessmentModal
