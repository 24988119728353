import { makeStyles } from '@material-ui/core/styles'
import { PAGE_CON } from 'constant/constants'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

const drawerWidth = PAGE_CON.PAGE_LEFT_MARGIN

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme?.palette?.background?.dark,
    minHeight: '100%',
    paddingBottom: theme?.spacing(3)
  },
  content: {
    flexGrow: 1
  },
  container: {
    marginLeft: drawerWidth - 20,
    paddingRight: 0,
    paddingLeft: '20px',
    marginTop: '70px'
  },
  parentContainer: {
    minWidth: 150
  },
  header: {
    ...textTheme.typography.p2,
    lineHeight: '14.4px',
    fontWeight: 375,
    color: themeColorPalette.palette.sys_trad.text.main,
    fontSize: '12px'
  },
  subHeader: {
    ...textTheme.typography.p3,
    lineHeight: '12px',
    fontWeight: 350,
    color: themeColorPalette.palette.sys_trad.text2.main,
    fontSize: '10px',
    paddingBottom: '5px',
    borderBottom: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
    height: 28,
    display: 'flex',
    alignItems: 'end'
  },
  itemStyle: {
    paddingTop: '12px',
    paddingBottom: '12px',
    borderBottom: '1px solid ' + themeColorPalette.palette.sys_trad.border.main
  },
  title: {
    paddingBottom: 10,
    fontSize: 12,
    lineHeight: '120%'
  },
  icon: {
    width: 16,
    height: 16,
    marginBottom: 6
  },
  prevButtonContainer: {
    paddingTop: 10
  },
  redirectIcon: {
    fontSize: '1rem',
    cursor: 'pointer',
    marginLeft: '2px',
    float: 'right',
    fill: 'none'
  },
  Container: {
    width: 200,
    marginTop: (props) => props.containerMarginTop || 0
  },
  subTextStyle: {
    ...textTheme.typography.p3,
    color: textTheme.palette.sys_trad.text2.main,
    fontWeight: 350,
    fontSize: 10,
    paddingLeft: 36,
    lineHeight: '12px'
  },
  downloadIcon: {
    width: 16,
    height: 16,
    marginBottom: 6
  },
  ButtonContainer: {
    padding: '10px 0 10px 0'
  },
  infoNote: {
    ...textTheme.typography.p5,
    fontWeight: 350,
    color: textTheme.palette.sys_trad.text2.main
  },
  ActionGrid: {
    alignItems: 'end'
  },
  ActionHeader: {
    ...textTheme.typography.p2,
    lineHeight: '12px',
    fontWeight: 350,
    fontStyle: 'normal',
    color: themeColorPalette.palette.sys_trad.text.main,
    fontSize: '10px',
    paddingBottom: '12px',
    marginTop: 40
  },
  ActionItemStyle: {
    paddingBottom: '12px'
  },
  methodologiesButtonContainer: {
    paddingTop: 10,
    '& > .MuiButton-root:hover': {
      borderColor: themeColorPalette.palette.sys_trad.hover2.main
    }
  },
  documentListLinks: {
    listStyleType: 'none',
    '& li': {
      padding: '3px 0',
      '& a': {
        color: themeColorPalette.palette.sys_trad.text.main,
        textDecoration: 'underline'
      }
    }
  },
  methologiesSubtitle: {
    ...textTheme.typography.h2,
    color: themeColorPalette.palette.sys_trad.text.main,
    fontSize: '16px',
    marginTop: '-5px',
    marginBottom: '8px'
  },
  dialogActionCustomStyle: {
    borderTop: '1px solid' + themeColorPalette.palette.sys_trad.border.main,
    padding: '16px 24px'
  },
  downloadZipIcon: {
    height: '0.7em',
    marginLeft: '-3px'
  }
}))
