import { PAGE_CON } from 'constant/constants'
import FormControl from '@material-ui/core/FormControl'
import LoadingMask from 'utils/loadingMask'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import Select from '@material-ui/core/Select'
import { CustomInput, useStyles } from './styles'

/**
 * @visibleName Dropdown
 */

const DropdownField = (props) => {
  const options = props.dropdownOptions

  useEffect(() => {
    if (props.multiSelect) setSelectedValue(props.dropdownDefaultSelction)
  }, [props.dropdownDefaultSelction])

  var allOptions = options.map((item) => {
    let displayTextFormatted = item.displayText.replace(/\s/g, '_')
    return (
      <MenuItem
        {...(props.heap_id ? { heap_id: props.heap_id } : {})}
        key={item.key}
        value={item.key}
        disabled={item.disabled ? item.disabled : false}
        id={`${props.id}_${displayTextFormatted}_id`}
        title={item[PAGE_CON.DROPDOWN_DISPLAY_COLUMN]}
      >
        {item[PAGE_CON.DROPDOWN_DISPLAY_COLUMN]}
      </MenuItem>
    )
  })

  const optionLength = allOptions.length
  const classes = useStyles({ optionLength, ...props })
  const [selectedValue, setSelectedValue] = React.useState(
    props.dropdownDefaultSelction
  )

  const onChange = (event) => {
    let selectedVal = event.target.value

    if (props.multiSelect) {
      selectedVal = selectedVal.filter(
        (val) =>
          ((val === '' && !props.labelTextDisabled) || val !== '') &&
          val !== PAGE_CON.FOOTER_COMPONENT
      )
      if (selectedVal.length === 0) selectedVal = ['']
    } else {
      selectedVal =
        event.target.value === PAGE_CON.FOOTER_COMPONENT
          ? selectedValue
          : event.target.value
    }

    setSelectedValue(selectedVal)
    if (props.onChange) props.onChange(event)
  }

  function onClose() {
    if (props.onClose) {
      props.onClose(selectedValue)
    }
  }

  const getText = (selectedkey) => {
    let filterResult = props.dropdownOptions.filter(
      (x) => x.key === selectedkey
    )
    if (filterResult && filterResult.length > 0)
      return filterResult[0][PAGE_CON.DROPDOWN_DISPLAY_COLUMN]
    return ''
  }

  const getLabelText = (val) => {
    let text = ''
    if (Array.isArray(val)) {
      let selectedText = []
      let removeText = 'shouldBeRemoved'
      val.map((key) => {
        let sText =
          key === ''
            ? val.length === 1
              ? props.labelText
              : !props.labelTextDisabled
              ? props.labelText
              : removeText
            : getText(key)
        if (sText !== '') selectedText.push(sText)
        return ''
      })
      text = selectedText.filter((x) => x !== removeText).join(', ')
    } else {
      text = getText(val)
    }
    return text
  }

  const displayLoadingText =
    props.dropdownDefaultSelction !== ''
      ? getText(props.dropdownDefaultSelction)
      : props.labelText && props.labelText !== ''
      ? props.labelText
      : props.alternateLabelText && props.alternateLabelText !== ''
      ? props.alternateLabelText
      : PAGE_CON.DROPDOWN_LOADING_TEXT

  return (
    <FormControl
      className={classes.formControl}
      {...(props.heap_id ? { heap_id: props.heap_id } : {})}
      disabled={props.disabled}
    >
      {!props.isLoading ? (
        <Select
          autoWidth
          id={props.id}
          onChange={onChange}
          title={
            props.dropdownDefaultSelction === ''
              ? props.alternateLabelText
                ? props.alternateLabelText
                : props.labelText
              : getLabelText(props.dropdownDefaultSelction)
          }
          value={
            props.multiSelect
              ? selectedValue
              : !props.saveValueUsingExternalState
              ? selectedValue
              : props.dropdownDefaultSelction
          }
          displayEmpty
          multiple={props.multiSelect}
          input={<CustomInput />}
          inputProps={{
            classes: { icon: classes.icon },
            renderValue: (val) =>
              val === ''
                ? props.alternateLabelText
                  ? props.alternateLabelText
                  : props.labelText
                : getLabelText(val)
          }}
          className={classes[props.dropDownClassName]}
          onClose={onClose}
          defaultValue=""
          MenuProps={{
            classes: {
              list: classes.dropdownMenuStyle,
              paper: classes.dropdownPaperStyle
            },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            }
          }}
        >
          {props.labelText && props.labelText !== '' && (
            <MenuItem key={''} value={''} disabled={props.labelTextDisabled}>
              {props.labelText}
            </MenuItem>
          )}
          {allOptions}
          {props.footerComponent && (
            <MenuItem
              key={PAGE_CON.FOOTER_COMPONENT}
              value={PAGE_CON.FOOTER_COMPONENT}
              onClick={props.onClickFooterComponent}
            >
              {props.footerComponent}
            </MenuItem>
          )}
        </Select>
      ) : (
        <Select
          autoWidth
          id={props.id}
          displayEmpty
          value={''}
          input={<CustomInput />}
          inputProps={{
            classes: { icon: classes.icon },
            // eslint-disable-next-line no-unused-vars
            renderValue: (val) =>
              props.dropdownDefaultSelction === ''
                ? props.alternateLabelText
                  ? props.alternateLabelText
                  : props.labelText && props.labelText !== ''
                  ? props.labelText
                  : PAGE_CON.DROPDOWN_LOADING_TEXT
                : getLabelText(props.dropdownDefaultSelction)
          }}
          className={classes[props.dropDownClassName]}
          defaultValue=""
          MenuProps={{
            classes: {
              list: classes.dropdownMenuStyle,
              paper: classes.dropdownPaperStyle
            },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            }
          }}
        >
          {
            <MenuItem key={''} value={''} disabled={true}>
              {displayLoadingText}
            </MenuItem>
          }
          <MenuItem key={'loading'} value={'loading'} disabled={true}>
            <LoadingMask height={24} width={50} />
          </MenuItem>
        </Select>
      )}
    </FormControl>
  )
}

DropdownField.propTypes = {
  /** Dropdown id */
  id: PropTypes.string,
  /** Options in dropdown */
  dropdownOptions: PropTypes.any,
  /** onChange */
  onChange: PropTypes.func,
  /** The Key of the default selected option for dropdown (must match a key in dropdownOptions object ) */
  dropdownDefaultSelction: PropTypes.any,
  /** If true will allow multiple item selection in dropdown */
  multiSelect: PropTypes.bool,
  /** Max width for dropdown */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** dropdown will show vertical scrollbar once items is more than displayScrollAfter*/
  displayScrollAfter: PropTypes.number,
  /** Show label for dropdown */
  labelText: PropTypes.string,
  /** call onClose function */
  onClose: PropTypes.func,
  /** display component in footer */
  footerComponent: PropTypes.any,
  /** unselected color of filter */
  unSelectedColor: PropTypes.string,
  /** can label text be disabled */
  labelTextDisabled: PropTypes.bool,
  /** display alternateLabelText on selection of labelText */
  alternateLabelText: PropTypes.string,
  /** if true will show selected text using passed in state variable under dropdownDefaultSelction */
  saveValueUsingExternalState: PropTypes.bool,
  /** click event for foot component */
  onClickFooterComponent: PropTypes.any,
  /** Style color for Impact Dropdown */
  impactColor: PropTypes.string,
  /** Disabled */
  disabled: PropTypes.bool,
  /** classname for drop down */
  dropDownClassName: PropTypes.string
}

DropdownField.defaultProps = {
  multiSelect: false,
  dropDownClassName: 'dropdownStyle'
}

export default DropdownField
