import { PAGE_CON } from './pageCon'

export const carbonFootPrintCalculationOptions = [
  {
    id: 'investment_value',
    label: 'Investment Value',
    pageLabel: 'Investment',
    checked: true
  },
  {
    id: 'enterprise_value',
    label: 'Enterprise Value Including Cash',
    pageLabel: 'EVIC',
    checked: false
  },
  {
    id: 'market_capitalization',
    label: 'Market Capitalization',
    pageLabel: 'Market Capitalization',
    checked: false
  },
  {
    id: 'total_assets',
    label: 'Total Assets',
    pageLabel: 'Total Assets',
    checked: false
  }
]

export const predictedScoresCalculationOptions = [
  {
    id: 'esgasmt',
    label: PAGE_CON.DISPLAY_ESG_PREDICTED_SCORES_LABEL,
    checked: false,
    display: false
  },
  {
    id: 'carbonfootprint',
    label: PAGE_CON.CARBON_FOOTPRINT_PREDICTED_DATA_LABEL,
    checked: false,
    display: true
  }
]

export const filterOptions = [
  { displayText: 'Top 5', key: PAGE_CON.IMPACT_KEY_TOP_5 },
  { displayText: 'Top 10', key: PAGE_CON.IMPACT_KEY_TOP_10 },
  { displayText: 'Bottom 5', key: PAGE_CON.IMPACT_KEY_BOTTOM_5 },
  { displayText: 'Bottom 10', key: PAGE_CON.IMPACT_KEY_BOTTOM_10 }
  // { displayText: 'Top 10% of Investment', key: PAGE_CON.IMPACT_KEY_TOP_10_PCT },
  // { displayText: 'Bottom 10% of Investment', key: PAGE_CON.IMPACT_KEY_BOTTOM_10_PCT }
]

export const entityFilterOptions = [
  {
    displayText: 'Show 10 largest investments',
    key: PAGE_CON.ENTITY_KEY_TOP_10
  },
  {
    displayText: 'Show 20 largest investments',
    key: PAGE_CON.ENTITY_KEY_TOP_20
  }
]

export const REG_REPORTING_TYPE = {
  sfdr: 'sfdr',
  euTaxonomy: 'eu_taxonomy',
  onDemandAsmnt: 'on_demand_assessment'
}
