import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { useStyles } from './styles'

function getDisplayText(content, data, dataField) {
  let displayText = ''
  if (data) {
    if (content && content.label && data[dataField || content.dataField]) {
      displayText = content.label + data[dataField || content.dataField]
    } else {
      displayText = data[dataField || content.dataField]
    }
  }
  return displayText
}

/**
 * @visibleName Platform Search Card Info Section
 */
const PlatformSearchCardInfoSection = (props) => {
  const { id, contentInfo, data } = props
  let classes = useStyles(props)

  return (
    <Box
      id={id}
      key={id}
      className={classes.root}
      heap_id="search"
      heap_entity={data?.entityName
        ?.replace(/<mark.*?>/gi, '')
        .replace(/<\/mark>/gi, '')}
    >
      {contentInfo &&
        contentInfo.length > 0 &&
        contentInfo.map((listContent, index) => {
          let text = getDisplayText(listContent, data)
          let parentText = getDisplayText(listContent, data, 'parentEntityName')

          let toolTipText = ''
          let parentToolTipText = ''
          if (listContent.showToolTip) {
            toolTipText = text
              .replace(/<mark.*?>/gi, '')
              .replace(/<\/mark>/gi, '')
            parentToolTipText = parentText
              ? parentText.replace(/<mark.*?>/gi, '').replace(/<\/mark>/gi, '')
              : ''
          }

          let markupText = { __html: text }
          let parentMarkupText = listContent.label
            ? null
            : { __html: parentText }
          return (
            <Box
              key={`innerBox${index}`}
              className={clsx(
                listContent.className
                  ? classes[listContent.className]
                  : classes.textStyle
              )}
            >
              <span className={clsx(classes.entityName, 'Wrapper')}>
                <span
                  className={clsx(classes.entityName, 'ellipsis')}
                  title={toolTipText}
                  dangerouslySetInnerHTML={markupText}
                />
                {parentToolTipText ? (
                  <span
                    className={clsx(
                      classes.entityName,
                      'ellipsis',
                      'CompanyName'
                    )}
                  >
                    (
                    <span
                      title={parentToolTipText}
                      dangerouslySetInnerHTML={parentMarkupText}
                    />
                    )
                  </span>
                ) : null}
              </span>
            </Box>
          )
        })}
    </Box>
  )
}

PlatformSearchCardInfoSection.propTypes = {
  /** contentInfo will have text and date details */
  contentInfo: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      dataField: PropTypes.string,
      labelType: PropTypes.oneOf(['Date', 'Radio', 'Text']),
      showToolTip: PropTypes.bool
    })
  ),
  /** Data to be consumed by this component  */
  data: PropTypes.any,
  /** id for Mini Card Info component */
  id: PropTypes.string,
  /** Max width for cardInfoSection */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default PlatformSearchCardInfoSection
