import React, { useContext } from 'react'
import * as S from '../dashboardComponents/index.jsx'
import Constant from 'constant'
import { MultiSelector } from 'components'
import { GlobalContext } from 'context/GlobalStateProvider'

export const handleItemClick = (
  key,
  handleHeatmapResearchLinesSelected,
  researchLinesSelected
) => {
  const newSelectedResearchLines = [...researchLinesSelected].filter(
    (e) => e !== undefined
  )
  if (
    newSelectedResearchLines.includes(key) &&
    newSelectedResearchLines.length > 1
  ) {
    const removeIndex = newSelectedResearchLines.indexOf(key)
    if (removeIndex !== -1 && newSelectedResearchLines.length === 2) {
      newSelectedResearchLines.splice(removeIndex, 1)
    }
  } else {
    if (newSelectedResearchLines.length === 2) {
      newSelectedResearchLines.shift()
    }
    if (newSelectedResearchLines[0] !== key) {
      newSelectedResearchLines.push(key)
    }
  }

  handleHeatmapResearchLinesSelected &&
    handleHeatmapResearchLinesSelected(newSelectedResearchLines)
}

const HeatmapHeader = ({
  entitlements,
  handleHeatmapResearchLinesSelected,
  researchLinesSelected
}) => {
  const context = useContext(GlobalContext)
  const [dashboardState] = context.globalDashboard

  const researchLines = Constant.Dashboard.getResearchLines(entitlements)
    .filter((researchline) => researchline.isEntitled)
    .filter(({ heatMap }) => heatMap)
    .filter(({ header }) => !header)

  const actionsDisabled =
    dashboardState.dashboardHeatmapDescriptionLeft.isLoading ||
    dashboardState.dashboardHeatmapDescriptionRight.isLoading ||
    dashboardState.dashboardHeatmapTable.isLoading ||
    researchLines.length === 1

  const items = researchLines.map(({ key, display_heatmap_label }) => ({
    key,
    active: researchLinesSelected.includes(key),
    label: display_heatmap_label
  }))

  return (
    <S.HeatmapHeader>
      <S.SectionTitle>Analyze Companies by Range</S.SectionTitle>
      <S.HeatmapSelector>
        {researchLines.length >= 2 && <p>Select two:</p>}
        <MultiSelector
          heap_id="heatmap"
          items={items}
          handleItemClick={(key) =>
            handleItemClick(
              key,
              handleHeatmapResearchLinesSelected,
              researchLinesSelected
            )
          }
          disabled={actionsDisabled}
        />
      </S.HeatmapSelector>
    </S.HeatmapHeader>
  )
}

HeatmapHeader.propTypes = {}

export default HeatmapHeader
