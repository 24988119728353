import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconAdd = (props) => (
  <SvgIcon {...props}>
    {props.ispressed === 'true' ? (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 23.5H22C22.8284 23.5 23.5 22.8284 23.5 22V2C23.5 1.17157 22.8284 0.5 22 0.5H2C1.17157 0.5 0.5 1.17157 0.5 2V22C0.5 22.8284 1.17157 23.5 2 23.5Z"
          fill="#EBF4FA"
          stroke="#DCE1E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5 17.5C11.5 17.7761 11.7239 18 12 18C12.2761 18 12.5 17.7761 12.5 17.5V12.5H17.5C17.7761 12.5 18 12.2761 18 12C18 11.7239 17.7761 11.5 17.5 11.5H12.5V6.5C12.5 6.22386 12.2761 6 12 6C11.7239 6 11.5 6.22386 11.5 6.5V11.5H6.5C6.22386 11.5 6 11.7239 6 12C6 12.2761 6.22386 12.5 6.5 12.5H11.5V17.5Z"
          fill="#26415E"
        />
      </svg>
    ) : props.ishover === 'true' ? (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 23.5H22C22.8284 23.5 23.5 22.8284 23.5 22V2C23.5 1.17157 22.8284 0.5 22 0.5H2C1.17157 0.5 0.5 1.17157 0.5 2V22C0.5 22.8284 1.17157 23.5 2 23.5Z"
          fill="#EBF4FA"
          stroke="#DCE1E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5 17.5C11.5 17.7761 11.7239 18 12 18C12.2761 18 12.5 17.7761 12.5 17.5V12.5H17.5C17.7761 12.5 18 12.2761 18 12C18 11.7239 17.7761 11.5 17.5 11.5H12.5V6.5C12.5 6.22386 12.2761 6 12 6C11.7239 6 11.5 6.22386 11.5 6.5V11.5H6.5C6.22386 11.5 6 11.7239 6 12C6 12.2761 6.22386 12.5 6.5 12.5H11.5V17.5Z"
          fill="#5379A3"
        />
      </svg>
    ) : (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 23.5H22C22.8284 23.5 23.5 22.8284 23.5 22V2C23.5 1.17157 22.8284 0.5 22 0.5H2C1.17157 0.5 0.5 1.17157 0.5 2V22C0.5 22.8284 1.17157 23.5 2 23.5Z"
          stroke="#DCE1E6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5 17.5C11.5 17.7761 11.7239 18 12 18C12.2761 18 12.5 17.7761 12.5 17.5V12.5H17.5C17.7761 12.5 18 12.2761 18 12C18 11.7239 17.7761 11.5 17.5 11.5H12.5V6.5C12.5 6.22386 12.2761 6 12 6C11.7239 6 11.5 6.22386 11.5 6.5V11.5H6.5C6.22386 11.5 6 11.7239 6 12C6 12.2761 6.22386 12.5 6.5 12.5H11.5V17.5Z"
          fill="#26415E"
        />
      </svg>
    )}
  </SvgIcon>
)

IconAdd.displayName = 'IconAdd'
IconAdd.muiName = 'SvgIcon'

export default IconAdd
