import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconDashboard = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2H2L2 5H6V2ZM2 1C1.44772 1 1 1.44772 1 2V5C1 5.55228 1.44772 6 2 6H6C6.55228 6 7 5.55228 7 5V2C7 1.44772 6.55228 1 6 1H2Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 9H2L2 14H6V9ZM2 8C1.44772 8 1 8.44772 1 9V14C1 14.5523 1.44772 15 2 15H6C6.55228 15 7 14.5523 7 14V9C7 8.44772 6.55228 8 6 8H2Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 2H10V7H14V2ZM10 1C9.44772 1 9 1.44772 9 2V7C9 7.55228 9.44772 8 10 8H14C14.5523 8 15 7.55228 15 7V2C15 1.44772 14.5523 1 14 1H10Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 11H10V14H14V11ZM10 10C9.44772 10 9 10.4477 9 11V14C9 14.5523 9.44772 15 10 15H14C14.5523 15 15 14.5523 15 14V11C15 10.4477 14.5523 10 14 10H10Z"
      fill="#26415E"
    />{' '}
  </SvgIcon>
)
IconDashboard.displayName = 'IconDashboard'
IconDashboard.muiName = 'SvgIcon'

export default IconDashboard
