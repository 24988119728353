import { Backdrop, Grid } from '@material-ui/core'
import { GlobalContext } from 'context/GlobalStateProvider'
import clsx from 'clsx'
import {
  IconDownload,
  Panel,
  PrimaryButton,
  PrimaryPopover,
  RadioButton
} from 'components'
import { REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import { useContext, useState } from 'react'
import theme from 'theme/theme'
import { getMonthDayYear } from 'utils/Date'
import LoadingMask from 'utils/loadingMask'
import formatDecimalValues from 'utils/formatDecimalValues'
import { exportPortfolioData } from '../ReportingServiceHelper'
import EmptyODAPortfolios from './EmptyODAPortfolios'
import * as S from './styles'
import CompaniesAndInvestmentsHeader from '../../dashboard/viewCompaniesInvestmentPortfolio/header'
import PortfolioDetailPanelTable from './PortfolioDetailPanelTable'

const OnDemandPortfolios = ({
  onPortfolioSelection,
  portfolios,
  isExportEntitled
}) => {
  const classes = S.useStyles({
    subHeaderAlignItems: 'end',
    coverageWidth: 50,
    itemHeight: 45,
    dateWidth: 115
  })

  const [isExportLoading, setIsExportLoading] = useState(false)
  const [isExportError, setIsExportError] = useState(false)
  const [openPanelDetails, setOpenPanelDetails] = useState(false)
  const [currentPortfolio, setCurrentPortfolio] = useState({})
  const [currentView, setCurrentView] = useState('score_type')
  const [reportingService, reportingServiceDispatch] =
    useContext(GlobalContext).globalReportingService
  const hasData = !!reportingService.coverageDetails.data.filter(
    (sections) =>
      Object.keys(sections).filter(
        (section) => Object.keys(sections[section]).length
      ).length
  ).length
  const contentInfo = [
    {
      buttonKey: 'score_type',
      labelText: 'Score Type',
      buttonDisabled: reportingService.coverageDetails.isLoading || !hasData
    },
    {
      buttonKey: 'sector',
      labelText: 'Sector',
      buttonDisabled: reportingService.coverageDetails.isLoading || !hasData
    },
    {
      buttonKey: 'region',
      labelText: 'Region',
      buttonDisabled: reportingService.coverageDetails.isLoading || !hasData
    }
  ]

  const onSelectPortfolio = (selectedItem) => {
    onPortfolioSelection(selectedItem, true)
  }

  const downloadOnDemandExcel = async (portfolio) => {
    setIsExportLoading(true)
    const exportURL = await exportPortfolioData(portfolio)
    setIsExportLoading(false)
    if (exportURL && exportURL.url) {
      window.location = exportURL.url
    } else {
      setIsExportError(true)
    }
  }

  const onPopoverErrorClose = () => {
    setIsExportError(false)
  }

  const onViewDetail = ({ portfolioName, ...portfolio }) => {
    setOpenPanelDetails(true)
    setCurrentPortfolio({ ...portfolio, name: portfolioName })
  }

  const onPanelClose = () => {
    setOpenPanelDetails(false)
    setCurrentView('score_type')
    setCurrentPortfolio({})
    reportingServiceDispatch({
      type: REDUCER_TYPE.RESET_REPORTING_SERVICE_SECTION,
      sectionName: REDUCER_STATE.REPORTING_COVERAGE_DETAILS
    })
  }

  return (
    <>
      <PrimaryPopover
        id="export-popover-error-test-id"
        alertError={true}
        alertHeight="20%"
        alertText={'Export failed because of an issue, please try again later.'}
        alertOpen={isExportError}
        onAlertClose={onPopoverErrorClose}
        alertPosition="center"
        alertTop="55px"
        marginLeft="16px"
      />
      <Grid container direction="column" style={{ marginBottom: 10 }}>
        <Grid item className={classes.header}>
          Select Portfolio
        </Grid>

        {portfolios.length ? (
          <>
            <Grid container>
              <Grid
                className={clsx(
                  classes.subHeader,
                  classes.customODAPortfolioName
                )}
                style={{ paddingLeft: '0px' }}
                item
                xs={3}
              >
                Portfolio
              </Grid>
              <Grid
                item
                xs={3}
                className={clsx(classes.subHeader, classes.customODADate)}
              >
                <span className={classes.dateColumn}>Last Uploaded</span>
              </Grid>
              <Grid item xs={1} className={classes.subHeader}>
                <span className={classes.coverageColumn}>Coverage</span>
              </Grid>
              <Grid item xs={2} className={classes.subHeader}>
                <span className={classes.onDemandColumn}>
                  On-Demand Assessment Eligible
                </span>
              </Grid>
              <Grid item xs={3} className={classes.subHeader}></Grid>
            </Grid>
            {portfolios.map((portfolio, index) => {
              const { key, portfolioName, date, checked } = portfolio
              const portfolioKey = key
              const portfolioDate = date
              const portfolioDisabled =
                portfolio.on_demand_coverage === 0 && portfolio.coverage === 0
              return (
                <Grid
                  container
                  className={classes.itemStyle}
                  key={`portfolio-${index}=${portfolioKey}`}
                >
                  <Grid
                    className={clsx(
                      classes.portfolioItem,
                      classes.customODAPortfolioName
                    )}
                    item
                    xs={3}
                  >
                    <RadioButton
                      labelWidth={'250px'}
                      id={portfolioKey}
                      isChecked={checked}
                      handleRadioChange={onSelectPortfolio}
                      isDisabled={portfolioDisabled}
                      radioLabelText={portfolioName}
                      labelClass={'portfolioLabel'}
                    />
                  </Grid>
                  <Grid
                    className={clsx(
                      classes.portfolioItem,
                      checked && classes.isChecked,
                      classes.customODADate
                    )}
                    item
                    xs={3}
                  >
                    <span className={classes.dateColumn}>
                      {getMonthDayYear(portfolioDate)}
                    </span>
                  </Grid>
                  <Grid
                    className={clsx(
                      classes.portfolioItem,
                      checked && classes.isChecked
                    )}
                    item
                    xs={1}
                  >
                    <span className={classes.coverageColumn}>
                      {formatDecimalValues(
                        portfolio.coverage,
                        {
                          isDecimal: true,
                          isPercentage: true,
                          keepRawValue: true
                        },
                        0.01,
                        '<0.01%'
                      )}
                    </span>
                  </Grid>
                  <Grid
                    className={clsx(
                      classes.portfolioItem,
                      checked && classes.isChecked
                    )}
                    item
                    xs={2}
                  >
                    <span className={classes.onDemandColumn}>
                      {formatDecimalValues(
                        portfolio.on_demand_coverage,
                        {
                          isDecimal: true,
                          isPercentage: true,
                          keepRawValue: true
                        },
                        0.01,
                        '<0.01%'
                      )}
                    </span>
                  </Grid>
                  <Grid item xs={3}>
                    <S.ODActionContainer>
                      <PrimaryButton
                        buttonText="View Detail"
                        buttonClass="buttonSlim"
                        buttonDisabled={portfolioDisabled}
                        onClick={() => {
                          onViewDetail(portfolio)
                        }}
                      />
                      <PrimaryButton
                        showTitle={isExportEntitled}
                        tooltipText="Download Portfolio with ESG Scores"
                        buttonDisabled={!isExportEntitled || portfolioDisabled}
                        buttonIconPosition="top"
                        buttonMinWidth="30px"
                        buttonMarginLeft="8px"
                        buttonClass="buttonSlim"
                        sync={true}
                        buttonIcon={
                          <IconDownload
                            viewBox="0 0 16 16"
                            fillcolor={
                              !isExportEntitled || portfolioDisabled
                                ? theme.palette.sys_trad.text2.main
                                : theme.palette.sys_trad.main.main
                            }
                            data-testid={'download-ondemand'}
                            className={classes.downloadIcon}
                          />
                        }
                        onClick={() => downloadOnDemandExcel(portfolio)}
                        buttonPadding="0px"
                        buttonWidth="0px"
                      />
                    </S.ODActionContainer>
                  </Grid>
                </Grid>
              )
            })}
            <Panel
              key={'panelcard-test-id-2'}
              openPanel={openPanelDetails}
              titlePadding="12px"
              onPanelClose={onPanelClose}
              topNum={0}
              rightNum={0}
              whiteBg={true}
              backDropInvisible={false}
              contentInfo={[]}
              companiesViewByValue={false}
              handleCompaniesViewByClick={() => {}}
              closeSymbol={true}
              panelHeaderComponent={
                <div className={classes.headerContainer}>
                  <CompaniesAndInvestmentsHeader
                    portfolioName={currentPortfolio.name}
                    handleCompaniesViewByClick={(section) => {
                      setCurrentView(section)
                    }}
                    companiesViewByValue={currentView}
                    contentInfo={contentInfo}
                    label="Group By:"
                  />
                  <div className={classes.infoContainer}>
                    <div className={classes.infoBox}></div>
                    Predicted Score
                  </div>
                </div>
              }
              panelContentComponent={
                <PortfolioDetailPanelTable
                  portfolio={currentPortfolio}
                  currentView={currentView}
                  onExport={downloadOnDemandExcel}
                  isExportEntitled={isExportEntitled}
                />
              }
              height="0"
            />
          </>
        ) : (
          <EmptyODAPortfolios />
        )}
      </Grid>
      <S.LoaderBackdrop visible={isExportLoading}>
        <Backdrop open={isExportLoading}>
          <LoadingMask loadText="Exporting" data-testid={'export-loading'} />
        </Backdrop>
      </S.LoaderBackdrop>
    </>
  )
}

export default OnDemandPortfolios
