import Grid from '@material-ui/core/Grid'
import React from 'react'
import Divider from '@material-ui/core/Divider'
import { Stacked } from 'components'
import * as S from '../styles'
import * as BSstyles from './styles'
import Error from 'utils/error'
import { PAGE_CON } from 'constant/constants'

const BrownShareAssessmentUnderlyingDataRiskMetrics = (props) => {
  const classes = S.useStyles(props)
  // const BSclasses = BSstyles.useStyles()
  const UnderlyingData = props.underlyingDataMetrics
  const dataStatus = props.dataStatus
  const maxCompanies = props.totalCompanies || '0'

  const reformatUDMData = () => {
    maxCompanies !== undefined &&
      UnderlyingData?.forEach((metric) => {
        metric?.data?.forEach((data, index) => {
          let totalCompanies = 0
          let totalPct = data?.reduce((total, data) => {
            totalCompanies += data.count
            return total + data.data[0]
          }, 0)
          data.unshift({
            data: totalPct < 100 ? [100 - totalPct] : [0],
            name: 'Not Covered'
          })
          if (index === 0) {
            data[0].count =
              totalPct < 100 ? parseInt(maxCompanies) - totalCompanies : 0
          }
        })
      })

    const GroupedUnderlyingData = UnderlyingData.map((metric) => {
      if (metric.type === 'range') {
        const categoryGrouping = {
          'Not Covered': 'Not Covered',
          '>=50%': 'Major Involvement',
          '33-50%': 'Major Involvement',
          '20-33%': 'Major Involvement',
          '10-20%': 'Minor Involvement',
          '0-10%': 'Minor Involvement',
          '0%': 'No Involvement'
        }

        const groupedRanges = metric.data
          .map((item) =>
            item.reduce((group, { name, count, data }) => {
              const currentGroupName = categoryGrouping[name]
              const currentGroup = group[currentGroupName] || {
                range: [],
                count: 0,
                name,
                data: [0]
              }
              const range = [
                ...currentGroup.range,
                { name, data, count, type: 'range' }
              ]

              return {
                ...group,
                [currentGroupName]: {
                  ...currentGroup,
                  range,
                  count: currentGroup.count + count,
                  data: [currentGroup.data[0] + data[0]]
                }
              }
            }, {})
          )
          .map(Object.values)

        return {
          ...metric,
          data: groupedRanges
        }
      }

      return metric
    })

    return GroupedUnderlyingData
  }

  const underlyingDataMetrics = React.useMemo(() => {
    return {
      data: reformatUDMData()
    }
  }, [dataStatus, maxCompanies])

  const isError = dataStatus === PAGE_CON.ERROR

  return (
    <Grid className={classes.gridUnderlyngDataMetrics} item xs={12} sm={12}>
      <Grid className={classes.gridUnderlyngDataMetrics} item xs={12}>
        {underlyingDataMetrics.data &&
        underlyingDataMetrics.data.length > 0 &&
        maxCompanies !== undefined ? (
          underlyingDataMetrics.data.map((item, index) => {
            return (
              <span key={`${item + index}`}>
                <Grid
                  container
                  className={classes.gridUnderlyngDataMetrics}
                  direction={'row'}
                  spacing={2}
                >
                  <Grid
                    item
                    className={classes.gridUnderlyngDataMetrics}
                    xs={4}
                  >
                    <br />
                    <br />
                    {item.title}
                  </Grid>
                  <Grid
                    className={classes.gridUnderlyngDataMetrics}
                    item
                    xs={8}
                  >
                    <Grid
                      container
                      direction={'row'}
                      spacing={2}
                      className={classes.gridUnderlyngDataMetrics}
                    >
                      <Stacked
                        data={item.data !== undefined ? item.data[1] : []}
                        title={index === 0 ? 'Investment in Category' : '.'}
                        sortToltip={true}
                        barColors={
                          item.type === 'boolean'
                            ? BSstyles.barColorsBool
                            : BSstyles.barColors
                        }
                      />
                      <Stacked
                        data={item.data !== undefined ? item.data[0] : []}
                        title={index === 0 ? 'Companies in Category' : '.'}
                        barColors={
                          item.type === 'boolean'
                            ? BSstyles.barColorsBool
                            : BSstyles.barColors
                        }
                        notPercent={true}
                        sortToltip={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>{' '}
                <br></br>
                {index + 1 !== props.underlyingDataMetrics.length && (
                  <Divider className={classes.divider} />
                )}
                <br></br>
              </span>
            )
          })
        ) : (
          <Error
            id={'underlyingDataMetricsError'}
            hideErrorActionButton={true}
            dataStatus={dataStatus}
            errorText={
              !isError && 'There is no data on which to base our calculations'
            }
            onErrorClick={props.reloadComponent}
            errorOccuredOn={PAGE_CON.BROWN_SHARE_UDM_TITLE}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default BrownShareAssessmentUnderlyingDataRiskMetrics
