import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconSuccess = (props) => (
  <SvgIcon {...props}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={props.colorprimary ? props.colorprimary : '#0F997D'}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8345 5.12836C12.0397 5.31309 12.0564 5.62923 11.8717 5.83449L7.37165 10.8345C7.19446 11.0314 6.89448 11.0559 6.68766 10.8904L4.18766 8.89044C3.97202 8.71793 3.93706 8.40329 4.10957 8.18766C4.28207 7.97203 4.59672 7.93707 4.81235 8.10957L6.94396 9.81485L11.1284 5.16552C11.3131 4.96027 11.6292 4.94363 11.8345 5.12836Z"
      />
      <circle
        cx="8"
        cy="8"
        r="7.5"
        stroke={props.colorprimary ? props.colorprimary : '#0F997D'}
      />
    </svg>
  </SvgIcon>
)
IconSuccess.displayName = 'IconSuccess'
IconSuccess.muiName = 'SvgIcon'

export default IconSuccess
