import { Grid } from '@material-ui/core'
import React from 'react'
import textTheme from 'theme/theme'
import { IconDownload, IconRedirect, PrimaryButton } from 'components'
import { useStyles } from './styles'

const ReportingOptions = ({
  buttonDisabled,
  reportCounts,
  openReportDownload,
  openReportPreviously
}) => {
  const classes = useStyles({
    containerMarginTop: 32
  })

  return (
    <Grid container direction="column" className={classes.Container}>
      <Grid item className={classes.buttonContainer} xs={12}>
        <PrimaryButton
          id="button-download-test-id-1"
          buttonText={`Create ${reportCounts} Report${
            reportCounts > 1 ? 's' : ''
          }`}
          buttonClass="buttonAltPrimary"
          buttonIcon={
            <IconDownload
              fillcolor="white"
              className={classes.downloadIcon}
              viewBox="0 0 16 16"
            />
          }
          buttonIconPosition="top"
          buttonHeight={60}
          buttonDisabled={buttonDisabled}
          onClick={openReportDownload}
          buttonWidth="100%"
          buttonTextClass="reportingTextStyle"
        />
        <div className={classes.ButtonContainer}>
          <PrimaryButton
            id="button-prev-download-test-id-1"
            buttonText="Previously Downloaded"
            buttonIcon={
              <IconRedirect
                className={classes.redirectIcon}
                fillcolor={textTheme.palette.sys_trad.text2.main}
              />
            }
            buttonClass="buttonSlim"
            buttonIconPosition="end"
            buttonWidth="100%"
            onClick={openReportPreviously}
          />
        </div>
        <div className={classes.infoNote}>
          Note: Monetary values in reports will be in EUR
        </div>
      </Grid>
    </Grid>
  )
}

export default ReportingOptions
