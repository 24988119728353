import { Grid, makeStyles } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { createTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { PAGE_CON } from 'constant/constants'
import ImpactTable from './ImpactTable'
import { BarchartWide } from 'components'
import textTheme from 'theme/theme'
import { useMediaQuery } from '@material-ui/core'
import { multiColumnSort } from 'utils/Sort'
import Error from 'utils/error'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 900,
      md: 1000,
      lg: 1200,
      xl: 1536
    }
  }
})

const cardHeaderBarChart = {
  ...textTheme.typography.p2,
  paddingBottom: '8px',
  lineHeight: '16px'
}

const useStyles = makeStyles(() => ({
  impactDropdown: {
    marginTop: '16px',
    marginBottom: '12px'
  },
  impactTable: {
    paddingRight: '16px'
  }
}))

const Impact = (props) => {
  const classes = useStyles()
  const smallResize = useMediaQuery(theme.breakpoints.down('sm'))
  const noOfRowsToShow = 20

  let posCategoryCount = props.posBarChartData[0].data.length
  let negCategoryCount = props.negBarChartData[0].data.length

  const isPosImpactHasData = () => {
    let hasData = false
    if (
      props.posBarChartData.length === 2 &&
      (props.posBarChartData[0].data.length > 0 ||
        props.posBarChartData[1].data.length > 0) &&
      props.positiveImpactData.length > 0
    )
      hasData = true
    return hasData
  }

  const isNegImpactHasData = () => {
    let hasData = false
    if (
      props.negBarChartData.length === 2 &&
      (props.negBarChartData[0].data.length > 0 ||
        props.negBarChartData[1].data.length > 0) &&
      props.negativeImpactData.length > 0
    )
      hasData = true
    return hasData
  }

  function sortData(data) {
    let returnData = multiColumnSort(data, [
      ['investment_pct', 'DESC'],
      ['company_name', 'ASC', 'string', true]
    ])

    return returnData
  }

  return (
    <MuiThemeProvider theme={theme}>
      {!smallResize ? (
        <>
          {!isPosImpactHasData() ? (
            <Grid item xs={12} sm={6} className={classes.impactTable}>
              <Error
                id={'chartError'}
                dataStatus={PAGE_CON.SUCCESS}
                errorOccuredOn={PAGE_CON.POS_IMPACT_TABLE_TITLE}
                height={'100%'}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={4} className={classes.impactTable}>
                <ImpactTable
                  colInfo={props.impactColumns}
                  impactDrawerColumns={props.impactDrawerColumns}
                  title={PAGE_CON.POS_IMPACT_TABLE_TITLE}
                  totalData={sortData(props.positiveImpactData)}
                  tableLength={noOfRowsToShow}
                  tableEditable={false}
                  sumRow={true}
                  filter={props.impactFilter}
                  tableType={'Positive'}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <div style={cardHeaderBarChart}>
                  {PAGE_CON.POS_IMPACT_BAR_TITLE}{' '}
                </div>
                <BarchartWide
                  data={props.posBarChartData}
                  barColors={props.posBarColors}
                  colWidth={posCategoryCount === 2 && 56}
                  chartWidth={172}
                  chartTitle={props.posBarTitle}
                />
              </Grid>{' '}
            </>
          )}

          {!isNegImpactHasData() ? (
            <Grid item xs={12} sm={6} className={classes.impactTable}>
              <Error
                id={'chartError'}
                dataStatus={PAGE_CON.SUCCESS}
                errorOccuredOn={PAGE_CON.NEG_IMPACT_TABLE_TITLE}
              />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} sm={4} className={classes.impactTable}>
                <ImpactTable
                  colInfo={props.impactColumns}
                  impactDrawerColumns={props.impactDrawerColumns}
                  totalData={sortData(props.negativeImpactData)}
                  tableLength={noOfRowsToShow}
                  tableEditable={false}
                  title={PAGE_CON.NEG_IMPACT_TABLE_TITLE}
                  sumRow={true}
                  filter={props.impactFilter}
                  tableType={'Negative'}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <div style={cardHeaderBarChart}>
                  {PAGE_CON.NEG_IMPACT_BAR_TITLE}
                </div>
                <BarchartWide
                  data={props.negBarChartData}
                  barColors={props.negBarColors}
                  colWidth={negCategoryCount === 2 && 56}
                  chartWidth={180}
                  chartTitle={props.negBarTitle}
                />
              </Grid>
            </>
          )}
        </>
      ) : (
        <>
          <Grid id="top-row" container>
            {!isPosImpactHasData() ? (
              <Grid item xs={12} sm={6} className={classes.impactTable}>
                <Error
                  id={'chartError'}
                  dataStatus={PAGE_CON.SUCCESS}
                  errorOccuredOn={PAGE_CON.POS_IMPACT_TABLE_TITLE}
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={6} className={classes.impactTable}>
                  <ImpactTable
                    colInfo={props.impactColumns}
                    impactDrawerColumns={props.impactDrawerColumns}
                    title={PAGE_CON.POS_IMPACT_TABLE_TITLE}
                    totalData={sortData(props.positiveImpactData)}
                    tableLength={noOfRowsToShow}
                    tableEditable={false}
                    sumRow={true}
                    filter={props.impactFilter}
                    tableType={'Positive'}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Grid>
                    <div style={cardHeaderBarChart}>
                      {PAGE_CON.POS_IMPACT_BAR_TITLE}
                    </div>
                    <BarchartWide
                      data={props.posBarChartData}
                      barColors={props.posBarColors}
                      colWidth={posCategoryCount === 2 && 56}
                      chartWidth={172}
                      chartTitle={props.posBarTitle}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {!isNegImpactHasData() ? (
              <Grid item xs={12} sm={6} className={classes.impactTable}>
                <Error
                  id={'chartError'}
                  dataStatus={PAGE_CON.SUCCESS}
                  errorOccuredOn={PAGE_CON.NEG_IMPACT_TABLE_TITLE}
                />
              </Grid>
            ) : (
              <>
                <Grid id="bottom-row" container style={{ marginTop: '40px' }}>
                  <Grid item xs={6} className={classes.impactTable}>
                    <ImpactTable
                      colInfo={props.impactColumns}
                      impactDrawerColumns={props.impactDrawerColumns}
                      totalData={sortData(props.negativeImpactData)}
                      tableLength={noOfRowsToShow}
                      tableEditable={false}
                      title={PAGE_CON.NEG_IMPACT_TABLE_TITLE}
                      sumRow={true}
                      filter={props.impactFilter}
                      tableType={'Negative'}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <div style={cardHeaderBarChart}>
                      {PAGE_CON.NEG_IMPACT_BAR_TITLE}
                    </div>
                    <BarchartWide
                      data={props.negBarChartData}
                      barColors={props.negBarColors}
                      colWidth={negCategoryCount === 2 && 56}
                      chartWidth={172}
                      chartTitle={props.negBarTitle}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </MuiThemeProvider>
  )
}

Impact.propTypes = {
  /** Leaders id */
  id: PropTypes.string,
  /** Data for Positive Impact Bar Chart */
  posBarChartData: PropTypes.any,
  posBarColors: PropTypes.any,
  posBarTitle: PropTypes.string,
  /** Data for Negative Impact Bar Chart */
  negBarChartData: PropTypes.any,
  negBarColors: PropTypes.any,
  negBarTitle: PropTypes.string,
  /** Data for Positive Impact Dist Table */
  positiveImpactData: PropTypes.any,
  /** Data for Negative Impact Dist Table */
  negativeImpactData: PropTypes.any,
  /** Columns for pos/neg Impact Dist Tables */
  impactColumns: PropTypes.any,
  /** Table length for pos/neg Impact Dist Tables */
  tableLength: PropTypes.number,
  /** Number of Categories - represents number of columns on bar chart */
  categoryCount: PropTypes.number,
  /** Impact Filter */
  impactFilter: PropTypes.string
}

export default Impact
