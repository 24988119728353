import { makeStyles } from '@material-ui/core'
import theme from 'theme/theme'
import textTheme from 'theme/theme'

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  rootTROverview: {
    flexGrow: 1,
    background: '#ffff',
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.14)',
    borderRadius: '2px'
  },
  content: {
    flexGrow: 1
  },
  container: {
    marginLeft: -10,
    paddingRight: 0,
    paddingLeft: '24px',
    marginTop: '22px'
  },
  tableTitle: {
    paddingBottom: '5px',
    ...textTheme.typography.h1
  },
  stickyHeader: {
    backgroundColor: theme.palette.white.main,
    boxSizing: 'border-box',
    color: theme.palette.sys_trad.text.main,
    lineHeight: 'normal',
    zIndex: 1,
    boxShadow: 'none'
  },
  containerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 16px 16px',
    marginTop: '24px'
  },
  impactContainerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 0px 16px',
    marginTop: '24px',
    paddingLeft: '16px'
  },
  impactDropdown: {
    marginTop: '16px',
    marginBottom: '12px'
  },
  overviewLinkPadding: {
    marginTop: '16px',
    marginLeft: '-4px'
  },
  linkPadding: {
    marginTop: '8px',
    marginLeft: '-4px'
  },
  linkStyle: {
    ...textTheme.typography.p1,
    color: theme.palette.sys_trad.link.main,
    lineHeight: '16px',
    verticalAlign: 'top'
  },
  regionTitleStyle: {
    top: '16px'
  },
  updatesStyle: {
    paddingLeft: '2px',
    marginTop: '16px'
  },
  updatesTableStyle: {
    marginTop: '12px',
    background: theme.palette.sys_trad.white.main,
    boxShadow: '0px 0px 16px rgba(38, 65, 94, 0.12)',
    boxRadius: '2px',
    boxSizing: 'border-box',
    padding: '12px',
    paddingBottom: '15px',
    paddingTop: '0px'
  },
  cardHeader: {
    ...textTheme.typography.p2,
    paddingBottom: '4px',
    paddingTop: '16px',
    lineHeight: '16px'
  },
  dataMetricbox: {
    marginTop: '12px',
    paddingTop: '10px',
    background: theme.palette.sys_trad.white.main,
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.12)',
    boxRadius: '2px',
    boxSizing: 'border-box',
    padding: '17px'
  },
  dataMetricTitle: {
    marginTop: '16px'
  },
  overViewTextSubtitleBenchmark: {
    ...textTheme.typography.p2,
    color: theme.palette.sys_trad.text.main,
    textAlign: 'left',
    paddingLeft: '7px'
  },
  overViewText: {
    ...textTheme.typography.p3,
    color: theme.palette.sys_trad.text.main,
    marginTop: '12px',
    marginBottom: '12px'
  },
  verticalHeader: {
    ...textTheme.typography.h2,
    fontSize: '16px !important',
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    textAlign: 'right',
    paddingBottom: '16px',
    marginRight: '-12px'
  },
  grid: {
    textAlign: 'left',
    color: theme.palette.sys_trad.main.main
  },
  gridUnderlyngDataMetrics: {
    ...textTheme.typography.p3
  },
  chartBoxFiller: {
    minHeight: '182px',
    maxWidth: '282px',
    background: theme.palette.sys_trad.bg.main
  },
  chartBoxFillerTROverview: {
    minHeight: '182px',
    minWidth: '282px',
    borderRadius: '2px'
  },
  historyBoxFiller: {
    ...textTheme.typography.p2,
    color: theme.palette.sys_trad.text.main,
    minHeight: '190px',
    maxWidth: '282px',
    textAlign: 'left',
    background: theme.palette.sys_trad.bg.main
  },
  historyLabel: {
    padding: '4px 4px 8px',
    boxDecorationBreak: 'clone'
  },
  benchmarkBoxStyle: {
    minHeight: '32px',
    maxWidth: '1148px',
    padding: '0px !important',
    margin: '24px 8px 24px 28px',
    display: 'table',
    backgroundColor: theme.palette.sys_trad.bg.main
  },
  infoBoxStyle: {
    marginLeft: '30px',
    padding: '0px',
    marginBottom: '16px'
  },
  infoBoxStyleTROverview: {
    marginTop: '24px',
    padding: '0px',
    marginBottom: '16px',
    marginLeft: '30px'
  },
  portfolioGrid: {
    padding: '4px 16px 0px 16px !important'
  },
  benchmarkGrid: {
    padding: '0px 16px 24px 16px !important',
    marginTop: '12px !important'
  },
  historyGrid: {
    minWidth: '282px',
    marginTop: '4px',
    marginRight: '-18px',
    paddingLeft: '2px !important'
  },
  distributionGrid: {
    minWidth: '266px',
    marginTop: '4px',
    marginLeft: '8px'
  },
  benchmarkBistributionGrid: {
    minWidth: '266px',
    margin: '4px 0px 16px 8px'
  },
  portfolioInfoBoxStyle: {
    padding: '0px !important',
    margin: '20px 0px 16px 30px'
  },
  benchmarkInfoBoxStyle: {
    marginLeft: '30px',
    padding: '0px !important',
    marginBottom: '16px'
  },
  legendStyle: {
    textAlign: 'left'
  },
  benchmarkTextStyle: {
    ...textTheme.typography.p2,
    color: theme.palette.sys_trad.texthelp.main,
    verticalAlign: 'middle',
    textAlign: 'center',
    display: 'table-cell'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.sys_trad.main.main
  },
  paperTROverview: {
    textAlign: 'center',
    color: theme.palette.sys_trad.main.main
  },
  divider: {
    backgroundColor: theme.palette.sys_trad.border.main
  },
  sectionTitle: {
    ...textTheme.typography.p2,
    textDecoration: 'underline'
  },
  containerCF: {
    paddingLeft: '20px'
  },
  emissionTableTitle: {
    paddingBottom: '5px',
    paddingTop: '40px',
    ...textTheme.typography.h1
  },
  overviewstickyHeader: {
    backgroundColor: theme.palette.white.main,
    boxSizing: 'border-box',
    color: theme.palette.sys_trad.text.main,
    lineHeight: 'normal',
    zIndex: 1,
    boxShadow: 'none',
    marginLeft: '1px'
  },
  containerWrapperCF: {
    marginTop: '12px'
  },
  impactContainerWrapperCF: {
    marginBottom: '12px'
  },
  linkPaddingOVerview: {
    marginTop: '16px',
    marginLeft: '-4px'
  },
  regionStyle: {
    paddingBottom: '10px',
    marginTop: '18px'
  }
}))
