import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  pageHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    margin: 0,
    boxShadow: 'none',
    top: '5%'
  },
  searchResultsGrid: {
    width: '72%',
    overflow: 'auto',
    maxHeight: '236px',
    flexBasis: 'unset'
  },
  entityLabelStyle: {
    padding: '6px 0',
    marginRight: '12px',
    marginLeft: '-3px'
  },
  searchTextClass: {
    ...textTheme.typography.p2,
    marginRight: '12px'
  },
  toolbarStyle: {
    marginLeft: '0px',
    minHeight: '40px',
    marginBottom: '8px',
    display: 'flex',
    justifyContent: 'center'
  },
  uploadGridStyle: {
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.sys_trad.text.main,
    justifyContent: 'center',
    paddingBottom: 8,
    minWidth: '600px'
  },
  notFound: {
    ...textTheme.typography.p3,
    fontWeight: '500',
    lineHeight: '16px',
    color: themeColorPalette.palette.sys_trad.texthelp.main,
    textAlign: 'center'
  },
  itemStyle: {
    minHeight: '212px',
    marginTop: '20px'
  },
  paddingStyle: {
    marginBottom: '16px'
  },
  highlight: {
    backgroundColor: themeColorPalette.palette.sys_trad.highlight.main,
    fontWeight: '400',
    borderRadius: '2px',
    fontFamily: 'WhitneyNarrMedium'
  },
  gridItemFirst: {
    paddingRight: 0,
    borderTop: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
    borderBottom: '1px solid ' + themeColorPalette.palette.sys_trad.border.main
  },
  gridItemLast: {
    paddingRight: 0,
    marginBottom: 8,
    borderBottom: '1px solid ' + themeColorPalette.palette.sys_trad.border.main
  },
  gridItem: {
    paddingRight: 0,
    borderBottom: '1px solid ' + themeColorPalette.palette.sys_trad.border.main
  }
}))
