import { makeStyles } from '@material-ui/core/styles'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'

var is_safari =
  navigator.userAgent.indexOf('Safari') !== -1 &&
  navigator.userAgent.indexOf('Chrome') === -1

export const backdropProps = {
  style: {
    backgroundColor: 'themeColorPalette.palette.sys_trad.main.main',
    opacity: 0.2
  }
}

export const useStyles = makeStyles(() => ({
  drawerToggleSearch: {
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    height: '32px',
    borderRadius: '2px',
    fontSize: '13px !important',
    marginLeft: 8,
    paddingLeft: 8,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    '&.MuiListItem-button': {
      fontSize: '13px !important'
    },
    ...textTheme.typography.p3,
    color: themeColorPalette.palette.sys_trad.main.main,
    fontFamily: 'WhitneyNarrMedium',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  searchDialog: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '12%',
    marginTop: 0,
    marginLeft: is_safari ? 0 : 'none'
  },
  searchDialogTextEntered: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '12%',
    marginTop: 0,
    marginLeft: is_safari ? 0 : 'none'
  },
  topScrollPaper: {
    alignItems: 'flex-start'
  },
  topPaperScrollBody: {
    verticalAlign: 'top'
  },
  dialogContent: {
    paddingTop: 0
  },
  dialogContentStyle: {
    overflowY: 'hidden'
  },
  searchIconStyle: {
    display: 'flex',
    cursor: 'pointer',
    marginRight: '14px',
    padding: '8px 2px 2px 8px',
    borderRadius: '2px',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main
    }
  },
  searchToolbarStyle: {
    minHeight: '48px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  searchToolbarStyleTextEntered: {
    minHeight: '48px',
    display: 'flex',
    justifyContent: 'space-between'
  }
}))
