import { makeStyles } from '@material-ui/core/styles'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: (props) => (props.maxWidth ? props.maxWidth : 'auto'),
    padding: 0,
    margin: 0,
    alignItems: 'left',
    lineHeight: 'normal'
  },
  labelStyle: {
    ...textTheme.typography.p3,
    color: themeColorPalette.palette.sys_trad.text2.main,
    fontSize: '12px',
    lineHeight: '16px',
    fontFamily: 'WhitneyNarrMedium'
  },
  driversContainer: {
    padding: '16px'
  },
  header: {
    paddingBottom: 12
  },
  driversTitle: {
    textAlign: 'left',
    ...theme.typography.h3
  },
  groupButtonContainer: {
    textAlign: 'left'
  },
  subcategoriesContainer: {
    margin: '0px',
    position: 'relative',
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    minHeight: '500px'
  },
  subCategoriesDiv: {
    marginLeft: '60px',
    display: 'flex'
  },
  driverListHeaderContainer: {
    margin: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #333333',
    marginBottom: '29px'
  },
  driverListHeaderTitle: {
    ...theme.typography.h3,
    textAlign: 'left',
    display: 'inline',
    fontSize: '14px',
    padding: '10px 0 10px'
  },
  driverListHeaderIcon: {
    height: '16px',
    width: '16px'
  },
  leftTopLabel: {
    ...textTheme.typography.p5,
    marginTop: '60px',
    textAlign: 'end',
    paddingBottom: '12px',
    color: themeColorPalette.palette.sys_trad.text2.main,
    opacity: 0.5
  },
  leftBottomLabel: {
    ...textTheme.typography.p5,
    marginRight: '5px',
    textAlign: 'end',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    color: themeColorPalette.palette.sys_trad.text2.main,
    opacity: 0.5,
    paddingTop: '12px'
  },
  arrowUp: {
    transform: 'rotate(90deg)',
    position: 'absolute',
    color: themeColorPalette.palette.sys_trad.text2.main,
    opacity: 0.5,
    top: '76px',
    left: '41.5px'
  },
  arrowDown: {
    color: themeColorPalette.palette.sys_trad.text2.main,
    transform: 'rotate(90deg)',
    opacity: 0.5,
    position: 'absolute',
    bottom: '27px',
    left: '41.5px'
  },
  arrow: {
    width: '1px',
    height: '100%',
    left: '45px',
    backgroundColor: themeColorPalette.palette.sys_trad.text2.main,
    transformOrigin: 'bottom left',
    opacity: 0.5,
    position: 'relative',
    '&::before': {
      bottom: 0,
      transform: 'translate(-40%, 50%)',
      borderWidth: '20px 10px 0 10px'
    }
  },
  leftScale: {
    position: 'absolute',
    left: '5px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: '12px'
  },
  rightHeader: {
    display: 'flex',
    ...textTheme.typography.p4,
    fontSize: '10px !important',
    float: 'right',
    fontFamily: 'WhitneyNarrMedium'
  },
  leftHeader: {
    float: 'left',
    marginLeft: '-8px'
  },
  footer: {
    marginLeft: '-8px',
    paddingTop: '12px'
  },
  driversContainerMinWidth: {
    minWidth: '1220px'
  },
  defaultItem: {
    padding: '4px',
    alignItems: 'inherit',
    width: '100%'
  },
  MESGErrorIcon: {
    position: 'relative',
    top: '10px',
    left: '5px'
  },
  MESGContent: {
    padding: '4px',
    alignItems: 'inherit',
    width: '100%'
  },
  titleStyle: {
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.sys_trad.text.main,
    display: 'flex'
  },
  titleWhiteStyle: {
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.white.main,
    display: 'flex'
  },
  titleStyleVE: {
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.sys_trad.main.main,
    marginRight: '10px'
  },
  extremeCaseMaterialityTitle: {
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.sys_trad.main.main,
    marginTop: '7px'
  },
  titleWhiteStyleVE: {
    ...textTheme.typography.p2,
    marginRight: '10px',
    color: themeColorPalette.palette.white.main
  },
  subTitleStyle: {
    ...textTheme.typography.p4,
    fontSize: '10px !important',
    color: themeColorPalette.palette.sys_trad.text.main
  },
  subTitleStyleMESG: {
    ...textTheme.typography.p4,
    fontSize: '10px !important',
    color: themeColorPalette.palette.sys_trad.text.main,
    display: 'flex',
    flexDirection: 'row'
  },
  subTitleWhiteStyleMESG: {
    ...textTheme.typography.p4,
    fontSize: '10px !important',
    display: 'flex',
    color: themeColorPalette.palette.white.main,
    flexDirection: 'row'
  },
  progressStyleWithWarning: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    position: 'relative',
    bottom: '12px',
    marginLeft: '8px',
    flex: '1',
    justifyContent: 'space-between'
  },
  progresStyleWithoutWarning: {
    margin: '5px'
  },
  emptyDescription: {
    padding: '4px',
    alignItems: 'inherit',
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  VEdescription: {
    padding: '4px',
    display: 'flex',
    alignItems: 'inherit',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  VEdescriptionTitleStyleWrapper: {
    marginTop: '7px'
  },
  displayBox: {
    display: 'inherit'
  },
  hideBox: {
    display: 'none'
  },
  iconError: {
    marginTop: '7px'
  }
}))
