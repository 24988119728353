import { makeStyles } from '@material-ui/core/styles'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'

const rowHeight = 24
const topBottomPadding = 4
const isHover = themeColorPalette.palette.sys_trad.hover2.main
const isSelected = themeColorPalette.palette.sys_trad.selected2.main

export const bgColor = themeColorPalette.palette.sys_trad.bg.main

export const useStyles = makeStyles({
  table: {
    width: '100%',
    cursor: 'default',
    borderColor: themeColorPalette.palette.sys_trad.border.main,
    '& tbody>.MuiTableRow-root:hover': {
      backgroundColor: (props) => props.bgColor || isHover
    }
  },
  sumRow: {
    '& td': {
      backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.bg.main
      }
    }
  },
  tableContainer: {
    width: 'auto',
    paddingBottom: (props) => props.tablePadding,
    paddingLeft: (props) => props.tablePadding,
    paddingRight: (props) => props.tablePadding
  },
  headerCell: {
    ...textTheme.typography.p3,
    paddingTop: topBottomPadding,
    paddingBottom: topBottomPadding,
    paddingLeft: '10px',
    paddingRight: '10px',
    backgroundColor: bgColor,
    minHeight: rowHeight,
    color: textTheme.palette.sys_trad.text2.main,
    lineHeight: 'normal',
    '&:first-child': {
      marginLeft: '4px'
    },
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  rowCell: {
    ...textTheme.typography.p3,
    color: textTheme.palette.sys_trad.text.main,
    paddingTop: topBottomPadding,
    paddingBottom: topBottomPadding,
    minHeight: rowHeight,
    paddingLeft: '10px',
    paddingRight: '10px',
    '&:first-child': {
      marginLeft: '4px'
    },
    cursor: (props) => props.showPointer,
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  rowCellIssuerSector: {
    ...textTheme.typography.p3,
    color: textTheme.palette.sys_trad.text.main,
    paddingTop: topBottomPadding,
    paddingBottom: topBottomPadding,
    minHeight: rowHeight,
    paddingLeft: '10px',
    paddingRight: '10px',
    '&:first-child': {
      marginLeft: '4px'
    },
    cursor: (props) => props.showPointer
  },
  rowLongCell: {
    ...textTheme.typography.p3,
    color: textTheme.palette.sys_trad.text.main,
    paddingTop: topBottomPadding,
    paddingBottom: topBottomPadding,
    minHeight: rowHeight,
    paddingLeft: '10px',
    paddingRight: '10px',
    '&:first-child': {
      paddingLeft: '0px'
    },
    width: '25%'
  },
  rowCellEllipse: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: (props) => props.showPointer
  },
  rowCellBrownShare: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '10%'
  },
  rowCellGreenShare: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '60%'
  },
  rowCellSemibold: {
    ...textTheme.typography.p2,
    paddingTop: topBottomPadding,
    paddingBottom: topBottomPadding,
    minHeight: rowHeight,
    paddingLeft: '10px',
    paddingRight: '10px',
    '&:first-child': {
      marginLeft: '4px'
    },
    borderBottom: '1px solid rgba(224, 224, 224, 1)'
  },
  hover: {
    '&:hover': {
      backgroundColor: (props) => props.bgColor || isHover
    }
  },

  tableRowSelected: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: (props) => props.bgColor || isSelected
    }
  },
  emptyTableCell: {
    padding: '0px',
    borderBottom: 'none'
  },
  textContainer: {
    paddingRight: '0px'
  },
  underLineStyle: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  rowBackground: {
    backgroundColor: `${themeColorPalette.palette.proxy.proxy.main} !important`,
    'pointer-events': 'none',
    textDecoration: 'none'
  },
  noRowHighlight: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: 'transparent'
    }
  },
  CompanyName: {
    '&.CompanyNameWrapper': {
      display: 'flex'
    },
    '&.ellipsis': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      float: 'left'
    }
  }
})
