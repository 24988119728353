import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import React from 'react'
import treemap from 'highcharts/modules/treemap.js'
import * as S from './styles'

/**
 * @visibleName Tree Map
 */

treemap(Highcharts)

const TreeMap = (props) => {
  const classes = S.useStyles(props)
  let treeMapData = props.data?.map((item) => ({ ...item }))
  let highVal = 0
  const mappedHighVal = 10
  const lowVal = 3

  treeMapData?.forEach((item) => {
    if (item.value > highVal) highVal = item.value
  })

  treeMapData?.forEach((item) => {
    let newVal = (mappedHighVal / highVal) * item.value
    item.value = newVal + lowVal
  })

  const options = {
    credits: {
      enabled: false
    },
    chart: {
      height: props.height ? props.height : 200,
      width: props.width && props.width,
      margin: [0, 0, 0, 0]
    },
    legend: {
      enabled: false
    },
    series: [
      {
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        borderRadius: 3,
        borderWidth: 2,
        dataLabels: {
          enabled: true,
          align: 'left',
          verticalAlign: 'top',
          style: S.labelStyle,
          color: 'black'
        },
        data: treeMapData
      }
    ],
    title: {
      align: 'left',
      text: ''
    },
    plotOptions: {
      series: {
        cursor: props.onClick ? 'pointer' : 'default',
        events: {
          click: function (e) {
            if (props.onClick) {
              let retObj = {
                name: e.point.name,
                value: e.point.value
              }
              props.onClick(retObj)
            }
          }
        }
      }
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return this.point.name
      }
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.title}>{props.headerText}</div>
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  )
}

TreeMap.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  data: PropTypes.any,
  onClick: PropTypes.func
}

export default React.memo(TreeMap)
