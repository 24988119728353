import React from 'react'
import * as S from './styles'
import PropTypes from 'prop-types'
import { CloseIcon } from 'components'

const BigModal = ({ title, children, closeAction }) => {
  const handleClose = () => closeAction && closeAction()
  return (
    <S.Container onClick={() => handleClose()}>
      <S.Modal>
        <S.TitleContainer>
          {title}
          <span
            aria-hidden={true}
            className="close"
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </span>
        </S.TitleContainer>
        <S.Content>{children}</S.Content>
      </S.Modal>
    </S.Container>
  )
}

BigModal.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  closeAction: PropTypes.func
}

export default BigModal
