import { GlobalContext } from '../../context/GlobalStateProvider'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from '../../constant/constants'
import clsx from 'clsx'
import Controversy from 'views/issuer/Controversy'
import Error from '../../utils/error'
import Grid from '@material-ui/core/Grid'
import LoadingMask from '../../utils/loadingMask'
import Paper from '@material-ui/core/Paper'
import React, { useCallback, useContext } from 'react'
import Banner from 'views/common/Banner/index.jsx'
import { EntityService } from 'services'
import * as S from './styles'

const IssuerOverview = (props) => {
  const classes = S.useStyles(props)
  const [entityState, entityDispatch] = useContext(GlobalContext).globalEntity

  const getScoreData = async (isErrorReload = false) => {
    if (entityState?.entityScore?.dataStatus !== '' && isErrorReload === false)
      return

    entityDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.ENTITY_SCORE
    })
    let scoreData = {}
    try {
      const data = await EntityService.getIssuerSummary()

      scoreData = Object.keys(data).length !== 0 && data

      entityDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.ENTITY_SCORE,
        data: scoreData
      })
    } catch (err) {
      entityDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        sectionName: REDUCER_STATE.ENTITY_SCORE
      })
    }
  }

  const getControversies = useCallback(
    async (isErrorReload = false) => {
      if (
        entityState?.entityControversy?.dataStatus !== '' &&
        isErrorReload === false
      )
        return

      entityDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName: REDUCER_STATE.ENTITY_CONTROVERSY
      })
      let controversyData = {}

      try {
        const data = await EntityService.getEntityControversy()

        controversyData = data?.controversies_list

        entityDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          sectionName: REDUCER_STATE.ENTITY_CONTROVERSY,
          data: controversyData
        })
      } catch (error) {
        entityDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.ENTITY_CONTROVERSY
        })
      }
    },
    [entityDispatch]
  )

  React.useEffect(() => {
    getScoreData()
    getControversies()
  }, [])

  const isControversyHasData = () => {
    let hasData = false
    if (
      entityState.entityControversy.data &&
      Array.isArray(entityState.entityControversy.data) &&
      entityState.entityControversy.data.length > 0
    )
      hasData = true
    return hasData
  }
  const reloadComponent = () => {
    getScoreData(true)
  }
  const reloadControversies = () => {
    getControversies(true)
  }
  const formatScoreData = () => {
    let score_categories =
      entityState.entityScore.data.score_categories?.filter(
        (category) => category.criteria !== 'ESG Rating'
      )
    entityState.entityScore.data.score_categories = score_categories
    return entityState.entityScore?.data
  }

  return (
    <Grid container spacing={3} style={{ margin: 0, width: 'auto' }}>
      <Grid item xs={12}>
        <Grid
          container
          direction="column"
          spacing={3}
          className={classes.parentGrid}
        >
          <Grid item>
            <Paper className={clsx(classes.paper, classes.summaryContainer)}>
              {entityState?.entityScore?.dataStatus === '' ||
              entityState?.entityScore?.isLoading ? (
                <LoadingMask />
              ) : entityState?.entityScore?.dataStatus === PAGE_CON.ERROR ? (
                <Error
                  id={'IssuerScorePerformanceError'}
                  dataStatus={PAGE_CON.ERROR}
                  onErrorClick={reloadComponent}
                  errorClass={'noMargin'}
                />
              ) : !entityState?.entityScore?.data &&
                entityState?.entityScore?.dataStatus === PAGE_CON.SUCCESS ? (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    margin: '8px'
                  }}
                >
                  <Error
                    errorClass={'noMargin'}
                    id={'driverError'}
                    topMargin={'0px'}
                    height={'100%'}
                    dataStatus={'success'}
                    errorOccuredOn={PAGE_CON.ENTITY_DRIVER_SUMMARY}
                  />
                </div>
              ) : (
                <Grid item className={classes.banner}>
                  <Banner data={formatScoreData()} isIssuer={true} />
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper className={clsx(classes.paper, classes.controversies)}>
          {entityState?.entityControversy?.dataStatus === '' ||
          entityState?.entityControversy?.isLoading ? (
            <div style={{ display: 'flex', height: '410px' }}>
              <LoadingMask />
            </div>
          ) : entityState?.entityControversy?.dataStatus === PAGE_CON.ERROR ||
            (entityState?.entityControversy?.dataStatus === PAGE_CON.SUCCESS &&
              !isControversyHasData()) ? (
            <div style={{ width: '100%', height: '410px' }}>
              <Error
                id={'pmError'}
                dataStatus={entityState?.entityControversy?.dataStatus}
                onErrorClick={reloadControversies}
                errorOccuredOn={PAGE_CON.ISSUER_CONTROVERSIES_LIST}
                justifyContent={'center'}
                height={'100%'}
              />
            </div>
          ) : (
            <Controversy listHeight={'331px'} />
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default IssuerOverview
