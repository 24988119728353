import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconSearch = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 12C9.76142 12 12 9.76142 12 7C12 4.23858 9.76142 2 7 2C4.23858 2 2 4.23858 2 7C2 9.76142 4.23858 12 7 12ZM7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6464 10.6464C10.8417 10.4512 11.1583 10.4512 11.3536 10.6464L14.8536 14.1464C15.0488 14.3417 15.0488 14.6583 14.8536 14.8536C14.6583 15.0488 14.3417 15.0488 14.1464 14.8536L10.6464 11.3536C10.4512 11.1583 10.4512 10.8417 10.6464 10.6464Z"
      fill="#26415E"
    />
  </SvgIcon>
)
IconSearch.displayName = 'IconSearch'
IconSearch.muiName = 'SvgIcon'

export default IconSearch
