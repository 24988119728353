import { makeStyles } from '@material-ui/core'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  legend: {
    display: 'flex',
    ...textTheme.typography.p4,
    fontSize: '10px !important'
  },
  legendItem: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: 8
  },
  esgScoreLegend: {
    ...textTheme.typography.p5,
    color: themeColorPalette.palette.sys_trad.text.main,
    boxShadow: 'none',
    display: 'flex',
    whiteSpace: 'nowrap',
    backgroundColor: (props) =>
      props.disableBackgroundColor
        ? themeColorPalette.palette.sys_trad.predicted_score.main
        : ''
  },
  esgScoreLegendBold: {
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.sys_trad.text.main,
    boxShadow: 'none',
    display: 'flex',
    whiteSpace: 'nowrap',
    backgroundColor: (props) =>
      props.disableBackgroundColor
        ? themeColorPalette.palette.sys_trad.predicted_score.main
        : ''
  },
  legendDiv: {
    paddingLeft: '8px',
    paddingTop: (props) => (props.boldTitle ? '2px' : 0)
  },
  rightIconStyle: {
    marginRight: 2,
    width: 12,
    height: 12,
    border: '1px solid #FFFFFF',
    borderRadius: 2
  },
  leftIconStyle: {
    marginLeft: 2,
    width: 12,
    height: 12,
    border: '1px solid #FFFFFF',
    borderRadius: 2
  },
  itemTextStyle: {}
}))
