import React, { useReducer } from 'react'
import { REDUCER_TYPE } from '../constant/constants'
import GlobalFilterReducer, {
  globalFilterInitialState
} from './reducers/GlobalFilterReducer'
import PhysicalRiskReducer, {
  physicalRiskInitialState
} from './reducers/PhysicalRiskReducer'
import MarketRiskReducer, {
  marketRiskInitialState
} from './reducers/MarketRiskReducer'
import SupplyChainRiskReducer, {
  supplyChainRiskInitialState
} from './reducers/SupplyChainRiskReducer'
import operationsRiskReducer, {
  operationsRiskInitialState
} from './reducers/OperationsRiskReducer'
import PhysicalRiskHazardsReducer, {
  physicalRiskHazardsInitialState
} from './reducers/PhysicalRiskHazardsReducer'
import BrownShareReducer, {
  BrownShareInitialState
} from './reducers/BrownShareReducer'
import EnergyTransitionReducer, {
  energyTransitionInitialState
} from './reducers/EnergyTransitionReducer'
import GreenShareAssessmentReducer, {
  greenShareInitialState
} from './reducers/GreenShareAssessmentReducer'
import CarbonFootPrintReducer, {
  carbonFootPrintInitialState
} from './reducers/CarbonFootPrintReducer'
import TcfdStrategyReducer, {
  tcfdStrategyInitialState
} from './reducers/TcfdStrategyReducer'
import PortfolioReviewReducer, {
  portfolioReviewInitialState
} from './reducers/PortfolioReviewReducer'
import DashboardReducer, {
  dashboardInitialState
} from './reducers/DashboardReducer'
import GlobalEntityReducer, {
  globalEntityHeaderInitialState
} from './reducers/GlobalEntityReducer'
import IssuerSourceDocumentReducer, {
  IssuerSourceDocumentInitialState
} from './reducers/IssuerSourceDocumentReducer'
import EntityReducer, { entityInitialState } from './reducers/EntityReducer'
import TemperatureAlignmentReducer, {
  temperatureAlignmentInitialState
} from './reducers/TemperatureAlignmentReducer'
import ClimateReducer, { climateInitialState } from './reducers/ClimateReducer'
import SectorInfoReducer, {
  sectorInfoInitialState
} from './reducers/SectorInfoReducer'
import IssuerUrlReducer, {
  IssuerUrlInitialState
} from './reducers/IssuerUrlReducer'
import EsgAssessmentsReducer, {
  EsgAssessmentsInitialState
} from './reducers/EsgAssessmentsReducer'
import PortfolioManagementReducer, {
  portfolioManagementInitialState
} from './reducers/PortfolioManagementReducer'
import ReportingServiceReducer, {
  reportingServiceInitialState
} from './reducers/ReportingServiceReducer'
import OnDemandAsmntReducer, {
  onDemandAsmntInitialState
} from './reducers/OnDemandAsmntReducer'
import UserSettingReducer, {
  userSettingInitialState
} from './reducers/UserSettingReducer'

export const GlobalContext = React.createContext()

// This is a reducer and reducers doesn't have an end return statement
// eslint-disable-next-line consistent-return
export const GlobalReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.RESET_ALL:
      Object.keys(action.payload)
        .filter((valKey) => valKey !== 'globalManager')
        .map((key) => action.payload[key][1]({ type: REDUCER_TYPE.RESET }))
      break
    case REDUCER_TYPE.BENCHMARK_CHANGED:
      Object.keys(action.payload)
        .filter((valKey) => valKey !== 'globalManager')
        .map((key) =>
          action.payload[key][1]({
            type: REDUCER_TYPE.BENCHMARK_CHANGED
          })
        )
      break
    default:
      return currentState
  }
}

// eslint-disable-next-line react/prop-types
function GlobalStateProvider({ children }) {
  const [globalState, globalDispatch] = useReducer(GlobalReducer, {})
  const [filterState, filterDispatch] = useReducer(
    GlobalFilterReducer,
    globalFilterInitialState()
  )
  const [physicalRiskHazardsState, physicalRiskHazardsDispatch] = useReducer(
    PhysicalRiskHazardsReducer,
    physicalRiskHazardsInitialState()
  )
  const [physicalRiskState, physicalRiskDispatch] = useReducer(
    PhysicalRiskReducer,
    physicalRiskInitialState()
  )
  const [operationsRiskState, operationsRiskDispatch] = useReducer(
    operationsRiskReducer,
    operationsRiskInitialState()
  )
  const [marketRiskState, marketRiskDispatch] = useReducer(
    MarketRiskReducer,
    marketRiskInitialState()
  )
  const [supplyChainRiskState, supplyChainRiskDispatch] = useReducer(
    SupplyChainRiskReducer,
    supplyChainRiskInitialState()
  )
  const [carbonFootPrintState, carbonFootPrintDispatch] = useReducer(
    CarbonFootPrintReducer,
    carbonFootPrintInitialState()
  )
  const [brownShareState, brownShareDispatch] = useReducer(
    BrownShareReducer,
    BrownShareInitialState()
  )
  const [energyTransitionState, energyTransitionDispatch] = useReducer(
    EnergyTransitionReducer,
    energyTransitionInitialState()
  )
  const [greenShareAssessmentState, greenShareAssessmentDispatch] = useReducer(
    GreenShareAssessmentReducer,
    greenShareInitialState()
  )
  const [tcfdStrategyState, tcfdStrategyDispatch] = useReducer(
    TcfdStrategyReducer,
    tcfdStrategyInitialState()
  )
  const [portfolioReviewState, portfolioReviewDispatch] = useReducer(
    PortfolioReviewReducer,
    portfolioReviewInitialState()
  )
  const [entityHeaderState, entityHeaderDispatch] = useReducer(
    GlobalEntityReducer,
    globalEntityHeaderInitialState()
  )
  const [issuerSourceDocumentState, issuerSourceDocumentDispatch] = useReducer(
    IssuerSourceDocumentReducer,
    IssuerSourceDocumentInitialState()
  )
  const [entityState, entityDispatch] = useReducer(
    EntityReducer,
    entityInitialState()
  )
  const [dashboardState, dashboardDispatch] = useReducer(
    DashboardReducer,
    dashboardInitialState()
  )
  const [temperatureAlignmentState, temperatureAlignmentDispatch] = useReducer(
    TemperatureAlignmentReducer,
    temperatureAlignmentInitialState()
  )
  const [climateState, climateDispatch] = useReducer(
    ClimateReducer,
    climateInitialState()
  )
  const [SectorInfoState, SectorInfoDispatch] = useReducer(
    SectorInfoReducer,
    sectorInfoInitialState()
  )
  const [IssuerUrlState, IssuerUrlDispatch] = useReducer(
    IssuerUrlReducer,
    IssuerUrlInitialState()
  )
  const [esgAssessmentsState, esgAssessmentsDispatch] = useReducer(
    EsgAssessmentsReducer,
    EsgAssessmentsInitialState()
  )
  const [portfolioManagementState, portfolioManagementDispatch] = useReducer(
    PortfolioManagementReducer,
    portfolioManagementInitialState()
  )
  const [reportingServiceState, reportingServiceDispatch] = useReducer(
    ReportingServiceReducer,
    reportingServiceInitialState()
  )
  const [onDemandAsmntState, onDemandAsmntDispatch] = useReducer(
    OnDemandAsmntReducer,
    onDemandAsmntInitialState()
  )
  const [userSettingState, userSettingDispatch] = useReducer(
    UserSettingReducer,
    userSettingInitialState()
  )

  const values = {
    globalManager: [globalState, globalDispatch],
    globalFilter: [filterState, filterDispatch],
    globalPhysicalRiskHazards: [
      physicalRiskHazardsState,
      physicalRiskHazardsDispatch
    ],
    globalPhysicalRisk: [physicalRiskState, physicalRiskDispatch],
    globalMarketRisk: [marketRiskState, marketRiskDispatch],
    globalSupplyChainRisk: [supplyChainRiskState, supplyChainRiskDispatch],
    globalOperationsRisk: [operationsRiskState, operationsRiskDispatch],
    globalCarbonFootPrint: [carbonFootPrintState, carbonFootPrintDispatch],
    globalBrownShareAssessment: [brownShareState, brownShareDispatch],
    globalEnergyTransition: [energyTransitionState, energyTransitionDispatch],
    globalGreenShareAssessment: [
      greenShareAssessmentState,
      greenShareAssessmentDispatch
    ],
    globalTcfdStrategy: [tcfdStrategyState, tcfdStrategyDispatch],
    globalPortfolioReview: [portfolioReviewState, portfolioReviewDispatch],
    globalDashboard: [dashboardState, dashboardDispatch],
    globalEntityHeader: [entityHeaderState, entityHeaderDispatch],
    issuerSourceDocument: [
      issuerSourceDocumentState,
      issuerSourceDocumentDispatch
    ],
    globalEntity: [entityState, entityDispatch],
    globalTemperatureAlignment: [
      temperatureAlignmentState,
      temperatureAlignmentDispatch
    ],
    globalClimate: [climateState, climateDispatch],
    globalSectorInfoState: [SectorInfoState, SectorInfoDispatch],
    globalIssuerUrl: [IssuerUrlState, IssuerUrlDispatch],
    globalEsgAssessments: [esgAssessmentsState, esgAssessmentsDispatch],
    globalPortfolioManagement: [
      portfolioManagementState,
      portfolioManagementDispatch
    ],
    globalReportingService: [reportingServiceState, reportingServiceDispatch],
    onDemandAsmntService: [onDemandAsmntState, onDemandAsmntDispatch],
    userSettingService: [userSettingState, userSettingDispatch]
  }

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>
  )
}

export default GlobalStateProvider
