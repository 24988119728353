import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styles'

const MultiSelector = ({ items, handleItemClick, disabled, heap_id }) => {
  return (
    <S.Container {...(heap_id ? { heap_id: heap_id } : {})}>
      {items.map((item) => (
        <S.Item
          {...(heap_id ? { heap_id: heap_id } : {})}
          key={item.key}
          active={item.active}
          action={() => !disabled && handleItemClick(item.key)}
          disabled={disabled}
        >
          {item.label}
        </S.Item>
      ))}
    </S.Container>
  )
}

MultiSelector.propTypes = {
  items: PropTypes.array,
  handleItemClick: PropTypes.func,
  disabled: PropTypes.bool
}

export default MultiSelector
