import { IconClose, IconSearch } from 'components'
import clsx from 'clsx'
import FormControl from '@material-ui/core/FormControl'
import InputAdornment from '@material-ui/core/InputAdornment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { useStyles } from './styles'

/**
 * @visibleName Textbox
 */
const Textbox = (props) => {
  const classes = useStyles(props)
  const [textboxError, settextboxError] = useState(props.textboxError)
  const characterLimit = props.textboxCharLimit ? props.textboxCharLimit : null
  const [valueLength, setValueLength] = useState(
    props.defaultText && props.textboxCharLimit !== undefined
      ? props.defaultText.length
      : 0
  )
  const [showSearchIcon, setShowSearchIcon] = useState(true)
  const [inputText, setInputText] = useState(
    props.defaultText !== undefined ? props.defaultText : ''
  )

  useEffect(() => {
    setInputText(props.defaultText !== undefined ? props.defaultText : '')
  }, [props.defaultText])

  const onChange = (event) => {
    let changeVal = event.target.value
    if (props.textType === 'number') {
      changeVal = changeVal.trim()
      if (changeVal.length) {
        const ret = new RegExp('^((\\d+(\\.\\d*)?)|(\\.\\d*))$', 'gi').test(
          changeVal
        )
        if (!ret) return
      }

      if (changeVal > props.thresholdValue) return
    }
    setValueLength(changeVal.length)
    setInputText(changeVal)

    if (changeVal.length > 0) setShowSearchIcon(false)
    else setShowSearchIcon(true)

    if (props.textboxError) {
      changeVal ? settextboxError(false) : settextboxError(true)
    }
    if (
      props.textboxCharLimit !== undefined &&
      !props.hideCharacterLimitError
    ) {
      changeVal.length < characterLimit
        ? settextboxError(false)
        : settextboxError(true)
    }

    if (props.onChange) props.onChange(changeVal, props.type)
  }

  const onBlur = () => {
    if (props.onBlur) props.onBlur(inputText)
  }

  const onSearchClick = () => {}

  const onCloseClick = () => {
    setInputText('')
    setShowSearchIcon(true)
    if (props.onChange) props.onChange('')
  }

  const searchComponenet = showSearchIcon ? (
    <IconSearch
      className={clsx(classes.searchIcon, classes.defaultHover)}
      onClick={() => onSearchClick()}
    />
  ) : (
    <IconClose className={classes.searchIcon} onClick={() => onCloseClick()} />
  )

  const canShowSuffixText = props.suffixText || '' !== ''

  return (
    <FormControl
      className={clsx(classes.formControl, classes[props.textboxClass])}
    >
      {props.textboxCharLimit !== undefined &&
      !props.hideCharacterLimitError ? (
        <div
          className={classes.charCount}
        >{`${valueLength}/${characterLimit}`}</div>
      ) : null}
      <TextField
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={props.autoFocus}
        autoComplete={props.autoCompleteOff ? 'off' : 'on'}
        type={'text'}
        disabled={props.disabled}
        multiline={props.multiline}
        error={textboxError}
        label={props.textboxLabelText}
        id={props.id}
        placeholder={props.textboxDefaultPlaceholder}
        onChange={onChange}
        value={props.externalInput ? props.defaultText : inputText}
        onBlur={onBlur}
        helperText={
          textboxError && characterLimit
            ? 'Maximum Character Limit Reached'
            : textboxError && characterLimit == null
            ? props.helperText
            : ''
        }
        FormHelperTextProps={{
          className: classes.helperText
        }}
        InputLabelProps={{
          shrink: true,
          className: classes.inputLabelClass
        }}
        InputProps={{
          className: clsx(
            classes.textboxInput,
            props.inputTextError && classes.textboxInputErrorState
          ),
          endAdornment: (props.showSearchIcon || canShowSuffixText) && (
            <InputAdornment position="start">
              {props.showSearchIcon && searchComponenet}
              {canShowSuffixText && (
                <div className={classes[props.suffixTextClass]}>
                  {props.suffixText}
                </div>
              )}
            </InputAdornment>
          )
        }}
        inputProps={{
          maxLength: characterLimit,
          className: clsx(classes.autoFillInput, classes[props.inputPropsClass])
        }}
      />
    </FormControl>
  )
}

Textbox.propTypes = {
  /** Textbox id */
  id: PropTypes.string,
  /** The Textbox Label text */
  textboxLabelText: PropTypes.string,
  /** Class for Textbox style : textboxDefault or textboxDefaultAlt */
  textboxClass: PropTypes.string,
  /** Width for Textbox */
  textboxWidth: PropTypes.string,
  /** Height for Textbox */
  textboxHeight: PropTypes.string,
  /** Margin (left) for Textbox */
  textboxMargin: PropTypes.string,
  /** Default Value for Textbox */
  textboxDefaultPlaceholder: PropTypes.string,
  /** Helper Text for Error on Textbox */
  helperText: PropTypes.string,
  /** Textbox error flag*/
  textboxError: PropTypes.bool,
  /** Gets called when the Textbox changes */
  onChange: PropTypes.func,
  /** Default text for textbox*/
  defaultText: PropTypes.string,
  /** Character count limit, if provided will be displayed*/
  textboxCharLimit: PropTypes.number,
  /** Textbox multiline flag*/
  multiline: PropTypes.bool,
  /** Textbox showSearchIcon flag*/
  showSearchIcon: PropTypes.bool,
  /** Textbox Search Icon size*/
  iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Textbox autocomplete off flag*/
  autoCompleteOff: PropTypes.bool,
  /** Textbox autoFocus flag*/
  autoFocus: PropTypes.bool,
  /** Textbox disbale flag*/
  disabled: PropTypes.bool,
  /** Textbox type*/
  textType: PropTypes.oneOf(['text', 'number']),
  /** Class for Input style : autoFillInput or textboxDefaultAlt */
  inputPropsClass: PropTypes.string,
  /** Textbox type */
  type: PropTypes.string,
  /** Textbox threshold/comparison value */
  thresholdValue: PropTypes.number,
  /** Whether Textbox value is modified via external sources */
  externalInput: PropTypes.bool,
  /** Padding for Textbox */
  textboxPadding: PropTypes.string,
  /** hide character limit error */
  hideCharacterLimitError: PropTypes.bool,
  /** fixed text to display at the end of text box */
  suffixText: PropTypes.string,
  /** Class for suffix text */
  suffixTextClass: PropTypes.string
}

Textbox.defaultProps = {
  multiline: true,
  showSearchIcon: false,
  autoFocus: true,
  disabled: false,
  textType: 'text',
  externalInput: false,
  hideCharacterLimitError: false
}

export default Textbox
