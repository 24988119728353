import { makeStyles } from '@material-ui/core/styles'
import BenchmarkComp from '../globalComponent/BenchmarkComp'
import Box from '@material-ui/core/Box'
import ExportComp from '../../views/globalComponent/exportComp/ExportComp'
import PortfolioComp from '../globalComponent/PortfolioComp'
import NavComp from '../globalComponent/NavComp'
import { PrimaryButton, PrimaryModal, PrimaryPopover } from 'components'
import React, { useContext, useEffect, useState } from 'react'
import RegionSectorDateComp from '../globalComponent/RegionSectorDateComp'
import ScoreQuality from 'views/globalComponent/ScoreQuality'
import themeColorPalette from 'theme/theme'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'

const useStyle = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: (props) => (props.maxWidth ? props.maxWidth : 'auto'),
    padding: 0,
    margin: 0,
    alignItems: 'left',
    lineHeight: 'normal'
  }
})
export let test

const GlobalFilter = (props) => {
  const { id, pageTitle } = props
  let classes = useStyle(props)
  const [portfolioReviewState] = useContext(GlobalContext).globalPortfolioReview
  const [scoreQualityState, scoreQualityDispatch] =
    useContext(GlobalContext).globalFilter
  const [popoverErrorOpen, setPopoverErrorOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const [showScoreQualityBtn, setShowScoreQualityBtn] = useState(false)
  const { esg } = props.entitlements.PortfolioAnalysis
  const onPopoverErrorClose = () => {
    setPopoverErrorOpen(false)
  }

  const handleShowScoreQuality = () => {
    scoreQualityDispatch({
      type: REDUCER_TYPE.SCORE_QUALITY_LEVEL,
      payload: !scoreQualityState.enableScoreQuality
    })
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  const openModal = () => {
    setOpen(true)
  }

  useEffect(() => {
    if (props.pageTitle === 'ESG Assessments') {
      setShowScoreQualityBtn(true)
    }
  }, [props.pageTitle])

  const canShowListOption = () => {
    return (
      props.entitlements &&
      props.entitlements['export'] &&
      (portfolioReviewState.portfolioReviewPage !== 'physicalriskhazards' ||
        !PAGE_CON.PRODUCTION_ENABLED) &&
      portfolioReviewState.portfolioReviewPage !== 'temperaturealignment' &&
      portfolioReviewState.portfolioReviewPage !== 'physicalriskmanagement' &&
      (!window.location.href.includes('physicalriskhazards') ||
        !PAGE_CON.PRODUCTION_ENABLED) &&
      !window.location.href.includes('temperaturealignment') &&
      !window.location.href.includes('physicalriskmanagement')
    )
  }

  const boxMarginRight = 1
  return (
    <Box id={id} key={id} className={classes.root}>
      <PrimaryPopover
        id="export-popover-error-test-id"
        alertError={true}
        alertHeight="20%"
        alertText={'Export failed because of an issue, please try again later.'}
        alertOpen={popoverErrorOpen}
        onAlertClose={onPopoverErrorClose}
        alertPosition="center"
        alertTop="55px"
        marginLeft="16px"
      />
      <PrimaryModal
        id="functionality-coming-soon-test-id"
        modalOpen={open}
        onModalClose={handleCloseDialog}
        modalClass="smallModal"
        modalTitle="Coming Soon"
        modalContentComponent={['Functionality Coming Soon']}
        modalActionComponent={
          <PrimaryButton
            id="overview-button-test-id"
            buttonText="Ok"
            onClick={handleCloseDialog}
            buttonClass="buttonPrimary"
          />
        }
      />
      <Box key={'innerBox'} className={classes.root}>
        <Box key={'Holdings'} m={0} marginBottom={0.5} marginTop={'-3px'}>
          <PortfolioComp id="portfolio_comp"></PortfolioComp>
        </Box>
        <Box marginRight={boxMarginRight} marginBottom={0.5}></Box>
        <Box key={'nav'} m={0} marginBottom={0.5}>
          <NavComp
            id="nav_comp"
            sideBarItems={props.sideBarItems}
            section={props.section}
            entitlements={props.entitlements}
          ></NavComp>
        </Box>
        <Box marginRight={boxMarginRight} marginBottom={0.5}></Box>
        <Box key={'regSect'} m={0} marginBottom={1}>
          <RegionSectorDateComp id="regSecDate-1"></RegionSectorDateComp>
        </Box>
        <Box marginRight={1} marginLeft={1} marginBottom={0.5}>
          {'with'}
        </Box>
        <Box key={'benchmark'} m={0} marginBottom={0.5}>
          <BenchmarkComp></BenchmarkComp>
        </Box>
        {canShowListOption() && (
          <Box
            marginLeft={1}
            marginBottom={0.5}
            color={themeColorPalette.palette.sys_trad.border.main}
          >
            {'|'}
          </Box>
        )}
        {canShowListOption() && (
          <Box key={'export'} m={0} marginBottom={0.5}>
            <ExportComp
              pageTitle={pageTitle}
              page={portfolioReviewState.portfolioReviewPage}
              openModal={openModal}
              setPopoverErrorOpen={setPopoverErrorOpen}
            />
          </Box>
        )}
        <Box marginRight={1} marginLeft={1} marginBottom={0.5}>
          {esg && (
            <ScoreQuality
              handleShowScoreQuality={handleShowScoreQuality}
              scoreQuality={scoreQualityState.enableScoreQuality}
              showBtn={showScoreQualityBtn}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default GlobalFilter
