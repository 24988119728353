import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme.typography.p1,
    color: theme.palette.sys_trad?.main.main,
    display: 'flex',
    width: '180px',
    height: '32px',
    padding: '0px 8px',
    borderRadius: '2px',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '5px',
    '&:hover': {
      background: theme.palette.sys_trad?.hover2.main
    },
    '& .text': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    }
  },
  active: {
    background: theme.palette.sys_trad?.selected2.main
  },
  iconContainer: {
    marginRight: '13px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content'
  }
}))

export const Container = ({ children, active = false, action }) => {
  const classes = useStyles(theme)
  return (
    <div
      onClick={action}
      aria-hidden={true}
      className={`${classes.container} ${active ? classes.active : ''}`}
    >
      {children}
    </div>
  )
}

export const IconContainer = ({ children }) => {
  const classes = useStyles(theme)
  return <div className={classes.iconContainer}>{children}</div>
}
