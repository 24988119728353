import React from 'react'
import HeatmapTable from './HeatmapTable'

const Heatmap = ({
  entitlements,
  handleHeatmapTableSelected,
  researchLinesSelected,
  selectedHeatmapTable
}) => {
  return (
    <div data-testid="HeatmapTable-container">
      <HeatmapTable
        researchLines={researchLinesSelected}
        entitlements={entitlements}
        handleHeatmapTableSelected={handleHeatmapTableSelected}
        selectedHeatmapTable={selectedHeatmapTable}
      />
    </div>
  )
}

Heatmap.propTypes = {}

export default Heatmap
