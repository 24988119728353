import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'

const useStyles = makeStyles((theme) => ({
  container: {
    ...theme?.typography?.p2,
    background: theme?.palette?.sys_trad?.bg?.main,
    color: theme?.palette?.sys_trad?.text?.main,
    borderRadius: '2px',
    padding: '4px 12px',
    width: 'fit-content',
    margin: '4px 0px',
    marginRight: '12px',
    '&.critical': {
      background: theme?.palette?.sys_trad?.error?.main,
      color: theme?.palette?.sys_trad?.white?.main,
      padding: '0px 6px',
      margin: '0px'
    },
    '&.dark': {
      background: theme?.palette?.sys_trad?.text?.main,
      color: theme?.palette?.sys_trad?.white?.main,
      padding: '0px 6px',
      margin: '0px'
    },
    '&.weak': {
      background: theme?.palette?.sys_trad?.adv_wk?.weak?.main,
      color: theme?.palette?.sys_trad?.white?.main,
      padding: '0px 6px',
      margin: '0px'
    },
    '&.limited': {
      background: theme?.palette?.sys_trad?.adv_wk?.limited?.main,
      color: theme?.palette?.sys_trad?.white?.main,
      padding: '0px 6px',
      margin: '0px'
    },
    '&.robust': {
      background: theme?.palette?.sys_trad?.adv_wk?.robust?.main,
      color: theme?.palette?.sys_trad?.white?.main,
      padding: '0px 6px',
      margin: '0px'
    },
    '&.advanced': {
      background: theme?.palette?.sys_trad?.adv_wk?.advanced?.main,
      color: theme?.palette?.sys_trad?.white?.main,
      padding: '0px 6px',
      margin: '0px'
    },
    '&.high': {
      background: theme?.palette?.sys_trad?.carbfoot?.high?.main,
      color: theme?.palette?.sys_trad?.text?.main,
      padding: '0px 6px',
      margin: '0px'
    },
    '&.significant': {
      background: theme?.palette?.sys_trad?.carbfoot?.significant?.main,
      color: theme?.palette?.sys_trad?.text?.main,
      padding: '0px 6px',
      margin: '0px'
    },
    '&.intense': {
      background: theme?.palette?.sys_trad?.carbfoot?.intense?.main,
      color: theme?.palette?.sys_trad?.white?.main,
      padding: '0px 6px',
      margin: '0px'
    },
    '&.moderate': {
      background: theme?.palette?.sys_trad?.carbfoot?.moderate?.main,
      color: theme?.palette?.sys_trad?.white?.main,
      padding: '0px 6px',
      margin: '0px'
    }
  }
}))

export const Container = ({ children, className }) => {
  const classes = useStyles(theme)
  return <span className={`${classes.container} ${className}`}>{children}</span>
}
