import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconPositiveTrend = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 3C8.22386 3 8 2.77614 8 2.5C8 2.22386 8.22386 2 8.5 2H13.5C13.7761 2 14 2.22386 14 2.5V7.5C14 7.77614 13.7761 8 13.5 8C13.2239 8 13 7.77614 13 7.5V3.70711L2.85355 13.8536C2.65829 14.0488 2.34171 14.0488 2.14645 13.8536C1.95118 13.6583 1.95118 13.3417 2.14645 13.1464L12.2929 3H8.5Z"
      fill="#0F997D"
    />
  </SvgIcon>
)
IconPositiveTrend.displayName = 'IconPositiveTrend'
IconPositiveTrend.muiName = 'SvgIcon'

export default IconPositiveTrend
