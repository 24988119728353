import TableMaterial from '@material-ui/core/Table'
import TableContainerMaterial from '@material-ui/core/TableContainer'
import TableHeadMaterial from '@material-ui/core/TableHead'
import TableRowMaterial from '@material-ui/core/TableRow'
import TableCellMaterial from '@material-ui/core/TableCell'
import TableBodyMaterial from '@material-ui/core/TableBody'
import TableFooterMaterial from '@material-ui/core/TableFooter'
import theme from 'theme'
import { withStyles } from '@material-ui/core/styles'
import themeColorPalette from 'theme/theme'

export const TableContainer = withStyles(() => ({
  root: {
    maxHeight: (props) => props.maxheight || 'unset'
  }
}))(TableContainerMaterial)

export const Table = withStyles(() => ({
  root: (props) => props.tablestyles
}))(TableMaterial)

export const TableHeader = withStyles(() => ({
  root: {
    '& tr': {
      borderTop: (props) =>
        props.withborder &&
        `1px solid ${theme?.palette?.sys_trad?.border.main}`,
      borderBlock: (props) => (props.sticky_header ? 'initial' : '')
    },
    '& th': {
      ...theme.typography.p2,
      backgroundColor: theme?.palette?.sys_trad?.bg.main,
      color: theme?.palette?.sys_trad?.text2.main,
      textAlign: 'left',
      verticalAlign: 'bottom',
      height: '40px',
      lineHeight: '16px',
      padding: '4px 8px',
      position: (props) => (props.sticky_header ? 'sticky' : ''),
      top: (props) => (props.sticky_header ? '0' : '')
    }
  }
}))(TableHeadMaterial)

export const TableBody = withStyles(() => ({
  root: {
    '& tr': {
      '& td:first-child': {
        paddingLeft: '10px'
      }
    },
    '& td': {
      verticalAlign: 'middle',
      height: '25px',
      padding: '4px 8px'
    }
  }
}))(TableBodyMaterial)

export const TableFooter = withStyles((theme) => ({
  root: {
    height: '40px',
    border: 'none',
    '& tr': {
      border: 'none'
    },
    '& td': {
      ...theme.typography.p3,
      backgroundColor: `${theme?.palette?.sys_trad?.bg.main} !important`,
      color: theme?.palette?.sys_trad?.text2.main,
      textAlign: 'left',
      border: 'none',
      padding: '4px 8px'
    }
  }
}))(TableFooterMaterial)

export const TableRow = withStyles((theme) => ({
  root: {
    ...theme.typography.p3,
    borderBottom: `1px solid ${theme?.palette?.sys_trad?.border.main}`,
    '& .clickable': {
      cursor: 'pointer'
    },
    '&.predicted-score': {
      backgroundColor: `${themeColorPalette.palette.proxy.proxy.main} !important`,
      '&:active': {
        'pointer-events': 'none'
      },
      '& td > span > span, & td > span > span > span': {
        textDecoration: 'none'
      },
      '& *': {
        cursor: 'default !important'
      }
    }
  }
}))(TableRowMaterial)

export const TableCell = withStyles((theme) => ({
  root: {
    '&.cell': {
      ...theme.typography.p3,
      whiteSpace: 'nowrap',
      maxWidth: 150,
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    },
    head: {
      color: theme.palette.common.white
    },
    body: {
      fontSize: 14
    }
  }
}))(TableCellMaterial)
