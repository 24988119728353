import { PAGE_CON, REDUCER_TYPE } from '../../constant/constants'
import { v4 as uuidv4 } from 'uuid'

const entityHeader = {
  company_name: 'Loading...',
  country: '',
  lei: '',
  primary_isin: '',
  orbis_id: '',
  region: '',
  mesg_sector: 'Loading...',
  sector_l2: 'Loading...',
  confidence_level: null,
  mesg_sector_detail_description: 'Loading...',
  score_quality: ''
}

export function globalEntityHeaderInitialState() {
  let obj = {}
  obj.apiCallId = uuidv4()
  obj.isLoading = false
  // obj.isPortfolioLoading = false
  obj.actionPerformedOn = ''
  obj.actionData = PAGE_CON.INITIAL_DATA
  obj.dataStatus = ''
  obj.globalEntityHeader = Object.assign({}, { ...entityHeader })
  obj.selectedOrbisId = ''
  return obj
}

const GlobalEntityReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.LOAD_DATA_START:
      currentState.isLoading = true
      currentState.dataStatus = PAGE_CON.LOADING
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_END:
      currentState.isLoading = false
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_COMPLETED:
      if (action.apiCallId && action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      action.headerItems &&
        Object.keys(action.headerItems).map((key) => {
          return (currentState.globalEntityHeader[key] =
            action.headerItems[key])
        })
      currentState.dataStatus = PAGE_CON.SUCCESS
      currentState.isLoading = false
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_ERROR:
      if (action.apiCallId && action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState.isLoading = false
      currentState.dataStatus = PAGE_CON.ERROR
      return { ...currentState }
    case REDUCER_TYPE.RESET_LOAD_DATA_ERROR:
      currentState.dataStatus = ''
      return { ...currentState }
    case REDUCER_TYPE.SET_ORBIB_ID:
      if (currentState.selectedOrbisId === '')
        currentState.selectedOrbisId = action.selectedOrbisId
      return { ...currentState }
    case REDUCER_TYPE.RESET:
      return globalEntityHeaderInitialState()
    default:
      return currentState
  }
}

export default GlobalEntityReducer
