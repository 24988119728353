import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'
import { hexToRgb } from 'utils/colorMix'

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: '35px',
    marginBottom: '25px',
    borderBottom: `1px solid ${theme?.palette?.sys_trad?.border.main}`
  },
  table: {
    width: '100%',
    maxWidth: (props) => props.maxWidth && `${props.maxWidth}px`,
    display: (props) => props.horizontal && 'flex',
    padding: '2px',
    '& td': {
      flex: 1
    },
    '& tbody': {
      flex: 1
    },
    '& tr': {
      display: (props) => props.horizontal && 'flex'
    },
    '& thead': {
      width: (props) => props.horizontal && `${100 / (props.columns + 1)}%`
    },
    '& tr>td>div': {
      display: (props) => props.horizontal && 'flex',
      justifyContent: (props) => props.horizontal && 'center',
      flexDirection: (props) => props.horizontal && 'column-reverse',
      alignItems: (props) => props.horizontal && 'flex-end',
      height: (props) => props.horizontal && '36px'
    },
    '& thead>tr>td>div': {
      height: (props) => props.horizontal && '28px'
    }
  },
  tableCell: {
    ...theme.typography.p2,
    fontSize: 10,
    color: theme?.palette?.sys_trad?.text.main,
    backgroundColor: (props) => props.backgroundColor,
    borderRadius: '2px',
    height: '32px',
    textAlign: 'right',
    padding: '10px',
    margin: '2px',
    '&>span:last-child': {
      width: '40px',
      display: 'inline-block'
    },
    cursor: (props) => props.clickable && 'pointer',
    '&:hover, &.selected': {
      padding: (props) => props.clickable && '9px',
      border: (props) =>
        props.clickable && `1px solid ${theme?.palette?.sys_trad?.hover.main}`
    },
    '&.disabled': {
      cursor: 'wait'
    }
  },
  tableHead: {
    ...theme.typography.p2,
    color: theme?.palette?.sys_trad?.text.main,
    padding: '10px',
    margin: '8px 8px 6px 8px',
    height: '24px',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  tableBoxContainer: {
    display: 'flex',
    marginBottom: '4px',
    maxWidth: (props) => props.maxWidth && `${props.maxWidth}px`
  },
  tableBox: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.08)',
    borderRadius: '2px',
    flex: (props) => props.columns,
    overflow: 'hidden'
  },
  spanClass: {
    width: '100% !important'
  }
}))

export const Container = ({ children, showBorder }) => {
  const classes = useStyles()
  return <div className={showBorder ? classes.container : null}>{children}</div>
}

export const Table = ({ children, horizontal, columns, maxWidth }) => {
  const classes = useStyles({ horizontal, columns, maxWidth })
  return <table className={classes.table}>{children}</table>
}

export const TableCell = ({
  label,
  value,
  color,
  isValueHide = false,
  onClick = null,
  selected = false,
  disabled = false
}) => {
  const isNumber = typeof value === 'number'
  const roundValue = isNumber ? Math.round(value) : 0
  const opacity = roundValue < 10 ? `0${roundValue}` : roundValue
  const rgbColor = hexToRgb(color)
  const backgroundColor = `rgba(${rgbColor[0]},${rgbColor[1]},${rgbColor[2]},${
    opacity / 100
  })`
  const classes = useStyles({
    backgroundColor,
    clickable: value !== '0%' && onClick !== null
  })
  return (
    <td>
      <div
        // eslint-disable-next-line react/no-unknown-property
        heap_id="heatmap"
        // eslint-disable-next-line react/no-unknown-property
        heap_heatmap_id="gridcell"
        className={`${classes.tableCell} ${selected && 'selected'} ${
          disabled && 'disabled'
        }`}
        onClick={onClick}
        aria-hidden={true}
      >
        <span className={`${isValueHide ? classes.spanClass : ''}`}>
          {label}
        </span>
        {!isValueHide ? (
          <span>{isNumber ? `${value.toFixed(2)}%` : value}</span>
        ) : (
          ''
        )}
      </div>
    </td>
  )
}

export const TableHead = ({ children }) => {
  const classes = useStyles()
  return (
    <td>
      <div className={classes.tableHead}>{children}</div>
    </td>
  )
}

export const TableBoxContainer = ({ children, maxWidth }) => {
  const classes = useStyles({ maxWidth })
  return <div className={classes.tableBoxContainer}>{children}</div>
}

export const TableBox = ({ children, columns }) => {
  const classes = useStyles({ columns })
  return <div className={classes.tableBox}>{children}</div>
}
