import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import themeColorPalette from '../../../theme/theme'
import theme from 'theme'

const useStyles = makeStyles(() => ({
  container: {
    height: 'calc(100% - 130px)',
    minWidth: '300px',
    padding: '0px 15px',
    '& td, th': {
      padding: '4px 0',
      paddingLeft: '0 !important'
    }
  },
  titleSidePanel: {
    ...theme.typography.h1,
    color: theme.palette.sys_trad.main.main,
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 10
  },
  tableHeader: {
    color: theme.palette.sys_trad.main.main,
    text: theme.typography.p5
  },
  tableCaption: {
    ...theme.typography.p1
  },
  content: {
    backgroundColor: theme.palette.sys_trad.white,
    width: '650px',
    paddingLeft: '10px',
    height: '100%'
  },
  tableTitle: {
    marginTop: '20px',
    marginBottom: '-2px'
  },
  headLine: {
    color: theme.palette.sys_trad.main.main
  },
  controversiesCellContainer: {
    display: 'flex'
  },
  controversiesCell: {
    paddingLeft: '10px',
    color: (props) =>
      props.critical > 0
        ? theme.palette.sys_trad.error.main
        : theme.palette.sys_trad.text.main
  },
  investmentCell: {
    display: 'flex',
    justifyContent: 'center'
  },
  companyNameCell: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  buttonTextContainer: {
    textAlign: 'center',
    marginTop: 30,
    '& button': {
      marginTop: 20,
      marginBottom: 30
    },
    '& p': {
      ...theme.typography.p3
    }
  },
  footer: {
    backgroundColor: theme.palette.sys_trad.white.main,
    boxShadow: 'none',
    height: '180px',
    minWidth: '645px'
  },
  footerContent: {
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    height: '125px',
    padding: '18px'
  },
  tablesContainer: {
    flex: 1,
    overflowY: 'scroll',

    '& td': {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '&>span': {
        display: 'block',
        width: '90%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      }
    },
    '& .controversiesCellContainer': {
      width: '100%',
      display: 'flex',
      '& .controversiesCell': {
        paddingLeft: '10px',
        color: (props) =>
          props.critical > 0
            ? theme.palette.sys_trad.error.main
            : theme.palette.sys_trad.text.main
      }
    },
    '& .investmentCell': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }
  },
  fixedContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  CompanyNameCell: {
    '&.entity': {
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    '&.CompanyNameWrapper': {
      display: 'flex'
    },
    '&.CompanyName': {
      maxWidth: '50%',
      minWidth: '4em'
    },
    '&.ellipsis': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      float: 'left',
      paddingRight: '7px'
    },
    '&.clear': {
      clear: 'both'
    }
  }
}))

export const Container = ({ children }) => {
  const classes = useStyles({})
  return <div className={classes.container}>{children}</div>
}

export const Content = ({ children }) => {
  const classes = useStyles({})
  return <div className={classes.content}>{children}</div>
}

export const TableHeader = ({ children }) => {
  const classes = useStyles({})
  return <span className={classes.tableHeader}>{children}</span>
}

export const TableTitle = ({ children }) => {
  const classes = useStyles({})
  return <div className={classes.tableTitle}>{children}</div>
}

export const TableCaption = ({ children }) => {
  const classes = useStyles({})
  return <div className={classes.tableCaption}>{children}</div>
}

export const PerformanceTableTitleSidePanel = ({ children }) => {
  const classes = useStyles()
  return (
    <div
      className={`${classes.titleSidePanel} ${classes.performanceTableTitle}`}
    >
      {children}
    </div>
  )
}

export const ControversiesCell = ({ total, critical }) => {
  const classes = useStyles({ critical })
  return (
    <div className={classes.controversiesCellConteiner}>
      <span>{`${total} total`}</span>
      <span
        className={classes.controversiesCell}
      >{`${critical} critical`}</span>
    </div>
  )
}

export const InvestmentCell = ({ children }) => {
  const classes = useStyles()
  return <span className={classes.investmentCell}>{children}</span>
}

export const CompanyNameCell = ({ onClick, title, children, className }) => {
  const classes = useStyles()
  return (
    <span
      title={title}
      onClick={onClick}
      // eslint-disable-next-line react/no-unknown-property
      heap_id="view-panel"
      // eslint-disable-next-line react/no-unknown-property
      heap_entity={children}
      className={`${classes.CompanyNameCell} ${className}`}
      aria-hidden={true}
    >
      {children}
    </span>
  )
}

export const ButtonTextContainer = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.buttonTextContainer}>{children}</div>
}

export const Footer = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.footer}>{children}</div>
}

export const FooterContent = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.footerContent}>{children}</div>
}

export const TablesContainer = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.tablesContainer}>{children}</div>
}

export const FixedContent = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.fixedContent}>{children}</div>
}
