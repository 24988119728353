import numberData from 'utils/numberFormat'

export default (value, config, min, placeholder) => {
  if (isNaN(value) || value === null) return ''
  if (value > 0 && value < min) {
    return placeholder
  } else {
    return numberData(value, config)
  }
}
