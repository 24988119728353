import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconDropDown = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.54935 2.28341C1.63261 2.11017 1.8078 2 2 2H6C6.19221 2 6.3674 2.11017 6.45066 2.28341C6.53392 2.45664 6.51051 2.66226 6.39044 2.81235L4.39044 5.31235C4.29555 5.43096 4.1519 5.5 4 5.5C3.84811 5.5 3.70446 5.43096 3.60957 5.31235L1.60957 2.81235C1.4895 2.66226 1.46609 2.45664 1.54935 2.28341Z"
      fill="#26415E"
    />
  </SvgIcon>
)
IconDropDown.displayName = 'IconDropDown'
IconDropDown.muiName = 'SvgIcon'

export default IconDropDown
