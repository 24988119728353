import axios from 'axios'
import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'

const criteriaFilterView = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const reqDetailInfo = {
  data: {}
}

const batchRequest = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

export function onDemandAsmntInitialState() {
  let obj = {}
  obj.modalOpen = false
  obj.reqDetailReadOnly = false
  obj.portfolio = {}
  obj.step = {}
  obj.prevStep = ''
  obj.initiatedFrom = ''
  obj.criteriaFilterView = Object.assign({}, { ...criteriaFilterView })
  obj.assessmentRequested = false
  obj.reqDetailInfo = Object.assign({}, { ...reqDetailInfo })
  obj.batchRequest = Object.assign({}, { ...batchRequest })
  obj.cancelToken = axios.CancelToken.source()
  obj.hasErrorPopUP = false
  return obj
}

const OnDemandAsmntReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.ON_DEMAND_ASMNT_MODAL:
      // eslint-disable-next-line no-case-declarations
      const assessmentRequested = action?.assessmentRequested
      // eslint-disable-next-line no-case-declarations
      const portfolio = action?.portfolio || currentState.portfolio
      // eslint-disable-next-line no-case-declarations
      const step = assessmentRequested
        ? {
            [portfolio?.key]: PAGE_CON.ON_DEMAND_ASMNT_BATCH_REVIEW
          }
        : {}

      return {
        ...currentState,
        modalOpen: action.modalOpen || false,
        assessmentRequested,
        portfolio,
        step
      }
    case REDUCER_TYPE.ON_DEMAND_ASMNT_SET_STEP:
      return {
        ...currentState,
        step: {
          ...currentState.step,
          [currentState.portfolio.key]: action.step
        },
        prevStep: action.prevStep || '',
        initiatedFrom: action.initiatedFrom || '',
        reqDetailReadOnly: action.reqDetailReadOnly || false
      }
    case REDUCER_TYPE.LOAD_DATA_START:
      currentState[action.sectionName].isLoading = true
      currentState[action.sectionName].dataStatus = PAGE_CON.LOADING
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_ERROR:
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.ERROR
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_COMPLETED:
      currentState[action.sectionName].data = action.data
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.SUCCESS
      return { ...currentState }
    case REDUCER_TYPE.ON_DEMAND_ASMNT_REQUEST_DETAIL_DATA:
      return {
        ...currentState,
        [action.sectionName]: {
          data: action.data
        }
      }
    case REDUCER_TYPE.ON_DEMAND_SELECTED_PORTFOLIO:
      return {
        ...currentState,
        [action.sectionName]: {
          data: action.data
        }
      }
    case REDUCER_TYPE.ON_DEMAND_ASMNT_RESET_VIEW:
      currentState[action.sectionName].isLoading = true
      currentState[action.sectionName].dataStatus = ''
      currentState[action.sectionName].data = {}
      return { ...currentState }
    case REDUCER_TYPE.RESET_API_CALLS:
      currentState.cancelToken.cancel('cancelling operation')
      currentState.cancelToken = axios.CancelToken.source()
      return { ...currentState }
    case REDUCER_TYPE.RESET:
      return onDemandAsmntInitialState()
    case REDUCER_TYPE.TOGGLE_HAS_ERROR_POPUP:
      currentState.hasErrorPopUP = action.hasErrorPopUP
      return { ...currentState }
    default:
      return currentState
  }
}
export default OnDemandAsmntReducer
