import { Grid } from '@material-ui/core'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'
import { getMonthDayYear } from 'utils/Date'
import {
  Card,
  CardInfoSection,
  CustomTooltip,
  Histogram,
  RegularTable
} from 'components'
import { GlobalContext } from 'context/GlobalStateProvider'
import { FEATURE_FLAGS, PAGE_CON } from 'constant/constants'
import Error from 'utils/error'
import React, { useContext } from 'react'
import clsx from 'clsx'
import LoadingMask from 'utils/loadingMask'
import numberData from 'utils/numberFormat'
import * as S from './styles'

const tempData = [
  {
    name: 'MIPS AB',
    y: 0.01,
    category: 'Well Below 2°C',
    isSelected: false
  },
  {
    name: 'Shoei Co. Ltd. (7839)',
    y: 0.02,
    category: 'Well Below 2°C',
    isSelected: false
  },
  {
    name: 'Schaffer Corp. Ltd.',
    y: 0.03,
    category: 'Well Below 2°C',
    isSelected: false
  },
  {
    name: 'XXL ASA',
    y: 0.04,
    category: 'Well Below 2°C',
    isSelected: false
  },
  {
    name: 'Yondoshi Holdings, Inc.',
    y: 0.05,
    category: 'Well Below 2°C',
    isSelected: false
  },
  {
    name: 'Movado Group, Inc.',
    y: 0.06,
    category: 'Well Below 2°C',
    isSelected: false
  },
  {
    name: 'Canada Goose Holdings, Inc.',
    y: 0.3,
    category: 'Well Below 2°C',
    isSelected: false
  },
  {
    name: 'NanJi E-Commerce Co., Ltd.',
    y: 0.5,
    category: 'Well Below 2°C',
    isSelected: false
  },
  {
    name: 'Brunello Cucinelli SpA',
    y: 1.0,
    category: 'Well Below 2°C',
    isSelected: false
  },
  {
    name: 'Geox SpA',
    y: 1.2,
    category: 'Below 2°C',
    isSelected: false
  },
  {
    name: 'Wacoal Holdings Corp.',
    y: 1.3,
    category: 'Below 2°C',
    isSelected: false
  },
  {
    name: 'Van de Velde NV',
    y: 1.5,
    category: 'Below 2°C',
    isSelected: false
  },
  {
    name: 'Delta Plus Group SA',
    y: 1.6,
    category: 'Below 2°C',
    isSelected: false
  },
  {
    name: 'Descente Ltd.',
    y: 1.7,
    category: 'Below 2°C',
    isSelected: false
  },
  {
    name: 'Rocky Brands, Inc.',
    y: 1.9,
    category: 'Below 2°C',
    isSelected: false
  },
  {
    name: 'Steven Madden Ltd.',
    y: 2.2,
    category: 'Above 2°C',
    isSelected: true
  },
  {
    name: 'Moncler SpA',
    y: 2.3,
    category: 'Above 2°C',
    isSelected: false
  },
  {
    name: 'Salvatore Ferragamo SpA',
    y: 2.5,
    category: 'Above 2°C',
    isSelected: false
  },
  {
    name: 'Page Industries Ltd.',
    y: 2.6,
    category: 'Above 2°C',
    isSelected: false
  },
  {
    name: 'PT Hartadinata Abadi',
    y: 2.7,
    category: 'Above 2°C',
    isSelected: false
  },
  {
    name: 'Pou Chen Corp.',
    y: 3.0,
    category: 'Above 2°C',
    isSelected: false
  },
  {
    name: 'Fossil Group, Inc.',
    y: 3.1,
    category: 'Above 2°C',
    isSelected: false
  },
  {
    name: 'Belle International Holdings Ltd.',
    y: 3.2,
    category: 'Above 2°C',
    isSelected: false
  }
]

const gsData = [
  {
    name: 'MIPS AB',
    y: 1,
    category: 'Minor',
    score_range: '0-20%',
    isSelected: false
  },
  {
    name: 'Shoei Co. Ltd. (7839)',
    y: 10,
    category: 'Minor',
    score_range: '0-20%',
    isSelected: false
  },
  {
    name: 'Schaffer Corp. Ltd.',
    y: 11,
    category: 'Minor',
    score_range: '0-20%',
    isSelected: false
  },
  {
    name: 'XXL ASA',
    y: 11,
    category: 'Minor',
    score_range: '0-20%',
    isSelected: false
  },
  {
    name: 'Yondoshi Holdings, Inc.',
    y: 12,
    category: 'Minor',
    score_range: '0-20%',
    isSelected: false
  },
  {
    name: 'Movado Group, Inc.',
    y: 13,
    category: 'Minor',
    score_range: '0-20%',
    isSelected: false
  },
  {
    name: 'Canada Goose Holdings, Inc.',
    y: 15,
    category: 'Minor',
    score_range: '0-20%',
    isSelected: false
  },
  {
    name: 'NanJi E-Commerce Co., Ltd.',
    y: 18,
    category: 'Minor',
    score_range: '0-20%',
    isSelected: false
  },
  {
    name: 'Brunello Cucinelli SpA',
    y: 19,
    category: 'Minor',
    score_range: '0-20%',
    isSelected: false
  },
  {
    name: 'Geox SpA',
    y: 22,
    category: 'Significant',
    score_range: '20-50%',
    isSelected: false
  },
  {
    name: 'Wacoal Holdings Corp.',
    y: 33,
    category: 'Significant',
    score_range: '20-50%',
    isSelected: false
  },
  {
    name: 'Van de Velde NV',
    y: 39,
    category: 'Significant',
    score_range: '20-50%',
    isSelected: false
  },
  {
    name: 'Delta Plus Group SA',
    y: 44,
    category: 'Significant',
    score_range: '20-50%',
    isSelected: false
  },
  {
    name: 'Descente Ltd.',
    y: 45,
    category: 'Significant',
    score_range: '20-50%',
    isSelected: false
  },
  {
    name: 'Rocky Brands, Inc.',
    y: 48,
    category: 'Significant',
    score_range: '20-50%',
    isSelected: false
  },
  {
    name: 'Steven Madden Ltd.',
    y: 64,
    category: 'Major',
    score_range: '50-100%',
    isSelected: true
  },
  {
    name: 'Moncler SpA',
    y: 66,
    category: 'Major',
    score_range: '50-100%',
    isSelected: false
  },
  {
    name: 'Salvatore Ferragamo SpA',
    y: 74,
    category: 'Major',
    score_range: '50-100%',
    isSelected: false
  },
  {
    name: 'Page Industries Ltd.',
    y: 78,
    category: 'Major',
    score_range: '50-100%',
    isSelected: false
  },
  {
    name: 'PT Hartadinata Abadi',
    y: 79,
    category: 'Major',
    score_range: '50-100%',
    isSelected: false
  },
  {
    name: 'Pou Chen Corp.',
    y: 88,
    category: 'Major',
    score_range: '50-100%',
    isSelected: false
  },
  {
    name: 'Fossil Group, Inc.',
    y: 88,
    category: 'Major',
    score_range: '50-100%',
    isSelected: false
  },
  {
    name: 'Belle International Holdings Ltd.',
    y: 95,
    category: 'Major',
    score_range: '50-100%',
    isSelected: false
  }
]

const ta_categoryInfo = [
  [
    {
      labelType: 'Data',
      dataField: 'aligned_temp',
      alignment: 'left',
      minHeight: '24px',
      borderRadius: '2px',
      className: 'climateStyle',
      padding: '4px 8px',
      bgColor: {
        colorGroup: 'TA_CATEGORY',
        colorDerivedByField: 'QualitativeScore'
      },
      color: {
        colorGroup: 'TA_CATEGORY_TEXT',
        colorDerivedByField: 'QualitativeScore'
      }
    }
  ]
]

const cf_categoryInfo = [
  [
    {
      labelType: 'Data',
      dataField: 'cf_ranking',
      alignment: 'left',
      minHeight: '24px',
      borderRadius: '2px',
      className: 'climateStyle',
      padding: '4px 8px',
      color: {
        colorGroup: 'CF_CATEGORY_TEXT',
        colorDerivedByField: 'ranking'
      },
      bgColor: {
        colorGroup: 'ranking',
        colorDerivedByField: 'ranking'
      }
    }
  ]
]

const gs_categoryInfo = [
  [
    {
      labelType: 'Data',
      dataField: 'gs_ranking',
      alignment: 'left',
      minHeight: '24px',
      borderRadius: '2px',
      className: 'climateStyle',
      padding: '4px 8px',
      color: {
        colorGroup: 'CATEGORY',
        colorDerivedByField: 'textColor'
      },
      bgColor: {
        colorGroup: 'GSA_CATEGORY',
        colorDerivedByField: 'ranking'
      }
    }
  ]
]

const bs_categoryInfo = [
  [
    {
      labelType: 'Data',
      dataField: 'bs_ranking',
      alignment: 'left',
      minHeight: '24px',
      borderRadius: '2px',
      className: 'climateStyle',
      padding: '4px 8px',
      color: {
        colorGroup: 'CATEGORY',
        colorDerivedByField: 'textColor'
      },
      bgColor: {
        colorGroup: 'BSA_CATEGORY',
        colorDerivedByField: 'ranking'
      }
    }
  ]
]

const cf_GSAColInfo = [
  {
    HeaderLabel: 'Products and Technologies',
    HideLabel: false,
    Alignment: 'left',
    DataField: [
      {
        Name: 'title',
        Alignment: 'left',
        width: 200
      }
    ]
  },
  {
    HeaderLabel: 'Investment in Category',
    isClimateProfile: true,
    HideLabel: false,
    Alignment: 'right',
    DataField: [
      {
        Name: 'investment_in_category_msg',
        Alignment: 'right',
        LeftPadding: 0,
        width: 100,
        FallbackField: {
          Name: 'investment_in_category',
          Alignment: 'right',
          NumberType: {
            isDecimal: false,
            isPercentage: true
          }
        }
      }
    ]
  }
]

const cf_BSAColInfo = [
  {
    HeaderLabel: 'Fossil Fuel Disclosures',
    HideLabel: false,
    Alignment: 'left',
    DataField: [
      {
        Name: 'title',
        Alignment: 'left',
        width: 200
      }
    ]
  },
  {
    HeaderLabel: 'Investment in Category',
    isClimateProfile: true,
    HideLabel: false,
    Alignment: 'right',
    DataField: [
      {
        Name: 'investment_in_category_msg',
        Alignment: 'right',
        LeftPadding: 0,
        width: 100,
        FallbackField: {
          Name: 'investment_in_category',
          Alignment: 'right',
          NumberType: {
            isDecimal: false,
            isPercentage: true
          }
        }
      }
    ]
  }
]

const TransitionRiskUDM = ({ reloadComponent }) => {
  const classes = S.useStyles()
  const [climateState] = useContext(GlobalContext).globalClimate

  // Common functions to manipulate graph data
  const getCategories = (sortedData) => sortedData.map((val) => val.category)
  const getYaxisValues = (histogram) =>
    histogram.filter((val) => val.y).map((val) => val.y)
  const calculateSum = (yAxisValues) =>
    yAxisValues.reduce((partialSum, a) => partialSum + a, 0)
  const between = (x, min, max) => {
    const rounded = Math.round(x)
    return rounded >= min && rounded <= max
  }
  const getSelected = (data, selected) => {
    let selectedItem = data.find((info) => info.name === selected)
    if (!selectedItem)
      selectedItem = { name: '', y: '', category: '', isSelected: false }
    return selectedItem
  }

  const computeRiskLevel = (val, graphType) => {
    let riskLevel

    if (graphType === 'carbon') {
      if (val < 100000) {
        riskLevel = 'moderate'
      } else if (between(val, 100000, 999999)) {
        riskLevel = 'significant'
      } else if (between(val, 1000000, 9999999)) {
        riskLevel = 'high'
      } else if (val >= 10000000) {
        riskLevel = 'intense'
      }
    } else if (graphType === 'brownshare') {
      if (val > 0 && val < 20) {
        riskLevel = 'green_signif'
      } else if (val === 0) {
        riskLevel = 'green_major'
      } else {
        riskLevel = 'green_no'
      }
    } else if (graphType === 'greenshare') {
      riskLevel = 'green_signif'
    } else if (graphType === 'temp') {
      riskLevel = 'two'
    } else {
      riskLevel = 'N/A'
    }
    return riskLevel
  }

  const getTempAlignComp = () => {
    let tempAlignment = climateState.transTempAlign.data

    const tmp = {
      QualitativeScore: tempAlignment?.temperature_alignment?.score_category,
      aligned_temp: tempAlignment?.temperature_alignment?.score_category
    }

    return [
      <Grid
        container
        direction="column"
        className={classes.ta_gridStyle}
        key={'temp_align_id'}
      >
        <Grid
          item
          className={classes.tempTextStyle}
          style={{ paddingBottom: '4px' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            Temperature Alignment
            <Grid item style={{ paddingBottom: '4px' }}>
              {tempHasData() && (
                <CardInfoSection
                  key={'climate-test-id'}
                  id={'climate-from-summaryoverview-'}
                  contentInfo={ta_categoryInfo}
                  data={tmp}
                  climateTable={true}
                  outerBackgroundColor={
                    themeColorPalette.palette.sys_trad.bg.main
                  }
                />
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>,
      !tempHasData() ? (
        noData(
          'tempAlignErr',
          'Read more about Temperature Alignment methodology',
          'TA'
        )
      ) : (
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.transitionRisktempStyle}
        >
          {tempAlignment?.temperature_alignment?.implied_temperature_rise && (
            <Grid
              item
              xs={12}
              style={{ paddingLeft: '12px', paddingBottom: '6px' }}
            >
              {'Implied Temperature Rise:'}
              <span style={{ paddingLeft: '8px', ...textTheme.typography.p3 }}>
                {tempAlignment?.temperature_alignment
                  ?.implied_temperature_rise_msg
                  ? tempAlignment?.temperature_alignment
                      ?.implied_temperature_rise_msg
                  : `${tempAlignment?.temperature_alignment?.implied_temperature_rise} \xB0C`}
              </span>
            </Grid>
          )}
          {tempAlignment?.temperature_alignment
            ?.emissions_reduction_target_year && (
            <Grid
              item
              xs={12}
              style={{ paddingLeft: '12px', paddingBottom: '6px' }}
            >
              {'Emissions Reduction Target Year:'}
              <span style={{ paddingLeft: '8px', ...textTheme.typography.p3 }}>
                {
                  tempAlignment?.temperature_alignment
                    ?.emissions_reduction_target_year
                }
              </span>
            </Grid>
          )}
          {tempAlignment?.temperature_alignment?.target_description && (
            <Grid
              item
              xs={12}
              style={{ paddingLeft: '12px', paddingTop: '6px' }}
            >
              {'Target Description:'}
              <span style={{ paddingLeft: '8px', ...textTheme.typography.p3 }}>
                {tempAlignment?.temperature_alignment?.target_description}
              </span>
            </Grid>
          )}
          {tempAlignment?.temperature_alignment?.last_updated_date && (
            <Grid
              item
              xs={12}
              className={classes.dateStyle}
              style={{ paddingLeft: '12px', paddingTop: '6px' }}
            >
              {'Updated on '}
              {getMonthDayYear(
                tempAlignment?.temperature_alignment?.last_updated_date,
                'month day, year'
              )}
            </Grid>
          )}
        </Grid>
      )
    ]
  }

  const getCarbonFootprintComp = () => {
    let carbonFootPrint = climateState.transCarbonFootPrint.data
    const valueColor = themeColorPalette.palette.sys_trad.white.main

    const tmp = {
      cf_ranking:
        carbonFootPrint?.score_category === 'No Info'
          ? `${carbonFootPrint?.score_category?.toUpperCase()}`
          : `${carbonFootPrint.score_category?.toUpperCase()}`,
      ranking: carbonFootPrint.score_category,
      textColor: valueColor
    }

    return [
      <Grid
        container
        direction="column"
        className={classes.ta_gridStyle}
        key={'carbon_footprint_id'}
      >
        <Grid
          item
          className={classes.tempTextStyle}
          style={{ paddingBottom: '4px' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            Carbon Footprint
            <Grid item style={{ paddingBottom: '4px' }}>
              {cfHasData() && (
                <CardInfoSection
                  key={'climate-test-id'}
                  id={'climate-from-summaryoverview-'}
                  contentInfo={cf_categoryInfo}
                  data={tmp}
                  climateTable={true}
                  outerBackgroundColor={
                    themeColorPalette.palette.sys_trad.bg.main
                  }
                />
              )}
            </Grid>
          </div>
        </Grid>
      </Grid>,
      !cfHasData() ? (
        noData('cfErr', 'Read more about Transition Risk methodology', 'CF')
      ) : (
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.transitionRisktempStyle}
        >
          {(carbonFootPrint.carbon_footprint_value_total_msg ||
            carbonFootPrint.carbon_footprint_value_total) && (
            <CustomTooltip
              title={
                <ul>
                  <li>
                    {
                      'We evaluate a companies carbon footprint based on the direct (Scope 1) and indirect (Scope 2) emissions.'
                    }
                  </li>
                  <li>
                    {
                      'When emissions data is not publicly disclosed, we estimate Scope 1 and Scope 2 emissions using our own proprietary models.'
                    }
                  </li>
                  <li>
                    {
                      'Other indirect emissions (Scope 3), are also collected or estimated and provided but are not part of the carbon footprint grade.'
                    }
                  </li>
                </ul>
              }
              placement="top-end"
            >
              <Grid
                item
                xs={12}
                style={{ paddingLeft: '12px', paddingBottom: '6px' }}
              >
                {PAGE_CON.CRBN_FT_PRT_TITLE}
                <span className={classes.tableValueStyle}>
                  {carbonFootPrint.carbon_footprint_value_total_msg
                    ? carbonFootPrint.carbon_footprint_value_total_msg
                    : numberData(carbonFootPrint.carbon_footprint_value_total, {
                        isCommaSeparated: true,
                        keepRawValue: true
                      })}
                </span>
                <span className={classes.eqStyle}>
                  {carbonFootPrint.carbon_footprint_value_total_msg
                    ? null
                    : 't CO2 eq.'}
                </span>
              </Grid>
            </CustomTooltip>
          )}
          <Grid
            item
            xs={12}
            style={{ paddingLeft: '12px', paddingBottom: '6px' }}
          >
            {'Scope 1 (t CO2 eq)'}
            <span className={classes.tableValueStyle}>
              {carbonFootPrint.emissions_scope_1_msg
                ? carbonFootPrint.emissions_scope_1_msg
                : numberData(carbonFootPrint.emissions_scope_1, {
                    isCommaSeparated: true,
                    keepRawValue: true
                  })}
            </span>
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: '12px', paddingTop: '6px' }}>
            {'Scope 2 (t CO2 eq)'}
            <span className={classes.tableValueStyle}>
              {carbonFootPrint.emissions_scope_2_msg
                ? carbonFootPrint.emissions_scope_2_msg
                : numberData(carbonFootPrint.emissions_scope_2, {
                    isCommaSeparated: true,
                    keepRawValue: true
                  })}
            </span>
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: '12px', paddingTop: '6px' }}>
            {'Scope 3 (t CO2 eq)'}
            <span className={classes.tableValueStyle}>
              {carbonFootPrint.emissions_scope_3_msg
                ? carbonFootPrint.emissions_scope_3_msg
                : numberData(carbonFootPrint.emissions_scope_3, {
                    isCommaSeparated: true,
                    keepRawValue: true
                  })}
            </span>
          </Grid>
          {carbonFootPrint.source && (
            <Grid
              item
              xs={12}
              style={{ paddingLeft: '12px', paddingBottom: '6px' }}
            >
              {'Source'}
              <span className={classes.tableValueStyle}>
                {carbonFootPrint.source}
              </span>
            </Grid>
          )}
          {(carbonFootPrint.third_party_verification ||
            carbonFootPrint.third_party_verification_msg) && (
            <Grid
              item
              xs={12}
              style={{ paddingLeft: '12px', paddingBottom: '6px' }}
            >
              {'3rd Party Verification'}
              <span className={classes.tableValueStyle}>
                {carbonFootPrint.third_party_verification_msg
                  ? carbonFootPrint.third_party_verification_msg
                  : carbonFootPrint.third_party_verification}
              </span>
            </Grid>
          )}
          {carbonFootPrint.carbon_footprint_reporting_year && (
            <Grid
              item
              xs={12}
              style={{ paddingLeft: '12px', paddingBottom: '6px' }}
            >
              {'Carbon Footprint Reporting Year'}
              <span className={classes.tableValueStyle}>
                {carbonFootPrint.carbon_footprint_reporting_year}
              </span>
            </Grid>
          )}
          {carbonFootPrint.estimated && (
            <Grid
              item
              xs={12}
              style={{ paddingLeft: '12px', paddingBottom: '6px' }}
            >
              {'Estimated'}
              <span className={classes.tableValueStyle}>
                {carbonFootPrint.estimated}
              </span>
            </Grid>
          )}
          {carbonFootPrint.carbon_intensity && (
            <Grid
              item
              xs={12}
              style={{ paddingLeft: '12px', paddingBottom: '6px' }}
            >
              {'Carbon Intensity (tCO2eq/M revenue)'}
              <span className={classes.tableValueStyle}>
                {numberData(carbonFootPrint.carbon_intensity, {
                  isCommaSeparated: true,
                  keepRawValue: true
                })}
              </span>
            </Grid>
          )}
          {carbonFootPrint.last_updated_date && (
            <Grid
              item
              xs={12}
              className={classes.dateStyle}
              style={{ paddingLeft: '12px', paddingTop: '6px' }}
            >
              {'Updated on '}
              {getMonthDayYear(
                carbonFootPrint.last_updated_date,
                'month day, year'
              )}
            </Grid>
          )}
        </Grid>
      )
    ]
  }

  const isSelectedEntity = (companyName, selected) => {
    return companyName === selected ? true : false
  }

  const getBrownShareCategory = (category) => {
    if (category === '0%') {
      return 'None'
    } else if (category === '0-20%') {
      return 'Minor'
    } else if (category === '20-100%') {
      return 'Major'
    } else {
      return category
    }
  }

  const getGraphData = (graphType) => {
    let graphData = []
    let companySelectedName = ''
    let sector = ''

    switch (graphType) {
      case 'carbon':
        graphData =
          climateState.transCarbonFootPrintChart?.data?.sector_distribution?.map(
            ({ company_name: name, score: y, score_category }) => {
              return {
                name,
                y,
                category: score_category,
                isSelected: isSelectedEntity(
                  name,
                  climateState.transCarbonFootPrintChart?.data?.company_name
                )
              }
            }
          )
        companySelectedName =
          climateState.transCarbonFootPrintChart?.data?.company_name
        sector = climateState.transCarbonFootPrintChart?.data?.mesg_sector
        break
      case 'temp':
        graphData = tempData
        companySelectedName = 'Steven Madden Ltd.'
        sector = 'Retail & Specialized Banks'
        break
      case 'greenshare':
        graphData = gsData
        companySelectedName = 'Steven Madden Ltd.'
        sector = 'Retail & Specialized Banks'
        break
      case 'brownshare':
        graphData =
          climateState.transBrownShareFootPrintChart?.data?.sector_distribution?.map(
            ({
              company_name: name,
              score: y,
              score_category,
              score_actual,
              score_range
            }) => {
              return {
                name,
                y,
                score_actual,
                score_range: getBrownShareCategory(score_category),
                range: score_range,
                category: score_category,
                isSelected: isSelectedEntity(
                  name,
                  climateState.transBrownShareFootPrintChart?.data?.company_name
                )
              }
            }
          )
        companySelectedName =
          climateState.transBrownShareFootPrintChart?.data?.company_name
        sector = climateState.transBrownShareFootPrintChart?.data?.mesg_sector
        break
      default:
        break
    }

    if (graphData?.length > 0) {
      const selected = getSelected(graphData, companySelectedName)
      const middleTopLabel =
        selected?.name &&
        `${selected.name} compared to ${
          graphData.length - 1
        } companies in ${sector}`
      const yAxisValues = getYaxisValues(graphData)
      const average = calculateSum(yAxisValues) / graphData.length
      const avg = average > 0 && average <= 1 ? 1 : Math.round(average)
      const sectorRisk = computeRiskLevel(avg, graphType)

      let avgCategory = ''
      if (graphType === 'brownshare') {
        const {
          company_score_category,
          company_score_range,
          sector_score_category,
          sector_score_range
        } = climateState.transBrownShareFootPrintChart?.data || {}
        avgCategory = sector_score_range || sector_score_category
        selected.category = company_score_range || company_score_category
      } else if (graphType === 'greenshare') {
        if (avg > 0 && avg <= 20) {
          avgCategory = '0-20%'
        } else if (avg > 20 && avg <= 50) {
          avgCategory = '20-50%'
        } else if (avg === 0) {
          avgCategory = '0%'
        } else {
          avgCategory = '50-100%'
        }
      }

      let legends = []
      let labels = {}
      let colorPalette = {}
      switch (graphType) {
        case 'carbon':
          legends = [
            {
              name: climateState.transCarbonFootPrintChart?.data?.mesg_sector,
              risk: `${numberData(avg, {
                isCommaSeparated: true,
                keepRawValue: true
              })} (t CO2eq)`,
              category: sectorRisk
            },
            {
              name: selected.name,
              risk: `${numberData(selected.y, {
                isCommaSeparated: true,
                keepRawValue: true
              })} (t CO2eq)`,
              category: 'status'
            }
          ]

          labels = {
            yTop: 'Intense',
            yBottom: 'Moderate',
            xCenter: 'Emissions (t CO2eq)'
          }

          colorPalette = themeColorPalette.palette.carbfoot

          break
        case 'temp':
          legends = [
            {
              name: 'Clothes, Footwear & Accessories',
              risk: `${numberData(avg, {
                isCommaSeparated: true,
                keepRawValue: true
              })}°C`,
              category: sectorRisk
            },
            {
              name: 'Steven Madden Ltd.',
              risk: `${numberData(selected.y, {
                isCommaSeparated: true,
                keepRawValue: true
              })}°C`,
              category: 'status'
            }
          ]

          labels = {
            yTop: 'Above 2°C',
            yBottom: 'Well Below 2°C',
            xRight: '3.1°C',
            xLeft: '0°C'
          }

          colorPalette = themeColorPalette.palette.genrang_c

          break
        case 'greenshare':
          legends = [
            {
              name: 'Clothes, Footwear & Accessories',
              risk: `${avgCategory}`,
              category: sectorRisk
            },
            {
              name: 'Steven Madden Ltd.',
              risk: `${selected.score_range}`,
              category: 'status'
            }
          ]

          labels = {
            yBottom: 'None',
            yTop: 'Major',
            xLeft: '0%',
            xRight: '20-100%'
          }

          colorPalette = themeColorPalette.palette.major_no

          break
        case 'brownshare':
          legends = [
            {
              name: climateState.transBrownShareFootPrintChart?.data
                ?.mesg_sector,
              risk: `${avgCategory}`,
              category: sectorRisk
            },
            {
              name: selected.name,
              risk: `${selected.category}`,
              category: 'status'
            }
          ]

          labels = {
            yBottom: 'None',
            yTop: 'Major',
            xLeft: '0%',
            xRight: '>=50%'
          }

          colorPalette = themeColorPalette.palette.major_no

          break
        default:
          break
      }

      return {
        data: graphData,
        categories: getCategories(graphData),
        totalCount: graphData.length,
        avg,
        labels,
        selected,
        middleTopLabel,
        colorPalette,
        legends,
        plotLineColor: sectorRisk
      }
    } else {
      return {}
    }
  }

  const getGreenShareComp = () => {
    let greenShare = climateState.transGreenShare.data

    const valueColor = themeColorPalette.palette.sys_trad.white.main
    const tmp = {
      gs_ranking: greenShare.score_category?.toUpperCase(),
      ranking: greenShare.score_category,
      textColor: valueColor
    }

    return [
      <Grid
        container
        direction="column"
        className={classes.ta_gridStyle}
        key="greenShareAssessment"
      >
        <Grid
          item
          className={classes.tempTextStyle}
          style={{ paddingBottom: '4px' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            Green Share Assessment
            <Grid item style={{ paddingBottom: '4px' }}>
              <CardInfoSection
                key={'climate-test-id'}
                id={'climate-from-summaryoverview-'}
                contentInfo={gs_categoryInfo}
                data={tmp}
                climateTable={true}
                outerBackgroundColor={
                  themeColorPalette.palette.sys_trad.bg.main
                }
              />
            </Grid>
          </div>
        </Grid>
      </Grid>,
      !gsHasData() ? (
        noData('gsErr', 'Read more about Transition Risk methodology', 'GSA')
      ) : (
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.transitionRisktempStyle}
        >
          <Grid item xs={12} style={{ paddingLeft: '12px', paddingTop: '6px' }}>
            {'% of Investments in Commercial Activities linked to Green'} <br />{' '}
            {'Solutions:'}
            <span style={{ paddingLeft: '8px', ...textTheme.typography.p3 }}>
              {greenShare.score_msg
                ? greenShare.score_msg
                : `${greenShare.score}%`}
            </span>
          </Grid>
          {
            <Grid
              item
              xs={12}
              style={{ paddingLeft: '12px', paddingTop: '6px' }}
            >
              <RegularTable
                id={'table-id-1'}
                columns={cf_GSAColInfo}
                totalData={greenShare.green_share_products}
                tableEditable={false}
              />
            </Grid>
          }
          {greenShare.updated_date && (
            <Grid item xs={12} className={classes.dateStyle}>
              {'Updated on '}
              {getMonthDayYear(greenShare.updated_date, 'month day, year')}
            </Grid>
          )}
        </Grid>
      )
    ]
  }

  const getBrownShareComp = () => {
    let brownShare = climateState?.transBrownShare?.data

    const valueColor = themeColorPalette.palette.sys_trad.white.main
    const tmp = {
      bs_ranking: brownShare.score_category?.toUpperCase() || '',
      ranking: brownShare.score_category || '',
      textColor: valueColor
    }

    return [
      <Grid
        container
        direction="column"
        className={classes.ta_gridStyle}
        key="brownShareAssessment"
      >
        <Grid
          item
          className={classes.tempTextStyle}
          style={{ paddingBottom: '4px' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            Brown Share Assessment
            <Grid item style={{ paddingBottom: '4px' }}>
              <CardInfoSection
                key={'climate-test-id'}
                id={'climate-from-summaryoverview-'}
                contentInfo={bs_categoryInfo}
                data={tmp}
                climateTable={true}
                outerBackgroundColor={
                  themeColorPalette.palette.sys_trad.bg.main
                }
              />
            </Grid>
          </div>
        </Grid>
      </Grid>,
      !bsHasData() ? (
        noData('bsErr', 'Read more about Transition Risk methodology', 'BSA')
      ) : (
        <Grid
          container
          direction="column"
          spacing={2}
          className={classes.transitionRisktempStyle}
        >
          <Grid item xs={12} style={{ paddingLeft: '12px', paddingTop: '6px' }}>
            {'% of Overall Revenue derived from Fossil Fuel'} <br />{' '}
            {'Activities:'}
            <span style={{ paddingLeft: '8px', ...textTheme.typography.p3 }}>
              {brownShare.score_msg
                ? brownShare.score_msg
                : `${brownShare.score}%`}
            </span>
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: '12px', paddingTop: '6px' }}>
            <RegularTable
              id={'table-id-1'}
              columns={cf_BSAColInfo}
              totalData={brownShare.brown_share_products}
              tableEditable={false}
            />
          </Grid>
          {brownShare.updated_date && (
            <Grid item xs={12} className={classes.dateStyle}>
              {'Updated on '}
              {getMonthDayYear(brownShare.updated_date, 'month day, year')}
            </Grid>
          )}
        </Grid>
      )
    ]
  }

  function isLoading() {
    return climateState.transTempAlign.isLoading
  }

  function isError() {
    return climateState.transTempAlign.dataStatus === PAGE_CON.ERROR
  }

  const tempHasData = () => {
    return Object.keys(climateState.transTempAlign.data).length > 0
  }

  const cfHasData = () => {
    return Object.keys(climateState.transCarbonFootPrint.data).length > 0
  }

  const gsHasData = () => {
    return Object.keys(climateState.transGreenShare.data).length > 0
  }

  const bsHasData = () => {
    return Object.keys(climateState.transBrownShare.data).length > 0
  }

  const noData = (id) => {
    return (
      <Grid
        container
        direction="column"
        spacing={2}
        className={classes.transitionRisktempStyle}
      >
        <Grid
          item
          xs={12}
          className={classes.dateStyle}
          style={{ paddingTop: '6px' }}
        >
          <Error
            id={id}
            dataStatus={PAGE_CON.SUCCESS}
            errorOccuredOn={PAGE_CON.CLIMATE_TRAN_RISK}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container className={classes.gridContainer}>
      {isLoading() ? (
        <LoadingMask />
      ) : isError() ? (
        <Error
          id={'tranError'}
          dataStatus={PAGE_CON.ERROR}
          onErrorClick={reloadComponent}
          errorOccuredOn={PAGE_CON.CLIMATE_TRAN_RISK}
        />
      ) : (
        <>
          <Grid item container xs={12} sm={12} className={classes.sectionGrid}>
            <Grid item xs={12} sm={'auto'}>
              <Card
                components={getTempAlignComp()}
                id="tempAlignclimate-test-id"
                selectionType="none"
                key="tempAlignclimate-test-id"
                maxWidth={'368px'}
              />
            </Grid>
            <Grid item xs={12} sm={'auto'}>
              {FEATURE_FLAGS.ESG_MAP_SECTOR && (
                <Grid item container xs={12} sm={'auto'} direction="column">
                  <div key={'container-id-temperature-alignment-graph'}>
                    <Grid
                      item
                      className={clsx(classes.chart, classes.sectionChart)}
                    >
                      {!getGraphData('temp')?.data?.length > 0 ? (
                        <div className={classes.histogramChartNoDataError}>
                          <Error
                            id={'tempChartErr'}
                            dataStatus={PAGE_CON.SUCCESS}
                            errorOccuredOn={PAGE_CON.SECTOR_COMPARISON}
                          />
                        </div>
                      ) : (
                        <Histogram
                          id="barchart-test-id-0"
                          width={740}
                          height={165}
                          title=""
                          data={getGraphData('temp').data}
                          categories={getGraphData('temp').categories}
                          totalCount={getGraphData('temp').totalCount}
                          avg={getGraphData('temp').avg}
                          labels={getGraphData('temp').labels}
                          selected={getGraphData('temp').selected}
                          middleTopLabel={getGraphData('temp').middleTopLabel}
                          legends={getGraphData('temp').legends}
                          colorPalette={getGraphData('temp').colorPalette}
                          graph="temp"
                          graphColorCategory={'ta_category'}
                          itemColorCategory={'category'}
                          plotLineColor={getGraphData('temp').plotLineColor}
                        />
                      )}
                    </Grid>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12}>
            <Grid item xs={12} sm={'auto'}>
              <Card
                components={getCarbonFootprintComp()}
                id="carbonClimate-test-id"
                selectionType="none"
                key="carbonClimate-test-id"
                maxWidth={'368px'}
              />
            </Grid>
            <Grid item xs={12} sm={'auto'}>
              <Grid item container xs={12} sm={'auto'} direction="column">
                <div key={'container-id-carbon-foot-print-graph'}>
                  <Grid item className={classes.chart}>
                    {!getGraphData('carbon')?.data?.length > 0 ? (
                      <div className={classes.histogramChartNoDataError}>
                        <Error
                          id={'cfChartErr'}
                          dataStatus={PAGE_CON.SUCCESS}
                          errorOccuredOn={PAGE_CON.SECTOR_COMPARISON}
                        />
                      </div>
                    ) : (
                      <Histogram
                        id="barchart-test-id"
                        width={740}
                        height={165}
                        title=""
                        data={getGraphData('carbon').data}
                        categories={getGraphData('carbon').categories}
                        totalCount={getGraphData('carbon').totalCount}
                        avg={getGraphData('carbon').avg}
                        labels={getGraphData('carbon').labels}
                        selected={getGraphData('carbon').selected}
                        middleTopLabel={getGraphData('carbon').middleTopLabel}
                        legends={getGraphData('carbon').legends}
                        colorPalette={getGraphData('carbon').colorPalette}
                        graph="carbon"
                        graphColorCategory={'category'}
                        itemColorCategory={'category'}
                        plotLineColor={getGraphData('carbon').plotLineColor}
                      />
                    )}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12}>
            <Grid item xs={12} sm={'auto'}>
              <Card
                components={getGreenShareComp()}
                id="greenClimate-test-id"
                selectionType="none"
                key="greenClimate-test-id"
                maxWidth={'368px'}
              />
            </Grid>
            <Grid item xs={12} sm={'auto'}>
              {FEATURE_FLAGS.ESG_MAP_SECTOR && (
                <Grid item container xs={12} sm={'auto'} direction="column">
                  <div key={'container-id-greenshare-graph'}>
                    <Grid item className={classes.chart}>
                      {!getGraphData('greenshare')?.data?.length > 0 ? (
                        <div className={classes.histogramChartNoDataError}>
                          <Error
                            id={'tempChartErr'}
                            dataStatus={PAGE_CON.SUCCESS}
                            errorOccuredOn={PAGE_CON.SECTOR_COMPARISON}
                          />
                        </div>
                      ) : (
                        <Histogram
                          id="barchart-test-id-0"
                          width={740}
                          height={165}
                          title=""
                          data={getGraphData('greenshare').data}
                          categories={getGraphData('greenshare').categories}
                          totalCount={getGraphData('greenshare').totalCount}
                          avg={getGraphData('greenshare').avg}
                          labels={getGraphData('greenshare').labels}
                          selected={getGraphData('greenshare').selected}
                          middleTopLabel={
                            getGraphData('greenshare').middleTopLabel
                          }
                          legends={getGraphData('greenshare').legends}
                          colorPalette={getGraphData('greenshare').colorPalette}
                          graph="greenshare"
                          graphColorCategory={'gsa_category'}
                          itemColorCategory={'category'}
                          plotLineColor={
                            getGraphData('greenshare').plotLineColor
                          }
                        />
                      )}
                    </Grid>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12}>
            <Grid item xs={12} sm={'auto'}>
              <Card
                components={getBrownShareComp()}
                id="greenClimate-test-id"
                selectionType="none"
                key="greenClimate-test-id"
                maxWidth={'368px'}
              />
            </Grid>
            <Grid item xs={12} sm={'auto'}>
              <Grid item container xs={12} sm={'auto'} direction="column">
                <div key={'container-id-brownshare-graph'}>
                  <Grid item className={classes.chart}>
                    {!getGraphData('brownshare')?.data?.length > 0 ? (
                      <div className={classes.histogramChartNoDataError}>
                        <Error
                          id={'tempChartErr'}
                          dataStatus={PAGE_CON.SUCCESS}
                          errorOccuredOn={PAGE_CON.SECTOR_COMPARISON}
                        />
                      </div>
                    ) : (
                      <Histogram
                        id="barchart-test-id-0"
                        width={740}
                        height={165}
                        title=""
                        data={getGraphData('brownshare').data}
                        categories={getGraphData('brownshare').categories}
                        totalCount={getGraphData('brownshare').totalCount}
                        avg={getGraphData('brownshare').avg}
                        labels={getGraphData('brownshare').labels}
                        selected={getGraphData('brownshare').selected}
                        middleTopLabel={
                          getGraphData('brownshare').middleTopLabel
                        }
                        legends={getGraphData('brownshare').legends}
                        colorPalette={getGraphData('brownshare').colorPalette}
                        graph="brownshare"
                        graphColorCategory={'bsa_category'}
                        itemColorCategory={'category'}
                        plotLineColor={getGraphData('brownshare').plotLineColor}
                      />
                    )}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default TransitionRiskUDM
