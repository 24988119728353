import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconPositionUp = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2023 6.23994C11.9238 5.07765 10.1539 4 8 4C5.84607 4 4.07619 5.07765 2.79767 6.23994C2.16431 6.81573 1.6728 7.39372 1.34048 7.82718C1.29391 7.88792 1.25059 7.94567 1.21056 8C1.25059 8.05433 1.29391 8.11208 1.34048 8.17282C1.6728 8.60628 2.16431 9.18427 2.79767 9.76006C4.07619 10.9224 5.84607 12 8 12C10.1539 12 11.9238 10.9224 13.2023 9.76006C13.8357 9.18427 14.3272 8.60628 14.6595 8.17282C14.7061 8.11208 14.7494 8.05433 14.7894 8C14.7494 7.94567 14.7061 7.88792 14.6595 7.82718C14.3272 7.39372 13.8357 6.81573 13.2023 6.23994ZM0 8C0 8 3 13 8 13C13 13 16 8 16 8C16 8 13 3 8 3C3 3 0 8 0 8ZM0.85668 7.48415C0.856545 7.48393 0.856459 7.48379 0.85668 7.48415V7.48415Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 9C9.10457 9 10 8.10457 10 7C10 5.89543 9.10457 5 8 5C6.89543 5 6 5.89543 6 7C6 8.10457 6.89543 9 8 9ZM8 10C9.65685 10 11 8.65685 11 7C11 5.34315 9.65685 4 8 4C6.34315 4 5 5.34315 5 7C5 8.65685 6.34315 10 8 10Z"
      fill="#26415E"
    />
  </SvgIcon>
)
IconPositionUp.displayName = 'IconPositionUp'
IconPositionUp.muiName = 'SvgIcon'

export default IconPositionUp
