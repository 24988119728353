import React from 'react'
import { PAGE_CON } from 'constant/constants'
import { Grid } from '@material-ui/core'
import { EntityProfileService } from 'services'
import LoadingMask from 'utils/loadingMask'
import Error from 'utils/error'
import clsx from 'clsx'
import { REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import { PrimaryLink } from 'components'

let helperProps,
  helperEntityHeaderState,
  helperClimateState,
  helperClimateDispatch,
  helperClimateAPICallId

export const init = (
  props,
  entityHeaderState,
  climateState,
  climateDispatch,
  climateAPICallId
) => {
  helperProps = props
  helperEntityHeaderState = entityHeaderState
  helperClimateState = climateState
  helperClimateDispatch = climateDispatch
  helperClimateAPICallId = climateAPICallId
}

export const hasExportDocuments = () => {
  return (
    Object.keys(helperClimateState?.entitySourceDocuments?.data || []).length >
    0
  )
}

export const handleKeyDown = (x) => {
  if (x.keyCode === 27 && helperProps?.handleEntityPopupClose)
    helperProps?.handleEntityPopupClose()
}

export const getFullFileName = () => {
  const date = new Date()
  const formattedDate = date
    .toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    })
    .replace(/ /g, '-')

  return `${
    helperEntityHeaderState.globalEntityHeader.company_name
  }Profile${formattedDate}_${date.getTime()}`
}

const getDownloadName = (fileName) => {
  return fileName + '.pdf'
}

export const checkForPDFEntitlements = () => {
  return helperProps?.entitlements['export']
}

export const methodologiesModalContent = (
  fileCollection,
  esg,
  controversy,
  showMethodologyModal,
  classes,
  showSubtitle
) => {
  const fileList = fileCollection
    .filter((item) => item.entitlement)
    .map((fileItem, key) => {
      return (
        <li key={key}>
          <PrimaryLink
            id={`link-id-${key}`}
            linkText={fileItem.fileName}
            onClick={() => window.open(fileItem.file, '_blank')}
            linkClass="linkInline"
            linkColorChange={true}
          />
        </li>
      )
    })
  return (
    <>
      {showSubtitle && (
        <h2 className={classes.methologiesSubtitle}> Methodology Documents </h2>
      )}
      <ul className={classes.documentListLinks}>{fileList}</ul>
    </>
  )
}

const isData = (data) => {
  return data ? Object.keys(data).length > 0 : false
}

const getFileList = (data) => {
  if (isData(data)) {
    return data?.map((fileItem, key) => {
      return (
        fileItem.presigned_url && (
          <li key={key}>
            <a
              href={fileItem.presigned_url}
              download={getDownloadName(fileItem.document_name)}
              target="_blank"
              rel="noreferrer"
            >
              {fileItem.document_name}
            </a>
          </li>
        )
      )
    })
  }
}

const isError = (climateState) => {
  return climateState?.entitySourceDocuments?.dataStatus === PAGE_CON.ERROR
}

const isLoading = (climateState) => {
  return climateState?.entitySourceDocuments?.isLoading
}

const reloadComponent = (errorOccuredOn) => {
  switch (errorOccuredOn) {
    case PAGE_CON.ENTITY_SOURCE_DOCUMENTS:
      loadDocs(
        true,
        helperEntityHeaderState,
        helperClimateState,
        helperClimateDispatch,
        helperClimateAPICallId
      )
      break
    default:
      break
  }
}

const getExportSourcesModalContent = (
  fileList,
  classes,
  matchesSm,
  matchesMd,
  climateState
) => {
  return isLoading(climateState) ? (
    <Grid container direction="row" alignItems="center">
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={clsx(
          classes.exprtSrcLoader,
          matchesSm ? classes.exprtReLoaderSm : ''
        )}
      >
        <LoadingMask />
      </Grid>
    </Grid>
  ) : isError(climateState) ? (
    <Grid container direction="row" alignItems="center">
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={clsx(
          classes.exprtReLoader,
          matchesSm ? classes.exprtReLoaderSm : ''
        )}
      >
        <Error
          id={PAGE_CON.ENTITY_SOURCE_DOCUMENTS}
          dataStatus={PAGE_CON.ERROR}
          onErrorClick={reloadComponent}
          errorOccuredOn={PAGE_CON.ENTITY_SOURCE_DOCUMENTS}
        />
      </Grid>
    </Grid>
  ) : hasExportDocuments() ? (
    <div style={{ minWidth: '600px' }}>
      <div className={classes.exprtSrcDocMdlSubHdr}>
        {PAGE_CON.EXPRT_SRC_DOC_MODAL_SUB_HDR}
      </div>
      <ul
        className={clsx(
          classes.exprtModalList,
          classes.documentListLinks,
          matchesSm ? classes.exprtModalListSm : '',
          matchesMd ? classes.exprtModalListMd : ''
        )}
      >
        {fileList}
      </ul>
    </div>
  ) : (
    <Grid container direction="row">
      <Grid
        item
        className={clsx(
          classes.exprtDocError,
          matchesMd ? classes.exprtDocErrorMd : ''
        )}
      >
        <Error
          errorClass={'noMargin'}
          width={'102%'}
          margin={0}
          id={PAGE_CON.ENTITY_SOURCE_DOCUMENTS}
          topMargin={'0px'}
          dataStatus={'success'}
          hideBoxShadow={true}
          errorOccuredOn={PAGE_CON.ENTITY_SOURCE_DOCUMENTS}
        />
      </Grid>
    </Grid>
  )
}

export const exportSourcesDocumentsModalContent = (
  data,
  classes,
  matchesSm,
  matchesMd,
  climateState
) => {
  const fileList = getFileList(data)
  return getExportSourcesModalContent(
    fileList,
    classes,
    matchesSm,
    matchesMd,
    climateState
  )
}

export const checkForEntitlements = (esg, controversy) => {
  // 	If there are future source docuemtnts added for PR,TR etc then check for those as well
  return esg || controversy
}

export const loadDocs = async (
  isErrorReload = false,
  entityHeaderState,
  climateState,
  climateDispatch,
  climateAPICallId
) => {
  if (entityHeaderState?.globalEntityHeader.orbis_id !== '') {
    if (
      climateState?.entitySourceDocuments?.dataStatus !== '' &&
      isErrorReload === false
    )
      return

    climateDispatch &&
      climateDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionNames: [REDUCER_STATE.ENTITY_SOURCE_DOCUMENTS]
      })
    try {
      const response = await EntityProfileService.getSourceDocuments(
        entityHeaderState
      )

      climateDispatch &&
        climateDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          sectionNames: [
            {
              [REDUCER_STATE.ENTITY_SOURCE_DOCUMENTS]: response
                ? response.data
                : []
            }
          ],
          apiCallId: climateAPICallId
        })
    } catch (err) {
      climateDispatch &&
        climateDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionNames: [REDUCER_STATE.ENTITY_SOURCE_DOCUMENTS],
          apiCallId: climateAPICallId
        })
    }
  }
}
