import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from 'context/GlobalStateProvider'
import { REDUCER_TYPE } from 'constant/constants'
import {
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  MenuItem,
  Toolbar
} from '@material-ui/core'
import {
  FullFeaturedCrudGrid,
  IconClose,
  PrimaryButton,
  PrimaryModal,
  PrimaryPopover
} from 'components'
import themeColorPalette from 'theme/theme'
import LoadingMask from 'utils/loadingMask'
import { EntityService } from 'services'
import clsx from 'clsx'
import * as S from './styles'

const urlsWithOutCheckedOptions = (urlsMap) =>
  [...urlsMap.values()]
    .map((urlData) => {
      const optionsChecked = [...urlData.options.values()]
        .map((opt) => opt.get('checked') || opt.get('indeterminate'))
        .some((selected) => selected === true)

      return !optionsChecked && urlData
    })
    .filter((urlData) => urlData)

const AddMissingDocuments = ({ open, onClose, showDisclosureYear }) => {
  const classes = S.useStyles()
  const [driverState] = useContext(GlobalContext).globalSectorInfoState
  const [issuerUrlState, issuerUrlDispatch] =
    useContext(GlobalContext).globalIssuerUrl

  const [withOutSavingModalOpen, setWithOutSavingModalOpen] = useState(false)
  const [noOptionsCheckedModalOpen, setNoOptionsCheckedModalOpen] =
    useState(false)
  const [urlsWithoutOptions, setUrlsWithoutOptions] = useState([])
  const [uploadDisabled, setUploadDisabled] = useState(true)
  const [uploadedFileSucces, setUploadedFileSucces] = useState(false)
  const [uploadedFileError, setUploadedFileError] = useState(false)
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [isURLValidating, setIsURLValidating] = useState(false)

  const onUploadButtonClick = () => {
    handleDocUpload()
  }

  const handleOnContinueWithOutSaving = () => {
    onClose()
    setWithOutSavingModalOpen(false)
    issuerUrlDispatch({
      type: REDUCER_TYPE.RESET
    })
  }

  const handleOnClose = (e, reason) => {
    if (
      (reason &&
        (reason === 'backdropClick' || reason === 'escapeKeyDown') &&
        issuerUrlState.urlInfo.hasModifiedUrls) ||
      issuerUrlState.urlInfo.hasModifiedUrls
    ) {
      setWithOutSavingModalOpen(true)
      return
    }
    onClose()
  }

  const handleDocUpload = async () => {
    const prepareData = (urlsMap) => {
      const data = []
      const urls = [...urlsMap.values()]
      urls.forEach((url) => {
        const selectedCategories = [...url.options.entries()].filter(
          ([, categoryValue]) =>
            categoryValue.get('checked') === true ||
            categoryValue.get('indeterminate') === true
        )

        const info = {
          document_type: url.documentType,
          disclosure_year: url.disclosureYear,
          document_url: url.URL,
          assigned_categories: []
        }

        if (info.disclosure_year === '') {
          delete info.disclosure_year
        }

        selectedCategories.forEach(
          ([selectedCategoryName, selectedCategoryData]) => {
            const categoryInfo = {
              category: selectedCategoryName,
              sub_categories: [
                ...selectedCategoryData.get('childrens').entries()
              ]
                .filter(
                  ([, subCategoryData]) => subCategoryData['checked'] === true
                )
                .map(([name, { page = null }]) => ({ name, page }))
            }

            info.assigned_categories.push(categoryInfo)
          }
        )

        data.push(info)
      })

      return data
    }
    const urlsNotChecked = urlsWithOutCheckedOptions(
      issuerUrlState.urlInfo.urls
    )
    if (urlsNotChecked.length === 0) {
      try {
        const urls = prepareData(issuerUrlState.urlInfo.urls)
        setIsUploadingFile(true)
        setUploadDisabled(true)
        await EntityService.uploadMissingDocs({ urls })
        setIsUploadingFile(false)
        onClose()
        setUploadedFileSucces(true)
      } catch (e) {
        console.error(e)
        setIsUploadingFile(false)
        setUploadedFileSucces(false)
        setUploadedFileError(true)
        setUploadDisabled(false)
      }
    } else {
      setNoOptionsCheckedModalOpen(true)
      setUrlsWithoutOptions(urlsNotChecked)
    }
  }

  const loadUserDocumentData = async () => {
    issuerUrlDispatch({
      type: REDUCER_TYPE.UPDATE_URL_INFO,
      data: {
        options: driverState.driverData.data.driver_criteria
      }
    })
  }

  useEffect(() => {
    if (
      !driverState?.driverData?.isLoading &&
      driverState?.driverData?.dataStatus === 'success'
    ) {
      loadUserDocumentData()
    }
  }, [driverState?.driverData?.isLoading])

  useEffect(() => {
    setUploadDisabled(!issuerUrlState?.urlInfo?.hasModifiedUrls)
  }, [issuerUrlState?.urlInfo?.hasModifiedUrls])

  const BulletList = () => {
    return (
      <span className={classes.bullet_list_style}>
        {'• Please only add '}
        {<b> publicly accessible URL links </b>}
        {'for published documents'} <br />
        <span className={classes.doc_lang_message}>
          {'• At present we can only accept documents in the '}
          {<b> English </b>}
          {'language'} <br />
        </span>
      </span>
    )
  }

  return (
    <>
      {isURLValidating && (
        <Backdrop className={classes.backdrop} open={isURLValidating}>
          <LoadingMask loadText="Validating URL" />
        </Backdrop>
      )}
      {isUploadingFile && (
        <div className={classes.loadStyle}>
          <LoadingMask
            loaderColor={themeColorPalette.palette.sys_trad.status.main}
            textColor={themeColorPalette.palette.sys_trad.status.main}
            loadText="Uploading"
          />
        </div>
      )}
      <Dialog open={open} onClose={handleOnClose} maxWidth="lg" fullWidth>
        <Toolbar disableGutters className={classes.searchToolbarStyle}>
          <div>
            <MenuItem
              className={clsx(
                classes.menu_item_style,
                classes.drawerToggleSearch
              )}
            >
              {'Document Information'}
            </MenuItem>
            <BulletList classes={classes} />
          </div>
          <div
            onClick={handleOnClose}
            aria-hidden={true}
            className={classes.dialogue_style}
          >
            {<IconClose />}
          </div>
        </Toolbar>
        <DialogContent className={classes.dialogue_content_style}>
          <FullFeaturedCrudGrid
            showDisclosureYear={showDisclosureYear}
            setIsURLValidating={setIsURLValidating}
          />
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            buttonDisabled={uploadDisabled}
            onClick={onUploadButtonClick}
            id="upload-button"
            buttonClass="buttonContact"
            buttonText="Upload"
            sync={true}
          ></PrimaryButton>
        </DialogActions>
      </Dialog>
      {/* {@TODO: refactor this} */}
      {/* CLOSE CONFIRMATION WHEN HAS CHANGES*/}
      <PrimaryModal
        modalOpen={withOutSavingModalOpen}
        onModalClose={() => setWithOutSavingModalOpen(false)}
        modalClass="smallModal"
        modalTitle="Unsaved changes"
        modalContentComponent={[
          'You have unsaved changes. If you close this window all your changes will be lost, do you want to continue?'
        ]}
        modalActionComponent={
          <>
            <PrimaryButton
              buttonText="Continue without saving"
              onClick={handleOnContinueWithOutSaving}
              buttonClass="buttonPrimary"
            />
            <PrimaryButton
              buttonText="Cancel"
              buttonClass="buttonGhost"
              onClick={() => setWithOutSavingModalOpen(false)}
            />
          </>
        }
        modalNoCloseOnClick
      />
      {/* UPLOAD CLICK WHEN HAS NO OPTIONS CHECKED*/}
      <PrimaryModal
        modalOpen={noOptionsCheckedModalOpen}
        onModalClose={() => setNoOptionsCheckedModalOpen(false)}
        modalClass="smallModal"
        modalTitle="Are you sure?"
        modalContentComponent={
          <>
            <p className={classes.text}>{`The following ${
              urlsWithoutOptions.length > 1 ? 'URLs need' : 'URL needs'
            } to have at least one category checked`}</p>
            <List>
              {urlsWithoutOptions.map((urlData) => (
                <ListItem key={urlData.URL}>
                  <p>• {urlData.URL}</p>
                </ListItem>
              ))}
            </List>
            <p className={classes.text}>
              {`Please select at least one category ${
                urlsWithoutOptions.length > 1
                  ? 'for each URL to continue'
                  : 'to continue'
              }`}
            </p>
          </>
        }
        modalActionComponent={
          <>
            <PrimaryButton
              buttonText="OK"
              buttonClass="buttonPrimary"
              onClick={() => setNoOptionsCheckedModalOpen(false)}
            />
          </>
        }
        modalNoCloseOnClick
      />
      {/* {UPLOAD FILE SUCCESS} */}
      <PrimaryPopover
        alertOpen={uploadedFileSucces}
        alertText="Documents saved"
        alertPosition="right"
        alertWidth="220px"
        marginLeft="72px"
        onAlertClose={() => setUploadedFileSucces(false)}
      />
      {/* {UPLOAD FILE ERROR} */}
      <PrimaryPopover
        alertOpen={uploadedFileError}
        alertPosition="right"
        alertText="Documents not saved. Please try again"
        alertWidth="320px"
        marginLeft="72px"
        alertError
        onAlertClose={() => setUploadedFileError(false)}
      />
    </>
  )
}

export default AddMissingDocuments
