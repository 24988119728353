import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { PAGE_CON, REDUCER_TYPE } from '../../constant/constants'

const initialState = {
  portfolios: {
    isLoading: true,
    dataStatus: '',
    data: []
  },
  onDemandPortfolios: {
    isLoading: true,
    dataStatus: '',
    data: []
  },
  generateReport: {
    isLoading: false,
    dataStatus: '',
    data: []
  },
  reportingHistory: {
    isLoading: false,
    dataStatus: '',
    data: []
  },
  coverageDetails: {
    isLoading: false,
    dataStatus: '',
    data: []
  },
  assessmentLimit: {
    isLoading: false,
    dataStatus: '',
    data: {}
  }
}

export function reportingServiceInitialState() {
  let obj = {}
  obj.apiCallId = uuidv4()
  obj.cancelToken = axios.CancelToken.source()
  obj.portfolios = Object.assign({}, { ...initialState.portfolios, data: [] })
  obj.assessmentLimit = Object.assign(
    {},
    { ...initialState.assessmentLimit, data: {} }
  )
  obj.onDemandPortfolios = Object.assign(
    {},
    { ...initialState.onDemandPortfolios, data: [] }
  )
  obj.coverageDetails = Object.assign(
    {},
    { ...initialState.coverageDetails, data: [] }
  )
  obj.generateReport = Object.assign(
    {},
    { ...initialState.generateReport, data: [] }
  )
  obj.reportingHistory = Object.assign(
    {},
    { ...initialState.reportingHistory, data: [] }
  )
  return obj
}

const ReportingServiceReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.LOAD_DATA_START:
      currentState[action.sectionName].isLoading = true
      currentState[action.sectionName].dataStatus = PAGE_CON.LOADING
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_ERROR:
      if (action.apiCallId && action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.ERROR
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_COMPLETED:
      if (action.apiCallId && action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState[action.sectionName].data = action.data
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.SUCCESS
      return { ...currentState }
    case REDUCER_TYPE.RESET_REPORTING_SERVICE:
      currentState.cancelToken.cancel('cancelling operation')
      return reportingServiceInitialState()
    case REDUCER_TYPE.RESET_REPORTING_SERVICE_SECTION:
      currentState.cancelToken.cancel('cancelling operation')
      currentState.apiCallId = uuidv4()
      currentState.cancelToken = axios.CancelToken.source()
      currentState[action.sectionName] = { ...initialState[action.sectionName] }
      return { ...currentState }

    case REDUCER_TYPE.RESET_ODA_REPORTING_SERVICE: {
      const sections = action.sections.reduce(
        (acc, section) => ({
          ...acc,
          [section]: {
            ...currentState[section],
            isLoading: true,
            dataStatus: ''
          }
        }),
        {}
      )

      return { ...currentState, ...sections }
    }
    case REDUCER_TYPE.UPDATE_REPORTING_PORTFOLIOS:
      currentState.portfolios.data.forEach((portfolio) => {
        var foundPortfolio = action.portfolios.find(
          (x) => x.key === portfolio.portfolio_id
        )

        if (foundPortfolio) {
          portfolio[PAGE_CON.CASH_AND_LIQUIDITIES] =
            foundPortfolio[PAGE_CON.CASH_AND_LIQUIDITIES]
          portfolio[PAGE_CON.NON_SOVEREIGN_DERIVATIVES] =
            foundPortfolio[PAGE_CON.NON_SOVEREIGN_DERIVATIVES]
        }
      })
      return { ...currentState }
    case REDUCER_TYPE.RESET_GENERATE_REPORT:
      currentState.generateReport = Object.assign(
        {},
        { ...initialState.generateReport, data: [] }
      )
      return { ...currentState }
    default:
      return currentState
  }
}

export default ReportingServiceReducer
