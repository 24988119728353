import { makeStyles, withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const CustomInput = withStyles(() => ({
  root: {
    ...textTheme.typography.p3,
    height: '16px',
    color: themeColorPalette.palette.sys_trad.text.main,
    textAlign: 'left',
    lineHeight: 'normal',
    '& .MuiSelect-select:focus': {
      backgroundColor: (props) =>
        props.impactColor ? props.impactColor : 'white'
    },
    '& .MuiSelect-select.MuiSelect-select:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main
    },
    '& .MuiSelect-select.MuiSelect-select[aria-expanded=\'true\']': {
      textDecoration: 'none'
    },
    '& .MuiSelect-icon': {
      color: themeColorPalette.palette.sys_trad.text.main,
      fontSize: '16px'
    },
    '& .MuiSelect-iconOpen': {
      transform: 'none'
    }
  },
  input: {
    padding: '0px'
  }
}))(InputBase)

export const useStyles = makeStyles(() => ({
  dropdownMenuStyle: {
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      borderRadius: '2px'
    },
    '& .MuiListItem-root:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
      borderRadius: '2px'
    },
    '& .Mui-focusVisible': {
      backgroundColor: 'white'
    },
    '& .MuiListItem-gutters': {
      paddingLeft: '4px',
      paddingRight: '4px'
    },
    width: 'auto !important',
    padding: '4px !important'
  },
  dropdownStyle: {
    '& .MuiSelect-select:focus': {
      backgroundColor: (props) =>
        props.impactColor ? props.impactColor : 'white'
    },
    ...textTheme.typography.p1,
    width: (props) => (props.width ? props.width : 'inherit'),
    maxWidth: (props) => (props.maxWidth ? props.maxWidth : 'inherit'),
    color: (props) =>
      props.unSelectedColor
        ? props.unSelectedColor
        : themeColorPalette.palette.sys_trad.text.main,
    '& .MuiSelect-select.MuiSelect-select': {
      backgroundColor: (props) =>
        props.defaultData !== props.dropdownDefaultSelction &&
        themeColorPalette.palette.sys_trad.selected2.main,
      paddingRight: '24px',
      paddingLeft: '8px',
      paddingTop: '4px',
      paddingBottom: '4px',
      borderRadius: 2
    }
  },
  noBackGroundDropdownStyle: {
    '& .MuiSelect-select:focus': {
      backgroundColor: (props) =>
        props.impactColor ? props.impactColor : 'white'
    },
    ...textTheme.typography.p2,
    fontSize: '14px',
    width: (props) => (props.width ? props.width : 'inherit'),
    maxWidth: (props) => (props.maxWidth ? props.maxWidth : 'inherit'),
    color: (props) =>
      props.unSelectedColor
        ? props.unSelectedColor
        : themeColorPalette.palette.sys_trad.text.main,
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: '24px',
      paddingLeft: '4px',
      paddingTop: '4px',
      paddingBottom: '4px',
      borderRadius: 2
    },
    '& .Mui-disabled': {
      ...textTheme.typography.p3,
      fontSize: '14px',
      fontWeight: 325
    }
  },
  icon: {
    fill: (props) =>
      props.unSelectedColor
        ? props.unSelectedColor
        : themeColorPalette.palette.sys_trad.text.main,
    margin: 5
  },
  dropdownPaperStyle: {
    '& .MuiMenuItem-root': {
      ...textTheme.typography.p3,
      lineHeight: '16px',
      paddingTop: '8px',
      paddingBottom: '8px'
    },
    marginTop: '10px',
    boxShadow: '1px 3px 5px 1px rgba(0, 0, 0, 0.20)',
    maxHeight: (props) =>
      props.displayScrollAfter
        ? props.optionLength > props.displayScrollAfter
          ? `${props.displayScrollAfter * 30 + 12}px`
          : 'inherit'
        : '210px',
    minWidth: '130px',
    maxWidth: '300px',
    '& .MuiListItem-button': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'block'
    }
  }
}))
