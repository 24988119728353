import themeColorPalette from '../theme/theme'

export default function getColor(colorGroup, val) {
  let color = themeColorPalette.palette.sys_trad.bg.main
  const colorGroupName = colorGroup?.toUpperCase()
  const colorFieldText = val ? val.toUpperCase() : ''

  switch (colorGroupName) {
    case 'CATEGORY':
      switch (colorFieldText) {
        case 'ADVANCED':
          color = themeColorPalette.palette.adv_wk.advanced.main
          break
        case 'ROBUST':
          color = themeColorPalette.palette.adv_wk.robust.main
          break
        case 'LIMITED':
          color = themeColorPalette.palette.adv_wk.limited.main
          break
        case 'WEAK':
          color = themeColorPalette.palette.adv_wk.weak.main
          break
        case 'MODERATE':
          color = themeColorPalette.palette.carbfoot.moderate.main
          break
        case 'SIGNIFICANT':
          color = themeColorPalette.palette.carbfoot.significant.main
          break
        case 'HIGH':
          color = themeColorPalette.palette.carbfoot.high.main
          break
        case 'INTENSE':
          color = themeColorPalette.palette.carbfoot.intense.main
          break
        case 'GENRANGBONE':
          color = themeColorPalette.palette.genrang_b.one.main
          break
        case 'GENRANGBTHREE':
          color = themeColorPalette.palette.genrang_b.three.main
          break
        case 'GENRANGBTEN':
          color = themeColorPalette.palette.genrang_b.ten.main
          break
        case 'A2':
          color = themeColorPalette.palette.adv_wk.advanced.main
          break
        case 'N/A':
          color = themeColorPalette.palette.sys_trad.disabled.main
          break
        case 'NO INFO':
          color = themeColorPalette.palette.sys_trad.disabled.main
          break
        case 'NO INFORMATION OR NOT RELEVENT':
          color = themeColorPalette.palette.sys_trad.border.main
          break
        case 'GREY':
          color = themeColorPalette.palette.sys_trad.bg.main
          break
        case 'WHITE':
          color = themeColorPalette.palette.sys_trad.white.main
          break
        default:
      }
      break
    case 'BSA_CATEGORY':
      switch (colorFieldText) {
        case 'N/A':
          color = themeColorPalette.palette.sys_trad.disabled.main
          break
        case '0%':
        case 'NO INVOLVEMENT':
          color = themeColorPalette.palette.major_no.green_major.main
          break
        case '0-20%':
        case '0-10%':
        case '10-20%':
        case 'MINOR INVOLVEMENT':
          color = themeColorPalette.palette.major_no.green_signif.main
          break
        case '20-100%':
        case '20-33%':
        case '33-50%':
        case '50-100%':
        case '>=50%':
        case 'MAJOR INVOLVEMENT':
          color = themeColorPalette.palette.major_no.green_no.main
          break
        default:
      }
      break
    case 'CF_CATEGORY_TEXT': {
      switch (colorFieldText) {
        case 'MODERATE':
          color = themeColorPalette.palette.sys_trad.white.main
          break
        case 'SIGNIFICANT':
          color = themeColorPalette.palette.sys_trad.white.main
          break
        case 'HIGH':
          color = themeColorPalette.palette.sys_trad.white.main
          break
        case 'INTENSE':
          color = themeColorPalette.palette.sys_trad.white.main
          break
        case 'N/A':
          color = themeColorPalette.palette.sys_trad.text.main
          break
        case 'NO INFO':
          color = themeColorPalette.palette.sys_trad.text.main
          break
        default:
      }
      break
    }
    case 'TA_CATEGORY':
      switch (colorFieldText) {
        case 'WELL BELOW 2°C':
          color = themeColorPalette.palette.genrang_c.two.main
          break
        case 'BELOW 2°C':
          color = themeColorPalette.palette.genrang_c.three.main
          break
        case '2°C':
          color = themeColorPalette.palette.genrang_c.four.main
          break
        case 'ABOVE 2°C':
          color = themeColorPalette.palette.genrang_c.five.main
          break
        case 'NO INFO':
          color = themeColorPalette.palette.genrang_c.one.main
          break
        case 'N/A':
          color = themeColorPalette.palette.sys_trad.disabled.main
          break
        default:
      }
      break
    case 'ESG_CATEGORY':
      switch (colorFieldText) {
        case 'ROBUST':
          color = themeColorPalette.palette.genrang_c.two.main
          break
        case 'LIMITED':
          color = themeColorPalette.palette.genrang_c.three.main
          break
        case 'WEAK':
          color = themeColorPalette.palette.genrang_c.four.main
          break
        case 'ADVANCED':
          color = themeColorPalette.palette.genrang_c.one.main
          break
        case 'N/A':
          color = themeColorPalette.palette.sys_trad.disabled.main
          break
        default:
      }
      break
    case 'TA_CATEGORY_TEXT':
      switch (colorFieldText) {
        case 'WELL BELOW 2°C':
          color = themeColorPalette.palette.sys_trad.text.main
          break
        case 'BELOW 2°C':
          color = themeColorPalette.palette.white.main
          break
        case '2°C':
          color = themeColorPalette.palette.white.main
          break
        case 'ABOVE 2°C':
          color = themeColorPalette.palette.white.main
          break
        case 'NO INFO':
          color = themeColorPalette.palette.sys_trad.text.main
          break
        case 'N/A':
          color = themeColorPalette.palette.sys_trad.text.main
          break
        default:
      }
      break
    case 'GSA_CATEGORY':
      switch (colorFieldText) {
        case 'NONE':
          color = themeColorPalette.palette.major_no.green_no.main
          break
        case 'MINOR':
          color = themeColorPalette.palette.major_no.green_minor.main
          break
        case 'SIGNIFICANT':
          color = themeColorPalette.palette.major_no.green_signif.main
          break
        case 'MAJOR':
          color = themeColorPalette.palette.major_no.green_major.main
          break
        case 'N/A':
          color = themeColorPalette.palette.sys_trad.disabled.main
          break
        default:
      }
      break
    case 'TEXTCOLOR':
      color = themeColorPalette.palette.white.main
      break
    case 'TWOCOLUMNS':
      color = themeColorPalette.palette.sys_trad.bg.main
      break
    case 'SEPERATORCOLUMN':
      color = themeColorPalette.palette.sys_trad.white.main
      break
    case 'RISK':
      switch (colorFieldText) {
        case '0-19':
        case 'NO RISK':
          color = themeColorPalette.palette.noflag.norsk.main
          break
        case '20-39':
        case 'LOW RISK':
          color = themeColorPalette.palette.noflag.lowrsk.main
          break
        case '40-59':
        case 'MEDIUM RISK':
          color = themeColorPalette.palette.noflag.medrsk.main
          break
        case '60-79':
        case 'HIGH RISK':
          color = themeColorPalette.palette.noflag.hirsk.main
          break
        case '80-100':
        case 'RED FLAG RISK':
          color = themeColorPalette.palette.noflag.redflgrsk.main
          break
        default:
      }
      break
    case 'RANKING':
      switch (colorFieldText) {
        case 'MODERATE':
          color = themeColorPalette.palette.carbfoot.moderate.main
          break
        case 'SIGNIFICANT':
          color = themeColorPalette.palette.carbfoot.significant.main
          break
        case 'HIGH':
          color = themeColorPalette.palette.carbfoot.high.main
          break
        case 'INTENSE':
          color = themeColorPalette.palette.carbfoot.intense.main
          break
        case 'N/A':
          color = themeColorPalette.palette.sys_trad.disabled.main
          break
        case 'NO INFO':
          color = themeColorPalette.palette.sys_trad.disabled.main
          break
        default:
      }
      break
    case 'PROXY':
      switch (colorFieldText) {
        case 'PREDICTED SCORE':
          color = themeColorPalette.palette.proxy.proxy.main
          break
        default:
      }
      break
    case 'ESG_ASSESSMENT':
      switch (colorFieldText) {
        case 'A1-A3':
        case 'A':
          color = themeColorPalette.palette.genrang_b.two.main
          break
        case 'B1-B3':
        case 'B':
          color = themeColorPalette.palette.genrang_b.three.main
          break
        case 'C1-C3':
        case 'C':
          color = themeColorPalette.palette.genrang_b.eight.main
          break
        case 'E':
          color = themeColorPalette.palette.genrang_b.ten.main
          break
        case 'PREDICTED SCORE':
          color = themeColorPalette.palette.proxy.proxy.main
          break
        default:
      }
      break
    case 'PORT_ENTITY_TEXT':
      color = themeColorPalette.palette.sys_trad.text2.main
      break
    default:
  }
  return color
}

export const hexToRGB = (hex, opacity) => {
  let r
  let g
  let b
  if (hex) {
    r = parseInt(hex.slice(1, 3), 16)
    g = parseInt(hex.slice(3, 5), 16)
    b = parseInt(hex.slice(5, 7), 16)
  }

  if (opacity) {
    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  }
  return `rgb(${r}, ${g}, ${b})`
}
