import PropTypes from 'prop-types'
import React from 'react'
import * as S from './styles'

const Chiclet = (props) => {
  const classes = S.useStyles(props)
  const value = props.value
  const title = props.title

  return (
    <>
      {title && (
        <div>
          <span className={classes.title}>{title}</span>
        </div>
      )}
      <span className={classes[props.valueClassName]}>{value}</span>
    </>
  )
}

Chiclet.propTypes = {
  /** id */
  id: PropTypes.string,
  /** title to be displayed */
  title: PropTypes.string,
  /** value to be displayed */
  value: PropTypes.string,
  /** color to be displayed */
  valueColor: PropTypes.string,
  /** background color to be displayed */
  valueBgColor: PropTypes.string,
  /** class applied to value prop */
  valueClassName: PropTypes.string,
  /** class applied to chicklet prop */
  chickletClassName: PropTypes.string,
  /** top margin for value text */
  valueMarginTop: PropTypes.string,
  /** left padding for value text */
  valuePaddingLeft: PropTypes.string
}

Chiclet.defaultProps = {
  valueClassName: 'display',
  chickletClassName: 'chiclet'
}

export default Chiclet
