import { makeStyles } from '@material-ui/core'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

const leftColumnWidth = 50
const rightColumnWidth = 1
const defaultChartWidth = 825
export const useStyles = makeStyles(() => ({
  containerStyle: {
    width: (props) =>
      `${
        leftColumnWidth +
        (props.width ? props.width : defaultChartWidth) +
        rightColumnWidth
      }px`
  },
  tempTextStyle: {
    color: themeColorPalette.palette.sys_trad.text.main,
    ...textTheme.typography.h2,
    fontSize: '16px',
    lineHeight: '19.2px',
    paddingBottom: '12px'
  },
  projectionGraphStyle: {
    margin: '6px',
    padding: '12px',
    marginTop: '12px',
    position: 'relative',
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main
  },
  footNoteLabel: {
    marginTop: '13px',
    backgroundColor: themeColorPalette.palette.sys_trad.white.main,
    padding: '10px',
    width: '98%',
    ...textTheme.typography.p3
  }
}))