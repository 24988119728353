import { makeStyles, withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const CustomInput = withStyles(() => ({
  root: {
    ...textTheme.typography.p3,
    height: '16px',
    color: themeColorPalette.palette.sys_trad.text.main,
    textAlign: 'left',
    lineHeight: 'normal',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'white'
    },
    '& .MuiSelect-select.MuiSelect-select:hover': {
      textDecoration: 'none'
    },
    '& .MuiSelect-select.MuiSelect-select[aria-expanded=\'true\']': {
      textDecoration: 'none'
    },
    '& .MuiSelect-icon': {
      color: themeColorPalette.palette.sys_trad.text.main,
      fontSize: '16px'
    },
    '& .MuiSelect-iconOpen': {
      transform: 'none'
    }
  },
  input: {
    padding: '0px'
  }
}))(InputBase)

export const useStyles = makeStyles(() => ({
  dropdownMenuStyle: {
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      borderRadius: 1
    },
    '& .MuiListItem-root:hover': {
      backgroundColor: 'white'
    },
    '& .Mui-focusVisible': {
      backgroundColor: 'white'
    },
    '& .MuiListItem-gutters': {
      paddingLeft: '4px',
      paddingRight: '4px'
    },
    padding: '4px !important'
  },
  navDropdownMenuStyle: {
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      borderRadius: 1
    },
    '& .MuiListItem-root:hover': {
      backgroundColor: 'white'
    },
    '& .Mui-focusVisible': {
      backgroundColor: 'white'
    },
    '& .MuiListItem-gutters': {
      paddingLeft: '0px',
      paddingRight: '0px'
    },
    width: '190px',
    padding: '0 4px'
  },
  dropdownStyle: {
    width: (props) => (props.width ? props.width : 'inherit'),
    maxWidth: (props) => (props.maxWidth ? props.maxWidth : 'inherit'),
    ...textTheme.typography.p1,
    height: '16px',
    textAlign: 'left',
    lineHeight: 'normal',
    '& .MuiSelect-select.MuiSelect-select': {
      textDecoration: 'none',
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      paddingRight: '24px',
      paddingLeft: '8px',
      paddingTop: '4px',
      paddingBottom: '4px',
      borderRadius: 2
    },
    '& .MuiSelect-select.MuiSelect-select:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main
    }
  },
  icon: {
    fill: (props) =>
      props.unSelectedColor
        ? props.unSelectedColor
        : themeColorPalette.palette.sys_trad.text.main,
    margin: 5
  },
  dropdownPaperStyle: {
    '& .MuiMenuItem-root': {
      ...textTheme.typography.p3,
      paddingRight: '10px'
    },
    marginTop: '10px',
    boxShadow: '1px 3px 5px 1px rgba(0, 0, 0, 0.20)'
  },
  ripple: {
    opacity: 0
  }
}))
