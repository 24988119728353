import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundColor: themeColorPalette.palette.sys_trad.main.main,
    padding: '12px',
    minWidth: '400px'
  },
  scoreGrid: {
    paddingTop: '12px',
    maxWidth: '1280px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  scoreCardInfo: {
    paddingRight: '20px',
    paddingTop: '0px !important',
    paddingLeft: '0px !important',
    flexBasis: 'auto',
    minWidth: '370px'
  },
  esg_header: {
    ...textTheme.typography.h2,
    color: 'white',
    marginBottom: '7px'
  },
  sectorComparisonGrid: {
    maxWidth: '100%',
    flexBasis: 'auto',
    flexGrow: 1,
    margin: '5px 5px 5px 0',
    paddingTop: 0,
    paddingRight: 0,
    paddingLeft: 0,
    [theme.breakpoints.down(700)]: {
      paddingTop: 0,
      marginTop: 0,
      marginLeft: '5px'
    }
  },
  sectorComparisonChart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    color: 'white',
    ...textTheme.typography.h2,
    height: '100%'
  }
}))
