import { PAGE_CON } from './pageCon'

export const API_DEFAULT_PAYLOAD = {
  [PAGE_CON.FILTER_REGIONS]: PAGE_CON.ALL_FILTER,
  [PAGE_CON.FILTER_SECTORS]: PAGE_CON.ALL_FILTER,
  [PAGE_CON.FILTER_LAST60DAYS]: true,
  [PAGE_CON.FILTER_CATEGORY_1]: '',
  [PAGE_CON.FILTER_CATEGORY_2]: '',
  [PAGE_CON.FILTER_VIEWBY]: PAGE_CON.SECTOR,
  [PAGE_CON.FILTER_LIMIT]: PAGE_CON.COMPANIES_DEFAULT_LIMIT_FILTER
}
