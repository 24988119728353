import { makeStyles } from '@material-ui/core'
import theme from '../../theme/theme'

export const useStyles = makeStyles(() => ({
  text: {
    ...theme.typography.p1,
    marginBottom: 5
  },
  title: {
    margin: '15px 0'
  },
  listItem: {
    padding: '15px 20px',
    listStyle: 'inside'
  }
}))
