import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  subheader: {
    ...textTheme.typography.p3,
    color: themeColorPalette.palette.sys_trad.text2.main,
    whiteSpace: 'normal',
    paddingLeft: 4,
    float: 'left',
    width: '100%',
    height: 'auto',
    wordWrap: 'break-word',
    lineHeight: '16px'
  },
  subheaderMargin: {
    marginTop: 16
  },
  list: {
    width: 'auto',
    paddingLeft: 0,
    paddingRight: 0
  }
}))
