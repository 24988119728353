import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import getColor from 'utils/color'
import numberData from 'utils/numberFormat'
import PropTypes from 'prop-types'
import React from 'react'
import themeColorPalette from 'theme/theme'
import {
  defaultDataTextColor,
  defaultTextColor,
  displayPadding,
  useStyle
} from './styles'

function getDisplayText(content, data) {
  let displayText = ''
  if (content.labelType.toUpperCase() === 'TEXT')
    displayText = content.labelText
  else if (content.labelType.toUpperCase() === 'DATA') {
    if (data) {
      let numberType = content.NumberType ? true : false
      if (numberType)
        displayText = numberData(data[content.dataField], content.NumberType)
      else
        displayText = content.singleData
          ? data[content.dataField] && data[content.dataField].toUpperCase()
          : data[content.dataField]
    }
  }

  if (content.showDegree) displayText = displayText + '\xB0C'

  return displayText
}

function getDisplayData(content, data) {
  let displayText = ''
  let cardInfoData = []

  if (content.labelType.toUpperCase() === 'DATA') {
    if (data) {
      let numberType = content.NumberType ? true : false
      if (numberType) {
        displayText =
          data[content.dataField] === 'No Info'
            ? data[content.dataField]
            : numberData(data[content.dataField], content.NumberType)
        content.topLabel && cardInfoData.push(content.topLabel)
        cardInfoData.push(displayText)
        content.labelText && cardInfoData.push(content.labelText)
      } else {
        if (content.isDegreeString) {
          displayText = data[content.dataField] + '\xB0C'
          cardInfoData.push(displayText)
          content.labelText && cardInfoData.push(content.labelText)
        } else {
          displayText = data[content.dataField]
          content.topLabel && cardInfoData.push(content.topLabel)
          cardInfoData.push(displayText)
          content.labelText && cardInfoData.push(content.labelText)
        }
      }
      if (content.topLabel === 'emptyCell') {
        content.topLabel && cardInfoData.push(content.topLabel)
      }
    }
  }

  return cardInfoData
}

function getFontColor(content, data) {
  let color =
    content.labelType.toUpperCase() === 'TEXT'
      ? defaultTextColor
      : defaultDataTextColor
  if (content.color && content.color.colorGroup) {
    let val = ''
    if (
      content.color.colorDerivedByField &&
      content.color.colorDerivedByField !== ''
    ) {
      if (data) val = data[content.color.colorDerivedByField]
    }
    color = getColor(content.color.colorGroup, val)
  }

  return color
}

function getCardInfoBgColor(content, data) {
  let color = themeColorPalette.palette.white.main
  if (content.bgColor && content.bgColor.colorGroup) {
    let val = ''
    if (
      content.bgColor.colorDerivedByField &&
      content.bgColor.colorDerivedByField !== ''
    ) {
      if (data) val = data[content.bgColor.colorDerivedByField]
    }
    color = getColor(
      content.bgColor.colorGroup,
      val,
      content.bgColor.greenshare
    )
  }

  return color
}

/**
 * @visibleName Card Info Section
 */
const CardInfoSection = (props) => {
  const { id, contentInfo, data } = props
  const classes = useStyle(props)

  return (
    <Box id={id} key={id} className={classes.root}>
      {contentInfo &&
        contentInfo.length > 0 &&
        contentInfo.map((listContent, outerIndex) => {
          return (
            <Box
              className={clsx(
                classes.outerBox,
                props.styleFirstChild ? classes.outerBoxFirstChild : null
              )}
              key={`outerBox${outerIndex}`}
            >
              {listContent.map((content, innerIndex) => {
                let text =
                  props.tableData ||
                  props.scoreTable ||
                  props.scoreInfo ||
                  props.carbonBrownGreen
                    ? getDisplayData(content, data)
                    : getDisplayText(content, data)
                let textAlign = content.alignment ? content.alignment : 'left'
                let minWidth = content.minWidth ? content.minWidth : 'auto'
                let maxWidth = content.maxWidth ? content.maxWidth : 'auto'
                let minHeight = content.minHeight ? content.minHeight : 'auto'
                let textColor = getFontColor(content, data)
                let backgroundColor = getCardInfoBgColor(content, data)
                let borderRadius = content.borderRadius
                  ? content.borderRadius
                  : 0
                let padding = content.padding ? content.padding : displayPadding
                let currentPage = props.tableData
                  ? props.currentPageState.portfolioESGScore
                    ? props.currentPageState.portfolioESGScore
                    : props.currentPageState
                  : ''
                let isCFWeightedAvg =
                  props.isCarbonFootprint &&
                  text[1] &&
                  text[1].toLowerCase().includes('weighted avg.')

                return props.tableData ? (
                  text && text.length !== 0 ? (
                    <Box
                      key={`innerBox${outerIndex}${innerIndex}`}
                      minWidth={minWidth}
                      color={textColor}
                      minHeight={minHeight}
                      maxWidth={maxWidth}
                      bgcolor={backgroundColor}
                      borderRadius={borderRadius}
                    >
                      <div className={classes.dataStyle}>
                        {props.stateData
                          ? text[1] === 'Companies'
                            ? currentPage.data.coverage &&
                              currentPage.data.coverage.portfolio_coverage &&
                              currentPage.data.coverage.portfolio_coverage &&
                              currentPage.data.coverage.portfolio_coverage
                                .companies
                              ? text[0] +
                                '/' +
                                currentPage.data.coverage.portfolio_coverage.companies.split(
                                  '/'
                                )[1]
                              : text[0] + '/ -'
                            : text[0]
                          : text[1] === 'Companies'
                          ? currentPage.portfolioCoverage &&
                            currentPage.portfolioCoverage.data &&
                            currentPage.portfolioCoverage.data
                              .portfolio_coverage &&
                            currentPage.portfolioCoverage.data
                              .portfolio_coverage.companies
                            ? text[0] +
                              '/' +
                              currentPage.portfolioCoverage.data.portfolio_coverage.companies.split(
                                '/'
                              )[1]
                            : text[0] + '/ -'
                          : text[0]}
                      </div>
                      <span
                        className={clsx(
                          classes.labelStyle,
                          isCFWeightedAvg ? classes.weightedAvgStyle : null
                        )}
                      >
                        {text[1]}
                      </span>
                    </Box>
                  ) : null
                ) : props.scoreTable ? (
                  <Box
                    key={`innerBox${outerIndex}${innerIndex}`}
                    minWidth={minWidth}
                    color={textColor}
                    minHeight={minHeight}
                    maxWidth={maxWidth}
                    bgcolor={backgroundColor}
                    borderRadius={borderRadius}
                    padding={padding}
                  >
                    <div
                      className={
                        text[0] === '.'
                          ? classes.scoreEmptyRow
                          : classes.scoreFirstLineStyle
                      }
                    >
                      {text[0]}
                    </div>
                    {text[0] === 'Weighted Avg.' ? (
                      <div className={classes.scoreStyle}>
                        {text[1] && text[1]}
                      </div>
                    ) : text[0] === 'Coverage' ? (
                      <h6 className={classes.cardHeader}>
                        {text[1] && text[1].split('/')[0]}
                        <span className={classes.scoreSecondRowDenom}>
                          /{text[1] && text[1].split('/')[1]}
                        </span>{' '}
                      </h6>
                    ) : (
                      <h6 className={classes.cardHeaderSecondRow}>{text[1]}</h6>
                    )}
                    {text[2] && (
                      <div className={classes.scoreThirdRow}>{text[2]}</div>
                    )}
                  </Box>
                ) : props.scoreInfo ? (
                  <Box
                    key={`innerBox${outerIndex}${innerIndex}`}
                    minWidth={minWidth}
                    color={textColor}
                    minHeight={minHeight}
                    maxWidth={maxWidth}
                    bgcolor={backgroundColor}
                    borderRadius={borderRadius}
                    padding={padding}
                  >
                    <div
                      className={
                        text[0] === '.'
                          ? classes.scoreEmptyRow
                          : classes.scoreTitleStyle
                      }
                    >
                      {text[0]}
                    </div>
                    <div
                      className={
                        text[0] === 'Score'
                          ? classes.otherscoreSecondRow
                          : classes.scoreSecondRow
                      }
                    >
                      {text[1]}
                    </div>
                    {text[2] && (
                      <div
                        className={
                          text[0] === 'Score'
                            ? classes.scoreSecondRow
                            : classes.otherscoreSecondRow
                        }
                      >
                        {' '}
                        {text[2]}{' '}
                      </div>
                    )}
                  </Box>
                ) : props.carbonBrownGreen ? (
                  <Box
                    key={`innerBox${outerIndex}${innerIndex}`}
                    minWidth={minWidth}
                    color={textColor}
                    minHeight={minHeight}
                    maxWidth={maxWidth}
                    bgcolor={backgroundColor}
                    borderRadius={borderRadius}
                    padding={padding}
                  >
                    <div
                      className={
                        text[0] === '.'
                          ? classes.scoreEmptyRow
                          : classes.carbonBrownGreenFirstLine
                      }
                    >
                      {<h6 className={classes.cardHeader}>{text[0]} </h6>}
                    </div>
                    <div
                      className={
                        text[0] === 'Score'
                          ? classes.otherscoreSecondRow
                          : classes.scoreThirdRow
                      }
                    >
                      {text[1]}
                    </div>
                  </Box>
                ) : (
                  <Box
                    key={`innerBox${outerIndex}${innerIndex}`}
                    id={`innerBox${outerIndex}${innerIndex}`}
                    className={
                      content.className
                        ? classes[content.className]
                        : content.labelType.toUpperCase() === 'TEXT'
                        ? classes.textStyle
                        : classes.dataStyleRedesignTitle
                    }
                    textAlign={textAlign}
                    width={minWidth}
                    minHeight={minHeight}
                    color={textColor}
                    bgcolor={backgroundColor}
                    borderRadius={borderRadius}
                    padding={padding}
                  >
                    {text}
                  </Box>
                )
              })}
            </Box>
          )
        })}
    </Box>
  )
}

CardInfoSection.propTypes = {
  /** id for Box component */
  id: PropTypes.string,
  /** contentInfo will have text and data details */
  contentInfo: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        labelType: PropTypes.oneOf(['Text', 'Data']),
        labelText: PropTypes.string,
        dataField: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        alignment: PropTypes.string,
        color: PropTypes.shape({
          colorGroup: PropTypes.string,
          colorDerivedByField: PropTypes.string
        }),
        bgColor: PropTypes.shape({
          colorGroup: PropTypes.string,
          colorDerivedByField: PropTypes.string
        }),
        borderRadius: PropTypes.any,
        padding: PropTypes.string,
        className: PropTypes.string,
        showDegree: PropTypes.bool
      })
    )
  ),
  /** Data to be consumed by this component  */
  data: PropTypes.any,
  /** Max width for cardInfoSection */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default CardInfoSection
