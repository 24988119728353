import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import getColor from 'utils/color'
import HC_more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import React from 'react'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

HC_more(Highcharts)

//function for smoothing outlines of graph
;(function (H) {
  H.Series.prototype.getConnectors = function (
    segment,
    index,
    calculateNeighbours,
    connectEnds
  ) {
    var i,
      prevPointInd,
      nextPointInd,
      previousPoint,
      nextPoint,
      previousX,
      previousY,
      nextX,
      nextY,
      plotX,
      plotY,
      ret,
      // 1 means control points midway between points, 2 means 1/3 from
      // the point, 3 is 1/4 etc;
      smoothing = 10,
      denom = smoothing + 1,
      leftContX,
      leftContY,
      rightContX,
      rightContY,
      dLControlPoint, // distance left control point
      dRControlPoint,
      leftContAngle,
      rightContAngle,
      jointAngle,
      addedNumber = connectEnds ? 1 : 0
    // Calculate final index of points depending on the initial index value.
    // Because of calculating neighbours, index may be outside segment
    // array.
    if (index >= 0 && index <= segment.length - 1) {
      i = index
    } else if (index < 0) {
      i = segment.length - 1 + index
    } else {
      i = 0
    }
    prevPointInd = i - 1 < 0 ? segment.length - (1 + addedNumber) : i - 1
    nextPointInd = i + 1 > segment.length - 1 ? addedNumber : i + 1
    previousPoint = segment[prevPointInd]
    nextPoint = segment[nextPointInd]
    previousX = previousPoint.plotX
    previousY = previousPoint.plotY
    nextX = nextPoint.plotX
    nextY = nextPoint.plotY
    plotX = segment[i].plotX // actual point
    plotY = segment[i].plotY
    leftContX = (smoothing * plotX + previousX) / denom
    leftContY = (smoothing * plotY + previousY) / denom
    rightContX = (smoothing * plotX + nextX) / denom
    rightContY = (smoothing * plotY + nextY) / denom
    dLControlPoint = Math.sqrt(
      Math.pow(leftContX - plotX, 2) + Math.pow(leftContY - plotY, 2)
    )
    dRControlPoint = Math.sqrt(
      Math.pow(rightContX - plotX, 2) + Math.pow(rightContY - plotY, 2)
    )
    leftContAngle = Math.atan2(leftContY - plotY, leftContX - plotX)
    rightContAngle = Math.atan2(rightContY - plotY, rightContX - plotX)
    jointAngle = Math.PI / 2 + (leftContAngle + rightContAngle) / 2
    // Ensure the right direction, jointAngle should be in the same quadrant
    // as leftContAngle
    if (Math.abs(leftContAngle - jointAngle) > Math.PI / 2) {
      jointAngle -= Math.PI
    }
    // Find the corrected control points for a spline straight through the
    // point
    leftContX = plotX + Math.cos(jointAngle) * dLControlPoint
    leftContY = plotY + Math.sin(jointAngle) * dLControlPoint
    rightContX = plotX + Math.cos(Math.PI + jointAngle) * dRControlPoint
    rightContY = plotY + Math.sin(Math.PI + jointAngle) * dRControlPoint
    // push current point's connectors into returned object
    ret = {
      rightContX: rightContX,
      rightContY: rightContY,
      leftContX: leftContX,
      leftContY: leftContY,
      plotX: plotX,
      plotY: plotY
    }
    // calculate connectors for previous and next point and push them inside
    // returned object
    if (calculateNeighbours) {
      ret.prevPointCont = this.getConnectors(
        segment,
        prevPointInd,
        false,
        connectEnds
      )
    }
    return ret
  }
})(Highcharts)

const Radar = ({
  id,
  chartPosition,
  colorCategory,
  data,
  radarColors,
  width
}) => {
  const useStyles = makeStyles(() => ({
    radarContainer: {
      borderRadius: 2,
      justifyContent: chartPosition ? chartPosition : undefined,
      backgroundColor: themeColorPalette.palette.sys_trad.bg.main
    }
  }))

  const classes = useStyles()
  const colorGroupName = colorCategory ? colorCategory : 'category'
  const desiredOrder = ['Leadership', 'Results', 'Implementation'] //can be exposed as prop if desired order changes per research line

  const radialGradientProps =
    data.length > 3
      ? { cx: 0.5, cy: 0.6, r: 0.7 }
      : { cx: 0.5, cy: 0.7, r: 0.5 } //identifies center of color gradient

  const radialGradientStops =
    data.length > 3
      ? [
          [0, radarColors[0]],
          [0.25, radarColors[1]],
          [0.5, radarColors[2]],
          [1, radarColors[3]]
        ]
      : [
          [0, radarColors[0]],
          [0.25, radarColors[1]],
          [0.7, radarColors[2]],
          [1, radarColors[3]]
        ]

  const sortedData =
    data &&
    data.sort((a, b) => {
      return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name)
    })

  let plotSeriesCategories = []
  let markerProps = []
  let rangeData = []

  sortedData.forEach((category) => {
    plotSeriesCategories.push(category.name)
    let marker = {}
    category.score = category.score ? category.score : 0
    rangeData.push([parseInt(category.score), 100])
    marker['y'] = parseInt(category.score)
    let markerColor = getColor(colorGroupName, category.ranking)
    marker['color'] =
      markerColor === themeColorPalette.palette.sys_trad.bg.main
        ? themeColorPalette.palette.sys_trad.border.main
        : markerColor
    markerProps.push(marker)
  })

  const options = {
    credits: {
      enabled: false
    },
    chart: {
      backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
      polar: true,
      height: 190,
      width: width ? width : 290,
      minWidth: 250,
      marginBottom: data.length > 3 ? -5 : -25,
      style: {
        ...textTheme.typography.p4,
        lineHeight: '16px'
      },
      events: {
        load() {
          this.seriesGroup.element.insertBefore(
            this.yAxis[0].gridGroup.element,
            this.series[1].markerGroup.element
          )
          this.seriesGroup.element.insertBefore(
            this.xAxis[0].gridGroup.element,
            this.series[1].markerGroup.element
          )
        }
      }
    },
    tooltip: {
      enabled: false
    },
    title: {
      text: ''
    },
    xAxis: {
      min: 0,
      gridLineColor: themeColorPalette.palette.sys_trad.border.main,
      categories: plotSeriesCategories,
      tickmarkPlacement: 'on',
      lineWidth: 0,
      labels: {
        useHTML: true,
        align: data.length > 3 ? null : 'center',
        formatter() {
          let margin = 0
          let height = '30px'
          if (data.length > 3) {
            switch (this.pos) {
              case 1:
                margin = '25px 0 0 -8px'
                break
              case 2:
                margin = '15px 0 0 -10px'
                break
              case 3:
                margin = '15px -10px 0 0'
                break
              case 4:
                margin = '25px 0 0 -5px'
                break
              default:
                margin = '-5px 0 0 0'
                height = '10px'
                break
            }
          } else if (data.length === 3 && !this.isFirst) {
            margin = '25px 0 0 0'
          } else {
            margin = '-5px 0 0 0'
            height = '10px'
          }
          return `
                    <div style="margin: ${margin}; height: ${height}">
                        <span>${this.value} </span>
                    </div>
                    `
        },
        style: {
          whiteSpace: 'nowrap',
          color: themeColorPalette.palette.sys_trad.main.main,
          fontSize: '12px'
        }
      }
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      gridLineColor: themeColorPalette.palette.sys_trad.border.main,
      min: 0,
      max: 100,
      showFirstLabel: false,
      showLastLabel: true,
      tickInterval: 25,
      labels: {
        align: 'center',
        y: 5,
        x: 0,
        style: {
          color: themeColorPalette.palette.sys_trad.text.main,
          fontSize: '10px'
        }
      }
    },
    plotOptions: {
      series: {
        marker: {
          radius: 2.5
        },
        states: {
          hover: {
            enabled: false
          },
          inactive: {
            enabled: false
          }
        }
      }
    },
    series: [
      {
        showInLegend: false,
        data: Array(data.length).fill(100),
        pointPlacement: 'on',
        marker: {
          symbol: 'circle'
        },
        color: {
          radialGradient: radialGradientProps,
          stops: radialGradientStops
        },
        opacity: 0.4,
        type: 'area',
        lineColor: 'transparent'
      },
      {
        showInLegend: false,
        zIndex: 5,
        data: markerProps,
        pointPlacement: 'on',
        type: 'area',
        color: 'transparent',
        marker: {
          symbol: 'circle'
        }
      },
      {
        showInLegend: false,
        data: rangeData,
        pointPlacement: 'on',
        type: 'areasplinerange',
        fillColor: themeColorPalette.palette.sys_trad.bg.main,
        opacity: 1,
        lineColor: 'transparent',
        marker: {
          fillColor: themeColorPalette.palette.sys_trad.border.main,
          lineColor: themeColorPalette.palette.sys_trad.border.main
        }
      }
    ]
  }

  return (
    <Grid container className={classes.radarContainer}>
      <HighchartsReact
        id={id}
        highcharts={Highcharts}
        options={options}
        oneToOne={true}
      />
    </Grid>
  )
}

Radar.propTypes = {
  /** radarChart id */
  id: PropTypes.string,
  /** radarChart Position */
  chartPosition: PropTypes.string,
  /** radarChart Color Category */
  colorCategory: PropTypes.string,
  /** radarChart Data */
  data: PropTypes.array,
  /** radarChart Color Array */
  radarColors: PropTypes.array,
  /** radarChart Width */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default React.memo(Radar)
