import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'

export const useStyles = makeStyles((theme) => ({
  mapTooltipContaiener: {
    padding: '0px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.14)',
    borderRadius: '2px',
    '& b': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      wordBreak: 'break-word',
      whiteSpace: 'nowrap',
      display: 'inline-block'
    },
    '& path': {
      fill: 'none !important',
      stroke: 'none !important'
    },
    '& >span': {
      top: '0px !important',
      left: '0px !important',
      minWidth: '222px',
      width: 'fit-content',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.14)',
      padding: '16px',
      margin: '0px',
      '& ul': {
        padding: 0,
        width: 'fit-content',
        listStyle: 'none',
        '& li': {
          marginTop: '2px',
          display: 'flex',
          justifyContent: 'space-between',
          color: theme?.palette?.sys_trad?.text2.main,
          '& span': {
            minWidth: '90px',
            ...theme?.typography?.p1,
            color: theme?.palette?.sys_trad?.text2.main
          },
          '& strong': {
            ...theme?.typography?.p3,
            ...theme?.typography?.bold,
            flex: 1,
            color: theme?.palette?.sys_trad?.text.main,
            marginLeft: 3
          }
        }
      },
      '& b': {
        marginBottom: '6px',
        display: 'block'
      },
      '& b, & ul': {
        color: theme?.palette?.sys_trad?.text.main,
        ...theme?.typography?.p2
      }
    }
  },
  title: {
    color: theme?.palette?.sys_trad?.main.main,
    fontSize: 16
  },
  categories: {
    display: 'flex'
  },
  coverage: {
    ...theme?.typography?.p1,
    color: theme?.palette?.sys_trad?.text.main,
    fontSize: 10,
    marginBottom: 16
  },
  bold: {
    ...theme?.typography?.bold
  },
  mapCotnainer: {
    width: '100% !important',
    '&>svg': {
      width: '100%'
    },
    '& .highcharts-legend': {
      ...theme?.typography?.p5,
      position: 'relative',
      overflow: 'visible',
      '& span': {
        ...theme?.typography?.p1,
        overflow: 'visible !important'
      },
      '& .container': {
        position: 'relative',
        overflow: 'visible'
      },
      '& .last': {
        marginLeft: '10px'
      },
      '& .first': {
        position: 'absolute',
        left: '-50px',
        top: 0,
        lineHeight: '16px'
      }
    }
  }
}))

export const Title = ({ children }) => {
  const classes = useStyles(theme)
  return <div className={classes.title}>{children}</div>
}

export const Coverage = ({ children }) => {
  const classes = useStyles(theme)
  return <div className={classes.coverage}>{children}</div>
}

export const mapMessageStyle = {
  textAlign: 'center',
  ...theme?.typography?.p3,
  color: theme?.palette?.sys_trad?.text2.main
}
