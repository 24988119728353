import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { REDUCER_TYPE } from 'constant/constants'
import PhysicalRisk from './PhysicalRisk'
import TransitionRisk from './TransitionRisk'
import Esg from './Esg'
import LoadingMask from 'utils/loadingMask'
import { GlobalContext } from 'context/GlobalStateProvider'
import * as S from './summaryHeaderComponents/index.jsx'
import { getMonthFromFilter, getYearFromFilter } from 'utils/Date'
import { numberOfCompanies } from 'utils/numberFormat'
import { PrimaryButton } from 'components'
import { formattingRules } from 'views/ondemandassessment/CriteriaFilterData'

const SummaryHeaders = ({
  isLoading,
  data,
  handleViewCompaniesClick,
  handleAboutClimateClick,
  coverage,
  entitlements,
  minify,
  portfolioName,
  reloadComponent
}) => {
  const context = useContext(GlobalContext)
  const [filterState] = context.globalFilter
  const [, setOnDemandAsmntDispatch] = context.onDemandAsmntService
  const percAvailForAssessment = formattingRules(
    100 * coverage?.perc_avail_for_assessment || 0
  )
  const companies = coverage?.companies || 0
  const holdings = coverage?.perc_investment || 0
  const assessmentRequested = !!coverage?.assessment_requested

  const aboutClimateClick = () => {
    handleAboutClimateClick && handleAboutClimateClick()
  }

  const getFiltersText = () => {
    if (filterState?.globalPortfolioFilter) {
      const region =
        filterState.globalPortfolioFilter.regions === ''
          ? 'All Regions'
          : filterState.globalPortfolioFilter.regions
      const sector =
        filterState.globalPortfolioFilter.sectors === ''
          ? 'All Sectors'
          : filterState.globalPortfolioFilter.sectors
      const month = getMonthFromFilter(
        filterState.globalPortfolioFilter.asOfDate
      )
      const year = getYearFromFilter(filterState.globalPortfolioFilter.asOfDate)
      const period = `${year}-${month}`
      return `${region}, ${sector}, ${period}`
    } else {
      return ''
    }
  }
  const handleOndemandAssessment = () => {
    setOnDemandAsmntDispatch({
      type: REDUCER_TYPE.ON_DEMAND_ASMNT_MODAL,
      modalOpen: true,
      assessmentRequested,
      portfolio: filterState?.globalPortfolioFilter.portfolio
    })
  }

  return (
    <S.Container minify={minify}>
      {isLoading ? (
        <LoadingMask />
      ) : (
        <S.Content minify={minify}>
          <div>
            <S.TitleContainer minify={minify}>
              {!minify ? (
                <>
                  <S.Title>{portfolioName}</S.Title>
                  <S.CoverageContainer>
                    <S.AboutButton
                      heap_id="view-panel"
                      onClick={handleViewCompaniesClick}
                    >{`Climate Coverage: Across ${numberOfCompanies(
                      companies
                    )}, representing ${holdings}% of investments`}</S.AboutButton>
                    <PrimaryButton
                      id="button-button-test-id-1"
                      onClick={aboutClimateClick}
                      buttonPadding={'4px'}
                      buttonMarginTop={'4px'}
                      buttonWidth={'fit-content'}
                      buttonText="View Methodologies"
                      buttonClass="buttonLink"
                    />
                  </S.CoverageContainer>
                </>
              ) : (
                `Viewing ${portfolioName}: ${getFiltersText()}`
              )}
            </S.TitleContainer>
            {entitlements.ondemandassessment && !minify ? (
              <PrimaryButton
                id="score-qualty-btn"
                buttonText={`${percAvailForAssessment} On-Demand Assessment Eligible`}
                buttonClass="buttonAltPrimary"
                onClick={handleOndemandAssessment}
                buttonHeight="22px"
                buttonMarginTop="8px"
                buttonMarginBottom="-3px"
                buttonPadding="5px"
                buttonDisabled={percAvailForAssessment === '0%'}
              />
            ) : null}
          </div>

          <S.CategoriesContainer minify={minify}>
            {entitlements.PortfolioAnalysis.esg && (
              <Esg
                data={data}
                minify={minify}
                reloadComponent={reloadComponent}
              />
            )}
            {entitlements.PortfolioAnalysis.physicalrisk && (
              <PhysicalRisk
                data={data}
                minify={minify}
                reloadComponent={reloadComponent}
              />
            )}
            {entitlements.PortfolioAnalysis.transitionrisk && (
              <TransitionRisk
                data={data}
                minify={minify}
                reloadComponent={reloadComponent}
              />
            )}
          </S.CategoriesContainer>
        </S.Content>
      )}
    </S.Container>
  )
}

SummaryHeaders.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isLoading: PropTypes.bool,
  handleViewCompaniesClick: PropTypes.func,
  handleAboutClimateClick: PropTypes.func,
  coverage: PropTypes.object,
  minify: PropTypes.bool,
  portfolioName: PropTypes.string
}

export default SummaryHeaders
