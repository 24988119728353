import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import Divider from '@material-ui/core/Divider'
import { ErrorComponent, Stacked } from 'components'

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: themeColorPalette.palette.sys_trad.border.main
  },
  sectionTitle: {
    ...textTheme.typography.p2,
    textDecoration: 'underline'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  grid: {
    ...textTheme.typography.p3
  }
}))

const barColors = [
  themeColorPalette.palette.adv_wk.weak.main,
  themeColorPalette.palette.adv_wk.limited.main,
  themeColorPalette.palette.adv_wk.robust.main,
  themeColorPalette.palette.adv_wk.advanced.main
]

const EnergyTransitionManagementUnderlyingDataRiskMetrics = (props) => {
  const classes = useStyles(props)
  const UnderlyingData = props.underlyingDataMetrics
  const dataStatus = props.dataStatus
  const underlyingDataMetrics = React.useMemo(() => {
    return {
      data: UnderlyingData
    }
  }, [dataStatus])

  return (
    <Grid className={classes.grid} item xs={12} sm={12}>
      <Grid className={classes.grid} item xs={12}>
        {underlyingDataMetrics.data && underlyingDataMetrics.data.length > 0 ? (
          underlyingDataMetrics.data.map((item, index) => {
            return (
              <span key={`${item + index}`}>
                <Grid
                  container
                  className={classes.grid}
                  direction={'row'}
                  spacing={2}
                >
                  <Grid item className={classes.grid} xs={4}>
                    <br />
                    <br />
                    {item.title}
                  </Grid>
                  <Grid className={classes.grid} item xs={8}>
                    <Grid
                      container
                      direction={'row'}
                      spacing={2}
                      className={classes.grid}
                    >
                      <Stacked
                        data={item.data !== undefined ? item.data[1] : []}
                        title={index === 0 ? 'Investment in Category' : '.'}
                        barColors={barColors}
                      />
                      <Stacked
                        data={item.data !== undefined ? item.data[0] : []}
                        title={index === 0 ? 'Companies in Category' : '.'}
                        barColors={barColors}
                        notPercent={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>{' '}
                <br></br>
                {index + 1 !== props.underlyingDataMetrics.length && (
                  <Divider className={classes.divider} />
                )}
                <br></br>
              </span>
            )
          })
        ) : (
          <ErrorComponent
            id={'underlyingDataMetricsError'}
            hideErrorActionButton={true}
            errorText="There is no data on which to base our calculations"
          />
        )}
      </Grid>
    </Grid>
  )
}

export default EnergyTransitionManagementUnderlyingDataRiskMetrics
