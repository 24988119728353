import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  container: {
    '& > .MuiFormControlLabel-root': {
      marginRight: '0px'
    }
  },
  backgroundColorOnInnerBoxOfCustomIcon: {
    width: '0px',
    marginLeft: '10px',
    height: '0px',
    backgroundColor: 'transparent !important',
    '&.MuiCheckbox-colorSecondary': {
      color: themeColorPalette.palette.sys_trad.selected.main,
      '&:hover': {
        color: themeColorPalette.palette.sys_trad.hover.main,
        '& .MuiSvgIcon-root .innerBox': {
          fill: themeColorPalette.palette.sys_trad.hover2.main
        }
      },
      '&.Mui-disabled': {
        pointerEvents: 'auto',
        '&:hover': {
          backgroundColor: 'transparent',
          color: themeColorPalette.palette.sys_trad.disabled.main,
          '& .MuiSvgIcon-root .innerBox': {
            fill: themeColorPalette.palette.sys_trad.disabled.main
          }
        },
        '& .MuiSvgIcon-root .innerBox': {
          fill: themeColorPalette.palette.sys_trad.disabled.main
        },
        '&.MuiCheckbox-root': {
          color: themeColorPalette.palette.sys_trad.disabled.main
        }
      },
      '&.Mui-checked': {
        color: themeColorPalette.palette.sys_trad.selected.main,
        '&:hover': {
          color: themeColorPalette.palette.sys_trad.hover.main
        }
      }
    }
  },
  labelClass: {
    ...textTheme.typography.p3,
    lineHeight: '16px',
    fontWeight: 600,
    color: textTheme.palette.sys_trad.text.main,
    fontSize: '13px',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: (props) => (props.labelWidth ? props.labelWidth : 'inherit'),
    paddingLeft: '8px'
  },
  selectedLabelStyle: {
    ...textTheme.typography.p2,
    lineHeight: '16px',
    fontWeight: 375,
    color: textTheme.palette.sys_trad.text.main,
    fontSize: '14px',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: (props) => (props.labelWidth ? props.labelWidth : 'inherit'),
    paddingLeft: '8px'
  },
  portfolioLabel: {
    ...textTheme.typography.p3,
    lineHeight: '16px',
    fontWeight: 325,
    color: textTheme.palette.sys_trad.text.main,
    fontSize: '14px',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: (props) => (props.labelWidth ? props.labelWidth : 'inherit'),
    paddingLeft: '8px'
  },
  criteriaFilterLabel: {
    ...textTheme.typography.p3,
    paddingLeft: '8px',
    color: textTheme.palette.sys_trad.text.main,
    width: '100%'
  },
  criteriaFilterLabelBold: {
    ...textTheme.typography.p2,
    paddingLeft: '8px',
    color: textTheme.palette.sys_trad.text.main,
    width: '100%',
    display: 'inline-block'
  }
}))
