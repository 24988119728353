export const validateDomain = (email) => {
  const domainList = ['@moodys.com']
  const emailList = domainList
    .map((domain) => email.endsWith(domain))
    .filter((email) => email)
  return !emailList.length
}

export const validateEmail = ({ email }) => {
  if (!validateDomain(email)) return false
  const pattern = /^[A-Za-z0-9._%+-]+@([A-Za-z0-9-]+\.)+([A-Za-z0-9]{2,4})$/
  return pattern.test(String(email).toLowerCase())
}
