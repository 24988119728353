import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  esgScore: {
    padding: '0px',
    textAlign: 'center',
    width: (props) => (props.width ? props.width : '355px'),
    backgroundColor: themeColorPalette.palette.sys_trad.main.main,
    borderRadius: '0.2em'
  },
  cardScore: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'white',
    padding: (props) => (props.isIssuer ? 0 : '10px 0 10px')
  },
  cardEsgScore: {
    display: 'flex',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    position: 'relative',
    width: '100%',
    height: '4em',
    margin: (props) => (props.isIssuer ? '1em' : 0),
    borderRadius: '0.1em',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px !important',
    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'inline-block',
      width: '30%',
      height: '1px',
      backgroundColor: themeColorPalette.palette.sys_trad.white.main,
      left: '50%',
      bottom: '-10%',
      transform: 'translateX(-50%)'
    }
  },
  cardEsgScoreTop: {
    width: '8em',
    marginTop: '4px',
    fontSize: '12px',
    textAlign: 'left',
    marginLeft: '0.5em',
    ...textTheme.typography.p3
  },
  cardEsgScoreBottom: {
    textAlign: 'center',
    display: 'inline-block',
    fontSize: '1.8em',
    color: themeColorPalette.palette.sys_trad.white.main,
    ...textTheme.typography.h1,
    '&> div': {
      fontSize: '24px'
    }
  },
  cardScoreValues: {
    textAlign: 'left',
    padding: (props) => (props.isIssuer ? '20px 10px 20px 0px' : '5px 0 0'),
    flexGrow: 1,
    '&:not(:first-child)': {
      borderLeft: '1px solid #455d75',
      paddingLeft: '20px'
    }
  },
  cardScoreValuesTitle: {
    fontSize: '12px',
    ...textTheme.typography.p3
  },
  cardScoreNumber: {
    ...textTheme.typography.p3,
    fontWeight: 'bold',
    fontSize: '14px'
  },
  spreaderBar: {
    paddingLeft: '10px',
    borderLeft: '2px solid #455d75',
    height: '35px'
  },
  updated: {
    display: 'flex',
    ...textTheme.typography.p3,
    marginLeft: (props) => (props.isIssuer ? '16px' : '0px'),
    color: themeColorPalette.palette.sys_trad.white.main,
    padding: (props) => (props.isIssuer ? '0 0 8px' : '1px 0 0')
  }
}))
