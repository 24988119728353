export const FILES = {
  PCAF_GLOBAL_GHG_STANDARD:
    'https://carbonaccountingfinancials.com/files/downloads/PCAF-Global-GHG-Standard.pdf'
}

export const ROUTE_PATHS = {
  PORTFOLIO_ANALYSIS_STATUS: '/portfolioanalysis/status/:id',
  REGULATORY_REPORTING_STATUS: '/reportingservice/status/:id',
  REDIRECT_404: '/404'
}
