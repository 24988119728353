export const sortPortfolioHistoryChart = (categorydata) => {
  return (
    categorydata?.length > 0 &&
    categorydata.map(({ name, data, ...rest }) => ({
      name,
      data: data.map(({ inv_pct, num_companies }, index) => ({
        x: index,
        y: inv_pct,
        companies: num_companies
      })),
      ...rest
    }))
  )
}
