import { GBL_FLT, GET_ENTITLEMENTS, PARAMS } from 'constant/APIconstants'
import axios from 'axios'
import { UserUtils } from 'utils'

export const getEntitlements = async () => {
  const { data } = await await axios.get(GET_ENTITLEMENTS)
  return data
}

export const getUserProfile = async ({ cancelToken }) => {
  const { data } = await axios.get(GBL_FLT.USER_PROFILE, { cancelToken })
  return data
}

export const updateUserProfile = async ({
  email,
  fName,
  lName,
  portfolio_id,
  cancelToken,
  display_cf_predicted_score
}) => {
  return await axios.put(
    GBL_FLT.USER_PROFILE,
    {
      [PARAMS.EMAIL]: email,
      [PARAMS.FNAME]: fName,
      [PARAMS.LNAME]: lName,
      [PARAMS.PORTFOLIO_ID]: portfolio_id,
      [PARAMS.DISPLAY_CF_PREDICTED_SCORE]: display_cf_predicted_score
    },
    cancelToken
  )
}

export const getUserSettings = async () => {
  const { data } = await axios.get(GBL_FLT.USER_SETTINGS)
  return data.settings
}

export const updateUserSettings = async ({
  carbon_footprint_weighted_avg,
  cancelToken
}) => {
  const newSettings = UserUtils.getDefaultUserSettings({
    weighted_average_calculations: {
      [PARAMS.CARBONFOOTPRINT]: carbon_footprint_weighted_avg
    }
  })

  const { data } = await axios.put(
    GBL_FLT.USER_SETTINGS,
    newSettings,
    cancelToken
  )

  return data
}
