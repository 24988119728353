import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconChevronLeft = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8717 12.8345C10.6869 13.0397 10.3708 13.0564 10.1655 12.8716L5.16552 8.37164C5.06016 8.27682 5 8.14174 5 8C5 7.85825 5.06016 7.72317 5.16552 7.62835L10.1655 3.12835C10.3708 2.94362 10.6869 2.96026 10.8716 3.16551C11.0564 3.37077 11.0397 3.68691 10.8345 3.87164L6.24743 8L10.8345 12.1283C11.0397 12.3131 11.0564 12.6292 10.8717 12.8345Z"
      fill="#26415E"
    />
  </SvgIcon>
)
IconChevronLeft.displayName = 'IconChevronLeft'
IconChevronLeft.muiName = 'SvgIcon'

export default IconChevronLeft
