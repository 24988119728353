import React from 'react'
import { PAGE_CON } from 'constant/constants'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core'
import { ErrorComponent } from 'components'
import getColor from 'utils/color'
import PropTypes from 'prop-types'
import textTheme from 'theme/theme'
import { useStyles } from './styles'

function getCellTextColor(field, dataRow) {
  let color = textTheme.palette.sys_trad.text.main
  if (field.cellTextColor && field.cellTextColor.colorGroup) {
    let val = ''
    if (
      field.cellTextColor.colorDerivedByField &&
      field.cellTextColor.colorDerivedByField !== ''
    ) {
      val = dataRow[field.cellTextColor.colorDerivedByField]
    }
    color = getColor(field.cellTextColor.colorGroup, val)
  }

  return color
}

function getCellBgColor(cellBgColor, row) {
  return (
    cellBgColor &&
    getColor(cellBgColor.colorGroup, row[cellBgColor.colorDerivedByField])
  )
}

/**
 * @visibleName Score Table
 */

const ScoreTable = (props) => {
  const classes = useStyles(props)
  const { id, columns, data } = props
  return (
    <div>
      {data && data.length > 0 ? (
        <TableContainer className={classes.tableContainer}>
          <Table id={id} className={classes.table}>
            <TableBody>
              {data.map((row, rowIndex) => {
                let bgColor
                let textColor
                return (
                  <TableRow key={`bodyrow${rowIndex}`}>
                    {columns.map((column, colIndex) =>
                      column.DataField.map((field, findex) => {
                        let value = row[field.Name] ? row[field.Name] : '-'
                        if (field.isWholeNumber && !isNaN(value))
                          value = parseInt(Math.round(value))

                        let isLastColumn = colIndex === columns.length - 1
                        let total =
                          isLastColumn && value === '-'
                            ? ''
                            : PAGE_CON.OUT_OF_100
                        let colAlign = column.Alignment
                          ? column.Alignment
                          : 'left'
                        let width = column.width ? column.width : 'inherit'
                        let cellBgColor = field.cellBackgroundColor
                        if (rowIndex === 0 && colIndex === 0) {
                          bgColor = getCellBgColor(cellBgColor, row)
                        }
                        textColor =
                          rowIndex === 0
                            ? getCellTextColor(field, row)
                            : textTheme.palette.sys_trad.text.main

                        return (
                          <TableCell
                            className={
                              rowIndex === 0
                                ? classes.headerCell
                                : classes.bodyCell
                            }
                            align={colAlign}
                            key={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                            style={{
                              minWidth: width,
                              paddingRight: isLastColumn ? '16px' : '24px',
                              backgroundColor: bgColor ? bgColor : 'inherit',
                              color: textColor
                            }}
                          >
                            {value}
                            {isLastColumn && (
                              <span className={classes.subheader}>{total}</span>
                            )}
                          </TableCell>
                        )
                      })
                    )}
                  </TableRow>
                )
              })}
              <TableRow>
                <TableCell className={classes.tablefooter} colSpan={2}>
                  <span>{props.updated}</span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <ErrorComponent id={'entityScoreError'} dataStatus={PAGE_CON.SUCCESS} />
      )}
    </div>
  )
}

ScoreTable.propTypes = {
  /** Score id */
  id: PropTypes.string,
  /** Column header and data field details */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      HeaderLabel: PropTypes.string,
      Alignment: PropTypes.string,
      width: PropTypes.number,
      DataField: PropTypes.arrayOf(
        PropTypes.shape({
          Name: PropTypes.string,
          cellBackgroundColor: PropTypes.shape({
            colorGroup: PropTypes.string,
            colorDerivedByField: PropTypes.string
          }),
          cellTextColor: PropTypes.shape({
            colorGroup: PropTypes.string,
            colorDerivedByField: PropTypes.string
          }),
          isWholeNumber: PropTypes.bool
        })
      )
    })
  ),

  /** Data to be displayed in table */
  data: PropTypes.any,
  /** Width for table */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Height for table */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Update date to be displayed in table footer */
  updated: PropTypes.string
}

export default ScoreTable
