import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'
import { v4 as uuidv4 } from 'uuid'
import { DropdownField, PrimaryLink } from 'components'
import React, { useContext } from 'react'
import themeColorPalette from 'theme/theme'
import useAppState from 'hooks/useAppState'

var is_safari =
  navigator.userAgent.indexOf('Safari') !== -1 &&
  navigator.userAgent.indexOf('Chrome') === -1

function getUniqueId(actionData) {
  return actionData + '$$' + uuidv4()
}

const BenchmarkComp = (props) => {
  const { pageTitle } = props
  const context = useContext(GlobalContext)
  const [filterState, filterDispatch] = context.globalFilter
  const [, globalDispatch] = context.globalManager
  const [currAppState] = useAppState(pageTitle)

  const [unSelectedColor, setUnSelectedColor] = React.useState(
    themeColorPalette.palette.sys_trad.text.main
  )

  // eslint-disable-next-line no-unused-vars
  const onBenchmarkChangeLoadData = async (benchmarkId) => {
    globalDispatch({ type: REDUCER_TYPE.BENCHMARK_CHANGED, payload: context })

    filterDispatch({
      type: REDUCER_TYPE.UPDATED_ACTION,
      actionData: getUniqueId(PAGE_CON.FILTER_BENCHMARK)
    })
  }

  //same as is export data avaliable
  const isDataAvailable = () => {
    let isDataLoading = false
    currAppState &&
      Object.keys(currAppState).some((key) => {
        let widget = currAppState[key]
        // Additional check on Sectors, since Regions & Sectors are grouped together in Post-MVP
        isDataLoading =
          widget.title &&
          widget.title !== PAGE_CON.SECTORS_TITLE &&
          Object.prototype.hasOwnProperty.call(widget, 'isLoading') &&
          widget.isLoading !== undefined
            ? widget.isLoading
            : false
        if (isDataLoading) {
          return isDataLoading
        }
        return false
      })
    return filterState.isLoading || isDataLoading
  }

  React.useEffect(() => {
    let initialBenchmarkColor =
      filterState.globalPortfolioFilter.benchmark === ''
        ? themeColorPalette.palette.sys_trad.text2.main
        : themeColorPalette.palette.sys_trad.text.main
    setUnSelectedColor(initialBenchmarkColor)
  }, [])

  React.useEffect(() => {
    let actionPerformedOn = filterState.actionPerformedOn.split('$$')[0]
    let initialBenchmarkColor = ''
    switch (actionPerformedOn) {
      case PAGE_CON.FILTER_PORTFOLIO:
        initialBenchmarkColor =
          filterState.globalPortfolioFilter.benchmark === ''
            ? themeColorPalette.palette.sys_trad.text2.main
            : themeColorPalette.palette.sys_trad.text.main
        setUnSelectedColor(initialBenchmarkColor)
        break
      default:
        break
    }
  }, [filterState.actionPerformedOn])

  React.useEffect(() => {
    if (
      filterState.updatePortfolioFromComponent === PAGE_CON.UPLOAD_PORTFOLIO
    ) {
      let initialBenchmarkColor = themeColorPalette.palette.sys_trad.text2.main
      setUnSelectedColor(initialBenchmarkColor)
    }
  }, [filterState.globalPortfolioFilter.portfolio])

  React.useEffect(() => {
    if (
      filterState.updatePortfolioFromComponent === PAGE_CON.UPLOAD_BENCHMARK
    ) {
      onBenchmarkChangeLoadData(filterState.globalPortfolioFilter.benchmark)
      let initialBenchmarkColor =
        filterState.globalPortfolioFilter.benchmark === ''
          ? themeColorPalette.palette.sys_trad.text2.main
          : themeColorPalette.palette.sys_trad.text.main
      setUnSelectedColor(initialBenchmarkColor)

      filterDispatch({
        type: REDUCER_TYPE.RESET_UPDATE_PORTFOLIO_FROM_COMPONENT
      })
    }
  }, [filterState.globalPortfolioFilter.benchmark])

  const saveFilter = (val, filterType) => {
    filterDispatch({
      type: REDUCER_TYPE.UPDATE_FILTER,
      payload: val,
      name: filterType,
      actionPerformedOn: getUniqueId(filterType)
    })
  }

  const onUpload = (uploadType) => {
    filterDispatch({
      type: REDUCER_TYPE.UPLOAD_PORTFOLIO_MODAL,
      payload: true,
      uploadType,
      uploadInitiatedFrom: PAGE_CON.FILTER_BENCHMARK
    })
  }

  const onBenchmarkDataChange = (event) => {
    if (event.target.value === PAGE_CON.FOOTER_COMPONENT) return
    let textColor =
      event.target.value === ''
        ? themeColorPalette.palette.sys_trad.text2.main
        : themeColorPalette.palette.sys_trad.text.main
    setUnSelectedColor(textColor)
    saveFilter(event.target.value, PAGE_CON.FILTER_BENCHMARK)
    onBenchmarkChangeLoadData(event.target.value)
  }

  const addBenchmark = (
    <PrimaryLink
      linkText="Add Benchmark"
      linkClass="linkInline"
      linkMargin="0px"
      onClick={() => onUpload(PAGE_CON.FILTER_BENCHMARK)}
    ></PrimaryLink>
  )

  return (
    <DropdownField
      id="BenchmarkDropdown-id"
      heap_id="benchmark"
      dropdownDefaultSelction={
        filterState.globalPortfolioFilter.benchmark
          ? filterState.globalPortfolioFilter.benchmark
          : ''
      }
      saveValueUsingExternalState={true}
      dropdownOptions={filterState.filterData.benchmarkList}
      onChange={onBenchmarkDataChange}
      unSelectedColor={unSelectedColor}
      labelText="No Benchmark"
      alternateLabelText="Select Benchmark"
      footerComponent={addBenchmark}
      onClickFooterComponent={() => onUpload(PAGE_CON.FILTER_BENCHMARK)}
      isLoading={is_safari ? isDataAvailable() : filterState.isLoading}
      maxWidth={'180px'}
      defaultData={''}
    />
  )
}

export default BenchmarkComp
