import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'

export const useStyles = makeStyles({
  tableContainer: {
    width: (props) => props.width,
    height: (props) => (props.height ? props.height : '100%'),
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
    borderRadius: '2px'
  },
  table: {
    width: (props) => props.width
  },
  headerCell: {
    ...textTheme.typography.p1,
    fontSize: '20px',
    lineHeight: '21px',
    padding: '16px',
    borderBottom: 'none'
  },
  subheader: {
    ...textTheme.typography.p3
  },
  bodyCell: {
    ...textTheme.typography.p2,
    fontSize: '14px',
    borderBottom: 'none',
    lineHeight: '15px',
    padding: '8px 24px 0 16px'
  },
  tablefooter: {
    ...textTheme.typography.p5,
    lineHeight: '12px',
    borderBottom: 'none',
    color: textTheme.palette.sys_trad.text.main
  }
})
