import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
    title: (props) =>
      props.title === '.'
        ? {
            color: themeColorPalette.palette.white.main,
            ...textTheme.typography.p2,
            fontWeight: '400'
          }
        : {
            ...textTheme.typography.p2,
            fontSize: '10px !important'
          },
    chiclet: {
      ...textTheme.typography.p4,
      paddingLeft: '12px',
      paddingTop: '7px'
    },
    customChiclet: {
      ...textTheme.typography.p4,
      paddingLeft: (props) =>
        props.valuePaddingLeft ? props.valuePaddingLeft : '24px',
      paddingTop: '0px'
    },
    customChicletScoreRange: {
      ...textTheme.typography.p4,
      paddingLeft: '12%',
      paddingTop: '0px'
    },
    display: {
      ...textTheme.typography.p4,
      color: (props) => props.valueColor,
      backgroundColor: (props) => props.valueBgColor,
      borderRadius: '2px',
      padding: '4px 8px'
    },
    margin: {
      marginTop: (props) =>
        props.valueMarginTop
          ? props.valueMarginTop === '0px'
            ? '5px'
            : props.valueMarginTop
          : '45px'
    },
    customDisplayScoreRange: {
      ...textTheme.typography.p4,
      color: (props) => props.valueColor,
      backgroundColor: (props) => props.valueBgColor,
      borderRadius: '2px',
      padding: '4px 4px 4px 4px',
      fontSize: '12px',
      lineHeight: '16px'
    },
    customDisplay: {
      ...textTheme.typography.p2,
      color: (props) => props.valueColor,
      backgroundColor: (props) => props.valueBgColor,
      borderRadius: '2px',
      padding: '4px 8px',
      margin: '0 4px 4px 0',
      fontSize: '12px',
      fontWeight: '400',
      whiteSpace: 'nowrap'
    }
}))