import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import Divider from '@material-ui/core/Divider'
import { ErrorComponent, Stacked } from 'components'

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: themeColorPalette.palette.sys_trad.border.main
  },
  sectionTitle: {
    ...textTheme.typography.p2,
    textDecoration: 'underline'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  grid: {
    ...textTheme.typography.p3
  }
}))

const barColors = [
  themeColorPalette.palette.sys_trad.border.main,
  themeColorPalette.palette.major_no.green_no.main,
  themeColorPalette.palette.major_no.green_minor.main,
  themeColorPalette.palette.major_no.green_signif.main,
  themeColorPalette.palette.major_no.green_major.main
]

const GreenShareAssessmentUnderlyingDataRiskMetrics = (props) => {
  const classes = useStyles(props)
  const UnderlyingData = props.underlyingDataMetrics
  const dataStatus = props.dataStatus
  const maxCompanies = props.totalCompanies || '0'

  const reformatUDMData = () => {
    maxCompanies !== undefined &&
      UnderlyingData?.forEach((metric) => {
        metric?.data?.forEach((data, index) => {
          let totalCompanies = 0
          let totalPct = data?.reduce((total, data) => {
            totalCompanies += data.count
            return total + data.data[0]
          }, 0)
          if (totalPct < 100) {
            data.unshift({
              data: [100 - totalPct],
              name: 'Not Covered'
            })
            if (index === 0) {
              data[0].count = parseInt(maxCompanies) - totalCompanies
            }
          }
        })
      })
    return UnderlyingData
  }

  const underlyingDataMetrics = React.useMemo(() => {
    return {
      data: reformatUDMData()
    }
  }, [dataStatus, maxCompanies])

  return (
    <Grid className={classes.grid} item xs={12} sm={12}>
      <Grid className={classes.grid} item xs={12}>
        {underlyingDataMetrics.data &&
        underlyingDataMetrics.data.length > 0 &&
        maxCompanies !== undefined ? (
          underlyingDataMetrics.data.map((item, index) => {
            return (
              <span key={`${item + index}`}>
                <Grid
                  container
                  className={classes.grid}
                  direction={'row'}
                  spacing={2}
                >
                  <Grid item className={classes.grid} xs={4}>
                    <br />
                    <br />
                    {item.title}
                  </Grid>
                  <Grid className={classes.grid} item xs={8}>
                    <Grid
                      container
                      direction={'row'}
                      spacing={2}
                      className={classes.grid}
                    >
                      <Stacked
                        data={item.data !== undefined ? item.data[1] : []}
                        title={index === 0 ? 'Investment in Category' : '.'}
                        barColors={barColors}
                      />

                      <Stacked
                        data={item.data !== undefined ? item.data[0] : []}
                        title={index === 0 ? 'Companies in Category' : '.'}
                        barColors={barColors}
                        notPercent={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>{' '}
                <br></br>
                {index + 1 !== props.underlyingDataMetrics.length && (
                  <Divider className={classes.divider} />
                )}
                <br></br>
              </span>
            )
          })
        ) : (
          <ErrorComponent
            id={'underlyingDataMetricsError'}
            hideErrorActionButton={true}
            errorText="There is no data on which to base our calculations"
          />
        )}
      </Grid>
    </Grid>
  )
}

export default GreenShareAssessmentUnderlyingDataRiskMetrics
