import { makeStyles } from '@material-ui/core'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

const leftColumnWidth = 50
const rightColumnWidth = 5
export const defaultChartWidth = 825
export const useStyles = makeStyles(() => ({
  button: {
    padding: '5px',
    border: 'none',
    maxWidth: '2px',
    maxHeight: '2px',
    minWidth: '2px',
    minHeight: '2px'
  },
  labelItem: {
    display: 'flex',
    marginRight: '20px',
    alignItems: 'center'
  },
  input: {
    display: 'none'
  },
  containerStyle: {
    width: (props) =>
      `${
        leftColumnWidth +
        (props.width ? props.width : defaultChartWidth) +
        rightColumnWidth
      }px`
  },
  firstColumn: {
    width: `${leftColumnWidth}px`,
    background: themeColorPalette.palette.sys_trad.bg.main
  },
  secondColumn: {
    width: (props) => `${props.width ? props.width : defaultChartWidth}px`,
    background: themeColorPalette.palette.sys_trad.bg.main
  },
  thirdColumn: {
    width: `${rightColumnWidth}px`,
    background: themeColorPalette.palette.sys_trad.bg.main
  },
  middleTopLabel: {
    ...textTheme.typography.p5,
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingBottom: '12px'
  },
  middleBottomLabel: {
    paddingBottom: '12px'
  },
  leftTopLabel: {
    ...textTheme.typography.p5,
    marginRight: '5px',
    textAlign: 'end',
    height: '50%',
    wordWrap: 'break-word',
    color: themeColorPalette.palette.sys_trad.text2.main,
    opacity: 0.5,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  leftBottomLabel: {
    ...textTheme.typography.p5,
    marginRight: '5px',
    textAlign: 'end',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    height: '50%',
    wordWrap: 'break-word',
    color: themeColorPalette.palette.sys_trad.text2.main,
    opacity: 0.5
  },
  bottomCenterLabel: {
    ...textTheme.typography.p5,
    marginRight: '5px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    height: '50%',
    wordWrap: 'break-word',
    color: themeColorPalette.palette.sys_trad.text2.main,
    opacity: 0.5
  },
  bottomLeftLabel: {
    ...textTheme.typography.p5,
    color: themeColorPalette.palette.sys_trad.text2.main,
    paddingTop: '3px',
    opacity: 0.5
  },
  bottomRightLabel: {
    ...textTheme.typography.p5,
    color: themeColorPalette.palette.sys_trad.text2.main,
    textAlign: 'end',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingTop: '3px',
    opacity: 0.5
  },
  legendLabel: {
    ...textTheme.typography.p4,
    color: themeColorPalette.palette.sys_trad.text.main
  }
}))