import React from 'react'
import {
  extractCardInfo,
  formatSectorOption,
  formatSizeOption,
  formattingRules} from './CriteriaFilterData'
import { Grid } from '@material-ui/core'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import { SMEOnDemand } from 'services'
import { useContext, useEffect, useState } from 'react'
import {
  Card,
  Page,
  PageHeader,
  PrimaryButton,
  SliderChart,
  ToggleButton
} from 'components'
import { useStyles } from './styles'
import Error from 'utils/error'
import numberData from 'utils/numberFormat'
import LoadingMask from 'utils/loadingMask'
import SectorSizeCriteriaFilter from './SectorSizeCriteriaFilter'
import themeColorPalette from 'theme/theme'

const CriteriaFilterView = ({ onCancelRequest }) => {
  const classes = useStyles()

  const [onDemandAsmntState, onDemandAsmntDispatch] =
    useContext(GlobalContext).onDemandAsmntService
  const [reportingServiceState] =
    useContext(GlobalContext).globalReportingService

  const portfolio = onDemandAsmntState.portfolio

  const [data, setData] = useState({})
  const [companies, setCompanies] = useState([])
  const [scoreRange, setScoreRange] = useState({ min: 0, max: 100 })
  const [scoreOptions, setScoreOptions] = useState([])
  const [locationOptions, setLocationOptions] = useState([])
  const [sectorOptions, setSectorOptions] = useState([])
  const [unknownSector, setUnknownSector] = useState({})
  const [sizeOptions, setSizeOptions] = useState([])
  const [unknownSize, setUnknownSize] = useState({})
  const [sectorToggleChecked, setSectorToggleChecked] = useState(true)
  const [sizeToggleChecked, setSizeToggleChecked] = useState(true)
  const [matchAllToggleChecked, setMatchAllToggleChecked] = useState(true)
  const [numCompanies, setNumCompanies] = useState(0)

  let entities = []

  const cardsInfo = [
    { title: 'Predicted ESG Score' },
    { title: 'Location', subTitle: '(Based on headquarters)' },
    { title: 'Sector' },
    { title: 'Size', subTitle: '(Employee Count)' }
  ]

  const portfolioName = portfolio?.portfolioName || ''

  const reviewRequests = () => {
    onDemandAsmntDispatch({
      type: REDUCER_TYPE.ON_DEMAND_ASMNT_SET_STEP,
      initiatedFrom: onDemandAsmntState.prevStep,
      step: PAGE_CON.ON_DEMAND_ASMNT_REQUEST_DETAIL,
      prevStep: PAGE_CON.ON_DEMAND_ASMNT_CRITERIA_FILTER_VIEW,
      reqDetailReadOnly: false
    })
  }

  const getMinMax = () => {
    let min = scoreOptions.reduce((a, b) => Math.min(a, b.score), 100)
    let max = scoreOptions.reduce((a, b) => Math.max(a, b.score), 0)
    return { min, max }
  }

  const cardHasAllSelection = (min, max, scoreRange, number_of_predicted) => {
    return (
      (scoreRange.min <= min && scoreRange.max >= max) ||
      locationOptions[0].checked ||
      sectorOptions[0].checked ||
      sizeOptions[0].checked ||
      (sectorToggleChecked &&
        unknownSector?.company_count === number_of_predicted) ||
      (sizeToggleChecked && unknownSize?.company_count === number_of_predicted)
    )
  }

  const getTotalCompanies = ({ scoreRange, matchAll = true }) => {
    const { min, max } = getMinMax()
    const number_of_predicted =
      onDemandAsmntState.criteriaFilterView.data?.info?.number_of_predicted
    const selectedOptions = getFilteredOptions()
    let total_companies = 0

    entities = [...data.pristineData.entities]
    if (matchAll && matchAllToggleChecked) {
      entities = data.pristineData.entities.filter((item) => {
        let match =
          Number(item.score) >= scoreRange.min &&
          Number(item.score) <= scoreRange.max &&
          selectedOptions.locations.includes(item.country_name) &&
          selectedOptions.sectors.includes(formatSectorOption(item.sector)) &&
          selectedOptions.sizes.includes(formatSizeOption(item.size))

        return match
      })
    } else if (
      !cardHasAllSelection(min, max, scoreRange, number_of_predicted)
    ) {
      entities = data.pristineData.entities.filter(
        (item) =>
          (Number(item.score) >= scoreRange.min &&
            Number(item.score) <= scoreRange.max) ||
          selectedOptions.locations.includes(item.country_name) ||
          selectedOptions.sectors.includes(formatSectorOption(item.sector)) ||
          selectedOptions.sizes.includes(formatSizeOption(item.size))
      )
    }

    total_companies = entities.length

    let subsetInfo = {
      info: {
        ...getCount(),
        scoreRange
      },
      entities
    }

    subsetInfo.entities.length &&
      onDemandAsmntDispatch({
        type: REDUCER_TYPE.ON_DEMAND_ASMNT_REQUEST_DETAIL_DATA,
        sectionName: REDUCER_STATE.ON_DEMAND_ASMNT_REQUEST_DETAIL_INFO,
        data: subsetInfo
      })
    setNumCompanies(total_companies)
  }

  const getCount = () => {
    let locs = new Set()
    let sectors = new Set()
    entities?.forEach((entity) => {
      locs.add(entity.country_name)
      sectors.add(entity.sector)
    })
    return {
      location: locs.size === locationOptions[0].total ? 'All' : locs.size,
      sector: sectors.size === sectorOptions[0].total ? 'All' : sectors.size
    }
  }

  const getAllCountries = () => {
    let countries = []
    locationOptions.forEach((item) => {
      item?.countries?.forEach((country) => {
        if (country.checked) {
          countries.push(country.name)
        }
      })
    })
    return countries
  }

  const getAllSectorsOrSizes = (arr) => {
    let result = []
    arr.forEach((item) => {
      if (!item.value.includes('All ') && item.checked) {
        result.push(item.name)
      }
    })
    return result
  }

  const getFilteredOptions = () => {
    let checkedOptions = {
      locations: [],
      sectors: [],
      sizes: []
    }
    if (locationOptions[0].checked) {
      checkedOptions.locations.push(...getAllCountries())
    } else {
      locationOptions.forEach((item) => {
        item?.countries?.forEach((country) => {
          if (country.checked) {
            checkedOptions.locations.push(country.name)
          }
        })
      })
    }

    if (sectorOptions[0].checked) {
      checkedOptions.sectors.push(...getAllSectorsOrSizes(sectorOptions))
    } else {
      sectorOptions.forEach((item) => {
        if (item.checked) {
          checkedOptions.sectors.push(item.name)
        }
      })
    }
    if (sectorToggleChecked) {
      checkedOptions.sectors.push(undefined)
    }

    if (sizeOptions[0].checked) {
      checkedOptions.sizes.push(...getAllSectorsOrSizes(sizeOptions))
    } else {
      sizeOptions.forEach((item) => {
        if (item.checked) {
          checkedOptions.sizes.push(item.name)
        }
      })
    }
    if (sizeToggleChecked) {
      checkedOptions.sizes.push(undefined)
    }

    return checkedOptions
  }

  const checkForAll = (arr = [], key) => {
    if (arr.length > 1) {
      let isAllChecked = arr
        .filter((item) => !item.value.includes(key))
        .every((x) => x.checked)
      arr[0].checked = isAllChecked
    }
  }

  const onLocationFilterOptionChange = (selectedItem) => {
    let newOptions = [...locationOptions]
    let parentOption = newOptions.find((x) => x.value === selectedItem.id) || {}
    if (Object.keys(parentOption).length > 0) {
      if (parentOption.value.includes('All Locations')) {
        newOptions.forEach((x) => {
          x.checked = selectedItem.checked
          if (x?.countries?.length) {
            x?.countries.forEach(
              (country) => (country.checked = selectedItem.checked)
            )
          }
        })
      } else {
        parentOption.checked = selectedItem.checked
        parentOption?.countries?.forEach(
          (country) => (country.checked = selectedItem.checked)
        )
        checkForAll(newOptions, 'All Locations')
      }
    } else {
      newOptions.forEach((option) => {
        let countryOption =
          option?.countries?.find((x) => x.value === selectedItem.id) || {}
        if (Object.keys(countryOption).length > 0) {
          countryOption.checked = selectedItem.checked
          if (selectedItem.checked) {
            option.checked = option?.countries?.every((x) => x.checked)
          } else {
            option.checked = selectedItem.checked
          }
          checkForAll(newOptions, 'All Locations')
        }
      })
    }

    setLocationOptions(newOptions)
  }

  const onSectorFilterOptionChange = (selectedItem) => {
    let newOptions = [...sectorOptions]
    newOptions = filterOptionChange(newOptions, selectedItem)
    setSectorOptions(newOptions)
  }

  const onSizeFilterOptionChange = (selectedItem) => {
    let newOptions = [...sizeOptions]
    newOptions = filterOptionChange(newOptions, selectedItem)
    setSizeOptions(newOptions)
  }

  const filterOptionChange = (options, selectedItem) => {
    let selectedOption = options?.find((x) => x.value === selectedItem.id) || {}
    if (Object.keys(selectedOption).length > 0) {
      if (options[0].value.trim() === selectedOption.value.trim()) {
        let remainingOptions = [...options]
        remainingOptions.shift()
        remainingOptions.forEach(
          (item) => (item.checked = selectedItem.checked)
        )
      }
      selectedOption.checked = selectedItem.checked
      checkForAll(options, 'All ')
      return options
    } else {
      return
    }
  }

  const handleMatchAll = (item) => {
    setMatchAllToggleChecked(item.checked)
    if (item.checked) {
      resetCards()
    } else {
      getTotalCompanies({ scoreRange, matchAll: item.checked })
    }
  }

  const onToggleChange = (toggleChangeHandler, item) => {
    let entities = [...new Set(companies)]
    toggleChangeHandler(item.checked)

    if (item.id.includes('Sector')) {
      if (item.checked) {
        entities.push(...unknownSector.companies)
      } else {
        let tempArr = [...entities]
        entities = [
          ...tempArr.filter(
            (orbis_id) => !unknownSector.companies.includes(orbis_id)
          )
        ]
      }
    } else if (item.id.includes('Size')) {
      if (item.checked) {
        entities.push(...unknownSize.companies)
      } else {
        let tempArr = [...entities]
        entities = [
          ...tempArr.filter(
            (orbis_id) => !unknownSize.companies.includes(orbis_id)
          )
        ]
      }
    }
    setCompanies(new Set(entities))
  }

  const onScoreRangeChange = ({ min, max }) => {
    setScoreRange({ min, max })
    if (matchAllToggleChecked) {
      adjustCards('score')
    } else {
      getTotalCompanies({ scoreRange: { min, max } })
    }
  }

  const getCardComp = (type) => {
    const props = {
      Location: {
        options: locationOptions,
        unknown: [],
        onFilterOptionChange: onLocationFilterOptionChange
      },
      Sector: {
        footerInfo: {
          title: 'Companies without Sector',
          text: 'an identified Sector'
        },
        onFilterOptionChange: onSectorFilterOptionChange,
        options: sectorOptions,
        toggleChecked: sectorToggleChecked,
        setToggleChecked: setSectorToggleChecked,
        onToggleChange,
        unknown: unknownSector
      },
      Size: {
        footerInfo: {
          title: 'Companies without Employee Size',
          text: 'Employee Size Figures'
        },
        onFilterOptionChange: onSizeFilterOptionChange,
        options: sizeOptions,
        toggleChecked: sizeToggleChecked,
        setToggleChecked: setSizeToggleChecked,
        onToggleChange,
        unknown: unknownSize
      }
    }

    if (type === 'Predicted ESG Score') {
      return (
        <>
          <div style={{ padding: '0px 5px' }}>
            <SliderChart
              chart={scoreOptions}
              width={'auto'}
              min={scoreRange.min}
              max={scoreRange.max}
              showInput={false}
              onChange={onScoreRangeChange}
            />
            <div
              className={classes.criteriaFilterViewInfoTextStyle}
              style={{ margin: 0 }}
            >
              Companies with predicted scores ranging {scoreRange.min}-
              {scoreRange.max} account for {getTotalInvestment()} of investments
            </div>
          </div>
          <hr className={classes.criteriaFilterViewHRStyle} />
        </>
      )
    } else {
      return <SectorSizeCriteriaFilter {...props[type]} />
    }
  }

  const isWithinScoreRange = (scoreList = []) => {
    return !!scoreList.filter(
      (score) => score >= scoreRange.min && score <= scoreRange.max
    ).length
  }

  const checkFilter = (item, options, type) => {
    const filters = options.reduce((acc, option) => {
      if (option.checked) return acc
      return [...acc, option.name]
    }, [])

    const filteredItem = item[type]?.filter(
      (dim) => dim && !filters.includes(dim)
    )

    const sizeFilter = item['size']?.filter(
      (size) => !size && sizeToggleChecked
    )
    const sectorFilter = item['sector']?.filter(
      (sector) => !sector && sectorToggleChecked
    )
    return (
      !!filteredItem?.length || !!sectorFilter?.length || !!sizeFilter?.length
    )
  }

  const getUndefinedScore = (item, type) => {
    return (
      item[type]?.reduce((acc, value, i) => {
        if (!value) return [...acc, item['score'][i]]
        return acc
      }, []) || []
    )
  }

  const updateScoreRangesFilter = (options) => {
    let range = []
    const rangeBase = options.reduce((acc, { score, checked }) => {
      if (checked) return [...acc, ...score]

      return acc
    }, [])

    if (sectorToggleChecked) {
      const sectorsLists = getUndefinedScore('sector')
      range = [...sectorsLists, ...range]
    }

    if (sizeToggleChecked) {
      const sizesLists = getUndefinedScore('size')
      range = [...sizesLists, ...range]
    }

    range = [...rangeBase, ...range].sort((a, b) => a - b)
    const min = range[0]
    const max = range[range.length - 1]
    setScoreRange({ min, max })
  }

  const updateCardOptions = (arr, type) => {
    return arr.map((item) => {
      item.checked = true

      if (type === 'score') {
        item.checked = isWithinScoreRange(item.score)
      }

      if (type === 'sector') {
        item.checked = checkFilter(item, sectorOptions, type)
        updateScoreRangesFilter(sectorOptions, item)
      }

      if (type === 'size') {
        item.checked = checkFilter(item, sizeOptions, type)
        updateScoreRangesFilter(sizeOptions, item)
      }

      if (type === 'location') {
        const countryOptions = locationOptions.reduce(
          (acc, { countries = [] }) => [...acc, ...countries],
          []
        )
        item.checked = checkFilter(item, countryOptions, 'country')
        updateScoreRangesFilter(countryOptions, item)
      }
      checkForAll(sectorOptions, 'All ')
      checkForAll(sizeOptions, 'All ')

      return item
    })
  }

  const updateLocationOptions = (type) => {
    locationOptions.forEach((location) => {
      location?.countries?.forEach((country) => {
        country.checked = true
        if (type === 'score' && !isWithinScoreRange(country.score)) {
          country.checked = false
        }
        if (type === 'sector' && !checkFilter(country, sectorOptions, type)) {
          country.checked = false
        }
        if (type === 'size' && !checkFilter(country, sizeOptions, type)) {
          country.checked = false
        }
      })
      location.checked = location?.countries?.every((c) => c.checked)
    })
    checkForAll(locationOptions, 'All Locations')
  }

  const adjustCards = (type) => {
    if (type !== 'location') {
      updateLocationOptions(type)
    }
    if (type !== 'sector') {
      setSectorOptions(updateCardOptions(sectorOptions, type))
    }
    if (type !== 'size') {
      setSizeOptions(updateCardOptions(sizeOptions, type))
    }
  }

  const extractUnknownInfo = (arr) =>
    arr.filter((item) => item.name === 'undefined')

  const formatScoreArr = (scoreArr) => {
    let score = [...scoreArr]
    score.forEach((s) => {
      s.score = Number(s.name)
      s.investment_pct = Number(s.investment_pct).toFixed(2)
    })

    return score
  }

  const getTotalInvestment = () => {
    let value = scoreOptions.reduce((acc, curr) => {
      if (curr.score >= scoreRange.min && curr.score <= scoreRange.max) {
        acc += Number(curr.investment_pct)
      }
      return acc
    }, 0)
    return numberData(value, {
      isDecimal: true,
      maxFraction: 1,
      isPercentage: true,
      keepRawValue: true
    })
  }

  const getCardCompData = (arr, showInvestmentPct = false) => {
    let cardInfo = []

    arr.forEach((item) => {
      if (item.name === 'undefined') return
      cardInfo.push({
        value: `${item.name} ${
          showInvestmentPct ? formattingRules(item.investment_pct, true) : ''
        }`,
        name: item.name,
        checked:
          item.name.includes('All S') && item.investment_pct === 0
            ? false
            : true,
        score: item.score === undefined ? [] : item.score,
        sector: item.sector,
        total: item.total,
        region: item.region,
        size: item.size,
        country: item.country,
        investment_pct: item.investment_pct,
        companies: [...item.companies]
      })
      if (item?.countries?.length > 0) {
        cardInfo[cardInfo.length - 1].countries = getCardCompData(
          item.countries,
          true
        )
      }
    })

    return cardInfo
  }

  const resetCards = () => {
    let unknownSector = extractUnknownInfo(data.sectorArr)
    let unknownSize = extractUnknownInfo(data.sizeArr)
    setScoreRange({ min: 0, max: 100 })
    setScoreOptions(formatScoreArr(data.scoreArr))
    setLocationOptions(getCardCompData(data.locationArr, true))
    setSectorOptions(getCardCompData(data.sectorArr, true))
    setUnknownSector(...unknownSector)
    setSizeOptions(getCardCompData(data.sizeArr, false))
    setUnknownSize(...unknownSize)
    setSectorToggleChecked(Object.keys(unknownSector).length ? true : false)
    setSizeToggleChecked(Object.keys(unknownSize).length ? true : false)
  }

  const isPageReset = () => {
    return (
      scoreRange.min === 0 &&
      scoreRange.max === 100 &&
      locationOptions[0]?.checked &&
      sectorOptions[0]?.checked &&
      sizeOptions[0]?.checked &&
      sectorToggleChecked &&
      sizeToggleChecked
    )
  }

  const getRemainingReqs = () => {
    return numberData(
      reportingServiceState.assessmentLimit.data?.remaining_assessments,
      { isCommaSeparated: true }
    )
  }

  const getCompanyCount = () => {
    return isPageReset()
      ? onDemandAsmntState.criteriaFilterView.data?.info?.number_of_predicted
      : numCompanies
  }

  const getCriteriaFilterData = async () => {
    onDemandAsmntDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.ON_DEMAND_ASMNT_CRITERIA_FILTER_VIEW
    })

    try {
      const criteriaFilterData = await SMEOnDemand.fetchCriteriaFilterData({
        portfolio: portfolio?.key,
        cancelToken: onDemandAsmntState.cancelToken.token
      })
      setData(extractCardInfo(criteriaFilterData))
      onDemandAsmntDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.ON_DEMAND_ASMNT_CRITERIA_FILTER_VIEW,
        data: criteriaFilterData
      })
    } catch (e) {
      onDemandAsmntDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        sectionName: REDUCER_STATE.ON_DEMAND_ASMNT_CRITERIA_FILTER_VIEW
      })
    }
  }

  const isLoading = () =>
    onDemandAsmntState.criteriaFilterView?.isLoading === undefined ||
    onDemandAsmntState.criteriaFilterView?.isLoading

  const isError = () =>
    onDemandAsmntState.criteriaFilterView?.dataStatus === PAGE_CON.ERROR

  const hasData = () =>
    onDemandAsmntState.criteriaFilterView.data?.entities?.length

  const reloadComponent = (errorOccuredOn) => {
    switch (errorOccuredOn) {
      case PAGE_CON.ON_DEMAND_ASMNT_CRITERIA_FILTER_VIEW:
        getCriteriaFilterData()
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (hasData()) {
      setData(extractCardInfo(onDemandAsmntState.criteriaFilterView.data))
      return
    }
    if (portfolio?.key !== '') {
      getCriteriaFilterData()
    }
  }, [portfolio])

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      resetCards()
    }
  }, [data])

  useEffect(() => {
    if (scoreOptions.length && locationOptions.length) {
      getTotalCompanies({ scoreRange })
    }
  }, [
    scoreOptions,
    locationOptions,
    sectorOptions,
    sizeOptions,
    sizeToggleChecked,
    sectorToggleChecked
  ])

  useEffect(() => {
    if (matchAllToggleChecked) {
      adjustCards('score')
    }
  }, [scoreRange])

  return isLoading() ? (
    <LoadingMask />
  ) : isError() ? (
    <Error
      id={'ESGError'}
      dataStatus={PAGE_CON.ERROR}
      onErrorClick={reloadComponent}
      errorOccuredOn={PAGE_CON.ON_DEMAND_ASMNT_CRITERIA_FILTER_VIEW}
    />
  ) : !hasData() ? (
    <Error
      id={'criteriaFilterErr'}
      dataStatus={PAGE_CON.SUCCESS}
      errorOccuredOn={PAGE_CON.ON_DEMAND_ASMNT_CRITERIA_FILTER_VIEW}
    />
  ) : (
    <Page className={classes.criteriaFilterViewRoot}>
      <div className={classes.criteriaFilterViewRemainingReq}>
        <b>
          {`${getRemainingReqs()} assessment request${
            getRemainingReqs() === '1' ? '' : 's'
          }`}
        </b>{' '}
        remaining for this period
      </div>
      <PageHeader
        pageHeaderMarginBottom="0px"
        pageHeaderHeight="auto"
        minWidth="600px"
        pageHeaderWidth="auto"
        showPageHeaderBorder={false}
        pageHeaderContentComponent={
          <Grid
            container
            className={classes.criteriaFilterViewHeaderGrid}
            spacing={2}
          >
            <Grid item xs={12} md={8}>
              <div className={classes.criteriaFilterViewPageHeader}>
                Request On-Demand Assessments for {portfolioName}
              </div>
              <div className={classes.criteriaFilterViewSubHeader}>
                {`${formattingRules(
                  portfolio?.on_demand_coverage || 0
                )} assessment eligible
                (${
                  onDemandAsmntState.criteriaFilterView.data?.info
                    ?.number_of_predicted
                }
                ${
                  onDemandAsmntState.criteriaFilterView.data?.info
                    ?.number_of_predicted > 1
                    ? 'companies'
                    : 'company'
                })`}
              </div>
            </Grid>
            <Grid
              item
              md={4}
              className={classes.criteriaFilterViewButtonContainer}
            >
              <PrimaryButton
                id="button-cancel-req-test-id"
                buttonText="Cancel"
                buttonClass="buttonSlim"
                buttonHeight={32}
                buttonWidth={59}
                buttonMarginBotom={'8px'}
                onClick={onCancelRequest}
              />
              <PrimaryButton
                id="button-review-req-test-id"
                buttonText={`Review and Send Request to
                                                ${getCompanyCount()}
                                                ${
                                                  getCompanyCount() === 1
                                                    ? 'Company'
                                                    : 'Companies'
                                                }`}
                buttonClass="buttonAltPrimary"
                buttonHeight={32}
                buttonWidth={261}
                buttonMarginLeft={'12px'}
                buttonMarginBotom={'8px'}
                buttonDisabled={!getCompanyCount()}
                onClick={reviewRequests}
              />
            </Grid>
          </Grid>
        }
      />
      <p className={classes.criteriaFilterViewInfoTextStyle}>
        Target a subset of companies for assessment request:
      </p>

      <Grid
        container
        className={classes.criteriaFilterViewHeaderGrid}
        spacing={2}
      >
        {cardsInfo &&
          cardsInfo.map((cardInfo, index) => (
            <Grid item xs={12} sm={6} md={3} key={`gridCard-${index}`}>
              <Card
                cardClass="shadow"
                cardPadding={9}
                id={`card-test-id-${index}`}
                key={`card-test-id-${index}`}
                headerTitle={cardInfo.title ? cardInfo.title.trim() : ''}
                headerColor={themeColorPalette.palette.sys_trad.text.main}
                maxWidth={'auto'}
                height={370}
                subTitle={
                  <span
                    className={classes.criteriaFilterViewInfoTextStyle}
                    style={{ marginLeft: 8 }}
                  >
                    {cardInfo.subTitle ? cardInfo.subTitle.trim() : ''}
                  </span>
                }
                components={[getCardComp(cardInfo.title)]}
              />
            </Grid>
          ))}
      </Grid>
      <div style={{ marginTop: 24 }}>
        <ToggleButton
          id={'all-criteria-toggle'}
          toggleLabelText={'Match all criteria across categories of selection'}
          labelPlacement={'start'}
          labelClass={'onDemandAsmntlabel'}
          isChecked={matchAllToggleChecked}
          onChange={(item) => handleMatchAll(item)}
        />
      </div>
    </Page>
  )
}

export default CriteriaFilterView
