import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconMenu = (props) => (
  <SvgIcon {...props}>
    <path
      d="M1 4.5C1 4.22386 1.22386 4 1.5 4H14.5C14.7761 4 15 4.22386 15 4.5C15 4.77614 14.7761 5 14.5 5H1.5C1.22386 5 1 4.77614 1 4.5Z"
      fill="#26415E"
    />
    <path
      d="M1 8.5C1 8.22386 1.22386 8 1.5 8H9.5C9.77614 8 10 8.22386 10 8.5C10 8.77614 9.77614 9 9.5 9H1.5C1.22386 9 1 8.77614 1 8.5Z"
      fill="#26415E"
    />
    <path
      d="M1 12.5C1 12.2239 1.22386 12 1.5 12H9.5C9.77614 12 10 12.2239 10 12.5C10 12.7761 9.77614 13 9.5 13H1.5C1.22386 13 1 12.7761 1 12.5Z"
      fill="#26415E"
    />{' '}
  </SvgIcon>
)
IconMenu.displayName = 'IconMenu'
IconMenu.muiName = 'SvgIcon'

export default IconMenu
