import Grid from '@material-ui/core/Grid'
import React, { useState } from 'react'
import themeColorPalette from 'theme/theme'
import { PrimaryModal, RegularTable } from 'components'
import DriverHeaderSector from 'views/issuer/driverInfo/DriverHeaderSector'
import DriverInfoSector from 'views/issuer/driverInfo/DriverInfoSector'
import { EntityService } from 'services'
import { PAGE_CON } from 'constant/constants'
import Error from 'utils/error'
import * as S from './styles'

const SectorDrivers = (props) => {
  const classes = S.useStyles(props)

  const [driverItem, setDriverItem] = useState(null)
  const [modalLoading, setModalLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [row, setRow] = useState(null)

  // esc key
  const handleKeyDown = (x) => {
    if (x.keyCode === 27) if (handleCloseDialog) handleCloseDialog()
  }

  const handleCloseDialog = () => {
    setOpenModal(false)
  }

  const desiredOrder = ['Environmental', 'Social', 'Governance']

  const sortedData =
    props.SectorData &&
    props.SectorData.sort((a, b) => {
      return (
        desiredOrder.indexOf(a.indicator) - desiredOrder.indexOf(b.indicator)
      )
    })

  const handleRowClick = async (rowId, row) => {
    if (!modalLoading) {
      setRow(row)
      setOpenModal(true)
      setModalLoading(true)
      const criteria = row.criteria_id

      const newDriverItemData = {
        label: row.criteria_weight,
        title: row.criteria_name,
        criteria: row.criteria_id
      }

      setDriverItem(newDriverItemData)

      try {
        const data = await EntityService.getDriverInfo({ criteria })

        setDriverItem({
          ...newDriverItemData,
          apiData: data,
          dataStatus: PAGE_CON.SUCCESS,
          subTitle: data?.sub_category_detailed_description || ''
        })

        setModalLoading(false)
      } catch (err) {
        setDriverItem({
          ...newDriverItemData,
          dataStatus: PAGE_CON.ERROR
        })
        setModalLoading(false)
      }
    }
  }

  return (
    <div className={classes.root} id={props.id}>
      <Grid id={props.id} container direction="column">
        {sortedData && sortedData.length > 0 ? (
          props.SectorTables &&
          props.SectorTables.length > 0 &&
          props.SectorTables.map((item, index) => {
            return (
              <Grid
                item
                className={classes.tableStyle}
                xs={12}
                key={`gridItem-${index}`}
              >
                <RegularTable
                  id={props.id}
                  tableEditable={false}
                  columns={props.SectorTables[index]}
                  totalData={sortedData[index]?.drivers || []}
                  tableLength={props.tableLength}
                  isSectorAllocation={true}
                  handleRowClick={handleRowClick}
                  showPointer="pointer"
                />
              </Grid>
            )
          })
        ) : (
          <Error
            errorClass={'noMargin'}
            width={'102%'}
            margin={0}
            id={'sourceDocErrors'}
            topMargin={'0px'}
            dataStatus={'success'}
            hideBoxShadow={true}
            errorOccuredOn={PAGE_CON.ENTITY_DRIVER_SUMMARY}
          />
        )}
      </Grid>

      <PrimaryModal
        id="subcategory-modal-test-id"
        modalOpen={openModal}
        onModalClose={handleCloseDialog}
        modalScroll={'paper'}
        modalClass="minorModal"
        modalMaxWidth={'md'}
        showCloseIcon={false}
        headerBgColor={themeColorPalette.palette.sys_trad.white.main}
        showDivider={true}
        onKeyDown={handleKeyDown}
        modalHeaderContentComponent={
          <DriverHeaderSector
            isLoading={modalLoading}
            data={driverItem}
          ></DriverHeaderSector>
        }
        modalContentComponent={
          <DriverInfoSector
            isLoading={modalLoading}
            data={driverItem}
            reloadComponent={() => handleRowClick(null, row)}
          />
        }
        backdropstyle={true}
      />
    </div>
  )
}

export default SectorDrivers
