import PropTypes from 'prop-types'
import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import * as S from './styles'


/**
 * @visibleName Table Bar Cell
 */
const TableCellBar = ({
  id,
  barValue,
  bgColor,
  cellHeight,
  cellWidth,
  barColor,
  benchmarkValue,
  hideBenchmark,
  barCorner,
  ESGAssessment
}) => {
  let actualVal = barValue ? barValue.toString().replace('%', '') : '0'
  let barWidth = actualVal + '%'
  let hideBenchmarkFlag = hideBenchmark === true ? 'none' : ''
  let formattedBenchmarkValue = benchmarkValue
    ? benchmarkValue.toString().replace('%', '')
    : '0'
  formattedBenchmarkValue =
    parseFloat(formattedBenchmarkValue) > 0 &&
    parseFloat(formattedBenchmarkValue) < 1
      ? 1
      : formattedBenchmarkValue

  const classes = S.useStyles({
    barWidth,
    barColor,
    bgColor,
    cellHeight,
    cellWidth,
    formattedBenchmarkValue,
    hideBenchmark,
    barCorner,
    ESGAssessment
  })

  return (
    <TableCell id={id} align="right" className={classes.BarCompStyle}>
      <div className={classes.innerDivStyle}></div>
      <div
        className={classes.benchmarkStyle}
        style={{ display: hideBenchmarkFlag }}
      ></div>
    </TableCell>
  )
}

TableCellBar.propTypes = {
  /** ID for table cell bar */
  id: PropTypes.string,
  /** barValue used to identify the width of bar */
  barValue: PropTypes.any,
  /** bgColor to set the TableCell backgroundColor  */
  bgColor: PropTypes.any,
  /** cellHeight to set the height of TableCell */
  cellHeight: PropTypes.number,
  /** cellWidth to set the width of TableCell */
  cellWidth: PropTypes.number,
  /** color for bar */
  barColor: PropTypes.string,
  /** benchmark to be set */
  benchmarkValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default TableCellBar
