import React, { useContext, useState } from 'react'
import { Grid } from '@material-ui/core'
import textTheme from 'theme/theme'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON } from 'constant/constants'
import { IconPaperPlane, IconRedirect, PrimaryButton } from 'components'
import { useStyles } from './styles'
import Error from 'utils/error'
import LoadingMask from 'utils/loadingMask'
import numberData from 'utils/numberFormat'
import ReportingMethologiesModal from './ReportingMethologiesModal'

const ReportingRequestAssessment = ({
  disableRequestAssessment = false,
  disableViewAssessmentStatus = false,
  remainingAssessments = 0,
  reloadComponent = {},
  onRequestAssessment = {},
  onViewAssessmentStatus = {}
}) => {
  const classes = useStyles()
  const [reportingServiceState] =
    useContext(GlobalContext).globalReportingService

  const [showMethodologyModal, setShowMethodologyModal] = useState(false)

  const viewMethodologies = () => {
    setShowMethodologyModal(true)
  }

  return (
    <>
      <Grid item xs={12}>
        {reportingServiceState.assessmentLimit?.isLoading ? (
          <LoadingMask />
        ) : reportingServiceState.assessmentLimit?.dataStatus ===
          PAGE_CON.ERROR ? (
          <Error
            id={'ESGError'}
            dataStatus={PAGE_CON.ERROR}
            onErrorClick={reloadComponent}
            errorOccuredOn={PAGE_CON.ON_DEMAND_ASMNT_LIMIT}
          />
        ) : (
          <div className={classes.title}>
            {numberData(remainingAssessments, { isCommaSeparated: true })}
            {Number(remainingAssessments) > 1
              ? ' Assessments '
              : ' Assessment '}
            remaining
          </div>
        )}
        <PrimaryButton
          id="button-report-test-id-1"
          buttonText={'Request Assessment'}
          buttonClass="buttonAltPrimary"
          buttonIcon={<IconPaperPlane fillcolor="white" viewBox="0 0 15 15" />}
          buttonIconPosition="top"
          buttonHeight={60}
          buttonDisabled={disableRequestAssessment}
          sync={true}
          onClick={onRequestAssessment}
          buttonWidth="100%"
          buttonTextClass="reportingTextStyle"
        />
        <div className={classes.prevButtonContainer}>
          <PrimaryButton
            id="button-prev-status-test-id-1"
            buttonText="View Assessment Status"
            buttonClass="buttonSlim"
            buttonDisabled={disableViewAssessmentStatus}
            buttonIconPosition="end"
            buttonWidth="100%"
            sync={true}
            onClick={onViewAssessmentStatus}
          />
        </div>
        <div className={classes.methodologiesButtonContainer}>
          <PrimaryButton
            id="button-prev-methodologies-test-id-1"
            buttonText="Methodologies"
            sync={true}
            buttonIcon={
              <IconRedirect
                className={classes.redirectIcon}
                fillcolor={textTheme.palette.sys_trad.text2.main}
              />
            }
            buttonClass="buttonSlim"
            buttonIconPosition="end"
            buttonWidth="100%"
            onClick={viewMethodologies}
          />
        </div>
      </Grid>
      <ReportingMethologiesModal
        showModal={showMethodologyModal}
        handleModalVisivility={setShowMethodologyModal}
      />
    </>
  )
}

export default ReportingRequestAssessment
