import React from 'react'
import * as S from '../dashboardComponents'
import { useStyles } from '../dashboardComponents/styles'
import { BigModal, ChicletBox, PrimaryLink } from 'components'
import PropTypes from 'prop-types'
import { getMonthDayYear } from 'utils/Date'

const PortfolioMonitoringModal = ({
  closeModalAction,
  selectedControversy,
  isIssuer
}) => {
  const {
    controversy_title,
    controversy_events,
    severity,
    controversy_description,
    sources
  } = selectedControversy
  const classes = useStyles()
  const formatText = (text, key = 'modal-paragraph') =>
    text?.split('\\r\\n').map((e, idx) => <p key={`${key}-${idx}`}>{e}</p>)
  const openControversyLink = () => {
    window.open('https://veconnect.vigeo-eiris.com/', '_blank')
  }
  const openControversyEmail = () => {
    window.location.href = 'mailto:veconnect@vigeo-eiris.com'
  }
  return (
    <BigModal
      title={
        <>
          <S.ModalTitle>ESG Incidents</S.ModalTitle>
          <S.ModalSubTitle>{controversy_title}</S.ModalSubTitle>
          <S.ModalDate>{getMonthDayYear(controversy_events)}</S.ModalDate>
        </>
      }
      closeAction={closeModalAction}
    >
      <>
        <S.ModalContentParagraph>
          The severity of this event is considered{' '}
          <ChicletBox type={severity.toLowerCase()}>{severity}</ChicletBox>{' '}
          based on an analysis of its impact on the company and stakeholders.
        </S.ModalContentParagraph>
        <S.ModalContentParagraphContent>
          <S.ModalContentContainer className="list">
            {formatText(controversy_description, 'modal-description')}
          </S.ModalContentContainer>
        </S.ModalContentParagraphContent>
        <S.ModalContentContainer className="footer">
          {formatText(sources, 'modal-sources')}
        </S.ModalContentContainer>
        <S.ModalContentParagraph className="link"></S.ModalContentParagraph>
        {isIssuer && (
          <>
            <S.ModalContentParagraph className={classes.contact}>
              To respond to an allegation or controversy, please go to:{' '}
              <PrimaryLink
                id="link-link-test-id-1"
                linkText={'https://veconnect.vigeo-eiris.com'}
                onClick={openControversyLink}
                linkClass="linkIssuerPDF"
                linkColorChange={true}
              />
              .
            </S.ModalContentParagraph>
            <S.ModalContentParagraph className={classes.contact}>
              Please contact{' '}
              <PrimaryLink
                id="link-link-test-id-2"
                linkText={'veconnect@vigeo-eiris.com'}
                onClick={openControversyEmail}
                linkClass="linkIssuerPDF"
                linkColorChange={true}
              />{' '}
              for any log-in queries.
            </S.ModalContentParagraph>
          </>
        )}
      </>
    </BigModal>
  )
}

PortfolioMonitoringModal.propTypes = {
  closeModalAction: PropTypes.func,
  selectedControversy: PropTypes.object
}

export default PortfolioMonitoringModal
