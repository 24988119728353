import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconUpload = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.406 1.342C5.40548 0.480135 6.68024 0.00413852 8 0C10.69 0 12.923 2 13.166 4.579C14.758 4.804 16 6.137 16 7.773C16 9.569 14.502 11 12.687 11H10C9.86739 11 9.74021 10.9473 9.64645 10.8536C9.55268 10.7598 9.5 10.6326 9.5 10.5C9.5 10.3674 9.55268 10.2402 9.64645 10.1464C9.74021 10.0527 9.86739 10 10 10H12.688C13.979 10 15 8.988 15 7.773C15 6.557 13.98 5.545 12.687 5.545H12.187V5.045C12.188 2.825 10.328 1 8 1C6.91988 1.00431 5.87684 1.39443 5.059 2.1C4.302 2.752 3.906 3.538 3.906 4.155V4.603L3.461 4.652C2.064 4.805 1 5.952 1 7.318C1 8.785 2.23 10 3.781 10H6C6.13261 10 6.25979 10.0527 6.35355 10.1464C6.44732 10.2402 6.5 10.3674 6.5 10.5C6.5 10.6326 6.44732 10.7598 6.35355 10.8536C6.25979 10.9473 6.13261 11 6 11H3.781C1.708 11 0 9.366 0 7.318C0 5.555 1.266 4.095 2.942 3.725C3.085 2.862 3.64 2.002 4.406 1.342Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.6459 4.14604C7.69234 4.09948 7.74752 4.06253 7.80827 4.03733C7.86901 4.01212 7.93413 3.99915 7.9999 3.99915C8.06567 3.99915 8.13079 4.01212 8.19153 4.03733C8.25228 4.06253 8.30745 4.09948 8.3539 4.14604L11.3539 7.14604C11.4478 7.23993 11.5005 7.36726 11.5005 7.50004C11.5005 7.63282 11.4478 7.76015 11.3539 7.85404C11.26 7.94793 11.1327 8.00067 10.9999 8.00067C10.8671 8.00067 10.7398 7.94793 10.6459 7.85404L8.4999 5.70704V14.5C8.4999 14.6326 8.44722 14.7598 8.35345 14.8536C8.25969 14.9474 8.13251 15 7.9999 15C7.86729 15 7.74011 14.9474 7.64635 14.8536C7.55258 14.7598 7.4999 14.6326 7.4999 14.5V5.70704L5.3539 7.85404C5.26001 7.94793 5.13268 8.00067 4.9999 8.00067C4.86712 8.00067 4.73979 7.94793 4.6459 7.85404C4.55201 7.76015 4.49927 7.63282 4.49927 7.50004C4.49927 7.36726 4.55201 7.23993 4.6459 7.14604L7.6459 4.14604Z"
      fill="#26415E"
    />
  </SvgIcon>
)
IconUpload.displayName = 'IconUpload'
IconUpload.muiName = 'SvgIcon'

export default IconUpload
