import { ChicletBox, PrimaryButton, ProgressBar } from 'components'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import { useContext } from 'react'
import { useStyles } from './styles'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import themeColorPalette from 'theme/theme'

const BatchCard = (props) => {
  const classes = useStyles(props)
  const { data = { status: [] } } = props

  const [, onDemandAsmntDispatch] =
    useContext(GlobalContext).onDemandAsmntService

  const handleRequestDetail = (data) => {
    const { location, sector, score_range } = data || ''
    const reqDetailData = {
      entities: data?.details || [],
      info: {
        location,
        sector,
        scoreRange: score_range
      }
    }
    onDemandAsmntDispatch({
      type: REDUCER_TYPE.ON_DEMAND_ASMNT_REQUEST_DETAIL_DATA,
      sectionName: REDUCER_STATE.ON_DEMAND_ASMNT_REQUEST_DETAIL_INFO,
      data: reqDetailData
    })

    onDemandAsmntDispatch({
      type: REDUCER_TYPE.ON_DEMAND_ASMNT_SET_STEP,
      step: PAGE_CON.ON_DEMAND_ASMNT_REQUEST_DETAIL,
      prevStep: PAGE_CON.ON_DEMAND_ASMNT_BATCH_REVIEW,
      reqDetailReadOnly: true
    })
  }

  const getWidth = (value) => {
    const pct = (value * 100) / data?.total
    return `${pct.toFixed(1)}%`
  }

  const statusColor = [
    {
      class: classes.batchCardPendingActivation,
      title: 'Pending Activation',
      progressColour: themeColorPalette.palette.genrang_b.five.main
    },
    {
      class: classes.batchCardCancelled,
      title: 'Cancelled',
      progressColour: '#F77F74'
    },
    {
      class: classes.batchCardInProgress,
      title: 'In Progress',
      progressColour: themeColorPalette.palette.sys_trad.focus.main
    },
    {
      class: classes.batchCardCompleted,
      title: 'Completed',
      progressColour: themeColorPalette.palette.sys_trad.successlight.main
    }
  ]

  const progressValue = data?.status?.filter((status) => status.value !== 0)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} className={classes.batchCardContainer}>
        <span className={classes.batchCardDateStyle}>
          Created on: {data.create_date}
        </span>
        <Grid item xs={12} className={classes.batchCardAssessmentInfo}>
          <span className={classes.batchCardSubHeader}>
            Sent to{' '}
            <span className={classes.batchCardTextStrong}>
              {`${data?.total} ${data?.total > 1 ? 'Companies' : 'Company'}`}
            </span>
          </span>
          <span className={classes.batchCardSubHeader}>
            Predicted ESG Score:{' '}
            <span className={classes.batchCardTextStrong}>
              {`${data.score_range.min}-${data.score_range.max}`}
            </span>
          </span>
          <span className={classes.batchCardSubHeader}>
            Location:{' '}
            <span className={classes.batchCardTextStrong}>
              {data.location === 'all' ? 'All' : data.location}
            </span>
          </span>
          <span className={classes.batchCardSubHeader}>
            Sector:{' '}
            <span className={classes.batchCardTextStrong}>
              {data.sector === 'all' ? 'All' : data.sector}
            </span>
          </span>
        </Grid>
        <Grid container className={classes.batchCardLowSection}>
          <div>
            <Grid item xs={12} className={classes.batchCardChicletAssessment}>
              {statusColor.map((colors, index) => (
                <span key={`colors-${index}`}>
                  <ChicletBox
                    type={clsx(colors.class, classes.batchCardBoxSize)}
                  />
                  <span
                    className={classes.batchCardSubHeader}
                    style={{ padding: 0 }}
                  >
                    {
                      data?.status?.filter((s) => s.label === colors.title)[0]
                        .value
                    }{' '}
                    {colors.title}
                  </span>
                </span>
              ))}
            </Grid>
            <Grid item className={classes.batchCardProgressBar} xs={12}>
              {progressValue?.map((status, index) => (
                <ProgressBar
                  key={`progressbar-${index}`}
                  progressColour={
                    statusColor.find(
                      (color) =>
                        status.label.toUpperCase() === color.title.toUpperCase()
                    ).progressColour
                  }
                  height={15}
                  width={getWidth(status.value)}
                  customStyle={{
                    borderRadius: '0px',
                    borderTopLeftRadius: index !== 0 ? '0px' : '4px',
                    borderBottomLeftRadius: index !== 0 ? '0px' : '4px',
                    borderTopRightRadius:
                      index !== progressValue.length - 1 ? '0px' : '4px',
                    borderBottomRightRadius:
                      index !== progressValue.length - 1 ? '0px' : '4px'
                  }}
                  value={100}
                />
              ))}
            </Grid>
          </div>
          <div style={{ marginTop: 8 }}>
            <PrimaryButton
              id="button-button-test-id-7"
              buttonText="View Request Details"
              buttonClass="buttonSlim"
              buttonHeight={34}
              onClick={() => handleRequestDetail(data)}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BatchCard
