import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Map, Panel, Section, Table } from 'components'
import * as S from './styles'
import { AnalysisServices } from 'services'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import Constant from 'constant'
import LoadingMask from 'utils/loadingMask'
import { getPromise } from 'utils/helper'
import Error from 'utils/error'
import numberData from 'utils/numberFormat'

const GeographicRisk = ({ sectorTreeMapHasError }) => {
  const context = useContext(GlobalContext)

  const [, entityHeaderDispatch] = useContext(GlobalContext)
    ?.globalEntityHeader || ['', '']
  const [esgAssessmentsState, esgAssessmentsDispatch] =
    context.globalEsgAssessments
  const [filterState] = context.globalFilter

  const [openPanel, setOpenPanel] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState('')

  const isFilterStateAvailable =
    filterState.filterData.regionsList.length > 0 ||
    filterState.filterData.sectorsList.length > 0

  const entityListRanges = {
    'A1-A3': ['a1.esg', 'a2.esg', 'a3.esg'],
    'B1-B3': ['b1.esg', 'b2.esg', 'b3.esg'],
    'C1-E3': ['c1.esg', 'c2.esg', 'c3.esg'],
    E: ['e.esg']
  }

  const getPortfolioName = (portfolioKey) =>
    filterState?.filterData?.portfolioList.find((x) => x.key === portfolioKey)
      ?.displayText || ''

  let apiCallId = esgAssessmentsState.apiCallId

  const { benchmark: selectedBenchmark } = filterState.globalPortfolioFilter

  const getEsgMapData = useCallback(async () => {
    const { portfolio, regions, sectors, asOfDate, benchmark } =
      filterState.globalPortfolioFilter
    if (portfolio) {
      esgAssessmentsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName: REDUCER_STATE.ESG_CONTROVERIES_MAP
      })
      try {
        const data = isFilterStateAvailable
          ? await AnalysisServices.getEsgMapData({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark
            })
          : []
        esgAssessmentsDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          sectionName: REDUCER_STATE.ESG_CONTROVERIES_MAP,
          data: data,
          apiCallId
        })
      } catch (error) {
        esgAssessmentsDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.ESG_CONTROVERIES_MAP,
          apiCallId
        })
      }
    }
  }, [apiCallId, esgAssessmentsDispatch, filterState.globalPortfolioFilter])

  const getEsgEntityListData = useCallback(
    async (country) => {
      const { portfolio, regions, sectors, asOfDate } =
        filterState.globalPortfolioFilter
      if (portfolio) {
        esgAssessmentsDispatch({
          type: REDUCER_TYPE.LOAD_DATA_START,
          sectionName: REDUCER_STATE.ESG_ENTITY_LIST
        })
        try {
          const data = { coverageData: {}, entityData: {} }
          if (isFilterStateAvailable) {
            const [coverage, entityData] = await Promise.all([
              getPromise(AnalysisServices.getEsgCoverage, {
                portfolio,
                regions,
                sectors,
                asOfDate
              }),
              getPromise(AnalysisServices.getEsgEntityListData, {
                portfolio,
                regions,
                sectors,
                asOfDate,
                country_code: country
              })
            ])
            data.coverageData = coverage
            data.entityData = entityData.data
          }

          esgAssessmentsDispatch({
            type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
            sectionName: REDUCER_STATE.ESG_ENTITY_LIST,
            data: data,
            apiCallId
          })
        } catch (error) {
          esgAssessmentsDispatch({
            type: REDUCER_TYPE.LOAD_DATA_ERROR,
            sectionName: REDUCER_STATE.ESG_ENTITY_LIST,
            apiCallId
          })
        }
      }
    },
    [apiCallId, esgAssessmentsDispatch, filterState.globalPortfolioFilter]
  )

  useEffect(() => {
    if (selectedCountry) {
      getEsgEntityListData(selectedCountry)
    }
  }, [getEsgEntityListData, selectedCountry])

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      getEsgMapData()
    }
  }, [filterState.actionData])

  const mapRange = Constant.Scores.scores('esg').map(({ min, max, color }) => ({
    color,
    from: min,
    to: max
  }))

  const mapData = useMemo(
    () => esgAssessmentsState.esgControversiesMap.data || [],
    [esgAssessmentsState.esgControversiesMap.data]
  )

  const entityData = esgAssessmentsState.esgEntityList.data.entityData || []
  const coverageData = esgAssessmentsState.esgEntityList.data.coverageData || {}

  const handleCountrySelected = (key, fromMap = false) => {
    if (key) {
      let countrySelected = key
      if (fromMap) {
        countrySelected = mapData.find(
          ({ country_code }) => country_code === key
        )?.country_code
      }
      setOpenPanel(true)
      setSelectedCountry(countrySelected)
    }
  }

  const tableItems = mapData.map(
    ({ country_name, holdings, score, country_code, benchmark_score }) => {
      const benchmarkText = `${score} vs ${benchmark_score || '-'}`
      return selectedBenchmark
        ? [
            <S.TableCell
              key='country_name'
              title={country_name}
              onClick={() => handleCountrySelected(country_code)}
            >
              {country_name}
            </S.TableCell>,
            <S.TableCell
              key='holdings'
              title={`${holdings}`}
              onClick={() => handleCountrySelected(country_code)}
            >{`${holdings}`}</S.TableCell>,
            <S.TableCell
              key='benchmarkText'
              title={benchmarkText}
              onClick={() => handleCountrySelected(country_code)}
            >
              {benchmarkText}
            </S.TableCell>
          ]
        : [
            <S.TableCell
              key="country_name"
              title={country_name}
              onClick={() => handleCountrySelected(country_code)}
            >
              {country_name}
            </S.TableCell>,
            <S.TableCell
              key='holdings'
              title={`${holdings}`}
              onClick={() => handleCountrySelected(country_code)}
            >{`${holdings}`}</S.TableCell>,
            <S.TableCell
              key="benchmarkText"
              title={`${score}`}
              onClick={() => handleCountrySelected(country_code)}
            >{`${score}`}</S.TableCell>
          ]
    }
  )

  const tableHeaders = selectedBenchmark
    ? [
        {
          label: 'Country',
          columnStyles: { border: 'none', fontWeight: 'bolder' }
        },
        { label: '% Investment', columnStyles: { border: 'none' } },
        {
          label: 'Avg. vs. Benchmark',
          columnStyles: { border: 'none', textAlign: 'right' }
        }
      ]
    : [
        {
          label: 'Country',
          columnStyles: { border: 'none', fontWeight: 'bolder' }
        },
        { label: '% Investment', columnStyles: { border: 'none' } },
        {
          label: 'ESG Score',
          columnStyles: { border: 'none', textAlign: 'right' }
        }
      ]

  const entityTableHeaders = [
    { label: 'Entity', columnStyles: { border: 'none', width: '40%' } },
    {
      label: '% Investment',
      columnStyles: { border: 'none', textAlign: 'right', width: '20%' }
    },
    { label: 'ESG Score', columnStyles: { border: 'none', width: '20%' } },
    { label: 'Sectors', columnStyles: { border: 'none', width: '20%' } }
  ]

  const handleClosePanel = () => {
    setOpenPanel(false)
    setSelectedCountry('')
  }

  const selectedSector =
    filterState.globalPortfolioFilter.sectors === ''
      ? 'All Sectors'
      : filterState.globalPortfolioFilter.sectors

  const entityListDataRanged =
    entityData && entityData.length
      ? Object.keys(entityListRanges).map((key) => {
          return {
            key,
            data: entityData.filter((element) =>
              entityListRanges[key].includes(element.score)
            )
          }
        })
      : []

  const openEntityPage = (orbis_id) => {
    entityHeaderDispatch({
      type: REDUCER_TYPE.SET_ORBIB_ID,
      selectedOrbisId: orbis_id
    })
  }

  const sortingCriteria = Object.values(entityListRanges)
    .flat()
    .map((e, index) => ({ score: e, index }))

  const formatEntityTableItems = (data) =>
    data
      .sort((a, b) => {
        const aScoreIndex = sortingCriteria.find(
          ({ score }) => score === a.score
        ).index
        const bScoreIndex = sortingCriteria.find(
          ({ score }) => score === b.score
        ).index
        return aScoreIndex - bScoreIndex
      })
      .map(({ company_name, holdings, score, sector, bvd9_number }) => {
        return [
          <S.TableCell
            key='company_name'
            title={company_name}
            underlined={true}
            onClick={() => openEntityPage(bvd9_number)}
          >
            {company_name}
          </S.TableCell>,
          <S.TableCell key='holdings'>{holdings}</S.TableCell>,
          <S.TableCell key='score'>{score}</S.TableCell>,
          <S.TableCell key='sector'>{sector}</S.TableCell>
        ]
      })
      .slice(0, 20)

  useEffect(() => {
    if (selectedCountry) {
      const findIndex = mapData.findIndex(
        ({ country_name }) => country_name === selectedCountry
      )
      if (findIndex !== -1) {
        const target = document.querySelector(
          `#ESGTable-tableCell-${findIndex}-0`
        )
        const parent = document.querySelector('#ESGTable')
        const scrollTo = target.offsetTop
        parent.parentNode.parentNode.scrollTo({
          top: scrollTo,
          behavior: 'smooth'
        })
      }
    }
  }, [mapData, selectedCountry])

  const selectedCountryName = selectedCountry
    ? mapData.find(({ country_code }) => country_code === selectedCountry)
        .country_name
    : ''

  const selectedCountryInvestment = selectedCountry
    ? mapData.find(({ country_code }) => country_code === selectedCountry)
        .holdings
    : ''

  const companiesCoverage = coverageData?.portfolio_coverage?.companies || '-/-'
  const investmentCoverage =
    coverageData?.portfolio_coverage?.investment === undefined
      ? '-'
      : numberData(selectedCountryInvestment, {
          isDecimal: true,
          isPercentage: true,
          keepRawValue: true
        })

  const entitiesLength = entityListDataRanged.reduce(
    (prev, acc) => prev + acc.data.length,
    0
  )

  const reloadComponent = (errorOccuredOn) => {
    switch (errorOccuredOn) {
      case PAGE_CON.ESG_RISK_DISTRIBUTION:
        getEsgMapData()
        break
      case PAGE_CON.ESG_ENTITY_LIST:
        getEsgEntityListData(selectedCountry)
        break
      default:
        break
    }
  }

  const panelContent = () => {
    if (esgAssessmentsState.esgEntityList.isLoading) {
      return <LoadingMask />
    }

    if (
      entitiesLength === 0 &&
      esgAssessmentsState.esgEntityList.dataStatus === PAGE_CON.SUCCESS
    ) {
      return (
        <Error
          id={'ESGGeographicRiskEntityError'}
          hideErrorActionButton={true}
          errorText="There is no data on which to base our calculations"
        />
      )
    }

    if (esgAssessmentsState.esgEntityList.dataStatus !== PAGE_CON.SUCCESS) {
      return (
        <Error
          id={'ESGGeographicRiskEntityError'}
          dataStatus={esgAssessmentsState.esgEntityList.dataStatus}
          onErrorClick={reloadComponent}
          errorOccuredOn={PAGE_CON.ESG_ENTITY_LIST}
        />
      )
    }

    return entityListDataRanged
      .filter(({ data }) => data.length)
      .map(({ key, data }, index) => (
        <S.EntityContainer key={`${index}-${key}`}>
          <S.EntityScoreTitle>{key}</S.EntityScoreTitle>
          <Table
            headers={entityTableHeaders}
            data={formatEntityTableItems(data)}
          />
        </S.EntityContainer>
      ))
  }

  const hasError =
    (mapData.length === 0 &&
      esgAssessmentsState.esgControversiesMap.dataStatus ===
        PAGE_CON.SUCCESS) ||
    esgAssessmentsState.esgControversiesMap.dataStatus !== PAGE_CON.SUCCESS

  return sectorTreeMapHasError && hasError ? (
    <></>
  ) : (
    <>
      <Panel
        key={'riskDistributionPanel-test-id-1'}
        openPanel={openPanel}
        titlePadding="12px"
        onPanelClose={handleClosePanel}
        topNum={0}
        title={`${selectedSector}, ${selectedCountryName}`}
        subtitle={
          esgAssessmentsState.esgEntityList.isLoading
            ? ''
            : `${companiesCoverage} companies/ ${investmentCoverage} Investment`
        }
        panelContentComponent={
          <S.PanelContainer>{panelContent()}</S.PanelContainer>
        }
        height={'0'}
      />
      <S.Container hasError={hasError}>
        {esgAssessmentsState.esgControversiesMap.isLoading ? (
          <LoadingMask />
        ) : mapData.length === 0 &&
          esgAssessmentsState.esgControversiesMap.dataStatus ===
            PAGE_CON.SUCCESS ? (
          <Error
            id={'ESGGeographicRiskError'}
            errorText="There is no data on which to base our calculations"
            errorOccuredOn={PAGE_CON.ESG_RISK_DISTRIBUTION}
            onErrorClick={reloadComponent}
            dataStatus={esgAssessmentsState.esgControversiesMap.dataStatus}
          />
        ) : esgAssessmentsState.esgControversiesMap.dataStatus !==
          PAGE_CON.SUCCESS ? (
          <Error
            id={'ESGGeographicRiskError'}
            dataStatus={
              isFilterStateAvailable
                ? esgAssessmentsState.esgControversiesMap.dataStatus
                : PAGE_CON.SUCCESS
            }
            errorOccuredOn={PAGE_CON.ESG_RISK_DISTRIBUTION}
            onErrorClick={reloadComponent}
          />
        ) : (
          <Section
            noMargin={true}
            sidebar={
              <S.TableContainer>
                <Table headers={tableHeaders} data={tableItems} id="ESGTable" />
              </S.TableContainer>
            }
          >
            <S.MapContainer>
              <Map
                height="530px"
                showLegend={false}
                tooltip="esg"
                countries={mapData}
                mapDataClasses={mapRange}
                researchLineSelectedUnserscore="esg"
                portfolioName={getPortfolioName(
                  filterState.globalPortfolioFilter.portfolio
                )}
                onSeriesClickAction={(key) => handleCountrySelected(key, true)}
              />
            </S.MapContainer>
          </Section>
        )}
      </S.Container>
    </>
  )
}

export default GeographicRisk
