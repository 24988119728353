/* eslint-disable react/no-unknown-property */
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let ESGLogo = (props) => (
  <SvgIcon {...props}>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 355.69 40.74"
    >
      <defs></defs>
      <line
        class="cls-1"
        fill="none"
        stroke="#000"
        stroke-miterlimit="10"
        stroke-width="0.5px"
        x1="169.61"
        x2="169.61"
        y2="40.74"
      />
      <path
        fill="none"
        stroke="#000"
        stroke-miterlimit="10"
        stroke-width="0.5px"
        d="M248.59,121.27h9.59l-.72,1.9h-6.64V130h6.55v1.9h-6.55v7.3h7.54v1.9h-9.77Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="none"
        stroke="#000"
        stroke-miterlimit="10"
        stroke-width="0.5px"
        d="M262.64,138.18a7.93,7.93,0,0,0,4.32,1.33c2.14,0,4-.88,4-3.23,0-4.76-8.74-4.19-8.74-10.13,0-2.8,2.14-5,5.85-5a10.25,10.25,0,0,1,4.31.9v2.08a8.82,8.82,0,0,0-4.25-1.08c-1.78,0-3.59.78-3.59,2.92,0,4.49,8.75,4,8.75,10.19,0,3.32-2.57,5.31-6.37,5.31a9.08,9.08,0,0,1-5.21-1.51Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="none"
        stroke="#000"
        stroke-miterlimit="10"
        stroke-width="0.5px"
        d="M292.86,139.93a14.7,14.7,0,0,1-6.52,1.51c-5.91,0-9.8-4.13-9.8-9.95,0-6.09,4.1-10.37,10.25-10.37a14.58,14.58,0,0,1,5.43,1v2.11a11.51,11.51,0,0,0-5.3-1.26c-5.13,0-8,3.4-8,8.23,0,5.09,3,8.26,7.72,8.26a9.56,9.56,0,0,0,4-.81v-6.94h2.21Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="none"
        stroke="#000"
        stroke-miterlimit="10"
        stroke-width="0.5px"
        d="M304,138.18a7.89,7.89,0,0,0,4.31,1.33c2.14,0,4-.88,4-3.23,0-4.76-8.74-4.19-8.74-10.13,0-2.8,2.14-5,5.85-5a10.25,10.25,0,0,1,4.31.9v2.08a8.85,8.85,0,0,0-4.25-1.08c-1.78,0-3.59.78-3.59,2.92,0,4.49,8.74,4,8.74,10.19,0,3.32-2.56,5.31-6.36,5.31a9.08,9.08,0,0,1-5.21-1.51Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="none"
        stroke="#000"
        stroke-miterlimit="10"
        stroke-width="0.5px"
        d="M317.46,134c0-4.19,2.56-7.48,6.6-7.48s6.57,3.29,6.57,7.48-2.59,7.45-6.6,7.45S317.46,138.15,317.46,134Zm10.85,0c0-3.28-1.65-5.58-4.25-5.58s-4.31,2.3-4.31,5.58,1.66,5.55,4.28,5.55S328.31,137.25,328.31,134Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="none"
        stroke="#000"
        stroke-miterlimit="10"
        stroke-width="0.5px"
        d="M333.89,119.79h2.2v18.06c0,1.18.37,1.51,1.12,1.51a2.84,2.84,0,0,0,.87-.21l.49,1.75a5,5,0,0,1-2,.42,2.47,2.47,0,0,1-2.69-2.78Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="none"
        stroke="#000"
        stroke-miterlimit="10"
        stroke-width="0.5px"
        d="M341.43,138.79a8.24,8.24,0,0,1-.36-2.6v-9.31h2.23v8.77a6.71,6.71,0,0,0,.24,2.23,2.21,2.21,0,0,0,2.35,1.6,5.42,5.42,0,0,0,4-2V126.88h2.2v9.43a22,22,0,0,0,.31,4.83h-2l-.43-1.66a7.17,7.17,0,0,1-4.73,2A3.86,3.86,0,0,1,341.43,138.79Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="none"
        stroke="#000"
        stroke-miterlimit="10"
        stroke-width="0.5px"
        d="M357.41,138.12v-9.43H355v-1.81h2.41v-3.23l2.2-.72v3.95h4.25l-.66,1.81h-3.59v9.07c0,1.24.51,1.78,1.48,1.78a4.2,4.2,0,0,0,2.32-.78l.78,1.56a6.2,6.2,0,0,1-3.58,1.12C358.56,141.44,357.41,140.23,357.41,138.12Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="none"
        stroke="#000"
        stroke-miterlimit="10"
        stroke-width="0.5px"
        d="M366.7,122.17a1.49,1.49,0,1,1,1.47,1.48A1.42,1.42,0,0,1,366.7,122.17Zm.39,4.71h2.2v14.26h-2.2Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="none"
        stroke="#000"
        stroke-miterlimit="10"
        stroke-width="0.5px"
        d="M372.79,134c0-4.19,2.56-7.48,6.6-7.48S386,129.8,386,134s-2.59,7.45-6.6,7.45S372.79,138.15,372.79,134Zm10.85,0c0-3.28-1.66-5.58-4.25-5.58s-4.31,2.3-4.31,5.58,1.66,5.55,4.28,5.55S383.64,137.25,383.64,134Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="none"
        stroke="#000"
        stroke-miterlimit="10"
        stroke-width="0.5px"
        d="M389.1,126.88H391l.27,1.84a7.78,7.78,0,0,1,5-2.21c2.23,0,4.22,1.42,4.22,5v9.62h-2.2v-8.87c0-2.14-.55-3.77-2.57-3.77a7,7,0,0,0-4.37,2.14v10.5H389.1Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="none"
        stroke="#000"
        stroke-miterlimit="10"
        stroke-width="0.5px"
        d="M403.91,138.51a6.39,6.39,0,0,0,3.55,1.06c1.66,0,3.05-.66,3.05-2.29,0-3.11-6.67-2.11-6.67-6.85,0-2.17,1.66-3.92,4.83-3.92a10,10,0,0,1,3.53.67v2a8.31,8.31,0,0,0-3.44-.79c-1.36,0-2.74.46-2.74,2,0,3.17,6.72,2.08,6.72,6.91,0,2.83-2.35,4.19-5.28,4.19a8.07,8.07,0,0,1-4.28-1.21Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="#231f20"
        d="M94.1,140c-2.12-.22-2.08-1.41-2.22-3.67-.37-6.54-.41-14.93-.5-19.74,0-3.17.32-4,3.17-4.31l1-.09a1,1,0,0,0,0-1.13c-1.42.09-2.91.13-4,.13-.91,0-1.86,0-3.41-.13-.09,2.08-1.35,4.58-3.44,8.7l-4.27,8.67c-1.13,2.32-2.27,4.68-3.49,6.8h-.09c-1.1-2-2.09-4.08-3.13-6.2L68.42,118.5c-1.4-2.9-2.54-5.72-2.36-7.48-1.49.13-2.72.13-3.76.13s-2.22,0-3.58-.13a.82.82,0,0,0-.18,1.13l.72.09c2.76.45,3.14,1,3,3.9-.09,2.49-.28,6.35-.5,10.12-.27,4.49-.49,7-.77,10-.27,3.17-.91,3.5-2.81,3.72l-1,.13a.88.88,0,0,0,.09,1.14c1.55-.09,3.18-.13,4.62-.13,1.73,0,3.5,0,5,.13.32-.13.37-.86,0-1.14L65.3,140c-1.78-.13-2-.86-2-4.22,0-3.85.17-7.9.31-10.66s.28-5.27.45-8.08h.1c1,2.09,1.94,4.45,2.9,6.53l4.08,8.76c1,2.23,3.22,6.68,4,9.07a.5.5,0,0,0,.4.19.47.47,0,0,0,.45-.19c.82-2.08,2.87-6.34,4.9-10.34l4-7.94c.91-1.76,2.63-5.39,3-5.89h.09l.14,18.92c0,2.9,0,3.63-2.22,3.85l-1.31.13a.84.84,0,0,0,.09,1.14c1.85-.09,3.89-.13,5.48-.13s3.5,0,5.36.13a.85.85,0,0,0,.09-1.14Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="#231f20"
        d="M107.38,120.77c-6.58,0-11.2,4.63-11.2,10.66,0,5.72,4.17,10.3,11.2,10.3,6.39,0,11-4.45,11-10.57C118.4,125.62,114.68,120.77,107.38,120.77Zm1,19.59c-5.24.45-7.79-4.39-8.19-9.05-.55-6.37,2.83-8.89,6.08-9.17,5-.43,7.71,4.26,8.13,9.09C114.92,137.43,111.81,140.07,108.38,140.36Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="#231f20"
        d="M194.25,115.49c0-2.36-1.63-4.36-3.5-4.36a1.89,1.89,0,0,0-2.17,1.87,1.22,1.22,0,0,0,1.22,1.27,2.48,2.48,0,0,1,2.27,2.54c0,1.86-1.76,3.13-3.45,3.9a.68.68,0,0,0,.32,1C191.79,120.8,194.25,118.62,194.25,115.49Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="#231f20"
        d="M202,130.2l-2.81-1.45c-1.81-1-3-2-3-3.57s1-3.1,3.66-3.1,3.86,2.32,4.23,3.91a.83.83,0,0,0,1.19-.09,12,12,0,0,0-.65-4.45,23,23,0,0,0-4.23-.68c-4.56,0-7.12,2.31-7.12,5.63,0,2.54,1.87,4.31,4.05,5.43l2,1c2.59,1.32,3.18,2.82,3.18,4.27,0,2.08-1.49,3.27-3.81,3.27-3,0-5-3.22-5.4-4.86a.68.68,0,0,0-1.17.1,9.35,9.35,0,0,0,1,4.66,9.3,9.3,0,0,0,5.21,1.41c4.49,0,7.4-2.22,7.4-5.76A6.49,6.49,0,0,0,202,130.2Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="#231f20"
        d="M160.55,122.27a20.87,20.87,0,0,0-6.9-1c-3.67,0-6.21.08-8.76.26-.4.14-.45,1,0,1.14l1.35.18c1.05.13,1.1.9,1.1,3.85v9.63c0,3.12-.18,3.3-2.14,3.62l-.86.13c-.31.23-.27,1,.09,1.14,1.77-.09,3.19-.13,4.64-.13.68,0,1.54,0,2.43.09s2,.13,3,.13a15.26,15.26,0,0,0,6.85-1.41,9.62,9.62,0,0,0,5.49-8.75A8.88,8.88,0,0,0,160.55,122.27ZM154.66,140c-3.47,0-3.91-1.46-3.91-4.35V124.85c0-1,0-1.58.26-1.77a4.82,4.82,0,0,1,2.74-.49c5.12,0,9,2.9,9,8.48S160.09,140,154.66,140Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="#231f20"
        d="M186.29,121.28c-1.28.08-2.23.12-3.24.12s-2.07,0-3.43-.12a.65.65,0,0,0-.1,1.12l1,.14c1.1.14,1,.54.32,1.81L179,127.67c-.76,1.45-1.45,2.53-2.18,3.76-.67-1.18-1.36-2.41-2-3.76l-1.69-3.22c-.73-1.42-.58-1.68.41-1.91l.64-.14a.66.66,0,0,0,0-1.12c-1.45.08-2.49.12-3.85.12-1.64,0-2.69,0-4.18-.12-.36.12-.45.85-.14,1.12l1.18.23c1.18.23,1.64.64,2.59,2.45l3.31,6.13c1.14,2.08,1.27,2.44,1.27,3.86v1.71c0,2.68-.18,3-2.13,3.18l-1.13.13c-.33.23-.28,1,.09,1.14,2-.09,3.45-.13,4.91-.13s2.89,0,4.88.13c.37-.13.42-.91.1-1.14l-1.14-.13c-2-.22-2.14-.5-2.14-3.18v-2.45c0-1.31.1-1.76,1.74-4.35l2.81-4.54c1.4-2.26,1.63-2.54,3-2.81l1.09-.23A.71.71,0,0,0,186.29,121.28Z"
        transform="translate(-57.05 -109.4)"
      />
      <path
        fill="#231f20"
        d="M131.74,120.77c-6.57,0-11.2,4.63-11.2,10.66,0,5.72,4.17,10.3,11.2,10.3,6.39,0,11-4.45,11-10.57C142.77,125.62,139.05,120.77,131.74,120.77Zm1,19.59c-5.24.45-7.8-4.39-8.2-9.05-.54-6.37,2.83-8.89,6.09-9.17,5-.43,7.7,4.26,8.12,9.09C139.29,137.43,136.18,140.07,132.75,140.36Z"
        transform="translate(-57.05 -109.4)"
      />
    </svg>
  </SvgIcon>
)
ESGLogo.displayName = 'ESGLogo'
ESGLogo.muiName = 'SvgIcon'

export default ESGLogo
