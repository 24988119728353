import clsx from 'clsx'
import getColor from 'utils/color'
import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'
import Error from 'utils/error'
import { PrimaryLink } from 'components'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
import { GlobalContext } from 'context/GlobalStateProvider'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableNumberCell from '../tablecellformats/tablenumbercell/TableNumberCell'
import TableRow from '@material-ui/core/TableRow'
import textTheme from 'theme/theme'
import { ScoreQualityLevel } from 'views/globalComponent/ScoreQualityLevel'
import { bgColor, useStyles } from './styles'

function getCellTextColor(field, dataRow) {
  let color = textTheme.palette.sys_trad.text.main
  if (field.cellTextColor && field.cellTextColor.colorGroup) {
    let val = ''
    if (
      field.cellTextColor.colorDerivedByField &&
      field.cellTextColor.colorDerivedByField !== ''
    ) {
      val = dataRow[field.cellTextColor.colorDerivedByField]
    }
    color = getColor(field.cellTextColor.colorGroup, val)
  }

  return color
}

function getDegreeString(data) {
  if (data !== '') {
    data = data.toString() + '\xB0C'
  }
  return data
}

function getCellBgColor(field, dataRow) {
  let color = bgColor
  if (
    field.cellBackgroundColor &&
    field.cellBackgroundColor.colorGroup &&
    field.cellBackgroundColor.colorDerivedByField
  ) {
    color = getColor(
      field.cellBackgroundColor.colorGroup,
      dataRow[field.cellBackgroundColor.colorDerivedByField],
      field.cellBackgroundColor.greenshare
    )
  }

  return color
}

function getCellText(rowData, index, fieldName) {
  if (index === 1) {
    return rowData[fieldName]
  } else {
    let text = ''
    fieldName.forEach((item, index) => {
      text += index === 0 ? `${rowData[item]} / ` : rowData[item]
    })
    return text
  }
}
const isPredicted = (row) => {
  return row['score_quality']
    ? row['score_quality'] === PAGE_CON.PREDICTED
    : false
}
const getPredictedScoreClass = (isRowHighlightedNow, row, classes) => {
  return isRowHighlightedNow && isPredicted(row) ? classes.rowBackground : ''
}

const CompanyName = ({
  row,
  isRowHighlightedNow,
  showUnderline,
  data,
  cellWidth,
  cellTextColor,
  isTextClick,
  field,
  rowIndex,
  onCellClick,
  classes,
  showScoreQuality
}) => {
  return (
    <div
      className={clsx(classes.CompanyName, 'CompanyNameWrapper')}
      style={{ width: cellWidth, color: cellTextColor }}
    >
      {showScoreQuality ? (
        <ScoreQualityLevel level={row.score_quality} />
      ) : null}

      <span
        className={clsx(
          classes.CompanyName,
          'ellipsis',
          getPredictedScoreClass(isRowHighlightedNow, row, classes)
        )}
      >
        <span
          className={clsx(
            showUnderline ? classes.underLineStyle : '',
            classes.rowCellEllipse,
            getPredictedScoreClass(isRowHighlightedNow, row, classes)
          )}
          aria-hidden={true}
          onClick={() => {
            isTextClick() && onCellClick(field.onClick.clickType, rowIndex)
          }}
          title={data}
        >
          {data}
        </span>
      </span>
    </div>
  )
}

/**
 * @visibleName Regular Table
 */

const RegularTable = (props) => {
  const {
    id,
    columns,
    totalData,
    tableLength,
    tableEditable,
    tablePadding,
    sumRow,
    isApplyShadow,
    isSectorAllocation,
    headerLabel,
    onClickFn,
    handleRowClick,
    showPointer,
    heap_id,
    hideHeader,
    isRowHighlighted,
    showScoreQuality
  } = props

  const [, entityHeaderDispatch] = useContext(GlobalContext)
    ?.globalEntityHeader || ['', '']

  const tableEditableNow =
    typeof tableEditable === 'undefined' ? true : tableEditable
  const isRowHighlightedNow =
    typeof isRowHighlighted === 'undefined' ? false : isRowHighlighted

  const bgColor = tableEditableNow ? '' : 'transparent'

  const classes = useStyles({ bgColor, tablePadding, showPointer })

  let data = totalData
    ? tableLength
      ? totalData.slice(0, tableLength)
      : totalData
    : null
  const dataLength = data?.length || 0
  const [selectedID, setSelectedID] = useState(null)

  function rowSelectionToggle(rowIndex) {
    if (selectedID === rowIndex) {
      setSelectedID(null)
    } else {
      setSelectedID(rowIndex)
    }
  }

  function extractData(row, field) {
    return row[field.Name]
  }

  function onCellClick(clickType, index) {
    const selectedData = totalData[index]
    const selectedOrbisId =
      selectedData?.parent_orbis_id || selectedData?.orbis_id || ''
    switch (clickType) {
      case 'entityName':
        if (selectedOrbisId !== '') {
          entityHeaderDispatch({
            type: REDUCER_TYPE.SET_ORBIB_ID,
            selectedOrbisId
          })
        }
        break
      default:
        if (onClickFn) onClickFn(clickType, selectedData)
        break
    }
  }

  function getField(row, field) {
    // eslint-disable-next-line no-prototype-builtins
    if (row.hasOwnProperty(field.Name)) return field
    if ((field?.FallbackField?.Name || '') !== '') {
      return getField(row, field.FallbackField)
    } else {
      return field
    }
  }

  const shadowClass =
    isApplyShadow !== undefined
      ? isApplyShadow === false
        ? ''
        : classes.boxShadow
      : classes.boxShadow

  const onRowClick = (rowId, row) =>
    handleRowClick && handleRowClick(rowId, row)

  const getRowClassName = (row, rowIndex, selectedID) => {
    if (isRowHighlightedNow && !props.sumRow) {
      if (isPredicted(row)) {
        return classes.rowBackground
      } else return classes.noRowHighlight
    } else
      return clsx(
        selectedID === rowIndex ? classes.tableRowSelected : '',
        props.sumRow && rowIndex === data.length - 1 ? classes.sumRow : ''
      )
  }

  return (
    <span>
      {data && data.length > 0 ? (
        <TableContainer className={clsx(classes.tableContainer, shadowClass)}>
          <Table id={id} className={classes.table}>
            {!hideHeader && (
              <TableHead>
                <TableRow key="headrow1">
                  {columns &&
                    columns.length > 0 &&
                    columns.map((column, hIndex) => {
                      let colLabel = column.HideLabel
                        ? ''
                        : column.isLabelDynamic
                        ? headerLabel
                        : column.HeaderLabel
                      let colAlign = column.Alignment
                        ? column.Alignment
                        : 'left'
                      let columnSpan = column.DataField.length
                      return (
                        <TableCell
                          className={classes.headerCell}
                          align={colAlign}
                          colSpan={columnSpan}
                          key={`headrowcell${hIndex}`}
                          style={
                            colLabel === '% Investment'
                              ? { whiteSpace: 'nowrap' }
                              : {}
                          }
                        >
                          {colLabel}
                        </TableCell>
                      )
                    })}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {data &&
                data.map((row, rowIndex) => (
                  <TableRow
                    key={`bodyrow${rowIndex}`}
                    hover={props.sumRow ? false : true}
                    onClick={() => {
                      rowSelectionToggle(rowIndex)
                      onRowClick(rowIndex, row)
                    }}
                    selected={selectedID === rowIndex}
                    className={getRowClassName(row, rowIndex, selectedID)}
                  >
                    {columns &&
                      columns.length > 0 &&
                      columns.map((column, colIndex) =>
                        column.DataField.map((rowDataField, findex) => {
                          let field = getField(row, rowDataField)
                          let data = isSectorAllocation
                            ? getCellText(row, colIndex, field.Name)
                            : extractData(row, field)
                          let cellAlignment = field.Alignment
                            ? field.Alignment
                            : 'right'
                          let cellBackgroundColor =
                            field.cellBackgroundColor &&
                            field.cellBackgroundColor
                              ? getCellBgColor(field, row)
                              : bgColor
                          let cellTextColor = getCellTextColor(field, row)
                          let cellRadius = field.cellRadius
                            ? field.cellRadius
                            : 0
                          let cellWidth = field.width ? field.width : 'inherit'
                          let numberType = field.NumberType ? true : false
                          let cellBgColor = field.cellBackgroundColor
                            ? true
                            : false
                          let numberSpec = field.NumberType && field.NumberType
                          let charLength = field.charLength || 50
                          data =
                            field.isDegreeString && data !== 'No Info'
                              ? getDegreeString(data)
                              : data
                          let cellLtPadding =
                            field.LeftPadding === 0 ? field.LeftPadding : '10px'
                          let isSumRow =
                            props.sumRow && rowIndex === dataLength - 1
                              ? true
                              : false
                          const isTextClick = () => {
                            if (field?.onClick) {
                              return row['climate'] && row['climate'] === 'No'
                                ? false
                                : field?.onClick
                            }
                            return false
                          }
                          const showUnderline =
                            row['climate'] && row['climate'] === 'No'
                              ? false
                              : field?.onClick?.showUnderline

                          let unit = row['unit']

                          return data !== 0 && !data && !sumRow ? (
                            <TableCell
                              className={classes.rowCell}
                              align={`${cellAlignment}`}
                              key={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                              style={
                                field.noWrap
                                  ? { whiteSpace: 'nowrap', width: cellWidth }
                                  : { width: cellWidth }
                              }
                            >
                              {'-'}
                            </TableCell>
                          ) : numberType && !isSumRow ? (
                            <TableNumberCell
                              key={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                              rowdata={
                                data === 'No Info'
                                  ? data
                                  : typeof data === 'string' ||
                                    data instanceof String
                                  ? data
                                  : Number(data)
                              }
                              cellclass={classes.rowCell}
                              numberSpecs={numberSpec}
                              cellWidth={cellWidth}
                              unit={unit}
                            />
                          ) : numberType &&
                            isSumRow &&
                            numberSpec.isPercentage ? (
                            <TableNumberCell
                              key={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                              rowdata={data !== '' ? Number(data) : ''}
                              cellclass={classes.rowCell}
                              numberSpecs={numberSpec}
                              cellWidth={cellWidth}
                            />
                          ) : field.isEllipsis &&
                            field.isSemibold &&
                            !props.inPanel ? (
                            <TableCell
                              {...(field.isHeapCompany
                                ? { heap_entity: data, heap_id: heap_id }
                                : {})}
                              className={clsx(
                                classes.rowCellSemibold,
                                classes.textContainer
                              )}
                              align={`${cellAlignment}`}
                              key={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                            >
                              <CompanyName
                                {...{
                                  row,
                                  isRowHighlightedNow,
                                  showUnderline,
                                  data,
                                  cellWidth,
                                  cellTextColor,
                                  isTextClick,
                                  field,
                                  rowIndex,
                                  onCellClick,
                                  classes,
                                  showScoreQuality
                                }}
                              />
                            </TableCell>
                          ) : field.isBrownShareDate ? (
                            <TableCell
                              title={data}
                              className={clsx(
                                classes.rowCellBrownShare,
                                classes.rowCell
                              )}
                              align={`${cellAlignment}`}
                              key={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                              style={{
                                minWidth: cellWidth
                              }}
                            >
                              {data
                                ? data.length > charLength
                                  ? data.slice(0, charLength) + ' ...'
                                  : data
                                : ''}
                            </TableCell>
                          ) : field.isGreenShare ? (
                            <TableCell
                              title={data}
                              className={clsx(
                                classes.rowCellGreenShare,
                                classes.rowCell
                              )}
                              align={`${cellAlignment}`}
                              key={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                              style={{
                                minWidth: cellWidth
                              }}
                            >
                              {data
                                ? data.length > charLength
                                  ? data.slice(0, charLength) + ' ...'
                                  : data
                                : ''}
                            </TableCell>
                          ) : field.isIssuerSector ? (
                            <TableCell
                              title={data}
                              className={clsx(
                                classes.rowCellEllipse,
                                classes.rowCellIssuerSector
                              )}
                              align={`${cellAlignment}`}
                              key={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                              style={{
                                minWidth: cellWidth
                              }}
                            >
                              <PrimaryLink
                                linkText={
                                  data
                                    ? data.length > charLength
                                      ? data.slice(0, charLength) + ' ...'
                                      : data
                                    : ''
                                }
                                linkClass="linkDefault"
                              />
                            </TableCell>
                          ) : field.isEllipsis ? (
                            <TableCell
                              {...(field.isHeapCompany
                                ? { heap_entity: data, heap_id: heap_id }
                                : {})}
                              className={clsx(classes.rowCell)}
                              align={`${cellAlignment}`}
                              key={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                            >
                              <CompanyName
                                {...{
                                  row,
                                  isRowHighlightedNow,
                                  showUnderline,
                                  data,
                                  cellWidth,
                                  cellTextColor,
                                  isTextClick,
                                  field,
                                  rowIndex,
                                  onCellClick,
                                  classes,
                                  showScoreQuality
                                }}
                              />
                            </TableCell>
                          ) : cellBgColor ? (
                            <TableCell
                              className={
                                field.isBrownShareRange
                                  ? classes.rowLongCell
                                  : classes.rowCell
                              }
                              align={`${cellAlignment}`}
                              key={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                            >
                              <span
                                style={{
                                  backgroundColor: cellBackgroundColor,
                                  color: cellTextColor,
                                  minwidth: cellWidth,
                                  padding: '2px 4px',
                                  borderRadius: cellRadius,
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                {data && data}
                              </span>
                            </TableCell>
                          ) : (
                            <TableCell
                              className={classes.rowCell}
                              align={`${cellAlignment}`}
                              key={`bodyrowcell${rowIndex}${colIndex}${findex}`}
                              style={
                                field.noWrap || unit
                                  ? {
                                      whiteSpace: 'nowrap',
                                      width: cellWidth,
                                      color: cellTextColor
                                    }
                                  : {
                                      width: cellWidth,
                                      paddingLeft: cellLtPadding,
                                      color: cellTextColor
                                    }
                              }
                            >
                              <span
                                className={
                                  showUnderline ? classes.underLineStyle : ''
                                }
                                {...(isTextClick()
                                  ? {
                                      onClick: () => {
                                        onCellClick(
                                          field.onClick.clickType,
                                          rowIndex
                                        )
                                      }
                                    }
                                  : {})}
                              >
                                {unit ? data + '(' + unit + ')' : data}
                              </span>
                            </TableCell>
                          )
                        })
                      )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Error
          id={'regular-table-error'}
          dataStatus={PAGE_CON.SUCCESS}
          onUploadClick={() => props.onUploadClick()}
        />
      )}
    </span>
  )
}

RegularTable.propTypes = {
  /** Table id */
  id: PropTypes.string,
  /** Horizontal Scroll Bar option */
  horScroll: PropTypes.any,
  /** Number of rows to display in tbale */
  tableLength: PropTypes.number,
  /** Table selectable */
  tableEditable: PropTypes.bool,
  /** Column header and data field details */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      HeaderLabel: PropTypes.string,
      HideLabel: PropTypes.bool,
      isLabelDynamic: PropTypes.bool,
      Alignment: PropTypes.string,
      DataField: PropTypes.arrayOf(
        PropTypes.shape({
          Name: PropTypes.any,
          Alignment: PropTypes.string,
          LeftPadding: PropTypes.number,
          width: PropTypes.number,
          boldText: PropTypes.bool
        })
      )
    })
  ),

  /** Data to be dispalyed in table */
  totalData: PropTypes.any,
  /** table Padding if needed */
  tablePadding: PropTypes.string,
  /** Data is in the right panel */
  inPanel: PropTypes.bool,
  /** onUploadClick will have parent component function reference */
  onUploadClick: PropTypes.func,
  /** isSectorAllocation checks if it is for Issuer Landing Page */
  isSectorAllocation: PropTypes.bool,
  /** Header label for table column */
  headerLabel: PropTypes.string,
  /** call parent function on click of cell item */
  onClickFn: PropTypes.func,
  /** handleRowClick returns rowId */
  handleRowClick: PropTypes.func,
  /** show pointer cursor passes pack cursor property: i.e. 'pointer'  */
  showPointer: PropTypes.any,
  /** show or hide header column  */
  hideHeader: PropTypes.any
}

export default RegularTable
