import { makeStyles } from '@material-ui/core'
import { PAGE_CON } from 'constant/constants'
import textTheme from '../../../../theme/theme'

const drawerWidth = PAGE_CON.PAGE_LEFT_MARGIN

export const cardHeader = {
  ...textTheme.typography.p2,
  paddingBottom: '4px',
  paddingTop: '16px',
  lineHeight: '16px'
}
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  content: {
    flexGrow: 1
  },
  container: {
    marginLeft: drawerWidth - 20,
    paddingRight: 0,
    paddingLeft: '24px',
    marginTop: '22px'
  },
  shadowStyle: {
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.14)',
    borderRadius: '2px',
    marginBottom: '24px',
    padding: '0px 16px 16px'
  },
  treeTitleStyle: {
    paddingTop: 12,
    paddingBottom: 12
  }
}))
