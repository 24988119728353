import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconRedirect = (props) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16">
    <path
      fill={props.fillcolor ? props.fillcolor : '#26415E'}
      fillRule="evenodd"
      d="M13.854 2.146a.5.5 0 010 .708l-6 6a.5.5 0 11-.708-.708l6-6a.5.5 0 01.708 0z"
      clipRule="evenodd"
    />
    <path
      fill={props.fillcolor ? props.fillcolor : '#26415E'}
      fillRule="evenodd"
      d="M9 2.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5z"
      clipRule="evenodd"
    />
    <path
      fill={props.fillcolor ? props.fillcolor : '#26415E'}
      fillRule="evenodd"
      d="M13.5 2a.5.5 0 01.5.5v4a.5.5 0 01-1 0v-4a.5.5 0 01.5-.5z"
      clipRule="evenodd"
    />
    <path
      stroke={props.fillcolor ? props.fillcolor : '#26415E'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.5 10.5v1a2 2 0 01-2 2h-7a2 2 0 01-2-2v-7a2 2 0 012-2h1"
    />
  </SvgIcon>
)
IconRedirect.displayName = 'IconRedirect'
IconRedirect.muiName = 'SvgIcon'

export default IconRedirect
