import { makeStyles } from '@material-ui/core'
import textTheme from 'theme/theme'
import theme from 'theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  headerStyle: {
    paddingBottom: 14,
    ...textTheme.typography.h2,
    lineHeight: '14px',
    fontSize: 14,
    color: themeColorPalette.palette.sys_trad.text.main
  },
  critical: {
    display: 'flex',
    height: '2em',
    paddingTop: 3
  },
  driversContainer: {
    padding: '16px'
  },
  driversTitle: {
    textAlign: 'left',
    ...theme.typography.h3
  },
  groupButtonContainer: {
    textAlign: 'left'
  },
  subcategoriesContainer: {
    marginTop: '16px'
  },
  driverListHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  driverListHeaderTitle: {
    ...theme.typography.p2,
    textAlign: 'left',
    display: 'inline'
  },
  driverListHeaderIcon: {
    height: '16px',
    width: '16px'
  },
  root: {
    flexGrow: 1,
    paddingLeft: 0,
    marginLeft: -12,
    marginTop: 8,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  },
  banner: {
    width: '100%'
  },
  parentGrid: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'inherit'
    }
  },
  paper: {
    textAlign: 'left',
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)'
  },
  summaryContainer: {
    maxWidth: '1200px'
  },
  controversies: {
    minHeight: '410px',
    maxWidth: '1200px',
    color: theme.palette.text.secondary,
    padding: '16px' //added for placeholder text, please remove if not required
  },
  loadingControversies: {
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
    height: '428px',
    width: '100%'
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '6px',
    wordBreak: 'break-word',
    fontFamily: 'WhitneyNarrBook, Roboto, Arial, sans-serif',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main
    }
  },
  textEmptyDoc: {
    ...textTheme.typography.p3,
    fontWeight: 500,
    color: themeColorPalette.palette.sys_trad.text2.main,
    textAlign: 'center',
    margin: '8px'
  },
  root_p2_landing_page: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%'
    // maxWidth: '1280px', // TODO may need to have this follow report template like all other pages...
  },
  header: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxShadow: 'none',
    '@media (max-width: 1100px)': {
      paddingLeft: 0
    },
    borderBottom: '1px solid ' + themeColorPalette.palette.sys_trad.border.main
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: themeColorPalette.palette.white.main,
    boxShadow: 'none',
    maxHeight: '48px',
    alignItems: 'center'
  },
  headerTextStyle: {
    marginTop: 16,
    marginLeft: 18.7,
    marginBottom: 16,
    ...textTheme.typography.p3,
    lineHeight: '16px',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  content: {
    flexGrow: 1,
    width: 'calc(100% - 170px)',
    paddingTop: 28
  },
  container: {
    marginLeft: 70,
    paddingRight: 0,
    paddingLeft: '24px'
  },
  tableTitle: {
    paddingBottom: '5px',
    ...textTheme.typography.h1
  },
  stickyHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    zIndex: 1,
    boxShadow: 'none'
  },
  divider: {
    backgroundColor: themeColorPalette.palette.sys_trad.border.main,
    marginTop: 8,
    marginBottom: 40
  },
  headerTitle: {
    ...textTheme.typography.h1,
    lineHeight: '16px',
    fontSize: '16px',
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingBottom: 8,
    marginRight: 20
  },
  headerSubText: {
    ...textTheme.typography.p3,
    lineHeight: '16px',
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingBottom: 4,
    marginRight: 20
  },
  headerSubTextSmall: {
    ...textTheme.typography.p3,
    lineHeight: '16px',
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingBottom: 16,
    marginRight: 20,
    fontSize: '10px !important'
  },
  SectorDriverStyle: {
    maxWidth: '100%',
    marginBottom: '12px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px'
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: '64px'
    }
  },
  footerText: {
    ...theme.typography.p5,
    color: themeColorPalette.palette.sys_trad.main.main
  },
  footerStyle: {
    paddingTop: 84,
    paddingBottom: 60
  },
  text: {
    ...textTheme.typography.p3,
    fontSize: '14px !important',
    marginBottom: '2px'
  },
  textBullet: {
    ...textTheme.typography.p3,
    fontSize: '14px !important',
    marginBottom: '4px'
  },
  textBottom: {
    ...textTheme.typography.p3,
    fontSize: '14px !important',
    marginBottom: '20px'
  },
  textSecondBottom: {
    ...textTheme.typography.p3,
    fontSize: '14px !important',
    marginTop: '16px',
    marginBottom: '2px'
  },
  textTop: {
    ...textTheme.typography.h2,
    marginBottom: '16px',
    fontSize: '20px !important'
  },
  copyIconClass: {
    cursor: 'pointer'
  },
  card: {
    display: 'flex',
    position: 'relative',
    margin: '0.5rem 0 1rem 0',
    backgroundColor: '#fff',
    borderRadius: '2px',
    boxShadow:
      '0 1px 6px 0 rgb(0 0 0 / 5%), 0 2px 5px -2px rgb(0 0 0 / 13%), 0 1px 4px 0 rgb(0 0 0 / 10%)'
  },
  cardStacked: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    position: 'relative'
  },
  cardReport: {
    flexGrow: '1',
    padding: '8px'
  },
  iconRedirectDocs: {
    fontSize: '1rem',
    fill: 'none',
    cursor: 'pointer',
    marginLeft: '4px'
  },
  errorContainer: {
    boxShadow:
      '0 1px 6px 0 rgb(0 0 0 / 5%), 0 2px 5px -2px rgb(0 0 0 / 13%), 0 1px 4px 0 rgb(0 0 0 / 10%)',
    borderRadius: '2px',
    margin: 0
  },
  root_p3_landing_page: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    width: '100%',
    paddingBottom: theme.spacing(3),
    flexGrow: 1,
    marginLeft: '12px'
  },
  rootDialog: {
    flexGrow: 1
  },
  paperDialog: {
    padding: theme.spacing(2),
    textAlign: 'center',
    boxShadow: 'none'
  },
  pageHeaderStyleTextTitle: {
    ...textTheme.typography.h1,
    color: themeColorPalette.palette.sys_trad.text.main,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0em',
    textAlign: 'left',
    width: '100%',
    height: '19px',
    left: '120px',
    top: '300px'
  },
  backdrop: {
    zIndex: 1200 + 1,
    color: '#fff'
  },
  backdropVisible: {
    display: 'block'
  },
  backdropHidden: {
    display: 'none'
  },
  pageSubHeader: {
    marginRight: '16px',
    marginLeft: '16px'
  },
  footerTextEmail: {
    ...theme.typography.p5,
    color: themeColorPalette.palette.sys_trad.main.main
  },
  documentsHeader: {
    ...textTheme.typography.h2,
    paddingBottom: '16px',
    boxShadow: 'none',
    fontSize: '16px'
  },
  dualMateriality: {
    ...textTheme.typography.h2,
    boxShadow: 'none',
    marginLeft: '4px',
    fontSize: '16px',
    paddingLeft: '24px',
    marginTop: '50px'
  },
  drivers: {
    marginBottom: '8px'
  },
  errorStyle: {
    marginLeft: '4px',
    paddingLeft: '24px',
    width: '98%'
  },
  sourceDocuments: {
    paddingLeft: '28px'
  },
  documents: {
    marginLeft: '4px',
    paddingBottom: '8px',
    minWidth: '134px'
  },
  copyButton: {
    padding: '0px 0px 8px 18px',
    ...theme.typography.p3,
    color: themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      background: 'none'
    }
  },
  copyIcon: {
    marginRight: '-6px',
    marginBottom: '-12px'
  },
  tooltip: {
    cursor: 'pointer'
  },
  contactUs: {
    paddingBottom: '48px'
  },
  divContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  header_p3_landing_page: {
    margin: '50px 24px 8px 0',
    maxWidth: '1200px',
    padding: '6px 16px 0',
    textAlign: 'initial'
  },
  pageSubHeaderSector: {
    color: themeColorPalette.palette.sys_trad.status.main,
    cursor: 'pointer'
  },
  redirectIcon: {
    fontSize: '1rem',
    color: themeColorPalette.palette.sys_trad.status.main,
    cursor: 'pointer',
    marginLeft: '2px',
    float: 'right',
    fill: 'none'
  },
  missingDocsContainer: {
    [theme.breakpoints.down(900)]: {
      marginBottom: '6px'
    }
  },
  driverContainer: {
    maxWidth: '1200px'
  },
  docsContainer: {
    width: '250px',
    height: '200px',
    padding: '40px'
  },
  subHeaderStyleText: {
    ...textTheme.typography.p1,
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    left: '284px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    flexFlow: 'row no-wrap',
    marginTop: '4px'
  }
}))
