import { GlobalContext } from 'context/GlobalStateProvider'
import { multiColumnSort } from 'utils/Sort'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef } from 'react'
import textTheme from 'theme/theme'
import {
  Card,
  CardInfoSection,
  IconRegion,
  Map,
  MinimalTable,
  Panel,
  RegularTable
  // eslint-disable-next-line import/named
} from 'components'
import { useStyles } from './styles'
import Legend from './Legend.jsx'
import { PAGE_CON } from 'constant/constants'
import IconSector from '../../components/ui/icons/IconIndustry'
const labelStyle = {
  color: textTheme.palette.sys_trad.text2.main
}

const barCorner = '2px'

const containerTop = {
  paddingLeft: '8px',
  paddingRight: '8px',
  marginBottom: '12px'
}

function getSelectedCardData(data, regionName, orderByScoreRange) {
  let rdata = []

  if (data.details && data.details.length > 0) {
    let summaryData = data.summary
      .concat(data.sectorsummary)
      .filter((data) => data.name && data.name === regionName.trim())
    if (summaryData && summaryData.length > 0) {
      summaryData = summaryData[0].portfolio_distribution
    } else {
      return ['-']
    }
    let rdetailDataArr = data.details
      .concat(data.sectordetails)
      .filter((regData) => regData.name && regData.name === regionName.trim())

    if (!rdetailDataArr || rdetailDataArr.length <= 0) return ['-']
    let rdetailData = rdetailDataArr[0]

    summaryData.map((data) => {
      let categoryData = rdetailData[data.category]
      if (categoryData && categoryData.length > 0) {
        let companyData = categoryData.map((dataRow) => {
          //added score_range for greenshare panel which shows both score_range and category
          dataRow.category = data.category
          if (!orderByScoreRange) {
            dataRow.score_range = data.score_range
          } else {
            const median = {
              '0%': 0,
              '0-10%': 5,
              '10-20%': 15,
              '20-33%': 26.5,
              '33-50%': 41.5,
              '>=50%': 75
            }
            dataRow.median_score = dataRow.score || median[dataRow.score_range]
          }
          return dataRow
        })

        rdata.push({
          category: data.category,
          CompanyData: companyData,
          score_range: data.score_range
        })
      }
      return ''
    })
  }
  return rdata
}

const Regions = (props) => {
  const classes = useStyles(props)
  const [open, setOpen] = React.useState(false)
  const [panelComp, setPanelComp] = React.useState([])
  const [panelTitle, setPanelTitle] = React.useState('')
  const panelTabColInfo = props.panelTabColInfo
  const [filterState] = useContext(GlobalContext).globalFilter
  const [currentPageState] = useContext(GlobalContext)[props.currentPageState]

  let showBenchMark =
    filterState.globalPortfolioFilter.benchmark !== '' ? true : false
  let sortedRegion = sortData(props.data.summary)
  let sortedRegionPropData = sortedRegion.map((r) => ({
    ...r,
    group: 'Region'
  })) // Adding an eleemt to identify Region and sector data for Icons
  let sortedSectionPropData = sortData(props.data.sectorsummary)
  let sortedPropData = sortedRegionPropData.concat(sortedSectionPropData)
  const mapRef = useRef()

  const [mpHeight, setMpHeight] = React.useState(500)

  const openDrawer = () => {
    setOpen(true)
  }

  const handleCloseDrawer = () => {
    setOpen(false)

    if (props.onRegionSelect) {
      const returnData = {
        cardId: 'none',
        cardType: 'region',
        cardSubType: '',
        data: []
      }
      props.onRegionSelect(returnData)
    }
  }

  const isPredictorScoreExist = (data) => {
    if (data && data.length > 0) {
      return data.some(
        (coverageDetails) =>
          coverageDetails['score_quality'] === PAGE_CON.PREDICTED
      )
    } else {
      return false
    }
  }

  function regionCardSelect(id, cardHeader) {
    let regionData = getSelectedCardData(
      props.data,
      cardHeader,
      props.orderByScoreRange
    )
    let sortedData = sortCompanyData(regionData)
    const returnData = {
      cardId: id,
      cardType: 'region',
      cardSubType: cardHeader,
      data: sortedData
    }

    if (props.onRegionSelect) props.onRegionSelect(returnData)

    let panelComp = []
    let isPredictorScoreAvailable = false
    let hidePredictedScoreLegend = !!props.noRowHighlight
    regionData.map((categoryData, catIndex) => {
      if (!isPredictorScoreAvailable) {
        isPredictorScoreAvailable = isPredictorScoreExist(
          categoryData.CompanyData
        )
      }
      let panelCol = []
      panelTabColInfo.map((col, index) => {
        let newCol = { ...col }
        if (index === 0) {
          props.headerUnitLabel
            ? (newCol.HeaderLabel =
                categoryData.category + props.headerUnitLabel)
            : props.categoryFalse
            ? (newCol.HeaderLabel = categoryData.score_range)
            : (newCol.HeaderLabel = categoryData.category)
        }
        return panelCol.push(newCol)
      })

      return panelComp.push(
        <div
          className={classes.panelComponentWrapperCss}
          key={`region-${categoryData.category}-Div-${catIndex}`}
        >
          <div className={classes.tableCss}>
            <RegularTable
              id={`regionTab-${categoryData.category}-Div-${catIndex}`}
              heap_id="regsec"
              columns={panelCol}
              totalData={categoryData.CompanyData}
              horScroll="hidden"
              tablePadding="20px"
              tableEditable={false}
              onUploadClick={() => handleCloseDrawer()}
              isRowHighlighted={props.noRowHighlight ? false : true}
            ></RegularTable>
          </div>
          {catIndex === regionData.length - 1 &&
          isPredictorScoreAvailable &&
          !hidePredictedScoreLegend ? (
            <Legend legendLableName="Predicted Score" />
          ) : null}
        </div>
      )
    })

    setPanelTitle(cardHeader)
    setPanelComp(panelComp)

    openDrawer()
  }

  function sortData(data) {
    let returnData = data
    if (data && data.length > 0) {
      let columnsToBeSorted = [
        ['companies', 'DESC'],
        ['name', 'ASC', 'string']
      ]

      returnData = multiColumnSort(data, columnsToBeSorted)
    }
    return returnData
  }

  function sortCompanyData(data) {
    let sortByCols = props.sortByCols
      ? props.sortByCols
      : [
          ['score', 'DESC'],
          ['investment_pct', 'DESC'],
          ['company_name', 'ASC', 'string']
        ]
    let returnData = data
    if (data && data.length > 0) {
      returnData = data.forEach((category) =>
        category && category.CompanyData
          ? multiColumnSort(category.CompanyData, sortByCols)
          : null
      )
    }
    return returnData
  }

  // Spliting the records, 2 element in one array and rest in another array
  let sortedData = []
  if (sortedPropData.length > 2 && !props.hideMap) {
    sortedData = sortedPropData.splice(2)
  }
  if (props.hideMap) {
    sortedData = sortedPropData
  }

  function mapResize() {
    if (mapRef && mapRef.current && mapRef.current.clientHeight) {
      setMpHeight(mapRef.current.clientHeight - 30)
    }
  }

  useEffect(() => {
    if (mapRef && mapRef.current && mapRef.current.clientHeight) {
      setMpHeight(mapRef.current.clientHeight - 30)
    }
    window.addEventListener('resize', mapResize, true)
    return () => window.removeEventListener('resize', mapResize, true)
  }, [])

  return (
    <div id={props.id}>
      <Grid container direction={'row'} spacing={2}>
        {!props.hideMap ? (
          <Grid
            container
            direction={'row-reverse'}
            spacing={2}
            style={containerTop}
          >
            <Grid
              item
              xs={12}
              sm={showBenchMark ? 7 : 8}
              md={showBenchMark ? 7 : 8}
            >
              <Card
                cardClass="shadow"
                maxWidth={'auto'}
                noHover={true}
                components={[
                  sortedPropData.length === 0 ? (
                    ''
                  ) : (
                    <Map
                      data={props.mapData}
                      stops={props.mapStops}
                      mapDataClasses={props.mapDataClasses}
                      height={mpHeight}
                      title="Weighted average scores based on portfolio <br/> companies’ headquarter locations"
                    />
                  )
                ]}
                id={'mapcard-test-id'}
              ></Card>
            </Grid>

            <Grid
              item
              xs={12}
              sm={showBenchMark ? 5 : 4}
              md={showBenchMark ? 5 : 4}
            >
              <Grid container spacing={2} ref={mapRef} id="cardsHeight">
                {sortedPropData.map((region, index) => {
                  let cardInfoData = {
                    category: region.category,
                    companies: Number(region.companies).toLocaleString(
                      'en-US',
                      { maximumFractionDigits: 0 }
                    ),
                    investment_pct: region.investment_pct,
                    weighted_average_score: region.weighted_average_score,
                    score_range: region.score_range
                  }

                  let regionDataRows = region.portfolio_distribution
                  let comp = [
                    <CardInfoSection
                      key={`regioncard-info-test-id-${index}`}
                      id={`regioncard-info-test-id-${index}`}
                      isCarbonFootprint={props.isCarbonFootprint}
                      styleFirstChild={props.styleFirstChild}
                      contentInfo={
                        region.group === 'Region'
                          ? props.cardInfoContentInfo
                          : props.cardInfoContentInfoSector
                      }
                      data={cardInfoData}
                      tableData={true}
                      stateData={props.stateData}
                      labelStyle={labelStyle}
                      currentPageState={currentPageState}
                    />,
                    <MinimalTable
                      key={`regionminimaltable-test-id-${index}`}
                      id={`regionminimaltable-test-id-${index}`}
                      columns={props.mimTabColInfo}
                      data={regionDataRows}
                      barCorner={barCorner}
                      maxWidth={'auto'}
                      hideBenchmark={!showBenchMark}
                    ></MinimalTable>
                  ]

                  return (
                    <Grid item xs={12} key={`gridCard-${index}`}>
                      <Card
                        heap_regsec_id={
                          region.group === 'Region' ? 'region' : 'sector'
                        }
                        cardClass="shadow"
                        cardIcon={
                          region.group === 'Region' ? (
                            <IconRegion />
                          ) : (
                            <IconSector />
                          )
                        }
                        maxWidth={'auto'}
                        headerTitle={region.name ? region.name.trim() : ' '}
                        components={comp}
                        id={`regioncard-test-id-${index}`}
                        selectedId={props.selectedCardId}
                        onCardSelect={regionCardSelect}
                        selectionType={'single'}
                        key={`regioncard-test-id-${index}`}
                      ></Card>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        {sortedData && sortedData.length !== 0 ? (
          <Grid
            container
            spacing={2}
            style={{ paddingLeft: '8px', paddingRight: '8px' }}
          >
            {sortedData &&
              sortedData.map((region, index) => {
                if (
                  (region.name === 'Unknown' || region.name === undefined) &&
                  region.code !== undefined
                ) {
                  window.heap.track('unknown_region_error', {
                    portfolio_id: filterState.globalPortfolioFilter.portfolio,
                    user_id: filterState.globalPortfolioFilter.user
                  })
                } else if (
                  (region.name === 'Unknown' || region.name === undefined) &&
                  region.code === undefined
                ) {
                  window.heap.track('unknown_sector_error', {
                    portfolio_id: filterState.globalPortfolioFilter.portfolio,
                    user_id: filterState.globalPortfolioFilter.user
                  })
                }

                let cardInfoData = {
                  category: region.category,
                  companies: Number(region.companies).toLocaleString('en-US', {
                    maximumFractionDigits: 0
                  }),
                  investment_pct: region.investment_pct,
                  weighted_average_score: region.weighted_average_score,
                  score_range: region.score_range
                }
                let regionDataRows = region.portfolio_distribution
                let comp = [
                  <CardInfoSection
                    key={`regioncard-info-test-id-${index}`}
                    id={`regioncard-info-test-id-${index}`}
                    isCarbonFootprint={props.isCarbonFootprint}
                    styleFirstChild={props.styleFirstChild}
                    contentInfo={
                      region.group === 'Region'
                        ? props.cardInfoContentInfo
                        : props.cardInfoContentInfoSector
                    }
                    data={cardInfoData}
                    tableData={true}
                    labelStyle={labelStyle}
                    stateData={props.stateData}
                    currentPageState={currentPageState}
                  />,
                  <MinimalTable
                    key={`regionminimaltable-test-id-${index}`}
                    id={`regionminimaltable-test-id-${index}`}
                    columns={props.mimTabColInfo}
                    data={regionDataRows}
                    barCorner={barCorner}
                    maxWidth={'auto'}
                    hideBenchmark={!showBenchMark}
                  ></MinimalTable>
                ]

                return (
                  <Grid item xs={12} sm={6} md={4} key={`gridCard-${index}`}>
                    <Card
                      heap_regsec_id={
                        region.group === 'Region' ? 'region' : 'sector'
                      }
                      cardClass="shadow"
                      cardIcon={
                        region.group === 'Region' ? (
                          <IconRegion />
                        ) : (
                          <IconSector />
                        )
                      }
                      maxWidth={'auto'}
                      headerTitle={region.name ? region.name.trim() : ' '}
                      components={comp}
                      id={`regioncard-test-id-${index}`}
                      selectedId={props.selectedCardId}
                      onCardSelect={regionCardSelect}
                      selectionType={'single'}
                      key={`regioncard-test-id-${index}`}
                    ></Card>
                  </Grid>
                )
              })}
          </Grid>
        ) : null}

        <Panel
          key={'panelcard-test-id-1'}
          title={panelTitle}
          openPanel={open}
          titlePadding="12px"
          onPanelClose={handleCloseDrawer}
          panelContentComponent={panelComp}
        ></Panel>
      </Grid>
    </div>
  )
}

Regions.prototype = {
  /** Region id */
  id: PropTypes.string,
  /** id of the region card which is currently selected */
  selectedCardId: PropTypes.string,
  /** Regions data */
  data: PropTypes.any,
  /** Callback function*/
  onRegionSelect: PropTypes.func,
  /** Regions to be displayed*/
  displayRegions: PropTypes.array,
  noRowHighlight: PropTypes.bool
}

export default Regions
