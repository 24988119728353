import { Grid } from '@material-ui/core'
import React from 'react'
import { ToggleButton } from 'components'
import { useStyles } from './styles'


const ReportingActions = ({
  ReportOptions,
  onReportingOptionChange,
  children
}) => {
  const classes = useStyles()

  return (
    <Grid container direction="column" className={classes.Container}>
      <Grid item className={classes.buttonContainer} xs={12}>
        {children}
      </Grid>
      {ReportOptions.length ? (
        <div>
          <Grid item className={classes.ActionHeader} xs={12}>
            Reporting Options
          </Grid>
          <Grid item container direction="column">
            {ReportOptions.map((item) => (
              <Grid
                item
                xs={12}
                key={`item-${item.id}`}
                className={classes.ActionItemStyle}
              >
                <span data-testid="reportOption">
                  <div data-testid={item.id}>
                    <ToggleButton
                      id={item.id}
                      toggleLabelText={item.buttonText}
                      labelPlacement={'end'}
                      labelClass={'reportingLabel'}
                      isChecked={item.checked}
                      disabled={item.disabled}
                      onChange={onReportingOptionChange}
                    />
                  </div>
                  <div className={classes.subTextStyle}>
                    {item.subTitleText}
                  </div>
                </span>
              </Grid>
            ))}
          </Grid>
        </div>
      ) : null}
    </Grid>
  )
}

export default ReportingActions
