import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import { useStyles } from './styles'

/**
 * @visibleName Input Fields
 */
const InputField = (props) => {
  const classes = useStyles(props)
  const [inputError, setInputError] = React.useState(props.inputError)

  const onChange = (event) => {
    if (props.inputError) {
      event.target.value ? setInputError(false) : setInputError(true)
    }
  }

  return (
    <FormControl className={classes.formControl}>
      <TextField
        type="text"
        autoComplete={props.autoCompleteOff ? 'off' : 'on'}
        error={props.onChange ? props.inputError : inputError}
        label={props.inputLabelText}
        id={props.id}
        placeholder={props.inputDefaultValue}
        onChange={props.onChange ? props.onChange : onChange}
        helperText={
          props.onChange
            ? props.inputError
              ? props.helperText
              : ''
            : inputError
            ? props.helperText
            : ''
        }
        FormHelperTextProps={{
          className: classes.helperText
        }}
        InputLabelProps={{
          shrink: true,
          className: classes.inputLabelClass
        }}
        InputProps={{
          className: classes[props.inputClass]
        }}
        inputProps={{
          className: classes.autoFillInput
        }}
      />
    </FormControl>
  )
}

InputField.propTypes = {
  /** Input id */
  id: PropTypes.string,
  /** The input label text */
  inputLabelText: PropTypes.string,
  /** Class for input style: lightWeightInput */
  inputClass: PropTypes.string,
  /** Width for input */
  inputWidth: PropTypes.string,
  /** Margin (left) for input */
  inputMargin: PropTypes.string,
  /** Default Value for input */
  inputDefaultValue: PropTypes.string,
  /** Helper Text for Error on input */
  helperText: PropTypes.string,
  /** Input error flag*/
  inputError: PropTypes.bool,
  /** Gets called when the input changes */
  onChange: PropTypes.func,
  /** Input autocomplete off flag*/
  autoCompleteOff: PropTypes.bool
}

export default InputField
