import clsx from 'clsx'
import React from 'react'
import * as S from './tabsComponents'
import PropTypes from 'prop-types'
import { useStyles } from './styles'

const Tabs = ({ tabs, onClick, isLoading, type }) => {
  const classes = useStyles(tabs)
  let isSmallTab = type === 'small'

  return (
    <S.Container small={isSmallTab ? true : false}>
      {tabs.map(({ key, label, active }, index) => (
        <S.Button
          height={isSmallTab ? '20px' : ''}
          onClick={() => tabs.length > 1 && onClick(key)}
          className={
            isSmallTab
              ? clsx(
                  classes.smallButton,
                  'fontStyleBig_color',
                  active ? 'active' : ''
                )
              : active
              ? 'active'
              : ''
          }
          disabled={isLoading}
          key={`${index}-${key}`}
          heap_id="perfchart"
          heap_perfchart_id={
            label === 'Largest Holdings'
              ? label
              : label.slice(label.lastIndexOf(' ') + 1)
          }
        >
          {label}
        </S.Button>
      ))}
    </S.Container>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.array,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  type: PropTypes.string
}

export default Tabs
