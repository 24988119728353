import React from 'react'
import { Box, Grid } from '@material-ui/core'
import axios from 'axios'
import { Page, Panel, PrimaryModal, RegularTable } from 'components'
import { COMMON, ENTITY, GBL_FLT, PARAMS } from 'constant/APIconstants'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import { GlobalContext } from 'context/GlobalStateProvider'
import { useCallback, useContext, useEffect } from 'react'
import { ResearchLineService } from 'services'
import Error from 'utils/error'
import LoadingMask from 'utils/loadingMask'
import { multiColumnSort } from 'utils/Sort'
import Banner from 'views/common/Banner/index.jsx'
import ClimateControversy from './ClimateControversy'
import ClimateSummary from './ClimateSummary'
import ClimateUnderlying from './ClimateUnderlying'
import * as S from './styles'

const subsidiariesColInfo = [
  {
    HeaderLabel: 'Company Name',
    Alignment: 'left',
    DataField: [
      {
        Name: 'company_name',
        Alignment: 'left',
        isEllipsis: true,
        LeftPadding: 0,
        onClick: {
          showUnderline: true
        }
      }
    ]
  },
  {
    HeaderLabel: 'ESG Score',
    Alignment: 'right',
    DataField: [
      {
        Name: 'esg_score',
        Alignment: 'right'
      }
    ]
  }
]

const ClimateProfile = (props) => {
  const classes = S.useStyles()

  const [entityHeaderState, entityHeaderDispatch] =
    useContext(GlobalContext).globalEntityHeader
  let apiCallId = entityHeaderState.apiCallId
  const [climateState, climateDispatch] =
    useContext(GlobalContext).globalClimate
  const { esg } = props.entitlements.PortfolioAnalysis
  let climateAPICallId = climateState.apiCallId

  useEffect(() => {
    loadHeaderData()
  }, [])

  useEffect(() => {
    if (
      entityHeaderState.globalEntityHeader.orbis_id !== '' &&
      !entityHeaderState.isLoading &&
      esg
    ) {
      loadBanner()
    }
  }, [entityHeaderState.globalEntityHeader.orbis_id])

  const loadBanner = useCallback(async () => {
    if (
      entityHeaderState.globalEntityHeader.orbis_id !== '' &&
      !entityHeaderState.isLoading
    ) {
      getBannerData()
    }
  }, [entityHeaderState.globalEntityHeader.orbis_id])

  const getBannerData = async (isErrorReload = false) => {
    if (
      climateState.overallESGScore.dataStatus !== '' &&
      isErrorReload === false
    )
      return

    climateDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionNames: [REDUCER_STATE.CLIMATE_ESGSCORE]
    })

    try {
      const { data } = await ResearchLineService.retrieveResearchLineApi({
        portfolio: entityHeaderState.globalEntityHeader.orbis_id,
        regions: '',
        sectors: '',
        asOfDate: '',
        benchmark: '',
        checkForEntitlements: true,
        isEntitled: true,
        researchLine: GBL_FLT.ESG_ASSESSMENT,
        token: '',
        endPoint: COMMON.CLIMATE_SUMMARY
      })

      const { esg_assessment } = data?.length > 0 ? data[0] : {}

      if (data?.length > 0) {
        esg_assessment.score_categories =
          esg_assessment?.score_categories?.reduce(
            (acc, category) =>
              category?.sub_category !== 'ESG Rating'
                ? [
                    ...acc,
                    {
                      ...category,
                      criteria: category?.sub_category
                    }
                  ]
                : acc,
            []
          )
      }

      climateDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionNames: [{ [REDUCER_STATE.CLIMATE_ESGSCORE]: esg_assessment }],
        apiCallId: climateAPICallId
      })
    } catch (err) {
      console.log(err)
      climateDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        sectionNames: [REDUCER_STATE.CLIMATE_ESGSCORE],
        apiCallId: climateAPICallId
      })
    }
  }
  function sortData(data) {
    let returnData = multiColumnSort(data, [
      ['esg_score', 'ASC'],
      ['company_name', 'ASC']
    ])
    return returnData
  }
  const loadHeaderData = async (isErrorReload = false) => {
    if (entityHeaderState.dataStatus === '' || isErrorReload === true) {
      try {
        entityHeaderDispatch({ type: REDUCER_TYPE.LOAD_DATA_START })

        const { data } = await axios.post(ENTITY.BASE_URL + COMMON.HEADER, {
          [PARAMS.ORBIS_ID]: props.orbis_id
        })

        let company_name,
          country,
          generic_sector,
          mesg_sector_detail_description,
          methodology,
          model_version,
          lei,
          primary_isin,
          orbis_id,
          region,
          mesg_sector,
          research_line_id,
          sector_l1,
          sector_l2,
          score,
          subsidiaries,
          tot_subs,
          confidence_level,
          score_quality = ''
        if (data && data.length > 0) {
          company_name = data[0].company_name
          country = data[0].country
          generic_sector = data[0].generic_sector
          mesg_sector_detail_description =
            data[0].mesg_sector_detail_description
          methodology = data[0].methodology
          model_version = data[0].model_version
          lei = data[0].lei
          primary_isin = data[0].primary_isin
          orbis_id = data[0].orbis_id
          region = data[0].region
          mesg_sector = data[0].mesg_sector
          score = data[0].overall_disclosure_score
          research_line_id = data[0].research_line_id
          sector_l1 = data[0].sector_l1
          sector_l2 = data[0].sector_l2
          subsidiaries =
            data[0].subsidiaries?.map(({ esg_score, ...sub }) => ({
              ...sub,
              esg_score: `${esg_score}/100`
            })) || []
          tot_subs = data[0].tot_subs
          confidence_level = data[0].esg_confidence_level
          score_quality = data[0].score_quality
        }

        entityHeaderDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          headerItems: {
            company_name,
            country,
            generic_sector,
            mesg_sector_detail_description,
            methodology,
            model_version,
            lei,
            primary_isin,
            orbis_id,
            region,
            mesg_sector,
            research_line_id,
            sector_l1,
            sector_l2,
            score,
            subsidiaries,
            tot_subs,
            confidence_level,
            score_quality
          },
          apiCallId
        })
      } catch (err) {
        entityHeaderDispatch({ type: REDUCER_TYPE.LOAD_DATA_ERROR, apiCallId })
      }
    }
  }

  const errorDialogClicked = () => {
    entityHeaderDispatch({ type: REDUCER_TYPE.RESET_LOAD_DATA_ERROR })
    loadHeaderData(true)
  }

  const isLoading = () => {
    return climateState.overallESGScore.isLoading
  }

  const isError = () => {
    return climateState.overallESGScore.dataStatus === PAGE_CON.ERROR
  }

  const reloadComponent = (errorOccuredOn) => {
    switch (errorOccuredOn) {
      case PAGE_CON.CLIMATE_ESG_SCORE:
        getBannerData(true)
        break
      default:
        break
    }
  }

  const ESGHasData = () => {
    return climateState.overallESGScore.data?.score_categories?.length > 0
  }

  const isCompanyHasOnDemand =
    entityHeaderState?.globalEntityHeader?.score_quality === 'on-demand'

  return (
    <Page className={classes.root}>
      <main className={classes.content}>
        <Panel
          topNum="0px"
          title={'About ' + entityHeaderState.globalEntityHeader.company_name}
          id="company-summary-panel"
          openPanel={props.openCompanyInfo}
          onPanelClose={props.handleCloseDrawer}
          panelContentComponent={[
            <Box style={{ width: '100%' }} key="company-summary-panel-box">
              {entityHeaderState?.globalEntityHeader?.primary_isin && (
                <div className={classes.companySummaryItem}>
                  <span className={classes.companySummaryLabel}>ISIN: </span>{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {entityHeaderState.globalEntityHeader.primary_isin}
                  </span>
                </div>
              )}
              {entityHeaderState?.globalEntityHeader?.lei && (
                <div className={classes.companySummaryItem}>
                  <span className={classes.companySummaryLabel}>LEI: </span>{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {entityHeaderState.globalEntityHeader.lei}
                  </span>
                </div>
              )}
              {entityHeaderState?.globalEntityHeader?.orbis_id && (
                <div className={classes.companySummaryItem}>
                  <span className={classes.companySummaryLabel}>
                    Orbis ID:{' '}
                  </span>{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {entityHeaderState.globalEntityHeader.orbis_id}
                  </span>
                </div>
              )}
              {entityHeaderState?.globalEntityHeader?.country && (
                <div className={classes.companySummaryItem}>
                  <span className={classes.companySummaryLabel}>Region: </span>{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {entityHeaderState.globalEntityHeader.country}
                  </span>
                </div>
              )}
              {entityHeaderState?.globalEntityHeader?.methodology &&
                entityHeaderState?.globalEntityHeader?.mesg_sector && (
                  <div className={classes.companySummaryItem}>
                    <span className={classes.companySummaryLabel}>
                      Sector:{' '}
                    </span>{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {entityHeaderState.globalEntityHeader.mesg_sector}
                    </span>
                  </div>
                )}
              {!PAGE_CON.PRODUCTION_ENABLED &&
                esg &&
                entityHeaderState?.globalEntityHeader?.model_version && (
                  <div className={classes.companySummaryItem}>
                    <span className={classes.companySummaryLabel}>
                      Methodology:{' '}
                    </span>{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {entityHeaderState.globalEntityHeader.model_version ===
                        'V2' ||
                      entityHeaderState.globalEntityHeader.model_version ===
                        '2.0'
                        ? 'ESG Assessment Methodology 2.0'
                        : 'ESG Assessment Methodology 1.0'}
                    </span>
                  </div>
                )}
              <div className={classes.companySummaryComingSoon}>
                Company's Description - Coming Soon
              </div>
            </Box>,
            esg && (
              <Box
                className={classes.subsidiaryBox}
                key="subsidiary-summary-panel-box"
              >
                {entityHeaderState?.globalEntityHeader?.tot_subs && (
                  <>
                    <div className={classes.companySummaryItem}>
                      <div className={classes.subsidiariesTitle}>
                        {entityHeaderState?.globalEntityHeader?.tot_subs > 1
                          ? 'Subsidiaries '
                          : 'Subsidiary '}{' '}
                        ({entityHeaderState?.globalEntityHeader?.tot_subs})
                      </div>
                      <div className={classes.subsidiariesDisclosure}>
                        Unless assessed, subsidiaries have the same score as
                        their parent company
                      </div>
                    </div>
                    <div className={classes.subsidiariesTable}>
                      <RegularTable
                        id="subsidiaries-table-id"
                        heap_id="subsidiaries-table"
                        columns={subsidiariesColInfo}
                        totalData={sortData(
                          entityHeaderState?.globalEntityHeader?.subsidiaries
                        )}
                        horScroll="hidden"
                        tablePadding="24px"
                        tableEditable={false}
                        inPanel={false}
                        showScoreQuality={false}
                        isRowHighlighted={false}
                        onClickFn={props.handleEntityClick}
                      />
                    </div>
                  </>
                )}
              </Box>
            )
          ]}
        ></Panel>
        <PrimaryModal
          id="functionality-error-test-id"
          modalOpen={
            entityHeaderState.dataStatus === PAGE_CON.ERROR ? true : false
          }
          modalClass="errorModal"
          modalTitle=""
          modalContentComponent={
            <Error
              id={'climateHeaderError'}
              dataStatus={PAGE_CON.ERROR}
              onErrorClick={errorDialogClicked}
              errorClass={'noMargin'}
            />
          }
          modalNoCloseOnClick={true}
          showCloseIcon={false}
          onKeyDown={props.onKeyDown}
        />
        {esg && (
          <Grid item className={classes.banner}>
            {isLoading() ? (
              <LoadingMask />
            ) : isError() ? (
              <Error
                id={'ESGError'}
                dataStatus={PAGE_CON.ERROR}
                onErrorClick={reloadComponent}
                errorOccuredOn={PAGE_CON.CLIMATE_ESG_SCORE}
              />
            ) : !ESGHasData() ? (
              <Error
                id={'esgSummaryErr'}
                dataStatus={PAGE_CON.SUCCESS}
                errorOccuredOn={PAGE_CON.CLIMATE_SUMMARY}
              />
            ) : (
              <Banner data={climateState.overallESGScore.data} />
            )}
            {entityHeaderState?.globalEntityHeader?.tot_subs ? (
              <div className={classes.subsidiariesSummaryContainer}>
                <div className={classes.subsidiariesSummary}>
                  This company has{' '}
                  <span
                    className={classes.subsidiariesAnchor}
                    onClick={props.openDrawer}
                    aria-hidden={true}
                  >
                    {entityHeaderState?.globalEntityHeader?.tot_subs} scored{' '}
                    {entityHeaderState?.globalEntityHeader?.tot_subs > 1
                      ? 'subsidiaries'
                      : 'subsidiary'}
                  </span>
                </div>
              </div>
            ) : null}
          </Grid>
        )}
        <Grid className={classes.bannerWrapper}>
          {!(props.entitlements.ondemandassessment && isCompanyHasOnDemand) && (
            <Grid item>
              <ClimateSummary
                entitlements={props.entitlements}
              ></ClimateSummary>
            </Grid>
          )}
        </Grid>
        <Grid className={classes.bannerWrapper}>
          {props.entitlements.Dashboard.controversy &&
            !(
              props.entitlements.ondemandassessment && isCompanyHasOnDemand
            ) && (
              <Grid item className={classes.climateSummarySection}>
                <ClimateControversy></ClimateControversy>
              </Grid>
            )}
        </Grid>
        <Grid
          container
          direction="column"
          spacing={4}
          className={classes.bannerWrapper}
        >
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className={classes.containerMaxWidth}>
              <Grid item className={classes.climateSummarySection}>
                <ClimateUnderlying
                  entitlements={props.entitlements}
                ></ClimateUnderlying>
              </Grid>
            </div>
          </div>
        </Grid>
      </main>
    </Page>
  )
}

export default ClimateProfile
