import React from 'react'
import * as S from '../../dashboardComponents/index.jsx'
import { PAGE_CON } from 'constant/constants'
import { PrimaryLink } from 'components'

const TransitionRisk = ({ onClick }) => {
  return (
    <S.PanelContainer>
      <h2>{PAGE_CON.TRN_RSK_TITLE}</h2>
      <p>
        Moody’s provides different assessments and datasets to help identify how
        a given portfolio of companies is contributing to the transition to a
        low-carbon economy.
      </p>
      <br />
      <ul>
        <li>
          <strong>Carbon Footprint:</strong> Assessment based on the direct
          (Scope 1) and indirect (Scope 2) emissions reported as tonnes of
          carbon dioxide equivalent (tCO2 eq) along with. Carbon Footprint
          assessments are graded according to a 4-level scale:
          <ul>
            <li>Moderate: &lt;100,000 (tCO2 eq)</li>
            <li>Significant: &gt;= 100,000 and &lt; 1,000,000 (tCO2 eq)</li>
            <li>High: &gt;= 1,000,000 and &lt; 10,000,000 (tCO2 eq)</li>
            <li>Intense: &gt;= 10,000,000 (tCO2 eq)</li>
          </ul>
        </li>
      </ul>

      <br />

      <p>
        When emissions data is not publicly disclosed, we estimate Scope 1 and
        Scope 2 emissions using our own proprietary models. Other indirect
        emissions (Scope 3), are also collected and provided but are not part of
        the carbon footprint grade.
      </p>

      <br />

      <ul>
        <li>
          <strong>Temperature Alignment:</strong> A dataset that leverages
          reported and estimated corporate greenhouse gas (GHG) emissions and
          applies announced company emissions targets to produce a
          forward-looking trajectory of emissions implied by those targets. We
          then compare cumulative targeted emissions for each company to
          benchmark climate scenarios produced by the International Energy
          Agency (IEA) over the period 2022-2030 to measure alignment with
          different emissions trajectories. Company alignment with these
          scenarios can be distilled in a single assessment, expressed in
          degrees Celsius, that indicates positioning relative to these
          benchmarks. This is referred to as an Implied Temperature Rise (ITR)
          or temperature alignment. We map these Implied Temperature Rise
          degrees to Temperature Alignment grades:
          <ul>
            <li>Well Below 2°C</li>
            <li>Below 2°C</li>
            <li>2°C</li>
            <li>Above 2°C</li>
          </ul>
        </li>
      </ul>

      <p>
        Since a company’s emissions pathway is projected as implied by its
        publicly stated targets, the alignment refers to the ambition of these
        targets rather than incorporating a company’s likelihood of achieving
        them.
      </p>
      <div>
        <PrimaryLink
          id="link-link-test-id-3"
          linkText={'Read more about Transition Risk'}
          onClick={() => onClick('Transition Risk')}
          linkClass="linkInline"
          linkColorChange={true}
        />
        <br />
        <PrimaryLink
          id="link-link-test-id-3"
          linkText={'Read more about Temperature Alignment'}
          onClick={() => onClick('Temperature Alignment')}
          linkClass="linkInline"
          linkColorChange={true}
        />
      </div>
    </S.PanelContainer>
  )
}

export default TransitionRisk
