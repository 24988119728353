import numberData from 'utils/numberFormat'
import { multiColumnSort } from 'utils/Sort'
import { Panel, PrimaryLink, RegularTable } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import { PAGE_CON } from 'constant/constants'

const Updates = (props) => {
  let recordLength =
    props.totalData && props.totalData.length - props.tableLength
  const [open, setOpen] = React.useState(false)

  const openDrawer = () => {
    setOpen(true)
  }
  const handleCloseDrawer = () => {
    setOpen(false)
  }

  function sortBy(companyA, companyB) {
    if (companyA.Updated === companyB.Updated) {
      if (companyA.Investment === companyB.Investment) {
        if (companyA.Company < companyB.Company) {
          return -1
        }
        if (companyA.Company > companyB.Company) {
          return 1
        }
      }
      if (companyA.Investment > companyB.Investment) {
        return -1
      }
      if (companyA.Investment < companyB.Investment) {
        return 1
      }
      return 0
    }
    return 0
  }

  function sortByPhysicalRisk(data) {
    let sortByCols = [
      ['ScorePhysicalRisk', 'DESC'],
      ['Investment', 'DESC'],
      ['Company', 'ASC', 'string']
    ]

    let returnData = multiColumnSort(data, sortByCols)

    return returnData
  }

  function sortData(data) {
    let returnData = data
    if (data && data.length > 0) {
      returnData = data.sort(sortBy)
    }
    return returnData
  }

  // used only for operations risk, market risk, supply chain risk
  function sortDataPhysicalRisk(data) {
    let returnData = data
    if (data && data.length > 0) {
      returnData = sortByPhysicalRisk(data)
    }
    return returnData
  }

  const totalData =
    props.totalData &&
    props.totalData.map((update) => {
      if (update.Company !== undefined) {
        return update
      }

      return {
        Company: update.company_name,
        Country: update.country_name,
        Investment: update.investment_pct,
        Previous: update.previous_update_date,
        PreviousScore: update.previous_score_category,
        PreviousScoreCategory: update.previous_score_category,
        Region: update.region_name,
        ScoreCategory: update.score_category,
        Score: update.score_category,
        Sector: update.sector_name,
        Updated: update.last_update_date,
        PreviousScorePhysicalRisk: update.previous_score,
        ScorePhysicalRisk: update.score,
        YearsToAlignment: update.years_to_alignment,
        Measurements: update.measurements,
        orbis_id: update.orbis_id,
        score_quality: update.score_quality
      }
    })

  return (
    <>
      <div style={{ paddingBottom: recordLength > 0 ? '8px' : '8px' }}>
        <RegularTable
          isUpdatesTable={true}
          id={props.id}
          heap_id="updates"
          tableEditable={props.tableEditable}
          columns={props.colInfo}
          totalData={
            props.physicalRisk
              ? sortDataPhysicalRisk(totalData)
              : sortData(totalData)
          }
          tableLength={props.tableLength}
          showScoreQuality={props.showScoreQuality}
          isRowHighlighted={true}
        ></RegularTable>
      </div>
      {recordLength > 0 ? (
        <PrimaryLink
          isUpdatesTable={true}
          linkText={
            props.physicalRisk
              ? numberData(recordLength, { isDecimal: false }) +
                ` more companies updated as of ${props.monthYear}`
              : numberData(recordLength, { isDecimal: false }) +
                ` more companies updated in ${props.monthYear}`
          }
          linkClass="linkInline"
          onClick={openDrawer}
        ></PrimaryLink>
      ) : null}
      <Panel
        title={
          props.physicalRisk
            ? `${PAGE_CON.UPDATESIN_PHSYICALRISK_TITLE} ${props.monthYear}`
            : `${PAGE_CON.UPDATESIN_TITLE} ${props.monthYear}`
        }
        isUpdatesTable={true}
        openPanel={open}
        titlePadding="12px"
        onPanelClose={handleCloseDrawer}
        panelContentComponent={[
          <div key="updatesdrilldown-id">
            <RegularTable
              id="table-id"
              heap_id="updates"
              columns={props.panelColInfo}
              totalData={
                props.physicalRisk
                  ? sortDataPhysicalRisk(totalData)
                  : sortData(totalData)
              }
              horScroll="hidden"
              tablePadding="24px"
              tableEditable={false}
              inPanel={true}
              isUpdatesPanelTable={true}
              showScoreQuality={props.showScoreQuality}
              isRowHighlighted={true}
            ></RegularTable>
          </div>
        ]}
      ></Panel>
    </>
  )
}

Updates.propTypes = {
  /** Leaders id */
  id: PropTypes.string,
  /** Table Editabel */
  tableEditable: PropTypes.bool,
  /** Tabel Header info */
  colInfo: PropTypes.array,
  /** Record set for Table */
  totalData: PropTypes.any,
  /** Number of records to display */
  tableLength: PropTypes.number
}

export default Updates
