import React from 'react'
import { makeStyles } from '@material-ui/core'
import theme from 'theme'

export const useStyles = makeStyles(() => ({
  container: {
    cursor: (props) => props.clickAble && 'pointer',
    background: (props) => !props.minify && theme.palette?.white.main,
    borderRadius: 2,
    paddingTop: 4,
    paddingRight: (props) => (!props.minify ? 4 : 13),
    paddingLeft: 4,
    marginRight: 4,
    minWidth: 124,
    boxShadow: (props) => !props.minify && '0px 0px 12px rgba(38, 65, 94, 0.1)',
    marginBottom: '5px',
    borderBottomWidth: (props) => !props.minify && '2px',
    borderBottomStyle: (props) => !props.minify && 'solid',
    borderBottomColor: (props) =>
      !props.minify && props.color
        ? props.color
        : theme.palette?.sys_trad?.white.main,
    display: (props) => props.minify && 'flex',
    alignItems: (props) => props.minify && 'center'
  },
  up: {
    borderBottom: `2px ${theme.palette?.sys_trad?.error.main} solid`
  },
  down: {
    borderBottom: `2px ${theme.palette?.sys_trad?.success.main} solid`
  },
  title: {
    color: theme.palette?.sys_trad?.main.main,
    fontSize: 10,
    marginTop: 2,
    marginBottom: 2,
    marginRight: (props) => props.minify && 5
  },
  value: {
    fontSize: (props) => (props.minify ? 10 : 12),
    marginTop: 2,
    marginBottom: 2,
    color: (props) =>
      props.color
        ? `${props.color} !important`
        : theme.palette?.sys_trad?.main.main
  },
  valueRow: {
    display: 'flex'
  },
  valueIcon: {
    marginRight: 4
  }
}))

export const Container = ({ children, color, minify, clickAble }) => {
  const classes = useStyles({ color, minify, clickAble })
  return <div className={classes.container}>{children}</div>
}

export const Title = ({ children, minify }) => {
  const classes = useStyles({ minify })
  return <div className={classes.title}>{children}</div>
}

export const ValueRow = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.valueRow}>{children}</div>
}

export const ValueIcon = ({ children }) => {
  const classes = useStyles()
  return <div className={classes.valueIcon}>{children}</div>
}

export const Value = ({ children, color, minify }) => {
  const classes = useStyles({ color, minify })
  return <div className={classes.value}>{children}</div>
}
