import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconFullScreen = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8536 2.14645C14.0488 2.34171 14.0488 2.65829 13.8536 2.85355L9.85355 6.85355C9.65829 7.04882 9.34171 7.04882 9.14645 6.85355C8.95118 6.65829 8.95118 6.34171 9.14645 6.14645L13.1464 2.14645C13.3417 1.95118 13.6583 1.95118 13.8536 2.14645Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.85355 9.14645C7.04882 9.34171 7.04882 9.65829 6.85355 9.85355L2.85355 13.8536C2.65829 14.0488 2.34171 14.0488 2.14645 13.8536C1.95118 13.6583 1.95118 13.3417 2.14645 13.1464L6.14645 9.14645C6.34171 8.95118 6.65829 8.95118 6.85355 9.14645Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2.5C10 2.22386 10.2239 2 10.5 2H13.5C13.7761 2 14 2.22386 14 2.5C14 2.77614 13.7761 3 13.5 3H10.5C10.2239 3 10 2.77614 10 2.5Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 13.5C2 13.2239 2.22386 13 2.5 13H5.5C5.77614 13 6 13.2239 6 13.5C6 13.7761 5.77614 14 5.5 14H2.5C2.22386 14 2 13.7761 2 13.5Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 2C13.7761 2 14 2.22386 14 2.5V5.5C14 5.77614 13.7761 6 13.5 6C13.2239 6 13 5.77614 13 5.5V2.5C13 2.22386 13.2239 2 13.5 2Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 10C2.77614 10 3 10.2239 3 10.5V13.5C3 13.7761 2.77614 14 2.5 14C2.22386 14 2 13.7761 2 13.5V10.5C2 10.2239 2.22386 10 2.5 10Z"
      fill="#26415E"
    />
  </SvgIcon>
)
IconFullScreen.displayName = 'IconFullScreen'
IconFullScreen.muiName = 'SvgIcon'

export default IconFullScreen
