import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  autoFillInput: {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset'
    }
  },
  regPortfolio: {
    ...textTheme.typography.p2,
    fontSize: '14px',
    fontWeight: 375,
    width: '64px', // props => props.textboxWidth,
    '&::placeholder': {
      ...textTheme.typography.p2,
      color: themeColorPalette.palette.sys_trad.text.main,
      fontSize: '14px',
      fontWeight: 375
    },
    '&.Mui-disabled': {
      ...textTheme.typography.p3,
      fontSize: '14px',
      fontWeight: 325,
      '&::placeholder': {
        ...textTheme.typography.p3,
        color: themeColorPalette.palette.sys_trad.text.main,
        fontSize: '14px',
        fontWeight: 325
      }
    }
  },
  regPortfolioEndTextActive: {
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.sys_trad.text2.main,
    fontSize: '14px',
    fontWeight: 375,
    paddingLeft: '4px'
  },
  regPortfolioEndTextDisabled: {
    ...textTheme.typography.p3,
    fontSize: '14px',
    fontWeight: 325,
    paddingLeft: '4px'
  },
  textboxDefault: {
    width: (props) => props.textboxWidth,
    marginLeft: (props) => props.texboxMargin,
    '& label + .MuiInput-formControl': {
      marginTop: '14px'
    },
    backgroundColor: themeColorPalette.palette.white.main,
    '&:focused': {
      borderBottom: '2px solid ' + themeColorPalette.palette.sys_trad.focus.main
    }
  },
  textboxDefaultAlt: {
    width: (props) => props.textboxWidth,
    marginLeft: (props) => props.textboxMargin,
    '& label + .MuiInput-formControl': {
      marginTop: '20px',
      padding: '8px'
    },
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    ':focus': {
      border: '2px solid ' + themeColorPalette.palette.sys_trad.focus.main
    }
  },
  searchTextbox: {
    width: (props) => (props.textboxWidth ? props.textboxWidth : '100%'),
    marginLeft: (props) => props.textboxMargin,
    '& .MuiInputBase-input': {
      ...textTheme.typography.p3,
      lineHeight: '16px',
      padding: 8
    },
    backgroundColor: themeColorPalette.palette.white.main,
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
    borderRadius: '2px 2px 0px 0px',
    ':focus': {
      border: '2px solid ' + themeColorPalette.palette.sys_trad.selected.main
    }
  },
  urlInput: {
    width: (props) => (props.textboxWidth ? props.textboxWidth : '100%'),
    marginLeft: (props) => props.textboxMargin,
    '& .MuiInputBase-input': {
      ...textTheme.typography.p3,
      lineHeight: '16px',
      padding: 15
    },
    backgroundColor: themeColorPalette.palette.white.main,
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
    borderRadius: '2px 2px 0px 0px',
    ':focus': {
      border: '2px solid ' + themeColorPalette.palette.sys_trad.selected.main
    }
  },
  searchIcon: {
    marginTop: 6,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  defaultHover: {
    '&:hover': {
      cursor: 'default'
    }
  },
  helperText: {
    fontSize: '12px',
    height: '14px',
    fontWeight: 500,
    textAlign: 'left',
    margin: '0px',
    backgroundColor: themeColorPalette.palette.white.main,
    '&.MuiFormHelperText-root.Mui-error': {
      color: themeColorPalette.palette.sys_trad.error.main
    }
  },
  noBoxShadowInput: {
    width: (props) => (props.textboxWidth ? props.textboxWidth : '100%'),
    '& .MuiInputBase-input': {
      ...textTheme.typography.p3
    },
    padding: (props) => props.textBoxPadding || 'inherit'
  },
  inputLabelClass: {
    height: '14px',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    boxSizing: 'border-box',
    fontWeight: 650,
    color: themeColorPalette.palette.sys_trad.text2.main,
    textAlign: 'left',
    lineHeight: '14px',
    fontSize: '13px',
    margin: (props) =>
      props.textboxClass === 'textboxDefaultAlt' ? '8px' : '0px',
    '&.Mui-focused': {
      color: themeColorPalette.palette.sys_trad.text2.main
    },
    '&.Mui-error': {
      color: themeColorPalette.palette.sys_trad.text2.main
    }
  },
  textboxInput: {
    height: (props) => props.textboxHeight,
    minWidth: (props) =>
      props.textboxWidth
        ? props.textboxWidth
        : props.minWidthFalse
        ? '150px'
        : '304px',
    fontSize: '12px',
    '&.MuiInput-underline:before': {
      borderBottom:
        '1px solid ' + themeColorPalette.palette.sys_trad.border.main
    },
    '&:hover': {
      '&.MuiInput-underline:before': {
        borderBottom:
          '1px solid ' + themeColorPalette.palette.sys_trad.selected.main
      }
    },
    '&.MuiInput-underline:after': {
      height: '1px',
      borderBottom:
        '1px solid ' + themeColorPalette.palette.sys_trad.selected.main
    },
    '&.Mui-focused': {
      color: '2px solid ' + themeColorPalette.palette.sys_trad.focus.main
    },
    '&.MuiInput-underline:focus': {
      border: '2px solid ' + themeColorPalette.palette.sys_trad.focus.main
    },
    '&.Mui-error': {
      '&.MuiInput-underline:after': {
        height: '1px',
        borderBottomColor: themeColorPalette.palette.sys_trad.error.main
      },
      '&.MuiInput-underline:focus': {
        border: '2px solid ' + themeColorPalette.palette.sys_trad.focus.main
      }
    },
    '& input:-internal-autofill-selected': {
      backgroundColor: 'yellow !important'
    }
  },

  textboxInputErrorState: {
    color: themeColorPalette.palette.sys_trad.error.main
  },
  charCount: {
    fontSize: '11px',
    height: '14px',
    fontWeight: 600,
    textAlign: 'right',
    lineHeight: '14px',
    position: 'absolute',
    fontFamily: 'sans-serif',
    right: '8px',
    top: (props) =>
      props.textboxClass === 'textboxDefaultAlt' ? '8px' : '0px',
    color: themeColorPalette.palette.sys_trad.text2.main,
    '&.MuiFormHelperText-root.Mui-error': {
      color: themeColorPalette.palette.sys_trad.error.main
    }
  },
  focused: {}
}))
