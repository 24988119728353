import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconScoreQuality1 = (props) => (
  <SvgIcon {...props}>
    <rect x="1" y="11" width="3" height="4" rx="1.5" fill="#26415E" />
    <rect x="5" y="7" width="3" height="8" rx="1.5" fill="#26415E" />
    <rect x="9" y="3" width="3" height="12" rx="1.5" fill="#26415E" />
    <rect x="13" y="1" width="3" height="14" rx="1.5" fill="#26415E" />
  </SvgIcon>
)
IconScoreQuality1.displayName = 'IconScoreQuality1'
IconScoreQuality1.muiName = 'SvgIcon'

export default IconScoreQuality1
