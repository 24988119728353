import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  group: {
    alignItems: 'flex-end',
    '&.MuiButtonGroup-root': {
      width: (props) => props.groupButtonWidth,
      borderRadius: '2px'
    }
  },
  button: {
    ...textTheme.typography.p1,
    borderRadius: '2px !important',
    boxShadow: 'none',
    boxSizing: 'border-box',
    textAlign: 'center',
    textTransform: 'none',
    outline: 'none',
    height: (props) => (props.buttonHeight ? props.buttonHeight : '24px'),
    padding: '4px 16px',
    marginLeft: (props) => props.buttonMarginLeft,
    marginTop: (props) => props.buttonMarginTop,
    marginBottom: (props) => props.buttonMarginBotom,
    width: (props) => props.buttonWidth,
    maxWidth: (props) => props.buttonMaxWidth && props.buttonMaxWidth,
    '&.MuiButtonGroup-groupedOutlinedHorizontal:not(:first-child)': {
      marginLeft: 0
    },
    '&.Mui-disabled': {
      backgroundColor: themeColorPalette.palette.sys_trad.disabled.main,
      color: themeColorPalette.palette.white.main,
      boxShadow: 'none',
      lineHeight: 'normal',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.disabled.main,
        color: themeColorPalette.palette.white.main,
        lineHeight: 'normal',
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.disabled.main,
        color: themeColorPalette.palette.white.main,
        lineHeight: 'normal',
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto',
        outline: 'none'
      }
    }
  },
  buttonPrimaryClicked: {
    ...textTheme.typography.p3,
    backgroundColor: themeColorPalette.palette.sys_trad.main.main,
    border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
    color: themeColorPalette.palette.white.main,
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover.main,
      borderColor: themeColorPalette.palette.sys_trad.hover.main,
      color: themeColorPalette.palette.white.main,
      outline: 'none',
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: themeColorPalette.palette.sys_trad.main.main,
      color: themeColorPalette.palette.white.main,
      boxShadow: 'none'
    }
  },
  buttonPrimary: {
    ...textTheme.typography.p3,
    textTransform: 'none',
    border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
    backgroundColor: themeColorPalette.palette.white.main,
    color: themeColorPalette.palette.sys_trad.main.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover.main,
      borderColor: themeColorPalette.palette.sys_trad.hover.main,
      color: themeColorPalette.palette.white.main,
      outline: 'none',
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: themeColorPalette.palette.sys_trad.main.main,
      color: themeColorPalette.palette.white.main,
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover.main,
        lineHeight: 'normal',
        outline: 'none',
        boxShadow: 'none'
      }
    },
    '&.Mui-selected': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      boxShadow: 'none',
      color: themeColorPalette.palette.white.main,
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover.main,
        lineHeight: 'normal',
        outline: 'none',
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
        boxShadow: 'none'
      },
      '&.Mui-disabled': {
        backgroundColor: 'red'
      }
    },
    '&.Mui-disabled': {
      color: themeColorPalette.palette.sys_trad.disabled.main,
      borderColor: themeColorPalette.palette.sys_trad.disabled.main,
      backgroundColor: themeColorPalette.palette.white.main,
      boxShadow: 'none',
      lineHeight: 'normal',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.white.main,
        color: themeColorPalette.palette.sys_trad.disabled.main,
        lineHeight: 'normal',
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        backgroundColor: 'red',
        lineHeight: 'normal',
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto',
        outline: 'none'
      }
    }
  },
  groupLabel: {
    fontWeight: 375,
    fontSize: 12,
    color: themeColorPalette.palette.sys_trad.text2.main,
    display: 'inline-block',
    alignItems: 'center',
    height: 24,
    marginRight: 8,
    marginTop: 18
  }
}))
