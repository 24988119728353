import clsx from 'clsx'
import Link from '@material-ui/core/Link'
import PropTypes from 'prop-types'
import React from 'react'
import { useStyles } from './styles'

/**
 * @visibleName Links
 */
const PrimaryLink = (props) => {
  const classes = useStyles(props)
  return (
    <Link
      {...(props.isUpdatesTable ? { heap_id: 'Updates' } : {})}
      {...(props.isLeadLagTable ? { heap_id: 'leadlag' } : {})}
      id={props.id}
      padding="0px 8px 0px 8px"
      component={props.component}
      onClick={props.linkClass !== 'linkDisable' ? props.onClick : undefined}
      className={clsx(classes.link, classes[props.linkClass], {
        'Mui-selected': props.linkSelected
      })}
    >
      {props.linkText}
    </Link>
  )
}

PrimaryLink.propTypes = {
  /** Link id */
  id: PropTypes.string,
  /** Class for link style: linkDefault, linkInline, linkHeader */
  linkClass: PropTypes.string,
  /** Link text */
  linkText: PropTypes.string,
  /** Link margin (left) */
  linkMargin: PropTypes.string,
  /** Link width */
  linkWidth: PropTypes.string,
  /** Link component */
  component: PropTypes.string,
  /** Link selected */
  linkSelected: PropTypes.bool,
  /** Gets called when the user clicks on the Link */
  onClick: PropTypes.func,
  /** True - switches inline link from black to blue */
  linkColorChange: PropTypes.bool,
  /** Link Font Weight Number */
  linkFontWeight: PropTypes.number,
  /** Link Font 10px */
  reducedSize: PropTypes.bool,
  /** Link Font 13px for default class only*/
  reducedSize13: PropTypes.bool,
  /** Link margin (top) */
  linkMarginTop: PropTypes.string,
  /** Link margin (right) */
  linkMarginRight: PropTypes.string,
  /** Link background color */
  linkBgColor: PropTypes.string
}

export default PrimaryLink
