import React from 'react'
import { Page, PrimaryButton, PrimaryModal } from 'components'
import { useOktaAuth } from '@okta/okta-react'
import { Redirect } from 'react-router-dom'
import { PAGE_CON } from 'constant/constants'

const InvalidEntitlement = () => {
  const [open, setOpen] = React.useState(true)
  const { oktaAuth, authState } = useOktaAuth()

  const handleCloseDialog = () => {
    setOpen(false)
    handleLogOut()
  }

  const handleLogOut = async () => {
    if (authState.isAuthenticated) {
      await oktaAuth.tokenManager.clear()
      window.localStorage.removeItem('role')
      window.localStorage.removeItem('displayESGVisible')
      await oktaAuth.signOut()
    }
    return <Redirect to="/login" />
  }

  return (
    <Page title={PAGE_CON.PORT_REV_TITLE}>
      <PrimaryModal
        id="invalid-entitlements-test-id"
        modalOpen={open}
        onModalClose={handleCloseDialog}
        modalClass="smallModal"
        modalTitle={'Invalid Entitlement'}
        modalContentComponent={
          <>
            <p>Invalid product combinations.</p>
            Please contact{' '}
            <a href="mailto:clientservices@moodys.com">
              clientservices@moodys.com
            </a>{' '}
            for further assistance.
          </>
        }
        modalActionComponent={
          <PrimaryButton
            id="invalid-entitlements-button-test-id"
            buttonText="Ok"
            onClick={handleCloseDialog}
            buttonClass="buttonPrimary"
          />
        }
      />
    </Page>
  )
}

export default InvalidEntitlement
