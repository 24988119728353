import { PortfolioService } from 'services'
import { GlobalContext } from '../../context/GlobalStateProvider'
import { PAGE_CON, REDUCER_TYPE } from '../../constant/constants'
import { useOktaAuth } from '@okta/okta-react'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import Error from '../../utils/error'
import PortfolioSearch from '../portfolioanalysis/PortfolioSearch'
import { PrimaryButton, PrimaryModal } from 'components'
import React, { useContext } from 'react'
import { UserService } from 'services'

function getUniqueId(actionData) {
  return actionData + '$$' + uuidv4()
}

const PortfolioComp = (props) => {
  const { authState } = useOktaAuth()
  const [openPortfolioSearch, setOpenPortfolioSearch] = React.useState(false)
  const context = useContext(GlobalContext)
  const [filterState, filterDispatch] = context.globalFilter
  const [, globalDispatch] = context.globalManager

  let apiCallId = filterState.apiCallId

  const getPortfolios = async () => {
    let portfolios = []
    const { data } = await PortfolioService.getPortfolioDetails({
      params: { cancelToken: filterState.cancelToken.token },
      queryParams: ''
    })

    for (const [, value] of Object.entries(data.portfolios)) {
      portfolios.push({
        key: value.portfolio_id,
        [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: value.portfolio_name,
        [PAGE_CON.CREATED_DATE]: value[PAGE_CON.CREATED_DATE],
        [PAGE_CON.UPDATED_DATE]: value[PAGE_CON.UPDATED_DATE],
        [PAGE_CON.UPLOAD_DATE_UTC]: value[PAGE_CON.UPLOAD_DATE_UTC]
      })
    }

    return portfolios
  }

  const getFilterData = async (portfolioId) => {
    let retData = { regions: [], sectors: [], asOfDate: [] }

    let regions = PAGE_CON.ALL_FILTER
    let sectors = PAGE_CON.ALL_FILTER

    const { data } = await PortfolioService.filterPortfolio({
      portfolio_id: portfolioId,
      regions: regions,
      sectors: sectors,
      token: filterState.cancelToken.token
    })

    if (data.regions) {
      data.regions.map((item) => {
        return retData.regions.push({
          key: item.region_code,
          [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: item.region_name
        })
      })
    }

    if (data.sectors) {
      data.sectors.map((item) => {
        return retData.sectors.push({
          key: item.sector_code,
          [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: item.sector_name
        })
      })
    }

    if (data.as_of_dates) {
      data.as_of_dates.map((item) => {
        return retData.asOfDate.push({
          key: item.date_code,
          [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: item.display_date_name
        })
      })
    }

    return retData
  }

  const getLastViewedPortfolio = async () => {
    const cancelToken = filterState.cancelToken.token
    const data = await UserService.getUserProfile({ cancelToken })

    return data['last-viewed-portfolio']
  }

  const saveLastViewedPortfolio = async (portfolio_id) => {
    const cancelToken = filterState.cancelToken.token
    await UserService.updateUserProfile({ portfolio_id, cancelToken })
  }

  const loadData = async () => {
    if (
      !filterState.isLoading &&
      Object.keys(filterState.filterData.portfolioList).length <= 0
    ) {
      try {
        filterDispatch({
          type: REDUCER_TYPE.LOAD_DATA_START,
          isPortfolioLoading: true
        })
        let user = filterState.globalPortfolioFilter.user
        if (user === '') {
          user = authState.idToken.claims.preferred_username
        }

        let portfolioList = await getPortfolios()
        let lastViewedPortfolio = await getLastViewedPortfolio()

        let portfolio = ''
        if (
          portfolioList.filter(
            (portfolio) => portfolio.key === lastViewedPortfolio
          ).length > 0
        ) {
          portfolio = lastViewedPortfolio
        } else {
          if (
            portfolioList.filter(
              (portfolio) => portfolio.key === PAGE_CON.SAMPLE_PORTFOLIO_ID
            ).length > 0
          ) {
            portfolio = PAGE_CON.SAMPLE_PORTFOLIO_ID
          } else {
            portfolio = portfolioList[0].key
          }

          await saveLastViewedPortfolio(portfolio)
        }

        let filterData = await getFilterData(portfolio)

        let regionsList = filterData.regions
        let sectorsList = filterData.sectors
        let asOfDateList = filterData.asOfDate
        let asOfDate =
          asOfDateList && asOfDateList.length > 0 ? asOfDateList[0].key : ''
        let asOfDateDefaultVal = asOfDate

        let benchmarkList = portfolioList

        filterDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          listData: {
            portfolioList,
            regionsList,
            sectorsList,
            asOfDateList,
            benchmarkList
          },
          filterVal: { user, portfolio, asOfDate, asOfDateDefaultVal },
          isPortfolioLoading: false
        })

        filterDispatch({
          type: REDUCER_TYPE.UPDATED_ACTION,
          actionData: getUniqueId(PAGE_CON.FILTER_PORTFOLIO)
        })
      } catch (err) {
        if (!axios.isCancel(err)) {
          filterDispatch({
            type: REDUCER_TYPE.LOAD_DATA_ERROR,
            errorOccuredOn: PAGE_CON.INITIAL_LOAD
          })
        }
      }
    }
  }

  const onProfileUploadloadData = async () => {
    try {
      filterDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        isPortfolioLoading: true
      })
      globalDispatch({ type: REDUCER_TYPE.RESET_ALL, payload: context })

      let portfolioList = await getPortfolios()
      let lastViewedPortfolio = await getLastViewedPortfolio()
      let portfolio = ''

      if (
        portfolioList.filter(
          (portfolio) => portfolio.key === lastViewedPortfolio
        ).length > 0
      ) {
        portfolio = lastViewedPortfolio
      } else {
        if (
          portfolioList.filter(
            (portfolio) => portfolio.key === PAGE_CON.SAMPLE_PORTFOLIO_ID
          ).length > 0
        ) {
          portfolio = PAGE_CON.SAMPLE_PORTFOLIO_ID
        } else {
          portfolio = portfolioList[0].key
        }

        await saveLastViewedPortfolio(portfolio)
      }

      let filterData = await getFilterData(portfolio)

      let regionsList = filterData.regions
      let regions = ''

      let sectorsList = filterData.sectors
      let sectors = ''

      let asOfDateList = filterData.asOfDate
      let asOfDate =
        asOfDateList && asOfDateList.length > 0 ? asOfDateList[0].key : ''
      let asOfDateDefaultVal = asOfDate

      let benchmarkList = portfolioList
      let benchmark = ''

      filterDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        listData: {
          portfolioList,
          regionsList,
          sectorsList,
          asOfDateList,
          benchmarkList
        },
        filterVal: {
          portfolio,
          regions,
          sectors,
          asOfDate,
          asOfDateDefaultVal,
          benchmark
        },
        isPortfolioLoading: false,
        apiCallId
      })

      filterDispatch({
        type: REDUCER_TYPE.UPDATED_ACTION,
        actionData: getUniqueId(PAGE_CON.FILTER_PORTFOLIO),
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(err.message)
        filterDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          errorOccuredOn: PAGE_CON.UPLOAD_PORTFOLIO,
          apiCallId
        })
      }
    }
  }

  const onProfileChangeloadData = async (portfolioId) => {
    try {
      filterDispatch({ type: REDUCER_TYPE.LOAD_DATA_START })
      globalDispatch({ type: REDUCER_TYPE.RESET_ALL, payload: context })

      let filterData = await getFilterData(portfolioId)

      let regionsList = filterData.regions
      let regions = ''

      let sectorsList = filterData.sectors
      let sectors = ''

      let asOfDateList = filterData.asOfDate
      let asOfDate =
        asOfDateList && asOfDateList.length > 0 ? asOfDateList[0].key : ''
      let asOfDateDefaultVal = asOfDate

      let benchmark = ''

      await saveLastViewedPortfolio(portfolioId)

      filterDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        listData: { regionsList, sectorsList, asOfDateList },
        filterVal: {
          regions,
          sectors,
          asOfDate,
          asOfDateDefaultVal,
          benchmark
        },
        apiCallId
      })

      filterDispatch({
        type: REDUCER_TYPE.UPDATED_ACTION,
        actionData: getUniqueId(PAGE_CON.FILTER_PORTFOLIO),
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.log(err.message)
        filterDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          errorOccuredOn: PAGE_CON.PROFILE_CHANGE,
          apiCallId
        })
      }
    }
  }

  React.useEffect(() => {
    loadData()
  }, [])

  React.useEffect(() => {
    let actionPerformedOn = filterState.actionPerformedOn.split('$$')[0]

    switch (actionPerformedOn) {
      case PAGE_CON.FILTER_PORTFOLIO:
        onProfileChangeloadData(filterState.globalPortfolioFilter.portfolio)
        break
      default:
        break
    }
  }, [filterState.actionPerformedOn])

  React.useEffect(() => {
    if (
      filterState.updatePortfolioFromComponent === PAGE_CON.UPLOAD_PORTFOLIO
    ) {
      filterDispatch({
        type: REDUCER_TYPE.RESET_UPDATE_PORTFOLIO_FROM_COMPONENT
      })
      onProfileChangeloadData(filterState.globalPortfolioFilter.portfolio)
    }
  }, [filterState.globalPortfolioFilter.portfolio])

  const onPortfolioChange = (portfolioId) => {
    setOpenPortfolioSearch(false)
    if (portfolioId === filterState.globalPortfolioFilter.portfolio) return

    filterDispatch({
      type: REDUCER_TYPE.UPDATE_FILTER,
      payload: portfolioId,
      name: PAGE_CON.FILTER_PORTFOLIO,
      actionPerformedOn: getUniqueId(PAGE_CON.FILTER_PORTFOLIO)
    })
  }

  const errorDialogClicked = () => {
    filterDispatch({ type: REDUCER_TYPE.RESET_LOAD_DATA_ERROR })
    switch (filterState.errorOccuredOn) {
      case PAGE_CON.INITIAL_LOAD:
        loadData()
        break
      case PAGE_CON.UPLOAD_BENCHMARK:
      case PAGE_CON.UPLOAD_PORTFOLIO:
        onProfileUploadloadData()
        break
      case PAGE_CON.PROFILE_CHANGE:
        onProfileChangeloadData(filterState.globalPortfolioFilter.portfolio)
        break
      default:
        break
    }
  }

  const portfolioName = () => {
    let portName = ''
    let selectedPortfolio = filterState.filterData.portfolioList.filter(
      (portfolio) =>
        portfolio.key === filterState.globalPortfolioFilter.portfolio
    )
    if (selectedPortfolio.length > 0)
      portName = selectedPortfolio[0][PAGE_CON.DROPDOWN_DISPLAY_COLUMN]
    return portName
  }

  return (
    <div key={props.key} id={props.key}>
      <PrimaryModal
        id="functionality-error-test-id"
        modalOpen={filterState.dataStatus === PAGE_CON.ERROR ? true : false}
        modalClass="errorModal"
        modalTitle=""
        modalContentComponent={
          <Error
            id={'updatesError'}
            dataStatus={PAGE_CON.ERROR}
            onErrorClick={errorDialogClicked}
            errorClass={'noMargin'}
          />
        }
        modalNoCloseOnClick={true}
        showCloseIcon={false}
      />
      <PrimaryModal
        id="portfolio-search-test-id"
        modalOpen={openPortfolioSearch}
        onModalClose={() => setOpenPortfolioSearch(false)}
        modalClass="smallModalWithHeight"
        hideModalXScroll={true}
        modalContentComponent={[
          <PortfolioSearch
            id={'prop-search'}
            key={'prop-search'}
            onUploadClick={() => setOpenPortfolioSearch(false)}
            onPortfolioClick={onPortfolioChange}
          />
        ]}
        showCloseIcon={false}
      />
      {!props.showPortfolioComp ? (
        ''
      ) : filterState.isPortfolioLoading ? (
        PAGE_CON.DROPDOWN_LOADING_TEXT
      ) : (
        <PrimaryButton
          id="button-holdings"
          heap_id="portfolio-selection"
          heap_title={portfolioName()}
          buttonSelected={true}
          buttonText={portfolioName()}
          buttonClass="borderLessButton"
          buttonHeight={22.5}
          buttonPadding={'4px 8px 4px 8px'}
          showTitle={true}
          showEllipsis={true}
          buttonMaxWidth={150}
          onClick={() => setOpenPortfolioSearch(true)}
        />
      )}
    </div>
  )
}

PortfolioComp.defaultProps = {
  showPortfolioComp: true
}

export default PortfolioComp
