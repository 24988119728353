import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconCoffeeMug = (props) => (
  <SvgIcon {...props} fill="none">
    <path
      d="M0.5 6.60645H12.4362V13.2554C12.4362 16.5515 9.76417 19.2235 6.46809 19.2235C3.172 19.2235 0.5 16.5515 0.5 13.2554V6.60645Z"
      stroke="#26415E"
    />
    <path
      d="M3.74463 5.08511C3.74463 2.80225 5.44676 3.1627 5.44676 1"
      stroke="#26415E"
      strokeLinecap="round"
    />
    <path
      d="M6.12769 5.08511C6.12769 2.80225 7.82981 3.1627 7.82981 1"
      stroke="#26415E"
      strokeLinecap="round"
    />
    <path
      d="M8.51074 5.08511C8.51074 2.80225 10.2129 3.1627 10.2129 1"
      stroke="#26415E"
      strokeLinecap="round"
    />
    <line
      x1="1.86182"
      y1="8.82983"
      x2="1.86182"
      y2="12.2341"
      stroke="#26415E"
    />
    <path
      d="M12.5957 9.17017C14.541 9.17017 16 9.48115 16 12.142C16 14.7642 14.2978 15.6383 12.5957 15.6383"
      stroke="#26415E"
    />
  </SvgIcon>
)
IconCoffeeMug.displayName = 'IconCoffeeMug'
IconCoffeeMug.muiName = 'SvgIcon'

export default IconCoffeeMug
