import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '200px',
    height: '100vh',
    position: 'fixed',
    top: '0px',
    left: '-100%',
    background: '#ffffff',
    padding: '8px',
    boxSizing: 'border-box',
    zIndex: '999',
    transition: 'left 0.5s',
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.12)',
    flexDirection: 'column'
  },
  isOpen: {
    left: '0px'
  },
  titleContainer: {
    padding: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    height: '32px',
    marginBottom: '16px',
    '& span': {
      cursor: 'pointer'
    }
  },
  menuTitle: {
    color: theme.palette.sys_trad?.text2.main,
    padding: '0px 8px',
    margin: '24px 0 10px 0'
  },
  title: {
    fontWeight: '400'
  }
}))

export const Container = ({ children, isOpen }) => {
  const classes = useStyles(theme)
  return (
    <div className={`${classes.container} ${isOpen && classes.isOpen}`}>
      {children}
    </div>
  )
}

export const Title = ({ children }) => {
  const classes = useStyles(theme)
  return <h5 className={classes.title}>{children}</h5>
}

export const TitleContainer = ({ children }) => {
  const classes = useStyles(theme)
  return <div className={classes.titleContainer}>{children}</div>
}

export const MenuTitle = ({ children }) => {
  const classes = useStyles(theme)
  return <div className={classes.menuTitle}>{children}</div>
}
