import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { PAGE_CON, REDUCER_TYPE } from '../../constant/constants'

const dashboardTable = {
  isLoading: true,
  dataStatus: '',
  data: []
}

const dashboardResearchLines = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const dashboardResearchLineCoverage = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const dashboardMapCoverage = {
  isLoading: true,
  dataStatus: '',
  data: {
    companies: 0,
    totalCompanies: 0
  }
}

const dashboardMap = {
  isLoading: true,
  dataStatus: '',
  data: []
}

const dashboardPortfolioMonitoringControversiesList = {
  isLoading: true,
  dataStatus: '',
  data: {
    last60Days: true,
    list: []
  }
}

const dashboardResearchLinesCoverage = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const dashboardMapEntityList = {
  isLoading: true,
  dataStatus: '',
  data: []
}

const dashboardHeatmapTable = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const dashboardHeatmapDescriptionLeft = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const dashboardHeatmapDescriptionRight = {
  isLoading: true,
  dataStatus: '',
  data: {}
}

const dashboardHeatmapEntity = {
  isLoading: false,
  dataStatus: '',
  data: []
}

const dashboardCompaniesTable = {
  isLoading: true,
  dataStatus: '',
  data: []
}

const downloadDashboardClimateData = {
  isLoading: false,
  dataStatus: '',
  data: {}
}

export function dashboardInitialState() {
  let obj = {}
  obj.apiCallId = uuidv4()
  obj.cancelToken = axios.CancelToken.source()
  obj.dashboardTable = Object.assign({}, { ...dashboardTable, data: [] })
  obj.dashboardResearchLines = Object.assign(
    {},
    { ...dashboardResearchLines, data: {} }
  )
  obj.dashboardResearchLineCoverage = Object.assign(
    {},
    { ...dashboardResearchLineCoverage, data: {} }
  )
  obj.dashboardMapCoverage = Object.assign(
    {},
    { ...dashboardMapCoverage, data: { companies: 0, totalCompanies: 0 } }
  )
  obj.dashboardMap = Object.assign({}, { ...dashboardMap, data: [] })
  obj.dashboardPortfolioMonitoringControversiesList = Object.assign(
    {},
    { ...dashboardPortfolioMonitoringControversiesList, data: [] }
  )
  obj.dashboardResearchLinesCoverage = Object.assign(
    {},
    { ...dashboardResearchLinesCoverage, data: {} }
  )
  obj.dashboardMapEntityList = Object.assign(
    {},
    { ...dashboardMapEntityList, data: [] }
  )
  obj.dashboardHeatmapTable = Object.assign(
    {},
    { ...dashboardHeatmapTable, data: {} }
  )
  obj.dashboardHeatmapDescriptionLeft = Object.assign(
    {},
    { ...dashboardHeatmapDescriptionLeft, data: {} }
  )
  obj.dashboardHeatmapDescriptionRight = Object.assign(
    {},
    { ...dashboardHeatmapDescriptionRight, data: {} }
  )
  obj.dashboardHeatmapEntity = Object.assign(
    {},
    { ...dashboardHeatmapEntity, data: [] }
  )
  obj.dashboardCompaniesTable = Object.assign(
    {},
    { ...dashboardCompaniesTable, data: {} }
  )
  obj.downloadDashboardClimateData = Object.assign(
    {},
    { ...downloadDashboardClimateData, data: {} }
  )
  return obj
}

const DashboardReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.LOAD_DATA_START:
      currentState[action.sectionName].isLoading = true
      currentState[action.sectionName].dataStatus = PAGE_CON.LOADING
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_ERROR:
      if (action.apiCallId && action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.ERROR
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_COMPLETED:
      if (action.apiCallId && action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState[action.sectionName].data = action.data
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.SUCCESS
      return { ...currentState }
    case REDUCER_TYPE.RELOAD_DASHBOARD_COVERAGE:
      if (action.apiCallId && action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState[action.sectionName].data.coverageData = action.data
      return { ...currentState }
    case REDUCER_TYPE.RESET:
      currentState.cancelToken.cancel('cancelling operation')
      return dashboardInitialState()
    default:
      return currentState
  }
}

export default DashboardReducer
