const isObject = (item) =>
  item && typeof item === 'object' && !Array.isArray(item)

const mergeDeep = (target, source) => {
  const output = { ...target }
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] })
        else output[key] = mergeDeep(target[key], source[key])
      } else {
        Object.assign(output, { [key]: source[key] })
      }
    })
  }
  return output
}

const defaultUserSettings = {
  portfolio_settings: {
    companies_wo_emissions_target: false,
    companies_w_no_info: false
  },
  weighted_average_calculations: {
    carbonfootprint: 'investment_value',
    tempalignment: 'enterprise_value'
  },
  predicted_scores_data: {
    carbonfootprint: false,
    esgasmt: false,
    tempalignment: false
  }
}

export const getDefaultUserSettings = (newSettings) => {
  const newUserSettings = mergeDeep(defaultUserSettings, newSettings)
  return newUserSettings
}
