import React from 'react'
import { formattingRules } from './CriteriaFilterData'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import { SMEOnDemand } from 'services'
import { useContext, useEffect, useState } from 'react'
import { Card, IconAdd, PrimaryButton } from 'components'
import { useStyles } from './styles'
import BatchCard from './BatchCard'
import Error from 'utils/error'
import Grid from '@material-ui/core/Grid'
import LoadingMask from 'utils/loadingMask'

const BatchReviewView = (props) => {
  const [onDemandAsmntState, onDemandAsmntDispatch] =
    useContext(GlobalContext).onDemandAsmntService
  const [reportingServiceState] =
    useContext(GlobalContext).globalReportingService
  const { company_count, entity_request_count } =
    onDemandAsmntState.batchRequest.data?.summary || ''

  const portfolio = onDemandAsmntState.portfolio

  const [isHover, setIsHover] = useState(false)
  const [isPressed, setIsPressed] = useState(false)

  const classes = useStyles(props)

  const handleNewRequest = () => {
    onDemandAsmntDispatch({
      type: REDUCER_TYPE.ON_DEMAND_ASMNT_RESET_VIEW,
      sectionName: REDUCER_STATE.ON_DEMAND_ASMNT_CRITERIA_FILTER_VIEW
    })
    onDemandAsmntDispatch({
      type: REDUCER_TYPE.ON_DEMAND_ASMNT_SET_STEP,
      step: PAGE_CON.ON_DEMAND_ASMNT_CRITERIA_FILTER_VIEW,
      prevStep: PAGE_CON.ON_DEMAND_ASMNT_BATCH_REVIEW
    })
  }

  const portfolioName = portfolio?.portfolioName

  const getBatchRequest = async () => {
    onDemandAsmntDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.ON_DEMAND_ASMNT_BATCH_REVIEW
    })

    try {
      const batchRequest = await SMEOnDemand.fetchBatchRequest({
        portfolio: portfolio.key,
        cancelToken: onDemandAsmntState.cancelToken.token
      })
      onDemandAsmntDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.ON_DEMAND_ASMNT_BATCH_REVIEW,
        data: batchRequest
      })
    } catch (e) {
      onDemandAsmntDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        sectionName: REDUCER_STATE.ON_DEMAND_ASMNT_BATCH_REVIEW
      })
    }
  }

  const isLoading = () => onDemandAsmntState.batchRequest?.isLoading

  const isError = () =>
    onDemandAsmntState.batchRequest?.dataStatus === PAGE_CON.ERROR

  const hasData = () => onDemandAsmntState.batchRequest.data?.requests?.length

  const reloadComponent = (errorOccuredOn) => {
    switch (errorOccuredOn) {
      case PAGE_CON.ON_DEMAND_ASMNT_BATCH_REVIEW:
        getBatchRequest()
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (hasData()) return
    if (portfolio?.key !== '') {
      getBatchRequest()
    }
  }, [portfolio])

  return isLoading() ? (
    <LoadingMask />
  ) : isError() ? (
    <Error
      id={'ESGError'}
      dataStatus={PAGE_CON.ERROR}
      onErrorClick={reloadComponent}
      errorOccuredOn={PAGE_CON.ON_DEMAND_ASMNT_BATCH_REVIEW}
    />
  ) : !hasData() ? (
    <Error
      id={'batchReviewErr'}
      dataStatus={PAGE_CON.SUCCESS}
      errorOccuredOn={PAGE_CON.ON_DEMAND_ASMNT_BATCH_REVIEW}
    />
  ) : (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <span className={classes.batchReviewTitle}>
          On-Demand Assessment Requests for {portfolioName}
        </span>
        <div className={classes.batchReviewSubHeader}>
          {formattingRules(portfolio?.on_demand_coverage || 0)} assessment
          eligible ({company_count} companies, {entity_request_count} requested)
        </div>
      </Grid>
      {onDemandAsmntState.batchRequest.data?.requests.map((request, index) => {
        return (
          <div
            className={classes.batchReviewCard}
            key={'batch-review-card-' + index}
          >
            <Card
              cardClass="shadow"
              headerTitle={'Open Assessment Request'}
              components={[<BatchCard data={request} key={'batchcard'} />]}
            />
          </div>
        )
      })}
      {reportingServiceState.assessmentLimit.data?.remaining_assessments ? (
        <div style={{ padding: '16px 0' }}>
          <PrimaryButton
            id="create-new-request-icon"
            buttonText="Create New Request"
            buttonTextClass="greyText"
            buttonClass="borderLessButtonNoHover"
            buttonWidth="auto"
            buttonIcon={
              <IconAdd
                ishover={isHover.toString()}
                ispressed={isPressed.toString()}
              />
            }
            buttonIconPosition="start"
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            onMouseDown={() => {
              setIsHover(false)
              setIsPressed(true)
            }}
            onMouseUp={() => {
              setIsHover(true)
              setIsPressed(false)
            }}
            onClick={handleNewRequest}
          />
        </div>
      ) : (
        ''
      )}
    </Grid>
  )
}

export default BatchReviewView
