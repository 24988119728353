import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconArrowUp = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 -0.00146484L9.93204 6.74841C10.071 6.98702 9.99029 7.29313 9.75168 7.43213C9.51307 7.57112 9.20696 7.49037 9.06796 7.25176L7 3.70183V15.5001C7 15.7762 6.77614 16.0001 6.5 16.0001C6.22386 16.0001 6 15.7762 6 15.5001V-0.00146484Z"
      fill="#A38355"
    />
  </SvgIcon>
)
IconArrowUp.displayName = 'IconArrowUp'
IconArrowUp.muiName = 'SvgIcon'

export default IconArrowUp
