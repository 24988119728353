import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconEyeOpen = (props) => (
  <SvgIcon {...props}>
    <path
      d="M15.9093 7.70503C13.8002 4.67022 10.9894 3 8 3C5.0106 3 2.19997 4.67001 0.0906778 7.70503C-0.0302259 7.88064 -0.0302259 8.11935 0.0906778 8.29497C2.1998 11.3298 5.0106 13 8 13C10.9894 13 13.8 11.3302 15.9093 8.29497C16.0302 8.11936 16.0302 7.88065 15.9093 7.70503ZM8 11.9995C5.41531 11.9995 2.96265 10.5846 1.06078 7.99979C2.94401 5.41495 5.41575 4.00006 8 4.00006C10.5847 4.00006 13.0374 5.41495 14.9392 7.99979C13.0372 10.5846 10.5847 11.9995 8 11.9995ZM8 5.00003C7.25079 5.00003 6.5323 5.31609 6.00265 5.87873C5.47285 6.4412 5.17524 7.20423 5.17524 7.99987C5.17524 8.79551 5.47285 9.55854 6.00265 10.121C6.5323 10.6837 7.25079 10.9997 8 10.9997C8.74921 10.9997 9.4677 10.6837 9.99735 10.121C10.5271 9.55854 10.8248 8.79551 10.8248 7.99987C10.8248 7.20423 10.5271 6.4412 9.99735 5.87873C9.4677 5.31609 8.74921 5.00003 8 5.00003ZM8 9.99991C7.50062 9.99991 7.02158 9.78915 6.66833 9.41417C6.31523 9.03902 6.11677 8.53029 6.11677 7.99996C6.11677 7.46963 6.31523 6.9609 6.66833 6.58575C7.02158 6.21077 7.50062 6.00001 8 6.00001C8.49938 6.00001 8.97842 6.21077 9.33167 6.58575C9.68477 6.9609 9.88323 7.46963 9.88323 7.99996C9.88323 8.53029 9.68477 9.03902 9.33167 9.41417C8.97842 9.78915 8.49938 9.99991 8 9.99991Z"
      fill={props.fillcolor || '#FFFFFF'}
      style={{ transform: 'translate(0px, 3px) scale(1.2)' }}
    />
  </SvgIcon>
)
IconEyeOpen.displayName = 'IconEyeOpen'
IconEyeOpen.muiName = 'SvgIcon'

export default IconEyeOpen
