import ButtonGroup from '@material-ui/core/ButtonGroup'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { useStyles } from './styles'

/**
 * @visibleName GroupButton
 */
const GroupButton = ({
  defaultButton,
  onChange,
  label,
  id,
  buttonDisabled,
  groupButtonContentComponent,
  buttonClass,
  disable,
  isViewPanel,
  heap_id,
  ...styles
}) => {
  const classes = useStyles(styles)
  const [selectedItem, setSelectedItem] = React.useState(defaultButton)

  useEffect(() => {
    setSelectedItem(defaultButton)
  }, [defaultButton])

  const onSelect = (selectedKey) => {
    if (selectedKey === selectedItem) return
    setSelectedItem(selectedKey)

    if (onChange) onChange(selectedKey)
  }

  return (
    <>
      {label && <div className={classes.groupLabel}>{label}</div>}

      <ButtonGroup
        id={id}
        disabled={buttonDisabled}
        disableRipple
        disableFocusRipple
        className={classes.group}
        variant="outlined"
      >
        {groupButtonContentComponent.length > 0
          ? groupButtonContentComponent.map((item, index) => (
              <Button
                className={clsx(
                  classes.button,
                  selectedItem === item.buttonKey
                    ? classes[buttonClass + 'Clicked']
                    : classes[buttonClass],
                  {
                    'Mui-disabled': item.buttonDisabled,
                    'Mui-selected': item.buttonSelected
                  }
                )}
                {...index}
                disabled={item.buttonDisabled}
                onClick={() => !disable && onSelect(item.buttonKey)}
                key={item.buttonKey}
                {...(heap_id && isViewPanel
                  ? {
                      heap_id: heap_id,
                      heap_view_panel: item.labelText.split(' ').pop()
                    }
                  : {})}
              >
                {item.labelText}
              </Button>
            ))
          : null}
      </ButtonGroup>
    </>
  )
}

GroupButton.propTypes = {
  /** Button id */
  id: PropTypes.string,
  /** Group Label */
  label: PropTypes.string,
  /** Class for all button styles: buttonPrimary*/
  buttonClass: PropTypes.string,
  /** Button individual margin left */
  buttonMarginLeft: PropTypes.string,
  /** Button individual margin top */
  buttonMarginTop: PropTypes.string,
  /** Button individual margin bottom */
  buttonMarginBotom: PropTypes.string,
  /** Button individual width */
  buttonWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Group Button width */
  groupButtonWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Button individual max width */
  buttonMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Button individual disabled */
  disable: PropTypes.bool,
  /** Button individual selected */
  buttonSelected: PropTypes.bool,
  /** Gets called when the user clicks on the button */
  onClick: PropTypes.func,
  /** Button individual height */
  buttonHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Button individual padding */
  buttonPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Additional Button Components to include in button group*/
  groupButtonContentComponent: PropTypes.any,
  /** Set Default Preselected Button */
  defaultButton: PropTypes.any
}

export default GroupButton
