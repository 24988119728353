export const REACT_APP_OKTA_BASE_URL_NONPROD =
  'https://mss-moodys.oktapreview.com'
export const REACT_APP_OKTA_CLIENT_ID_NONPROD = '0oafutox9KSQmOqXs1d6'
export const REACT_APP_OKTA_ISSUER_ID_NONPROD =
  'https://mss-moodys.oktapreview.com/oauth2/default'

//UAT
export const REACT_APP_OKTA_CLIENT_ID_UAT_NONPROD = '0oaxpcacmZOpz5Ws91d6'

//PERF
export const REACT_APP_OKTA_CLIENT_ID_PERF_NONPROD = '0oa1i1fz39ygewsTg1d7'

//DEMO
export const REACT_APP_OKTA_CLIENT_ID_DEMO_NONPROD = '0oa8ivopzsI2IZWTV1d7'
