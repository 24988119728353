import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconError = (props) => (
  <SvgIcon {...props}>
    <path
      fill={props.colorprimary ? props.colorprimary : '#B31717'}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.44166 2.26852C8.2537 1.9144 7.74632 1.9144 7.55837 2.26852L1.72128 13.2658C1.54452 13.5988 1.7859 14.0002 2.16292 14.0002H13.8371C14.2141 14.0002 14.4555 13.5988 14.2788 13.2658L8.44166 2.26852ZM6.67508 1.79969C7.23895 0.737345 8.76108 0.737343 9.32495 1.79969L15.162 12.797C15.6923 13.796 14.9682 15.0002 13.8371 15.0002H2.16292C1.03186 15.0002 0.30771 13.796 0.837986 12.797L6.67508 1.79969Z"
    />
    <path
      fill={props.colorprimary ? props.colorprimary : '#B31717'}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.26657 6.7807C7.24013 6.35764 7.57611 6 8 6C8.42388 6 8.75987 6.35764 8.73343 6.7807L8.49902 10.5312C8.48255 10.7947 8.26403 11 8 11C7.73597 11 7.51744 10.7947 7.50097 10.5312L7.26657 6.7807Z"
    />
    <path
      fill={props.colorprimary ? props.colorprimary : '#B31717'}
      d="M8.5 12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5C7.5 12.2239 7.72386 12 8 12C8.27614 12 8.5 12.2239 8.5 12.5Z"
    />{' '}
  </SvgIcon>
)
IconError.displayName = 'IconError'
IconError.muiName = 'SvgIcon'

export default IconError
