import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 0,
    '&:not(.Mui-disabled):hover': {
      '& .MuiSwitch-track': {
        backgroundColor: `${themeColorPalette.palette.sys_trad.hover.main} !important`
      }
    }
  },
  label: {
    color: (props) =>
      props.disabled
        ? 'rgba(0,0,0, 0.38)'
        : themeColorPalette.palette.sys_trad.main.main,
    ...textTheme.typography.p2,
    fontWeight: 'bold',
    fontSize: '13px',
    paddingRight: '5px',
    marginBottom: '2px',
    display: 'flex'
  },
  onDemandAsmntlabel: {
    color: (props) =>
      props.disabled
        ? 'rgba(0,0,0, 0.38)'
        : themeColorPalette.palette.sys_trad.main.main,
    ...textTheme.typography.p3,
    marginRight: '8px',
    display: 'flex'
  },
  reportingLabel: {
    ...textTheme.typography.p1,
    color: (props) =>
      props.disabled
        ? 'rgba(0,0,0, 0.38)'
        : themeColorPalette.palette.sys_trad.main.main,
    fontWeight: 350,
    lineHeight: '14.4px',
    fontSize: '12px',
    paddingLeft: '8px',
    display: 'flex'
  }
}))
