import React from 'react'
import { ResearchLine, ResearchLineCategory } from 'components'
import PropTypes from 'prop-types'
import Error from 'utils/error'
import { PAGE_CON } from 'constant/constants'
import * as S from './summaryHeaderComponents/index.jsx'

const TransitionRisk = ({ data, minify, reloadComponent }) => {
  const temperaturealgmt = data.find(
    ({ researchLine }) => researchLine === 'temperaturealgmt'
  )
  const temperaturealgmtValue = temperaturealgmt?.value

  const carbonfootprint = data.find(
    ({ researchLine }) => researchLine === 'carbonfootprint'
  )
  const carbonfootprintValue = carbonfootprint?.value

  return (
    <S.ResearchLineContainer minify={minify}>
      <ResearchLineCategory minify={minify} title="Transition Risk">
        {(typeof temperaturealgmt == 'undefined' ||
          temperaturealgmt?.status !== 'fulfilled') &&
        !minify ? (
          <Error
            id={'temperaturealgmtError'}
            dataStatus={PAGE_CON.ERROR}
            onErrorClick={reloadComponent}
            errorOccuredOn={PAGE_CON.DASHBOARD_RESEARCH_LINES}
            width={170}
          />
        ) : (
          <ResearchLine
            title="Temperature Alignment"
            label={temperaturealgmtValue?.ranking || '-'}
            research_line="temperature_alignment"
            minify={minify}
          />
        )}
        {(typeof carbonfootprint == 'undefined' ||
          carbonfootprint?.status !== 'fulfilled') &&
        !minify ? (
          <Error
            id={'temperaturealgmtError'}
            dataStatus={PAGE_CON.ERROR}
            onErrorClick={reloadComponent}
            errorOccuredOn={PAGE_CON.DASHBOARD_RESEARCH_LINES}
            width={170}
          />
        ) : (
          <ResearchLine
            title="Carbon Footprint"
            label={carbonfootprintValue?.ranking || '-'}
            research_line="carbon_footprint"
            minify={minify}
          />
        )}
      </ResearchLineCategory>
    </S.ResearchLineContainer>
  )
}

TransitionRisk.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  minify: PropTypes.bool
}

export default TransitionRisk
