import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import {
  IconInformationCircle,
  PrimaryButton,
  PrimaryPopover
} from 'components'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import { GlobalContext } from 'context/GlobalStateProvider'
import { useContext, useEffect, useState } from 'react'
import { SMEOnDemand } from 'services'
import themeColorPalette from 'theme/theme'
import { validateEmail } from '../../utils/validations'
import RequestDetailRow from './RequestDetailRow'
import { useStyles } from './styles'

const RequestDetail = ({ companies = [], readOnly = false }) => {
  const classes = useStyles()
  const [onDemandAsmntState, onDemandAsmntDispatch] =
    useContext(GlobalContext).onDemandAsmntService
  const [reportingServiceState, reportingServiceDispatch] =
    useContext(GlobalContext).globalReportingService
  const [rows, setRows] = useState(
    companies.map((company) => ({ ...company, email: '' }))
  )
  const [limit, setLimit] = useState(20)
  const [filterStatus, setFilterStatus] = useState('*')
  const [modal, setModal] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [hasAllFailedAssessments, setHasAllFailedAssessments] = useState(false)
  const [failedCompanies, setFailedCompanies] = useState(false)
  const [hasDupeEmails, setHasDupeEmails] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [duplicateEmails, setDuplicateEmails] = useState({})
  const [proceedDisabled, setProceedDisabled] = useState(false)
  const portfolio = onDemandAsmntState.portfolio

  const assessmentLimit =
    reportingServiceState.assessmentLimit.data?.remaining_assessments

  useEffect(() => {
    if (requestsCount >= assessmentLimit) {
      setIsValid(false)
    }
  }, [])

  const statuses = [
    { key: '*', value: 'All Statuses', readOnly },
    { key: 'not_sent', value: 'No Request Sent', readOnly: false },
    { key: 'pending', value: 'Pending Activation', readOnly },
    { key: 'in-progress', value: 'In Progress', readOnly },
    { key: 'cancelled', value: 'Cancelled', readOnly },
    { key: 'completed', value: 'Completed', readOnly }
  ].filter(
    ({ key }) => key === '*' || !!rows.find((row) => row.status.value === key)
  )

  const openModal = () => {
    setHasError(false)
    setModal(true)
  }

  const closeModal = () => {
    setModal(false)
  }
  let newDuplicateEmailIds = {}

  const removeRow = (orbis_id) => {
    const filteredRows = rows.filter((row) => orbis_id !== row.orbis_id)

    const duplicateEmailOrbisIds = Object.keys(duplicateEmails)
    newDuplicateEmailIds = { ...duplicateEmails }
    if (duplicateEmailOrbisIds.includes(orbis_id)) {
      delete newDuplicateEmailIds[orbis_id]
      setDuplicateEmails(newDuplicateEmailIds)
    } else {
      const removedRow = rows.find((row) => row.orbis_id === orbis_id)
      if (removedRow.email !== '') {
        const duplicateEmailRows = rows.filter(
          (row) =>
            row.email.toLowerCase() === removedRow.email.toLowerCase() &&
            row.orbis_id !== removedRow.orbis_id
        )
        if (duplicateEmailRows.length)
          delete newDuplicateEmailIds[duplicateEmailRows[0].orbis_id]
        setDuplicateEmails(newDuplicateEmailIds)
      }
    }
    const valid =
      requestsCount - 1 &&
      filteredRows.filter(validateEmail).length === requestsCount - 1 &&
      requestsCount - 1 <= assessmentLimit &&
      !Object.keys(newDuplicateEmailIds).length
    setRows(filteredRows)
    setIsValid(valid)
  }

  const setEmail = (orbis_id, email) => {
    const newRows = rows.map((row) => ({
      ...row,
      email: row.orbis_id === orbis_id ? email : row.email
    }))

    const duplicateEmail = rows.find(
      (item) => item.email.toLowerCase() === email.toLowerCase() && email !== ''
    )
    newDuplicateEmailIds = { ...duplicateEmails }

    if (duplicateEmail) {
      newDuplicateEmailIds[orbis_id] = true
    } else {
      delete newDuplicateEmailIds[orbis_id]
    }
    duplicateEmailCheck(orbis_id, newRows)

    const valid =
      requestsCount &&
      newRows.filter(validateEmail).length === requestsCount &&
      requestsCount <= assessmentLimit &&
      !Object.keys(newDuplicateEmailIds).length
    setIsValid(valid)
    setRows(newRows)
  }

  const duplicateEmailCheck = (orbis_id, newRows) => {
    newRows.forEach((row) => {
      if (orbis_id !== row.orbis_id) {
        const existingDuplicateEmails = newRows.filter(
          (subRow) =>
            row.email.toLowerCase() == subRow.email.toLowerCase() &&
            row.email !== '' &&
            row.orbis_id !== subRow.orbis_id
        )

        if (existingDuplicateEmails.length == 0) {
          delete newDuplicateEmailIds[row.orbis_id]
        }

        if (newDuplicateEmailIds[row.orbis_id]) {
          const duplicateItemsAlreadyInMap = existingDuplicateEmails.every(
            (item) => newDuplicateEmailIds[item.orbis_id]
          )
          if (duplicateItemsAlreadyInMap) {
            delete newDuplicateEmailIds[row.orbis_id]
          }
        }
      }
    })
    setDuplicateEmails(newDuplicateEmailIds)
  }

  const loadMore = () => {
    setLimit(limit + 20)
  }

  const onChangeHandler = (e) => {
    setFilterStatus(e.target.value)
    setLimit(20)
  }

  const confirmRequest = async () => {
    setProceedDisabled(true)
    const { location, sector, scoreRange } =
      onDemandAsmntState?.reqDetailInfo?.data?.info || ''
    const entities = rows
      .filter(({ status }) => status?.value === 'not_sent')
      .map(
        ({
          email: user_email,
          orbis_id,
          name,
          country_code,
          sector_code,
          size
        }) => ({
          user_email: user_email.toLowerCase(),
          orbis_id,
          name,
          country_code,
          sector_code,
          size
        })
      )

    try {
      const data = await SMEOnDemand.submitAssessmentRequest({
        portfolio: portfolio?.key,
        body: {
          entities,
          filter_info: {
            location: location === 'All' ? 'all' : location,
            sector: sector === 'All' ? 'all' : sector,
            score_range: scoreRange
          }
        }
      })
      if (data.failed_assessments) {
        setFailedCompanies(data.companies)
        if (data.total_assessments === data.failed_assessments)
          setHasAllFailedAssessments(true)
        else if (data.total_assessments > data.failed_assessments)
          setHasDupeEmails(true)
        disableEscButton()
      } else {
        viewAssesmentBatchView()
      }
    } catch (e) {
      setHasError(true)
      disableEscButton()
    }
    setModal(false)
    setProceedDisabled(false)
  }

  const onPopoverDupeEmailsErrorClose = () => {
    enableEscButton()
    setHasDupeEmails(false)
    viewAssesmentBatchView()
  }
  function enableEscButton() {
    onDemandAsmntDispatch({
      type: REDUCER_TYPE.TOGGLE_HAS_ERROR_POPUP,
      hasErrorPopUP: false
    })
  }
  function disableEscButton() {
    onDemandAsmntDispatch({
      type: REDUCER_TYPE.TOGGLE_HAS_ERROR_POPUP,
      hasErrorPopUP: true
    })
  }
  function viewAssesmentBatchView() {
    reportingServiceDispatch({
      type: REDUCER_TYPE.RESET_ODA_REPORTING_SERVICE,
      sections: [
        REDUCER_STATE.REPORTING_ODA_PORTFOLIO,
        REDUCER_STATE.ON_DEMAND_ASMNT_LIMIT
      ]
    })

    onDemandAsmntDispatch({
      type: REDUCER_TYPE.ON_DEMAND_ASMNT_RESET_VIEW,
      sectionName: REDUCER_STATE.ON_DEMAND_ASMNT_BATCH_REVIEW
    })

    onDemandAsmntDispatch({
      type: REDUCER_TYPE.ON_DEMAND_ASMNT_SET_STEP,
      step: PAGE_CON.ON_DEMAND_ASMNT_BATCH_REVIEW
    })
  }

  const getDuplicatedEmails = (dupeEmails) => {
    return (
      <>
        <b>Failed {dupeEmails.length > 1 ? 'Assessments:' : 'Assessment:'}</b>
        <br />
        {dupeEmails.map((item) => {
          return (
            <React.Fragment key={item.company_name}>
              {' '}
              {item.company_name}
              <br />
            </React.Fragment>
          )
        })}
      </>
    )
  }

  const entities = rows.filter(
    ({ status }) => filterStatus === '*' || status.value === filterStatus
  )
  const requestsCount = rows.filter(
    ({ status }) => status.value === 'not_sent'
  ).length
  return (
    <>
      <div>
        {!readOnly && (
          <div className={classes.ReqDetailAlert}>
            <IconInformationCircle
              className={classes.ReqDetailInfoIcon}
              fill={themeColorPalette.palette.sys_trad.status.main}
            />{' '}
            Please confirm email addresses. The listed contacts will receive an
            email prompting them to complete an ESG assessment questionnaire.
          </div>
        )}
        <div className={classes.ReqDetailTableHeader}>
          <div>
            <span className={classes.ReqDetailLabel}>Show</span>
            <Select
              value={filterStatus}
              onChange={onChangeHandler}
              className={classes.p3Typography}
            >
              {statuses
                .filter((status) => readOnly === status.readOnly)
                .map(({ key, value }) => (
                  <MenuItem
                    value={key}
                    key={key}
                    className={classes.p3Typography}
                  >
                    {value}
                  </MenuItem>
                ))}
            </Select>
          </div>
          {!readOnly && (
            <div>
              <PrimaryButton
                id="ondemand-assessment"
                buttonText={`Next: Confirm Request to ${requestsCount} ${
                  requestsCount > 1 ? 'Companies' : 'Company'
                }`}
                buttonClass="buttonAltPrimary"
                onClick={openModal}
                buttonDisabled={!isValid}
              />
            </div>
          )}
        </div>
        {entities.length > 0 ? (
          <div className={classes.ReqDetailTable}>
            <TableContainer>
              <Table>
                <TableBody>
                  {entities.slice(0, limit).map((rowProps, i) => (
                    <RequestDetailRow
                      {...rowProps}
                      key={i}
                      readOnly={readOnly}
                      removeRow={removeRow}
                      setEmail={setEmail}
                      isDuplicateEmail={duplicateEmails[rowProps.orbis_id]}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : null}

        {entities.length - limit > 0 ? (
          <div className={classes.ReqDetailLoadMoreContainer}>
            <div
              className={classes.ReqDetailLoadMore}
              onClick={loadMore}
              aria-hidden={true}
            >
              Load More (
              {entities.length - limit < 20 ? entities.length - limit : 20})
            </div>
          </div>
        ) : null}
      </div>
      <PrimaryPopover
        snackbar={classes.ReqDetailSnackbar}
        id="upload-popover-error-request-test-id"
        hideBackdrop={false}
        alertError={true}
        alertHeight="20%"
        alertText={'Something went wrong, please try again later.'}
        alertOpen={hasError}
        onAlertClose={() => {
          setHasError(false)
          enableEscButton()
        }}
        alertPosition="center"
      />
      <PrimaryPopover
        snackbar={classes.ReqDetailSnackbar}
        id="upload-popover-error-request-test-id"
        hideBackdrop={false}
        alertError={true}
        alertHeight="20%"
        alertText={`No assessment ${
          failedCompanies.length > 1 ? 'requests' : 'request'
        } have been created. Please review the email address entries.`}
        alertOpen={hasAllFailedAssessments}
        onAlertClose={() => {
          setHasAllFailedAssessments(false)
          enableEscButton()
        }}
        alertPosition="center"
      />
      {hasDupeEmails && (
        <PrimaryPopover
          id="dupe-email-popover-error-request-test-id"
          hideBackdrop={false}
          alertError={true}
          alertHeight="20%"
          alertText={`The following ${
            failedCompanies.length > 1 ? 'companies' : 'company'
          } failed assessment creation 
              due to pre-existing email in the system. Please review
              the email address provided and re-submit the
              assessment request.`}
          alertWidth={320}
          alertPosition="center"
          alertOpen={hasDupeEmails}
          onAlertClose={onPopoverDupeEmailsErrorClose}
          alertContentComponent={
            <div className={classes.divStyleError}>
              <span className={classes.boxStyleError}>
                {getDuplicatedEmails(failedCompanies)}
              </span>
            </div>
          }
        />
      )}
      <Dialog
        open={modal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={() => {
          setModal(false)
        }}
        className={classes.ReqDetailModal}
      >
        <DialogContent>
          <div>
            <div className={classes.ReqDetailModalTitle}>Confirm Request</div>
            <div className={classes.p3Typography}>
              Assessment request will be sent to {requestsCount}{' '}
              {requestsCount > 1 ? 'companies' : 'company'}. You will receive
              confirmation when all requests have been sent.
            </div>
          </div>
          <div className={classes.ReqDetailModalButtons}>
            <PrimaryButton
              id="ondemand-assessment-cancel"
              buttonText="Cancel"
              buttonClass="buttonSlim"
              buttonHeight="32px"
              onClick={closeModal}
              buttonDisabled={proceedDisabled}
            />
            <PrimaryButton
              id="ondemand-assessment-confirmation"
              buttonText="Proceed"
              buttonClass="buttonAltPrimary"
              onClick={confirmRequest}
              buttonDisabled={proceedDisabled}
              sync
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default RequestDetail
