import React from 'react'
import { IconEyeClosed, IconEyeOpen, PrimaryButton } from 'components'

const ScoreQuality = ({
  handleShowScoreQuality,
  scoreQuality,
  showBtn = true
}) => {
  let btnIcon = scoreQuality ? <IconEyeOpen /> : <IconEyeClosed />

  return (
    <div>
      {showBtn && (
        <PrimaryButton
          id="score-qualty-btn"
          buttonText="Score Quality"
          buttonClass="buttonAltPrimary"
          buttonIcon={btnIcon}
          onClick={handleShowScoreQuality}
          buttonMarginTop="-3px"
          buttonPaddingTop="5px"
          buttonHeight="22px"
          buttonWidth="140px"
        />
      )}
    </div>
  )
}

export default ScoreQuality
