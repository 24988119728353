import { AppBar } from '@material-ui/core'
import { Container, Grid, makeStyles } from '@material-ui/core'
import { GlobalContext } from '../../../../context/GlobalStateProvider'
import {
  COMMON,
  MRK_RSK_MGM,
  OPR_RSK_MGM,
  PARAMS,
  SPCH_RSK_MGM
} from '../../../../constant/APIconstants'
import { ResearchLineService } from 'services'
import {
  PAGE_CON,
  REDUCER_STATE,
  REDUCER_TYPE,
  filterOptions
} from 'constant/constants'
import axios from 'axios'
import Error from 'utils/error'
import getMonthYear from 'utils/Date'
import Laggards from '../../Laggards'
import Leaders from '../../Leaders'
import LoadingMask from 'utils/loadingMask'
import PhysicalRiskColumns from '../PhysicalRiskColumns.json'
import PhysicalRiskHazardsOverView from './PhysicalRiskHazardsOverView'
import React, { useCallback, useContext, useEffect } from 'react'
import Regions from '../../Regions'
import scoreDict from 'utils/scoreDictionary'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import UnderlyingDataRiskMetrics from '../UnderlyingDataMetrics'
import Updates from '../../Updates'
import { DropdownField, IconArrowDown, Page } from 'components'
import Impact from '../../Impact'
import getColor from 'utils/color'
import PhysicalRiskHazardsColumns from './PhysicalRiskHazardsColumns.json'
import UnderlyingMetrics from 'views/portfolioanalysis/underlyingmetrics/UnderlyingMetrics'

const colInfo = JSON.parse(
  JSON.stringify(PhysicalRiskColumns.physicalrisk_Portfolio_Score_Columns)
)
const UpdatesTableColInfo = PhysicalRiskColumns.physicalrisk_updates_Columns
const PanelUpdatesTableColInfo =
  PhysicalRiskColumns.physicalrisk_updates_Panel_Columns
const LeaderLaggardColInfo =
  PhysicalRiskColumns.physicalrisk_leaders_Laggards_Panel_Columns
const mimTabColInfo = JSON.parse(
  JSON.stringify(
    PhysicalRiskColumns.physicalrisk_region_Sector_Minimal_Table_Columns
  )
)
const panelTabColInfo =
  PhysicalRiskColumns.physicalrisk_region_Sector_Panel_Columns
const mrkSupplyPanelTabColInfo =
  PhysicalRiskColumns.physicalrisk_market_supply_underlying_metrics_Panel_Columns
// cardInfoContentInfo is for regions
const cardInfoContentInfo =
  PhysicalRiskColumns.physicalrisk_card_Info_Section_Columns
const cardInfoContentInfoSector =
  PhysicalRiskColumns.physicalrisk_card_Info_Section_Columns_Sector
const impactColumns = JSON.parse(
  JSON.stringify(PhysicalRiskHazardsColumns.impactColumns)
)
const UDMColumns = JSON.parse(
  JSON.stringify(PhysicalRiskHazardsColumns.UDMColumns)
)

const drawerWidth = PAGE_CON.PAGE_LEFT_MARGIN

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  content: {
    flexGrow: 1
  },
  container: {
    marginLeft: drawerWidth - 20,
    paddingRight: 0,
    paddingLeft: '24px',
    marginTop: '22px'
  },
  drawerHeader: {
    display: 'flex',
    ...textTheme.typography.h1,
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  },
  tableTitle: {
    paddingBottom: '5px',
    ...textTheme.typography.h1
  },
  stickyHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    zIndex: 1,
    boxShadow: 'none'
  },
  overviewstickyHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    zIndex: 1,
    boxShadow: 'none'
  },
  containerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 16px 16px',
    marginTop: '24px'
  },
  impactContainerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 0px 16px',
    marginTop: '24px',
    paddingLeft: '16px'
  },
  impactDropdown: {
    marginTop: '16px',
    marginBottom: '12px'
  },
  physRiskSubHeader: {
    ...textTheme.typography.h3,
    color: themeColorPalette.palette.sys_trad.main.main,
    marginBottom: '14px'
  }
}))

const tableLength = 10

const linkPadding = {
  marginTop: '8px',
  marginLeft: '-4px'
}

const overviewLinkPadding = {
  marginTop: '16px',
  marginLeft: '-4px'
}

const linkPaddingRegionIndustry = {
  marginTop: '8px',
  marginLeft: '-4px'
}

const linkStyle = {
  ...textTheme.typography.p1,
  color: themeColorPalette.palette.sys_trad.link.main,
  lineHeight: '16px',
  verticalAlign: 'top'
}

const regionStyle = {
  paddingBottom: '10px',
  marginTop: '18px'
}

const regionTitleStyle = {
  top: '16px'
}

const updatesStyle = {
  paddingLeft: '2px',
  marginTop: '16px'
}

const UpdatesTableStyle = {
  marginTop: '12px',
  background: themeColorPalette.palette.sys_trad.white.main,
  boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.12)',
  boxRadius: '2px',
  boxSizing: 'border-box',
  padding: '12px',
  paddingTop: '0px',
  paddingBottom: '10px'
}

const cardHeader = {
  ...textTheme.typography.p2,
  paddingBottom: '4px',
  paddingTop: '16px',
  lineHeight: '16px'
}

const dataMetricbox = {
  marginTop: '24px',
  paddingTop: '10px',
  background: themeColorPalette.palette.sys_trad.white.main,
  boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.12)',
  boxRadius: '2px',
  boxSizing: 'border-box',
  padding: '17px'
}

const dataMetricboxMSupRsk = {
  marginTop: '24px',
  paddingTop: '22px',
  background: themeColorPalette.palette.sys_trad.white.main,
  boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.12)',
  boxRadius: '2px',
  boxSizing: 'border-box',
  padding: '17px'
}

const dataMetricTitle = {
  marginTop: '16px'
}

const mapDataClasses = [
  { color: themeColorPalette.palette.noflag.norsk.main, from: 0, to: 19 },
  { color: themeColorPalette.palette.noflag.lowrsk.main, from: 20, to: 39 },
  { color: themeColorPalette.palette.noflag.medrsk.main, from: 40, to: 59 },
  { color: themeColorPalette.palette.noflag.hirsk.main, from: 60, to: 79 },
  { color: themeColorPalette.palette.noflag.redflgrsk.main, from: 80, to: 100 }
]

const PhysicalRiskHazards = (props) => {
  const classes = useStyles()
  const [selectedCardId, setSelectedCardId] = React.useState(null)
  const [filterState] = useContext(GlobalContext).globalFilter
  const [scoreQualityState] = useContext(GlobalContext).globalFilter
  const [physicalRiskHazardsState, physicalRiskHazardsDispatch] =
    useContext(GlobalContext).globalPhysicalRiskHazards

  const isFilterStateAvailable =
    filterState.filterData.regionsList.length > 0 ||
    filterState.filterData.sectorsList.length > 0

  let apiCallId = physicalRiskHazardsState.apiCallId
  const pageConstant = OPR_RSK_MGM.PAGE_URL

  let showBenchMark =
    filterState.globalPortfolioFilter.benchmark !== '' ? true : false

  let benchmarckCol = colInfo.filter(
    (col) => col.HeaderLabel === PAGE_CON.BENCHMARK
  )
  benchmarckCol[0].HideColumn = !showBenchMark

  let benchmarkPctCol = mimTabColInfo.filter(
    (col) => col.HeaderLabel === PAGE_CON.BENCHMARK_PERC
  )
  benchmarkPctCol[0].HideColumn = !showBenchMark

  let monthYear = getMonthYear(filterState)
  const [updateSectionText, setUpdateSectionText] = React.useState(
    `${PAGE_CON.UPDATESIN_PHSYICALRISK_TITLE} ${monthYear}, ${PAGE_CON.IMPACT_TITLE}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`
  )

  function selectedCard(selectCardInfo) {
    setSelectedCardId(selectCardInfo.cardId)
  }

  const onImpactFilterChange = (event) => {
    physicalRiskHazardsDispatch({
      type: REDUCER_TYPE.UPDATE_IMPACT_FILTER,
      data: event.target.value
    })
  }

  const isRegionHasData = () => {
    let hasData = false
    if (
      physicalRiskHazardsState.portfolioRegions.data.summary.length > 0 &&
      physicalRiskHazardsState.portfolioRegions.data.details.length > 0 &&
      physicalRiskHazardsState.portfolioRegions.data.mapData.length > 0
    )
      hasData = true
    return hasData
  }

  const getUnderlyingDataMetricsSupRisk = async (
    sectors,
    regions,
    isErrorReload = false
  ) => {
    if (
      Object.keys(
        physicalRiskHazardsState?.portfolioUnderlyingDataMetricsSCR?.data || {}
      ).length > 0 ||
      (physicalRiskHazardsState.portfolioUnderlyingDataMetricsSCR.dataStatus !==
        '' &&
        isErrorReload === false)
    )
      return

    physicalRiskHazardsDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS_SCR
    })
    let metricsData = []
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter

    try {
      const { data } = isFilterStateAvailable
        ? await ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: SPCH_RSK_MGM.PAGE_URL,
            token: physicalRiskHazardsState.cancelToken.token,
            endPoint: COMMON.DATA_METRICS
          })
        : []

      metricsData = data

      physicalRiskHazardsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS_SCR,
        data: metricsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        physicalRiskHazardsDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS_SCR,
          apiCallId
        })
      }
    }
  }

  const getUnderlyingDataMetricsSupRiskPanel = async (
    sectors,
    regions,
    isErrorReload = false
  ) => {
    if (
      Object.keys(
        physicalRiskHazardsState?.portfolioUnderlyingDataMetricsSCRPanel
          ?.data || {}
      ).length > 0 ||
      (physicalRiskHazardsState.portfolioUnderlyingDataMetricsSCRPanel
        .dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    physicalRiskHazardsDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS_SCR_PANEL
    })
    let metricsPanelData = []
    try {
      const { data } = isFilterStateAvailable
        ? await axios.post(
            COMMON.BASE_URL +
              filterState.globalPortfolioFilter.portfolio +
              SPCH_RSK_MGM.PAGE_URL +
              COMMON.DATA_METRICS_DETAILS,
            {
              region: regions,
              sector: sectors,
              month: filterState.globalPortfolioFilter.asOfDate.slice(4),
              year: filterState.globalPortfolioFilter.asOfDate.slice(0, 4)
            },
            { cancelToken: physicalRiskHazardsState.cancelToken.token }
          )
        : []

      metricsPanelData = data

      physicalRiskHazardsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS_SCR_PANEL,
        data: metricsPanelData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        physicalRiskHazardsDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS_SCR_PANEL,
          apiCallId
        })
      }
    }
  }

  const getUnderlyingDataMetricsMktRisk = async (
    sectors,
    regions,
    isErrorReload = false
  ) => {
    if (
      Object.keys(
        physicalRiskHazardsState?.portfolioUnderlyingDataMetricsMR?.data || {}
      ).length > 0 ||
      (physicalRiskHazardsState.portfolioUnderlyingDataMetricsMR.dataStatus !==
        '' &&
        isErrorReload === false)
    )
      return

    physicalRiskHazardsDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS_MR
    })
    let metricsData = []
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter

    try {
      const { data } = isFilterStateAvailable
        ? await ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: MRK_RSK_MGM.PAGE_URL,
            token: physicalRiskHazardsState.cancelToken.token,
            endPoint: COMMON.DATA_METRICS
          })
        : []
      metricsData = data

      physicalRiskHazardsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS_MR,
        data: metricsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        physicalRiskHazardsDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS_MR,
          apiCallId
        })
      }
    }
  }

  const getUnderlyingDataMetricsMktRiskPanel = async (
    sectors,
    regions,
    isErrorReload = false
  ) => {
    if (
      Object.keys(
        physicalRiskHazardsState?.portfolioUnderlyingDataMetricsMRPanel?.data ||
          {}
      ).length > 0 ||
      (physicalRiskHazardsState.portfolioUnderlyingDataMetricsMRPanel
        .dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    physicalRiskHazardsDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS_MR_PANEL
    })
    let metricsPanelData = []
    try {
      const { data } = isFilterStateAvailable
        ? await axios.post(
            COMMON.BASE_URL +
              filterState.globalPortfolioFilter.portfolio +
              MRK_RSK_MGM.PAGE_URL +
              COMMON.DATA_METRICS_DETAILS,
            {
              [PARAMS.REGION]: regions,
              [PARAMS.SECTOR]: sectors,
              [PARAMS.MONTH]:
                filterState.globalPortfolioFilter.asOfDate.slice(4),
              [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(
                0,
                4
              )
            },
            { cancelToken: physicalRiskHazardsState.cancelToken.token }
          )
        : []

      metricsPanelData = data

      physicalRiskHazardsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS_MR_PANEL,
        data: metricsPanelData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        physicalRiskHazardsDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS_MR_PANEL,
          apiCallId
        })
      }
    }
  }

  const getLeadersLaggardsData = async (
    sectors,
    regions,
    isErrorReload = false
  ) => {
    if (
      (physicalRiskHazardsState.portfolioLeaderLaggard.data.leaders &&
        physicalRiskHazardsState.portfolioLeaderLaggard.data.laggards &&
        physicalRiskHazardsState.portfolioLeaderLaggard.data.leaders.length >
          0 &&
        physicalRiskHazardsState.portfolioLeaderLaggard.data.laggards.length >
          0) ||
      (physicalRiskHazardsState.portfolioLeaderLaggard.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    physicalRiskHazardsDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS
    })
    let leadersLaggardsData = { leaders: [], laggards: [] }

    try {
      const { data } = isFilterStateAvailable
        ? await axios.post(
            COMMON.BASE_URL +
              filterState.globalPortfolioFilter.portfolio +
              OPR_RSK_MGM.PAGE_URL +
              COMMON.LEADERS_LAGGARDS,
            {
              [PARAMS.REGION]: regions,
              [PARAMS.SECTOR]: sectors,
              [PARAMS.MONTH]:
                filterState.globalPortfolioFilter.asOfDate.slice(4),
              [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(
                0,
                4
              )
            },
            { cancelToken: physicalRiskHazardsState.cancelToken.token }
          )
        : { leaders: {}, laggards: {} }

      leadersLaggardsData.leaders = data.leaders
      leadersLaggardsData.laggards = data.laggards

      physicalRiskHazardsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS,
        data: leadersLaggardsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        physicalRiskHazardsDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS,
          apiCallId
        })
      }
    }
  }

  const getUpdatesData = async (sectors, regions, isErrorReload = false) => {
    if (
      physicalRiskHazardsState.portfolioUpdates.data?.length > 0 ||
      (physicalRiskHazardsState.portfolioUpdates.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    physicalRiskHazardsDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_UPDATES
    })
    let updatesData = []
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter
    try {
      const { data } = isFilterStateAvailable
        ? await ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: OPR_RSK_MGM.PAGE_URL,
            token: physicalRiskHazardsState.cancelToken.token,
            endPoint: COMMON.UPDATES
          })
        : []

      updatesData = data

      physicalRiskHazardsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_UPDATES,
        data: updatesData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        physicalRiskHazardsDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_UPDATES,
          apiCallId
        })
      }
    }
  }

  const getRegionsData = async (sectors, regions, isErrorReload = false) => {
    if (
      (physicalRiskHazardsState.portfolioRegions.data.summary.length > 0 &&
        physicalRiskHazardsState.portfolioRegions.data.details.length > 0 &&
        physicalRiskHazardsState.portfolioRegions.data.mapData.length > 0 &&
        physicalRiskHazardsState.portfolioSectors.data.summary.length > 0 &&
        physicalRiskHazardsState.portfolioSectors.data.details.length > 0) ||
      (physicalRiskHazardsState.portfolioRegions.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    physicalRiskHazardsDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_REGIONS
    })
    let regionsData = {
      summary: [],
      details: [],
      mapData: [],
      sectorsummary: [],
      sectordetails: []
    }
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter

    try {
      if (isFilterStateAvailable) {
        const [summary, details, mapData, sectorsummary, sectordetails] =
          await axios.all([
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: OPR_RSK_MGM.PAGE_URL,
              token: physicalRiskHazardsState.cancelToken.token,
              endPoint: COMMON.REGION_SUMMARY
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: OPR_RSK_MGM.PAGE_URL,
              token: physicalRiskHazardsState.cancelToken.token,
              endPoint: COMMON.REGION_DETAILS
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: OPR_RSK_MGM.PAGE_URL,
              token: physicalRiskHazardsState.cancelToken.token,
              endPoint: COMMON.MAP
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: OPR_RSK_MGM.PAGE_URL,
              token: physicalRiskHazardsState.cancelToken.token,
              endPoint: COMMON.SECTOR_SUMMARY
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: OPR_RSK_MGM.PAGE_URL,
              token: physicalRiskHazardsState.cancelToken.token,
              endPoint: COMMON.SECTOR_DETAILS
            })
          ])

        regionsData.summary = summary.data
        regionsData.details = details.data
        regionsData.sectorsummary = sectorsummary.data
        regionsData.sectordetails = sectordetails.data
        mapData?.data
          .filter(
            (data) => !isNaN(data.score) && data.score >= 0 && data.score <= 100
          )
          .map((mdata) => {
            let range = scoreDict[mdata.score_category]
            return regionsData.mapData.push([
              mdata.country_code,
              mdata.score,
              range
            ])
          })
      }

      physicalRiskHazardsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_REGIONS,
        data: regionsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        physicalRiskHazardsDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_REGIONS,
          apiCallId
        })
      } else console.log(err)
    }
  }

  const getImpactCall = (filter, regions, sectors, month, year) => {
    return isFilterStateAvailable
      ? axios.post(
          COMMON.BASE_URL +
            filterState.globalPortfolioFilter.portfolio +
            OPR_RSK_MGM.IMPACT_PAGE_URL +
            COMMON.IMPACT,
          {
            [PARAMS.IMPACT_FILTER]: filter,
            [PARAMS.REGION]: regions,
            [PARAMS.SECTOR]: sectors,
            [PARAMS.MONTH]: month,
            [PARAMS.YEAR]: year
          },
          { cancelToken: physicalRiskHazardsState.cancelToken.token }
        )
      : []
  }

  const getImpactData = async (sectors, regions, isErrorReload = false) => {
    if (
      (physicalRiskHazardsState.portfolioImpact.data &&
        physicalRiskHazardsState.portfolioImpact.data?.length > 0) ||
      (physicalRiskHazardsState.portfolioImpact.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    physicalRiskHazardsDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_IMPACT
    })
    let impactData = {}
    try {
      const month = filterState.globalPortfolioFilter.asOfDate.slice(4)
      const year = filterState.globalPortfolioFilter.asOfDate.slice(0, 4)

      const [top5, top10, bottom5, bottom10] = await axios.all([
        getImpactCall(PAGE_CON.IMPACT_KEY_TOP_5, regions, sectors, month, year),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_TOP_10,
          regions,
          sectors,
          month,
          year
        ),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_BOTTOM_5,
          regions,
          sectors,
          month,
          year
        ),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_BOTTOM_10,
          regions,
          sectors,
          month,
          year
        )
      ])

      impactData[PAGE_CON.IMPACT_KEY_TOP_5] = top5.data[0]
      impactData[PAGE_CON.IMPACT_KEY_TOP_10] = top10.data[0]
      impactData[PAGE_CON.IMPACT_KEY_BOTTOM_5] = bottom5.data[0]
      impactData[PAGE_CON.IMPACT_KEY_BOTTOM_10] = bottom10.data[0]

      physicalRiskHazardsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_IMPACT,
        data: impactData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        physicalRiskHazardsDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_IMPACT,
          apiCallId
        })
      }
    }
  }

  const getUnderlyingDataMetrics = async (
    sectors,
    regions,
    isErrorReload = false
  ) => {
    if (
      Object.keys(
        physicalRiskHazardsState?.portfolioUnderlyingDataMetrics?.data || {}
      ).length > 0 ||
      (physicalRiskHazardsState?.portfolioUnderlyingDataMetrics?.dataStatus !==
        '' &&
        isErrorReload === false)
    )
      return

    physicalRiskHazardsDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS
    })
    let metricsData = []
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter

    try {
      const { data } = isFilterStateAvailable
        ? await ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: OPR_RSK_MGM.PAGE_URL,
            token: physicalRiskHazardsState.cancelToken.token,
            endPoint: COMMON.DATA_METRICS
          })
        : []
      metricsData = data

      physicalRiskHazardsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS,
        data: metricsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        physicalRiskHazardsDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS,
          apiCallId
        })
      }
    }
  }

  const reloadComponent = (errorOccuredOn) => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    switch (errorOccuredOn) {
      case PAGE_CON.LEADERS_TITLE:
      case PAGE_CON.LAGGARDS_TITLE:
        getLeadersLaggardsData(sectors, regions, true)
        break
      case PAGE_CON.REGIONS_TITLE:
        getRegionsData(sectors, regions, true)
        break
      case PAGE_CON.UPDATESIN_PHSYICALRISK_TITLE:
        getUpdatesData(sectors, regions, true)
        break
      case PAGE_CON.RISK_CAT_TITLE:
        getUnderlyingDataMetrics(sectors, regions, true)
        break
      case PAGE_CON.IMPACT_TITLE:
        getImpactData(sectors, regions, true)
        break
      case PAGE_CON.PHY_RSK_HZRD_TITLE:
        getUnderlyingDataMetricsMktRisk(sectors, regions, true)
        getUnderlyingDataMetricsSupRisk(sectors, regions, true)
        break
      default:
        break
    }
  }

  const getPhysicalRiskHazardsData = useCallback(async () => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    if (filters.portfolio !== '' && !filterState.isLoading) {
      getRegionsData(sectors, regions)
      getLeadersLaggardsData(sectors, regions)
      getUpdatesData(sectors, regions)
      getImpactData(sectors, regions)
      getUnderlyingDataMetrics(sectors, regions)
      getUnderlyingDataMetricsMktRisk(sectors, regions)
      getUnderlyingDataMetricsSupRisk(sectors, regions)
      getUnderlyingDataMetricsSupRiskPanel(sectors, regions)
      getUnderlyingDataMetricsMktRiskPanel(sectors, regions)
    }
  }, [filterState.actionData])

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      getPhysicalRiskHazardsData()
    }
  }, [filterState.actionData])

  useEffect(() => {
    let updateText = `${PAGE_CON.UPDATESIN_PHSYICALRISK_TITLE} ${monthYear}, ${PAGE_CON.IMPACT_TITLE}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`

    if (
      !physicalRiskHazardsState.portfolioImpact.isLoading &&
      !physicalRiskHazardsState.portfolioLeaderLaggard.isLoading
    ) {
      if (canShowImpact())
        updateText = `${PAGE_CON.UPDATESIN_PHSYICALRISK_TITLE} ${monthYear}, and ${PAGE_CON.IMPACT_TITLE}`
      else updateText = `${PAGE_CON.UPDATESIN_PHSYICALRISK_TITLE} ${monthYear}`

      if (canShowLeadersLaggards()) {
        if (canShowImpact())
          updateText = `${PAGE_CON.UPDATESIN_PHSYICALRISK_TITLE} ${monthYear}, ${PAGE_CON.IMPACT_TITLE}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`
        else
          updateText = `${PAGE_CON.UPDATESIN_PHSYICALRISK_TITLE} ${monthYear}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`
      }
    }

    setUpdateSectionText(updateText)
  }, [
    physicalRiskHazardsState.portfolioLeaderLaggard.dataStatus,
    physicalRiskHazardsState.portfolioImpact.dataStatus
  ])

  const updateVal = React.useMemo(() => {
    return {
      data: physicalRiskHazardsState.portfolioUpdates.data,
      colInfo: UpdatesTableColInfo
    }
  }, [physicalRiskHazardsState.portfolioUpdates.dataStatus])

  const leadersData = React.useMemo(() => {
    return {
      data: physicalRiskHazardsState.portfolioLeaderLaggard.data.leaders,
      colInfo: LeaderLaggardColInfo
    }
  }, [physicalRiskHazardsState.portfolioLeaderLaggard.dataStatus])

  const physicalRiskHazardsMapData =
    physicalRiskHazardsState.portfolioRegions.data &&
    physicalRiskHazardsState.portfolioRegions.data.mapData &&
    physicalRiskHazardsState.portfolioRegions.data.mapData.map((item) => {
      return {
        'hc-key': item[0] || '-',
        value: item[1] !== undefined ? item[1] : '-',
        ScoreRangeScore: item[1] !== undefined ? item[1] : '-',
        category: item[2] || '-'
      }
    })

  const getPositiveBarData = () => {
    const positiveData = [
      {
        name: 'total',
        data: [],
        opacity: 0.6
      },
      {
        name: 'selection',
        data: [],
        opacity: 1
      }
    ]
    physicalRiskHazardsState.portfolioImpact.data[
      physicalRiskHazardsState.portfolioImpact.filter
    ]?.positive_impact?.distribution?.map((item) => {
      positiveData[0].data.push(item.total - item.selection)
      positiveData[1].data.push(item.selection)
      return ''
    })

    return positiveData
  }

  const getPositiveBarColor = () => {
    const positiveColor = []

    physicalRiskHazardsState.portfolioImpact.data[
      physicalRiskHazardsState.portfolioImpact.filter
    ]?.positive_impact?.distribution?.map((item) => {
      positiveColor.push(getColor('risk', item.category))
      return ''
    })

    return positiveColor
  }

  const getNegativeBarData = () => {
    const negativeData = [
      {
        name: 'total',
        data: [],
        opacity: 0.6
      },
      {
        name: 'selection',
        data: [],
        opacity: 1
      }
    ]
    physicalRiskHazardsState.portfolioImpact.data[
      physicalRiskHazardsState.portfolioImpact.filter
    ]?.negative_impact?.distribution?.map((item) => {
      negativeData[0].data.push(item.total - item.selection)
      negativeData[1].data.push(item.selection)
      return ''
    })

    return negativeData
  }

  const getNegativeBarColor = () => {
    const negativeColor = []

    physicalRiskHazardsState.portfolioImpact.data[
      physicalRiskHazardsState.portfolioImpact.filter
    ]?.negative_impact?.distribution?.map((item) => {
      negativeColor.push(getColor('risk', item.category))
      return ''
    })

    return negativeColor
  }

  const canShowImpact = () => {
    let showImpact = false
    if (
      physicalRiskHazardsState.portfolioImpact.dataStatus === PAGE_CON.ERROR ||
      (physicalRiskHazardsState.portfolioImpact.dataStatus ===
        PAGE_CON.SUCCESS &&
        filterOptions.some(
          (item) =>
            physicalRiskHazardsState.portfolioImpact.data[item.key]
              ?.positive_impact?.investment_and_score?.length > 0 ||
            physicalRiskHazardsState.portfolioImpact.data[item.key]
              ?.negative_impact?.investment_and_score?.length > 0
        ))
    ) {
      showImpact = true
    }

    return showImpact
  }

  const canShowLeadersLaggards = () => {
    let showLeadersLaggardsComp = false
    if (
      physicalRiskHazardsState.portfolioLeaderLaggard.dataStatus ===
        PAGE_CON.ERROR ||
      (physicalRiskHazardsState.portfolioLeaderLaggard.dataStatus ===
        PAGE_CON.SUCCESS &&
        (physicalRiskHazardsState.portfolioLeaderLaggard.data?.leaders?.length >
          0 ||
          physicalRiskHazardsState.portfolioLeaderLaggard.data?.laggards
            ?.length > 0))
    ) {
      showLeadersLaggardsComp = true
    }

    return showLeadersLaggardsComp
  }

  const canShowRegionComp = () => {
    let showRegionComp = false
    if (
      physicalRiskHazardsState.portfolioRegions.isLoading ||
      physicalRiskHazardsState.portfolioRegions.dataStatus === PAGE_CON.ERROR ||
      (physicalRiskHazardsState.portfolioRegions.dataStatus ===
        PAGE_CON.SUCCESS &&
        physicalRiskHazardsState.portfolioRegions.data?.summary?.length > 0 &&
        physicalRiskHazardsState.portfolioRegions.data?.details?.length > 0 &&
        physicalRiskHazardsState.portfolioRegions.data?.mapData?.length > 0)
    )
      showRegionComp = true
    return showRegionComp
  }

  const canShowAdditionalComp = () => {
    let showAdditionalComp = false
    if (
      physicalRiskHazardsState.portfolioUnderlyingDataMetricsMR.isLoading ||
      physicalRiskHazardsState.portfolioUnderlyingDataMetricsMR.dataStatus ===
        PAGE_CON.ERROR ||
      (physicalRiskHazardsState.portfolioUnderlyingDataMetricsMR.dataStatus ===
        PAGE_CON.SUCCESS &&
        physicalRiskHazardsState.portfolioUnderlyingDataMetricsMR.data?.length >
          0) ||
      physicalRiskHazardsState.portfolioUnderlyingDataMetricsSCR.isLoading ||
      physicalRiskHazardsState.portfolioUnderlyingDataMetricsSCR.dataStatus ===
        PAGE_CON.ERROR ||
      (physicalRiskHazardsState.portfolioUnderlyingDataMetricsSCR.dataStatus ===
        PAGE_CON.SUCCESS &&
        physicalRiskHazardsState.portfolioUnderlyingDataMetricsSCR.data
          ?.length > 0)
    ) {
      showAdditionalComp = true
    }

    return showAdditionalComp
  }

  return (
    <Page className={classes.root} title={PAGE_CON.PAGE_TITLE}>
      <main className={classes.content}>
        <Container className={classes.container} maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PhysicalRiskHazardsOverView
                showBenchMark={showBenchMark}
                isPhysicalRiskHazards={true}
                colInfo={colInfo}
                pageTitle={props.pageHeaderTitle}
                overViewText={PAGE_CON.PHYSICALRISKHZRD}
                coverageHeaderWidth={showBenchMark ? '49%' : '47%'}
                pageState={physicalRiskHazardsState}
                pageDispatch={physicalRiskHazardsDispatch}
                apiCallId={apiCallId}
                pageConstant={pageConstant}
                baseUrl={OPR_RSK_MGM.BASE_URL}
              />

              <Grid item xs={12} style={dataMetricbox}>
                {physicalRiskHazardsState.portfolioUnderlyingDataMetrics
                  .isLoading ? (
                  <LoadingMask />
                ) : physicalRiskHazardsState.portfolioUnderlyingDataMetrics
                    .dataStatus === PAGE_CON.ERROR ||
                  (physicalRiskHazardsState.portfolioUnderlyingDataMetrics
                    .dataStatus === PAGE_CON.SUCCESS &&
                    physicalRiskHazardsState.portfolioUnderlyingDataMetrics.data
                      ?.length === 0) ? (
                  <Error
                    id={'udmError'}
                    dataStatus={
                      physicalRiskHazardsState.portfolioUnderlyingDataMetrics
                        .dataStatus
                    }
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.RISK_CAT_TITLE}
                  />
                ) : (
                  <UnderlyingMetrics
                    underlyingDataMetrics={
                      physicalRiskHazardsState.portfolioUnderlyingDataMetrics
                        .data
                    }
                    columns={UDMColumns}
                    showDonut={false}
                    isFilterStateAvailable={isFilterStateAvailable}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item container>
              <a
                href={'#' + PAGE_CON.UPDATES_AND_CURRENT_LEADERS_LAGGARDS}
                style={overviewLinkPadding}
              >
                <IconArrowDown />
                <span style={linkStyle}>{updateSectionText}</span>
              </a>
            </Grid>

            <Grid item container>
              <AppBar
                id={props.id}
                position="sticky"
                className={classes.stickyHeader}
                style={{ top: '30px' }}
              >
                <div
                  id={PAGE_CON.UPDATES_AND_CURRENT_LEADERS_LAGGARDS}
                  style={{ paddingTop: '90px' }}
                >
                  <div className={classes.tableTitle} style={updatesStyle}>
                    {' '}
                    {updateSectionText}{' '}
                  </div>
                </div>
              </AppBar>
              <Grid item xs={12} style={UpdatesTableStyle}>
                <h6 style={cardHeader}>Updates</h6>
                {physicalRiskHazardsState.portfolioUpdates.isLoading ? (
                  <LoadingMask />
                ) : physicalRiskHazardsState.portfolioUpdates.dataStatus ===
                    PAGE_CON.ERROR ||
                  (physicalRiskHazardsState.portfolioUpdates.dataStatus ===
                    PAGE_CON.SUCCESS &&
                    physicalRiskHazardsState.portfolioUpdates.data?.length ===
                      0) ? (
                  <Error
                    id={'updatesError'}
                    errorClass={'noMargin'}
                    width={'101.3%'}
                    margin={0}
                    dataStatus={
                      physicalRiskHazardsState.portfolioUpdates.dataStatus
                    }
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.UPDATESIN_TITLE}
                    topMargin="5px"
                  />
                ) : (
                  <Updates
                    physicalRisk={true}
                    colInfo={updateVal.colInfo}
                    panelColInfo={PanelUpdatesTableColInfo}
                    tableLength={10}
                    totalData={updateVal.data}
                    tableEditable={false}
                    monthYear={monthYear}
                    showScoreQuality={scoreQualityState.enableScoreQuality}
                  />
                )}
              </Grid>

              {physicalRiskHazardsState.portfolioImpact.isLoading ||
              canShowImpact() ? (
                <Grid
                  item
                  container
                  className={classes.impactContainerWrapper}
                  style={{ paddingLeft: '16px', paddingRight: '16px' }}
                >
                  {physicalRiskHazardsState.portfolioImpact.isLoading ? (
                    <LoadingMask />
                  ) : physicalRiskHazardsState.portfolioImpact.dataStatus ===
                      PAGE_CON.ERROR ||
                    (physicalRiskHazardsState.portfolioImpact.dataStatus ===
                      PAGE_CON.SUCCESS &&
                      physicalRiskHazardsState.portfolioImpact.data?.length ===
                        0) ? (
                    <Error
                      id={'impactError'}
                      dataStatus={
                        isFilterStateAvailable
                          ? physicalRiskHazardsState.portfolioImpact.dataStatus
                          : PAGE_CON.SUCCESS
                      }
                      onErrorClick={reloadComponent}
                      errorOccuredOn={PAGE_CON.IMPACT_TITLE}
                      topMargin="5px"
                    />
                  ) : (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className={classes.impactDropdown}
                      >
                        <div className={classes.physRiskSubHeader}>
                          Physical Risk Hazards: Operations Risk
                        </div>
                        <span
                          style={{
                            backgroundColor:
                              themeColorPalette.palette.sys_trad.selected2.main,
                            marginBottom: '12px',
                            paddingTop: 4
                          }}
                        >
                          <DropdownField
                            id="impact-filter"
                            dropdownDefaultSelction={
                              physicalRiskHazardsState.portfolioImpact.filter
                            }
                            saveValueUsingExternalState={true}
                            dropdownOptions={filterOptions}
                            onChange={onImpactFilterChange}
                            labelTextDisabled={true}
                            labelText=""
                            alternateLabelText="Top 5"
                            defaultData={''}
                            impactColor={
                              themeColorPalette.palette.sys_trad.selected2.main
                            }
                          />
                        </span>
                      </Grid>
                      <Impact
                        positiveImpactData={
                          physicalRiskHazardsState.portfolioImpact.data[
                            physicalRiskHazardsState.portfolioImpact.filter
                          ]?.positive_impact?.investment_and_score || []
                        }
                        posBarChartData={getPositiveBarData()}
                        posBarColors={getPositiveBarColor()}
                        posBarTitle={`${
                          filterOptions.find(
                            (item) =>
                              item.key ===
                              physicalRiskHazardsState.portfolioImpact.filter
                          ).displayText
                        } vs. Total Investment %`}
                        negativeImpactData={
                          physicalRiskHazardsState.portfolioImpact.data[
                            physicalRiskHazardsState.portfolioImpact.filter
                          ]?.negative_impact?.investment_and_score || []
                        }
                        negBarChartData={getNegativeBarData()}
                        negBarColors={getNegativeBarColor()}
                        negBarTitle={`${
                          filterOptions.find(
                            (item) =>
                              item.key ===
                              physicalRiskHazardsState.portfolioImpact.filter
                          ).displayText
                        } vs. Total Investment %`}
                        impactColumns={impactColumns}
                        impactFilter={
                          physicalRiskHazardsState.portfolioImpact.filter
                        }
                      />
                    </>
                  )}
                </Grid>
              ) : null}
              {physicalRiskHazardsState.portfolioLeaderLaggard.isLoading ||
              canShowLeadersLaggards() ? (
                <Grid item container className={classes.containerWrapper}>
                  <Grid item xs={12} sm={6} style={{ paddingRight: '12px' }}>
                    <h6 style={cardHeader}>
                      {PAGE_CON.PORTFOLIO_LEADERS_BY_SCORE}
                    </h6>
                    {physicalRiskHazardsState.portfolioLeaderLaggard
                      .isLoading ? (
                      <LoadingMask />
                    ) : physicalRiskHazardsState.portfolioLeaderLaggard
                        .dataStatus === PAGE_CON.ERROR ||
                      (physicalRiskHazardsState.portfolioLeaderLaggard
                        .dataStatus === PAGE_CON.SUCCESS &&
                        physicalRiskHazardsState.portfolioLeaderLaggard.data
                          .leaders &&
                        physicalRiskHazardsState.portfolioLeaderLaggard.data
                          .leaders.length === 0) ? (
                      <Error
                        id={'leadersError'}
                        dataStatus={
                          isFilterStateAvailable
                            ? physicalRiskHazardsState.portfolioLeaderLaggard
                                .dataStatus
                            : PAGE_CON.SUCCESS
                        }
                        onErrorClick={reloadComponent}
                        errorOccuredOn={PAGE_CON.LEADERS_TITLE}
                        height={'312px'}
                      />
                    ) : (
                      <Leaders
                        colInfo={leadersData.colInfo}
                        totalData={leadersData.data}
                        tableLength={tableLength}
                        tableEditable={false}
                        showScoreQuality={scoreQualityState.enableScoreQuality}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingLeft: '12px' }}>
                    <h6 style={cardHeader}>
                      {PAGE_CON.PORTFOLIO_LAGGARDS_BY_SCORE}
                    </h6>
                    {physicalRiskHazardsState.portfolioLeaderLaggard
                      .isLoading ? (
                      <LoadingMask />
                    ) : physicalRiskHazardsState.portfolioLeaderLaggard
                        .dataStatus === PAGE_CON.ERROR ||
                      (physicalRiskHazardsState.portfolioLeaderLaggard
                        .dataStatus === PAGE_CON.SUCCESS &&
                        physicalRiskHazardsState.portfolioLeaderLaggard.data
                          .laggards &&
                        physicalRiskHazardsState.portfolioLeaderLaggard.data
                          .laggards.length === 0) ? (
                      <div style={{ paddingBottom: 8, paddingRight: 8 }}>
                        <Error
                          id={'laggardsError'}
                          dataStatus={
                            isFilterStateAvailable
                              ? physicalRiskHazardsState.portfolioLeaderLaggard
                                  .dataStatus
                              : PAGE_CON.SUCCESS
                          }
                          onErrorClick={reloadComponent}
                          errorOccuredOn={PAGE_CON.LAGGARDS_TITLE}
                          height={'312px'}
                        />
                      </div>
                    ) : (
                      <Laggards
                        colInfo={LeaderLaggardColInfo}
                        totalData={
                          physicalRiskHazardsState.portfolioLeaderLaggard.data
                            .laggards
                        }
                        tableLength={tableLength}
                        tableEditable={false}
                        showScoreQuality={scoreQualityState.enableScoreQuality}
                      />
                    )}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            {canShowRegionComp() ? (
              <>
                <Grid item container>
                  <a
                    href={'#' + PAGE_CON.REGIONS_INDUSTRIES}
                    style={linkPaddingRegionIndustry}
                  >
                    <IconArrowDown />
                    <span style={linkStyle}>
                      {PAGE_CON.REGIONS_TITLE} & {PAGE_CON.SECTORS_TITLE}
                    </span>
                  </a>
                </Grid>

                <Grid item container>
                  <AppBar
                    id={props.id}
                    position="sticky"
                    className={classes.stickyHeader}
                    style={regionTitleStyle}
                  >
                    <div
                      id={PAGE_CON.REGIONS_INDUSTRIES}
                      style={{ paddingTop: '80px' }}
                    >
                      <div
                        className={classes.tableTitle}
                        style={{ marginTop: '36px' }}
                      >
                        {' '}
                        {PAGE_CON.REGIONS_TITLE} & {PAGE_CON.SECTORS_TITLE}{' '}
                      </div>
                      <div className={classes.physRiskSubHeader}>
                        Physical Risk Hazards: Operations Risk
                      </div>
                    </div>
                  </AppBar>
                  <Grid item xs={12} style={regionStyle} heap_id="regsec">
                    {physicalRiskHazardsState.portfolioRegions.isLoading ? (
                      <LoadingMask />
                    ) : physicalRiskHazardsState.portfolioRegions.dataStatus ===
                        PAGE_CON.ERROR ||
                      (physicalRiskHazardsState.portfolioRegions.dataStatus ===
                        PAGE_CON.SUCCESS &&
                        !isRegionHasData()) ? (
                      <Error
                        id={'regionsError'}
                        dataStatus={
                          isFilterStateAvailable
                            ? physicalRiskHazardsState.portfolioRegions
                                .dataStatus
                            : PAGE_CON.SUCCESS
                        }
                        onErrorClick={reloadComponent}
                        errorOccuredOn={PAGE_CON.REGIONS_TITLE}
                      />
                    ) : (
                      <Regions
                        hideMap={true}
                        key={'RegionKey'}
                        data={physicalRiskHazardsState.portfolioRegions.data}
                        displayRegions={[]}
                        selectedCardId={selectedCardId}
                        onRegionSelect={selectedCard}
                        mimTabColInfo={mimTabColInfo}
                        panelTabColInfo={panelTabColInfo}
                        cardInfoContentInfo={cardInfoContentInfo}
                        cardInfoContentInfoSector={cardInfoContentInfoSector}
                        mapData={physicalRiskHazardsMapData}
                        mapDataClasses={mapDataClasses}
                        currentPageState={'globalPhysicalRiskHazards'}
                        categoryFalse={true}
                        stateData={true}
                        physicalRiskQuarterly={true}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            ) : null}
            {canShowAdditionalComp() ? (
              <>
                <Grid item container>
                  <a
                    href={'#' + PAGE_CON.PHY_RSK_HZRD_TITLE}
                    style={linkPadding}
                  >
                    <IconArrowDown />
                    <span style={linkStyle}>
                      Additional {PAGE_CON.PHY_RSK_HZRD_TITLE}
                    </span>
                  </a>
                </Grid>
                <Grid item container>
                  <AppBar
                    id={props.id}
                    position="sticky"
                    className={classes.overviewstickyHeader}
                    style={{ top: '37px' }}
                  >
                    <div
                      id={PAGE_CON.PHY_RSK_HZRD_TITLE}
                      style={{ paddingTop: '80px' }}
                    >
                      <div
                        className={classes.tableTitle}
                        style={dataMetricTitle}
                      >
                        {' '}
                        Additional {PAGE_CON.PHY_RSK_HZRD_TITLE}{' '}
                      </div>
                    </div>
                  </AppBar>

                  <Grid item xs={12} style={dataMetricboxMSupRsk}>
                    {PAGE_CON.MRK_RSK_TITLE}
                    {physicalRiskHazardsState.portfolioUnderlyingDataMetricsMR
                      .isLoading ||
                    physicalRiskHazardsState
                      .portfolioUnderlyingDataMetricsMRPanel.isLoading ? (
                      <LoadingMask />
                    ) : physicalRiskHazardsState
                        .portfolioUnderlyingDataMetricsMR.dataStatus ===
                        PAGE_CON.ERROR ||
                      (physicalRiskHazardsState.portfolioUnderlyingDataMetricsMR
                        .dataStatus === PAGE_CON.SUCCESS &&
                        physicalRiskHazardsState
                          .portfolioUnderlyingDataMetricsMR.data?.length ===
                          0) ? (
                      <Error
                        id={'udmError'}
                        dataStatus={
                          isFilterStateAvailable
                            ? physicalRiskHazardsState
                                .portfolioUnderlyingDataMetricsMR.dataStatus
                            : PAGE_CON.SUCCESS
                        }
                        onErrorClick={reloadComponent}
                        errorOccuredOn={PAGE_CON.PHY_RSK_HZRD_TITLE}
                      />
                    ) : (
                      <UnderlyingDataRiskMetrics
                        id="market_risk"
                        showBar={true}
                        panelTabColInfo={mrkSupplyPanelTabColInfo}
                        underlyingDataMetrics={
                          physicalRiskHazardsState
                            .portfolioUnderlyingDataMetricsMR.data
                        }
                        underlyingDataMetricsPanel={
                          physicalRiskHazardsState
                            .portfolioUnderlyingDataMetricsMRPanel.data
                        }
                        dataStatus={
                          physicalRiskHazardsState
                            .portfolioUnderlyingDataMetricsMR.dataStatus
                        }
                      />
                    )}
                    {PAGE_CON.SUPP_CHN_RISK_TITLE}
                    {physicalRiskHazardsState.portfolioUnderlyingDataMetricsSCR
                      .isLoading ||
                    physicalRiskHazardsState
                      .portfolioUnderlyingDataMetricsSCRPanel.isLoading ? (
                      <LoadingMask />
                    ) : physicalRiskHazardsState
                        .portfolioUnderlyingDataMetricsSCR.dataStatus ===
                        PAGE_CON.ERROR ||
                      (physicalRiskHazardsState
                        .portfolioUnderlyingDataMetricsSCR.dataStatus ===
                        PAGE_CON.SUCCESS &&
                        physicalRiskHazardsState
                          .portfolioUnderlyingDataMetricsSCR.data?.length ===
                          0) ? (
                      <Error
                        id={'udmError'}
                        dataStatus={
                          isFilterStateAvailable
                            ? physicalRiskHazardsState
                                .portfolioUnderlyingDataMetricsSCR.dataStatus
                            : PAGE_CON.SUCCESS
                        }
                        onErrorClick={reloadComponent}
                        errorOccuredOn={PAGE_CON.PHY_RSK_HZRD_TITLE}
                      />
                    ) : (
                      <UnderlyingDataRiskMetrics
                        id="supply_risk"
                        showBar={true}
                        panelTabColInfo={mrkSupplyPanelTabColInfo}
                        underlyingDataMetrics={
                          physicalRiskHazardsState
                            .portfolioUnderlyingDataMetricsSCR.data
                        }
                        underlyingDataMetricsPanel={
                          physicalRiskHazardsState
                            .portfolioUnderlyingDataMetricsSCRPanel.data
                        }
                        dataStatus={
                          physicalRiskHazardsState
                            .portfolioUnderlyingDataMetricsSCR.dataStatus
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
          <div style={{ height: '600px' }}></div>
        </Container>
      </main>
    </Page>
  )
}

export default PhysicalRiskHazards
