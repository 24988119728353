import React from 'react'
import * as S from '../../dashboardComponents/index.jsx'
import { PAGE_CON } from 'constant/constants'
import { PrimaryLink } from 'components'

const PhysicalRisk = ({ onClick }) => {
  return (
    <S.PanelContainer>
      <h2>{PAGE_CON.PHY_RSK_TITLE}</h2>
      <p>
        Moody’s provides risk scores for floods, heat stress, hurricanes &
        typhoons, sea level rise, water stress and wildfires across asset
        classes, including individual corporate facilities and other real
        assets, infrastructure assets, corporate entities, sub-national
        entities, and sovereigns to facilitate the understanding of a given
        portfolio’s exposure to physical climate hazards.
      </p>
      <br />

      <p>
        Facility scores for each hazard are aggregated up to the associated
        company and scaled by percentile with respect to the Reference Universe
        of companies to derive a hazard risk score for each company between 0
        (low risk) and 100 (high risk). We use the below scale to map
        corporate/facility hazard scores to risk levels:
      </p>

      <br />
      <p>No Risk – Not exposed</p>
      <p>
        Low Risk – Not significantly exposed to historical or projected risks
      </p>
      <p>Medium Risk – Exposed to some historical and/or projected risks</p>
      <p>High Risk – Exposed today and exposure level is increasing</p>
      <p>
        Red Flag – Highly exposed to historical and/ or projected risks,
        indicating high potential for negative impacts
      </p>

      <br />
      <ul>
        <li>
          <strong>Facilities Exposed:</strong> Percentage of underlying
          corporate facilities exposure to High Risk & Red Flag Risk hazards.
        </li>
        <li>
          <strong>Highest Risk Hazard:</strong> On average, the highest risk
          hazard exposed to your portfolio holdings.
        </li>
      </ul>
      <div>
        <PrimaryLink
          id="link-link-test-id-3"
          linkText={'Read more about Physical Risk'}
          onClick={() => onClick('Physical Risk')}
          linkClass="linkInline"
          linkColorChange={true}
        />
      </div>
    </S.PanelContainer>
  )
}

export default PhysicalRisk
