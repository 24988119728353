import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GlobalContext } from 'context/GlobalStateProvider'
import * as S from '../dashboardComponents/index.jsx'
import { List } from 'components'
import numberData, { carbonFootPrintDataFormat } from 'utils/numberFormat'
import { REDUCER_TYPE } from 'constant/constants'
import { numberOfCompanies } from 'utils/numberFormat'
import Description from './Description'
import {
  ScoreQualityLevel,
  predictedScoreHighlight
} from 'views/globalComponent/ScoreQualityLevel'

export const onEntityClick = (event, entityID, entityHeaderDispatch) => {
  if (entityID && entityID != '')
    entityHeaderDispatch({
      type: REDUCER_TYPE.SET_ORBIB_ID,
      selectedOrbisId: entityID
    })
  event.stopPropagation() // added to persist selected cell state on heatmap
}

const HeatmapEntity = ({
  entitlements,
  itemSelected,
  researchLinesSelected,
  handleHeatmapTableSelected,
  scoreQuality
}) => {
  const context = useContext(GlobalContext)
  const [dashboardState] = context.globalDashboard
  const [, entityHeaderDispatch] = useContext(GlobalContext).globalEntityHeader

  const selected =
    itemSelected &&
    researchLinesSelected &&
    itemSelected[0] &&
    researchLinesSelected[0] &&
    itemSelected[0].key !== '' &&
    researchLinesSelected[0] !== ''

  const formatValue = (value, researchLine, itemSelected) => {
    switch (researchLine) {
      case 'carbonfootprint':
        return (
          <div data-testid="HeatmapEntity-tCO2eq">
            {carbonFootPrintDataFormat(value)}
            <br />
            (tCO2eq)
          </div>
        )
      case 'brownshareasmt':
        return (
          <span data-testid="HeatmapEntity-brownshareasmt-value">
            {' '}
            {Number(value) > 0 ? (
              numberData(value, {
                isDecimal: true,
                isPercentage: true,
                maxFraction: 1
              })
            ) : (
              <span data-testid="HeatmapEntity-brownshareasmt-unformated">
                value
              </span>
            )}
          </span>
        )
      default:
        return itemSelected
    }
  }

  const formatValuePercent = (value) => `${value.toFixed(2)}%`

  const dashboardHeatmapEntity = dashboardState.dashboardHeatmapEntity.data

  const items = dashboardHeatmapEntity.map((item) => {
    const value1 = formatValue(
      item?.score_rl_1 || item?.category_score_r1_1,
      researchLinesSelected[0],
      itemSelected[0]?.label || itemSelected[0]?.key || ''
    )
    const value2 = formatValue(
      item?.score_rl_2 || item?.category_score_r1_2,
      researchLinesSelected[1],
      (itemSelected[1] && itemSelected[1]?.label) || ''
    )
    const scoreQualityKey = item
      ? Object.keys(item).filter((k) => k.startsWith('score_quality_rl'))[0]
      : undefined
    return {
      key: item.bvd9_number,
      title: item.parent_company_name || item.company_name,
      titleValue: (
        <S.ListCellContainer className="CompanyNameWrapper">
          {scoreQuality ? (
            <ScoreQualityLevel level={item[scoreQualityKey]} />
          ) : null}
          <S.ListCellContainer
            className="entity ellipsis"
            title={item.company_name}
            onClick={
              item.score_quality === 'Predicted'
                ? () => {}
                : (event) =>
                    onEntityClick(
                      event,
                      item.parent_bvd9_number || item.bvd9_number,
                      entityHeaderDispatch
                    )
            }
          >
            {item.company_name}
          </S.ListCellContainer>
        </S.ListCellContainer>
      ),
      subTitle: `${formatValuePercent(item.percent_holdings)} investment`,
      value: (
        <S.HeatmapEntityItem>
          <div>{value1}</div>
          {researchLinesSelected[1] && <div>{value2}</div>}
        </S.HeatmapEntityItem>
      ),
      percent_holdings: item.percent_holdings,
      predicted_score: predictedScoreHighlight(item[scoreQualityKey])
    }
  })

  useEffect(() => {
    handleHeatmapTableSelected([])
  }, [researchLinesSelected])

  const totalCompanies = items.length || 0
  const totalInvestment = items
    .reduce((acc, value) => {
      acc = acc + value.percent_holdings
      return acc
    }, 0)
    .toFixed(2)

  return (
    <div data-testid="HeatmapEntity">
      <S.HeatmapEntityContainer>
        {selected ? (
          <div data-testid="HeatmapEntity-list" className="entityList">
            <div>
              <List
                isEntityListHeatmap={true}
                isMultiline={true}
                header={
                  <S.HeatmapEntityHeader>
                    <div>
                      {numberOfCompanies(totalCompanies, true)},<br />
                      {totalInvestment}% Investment
                    </div>
                    <div>
                      <div>{itemSelected[0].label}</div>
                      {itemSelected[1] && <div>{itemSelected[1].label}</div>}
                    </div>
                  </S.HeatmapEntityHeader>
                }
                items={items}
                isLoading={dashboardState.dashboardHeatmapEntity.isLoading}
              />
            </div>
          </div>
        ) : (
          <div data-testid="HeatmapEntity-description">
            <Description
              researchLines={researchLinesSelected}
              entitlements={entitlements}
            />
          </div>
        )}
      </S.HeatmapEntityContainer>
    </div>
  )
}

HeatmapEntity.propTypes = {
  itemSelected: PropTypes.array,
  researchLinesSelected: PropTypes.array
}

export default HeatmapEntity
