export const DOCUMENT_TYPES = [
  'Annual Report',
  'Anti-Corruption & Compliance',
  'Code of Ethics and Business Conduct',
  'Corporate Governance Report & Charter',
  'Diversity Report',
  'Employees & Health and Safety',
  'Environmental Technical Report',
  'Fiscal Contributions',
  'Forest Policy',
  'Human Rights',
  'Sustainability Plan',
  'Sustainability Report',
  'Technology',
  'Whistleblower Policy',
  'Other'
]

export const COLUMNS_FIELDS = {
  ID: 'id',
  URL: 'URL',
  DOCUMENT_TYPE: 'documentType',
  DISCLOSURE_YEAR: 'disclosureYear',
  DELETE: 'delete'
}

export const URL_REGEX = new RegExp(
  '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
)
