import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'
import { useStyle } from './styles'

function getDisplayText(content, data) {
  let displayText = ''
  if (data) {
    displayText = data[content.dataField]
  }
  return displayText
}

/**
 * @visibleName Mini Card
 */
const SearchCardInfoSection = (props) => {
  const { id, contentInfo, data } = props
  let classes = useStyle(props)

  return (
    <Box id={id} key={id} className={classes.root}>
      {contentInfo &&
        contentInfo.length > 0 &&
        contentInfo.map((listContent, index) => {
          let text = getDisplayText(listContent, data)
          let toolTipText = ''
          if (listContent.showToolTip) {
            toolTipText = text
              .replace(/<mark.*?>/gi, '')
              .replace(/<\/mark>/gi, '')
          }

          let markupText = { __html: text }
          return (
            <Box
              key={`innerBox${index}`}
              className={clsx(
                listContent.className
                  ? classes[listContent.className]
                  : classes.textStyle
              )}
            >
              <div
                title={toolTipText}
                dangerouslySetInnerHTML={markupText}
              ></div>
            </Box>
          )
        })}
    </Box>
  )
}

SearchCardInfoSection.propTypes = {
  /** contentInfo will have text and date details */
  contentInfo: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      dataField: PropTypes.string,
      labelType: PropTypes.oneOf(['Date', 'Radio', 'Text']),
      showToolTip: PropTypes.bool
    })
  ),
  /** Data to be consumed by this component  */
  data: PropTypes.any,
  /** id for Mini Card Info component */
  id: PropTypes.string,
  /** Max width for cardInfoSection */
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default SearchCardInfoSection
