import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'

const useStyles = makeStyles(() => ({
  container: {
    width: '10px',
    display: 'inline-block'
  }
}))

export const Container = ({ children }) => {
  const classes = useStyles(theme)
  return <span className={classes.container}>{children}</span>
}
