import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import React from 'react'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import * as S from './styles'

const BarchartWide = ({
  chartTitle,
  title,
  data,
  chartHeight,
  chartWidth,
  colHeight,
  colWidth,
  barColors,
  labelNearestTenth
}) => {
  // calculate yaxis tick values by getting largest bar total height from data
  var sum = data[0].data.map(function (num, idx) {
    return num + data[1].data[idx]
  })
  let ymax = Math.max(...sum)

  if (labelNearestTenth) {
    let division = parseInt(ymax / 10)
    let modVal = ymax % 10

    ymax = division * 10

    if (modVal > 0) ymax = (division + 1) * 10
  }

  const labels = [0, ymax / 2, ymax]
  const options = {
    credits: {
      enabled: false
    },
    chart: {
      spacingBottom: 0,
      marginBottom: 1,
      marginTop: 20,
      marginLeft: 20,
      backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
      type: 'column',
      width: chartWidth ? chartWidth : 172,
      height: chartHeight ? chartHeight : 144,
      style: {
        ...textTheme.typography.p4,
        fontSize: '10px !important'
      }
    },
    title: {
      enabled: false,
      text: title,
      align: 'left',
      style:
        title === '.'
          ? {
              color: themeColorPalette.palette.white.main,
              ...textTheme.typography.p2
            }
          : {
              ...textTheme.typography.p2
            }
    },
    xAxis: {
      labels: {
        enabled: false,
        style: {
          ...textTheme.typography.p2,
          fontSize: '10px',
          color: textTheme.palette.sys_trad.text.main
        }
      },
      height: colHeight ? colHeight : null,
      lineWidth: 0,
      tickLength: 0
    },
    yAxis: {
      tickPositions: labels,
      visible: true,
      title: {
        enabled: false
      },
      gridLineWidth: 0,
      labels: {
        y: -4,
        align: 'left',
        formatter: function () {
          return `${this.value}%`
        },
        style: {
          ...textTheme.typography.p2,
          fontSize: '10px',
          color: textTheme.palette.sys_trad.text.main
        }
      }
    },
    legend: {
      reversed: true,
      enabled: false
    },
    plotOptions: {
      column: {
        pointPadding: 0.08,
        borderWidth: 0,
        groupPadding: 0.08,
        pointWidth: colWidth ? colWidth : undefined,
        colorByPoint: true,
        borderRadius: 2
      },
      series: {
        stacking: 'normal', //normal (true value height) or percent (all even heights)
        enableMouseTracking: false
      }
    },
    colors: barColors,
    series: data
  }

  return (
    <div>
      <div style={S.titleStyle}> {chartTitle} </div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        oneToOne={true}
      />
    </div>
  )
}
BarchartWide.propTypes = {
  /** Title of the BarchartWide bar - inside chart */
  title: PropTypes.string,
  /** Title of the BarchartWide bar - above chart */
  chartTitle: PropTypes.string,
  /** Data to be displayed */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.number)
    })
  ),
  /** color code to be filled in BarchartWide bar */
  barColors: PropTypes.arrayOf(PropTypes.string),
  /** no percent for tooltip and visual chart label */
  notPercent: PropTypes.bool,
  /** Titles at the x axis for each column */
  xAxisTitles: PropTypes.array,
  /** Labels at the y axis */
  yAxisLabels: PropTypes.any,
  /** Height of each column */
  colHeight: PropTypes.any,
  /** Height of entire chart */
  chartHeight: PropTypes.any,
  /** Width of each column */
  colWidth: PropTypes.any,
  /** Width of entire chart */
  chartWidth: PropTypes.any,
  /** if true will display the y axis top label value to its nearest tenth */
  labelNearestTenth: PropTypes.bool
}

BarchartWide.defaultProps = {
  labelNearestTenth: true
}

export default React.memo(BarchartWide)
