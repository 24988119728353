import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconPaperPlane = (props) => (
  <SvgIcon {...props}>
    <svg width={props.width || '12'} height={props.height || '12'} viewBox={props.viewBox || '0 0 12 12'}fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.370035 6.09848C-0.160005 6.40082 -0.110754 7.20707 0.454466 7.44144L3.74494 8.81252V11.2336C3.74494 11.6578 4.08735 12 4.51185 12C4.73935 12 4.95512 11.8992 5.10053 11.7234L6.55462 9.98205L9.46046 11.1914C9.90372 11.3766 10.4173 11.0859 10.49 10.6125L11.991 0.862583C12.0356 0.578991 11.9113 0.293056 11.6744 0.131339C11.4376 -0.0303789 11.128 -0.0444413 10.877 0.0985264L0.370035 6.09848ZM1.59194 6.69613L9.60118 2.12351L4.45087 7.87503L4.47902 7.89847L1.59194 6.69613ZM9.45108 9.97033L5.54379 8.34143L10.5651 2.73288L9.45108 9.97033Z"
        fill={props.fillcolor || '#26415E'}
      />
    </svg>
  </SvgIcon>
)
IconPaperPlane.displayName = 'IconPaperPlane'
IconPaperPlane.muiName = 'SvgIcon'

export default IconPaperPlane
