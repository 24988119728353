import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconDownload = (props) => (
  <SvgIcon {...props}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 9.4502V11.4502C2 12.5548 2.89543 13.4502 4 13.4502H12C13.1046 13.4502 14 12.5548 14 11.4502V9.4502"
        stroke={props.fillcolor ? props.fillcolor : '#26415E'}
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.9497 10.2057C7.63392 10.2057 7.37793 9.94969 7.37793 9.63391L7.37793 2.8086C7.37793 2.49282 7.63392 2.23683 7.9497 2.23683C8.26548 2.23683 8.52147 2.49282 8.52147 2.8086L8.52147 9.63391C8.52147 9.94969 8.26548 10.2057 7.9497 10.2057Z"
        fill={props.fillcolor ? props.fillcolor : '#26415E'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5884 6.86126C11.8117 7.08455 11.8117 7.44657 11.5884 7.66987L8.35401 10.9043C8.13072 11.1276 7.76869 11.1276 7.5454 10.9043C7.32211 10.681 7.32211 10.319 7.5454 10.0957L10.7798 6.86126C11.0031 6.63797 11.3651 6.63797 11.5884 6.86126Z"
        fill={props.fillcolor ? props.fillcolor : '#26415E'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.35401 10.9043C8.13072 11.1276 7.76869 11.1276 7.5454 10.9043L4.31097 7.66987C4.08768 7.44658 4.08768 7.08455 4.31097 6.86126C4.53426 6.63797 4.89629 6.63797 5.11958 6.86126L8.35401 10.0957C8.5773 10.319 8.5773 10.681 8.35401 10.9043Z"
        fill={props.fillcolor ? props.fillcolor : '#26415E'}
      />
    </svg>
  </SvgIcon>
)
IconDownload.displayName = 'IconDownload'
IconDownload.muiName = 'SvgIcon'

export default IconDownload
