import { Grid } from '@material-ui/core'
import bullet from 'highcharts/modules/bullet.js'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import React from 'react'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import * as S from './styles'

/**
 * @visibleName Bar Chart
 */

const findScoreRange = (xValue) => {
  if (Number(xValue) < 100) {
    const nextVal = Number(xValue) + 9
    let returnText = xValue + '-' + nextVal
    return returnText
  } else {
    return '90-100'
  }
}

//need to add unique ids to all charts!!
const BarChart = ({
  id,
  title,
  categories,
  data,
  underlyingDataMetricsBarChart,
  height,
  width,
  categoryColor,
  clickable,
  onClick
}) => {
  const classes = S.useStyles()

  bullet(Highcharts)
  const options = {
    colors: categoryColor,
    credits: {
      enabled: false
    },
    chart: {
      type: 'column',
      height: height || 120,
      width: width || 225,
      style: {
        ...textTheme.typography.p4
      }
    },
    title: {
      style:
        title === '.'
          ? {
              color: themeColorPalette.palette.white.main,
              ...textTheme.typography.p2
            }
          : {
              ...textTheme.typography.p2,
              fontSize: '10 !important'
            },
      text: title || 'BarChart',
      align: 'left'
    },
    xAxis: {
      lineColor: themeColorPalette.palette.sys_trad.border.main,
      labels: {
        style: {
          textOverflow: 'none',
          whiteSpace: 'nowrap'
        },
        rotation: 0,
        formatter: function () {
          if (this.isLast || this.isFirst) {
            return this.value
          }
        }
      },
      categories: categories,
      crosshair: true
    },
    yAxis: {
      min: 0,
      visible: false,
      title: {
        text: 'Value'
      }
    },
    tooltip: {
      enabled: underlyingDataMetricsBarChart ? true : false,
      formatter: function () {
        return (
          '<b> Score Range: </b>' +
          findScoreRange(this.x) +
          '<br/><b>Company Count: </b>' +
          this.y
        )
      }
    },
    plotOptions: {
      series: {
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 0,
        cursor: clickable && 'pointer',
        states: {
          hover: {
            enabled: false
          }
        }
      },
      column: {
        borderWidth: 0,
        colorByPoint: true,
        events: {
          click: function () {
            clickable && onClick()
          }
        }
      }
    },
    legend: {
      enabled: false
    },
    series: [
      {
        data: data,
        enableMouseTracking: underlyingDataMetricsBarChart ? true : false
      }
    ]
  }

  return (
    <Grid className={classes.barChart}>
      <HighchartsReact
        id={id}
        highcharts={Highcharts}
        options={options}
        allowChartUpdate={false}
      />
    </Grid>
  )
}

BarChart.propTypes = {
  /** BarChart id */
  id: PropTypes.string,
  /** BarChart title */
  title: PropTypes.string,
  /** BarChart data */
  data: PropTypes.any,
  /** BarChart categories */
  categories: PropTypes.any,
  /** BarChart to add paddingLeft */
  padding: PropTypes.bool,
  /** Stops for colors */
  stops: PropTypes.array,
  /** Bar Chart for Underlying Data Metrics */
  underlyingDataMetricsBarChart: PropTypes.any
}

export default React.memo(BarChart)
