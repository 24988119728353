import { Container, Grid, makeStyles } from '@material-ui/core'
import GenericContainer from './GenericContainer'
import React from 'react'
import { PAGE_CON } from '../../constant/constants'

const drawerWidth = PAGE_CON.PAGE_LEFT_MARGIN

const useStyles = makeStyles(() => ({
  content: {
    flexGrow: 1
  },
  container: {
    marginLeft: drawerWidth - 20
  }
}))

const ComingSoon = () => {
  const classes = useStyles()

  return (
    <main className={classes.content}>
      <Container className={classes.container} maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <GenericContainer />
          </Grid>
        </Grid>
      </Container>
    </main>
  )
}

export default ComingSoon
