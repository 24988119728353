import { Radar } from 'components'
import React from 'react'
import themeColorPalette from 'theme/theme'

const RadarChart = ({ id, showBenchmark, state }) => {
  const radar = React.useMemo(() => {
    const radarData = showBenchmark
      ? state.portfolioESGScore.data.score.benchmark_score
      : state.portfolioESGScore.data.score.portfolio_score

    return (
      <Radar
        id={id}
        chartPosition="center"
        data={radarData.filter((category) => category.name !== 'Total')}
        radarColors={[
          themeColorPalette.palette.adv_wk.weak.main,
          themeColorPalette.palette.adv_wk.limited.main,
          themeColorPalette.palette.adv_wk.robust.main,
          themeColorPalette.palette.adv_wk.advanced.main
        ]}
      />
    )
  }, [])

  return radar
}

export default RadarChart
