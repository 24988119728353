import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'

// eslint-disable-next-line react/display-name
const Page = forwardRef(({ children, title = '', ...rest }, ref) => {
  return (
    <div ref={ref} {...rest}>
      <Helmet id="page-helmet-test-id">
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  )
})

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}

export default Page
