export const datadogConfig = {
  applicationId: '6b3489d7-6c42-40dd-823a-9b7f7b63e1ff',
  clientToken: process.env.REACT_APP_DATADOG_KEY,
  site: 'datadoghq.com',
  service: 'esg360',
  env: process.env.REACT_APP_ENV,
  host: window.location.host,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: '2.1.0',
  forwardErrorsToLogs: true,
  sampleRate: 100,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackInteractions: true, // enable tracking user interactions
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input'
}
