import React from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { Page, PrimaryButton, PrimaryModal } from 'components'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import { useHistory } from 'react-router-dom'
import getEntitlements from 'entitlements'
import { useOktaAuth } from '@okta/okta-react'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  textStyle: {
    ...textTheme.typography.p3,
    color: themeColorPalette.palette.sys_trad.text2.main
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}))

const errorHeaderStyle = {
  ...textTheme.typography.h2,
  textAlign: 'center',
  color: themeColorPalette.palette.sys_trad.main.main
}

const NotFoundView = (props) => {
  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const { authState } = useOktaAuth()
  const entitlements = getEntitlements(authState, props.apiEntitlements)

  const openModal = () => {
    setOpen(true)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  const onReturnButtonClick = () => {
    entitlements['issuer']
      ? history.push('/issuerworkspace')
      : props.isEntitled
      ? history.push('/')
      : openModal()
  }

  return (
    <Page className={classes.root} title="Moody's Analytics">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Grid
          container
          alignItems={'center'}
          direction="column"
          id={props.id}
          spacing={2}
          className={classes.gridStyle}
        >
          <Grid item className={classes.textStyle}>
            <div style={errorHeaderStyle}> Page not found </div>
            <br />
            The link might be corrupted or the page removed
          </Grid>
          <Grid item>
            <PrimaryButton
              id="error-button-test-id"
              buttonText="Return to Moody's Analytics"
              onClick={onReturnButtonClick}
              buttonClass="buttonPrimary"
            />
          </Grid>
        </Grid>
        <PrimaryModal
          id="no-entitlements-test-id"
          modalOpen={open}
          onModalClose={handleCloseDialog}
          modalClass="smallModal"
          modalTitle={'Subscription Required'}
          modalContentComponent={
            <span>
              You are not subscribed to Moody's Analytics Platform.
              <br />
              For support, please contact:
              <a href="mailto:clientservices@moodys.com">
                clientservices@moodys.com
              </a>
            </span>
          }
          modalActionComponent={
            <PrimaryButton
              id="no-entitlements-button-test-id"
              buttonText="Ok"
              onClick={handleCloseDialog}
              buttonClass="buttonPrimary"
            />
          }
        />
      </Box>
    </Page>
  )
}

export default NotFoundView
