import React from 'react'
import * as S from './styles'
import PropTypes from 'prop-types'
import Constant from 'constant'

const Item = ({
  entityName,
  title,
  subTitle,
  value,
  label,
  itemKey,
  clickHandler,
  selected,
  mapRange,
  researchLineSelected,
  researchLineSelectedUnserscore,
  isEntity
}) => {
  const findColorByScoreCategory = (score_category) => {
    if (researchLineSelectedUnserscore && score_category) {
      const researchLineByLabel = Constant.Scores.researchLineScoreByLabel(
        researchLineSelectedUnserscore,
        score_category
      )
      return researchLineByLabel?.color || undefined
    } else {
      return undefined
    }
  }

  return (
    <S.ListItem
      value={value}
      onClick={() => clickHandler && clickHandler(itemKey)}
      selected={selected}
      clickable={clickHandler !== undefined}
      mapRange={mapRange}
      isEntity={isEntity}
      bgColor={findColorByScoreCategory(label)}
    >
      <div
        id={
          title && label && researchLineSelected
            ? `${title}_${label}_${researchLineSelected}_id`
            : 'undefined'
        }
        // eslint-disable-next-line react/no-unknown-property
        heap_id_title={
          title ? `${title.replace(';', '_').replace(/\s/g, '_')}` : 'undefined'
        }
        // eslint-disable-next-line react/no-unknown-property
        heap_id_label={
          label ? `${label.replace(';', '_').replace(/\s/g, '_')}` : 'undefined'
        }
        // eslint-disable-next-line react/no-unknown-property
        heap_id_researchline={
          researchLineSelected
            ? `${researchLineSelected.replace(';', '_').replace(/\s/g, '_')}`
            : 'undefined'
        }
      >
        <h3 title={entityName ? entityName : title}>
          {entityName ? entityName : title}
        </h3>
        <h4 title={subTitle}>{subTitle}</h4>
      </div>
      {researchLineSelected !== 'controversies' && (
        <div className="label">
          <span>{label}</span>
        </div>
      )}
    </S.ListItem>
  )
}

Item.propTypes = {
  entityName: PropTypes.any,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  value: PropTypes.number,
  label: PropTypes.string,
  itemKey: PropTypes.string,
  clickHandler: PropTypes.func,
  selected: PropTypes.bool,
  mapRange: PropTypes.array,
  researchLineSelected: PropTypes.string,
  researchLineSelectedUnserscore: PropTypes.string
}

export default Item
