import theme from 'theme'

export const researchLinesColors = (researchLine) => {
  const positiveResearchLines = [
    'company_name',
    'percent_holdings',
    'physical_risk_management',
    'energy_transition',
    'green_share',
    'tcfd_strategy'
  ]

  const upColor = positiveResearchLines.includes(researchLine)
    ? theme?.palette?.sys_trad?.success.main
    : theme?.palette?.sys_trad?.error.main
  const downColor = positiveResearchLines.includes(researchLine)
    ? theme?.palette?.sys_trad?.error.main
    : theme?.palette?.sys_trad?.success.main

  return { upColor, downColor }
}
