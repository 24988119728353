import { REDUCER_TYPE } from 'constant/constants'

export function portfolioReviewInitialState() {
  let obj = {}
  obj.portfolioReviewPage = ''
  return obj
}

const PortfolioReviewReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.PORTFOLIO_REVIEW_PAGE:
      currentState.portfolioReviewPage = action.page
        .replace(/\s+/g, '')
        .toLowerCase()
      return { ...currentState }
    default:
      return currentState
  }
}

export default PortfolioReviewReducer
