import React from 'react'
import { Grid } from '@material-ui/core'
import { useStyles } from './styles'

const EmptyPortfolios = () => {
  const classes = useStyles()

  return (
    <Grid item className={classes.emptyList}>
      No portfolio available.
    </Grid>
  )
}
export default EmptyPortfolios
