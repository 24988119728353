import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { HeatMap } from 'components'
import LoadingMask from 'utils/loadingMask'
import { GlobalContext } from 'context/GlobalStateProvider'
import * as S from '../dashboardComponents/index.jsx'

const HeatmapTable = ({
  entitlements,
  researchLines,
  handleHeatmapTableSelected,
  selectedHeatmapTable
}) => {
  const context = useContext(GlobalContext)
  const [dashboardState] = context.globalDashboard

  const data = dashboardState.dashboardHeatmapTable?.data[0]
    ? {
        table: dashboardState.dashboardHeatmapTable?.data[0]?.HeatMap_Data,
        researchLine1:
          dashboardState.dashboardHeatmapTable?.data[0].y_axis_total_invct_pct,
        researchLine2:
          dashboardState.dashboardHeatmapTable?.data[0].x_axis_total_invct_pct
      }
    : null

  return (
    <div data-testid="HeatmapTable">
      <S.BoxContainer padding="0 5px 0 0">
        {dashboardState.dashboardHeatmapTable.isLoading ? (
          <div data-testid="HeatmapTable-loading">
            <LoadingMask />
          </div>
        ) : (
          <div data-testid="HeatMap">
            <HeatMap
              data={data}
              entitlements={entitlements}
              researchLines={researchLines}
              handleSelectItem={handleHeatmapTableSelected}
              selectedHeatmapTable={selectedHeatmapTable}
              cellsDisabled={dashboardState.dashboardHeatmapEntity.isLoading}
            />
          </div>
        )}
      </S.BoxContainer>
    </div>
  )
}

HeatmapTable.propTypes = {
  entitlements: PropTypes.object,
  researchLines: PropTypes.array,
  handleHeatmapTableSelected: PropTypes.func
}

export default HeatmapTable
