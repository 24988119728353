import { MenuItem, Toolbar } from '@material-ui/core'
import React, { useContext } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { GlobalContext } from 'context/GlobalStateProvider'
import { REDUCER_TYPE } from 'constant/constants'
import PlatformSearch from 'platformSearch/index'
import { IconClose, IconSearch } from 'components'
import * as S from './styles'

const EntitySearch = (props) => {
  const classes = S.useStyles()
  const { drawerToggleSearch } = S.useStyles()

  const [open, setOpen] = React.useState(false)
  const [searchType, setSearchType] = React.useState(false)
  const [, entityHeaderDispatch] = useContext(GlobalContext).globalEntityHeader

  const onSearchClick = () => {
    setSearchType(false)
    setOpen(true)
  }

  const handleCookiesPopupClose = () => {
    setOpen(false)
  }

  const onEntitySelect = (orbis_Id) => {
    entityHeaderDispatch({
      type: REDUCER_TYPE.SET_ORBIB_ID,
      selectedOrbisId: orbis_Id
    })
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSearchType = (inputText) => {
    if (inputText === '' || inputText.trim().length === 0) {
      setSearchType(false)
    } else {
      setSearchType(true)
    }
  }

  const showSearchIcon = props.entitlements.climate
  return (
    <>
      {showSearchIcon && (
        <div
          aria-hidden={true}
          className={classes.searchIconStyle}
          onClick={onSearchClick}
          // eslint-disable-next-line react/no-unknown-property
          heap_id="search"
        >
          {<IconSearch />}
        </div>
      )}
      <Dialog
        open={open}
        onClose={handleCookiesPopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: !searchType
            ? classes.searchDialog
            : classes.searchDialogTextEntered,
          scrollPaper: classes.topScrollPaper,
          paperScrollBody: classes.topPaperScrollBody
        }}
        BackdropProps={S.backdropProps}
      >
        <Toolbar
          disableGutters
          className={
            !searchType
              ? classes.searchToolbarStyle
              : classes.searchToolbarStyleTextEntered
          }
        >
          <div>
            <MenuItem
              style={{ fontSize: '13px !important', cursor: 'auto' }}
              className={drawerToggleSearch}
            >
              {'Search for Entities'}
            </MenuItem>
          </div>
          <div
            onClick={handleClose}
            aria-hidden={true}
            style={{
              display: 'flex',
              cursor: 'pointer',
              marginRight: '14px',
              marginTop: '8px'
            }}
          >
            {<IconClose />}
          </div>
        </Toolbar>
        <DialogContent className={classes.dialogContentStyle}>
          <PlatformSearch
            id={'prop-search'}
            key={'prop-search'}
            onUploadClick={() => setOpen(false)}
            onEntityClick={onEntitySelect}
            onSearchType={onSearchType}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default EntitySearch
