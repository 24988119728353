import { multiColumnSort } from 'utils/Sort'
import clsx from 'clsx'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Error from 'utils/error'
import React from 'react'
import { BarChart, Panel, RegularTable, Stacked } from 'components'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: themeColorPalette.palette.sys_trad.border.main
  },
  sectionTitle: {
    ...textTheme.typography.p2,
    textDecoration: 'underline'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  grid: {
    ...textTheme.typography.p3,
    color: themeColorPalette.palette.sys_trad.text.main
  },
  hazardsGrid: {
    paddingLeft: '30px !important',
    textAlign: 'left'
  },
  panelSubTitle: {
    paddingLeft: '20px',
    marginTop: '-8px'
  }
}))

function getSelectedData(data, catName) {
  let separatedData = []

  if (data && data.length > 0) {
    const detailDataArr = data.filter(
      (regData) => regData.name && regData.name === catName.trim()
    )
    separatedData = detailDataArr[0]
  }
  return separatedData
}

const UnderlyingDataRiskMetrics = (props) => {
  const classes = useStyles(props)
  const UnderlyingData = props.underlyingDataMetrics
  const dataStatus = props.dataStatus
  const showBar = props.showBar

  const [openMrkSupply, setOpenMrkSupply] = React.useState(false)
  const [panelComp, setPanelComp] = React.useState([])
  const [panelSubtitle, setPanelSubtitle] = React.useState('')

  const openMrkSupplyRiskDrawer = () => {
    setOpenMrkSupply(true)
  }
  const handleMrkSupplyRiskDrawerCloseDrawer = () => {
    setOpenMrkSupply(false)
  }

  function sortData(data) {
    const sortByCols = [
      ['score', 'ASC'],
      ['investment_pct', 'ASC'],
      ['company_name', 'ASC', 'string']
    ]
    let returnData = data
    if (returnData && returnData.length > 0) {
      returnData.forEach((category) =>
        category && category[1]
          ? multiColumnSort(category[1], sortByCols)
          : null
      )
    }
    return returnData
  }

  const mrkSupplySelect = (item) => {
    let separatedData = getSelectedData(
      props.underlyingDataMetricsPanel,
      item.title
    )

    const newPanelComp = []
    setPanelSubtitle(item.title)

    let tempSeparatedData = Object.entries(separatedData)
    tempSeparatedData = tempSeparatedData.filter((item) => item[0] !== 'name')
    let sortedData = sortData(tempSeparatedData)

    const mappedData = sortedData?.map((categoryData, catIndex) => {
      const panelCol = props.panelTabColInfo.map((col, index) => {
        const newCol = { ...col }
        if (index === 0) {
          newCol.HeaderLabel = categoryData[0]
        }
        return newCol
      })

      return (
        <div key={`mrksupplyriskPanel-${categoryData[0]}-Div-${catIndex}`}>
          <RegularTable
            id={`mrksupplyriskPanelTab-${categoryData[0]}-Div-${catIndex}`}
            columns={panelCol}
            totalData={categoryData[1]}
            horScroll="hidden"
            tablePadding="20px"
            tableEditable={false}
            onUploadClick={() => handleMrkSupplyRiskDrawerCloseDrawer()}
          />
        </div>
      )
    })

    const mergedData = newPanelComp.concat(mappedData)

    setPanelComp(mergedData)

    openMrkSupplyRiskDrawer()
  }

  const underlyingDataMetrics = React.useMemo(() => {
    return {
      data: UnderlyingData,
      categories: ['0', '10', '20', '30', '40', '50', '60', '70', '80', '100'],
      categoryColor: [
        themeColorPalette.palette.genrang_b.one.main,
        themeColorPalette.palette.genrang_b.two.main,
        themeColorPalette.palette.genrang_b.three.main,
        themeColorPalette.palette.genrang_b.four.main,
        themeColorPalette.palette.genrang_b.five.main,
        themeColorPalette.palette.genrang_b.six.main,
        themeColorPalette.palette.genrang_b.seven.main,
        themeColorPalette.palette.genrang_b.eight.main,
        themeColorPalette.palette.genrang_b.nine.main,
        themeColorPalette.palette.genrang_b.ten.main
      ],

      barColors: [
        themeColorPalette.palette.noflag.redflgrsk.main,
        themeColorPalette.palette.noflag.hirsk.main,
        themeColorPalette.palette.noflag.medrsk.main,
        themeColorPalette.palette.noflag.lowrsk.main,
        themeColorPalette.palette.noflag.norsk.main
      ]
    }
  }, [dataStatus])

  const udm_description = {
    'Weather Sensitivity':
      'Weather Sensitivity measures the sensitivity of economic output to climate variability based on the industries within which the company operates. Weather Sensitivity is a percentile score between 0 (low risk) and 100 (high risk).',
    'Country of Sales':
      'Country of Sales measures climate risk in the countries in which a company generates its sales. Country of Sales is a percentile score between 0 (low risk) and 100 (high risk).',
    'Country of Origin':
      'Country of Origin measures climate risk in countries that export commodities that a company depends on for the production and delivery of products and services. Country of Origin is a percentile score between 0 (low risk) and 100 (high risk).',
    'Resource Demand':
      'Resource Demand measures a company’s dependence on resources affected by climate change. Resource Demand is a percentile score between 0 (low risk) and 100 (high risk).'
  }
  return (
    <Grid className={classes.grid} item xs={12} sm={12}>
      <Grid className={classes.grid} item xs={12}>
        {underlyingDataMetrics.data && underlyingDataMetrics.data.length > 0 ? (
          underlyingDataMetrics.data.map((item, index) => {
            return (
              <span key={`${index}`}>
                <Grid
                  container
                  className={classes.grid}
                  direction={'row'}
                  spacing={2}
                >
                  <Grid
                    item
                    className={clsx(
                      classes.grid,
                      props.isPhysicalRiskHazards ? classes.hazardsGrid : null
                    )}
                    xs={4}
                  >
                    <br />
                    <b>{item.title}</b> <br />
                    <br />
                    {udm_description[item.title]}
                  </Grid>

                  <Grid className={classes.grid} item xs={8}>
                    <Grid
                      container
                      direction={'row'}
                      spacing={2}
                      className={classes.grid}
                    >
                      {showBar ? (
                        <BarChart
                          clickable={
                            props.id === 'market_risk' ||
                            props.id === 'supply_risk'
                              ? true
                              : false
                          }
                          onClick={
                            props.id === 'market_risk' ||
                            props.id === 'supply_risk'
                              ? () => mrkSupplySelect(item)
                              : null
                          }
                          underlyingDataMetricsBarChart={true}
                          title={index === 0 ? ' ' : '.'}
                          padding={true}
                          data={item.data !== undefined ? item.data[0] : []}
                          categories={underlyingDataMetrics.categories}
                          categoryColor={underlyingDataMetrics.categoryColor}
                        />
                      ) : (
                        <Stacked
                          data={item.data !== undefined ? item.data[0] : []}
                          title={index === 0 ? '% Facilities Exposed' : '.'}
                          barColors={underlyingDataMetrics.barColors}
                        />
                      )}
                      <Panel
                        title={
                          props.id === 'market_risk'
                            ? 'Market Risk'
                            : 'Supply Risk'
                        }
                        subtitle={panelSubtitle}
                        openPanel={openMrkSupply}
                        titlePadding="12px"
                        onPanelClose={handleMrkSupplyRiskDrawerCloseDrawer}
                        panelContentComponent={panelComp}
                      ></Panel>
                    </Grid>
                  </Grid>
                </Grid>{' '}
                <br></br>
                {index + 1 !== underlyingDataMetrics.data.length && (
                  <Divider className={classes.divider} />
                )}
                <br></br>
              </span>
            )
          })
        ) : (
          <Error id={'updatesError'} dataStatus={'error'} />
        )}
      </Grid>
    </Grid>
  )
}

export default UnderlyingDataRiskMetrics
