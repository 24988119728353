import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
  CountryList,
  Page,
  PageHeader,
  Panel,
  PrimaryLink,
  PrimaryPopover,
  Section
} from 'components'
import { GlobalContext } from 'context/GlobalStateProvider'
import { DashboardService, ResearchLineService } from 'services'
import {
  FEATURE_FLAGS,
  PAGE_CON,
  REDUCER_STATE,
  REDUCER_TYPE
} from 'constant/constants'
import SummaryHeaders from './SummaryHeaders'
import * as S from './dashboardComponents'
import PortfolioMonitoring from './PortfolioMonitoring'
import PerformanceTable from './PerformanceTable'
import PortfolioComp from 'views/globalComponent/PortfolioComp'
import BackdropComp from 'views/globalComponent/BackdropComp'
import RegionSectorDateComp from 'views/globalComponent/RegionSectorDateComp'
import ScoreQuality from 'views/globalComponent/ScoreQuality'
import Upload from '../upload/UploadComponent'
import SummarySidePanel from './SummaryHeaders/SummarySidePanel'
import GeographicRisk from './GeographicRisk'
import Heatmap from './Heatmap'
import HeatmapEntity from './Heatmap/HeatmapEntity'
import HeatmapHeader from './Heatmap/Header'
import Constant from 'constant'
import Error from 'utils/error'
import LoadingMask from 'utils/loadingMask'
import CompaniesAndInvestmentsTable from './viewCompaniesInvestmentPortfolio'
import CompaniesAndInvestmentsHeader from './viewCompaniesInvestmentPortfolio/header'
import { Backdrop } from '@material-ui/core'
import { numberOfCompanies } from 'utils/numberFormat'
import { getPromise } from 'utils/helper'
import { useHistory } from 'react-router-dom'

const DashboardView = ({ entitlements }) => {
  const history = useHistory()
  const context = useContext(GlobalContext)
  const [dashboardState, dashboardDispatch] = context.globalDashboard
  const [, entityHeaderDispatch] = useContext(GlobalContext).globalEntityHeader
  const [, portfolioReviewDispatch] = context.globalPortfolioReview
  let apiCallId = dashboardState.apiCallId
  const [countryListSelectedKey, setCountryListSelectedKey] = useState('')
  const [entityListSelectedKey, setEntityListSelectedKey] = useState('')
  const [mapRef, setMapRef] = useState(null)
  const [openPanel, setOpenPanel] = useState(false)
  const [openViewCPanel, setopenViewCPanel] = useState(false)
  const [selectedHeatmapTable, setSelectedHeatmapTable] = useState([])
  const [setOpen] = React.useState(false)

  const researchLines = Constant.Dashboard.getResearchLines(
    entitlements
  ).filter((researchline) => researchline.isEntitled)

  const researchLinesNoHeaders = researchLines.filter(({ header }) => !header)

  const researchLinesNoHeadersHeatmap = researchLines.filter(
    ({ header, heatMap }) => !header && heatMap
  )
  const researchLinesNoHeadersGeographicRisk = researchLines.filter(
    ({ header, geographicRisk }) => !header && geographicRisk
  )

  const [selectedHeatmapResearchLines, setSelectedHeatmapResearchLines] =
    useState([
      researchLinesNoHeadersHeatmap[0]?.key,
      researchLinesNoHeadersHeatmap[1]?.key
    ])
  const [selectedGeographicResearchLine, setSelectedGeographicResearchLine] =
    useState(researchLinesNoHeadersGeographicRisk[0]?.key)

  const [selectedPerformanceTableRange, setSelectedPerformanceTableRange] =
    useState(null)
  const [selectedPerformanceTableTable, setSelectedPerformanceTableTable] =
    useState(null)
  const [
    selectedPerformanceTableResearchLine,
    setSelectedPerformanceTableResearchLine
  ] = useState(null)

  const [showScoreQuality, setShowScoreQuality] = useState(false)

  const [companiesViewBy, setCompaniesViewBy] = useState('sector')

  const [scrollOnTop, setScrollOnTop] = useState(true)

  const [filterState, filterDispatch] = context.globalFilter

  const isFilterStateAvailable =
    filterState.filterData.regionsList.length > 0 ||
    filterState.filterData.sectorsList.length > 0

  const { esg } = entitlements.PortfolioAnalysis

  const handleCompaniesViewByClick = (newValue) => {
    setCompaniesViewBy(newValue)
    getViewCompaniesData(newValue)
  }

  const handleCountrySelected = (key) => {
    setCountryListSelectedKey(key)
    setEntityListSelectedKey('')
  }

  const handleEntitySelected = (key) => {
    if (key === '') {
      dashboardDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.DASHBOARD_MAP_ENTITY_LIST,
        data: []
      })
    }
    setEntityListSelectedKey(key)
  }

  const getViewCompaniesData = async (viewBy) => {
    const { portfolio, regions, sectors, asOfDate } =
      filterState.globalPortfolioFilter
    if (portfolio) {
      dashboardDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName: REDUCER_STATE.DASHBOARD_COMPANIES_TABLE
      })
      try {
        const data = isFilterStateAvailable
          ? await DashboardService.getCompaniesTableData({
              portfolio,
              regions,
              sectors,
              asOfDate,
              viewBy
            })
          : {}

        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          sectionName: REDUCER_STATE.DASHBOARD_COMPANIES_TABLE,
          data: data,
          apiCallId
        })
      } catch (error) {
        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.DASHBOARD_COMPANIES_TABLE,
          apiCallId
        })
      }
    }
  }

  const getHeatmapEntity = async (researchLinesSelected, itemSelected) => {
    if (filterState.globalPortfolioFilter?.portfolio) {
      let { regions } = filterState.globalPortfolioFilter
      const { portfolio, asOfDate, sectors } = filterState.globalPortfolioFilter
      regions = regions ? regions : PAGE_CON.ALL_FILTER
      dashboardDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName: REDUCER_STATE.DASHBOARD_HEATMAP_ENTITY
      })
      try {
        const data = await DashboardService.getHeatmapEntity({
          portfolio,
          regions,
          sectors,
          asOfDate,
          research_line_1: researchLinesSelected[0] && researchLinesSelected[0],
          research_line_2: researchLinesSelected[1] && researchLinesSelected[1],
          category_1: itemSelected[0] && itemSelected[0],
          category_2: itemSelected[1] && itemSelected[1]
        })

        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          sectionName: REDUCER_STATE.DASHBOARD_HEATMAP_ENTITY,
          data: data,
          apiCallId
        })
      } catch (error) {
        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.DASHBOARD_HEATMAP_ENTITY,
          apiCallId
        })
      }
    }
  }

  const handleHeatmapTableSelected = (selected) => {
    const keys = selected.filter((item) => item).map(({ key }) => key)
    if (selected.length !== 0) {
      getHeatmapEntity(selectedHeatmapResearchLines, keys)
    }
    setSelectedHeatmapTable(selected)
  }

  const handleCloseDrawer = () => {
    setSelectedHeatmapTable([])
  }

  const handleShowScoreQuality = () => {
    setShowScoreQuality(!showScoreQuality)
  }

  const getHeatmapTable = async (researchLines) => {
    if (filterState.globalPortfolioFilter?.portfolio) {
      let { regions } = filterState.globalPortfolioFilter
      const { portfolio, asOfDate, sectors } = filterState.globalPortfolioFilter
      regions = regions ? regions : PAGE_CON.ALL_FILTER
      dashboardDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName: REDUCER_STATE.DASHBOARD_HEATMAP_TABLE
      })
      try {
        const data = isFilterStateAvailable
          ? await DashboardService.getHeatmapTable({
              portfolio,
              regions,
              sectors,
              asOfDate,
              research_line_1: researchLines[0] && researchLines[0],
              research_line_2: researchLines[1] && researchLines[1]
            })
          : []

        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          sectionName: REDUCER_STATE.DASHBOARD_HEATMAP_TABLE,
          data: data,
          apiCallId
        })
      } catch (error) {
        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.DASHBOARD_HEATMAP_TABLE,
          apiCallId
        })
      }
    }
  }

  const handleHeatmapResearchLinesSelected = (key) => {
    if (
      !(
        selectedHeatmapResearchLines.length === 1 &&
        key.length === 1 &&
        selectedHeatmapResearchLines[0] === key[0]
      )
    ) {
      if (key.length !== 0) {
        getHeatmapTable(key)
      }
    }
    setSelectedHeatmapResearchLines(key)
  }

  const isTransitionRiskRLorPRM = (researchLineSelected) =>
    PAGE_CON.CATEGORY_RL_GEO_RISK.includes(researchLineSelected)

  useEffect(() => {
    if (entityListSelectedKey !== '') {
      getEntityList(entityListSelectedKey, selectedGeographicResearchLine)
    }
  }, [entityListSelectedKey])

  useEffect(() => {
    if (mapRef && mapRef.index >= 1 && mapRef.reflow) {
      mapRef.reflow()
    }
  }, [countryListSelectedKey, mapRef])

  const getPortfolioName = (portfolioKey) =>
    filterState?.filterData?.portfolioList.find((x) => x.key === portfolioKey)
      ?.displayText || ''

  const getTableData = async (limit = 10, table_type, research_line) => {
    const { portfolio, regions, sectors, asOfDate } =
      filterState.globalPortfolioFilter
    if (portfolio && limit && table_type) {
      dashboardDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName: REDUCER_STATE.DASHBOARD_TABLE
      })

      try {
        const data = isFilterStateAvailable
          ? await DashboardService.getTableData({
              portfolio,
              regions,
              sectors,
              asOfDate,
              limit,
              table_type,
              research_line: research_line || undefined
            })
          : []

        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          sectionName: REDUCER_STATE.DASHBOARD_TABLE,
          data: data,
          apiCallId
        })
      } catch (error) {
        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.DASHBOARD_TABLE,
          apiCallId
        })
      }
    }
  }

  const getResearchLines = async (isErrorReload = false) => {
    if (
      dashboardState.dashboardResearchLines.dataStatus !== '' &&
      isErrorReload === false
    )
      return

    const { portfolio, regions, sectors, asOfDate } =
      filterState.globalPortfolioFilter

    if (portfolio) {
      dashboardDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName: REDUCER_STATE.DASHBOARD_RESEARCH_LINES
      })
      try {
        const researchLinesWithEntitlements = [
          {
            key: 'temperaturealgmt',
            entitled:
              entitlements.PortfolioAnalysis.transitionriskChildren[
                'temperaturealignment'
              ]
          },
          {
            key: 'physicalriskhazard',
            entitled: entitlements.PortfolioAnalysis.physicalrisk
          },
          {
            key: 'carbonfootprint',
            entitled:
              entitlements.PortfolioAnalysis.transitionriskChildren[
                'carbonfootprint'
              ]
          },
          {
            key: 'physicalriskmgmt',
            entitled: entitlements.PortfolioAnalysis.physicalrisk
          },
          { key: 'esgasmt', entitled: entitlements.PortfolioAnalysis['esg'] }
        ]

        const entitledResearchLinesSummaryHeaders =
          researchLinesWithEntitlements
            .filter(({ entitled }) => entitled)
            .map(({ key }) => key)

        const data = { coverageData: {}, researchLinesData: [] }

        if (isFilterStateAvailable) {
          const [coverage, researchData] = await Promise.all([
            getPromise(DashboardService.getDashboardCoverage, {
              portfolio,
              regions,
              sectors,
              asOfDate
            }),
            getPromise(DashboardService.getResearchLines, {
              portfolio,
              regions,
              sectors,
              asOfDate,
              researchLines: entitledResearchLinesSummaryHeaders
            })
          ])

          data.coverageData = coverage
          data.researchLinesData = researchData
        } else {
          entitledResearchLinesSummaryHeaders.map((reaserchLine) => {
            data.researchLinesData.push({
              status: 'fulfilled',
              researchLine: reaserchLine,
              value: undefined
            })
          })
        }

        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          sectionName: REDUCER_STATE.DASHBOARD_RESEARCH_LINES,
          data,
          apiCallId
        })
      } catch (error) {
        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.DASHBOARD_RESEARCH_LINES,
          apiCallId
        })
      }
    }
  }

  const getMapData = async (
    research_line = 'operationsrisk',
    // eslint-disable-next-line no-unused-vars
    isErrorReload = false
  ) => {
    const { portfolio, regions, sectors, asOfDate } =
      filterState.globalPortfolioFilter
    research_line = research_line || 'operationsrisk'

    if (portfolio && research_line) {
      dashboardDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName: REDUCER_STATE.DASHBOARD_MAP
      })
      try {
        const data = { coverageData: {}, mapData: [] }

        if (isFilterStateAvailable) {
          const [researchCoverageData, researchMapData] = await Promise.all([
            getPromise(DashboardService.getResearchLinesCoverage, {
              portfolio,
              research_line,
              regions,
              sectors,
              asOfDate
            }),
            getPromise(DashboardService.getMapData, {
              portfolio,
              research_line,
              regions,
              sectors,
              asOfDate
            })
          ])
          data.coverageData = researchCoverageData
          data.mapData = researchMapData
        }

        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          sectionName: REDUCER_STATE.DASHBOARD_MAP,
          data,
          apiCallId
        })
      } catch (error) {
        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.DASHBOARD_MAP,
          apiCallId
        })
      }
    }
  }

  const getEntityList = async (
    country_code,
    research_line = 'operations_risk'
  ) => {
    const { portfolio, regions, sectors, asOfDate } =
      filterState.globalPortfolioFilter
    if (portfolio) {
      dashboardDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName: REDUCER_STATE.DASHBOARD_MAP_ENTITY_LIST
      })
      try {
        const newMapData = await ResearchLineService.getEntityList({
          portfolio,
          research_line,
          regions,
          sectors,
          asOfDate,
          country_code
        })

        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          sectionName: REDUCER_STATE.DASHBOARD_MAP_ENTITY_LIST,
          data: newMapData?.data ? newMapData.data : {}
        })
      } catch (error) {
        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.DASHBOARD_MAP_ENTITY_LIST
        })
      }
    }
  }

  const getDashboardData = useCallback(async () => {
    let filters = filterState.globalPortfolioFilter

    if (filters.portfolio !== '' && !filterState.isLoading) {
      getResearchLines()
      getMapData(selectedGeographicResearchLine)
      getTableData(
        selectedPerformanceTableRange,
        selectedPerformanceTableTable,
        selectedPerformanceTableResearchLine
      )
      getHeatmapTable(selectedHeatmapResearchLines)
      getViewCompaniesData(companiesViewBy)
    }
  }, [filterState.actionData])

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      getDashboardData()
      setCountryListSelectedKey('')
      setEntityListSelectedKey('')
      setSelectedHeatmapTable([])
    }
  }, [filterState.actionData])

  const mapCountryList = dashboardState.dashboardMap?.data
    ? dashboardState.dashboardMap.data?.mapData?.map(
        ({
          country_code,
          country_name,
          holdings,
          number_of_companies,
          score,
          score_range,
          score_category,
          total_controversies,
          controversies_critical_high
        }) => {
          if (selectedGeographicResearchLine === 'controversies') {
            return {
              title: country_name,
              subTitle: `${controversies_critical_high} critical or high/ ${total_controversies} controversies`,
              key: country_code,
              value: controversies_critical_high,
              label: controversies_critical_high ? 'High' : ''
            }
          } else {
            return {
              title: country_name,
              subTitle: `${numberOfCompanies(
                number_of_companies
              )}/ ${holdings}% investment`,
              key: country_code,
              value: score,
              label: isTransitionRiskRLorPRM(selectedGeographicResearchLine)
                ? score_category
                : score_range
            }
          }
        }
      )
    : []

  const onCloseUpload = () => {
    filterDispatch({
      type: REDUCER_TYPE.UPLOAD_PORTFOLIO_MODAL,
      payload: false,
      uploadType: filterState.uploadType,
      uploadInitiatedFrom: filterState.uploadInitiatedFrom
    })
  }

  const handleAboutClimateClick = () => {
    setOpenPanel(true)
  }

  const handleClosePanel = () => {
    setOpenPanel(false)
  }

  const handleViewCompaniesClick = () => {
    setopenViewCPanel(true)
  }

  const closeViewCompanies = () => {
    setopenViewCPanel(false)
  }

  const handleFiltersChange = ({
    range,
    table,
    research_line,
    onError = false
  }) => {
    if (
      selectedPerformanceTableRange !== range ||
      selectedPerformanceTableTable !== table ||
      selectedPerformanceTableResearchLine !== research_line ||
      onError
    ) {
      getTableData(range, table, research_line)
      setSelectedPerformanceTableRange(range)
      setSelectedPerformanceTableTable(table)
      setSelectedPerformanceTableResearchLine(research_line)
    }
  }

  const handleGeographicResearchLineSelected = (research_line) => {
    if (selectedGeographicResearchLine !== research_line) {
      getMapData(research_line)
      setSelectedGeographicResearchLine(research_line)
      setEntityListSelectedKey('')
      setCountryListSelectedKey('')
    }
  }

  const listenScrollEvent = (event) => {
    if (event.target.id === 'div-mainlayout') {
      setTimeout(() => {
        if (scrollOnTop) {
          let divContainer = document.querySelector('#div-mainlayout')
          if (
            divContainer.scrollHeight - divContainer.clientHeight >
            document.querySelector('#dashboard_PageHeader').clientHeight
          )
            setScrollOnTop(event.target.scrollTop === 0)
        } else setScrollOnTop(event.target.scrollTop === 0)
      }, 200)
    }
  }

  useEffect(() => {
    let divContainer = document.querySelector('#div-mainlayout')
    if (!scrollOnTop) {
      if (divContainer.scrollTop === 0) divContainer.scrollTo(0, 3)
    } else {
      if (divContainer.scrollTop !== 0) divContainer.scrollTo(0, 0)
    }
  }, [scrollOnTop])

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent, true)
    return () => {
      window.removeEventListener('scroll', listenScrollEvent, true)
    }
  })

  const isClimateRiskHasData = () => {
    let hasData = false
    if (
      dashboardState.dashboardResearchLines.data &&
      dashboardState.dashboardResearchLines.data.coverageData &&
      dashboardState.dashboardResearchLines.data.researchLinesData &&
      dashboardState.dashboardResearchLines.data.coverageData.portfolio_coverage
        ?.companies !== ''
    )
      hasData = true
    return hasData
  }

  const reloadComponent = (errorOccuredOn) => {
    switch (errorOccuredOn) {
      case PAGE_CON.DASHBOARD_MAP:
        getMapData(selectedGeographicResearchLine)
        break
      case PAGE_CON.DASHBOARD_RESEARCH_LINES:
        getResearchLines(true)
        break
      default:
        break
    }
  }

  const onPopoverErrorClose = () => {
    dashboardDispatch({
      type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
      sectionName: REDUCER_STATE.DOWNLOAD_DASHBOARD_CLIMATE_DATA,
      data: {},
      apiCallId
    })
    setOpen(false)
  }

  const contentInfo = [
    {
      buttonKey: 'sector',
      labelText: 'View By Sector',
      buttonDisabled: dashboardState.dashboardCompaniesTable.isLoading
    },
    {
      buttonKey: 'region',
      labelText: 'View By Region',
      buttonDisabled: dashboardState.dashboardCompaniesTable.isLoading
    }
  ]

  const popoverErrorOpen =
    dashboardState.downloadDashboardClimateData.dataStatus === 'error'

  const openModal = () => {
    setOpen(true)
  }

  const onEntityClick = (entityID) => {
    if (entityID && entityID != '')
      entityHeaderDispatch({
        type: REDUCER_TYPE.SET_ORBIB_ID,
        selectedOrbisId: entityID
      })
  }

  const openFile = (linkName) => {
    let pdf = ''

    switch (linkName) {
      case 'Physical Risk':
        pdf =
          process.env.PUBLIC_URL +
          '/assets/Methodology_Corporate Physical Climate Risk  Operations Risk (Jan 2022).pdf'
        break
      case 'Transition Risk':
        pdf =
          process.env.PUBLIC_URL +
          '/assets/Methodology_Climate_Climate Risk Assessment.pdf'
        break
      case 'Temperature Alignment':
        pdf =
          process.env.PUBLIC_URL +
          '/assets/Methodology_Climate_Temperature Alignment Data.pdf'
        break
      case 'Methodology_1':
        pdf =
          process.env.PUBLIC_URL + '/assets/Methodology 1.0 ESG Assessment.pdf'
        break
      case 'Methodology_2':
        pdf =
          process.env.PUBLIC_URL + '/assets/Methodology 2.0 ESG Assessment.pdf'
        break
      case 'Controversy':
        pdf =
          process.env.PUBLIC_URL +
          '/assets/Methodology Controversy Risk Assessment.pdf'
        break
      default:
        break
    }

    if (pdf !== '') {
      window.open(pdf, '_blank')
    }
  }

  const sectionEntitlements = {
    portfolioMonitoring: entitlements.Dashboard.controversy,
    geographicRisk:
      (entitlements.Dashboard.physicalrisk ||
        entitlements.Dashboard.transitionrisk) &&
      researchLinesNoHeaders.filter(({ geographicRisk }) => geographicRisk)
        .length > 0,
    heatMap:
      entitlements.Dashboard.physicalrisk ||
      entitlements.Dashboard.transitionrisk
  }

  const goToPortfolioAnalysis = (selectedResearchLine) => {
    const selectedResearchLineRoute = researchLines.find(
      ({ key }) => key === selectedResearchLine
    )?.route

    if (selectedResearchLineRoute) {
      let updatedSelectedResearchLineRoute = selectedResearchLineRoute
      document.querySelector('#div-mainlayout').scrollTo(0, 0)
      if (
        selectedResearchLineRoute === 'operationsrisk' ||
        selectedResearchLineRoute === 'supplychainrisk' ||
        selectedResearchLineRoute === 'marketrisk'
      ) {
        updatedSelectedResearchLineRoute = 'physicalriskhazards'
      }
      history.push(
        `${PAGE_CON.PORTFOLIO_OVERVIEW}/${updatedSelectedResearchLineRoute}`
      )
      portfolioReviewDispatch({
        type: REDUCER_TYPE.PORTFOLIO_REVIEW_PAGE,
        page: updatedSelectedResearchLineRoute
      })
    }
  }

  return (
    <>
      <PrimaryPopover
        id="export-popover-error-test-id"
        alertError={true}
        alertHeight="20%"
        alertText={'Export failed because of an issue, please try again later.'}
        alertOpen={popoverErrorOpen}
        onAlertClose={onPopoverErrorClose}
        alertPosition="center"
        alertTop="55px"
        marginLeft="16px"
      />
      <Page title="Moody's Analytics">
        <Panel
          key={'panelcard-test-id-1'}
          openPanel={openPanel}
          titlePadding="12px"
          onPanelClose={handleClosePanel}
          topNum={0}
          title={'Methodologies'}
          panelContentComponent={
            <SummarySidePanel
              entitlements={entitlements}
              onLinkClick={openFile}
            />
          }
          height={'0'}
        />
        <Panel
          key={'panelcard-test-id-2'}
          openPanel={openViewCPanel}
          titlePadding="12px"
          onPanelClose={closeViewCompanies}
          topNum={0}
          rightNum={0}
          whiteBg={true}
          backDropInvisible={false}
          contentInfo={contentInfo}
          companiesViewByValue={companiesViewBy}
          handleCompaniesViewByClick={handleCompaniesViewByClick}
          closeSymbol={true}
          panelHeaderComponent={
            <CompaniesAndInvestmentsHeader
              portfolioName={getPortfolioName(
                filterState.globalPortfolioFilter.portfolio
              )}
              contentInfo={contentInfo}
              handleCompaniesViewByClick={handleCompaniesViewByClick}
              companiesViewByValue={companiesViewBy}
            />
          }
          panelContentComponent={
            <CompaniesAndInvestmentsTable
              entitlements={entitlements}
              openModal={openModal}
              portfolioName={getPortfolioName(
                filterState.globalPortfolioFilter.portfolio
              )}
              companiesViewByValue={companiesViewBy}
              scoreQuality={showScoreQuality}
            />
          }
          height={'0'}
        />
        <Upload
          modalOpen={filterState.isUploadOpen}
          uploadType={filterState.uploadType}
          onClose={onCloseUpload}
        >
          {' '}
        </Upload>
        <BackdropComp />
        <PageHeader
          id={'dashboard_PageHeader'}
          minify={!scrollOnTop}
          pageHeaderWidth={!scrollOnTop ? '100vw' : undefined}
          pageHeaderMarginBottom={scrollOnTop ? '20px' : '0px'}
          pageHeaderHeight="auto"
          pageHeaderContentComponent={
            <S.HeaderContainer minify={!scrollOnTop}>
              {scrollOnTop && (
                <S.FiltersContainer>
                  <PortfolioComp />
                  <RegionSectorDateComp />
                  {esg && (
                    <ScoreQuality
                      handleShowScoreQuality={handleShowScoreQuality}
                      scoreQuality={showScoreQuality}
                    />
                  )}
                </S.FiltersContainer>
              )}
              {dashboardState.dashboardResearchLines.isLoading ? (
                <LoadingMask />
              ) : dashboardState.dashboardResearchLines.dataStatus ===
                  PAGE_CON.ERROR ||
                (dashboardState.dashboardResearchLines.dataStatus ===
                  PAGE_CON.SUCCESS &&
                  !isClimateRiskHasData()) ? (
                <Error
                  id={'summaryHeadersError'}
                  dataStatus={dashboardState.dashboardResearchLines.dataStatus}
                  onErrorClick={reloadComponent}
                  errorOccuredOn={PAGE_CON.DASHBOARD_RESEARCH_LINES}
                />
              ) : (
                <SummaryHeaders
                  entitlements={entitlements}
                  isLoading={dashboardState.dashboardResearchLines.isLoading}
                  data={
                    dashboardState.dashboardResearchLines.data.researchLinesData
                  }
                  handleViewCompaniesClick={handleViewCompaniesClick}
                  coverage={
                    dashboardState.dashboardResearchLines.data.coverageData
                  }
                  minify={!scrollOnTop}
                  portfolioName={getPortfolioName(
                    filterState.globalPortfolioFilter.portfolio
                  )}
                  handleAboutClimateClick={handleAboutClimateClick}
                  reloadComponent={reloadComponent}
                />
              )}
            </S.HeaderContainer>
          }
          scrollWithPage={true}
        />
        {sectionEntitlements.portfolioMonitoring && (
          <Section box>
            <PortfolioMonitoring
              entitlements={entitlements}
              id="portfoliomonitoring-test-id"
            />
          </Section>
        )}
        {FEATURE_FLAGS.ESG_MAP_SECTOR && sectionEntitlements.geographicRisk && (
          <Section
            id="geographic-risk-section-test-id"
            box
            sidebar={
              dashboardState.dashboardMap.isLoading ||
              dashboardState.dashboardMap.dataStatus === PAGE_CON.ERROR ||
              (dashboardState.dashboardMap.dataStatus === PAGE_CON.SUCCESS &&
                dashboardState.dashboardMap.data.mapData.length ===
                  0) ? undefined : (
                <S.CountryListContainer id="countrylistcontainer-test-id">
                  {selectedGeographicResearchLine !== 'controversies' && (
                    <S.PortfolioAnalysisLink>
                      <PrimaryLink
                        id="link-link-test-id-1"
                        linkText={`Go to Portfolio Analysis for ${
                          researchLinesNoHeadersGeographicRisk.find(
                            ({ key }) => key === selectedGeographicResearchLine
                          ).title
                        }`}
                        onClick={() =>
                          goToPortfolioAnalysis(selectedGeographicResearchLine)
                        }
                        linkClass="linkInline"
                        linkColorChange={true}
                      />
                    </S.PortfolioAnalysisLink>
                  )}
                  <CountryList
                    items={mapCountryList || []}
                    isLoading={dashboardState.dashboardMap.isLoading}
                    action={handleEntitySelected}
                    selectedFromMap={countryListSelectedKey}
                    countrySelected={entityListSelectedKey}
                    entityList={dashboardState.dashboardMapEntityList}
                    researchLineSelected={selectedGeographicResearchLine}
                    entitlements={entitlements}
                    handleEntityClick={onEntityClick}
                  />
                </S.CountryListContainer>
              )
            }
          >
            <GeographicRisk
              id="geographicrisk-test-id"
              dashboardMap={dashboardState.dashboardMap}
              countryListSelectedKey={countryListSelectedKey}
              handleCountrySelected={handleCountrySelected}
              setMapRef={setMapRef}
              portfolioName={getPortfolioName(
                filterState.globalPortfolioFilter.portfolio
              )}
              entitlements={entitlements}
              handleResearchLineSelected={handleGeographicResearchLineSelected}
              researchLineSelected={selectedGeographicResearchLine}
              onErrorReload={() => reloadComponent(PAGE_CON.DASHBOARD_MAP)}
            />
          </Section>
        )}
        {sectionEntitlements.heatMap && (
          <Section
            box
            sidebarHeight="100%"
            id="heatmapentity-test-id"
            onClick={handleCloseDrawer}
            sidebar={
              <HeatmapEntity
                entitlements={entitlements}
                itemSelected={selectedHeatmapTable}
                researchLinesSelected={selectedHeatmapResearchLines}
                handleHeatmapTableSelected={handleHeatmapTableSelected}
                scoreQuality={showScoreQuality}
              />
            }
            header={
              <HeatmapHeader
                entitlements={entitlements}
                handleHeatmapResearchLinesSelected={
                  handleHeatmapResearchLinesSelected
                }
                researchLinesSelected={selectedHeatmapResearchLines}
              />
            }
          >
            <Heatmap
              entitlements={entitlements}
              selectedHeatmapTable={selectedHeatmapTable}
              handleHeatmapTableSelected={handleHeatmapTableSelected}
              researchLinesSelected={selectedHeatmapResearchLines}
            />
          </Section>
        )}
        <Section box>
          <PerformanceTable
            id="performancetable-test-id"
            entitlements={entitlements}
            dashboardTable={dashboardState.dashboardTable}
            handleFiltersChange={handleFiltersChange}
            rangeSelected={selectedPerformanceTableRange}
            tableSelected={selectedPerformanceTableTable}
            researchLineSelected={selectedPerformanceTableResearchLine}
            scoreQuality={showScoreQuality}
          />
        </Section>
      </Page>
      <S.LoaderBackdrop
        visible={dashboardState.downloadDashboardClimateData.isLoading}
      >
        <Backdrop open={dashboardState.downloadDashboardClimateData.isLoading}>
          <LoadingMask loadText="Exporting" />
        </Backdrop>
      </S.LoaderBackdrop>
    </>
  )
}

export default DashboardView
