import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'

const useStyles = (props) =>
  makeStyles((theme) => ({
    container: {
      paddingLeft: '10px',
      '& li.selectedCountry': {
        padding: '0px 25px 0 5px',
        listStyle: 'none'
      },
      '& ul.countryList': {
        height: '582px',
        overflowY: 'auto',
        padding: '0px 5px'
      },
      '& ul.entityList': {
        height: '360px',
        overflowY: 'auto',
        padding: '0px 5px'
      }
    },
    entityContainer: {
      '& p.companiesTitle': {
        ...theme.typography.p2,
        padding: '0px 9px',
        marginBottom: '4px',
        color: theme.palette.sys_trad.text2.main
      },
      '& span.backButton': {
        ...theme.typography.p2,
        color: theme.palette.sys_trad.main.main,
        textDecoration: 'underline',
        cursor: 'pointer',
        margin: '8px 0px 30px 0px',
        display: 'inline-block',
        padding: '0px 25px 0 5px'
      }
    },

    listItem: {
      backgroundColor: (props) => {
        return props.color
      },
      ...theme.typography.p5,
      display: 'flex',
      height: '48px',
      background: ' #FFFFFF',
      boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
      borderRadius: '2px',
      marginBottom: '4px',
      padding: '4px',
      justifyContent: 'space-between',
      flex: 1,
      '& div:first-child': {
        flex: 1,
        overflow: 'hidden',
        '& h3': {
          margin: 0,
          lineHeight: '16px',
          color: theme.palette.sys_trad.text.main,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        },
        padding: '4px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '& h4': {
          margin: 0,
          lineHeight: '16px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...theme.typography.p4
        }
      },
      '& div.label': {
        ...theme.typography.p2,
        width: '72px',
        minWidth: '72px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: '#ffffff',
        backgroundColor: props?.color,
        borderRadius: '2px',
        '& span:last-child': {
          ...theme.typography.bold,
          textAlign: 'center'
        }
      },
      '&.selected': {
        border: `1px solid ${theme.palette.sys_trad.hover.main}`
      },
      '&.clickable': {
        cursor: 'pointer'
      }
    },
    companyNameStyle: {
      '&.entity': {
        textDecoration: 'underline',
        cursor: 'pointer'
      },
      '&.CompanyNameWrapper': {
        display: 'flex'
      },
      '&.CompanyName': {
        maxWidth: '50%',
        minWidth: '4em'
      },
      '&.ellipsis': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        float: 'left',
        paddingRight: '5px'
      },
      '&.clear': {
        clear: 'both'
      }
    }
  }))

export const Container = ({ children }) => {
  const classes = useStyles()(theme)
  return <div className={classes.container}>{children}</div>
}

export const EntityContainer = ({ children }) => {
  const classes = useStyles()(theme)
  return <div className={classes.entityContainer}>{children}</div>
}

export const CompanyNameItem = ({ onClick, title, children, className }) => {
  const classes = useStyles()(theme)
  return (
    <span
      title={title}
      onClick={onClick}
      aria-hidden={true}
      className={`${classes.companyNameStyle} ${className}`}
      // eslint-disable-next-line react/no-unknown-property
      heap_id="grd"
      // eslint-disable-next-line react/no-unknown-property
      heap_entity={children}
    >
      {children}
    </span>
  )
}

export const ListItem = ({
  children,
  value,
  onClick,
  selected,
  clickable = false,
  mapRange,
  bgColor,
  isEntity
}) => {
  const { color } = mapRange.find(
    ({ from, to }) => value >= from && value <= to
  ) || { color: '#FFFFFF' }

  const heap_grd_id = () => {
    const childrenProp = children[0]?.props?.children[0]?.props?.children
    if (typeof childrenProp === 'string') {
      return childrenProp
    } else {
      return childrenProp?.props?.children || 'undefined'
    }
  }

  const classes = useStyles({ color: bgColor || color })(theme)
  return (
    <div
      className={`${classes.listItem} ${selected ? 'selected' : ''} ${
        clickable ? 'clickable' : ''
      }`}
      aria-hidden={true}
      onClick={onClick}
      {...(isEntity ? {} : { heap_id: 'grd', heap_grd_id: heap_grd_id() })}
    >
      {children}
    </div>
  )
}
