import { AppBar } from '@material-ui/core'
import axios from 'axios'
import CarbonFootPrintColumns from './CarbonFootPrintColumns.json'
import CarbonFootprintUnderlyingDataMetrics from './CarbonFootprintUnderlyingDataMetrics'
import CarbonFootPrintOverview from './CarbonFootPrintOverview'
import { DropdownField, IconArrowDown, Page } from 'components'
import Emissions from './Emissions'
import getMonthYear from 'utils/Date'
import Laggards from '../../Laggards'
import Leaders from '../../Leaders'
import LoadingMask from 'utils/loadingMask'
import PortfolioAnalysisColumns from '../../PortfolioAnalysisColumns.json'
import React, { useCallback, useContext, useEffect } from 'react'
import Regions from '../../Regions'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'
import Updates from '../../Updates'
import { CAR_FOOT_PRNT, COMMON, PARAMS } from 'constant/APIconstants'
import { ResearchLineService } from 'services'
import { Container, Grid, makeStyles } from '@material-ui/core'
import { GlobalContext } from 'context/GlobalStateProvider'
import { CFPData } from './CarFootPrntData.json'
import {
  PAGE_CON,
  REDUCER_STATE,
  REDUCER_TYPE,
  filterOptions
} from 'constant/constants'
import Error from 'utils/error'
import numberData from 'utils/numberFormat'
import Impact from '../../Impact'
import getColor from 'utils/color'

const CarbonEmissionColInfo =
  CarbonFootPrintColumns.carbonfootprint_Carbon_Emission_Columns
const cardInfoContentInfo =
  CarbonFootPrintColumns.card_Info_Section_Columns_carbonFootprint
const cardInfoContentInfoSector =
  CarbonFootPrintColumns.card_Info_Section_Columns_carbonFootprint_Sector
const drawerWidth = PAGE_CON.PAGE_LEFT_MARGIN
const emissionObj = CFPData.emission

const LeaderLaggardColInfo =
  CarbonFootPrintColumns.leaders_Laggards_Panel_Columns
const mapDataClasses = [
  {
    color: themeColorPalette.palette.carbfoot.moderate.main,
    from: 0,
    to: 99999
  },
  {
    color: themeColorPalette.palette.carbfoot.significant.main,
    from: 100000,
    to: 999999
  },
  {
    color: themeColorPalette.palette.carbfoot.high.main,
    from: 1000000,
    to: 9999999
  },
  {
    color: themeColorPalette.palette.carbfoot.intense.main,
    from: 10000000,
    to: 999999999999999
  }
]

const mimTabColInfo = JSON.parse(
  JSON.stringify(PortfolioAnalysisColumns.region_Sector_Minimal_Table_Columns)
)
const panelTabColInfo = CarbonFootPrintColumns.region_Sector_Panel_Columns
const UpdatesTableColInfo = PortfolioAnalysisColumns.updates_Columns
const PanelUpdatesTableColInfo = PortfolioAnalysisColumns.updates_Panel_Columns
const impactColumns = JSON.parse(
  JSON.stringify(CarbonFootPrintColumns.impactColumns)
)

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  content: {
    flexGrow: 1
  },
  container: {
    marginLeft: drawerWidth - 20,
    paddingRight: 0,
    paddingLeft: '20px',
    marginTop: '22px'
  },
  tableTitle: {
    paddingBottom: '5px',
    ...textTheme.typography.h1
  },
  emissionTableTitle: {
    paddingBottom: '5px',
    paddingTop: '40px',
    ...textTheme.typography.h1
  },
  stickyHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    zIndex: 1,
    boxShadow: 'none'
  },
  overviewstickyHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    zIndex: 1,
    boxShadow: 'none',
    marginLeft: '1px'
  },
  containerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 16px 16px',
    marginTop: '12px'
  },
  impactContainerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 0px 16px',
    marginTop: '24px',
    marginBottom: '12px',
    paddingLeft: '16px'
  },
  impactDropdown: {
    marginTop: '16px',
    marginBottom: '12px'
  }
}))

const tableLength = 10

const linkPaddingOVerview = {
  marginTop: '16px',
  marginLeft: '-4px'
}

const linkPaddingOverviewEmission = {
  marginTop: '24px',
  marginLeft: '-4px'
}

const linkPadding = {
  marginTop: '8px',
  marginLeft: '-4px'
}

const linkPaddingRegionIndustry = {
  marginTop: '8px',
  marginLeft: '-4px'
}

const linkStyle = {
  ...textTheme.typography.p1,
  color: themeColorPalette.palette.sys_trad.link.main,
  lineHeight: '16px',
  verticalAlign: 'top'
}

const regionStyle = {
  paddingBottom: '10px',
  marginTop: '18px'
}

const regionTitleStyle = {
  top: '16px'
}

const updatesStyle = {
  paddingLeft: '2px',
  marginTop: '16px'
}

const UpdatesTableStyle = {
  marginTop: '12px',
  background: themeColorPalette.palette.sys_trad.white.main,
  boxShadow: '0px 0px 16px rgba(38, 65, 94, 0.12)',
  boxRadius: '2px',
  boxSizing: 'border-box',
  padding: '12px',
  paddingBottom: '15px',
  paddingTop: '0px'
}

const cardHeader = {
  ...textTheme.typography.p2,
  paddingBottom: '4px',
  paddingTop: '16px',
  lineHeight: '16px'
}

const dataMetricbox = {
  marginTop: '12px',
  paddingTop: '10px',
  background: themeColorPalette.palette.sys_trad.white.main,
  boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.12)',
  boxRadius: '2px',
  boxSizing: 'border-box',
  padding: '17px'
}

const dataMetricTitle = {
  marginTop: '16px'
}

const CarbonFootPrintManagement = (props) => {
  const classes = useStyles()

  const [selectedCardId, setSelectedCardId] = React.useState(null)
  const [filterState] = useContext(GlobalContext).globalFilter
  const [carbonFootPrintState, carbonFootPrintDispatch] =
    useContext(GlobalContext).globalCarbonFootPrint
  const [userSettingState] = useContext(GlobalContext).userSettingService

  const isFilterStateAvailable =
    filterState.filterData.regionsList.length > 0 ||
    filterState.filterData.sectorsList.length > 0

  let apiCallId = carbonFootPrintState.apiCallId
  let showBenchMark =
    filterState.globalPortfolioFilter.benchmark !== '' ? true : false
  let benchmarkPctCol = mimTabColInfo.filter(
    (col) => col.HeaderLabel === PAGE_CON.BENCHMARK_PERC
  )
  benchmarkPctCol[0].HideColumn = !showBenchMark
  let monthYear = getMonthYear(filterState)

  let weightedAverageCalculations =
    userSettingState?.userSetting?.data?.weighted_average_calculations
      ?.carbonfootprint || 'investment_value'
  let emmissionColumn = CarbonEmissionColInfo[weightedAverageCalculations]

  const [updateSectionText, setUpdateSectionText] = React.useState(
    `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}, ${PAGE_CON.IMPACT_TITLE}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`
  )

  const onImpactFilterChange = (event) => {
    carbonFootPrintDispatch({
      type: REDUCER_TYPE.UPDATE_IMPACT_FILTER,
      data: event.target.value
    })
  }

  const isRegionHasData = () => {
    let hasData = false
    if (
      carbonFootPrintState.portfolioRegions.data.summary &&
      carbonFootPrintState.portfolioRegions.data.summary.length > 0 &&
      carbonFootPrintState.portfolioRegions.data.details &&
      carbonFootPrintState.portfolioRegions.data.details.length > 0 &&
      carbonFootPrintState.portfolioRegions.data.mapData &&
      carbonFootPrintState.portfolioRegions.data.mapData.length > 0
    )
      hasData = true
    return hasData
  }

  const getUpdatesData = async (sectors, regions, isErrorReload = false) => {
    if (
      (carbonFootPrintState.portfolioUpdates.data &&
        carbonFootPrintState.portfolioUpdates.data.length > 0) ||
      (carbonFootPrintState.portfolioUpdates.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    carbonFootPrintDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_UPDATES
    })
    let updatesData = []
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter
    try {
      const { data } = isFilterStateAvailable
        ? await ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: CAR_FOOT_PRNT.PAGE_URL,
            token: carbonFootPrintState.cancelToken.token,
            endPoint: COMMON.UPDATES
          })
        : []

      updatesData = data
      carbonFootPrintDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_UPDATES,
        data: updatesData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        carbonFootPrintDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_UPDATES,
          apiCallId
        })
      }
    }
  }

  const getImpactCall = (filter, regions, sectors, month, year) => {
    return isFilterStateAvailable
      ? axios.post(
          COMMON.BASE_URL +
            filterState.globalPortfolioFilter.portfolio +
            CAR_FOOT_PRNT.PAGE_URL +
            COMMON.IMPACT,
          {
            [PARAMS.IMPACT_FILTER]: filter,
            [PARAMS.REGION]: regions,
            [PARAMS.SECTOR]: sectors,
            [PARAMS.MONTH]: month,
            [PARAMS.YEAR]: year
          },
          { cancelToken: carbonFootPrintState.cancelToken.token }
        )
      : {}
  }

  const getImpactData = async (sectors, regions, isErrorReload = false) => {
    if (
      (carbonFootPrintState.portfolioImpact.data &&
        carbonFootPrintState.portfolioImpact.data.length > 0) ||
      (carbonFootPrintState.portfolioImpact.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    carbonFootPrintDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_IMPACT
    })
    let impactData = {}
    try {
      const month = filterState.globalPortfolioFilter.asOfDate.slice(4)
      const year = filterState.globalPortfolioFilter.asOfDate.slice(0, 4)

      const [top5, top10, bottom5, bottom10] = await axios.all([
        getImpactCall(PAGE_CON.IMPACT_KEY_TOP_5, regions, sectors, month, year),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_TOP_10,
          regions,
          sectors,
          month,
          year
        ),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_BOTTOM_5,
          regions,
          sectors,
          month,
          year
        ),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_BOTTOM_10,
          regions,
          sectors,
          month,
          year
        )
      ])

      impactData[PAGE_CON.IMPACT_KEY_TOP_5] = top5.data[0]
      impactData[PAGE_CON.IMPACT_KEY_TOP_10] = top10.data[0]
      impactData[PAGE_CON.IMPACT_KEY_BOTTOM_5] = bottom5.data[0]
      impactData[PAGE_CON.IMPACT_KEY_BOTTOM_10] = bottom10.data[0]

      carbonFootPrintDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_IMPACT,
        data: impactData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        carbonFootPrintDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_IMPACT,
          apiCallId
        })
      }
    }
  }

  const getEmisionData = async (sectors, regions, isErrorReload = false) => {
    if (
      (carbonFootPrintState.portfolioEmissions.data &&
        carbonFootPrintState.portfolioEmissions.data.length > 0) ||
      (carbonFootPrintState.portfolioEmissions.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    carbonFootPrintDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_EMISSIONS
    })
    carbonFootPrintDispatch({
      type: REDUCER_TYPE.SET_EMISSION_COLUMN,
      weightedAvgCalc: weightedAverageCalculations
    })

    let emissionsData = []
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter
    try {
      const { data } = isFilterStateAvailable
        ? await ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: CAR_FOOT_PRNT.PAGE_URL,
            token: carbonFootPrintState.cancelToken.token,
            endPoint: COMMON.EMISSIONS
          })
        : []

      emissionsData = data

      carbonFootPrintDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_EMISSIONS,
        data: emissionsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        carbonFootPrintDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_EMISSIONS,
          apiCallId
        })
      }
    }
  }

  const getLeadersLaggardsData = async (
    sectors,
    regions,
    isErrorReload = false
  ) => {
    if (
      (carbonFootPrintState.portfolioLeaderLaggard.data.leaders &&
        carbonFootPrintState.portfolioLeaderLaggard.data.laggards &&
        carbonFootPrintState.portfolioLeaderLaggard.data.leaders.length > 0 &&
        carbonFootPrintState.portfolioLeaderLaggard.data.laggards.length > 0) ||
      (carbonFootPrintState.portfolioRegions.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    carbonFootPrintDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS
    })
    let leadersLaggardsData = { leaders: [], laggards: [] }
    try {
      if (isFilterStateAvailable) {
        const { data } = await axios.post(
          COMMON.BASE_URL +
            filterState.globalPortfolioFilter.portfolio +
            CAR_FOOT_PRNT.PAGE_URL +
            COMMON.LEADERS_LAGGARDS.trim(),
          {
            [PARAMS.REGION]: regions,
            [PARAMS.SECTOR]: sectors,
            [PARAMS.MONTH]: filterState.globalPortfolioFilter.asOfDate.slice(4),
            [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(
              0,
              4
            )
          },
          { cancelToken: carbonFootPrintState.cancelToken.token }
        )

        leadersLaggardsData.leaders = data.leaders
        leadersLaggardsData.laggards = data.laggards
      }

      carbonFootPrintDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS,
        data: leadersLaggardsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        carbonFootPrintDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS,
          apiCallId
        })
      }
    }
  }
  const getRegionsData = async (sectors, regions, isErrorReload = false) => {
    if (
      (carbonFootPrintState.portfolioRegions.data.summary.length > 0 &&
        carbonFootPrintState.portfolioRegions.data.details.length > 0 &&
        carbonFootPrintState.portfolioRegions.data.mapData.length > 0 &&
        carbonFootPrintState.portfolioSectors.data.summary.length > 0 &&
        carbonFootPrintState.portfolioSectors.data.details.length > 0) ||
      (carbonFootPrintState.portfolioRegions.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    carbonFootPrintDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_REGIONS
    })
    let regionsData = {
      summary: [],
      details: [],
      mapData: [],
      sectorsummary: [],
      sectordetails: []
    }
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter

    try {
      if (isFilterStateAvailable) {
        const [summary, details, mapData, sectorsummary, sectordetails] =
          await axios.all([
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: CAR_FOOT_PRNT.PAGE_URL,
              token: carbonFootPrintState.cancelToken.token,
              endPoint: COMMON.REGION_SUMMARY
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: CAR_FOOT_PRNT.PAGE_URL,
              token: carbonFootPrintState.cancelToken.token,
              endPoint: COMMON.REGION_DETAILS
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: CAR_FOOT_PRNT.PAGE_URL,
              token: carbonFootPrintState.cancelToken.token,
              endPoint: COMMON.MAP
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: CAR_FOOT_PRNT.PAGE_URL,
              token: carbonFootPrintState.cancelToken.token,
              endPoint: COMMON.SECTOR_SUMMARY
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: CAR_FOOT_PRNT.PAGE_URL,
              token: carbonFootPrintState.cancelToken.token,
              endPoint: COMMON.SECTOR_DETAILS
            })
          ])
        regionsData.summary = summary.data
        regionsData.details = details.data
        regionsData.sectorsummary = sectorsummary.data
        regionsData.sectordetails = sectordetails.data
        mapData?.data
          .filter((data) => !isNaN(data.score) && data.score >= 0)
          .map((mdata) => {
            return regionsData.mapData.push([
              mdata.country_code,
              mdata.score,
              mdata.score_category
            ])
          })
      }

      carbonFootPrintDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_REGIONS,
        data: regionsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        carbonFootPrintDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_REGIONS,
          apiCallId
        })
      }
    }
  }

  const getUnderlyingDataMetrics = async (
    sectors,
    regions,
    isErrorReload = false
  ) => {
    if (
      (carbonFootPrintState.portfolioUnderlyingDataMetrics.data &&
        Object.keys(carbonFootPrintState.portfolioUnderlyingDataMetrics.data)
          .length > 0) ||
      (carbonFootPrintState.portfolioUnderlyingDataMetrics.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    carbonFootPrintDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS
    })
    let metricsData = []
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter

    try {
      if (isFilterStateAvailable) {
        const { data } = await ResearchLineService.retrieveResearchLineApi({
          portfolio,
          regions,
          sectors,
          asOfDate,
          benchmark,
          checkForEntitlements: false,
          isEntitled: false,
          researchLine: CAR_FOOT_PRNT.PAGE_URL,
          token: carbonFootPrintState.cancelToken.token,
          endPoint: COMMON.DATA_METRICS
        })

        metricsData = data
      }

      carbonFootPrintDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS,
        data: metricsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        carbonFootPrintDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS,
          apiCallId
        })
      }
    }
  }

  const reloadComponent = (errorOccuredOn) => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    switch (errorOccuredOn) {
      case PAGE_CON.EMISSION_TITLE:
        getEmisionData(sectors, regions, true)
        break
      case PAGE_CON.LEADERS_TITLE:
      case PAGE_CON.LAGGARDS_TITLE:
        getLeadersLaggardsData(sectors, regions, true)
        break
      case PAGE_CON.REGIONS_TITLE:
        getRegionsData(sectors, regions, true)
        break
      case PAGE_CON.UPDATESIN_TITLE:
        getUpdatesData(sectors, regions, true)
        break
      case PAGE_CON.SCOPE_TITLE:
        getUnderlyingDataMetrics(sectors, regions, true)
        break
      case PAGE_CON.IMPACT_TITLE:
        getImpactData(sectors, regions, true)
        break
      default:
        break
    }
  }

  const getCarbonFootPrintData = useCallback(async () => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    if (filters.portfolio !== '' && !filterState.isLoading) {
      getEmisionData(sectors, regions)
      getUpdatesData(sectors, regions)
      getLeadersLaggardsData(sectors, regions)
      getRegionsData(sectors, regions)
      getImpactData(sectors, regions)
      getUnderlyingDataMetrics(sectors, regions)
    }
  }, [filterState.actionData])

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      getCarbonFootPrintData()
    }
  }, [filterState.actionData])

  useEffect(() => {
    let updateText = `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}, ${PAGE_CON.IMPACT_TITLE}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`

    if (
      !carbonFootPrintState.portfolioImpact.isLoading &&
      !carbonFootPrintState.portfolioLeaderLaggard.isLoading
    ) {
      if (canShowImpact())
        updateText = `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}, and ${PAGE_CON.IMPACT_TITLE}`
      else updateText = `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}`

      if (canShowLeadersLaggards()) {
        if (canShowImpact())
          updateText = `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}, ${PAGE_CON.IMPACT_TITLE}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`
        else
          updateText = `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`
      }
    }

    setUpdateSectionText(updateText)
  }, [
    carbonFootPrintState.portfolioLeaderLaggard.dataStatus,
    carbonFootPrintState.portfolioImpact.dataStatus
  ])

  function selectedCard(selectCardInfo) {
    setSelectedCardId(selectCardInfo.cardId)
  }

  const carbonFootprintMapData =
    carbonFootPrintState.portfolioRegions.data &&
    carbonFootPrintState.portfolioRegions.data.mapData &&
    carbonFootPrintState.portfolioRegions.data.mapData.map((item) => {
      let data = numberData(item[1], { isDecimal: false })
      return {
        'hc-key': item[0] || '-',
        value: item[1] !== undefined ? item[1] : '-',
        carbonFootPrintScore: item[1] !== undefined ? data : '-',
        category: item[2] || '-'
      }
    })

  const getPositiveBarData = () => {
    const positiveData = [
      {
        name: 'total',
        data: [],
        opacity: 0.6
      },
      {
        name: 'selection',
        data: [],
        opacity: 1
      }
    ]
    carbonFootPrintState.portfolioImpact.data[
      carbonFootPrintState.portfolioImpact.filter
    ]?.positive_impact?.distribution?.map((item) => {
      positiveData[0].data.push(item.total - item.selection)
      positiveData[1].data.push(item.selection)
      return ''
    })

    return positiveData
  }

  const getPositiveBarColor = () => {
    const positiveColor = []

    carbonFootPrintState.portfolioImpact.data[
      carbonFootPrintState.portfolioImpact.filter
    ]?.positive_impact?.distribution?.map((item) => {
      positiveColor.push(getColor('CATEGORY', item.category))
      return ''
    })

    return positiveColor
  }

  const getNegativeBarData = () => {
    const negativeData = [
      {
        name: 'total',
        data: [],
        opacity: 0.6
      },
      {
        name: 'selection',
        data: [],
        opacity: 1
      }
    ]
    carbonFootPrintState.portfolioImpact.data[
      carbonFootPrintState.portfolioImpact.filter
    ]?.negative_impact?.distribution?.map((item) => {
      negativeData[0].data.push(item.total - item.selection)
      negativeData[1].data.push(item.selection)
      return ''
    })

    return negativeData
  }

  const getNegativeBarColor = () => {
    const negativeColor = []

    carbonFootPrintState.portfolioImpact.data[
      carbonFootPrintState.portfolioImpact.filter
    ]?.negative_impact?.distribution?.map((item) => {
      negativeColor.push(getColor('CATEGORY', item.category))
      return ''
    })

    return negativeColor
  }

  const canShowEmissions = () => {
    let showEmissions = false
    if (
      carbonFootPrintState.portfolioEmissions.isLoading ||
      carbonFootPrintState.portfolioEmissions.dataStatus === PAGE_CON.ERROR ||
      (carbonFootPrintState.portfolioEmissions.dataStatus ===
        PAGE_CON.SUCCESS &&
        carbonFootPrintState.portfolioEmissions.data?.length > 0)
    )
      showEmissions = true
    return showEmissions
  }

  const canShowImpact = () => {
    let showImpact = false
    if (
      carbonFootPrintState.portfolioImpact.dataStatus === PAGE_CON.ERROR ||
      (carbonFootPrintState.portfolioImpact.dataStatus === PAGE_CON.SUCCESS &&
        filterOptions.some(
          (item) =>
            carbonFootPrintState.portfolioImpact.data[item.key]?.positive_impact
              ?.investment_and_score?.length > 0 ||
            carbonFootPrintState.portfolioImpact.data[item.key]?.negative_impact
              ?.investment_and_score?.length > 0
        ))
    ) {
      showImpact = true
    }

    return showImpact
  }

  const canShowLeadersLaggards = () => {
    let showLeadersLaggardsComp = false
    if (
      carbonFootPrintState.portfolioLeaderLaggard.dataStatus ===
        PAGE_CON.ERROR ||
      (carbonFootPrintState.portfolioLeaderLaggard.dataStatus ===
        PAGE_CON.SUCCESS &&
        (carbonFootPrintState.portfolioLeaderLaggard.data?.leaders?.length >
          0 ||
          carbonFootPrintState.portfolioLeaderLaggard.data?.laggards?.length >
            0))
    ) {
      showLeadersLaggardsComp = true
    }

    return showLeadersLaggardsComp
  }

  const canShowRegionComp = () => {
    let showRegionComp = false
    if (
      carbonFootPrintState.portfolioRegions.isLoading ||
      carbonFootPrintState.portfolioRegions.dataStatus === PAGE_CON.ERROR ||
      (carbonFootPrintState.portfolioRegions.dataStatus === PAGE_CON.SUCCESS &&
        carbonFootPrintState.portfolioRegions.data?.summary?.length > 0 &&
        carbonFootPrintState.portfolioRegions.data?.details?.length > 0 &&
        carbonFootPrintState.portfolioRegions.data?.mapData?.length > 0)
    )
      showRegionComp = true
    return showRegionComp
  }

  const canShowUnderlyingComp = () => {
    let showUnderlyingComp = false
    if (
      carbonFootPrintState.portfolioUnderlyingDataMetrics.isLoading ||
      carbonFootPrintState.portfolioUnderlyingDataMetrics.dataStatus ===
        PAGE_CON.ERROR ||
      (carbonFootPrintState.portfolioUnderlyingDataMetrics.dataStatus ===
        PAGE_CON.SUCCESS &&
        carbonFootPrintState.portfolioUnderlyingDataMetrics.data?.length > 0)
    )
      showUnderlyingComp = true
    return showUnderlyingComp
  }

  return (
    <Page
      className={classes.root}
      title={PAGE_CON.PHY_OVE_TEXT_TITLE}
      id="CarbonFootPrint-test-id"
    >
      <main className={classes.content}>
        <Container className={classes.container} maxWidth={false}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{ marginLeft: '7px', paddingLeft: '0px' }}
            >
              <CarbonFootPrintOverview
                showBenchMark={showBenchMark}
                pageTitle={props.pageHeaderTitle}
              />
              {canShowEmissions() ? (
                <>
                  <Grid item container style={linkPaddingOverviewEmission}>
                    <a href={'#' + PAGE_CON.EMISSION_TITLE}>
                      <IconArrowDown />
                      <span style={linkStyle}>{PAGE_CON.EMISSION_TITLE}</span>
                    </a>
                  </Grid>

                  <Grid item container>
                    <AppBar
                      id={props.id}
                      position="sticky"
                      className={classes.stickyHeader}
                      style={{ top: '20px' }}
                    >
                      <div
                        id={PAGE_CON.EMISSION_TITLE}
                        style={{ paddingTop: '100px' }}
                      >
                        <div
                          className={classes.tableTitle}
                          style={updatesStyle}
                        >
                          {' '}
                          {PAGE_CON.EMISSION_TITLE}{' '}
                        </div>
                      </div>
                    </AppBar>
                    <Grid item xs={12} className={classes.containerWrapper}>
                      <br />
                      {carbonFootPrintState.portfolioEmissions.isLoading ? (
                        <LoadingMask />
                      ) : carbonFootPrintState.portfolioEmissions.dataStatus ===
                          PAGE_CON.ERROR ||
                        (carbonFootPrintState.portfolioEmissions.dataStatus ===
                          PAGE_CON.SUCCESS &&
                          carbonFootPrintState.portfolioEmissions.data &&
                          carbonFootPrintState.portfolioEmissions.data
                            .length === 0) ? (
                        <Error
                          id={'emissionError'}
                          dataStatus={
                            isFilterStateAvailable
                              ? carbonFootPrintState.portfolioEmissions
                                  .dataStatus
                              : PAGE_CON.SUCCESS
                          }
                          onErrorClick={reloadComponent}
                          errorOccuredOn={PAGE_CON.EMISSION_TITLE}
                        />
                      ) : (
                        <Emissions
                          emissionTableTitles={emissionObj.emissionTableTitles}
                          colInfo={emmissionColumn}
                          emissionData={
                            carbonFootPrintState.portfolioEmissions.data
                          }
                        />
                      )}
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </Grid>
            <Grid item container style={linkPaddingOVerview}>
              <a href={'#' + PAGE_CON.UPDATES_AND_CURRENT_LEADERS_LAGGARDS}>
                <IconArrowDown />
                <span style={linkStyle}>{updateSectionText}</span>
              </a>
            </Grid>
            <Grid item container>
              <AppBar
                id={props.id}
                position="sticky"
                className={classes.stickyHeader}
                style={{ top: '20px' }}
              >
                <div
                  id={PAGE_CON.UPDATES_AND_CURRENT_LEADERS_LAGGARDS}
                  style={{ paddingTop: '100px' }}
                >
                  <div className={classes.tableTitle} style={updatesStyle}>
                    {' '}
                    {updateSectionText}{' '}
                  </div>
                </div>
              </AppBar>
              <Grid item xs={12} style={UpdatesTableStyle}>
                <h6 style={cardHeader}>Updates</h6>
                {carbonFootPrintState.portfolioUpdates.isLoading ? (
                  <LoadingMask />
                ) : carbonFootPrintState.portfolioUpdates.dataStatus ===
                    PAGE_CON.ERROR ||
                  (carbonFootPrintState.portfolioUpdates.dataStatus ===
                    PAGE_CON.SUCCESS &&
                    carbonFootPrintState.portfolioUpdates.data?.length ===
                      0) ? (
                  <Error
                    id={'updatesError'}
                    errorClass={'noMargin'}
                    width={'101.3%'}
                    margin={0}
                    topMargin={'0px'}
                    dataStatus={
                      isFilterStateAvailable
                        ? carbonFootPrintState.portfolioUpdates.dataStatus
                        : PAGE_CON.SUCCESS
                    }
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.UPDATESIN_TITLE}
                  />
                ) : (
                  <Updates
                    colInfo={UpdatesTableColInfo}
                    panelColInfo={PanelUpdatesTableColInfo}
                    totalData={carbonFootPrintState.portfolioUpdates.data}
                    tableLength={10}
                    tableEditable={false}
                    monthYear={monthYear}
                  />
                )}
              </Grid>
              {carbonFootPrintState.portfolioImpact.isLoading ||
              canShowImpact() ? (
                <Grid
                  item
                  container
                  className={classes.impactContainerWrapper}
                  style={{ paddingLeft: '16px' }}
                >
                  {carbonFootPrintState.portfolioImpact.isLoading ? (
                    <LoadingMask />
                  ) : carbonFootPrintState.portfolioImpact.dataStatus ===
                    PAGE_CON.ERROR ? (
                    <Error
                      id={'impactError'}
                      dataStatus={
                        isFilterStateAvailable
                          ? carbonFootPrintState.portfolioImpact.dataStatus
                          : PAGE_CON.SUCCESS
                      }
                      onErrorClick={reloadComponent}
                      errorOccuredOn={PAGE_CON.IMPACT_TITLE}
                      topMargin="5px"
                    />
                  ) : (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className={classes.impactDropdown}
                      >
                        <span
                          style={{
                            backgroundColor:
                              themeColorPalette.palette.sys_trad.selected2.main,
                            marginBottom: '12px',
                            paddingTop: 4
                          }}
                        >
                          <DropdownField
                            id="impact-filter"
                            dropdownDefaultSelction={
                              carbonFootPrintState.portfolioImpact.filter
                            }
                            saveValueUsingExternalState={true}
                            dropdownOptions={filterOptions}
                            onChange={onImpactFilterChange}
                            labelTextDisabled={true}
                            labelText=""
                            alternateLabelText="Top 5"
                            defaultData={''}
                            impactColor={
                              themeColorPalette.palette.sys_trad.selected2.main
                            }
                          />
                        </span>
                      </Grid>
                      <Impact
                        positiveImpactData={
                          carbonFootPrintState.portfolioImpact.data[
                            carbonFootPrintState.portfolioImpact.filter
                          ]?.positive_impact?.investment_and_score || []
                        }
                        posBarChartData={getPositiveBarData()}
                        posBarColors={getPositiveBarColor()}
                        posBarTitle={`${
                          filterOptions.find(
                            (item) =>
                              item.key ===
                              carbonFootPrintState.portfolioImpact.filter
                          ).displayText
                        } vs. Total Investment %`}
                        negativeImpactData={
                          carbonFootPrintState.portfolioImpact.data[
                            carbonFootPrintState.portfolioImpact.filter
                          ]?.negative_impact?.investment_and_score || []
                        }
                        negBarChartData={getNegativeBarData()}
                        negBarColors={getNegativeBarColor()}
                        negBarTitle={`${
                          filterOptions.find(
                            (item) =>
                              item.key ===
                              carbonFootPrintState.portfolioImpact.filter
                          ).displayText
                        } vs. Total Investment %`}
                        impactColumns={impactColumns}
                        impactFilter={
                          carbonFootPrintState.portfolioImpact.filter
                        }
                      />
                    </>
                  )}
                </Grid>
              ) : null}
              {carbonFootPrintState.portfolioLeaderLaggard.isLoading ||
              canShowLeadersLaggards() ? (
                <Grid item container className={classes.containerWrapper}>
                  <Grid item xs={12} sm={6} style={{ paddingRight: '12px' }}>
                    <h6 style={cardHeader}>
                      {PAGE_CON.PORTFOLIO_LEADERS_BY_SCORE}
                    </h6>
                    {carbonFootPrintState.portfolioLeaderLaggard.isLoading ? (
                      <LoadingMask />
                    ) : carbonFootPrintState.portfolioLeaderLaggard
                        .dataStatus === PAGE_CON.ERROR ||
                      (carbonFootPrintState.portfolioLeaderLaggard
                        .dataStatus === PAGE_CON.SUCCESS &&
                        carbonFootPrintState.portfolioLeaderLaggard.data
                          .leaders &&
                        carbonFootPrintState.portfolioLeaderLaggard.data.leaders
                          .length === 0) ? (
                      <Error
                        id={'leadersError'}
                        dataStatus={
                          isFilterStateAvailable
                            ? carbonFootPrintState.portfolioLeaderLaggard
                                .dataStatus
                            : PAGE_CON.SUCCESS
                        }
                        onErrorClick={reloadComponent}
                        errorOccuredOn={PAGE_CON.LEADERS_TITLE}
                        height={'312px'}
                      />
                    ) : (
                      <Leaders
                        colInfo={LeaderLaggardColInfo}
                        totalData={
                          carbonFootPrintState.portfolioLeaderLaggard.data
                            .leaders
                        }
                        tableLength={tableLength}
                        tableEditable={false}
                        noRowHighlight={true}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingLeft: '12px' }}>
                    <h6 style={cardHeader}>
                      {PAGE_CON.PORTFOLIO_LAGGARDS_BY_SCORE}
                    </h6>
                    {carbonFootPrintState.portfolioLeaderLaggard.isLoading ? (
                      <LoadingMask />
                    ) : carbonFootPrintState.portfolioLeaderLaggard
                        .dataStatus === PAGE_CON.ERROR ||
                      (carbonFootPrintState.portfolioLeaderLaggard
                        .dataStatus === PAGE_CON.SUCCESS &&
                        carbonFootPrintState.portfolioLeaderLaggard.data
                          .laggards &&
                        carbonFootPrintState.portfolioLeaderLaggard.data
                          .laggards.length === 0) ? (
                      <div style={{ paddingBottom: 8, paddingRight: 8 }}>
                        <Error
                          id={'laggardsError'}
                          dataStatus={
                            isFilterStateAvailable
                              ? carbonFootPrintState.portfolioLeaderLaggard
                                  .dataStatus
                              : PAGE_CON.SUCCESS
                          }
                          onErrorClick={reloadComponent}
                          errorOccuredOn={PAGE_CON.LAGGARDS_TITLE}
                          height={'312px'}
                        />
                      </div>
                    ) : (
                      <Laggards
                        colInfo={LeaderLaggardColInfo}
                        totalData={
                          carbonFootPrintState.portfolioLeaderLaggard.data
                            .laggards
                        }
                        tableLength={tableLength}
                        tableEditable={false}
                        noRowHighlight={true}
                      />
                    )}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            {canShowRegionComp() ? (
              <>
                <Grid item container>
                  <a
                    href={'#' + PAGE_CON.REGIONS_INDUSTRIES}
                    style={linkPaddingRegionIndustry}
                  >
                    <IconArrowDown />
                    <span style={linkStyle}>
                      {PAGE_CON.REGIONS_TITLE} & {PAGE_CON.SECTORS_TITLE}
                    </span>
                  </a>{' '}
                </Grid>

                <Grid item container>
                  <AppBar
                    id={props.id}
                    position="sticky"
                    className={classes.stickyHeader}
                    style={regionTitleStyle}
                  >
                    <div
                      id={PAGE_CON.REGIONS_INDUSTRIES}
                      style={{ paddingTop: '100px' }}
                    >
                      <div
                        className={classes.tableTitle}
                        style={{ marginTop: '18px' }}
                      >
                        {' '}
                        {PAGE_CON.REGIONS_TITLE} & {PAGE_CON.SECTORS_TITLE}{' '}
                      </div>
                    </div>
                  </AppBar>
                  <Grid item xs={12} style={regionStyle}>
                    {carbonFootPrintState.portfolioRegions.isLoading ? (
                      <LoadingMask />
                    ) : carbonFootPrintState.portfolioRegions.dataStatus ===
                        PAGE_CON.ERROR ||
                      (carbonFootPrintState.portfolioRegions.dataStatus ===
                        PAGE_CON.SUCCESS &&
                        !isRegionHasData()) ? (
                      <Error
                        id={'regionsError'}
                        dataStatus={
                          isFilterStateAvailable
                            ? carbonFootPrintState.portfolioRegions.dataStatus
                            : PAGE_CON.SUCCESS
                        }
                        onErrorClick={reloadComponent}
                        errorOccuredOn={PAGE_CON.REGIONS_TITLE}
                      />
                    ) : (
                      <Regions
                        hideMap={true}
                        key={'RegionKey'}
                        data={carbonFootPrintState.portfolioRegions.data}
                        displayRegions={[]}
                        selectedCardId={selectedCardId}
                        onRegionSelect={selectedCard}
                        mimTabColInfo={mimTabColInfo}
                        panelTabColInfo={panelTabColInfo}
                        isCarbonFootprint={true}
                        styleFirstChild={true}
                        cardInfoContentInfo={cardInfoContentInfo}
                        cardInfoContentInfoSector={cardInfoContentInfoSector}
                        currentPageState={'globalCarbonFootPrint'}
                        mapData={carbonFootprintMapData}
                        mapDataClasses={mapDataClasses}
                        stateData={true}
                        noRowHighlight={true}
                        height={
                          carbonFootPrintState.portfolioRegions.data &&
                          carbonFootPrintState.portfolioRegions.data.summary
                            .length === 1
                            ? '50%'
                            : '100%'
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            ) : null}
            {canShowUnderlyingComp() ? (
              <>
                <Grid item container>
                  <a
                    href={'#' + PAGE_CON.CARBON_FOOTPRINT_RISK_CATEGORIES}
                    style={linkPadding}
                  >
                    <IconArrowDown />
                    <span style={linkStyle}>
                      {PAGE_CON.CRBN_FT_PRT_TITLE} {PAGE_CON.SCOPE_TITLE}
                    </span>
                  </a>
                </Grid>

                <Grid item container>
                  <AppBar
                    id={props.id}
                    position="sticky"
                    className={classes.stickyHeader}
                    style={{ top: '20px' }}
                  >
                    <div
                      id={PAGE_CON.CARBON_FOOTPRINT_RISK_CATEGORIES}
                      style={{ paddingTop: '100px' }}
                    >
                      <div
                        className={classes.tableTitle}
                        style={dataMetricTitle}
                      >
                        {' '}
                        {PAGE_CON.CRBN_FT_PRT_TITLE} {PAGE_CON.SCOPE_TITLE}{' '}
                      </div>
                    </div>
                  </AppBar>
                  <Grid item xs={12} style={dataMetricbox}>
                    {carbonFootPrintState.portfolioUnderlyingDataMetrics
                      .isLoading ? (
                      <LoadingMask />
                    ) : carbonFootPrintState.portfolioUnderlyingDataMetrics
                        .dataStatus === PAGE_CON.ERROR ||
                      (carbonFootPrintState.portfolioUnderlyingDataMetrics
                        .dataStatus === PAGE_CON.SUCCESS &&
                        carbonFootPrintState.portfolioUnderlyingDataMetrics.data
                          .length === 0) ? (
                      <Error
                        id={'udmError'}
                        dataStatus={
                          isFilterStateAvailable
                            ? carbonFootPrintState
                                .portfolioUnderlyingDataMetrics.dataStatus
                            : PAGE_CON.SUCCESS
                        }
                        onErrorClick={reloadComponent}
                        errorOccuredOn={PAGE_CON.SCOPE_TITLE}
                      />
                    ) : (
                      <CarbonFootprintUnderlyingDataMetrics
                        pageTitle={CFPData.underlyingDataMatrics.pageTitle}
                        sections={CFPData.underlyingDataMatrics.sections}
                        messages={CFPData.underlyingDataMatrics.messages}
                        companyData={CFPData.underlyingDataMatrics.companyData}
                        investmentPercentData={
                          CFPData.underlyingDataMatrics.investmentPercentData
                        }
                        underlyingDataMetrics={
                          carbonFootPrintState.portfolioUnderlyingDataMetrics
                            .data
                        }
                        dataStatus={
                          carbonFootPrintState.portfolioUnderlyingDataMetrics
                            .dataStatus
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
          <div style={{ height: '550px' }}></div>
        </Container>
      </main>
    </Page>
  )
}

export default CarbonFootPrintManagement
