import React from 'react'
import { formattingRules } from './CriteriaFilterData'
import { useStyles } from './styles'
import { Checkbox, ToggleButton } from 'components'
import themeColorPalette from 'theme/theme'

const checkboxTemplate = (option, onFilterOptionChange, i) => {
  return (
    <span key={`checkbox-block-${i}`}>
      <div
        style={{
          margin: option?.countries
            ? '12px 0 0px'
            : option.value.includes('All S')
            ? '0 0 12px'
            : 0
        }}
      >
        <Checkbox
          key={`criteria-filter-checkbox-${i}`}
          id={option.value}
          checked={option.checked}
          customStyle={
            option?.countries || option.value.includes('All ')
              ? 'criteriaFilterLabelBold'
              : 'criteriaFilterLabel'
          }
          onChange={onFilterOptionChange}
          label={option.value}
        />
      </div>
      {option?.countries?.length > 0 &&
        option?.countries?.map((country, i) => (
          <Checkbox
            key={`criteria-country-filter-checkbox-${i}`}
            id={country.value}
            checked={country.checked}
            customStyle={'criteriaFilterLabel'}
            onChange={onFilterOptionChange}
            label={country.value}
          />
        ))}
    </span>
  )
}

const SectorSizeCriteriaFilter = ({
  options,
  onFilterOptionChange,
  toggleChecked,
  setToggleChecked,
  unknown,
  footerInfo
}) => {
  const classes = useStyles()
  const duplicatedOptions = [...options]
  const firstItem =
    duplicatedOptions.length > 1 ? duplicatedOptions.shift() : null

  return (
    <div style={{ padding: 4 }}>
      {firstItem && checkboxTemplate(firstItem, onFilterOptionChange, 0)}
      <div
        style={{
          maxHeight:
            unknown !== undefined && Object.keys(unknown).length > 1
              ? 148
              : 280,
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        {firstItem !== null &&
          duplicatedOptions?.map((option, i) =>
            checkboxTemplate(option, onFilterOptionChange, i)
          )}
      </div>
      {!Array.isArray(unknown) && unknown?.company_count && (
        <>
          {firstItem !== null && <hr className={classes.sectorSizeCriteriaHRStyle} />}
          <p
            className={classes.sectorSizeCriteriaInfoTextStyle}
            style={{
              color: themeColorPalette.palette.sys_trad.main.main,
              margin: '8px 0px',
              marginTop: firstItem ? 8 : 0
            }}
          >
            {footerInfo.title}
          </p>
          <ToggleButton
            id={footerInfo.title}
            toggleLabelText={''}
            labelPlacement={'end'}
            isChecked={toggleChecked}
            onChange={(item) => setToggleChecked(item.checked)}
          />
          <p className={classes.sectorSizeCriteriaInfoTextStyle} style={{ margin: 0 }}>
            {`Include ${unknown.company_count} identifiable ${
              unknown.company_count > 1 ? 'companies' : 'company'
            }  without ${footerInfo.text}
            (${formattingRules(unknown.investment_pct)} invested)`}
          </p>
        </>
      )}
    </div>
  )
}

export default SectorSizeCriteriaFilter