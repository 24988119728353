import { makeStyles } from '@material-ui/core/styles'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  formControl: {
    width: (props) => props.inputWidth,
    marginLeft: (props) => props.inputMargin,
    '& label + .MuiInput-formControl': {
      marginTop: '14px'
    },
    '&:focus': {
      border: '2px solid ' + themeColorPalette.palette.sys_trad.focus.main
    }
  },
  autoFillInput: {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset'
    }
  },
  helperText: {
    fontSize: '12px',
    height: '14px',
    fontWeight: 500,
    textAlign: 'left',
    lineHeight: '14px',
    '&.MuiFormHelperText-root.Mui-error': {
      color: themeColorPalette.palette.sys_trad.error.main
    }
  },
  inputLabelClass: {
    height: '14px',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    boxSizing: 'border-box',
    fontWeight: 650,
    color: themeColorPalette.palette.sys_trad.text2.main,
    textAlign: 'left',
    lineHeight: '14px',
    fontSize: '13px',
    '&.Mui-focused': {
      color: themeColorPalette.palette.sys_trad.text2.main
    },
    '&.Mui-error': {
      color: themeColorPalette.palette.sys_trad.text2.main
    }
  },
  lightWeightInput: {
    padding: '16px 0px 16px 0px',
    height: '32px',
    fontSize: '12px',
    color: themeColorPalette.palette.sys_trad.text.main,
    '&:hover': {
      '&.MuiInput-underline:before': {
        borderBottom:
          '1px solid ' + themeColorPalette.palette.sys_trad.selected.main
      }
    },
    '&.MuiInput-underline:after': {
      height: '1px',
      borderBottom:
        '1px solid ' + themeColorPalette.palette.sys_trad.selected.main
    },
    '&.Mui-focused': {
      color: '2px solid ' + themeColorPalette.palette.sys_trad.focus.main
    },
    '&.MuiInput-underline:focus': {
      border: '2px solid ' + themeColorPalette.palette.sys_trad.focus.main
    },
    '&:focus': {
      border: '2px solid ' + themeColorPalette.palette.sys_trad.focus.main
    },
    '&.Mui-error': {
      '&.MuiInput-underline:after': {
        height: '1px',
        borderBottomColor: themeColorPalette.palette.sys_trad.error.main
      },
      '&.MuiInput-underline:focus': {
        border: '2px solid ' + themeColorPalette.palette.sys_trad.focus.main
      }
    }
  }
}))
