import * as React from 'react'
import PropTypes from 'prop-types'
import { BorderLinearProgress } from './styles'

const ProgressBar = (props) => {
  const width = props.width || '880px'
  return (
    // eslint-disable-next-line react/no-unknown-property
    <p sx={{ m: 1 }} style={{ width }}>
      <BorderLinearProgress
        variant="determinate"
        customstyle={props.customStyle}
        value={props.value}
        progressColour={props.progressColour}
        primaryColor={props.primaryColor}
        height={props.height}
      />
    </p>
  )
}

ProgressBar.propTypes = {
  /** width */
  width: PropTypes.string,
  /** value */
  value: PropTypes.number,
  /** height */
  height: PropTypes.number,
  /** progressColour */
  progressColour: PropTypes.string,
  /** primaryColor */
  primaryColor: PropTypes.string,
  /** customStyle */
  customStyle: PropTypes.object
}
export default ProgressBar
