export const getResearchLinePrefix = ({ researchLine = '' }) =>
  ({
    supplychainrisk: '/supplychainrisk/',
    marketrisk: '/marketrisk/',
    operationsrisk: '/operationsrisk/',
    physicalriskhazard: '/physicalriskhazard/',
    physicalriskmgmt: '/physicalriskmgmt/',
    greenshareasmt: '/greenshareasmt/',
    brownshareasmt: '/brownshareasmt/',
    temperaturealignment: '/temperaturealgmt/',
    carbonfootprint: '/carbonfootprint/',
    esgasmt: '/esgasmt/',
    controversies: 'controdata/',
    default: ''
  }[researchLine] || '')

export const getPromise = async (fn, ...args) =>
  new Promise((resolve) => {
    resolve(fn(...args))
  })

export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min
}

export const fakeApiCall = (delay = 1000) =>
  new Promise((resolve) =>
    setTimeout(
      (data) => resolve(Math.floor(Math.random() * data)),
      delay,
      delay
    )
  )
