import React, { forwardRef, useContext, useState } from 'react'
import {
  Arrows,
  CustomCell,
  DropdownContainer,
  ListComp,
  Table,
  Tabs
} from 'components'
import * as S from '../dashboardComponents/index.jsx'
import Constant from 'constant'
import { LabelColors } from 'config'
import numberData, { carbonFootPrintDataFormat } from 'utils/numberFormat'
import { GlobalContext } from 'context/GlobalStateProvider'
import getMonthYear from 'utils/Date'
import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'
import Error from 'utils/error'
import {
  ScoreQualityLevel,
  predictedScoreHighlight
} from 'views/globalComponent/ScoreQualityLevel'

const PerformanceTable = ({
  dashboardTable,
  entitlements,
  handleFiltersChange,
  rangeSelected,
  researchLineSelected,
  scoreQuality
}) => {
  const entitledTableHeaders = Constant.Dashboard.getTableHeaders(
    entitlements
  ).filter((header) => header.entitled === true)

  const defaultResearchLine = entitledTableHeaders.filter(
    ({ sortable }) => sortable
  )[0]?.key

  const [tabs, setTabs] = useState(Constant.Dashboard.defaultTableTabs)
  const [selectedRange, setSelectedRange] = useState(
    rangeSelected || Constant.Dashboard.performanceTableRanges[0]
  )
  const [selectedResearchLine, setSelectedResearchLine] = useState(
    researchLineSelected || defaultResearchLine
  )
  const context = useContext(GlobalContext)
  const [filterState] = context.globalFilter
  const [, entityHeaderDispatch] = useContext(GlobalContext).globalEntityHeader

  const activeTab = tabs.find(({ active }) => active)
  const selectedTableType = activeTab.key

  const tableHandleSort = (research_line) => {
    if (selectedTableType !== 'largest_holdings') {
      setSelectedResearchLine(research_line)
      handleFiltersChange({
        range: selectedRange,
        table: selectedTableType,
        research_line
      })
    }
  }

  const handleTabClick = (table) => {
    const newTabs = tabs.map((tab) => ({ ...tab, active: tab.key === table }))
    setTabs(newTabs)
    setSelectedResearchLine(selectedResearchLine)
    handleFiltersChange({
      range: selectedRange,
      table,
      research_line: selectedResearchLine
    })
  }

  const onErrorReloadComponent = () => {
    const table = tabs.filter((tab) => tab.active === true)[0].key
    const new_research_line =
      table === 'largest_holdings'
        ? 'physical_risk_management'
        : selectedResearchLine

    handleFiltersChange({
      range: selectedRange,
      table,
      research_line: new_research_line,
      onError: true
    })
  }

  const removeUndefined = (text) => (text === undefined ? '' : text)

  const Cell = ({ label, value, customClass }) => (
    <S.CellContainer label={label} value={value} customClass={customClass} />
  )

  const onEntityClick = (item) => {
    const selectedOrbisId = item?.PARENT_BVD9_NUMBER || item?.BVD9_NUMBER
    if ((item?.BVD9_NUMBER || '') !== '') {
      entityHeaderDispatch({ type: REDUCER_TYPE.SET_ORBIB_ID, selectedOrbisId })
    }
  }

  let isRowHighlighted = []

  const formatTableData = (data) =>
    [...data].map((item, key) => {
      const newItem = []
      const keys = entitledTableHeaders.map(({ dataKey }) => dataKey)
      const scoreQualityKey = item
        ? Object.keys(item).filter((k) =>
            k.toLowerCase().startsWith('score_quality')
          )[0]
        : undefined

      entitledTableHeaders.forEach((header, index) => {
        const value =
          header.key === 'carbon_footprint'
            ? removeUndefined(carbonFootPrintDataFormat(item[keys[index]]))
            : removeUndefined(item[keys[index]])

        if (header.key === 'company_name') {
          newItem[index] = (
            <S.ListCellContainer className="CompanyNameWrapper">
              {scoreQuality && item.SCORE_CATEGORY_ESG_ASSESSMENT ? (
                <ScoreQualityLevel level={item[scoreQualityKey]} />
              ) : null}
              <S.ListCellContainer
                className={'ellipsis'}
                onClick={
                  item[scoreQualityKey] === 'Predicted'
                    ? () => {}
                    : () => onEntityClick(item)
                }
              >
                <S.ListCellContainer
                  title={item.COMPANY_NAME}
                  className="entity"
                >
                  {item.COMPANY_NAME}
                </S.ListCellContainer>
              </S.ListCellContainer>
            </S.ListCellContainer>
          )
        } else if (header.key === 'percent_holdings') {
          newItem[index] = numberData(value, {
            isDecimal: true,
            isPercentage: true,
            keepRawValue: true
          })
        } else if (
          header.key === 'brown_share' ||
          header.key === 'green_share'
        ) {
          const itemFlag = item[
            `SCORE_CATEGORY_${header.key.toUpperCase()}`
          ] ? (
            <>
              {header.key === 'brown_share' ? (
                <CustomCell styles={LabelColors}>
                  {selectedResearchLine === header.key &&
                    selectedTableType !== 'largest_holdings' && (
                      <Arrows
                        prev={item[`PREV_SCORE_${header.key.toUpperCase()}`]}
                        curr={item[`CURR_SCORE_${header.key.toUpperCase()}`]}
                        researchLine={header.key}
                      />
                    )}
                  {parseInt(item['CURR_SCORE_BROWN_SHARE']) >= 0
                    ? `${item['CURR_SCORE_BROWN_SHARE']}%`
                    : item['SCORE_CATEGORY_BROWN_SHARE']}
                </CustomCell>
              ) : (
                <CustomCell
                  styles={LabelColors}
                  text={item[`SCORE_CATEGORY_${header.key.toUpperCase()}`]}
                >
                  {selectedResearchLine === header.key &&
                    selectedTableType !== 'largest_holdings' && (
                      <Arrows
                        prev={item[`PREV_SCORE_${header.key.toUpperCase()}`]}
                        curr={item[`CURR_SCORE_${header.key.toUpperCase()}`]}
                        researchLine={header.key}
                      />
                    )}
                  {item[`SCORE_CATEGORY_${header.key.toUpperCase()}`]}
                </CustomCell>
              )}
            </>
          ) : null
          newItem[index] = <Cell label={itemFlag} />
        } else if (header.key === 'physicalriskhazard') {
          newItem[index] = numberData(value, {
            isPercentage: true,
            keepRawValue: true
          })
        } else if (header.key === 'physicalriskhazard_hrh') {
          const customClass =
            item['PHYSICAL_RISK_HAZARD_PERCENT_FACILITIES_EXPOSED'] > 0 &&
            'critical'
          newItem[index] = <Cell label={value} customClass={customClass} />
        } else if (header.key === 'physicalriskmgmt') {
          newItem[index] = (
            <CustomCell text={value} styles={LabelColors}>
              {value}
            </CustomCell>
          )
        } else if (header.key === 'temperaturealgmt' && value === 'Above 2°C') {
          newItem[index] = (
            <CustomCell styles={LabelColors} text={value}>
              {value}
            </CustomCell>
          )
        } else if (header.key === 'critical_controversies') {
          const customClass = value > 0 && 'critical'
          newItem[index] = <Cell label={value} customClass={customClass} />
        } else {
          const itemFlag = item[
            `SCORE_CATEGORY_${header.key.toUpperCase()}`
          ] ? (
            <CustomCell
              styles={LabelColors}
              text={item[`SCORE_CATEGORY_${header.key.toUpperCase()}`]}
            >
              {selectedResearchLine === header.key &&
                selectedTableType !== 'largest_holdings' && (
                  <Arrows
                    prev={item[`PREV_SCORE_${header.key.toUpperCase()}`]}
                    curr={item[`CURR_SCORE_${header.key.toUpperCase()}`]}
                    researchLine={header.key}
                  />
                )}
              {item[`SCORE_CATEGORY_${header.key.toUpperCase()}`]}
            </CustomCell>
          ) : null
          const valueWithStyles = item[
            `SCORE_CATEGORY_${header.key.toUpperCase()}`
          ] ? (
            <CustomCell
              styles={LabelColors}
              text={item[`SCORE_CATEGORY_${header.key.toUpperCase()}`]}
            >
              {value}
            </CustomCell>
          ) : (
            value
          )
          newItem[index] = <Cell label={itemFlag} value={valueWithStyles} />
        }

        if (!entitledTableHeaders[index + 1]) {
          isRowHighlighted[key] = predictedScoreHighlight(item[scoreQualityKey])
        }
      })

      return newItem
    })

  const tableHeaders = entitledTableHeaders.map((element) => {
    const highlighted =
      selectedTableType === 'largest_holdings'
        ? 'percent_holdings'
        : selectedResearchLine
    const sortable =
      selectedTableType === 'largest_holdings' ? false : element.sortable
    return {
      ...element,
      columnStyles: {
        ...element.columnStyles,
        backgroundColor: element.key === highlighted ? '#D7EDFA' : 'none'
      },
      sortable
    }
  })

  const totalPercentHoldings =
    dashboardTable.data
      .map((e) => parseFloat(e['PERCENT_HOLDINGS']))
      .reduce((a, b) => a + b, 0) || 0

  const tableFooter = entitledTableHeaders.map((e) =>
    e.key === 'percent_holdings'
      ? `Sum: ${
          totalPercentHoldings >= 100 ? 100 : totalPercentHoldings.toFixed(2)
        }%`
      : ''
  )

  const rangeData = Constant.Dashboard.performanceTableRanges.map((e) => ({
    key: e,
    displayText: e
  }))

  const [itemClick, setItemClick] = useState(0)

  const onItemClick = () => {
    setItemClick((prev) => prev + 1)
  }

  const onRangeChange = (val) => {
    setSelectedRange(val)
    onItemClick()
  }

  const TopItems = forwardRef(() => (
    <ListComp
      height="auto"
      id="list-range"
      listDefaultSelection={selectedRange}
      onChange={onRangeChange}
      listOptions={rangeData}
      labelText={''}
      labelTextKey={''}
      titleText={''}
      width={120}
      isLoading={false}
    />
  ))

  TopItems.displayName = 'TopItems'
  const onClose = () => {
    handleFiltersChange({
      range: selectedRange,
      table: activeTab.key,
      research_line: selectedResearchLine
    })
  }

  const tabsWithDates = tabs.map((t) => {
    return {
      ...t,
      label:
        t.key !== 'largest_holdings'
          ? `${getMonthYear(filterState)} ${t.label}`
          : t.label
    }
  })

  return (
    <>
      <S.PerformanceTableTitle>
        Performance: Largest Holdings, Leaders, Laggards
      </S.PerformanceTableTitle>

      <S.PerformanceTableDropdown>
        <DropdownContainer
          heap_id="perfchart"
          heap_perfchart_id="filter"
          containerClick={itemClick}
          displayText={`Show ${activeTab.order} ${selectedRange}`}
          containerComponent={<TopItems />}
          onClose={onClose}
          disabled={dashboardTable.isLoading}
        />
      </S.PerformanceTableDropdown>

      <Tabs
        tabs={tabsWithDates}
        onClick={(key) => handleTabClick(key)}
        isLoading={dashboardTable.isLoading}
      />
      {dashboardTable.dataStatus === PAGE_CON.ERROR ? (
        <Error
          id={'perfError'}
          dataStatus={dashboardTable.dataStatus}
          onErrorClick={onErrorReloadComponent}
        />
      ) : (
        <Table
          headers={tableHeaders}
          footers={tableFooter}
          data={formatTableData(dashboardTable.data)}
          handleSort={tableHandleSort}
          isLoading={dashboardTable.isLoading}
          maxheight={360}
          isPerformanceTable={true}
          isHighlighted={isRowHighlighted}
          isStickyHeader={true}
        />
      )}
    </>
  )
}

PerformanceTable.propTypes = {}

export default PerformanceTable
