import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { IconError, PrimaryPopover, Section } from 'components'
import theme from 'theme/theme'
import { GlobalContext } from '../../context/GlobalStateProvider'
import { ReportingServices } from 'services'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import LoadingMask from 'utils/loadingMask'
import Error from 'utils/error'
import { Backdrop } from '@material-ui/core'
import themeColorPalette from 'theme/theme'

const useStyles = makeStyles(() => ({
  parentContainer: {},
  header: {
    ...theme.typography.p2,
    lineHeight: '14px',
    fontWeight: 350,
    fontStyle: 'normal',
    color: theme.palette.sys_trad.main.main,
    fontSize: '12px',
    paddingBottom: '22px'
  },
  item: {
    ...theme.typography.p3,
    color: theme.palette.sys_trad.text.main,
    fontSize: '12px',
    marginBottom: 7,
    '&>div': {
      padding: 3
    }
  },
  url: {
    textDecoration: 'underline',
    overflowWrap: 'break-word',
    '& span': {
      cursor: 'pointer'
    }
  },
  description: {
    overflowWrap: 'break-word'
  }
}))

const ReportingHistory = () => {
  const classes = useStyles()
  const [reportingServiceState, reportingServiceDispatch] =
    useContext(GlobalContext).globalReportingService
  const [downloadStart, setDownloadStart] = useState(false)
  const [errorPopoverOpen, setErrorPopoverOpen] = useState(false)

  const reportTypeMapping = {
    eu_taxonomy: 'EU_Taxonomy',
    interim: 'Interim'
  }

  const getReportHistory = useCallback(async () => {
    reportingServiceDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.REPORTING_PORTFOLIO_HISTORY
    })

    try {
      const data = await ReportingServices.getReportHistory()
      reportingServiceDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.REPORTING_PORTFOLIO_HISTORY,
        data: data
      })
      return data
    } catch (err) {
      reportingServiceDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        sectionName: REDUCER_STATE.REPORTING_PORTFOLIO_HISTORY
      })
    }
  }, [reportingServiceDispatch])

  useEffect(() => {
    getReportHistory()
  }, [getReportHistory])

  const reloadComponent = (errorOccuredOn) => {
    switch (errorOccuredOn) {
      case PAGE_CON.PORTFOLIO_HISTORY:
        getReportHistory()
        break
      default:
        break
    }
  }

  const mappedData = reportingServiceState.reportingHistory.data.map(
    ({
      request_id,
      report_date_utc,
      report_name,
      report_quantity,
      report_type,
      portfolio_name_list,
      report_url
    }) => ({
      id: request_id,
      date: report_date_utc,
      filename: report_name,
      description: `${report_quantity}: ${
        reportTypeMapping[report_type]
      } for ${portfolio_name_list.join(', ')}`,
      report_url
    })
  )

  const showErrorPopover = () => {
    setErrorPopoverOpen(true)
    setTimeout(() => {
      setErrorPopoverOpen(false)
    }, 3000)
  }

  const downloadFile = async (id) => {
    setDownloadStart(true)
    try {
      const data = await ReportingServices.getReportUrl({
        request_id: id
      })

      const link = document.createElement('a')
      if (link.download !== undefined) {
        link.setAttribute('href', data)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } catch (error) {
      setDownloadStart(false)
      showErrorPopover()
    }
    setDownloadStart(false)
  }

  const backdrop = {
    zIndex: 1201,
    color: '#fff'
  }

  return (
    <>
      <Grid container direction="column" className={classes.parentContainer}>
        <Section box>
          {reportingServiceState.reportingHistory.isLoading ? (
            <LoadingMask />
          ) : reportingServiceState.reportingHistory.dataStatus ===
              PAGE_CON.ERROR ||
            (reportingServiceState.reportingHistory.dataStatus ===
              PAGE_CON.SUCCESS &&
              (reportingServiceState.reportingHistory.data?.length || 0) ===
                0) ? (
            <Error
              id={'reportingHistoryError'}
              dataStatus={reportingServiceState.reportingHistory.dataStatus}
              onErrorClick={reloadComponent}
              errorOccuredOn={PAGE_CON.PORTFOLIO_HISTORY}
            />
          ) : (
            <>
              <Grid item className={classes.header} xs={12}>
                Reports created previously:
              </Grid>
              <Grid item xs={12}>
                {mappedData.map(
                  ({ id, date, filename, description }, index) => (
                    <Grid
                      container
                      key={`item-${index}-${id}`}
                      className={classes.item}
                    >
                      <Grid item xs={2}>
                        {date}
                      </Grid>
                      <Grid item xs={4} className={classes.url}>
                        <span
                          aria-hidden={true}
                          onClick={() => downloadFile(id)}
                        >
                          {filename}
                        </span>
                      </Grid>
                      <Grid item xs={6} className={classes.description}>
                        {description}
                      </Grid>
                    </Grid>
                  )
                )}
              </Grid>
            </>
          )}
        </Section>
      </Grid>
      <PrimaryPopover
        alertOpen={errorPopoverOpen}
        alertIcon={
          <IconError
            colorprimary={themeColorPalette.palette.sys_trad.text.main}
          />
        }
        alertText={
          <span>
            Export failed because of an issue, please try again later.
          </span>
        }
        alertPosition="center"
        alertWidth={'190px'}
        alertTop="55px"
        alertTextTopPadding={'1px'}
        paddingTop="4px"
        paddingBottom="4px"
        paddingLeft="8px"
        paddingRight="8px"
        overflowY="hidden"
        hideBackdrop={true}
        hideClose={true}
        popoverShadow={false}
        popoverBgColor={themeColorPalette.palette.sys_trad.warning.main}
      />
      {downloadStart && (
        <Backdrop style={backdrop} open={downloadStart}>
          <LoadingMask loadText="Loading" />
        </Backdrop>
      )}
    </>
  )
}

export default ReportingHistory
