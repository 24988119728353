import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useStyles } from './styles'
import * as S from '../dashboard/dashboardComponents/index.jsx'
import { GlobalContext } from 'context/GlobalStateProvider'
import { IconErrorTable, InfiniteList } from 'components'
import LoadingMask from 'utils/loadingMask'
import { getMonthDayYear } from 'utils/Date'
import PortfolioMonitoringModal from '../dashboard/PortfolioMonitoring/Modal'

const Controversy = (props) => {
  const classes = useStyles()

  const [listPage, setListPage] = useState(1)
  const [tableRows, setTableRows] = useState([])
  const [tableRowsLoading, setTableRowsLoading] = useState(false)
  const [, setControversyCount] = useState(0)
  const [, setCriticalCount] = useState(0)
  const [, setTotalEvents] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [selectedControversy, setSelectedControversy] = useState(null)

  const context = useContext(GlobalContext)
  const [entityState] = context.globalEntity

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  const getControversiesList = async (data, page = 1) => {
    if (data) {
      const controversiesRowsPerPage = 15
      await sleep(500)
      const newData = [...data]
      const EOL =
        controversiesRowsPerPage * page >= newData.length
          ? newData.length
          : controversiesRowsPerPage * page
      const dataSpliced = newData.splice(0, EOL)
      return dataSpliced
    }
  }

  const getControversies = useCallback(
    async (data) => {
      setTotalEvents(data?.length)

      const controversiesTotal =
        data?.filter(({ type }) => type === 'Controversy').length || 0
      const controversiesCritical =
        data?.filter(({ severity }) => severity === 'Critical').length || 0
      setControversyCount(controversiesTotal)
      setCriticalCount(controversiesCritical)

      const initialData = await getControversiesList(data)
      setTableRows(initialData)
    },
    [entityState]
  )

  useEffect(() => {
    getControversies(entityState?.entityControversy?.data)
  }, [entityState?.entityControversy?.dataStatus])

  useEffect(async () => {
    const newLoadedData = await getControversiesList(
      entityState?.entityControversy?.data,
      listPage
    )

    setTableRows(newLoadedData)
    setTableRowsLoading(false)
  }, [listPage])

  const scrollEndHandler = () => {
    const newListPage = listPage + 1
    setListPage(newListPage)
  }

  const openModal = (key) => {
    setSelectedControversy(entityState?.entityControversy?.data[key])
    setShowModal(true)
  }

  const mappedListData = (data) =>
    data
      ? data.map((row) => {
          const { controversy_title, controversy_events, severity } = row
          return [
            <S.ListCellContainer className="date" key={'controversy_events_id'}>
              {getMonthDayYear(controversy_events)}
            </S.ListCellContainer>,
            <S.ListCellContainer
              className={classes.critical}
              key={'critical_id'}
            >
              {severity === 'Critical' && (
                <div data-testid="critical">
                  {' '}
                  <IconErrorTable />
                </div>
              )}
            </S.ListCellContainer>,
            <S.ListCellContainer
              className="description"
              key={'controversy_title_id'}
            >
              {controversy_title}
            </S.ListCellContainer>
          ]
        })
      : []

  const dataTableRows = mappedListData(tableRows)
  return (
    <div>
      {showModal && (
        <PortfolioMonitoringModal
          closeModalAction={() => setShowModal(false)}
          selectedControversy={selectedControversy}
          isIssuer={true}
        />
      )}
      <div>
        <div className={classes.headerStyle}>Controversies</div>
        {dataTableRows.length === 0 ? (
          <LoadingMask />
        ) : (
          <div data-testid="controversy-list">
            <InfiniteList
              notClickable={false}
              isLoading={tableRowsLoading}
              data={dataTableRows}
              onScrollEnd={scrollEndHandler}
              height={props.listHeight}
              rowAction={(key) => openModal(key)}
            />
          </div>
        )}
      </div>
    </div>
  )
}

Controversy.propTypes = {}

export default Controversy
