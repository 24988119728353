import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  link: {
    ...textTheme.typography.p3,
    height: '14px',
    cursor: 'pointer',
    pointerEvents: 'auto',
    boxSizing: 'border-box',
    textAlign: 'left',
    lineHeight: '16px',
    textTransform: 'none',
    outline: 'none',
    marginLeft: (props) => props.linkMargin,
    width: (props) => props.linkWidth,
    fontSize: (props) => (props.reducedSize ? '10px !important' : '12px')
  },
  linkDefault: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    fontSize: (props) => props.reducedSize13 && '13px !important',
    marginTop: (props) => props.linkMarginTop && props.linkMarginTop,
    marginRight: (props) => props.linkMarginRight && props.linkMarginRight,
    color: themeColorPalette.palette.sys_trad.link.main,
    '&:hover': {
      color: themeColorPalette.palette.sys_trad.linkhover.main,
      outline: 'none',
      textDecoration: 'none'
    },
    '&.Mui-selected': {
      color: themeColorPalette.palette.sys_trad.linkpress.main,
      outline: 'none',
      textDecoration: 'none',
      '&:hover': {
        color: themeColorPalette.palette.sys_trad.linkhover.main,
        outline: 'none',
        textDecoration: 'none'
      }
    }
  },
  linkDisable: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    fontSize: (props) => props.reducedSize13 && '13px !important',
    marginTop: (props) => props.linkMarginTop && props.linkMarginTop,
    marginRight: (props) => props.linkMarginRight && props.linkMarginRight,
    color: themeColorPalette.palette.sys_trad.disabled.main,
    cursor: 'not-allowed',
    '&:hover': {
      color: themeColorPalette.palette.sys_trad.disabled.main,
      outline: 'none',
      textDecoration: 'underline',
      cursor: 'not-allowed'
    },
    '&.Mui-selected': {
      color: themeColorPalette.palette.sys_trad.disabled.main,
      outline: 'none',
      textDecoration: 'underline',
      cursor: 'not-allowed',
      '&:hover': {
        color: themeColorPalette.palette.sys_trad.disabled.main,
        outline: 'none',
        textDecoration: 'underline',
        cursor: 'not-allowed'
      }
    }
  },
  linkIssuerPDF: {
    color: themeColorPalette.palette.sys_trad.main.main,
    textDecoration: 'underline',
    fontSize: '16px !important',
    ...textTheme.typography.p3,
    marginLeft: '0px !important',
    '&:hover': {
      color: themeColorPalette.palette.sys_trad.hover.main
    }
  },
  linkInline: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    color: (props) =>
      props.linkColorChange
        ? themeColorPalette.palette.sys_trad.link.main
        : themeColorPalette.palette.sys_trad.text.main,
    textDecoration: (props) => (props.linkColorChange ? 'none' : 'underline'),
    fontFamily: 'WhitneyNarrMedium',
    '&:hover': {
      color: themeColorPalette.palette.sys_trad.linkhover.main,
      outline: 'none',
      textDecoration: 'none'
    },
    '&.Mui-selected': {
      color: themeColorPalette.palette.sys_trad.linkpress.main,
      outline: 'none',
      textDecoration: 'none',
      '&:hover': {
        color: themeColorPalette.palette.sys_trad.linkhover.main,
        outline: 'none',
        textDecoration: 'none'
      }
    }
  },
  linkMateriality: {
    ...textTheme.typography.h3,
    backgroundColor: themeColorPalette.palette.sys_trad.white.main,
    color: themeColorPalette.palette.sys_trad.main.main,

    '&:hover': {
      color: themeColorPalette.palette.sys_trad.main.main,
      outline: 'none',
      textDecoration: 'none'
    },
    '&.Mui-selected': {
      color: themeColorPalette.palette.sys_trad.main.main,
      outline: 'none',
      textDecoration: 'none',
      '&:hover': {
        color: themeColorPalette.palette.sys_trad.main.main,
        outline: 'none',
        textDecoration: 'none'
      }
    }
  },
  linkInlineEsgCoverage: {
    ...textTheme.typography.p2,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    color: (props) =>
      props.linkColorChange
        ? themeColorPalette.palette.sys_trad.main.main
        : themeColorPalette.palette.sys_trad.text.main,
    textDecoration: 'underline',
    '&.Mui-selected': {
      color: themeColorPalette.palette.sys_trad.linkpress.main,
      outline: 'none',
      textDecoration: 'none',
      '&:hover': {
        color: themeColorPalette.palette.sys_trad.linkhover.main,
        outline: 'none',
        textDecoration: 'none'
      }
    }
  },
  linkInlineEntity: {
    ...textTheme.typography.p3,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    color: (props) =>
      props.linkColorChange
        ? themeColorPalette.palette.sys_trad.main.main
        : themeColorPalette.palette.sys_trad.text.main,
    textDecoration: 'underline',
    '&.Mui-selected': {
      color: themeColorPalette.palette.sys_trad.linkpress.main,
      outline: 'none',
      textDecoration: 'none',
      '&:hover': {
        color: themeColorPalette.palette.sys_trad.linkhover.main,
        outline: 'none',
        textDecoration: 'none'
      }
    }
  },
  linkHeader: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    color: themeColorPalette.palette.sys_trad.main.main,
    width: '60px',
    height: '14px',
    fontWeight: 650, //figma says 400
    fontSize: '14px',
    lineHeight: '24px',
    '&:hover': {
      color: themeColorPalette.palette.sys_trad.linkhover.main,
      outline: 'none',
      textDecoration: 'none'
    },
    '&.Mui-selected': {
      color: themeColorPalette.palette.sys_trad.linkpress.main,
      outline: 'none',
      textDecoration: 'none',
      '&:hover': {
        color: themeColorPalette.palette.sys_trad.linkhover.main,
        outline: 'none',
        textDecoration: 'none'
      }
    }
  },
  linkIssuer: {
    fontSize: (props) => props.reducedSize13 && '13px !important',
    marginTop: (props) => props.linkMarginTop && props.linkMarginTop,
    marginRight: (props) => props.linkMarginRight && props.linkMarginRight,
    color: themeColorPalette.palette.white.main,
    '&:hover': {
      color: themeColorPalette.palette.white.main,
      outline: 'none',
      textDecoration: 'none'
    },
    '&.Mui-selected': {
      color: themeColorPalette.palette.white.main,
      outline: 'none',
      textDecoration: 'none',
      '&:hover': {
        color: themeColorPalette.palette.white.main,
        outline: 'none',
        textDecoration: 'none'
      }
    }
  },
  linkDeafultDark: {
    color: themeColorPalette.palette.white.main,
    textDecoration: 'underline',
    '&:hover': {
      color: themeColorPalette.palette.white.main,
      textDecoration: 'underline',
      outline: 'none'
    },
    '&.Mui-selected': {
      color: themeColorPalette.palette.white.main,
      textDecoration: 'underline',
      outline: 'none',
      '&:hover': {
        color: themeColorPalette.palette.white.main,
        textDecoration: 'underline',
        outline: 'none'
      }
    }
  },
  uploadLink: {
    ...textTheme.typography.p3,
    fontWeight: 400,
    backgroundColor: themeColorPalette.palette.white.main,
    color: themeColorPalette.palette.sys_trad.selected.main,
    textDecoration: 'none',
    marginRight: (props) => props.linkMargin && props.linkMargin,
    '&:hover': {
      color: themeColorPalette.palette.sys_trad.selected.main,
      outline: 'none',
      textDecoration: 'none'
    },
    '&.Mui-selected': {
      color: themeColorPalette.palette.sys_trad.selected.main,
      outline: 'none',
      textDecoration: 'none',
      '&:hover': {
        color: themeColorPalette.palette.sys_trad.selected.main,
        outline: 'none',
        textDecoration: 'none'
      }
    }
  },
  portfolioUploadLink: {
    ...textTheme.typography.p3,
    fontWeight: 400,
    backgroundColor: (props) =>
      props.linkBgColor || themeColorPalette.palette.white.main,
    color: themeColorPalette.palette.sys_trad.text.main,
    textDecoration: 'underline',
    marginTop: (props) => props.linkMarginTop && props.linkMarginTop,
    marginRight: (props) => props.linkMargin && props.linkMargin,
    '&:hover': {
      color: themeColorPalette.palette.sys_trad.text.main,
      outline: 'none',
      textDecoration: 'underline'
    },
    '&.Mui-selected': {
      color: themeColorPalette.palette.sys_trad.text.main,
      outline: 'none',
      textDecoration: 'underline',
      '&:hover': {
        color: themeColorPalette.palette.sys_trad.text.main,
        outline: 'none',
        textDecoration: 'underline'
      }
    }
  }
}))
