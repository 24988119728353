import React from 'react'
import * as S from '../../dashboardComponents/index.jsx'
import { PrimaryLink } from 'components'

const EsgAssessment = ({ onClick, entitlements }) => {
  return (
    <S.PanelContainer>
      <h2>ESG Assessment Framework</h2>
      <p>
        Our ESG Assessment Framework is designed to provide relevant and
        comparable insight across sectors and regions by encompassing ESG
        drivers of risks or opportunities that can be material for entities in
        the short or long term. This double materiality framework covers a broad
        range of stakeholders including shareholders, employees,
        sub-contractors, suppliers, business partners, customers, local
        communities, regulatory authorities, society, as well as the
        environment.
      </p>
      <br />

      <p>
        The Assessment Framework considers universally recognized sustainability
        standards, guidelines and recommendations set by international
        organizations with a mandate to define expectations of conduct for
        business organizations.
      </p>

      <br />
      <p>
        The framework also considers evolving ESG trends and emerging factors:
      </p>
      <br />

      <S.ListContainer>
        <li>
          <strong>ESG trends</strong> are observations of patterns in the
          sustainability sphere that have significant impacts on stakeholders,
          the environment or an entity’s business continuity.
        </li>
        <li>
          <strong>Emerging issues</strong> are ESG-related phenomena or concepts
          that we consider relevant, with the potential to become more central
          to an entity's future performance.
        </li>
      </S.ListContainer>
      <br />

      <h2>ESG Categories and Subcategories</h2>
      <p>
        By analyzing the content of the above-mentioned sustainability
        standards, guidelines, and trends, we have identified the underlying
        objectives and expectations that we consider relevant and material to
        the assessments and have condensed them into 30 ESG Subcategories. Each
        ESG Subcategory is organized into 12 ESG Categories split across
        Environmental (E), Social (S) and Governance (G).
      </p>

      <div>
        <PrimaryLink
          id="link-link-test-id-1"
          linkText={'Read more about ESG Assessment Methodology 1.0'}
          onClick={() => onClick('Methodology_1')}
          linkClass="linkInline"
          linkColorChange={true}
        />
        <br />
        {entitlements.Dashboard.controversy && (
          <PrimaryLink
            id="link-link-test-id-3"
            linkText={'Read more about Controversy Risk Assessment Methodology'}
            onClick={() => onClick('Controversy')}
            linkClass="linkInline"
            linkColorChange={true}
          />
        )}
      </div>
    </S.PanelContainer>
  )
}

export default EsgAssessment
