import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconArrowDown = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 16L9.93204 9.25013C10.071 9.01152 9.99029 8.70541 9.75168 8.56641C9.51307 8.42741 9.20696 8.50816 9.06796 8.74677L7 12.2967V0.498447C7 0.222305 6.77614 -0.00155258 6.5 -0.00155258C6.22386 -0.00155258 6 0.222305 6 0.498447V16Z"
      fill="#A38355"
    />
  </SvgIcon>
)
IconArrowDown.displayName = 'IconArrowDown'
IconArrowDown.muiName = 'SvgIcon'

export default IconArrowDown
