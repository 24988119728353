/* eslint-disable*/
import clsx from 'clsx'
import { Button, Link, ListItem } from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import { REDUCER_TYPE } from 'constant/constants'
import { GlobalContext } from 'context/GlobalStateProvider'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useStyles } from './styles'

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  styleguide,
  lastItemInSection,
  disabled,
  onNavItemClick,
  ...rest
}) => {
  const classes = useStyles()
  const context = useContext(GlobalContext)
  const [portfolioReviewState, portfolioReviewDispatch] =
    context.globalPortfolioReview

  const onReportNavClick = (event) => {
    portfolioReviewDispatch({
      type: REDUCER_TYPE.PORTFOLIO_REVIEW_PAGE,
      page: event.target.innerText
    })
    if (onNavItemClick) {
      onNavItemClick()
    }
  }

  const onDisabledReportNavClick = (event) => {
    portfolioReviewDispatch({
      type: REDUCER_TYPE.PORTFOLIO_REVIEW_PAGE,
      page: portfolioReviewState.portfolioReviewPage
    })
  }

  return (
    <ListItem
      className={clsx(classes.item, className)}
      onClick={!disabled ? onReportNavClick : onDisabledReportNavClick}
      disabled={disabled}
      disableGutters
      {...rest}
    >
      <Button
        disableRipple
        disabled={disabled}
        activeClassName={classes.active}
        className={classes.button}
        component={styleguide ? Link : RouterLink}
        to={!disabled ? href : portfolioReviewState.portfolioReviewPage}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{title}</span>
      </Button>
    </ListItem>
  )
}

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  styleguide: PropTypes.bool,
  lastItemInSection: PropTypes.bool,
  onNavItemClick: PropTypes.func
}

export default NavItem
