import React from 'react'
import PropTypes from 'prop-types'
import LoadingMask from 'utils/loadingMask'
import * as S from '../dashboardComponents/index.jsx'
import { numberOfCompanies } from 'utils/numberFormat'

const MapCoverage = ({ companies, investment, portfolio, isLoading }) => {
  return isLoading ? (
    <LoadingMask />
  ) : (
    <S.CoverageContent>
      Coverage {numberOfCompanies(companies)}, {investment} of investments in
      Portfolio {portfolio}
    </S.CoverageContent>
  )
}

MapCoverage.propTypes = {
  companies: PropTypes.string,
  investment: PropTypes.any,
  portfolio: PropTypes.string,
  isLoading: PropTypes.bool,
  selectedResearchLine: PropTypes.string,
  entitlements: PropTypes.object
}

export default MapCoverage
