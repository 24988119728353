import React from 'react'
import { Box, Container, Typography } from '@material-ui/core'
import Logo from './mlogo.png'
import { Page, PageHeader, PrimaryLink } from 'components'
import * as S from './styles'

const Title = ({ children }) => {
  const classes = S.useStyles()
  return (
    <Typography variant="h1" className={classes.title}>
      {children}
    </Typography>
  )
}

const Paragraph = ({ children }) => {
  const classes = S.useStyles()
  return (
    <Box>
      <Typography className={classes.text}>{children}</Typography>
    </Box>
  )
}

const Terms = () => {
  return (
    <Page title="Moody's Analytics">
      <PageHeader
        pageHeaderHeight={'auto'}
        pageHeaderContentComponent={
          <Container maxWidth="md">
            <img alt={'moodyslogo'} src={Logo} style={{ height: '40px' }}></img>
          </Container>
        }
      />
      <Container maxWidth="md">
        <Box>
          <Box>
            <Title>User Agreement</Title>
          </Box>
          <Box>
            <Paragraph>
              Thank you for your interest in this website. Before accessing the
              Site (as defined below), please read the following terms and
              conditions carefully as they govern your use of the Site and its
              content. If you agree with the terms and conditions, click “I
              agree” at the end of this document. If you do not agree with these
              terms and conditions, Moody’s Investors Service, Inc. (“MIS”),
              Moody’s Analytics, Inc. and their affiliates (collectively,
              “Moody’s”) cannot grant you the right to use the content on the
              Site. A complete list of Moody’s group affiliates is available on{' '}
              <PrimaryLink
                linkText="www.moodys.com"
                onClick={() =>
                  window.location.replace('https://www.moodys.com')
                }
              ></PrimaryLink>
              .
            </Paragraph>
            <Paragraph>
              By clicking “I AGREE” at the end of this document, you indicate
              that you understand and intend these terms and conditions to be
              the legal equivalent of a signed, written contract and equally
              binding, and that you accept such terms and conditions and agree
              to be legally bound by them.
            </Paragraph>
            <Paragraph>
              This website, and all other websites that redirect to this User
              Agreement (defined below) or on which this User Agreement appears
              (collectively, the “Site”) shall be governed by the terms of use
              of this User Agreement (this “User Agreement” or these “Terms of
              Use”).
            </Paragraph>
            <Paragraph>
              Moody’s reserves the right to change the terms and conditions of
              this User Agreement upon notice, which may be given by Moody’s
              posting such change (or revised User Agreement) on the Site, by
              e-mail, or any other reasonable way. If a change is notified by a
              posting on the Site, it shall be deemed to take effect when
              posted; if a change is notified by e-mail, it shall be deemed to
              take effect when the e-mail is sent; and if a modification is
              notified in any other way, it shall be deemed to take effect when
              the relevant notice is sent or issued by or on behalf of Moody’s.
              Your continued use of this Site following notice of such
              modifications will be conclusively deemed acceptance of any
              changes to this User Agreement. You agree that notice of changes
              to this User Agreement on the Site, delivered by email, or
              provided in any other reasonable way constitutes reasonable and
              sufficient notice.
            </Paragraph>
            <Paragraph>
              The Site is owned and operated by Moody’s. This User Agreement
              contains the terms, covenants, conditions, and provisions upon
              which you (“You” or “User”) may access and use the Site and the
              content, including (without limitation) the ratings and other
              opinions, displayed on the Site. This agreement was last updated
              on July 2, 2019.
            </Paragraph>
          </Box>
        </Box>
        <Box>
          <Box>
            <Title>Terms and Conditions</Title>
          </Box>
          <Box>
            <ul style={{ listStyle: 'decimal', padding: '20px' }}>
              <li>
                <Paragraph>
                  <u>Grant of License</u>. These Terms of Use provide you with a
                  personal, revocable, non-exclusive, non-transferable license
                  to use the Site conditioned on your continued compliance with
                  the terms and conditions of these Terms of Use. You may, on an
                  occasional and irregular basis, print and download Materials
                  on the Site solely for personal and non-commercial use. In
                  addition, subject to any limitations set forth in an Other
                  Agreement (defined below), you may: (a) print, copy and use,
                  solely for your internal business purposes, the Moody’s
                  Investors Service credit ratings and credit rating
                  announcements on the Site (excluding any other Materials that
                  may accompany such credit ratings and credit rating
                  announcements); and (b) solely to the extent that credit
                  ratings are required by law or regulation to be included
                  within a report filed with, and distributed solely to, a
                  governmental authority, copy and distribute credit ratings
                  within, and as part of, such reports. In each case, you may
                  not obscure, alter, remove or delete any copyright or other
                  proprietary notices or disclosures contained in such
                  Materials. With the exception of the foregoing and except as
                  otherwise expressly permitted herein, you may not modify,
                  create derivatives of, copy, distribute, repackage,
                  redistribute, disseminate, broadcast, transmit, reproduce,
                  publish, license, transfer, sell or re-sell, mirror, frame,
                  “deep link”, “scrape”, data mine, or otherwise use or store
                  for subsequent use for any such purpose, any information or
                  Materials obtained from or through this Site, without Moody’s
                  prior written consent. Further, you may not post any Materials
                  from this Site to forums, newsgroups, list serves, mailing
                  lists, electronic bulletin boards, or other websites, without
                  the prior written consent of Moody’s. You warrant to Moody’s
                  that you will not use this Site for any purpose that is
                  unlawful or prohibited by these Terms of Use, including but
                  not limited to attempting or actually (i) disrupting,
                  impairing or interfering with this Site, or (ii) collecting
                  any information about other users of this Site, including
                  passwords, accounts or other information.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Intellectual Property Rights</u>. All Materials contained
                  on the Site, unless otherwise indicated, are protected by law
                  including, but not limited to, United States copyright, trade
                  secret, and trademark law, as well as other state, national,
                  and international laws and regulations. The Site, its
                  Materials, layout and design are the exclusive property of
                  Moody’s or its licensors and, except as expressly provided
                  herein, Moody’s does not grant any express or implied right in
                  any such Materials to you. Notwithstanding anything to the
                  contrary in these Terms of Use, including with respect to any
                  rights expressly provided to you herein, you may not use
                  scraping tools or other computer automation to copy or extract
                  any Materials contained on this Site for any purpose. Moody’s
                  owns the copyright in the Site as a collective work and/or
                  compilation, any and all databases accessible on the Site, and
                  in the selection, coordination, arrangement, and enhancement
                  of the Materials on the Site. “Moody’s”, “Moody’s Investors
                  Service”, “Moody’s Analytics”, and all other names, logos, and
                  icons identifying Moody’s and/or Moody’s products and services
                  are proprietary marks of Moody’s or its licensors. Third-party
                  trademarks displayed on the Site are the property of their
                  respective owners.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Privacy Policy</u>. Please see Moody’s Privacy Policy
                  available at{' '}
                  <PrimaryLink
                    linkText="www.moodys.com"
                    onClick={() =>
                      window.location.replace('https://www.moodys.com')
                    }
                  ></PrimaryLink>{' '}
                  for a summary of Moody’s personal data collection and use
                  practices with respect to the Site.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Password Policy</u>. Your use of certain portions of the
                  Site requires a password. As part of the registration process,
                  you must select a member name and password and provide Moody’s
                  with accurate, complete and up-to-date information. Anyone
                  with knowledge of your password can gain access to the
                  restricted portions of the Site and the information available
                  to you. Accordingly, you must keep your password secret. By
                  agreeing to these Terms of Use, you agree to be solely
                  responsible for the confidentiality and use of your respective
                  password, as well as for any communications entered through
                  this Site using your password. You will also immediately
                  notify Moody’s if you become aware of any loss or theft of
                  your password or any unauthorized use of your password.
                  Moody’s reserves the right to delete or change a password at
                  any time and for any reason.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Assumption of Risk</u>. You use the Internet solely at your
                  own risk and subject to all applicable local, state, national,
                  and international laws and regulations. While Moody’s has
                  endeavored to create a secure and reliable website, please be
                  advised that the confidentiality of any communication or
                  material transmitted to/from this Site over the Internet
                  cannot be guaranteed. Accordingly, Moody’s and Moody’s
                  licensors and suppliers are not responsible for the security
                  of any information transmitted via the Internet, the accuracy
                  of the information contained on the Site, or for the
                  consequences of any reliance on such information. Moody’s and
                  Moody’s licensors and suppliers shall have no liability for
                  interruptions or omissions in Internet, network or hosting
                  services. You assume the sole and complete risk of using the
                  Site.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Enforcing Security</u>. Actual or attempted unauthorized
                  use of any of the Site may result in criminal and/or civil
                  prosecution. To maintain the security and integrity of the
                  Site, Moody’s reserves the right to review and record activity
                  on the Site to the extent permitted by law and consistent with
                  Moody’s Privacy Policy. Any information obtained by such
                  reviewing or recording is subject to review by law enforcement
                  organizations in connection with the investigation or
                  prosecution of possible criminal activity on any of the Site.
                  Moody’s will also comply with all court orders involving
                  requests for such information.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Links to Other Websites</u>. Moody’s may provide links, in
                  its sole discretion, to other websites on the World Wide Web
                  for your convenience in locating related information and
                  services. These websites have not necessarily been reviewed by
                  Moody’s and are maintained by third parties over which Moody’s
                  exercises no control. Accordingly, Moody’s and its directors,
                  officers, employees, agents, representatives, licensors and
                  suppliers (together, the “Moody’s Parties”) expressly disclaim
                  any responsibility for these third-party websites, including,
                  without limitation, for the content, the accuracy of the
                  information, the quality of products or services provided by
                  or advertised on and/or software downloaded from these
                  third-party websites. Moreover, these links do not imply an
                  endorsement of any third party or any website or the products
                  or services provided by any third party.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Events Beyond Moody’s Control</u>. You expressly absolve
                  and release Moody’s and Moody’s licensors and suppliers from
                  any claim of harm resulting from a cause beyond their control,
                  including, but not limited to, the failure of electronic or
                  mechanical equipment or communication lines, telephone or
                  other interconnect problems, computer viruses, unauthorized
                  access, theft, operator errors, severe weather, earthquakes,
                  or natural disasters, strikes or other labor problems, wars,
                  terrorism or governmental restrictions.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Disclosures</u>. MIS hereby discloses that most issuers of
                  debt securities (including corporate and municipal bonds,
                  debentures, notes and commercial paper) and preferred stock
                  rated by MIS have, prior to assignment of any credit rating,
                  agreed to pay to MIS for credit ratings opinions and services
                  rendered by it fees ranging from $1,000 to approximately
                  $2,700,000. Moody's Corporation (“MCO”) and its wholly-owned
                  credit rating agency subsidiary, MIS and its ratings
                  affiliates, also maintain policies and procedures to address
                  the independence of the ratings and rating processes of MIS
                  and its ratings affiliates. Information regarding certain
                  affiliations that may exist between directors of MCO and rated
                  entities, and between entities who hold ratings from MIS
                  and/or its ratings affiliates and have also publicly reported
                  to the SEC an ownership interest in MCO of more than 5%, is
                  posted annually at www.moodys.com under the heading "Investor
                  Relations -- Corporate Governance -- Director and Shareholder
                  Affiliation Policy."
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>DISCLAIMERS</u>. Moody’s obtains all Materials furnished on
                  the Site from sources believed by it to be accurate and
                  reliable. You expressly agree that the credit ratings and
                  other opinions provided via the Site (a) are, and will be
                  construed solely as, statements of opinion of the relative
                  future credit risk (as defined below) of entities, credit
                  commitments, or debt or debt-like securities and not
                  statements of current or historical fact as to credit
                  worthiness, investment or financial advice, recommendations
                  regarding credit decisions or decisions to purchase, hold or
                  sell any securities, endorsements of the accuracy of any of
                  the data or conclusions, or attempts to independently assess
                  or vouch for the financial condition of any company; (b) do
                  not address any other risk, including but not limited to
                  liquidity risk, market value risk or price volatility; (c) do
                  not take into account your personal objectives, financial
                  situations or needs; and (d) will be weighed, if at all,
                  solely as one factor in any investment or credit decision made
                  by or on behalf of you. Accordingly, you further expressly
                  agree that you will with due care, make your own study and
                  evaluation of each investment decision or security, and of
                  each issuer and guarantor of, and each provider of credit
                  support for, each security or credit that you may consider
                  purchasing, holding, selling, or providing. For the avoidance
                  of doubt, Moody’s Materials and opinions may also include
                  quantitative model-based estimates of credit risk and related
                  opinions or commentary published by Moody’s Analytics, Inc.
                  Further, you expressly agree that any tools or information
                  made available on the Site are not a substitute for the
                  exercise of independent judgment and expertise. You should
                  always seek the assistance of a professional for advice on
                  investments, tax, the law, or other professional matters. For
                  purposes of this paragraph, Moody’s defines “credit risk” as
                  the risk that an entity may not meet its contractual,
                  financial obligations as they come due and any estimated
                  financial loss in the event of default or impairment. See
                  Moody’s Ratings Symbols and Definitions publication for
                  information on the types of contractual financial obligations
                  addressed by Moody’s ratings.
                </Paragraph>
                <Paragraph>
                  Moody’s adopts all necessary measures so that the information
                  it uses in assigning a credit rating is of sufficient quality
                  and from sources Moody’s considers to be reliable, including,
                  when appropriate, independent third-party sources. However,
                  Moody’s is not an auditor and cannot in every instance
                  independently verify or validate information received in the
                  rating process or in preparing Moody’s Materials made
                  available on the Site. Because of the possibility of human or
                  mechanical error as well as other factors, the Site and all
                  related Materials are provided on an "AS IS" and “AS
                  AVAILABLE” basis without representation or warranty of any
                  kind, and THE MOODY'S PARTIES MAKE NO REPRESENTATION OR
                  WARRANTY, EXPRESS OR IMPLIED, TO YOU OR ANY OTHER PERSON OR
                  ENTITY AS TO THE ACCURACY, RESULTS, TIMELINESS, COMPLETENESS,
                  MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE WITH
                  RESPECT TO THE SITE OR ANY RELATED MATERIALS. Moody’s makes no
                  representation or warranty that any Materials on the Site are
                  appropriate or available for use in any particular locations,
                  and access to them from territories where any of the contents
                  of this Site are illegal is prohibited. If you choose to
                  access this Site from such locations, you do so on your own
                  volition and are responsible for compliance with any
                  applicable local laws, rules and regulations. Moody’s may
                  limit the Site’s availability, in whole or in part, to any
                  person, geographic area or jurisdiction Moody’s chooses, at
                  any time and in Moody’s sole discretion. You agree and
                  acknowledge that no oral or written information or advice
                  given by Moody’s or any of its employees or agents in respect
                  to the Site shall constitute a representation or a warranty
                  unless such information or advice is incorporated into these
                  Terms of Use by a written agreement. FURTHER, THE MATERIALS
                  MADE AVAILABLE ON THIS SITE MAY INCLUDE INACCURACIES OR
                  TYPOGRAPHICAL ERRORS, AND THERE MAY BE TIMES WHEN THIS SITE OR
                  ITS MATERIALS ARE UNAVAILABLE. MOREOVER, MOODY’S MAY MAKE
                  MODIFICATIONS AND/OR CHANGES TO THE SITE OR TO THE MATERIALS
                  DESCRIBED OR MADE AVAILABLE ON THE SITE AT ANY TIME, FOR ANY
                  REASON. YOU ASSUME THE SOLE RISK OF MAKING USE OF AND/OR
                  RELYING ON THE MATERIALS MADE AVAILABLE ON THE SITE.
                </Paragraph>
                <Paragraph>
                  CREDIT RATINGS AND MOODY’S MATERIALS ARE NOT INTENDED FOR USE
                  BY ANY PERSON AS A BENCHMARK AS THAT TERM IS DEFINED FOR
                  REGULATORY PURPOSES, AND MUST NOT BE USED IN ANY WAY THAT
                  COULD RESULT IN THEM BEING CONSIDERED A BENCHMARK.
                </Paragraph>
                <Paragraph>
                  MOODY’S CREDIT RATINGS AND MOODY’S MATERIALS ARE NOT INTENDED
                  FOR USE BY RETAIL INVESTORS AND IT WOULD BE RECKLESS AND
                  INAPPROPRIATE FOR RETAIL INVESTORS TO USE MOODY’S CREDIT
                  RATINGS OR MOODY’S MATERIALS WHEN MAKING AN INVESTMENT
                  DECISION. IF IN DOUBT YOU SHOULD CONTACT YOUR FINANCIAL OR
                  OTHER PROFESSIONAL ADVISER.
                </Paragraph>
                <Paragraph>
                  TO THE EXTENT PERMITTED BY LAW, THE MOODY’S PARTIES DISCLAIM
                  LIABILITY TO ANY PERSON OR ENTITY FOR ANY INDIRECT, SPECIAL,
                  CONSEQUENTIAL, OR INCIDENTAL LOSSES OR DAMAGES WHATSOEVER
                  ARISING FROM OR IN CONNECTION WITH YOUR ACCESS TO OR USE OF
                  THIS SITE AND ITS MATERIALS OR THE USE OF OR INABILITY TO USE
                  THE SITE OR ANY OF ITS MATERIALS, EVEN IF ANY OF THE MOODY’S
                  PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR
                  DAMAGES, INCLUDING, BUT NOT LIMITED TO:
                </Paragraph>
                <Paragraph>
                  (i) COMPENSATION, REIMBURSEMENT OR DAMAGES ON ACCOUNT OF THE
                  LOSS OF PRESENT OR PROSPECTIVE PROFITS;
                </Paragraph>
                <Paragraph>
                  (ii) EXPENDITURES, INVESTMENTS OR COMMITMENTS, WHETHER MADE IN
                  THE ESTABLISHMENT, DEVELOPMENT OR MAINTENANCE OF BUSINESS
                  REPUTATION OR GOODWILL;
                </Paragraph>
                <Paragraph>
                  (iii) ANY LOSS OR DAMAGE ARISING WHERE THE RELEVANT FINANCIAL
                  INSTRUMENT IS NOT THE SUBJECT OF A PARTICULAR CREDIT RATING
                  ASSIGNED BY MOODY’S;
                </Paragraph>
                <Paragraph>(iv) LOSS OF DATA;</Paragraph>
                <Paragraph>(v) COST OF SUBSTITUTE MATERIALS;</Paragraph>
                <Paragraph>(vi) COST OF CAPITAL;</Paragraph>
                <Paragraph>(vii) THE CLAIMS OF ANY THIRD PARTY; OR</Paragraph>
                <Paragraph>
                  (viii) ANY SUCH DAMAGES ARISING OUT OF OR IN CONNECTION WITH
                  ANY OTHER REASON WHATSOEVER.
                </Paragraph>
                <Paragraph>
                  TO THE EXTENT PERMITTED BY LAW, THE MOODY’S PARTIES DISCLAIM
                  LIABILITY FOR ANY DIRECT OR COMPENSATORY LOSSES OR DAMAGES
                  CAUSED TO ANY PERSON OR ENTITY, INCLUDING BUT NOT LIMITED TO
                  BY ANY NEGLIGENCE (BUT EXCLUDING FRAUD, WILLFUL MISCONDUCT OR
                  ANY OTHER TYPE OF LIABILITY THAT, FOR THE AVOIDANCE OF DOUBT,
                  BY LAW CANNOT BE EXCLUDED) ON THE PART OF, OR ANY CONTINGENCY
                  WITHIN OR BEYOND THE CONTROL OF, THE MOODY’S PARTIES ARISING
                  FROM OR IN CONNECTION WITH YOUR USE OF OR INABILITY TO USE
                  THIS SITE AND ITS MATERIALS.
                </Paragraph>
                <Paragraph>
                  THE USER MUST USE ALL REASONABLE EFFORTS TO MITIGATE ANY LOSS
                  OR DAMAGE WHATSOEVER (AND HOWSOEVER ARISING) AND NOTHING IN
                  THESE TERMS OF USE SHALL BE DEEMED TO RELIEVE OR ABROGATE THE
                  USER OF ANY SUCH DUTY TO MITIGATE ANY LOSS OR DAMAGE.
                </Paragraph>
                <Paragraph>
                  IN ANY EVENT, TO THE EXTENT PERMITTED BY LAW, THE AGGREGATE
                  LIABILITY OF THE MOODY’S PARTIES FOR ANY REASON WHATSOEVER
                  RELATED TO ACCESS TO OR USE OF THIS SITE AND ITS MATERIALS
                  SHALL NOT EXCEED THE GREATER OF (A) THE TOTAL AMOUNT PAID BY
                  THE USER FOR SERVICES PROVIDED VIA THIS SITE PURSUANT TO THESE
                  TERMS OF USE DURING THE TWELVE (12) MONTHS IMMEDIATELY
                  PRECEDING THE EVENT GIVING RISE TO LIABILITY, OR (B) U.S.
                  $100.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Indemnity</u>. You agree to indemnify and hold harmless
                  Moody’s, its licensors and suppliers, all of their affiliates,
                  and all of their respective officers, directors, employees,
                  shareholders, representatives, agents, successors and assigns,
                  from and against any damages, liabilities, costs and expenses
                  (including reasonable attorneys’ and professionals' fees and
                  court costs) arising out of any third party claims based on or
                  related to your use of the Site or any breach by you of these
                  Terms of Use.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Submissions</u>. Moody’s welcomes your feedback and
                  suggestions, including about how to improve this Site. Moody’s
                  and/or its service providers may make available through the
                  Site certain services to which you are able to post comments,
                  information and/or feedback (for example, message boards,
                  blogs, chat features, messaging and/or comment
                  functionalities). Any ideas, suggestions, information,
                  feedback, know-how, material, and any other content
                  (collectively, “Submissions”) posted and/or received through
                  this Site, will be deemed to include a worldwide,
                  royalty-free, fully paid-up, perpetual, irrevocable,
                  nonexclusive, transferable and fully sublicensable (through
                  multiple tiers) right and license for Moody’s to adopt,
                  publish, reproduce, disseminate, transmit, distribute, copy,
                  use, create derivative works, display, (in whole or part)
                  worldwide, or act on such Submissions without additional
                  approval or consideration, in any form, media, or technology
                  now known or later developed for the full term of any rights
                  that may exist in such Submissions. You hereby waive (a) any
                  claim to the contrary; and (b) any “moral rights” associated
                  with your Submissions. You represent and warrant that you have
                  all rights necessary for you to grant the foregoing license,
                  and that each Submission you provide to the Site complies with
                  all applicable laws, rules and regulations. You are and remain
                  responsible and liable for the content of any Submission. IF
                  YOU DO NOT WISH TO GRANT THE RIGHTS GRANTED IN THIS SECTION,
                  PLEASE DO NOT POST, TRANSMIT OR OTHERWISE MAKE ANY SUBMISSION.
                  ANY SUBMISSIONS MADE ARE DONE SO AT YOUR OWN RISK. Please
                  note, Moody’s does not control any of the User-submitted
                  Submissions, they do not reflect the opinion of Moody’s, and
                  Moody’s does not guarantee their accuracy or endorse any of
                  the opinions expressed. The Moody’s Parties are not
                  responsible or liable for (i) any Submissions, including,
                  without limitation, any errors or omissions in such
                  Submissions, links or images embedded therein, or results
                  obtained by using any of the same; or (ii) any loss or damage
                  caused by the Submissions or resulting from the use (including
                  without limitation republication) or misuse thereof by any
                  third party, including your reliance thereon.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Export Restrictions</u>. No software or any other Materials
                  associated with this Site may be downloaded or otherwise
                  exported or re-exported to countries or persons prohibited
                  under relevant export control laws, including, without
                  limitation, countries against which the United States has
                  embargoed goods, or to anyone on the U.S. Treasury Department
                  list of Specially Designated Nationals and Blocked Persons or
                  the U.S. Commerce Department’s Table of Deny Orders. You are
                  responsible for compliance with the laws of your local
                  jurisdiction regarding the import, export, or re-export of any
                  such software or other Materials. By using and/or downloading
                  any such software and/or other Materials from this Site, you
                  represent and warrant that you are not located in, under the
                  control of, or a national or resident of any such country to
                  which such import, export, or re-export is prohibited or are
                  not a person or entity to which such export is prohibited.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>QIB Notification</u>. Portions of this Site were created in
                  order to enable "qualified institutional buyers," as defined
                  in Rule 144A under the Securities Act of 1933 ("QIBs"), to
                  analyze data made available by issuers to QIBs in securities
                  offerings and in the QIB secondary market. These data may or
                  may not otherwise be publicly available. If you choose to
                  access such QIB information on the Site (“QIB Information”),
                  you hereby acknowledge and agree that (a) you must be a QIB to
                  access the QIB Information, (b) the QIB Information contained
                  on this Site may only be available to QIBs, (c) you will not
                  transmit or use any such QIB Information in violation of the
                  federal securities laws, and (d) you represent and warrant to
                  Moody's (on behalf of yourself or your organization) that you
                  have such knowledge and experience in financial and business
                  matters so that you are capable of evaluating the merits and
                  risks of the securities for which QIB Information has been
                  made available by the issuers, and utilize effectively the
                  software, products and/or data provided by Moody's as an
                  analytical tool to assist in any such evaluation. You also
                  confirm that you are solely responsible for performing your
                  own evaluation of any such securities, and with respect to QIB
                  Information made available by issuers to QIBs in securities
                  offerings and in the QIB secondary market, Moody's does not
                  make any recommendations as to whether to buy, sell or hold
                  any such securities or as to the usefulness of its software,
                  products, and data to you for purposes of making any such
                  evaluation.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Governing Law</u>. These Terms of Use, including (without
                  limitation) any disputes relating to the Materials on the
                  Site, whether in contract, tort, statute or otherwise, are
                  governed by the laws of the State of New York, without
                  reference to its conflict of law principles and without regard
                  to the U.N. Convention on Contracts for the International Sale
                  of Goods.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Dispute Resolution</u>.{' '}
                  <b>
                    THIS PARAGRAPH CONTAINS ARBITRATION AND CLASS ACTION WAIVER
                    PROVISIONS THAT WAIVE THE RIGHT TO A COURT HEARING OR JURY
                    TRIAL OR TO PARTICIPATE IN A CLASS ACTION. PLEASE REVIEW
                    CAREFULLY.
                  </b>{' '}
                  Any dispute, controversy or claim arising out of or relating
                  to these Terms of Use, including any disputes relating to the
                  Materials on Site, whether in contract, tort, statute or
                  otherwise, shall be finally resolved by arbitration. Any claim
                  must be brought in the claimant’s individual capacity, and not
                  as a plaintiff or class member in any purported class,
                  collective, representative, multiple plaintiff, or similar
                  proceeding (“Class Action”). The parties expressly waive any
                  ability to maintain any Class Action in any forum. The
                  arbitration shall be conducted by one arbitrator in English
                  and in accordance with the International Rules of the American
                  Arbitration Association, which shall administer the
                  arbitration and act as appointing authority. The place of the
                  arbitration shall be New York, New York. The decision of the
                  arbitrators shall be binding upon the parties hereto, and the
                  expense of the arbitration (including without limitation the
                  award of attorneys’ fees to the prevailing party) shall be
                  paid as the arbitrators determine. Notwithstanding the
                  foregoing, if you bring a claim subject to arbitration,
                  Moody’s, if so requested by you, will pay the cost of fees and
                  deposits imposed upon you by the American Arbitration
                  Association or other arbitrator to the extent that such cost
                  exceeds the amount you would have had to pay as filing fees
                  and initial court costs if you had filed suit in a court of
                  competent jurisdiction. The decision of the arbitrator may be
                  entered by any court of competent jurisdiction. You agree to
                  submit to the jurisdiction of the state and federal courts in
                  New York for the purposes of any judicial proceedings to
                  obtain interim relief and in aid of the arbitration or
                  judicial proceedings to confirm or enforce the award.
                  Notwithstanding the foregoing, Moody’s may seek preliminary
                  injunctive relief from a court of law in the event of a breach
                  by you of these Terms of Use.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Term, Termination</u>. These Terms of Use will take effect
                  when you first commence using the Site. Moody’s reserves the
                  right at any time and for any reason to deny you access to the
                  Site or any portion thereof. Termination will be effective
                  without notice.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Waiver</u>. Failure to insist on strict performance of any
                  of the terms and conditions of these Terms of Use will not
                  operate as a waiver of any subsequent default or failure of
                  performance. No waiver by Moody’s of any right under these
                  Terms of Use will be deemed to be either a waiver of any other
                  right or provision or a waiver of that same right or provision
                  at any other time.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Nature of Relationship</u>. No joint venture, partnership,
                  employment, or agency relationship exists between you and
                  Moody’s as a result of these Terms of Use or your use of this
                  Site.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Severability</u>. The provisions of these Terms of Use are
                  severable. If any provision (or part of any provision) shall
                  be determined to be void or unenforceable, the relevant
                  provision or part of any provision shall be deemed deleted and
                  these Terms of Use, and the validity and enforceability of all
                  remaining provisions (and parts of any provisions) of these
                  Terms of Use, shall not be affected.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Entire Agreement/Reservation of Rights</u>. Your rights to
                  use certain Materials available on or through this Site may be
                  subject to separate written agreements with Moody’s (“Other
                  Agreements”). Particular pages or features of this Site with
                  content supplied by Moody’s or its licensors may have
                  different or additional terms ("Special Terms"), which will be
                  disclosed to you when you access those pages or features, and
                  by accessing or using such pages and features, you will be
                  deemed to have agreed to the applicable Special Terms. If
                  there is a conflict between these Terms of Use and the Special
                  Terms, the Special Terms will govern with respect to such
                  pages or features or content. In the event of a conflict
                  between these Terms of Use and one or more Other Agreements,
                  the terms of such Other Agreement(s) shall govern and control
                  with respect to such pages or features or content that are the
                  subject of the Other Agreement(s). With the exception of any
                  Special Terms and Other Agreements, these Terms of Use
                  represent the entire agreement between you and Moody’s with
                  respect to your use of, and Material available on or through,
                  the Site, and it supersedes all prior or contemporaneous
                  communications and proposals, whether electronic, oral, or
                  written between you and Moody’s with respect to the Site. Any
                  rights not expressly granted herein are reserved.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Third Party Beneficiaries</u>. Moody's licensors set forth
                  in these Terms of Use are intended to be third party
                  beneficiaries of the terms and conditions set forth herein, as
                  applicable to their respective products and services.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Assignment</u>. You may not assign your rights and
                  obligations under these Terms of Use without the prior written
                  consent of Moody's. Any assignment made in violation of the
                  foregoing prohibition shall be null and void. These Terms of
                  Use shall be binding upon the parties hereto and their
                  respective successors and permitted assigns.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Sanctions Compliance</u>. You warrant you are familiar
                  with, have complied with, and will comply, in all respects,
                  with the laws and regulations regarding the offering of
                  unlawful or improper inducements (including the U.S. Foreign
                  Corrupt Practices Act, as amended, and other anti-corruption
                  and anti-bribery laws). You warrant that neither you, nor any
                  entity on whose behalf you are acting, is owned or controlled
                  by, nor do you or such entity own or control, directly or
                  indirectly, a person or entity that is (i) on the list of
                  Specially Designated Nationals and Blocked Persons maintained
                  by the Office of Foreign Assets Control of the U.S. Department
                  of the Treasury or the U.K. Consolidated Financial Sanctions
                  List maintained by Her Majesty’s Treasury; or (ii) subject to
                  country sanctions imposed by the U.S. Government for any
                  reason, including but not limited to being organized or
                  headquartered in or a governmental entity of a country or
                  territory subject to such sanctions (currently Crimea, Cuba,
                  North Korea, Iran, and Syria). Additionally, you, or the
                  entity on whose behalf you are acting, warrant that you or
                  such entity do not intend to and will not knowingly supply or
                  use the Site to or for the benefit of any of the foregoing.
                  You agree to notify Moody's if these circumstances change. For
                  purposes of this provision, "owned" and "own" mean an interest
                  of 50 percent or more and "control" means the right or ability
                  to dictate the decisions, actions, and/or policies of an
                  entity or its management. If you breach this Section, in
                  addition to any other rights or remedies Moody's may have,
                  Moody's may immediately terminate your access to the Site and
                  any affected subscriptions.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Forward-Looking Statements</u>. Certain statements
                  contained on this Site, in Moody’s news releases or
                  communicated orally or in writing by Moody’s concerning the
                  company or its management, are “forward-looking statements” as
                  defined in the federal securities laws. These statements are
                  based on future expectations, plans and prospects for MCO’s
                  business and operations that involve a number of risks and
                  uncertainties, which could cause actual results or outcomes to
                  differ materially. Forward-looking statements appear at
                  various places throughout the Site and may contain the words
                  “believe”, “expect”, “anticipate”, “intend”, “plan”, “will”,
                  “predict”, “potential”, “continue”, “strategy”, “aspire”,
                  “target”, “forecast”, “project”, “estimate”, “should”,
                  “could”, “may” and similar expressions or words and variations
                  thereof. For information about factors, risks and
                  uncertainties that could cause Moody’s actual results to
                  differ materially from those in the forward-looking
                  statements, please see the discussion of “Risk Factors” in
                  MCO’s most recent annual report on Form 10-K and quarterly
                  report on Form 10-Q, and other filings made by MCO from time
                  to time with the SEC. Any forward-looking statements speak
                  only as of the date on which they were made. MCO undertakes no
                  obligation (nor does it intend) to publicly supplement, update
                  or revise any forward-looking statements on a going-forward
                  basis, whether as a result of subsequent developments, changed
                  expectations or otherwise.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>CUSIP Database</u>. You agree and acknowledge that the
                  CUSIP Database and the information contained therein is and
                  shall remain valuable intellectual property owned by, or
                  licensed to, CUSIP Global Services ("CGS") and the American
                  Bankers Association ("ABA"), and that no proprietary rights
                  are being transferred to you in such materials or in any of
                  the information contained therein. Any use by you outside of
                  the clearing and settlement of transactions requires a license
                  from CGS, along with an associated fee based on usage. You
                  agree that misappropriation or misuse of such materials will
                  cause serious damage to CGS and ABA, and that in such event
                  money damages may not constitute sufficient compensation to
                  CGS and ABA; consequently, you agree that in the event of any
                  misappropriation or misuse, CGS and ABA shall have the right
                  to obtain injunctive relief in addition to any other legal or
                  financial remedies to which CGS and ABA may be entitled. You
                  agree that you shall not publish or distribute in any medium
                  the CUSIP Database or any information contained therein or
                  summaries or subsets thereof to any person or entity except in
                  connection with the normal clearing and settlement of security
                  transactions. You further agree that the use of CUSIP numbers
                  and descriptions is not intended to create or maintain, and
                  does not serve the purpose of the creation or maintenance of,
                  a master file or database of CUSIP descriptions or numbers for
                  itself or any third party recipient of such service and is not
                  intended to create and does not serve in any way as a
                  substitute for the CUSIP MASTER TAPE, PRINT, DB, INTERNET,
                  ELECTRONIC, CD-ROM Services and/or any other future services
                  developed by the CGS.
                </Paragraph>
                <Paragraph>
                  NEITHER CGS, ABA NOR ANY OF THEIR AFFILIATES MAKE ANY
                  WARRANTIES, EXPRESS OR IMPLIED, AS TO THE ACCURACY, ADEQUACY
                  OR COMPLETENESS OF ANY OF THE INFORMATION CONTAINED IN THE
                  CUSIP DATABASE. ALL SUCH MATERIALS ARE PROVIDED TO YOU ON AN
                  "AS IS" BASIS, WITHOUT ANY WARRANTIES AS TO MERCHANTABILITY OR
                  FITNESS FOR A PARTICULAR PURPOSE OR USE NOR WITH RESPECT TO
                  THE RESULTS WHICH MAY BE OBTAINED FROM THE USE OF SUCH
                  MATERIALS. NEITHER CGS, ABA NOR THEIR AFFILIATES SHALL HAVE
                  ANY RESPONSIBILITY OR LIABILITY FOR ANY ERRORS OR OMISSIONS
                  NOR SHALL THEY BE LIABLE FOR ANY DAMAGES, WHETHER DIRECT OR
                  INDIRECT, SPECIAL OR CONSEQUENTIAL, EVEN IF THEY HAVE BEEN
                  ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL
                  THE LIABILITY OF CGS, ABA OR ANY OF THEIR AFFILIATES PURSUANT
                  TO ANY CAUSE OF ACTION, WHETHER IN CONTRACT, TORT, OR
                  OTHERWISE, EXCEED THE FEE PAID BY YOU FOR ACCESS TO SUCH
                  MATERIALS IN THE MONTH IN WHICH SUCH CAUSE OF ACTION IS
                  ALLEGED TO HAVE ARISEN. FURTHERMORE, CGS AND ABA SHALL HAVE NO
                  RESPONSIBILITY OR LIABILITY FOR DELAYS OR FAILURES DUE TO
                  CIRCUMSTANCES BEYOND THEIR CONTROL.
                </Paragraph>
                <Paragraph>
                  Your use of and access to the CGS Data is expressly
                  conditioned on MCO maintaining a distribution agreement with
                  CGS. In the event MCO’s distribution agreement is terminated,
                  your right to access and use CGS Data via Moody’s Sites shall
                  automatically terminate.
                </Paragraph>
                <Paragraph>
                  In the event you have an appropriate agreement with CGS
                  covering your use of the CGS Database (the “Separate
                  Agreement”), your use of the CGS Database shall be governed by
                  the terms of the Separate Agreement for as long as it remains
                  in effect.
                </Paragraph>
                <Paragraph>
                  You agree that the foregoing terms and conditions shall
                  survive any termination of your rights of access to the
                  materials identified above.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Additional Terms for Australia only</u>. Any publication
                  into Australia is pursuant to the Australian Financial
                  Services License of Moody’s affiliate, Moody’s Investors
                  Service Pty Limited ABN 61 003 399 657 AFSL 336969 and/or
                  Moody’s Analytics Australia Pty Ltd ABN 94 105 136 972 AFSL
                  383569 (as applicable). Moody’s credit ratings and
                  publications are intended to be provided only to “wholesale
                  clients” within the meaning of section 761G of the
                  Corporations Act 2001. By continuing to access, browse or use
                  this Site from within Australia, you represent to Moody’s that
                  you are, or are accessing the Site as a representative of, a
                  “wholesale client” and that neither you nor the entity you
                  represent will directly or indirectly disseminate any
                  Materials from this Site to “retail clients” within the
                  meaning of section 761G of the Corporations Act 2001. Moody’s
                  credit rating is an opinion as to the creditworthiness of a
                  debt obligation of the issuer, not on the equity securities of
                  the issuer or any form of security that is available to retail
                  investors.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Additional Terms for Japan only</u>. Moody's Japan K.K.
                  (“MJKK”) is a wholly-owned credit rating agency subsidiary of
                  Moody's Group Japan G.K., which is wholly-owned by Moody’s
                  Overseas Holdings Inc., a wholly-owned subsidiary of MCO.
                  Moody’s SF Japan K.K. (“MSFJ”) is a wholly-owned credit rating
                  agency subsidiary of MJKK. MSFJ is not a Nationally Recognized
                  Statistical Rating Organization (“NRSRO”). Therefore, credit
                  ratings assigned by MSFJ are Non-NRSRO Credit Ratings.
                  Non-NRSRO Credit Ratings are assigned by an entity that is not
                  a NRSRO and, consequently, the rated obligation will not
                  qualify for certain types of treatment under U.S. laws. MJKK
                  and MSFJ are credit rating agencies registered with the Japan
                  Financial Services Agency and their registration numbers are
                  FSA Commissioner (Ratings) No. 2 and 3 respectively.
                </Paragraph>
                <Paragraph>
                  MJKK or MSFJ (as applicable) hereby disclose that most issuers
                  of debt securities (including corporate and municipal bonds,
                  debentures, notes and commercial paper) and preferred stock
                  rated by MJKK or MSFJ (as applicable) have, prior to
                  assignment of any credit rating, agreed to pay to MJKK or MSFJ
                  (as applicable) for credit ratings opinions and services
                  rendered by it fees ranging from JPY125,000 to approximately
                  JPY250,000,000.
                </Paragraph>
                <Paragraph>
                  MJKK and MSFJ also maintain policies and procedures to address
                  Japanese regulatory requirements.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Claims of Copyright Infringement</u>. The Digital
                  Millennium Copyright Act of 1998 (the “DMCA”) provides
                  recourse for copyright owners who believe that material
                  appearing on the Internet infringes their rights under U.S.
                  copyright law. If you believe in good faith that materials
                  available on the Site infringe your copyright, you (or your
                  agent) may send Moody’s a notice requesting that Moody’s
                  remove the material or block access to it. If you believe in
                  good faith that someone has wrongly filed a notice of
                  copyright infringement against you, the DMCA permits you to
                  send Moody’s a counter-notice. Notices and counter-notices
                  must meet the then-current statutory requirements imposed by
                  the DMCA. See{' '}
                  <PrimaryLink
                    linkText="http://www.copyright.gov"
                    onClick={() =>
                      window.location.replace('http://www.copyright.gov')
                    }
                  ></PrimaryLink>{' '}
                  for details. Notices and counter-notices should be sent by
                  mail to: Legal Department (DMCA Notices), 405 Howard Street,
                  Suite 300, San Francisco, CA 94105; by email to
                  <span
                    style={{
                      marginLeft: 3,
                      color: '#0074b3'
                    }}
                  >
                    <a href="mailto:DMCAnotices@moodys.com">
                      DMCAnotices@moodys.com
                    </a>
                  </span>
                  ; or by fax to +1-415-874-6809. You may contact Moody’s DMCA
                  Agent by phone at +1-415-874-6000. Moody’s suggests that you
                  consult your legal advisor before filing a notice or
                  counter-notice.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Evaluation and Reporting</u>. You are receiving access to
                  this Site for evaluation purposes only and Moody’s will
                  therefore request from you a report of your evaluation at
                  agreed-upon intervals. Reports shall be made to a designated
                  representative of Moody’s in person, by telephone and/or in
                  writing as Moody’s deems reasonable and useful. Moody’s shall
                  have the right to use your ideas, information, understandings,
                  and concepts derived from your evaluation of the Site and
                  anything contained therein (your “Input”) without restriction
                  and without compensating you therefore in at least the
                  following manner: (1) to make, use, copy, modify, and create
                  derivative works of the Input, (2) to publicly perform or
                  display, import broadcast, transmit, license, offer to sell
                  and sell, rent, lease or lend copies of the Input (and
                  derivative works thereof) and (3) to sublicense to third
                  parties the foregoing rights, including the right to
                  sublicense to further third parties.
                </Paragraph>
              </li>
              <li>
                <Paragraph>
                  <u>Contact Information</u>. If you would like to contact
                  Moody’s regarding these Terms of Use or the Privacy Policy,
                  please contact:
                </Paragraph>
                <Paragraph>Legal Department</Paragraph>
                <Paragraph>Moody’s Corporation</Paragraph>
                <Paragraph>
                  7 World Trade Center at 250 Greenwich Street
                </Paragraph>
                <Paragraph>New York, NY 10007</Paragraph>
                <Paragraph>Phone: +1-212-553-1653</Paragraph>
                <Paragraph>
                  E-mail:{' '}
                  <a href="mailto:privacy@moodys.com">privacy@moodys.com</a>
                </Paragraph>
                <Paragraph>
                  If you choose to contact Moody’s via e-mail regarding these
                  Terms of Use or the Privacy Policy, respectively, please mark
                  the subject heading of your e-mail “Terms of Use Inquiry” or
                  “Privacy Inquiry.”
                </Paragraph>
              </li>
            </ul>
          </Box>
        </Box>
      </Container>
    </Page>
  )
}
export default Terms
