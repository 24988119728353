import axios from 'axios'
import Box from '@material-ui/core/Box'
import CarbonFootPrintColumns from './CarbonFootPrintColumns.json'
import {
  Card,
  CardInfoSection,
  Legend,
  MinimalTable,
  PrimaryLink,
  StackedVert
} from 'components'
import Error from 'utils/error'
import Grid from '@material-ui/core/Grid'
import LoadingMask from 'utils/loadingMask'
import OverViewText from '../../PortfolioAnalysisOverViewText.json'
import PortfolioAnalysisColumns from '../../PortfolioAnalysisColumns.json'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import { CAR_FOOT_PRNT, COMMON, PARAMS } from 'constant/APIconstants'
import { ResearchLineService } from 'services'
import { GlobalContext } from 'context/GlobalStateProvider'
import { makeStyles } from '@material-ui/core/styles'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import getColor from 'utils/color'
import { sortPortfolioHistoryChart } from 'utils/sortPortfolioHistoryChart'

const portfolioDistributionColumns = JSON.parse(
  JSON.stringify(
    CarbonFootPrintColumns.carbonfootprint_Portfolio_Distribution_Columns
  )
)

const cardInfoContentInfo = JSON.parse(
  JSON.stringify(PortfolioAnalysisColumns.Portfolio_ESG_score_New)
)
const outerColor = themeColorPalette.palette.sys_trad.bg.main
const scoreHeader = themeColorPalette.palette.sys_trad.white.main

const categoryInfo = JSON.parse(
  JSON.stringify(CarbonFootPrintColumns.categoryInfo)
)
const scoreInfoFootPrint = JSON.parse(
  JSON.stringify(CarbonFootPrintColumns.scoreInfoFootPrint)
)
const scoreInfoIntensity = JSON.parse(
  JSON.stringify(CarbonFootPrintColumns.scoreInfoIntensity)
)

const overViewTextSubtitleBenchmark = {
  ...textTheme.typography.h3,
  color: themeColorPalette.palette.sys_trad.text.main,
  textAlign: 'left',
  paddingBottom: '2px',
  paddingLeft: '7px'
}
const overViewText = {
  ...textTheme.typography.p3,
  color: themeColorPalette.palette.sys_trad.text.main,
  marginTop: '12px',
  marginBottom: '12px'
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    background: '#ffff',
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.14)',
    borderRadius: '2px'
  },
  verticalHeader: {
    ...textTheme.typography.h2,
    fontSize: '16px !important',
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    textAlign: 'right',
    paddingBottom: '16px',
    marginRight: '-12px'
  },
  paper: {
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.main.main,
    padding: '16px 16px 0px 20px'
  },
  grid: {
    textAlign: 'left',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  portfolioCoverageHeaders: {
    ...textTheme.typography.p4,
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.text2.main,
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    padding: '4px',
    marginBottom: '4px',
    minWidth: (props) => props.coverageHeaderWidth || '100%',
    width: (props) => props.coverageHeaderWidth || '100%'
  },
  portfolioCoverageHeadersRight: {
    ...textTheme.typography.p4,
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.text2.main,
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    marginLeft: '4px',
    marginBottom: '4px',
    padding: '4px',
    minWidth: (props) => props.coverageHeaderWidth || '100%',
    width: (props) => props.coverageHeaderWidth || '100%'
  },
  portfolioCoverageHeadersSmall: {
    ...textTheme.typography.p4,
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.text2.main,
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    marginBottom: '4px',
    padding: '4px',
    minWidth: '49%',
    width: '49%'
  },
  coverageBenchmarkGrid: {
    paddingLeft: '8px',
    marginLeft: 0
  },
  cardInfoFiller: {
    minHeight: '148px',
    borderRadius: '2px',
    padding: '0px',
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main
  },
  chartBoxFiller: {
    minHeight: '182px',
    maxWidth: '282px',
    background: themeColorPalette.palette.sys_trad.bg.main
  },
  historyBoxFiller: {
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.sys_trad.text.main,
    minHeight: '190px',
    maxWidth: '282px',
    textAlign: 'left',
    background: themeColorPalette.palette.sys_trad.bg.main
  },
  historyLabel: {
    padding: '4px 4px 8px',
    boxDecorationBreak: 'clone'
  },

  distributionBoxFiller: {
    minHeight: '129px',
    background: themeColorPalette.palette.sys_trad.bg.main
  },
  benchmarkBoxStyle: {
    minHeight: '32px',
    maxWidth: '1148px',
    padding: '0px !important',
    display: 'table',
    margin: '24px 8px 24px 28px',
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main
  },
  benchmarkTextStyle: {
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.sys_trad.texthelp.main,
    verticalAlign: 'middle',
    textAlign: 'center',
    display: 'table-cell'
  },
  infoBoxStyle: {
    marginLeft: '30px',
    padding: '0px',
    marginBottom: '16px'
  },
  portfolioGrid: {
    padding: '4px 16px 0px 16px !important'
  },
  benchmarkGrid: {
    padding: '0px 16px 24px 16px !important',
    marginTop: '12px !important'
  },
  cardInfoGrid: {
    minWidth: '246px'
  },
  historyGrid: {
    minWidth: '282px',
    marginTop: '4px',
    marginRight: '-18px',
    paddingLeft: '2px !important'
  },
  distributionGrid: {
    minWidth: '266px',
    marginTop: '4px',
    marginLeft: '8px'
  },
  benchmarkBistributionGrid: {
    minWidth: '266px',
    margin: '4px 0px 16px 8px'
  },
  portfolioInfoBoxStyle: {
    padding: '0px !important',
    margin: '20px 0px 16px 30px'
  },
  benchmarkInfoBoxStyle: {
    marginLeft: '30px',
    padding: '0px !important',
    marginBottom: '16px'
  },
  legendStyle: {
    textAlign: 'left'
  },
  linkStyle: {
    textAlign: 'left'
  }
}))

const CarbonFootPrintOverview = (props) => {
  const classes = useStyles(props)
  const [filterState] = useContext(GlobalContext).globalFilter

  const isFilterStateAvailable =
    filterState.filterData.regionsList.length > 0 ||
    filterState.filterData.sectorsList.length > 0

  const [carbonFootPrintState, carbonFootPrintDispatch] =
    useContext(GlobalContext).globalCarbonFootPrint

  let apiCallId = carbonFootPrintState.apiCallId

  const [state, setState] = useState({
    smallResize: false
  })

  const { smallResize } = state

  const getHistoryData = async (sectors, regions, isErrorReload = false) => {
    if (
      carbonFootPrintState.portfolioHistory.dataStatus !== '' &&
      isErrorReload === false
    )
      return

    carbonFootPrintDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_HISTORY
    })
    let history = {}

    try {
      if (isFilterStateAvailable) {
        const { data } = await axios.post(
          COMMON.BASE_URL +
            filterState.globalPortfolioFilter.portfolio +
            CAR_FOOT_PRNT.PAGE_URL +
            COMMON.ENTITY_SCORE_HISTORY,
          {
            [PARAMS.REGION]: regions,
            [PARAMS.SECTOR]: sectors,
            [PARAMS.MONTH]: filterState.globalPortfolioFilter.asOfDate.slice(4),
            [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(
              0,
              4
            ),
            [PARAMS.BENCHMARK_ID]: filterState.globalPortfolioFilter.benchmark
          },
          { cancelToken: carbonFootPrintState.cancelToken.token }
        )

        history = data[0]
      }

      carbonFootPrintDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_HISTORY,
        data: history,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        carbonFootPrintDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_HISTORY,
          apiCallId
        })
      }
    }
  }

  const getESGScoreData = async (sectors, regions, isErrorReload = false) => {
    if (
      (carbonFootPrintState.portfolioESGScore.data.score.length > 0 &&
        carbonFootPrintState.portfolioESGScore.data.coverage.length > 0 &&
        carbonFootPrintState.portfolioESGScore.data.distribution.length > 0) ||
      (carbonFootPrintState.portfolioESGScore.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    carbonFootPrintDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_ESGSCORE
    })
    let ESGScore = { score: [], distribution: [], coverage: {} }
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter
    try {
      if (isFilterStateAvailable) {
        const [score, distribution, coverage] = await axios.all([
          ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: CAR_FOOT_PRNT.PAGE_URL,
            token: carbonFootPrintState.cancelToken.token,
            endPoint: COMMON.SCORE
          }),
          ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: CAR_FOOT_PRNT.PAGE_URL,
            token: carbonFootPrintState.cancelToken.token,
            endPoint: COMMON.DISTRIBUTION
          }),
          ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: CAR_FOOT_PRNT.PAGE_URL,
            token: carbonFootPrintState.cancelToken.token,
            endPoint: COMMON.COVERAGE
          })
        ])

        ESGScore.score = Object.keys(score.data).length > 0 ? score.data[0] : []
        ESGScore.distribution =
          distribution.data.length > 0 ? distribution.data[0] : []
        ESGScore.coverage = coverage.data.length > 0 ? coverage.data[0] : []
      }
      carbonFootPrintDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_ESGSCORE,
        data: ESGScore,
        apiCallId
      })

      carbonFootPrintDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_SCORE,
        data: ESGScore.score,
        apiCallId
      })
      carbonFootPrintDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_DISTRIBUTION,
        data: ESGScore.distribution,
        apiCallId
      })
      carbonFootPrintDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_COVERAGE,
        data: ESGScore.coverage,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        carbonFootPrintDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_ESGSCORE,
          apiCallId
        })
      } else console.log(err)
    }
  }

  const reloadComponent = (errorOccuredOn) => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions

    switch (errorOccuredOn) {
      case PAGE_CON.PORTFOLIO_ESGSCORE_TITLE:
      case PAGE_CON.BENCHMARK_ESG_TITLE:
        getESGScoreData(sectors, regions, true)
        break
      case PAGE_CON.PORTFOLIO_HISTORY_TITLE:
        getHistoryData(sectors, regions, true)
        break
      default:
        break
    }
  }

  const getCarbonFootPrintData = useCallback(async () => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    if (filters.portfolio !== '' && !filterState.isLoading) {
      getESGScoreData(sectors, regions)
      getHistoryData(sectors, regions)
    }
  }, [filterState.actionData])

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      getCarbonFootPrintData()
    }
    const setResponsiveness = () => {
      return window.innerWidth < 1050
        ? setState((prevState) => ({ ...prevState, smallResize: true }))
        : setState((prevState) => ({ ...prevState, smallResize: false }))
    }
    setResponsiveness()

    window.addEventListener('resize', setResponsiveness, true)
    return () => window.removeEventListener('resize', setResponsiveness, true)
  }, [filterState.actionData])

  const openAboutLinkOpen = () => {
    let aboutPDF =
      process.env.PUBLIC_URL +
      '/assets/Methodology_Climate_Climate Risk Assessment.pdf'
    window.open(aboutPDF, '_blank')
  }

  const colorLabel = () => {
    let legendData = []

    if (
      carbonFootPrintState.portfolioESGScore.dataStatus === PAGE_CON.SUCCESS
    ) {
      let distributionData = []
      if (
        (carbonFootPrintState?.portfolioESGScore?.data?.distribution
          ?.portfolio_distribution?.length || 0) > 0
      )
        distributionData =
          carbonFootPrintState.portfolioESGScore.data.distribution
            .portfolio_distribution
      else if (
        (carbonFootPrintState?.portfolioESGScore?.data?.distribution
          ?.benchmark_distribution?.length || 0) > 0
      )
        distributionData =
          carbonFootPrintState.portfolioESGScore.data.distribution
            .benchmark_distribution
      else if (
        (carbonFootPrintState.portfolioHistory?.data?.portfolio?.yearlydata
          ?.length || 0) > 0
      ) {
        distributionData =
          carbonFootPrintState.portfolioHistory.data.portfolio.categorydata
      }

      distributionData.length > 0 &&
        distributionData.map((Cdata) => {
          return Cdata.category
            ? legendData.push(Cdata.category)
            : Cdata.name
            ? legendData.push(Cdata.name)
            : ''
        })
    }

    return legendData
  }

  const isDistributionHasData = () => {
    let hasData = false
    if (
      (carbonFootPrintState?.portfolioESGScore?.data?.distribution
        ?.portfolio_distribution?.length || 0) > 0
    )
      hasData = true
    else if (
      (carbonFootPrintState?.portfolioESGScore?.data?.distribution
        ?.benchmark_distribution?.length || 0) > 0
    )
      hasData = true
    else if (
      (carbonFootPrintState.portfolioHistory?.data?.portfolio?.yearlydata
        .length || 0) > 0
    )
      hasData = true
    return hasData
  }

  let colorGroup =
    portfolioDistributionColumns[0].DataField[0].categoryColor.colorGroup
  let legendColor = colorLabel()
  //eslint-disable-next-line no-unused-vars
  const scoreCardESGData = (score, coverage, id) => {
    let tmp,
      tmp_two = {}

    tmp = score?.find((item) => item.name === 'Carbon Footprint')
    tmp_two = score?.find((item) => item.name === 'Carbon Intensity')

    coverage = coverage ? coverage : {}
    coverage.investment = coverage.investment ? coverage.investment : 0

    coverage.investment =
      Number(coverage.investment) >= 1
        ? `${coverage.investment}`
        : Number(coverage.investment) === 0
        ? `${coverage.investment}`
        : '<1'

    let comp = [
      <CardInfoSection
        key={'crbn-rsk-test-id1'}
        id={'crbn-rsk-test-id-from-overview-'}
        contentInfo={categoryInfo}
        data={tmp}
        singleData={true}
        outerBackgroundColor={outerColor}
      />,
      <CardInfoSection
        key={'crbn-rsk-test-id2'}
        id={'crbn-rsk-test-id-from-overview-'}
        contentInfo={scoreInfoFootPrint}
        data={tmp}
        scoreTable={true}
        outerBackgroundColor={outerColor}
      />,
      <CardInfoSection
        key={'crbn-rsk-test-id3'}
        id={'crbn-rsk-test-id-from-overview-'}
        contentInfo={scoreInfoIntensity}
        data={tmp_two}
        carbonBrownGreen={true}
        outerBackgroundColor={outerColor}
      />,
      <CardInfoSection
        key={'crbn-rsk-test-id4'}
        id={'crbn-rsk-test-id-from-overview-'}
        contentInfo={cardInfoContentInfo}
        data={coverage}
        scoreTable={true}
        minWidthCoverage={255}
        outerBackgroundColor={outerColor}
      />
    ]
    return comp
  }

  const isPortfolioHistoryHasData = () => {
    let hasData = false

    if (
      carbonFootPrintState.portfolioHistory.data?.portfolio?.categorydata
        ?.length > 0 &&
      carbonFootPrintState.portfolioHistory.data?.portfolio?.yearlydata
        ?.length > 0
    )
      hasData = true
    return hasData
  }

  const isBenchmarkHistoryHasData = () => {
    let hasData = false

    if (
      carbonFootPrintState.portfolioHistory.data?.benchmark?.categorydata
        ?.length > 0 &&
      carbonFootPrintState.portfolioHistory.data?.benchmark?.yearlydata
        ?.length > 0
    )
      hasData = true
    return hasData
  }

  const showHistoryChart = (isBenchmark = false) => {
    let showHistoryChart = false

    const checkYears = isBenchmark
      ? historyData.YearsData.length > 1 &&
        historyData.BenchmarkYearsData.length > 1
      : historyData.YearsData.length > 1

    if (
      carbonFootPrintState.portfolioHistory.isLoading ||
      carbonFootPrintState.portfolioHistory.dataStatus === PAGE_CON.ERROR ||
      (carbonFootPrintState.portfolioHistory.dataStatus === PAGE_CON.SUCCESS &&
        checkYears)
    )
      showHistoryChart = true
    return showHistoryChart
  }

  const historyData = React.useMemo(() => {
    let colors = []
    let benchmarkColors = []

    carbonFootPrintState.portfolioHistory.data?.portfolio?.categorydata?.map(
      (item) => {
        if (item.name === 'N/A')
          colors.push(themeColorPalette.palette.sys_trad.border.main)
        else colors.push(getColor('CATEGORY', item.name))
        return colors
      }
    )

    carbonFootPrintState.portfolioHistory.data?.benchmark?.categorydata?.map(
      (item) => {
        if (item.name === 'N/A')
          benchmarkColors.push(themeColorPalette.palette.sys_trad.border.main)
        else benchmarkColors.push(getColor('CATEGORY', item.name))
        return benchmarkColors
      }
    )

    const newStackedBarData =
      carbonFootPrintState?.portfolioHistory?.data?.portfolio?.categorydata
        ?.length > 0 &&
      sortPortfolioHistoryChart(
        carbonFootPrintState.portfolioHistory.data.portfolio.categorydata
      )

    const newBenchmarkStackedBarData =
      carbonFootPrintState?.portfolioHistory?.data?.benchmark?.categorydata
        ?.length > 0 &&
      sortPortfolioHistoryChart(
        carbonFootPrintState.portfolioHistory.data.benchmark.categorydata
      )
    return {
      StackedBarData: newStackedBarData || [],
      YearsData:
        carbonFootPrintState.portfolioHistory.data?.portfolio?.yearlydata || [],
      BarColors: colors,
      BenchmarkStackedBarData: newBenchmarkStackedBarData || [],
      BenchmarkYearsData:
        carbonFootPrintState.portfolioHistory.data?.benchmark?.yearlydata || [],
      BenchmarkBarColors: benchmarkColors
    }
  }, [carbonFootPrintState.portfolioHistory.dataStatus])

  const isYearData =
    historyData.YearsData.length > 1 ||
    (historyData.YearsData.length > 1 &&
      historyData.BenchmarkYearsData.length > 1)
  const noData =
    historyData.YearsData.length === 0 &&
    historyData.BenchmarkYearsData.length === 0
  const isDescription =
    (noData && !smallResize) ||
    ((historyData.YearsData.length === 1 ||
      historyData.BenchmarkYearsData.length === 1) &&
      !smallResize) ||
    (historyData.YearsData.length === 0 &&
      historyData.BenchmarkYearsData.length >= 1 &&
      !smallResize)

  return (
    <div className={classes.root}>
      <Grid
        id={props.id}
        container
        direction={'column'}
        spacing={2}
        className={classes.paper}
      >
        <Grid
          id="portfolio_4_box"
          container
          item
          direction={'row'}
          spacing={3}
          className={classes.portfolioGrid}
        >
          <span className={classes.verticalHeader}> Portfolio </span>
          <Grid
            item
            id="cardInfo_box"
            sm={1}
            md={3}
            className={classes.cardInfoGrid}
          >
            {carbonFootPrintState.portfolioESGScore.isLoading ? (
              <LoadingMask />
            ) : carbonFootPrintState.portfolioESGScore.dataStatus ===
                PAGE_CON.ERROR ||
              (carbonFootPrintState.portfolioESGScore.dataStatus ===
                PAGE_CON.SUCCESS &&
                carbonFootPrintState.portfolioESGScore.data &&
                carbonFootPrintState.portfolioESGScore.data.score &&
                carbonFootPrintState.portfolioESGScore.data.score
                  .portfolio_score &&
                carbonFootPrintState.portfolioESGScore.data.score
                  .portfolio_score.length === 0) ||
              (carbonFootPrintState.portfolioESGScore.dataStatus ===
                PAGE_CON.SUCCESS &&
                carbonFootPrintState.portfolioESGScore.data.coverage &&
                carbonFootPrintState.portfolioESGScore.data.coverage.length ===
                  0) ||
              !isFilterStateAvailable ? (
              <Error
                id={'distributionError'}
                dataStatus={carbonFootPrintState.portfolioESGScore.dataStatus}
                onErrorClick={reloadComponent}
                errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
              />
            ) : (
              <Card
                components={scoreCardESGData(
                  carbonFootPrintState.portfolioESGScore.data.score
                    .portfolio_score,
                  carbonFootPrintState.portfolioESGScore.data.coverage
                    .portfolio_coverage,
                  'PortfolioScoreESG-test-id'
                )}
                id="PortfolioScoreESG-test-id"
                selectionType="none"
                key="PortfolioScoreESG-test-id"
              ></Card>
            )}
          </Grid>
          {carbonFootPrintState.portfolioHistory.isLoading ||
          showHistoryChart() ? (
            <Grid
              item
              id="history_box_portfolio"
              className={classes.historyGrid}
            >
              <div className={classes.historyBoxFiller}>
                <span className={classes.historyLabel}>
                  {PAGE_CON.PORTFOLIO_HISTORY_TABLE_TITLE}
                </span>
                {carbonFootPrintState.portfolioHistory.isLoading ? (
                  <LoadingMask />
                ) : carbonFootPrintState.portfolioHistory.dataStatus ===
                    PAGE_CON.ERROR ||
                  (carbonFootPrintState.portfolioHistory.dataStatus ===
                    PAGE_CON.SUCCESS &&
                    !isPortfolioHistoryHasData()) ? (
                  <Error
                    id={'historyError'}
                    dataStatus={
                      isFilterStateAvailable
                        ? carbonFootPrintState.portfolioHistory.dataStatus
                        : PAGE_CON.SUCCESS
                    }
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.PORTFOLIO_HISTORY_TITLE}
                  />
                ) : (
                  <div>
                    <StackedVert
                      data={historyData.StackedBarData}
                      xAxisTitles={historyData.YearsData}
                      barColors={historyData.BarColors}
                      chartWidth={270}
                      tooltipEnabled={true}
                    />
                  </div>
                )}
              </div>
            </Grid>
          ) : null}
          <Grid
            item
            id="distribution_box"
            sm={3}
            md={3}
            className={classes.distributionGrid}
          >
            <div style={overViewTextSubtitleBenchmark}>
              {' '}
              {filterState.globalPortfolioFilter.asOfDate.substring(0, 4)}{' '}
              {PAGE_CON.PORTFOLIO_DISTRIBUTION_TITLE}
            </div>
            {carbonFootPrintState.portfolioESGScore.isLoading ? (
              <LoadingMask />
            ) : carbonFootPrintState.portfolioESGScore.dataStatus ===
                PAGE_CON.ERROR ||
              (carbonFootPrintState.portfolioESGScore.dataStatus ===
                PAGE_CON.SUCCESS &&
                (carbonFootPrintState.portfolioESGScore.data.distribution
                  ?.portfolio_distribution?.length || 0) === 0) ? (
              <Error
                id={'distributionError'}
                dataStatus={
                  isFilterStateAvailable
                    ? carbonFootPrintState.portfolioESGScore.dataStatus
                    : PAGE_CON.SUCCESS
                }
                onErrorClick={reloadComponent}
                errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
              />
            ) : (
              <MinimalTable
                id="physical-risk-management-overview-portfolio-distribution-minimaltable-test-id"
                columns={portfolioDistributionColumns}
                headerBgColor={scoreHeader}
                data={
                  carbonFootPrintState.portfolioESGScore.data.distribution
                    .portfolio_distribution
                }
                hideBenchmark={true}
                ESCScore={true}
              ></MinimalTable>
            )}
          </Grid>

          <Grid
            item
            id="legend_summary_link_box"
            sm={smallResize ? 7 : 5}
            className={classes.portfolioInfoBoxStyle}
            style={{ paddingLeft: smallResize ? '12px' : '0' }}
          >
            {((historyData.YearsData.length === 1 ||
              historyData.BenchmarkYearsData.length === 1) &&
              !smallResize) ||
            (historyData.YearsData.length === 0 &&
              historyData.BenchmarkYearsData.length > 1 &&
              !smallResize) ? (
              <div id="legend_box" className={classes.legendStyle}>
                {carbonFootPrintState.portfolioESGScore.isLoading ? (
                  <LoadingMask />
                ) : carbonFootPrintState.portfolioESGScore.dataStatus ===
                    PAGE_CON.ERROR ||
                  (carbonFootPrintState.portfolioESGScore.dataStatus ===
                    PAGE_CON.SUCCESS &&
                    !isDistributionHasData()) ? (
                  <Error
                    id={'distributionError'}
                    dataStatus={
                      isFilterStateAvailable
                        ? carbonFootPrintState.portfolioESGScore.dataStatus
                        : PAGE_CON.SUCCESS
                    }
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
                  />
                ) : (
                  <Legend
                    colorOptions={legendColor}
                    colorGroup={colorGroup}
                    legendTitle={props.pageTitle + ' Score:'}
                    boldTitle={true}
                  />
                )}
              </div>
            ) : null}

            {isDescription ? (
              <>
                <div
                  id="summary_box"
                  className={classes.grid}
                  style={overViewText}
                >
                  {OverViewText.OverViewText.carbonFootprint}
                  <br></br>
                  <br></br>
                  {OverViewText.OverViewText.additionalNote}
                </div>

                <div id="link_box" className={classes.linkStyle}>
                  <PrimaryLink
                    id="link-link-test-id-3"
                    linkText={`Read more about ${props.pageTitle} assessment methodology`}
                    onClick={openAboutLinkOpen}
                    linkClass="linkInline"
                    linkColorChange={true}
                  />
                </div>
              </>
            ) : null}
          </Grid>
        </Grid>
        {props.showBenchMark ? (
          <Grid
            id="benchmark_4_box"
            container
            item
            direction={'row'}
            spacing={3}
            className={classes.benchmarkGrid}
          >
            <span className={classes.verticalHeader}> Benchmark </span>
            <Grid
              item
              id="cardInfo_box"
              sm={1}
              md={3}
              className={classes.cardInfoGrid}
            >
              {carbonFootPrintState.portfolioESGScore.isLoading ? (
                <LoadingMask />
              ) : carbonFootPrintState.portfolioESGScore.dataStatus ===
                  PAGE_CON.ERROR ||
                (carbonFootPrintState.portfolioESGScore.dataStatus ===
                  PAGE_CON.SUCCESS &&
                  carbonFootPrintState.portfolioESGScore.data &&
                  carbonFootPrintState.portfolioESGScore.data.score &&
                  carbonFootPrintState.portfolioESGScore.data.score
                    .benchmark_score &&
                  carbonFootPrintState.portfolioESGScore.data.score
                    .benchmark_score.length === 0) ||
                (carbonFootPrintState.portfolioESGScore.dataStatus ===
                  PAGE_CON.SUCCESS &&
                  carbonFootPrintState.portfolioESGScore.data.coverage &&
                  carbonFootPrintState.portfolioESGScore.data.coverage
                    .length === 0) ||
                !isFilterStateAvailable ? (
                <Error
                  id={'distributionError'}
                  dataStatus={
                    isFilterStateAvailable
                      ? carbonFootPrintState.portfolioESGScore.dataStatus
                      : PAGE_CON.SUCCESS
                  }
                  onErrorClick={reloadComponent}
                  errorOccuredOn={PAGE_CON.BENCHMARK_ESG_TITLE}
                />
              ) : (
                <Card
                  components={scoreCardESGData(
                    carbonFootPrintState.portfolioESGScore.data.score
                      .benchmark_score,
                    carbonFootPrintState.portfolioESGScore.data.coverage
                      .benchmark_coverage,
                    'BenchmarkScoreESG-test-id'
                  )}
                  id="BenchmarkScoreESG-test-id"
                  selectionType="none"
                  key="BenchmarkScoreESG-test-id"
                ></Card>
              )}
            </Grid>
            {carbonFootPrintState.portfolioHistory.isLoading ||
            showHistoryChart(true) ? (
              <Grid
                item
                id="history_box_benchmark"
                className={classes.historyGrid}
              >
                <div className={classes.historyBoxFiller}>
                  <span className={classes.historyLabel}>
                    {PAGE_CON.BENCHMARK_HISTORY_TABLE_TITLE}
                  </span>
                  {carbonFootPrintState.portfolioHistory.isLoading ? (
                    <LoadingMask />
                  ) : carbonFootPrintState.portfolioHistory.dataStatus ===
                      PAGE_CON.ERROR ||
                    (carbonFootPrintState.portfolioHistory.dataStatus ===
                      PAGE_CON.SUCCESS &&
                      !isBenchmarkHistoryHasData()) ? (
                    <Error
                      id={'benchmarkHistoryError'}
                      dataStatus={
                        isFilterStateAvailable
                          ? carbonFootPrintState.portfolioHistory.dataStatus
                          : PAGE_CON.SUCCESS
                      }
                      onErrorClick={reloadComponent}
                      errorOccuredOn={PAGE_CON.PORTFOLIO_HISTORY_TITLE}
                    />
                  ) : (
                    <div>
                      <StackedVert
                        data={historyData.BenchmarkStackedBarData}
                        xAxisTitles={historyData.BenchmarkYearsData}
                        barColors={historyData.BenchmarkBarColors}
                        chartWidth={270}
                        tooltipEnabled={true}
                      />
                    </div>
                  )}
                </div>
              </Grid>
            ) : null}
            <Grid
              item
              id="distribution_box"
              sm={3}
              md={3}
              className={classes.benchmarkBistributionGrid}
            >
              <div style={overViewTextSubtitleBenchmark}>
                {' '}
                {filterState.globalPortfolioFilter.asOfDate.substring(
                  0,
                  4
                )}{' '}
                {PAGE_CON.BENCHMARK_DISTRIBUTION_TITLE}
              </div>
              {carbonFootPrintState.portfolioESGScore.isLoading ? (
                <LoadingMask />
              ) : carbonFootPrintState.portfolioESGScore.dataStatus ===
                  PAGE_CON.ERROR ||
                (carbonFootPrintState.portfolioESGScore.dataStatus ===
                  PAGE_CON.SUCCESS &&
                  (carbonFootPrintState.portfolioESGScore.data.distribution
                    ?.benchmark_distribution?.length || 0) === 0) ? (
                <Error
                  id={'distributionError'}
                  dataStatus={
                    isFilterStateAvailable
                      ? carbonFootPrintState.portfolioESGScore.dataStatus
                      : PAGE_CON.SUCCESS
                  }
                  onErrorClick={reloadComponent}
                  errorOccuredOn={PAGE_CON.BENCHMARK_ESG_TITLE}
                />
              ) : (
                <MinimalTable
                  id="physical-risk-management-overview-portfolio-distribution-minimaltable-test-id"
                  columns={portfolioDistributionColumns}
                  headerBgColor={scoreHeader}
                  data={
                    carbonFootPrintState.portfolioESGScore.data.distribution
                      .benchmark_distribution
                  }
                  hideBenchmark={true}
                  ESCScore={true}
                ></MinimalTable>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            id="benchmark_box"
            className={classes.benchmarkBoxStyle}
            md={6}
            lg={12}
          >
            <Box className={classes.benchmarkTextStyle}>
              No benchmark selected. Please select a benchmark
            </Box>
          </Grid>
        )}

        <Grid
          item
          id="legend_summary_link_box"
          sm={smallResize ? 6 : 5}
          className={classes.benchmarkInfoBoxStyle}
        >
          {(smallResize &&
            (historyData.YearsData.length === 1 ||
              historyData.BenchmarkYearsData.length === 1)) ||
          isYearData ? (
            <div id="legend_box" className={classes.legendStyle}>
              {carbonFootPrintState.portfolioESGScore.isLoading ? (
                <LoadingMask />
              ) : carbonFootPrintState.portfolioESGScore.dataStatus ===
                  PAGE_CON.ERROR ||
                (carbonFootPrintState.portfolioESGScore.dataStatus ===
                  PAGE_CON.SUCCESS &&
                  !isDistributionHasData()) ? (
                <Error
                  id={'distributionError'}
                  dataStatus={
                    isFilterStateAvailable
                      ? carbonFootPrintState.portfolioESGScore.dataStatus
                      : PAGE_CON.SUCCESS
                  }
                  onErrorClick={reloadComponent}
                  errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
                />
              ) : (
                <Legend
                  colorOptions={legendColor}
                  colorGroup={colorGroup}
                  legendTitle={props.pageTitle + ' Score:'}
                  boldTitle={true}
                />
              )}
            </div>
          ) : null}

          {(smallResize && noData) ||
          (smallResize &&
            (historyData.YearsData.length === 1 ||
              historyData.BenchmarkYearsData.length === 1)) ||
          isYearData ? (
            <>
              <div
                id="summary_box"
                className={classes.grid}
                style={overViewText}
              >
                {OverViewText.OverViewText.carbonFootprint}
                <br></br>
                <br></br>
                {OverViewText.OverViewText.additionalNote}
              </div>

              <div id="link_box" className={classes.linkStyle}>
                <PrimaryLink
                  id="link-link-test-id-3"
                  linkText={`Read more about ${props.pageTitle} assessment methodology`}
                  onClick={openAboutLinkOpen}
                  linkClass="linkInline"
                  linkColorChange={true}
                />
              </div>
            </>
          ) : null}
        </Grid>
      </Grid>
    </div>
  )
}

export default CarbonFootPrintOverview
