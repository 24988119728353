import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  batchCardContainer: {
    '&.MuiGrid-item': {
      padding: '10px 4px 12px'
    }
  },
  batchCardSubHeader: {
    ...textTheme.typography.p3,
    whiteSpace: 'nowrap',
    padding: '0 6px',
    border: 'none',
    borderRight: `1px solid ${themeColorPalette.palette.sys_trad.border.main}`,
    '&:last-child': {
      borderRight: 'none'
    },
    '&:first-child': {
      paddingLeft: 0
    }
  },
  batchCardTextStrong: {
    ...textTheme.typography.p2
  },
  batchCardPendingActivation: {
    backgroundColor: themeColorPalette.palette.genrang_b.five.main
  },
  batchCardCancelled: {
    backgroundColor: '#F77F74'
  },
  batchCardInProgress: {
    backgroundColor: themeColorPalette.palette.sys_trad.focus.main
  },
  batchCardCompleted: {
    backgroundColor: themeColorPalette.palette.sys_trad.successlight.main
  },
  batchCardBoxSize: {
    padding: '0px 7px',
    marginLeft: 12,
    marginRight: 4
  },
  batchCardProgressBar: {
    display: 'flex',
    width: '400px',
    flexBasis: 'auto'
  },
  batchCardLowSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '0px 10px 5px 10px',
    marginTop: '-3px'
  },
  batchCardDateStyle: {
    ...textTheme.typography.p3,
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    bottom: '25px',
    right: '12px',
    height: '0px'
  },
  batchCardAssessmentInfo: {
    left: '10px',
    bottom: '8px',
    position: 'relative'
  },
  batchCardChicletAssessment: {
    marginBottom: 5,
    marginLeft: -12,
    width: '100%'
  },
  batchReviewCard: {
    padding: '10px',
    '& .MuiCardHeader-content': {
      color: themeColorPalette.palette.sys_trad.text.main,
      ...textTheme.typography.p2,
      padding: '2px 0px 9px 0px'
    },
    '& .MuiCard-root': {
      padding: '8px 13px 10px 13px'
    },
    '& .MuiCardActionArea-root': {
      padding: 0
    }
  },
  batchReviewTitle: {
    ...textTheme.typography.h1,
    fontSize: '20px',
    margin: '32px 0 4px 0',
    display: 'inline-block'
  },
  batchReviewSubHeader: {
    ...textTheme.typography.h1,
    fontSize: '14px',
    marginBottom: '5px',
    display: 'block'
  },
  criteriaFilterViewRoot: {
    backgroundColor: themeColorPalette.palette.background.dark,
    minHeight: '100%',
    paddingBottom: themeColorPalette.spacing(3),
    flexGrow: 1
  },
  criteriaFilterViewRemainingReq: {
    ...textTheme.typography.p3,
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    borderRadius: '2px',
    padding: '12px',
    margin: '36px 0 24px 2px'
  },
  criteriaFilterViewHeaderGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiCardHeader-content': {
      '&:hover': {
        cursor: 'default'
      }
    },
    '& .MuiCard-root': {
      border: '1px solid' + themeColorPalette.palette.white.main
    }
  },
  criteriaFilterViewPageHeader: {
    ...textTheme.typography.h1,
    fontSize: '20px'
  },
  criteriaFilterViewSubHeader: {
    ...textTheme.typography.h2
  },
  criteriaFilterViewButtonContainer: {
    textAlign: 'right'
  },
  criteriaFilterViewInfoTextStyle: {
    ...textTheme.typography.p3,
    color: themeColorPalette.palette.sys_trad.text2.main,
    margin: '24px 0'
  },
  criteriaFilterViewHRStyle: {
    border: `1px solid ${themeColorPalette.palette.sys_trad.border.main}`,
    margin: '8px 2px',
    backgroundColor: themeColorPalette.palette.sys_trad.border.main
  },
  ODAModalToolbarStyle: {
    borderBottom: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
    minHeight: '48px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  ODAModalCloseStyle: {
    display: 'flex',
    cursor: 'pointer',
    marginRight: '14px',
    marginTop: '8px'
  },
  ODAModalTitle: {
    marginLeft: 8,
    paddingLeft: 8,
    ...textTheme.typography.p3,
    fontSize: '13px !important',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  ODAModalEscapeStyle: {
    fontSize: '13px !important',
    paddingRight: 7.5,
    ...textTheme.typography.p3,
    color: themeColorPalette.palette.sys_trad.main.main
  },
  ODAModalRequestTitle: {
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    marginLeft: 8,
    paddingLeft: 8,
    cursor: 'auto',
    '&.MuiListItem-button': {
      fontSize: '13px !important'
    },
    ...textTheme.typography.h2,
    fontSize: 16,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  ODAModalFilterList: {
    display: 'flex',
    ...textTheme.typography.p3,
    marginTop: 5,
    marginLeft: -5
  },
  ODAModalFilterItem: {
    listStyle: 'none',
    borderRight: `1px solid ${themeColorPalette.palette.sys_trad.border.main}`,
    padding: '0 4px',
    '&:last-child': {
      border: 'none'
    }
  },
  p2Typography: {
    ...textTheme.typography.p2
  },
  ReqDetailAlert: {
    ...themeColorPalette.typography.p1,
    background: '#f4f9ff', // Color doesn't exist in styleguide
    border: '1px solid #8ec3ff', // Color doesn't exist in styleguide
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 2,
    height: 48,
    marginTop: 16
  },
  ReqDetailInfoIcon: {
    width: 16,
    marginRight: 8
  },
  ReqDetailTableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30
  },
  ReqDetailLabel: {
    ...themeColorPalette.typography.p2,
    color: themeColorPalette.palette.sys_trad.texthelp.main,
    paddingRight: 8
  },
  ReqDetailTable: {
    borderTop: '1px solid',
    borderColor: themeColorPalette.palette.sys_trad.texthelp.main,
    marginTop: 24,
    minHeight: 160,
    '& td': {
      borderBottomColor: themeColorPalette.palette.sys_trad.texthelp.main
    },
    '& table': {
      marginBottom: 36
    }
  },
  ReqDetailRemove: {
    ...themeColorPalette.typography.p2,
    color: themeColorPalette.palette.sys_trad.texthelp.main,
    cursor: 'pointer'
  },
  ReqDetailEmail: {
    width: '100%'
  },
  ReqDetailEmailInput: {
    ...themeColorPalette.typography.p3,
    marginTop: 4,
    width: '100%',
    '& .MuiInputBase-input::placeholder': {
      ...themeColorPalette.typography.p3,
      color: themeColorPalette.palette.sys_trad.error.main,
      opacity: 1
    }
  },
  ReqDetailEmailError: {
    '& .MuiInputBase-root': {
      color: themeColorPalette.palette.sys_trad.error.main
    }
  },
  ReqDetailLoadMoreContainer: {
    textAlign: 'center'
  },
  ReqDetailLoadMore: {
    ...themeColorPalette.typography.p3,
    textDecoration: 'underline',
    marginBottom: 36,
    cursor: 'pointer',
    display: 'inline-block'
  },
  ReqDetailModal: {
    '& .MuiPaper-root': {
      position: 'absolute',
      top: 110,
      left: '50%',
      transform: 'translate(-50%, 0)',
      background: themeColorPalette.palette.sys_trad.white.main,
      boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.14)',
      width: 384,
      height: 323,
      borderRadius: 2,
      margin: 0
    },
    '& .MuiDialogContent-root:first-child': {
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'space-between',
      flexDirection: 'column',
      padding: 24
    }
  },
  ReqDetailModalTitle: {
    ...themeColorPalette.typography.h1,
    fontSize: 24,
    marginBottom: 16
  },
  p3Typography: {
    ...themeColorPalette.typography.p3
  },
  ReqDetailModalButtons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  sectorSizeCriteriaInfoTextStyle: {
    ...textTheme.typography.p3,
    color: themeColorPalette.palette.sys_trad.text2.main,
    margin: '24px 0'
  },
  sectorSizeCriteriaHRStyle: {
    border: `1px solid ${themeColorPalette.palette.sys_trad.border.main}`,
    margin: '8px 0px',
    backgroundColor: themeColorPalette.palette.sys_trad.border.main
  },
  divStyleError: {
    overflow: 'auto',
    overflowX: 'hidden',
    maxHeight: '250px'
  },
  boxStyleError: {
    width: '270px',
    marginLeft: '24px',
    paddingTop: '15px',
    paddingBottom: '15px',
    display: 'table',
    borderRadius: '2px',
    verticalAlign: 'middle',
    border: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    boxSizing: 'borderBox',
    ...textTheme.typography.p1,
    color: themeColorPalette.palette.sys_trad.text.main,
    textAlign: 'center',
    lineHeight: '16px'
  },
  errorMessage: {
    color: themeColorPalette.palette.sys_trad.error.main
  }
}))
