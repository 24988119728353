import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconNegativeTrend = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 13C8.22386 13 8 13.2239 8 13.5C8 13.7761 8.22386 14 8.5 14H13.5C13.7761 14 14 13.7761 14 13.5V8.5C14 8.22386 13.7761 8 13.5 8C13.2239 8 13 8.22386 13 8.5V12.2929L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L12.2929 13H8.5Z"
      fill="#B31717"
    />
  </SvgIcon>
)
IconNegativeTrend.displayName = 'IconNegativeTrend'
IconNegativeTrend.muiName = 'SvgIcon'

export default IconNegativeTrend
