import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import themeColorPalette from '../../theme/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.main.main
  }
}))

const SomeContainer = (props) => {
  const classes = useStyles(props)

  return (
    <div className={classes.root}>
      <Grid id={props.id} container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>Coming Soon</Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default SomeContainer
