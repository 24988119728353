import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'
import themeColorPalette from 'theme/theme'

export const useStyles = () =>
  makeStyles(() => ({
    container: {
      height: (props) => (props.isEntityListHeatmap ? '345px' : '582px'),
      overflowY: 'auto',
      padding: '0 4px 4px 4px'
    },
    listItem: {
      ...theme.typography.p5,
      display: 'flex',
      height: '48px',
      background: '#FFFFFF',
      boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
      borderRadius: '2px',
      margin: '0 0 4px 0',
      padding: '8px',
      justifyContent: 'space-between',
      '&.clickable': {
        cursor: 'pointer'
      },
      '&>div': {
        flex: 1,
        maxWidth: '50%',
        color: theme.palette.sys_trad.text.main
      },
      '& h3': {
        color: theme.palette.sys_trad.text.main,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      },
      '& h4': {
        ...theme.typography.p4
      }
    },
    predictedScore: {
      backgroundColor:
        themeColorPalette.palette.proxy.proxy.main + ' !important',
      pointerEvents: 'none',
      '&>div > h3 > span > span > span': {
        textDecoration: 'none !important'
      }
    }
  }))

export const Container = ({ children, isEntityListHeatmap = false }) => {
  const classes = useStyles()({ isEntityListHeatmap })
  return <div className={classes.container}>{children}</div>
}

export const EntityContainer = ({ children }) => {
  const classes = useStyles()()
  return <div className={classes.entityContainer}>{children}</div>
}

export const ListItem = ({
  children,
  onClick,
  clickable = false,
  className = ''
}) => {
  const classes = useStyles()()
  return (
    <div
      className={`${classes.listItem} ${
        clickable ? 'clickable' : ''
      } ${className}`}
      onClick={onClick}
      aria-hidden={true}
    >
      {children}
    </div>
  )
}
