export const REDUCER_TYPE = {
  BENCHMARK_CHANGED: 'BENCHMARK_CHANGED',
  LOAD_DATA_START: 'LOAD_DATA_START',
  LOAD_DATA_ERROR: 'LOAD_DATA_ERROR',
  LOAD_DATA_END: 'LOAD_DATA_END',
  LOAD_DATA_COMPLETED: 'LOAD_DATA_COMPLETED',
  LOAD_DATA_COMPLETE_ERROR: 'LOAD_DATA_COMPLETE_ERROR',
  UPDATED_ACTION: 'UPDATED_ACTION',
  UPDATE_BENCHMARK: 'UPDATE_BENCHMARK',
  UPDATE_FILTER: 'UPDATE_FILTER',
  UPDATE_PORTFOLIO: 'UPDATE_PORTFOLIO',
  UPDATE_PORTFOLIO_COMPLETED: 'UPDATE_PORTFOLIO_COMPLETED',
  UPLOAD_PORTFOLIO_MODAL: 'UPLOAD_PORTFOLIO_MODAL',
  SCORE_QUALITY_LEVEL: 'SCORE_QUALITY_LEVEL',
  RESET: 'RESET',
  USER_SETTING_RESET: 'USER_SETTING_RESET',
  RESET_ALL: 'RESET_ALL',
  RESET_UPDATE_PORTFOLIO_FROM_COMPONENT:
    'RESET_UPDATE_PORTFOLIO_FROM_COMPONENT',
  RESET_LOAD_DATA_ERROR: 'RESET_LOAD_DATA_ERROR',
  PORTFOLIO_REVIEW_PAGE: 'PORTFOLIO_REVIEW_PAGE',
  DOWNLOAD_PORTFOLIO: 'DOWNLOAD_PORTFOLIO',
  UPDATE_IMPACT_FILTER: 'UPDATE_IMPACT_FILTER',
  SET_ORBIB_ID: 'SET_ORBIB_ID',
  UPDATE_URL: 'UPDATE_URL',
  UPDATE_URL_INFO: 'UPDATE_URL_INFO',
  DELETE_URL: 'DELETE_URL',
  OPEN_BACKDROP: 'OPEN_BACKDROP',
  CLOSE_BACKDROP: 'CLOSE_BACKDROP',
  PORTFOLIO_MANAGEMENT: 'PORTFOLIO_MANAGEMENT',
  USER_SETTING_MANAGEMENT: 'USER_SETTING_MANAGEMENT',
  USER_SETTING_CHANGED: 'USER_SETTING_CHANGED',
  RESET_PORTFOLIO_ENTITY: 'RESET_PORTFOLIO_ENTITY',
  SET_PORTFOLIO: 'SET_PORTFOLIO',
  DELETE_MODAL: 'DELETE_MODAL',
  ENTITY_DOWNLOAD_START: 'ENTITY_DOWNLOAD_START',
  ENTITY_DOWNLOAD_COMPLETE: 'ENTITY_DOWNLOAD_COMPLETE',
  RESET_REPORTING_SERVICE: 'RESET_REPORTING_SERVICE',
  RESET_ODA_REPORTING_SERVICE: 'RESET_ODA_REPORTING_SERVICE',
  RESET_REPORTING_SERVICE_SECTION: 'RESET_REPORTING_SERVICE_SECTION',
  UPDATE_REPORTING_PORTFOLIOS: 'UPDATE_REPORTING_PORTFOLIOS',
  RESET_GENERATE_REPORT: 'RESET_GENERATE_REPORT',
  ON_DEMAND_ASMNT_MODAL: 'ON_DEMAND_ASMNT_MODAL',
  ON_DEMAND_ASMNT_SET_STEP: 'ON_DEMAND_ASMNT_SET_STEP',
  REQUEST_DETAIL_DATA: 'REQUEST_DETAIL_DATA',
  UPDATE_USER_SETTING: 'UPDATE_USER_SETTING',
  ON_DEMAND_ASMNT_REQUEST_DETAIL_DATA: 'ON_DEMAND_ASMNT_REQUEST_DETAIL_DATA',
  ON_DEMAND_SELECTED_PORTFOLIO: 'ON_DEMAND_SELECTED_PORTFOLIO',
  ON_DEMAND_ASMNT_RESET_VIEW: 'ON_DEMAND_ASMNT_RESET_VIEW',
  RELOAD_DASHBOARD_COVERAGE: 'RELOAD_DASHBOARD_COVERAGE',
  SET_EMISSION_COLUMN: 'SET_EMISSION_COLUMN',
  RESET_API_CALLS: 'RESET_API_CALLS',
  TOGGLE_HAS_ERROR_POPUP: 'TOGGLE_HAS_ERROR_POPUP'
}

export const REDUCER_STATE = {
  PORTFOLIO_SCORE: 'portfolioScore',
  PORTFOLIO_DISTRIBUTION: 'portfolioDistribution',
  PORTFOLIO_COVERAGE: 'portfolioCoverage',
  PORTFOLIO_COVERAGE_DETAILS: 'portfolioCoverageDetails',
  PORTFOLIO_ESGSCORE: 'portfolioESGScore',
  PORTFOLIO_LEADERS_LAGGARDS: 'portfolioLeaderLaggard',
  PORTFOLIO_REGIONS: 'portfolioRegions',
  PORTFOLIO_SECTORS: 'portfolioSectors',
  PORTFOLIO_UPDATES: 'portfolioUpdates',
  PORTFOLIO_EMISSIONS: 'portfolioEmissions',
  PORTFOLIO_UNDERLYING_DATAMETRICS: 'portfolioUnderlyingDataMetrics',
  PORTFOLIO_UNDERLYING_DATAMETRICS_MR: 'portfolioUnderlyingDataMetricsMR',
  PORTFOLIO_UNDERLYING_DATAMETRICS_SCR: 'portfolioUnderlyingDataMetricsSCR',
  PORTFOLIO_UNDERLYING_DATAMETRICS_MR_PANEL:
    'portfolioUnderlyingDataMetricsMRPanel',
  PORTFOLIO_UNDERLYING_DATAMETRICS_SCR_PANEL:
    'portfolioUnderlyingDataMetricsSCRPanel',
  DASHBOARD_TABLE: 'dashboardTable',
  DASHBOARD_MAP: 'dashboardMap',
  DASHBOARD_MAP_COVERAGE: 'dashboardMapCoverage',
  DASHBOARD_MAP_ENTITY_LIST: 'dashboardMapEntityList',
  DASHBOARD_RESEARCH_LINES_COVERAGE: 'dashboardResearchLinesCoverage',
  DASHBOARD_RESEARCH_LINES: 'dashboardResearchLines',
  DASHBOARD_PORTFOLIOMONITORING_CONTROVERSIES_LIST:
    'dashboardPortfolioMonitoringControversiesList',
  DASHBOARD_HEATMAP_TABLE: 'dashboardHeatmapTable',
  DASHBOARD_HEATMAP_DESCRIPTION_LEFT: 'dashboardHeatmapDescriptionLeft',
  DASHBOARD_HEATMAP_DESCRIPTION_RIGHT: 'dashboardHeatmapDescriptionRight',
  DASHBOARD_HEATMAP_ENTITY: 'dashboardHeatmapEntity',
  DASHBOARD_EXPORT_DATA: 'dashboardExportData',
  ENTITY_CONTROVERSY: 'entityControversy',
  ENTITY_SCORE: 'entityScore',
  ENTITY_POSITION_IN_SECTOR: 'entityPositionInSector',
  ENTITY_DRIVER: 'entityDrivers',
  PORTFOLIO_IMPACT: 'portfolioImpact',
  PORTFOLIO_HISTORY: 'portfolioHistory',
  DASHBOARD_COMPANIES_TABLE: 'dashboardCompaniesTable',
  DRIVER_DATA: 'driverData',
  CLIMATE_SUMMARY_TEMPALIGNMENT: 'summaryTempAlignment',
  CLIMATE_SUMMARY_CARBONFOOTPRINT: 'summaryCarbonFootprint',
  CLIMATE_SUMMARY_PHYRISKHARZ: 'summaryPhyRiskHarz',
  CLIMATE_SUMMARY_PHYRISKMGM: 'summaryPhyRiskMgm',
  CLIMATE_SUMMARY_GREENSHARE: 'summaryGreenShare',
  CLIMATE_SUMMARY_BROWNSHARE: 'summaryBrownShare',
  CLIMATE_SUMMARY_TEMPALIGNMENT_PROJ_GRAPH: 'tempAlignmentProjectionGraph',
  CLIMATE_PHY_OPERATION_RISK: 'phyOperationRisk',
  CLIMATE_ESG_MTLTY_MATRIX: 'esgMateriality',
  CLIMATE_PHY_MARKET_RISK: 'phyMarketRisk',
  CLIMATE_PHY_SUPPLYCHAIN_RISK: 'phySupplyChainRisk',
  CLIMATE_PHY_OPERATIONAL_RISK_CHART: 'phyOperationalRiskChart',
  CLIMATE_PHY_MARKET_RISK_CHART: 'phyMarketRiskChart',
  CLIMATE_PHY_SUPPLY_CHAIN_RISK_CHART: 'phySupplyChainChart',
  CLIMATE_TRANS_CARBON_FOOT_PRINT_CHART: 'transCarbonFootPrintChart',
  CLIMATE_TRANS_BROWNSHARE_FOOT_PRINT_CHART: 'transBrownShareFootPrintChart',
  CLIMATE_TRANS_TEMP_ALIGN: 'transTempAlign',
  CLIMATE_TRANS_CARBON_FOOT_PRINT: 'transCarbonFootPrint',
  CLIMATE_TRANS_BROWN_SHARE: 'transBrownShare',
  CLIMATE_TRANS_GREEN_SHARE: 'transGreenShare',
  CLIMATE_ESGSCORE: 'overallESGScore',
  ESG_CONTROVERIES_MAP: 'esgControversiesMap',
  ESG_ENTITY_LIST: 'esgEntityList',
  CLIMATE_CONTROVERSY: 'climateControversy',
  CLIMATE_MTLTY_CONTROVERSY: 'climateMaterialityControversy',
  CLIMATE_PHY_RISK_MGM_UNDERLYING_DATA: 'PhyRiskMgmUDM',
  DOWNLOAD_DASHBOARD_CLIMATE_DATA: 'downloadDashboardClimateData',
  ENTITY_SOURCE_DOCUMENTS: 'entitySourceDocuments',
  ESG_SECTOR_TREE_MAP: 'esgSectorTreeMap',
  PORTFOLIO_ENTITY: 'portfolioEntity',
  REPORTING_PORTFOLIO: 'portfolios',
  REPORTING_COVERAGE_DETAILS: 'coverageDetails',
  REPORTING_ODA_PORTFOLIO: 'onDemandPortfolios',
  REPORTING_PORTFOLIO_GENERATE: 'generateReport',
  REPORTING_PORTFOLIO_HISTORY: 'reportingHistory',
  CRITERIA_FILTER_VIEW: 'criteriaFilterView',
  REQUEST_DETAIL_INFO: 'reqDetailInfo',
  USER_SETTING: 'userSetting',
  ON_DEMAND_ASMNT_CRITERIA_FILTER_VIEW: 'criteriaFilterView',
  ON_DEMAND_ASMNT_REQUEST_DETAIL_INFO: 'reqDetailInfo',
  ON_DEMAND_ASMNT_SELECTED_PORTFOLIO: 'selectedPortfolio',
  ON_DEMAND_ASMNT_BATCH_REVIEW: 'batchRequest',
  ON_DEMAND_ASMNT_LIMIT: 'assessmentLimit'
}
