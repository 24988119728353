import { AppBar } from '@material-ui/core'
import { GlobalContext } from 'context/GlobalStateProvider'
import {
  PAGE_CON,
  REDUCER_STATE,
  REDUCER_TYPE,
  entityFilterOptions
} from 'constant/constants'
import Grid from '@material-ui/core/Grid'
import {
  DropdownField,
  IconClose,
  IconError,
  IconLeftArrow,
  IconSuccess,
  PrimaryButton,
  PrimaryLink,
  PrimaryPopover,
  RegularTable,
  Textbox
} from 'components'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import themeColorPalette from 'theme/theme'
import axios from 'axios'
import { IMPORT_UPDATE_GET_PORTFOLIOS, PARAMS } from 'constant/APIconstants'
import { PortfolioManagementService, PortfolioService } from 'services'
import Error from 'utils/error'
import LoadingMask from 'utils/loadingMask'
import numberData from 'utils/numberFormat'
import * as S from './styles'

const EntityCol = (entityOnClickInfo) => [
  {
    HeaderLabel: 'Company',
    HideLabel: false,
    Alignment: 'left',
    DataField: [
      {
        Name: 'company_name',
        root: 'parent_company_name',
        Alignment: 'left',
        isEllipsis: true,
        charLength: 26,
        width: 265,
        isSemibold: true,
        onClick: entityOnClickInfo
      }
    ]
  },
  {
    HeaderLabel: '% Investment',
    HideLabel: false,
    Alignment: 'right',
    DataField: [
      {
        Name: 'investment_pct',
        Alignment: 'right',
        NumberType: {
          isDecimal: true,
          isPercentage: true,
          isLessThanOneFormat: true
        }
      }
    ]
  }
]

const LastRowCol = [
  {
    HeaderLabel: '',
    HideLabel: true,
    Alignment: 'left',
    DataField: [
      {
        Name: 'company_name',
        Alignment: 'left',
        isEllipsis: true,
        charLength: 26,
        width: 265,
        isSemibold: true,
        cellTextColor: {
          colorGroup: 'PORT_ENTITY_TEXT',
          colorDerivedByField: ''
        }
      }
    ]
  },
  {
    HeaderLabel: '',
    HideLabel: true,
    Alignment: 'right',
    DataField: [
      {
        Name: 'investment_pct',
        Alignment: 'right',
        isSemibold: true,
        cellTextColor: {
          colorGroup: 'PORT_ENTITY_TEXT',
          colorDerivedByField: ''
        }
      }
    ]
  }
]

function PortfolioEntity(props) {
  const classes = S.useStyles(props)
  const context = useContext(GlobalContext)
  const [filterState, filterDispatch] = context.globalFilter
  const [portfolioManagementState, portfolioManagementDispatch] =
    useContext(GlobalContext).globalPortfolioManagement
  const [, reportingServiceDispatch] =
    useContext(GlobalContext).globalReportingService
  const [portfolio, setPortfolio] = useState({ id: '', name: '' })
  const [entityFilter, setEntityFilter] = useState(entityFilterOptions[0].key)
  const [showMore, setShowMore] = useState(false)
  const [isNameChanged, setIsNameChanged] = useState(false)
  const [newPortfolioName, setNewPortfolioName] = React.useState('')
  const [successPopoverOpen, setSuccessPopoverOpen] = React.useState(false)
  const [errorPopoverOpen, setErrorPopoverOpen] = React.useState(false)

  const isDisabled = portfolio.id === PAGE_CON.SAMPLE_PORTFOLIO_ID
  const { apiCallId } = portfolioManagementState
  const entityOnClickInfo = props.isEntityClickable && {
    clickType: 'entityName',
    showUnderline: true
  }
  let timer = 0

  if (
    isNameChanged ||
    (portfolioManagementState.portfolioId !== '' &&
      portfolioManagementState.portfolioId !== portfolio.id)
  ) {
    const portfolio = filterState.filterData.portfolioList.filter(
      (portfolio) => portfolio.key === portfolioManagementState.portfolioId
    )
    if (portfolio?.length > 0) {
      const selPortfolio = portfolio[0]
      setPortfolio({
        id: selPortfolio.key,
        name: selPortfolio[PAGE_CON.DROPDOWN_DISPLAY_COLUMN]
      })
    }
    setIsNameChanged(false)
  }

  const getPortfolioEntity = async (isErrorReload = false) => {
    if (
      (portfolioManagementState.portfolioEntity.data?.investments?.length > 0 &&
        portfolioManagementState.portfolioId ===
          portfolioManagementState.portfolioEntity?.portfolio_id) ||
      (portfolioManagementState.portfolioEntity.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    portfolioManagementDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_ENTITY
    })
    let entityData = {}

    try {
      const data = await PortfolioManagementService.getPortfolioEntityDetail({
        portfolioID: portfolioManagementState.portfolioId,
        cancelToken: portfolioManagementState.cancelToken.token,
        mockData: false
      })

      entityData = data

      portfolioManagementDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_ENTITY,
        data: entityData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        portfolioManagementDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_ENTITY,
          apiCallId
        })
      }
    }
  }

  const loadData = useCallback(async () => {
    if (portfolioManagementState.portfolioId !== '') {
      getPortfolioEntity()
    }
  }, [portfolioManagementState.portfolioId])

  useEffect(() => {
    if (portfolioManagementState.portfolioId !== '') {
      loadData()
    }
  }, [portfolioManagementState.portfolioId])

  useEffect(() => {
    if (newPortfolioName !== '') {
      timer = setTimeout(updatePortfolioName, 2000)
    }
  }, [newPortfolioName])

  const onUpload = (uploadType) => {
    props.handlePortfolioClose()
    if (props.onUploadClick) props.onUploadClick()
    filterDispatch({
      type: REDUCER_TYPE.UPLOAD_PORTFOLIO_MODAL,
      payload: true,
      uploadType,
      uploadInitiatedFrom: PAGE_CON.PORTFOLIO_MANAGEMENT_REUPLOAD
    })
  }

  const onEntityFilterChange = (event) => {
    setEntityFilter(event.target.value)
    setShowMore(false)
  }

  const onShowMoreClick = () => {
    setShowMore(true)
  }

  const companiesSlice = {
    top10: 10,
    top20: 20
  }[entityFilter]

  const investments =
    portfolioManagementState?.portfolioEntity?.data?.investments?.sort((a, b) =>
      a.order < b.order ? -1 : 1
    ) || []

  const showMoreCompanies = investments
    .slice(companiesSlice, investments.length)
    .filter(
      (company) =>
        company.rank ===
        investments.slice(0, companiesSlice)[
          investments.slice(0, companiesSlice).length - 1
        ].rank
    )

  const companiesList = () => {
    let companies = [...investments.slice(0, companiesSlice)]
    if (showMore) {
      companies = companies.concat(showMoreCompanies)
    }

    return companies
  }

  const LastRowList = () => {
    const inv_text =
      portfolioManagementState?.portfolioEntity?.data
        ?.total_unmatched_companies > 1
        ? 'investments'
        : 'investment'
    return [
      {
        company_name: `${portfolioManagementState?.portfolioEntity?.data?.total_unmatched_companies} ${inv_text} not matched`,
        investment_pct: numberData(
          portfolioManagementState?.portfolioEntity?.data
            ?.unmatched_portfolio_percent,
          { isDecimal: true, isPercentage: true, isLessThanOneFormat: true }
        )
      }
    ]
  }

  const reloadComponent = (errorOccuredOn) => {
    switch (errorOccuredOn) {
      case PAGE_CON.PORTFOLIO_ENTITY:
        getPortfolioEntity(true)
        break
      default:
        break
    }
  }

  const portfolioManagementError =
    portfolioManagementState.portfolioEntity.dataStatus === PAGE_CON.ERROR ||
    (portfolioManagementState.portfolioEntity.dataStatus === PAGE_CON.SUCCESS &&
      portfolioManagementState.portfolioEntity.data?.investments?.length === 0)

  const loadPortfolios = async () => {
    let portfolios = []
    try {
      const { data } = await PortfolioService.getPortfolioDetails({
        params: {},
        queryParams: ''
      })

      for (const [, value] of Object.entries(data.portfolios)) {
        portfolios.push({
          key: value.portfolio_id,
          [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: value.portfolio_name,
          [PAGE_CON.CREATED_DATE]: value[PAGE_CON.CREATED_DATE],
          [PAGE_CON.UPLOAD_DATE_UTC]: value[PAGE_CON.UPLOAD_DATE_UTC]
        })
      }

      filterDispatch({
        type: REDUCER_TYPE.UPDATE_PORTFOLIO_COMPLETED,
        portfolioList: portfolios
      })
    } catch (err) {
      console.log(err)
    }
  }

  const updatePortfolioName = async () => {
    if (
      portfolioManagementState.openPortfolioManagement &&
      portfolioManagementState.portfolioManagementView ===
        PAGE_CON.PORTFOLIO_ENTITY
    ) {
      try {
        await axios.put(`${IMPORT_UPDATE_GET_PORTFOLIOS}/${portfolio.id}`, {
          [PARAMS.PORTFOLIO_NAME]: newPortfolioName
        })
        reportingServiceDispatch({ type: REDUCER_TYPE.RESET_REPORTING_SERVICE })
        await loadPortfolios()

        setErrorPopoverOpen(false)
        setIsNameChanged(true)
        setSuccessPopoverOpen(true)
        setTimeout(() => {
          onPopoverClose('success')
        }, 3000)
      } catch (err) {
        clearTimeout(timer)
        setSuccessPopoverOpen(false)
        setErrorPopoverOpen(true)
      }
    } else {
      clearTimeout(timer)
    }
  }

  const onPortfolioNameChange = (inputText) => {
    if (errorPopoverOpen) {
      setErrorPopoverOpen(false)
    }
    setNewPortfolioName(inputText)
    clearTimeout(timer)
  }

  const onPopoverClose = (type) => {
    type === 'success'
      ? setSuccessPopoverOpen(false)
      : setErrorPopoverOpen(false)
  }

  const handleDeleteButton = () => {
    portfolioManagementDispatch({
      type: REDUCER_TYPE.PORTFOLIO_MANAGEMENT,
      openPortfolioManagement: false
    })
    portfolioManagementDispatch({
      type: REDUCER_TYPE.DELETE_MODAL,
      openDeletePortfolioModal: true
    })
  }

  return (
    <Grid container direction="column" spacing={0} id={props.id} key={props.id}>
      <Grid item className={classes.parentContainer}>
        <AppBar id={props.id} position="sticky" className={classes.pageHeader}>
          <Grid container className={classes.headerContainer}>
            <Grid item xs="auto">
              <span style={{ margin: '0px', float: 'left' }}>
                <IconLeftArrow
                  style={{ cursor: 'pointer', marginTop: 2 }}
                  onClick={() => props.onMovePrevClick()}
                />
              </span>
            </Grid>
            <Grid item xs={10}>
              <Grid container direction="column">
                <Grid item className={classes.headerText}>
                  <span title={portfolio.name}>{portfolio.name}</span>
                </Grid>
                <Grid item className={classes.uploadStyle}>
                  <PrimaryLink
                    id="link-upload"
                    key="link-upload"
                    linkSelected
                    linkText="Re-upload"
                    linkClass={
                      isDisabled ? 'linkDisable' : 'portfolioUploadLink'
                    }
                    onClick={
                      isDisabled
                        ? undefined
                        : () => onUpload(PAGE_CON.FILTER_PORTFOLIO)
                    }
                    linkMargin="0px"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <span
                style={{
                  margin: '0px',
                  marginRight: '-18px',
                  float: 'right'
                }}
              >
                <IconClose
                  style={{ cursor: 'pointer', marginTop: 2 }}
                  onClick={() => props.handlePortfolioClose()}
                />
              </span>
            </Grid>
            <Grid item xs={12} className={classes.border} />
          </Grid>

          <PrimaryPopover
            alertOpen={successPopoverOpen || errorPopoverOpen}
            alertIcon={
              successPopoverOpen ? (
                <IconSuccess
                  colorprimary={themeColorPalette.palette.sys_trad.text.main}
                />
              ) : errorPopoverOpen ? (
                <IconError
                  colorprimary={themeColorPalette.palette.sys_trad.text.main}
                />
              ) : null
            }
            alertText={
              successPopoverOpen ? (
                'Name Saved'
              ) : errorPopoverOpen ? (
                <>
                  <span>Auto-save error</span>
                  <span
                    className={classes.retry}
                    onClick={() => updatePortfolioName()}
                    aria-hidden={true}
                  >
                    try again
                  </span>
                </>
              ) : null
            }
            alertPosition="left"
            alertWidth={successPopoverOpen ? '105px' : '170px'}
            alertTop="5px"
            alertLeft="150px"
            alertTextTopPadding={successPopoverOpen ? '0px' : '1px'}
            paddingTop="2px"
            paddingBottom="0px"
            paddingLeft="8px"
            paddingRight="8px"
            overflowY="hidden"
            onAlertClose={onPopoverClose}
            hideBackdrop
            hideClose
            popoverShadow={false}
            popoverBgColor={
              successPopoverOpen
                ? themeColorPalette.palette.sys_trad.successlight.main
                : errorPopoverOpen
                ? '#F2994A'
                : themeColorPalette.palette.sys_trad.white.main
            }
          />

          <Grid container direction="column">
            <Grid item className={classes.shadowItem}>
              {portfolioManagementState.portfolioEntity.isLoading ? (
                <LoadingMask />
              ) : portfolioManagementError ? (
                <Error
                  id="entityError"
                  dataStatus={
                    portfolioManagementState.portfolioEntity.dataStatus
                  }
                  onErrorClick={reloadComponent}
                  errorOccuredOn={PAGE_CON.PORTFOLIO_ENTITY}
                />
              ) : (
                <>
                  <div
                    className={classes.sectionOneNormalStyle}
                    style={{ marginBottom: '4px' }}
                  >
                    We've matched{' '}
                    <span className={classes.sectionOneBoldStyle}>
                      {
                        portfolioManagementState.portfolioEntity.data
                          ?.total_matched_companies
                      }
                      /
                      {
                        portfolioManagementState.portfolioEntity.data
                          ?.total_unique_companies
                      }{' '}
                      INVESTMENTS
                    </span>{' '}
                    to{' '}
                    <span className={classes.sectionOneBoldStyle}>
                      {portfolioManagementState.portfolioEntity.data?.entities}{' '}
                      ENTITIES
                    </span>
                  </div>
                  <div className={classes.sectionOneNormalStyle}>
                    Accounting for{' '}
                    <span className={classes.sectionOneBoldStyle}>
                      {numberData(
                        portfolioManagementState.portfolioEntity.data
                          ?.portfolio_percent,
                        {
                          isDecimal: true,
                          isPercentage: true,
                          keepRawValue: false
                        }
                      )}
                    </span>{' '}
                    of your uploaded portfolio
                  </div>
                </>
              )}
            </Grid>
            <Grid item className={classes.sectionTwoNormalStyle}>
              Name
              <span className={classes.sectionTwoColorStyle}>*</span>
            </Grid>
            <Grid item style={{ marginBottom: '24px' }}>
              <Textbox
                id="textbox-test-id-1"
                data-testid="input-component"
                textboxDefaultPlaceholder={props.placeholder || ''}
                textboxClass="searchTextbox"
                showSearchIcon={false}
                textboxMargin="8"
                textboxError={false}
                multiline={false}
                textboxHeight="32px"
                defaultText={portfolio.name}
                autoCompleteOff
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={false}
                disabled={isDisabled}
                onChange={onPortfolioNameChange}
              />
            </Grid>
            <Grid item style={{ marginBottom: '16px' }}>
              {!portfolioManagementError && (
                <DropdownField
                  id="entity-filter"
                  dropdownDefaultSelction={entityFilter}
                  saveValueUsingExternalState
                  dropdownOptions={entityFilterOptions}
                  onChange={onEntityFilterChange}
                  labelTextDisabled
                  labelText=""
                  defaultData=""
                  disabled={portfolioManagementState.portfolioEntity.isLoading}
                />
              )}
            </Grid>
          </Grid>
        </AppBar>
        <Grid container direction="column">
          <Grid item style={{ marginBottom: '65px' }}>
            {portfolioManagementState.portfolioEntity.isLoading ? (
              <LoadingMask />
            ) : portfolioManagementError ? (
              <Error
                id="entityErrorList"
                dataStatus={portfolioManagementState.portfolioEntity.dataStatus}
                onErrorClick={reloadComponent}
                errorOccuredOn={PAGE_CON.PORTFOLIO_ENTITY}
              />
            ) : (
              <>
                <RegularTable
                  id="table-id"
                  tableEditable={false}
                  columns={EntityCol(entityOnClickInfo)}
                  isRowHighlighted
                  totalData={companiesList()}
                  horScroll="hidden"
                />
                <RegularTable
                  id="table-id-1"
                  heap_id="leadlag"
                  tableEditable={false}
                  columns={LastRowCol}
                  totalData={LastRowList()}
                  horScroll="hidden"
                  hideHeader
                />
              </>
            )}
            {!showMore && showMoreCompanies.length !== 0 && (
              <span
                className={classes.showMoreText}
                onClick={onShowMoreClick}
                aria-hidden={true}
              >
                {showMoreCompanies.length} more{' '}
                {showMoreCompanies.length === 1 ? 'company' : 'companies'}
              </span>
            )}
          </Grid>
          <Grid item>
            <div className={classes.footerContainer}>
              <div className={classes.footerBorder} />
              <div className={classes.deleteStyle}>
                <PrimaryButton
                  id="button-button-test-id-1"
                  buttonText="Delete Portfolio"
                  buttonClass="buttonDelete"
                  buttonDisabled={isDisabled}
                  onClick={handleDeleteButton}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PortfolioEntity
