import { useStyles } from './styles';
const Legend = (props) => {
    const label = props.legendLableName;
    const classes = useStyles(props);
    return (
        <div className={classes.drawerFooter}>
            <div className={classes.subLegendColor}></div>
            <span className={classes.subLegendText}>{label}</span>
        </div>
    );
}


export default Legend;