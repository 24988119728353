import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'

export const useStyle = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: (props) => (props.maxWidth ? props.maxWidth : 'auto'),
    padding: 8
  },
  outerBox: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  textStyle: {
    ...textTheme.typography.p3,
    lineHeight: '16px',
    color: textTheme.palette.sys_trad.text.main,
    paddingLeft: '8px'
  },
  portfolio: {
    ...textTheme.typography.p2,
    lineHeight: '16px',
    fontWeight: 600,
    color: textTheme.palette.sys_trad.text.main,
    textDecoration: 'underline',
    flex: 1,
    width: '242px',
    maxWidth: '242px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  portfolioList: {
    ...textTheme.typography.p2,
    lineHeight: '16px',
    fontWeight: 600,
    color: textTheme.palette.sys_trad.text.main,
    textDecoration: 'underline',
    flex: 1,
    width: '312px',
    maxWidth: '312px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  portfolioTextStyle: {
    ...textTheme.typography.p2,
    lineHeight: '16px',
    fontWeight: 600,
    color: textTheme.palette.sys_trad.text2.main,
    flex: 1,
    width: '312px',
    maxWidth: '312px',
    whiteSpace: 'nowrap'
  },
  uploadTextStyle: {
    ...textTheme.typography.p2,
    lineHeight: '16px',
    fontWeight: 600,
    paddingLeft: 8,
    color: textTheme.palette.sys_trad.text2.main
  },
  portfolioHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    width: (props) => (props.maxWidth ? props.maxWidth : 'auto'),
    paddingLeft: 8,
    paddingBottom: 2
  }
})
