import { makeStyles } from '@material-ui/core'
import { default as base } from 'theme/theme'

export const researchLineCategory = makeStyles(() => ({
  container: {
    marginRight: (props) => (props.minify ? 0 : 16),
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  title: {
    color: base.palette.sys_trad.text.main,
    fontSize: 10,
    marginBottom: 4
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  background: {
    backgroundColor: base.palette.sys_trad.white.main,
    padding: '8px',
    borderRadius: '4px',
    height: 'auto',
    '&>div:last-child': {
      overflow: 'hidden'
    }
  }
}))
