import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconErrorTable = (props) => (
  <SvgIcon {...props}>
    <path
      d="M7.27249 6.79066C7.23702 6.36504 7.5729 6 8 6C8.4271 6 8.76298 6.36504 8.72751 6.79066L8.49827 9.54152C8.47668 9.80067 8.26004 10 8 10C7.73996 10 7.52332 9.80067 7.50173 9.54152L7.27249 6.79066Z"
      fill="#B31717"
    />
    <path
      d="M8.5 11.5C8.5 11.7761 8.27614 12 8 12C7.72386 12 7.5 11.7761 7.5 11.5C7.5 11.2239 7.72386 11 8 11C8.27614 11 8.5 11.2239 8.5 11.5Z"
      fill="#B31717"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.3424 2.82673C8.7894 1.72075 7.21092 1.72128 6.65867 2.82764L2.16497 11.8302C1.66716 12.8275 2.39243 14.0001 3.50706 14.0001H12.5021C13.6171 14.0001 14.3424 12.8266 13.8437 11.8293L9.3424 2.82673ZM7.5534 3.27425C7.73748 2.90547 8.26364 2.90529 8.44797 3.27395L12.9493 12.2765C13.1155 12.6089 12.8738 13.0001 12.5021 13.0001H3.50706C3.13551 13.0001 2.89376 12.6092 3.05969 12.2768L7.5534 3.27425Z"
      fill="#B31717"
    />{' '}
  </SvgIcon>
)
IconErrorTable.displayName = 'IconErrorTable'
IconErrorTable.muiName = 'SvgIcon'

export default IconErrorTable
