import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import textTheme from '../../../../theme/theme'
import themeColorPalette from '../../../../theme/theme'
import numberData from '../../../../utils/numberFormat'

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: themeColorPalette.palette.sys_trad.border.main
  },
  sectionTitle: {
    ...textTheme.typography.p2,
    textDecoration: 'underline'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  grid: {
    ...textTheme.typography.p3
  }
}))

const overViewTextSubtitle = {
  ...textTheme.typography.h2,
  color: themeColorPalette.palette.sys_trad.text.main,
  textAlign: 'left'
}

const CarbonFootprintUnderlyingDataMetrics = (props) => {
  const classes = useStyles(props)
  const pageTitle = props.pageTitle
  const underlyingDataMetrics = React.useMemo(() => {
    return {
      pageTitle: pageTitle,
      Chiclet: (
        <Grid className={classes.grid} item xs={12}>
          {props.underlyingDataMetrics !== undefined
            ? props.underlyingDataMetrics.map((item, index) => {
                return (
                  <span key={index}>
                    <Grid
                      container
                      className={classes.grid}
                      direction={'row'}
                      spacing={2}
                    >
                      <Grid item className={classes.grid} xs={4}>
                        <span className={classes.sectionTitle}>
                          {' '}
                          {item.title}
                        </span>
                        <br />
                        <br />
                        {item.description}
                      </Grid>
                      <Grid
                        className={classes.grid}
                        item
                        xs={7}
                        style={{ marginLeft: '24px' }}
                      >
                        <span
                          className={classes.sectionTitle}
                          style={{ textDecoration: 'none' }}
                        >
                          {' '}
                          {item.emissions_title}
                        </span>
                        <br />
                        <br />
                        {`${numberData(item.total_scope_emission, {
                          isCommaSeparated: true
                        }).toString()} (t CO2 eq)`}
                      </Grid>
                    </Grid>{' '}
                    <br></br>
                    {item + 1 !== props.sections.length && (
                      <Divider className={classes.divider} />
                    )}
                    <br></br>
                  </span>
                )
              })
            : null}
        </Grid>
      )
    }
  }, [])

  return (
    <Grid className={classes.grid} item xs={12} sm={12}>
      <div style={overViewTextSubtitle}>
        {' '}
        <br />
        {underlyingDataMetrics.Chiclet}
      </div>
    </Grid>
  )
}

export default CarbonFootprintUnderlyingDataMetrics
