const fileCollection = (entitlements) => {
  return [
    {
      fileName: 'Controversy Risk Assessment Methodology',
      entitlement: entitlements.Dashboard.controversy,
      file: `${process.env.PUBLIC_URL}/assets/Controversy Risk Assessment - Methodology June 2021.pdf`
    },
    {
      fileName: 'Physical Risk methodology',
      entitlement: entitlements.PortfolioAnalysis.physicalrisk,
      file: `${process.env.PUBLIC_URL}/assets/Methodology_Corporate Physical Climate Risk  Operations Risk (Jan 2022).pdf`
    },
    {
      fileName: 'Physical Risk Management methodology',
      entitlement: entitlements.PortfolioAnalysis.physicalrisk,
      file: `${process.env.PUBLIC_URL}/assets/Methodology_VEC_Climate_v4.1c52bfc9.pdf`
    },
    {
      fileName: 'Temperature Alignment methodology',
      entitlement: entitlements.PortfolioAnalysis.transitionrisk,
      file: `${process.env.PUBLIC_URL}/assets/Methodology_Climate_Temperature Alignment Data.pdf`
    },
    {
      fileName: 'Transition Risk methodology',
      entitlement: entitlements.PortfolioAnalysis.transitionrisk,
      file: `${process.env.PUBLIC_URL}/assets/Methodology_Climate_Climate Risk Assessment.pdf`
    }
  ]
}

export default fileCollection
