import { makeStyles } from '@material-ui/core/styles'
import numberData from 'utils/numberFormat'
import PropTypes from 'prop-types'
import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

const hiRisk = themeColorPalette.palette.noflag.hirsk.main
const regColor = textTheme.palette.sys_trad.text.main
const useStyles = makeStyles({
  numberCell: {
    textAlign: 'right',
    minWidth: (props) => props.cellWidth
  }
})

function negativeChecking(data) {
  let isNegative = false
  if (parseFloat(data) < 0) isNegative = true
  return isNegative
}

/**
 * @visibleName TableNumberCell
 */

const TableNumberCell = (props) => {
  const { id, cellclass, rowdata, numberSpecs, unit } = props

  let isNegative = negativeChecking(rowdata)
  let finalData =
    rowdata === 'No Info'
      ? rowdata
      : typeof rowdata === 'string' || rowdata instanceof String
      ? rowdata
      : unit
      ? rowdata
      : numberData(rowdata, numberSpecs)

  const classes = useStyles({ ...props })

  return (
    <TableCell
      id={id}
      className={classes.numberCell}
      classes={{ root: cellclass }}
      style={isNegative ? { color: hiRisk } : { color: regColor }}
    >
      {finalData}
    </TableCell>
  )
}

TableNumberCell.propTypes = {
  /** TableNumberCell id */
  id: PropTypes.string,
  /** Width of the Cell */
  cellWidth: PropTypes.any,
  /** data to be displayed in table */
  rowdata: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default TableNumberCell
