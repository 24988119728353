import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconCopy = (props) => (
  <SvgIcon {...props}>
    <path
      fill="#26415E"
      fillRule="evenodd"
      d="M13 6H6v7h7V6zM6 5a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1V6a1 1 0 00-1-1H6z"
      clipRule="evenodd"
    />
    <path
      fill="#26415E"
      fillRule="evenodd"
      d="M3 3h7v2h1V3a1 1 0 00-1-1H3a1 1 0 00-1 1v7a1 1 0 001 1h2v-1H3V3z"
      clipRule="evenodd"
    />
  </SvgIcon>
)
IconCopy.displayName = 'IconCopy'
IconCopy.muiName = 'SvgIcon'

export default IconCopy
