import { AppBar } from '@material-ui/core'
import Error from 'utils/error'
import axios from 'axios'
import { Container, Grid, makeStyles } from '@material-ui/core'
import { DropdownField, IconArrowDown, Page } from 'components'
import getMonthYear from 'utils/Date'
import { GlobalContext } from 'context/GlobalStateProvider'
import { BRN_SHR_MGM, COMMON, PARAMS } from 'constant/APIconstants'
import { ResearchLineService } from 'services'
import Laggards from '../../Laggards'
import Leaders from '../../Leaders'
import LoadingMask from 'utils/loadingMask'
import {
  PAGE_CON,
  REDUCER_STATE,
  REDUCER_TYPE,
  filterOptions
} from 'constant/constants'
import React, { useCallback, useContext, useEffect } from 'react'
import Regions from '../../Regions'
import Updates from '../../Updates'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'
import BrownShareAssessmentUnderlyingDataRiskMetrics from './BrownShareAssessmentUnderlyingDataMetrics'
import BrownShareAssessmentColumns from './BrownShareAssessmentColumns.json'
import BrownShareAssessmentOverview from './BrownShareAssessmentOverview'
import Impact from '../../Impact'
import getColor from 'utils/color'

const LeaderLaggardColInfo =
  BrownShareAssessmentColumns.brownshare_leaders_Laggards_Panel_Columns
const UpdatesTableColInfo =
  BrownShareAssessmentColumns.brownshare_updates_Columns
const PanelUpdatesTableColInfo =
  BrownShareAssessmentColumns.brownshare_updates_Panel_Columns
const mimTabColInfo = JSON.parse(
  JSON.stringify(
    BrownShareAssessmentColumns.brownshare_region_Sector_Minimal_Table_Columns
  )
)
const panelTabColInfo =
  BrownShareAssessmentColumns.brownshare_region_Sector_Panel_Columns
const cardInfoContentInfo =
  BrownShareAssessmentColumns.brownshare_card_Info_Section_Columns
const cardInfoContentInfoSector =
  BrownShareAssessmentColumns.brownshare_card_Info_Section_Columns_Sector
const impactColumns = JSON.parse(
  JSON.stringify(BrownShareAssessmentColumns.impactColumns)
)

const drawerWidth = PAGE_CON.PAGE_LEFT_MARGIN

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  content: {
    flexGrow: 1
  },
  container: {
    marginLeft: drawerWidth - 20,
    paddingRight: 0,
    paddingLeft: '24px',
    marginTop: '22px'
  },
  tableTitle: {
    paddingBottom: '5px',
    ...textTheme.typography.h1
  },
  stickyHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    zIndex: 1,
    boxShadow: 'none'
  },
  containerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 16px 16px',
    marginTop: '24px'
  },
  impactContainerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 0px 16px',
    marginTop: '24px',
    paddingLeft: '16px'
  },
  impactDropdown: {
    marginTop: '16px',
    marginBottom: '12px'
  }
}))

const tableLength = 10

const overviewLinkPadding = {
  marginTop: '16px',
  marginLeft: '-4px'
}

const linkPadding = {
  marginTop: '8px',
  marginLeft: '-4px'
}

const linkPaddingRegionIndustry = {
  marginTop: '8px',
  marginLeft: '-4px'
}

const linkStyle = {
  ...textTheme.typography.p1,
  color: themeColorPalette.palette.sys_trad.link.main,
  lineHeight: '16px',
  verticalAlign: 'top'
}

const regionStyle = {
  marginTop: '18px'
}

const regionTitleStyle = {
  top: '16px'
}

const updatesStyle = {
  paddingLeft: '2px',
  marginTop: '16px'
}

const updatesTableStyle = {
  marginTop: '12px',
  background: themeColorPalette.palette.sys_trad.white.main,
  boxShadow: '0px 0px 16px rgba(38, 65, 94, 0.12)',
  boxRadius: '2px',
  boxSizing: 'border-box',
  padding: '12px',
  paddingBottom: '15px',
  paddingTop: '0px'
}

const cardHeader = {
  ...textTheme.typography.p2,
  paddingBottom: '4px',
  paddingTop: '16px',
  lineHeight: '16px'
}

const dataMetricbox = {
  marginTop: '12px',
  paddingTop: '10px',
  background: themeColorPalette.palette.sys_trad.white.main,
  boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.12)',
  boxRadius: '2px',
  boxSizing: 'border-box',
  padding: '17px'
}

const dataMetricTitle = {
  marginTop: '16px'
}

const pageConstant = BRN_SHR_MGM.PAGE_URL

const mapDataClasses = [
  {
    color: themeColorPalette.palette.major_no.green_major.main,
    from: 0,
    to: 0
  },
  {
    color: themeColorPalette.palette.major_no.green_signif.main,
    from: 1,
    to: 20
  },
  { color: themeColorPalette.palette.major_no.green_no.main, from: 21, to: 100 }
]

const BrownShareAssessment = (props) => {
  const classes = useStyles()
  const [filterState] = useContext(GlobalContext).globalFilter
  const [brownShareState, brownShareDispatch] =
    useContext(GlobalContext).globalBrownShareAssessment
  const [scoreQualityState] = useContext(GlobalContext).globalFilter
  const [selectedCardId, setSelectedCardId] = React.useState(null)
  let showBenchMark =
    filterState.globalPortfolioFilter.benchmark !== '' ? true : false

  let apiCallId = brownShareState.apiCallId

  let benchmarkPctCol = mimTabColInfo.filter(
    (col) => col.HeaderLabel === PAGE_CON.BENCHMARK_PERC
  )
  benchmarkPctCol[0].HideColumn = !showBenchMark

  let monthYear = getMonthYear(filterState)

  const isFilterStateAvailable =
    filterState.filterData.regionsList.length > 0 ||
    filterState.filterData.sectorsList.length > 0
  const [updateSectionText, setUpdateSectionText] = React.useState(
    `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}, ${PAGE_CON.IMPACT_TITLE}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`
  )

  const onImpactFilterChange = (event) => {
    brownShareDispatch({
      type: REDUCER_TYPE.UPDATE_IMPACT_FILTER,
      data: event.target.value
    })
  }

  const isRegionHasData = () => {
    let hasData = false
    if (
      brownShareState.portfolioRegions.data.summary.length > 0 &&
      brownShareState.portfolioRegions.data.details.length > 0 &&
      brownShareState.portfolioRegions.data.mapData.length > 0
    )
      hasData = true
    return hasData
  }

  const getLeadersLaggardsData = async (
    sectors,
    regions,
    isErrorReload = false
  ) => {
    if (
      (brownShareState.portfolioLeaderLaggard.data.leaders &&
        brownShareState.portfolioLeaderLaggard.data.laggards &&
        brownShareState.portfolioLeaderLaggard.data.leaders.length > 0 &&
        brownShareState.portfolioLeaderLaggard.data.laggards.length > 0) ||
      (brownShareState.portfolioLeaderLaggard.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    brownShareDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS
    })
    let leadersLaggardsData = { leaders: [], laggards: [] }
    try {
      if (isFilterStateAvailable) {
        let { data } = await axios.post(
          COMMON.BASE_URL +
            filterState.globalPortfolioFilter.portfolio +
            BRN_SHR_MGM.PAGE_URL +
            COMMON.LEADERS_LAGGARDS.trim(),
          {
            [PARAMS.REGION]: regions,
            [PARAMS.SECTOR]: sectors,
            [PARAMS.MONTH]: filterState.globalPortfolioFilter.asOfDate.slice(4),
            [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(
              0,
              4
            )
          },
          { cancelToken: brownShareState.cancelToken.token }
        )

        leadersLaggardsData.leaders = data.leaders
        leadersLaggardsData.laggards = data.laggards
      }
      brownShareDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS,
        data: leadersLaggardsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        brownShareDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_LEADERS_LAGGARDS,
          apiCallId
        })
      }
    }
  }

  const getRegionsData = async (sectors, regions, isErrorReload = false) => {
    if (
      (brownShareState.portfolioRegions.data.summary.length > 0 &&
        brownShareState.portfolioRegions.data.details.length > 0 &&
        brownShareState.portfolioRegions.data.mapData.length > 0 &&
        brownShareState.portfolioSectors.data.summary.length > 0 &&
        brownShareState.portfolioSectors.data.details.length > 0) ||
      (brownShareState.portfolioRegions.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    brownShareDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_REGIONS
    })
    let regionsData = {
      summary: [],
      details: [],
      mapData: [],
      sectorsummary: [],
      sectordetails: []
    }
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter
    try {
      if (isFilterStateAvailable) {
        const [summary, details, mapData, sectorsummary, sectordetails] =
          await axios.all([
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: BRN_SHR_MGM.PAGE_URL,
              token: brownShareState.cancelToken.token,
              endPoint: COMMON.REGION_SUMMARY
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: BRN_SHR_MGM.PAGE_URL,
              token: brownShareState.cancelToken.token,
              endPoint: COMMON.REGION_DETAILS
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: BRN_SHR_MGM.PAGE_URL,
              token: brownShareState.cancelToken.token,
              endPoint: COMMON.MAP
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: BRN_SHR_MGM.PAGE_URL,
              token: brownShareState.cancelToken.token,
              endPoint: COMMON.SECTOR_SUMMARY
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: BRN_SHR_MGM.PAGE_URL,
              token: brownShareState.cancelToken.token,
              endPoint: COMMON.SECTOR_DETAILS
            })
          ])

        regionsData.summary = summary.data
        regionsData.details = details.data
        regionsData.sectorsummary = sectorsummary.data
        regionsData.sectordetails = sectordetails.data
        mapData?.data
          .filter((data) => !isNaN(data.score) && data.score >= 0)
          .map((mdata) => {
            return regionsData.mapData.push([
              mdata.country_code,
              mdata.score,
              mdata.score_range
            ])
          })
      }

      brownShareDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_REGIONS,
        data: regionsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        brownShareDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_REGIONS,
          apiCallId
        })
      } else console.log(err)
    }
  }

  const getUpdatesData = async (sectors, regions, isErrorReload = false) => {
    if (
      brownShareState.portfolioUpdates.data.length > 0 ||
      (brownShareState.portfolioRegions.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    brownShareDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_UPDATES
    })
    let updatesData = []
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter
    try {
      if (isFilterStateAvailable) {
        const { data } = await ResearchLineService.retrieveResearchLineApi({
          portfolio,
          regions,
          sectors,
          asOfDate,
          benchmark,
          checkForEntitlements: false,
          isEntitled: false,
          researchLine: BRN_SHR_MGM.PAGE_URL,
          token: brownShareState.cancelToken.token,
          endPoint: COMMON.UPDATES
        })

        updatesData = data
      }
      brownShareDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_UPDATES,
        data: updatesData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        brownShareDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_UPDATES,
          apiCallId
        })
      }
    }
  }

  const renderBrownShareImpactCategory = (range) => {
    let categoryMap = {
      '0%': 'No Involvement',
      '0-10%': 'Minor Involvement',
      '10-20%': 'Minor Involvement',
      '20-33%': 'Major Involvement',
      '33-50%': 'Major Involvement',
      '>=50%': 'Major Involvement'
    }
    return {
      ...range,
      category: categoryMap[range.category]
    }
  }
  const reduceBrownShareImpactData = (acc, curr) => {
    let category = acc.find((item) => item.category === curr.category)
    if (category) {
      category.selection += curr.selection
      category.total += curr.total
    } else {
      acc.push(curr)
    }
    return acc
  }

  const getImpactCall = (filter, regions, sectors, month, year) => {
    return isFilterStateAvailable
      ? axios.post(
          COMMON.BASE_URL +
            filterState.globalPortfolioFilter.portfolio +
            BRN_SHR_MGM.PAGE_URL +
            COMMON.IMPACT,
          {
            [PARAMS.IMPACT_FILTER]: filter,
            [PARAMS.REGION]: regions,
            [PARAMS.SECTOR]: sectors,
            [PARAMS.MONTH]: month,
            [PARAMS.YEAR]: year
          },
          { cancelToken: brownShareState.cancelToken.token }
        )
      : {}
  }

  const getImpactData = async (sectors, regions, isErrorReload = false) => {
    if (
      (brownShareState.portfolioImpact.data &&
        brownShareState.portfolioImpact.data.length > 0) ||
      (brownShareState.portfolioImpact.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    brownShareDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_IMPACT
    })
    let impactData = {}
    try {
      const month = filterState.globalPortfolioFilter.asOfDate.slice(4)
      const year = filterState.globalPortfolioFilter.asOfDate.slice(0, 4)

      const [top5, top10, bottom5, bottom10] = await axios.all([
        getImpactCall(PAGE_CON.IMPACT_KEY_TOP_5, regions, sectors, month, year),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_TOP_10,
          regions,
          sectors,
          month,
          year
        ),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_BOTTOM_5,
          regions,
          sectors,
          month,
          year
        ),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_BOTTOM_10,
          regions,
          sectors,
          month,
          year
        )
      ])

      impactData[PAGE_CON.IMPACT_KEY_TOP_5] = top5.data[0]
      impactData[PAGE_CON.IMPACT_KEY_TOP_10] = top10.data[0]
      impactData[PAGE_CON.IMPACT_KEY_BOTTOM_5] = bottom5.data[0]
      impactData[PAGE_CON.IMPACT_KEY_BOTTOM_10] = bottom10.data[0]

      Object.keys(impactData).forEach((key) => {
        impactData[key].negative_impact.distribution = impactData[
          key
        ].negative_impact.distribution
          .map(renderBrownShareImpactCategory)
          .reduce(reduceBrownShareImpactData, [])
        impactData[key].positive_impact.distribution = impactData[
          key
        ].positive_impact.distribution
          .map(renderBrownShareImpactCategory)
          .reduce(reduceBrownShareImpactData, [])
      })

      brownShareDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_IMPACT,
        data: impactData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        brownShareDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_IMPACT,
          apiCallId
        })
      }
    }
  }

  const getUnderlyingDataMetrics = async (
    sectors,
    regions,
    isErrorReload = false
  ) => {
    if (
      Object.keys(brownShareState.portfolioUnderlyingDataMetrics.data).length >
        0 ||
      (brownShareState.portfolioUnderlyingDataMetrics.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    brownShareDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS
    })
    let metricsData = []
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter

    try {
      if (isFilterStateAvailable) {
        const { data } = await ResearchLineService.retrieveResearchLineApi({
          portfolio,
          regions,
          sectors,
          asOfDate,
          benchmark,
          researchLine: BRN_SHR_MGM.PAGE_URL,
          token: brownShareState.cancelToken.token,
          endPoint: COMMON.DATA_METRICS
        })
        metricsData = data
      }
      brownShareDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS,
        data: metricsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        brownShareDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_UNDERLYING_DATAMETRICS,
          apiCallId
        })
      }
    }
  }

  const reloadComponent = (errorOccuredOn) => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    switch (errorOccuredOn) {
      case PAGE_CON.LEADERS_TITLE:
      case PAGE_CON.LAGGARDS_TITLE:
        getLeadersLaggardsData(sectors, regions, true)
        break
      case PAGE_CON.REGIONS_TITLE:
        getRegionsData(sectors, regions, true)
        break
      case PAGE_CON.UPDATESIN_TITLE:
        getUpdatesData(sectors, regions, true)
        break
      case PAGE_CON.BROWN_SHARE_UDM_TITLE:
        getUnderlyingDataMetrics(sectors, regions, true)
        break
      case PAGE_CON.IMPACT_TITLE:
        getImpactData(sectors, regions, true)
        break
      default:
        break
    }
  }

  const getBrownShareAssessmentData = useCallback(async () => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    if (filters.portfolio !== '' && !filterState.isLoading) {
      getLeadersLaggardsData(sectors, regions)
      getRegionsData(sectors, regions)
      getUpdatesData(sectors, regions)
      getImpactData(sectors, regions)
      getUnderlyingDataMetrics(sectors, regions)
    }
  }, [filterState.actionData])

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      getBrownShareAssessmentData()
    }
  }, [filterState.actionData])

  useEffect(() => {
    let updateText = `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}, ${PAGE_CON.IMPACT_TITLE}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`

    if (
      !brownShareState.portfolioImpact.isLoading &&
      !brownShareState.portfolioLeaderLaggard.isLoading
    ) {
      if (canShowImpact())
        updateText = `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}, and ${PAGE_CON.IMPACT_TITLE}`
      else updateText = `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}`

      if (canShowLeadersLaggards()) {
        if (canShowImpact())
          updateText = `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}, ${PAGE_CON.IMPACT_TITLE}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`
        else
          updateText = `${PAGE_CON.UPDATESIN_TITLE} ${monthYear}, and Current ${PAGE_CON.LEADERS_TITLE}/${PAGE_CON.LAGGARDS_TITLE}`
      }
    }

    setUpdateSectionText(updateText)
  }, [
    brownShareState.portfolioLeaderLaggard.dataStatus,
    brownShareState.portfolioImpact.dataStatus
  ])

  function selectedCard(selectCardInfo) {
    setSelectedCardId(selectCardInfo.cardId)
  }

  const brownShareMapData =
    brownShareState.portfolioRegions.data &&
    brownShareState.portfolioRegions.data.mapData &&
    brownShareState.portfolioRegions.data.mapData.map((item) => {
      return {
        'hc-key': item[0] || '-',
        value: item[1] !== undefined ? item[1] : '-',
        isBrownShare: true,
        ScoreRangeScore: item[1] !== undefined ? item[1] : '-',
        category: item[2] || '-'
      }
    })

  const getPositiveBarData = () => {
    const positiveData = [
      {
        name: 'total',
        data: [],
        opacity: 0.6
      },
      {
        name: 'selection',
        data: [],
        opacity: 1
      }
    ]
    brownShareState.portfolioImpact.data[
      brownShareState.portfolioImpact.filter
    ]?.positive_impact?.distribution?.map((item) => {
      positiveData[0].data.push(item.total - item.selection)
      positiveData[1].data.push(item.selection)
      return ''
    })

    return positiveData
  }

  const getPositiveBarColor = () => {
    const positiveColor = []

    brownShareState.portfolioImpact.data[
      brownShareState.portfolioImpact.filter
    ]?.positive_impact?.distribution?.map((item) => {
      positiveColor.push(getColor('bsa_category', item.category))
      return ''
    })

    return positiveColor
  }

  const getNegativeBarData = () => {
    const negativeData = [
      {
        name: 'total',
        data: [],
        opacity: 0.6
      },
      {
        name: 'selection',
        data: [],
        opacity: 1
      }
    ]
    brownShareState.portfolioImpact.data[
      brownShareState.portfolioImpact.filter
    ]?.negative_impact?.distribution?.map((item) => {
      negativeData[0].data.push(item.total - item.selection)
      negativeData[1].data.push(item.selection)
      return ''
    })

    return negativeData
  }

  const getNegativeBarColor = () => {
    const negativeColor = []

    brownShareState.portfolioImpact.data[
      brownShareState.portfolioImpact.filter
    ]?.negative_impact?.distribution?.map((item) => {
      negativeColor.push(getColor('bsa_category', item.category))
      return ''
    })

    return negativeColor
  }

  const getTotalCompanies = () => {
    let coverageState = brownShareState?.portfolioESGScore
    if (
      !coverageState?.isLoading &&
      coverageState?.dataStatus === PAGE_CON.SUCCESS
    ) {
      return coverageState?.data?.coverage?.portfolio_coverage?.companies?.split(
        '/'
      )[1]
    }
  }

  const canShowImpact = () => {
    let showImpact = false
    if (
      brownShareState.portfolioImpact.dataStatus === PAGE_CON.ERROR ||
      (brownShareState.portfolioImpact.dataStatus === PAGE_CON.SUCCESS &&
        filterOptions.some(
          (item) =>
            brownShareState.portfolioImpact.data[item.key]?.positive_impact
              ?.investment_and_score?.length > 0 ||
            brownShareState.portfolioImpact.data[item.key]?.negative_impact
              ?.investment_and_score?.length > 0
        ))
    ) {
      showImpact = true
    }

    return showImpact
  }

  const canShowLeadersLaggards = () => {
    let showLeadersLaggardsComp = false
    if (
      brownShareState.portfolioLeaderLaggard.dataStatus === PAGE_CON.ERROR ||
      (brownShareState.portfolioLeaderLaggard.dataStatus === PAGE_CON.SUCCESS &&
        (brownShareState.portfolioLeaderLaggard.data?.leaders?.length > 0 ||
          brownShareState.portfolioLeaderLaggard.data?.laggards?.length > 0))
    ) {
      showLeadersLaggardsComp = true
    }

    return showLeadersLaggardsComp
  }

  const canShowRegionComp = () => {
    let showRegionComp = false
    if (
      brownShareState.portfolioRegions.isLoading ||
      brownShareState.portfolioRegions.dataStatus === PAGE_CON.ERROR ||
      (brownShareState.portfolioRegions.dataStatus === PAGE_CON.SUCCESS &&
        brownShareState.portfolioRegions.data?.summary?.length > 0 &&
        brownShareState.portfolioRegions.data?.details?.length > 0 &&
        brownShareState.portfolioRegions.data?.mapData?.length > 0)
    )
      showRegionComp = true
    return showRegionComp
  }

  const canShowUnderlyingComp = () => {
    let showUnderlyingComp = false

    if (
      brownShareState.portfolioUnderlyingDataMetrics.isLoading ||
      brownShareState.portfolioESGScore.isLoading ||
      brownShareState.portfolioUnderlyingDataMetrics.dataStatus ===
        PAGE_CON.ERROR ||
      (brownShareState.portfolioUnderlyingDataMetrics.dataStatus ===
        PAGE_CON.SUCCESS &&
        // eslint-disable-next-line no-prototype-builtins
        brownShareState.portfolioESGScore.data?.coverage?.portfolio_coverage?.hasOwnProperty(
          'companies'
        ))
    )
      showUnderlyingComp = true
    return showUnderlyingComp
  }

  return (
    <Page
      className={classes.root}
      title={PAGE_CON.PAGE_TITLE}
      id="brownShare-test-id"
    >
      <main className={classes.content}>
        <Container className={classes.container} maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BrownShareAssessmentOverview
                overViewText="brownShareAssessment"
                pageTitle={props.pageHeaderTitle}
                showBenchMark={showBenchMark}
                coverageHeaderWidth={showBenchMark ? '49%' : '49%'}
                pageState={brownShareState}
                pageDispatch={brownShareDispatch}
                apiCallId={apiCallId}
                pageConstant={pageConstant}
              />
            </Grid>
            <Grid item container>
              <a
                href={'#' + PAGE_CON.UPDATES_AND_CURRENT_LEADERS_LAGGARDS}
                style={overviewLinkPadding}
              >
                <IconArrowDown />
                <span style={linkStyle}>{updateSectionText}</span>
              </a>
            </Grid>
            <Grid item container>
              <AppBar
                id={props.id}
                position="sticky"
                className={classes.stickyHeader}
                style={{ top: '30px' }}
              >
                <div
                  id={PAGE_CON.UPDATES_AND_CURRENT_LEADERS_LAGGARDS}
                  style={{ paddingTop: '90px' }}
                >
                  <div className={classes.tableTitle} style={updatesStyle}>
                    {' '}
                    {updateSectionText}{' '}
                  </div>
                </div>
              </AppBar>
              <Grid item xs={12} style={updatesTableStyle}>
                <h6 style={cardHeader}>Updates</h6>
                {brownShareState.portfolioUpdates.isLoading ? (
                  <LoadingMask />
                ) : brownShareState.portfolioUpdates.dataStatus ===
                    PAGE_CON.ERROR ||
                  (brownShareState.portfolioUpdates.dataStatus ===
                    PAGE_CON.SUCCESS &&
                    brownShareState.portfolioUpdates.data.length === 0) ? (
                  <Error
                    id={'updatesError'}
                    errorClass={'noMargin'}
                    width={'101.3%'}
                    margin={0}
                    dataStatus={
                      isFilterStateAvailable
                        ? brownShareState.portfolioUpdates.dataStatus
                        : PAGE_CON.SUCCESS
                    }
                    onErrorClick={reloadComponent}
                    errorOccuredOn={PAGE_CON.UPDATESIN_TITLE}
                    topMargin="5px"
                  />
                ) : (
                  <Updates
                    colInfo={UpdatesTableColInfo}
                    totalData={brownShareState.portfolioUpdates.data}
                    panelColInfo={PanelUpdatesTableColInfo}
                    tableEditable={false}
                    tableLength={10}
                    monthYear={monthYear}
                    showScoreQuality={scoreQualityState.enableScoreQuality}
                  />
                )}
              </Grid>
              {brownShareState.portfolioImpact.isLoading || canShowImpact() ? (
                <Grid item container className={classes.impactContainerWrapper}>
                  {brownShareState.portfolioImpact.isLoading ? (
                    <LoadingMask />
                  ) : brownShareState.portfolioImpact.dataStatus ===
                      PAGE_CON.ERROR ||
                    (brownShareState.portfolioImpact.dataStatus ===
                      PAGE_CON.SUCCESS &&
                      brownShareState.portfolioImpact.data.length === 0) ? (
                    <Error
                      id={'impactError'}
                      dataStatus={
                        isFilterStateAvailable
                          ? brownShareState.portfolioImpact.dataStatus
                          : PAGE_CON.SUCCESS
                      }
                      onErrorClick={reloadComponent}
                      errorOccuredOn={PAGE_CON.IMPACT_TITLE}
                      topMargin="5px"
                    />
                  ) : (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className={classes.impactDropdown}
                      >
                        <span
                          style={{
                            backgroundColor:
                              themeColorPalette.palette.sys_trad.selected2.main,
                            marginBottom: '12px',
                            paddingTop: 4
                          }}
                        >
                          <DropdownField
                            id="impact-filter"
                            dropdownDefaultSelction={
                              brownShareState.portfolioImpact.filter
                            }
                            saveValueUsingExternalState={true}
                            dropdownOptions={filterOptions}
                            onChange={onImpactFilterChange}
                            labelTextDisabled={true}
                            labelText=""
                            alternateLabelText="Top 5"
                            defaultData={''}
                            impactColor={
                              themeColorPalette.palette.sys_trad.selected2.main
                            }
                          />
                        </span>
                      </Grid>
                      <Impact
                        positiveImpactData={
                          brownShareState.portfolioImpact.data[
                            brownShareState.portfolioImpact.filter
                          ]?.positive_impact?.investment_and_score || []
                        }
                        posBarChartData={getPositiveBarData()}
                        posBarColors={getPositiveBarColor()}
                        posBarTitle={`${
                          filterOptions.find(
                            (item) =>
                              item.key ===
                              brownShareState.portfolioImpact.filter
                          ).displayText
                        } vs. Total Investment %`}
                        negativeImpactData={
                          brownShareState.portfolioImpact.data[
                            brownShareState.portfolioImpact.filter
                          ]?.negative_impact?.investment_and_score || []
                        }
                        negBarChartData={getNegativeBarData()}
                        negBarColors={getNegativeBarColor()}
                        negBarTitle={`${
                          filterOptions.find(
                            (item) =>
                              item.key ===
                              brownShareState.portfolioImpact.filter
                          ).displayText
                        } vs. Total Investment %`}
                        impactColumns={impactColumns}
                        impactFilter={brownShareState.portfolioImpact.filter}
                      />
                    </>
                  )}
                </Grid>
              ) : null}
              {brownShareState.portfolioLeaderLaggard.isLoading ||
              canShowLeadersLaggards() ? (
                <Grid item container className={classes.containerWrapper}>
                  <Grid item xs={12} sm={6} style={{ paddingRight: '12px' }}>
                    <h6 style={cardHeader}>
                      {PAGE_CON.PORTFOLIO_LEADERS_BY_SCORE}
                    </h6>
                    {brownShareState.portfolioLeaderLaggard.isLoading ? (
                      <LoadingMask />
                    ) : brownShareState.portfolioLeaderLaggard.dataStatus ===
                        PAGE_CON.ERROR ||
                      (brownShareState.portfolioLeaderLaggard.dataStatus ===
                        PAGE_CON.SUCCESS &&
                        brownShareState.portfolioLeaderLaggard.data.leaders &&
                        brownShareState.portfolioLeaderLaggard.data.leaders
                          .length === 0) ? (
                      <Error
                        id={'leadersError'}
                        dataStatus={
                          isFilterStateAvailable
                            ? brownShareState.portfolioLeaderLaggard.dataStatus
                            : PAGE_CON.SUCCESS
                        }
                        onErrorClick={reloadComponent}
                        errorOccuredOn={PAGE_CON.LEADERS_TITLE}
                        height={'312px'}
                      />
                    ) : (
                      <Leaders
                        colInfo={LeaderLaggardColInfo}
                        totalData={
                          brownShareState.portfolioLeaderLaggard.data.leaders
                        }
                        tableLength={tableLength}
                        tableEditable={false}
                        showScoreQuality={scoreQualityState.enableScoreQuality}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ paddingLeft: '12px' }}>
                    <h6 style={cardHeader}>
                      {PAGE_CON.PORTFOLIO_LAGGARDS_BY_SCORE}
                    </h6>
                    {brownShareState.portfolioLeaderLaggard.isLoading ? (
                      <LoadingMask />
                    ) : brownShareState.portfolioLeaderLaggard.dataStatus ===
                        PAGE_CON.ERROR ||
                      (brownShareState.portfolioLeaderLaggard.dataStatus ===
                        PAGE_CON.SUCCESS &&
                        brownShareState.portfolioLeaderLaggard.data.laggards &&
                        brownShareState.portfolioLeaderLaggard.data.laggards
                          .length === 0) ? (
                      <div style={{ paddingBottom: 8, paddingRight: 8 }}>
                        <Error
                          id={'laggardsError'}
                          dataStatus={
                            isFilterStateAvailable
                              ? brownShareState.portfolioLeaderLaggard
                                  .dataStatus
                              : PAGE_CON.SUCCESS
                          }
                          onErrorClick={reloadComponent}
                          errorOccuredOn={PAGE_CON.LAGGARDS_TITLE}
                          height={'312px'}
                        />
                      </div>
                    ) : (
                      <Laggards
                        colInfo={LeaderLaggardColInfo}
                        totalData={
                          brownShareState.portfolioLeaderLaggard.data.laggards
                        }
                        tableLength={tableLength}
                        tableEditable={false}
                        showScoreQuality={scoreQualityState.enableScoreQuality}
                      />
                    )}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            {canShowRegionComp() ? (
              <>
                <Grid item container>
                  <a
                    href={'#' + PAGE_CON.REGIONS_INDUSTRIES}
                    style={linkPaddingRegionIndustry}
                  >
                    <IconArrowDown />
                    <span style={linkStyle}>
                      {PAGE_CON.REGIONS_TITLE} & {PAGE_CON.SECTORS_TITLE}
                    </span>
                  </a>
                </Grid>
                <Grid item container>
                  <AppBar
                    id={props.id}
                    position="sticky"
                    className={classes.stickyHeader}
                    style={regionTitleStyle}
                  >
                    <div
                      id={PAGE_CON.REGIONS_INDUSTRIES}
                      style={{ paddingTop: '80px' }}
                    >
                      <div
                        data-testid="sector-title"
                        className={classes.tableTitle}
                        style={{ marginTop: '36px' }}
                      >
                        {' '}
                        {PAGE_CON.REGIONS_TITLE} & {PAGE_CON.SECTORS_TITLE}{' '}
                      </div>
                    </div>
                  </AppBar>
                  <Grid item xs={12} style={regionStyle}>
                    {brownShareState.portfolioRegions.isLoading ? (
                      <LoadingMask />
                    ) : brownShareState.portfolioRegions.dataStatus ===
                        PAGE_CON.ERROR ||
                      (brownShareState.portfolioRegions.dataStatus ===
                        PAGE_CON.SUCCESS &&
                        !isRegionHasData()) ? (
                      <Error
                        id={'regionsError'}
                        dataStatus={
                          isFilterStateAvailable
                            ? brownShareState.portfolioRegions.dataStatus
                            : PAGE_CON.SUCCESS
                        }
                        onErrorClick={reloadComponent}
                        errorOccuredOn={PAGE_CON.REGIONS_TITLE}
                      />
                    ) : (
                      <Regions
                        hideMap={true}
                        key={'RegionKey'}
                        data={brownShareState.portfolioRegions.data}
                        displayRegions={[]}
                        styleFirstChild={true}
                        orderByScoreRange={true}
                        selectedCardId={selectedCardId}
                        onRegionSelect={selectedCard}
                        mimTabColInfo={mimTabColInfo}
                        panelTabColInfo={panelTabColInfo}
                        cardInfoContentInfo={cardInfoContentInfo}
                        cardInfoContentInfoSector={cardInfoContentInfoSector}
                        currentPageState={'globalBrownShareAssessment'}
                        mapData={brownShareMapData}
                        mapDataClasses={mapDataClasses}
                        stateData={true}
                        height={
                          brownShareState.portfolioRegions.data &&
                          brownShareState.portfolioRegions.data.summary
                            .length === 1
                            ? '50%'
                            : '100%'
                        }
                        sortByCols={[
                          ['median_score', 'ASC'],
                          ['investment_pct', 'DESC'],
                          ['company_name', 'ASC', 'string']
                        ]}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            ) : null}
            {canShowUnderlyingComp() ? (
              <>
                <Grid item container>
                  <a
                    href={'#' + PAGE_CON.FOSSIL_FUEL_DISCLOSURES}
                    style={linkPadding}
                  >
                    <IconArrowDown />
                    <span style={linkStyle}>
                      {PAGE_CON.FOSSIL_FUEL_DISCLOSURES_TITLE}{' '}
                    </span>
                  </a>
                </Grid>
                <Grid item container>
                  <AppBar
                    id={props.id}
                    position="sticky"
                    className={classes.stickyHeader}
                    style={{ top: '35px' }}
                  >
                    <div
                      id={PAGE_CON.FOSSIL_FUEL_DISCLOSURES}
                      style={{ paddingTop: '83px' }}
                    >
                      <div
                        className={classes.tableTitle}
                        style={dataMetricTitle}
                      >
                        {' '}
                        {PAGE_CON.FOSSIL_FUEL_DISCLOSURES_TITLE}{' '}
                      </div>
                    </div>
                  </AppBar>
                  <Grid item xs={12} style={dataMetricbox}>
                    {brownShareState.portfolioUnderlyingDataMetrics.isLoading ||
                    brownShareState.portfolioESGScore.isLoading ? (
                      <LoadingMask />
                    ) : (brownShareState.portfolioUnderlyingDataMetrics
                        .dataStatus === PAGE_CON.ERROR ||
                        (brownShareState.portfolioUnderlyingDataMetrics
                          .dataStatus === PAGE_CON.SUCCESS &&
                          brownShareState.portfolioUnderlyingDataMetrics.data
                            .length === 0)) &&
                      (brownShareState.portfolioESGScore.dataStatus ===
                        PAGE_CON.ERROR ||
                        (brownShareState.portfolioESGScore.dataStatus ===
                          PAGE_CON.SUCCESS &&
                          // eslint-disable-next-line no-prototype-builtins
                          !brownShareState.portfolioESGScore.data.coverage.portfolio_coverage.hasOwnProperty(
                            'companies'
                          ))) ? (
                      <Error
                        id={'udmError'}
                        dataStatus={
                          isFilterStateAvailable
                            ? brownShareState.portfolioUnderlyingDataMetrics
                                .dataStatus
                            : PAGE_CON.SUCCESS
                        }
                        onErrorClick={reloadComponent}
                        errorOccuredOn={PAGE_CON.BROWN_SHARE_UDM_TITLE}
                      />
                    ) : (
                      <BrownShareAssessmentUnderlyingDataRiskMetrics
                        pageTitle={'Fossil Fuel Disclosures'}
                        totalCompanies={getTotalCompanies()}
                        underlyingDataMetrics={
                          brownShareState.portfolioUnderlyingDataMetrics.data
                        }
                        dataStatus={
                          brownShareState.portfolioUnderlyingDataMetrics
                            .dataStatus
                        }
                        reloadComponent={reloadComponent}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Container>
      </main>
    </Page>
  )
}

export default BrownShareAssessment
