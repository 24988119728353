import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'

const useStyles = makeStyles((theme) => ({
  button: {
    ...theme.typography.p2,
    color: theme.palette.sys_trad?.link.main,
    '& :hover': {
      color: theme.palette.sys_trad?.linkhover.main
    },
    cursor: 'pointer',
    border: 'none',
    background: 'none'
  }
}))

export const Button = ({ children, onClick }) => {
  const classes = useStyles(theme)
  return (
    <button onClick={onClick} className={classes.button}>
      {children}
    </button>
  )
}
