import { COMMON } from '../../constant/APIconstants'
import { EntityService, UserService } from 'services'
import { Toolbar } from '@material-ui/core'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import { PortfolioAnalysis } from '../../views/portfolioanalysis/ReportNavigationPortfolioAnalysis.json'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import Avatar from '@material-ui/core/Avatar'
import axios from 'axios'
import NotFoundView from 'views/error/NotFoundView'
import React, { useContext, useEffect, useRef } from 'react'
import routes from '../../routes'
import TopBar from './TopBar'
import getEntitlements from 'entitlements'
import ESGLogo from '../../icons/esg logo.svg'
import IssuerBanner from 'views/issuer/sector/IssuerBanner'
import { GlobalContext } from 'context/GlobalStateProvider'
import * as S from './styles'

const TabLayout = (props) => {
  const { oktaAuth, authState } = useOktaAuth()
  const [isInitialized, setIsInitialized] = React.useState(false)
  const [entitlements, setEntitlements] = React.useState({})
  const [apiEntitlements, setApiEntitlements] = React.useState([])
  const [contentTypeEntitlements, setContentTypeEntitlements] = React.useState(
    []
  )
  const [account, setAccount] = React.useState({})
  const didMount = useRef(false)

  const [, userSettingDispatch] = useContext(GlobalContext).userSettingService

  const classes = S.useStyles()
  const routes_404 = [
    {
      path: '/404',
      component: <NotFoundView isEntitled={false} />
    },
    {
      component: <Redirect to="/404" />
    }
  ]
  let initials = false

  /**
   * Registers a request interceptor for API requests,
   * which will prepend the OKTA's accesstoken as Bearer token on Authorization Header for each request.
   * Exclude AWS's S3 URL endpoint from this operation, because it doesn't support Access Token Validation.
   */

  const registerRequestInterceptor = () => {
    axios.interceptors.request.use(
      (request) => {
        if (!COMMON.EXCLUDED_URLS.find((url) => request.url.includes(url))) {
          request.headers[
            'Authorization'
          ] = `Bearer ${oktaAuth.getAccessToken()}`
        }
        return request
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

  const getUserSettings = async () => {
    try {
      userSettingDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName: REDUCER_STATE.USER_SETTING
      })

      const data = await UserService.getUserSettings()

      userSettingDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.USER_SETTING,
        data
      })
    } catch (error) {
      console.log('error', error)
      userSettingDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        sectionName: REDUCER_STATE.USER_SETTING
      })
    }
  }

  const saveUserProfile = async () => {
    const { email, firstName, lastName, name } = authState.idToken.claims
    let fName = firstName ? firstName : name ? name.split(',')[1].trim() : ''
    let lName = lastName ? lastName : name ? name.split(',')[0] : ''
    try {
      const displayCFPredictedScoreInLS =
        window.localStorage.displayESGVisible !== undefined
          ? JSON.parse(window.localStorage.displayESGVisible)
          : 1
      UserService.updateUserProfile({
        email,
        fName,
        lName,
        display_cf_predicted_score: displayCFPredictedScoreInLS
      })
    } catch (err) {
      console.log(err)
    }
  }

  const fetchEntitlements = async (isIssuer) => {
    try {
      const data = await UserService.getEntitlements()
      let responseAccount = data?.account
      if (!isIssuer) {
        let entitlements =
          data?.entitlements && data?.entitlements?.map((bundle) => bundle.key)
        window.entitlements = entitlements
        setApiEntitlements(entitlements)
        if (data?.content_type) {
          setContentTypeEntitlements(data?.content_type)
          window.content_type = data?.content_type
        }
      }
      setAccount(responseAccount)
      window.account_name = responseAccount.name
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(async () => {
    if (authState.isAuthenticated) {
      const entitlements = getEntitlements(authState, [], [])
      !props.isTestCase && setEntitlements(entitlements)
      registerRequestInterceptor()
      const isIssuer = entitlements.issuer
      await fetchEntitlements(isIssuer)

      if (!isInitialized) {
        if (isIssuer) {
          await routeIssuer()
          window.userType = 'issuer'
        } else {
          saveUserProfile()
          getUserSettings()
        }
        setIsInitialized(true)
      }

      window.emailID = authState.idToken.claims.email
    }
  }, [authState])

  const isEmpty = (obj) => {
    return obj ? Object.entries(obj).length === 0 : true
  }

  useEffect(() => {
    if (didMount.current && !isEmpty(account)) {
      updateHeapProperties()
    } else {
      didMount.current = true
    }
  }, [entitlements, account])

  if (authState.isPending) return null

  const handleLogOut = async () => {
    await oktaAuth.tokenManager.clear()
    window.localStorage.removeItem('role')
    window.localStorage.removeItem('displayESGVisible')
    await oktaAuth.signOut()
    return <Redirect to="/login" />
  }

  const updateHeapProperties = () => {
    !props.isTestCase &&
      window.heap.identify(authState.idToken.claims.preferred_username)
    !props.isTestCase &&
      window.heap.addUserProperties({
        email: authState.idToken.claims.email,
        Name:
          authState.idToken.claims.firstName +
          ' ' +
          authState.idToken.claims.lastName,
        Groups: JSON.stringify(authState.idToken.claims.groups),
        Organization: account?.name ? account.name : '',
        'Subscriber Type': account?.subscriberType
          ? account.subscriberType
          : '',
        Role: entitlements['issuer']
          ? 'Issuer'
          : entitlements['dashboard']
          ? 'Investor'
          : 'No Role assigned'
      })
  }

  if (authState.isAuthenticated && Object.keys(entitlements).length > 0) {
    var firstInitial = ''
    var lastInitial = ''
    if (authState.idToken.claims.name && authState.idToken.claims.name !== '') {
      const names = authState.idToken.claims.name.split(' ')
      if (names && names.length > 1) {
        firstInitial = names[0].charAt(0)
        lastInitial = names[1].charAt(0)
      }
      if (names && names.length === 1) {
        firstInitial = names[0].charAt(0)
      }
    }
    if (
      authState.idToken.claims.firstName &&
      authState.idToken.claims.firstName !== ''
    ) {
      firstInitial = authState.idToken.claims.firstName.charAt(0)
      if (
        authState.idToken.claims.lastName &&
        authState.idToken.claims.lastName !== ''
      ) {
        lastInitial = authState.idToken.claims.lastName.charAt(0)
      }
    }
    initials = `${firstInitial}${lastInitial}`
  } else {
    initials = ''
  }

  const userAvitar = initials ? (
    <Avatar className={classes.avitar}>{initials.toUpperCase()}</Avatar>
  ) : (
    <Avatar></Avatar>
  )

  const onLogout = authState.isAuthenticated ? handleLogOut : null

  /**
   * Filter out the routes without productionDisabled flag to avoid navigating to them.
   *
   * @param [] routes  - subroutes from routes.js.
   * @return [] routes without productionDisabled flag.
   */

  const filteredRoutes = (routes) => {
    return PAGE_CON.PRODUCTION_ENABLED
      ? routes.filter((route) => {
          const foundMatch = PortfolioAnalysis.find(
            (item) => route.path === item.href
          )
          if (foundMatch) {
            return foundMatch.productionDisabled !== true
          }
          return true
        })
      : routes
  }

  const routeIssuer = async () => {
    try {
      const data = await EntityService.getScoreStatus()

      switch (data['coverage_status']?.toLowerCase()) {
        case PAGE_CON.RESEARCHED:
        case PAGE_CON.RESEARCHED_UNDER_REVIEW:
        case PAGE_CON.PUBLISHED:
        case PAGE_CON.PUBLISHED_UNDER_REVIEW:
          apiEntitlements.push('issuerp3')
          setApiEntitlements(apiEntitlements)
          setContentTypeEntitlements(contentTypeEntitlements)
          break
        case PAGE_CON.DATA_ENRICHED:
        case PAGE_CON.DATA_ENRICHED_UNDER_REVIEW:
        default:
          break
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className={classes.root}>
      {isInitialized && (
        <TopBar
          apiEntitlements={apiEntitlements}
          contentTypeEntitlements={contentTypeEntitlements}
          userAvitar={userAvitar}
          onLogout={onLogout}
          id="tablayout-topbar-test-id"
          getUserSettings={getUserSettings}
        />
      )}
      <div
        className={classes.wrapper}
        style={{
          paddingTop: window.location.pathname.includes('/terms') ? 0 : '48px'
        }}
      >
        <div className={classes.contentContainer}>
          <div className={classes.content} id="div-mainlayout">
            {isInitialized &&
              entitlements &&
              entitlements['issuer'] &&
              !apiEntitlements?.includes('issuerp3') && (
                <IssuerBanner apiEntitlements={apiEntitlements} />
              )}
            <Switch>
              {!authState.isAuthenticated ? (
                <Redirect to="/login" />
              ) : !isInitialized ? null : (entitlements &&
                  entitlements['issuer']) ||
                (apiEntitlements && apiEntitlements.length > 0) ? (
                filteredRoutes(
                  routes.subRoutes(
                    authState,
                    apiEntitlements,
                    contentTypeEntitlements
                  )
                ).map((route, index) => (
                  <Route
                    key={index}
                    exact={route.exact}
                    path={route.path}
                    render={() => route.component}
                  />
                ))
              ) : (
                routes_404.map((route, index) => (
                  <Route
                    key={index}
                    exact={route.exact}
                    path={route.path}
                    render={() => route.component}
                  />
                ))
              )}
            </Switch>
            {isInitialized && entitlements && entitlements['issuer'] && (
              <Toolbar className={classes.footerToolbar}>
                <img src={ESGLogo} alt="" width="140" height="40" />
              </Toolbar>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default TabLayout
