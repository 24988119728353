import { COMMON, ENTITY, PARAMS } from 'constant/APIconstants'
import axios from 'axios'

export const getHeaderInfo = async () => {
  const { data } = await axios.post(ENTITY.BASE_URL + COMMON.HEADER)
  return data[0]
}

export const getScoreStatus = async () => {
  const { data } = await axios.post(
    ENTITY.BASE_URL + COMMON.ISSUER_SCORE_STATUS
  )
  return data
}

export const getIssuerSummary = async () => {
  const { data } = await axios.post(ENTITY.BASE_URL + COMMON.ENTITY_SUMMARY)
  return data[0]
}

export const getEntityControversy = async () => {
  const { data } = await axios.post(ENTITY.BASE_URL + COMMON.ENTITY_CONTROVERSY)
  return data
}

export const getDriverSummary = async () => {
  const { data } = await axios.post(
    ENTITY.BASE_URL + COMMON.ENTITY_DRIVER_SUMMARY
  )
  return data
}

export const getDriverCriteria = async () => {
  const { data } = await axios.post(ENTITY.BASE_URL + COMMON.SECTOR_ALLOCATION)
  return data[0]
}

export const getDriverInfo = async ({ criteria, orbis_id }) => {
  const apiUrl = ENTITY.BASE_URL + COMMON.ENTITY_DRIVER_DETAIL
  let bodyParams = {
    [PARAMS.CRITERIA]: criteria
  }

  if (orbis_id) {
    bodyParams[PARAMS.ORBIS_ID] = orbis_id
  }
  const { data } = await axios.post(apiUrl, bodyParams)
  return data[0]
}

export const getSourceDocuments = async () => {
  const { data } = await axios.post(ENTITY.BASE_URL + COMMON.ISSUER_SOURCE_DOC)
  return data
}

export const uploadMissingDocs = async ({ urls }) => {
  const { data } = await axios.post(ENTITY.BASE_URL + COMMON.MISSING_DOCS, {
    urls
  })
  return data
}

export const urlValidator = async ({ url }) => {
  return await axios.post(ENTITY.BASE_URL + COMMON.URL_VALIDATOR, {
    url
  })
}

export const getESGMateriality = async ({ orbis_id }) => {
  return await axios.post(`${ENTITY.ENTITY_BASE_URL}${ENTITY.MATERIALITY}`, {
    [PARAMS.ORBIS_ID]: orbis_id
  })
}

export const getControversies = async ({ orbis_id }) => {
  return await axios.post(
    `${ENTITY.ENTITY_BASE_URL}${ENTITY.ENTITY_CONTROVERSIES}`,
    {
      [PARAMS.ORBIS_ID]: orbis_id
    }
  )
}

export const getEntityHeader = async ({ orbis_id }) => {
  return await axios.post(`${ENTITY.ENTITY_BASE_URL}${ENTITY.HEADER}`, {
    [PARAMS.ORBIS_ID]: orbis_id
  })
}
