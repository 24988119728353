import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON } from 'constant/constants'
import EsgAssessmentColumns from './EsgAssessmentsColumns.json'
import { multiColumnSort } from 'utils/Sort'
import { Panel, RegularTable, TreeMap } from 'components'
import getColor from 'utils/color'
import numberData from 'utils/numberFormat'
import { AnalysisServices } from 'services'
import LoadingMask from 'utils/loadingMask'
import * as S from './GeographicRisk/styles'
import Error from 'utils/error'

const EegSectorTreeMap = (props) => {
  const [filterState] = useContext(GlobalContext).globalFilter
  const [esgAssessmentsState] = useContext(GlobalContext).globalEsgAssessments
  const colInfo = JSON.parse(
    JSON.stringify(
      EsgAssessmentColumns.esgAssessment_Sector_treemap_Panel_Columns
    )
  )
  const [open, setOpen] = useState(false)
  const [panelSubTitle, setPanelSubTitle] = useState('')
  const [selectedSector, setSelectedSector] = useState(null)
  const initialPanelStatus = { loading: false, status: null }
  const [panelStatus, setPanelStatus] = useState({ ...initialPanelStatus })

  const panelSubTitleInvestment = (sector) => {
    let investment = ''
    if (sector) {
      const summaryData = props.data.summary.filter(
        (sumData) => sumData?.name === sector.trim()
      )
      if (summaryData?.length > 0) {
        investment = `${numberData(summaryData[0].investment_pct, {
          isDecimal: true,
          isPercentage: true,
          keepRawValue: true
        })} Investment`
      }
    }
    return investment
  }

  const openDrawer = () => {
    setOpen(true)
  }

  const getEsgCoverage = async (sector) => {
    const { portfolio, regions, asOfDate } = filterState.globalPortfolioFilter
    if (portfolio) {
      setPanelStatus({ ...panelStatus, loading: true })
      try {
        const esgCoverageData = await AnalysisServices.getEsgCoverage({
          portfolio,
          regions,
          sectors: sector,
          asOfDate
        })

        let subTitle = ''
        let summaryData = props.data.summary.filter(
          (sumData) => sumData?.name === sector.trim()
        )
        if (summaryData?.length > 0) {
          subTitle = `${
            esgCoverageData.portfolio_coverage.companies
          } companies/ ${panelSubTitleInvestment(sector)}`
        }
        setPanelSubTitle(subTitle)
        setPanelStatus({
          ...panelStatus,
          loading: false,
          status: PAGE_CON.SUCCESS
        })
      } catch (error) {
        setPanelStatus({
          ...panelStatus,
          loading: false,
          status: PAGE_CON.ERROR
        })
      }
    }
  }

  const reloadComponent = (errorOccuredOn) => {
    switch (errorOccuredOn) {
      case PAGE_CON.ESG_TREEMAP_ENTITY_LIST:
        getEsgCoverage(selectedSector)
        break
      default:
        break
    }
  }

  const panelContent = () => {
    if (panelStatus.loading) {
      return <LoadingMask />
    }

    if (panelStatus.status !== PAGE_CON.SUCCESS) {
      return (
        <Error
          id={'ESGTreeMapEntityError'}
          dataStatus={panelStatus.status}
          onErrorClick={reloadComponent}
          errorOccuredOn={PAGE_CON.ESG_TREEMAP_ENTITY_LIST}
        />
      )
    }

    if (selectedSector) {
      let sectorData = getSelectedCardData(selectedSector)
      sortCompanyData(sectorData)

      let panelComp = []
      sectorData.map((categoryData, catIndex) => {
        let panelCol = []
        colInfo.map((col) => {
          let newCol = { ...col }
          return panelCol.push(newCol)
        })

        return panelComp.push(
          <div key={`region-${categoryData.category}-Div-${catIndex}`}>
            <div style={{ paddingLeft: 30 }}>{categoryData.category}</div>
            <RegularTable
              id={`regionTab-${categoryData.category}-Div-${catIndex}`}
              heap_id="regsec"
              columns={panelCol}
              totalData={categoryData.CompanyData}
              horScroll="hidden"
              tablePadding="20px"
              tableEditable={false}
              onUploadClick={() => handleCloseDrawer()}
              tableLength={20}
            ></RegularTable>
          </div>
        )
      })

      return panelComp
    }
  }

  useEffect(() => {
    if (selectedSector && !panelStatus.loading && panelStatus.status === null) {
      getEsgCoverage(selectedSector)
    }
  }, [selectedSector, panelStatus.status])

  const getFilterText = (filterType) => {
    let filterResult = []

    switch (filterType) {
      case PAGE_CON.FILTER_REGIONS:
        if (filterState.globalPortfolioFilter.regions === '') {
          filterResult.push({
            key: '',
            [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: PAGE_CON.ALL_REGIONS
          })
        } else
          filterResult = filterState.filterData.regionsList.filter(
            (x) => x.key === filterState.globalPortfolioFilter.regions
          )
        break
      case PAGE_CON.FILTER_SECTORS:
        if (filterState.globalPortfolioFilter.sectors === '') {
          filterResult.push({
            key: '',
            [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: PAGE_CON.ALL_SECTORS
          })
        } else
          filterResult = filterState.filterData.sectorsList.filter(
            (x) => x.key === filterState.globalPortfolioFilter.sectors
          )
        break
      default:
        break
    }

    if (filterResult && filterResult.length > 0)
      return filterResult[0][PAGE_CON.DROPDOWN_DISPLAY_COLUMN]
    return ''
  }

  function getSummaryData() {
    let newData = []
    props.data.summary.map((sumData) => {
      let dataObj = { ...sumData, portfolio_distribution: [] }
      dataObj.name = sumData.name
      dataObj.color = getColor('ESG_ASSESSMENT', sumData.category)
      dataObj.value = sumData.investment_pct
      return newData.push(dataObj)
    })

    return newData
  }

  const handleCloseDrawer = () => {
    setOpen(false)
    setSelectedSector(null)
    setPanelStatus({ ...initialPanelStatus })
  }

  function sortCompanyData(data) {
    let sortByCols = [
      ['score', 'ASC', 'string'],
      ['investment_pct', 'DESC'],
      ['company_name', 'ASC', 'string']
    ]
    let returnData = data
    if (data?.length > 0) {
      returnData = data.forEach((category) =>
        category && category.CompanyData
          ? multiColumnSort(category.CompanyData, sortByCols)
          : null
      )
    }
    return returnData
  }

  const dataCategory = [
    { category: 'A1-A3' },
    { category: 'B1-B3' },
    { category: 'C1-C3' },
    { category: 'E' }
  ]

  function getSelectedCardData(sectorName) {
    let rdata = []

    if (props.data?.details?.length > 0) {
      let summaryData = props.data.summary.filter(
        (sumData) => sumData?.name === sectorName.trim()
      )
      if (summaryData?.length <= 0) return [{ category: '', CompanyData: [] }]

      let rdetailDataArr = props.data.details.filter(
        (sectorData) => sectorData?.name === sectorName.trim()
      )

      if (!rdetailDataArr || rdetailDataArr.length <= 0)
        return [{ category: '', CompanyData: [] }]
      let rdetailData = rdetailDataArr[0]

      dataCategory.map((data) => {
        let categoryData = rdetailData[data.category]
        if (categoryData?.length > 0) {
          let companyData = categoryData.map((dataRow) => {
            dataRow.sector = sectorName
            return dataRow
          })

          rdata.push({ category: data.category, CompanyData: companyData })
        }
        return ''
      })
    }
    return rdata
  }

  function onSectorSelect({ name }) {
    setSelectedSector(name)
    openDrawer()
  }

  const treeMapData = React.useMemo(() => {
    return {
      data: getSummaryData() || [],
      displayText: `${getFilterText(PAGE_CON.FILTER_REGIONS)}, 
                          ${getFilterText(
                            PAGE_CON.FILTER_SECTORS
                          )} - ${numberData(
        props.data.coverage.portfolio_coverage.investment,
        { isDecimal: true, isPercentage: true, keepRawValue: true }
      )}`,
      onClickFunc: onSectorSelect
    }
  }, [esgAssessmentsState.esgSectorTreeMap.dataStatus])

  return (
    <>
      <TreeMap
        height={150}
        data={treeMapData.data}
        headerText={treeMapData.displayText}
        onClick={treeMapData.onClickFunc}
      />
      <Panel
        key={'panelcard-test-id-1'}
        topNum={0}
        title={
          panelStatus.loading
            ? undefined
            : `${getFilterText(PAGE_CON.FILTER_REGIONS)}, ${selectedSector}`
        }
        subtitle={panelStatus.loading ? undefined : panelSubTitle}
        openPanel={open}
        titlePadding="12px"
        onPanelClose={handleCloseDrawer}
        panelContentComponent={
          <S.PanelContainer width="755px">{panelContent()}</S.PanelContainer>
        }
      ></Panel>
    </>
  )
}

export default EegSectorTreeMap
