import { Grid, Paper } from '@material-ui/core'
import React, { useCallback, useContext, useEffect } from 'react'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import Error from 'utils/error'
import LoadingMask from 'utils/loadingMask'
import ControversyDetails from './ControversyDetails'
import * as S from './styles'
import { EntityService } from 'services'

const ClimateControversy = () => {
  const classes = S.useStyles()
  const [climateState, climateDispatch] =
    useContext(GlobalContext).globalClimate

  let apiCallId = climateState.apiCallId

  const [entityHeaderState] = useContext(GlobalContext).globalEntityHeader

  const isControversyHasData = () => {
    let hasData = false
    if (
      climateState.climateControversy.data &&
      Array.isArray(climateState.climateControversy.data.controversies_list) &&
      climateState.climateControversy.data.controversies_list.length > 0
    )
      hasData = true
    return hasData
  }

  const reloadComponent = (errorOccuredOn) => {
    switch (errorOccuredOn) {
      case PAGE_CON.ENTITY_CONTROVERSY:
        getControversies(true)
        break
      case PAGE_CON.ENTITY_CLIMATE_PAGE_CONTROVERSIES_LIST:
        getControversies(true)
        break
      default:
        break
    }
  }
  const getControversies = useCallback(
    async (isErrorReload = false) => {
      if (
        climateState.climateControversy.dataStatus !== '' &&
        isErrorReload === false
      )
        return
      climateDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionNames: [REDUCER_STATE.CLIMATE_CONTROVERSY]
      })
      let controversyData = {}

      try {
        const { data } = await EntityService.getControversies({
          orbis_id: entityHeaderState.globalEntityHeader.orbis_id
        })

        controversyData = data
        climateDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          sectionNames: [
            { [REDUCER_STATE.CLIMATE_CONTROVERSY]: controversyData || {} }
          ],
          apiCallId
        })
      } catch (error) {
        climateDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionNames: [REDUCER_STATE.CLIMATE_CONTROVERSY],
          apiCallId
        })
      }
    },
    [climateDispatch]
  )

  const getEntityOverviewData = useCallback(async () => {
    if (
      entityHeaderState.globalEntityHeader.orbis_id !== '' &&
      !entityHeaderState.isLoading
    ) {
      getControversies()
    }
  }, [entityHeaderState.globalEntityHeader.orbis_id])

  useEffect(() => {
    if (entityHeaderState.globalEntityHeader.orbis_id !== '') {
      getEntityOverviewData()
    }
  }, [entityHeaderState.globalEntityHeader.orbis_id])

  const isError = () => {
    return (
      climateState.climateControversy.dataStatus === PAGE_CON.ERROR ||
      (climateState.climateControversy.dataStatus === PAGE_CON.SUCCESS &&
        !isControversyHasData())
    )
  }

  return (
    <Grid
      container
      className={`${classes.itemStyle} ${isError() ? classes.errorStyle : ''}`}
    >
      <Grid item className={classes.controversiesGridWidth}>
        <Grid item xs={12} className={classes.controversiesGrid}>
          {climateState.climateControversy.isLoading ? (
            <div className={classes.loadingControversies}>
              <LoadingMask />
            </div>
          ) : climateState.climateControversy.dataStatus === PAGE_CON.ERROR ||
            (climateState.climateControversy.dataStatus === PAGE_CON.SUCCESS &&
              !isControversyHasData()) ? (
            <div className={classes.divControversies}>
              <Error
                errorClass={'centerAlign'}
                hideBoxShadow={true}
                id={'controversyError'}
                dataStatus={climateState.climateControversy.dataStatus}
                onErrorClick={reloadComponent}
                errorOccuredOn={PAGE_CON.ENTITY_CLIMATE_PAGE_CONTROVERSIES_LIST}
              />
            </div>
          ) : (
            <Paper className={classes.controversies}>
              <ControversyDetails listHeight={'331px'} />
            </Paper>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ClimateControversy
