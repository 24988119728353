import React from 'react'

const ArrowUp = ({ color = '#B31717' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="12"
      fill="none"
      viewBox="0 0 8 12"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.646.146a.5.5 0 01.708 0l3.5 3.5a.5.5 0 11-.708.708L4.5 1.707V11.5a.5.5 0 01-1 0V1.707L.854 4.354a.5.5 0 11-.708-.708l3.5-3.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default ArrowUp
