import { Grid } from '@material-ui/core'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON } from 'constant/constants'
import {
  Chiclet,
  DropdownField,
  Histogram,
  RegularTable,
  Table
} from 'components'
import { getMonthDayYear } from 'utils/Date'
import getColor from 'utils/color'
import React, { useContext, useState } from 'react'
import themeColorPalette from 'theme/theme'
import ClimateColumns from './ClimateEntity.json'
import LoadingMask from 'utils/loadingMask'
import Error from 'utils/error'
import mockPRMSectorChartData from './mockPRMSectorChartData.json' //TODO: This is mock data, should be removed after integration
import * as S from './styles'

const PhysicalRiskUDM = ({ reloadComponent }) => {
  const classes = S.useStyles()
  const [climateState] = useContext(GlobalContext).globalClimate
  const [currentPhysicalGraph, setCurrentPhysicalGraph] =
    useState('operationsRisk')

  const oprRiskColumn = JSON.parse(
    JSON.stringify(ClimateColumns.phyOprRiskcolumns)
  )
  const marketRiskColumn = JSON.parse(
    JSON.stringify(ClimateColumns.phyMarketRiskcolumns)
  )
  const supplyChainRiskColumn = JSON.parse(
    JSON.stringify(ClimateColumns.phySupplyChainRiskcolumns)
  )
  const supplyRiskMangmntColumn = JSON.parse(
    JSON.stringify(ClimateColumns.phyRiskManagementcolumns)
  )

  function isLoading() {
    return climateState.phyOperationRisk.isLoading
  }

  function isError() {
    return climateState.phyOperationRisk.dataStatus === PAGE_CON.ERROR
  }

  const phyOperationRiskHasData = () => {
    return (
      Object.keys(climateState.phyOperationRisk.data).length > 0 &&
      climateState.phyOperationRisk.data?.underlying_metrics?.length > 0
    )
  }

  const phyMarketRiskHasData = () => {
    return (
      Object.keys(climateState.phyMarketRisk.data).length > 0 &&
      climateState.phyMarketRisk.data?.underlying_metrics?.length > 0
    )
  }

  const phySupplyChainRiskHasData = () => {
    return (
      Object.keys(climateState.phySupplyChainRisk.data).length > 0 &&
      climateState.phySupplyChainRisk.data?.underlying_metrics?.length > 0
    )
  }

  // Common functions to manipulate graph data

  const getCategories = (sortedData) => sortedData.map((val) => val.category)
  const getYaxisValues = (histogram) => histogram.map((val) => val.y)
  const calculateSum = (yAxisValues) =>
    yAxisValues.reduce((partialSum, a) => partialSum + a, 0)

  const between = (x, min, max) => {
    const rounded = Math.round(x)
    return rounded >= min && rounded <= max
  }
  const computeRiskLevel = (val, isPhysicalRiskMgm = false) => {
    if (isPhysicalRiskMgm) {
      switch (true) {
        case val >= 0 && val < 30:
          return ['weak', 'Weak']
        case val >= 30 && val < 50:
          return ['limited', 'Limited']
        case val >= 50 && val < 60:
          return ['robust', 'Robust']
        case val >= 60 && val <= 100:
          return ['advanced', 'Advanced']
        default:
          break
      }
    } else {
      if (between(val, 0, 19)) {
        return ['norsk', 'No Risk']
      } else if (between(val, 20, 39)) {
        return ['lowrsk', 'Low Risk']
      } else if (between(val, 40, 59)) {
        return ['medrsk', 'Medium Risk']
      } else if (between(val, 60, 79)) {
        return ['hirsk', 'High Risk']
      } else {
        return ['redflgrsk', 'Red Flag Risk']
      }
    }
  }

  const getSelected = (data, selected) => {
    let selectedItem = data.find((info) => info.name === selected)
    if (!selectedItem)
      selectedItem = { name: '', y: '', category: '', isSelected: false }
    return selectedItem
  }

  const isSelectedEntity = (companyName, selected) => {
    return companyName === selected ? true : false
  }

  const onChangeGraphSelection = (event) => {
    setCurrentPhysicalGraph(event.target.value)
  }

  const dataProvider = (graphType) => {
    if (graphType === 'operationsRisk') {
      return climateState.phyOperationalRiskChart?.data
    } else if (graphType === 'marketRisk') {
      return climateState.phyMarketRiskChart?.data
    } else if (graphType === 'physicalRiskMgm') {
      return mockPRMSectorChartData //TODO: This is mock data, should be replaced with actual data during integration
    } else {
      return climateState.phySupplyChainChart?.data
    }
  }

  const getScoreLabel = (type) => {
    if (type === 'operationsRisk') {
      return 'Operation Risk Score'
    } else if (type === 'marketRisk') {
      return 'Market risk score'
    } else if (type === 'physicalRiskMgm') {
      return 'Physical Risk Management Score'
    } else {
      return 'Supplychain score'
    }
  }

  const formatTableData = (data = []) => {
    const dataField = []
    ;[...data].forEach((e, i) => {
      const value_score = e.score < 0 ? e.score_msg : e.score
      if (i !== 0) {
        dataField.push([
          <span style={{ paddingLeft: '15px' }} key={'title'}>
            {e.title}
          </span>,
          <span data-testid="score_category_test" key={'score_category'}>
            {e.score_category}
          </span>,
          <span key={'score'}>{value_score}</span>
        ])
      } else {
        dataField.push([
          <span key={'title'}> {e.title}</span>,
          <span data-testid="score_category_test" key={'score_category'}>
            {e.score_category}
          </span>,
          <span key={'score'}>{value_score}</span>
        ])
      }
    })
    return dataField
  }
  const phyRiskMgmtUDMHasData = () => {
    if (climateState.PhyRiskMgmUDM.data.score_category === 'No info') {
      return false
    }

    return (
      Object.keys(climateState.PhyRiskMgmUDM.data).length > 0 &&
      climateState.PhyRiskMgmUDM.data?.underlying_metrics?.length > 0
    )
  }
  const getGraphData = React.useMemo(() => {
    const graphData = dataProvider(
      currentPhysicalGraph
    )?.sector_distribution?.map(
      ({ company_name: name, score: y, score_category }) => {
        return {
          name,
          y,
          category: score_category,
          isSelected: isSelectedEntity(
            name,
            dataProvider(currentPhysicalGraph)?.company_name
          )
        }
      }
    )
    const tooltipScoreLabel = getScoreLabel(currentPhysicalGraph)

    if (graphData?.length > 0) {
      const selected = getSelected(
        graphData,
        dataProvider(currentPhysicalGraph)?.company_name
      )

      const middleTopLabel = `${selected.name} compared to ${
        graphData.length - 1
      } companies in ${dataProvider(currentPhysicalGraph)?.mesg_sector}`
      const avg = Math.round(
        calculateSum(getYaxisValues(graphData)) / graphData.length
      )
      const sectorRisk = computeRiskLevel(
        avg,
        currentPhysicalGraph === 'physicalRiskMgm'
      )

      const legends = [
        {
          name: dataProvider(currentPhysicalGraph)?.mesg_sector,
          risk: avg,
          category: sectorRisk[0]
        },
        {
          name: selected.name,
          risk: selected.y,
          category: 'status'
        }
      ]

      const colorPalette =
        currentPhysicalGraph === 'physicalRiskMgm'
          ? themeColorPalette.palette.adv_wk
          : themeColorPalette.palette.noflag

      return {
        data: graphData,
        categories: getCategories(graphData),
        totalCount: graphData.length,
        avg,
        labels: {
          yTop: currentPhysicalGraph === 'physicalRiskMgm' ? 'Advanced' : '100',
          yBottom: currentPhysicalGraph === 'physicalRiskMgm' ? 'Weak' : '0',
          xLeft:
            currentPhysicalGraph === 'physicalRiskMgm' ? '0' : 'Least Risk',
          xRight:
            currentPhysicalGraph === 'physicalRiskMgm' ? '100' : 'Most Risk'
        },
        graphColorCategory:
          currentPhysicalGraph === 'physicalRiskMgm' ? 'category' : 'risk',
        selected,
        middleTopLabel,
        legends,
        colorPalette,
        plotLineColor: sectorRisk[0],
        tooltipScoreLabel
      }
    } else {
      return {}
    }
  }, [climateState.phyOperationalRiskChart.dataStatus, currentPhysicalGraph])
  const valueColor = themeColorPalette.palette.sys_trad.white.main
  const valueBgColor = getColor(
    'category',
    climateState?.PhyRiskMgmUDM?.data?.score_category?.toUpperCase()
  )
  return (
    <Grid container>
      {isLoading() ? (
        <LoadingMask />
      ) : isError() ? (
        <Error
          id={'phyError'}
          dataStatus={PAGE_CON.ERROR}
          onErrorClick={reloadComponent}
          errorOccuredOn={PAGE_CON.CLIMATE_PHY_RISK}
        />
      ) : (
        <div className={classes.containerMinWidth}>
          <Grid
            item
            container
            xs={12}
            sm={'auto'}
            direction="column"
            className={classes.parentContainer}
          >
            <div
              key={'container-id-1'}
              style={{ width: 368, paddingRight: '12px' }}
            >
              {!phyOperationRiskHasData() ? (
                <>
                  <div
                    className={classes.overallRiskLabel}
                    style={{
                      color: themeColorPalette.palette.sys_trad.text.main,
                      paddingTop: 24
                    }}
                  >
                    {'OPERATIONS RISK'}
                  </div>
                  <div className={classes.noDataErrorStyle}>
                    <Error
                      id={'oprErr'}
                      dataStatus={PAGE_CON.SUCCESS}
                      errorOccuredOn={PAGE_CON.CLIMATE_PHY_RISK}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={classes.overallRiskLabel}
                    style={{ paddingTop: 24 }}
                  >
                    {`${climateState.phyOperationRisk.data.research_line}`}
                  </div>
                  <RegularTable
                    id={'table-id-1'}
                    columns={oprRiskColumn}
                    totalData={
                      climateState.phyOperationRisk.data.underlying_metrics
                    }
                    tableEditable={false}
                  />
                </>
              )}
            </div>
            <div
              key={'container-id-2'}
              style={{ width: 258, paddingRight: '12px' }}
            >
              {!phyMarketRiskHasData() ? (
                <>
                  <div
                    className={classes.overallRiskLabel}
                    style={{
                      color: themeColorPalette.palette.sys_trad.text.main,
                      paddingTop: 36
                    }}
                  >
                    {'MARKET RISK'}
                  </div>
                  <div className={classes.noDataErrorStyle}>
                    <Error
                      id={'marketErr'}
                      dataStatus={PAGE_CON.SUCCESS}
                      errorOccuredOn={PAGE_CON.CLIMATE_PHY_RISK}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={classes.overallRiskLabel}
                    style={{ paddingTop: 36 }}
                  >
                    {`${climateState.phyMarketRisk.data.research_line}`}
                  </div>
                  <RegularTable
                    id={'table-id-2'}
                    columns={marketRiskColumn}
                    totalData={
                      climateState.phyMarketRisk.data.underlying_metrics
                    }
                    tableEditable={false}
                  />
                </>
              )}
            </div>
            <div
              key={'container-id-3'}
              style={{ width: 258, paddingRight: '12px' }}
            >
              {!phySupplyChainRiskHasData() ? (
                <>
                  <div
                    className={classes.overallRiskLabel}
                    style={{
                      color: themeColorPalette.palette.sys_trad.text.main,
                      paddingTop: 36
                    }}
                  >
                    {'SUPPLY CHAIN RISK'}
                  </div>
                  <div className={classes.noDataErrorStyle}>
                    <Error
                      id={'supplyChainErr'}
                      dataStatus={PAGE_CON.SUCCESS}
                      errorOccuredOn={PAGE_CON.CLIMATE_PHY_RISK}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={classes.overallRiskLabel}
                    style={{ paddingTop: 36 }}
                  >
                    {`${climateState.phySupplyChainRisk.data.research_line}`}
                  </div>
                  <RegularTable
                    id={'table-id-3'}
                    columns={supplyChainRiskColumn}
                    totalData={
                      climateState.phySupplyChainRisk.data.underlying_metrics
                    }
                    tableEditable={false}
                  />
                </>
              )}
            </div>
            <div className={classes.linkStyle}>
              {climateState.phySupplyChainRisk.data.last_updated_date && (
                <span className={classes.physicalRiskdateStyle}>
                  {'Updated on '}
                  {getMonthDayYear(
                    climateState?.phySupplyChainRisk?.data?.last_updated_date,
                    'month day, year'
                  )}
                </span>
              )}
            </div>

            <div
              key={'container-id-4'}
              style={{ width: 368, paddingRight: '12px' }}
            >
              {!phyRiskMgmtUDMHasData() ? (
                <>
                  <div
                    className={classes.overallRiskLabel}
                    style={{
                      color: themeColorPalette.palette.sys_trad.text.main,
                      paddingTop: 20
                    }}
                  >
                    {'PHYSICAL RISK MANAGEMENT'}
                  </div>
                  <div className={classes.noDataErrorStyle}>
                    <Error
                      id={'physicalRiskManagmentErr'}
                      dataStatus={PAGE_CON.SUCCESS}
                      errorOccuredOn={PAGE_CON.CLIMATE_PHY_RISK}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.PhyRiskMgmLabel}>
                    <span className={classes.physicalRiskchicletPrefix}>
                      {'PHYSICAL RISK MANAGEMENT'}
                    </span>
                    <span>
                      <Chiclet
                        id="physRskUDM_chiclet-id"
                        valueClassName={'customDisplay'}
                        value={climateState.PhyRiskMgmUDM?.data?.score_category?.toUpperCase()}
                        valueColor={valueColor}
                        valueBgColor={valueBgColor}
                      />
                    </span>
                  </div>
                  <div
                    data-testid="table-id-4"
                    style={{ paddingRight: '12px', width: '368px' }}
                  >
                    <Table
                      id={'table-id-4'}
                      headers={supplyRiskMangmntColumn}
                      data={formatTableData(
                        climateState?.PhyRiskMgmUDM?.data?.underlying_metrics
                      )}
                    />
                  </div>
                </>
              )}
            </div>
            <div className={classes.linkStyle}>
              {climateState.PhyRiskMgmUDM.data.last_updated_date && (
                <span className={classes.physicalRiskdateStyle}>
                  {'Updated on '}
                  {getMonthDayYear(
                    climateState?.PhyRiskMgmUDM?.data?.last_updated_date,
                    'month day, year'
                  )}
                </span>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={'auto'}>
            <Grid item container xs={12} sm={'auto'} direction="column">
              <div className={classes.listButton}>
                <DropdownField
                  id="dropdown-graph-selection-id"
                  dropdownDefaultSelction={currentPhysicalGraph}
                  onChange={onChangeGraphSelection}
                  dropdownOptions={[
                    {
                      key: 'operationsRisk',
                      displayText: 'Physical Climate Risk: Operations Risk'
                    },
                    {
                      key: 'marketRisk',
                      displayText: 'Physical Climate Risk: Market Risk'
                    },
                    {
                      key: 'supplyChainRisk',
                      displayText: 'Physical Climate Risk: Supply Chain Risk'
                    }
                  ]}
                />
              </div>
              {!getGraphData?.data?.length ? (
                <>
                  <div className={classes.histogramChartNoDataError}>
                    <Error
                      id={'oprChartErr'}
                      dataStatus={PAGE_CON.SUCCESS}
                      errorOccuredOn={PAGE_CON.SECTOR_COMPARISON}
                    />
                  </div>
                </>
              ) : (
                <div key={'container-id-histogram'}>
                  <Grid item className={classes.histogramChart}>
                    <Histogram
                      id="barchart-test-id"
                      width={748}
                      height={165}
                      title=""
                      data={getGraphData.data}
                      categories={getGraphData.categories}
                      totalCount={getGraphData.totalCount}
                      avg={getGraphData.avg}
                      labels={getGraphData.labels}
                      selected={getGraphData.selected}
                      middleTopLabel={getGraphData.middleTopLabel}
                      legends={getGraphData.legends}
                      colorPalette={getGraphData.colorPalette}
                      graphColorCategory={getGraphData.graphColorCategory}
                      itemColorCategory={'category'}
                      tooltipScoreLabel={getGraphData.tooltipScoreLabel}
                      plotLineColor={getGraphData.plotLineColor}
                    />
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </Grid>
  )
}

export default PhysicalRiskUDM
