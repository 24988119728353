import React from 'react'
import * as S from './styles'
import PropTypes from 'prop-types'
import { ArrowDownIcon, ArrowUpIcon } from 'components'
import { researchLinesColors } from 'utils/arrows'

const Arrows = ({ prev, curr, researchLine }) => {
  const { upColor, downColor } = researchLinesColors(researchLine)

  return (
    <S.Container>
      {prev !== curr && (
        <>
          {curr > prev ? (
            <ArrowUpIcon color={upColor} />
          ) : (
            <ArrowDownIcon color={downColor} />
          )}
        </>
      )}
    </S.Container>
  )
}

Arrows.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  closeAction: PropTypes.func
}

export default Arrows
