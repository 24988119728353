import { EXPORT, PARAMS, REPORTING_SERVICE } from 'constant/APIconstants'
import { getMonthFromFilter, getYearFromFilter } from 'utils/Date'
import axios from 'axios'

export const getExportClimateData = async ({
  portfolio = '00000000-0000-0000-0000-000000000000',
  regions = 'all',
  sectors = 'all',
  asOfDate = '202106',
  portfolioName = 'Sample Portfolio',
  ondemand_page = false
}) => {
  const month = getMonthFromFilter(asOfDate)
  const year = getYearFromFilter(asOfDate)
  regions = regions ? regions : 'all'
  sectors = sectors ? sectors : 'all'

  const { data } = await axios.post(
    EXPORT.BASE_URL + portfolio + EXPORT.EXPORT_CLIMATE_DATA,
    {
      [PARAMS.REGION]: regions,
      [PARAMS.SECTOR]: sectors,
      [PARAMS.MONTH]: month,
      [PARAMS.YEAR]: year,
      [PARAMS.PORTFOLIO_NAME]: portfolioName,
      [PARAMS.ONDEMAND_PAGE]: ondemand_page
    }
  )
  return data
}

export const getUploadJsonUrl = async ({ portfolioId, fileName }) => {
  return await axios.get(
    EXPORT.BASE_URL +
      portfolioId +
      EXPORT.UPLOAD_JSON_URL +
      '?' +
      PARAMS.FILE_NAME +
      '=' +
      fileName
  )
}
export const getExcelReport = async ({ portfolioId, xlsFileName }) => {
  return await axios.get(
    EXPORT.BASE_URL +
      portfolioId +
      EXPORT.EXCEL_REPORT +
      '?' +
      PARAMS.FILE_NAME +
      '=' +
      xlsFileName
  )
}
export const postExcelReport = async ({ portfolio, esgConstant, fileName }) => {
  return await axios.post(
    EXPORT.BASE_URL + portfolio + esgConstant + EXPORT.EXCEL_EXPORT,
    fileName
  )
}
export const postPdfExport = async ({
  fileName,
  research_line,
  portfolio_id,
  month,
  year,
  sector,
  region,
  benchmark,
  filter,
  reportType
}) => {
  return await axios.post(
    EXPORT.BASE_URL + portfolio_id + '/' + research_line + EXPORT.PDF_EXPORT,
    {
      region,
      sector,
      month,
      year,
      benchmark,
      fileName,
      filter,
      reportType
    }
  )
}

export const checkStatus = async ({ request_id }) => {
  const { data } = await axios.post(
    REPORTING_SERVICE.BASE_URL + EXPORT.PDF_REPORT_STATUS,
    {
      request_id
    }
  )

  return data[0]
}

export const getReportUrl = async ({ request_id }) => {
  const { data } = await axios.post(
    REPORTING_SERVICE.BASE_URL + EXPORT.PDF_DOWNLOAD,
    {
      request_id
    }
  )

  return data
}
