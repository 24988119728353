import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconReview = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 4.5C1 3.67157 1.67157 3 2.5 3H13.5C14.3284 3 15 3.67157 15 4.5V5.5H14V4.5C14 4.22386 13.7761 4 13.5 4H2.5C2.22386 4 2 4.22386 2 4.5V12.5C2 12.7761 2.22386 13 2.5 13H3.5V14H2.5C1.67157 14 1 13.3284 1 12.5V4.5Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 1.5C5.5 1.22386 5.72386 1 6 1H10C10.2761 1 10.5 1.22386 10.5 1.5V3C10.5 3.27614 10.2761 3.5 10 3.5C9.72386 3.5 9.5 3.27614 9.5 3V2H6.5V3C6.5 3.27614 6.27614 3.5 6 3.5C5.72386 3.5 5.5 3.27614 5.5 3V1.5Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 11C11.0523 11 11.5 10.5523 11.5 10C11.5 9.44771 11.0523 9 10.5 9C9.94771 9 9.5 9.44771 9.5 10C9.5 10.5523 9.94771 11 10.5 11ZM10.5 12C11.6046 12 12.5 11.1046 12.5 10C12.5 8.89543 11.6046 8 10.5 8C9.39543 8 8.5 8.89543 8.5 10C8.5 11.1046 9.39543 12 10.5 12Z"
      fill="#26415E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7933 10.5C14.0928 9.56568 12.5868 8 10.5 8C8.41324 8 6.90724 9.56567 6.2067 10.5C6.90724 11.4343 8.41324 13 10.5 13C12.5868 13 14.0928 11.4343 14.7933 10.5ZM15.6855 10.0253C14.9951 9.0688 13.1909 7 10.5 7C7.80913 7 6.00488 9.0688 5.31449 10.0253C5.10726 10.3124 5.10726 10.6876 5.31449 10.9747C6.00488 11.9312 7.80913 14 10.5 14C13.1909 14 14.9951 11.9312 15.6855 10.9747C15.8927 10.6876 15.8927 10.3124 15.6855 10.0253Z"
      fill="#26415E"
    />{' '}
  </SvgIcon>
)
IconReview.displayName = 'IconReview'
IconReview.muiName = 'SvgIcon'

export default IconReview
