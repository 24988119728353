import React, { useContext } from 'react'
import { ErrorComponent } from 'components'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'

/**
 * @visibleName Error Component
 */
function Error(props) {
  const context = useContext(GlobalContext)
  const [filterState, filterDispatch] = context.globalFilter
  const onUpload = () => {
    if (props.onUploadClick) props.onUploadClick()

    filterDispatch({
      type: REDUCER_TYPE.UPLOAD_PORTFOLIO_MODAL,
      payload: true,
      uploadType: 'portfolio',
      uploadInitiatedFrom: PAGE_CON.ERROR
    })
  }

  const getFilterText = (filterType) => {
    let filterResult = []

    switch (filterType) {
      case PAGE_CON.FILTER_REGIONS:
        if (filterState.globalPortfolioFilter.regions === '') {
          filterResult.push({
            key: '',
            [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: PAGE_CON.ALL_REGIONS
          })
        } else {
          filterResult = filterState.filterData.regionsList.filter(
            (x) => x.key === filterState.globalPortfolioFilter.regions
          )
        }
        break
      case PAGE_CON.FILTER_SECTORS:
        if (filterState.globalPortfolioFilter.sectors === '') {
          filterResult.push({
            key: '',
            [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: PAGE_CON.ALL_SECTORS
          })
        } else {
          filterResult = filterState.filterData.sectorsList.filter(
            (x) => x.key === filterState.globalPortfolioFilter.sectors
          )
        }
        break
      case PAGE_CON.FILTER_ASOFDATE:
        filterResult = filterState.filterData.asOfDateList.filter(
          (x) => x.key === filterState.globalPortfolioFilter.asOfDate
        )
        break
      default:
        break
    }

    if (filterResult && filterResult.length > 0)
      return filterResult[0][PAGE_CON.DROPDOWN_DISPLAY_COLUMN]
    return ''
  }

  const getUpdateErrorData = () => {
    const obj = {
      text1: '',
      text2: '',
      text1ClassName: ''
    }
    const sector = getFilterText(PAGE_CON.FILTER_SECTORS)
    const region = getFilterText(PAGE_CON.FILTER_REGIONS)

    switch (props.errorOccuredOn) {
      case PAGE_CON.POS_IMPACT_TABLE_TITLE:
        obj.text1 =
          'There are no companies in this portfolio that contribute to positive impacts.'
        obj.text2 = `There are no positive impacts in ${region}, ${sector} in your portfolio.`
        obj.text1ClassName = 'redColorStyle'

        break

      case PAGE_CON.ON_DEMAND_ASMNT_CRITERIA_FILTER_VIEW:
      case PAGE_CON.ON_DEMAND_ASMNT_BATCH_REVIEW:
      case PAGE_CON.ENTITY_DRIVER_SUMMARY:
        obj.text1 = 'No information available.'
        obj.text1ClassName = 'blueColorStyle'
        break

      case PAGE_CON.ISSUER_SOURCE_DOCUMENTS:
        obj.text1 = 'No source documents available.'
        obj.text1ClassName = 'blueColorStyle'
        break

      case PAGE_CON.ENTITY_SOURCE_DOCUMENTS:
        obj.text1 = 'No source documents available.'
        obj.text1ClassName = 'blueColorStyle'
        break

      case PAGE_CON.NEG_IMPACT_TABLE_TITLE:
        obj.text1 =
          'There are no companies in this portfolio that contribute to negative impacts.'
        obj.text2 = `There are no negative impacts in ${region}, ${sector} in your portfolio.`
        obj.text1ClassName = 'greenColorStyle'
        break

      case PAGE_CON.DASHBOARD_PORTFOLIOMONITORING_CONTROVERSIES_LIST:
        obj.text1 = 'No ESG Incidents to display.'
        obj.text2 = `There are no ESG Incidents in this portfolio in ${region}, ${sector} over the selected time period.`
        obj.text1ClassName = 'greenColorStyle'
        break

      case PAGE_CON.ENTITY_CLIMATE_PAGE_CONTROVERSIES_LIST:
        obj.text1 = 'No ESG Incidents to display.'
        obj.text1ClassName = 'greenColorStyle'
        break

      case PAGE_CON.ISSUER_CONTROVERSIES_LIST:
        obj.text1 = 'No ESG Incidents to display.'
        obj.text1ClassName = 'greenColorStyle'
        break

      case PAGE_CON.CLIMATE_SUMMARY:
      case PAGE_CON.CLIMATE_PHY_RISK:
      case PAGE_CON.CLIMATE_TRAN_RISK:
        obj.text1 = 'No information available.'
        obj.text1ClassName = 'blueColorStyle'
        break

      case PAGE_CON.CLIMATE_ENTITY_PAGE_PROJECTION_GRAPH:
        obj.text1 = 'Not enough information for a projection.'
        obj.text1ClassName = 'redColorStyle'
        break

      case PAGE_CON.SECTOR_COMPARISON:
        obj.text1 = 'No sector comparison chart available.'
        obj.text1ClassName = 'blueColorStyle'
        break

      case PAGE_CON.REPORTING_STATUS:
        obj.text1 = 'Export failed because of an issue, please try again later.'
        break

      case PAGE_CON.PORTFOLIO_HISTORY:
        obj.text1 = 'No previously downloaded reports to be displayed.'
        obj.text1ClassName = 'greenColorStyle'
        break

      case PAGE_CON.REPORTING_SERVICE:
        obj.text1 = 'No data available for this portfolio.'
        obj.text1ClassName = 'blueColorStyle'
        break

      default:
        obj.text1 = 'No updates for this time period.'
        obj.text2 = `There are no updates in ${region}, ${sector} for the time period you selected.`
        obj.text1ClassName = 'blueColorStyle'
        break
    }
    return obj
  }

  const val = getUpdateErrorData()

  let gridStyle = 'gridStyle'

  if (props.errorClass) gridStyle = props.errorClass
  else if (props.dataStatus === PAGE_CON.SUCCESS) gridStyle = 'noDataStyle'

  return (
    <ErrorComponent
      {...props}
      onUpload={() => onUpload()}
      onErrorClick={() => props.onErrorClick(props.errorOccuredOn)}
      dataStatus={props.dataStatus}
      noDataText1={val.text1}
      noDataText2={val.text2}
      noDataText1ClassName={val.text1ClassName}
      errorClass={gridStyle}
    />
  )
}

export default Error
