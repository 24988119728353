import bullet from 'highcharts/modules/bullet.js'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import React from 'react'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

bullet(Highcharts)
const borderRadius = require('highcharts-rounded-corners')
borderRadius(Highcharts)

const StackedVert = ({
  title,
  data,
  chartHeight,
  chartWidth,
  colHeight,
  colWidth,
  barColors,
  xAxisTitles,
  tooltipEnabled,
  multilineTooltip,
  isScoreRange
}) => {
  const options = {
    credits: {
      enabled: false
    },
    chart: {
      backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
      type: 'column',
      width: chartWidth ? chartWidth : 288,
      height: chartHeight ? chartHeight : 150,
      style: {
        ...textTheme.typography.p4,
        fontSize: '10px !important'
      }
    },
    title: {
      text: title,
      align: 'left',
      style:
        title === '.'
          ? {
              color: themeColorPalette.palette.white.main,
              ...textTheme.typography.p2
            }
          : {
              ...textTheme.typography.p2
            }
    },
    xAxis: {
      labels: {
        style: {
          ...textTheme.typography.p2,
          fontSize: '10px',
          color: textTheme.palette.sys_trad.text.main
        }
      },
      height: colHeight ? colHeight : 121,
      categories: xAxisTitles,
      lineWidth: 0
    },
    yAxis: {
      height: colHeight ? colHeight : 121,
      min: 0,
      visible: false,
      title: {
        text: 'Y Axis'
      }
    },
    legend: {
      reversed: true,
      enabled: false
    },
    tooltip: {
      enabled: tooltipEnabled ? tooltipEnabled : false,
      formatter: function () {
        if (multilineTooltip) {
          const { range } = this.series.userOptions
          const { index } = this.point
          return range?.map(({ data, name }) => {
            const num_companies = data[index].num_companies || 0
            const scoreText =
              name === 'N/A' ? 'Not Covered' : `<b>Score Range: ${name}</b>`
            return `${scoreText} <br /><b>Number of Companies: ${num_companies}</b> <br />`
          })
        }

        let name =
          this.series.userOptions.name === 'N/A'
            ? 'Not Covered'
            : isScoreRange === true
            ? '<b> Score Range: </b>' + this.series.userOptions.name
            : '<b> Score Category: </b>' + this.series.userOptions.name
        let numCompanies = this.point.companies
        return name + '<br>' + '<b>Number of Companies: </b>' + numCompanies
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 1,
        groupPadding: 0.04,
        pointWidth: colWidth ? colWidth : undefined,
        borderRadius: '1px',
        states: {
          inactive: {
            enabled: false
          }
        }
      },
      series: {
        stacking: 'percent' //normal (true value height) or percent (all even heights)
      }
    },
    enableMouseTracking: true,
    colors: barColors,
    series: data
  }
  return <HighchartsReact highcharts={Highcharts} options={options} />
}

StackedVert.propTypes = {
  /** Title of the StackedVert bar */
  title: PropTypes.string,

  /** Data to be displayed */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(
          PropTypes.shape({
            x: PropTypes.number,
            y: PropTypes.number,
            companies: PropTypes.number
          })
        )
      ])
    })
  ),

  /** color code to be filled in StackedVert bar */
  barColors: PropTypes.arrayOf(PropTypes.string),
  /** no percent for tooltip and visual chart label */
  notPercent: PropTypes.bool,
  /** Titles at the x axis for each column */
  xAxisTitles: PropTypes.array,
  /** Height of each column */
  colHeight: PropTypes.any,
  /** Height of entire chart */
  chartHeight: PropTypes.any,
  /** Width of each column */
  colWidth: PropTypes.any,
  /** Width of entire chart */
  chartWidth: PropTypes.any
}

export default React.memo(StackedVert)
