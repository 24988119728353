import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

let IconLogout = (props) => (
  <SvgIcon {...props}>
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5498 1L2.5498 1C1.44524 1 0.549805 1.89543 0.549805 3L0.549804 11C0.549804 12.1046 1.44523 13 2.5498 13L4.5498 13"
        stroke="#26415E"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8971 6.94971C10.8971 7.26549 10.6411 7.52148 10.3253 7.52148L3.50001 7.52149C3.18423 7.52149 2.92824 7.2655 2.92824 6.94972C2.92824 6.63394 3.18423 6.37794 3.50001 6.37794L10.3253 6.37794C10.6411 6.37794 10.8971 6.63393 10.8971 6.94971Z"
        fill="#26415E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.55266 3.31098C7.77595 3.08769 8.13798 3.08769 8.36127 3.31098L11.5957 6.54541C11.819 6.7687 11.819 7.13072 11.5957 7.35402C11.3724 7.57731 11.0104 7.57731 10.7871 7.35401L7.55266 4.11959C7.32937 3.8963 7.32937 3.53427 7.55266 3.31098Z"
        fill="#26415E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5957 6.54541C11.819 6.7687 11.819 7.13072 11.5957 7.35402L8.36127 10.5884C8.13798 10.8117 7.77596 10.8117 7.55267 10.5884C7.32937 10.3652 7.32937 10.0031 7.55267 9.77984L10.7871 6.54541C11.0104 6.32212 11.3724 6.32212 11.5957 6.54541Z"
        fill="#26415E"
      />
    </svg>
  </SvgIcon>
)
IconLogout.displayName = 'IconLogout'
IconLogout.muiName = 'SvgIcon'

export default IconLogout
