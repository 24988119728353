import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Legend, PrimaryButton, Table } from 'components'
import * as S from './styles'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import Error from 'utils/error'
import { ExportService } from 'services'
import LoadingMask from 'utils/loadingMask'
import { numberOfCompanies } from 'utils/numberFormat'
import Grid from '@material-ui/core/Grid'
import {
  ScoreQualityLevel,
  predictedScoreHighlight
} from 'views/globalComponent/ScoreQualityLevel'

const CompaniesAndInvestmentsTable = ({
  portfolioName,
  companiesViewByValue,
  entitlements,
  scoreQuality
}) => {
  const context = useContext(GlobalContext)
  const [filterState] = context.globalFilter
  const [dashboardState, dashboardDispatch] = context.globalDashboard
  const [, entityHeaderDispatch] = useContext(GlobalContext).globalEntityHeader
  let apiCallId = dashboardState.apiCallId

  const isFilterStateAvailable =
    filterState.filterData.regionsList.length > 0 ||
    filterState.filterData.sectorsList.length > 0

  const reloadComponent = (errorOccuredOn) => {
    switch (errorOccuredOn) {
      case PAGE_CON.COMPANIES_AND_INVESTMENT_TABLE:
        break
      default:
        break
    }
  }

  const tableHeaders = [
    {
      label: <span>Company</span>,
      columnStyles: {
        overflow: 'hidden',
        textAlign: 'left',
        border: '0px',
        width: '31%'
      },
      entitled: true,
      sortable: false
    },
    {
      label: <S.TableHeader>ESG Score</S.TableHeader>,
      columnStyles: {
        overflow: 'hidden',
        textAlign: 'left',
        border: '0px',
        width: '10%'
      },
      entitled: entitlements.PortfolioAnalysis.esg,
      sortable: false
    },
    {
      label: (
        <S.TableHeader>
          ESG Incidents in <br /> the past 12 months
        </S.TableHeader>
      ),
      columnStyles: {
        overflow: 'hidden',
        textAlign: 'left',
        border: '0px',
        width: '17%'
      },
      entitled: entitlements.Dashboard.controversy,
      sortable: false
    },
    {
      label: <S.TableHeader>% Investment</S.TableHeader>,
      columnStyles: {
        overflow: 'hidden',
        textAlign: 'left',
        border: '0px',
        width: '12%'
      },
      entitled: true,
      sortable: false
    },
    {
      label: (
        <S.TableHeader>
          {companiesViewByValue === 'sector' ? 'Region/Country' : 'Sector'}
        </S.TableHeader>
      ),
      columnStyles: {
        overflow: 'hidden',
        textAlign: 'left',
        border: '0px',
        width: '29%'
      },
      entitled: true,
      sortable: false
    }
  ]

  const entitledTableHeader = tableHeaders.filter(
    (header) => header.entitled === true
  )

  const exportData = async () => {
    const { portfolio, regions, sectors, asOfDate } =
      filterState.globalPortfolioFilter

    try {
      dashboardDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName: REDUCER_STATE.DOWNLOAD_DASHBOARD_CLIMATE_DATA
      })

      const exportURL = await ExportService.getExportClimateData({
        portfolio,
        regions,
        sectors,
        asOfDate,
        portfolioName
      })

      dashboardDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.DOWNLOAD_DASHBOARD_CLIMATE_DATA,
        data: { exportURL },
        apiCallId
      })

      if (exportURL && exportURL.url) {
        window.location = exportURL.url
      } else {
        dashboardDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.DOWNLOAD_DASHBOARD_CLIMATE_DATA,
          apiCallId
        })
      }
    } catch (error) {
      dashboardDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        sectionName: REDUCER_STATE.DOWNLOAD_DASHBOARD_CLIMATE_DATA,
        apiCallId
      })
    }
  }

  const normalizeTitle = (title) =>
    title
      .split('_')
      .map((e) => `${e.charAt(0).toUpperCase()}${e.slice(1)}`)
      .join(' ')

  const showingCompanies = Object.values(
    dashboardState.dashboardCompaniesTable.data
  ).reduce((prev, current) => prev + current.entities.length, 0)
  const totalCompanies =
    showingCompanies +
    Object.values(dashboardState.dashboardCompaniesTable.data).reduce(
      (prev, current) => prev + current.remaining_entities,
      0
    )

  const tableData = useMemo(
    () => Object.keys(dashboardState.dashboardCompaniesTable.data) || [],
    [dashboardState.dashboardCompaniesTable.data]
  )
  let legendData = false
  const onEntityClick = (item) => {
    const selectedOrbisId = item?.parent_bvd9_number || item?.bvd9_number
    if ((selectedOrbisId || '') !== '') {
      entityHeaderDispatch({ type: REDUCER_TYPE.SET_ORBIB_ID, selectedOrbisId })
    }
  }

  const formatTableData = (data) => {
    const newItem = []
    const isRowHighlighted = []

    data.map((item, key) => {
      newItem[key] = [
        <S.CompanyNameCell key="companyName" className="CompanyNameWrapper">
          {scoreQuality && item.esg_score_category ? (
            <ScoreQualityLevel level={item.score_quality} />
          ) : null}
          <S.CompanyNameCell
            onClick={
              item.score_quality === 'Predicted'
                ? () => {}
                : () => onEntityClick(item)
            }
            heap_id="view-panel"
            heap_entity={item.company_name}
            title={item.company_name}
            className="ellipsis entity"
          >
            {item.company_name}
          </S.CompanyNameCell>
        </S.CompanyNameCell>,
        ...Object.values({
          ...(entitlements.PortfolioAnalysis.esg && [
            <span className="esgScoreCell" key="score">{`${
              item.esg_score_category ? item.esg_score_category : '-'
            }`}</span>
          ])
        }),
        ...Object.values({
          ...(entitlements.Dashboard.controversy && [
            <div className="controversiesCellContainer" key="controversies">
              <span>{item.controversies_total} total</span>
              <span className="controversiesCell">
                {item.controversies_critical} critical
              </span>
            </div>
          ])
        }),
        <span
          className="investmentCell"
          key="investment"
        >{`${item.perc_investment} %`}</span>,
        companiesViewByValue === 'sector' ? (
          <span>{item.region_country}</span>
        ) : (
          <span>{item.sector}</span>
        )
      ]

      isRowHighlighted[key] = predictedScoreHighlight(item?.score_quality)
      if (isRowHighlighted[key] && !legendData) {
        legendData = true
      }
    })

    return { items: newItem, isRowHighlighted: isRowHighlighted }
  }

  return (
    <S.Container>
      <S.Content>
        {dashboardState.dashboardCompaniesTable.dataStatus ===
        PAGE_CON.ERROR ? (
          <Error
            id={'viewCompaniesError'}
            dataStatus={dashboardState.dashboardCompaniesTable.dataStatus}
            onErrorClick={reloadComponent}
            errorOccuredOn={PAGE_CON.COMPANIES_AND_INVESTMENT_TABLE}
          />
        ) : (
          <>
            {dashboardState.dashboardCompaniesTable.isLoading ? (
              <LoadingMask />
            ) : (
              <S.FixedContent>
                <S.TablesContainer>
                  {tableData.length > 0 &&
                    tableData.map((ele, index) => {
                      const data =
                        dashboardState.dashboardCompaniesTable.data[ele]
                      const eleKey = ele.replaceAll(' ', '_')
                      const tableDataFormatted = formatTableData(data.entities)
                      return (
                        <div
                          id={`${index}-${eleKey}`}
                          key={`${index}-${eleKey}`}
                        >
                          <S.TableTitle>{normalizeTitle(ele)}</S.TableTitle>
                          <Table
                            isLoading={false}
                            id={`viewcomapnies-${index}-${eleKey}`}
                            custBorderTop={false}
                            headers={entitledTableHeader}
                            data={tableDataFormatted.items}
                            isHighlighted={tableDataFormatted.isRowHighlighted}
                          />
                          {data.remaining_entities > 0 && (
                            <S.TableCaption>
                              {data.remaining_entities} more companies in this{' '}
                              {companiesViewByValue}
                            </S.TableCaption>
                          )}
                        </div>
                      )
                    })}
                </S.TablesContainer>
                <S.Footer>
                  <S.ButtonTextContainer>
                    <S.FooterContent>
                      {entitlements && entitlements['export'] && (
                        <Grid container>
                          <Grid item xs={12} sm={2} md={2} xl={2}>
                            {legendData ? (
                              <Legend
                                colorOptions={[PAGE_CON.PREDICTED_SCORE]}
                                colorGroup={PAGE_CON.ESG_ASSESSMENT}
                                boldTitle={true}
                                disableBackgroundColor={true}
                              />
                            ) : null}
                          </Grid>
                          <Grid item xs={12} sm={8} md={8} xl={8}>
                            <p>
                              Showing {showingCompanies} out {totalCompanies} in{' '}
                              {portfolioName}.
                            </p>
                            <p>
                              Export to view all data for{' '}
                              {numberOfCompanies(totalCompanies)}.
                            </p>
                          </Grid>
                          <Grid item sm={2} md={2} xl={2}></Grid>
                          <Grid item xs={12}>
                            <PrimaryButton
                              id="dashboard-export-button-test-id"
                              buttonText="Export To Excel"
                              heap_id="view-panel"
                              heap_view_panel={`export-${numberOfCompanies(
                                totalCompanies
                              ).substring(
                                0,
                                numberOfCompanies(totalCompanies).indexOf(' ')
                              )}`}
                              onClick={() => exportData()}
                              buttonClass="buttonPrimary"
                              buttonDisabled={!isFilterStateAvailable}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </S.FooterContent>
                  </S.ButtonTextContainer>
                </S.Footer>
              </S.FixedContent>
            )}
          </>
        )}
      </S.Content>
    </S.Container>
  )
}

CompaniesAndInvestmentsTable.propTypes = {
  portfolioName: PropTypes.string
}

export default CompaniesAndInvestmentsTable
