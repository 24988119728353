import { Container, Grid, makeStyles } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { PAGE_CON } from 'constant/constants'
import { useParams } from 'react-router-dom'
import { IconCoffeeMug, IconDownload, Page, PrimaryButton } from 'components'
import { ExportService, ReportingServices } from 'services'
import Error from 'utils/error'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    textAlign: 'center'
  },
  container: {
    position: 'fixed',
    top: '50%',
    left: '0%',
    marginTop: '-100px'
  },
  svgIcon: {
    fill: 'white',
    width: 80,
    height: 80,
    marginTop: 36
  },
  title: {
    ...theme.typography.p2,
    fontWeight: 375,
    fontSize: '20px',
    lineHeight: '24px'
  },
  subTitle: {
    ...theme.typography.h2,
    fontWeight: 375,
    fontSize: '14px',
    lineHeight: '16.8px',
    marginTop: 12
  },
  parentContainer: {
    minWidth: 150,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  downloadIcon: {
    width: 16,
    height: 16,
    marginBottom: 6
  },
  fileContainer: {
    ...theme.typography.p2,
    fontWeight: 375,
    fontSize: '20px',
    lineHeight: '24px',
    width: '100%',
    marginBottom: '16px'
  },
  fileNameStyle: {
    textAlign: 'center'
  },
  statusStyle: {
    paddingLeft: '16px'
  }
}))

const ReportingStatus = ({ reportingType }) => {
  const classes = useStyles()

  const delay = 20000
  const [completed, setCompleted] = useState(false)
  const [countInfo, setCountInfo] = useState({
    pollingCount: 0,
    failureCount: 0
  })
  const [reportFailed, setReportFailed] = useState(false)
  const [fileDetails, setFileDetails] = useState({})
  const [downloadStart, setDownloadStart] = useState(false)
  const maxFailureAttempt = 5

  const getService = () => {
    let service = null
    let exportType = ''

    if (reportingType === 'Regulatory') {
      service = ReportingServices
      exportType = 'reports'
    } else if (reportingType === 'Portfolio') {
      service = ExportService
      exportType = 'PDF'
    }

    return { service, exportType }
  }

  const { service, exportType } = getService()
  const { id } = useParams()

  let buttonDisabled = false

  const getProcessedData = (data) => {
    let rData = {}

    if (data?.file_details) {
      // for Reg reporting
      Object.keys(data.file_details).map((key) => {
        return (rData[data.file_details[key].file_name] =
          data.file_details[key])
      })
    } else if (data?.file_name) {
      // for portfolio Analysis
      rData[data.file_name] = {
        file_name: data.file_name,
        file_status: 'complete'
      }
    }

    return rData
  }

  const checkStatus = useCallback(
    async (requestId) => {
      try {
        const data = await service.checkStatus({
          request_id: requestId
        })
        if (data?.request_status?.toUpperCase() === 'COMPLETE') {
          setFileDetails(getProcessedData(data))
          setCompleted(true)
        } else if (data?.request_status?.toUpperCase() === 'ERROR') {
          setReportFailed(true)
        } else {
          setTimeout(() => {
            setCountInfo((prev) => ({
              pollingCount: prev.pollingCount + 1,
              failureCount: 0
            }))
          }, delay)
        }
      } catch (err) {
        setTimeout(() => {
          setCountInfo((prev) => ({
            pollingCount: prev.pollingCount + 1,
            failureCount: prev.failureCount + 1
          }))
        }, delay)
      }
    },
    [service]
  )

  useEffect(() => {
    if (id && id !== 'waiting' && countInfo.failureCount < maxFailureAttempt)
      checkStatus(id)
  }, [countInfo, checkStatus, id])

  const downloadFile = async () => {
    if (buttonDisabled) return
    buttonDisabled = true
    setDownloadStart(true)
    try {
      const data = await service.getReportUrl({
        request_id: id
      })

      const link = document.createElement('a')
      if (link.download !== undefined) {
        link.setAttribute('href', data)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      //eslint-disable-next-line no-empty
    } catch (err) {
      console.log(err)
    }
    setDownloadStart(false)
    buttonDisabled = false
  }

  const reloadComponent = () => {
    setCountInfo((prev) => ({
      pollingCount: prev.pollingCount + 1,
      failureCount: 0
    }))
  }

  const fail = countInfo.failureCount >= maxFailureAttempt

  const getFileStatus = (fileStatus) => {
    let fStatus = fileStatus
    switch (fileStatus) {
      case 'complete':
        fStatus = 'ready'
        break
      case 'error':
        fStatus = 'error'
        break
      default:
        break
    }

    return fStatus
  }

  return (
    <Page className={classes.root}>
      <Container className={classes.container} maxWidth={false}>
        <Grid container direction="column" className={classes.parentContainer}>
          {fail ? (
            <Error
              id={'reportingStatusError'}
              dataStatus={PAGE_CON.ERROR}
              onErrorClick={reloadComponent}
              errorOccuredOn={PAGE_CON.REPORTING_STATUS}
            />
          ) : reportFailed ? (
            <Error
              id={'reportingStatusFail'}
              dataStatus={PAGE_CON.SUCCESS}
              onErrorClick={reloadComponent}
              errorOccuredOn={PAGE_CON.REPORTING_STATUS}
            />
          ) : completed ? (
            <>
              <Grid
                container
                item
                xs={12}
                spacing={2}
                className={classes.fileContainer}
              >
                {Object.keys(fileDetails).length &&
                  Object.keys(fileDetails).map((fileInfo, index) => {
                    return (
                      <React.Fragment key={`itemNo_${index}`}>
                        <Grid item xs={12} className={classes.fileNameStyle}>
                          {fileDetails[fileInfo]?.file_name}
                          <span className={classes.statusStyle}>
                            {getFileStatus(fileDetails[fileInfo]?.file_status)}
                          </span>
                        </Grid>
                      </React.Fragment>
                    )
                  })}
              </Grid>
              <PrimaryButton
                id="button-download-test-id-2"
                buttonText="Download"
                buttonClass="buttonAltPrimary"
                buttonIcon={
                  <IconDownload
                    fillcolor="white"
                    className={classes.downloadIcon}
                    viewBox="0 0 16 16"
                  />
                }
                buttonIconPosition="top"
                buttonHeight={60}
                onClick={downloadFile}
                buttonWidth={160}
                buttonDisabled={downloadStart}
                buttonTextClass="reportingTextStyle"
              />
            </>
          ) : (
            <>
              <h3 className={classes.title}>
                This may take a few minutes, please do not close this window.
              </h3>
              <p className={classes.subTitle}>
                Gathering data, preparing {exportType}...
              </p>
              <IconCoffeeMug className={classes.svgIcon} viewBox="0 0 16 22" />
            </>
          )}
        </Grid>
      </Container>
    </Page>
  )
}

export default ReportingStatus
