import { AppBar, Toolbar } from '@material-ui/core'
import { PAGE_CON } from 'constant/constants'
import { COMMON, PARAMS } from 'constant/APIconstants'
import { Card, PlatformSearchCardInfoSection, Textbox } from 'components'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import axios from 'axios'
import { getMonthDayYear } from 'utils/Date'
import * as S from './styles'

const PlatformSearch = (props) => {
  const classes = S.useStyles(props)
  const [searchText, setSearchText] = React.useState('')
  const [selectedCardId, setSelectedCardId] = React.useState('')
  const [searchResult, setSearchResult] = React.useState('')
  const [emptyText, setEmptyText] = React.useState('')
  const minSearchTextLength = 1

  React.useEffect(() => {
    if (
      searchText.trim() === '' ||
      searchText.trim().length === 0 ||
      searchText.length < minSearchTextLength
    ) {
      setSearchResult([])
    } else {
      const timeoutInterval = searchText.trim().length === 1 ? 2000 : 250
      timer = setTimeout(search, timeoutInterval)
    }
  }, [searchText])

  var timer = 0

  async function search() {
    try {
      if (searchText.length >= minSearchTextLength) {
        let response = await axios.post(
          COMMON.BASE_SEARCH + COMMON.ENTITY_SEARCH,
          {
            [PARAMS.SEARCH_TERM]: searchText
          }
        )
        let regSerachText =
          '(' +
          searchText
            .split(' ')
            .filter((x) => x !== '')
            .join('|') +
          ')'

        let rData = response['data']['hits']['hits'].map((entity) => {
          const entityName = entity._source.entity_name.replace(
            new RegExp(regSerachText, 'gi'),
            (match) => `<mark class=${classes.highlight}>${match}</mark>`
          )

          return {
            id: entity._source.parent_orbis_id || entity._id,
            [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: entityName,
            parent_entity_name: entity?._source?.parent_entity_name,
            date:
              entity._source.last_updated_date &&
              getMonthDayYear(
                entity._source.last_updated_date,
                'month day, year'
              ),
            orbis_id: entity._source.parent_orbis_id || entity._source.orbis_id
          }
        })

        setSearchResult(rData)
        rData.length === 0 ? setEmptyText('No results found') : setEmptyText('')
      }
    } catch (err) {
      setSearchResult([])
    }
  }

  const onSearchChange = (inputText) => {
    if (props.onSearchType) props.onSearchType(inputText)
    setSearchText(inputText)
    clearTimeout(timer)
  }

  const entityCardSelect = (id) => {
    setSelectedCardId(id)
    if (props.onEntityClick) props.onEntityClick(id)
  }

  const contentInfo = [
    {
      labelType: 'Text',
      className: 'portfolio',
      dataField: 'entityName',
      showToolTip: true
    },
    {
      labelType: 'Text',
      dataField: 'date',
      label: 'Last Update: ',
      showToolTip: false
    }
  ]

  return (
    <Grid container direction="column" spacing={2} id={props.id} key={props.id}>
      <Grid item style={{ padding: '0' }}>
        <AppBar
          id={props.id}
          position="relative"
          className={classes.pageHeader}
        >
          <Toolbar
            disableGutters
            className={classes.toolbarStyle}
            heap_id="search"
          >
            <span className={classes.searchTextClass}> Search: </span>
            <Textbox
              id="platform-search-test-id"
              textboxClass="searchTextbox"
              textboxDefaultPlaceholder="Search by entity "
              textboxMargin={'8'}
              textboxError={false}
              multiline={false}
              iconSize={20}
              onChange={onSearchChange}
              key={'platform-search-test-id-2'}
              textboxWidth={'72%'}
            />
          </Toolbar>
        </AppBar>
        {searchResult.length > 0 && searchText !== '' ? (
          <Grid container direction="column">
            <Grid item>
              <Grid
                container
                direction="row"
                className={classes.uploadGridStyle}
                style={{
                  minHeight:
                    searchResult && searchResult.length >= 10 ? '300px' : '0px'
                }}
                alignItems={'flex-start'}
              >
                <span className={classes.entityLabelStyle}>Entities:</span>
                <Grid
                  item
                  xl={12}
                  className={classes.searchResultsGrid}
                  style={{
                    minHeight:
                      searchResult && searchResult.length >= 10
                        ? '300px'
                        : '0px'
                  }}
                >
                  {searchResult.slice(0, 10).map((entity, index) => {
                    let cardInfoData = {
                      entityName: entity[PAGE_CON.DROPDOWN_DISPLAY_COLUMN],
                      parentEntityName: entity.parent_entity_name,
                      parentOrbisID: entity.parent_orbis_id,
                      date: entity['date'],
                      orbisId: entity['orbis_id']
                    }

                    let comp = [
                      <PlatformSearchCardInfoSection
                        id={`mini-${index}`}
                        key={`mini-${index}`}
                        contentInfo={contentInfo}
                        data={cardInfoData}
                      />
                    ]

                    return (
                      <Grid
                        item
                        key={`gridPort-${index}`}
                        className={
                          index === 0
                            ? classes.gridItemFirst
                            : index === 9
                            ? classes.gridItemLast
                            : classes.gridItem
                        }
                      >
                        <Card
                          cardClass="platformSearchCard"
                          maxWidth={'100%'}
                          components={comp}
                          id={entity.id}
                          selectedId={selectedCardId}
                          onCardSelect={entityCardSelect}
                          selectionType={'single'}
                          key={`entitycard-test-id-${index}`}
                          isCardClickable={true}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : searchText.trim().length === 0 || searchText === '' ? (
          <Grid container direction="column" spacing={1} alignItems="center">
            <Grid item>
              <div className={clsx(classes.notFound)}></div>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="column" spacing={1} alignItems="center">
            <Grid item>
              <div className={clsx(classes.notFound, classes.itemStyle)}>
                {emptyText}
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default PlatformSearch
