import React, { useContext } from 'react'
import { Backdrop, makeStyles } from '@material-ui/core'
import { GlobalContext } from 'context/GlobalStateProvider'
import LoadingMask from 'utils/loadingMask'

const useStyles = makeStyles(() => ({
  backdropVisible: {
    display: 'block'
  },
  backdropHidden: {
    display: 'none'
  },
  backdrop: {
    zIndex: 12000000 + 1,
    color: '#fff'
  }
}))

const BackdropComp = () => {
  const classes = useStyles()
  const [filterState] = useContext(GlobalContext).globalFilter

  return (
    <div
      className={
        filterState.openBackdrop
          ? classes.backdropVisible
          : classes.backdropHidden
      }
    >
      <Backdrop className={classes.backdrop} open={filterState.openBackdrop}>
        <LoadingMask loadText={filterState.backdropText} />
      </Backdrop>
    </div>
  )
}

export default BackdropComp
