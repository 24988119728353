import { Grid } from '@material-ui/core'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import themeColorPalette from 'theme/theme'
import PropTypes from 'prop-types'
import numberData from 'utils/numberFormat'
import React from 'react'
import * as S from './styles'
/**
 * @visibleName Line Chart with Projection
 */
const ProjectionGraph = ({
  id,
  header,
  footer,
  height,
  width,
  scores,
  sector_measurement
}) => {
  const classes = S.useStyles({ width })

  const options = {
    chart: {
      type: 'line',
      height: height || 420,
      width: width || 600
    },
    boost: {
      useGPUTranslations: true
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        return (
          '<b> Emissions (' +
          sector_measurement +
          '): </b>' +
          numberData(this.y, {
            isCommaSeparated: true,
            isDecimal: true,
            maxFraction: 3
          }) +
          '<br/><b>Year: </b>' +
          this.x
        )
      }
    },
    yAxis: {
      lineWidth: 1,
      tickWidth: 0,
      tickmarkPlacement: 'on',
      gridLineWidth: 1,
      gridLineColor: themeColorPalette.palette.sys_trad.border.main,
      title: {
        text: `Emissions  (${sector_measurement}) `
      }
    },

    plotOptions: {
      series: {
        pointPlacement: 'on',
        allowPointSelect: false,
        gridLineDashStyle: 'ShortDash',
        enableMouseTracking: true,
        events: {
          legendItemClick: function () {
            return false
          }
        },
        animation: false
      },
      line: {
        marker: {
          symbol: 'circle',
          enabled: true
        }
      }
    },
    legend: {
      align: 'left',
      verticalAlign: 'top',
      symbolWidth: 30,
      x: 40,
      y: 0,
      itemStyle: {
        cursor: 'default'
      }
    },
    series: scores
  }

  return (
    <Grid container className={classes.containerStyle}>
      <div className={classes.projectionGraphStyle}>
        {header && <div className={classes.tempTextStyle}> {header} </div>}
        <HighchartsReact id={id} highcharts={Highcharts} options={options} />
        {footer && <div className={classes.footNoteLabel}>{footer}</div>}
      </div>
    </Grid>
  )
}
ProjectionGraph.propTypes = {
  /** LineChart id */
  id: PropTypes.string,
  /** LineChart title */
  title: PropTypes.string,
  /** LineChart data */
  scores: PropTypes.array
}
export default React.memo(ProjectionGraph)
