import { makeStyles } from '@material-ui/core'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: 2
  },
  disabled: {
    cursor: 'not-allowed',
    '&.Mui-disabled': {
      cursor: 'not-allowed'
    }
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    borderRadius: 2,
    letterSpacing: 0,
    paddingTop: '8px',
    paddingBottom: '8px',
    textTransform: 'none',
    lineHeight: '16px',
    width: '100%',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
      lineHeight: '16px'
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
        lineHeight: '16px',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      }
    },
    '&.MuiButton-text': {
      paddingRight: 4,
      paddingLeft: 4
    }
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    ...textTheme.typography.p3,
    lineHeight: '16px',
    color: themeColorPalette.palette.sys_trad.text.main
  },
  active: {
    backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
    lineHeight: 'normal',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      lineHeight: '16px'
    },
    '&.Mui-disabled': {
      backgroundColor: themeColorPalette.palette.white.main,
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
        lineHeight: '16px'
      }
    }
  }
}))
