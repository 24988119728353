import { multiColumnSort } from 'utils/Sort'
import React, { useContext } from 'react'
import { PrimaryButton, PrimaryModal } from 'components'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'
import { COMMON } from 'constant/APIconstants'
import { PortfolioService } from 'services'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import * as S from './styles'

/**
 * @visibleName Links
 */

function PortfolioDelete(props) {
  const classes = S.useStyles(props)
  const context = useContext(GlobalContext)
  const [portfolioManagementState, portfolioManagementDispatch] =
    context.globalPortfolioManagement
  const [, reportingServiceDispatch] =
    useContext(GlobalContext).globalReportingService
  const [filterState, filterDispatch] = context.globalFilter

  const handleCancelButton = () => {
    portfolioManagementDispatch({
      type: REDUCER_TYPE.DELETE_MODAL,
      openDeletePortfolioModal: false
    })
    portfolioManagementDispatch({
      type: REDUCER_TYPE.PORTFOLIO_MANAGEMENT,
      openPortfolioManagement: true
    })
  }

  function getUniqueId(actionData) {
    return `${actionData}$$${uuidv4()}`
  }

  const getPortfolios = async () => {
    const portfolios = []
    const { data } = await PortfolioService.getPortfolioDetails({
      params: {},
      queryParams: ''
    })

    for (const [, value] of Object.entries(data.portfolios)) {
      portfolios.push({
        key: value.portfolio_id,
        [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: value.portfolio_name,
        [PAGE_CON.CREATED_DATE]: value[PAGE_CON.CREATED_DATE],
        [PAGE_CON.UPLOAD_DATE_UTC]: value[PAGE_CON.UPLOAD_DATE_UTC]
      })
    }

    const columnsToBeSorted = [[PAGE_CON.UPLOAD_DATE_UTC, 'DESC']]

    return multiColumnSort(portfolios, columnsToBeSorted)
  }

  const deletePortfolio = async () => {
    await axios.delete(COMMON.BASE_URL + portfolioManagementState.portfolioId)
  }

  const handleDeleteConfirmationButton = async () => {
    filterDispatch({
      type: REDUCER_TYPE.OPEN_BACKDROP,
      backdropText: PAGE_CON.DELETE_PORTFOLIO
    })

    portfolioManagementDispatch({
      type: REDUCER_TYPE.DELETE_MODAL,
      openDeletePortfolioModal: false
    })

    try {
      await deletePortfolio()
      const newPortfolioList = await getPortfolios()
      filterDispatch({
        type: REDUCER_TYPE.UPDATE_PORTFOLIO_COMPLETED,
        portfolioList: newPortfolioList
      })

      if (
        filterState.globalPortfolioFilter.portfolio ===
        portfolioManagementState.portfolioId
      ) {
        filterDispatch({
          type: REDUCER_TYPE.UPDATE_FILTER,
          payload: newPortfolioList[0].key,
          name: PAGE_CON.FILTER_PORTFOLIO,
          actionPerformedOn: getUniqueId(PAGE_CON.FILTER_PORTFOLIO)
        })
      }
    } catch (err) {
      console.log(err)
    }

    filterDispatch({ type: REDUCER_TYPE.CLOSE_BACKDROP })
    portfolioManagementDispatch({
      type: REDUCER_TYPE.PORTFOLIO_MANAGEMENT,
      openPortfolioManagement: true,
      portfolioManagementView: PAGE_CON.PORTFOLIO_LIST
    })
    reportingServiceDispatch({ type: REDUCER_TYPE.RESET_REPORTING_SERVICE })
  }

  const deletePortfolioModalContent = (
    <Grid item>
      <div className={classes.titleStyle}>Confirm Portfolio Deletion</div>
      <Grid item style={{ paddingRight: '25px' }}>
        {filterState.globalPortfolioFilter.portfolio ===
        portfolioManagementState.portfolioId
          ? 'You are about to delete the portfolio you are currently viewing. You won’t be able to use it anymore.'
          : ''}
      </Grid>
      <div className={classes.deleteModalButtonsStyle}>
        <PrimaryButton
          id="button-button-test-id-1"
          buttonText="No, Cancel"
          buttonClass="buttonPrimary"
          onClick={handleCancelButton}
        />
      </div>
      <div
        className={classes.deleteModalButtonsStyle}
        style={{ marginRight: 16 }}
      >
        <PrimaryButton
          id="button-button-test-id-1"
          buttonText="Yes, Delete"
          buttonClass="buttonGhost"
          onClick={handleDeleteConfirmationButton}
        />
      </div>
    </Grid>
  )

  return (
    <PrimaryModal
      id="delete-button-confirmation-modal"
      modalOpen={portfolioManagementState.openDeletePortfolioModal}
      modalClass="smallModal"
      modalTitle=""
      modalContentComponent={deletePortfolioModalContent}
      modalNoCloseOnClick
      modalWidth="375px"
      modalMaxWidth={false}
    />
  )
}

PortfolioDelete.propTypes = {}

export default PortfolioDelete
