import theme from 'theme'
import { PAGE_CON } from './constants'

export const genericScores = [
  {
    min: 60,
    max: 100,
    score: PAGE_CON.ADVANCED,
    color: theme.palette.adv_wk.advanced.main
  },
  {
    min: 50,
    max: 59,
    score: PAGE_CON.ROBUST,
    color: theme.palette.adv_wk.robust.main
  },
  {
    min: 30,
    max: 49,
    score: PAGE_CON.LIMITED,
    color: theme.palette.adv_wk.limited.main
  },
  {
    min: 0,
    max: 29,
    score: PAGE_CON.WEAK,
    color: theme.palette.adv_wk.weak.main
  }
]

export const carbonFootprintScores = [
  {
    min: 0,
    max: 99999,
    score: PAGE_CON.MODERATE,
    color: theme.palette.carbfoot.moderate.main
  },
  {
    min: 100000,
    max: 999999,
    score: PAGE_CON.SIGNIFICANT,
    color: theme.palette.carbfoot.significant.main
  },
  {
    min: 1000000,
    max: 9999999,
    score: PAGE_CON.HIGH,
    color: theme.palette.carbfoot.high.main
  },
  {
    min: 10000000,
    max: 999999999,
    score: PAGE_CON.INTENSE,
    color: theme.palette.carbfoot.intense.main
  }
]

export const brownShareScores = [
  {
    min: 0,
    max: 0,
    score: PAGE_CON.NO_INVOLVEMENT,
    color: theme.palette.major_no.green_major.main
  },
  {
    min: 1,
    max: 20,
    score: PAGE_CON.MINOR_INVOLVEMENT,
    color: theme.palette.major_no.green_signif.main
  },
  {
    min: 21,
    max: 100,
    score: PAGE_CON.MAJOR_INVOLVEMENT,
    color: theme.palette.major_no.green_no.main
  }
]

export const greenShareScores = [
  {
    min: 51,
    max: 100,
    score: PAGE_CON.MAJOR,
    color: theme.palette.major_no.green_major.main
  },
  {
    min: 21,
    max: 50,
    score: PAGE_CON.SIGNIFICANT,
    color: theme.palette.major_no.green_signif.main
  },
  {
    min: 1,
    max: 20,
    score: PAGE_CON.MINOR,
    color: theme.palette.major_no.green_minor.main
  },
  {
    min: 0,
    max: 0,
    score: PAGE_CON.NONE,
    color: theme.palette.major_no.green_no.main
  }
]

export const riskScores = [
  {
    min: 0,
    max: 19,
    score: PAGE_CON['0_19'],
    color: theme.palette.noflag.norsk.main
  },
  {
    min: 20,
    max: 39,
    score: PAGE_CON['20_39'],
    color: theme.palette.noflag.lowrsk.main
  },
  {
    min: 40,
    max: 59,
    score: PAGE_CON['40_59'],
    color: theme.palette.noflag.medrsk.main
  },
  {
    min: 60,
    max: 79,
    score: PAGE_CON['60_79'],
    color: theme.palette.noflag.hirsk.main
  },
  {
    min: 80,
    max: 100,
    score: PAGE_CON['80_100'],
    color: theme.palette.noflag.redflgrsk.main
  }
]

export const tempAlgmtScores = [
  {
    min: -1,
    max: -1,
    score: PAGE_CON.NO_INFO,
    color: theme.palette.genrang_c.one.main
  },
  {
    min: 0,
    max: 1.64,
    score: PAGE_CON.WELL_BELOW_2,
    color: theme.palette.genrang_c.two.main
  },
  {
    min: 1.65,
    max: 1.94,
    score: PAGE_CON.BELOW_2,
    color: theme.palette.genrang_c.three.main
  },
  {
    min: 1.95,
    max: 2.04,
    score: PAGE_CON['2_DEGREES'],
    color: theme.palette.genrang_c.four.main
  },
  {
    min: 2.05,
    max: 100,
    score: PAGE_CON.ABOVE_2,
    color: theme.palette.genrang_c.five.main
  }
]

export const esgScores = [
  {
    min: 0,
    max: 9,
    score: PAGE_CON.ESG_A1,
    color: theme.palette.genrang_b.two.main
  },
  {
    min: 10,
    max: 19,
    score: PAGE_CON.ESG_A2,
    color: theme.palette.genrang_b.two.main
  },
  {
    min: 20,
    max: 29,
    score: PAGE_CON.ESG_A3,
    color: theme.palette.genrang_b.two.main
  },
  {
    min: 30,
    max: 39,
    score: PAGE_CON.ESG_B1,
    color: theme.palette.genrang_b.three.main
  },
  {
    min: 40,
    max: 49,
    score: PAGE_CON.ESG_B2,
    color: theme.palette.genrang_b.three.main
  },
  {
    min: 50,
    max: 59,
    score: PAGE_CON.ESG_B3,
    color: theme.palette.genrang_b.three.main
  },
  {
    min: 60,
    max: 69,
    score: PAGE_CON.ESG_C1,
    color: theme.palette.genrang_b.eight.main
  },
  {
    min: 70,
    max: 79,
    score: PAGE_CON.ESG_C2,
    color: theme.palette.genrang_b.eight.main
  },
  {
    min: 80,
    max: 89,
    score: PAGE_CON.ESG_C3,
    color: theme.palette.genrang_b.eight.main
  },
  {
    min: 90,
    max: 100,
    score: PAGE_CON.ESG_E,
    color: theme.palette.genrang_b.ten.main
  }
]

export const controversiesScores = [
  {
    min: 1,
    max: 9999,
    score: 'High',
    color: theme.palette.noflag.redflgrsk.main
  }
]

export const controversyRiskScores = [
  {
    min: 60,
    max: 100,
    score: PAGE_CON.ADVANCED,
    color: theme.palette.sys_trad.error.main
  },
  {
    min: 50,
    max: 59,
    score: PAGE_CON.ROBUST,
    color: theme.palette.sys_trad.error.main
  },
  {
    min: 30,
    max: 49,
    score: PAGE_CON.LIMITED,
    color: theme.palette.sys_trad.error.main
  },
  {
    min: 0,
    max: 29,
    score: PAGE_CON.WEAK,
    color: theme.palette.sys_trad.error.main
  }
]

export const overallEsgScores = [
  {
    min: 0,
    max: 29,
    score: 'a1-3.esg',
    color: theme.palette.genrang_b.one.main
  },
  {
    min: 30,
    max: 59,
    score: 'b1-3.esg',
    color: theme.palette.genrang_b.three.main
  },
  {
    min: 60,
    max: 89,
    score: 'c1-3.esg',
    color: theme.palette.genrang_b.eight.main
  },
  {
    min: 90,
    max: 100,
    score: 'e.esg',
    color: theme.palette.genrang_b.ten.main
  }
]

export const summaryEsgScores = [
  {
    min: 65,
    max: 100,
    score: PAGE_CON.ADVANCED,
    color: theme.palette.genrang_c.one.main
  },
  {
    min: 45,
    max: 64,
    score: PAGE_CON.ROBUST,
    color: theme.palette.genrang_c.two.main
  },
  {
    min: 25,
    max: 44,
    score: PAGE_CON.LIMITED,
    color: theme.palette.genrang_c.three.main
  },
  {
    min: 0,
    max: 24,
    score: PAGE_CON.WEAK,
    color: theme.palette.genrang_c.four.main
  }
]

export const scores = (research_line, isHeatMap = false) =>
  ({
    tcfd_strategy: genericScores,
    energy_transition: genericScores,
    physical_risk_management: genericScores,

    green_share: greenShareScores,

    operations_risk: riskScores,
    market_risk: riskScores,
    supply_chain_risk: riskScores,

    carbon_footprint: carbonFootprintScores,

    brown_share: brownShareScores,

    temperature_alignment: tempAlgmtScores,

    esg: esgScores,

    controversies: isHeatMap ? controversyRiskScores : controversiesScores,

    controversyriskmitigation: controversyRiskScores,

    esgasmt: isHeatMap ? summaryEsgScores : overallEsgScores,

    summaryEsg: summaryEsgScores
  }[research_line])

export const researchLineScore = (research_line, number, isHeatMap) =>
  scores(research_line, isHeatMap).find(
    ({ min, max }) => Math.round(number) >= min && Math.round(number) <= max
  ) || null

export const researchLineScoreByLabel = (research_line, label, isHeatMap) =>
  scores(research_line, isHeatMap).find(
    ({ score }) => score?.toUpperCase() === label?.toUpperCase()
  ) || null
