import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CFPData } from '../../../views/portfolioanalysis/transitionrisk/carbonfootprint/CarFootPrntData.json'
import { exportNumberData } from '../../../utils/numberFormat'
import { GBL_FLT, PARAMS } from '../../../constant/APIconstants'
import { multiColumnSort } from '../../../utils/Sort'
import { GlobalContext } from '../../../context/GlobalStateProvider'
import {
  FEATURE_FLAGS,
  PAGE_CON,
  REDUCER_TYPE,
  ROUTE_PATHS
} from 'constant/constants'
import { ExportService } from 'services'
import axios from 'axios'
import { DropdownField } from 'components'
import useAppState from '../../../hooks/useAppState'
import portfolioAnalysisNavItems from '../../../views/portfolioanalysis/ReportNavigationPortfolioAnalysis.json'
import { PrimaryPopover } from 'components'
import { IconError } from 'components'
import themeColorPalette from 'theme/theme'

const ExportComp = (props) => {
  const { pageTitle, setPopoverErrorOpen } = props
  const context = React.useContext(GlobalContext)
  const [filterState, filterDispatch] = context.globalFilter
  const [currAppState] = useAppState(pageTitle)
  const portfolio = filterState.globalPortfolioFilter.portfolio
  const [exportData, setExportData] = useState([])
  const [errorPopoverMessage, setErrorPopoverMessage] = useState(null)
  const count = useRef(0)

  const companyListNumber =
    currAppState?.portfolioCoverage?.data?.portfolio_coverage?.companies

  const createExportOptions = useCallback(async () => {
    const isFilterStateAvailable =
      filterState.filterData.regionsList.length > 0 ||
      filterState.filterData.sectorsList.length > 0
    let fileDisabled = !isFilterStateAvailable

    if (companyListNumber && !fileDisabled) {
      const companyCount = companyListNumber.split('/')
      fileDisabled = parseInt(companyCount[0]) === 0
    }

    let exportOptions = []
    const isPdfDisabled =
      pageTitle === PAGE_CON.CRBN_FT_PRT_TITLE &&
      FEATURE_FLAGS.CARBON_FOOTPRINT_PDF_EXPORT
        ? fileDisabled
        : true

    if (pageTitle === PAGE_CON.PHY_RSK_HZRD_TITLE)
      exportOptions.push({
        key: 'pdf',
        [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: 'PDF - ' + pageTitle + ' (.pdf)',
        disabled: true
      })
    else if (pageTitle === PAGE_CON.ESG_ASSESSMENT_TITLE)
      exportOptions.push({
        key: 'xlsx',
        [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: 'Data - ' + pageTitle + ' (.xlsx)',
        disabled: fileDisabled
      })
    else if (PAGE_CON.PRODUCTION_ENABLED) {
      exportOptions.push({
        key: 'individual',
        [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: 'Data - ' + pageTitle + ' (.xlsx)',
        disabled: fileDisabled
      })
      if (
        pageTitle === PAGE_CON.CRBN_FT_PRT_TITLE &&
        FEATURE_FLAGS.CARBON_FOOTPRINT_PDF_EXPORT
      )
        exportOptions.push({
          key: 'pdf',
          [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: 'PDF - ' + pageTitle + ' (.pdf)',
          disabled: fileDisabled
        })
    } else {
      exportOptions.push({
        key: 'individual',
        [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: 'Data - ' + pageTitle + ' (.xlsx)',
        disabled: fileDisabled
      })
      exportOptions.push({
        key: 'pdf',
        [PAGE_CON.DROPDOWN_DISPLAY_COLUMN]: 'PDF - ' + pageTitle + ' (.pdf)',
        disabled: isPdfDisabled
      })
    }

    setExportData(exportOptions)
  }, [
    companyListNumber,
    filterState.filterData.regionsList.length,
    filterState.filterData.sectorsList.length,
    pageTitle
  ])

  useEffect(() => {
    createExportOptions()
  }, [createExportOptions])

  let xlsFileName

  const PHYSICAL_RISK_PAGES = [
    PAGE_CON.OPR_RSK_TITLE,
    PAGE_CON.MRK_RSK_TITLE,
    PAGE_CON.SUPP_CHN_RISK_TITLE,
    PAGE_CON.PHY_RSK_HZRD_TITLE
  ]
  const NO_CATEGORY_PAGES = [
    ...PHYSICAL_RISK_PAGES,
    PAGE_CON.BRWN_SHR_ASM_TITLE
  ]
  const SCORE_RANGE_PAGES = {
    [PAGE_CON.BRWN_SHR_ASM_TITLE]: [
      ['median_score', 'ASC'],
      ['investment_pct', 'DESC'],
      ['company_name', 'ASC', 'string']
    ],
    [PAGE_CON.GRN_SHR_ASM_TITLE]: [
      ['score_range', 'DESC'],
      ['investment_pct', 'DESC'],
      ['company_name', 'ASC', 'string']
    ]
  }

  const emissionDescArr = []
  CFPData.emission.emissionTableTitles.forEach((title) => {
    emissionDescArr.push(title.description)
  })

  const getFullFileName = () => {
    const date = new Date()
    const formattedDate = date
      .toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      })
      .replace(/ /g, '-')

    return `${pageTitle}_${formattedDate}_${date.getTime()}`
  }

  /**
   * Generates and returns the filename based on the requirement.
   * For Example: <Research Line><21-Jul-2021><Unique Timestamp>.json.
   *
   * @returns {String} A filename, that matches the above format.
   */

  const generateFileName = () => {
    let fileName = getFullFileName()
    xlsFileName = `${fileName}.json`.replace('Management', 'Mgmt')
    return xlsFileName
  }

  /**
   * Store App State in JSON format in a presigned S3 URL returned by : /upload-json-url endpoint.
   *
   * @param {Object} awsPresignedData Presigned URL, key and other credentials to access AWS S3 bucket.
   * @param {Object} jsonPayload JSON Object that'll be stored in S3.
   */

  const storeAppStateInS3 = async (awsPresignedData, jsonPayload) => {
    const appData = new FormData()
    Object.keys(awsPresignedData.fields).forEach((key) => {
      appData.append(key, awsPresignedData.fields[key])
    })
    appData.append(
      'file',
      new Blob([JSON.stringify(jsonPayload)], { type: 'application/json' })
    )

    //Upload json file
    await axios.post(awsPresignedData.url, appData)

    // Get xls download url
    const { data } = await ExportService.getExcelReport({
      portfolioId: portfolio,
      xlsFileName
    })

    if (data && data.url) {
      // Start download
      window.location = data.url
    }

    filterDispatch({ type: REDUCER_TYPE.CLOSE_BACKDROP })
  }

  /**
   * Formatting App Data in Excel friendly format.
   *
   * @param {Object} bodyData data to be formatted.
   * @param {Object} columns for each widget.
   * @param {Boolean} isKeepPristine is true it doesn't delete any field
   */

  const formatAppData = (bodyData, columns, isKeepPristine = false) => {
    let dfArr = []
    columns &&
      columns.forEach((col) => {
        const dataFields = col.DataField
        dataFields &&
          dataFields.forEach((dataField) => {
            dfArr.push({
              name: dataField.ApiField || dataField.Name,
              hidden: col.HideColumn,
              numberType: dataField.NumberType,
              fallbackField: dataField.FallbackField?.Name
            })
          })
      })
    dfArr.forEach((col) => {
      if (Array.isArray(bodyData)) {
        bodyData.forEach((row) => {
          if (
            col.numberType &&
            Object.prototype.hasOwnProperty.call(row, col.name)
          ) {
            row[col.name] = exportNumberData(row[col.name], col.numberType)
          }
          if (
            Object.prototype.hasOwnProperty.call(row, col.name) &&
            row[col.name] === ''
          ) {
            row[col.name] = row[col.fallbackField]
              ? row[col.fallbackField]
              : '-'
          }
          // Find the column to be shown, if there's no data from API response add "-",
          // and remove items from app state if it shouldn't be displayed in XL.
          if (
            !Object.prototype.hasOwnProperty.call(row, col.name) &&
            (filterState.globalPortfolioFilter.benchmark !== '' || !col.hidden)
          ) {
            row[col.name] = row[col.fallbackField]
              ? row[col.fallbackField]
              : '-'
          }
          !isKeepPristine &&
            Object.keys(row).forEach((key) => {
              if (
                !dfArr.find(
                  (element) =>
                    key === element.name || key === element.fallbackField
                )
              ) {
                delete row[key]
              }
            })
        })
      } else {
        Object.keys(bodyData).forEach((key) => {
          // Find the column to be shown, if there's no data from API response add "-",
          // and remove items from app state if it shouldn't be displayed in XL.
          bodyData[key].forEach((row) => {
            if (
              col.numberType &&
              Object.prototype.hasOwnProperty.call(row, col.name)
            ) {
              row[col.name] = exportNumberData(row[col.name], col.numberType)
            }
            if (
              Object.prototype.hasOwnProperty.call(row, col.name) &&
              row[col.name] === ''
            ) {
              row[col.name] = row[col.fallbackField]
                ? row[col.fallbackField]
                : '-'
            }
            if (
              !Object.prototype.hasOwnProperty.call(row, col.name) &&
              (filterState.globalPortfolioFilter.benchmark !== '' ||
                !col.hidden)
            ) {
              row[col.name] = row[col.fallbackField]
                ? row[col.fallbackField]
                : '-'
            }
            Object.keys(row).forEach((key) => {
              if (
                !dfArr.find(
                  (element) =>
                    key === element.name || key === element.fallbackField
                )
              ) {
                delete row[key]
              }
            })
          })
        })
      }
    })
  }

  /**
   *  Add categories to underlying data metrics data for Carbon Footprint,
   *  Market Risk and Supply Chain Risk research lines.
   *
   * @param {[]} apiData array from underlying data metrics API response.
   * @param {Boolean} isCFPage true if carbon footprint's data is being formatted, false otherwise.
   */

  const addCategoriesOnUDMData = (apiData, isCFPage = false) => {
    const dataArr = []
    let i = 0
    let udmData = isCFPage
      ? apiData.data_export
        ? apiData.data_export
        : apiData.data
      : apiData
    udmData.forEach((element, index) => {
      if (isCFPage) {
        let dataObj = {}
        let min = element.min
          ? exportNumberData(element.min, { isCommaSeparated: true })
          : ''
        let max = element.max
          ? exportNumberData(element.max, { isCommaSeparated: true })
          : ''
        dataObj['category'] = `${min}-${max}`
        dataObj['data'] = [element.data]
        apiData.data[i++] = dataObj
      } else {
        element.forEach((item, index) => {
          let dataObj = {}
          dataObj['category'] = `${index * 10}-${index * 10 + 9}` //creates category in the format: ["0-9", "10-19" ... "90-100"];
          if (!isCFPage && index === element.length - 1) {
            dataObj['category'] = `${index * 10}-${index * 10 + 10}`
          }
          dataObj['data'] = [item]
          dataArr.push(dataObj)
        })
        apiData[index] = dataArr
      }
    })
  }

  /**
   *  Format underlying data metrics data per Export requirement for each research line.
   *
   * @param {[]} data array from underlying data metrics API response.
   */

  const formatUDMData = (data, widgetTitle = '') => {
    if (widgetTitle) {
      switch (widgetTitle) {
        case `${PAGE_CON.OPR_RSK_TITLE}${PAGE_CON.RISK_CAT_TITLE}`:
          data.forEach((metric) => {
            metric.data.forEach((section) => {
              // eslint-disable-next-line no-unused-vars
              section = section.reverse()
            })
          })
          break
        case `${PAGE_CON.MRK_RSK_TITLE}${PAGE_CON.RISK_CAT_TITLE}`:
        case `${PAGE_CON.SUPP_CHN_RISK_TITLE}${PAGE_CON.RISK_CAT_TITLE}`:
          data.forEach((metric) => {
            addCategoriesOnUDMData(metric.data)
          })
          break
        default: // Do nothing
      }
    } else {
      switch (props.pageTitle) {
        case PAGE_CON.OPR_RSK_TITLE:
          data.forEach((metric) => {
            metric.data.forEach((section) => {
              // eslint-disable-next-line no-unused-vars
              section = section.reverse()
            })
          })
          break
        case PAGE_CON.BRWN_SHR_ASM_TITLE:
        case PAGE_CON.ENR_TRN_MGM_TITLE:
        case PAGE_CON.GRN_SHR_ASM_TITLE:
          data.forEach((metric) => {
            metric.data.forEach((section) => {
              section = section.reverse()
              section.forEach((category) => {
                category.data = category.count
                  ? [category.count]
                  : category.data
                delete category.count
              })
            })
          })
          break
        case PAGE_CON.MRK_RSK_TITLE:
        case PAGE_CON.SUPP_CHN_RISK_TITLE:
          data.forEach((metric) => {
            addCategoriesOnUDMData(metric.data)
          })
          break
        case PAGE_CON.CRBN_FT_PRT_TITLE:
          data.forEach((metric) => {
            metric.total_scope_emission = exportNumberData(
              metric.total_scope_emission,
              { isCommaSeparated: true }
            )
          })
          break
        default: // Do nothing
      }
    }
  }

  const getSelectedCardData = (data, regionName, showScore) => {
    let rdata = []
    if (data.details && data.details.length > 0) {
      let summaryData = data.summary.filter(
        (data) => data.name === regionName
      )[0].portfolio_distribution
      let rdetailDataArr = data.details.filter(
        (regData) => regData.name === regionName
      )
      if (!rdetailDataArr || rdetailDataArr.length <= 0) return []

      let rdetailData = rdetailDataArr[0]
      const median = {
        '0%': 0,
        '0-10%': 5,
        '10-20%': 15,
        '20-33%': 26.5,
        '33-50%': 41.5,
        '>=50%': 75
      }

      summaryData.map((data) => {
        let categoryData = rdetailData[data.category]
        if (categoryData && categoryData.length > 0) {
          let companyData = categoryData.map((dataRow) => {
            //added score_range for greenshare panel which shows both score_range and category
            const score =
              dataRow.score || dataRow.score_range || data.score_range
            dataRow.category = data.category
            dataRow.score_range = showScore
              ? score
              : dataRow.score_range || data.score_range
            dataRow.median_score = dataRow.score || median[dataRow.score_range]

            return dataRow
          })
          rdata.push({
            category: data.category,
            CompanyData: companyData,
            score_range: data.score_range
          })
        }
        return ''
      })
    }
    return rdata
  }

  /**
   * Fetch formatted app JSON from appState in Excel friendly format.
   *
   * @param {Object} appState App state fetched for current research line.
   * @returns {[]} Excel Friendly Formatted App JSON, which'll be sent as payload with S3 API to be stored in S3 bucket.
   */

  const fetchAppJSON = (appState) => {
    let sortByCols = SCORE_RANGE_PAGES[props.pageTitle] || [
      ['score', 'DESC'],
      ['investment_pct', 'DESC'],
      ['company_name', 'ASC', 'string']
    ]
    let exportPayload = []
    let sectorSummary, sectorDetails
    appState &&
      Object.keys(appState).forEach((key) => {
        const widget = appState[key]
        if (widget && widget.data && widget.title) {
          let item
          let LnLTitle = `${PAGE_CON.LEADERS_TITLE} and ${PAGE_CON.LAGGARDS_TITLE}`
          let widgetTitle = widget.isUnderlyingDataMetrics
            ? PAGE_CON.UNDERLYING_DATA_METRICS
            : widget.title
          //deep copy object to modify references
          let widgetData = JSON.parse(JSON.stringify(widget.data))
          if (
            Object.prototype.hasOwnProperty.call(widgetData, 'errorMessage')
          ) {
            widgetData = []
          }
          // Additional check on Sectors, since Regions & Sectors are grouped together in Post-MVP
          if (widget.title === PAGE_CON.SECTORS_TITLE) {
            widgetData.summary = sectorSummary
            widgetData.details = sectorDetails
          }
          sectorSummary = widgetData.sectorsummary
            ? widgetData.sectorsummary
            : sectorSummary
          sectorDetails = widgetData.sectordetails
            ? widgetData.sectordetails
            : sectorDetails
          switch (widgetTitle) {
            case PAGE_CON.PORTFOLIO_SCORE_TITLE:
              item = {
                title: widget.title,
                body: widgetData
              }
              switch (props.pageTitle) {
                case PAGE_CON.BRWN_SHR_ASM_TITLE:
                case PAGE_CON.GRN_SHR_ASM_TITLE:
                  Object.keys(item.body).forEach((score) => {
                    let scoreData = item.body[score] ? item.body[score][0] : {}
                    if (scoreData) {
                      scoreData.score = scoreData.score
                        ? scoreData.score + ' - ' + scoreData.name
                        : scoreData.name
                        ? scoreData.name
                        : ''
                      delete scoreData.name
                      delete scoreData.ranking
                    }
                  })
                  item.body = [item.body]
                  break
                case PAGE_CON.PHY_RSK_HZRD_TITLE:
                case PAGE_CON.OPR_RSK_TITLE:
                case PAGE_CON.MRK_RSK_TITLE:
                case PAGE_CON.SUPP_CHN_RISK_TITLE:
                case PAGE_CON.TMP_ALM_TITLE:
                  item.body = [item.body]
                  formatAppData(item.body, widget.columns)
                  break
                case PAGE_CON.PHYRSK_MGM_TITLE:
                case PAGE_CON.CRBN_FT_PRT_TITLE:
                case PAGE_CON.ENR_TRN_MGM_TITLE:
                case PAGE_CON.TCFD_STR_TITLE:
                  formatAppData(item.body, widget.columns)
                  item.body = [item.body]
                  break
                default:
                  break
              }
              break
            case PAGE_CON.PORTFOLIO_DISTRIBUTION_TITLE:
              item = {
                title: widget.title,
                body: widgetData
              }
              // eslint-disable-next-line no-case-declarations
              let distCols = widget.columns
              if (item.body && Object.keys(item.body).length > 0) {
                formatAppData(item.body, distCols)
              }
              break
            case PAGE_CON.EMISSIONS_TITLE:
              item = {
                title: widget.title,
                body: widgetData
              }
              widgetData.forEach((emissionData, index) => {
                formatAppData(emissionData.data, widget.columns)
                emissionData['description'] = emissionDescArr[index]
              })
              break
            case PAGE_CON.PORTFOLIO_COVERAGE_TITLE:
              item = {
                title: widget.title,
                body: [widgetData]
              }
              break
            case PAGE_CON.UPDATES_TITLE:
              item = {
                title: widget.title,
                body: widgetData
              }
              if (item.body && item.body.length > 0) {
                if (PHYSICAL_RISK_PAGES.includes(props.pageTitle)) {
                  item.body = multiColumnSort(item.body, [
                    ['score', 'DESC'],
                    ['investment_pct', 'DESC'],
                    ['company_name', 'ASC', 'string']
                  ])
                } else {
                  item.body = multiColumnSort(item.body, [
                    ['last_update_date', 'DESC'],
                    ['investment_pct', 'DESC'],
                    ['company_name', 'ASC', 'string']
                  ])
                }
                formatAppData(item.body, widget.columns, true)
              }
              item.body.length &&
                item.body.forEach((company) => {
                  delete company['score']
                  delete company['previous_score']
                })
              break
            case PAGE_CON.IMPACT_TITLE:
              // eslint-disable-next-line no-case-declarations
              let impactData = widgetData[widget.filter]
              // eslint-disable-next-line no-case-declarations
              let data = { positive_impact: [], negative_impact: [] }
              data['positive_impact'] =
                impactData &&
                impactData['positive_impact']['investment_and_score']
              data['positive_impact'].forEach(
                (impact) =>
                  (impact.score = exportNumberData(impact.score, {
                    isCommaSeparated: true,
                    isDecimal: true
                  }))
              )
              data['negative_impact'] =
                impactData &&
                impactData['negative_impact']['investment_and_score']
              data['negative_impact'].forEach(
                (impact) =>
                  (impact.score = exportNumberData(impact.score, {
                    isCommaSeparated: true,
                    isDecimal: true
                  }))
              )
              item = {
                title: widget.title,
                body: data
              }
              break
            case LnLTitle:
              item = {
                title: widget.title,
                body: widgetData
              }
              Object.keys(item.body).forEach((obj) => {
                if (obj === PAGE_CON.LEADERS_TITLE.toLowerCase()) {
                  item.body[obj] = multiColumnSort(item.body[obj], [
                    ['rank', 'ASC'],
                    ['investment_pct', 'DESC'],
                    ['company_name', 'ASC', 'string', true]
                  ])
                } else {
                  item.body[obj] = multiColumnSort(item.body[obj], [
                    ['rank', 'DESC'],
                    ['investment_pct', 'ASC'],
                    ['company_name', 'DESC', 'string', false]
                  ])
                }
                formatAppData(item.body[obj], widget.columns)
                item.body[obj].forEach((company) => {
                  if (props.pageTitle === PAGE_CON.BRWN_SHR_ASM_TITLE) {
                    company['score_range'] = company['score_category']
                    // JSON to send only score_range for XL to be populated correctly
                    delete company['score_category']
                  }
                })
              })
              break
            case PAGE_CON.REGIONS_TITLE:
            case PAGE_CON.SECTORS_TITLE:
              item = {
                title: widget.title,
                body: widgetData.summary
              }
              //Round off investment value in summary
              item.body &&
                item.body.forEach((region) => {
                  region.investment_pct = parseFloat(
                    region.investment_pct
                  ).toLocaleString('en-US', {
                    maximumFractionDigits: 0
                  })
                  region.weighted_average_score = exportNumberData(
                    region.weighted_average_score,
                    { isCommaSeparated: true }
                  )
                })
              //Sorts region/sector cards
              if (item.body && item.body.length > 0) {
                item.body = multiColumnSort(item.body, [
                  ['companies', 'DESC'],
                  ['name', 'ASC']
                ])
              }
              //Panel Sorting
              item.body.forEach((region) => {
                const showScore =
                  props.pageTitle === PAGE_CON.BRWN_SHR_ASM_TITLE
                let regionData = getSelectedCardData(
                  widgetData,
                  region.name,
                  showScore
                )
                let sortedData = regionData
                if (regionData && regionData.length > 0) {
                  sortedData = regionData.map((category) =>
                    category && category.CompanyData
                      ? multiColumnSort(category.CompanyData, sortByCols)
                      : null
                  )
                }
                region['details'] = sortedData
                region.details.forEach((category) => {
                  formatAppData(category, widget.panelcolumns, true)
                  category.forEach((company) => {
                    if (props.pageTitle === PAGE_CON.BRWN_SHR_ASM_TITLE) {
                      delete company['score']
                      delete company['category'] // category and score_range have same values, lambda uses fields for header label
                    } else if (props.pageTitle === PAGE_CON.GRN_SHR_ASM_TITLE) {
                      delete company['score']
                    } else if (PHYSICAL_RISK_PAGES.includes(props.pageTitle)) {
                      delete company['score_range']
                      delete company['category']
                    } else {
                      delete company['score_range']
                    }
                  })
                })

                // Removing category, to avoid populating it in XL for pages that don't show category (region/sector card) in UI.
                if (NO_CATEGORY_PAGES.includes(props.pageTitle)) {
                  delete region['category']
                }
              })
              if (item.body.length > 0) {
                item.body.forEach((element) => {
                  let distributionData = element.portfolio_distribution
                  formatAppData(distributionData, widget.columns)
                })
              }
              break
            case PAGE_CON.UNDERLYING_DATA_METRICS:
              item = {
                title: widget.title,
                body: widgetData
              }

              if (pageTitle === PAGE_CON.PHY_RSK_HZRD_TITLE) {
                formatUDMData(item.body, item.title)
              } else {
                formatUDMData(item.body)
              }

              widget.subtitle &&
                item.body.forEach((data) => {
                  data['subtitle'] = widget.subtitle
                })
              break
            default:
              item = {
                title: widget.title,
                body: widgetData
              }
          }
          exportPayload.push(item)
        }
      })
    return exportPayload
  }

  /**
   * Fetch AWS Presigned data from /upload-json-url endpoint,
   * populate App State into JSON, and send it back to be stored into Presigned S3 bucket.
   */

  const populateStateIntoJSON = async () => {
    filterDispatch({
      type: REDUCER_TYPE.OPEN_BACKDROP,
      backdropText: PAGE_CON.EXPORT_EXCEL
    })
    try {
      const { data } = await ExportService.getUploadJsonUrl({
        portfolioId: portfolio,
        fileName: generateFileName()
      })
      let exportPayload = fetchAppJSON(currAppState)
      await storeAppStateInS3(data, exportPayload)
    } catch (err) {
      console.error(err)
      setPopoverErrorOpen(true)
      filterDispatch({ type: REDUCER_TYPE.CLOSE_BACKDROP })
    }
  }

  /**
   * Check if the page has loaded with all the widgets to enable Export feature on the page.
   *
   * @returns {Boolean} true if all the widgets and filter state has loaded and false otherwise.
   */

  const isExportDataAvailable = () => {
    let isDataLoading = false

    if (filterState.isLoading) return true

    isDataLoading =
      currAppState &&
      Object.keys(currAppState).some((key) => {
        return (
          (currAppState[key]?.isLoading || false) === true &&
          (currAppState[key]?.dataStatus || '') !== ''
        )
      })

    return isDataLoading
  }

  const getResearchLine = () => {
    return portfolioAnalysisNavItems.PortfolioAnalysis.filter(
      (x) => x.title === pageTitle
    )[0].entitled
  }

  const getText = (selectedkey) => {
    let filterResult = filterState.filterData.portfolioList.filter(
      (x) => x.key === selectedkey
    )
    if (filterResult && filterResult.length > 0)
      return filterResult[0][PAGE_CON.DROPDOWN_DISPLAY_COLUMN]
    return ''
  }

  const esgExcelGeneration = async () => {
    filterDispatch({
      type: REDUCER_TYPE.OPEN_BACKDROP,
      backdropText: PAGE_CON.EXPORT_EXCEL
    })

    try {
      const payLoad = {
        [PARAMS.PORTFOLIO_NAME]: getText(
          filterState.globalPortfolioFilter.portfolio
        ),
        [PARAMS.MONTH]: filterState.globalPortfolioFilter.asOfDate.slice(4),
        [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(0, 4),
        [PARAMS.SECTOR]:
          filterState.globalPortfolioFilter.sectors === ''
            ? PAGE_CON.ALL_FILTER
            : filterState.globalPortfolioFilter.sectors,
        [PARAMS.REGION]:
          filterState.globalPortfolioFilter.regions === ''
            ? PAGE_CON.ALL_FILTER
            : filterState.globalPortfolioFilter.regions
      }

      const { data } = await ExportService.postExcelReport({
        portfolio,
        esgConstant: GBL_FLT.ESG_ASSESSMENT,
        fileName: payLoad
      })

      if (data?.url !== '') {
        const link = document.createElement('a')
        if (link.download !== undefined) {
          // feature detection
          link.setAttribute('href', data.url)
          link.setAttribute('download', data.url)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      } else {
        setPopoverErrorOpen(true)
      }

      filterDispatch({ type: REDUCER_TYPE.CLOSE_BACKDROP })
    } catch (err) {
      console.log(err)
      setPopoverErrorOpen(true)
      filterDispatch({ type: REDUCER_TYPE.CLOSE_BACKDROP })
    }
  }

  const showErrorPopover = (message) => {
    count.current = count.current + 1

    if (message) {
      setErrorPopoverMessage(message)
    }
    setTimeout(
      (counter) => {
        if (counter === count.current) setErrorPopoverMessage(null)
      },
      4000,
      count.current
    )
  }

  const sendPdfRequest = async () => {
    let retData = {}
    filterDispatch({
      type: REDUCER_TYPE.OPEN_BACKDROP,
      backdropText: PAGE_CON.LOADING_SCREEN
    })

    try {
      const payLoad = {
        [PARAMS.FILE_NAME]: getFullFileName(),
        [PARAMS.RESEARCHLINE]: getResearchLine(),
        [PARAMS.PORTFOLIO_ID]: filterState.globalPortfolioFilter.portfolio,
        [PARAMS.MONTH]: filterState.globalPortfolioFilter.asOfDate.slice(4),
        [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(0, 4),
        [PARAMS.SECTOR]:
          filterState.globalPortfolioFilter.sectors === ''
            ? PAGE_CON.ALL_FILTER
            : filterState.globalPortfolioFilter.sectors,
        [PARAMS.REGION]:
          filterState.globalPortfolioFilter.regions === ''
            ? PAGE_CON.ALL_FILTER
            : filterState.globalPortfolioFilter.regions,
        [PARAMS.BENCHMARK_ID]: filterState.globalPortfolioFilter.benchmark,
        [PARAMS.IMPACT_FILTER]: currAppState?.portfolioImpact?.filter || '',
        [PARAMS.REPORT_TYPE]: 'Portfolio'
      }

      const { data } = await ExportService.postPdfExport({ ...payLoad })
      retData = data
    } catch (err) {
      console.log(err)
    }

    filterDispatch({ type: REDUCER_TYPE.CLOSE_BACKDROP })
    return retData
  }

  const pdfGeneration = async () => {
    const routeParam = ':id'
    const newWindow = window.open(
      ROUTE_PATHS.PORTFOLIO_ANALYSIS_STATUS.replace(routeParam, 'waiting'),
      '_blank'
    )

    if (newWindow) {
      const data = await sendPdfRequest()

      if (data?.request_id && data?.status === 'new') {
        newWindow.location.href = ROUTE_PATHS.PORTFOLIO_ANALYSIS_STATUS.replace(
          routeParam,
          data.request_id
        )
      } else {
        if (data?.status === 'pending') {
          showErrorPopover('PDF generating. Try again later.')
        } else {
          showErrorPopover(
            'Export failed because of an issue, please try again later.'
          )
        }
        newWindow.close()
      }
    }
  }

  const onExportChange = async (item) => {
    if (item.target.value === 'pdf') {
      pdfGeneration()
    } else if (
      item.target.value === 'xlsx' &&
      props.pageTitle === PAGE_CON.ESG_ASSESSMENT_TITLE
    ) {
      esgExcelGeneration()
    } else {
      populateStateIntoJSON()
    }
  }

  return (
    <>
      <DropdownField
        id="ExportDropdown-test-id-1"
        dropdownDefaultSelction={''}
        saveValueUsingExternalState={true}
        dropdownOptions={exportData}
        onChange={onExportChange}
        labelTextDisabled={true}
        labelText=""
        alternateLabelText="Export"
        defaultData={''}
        isLoading={isExportDataAvailable()}
      />
      <PrimaryPopover
        alertOpen={errorPopoverMessage !== null}
        alertIcon={
          <IconError
            colorprimary={themeColorPalette.palette.sys_trad.text.main}
          />
        }
        alertText={<span>{errorPopoverMessage}</span>}
        alertPosition="center"
        alertWidth={'190px'}
        alertTop="55px"
        alertTextTopPadding={'1px'}
        paddingTop="4px"
        paddingBottom="4px"
        paddingLeft="8px"
        paddingRight="8px"
        overflowY="hidden"
        hideBackdrop={true}
        hideClose={true}
        popoverShadow={false}
        popoverBgColor={themeColorPalette.palette.sys_trad.warning.main}
      />
    </>
  )
}

export default ExportComp
