import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Popper from '@material-ui/core/Popper'
import PropTypes from 'prop-types'
import { PAGE_CON } from 'constant/constants'
import { useStyles } from './styles'

const CustomPopper = function (props) {
  const classes = useStyles(props)
  return <Popper {...props} className={classes.root} placement="bottom" />
}

const ComboBox = (props) => {
  const classes = useStyles(props)
  const getSelectedObj = (val) =>
    props.comboBoxOptions.find((x) => x.key === val) || null

  return (
    <Autocomplete
      id={props.id}
      disableClearable
      PopperComponent={CustomPopper}
      className={classes.autocompleteStyle}
      options={props.comboBoxOptions}
      onChange={(_, newValue) => props.onChange(newValue)}
      getOptionLabel={(option) =>
        option[PAGE_CON.DROPDOWN_DISPLAY_COLUMN] || ''
      }
      value={getSelectedObj(props.value)}
      disabled={props.disabled}
      classes={{
        option: classes.option,
        noOptions: classes.noOptions,
        popupIndicator: classes.popupIndicator
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes[props.displayTextClassName]}
          label={props.labelText}
        />
      )}
    ></Autocomplete>
  )
}

ComboBox.propTypes = {
  /** ComboBox id */
  id: PropTypes.string,
  /** Options in ComboBox */
  comboBoxOptions: PropTypes.any,
  /** onChange */
  onChange: PropTypes.func,
  /** Default or selected value to display in ComboBox */
  value: PropTypes.any,
  /** Max width for ComboBox */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Max height for ComboBox */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Show label for ComboBox */
  labelText: PropTypes.string,
  /** Disabled */
  disabled: PropTypes.bool,
  /** classname for textfield */
  displayTextClassName: PropTypes.string
}

ComboBox.defaultProps = {
  displayTextClassName: 'textStyle'
}

export default ComboBox
