import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import theme from 'theme'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100,
    overflow: 'auto',
    background: `${theme.palette?.sys_trad?.main?.main}50`
  },
  modal: {
    display: 'flex',
    background: '#FFFFFF',
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.12)',
    borderRadius: '2px',
    padding: '0',
    width: '60vw',
    '@media (max-width: 768px)': {
      width: '85vw'
    },
    maxWidth: '780px',
    flexDirection: 'column',
    position: 'absolute',
    top: 100
  },
  titleContainer: {
    position: 'relative',
    padding: '24px',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    marginBottom: '12px',
    backgroundColor: theme.palette?.sys_trad?.bg.main,
    flexDirection: 'column',
    '& span.close': {
      position: 'absolute',
      top: '24px',
      right: '24px',
      cursor: 'pointer'
    },
    borderBottom: `1px solid ${theme.palette?.sys_trad?.border.main}`
  },
  content: {
    flex: 1,
    padding: '24px',
    ...theme.typography.p3
  }
}))

export const Container = ({ children, onClick }) => {
  const classes = useStyles(theme)
  return (
    <div aria-hidden={true} onClick={onClick} className={classes.container}>
      {children}
    </div>
  )
}

export const Modal = ({ children }) => {
  const classes = useStyles(theme)
  return (
    <div
      aria-hidden={true}
      onClick={(e) => e.stopPropagation()}
      className={classes.modal}
    >
      {children}
    </div>
  )
}

export const TitleContainer = ({ children }) => {
  const classes = useStyles(theme)
  return <div className={classes.titleContainer}>{children}</div>
}

export const Content = ({ children }) => {
  const classes = useStyles(theme)
  return <div className={classes.content}>{children}</div>
}
