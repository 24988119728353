import FormControl from '@material-ui/core/FormControl'
import List from '@material-ui/core/List'
import LoadingMask from 'utils/loadingMask'
import { PAGE_CON } from 'constant/constants'
import Error from 'utils/error'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useStyles } from './styles'

/**
 * @visibleName List
 */
const ListComp = (props) => {
  const classes = useStyles(props)
  const [selectedItem, setSelectedItem] = useState(props.listDefaultSelection)

  useEffect(() => {
    setSelectedItem(props.listDefaultSelection)
  }, [props.listDefaultSelection])

  const onSelect = (selectedKey) => {
    if (selectedKey === selectedItem) return
    setSelectedItem(selectedKey)

    if (props.onChange) props.onChange(selectedKey)
  }

  return (
    <FormControl className={classes.formCtrlStyle}>
      <div className={classes.title}>{props.titleText}</div>
      {!props.isLoading ? (
        props.hasError ? (
          <Error
            dataStatus={PAGE_CON.ERROR}
            id={'listError'}
            onErrorClick={props.errorReload}
            errorOccuredOn={''}
            width={props.width}
          />
        ) : (
          <List
            className={classes.list}
            component="div"
            id={props.id}
            key={props.id}
          >
            {props.labelText && props.labelText !== '' && (
              <ListItem
                button
                selected={selectedItem === props.labelTextKey}
                onClick={() => onSelect(props.labelTextKey)}
                TouchRippleProps={{ className: classes.ripple }}
                key={props.labelTextKey}
                {...(props.listLabel
                  ? {
                      heap_id: 'filter',
                      heap_filter: `${props.listLabel}_${props.labelText}`
                    }
                  : {})}
              >
                <ListItemText primary={props.labelText} />
              </ListItem>
            )}
            {props.listOptions.map((item) => {
              return (
                <ListItem
                  button
                  selected={selectedItem === item.key}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={selectedItem === item.key}
                  onClick={() => onSelect(item.key)}
                  TouchRippleProps={{ className: classes.ripple }}
                  key={item.key}
                  {...(props.listLabel
                    ? {
                        heap_id: 'filter',
                        heap_filter: `${props.listLabel}_${item.displayText}`
                      }
                    : {})}
                >
                  <ListItemText
                    primary={item[PAGE_CON.DROPDOWN_DISPLAY_COLUMN]}
                  />
                </ListItem>
              )
            })}
          </List>
        )
      ) : (
        <div className={classes.loadingDivStyle}>
          <LoadingMask height={24} width={50} />
        </div>
      )}
    </FormControl>
  )
}

ListComp.propTypes = {
  /** ListComp id */
  id: PropTypes.string,
  /** Options in ListComp */
  listOptions: PropTypes.any,
  /** onChange */
  onChange: PropTypes.func,
  /** The Key of the default selected option for ListComp (must match a key in listOptions object ) */
  listDefaultSelection: PropTypes.any,
  /** Max width for ListComp */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Max height for ListComp */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** label key value for ListComp */
  labelTextKey: PropTypes.string,
  /** Show label for ListComp */
  labelText: PropTypes.string,
  /** true, if component is currently loading */
  isLoading: PropTypes.bool,
  /** Show title for ListComp */
  titleText: PropTypes.string,
  /** error while getting data */
  hasError: PropTypes.bool,
  /** having reference of parent function to be called */
  errorReload: PropTypes.func
}

export default ListComp
