import React, { useState } from 'react'
import { getMonthDayYear } from 'utils/Date'
import PortfolioMonitoringModal from 'views/dashboard/PortfolioMonitoring/Modal'
import * as S from './styles'

const MaterialityRelatedControversy = (props) => {
  const classes = S.useStyles(props)
  const [showModal, setShowModal] = useState(false)
  const [popupData, setPopupData] = useState()

  const openModal = ({
    controversy_title,
    severity,
    sources,
    controversy_description,
    controversy_events,
    controversy_risk_mitigation,
    responsiveness
  }) => {
    setPopupData({
      controversy_title,
      severity,
      sources,
      controversy_events,
      controversy_description,
      controversy_risk_mitigation,
      responsiveness
    })
    setShowModal(true)
  }

  return (
    <>
      {showModal && (
        <PortfolioMonitoringModal
          closeModalAction={() => setShowModal(false)}
          selectedControversy={popupData}
        />
      )}
      <section
        aria-hidden={true}
        id="controversy-section"
        className={classes.recentControversies_material_related_con}
        onClick={() => props.onClick() || openModal(props.controversy)}
      >
        {props.controversy?.severity === 'Critical' && (
          <span className={classes.criticalLabel}>Critical</span>
        )}
        <span className={classes.controversyTitle}>
          {props.controversy?.controversy_title}
        </span>
        <span className={classes.controversyDate}>
          {getMonthDayYear(props.controversy?.controversy_events)}
        </span>
      </section>
    </>
  )
}

export default MaterialityRelatedControversy
