import { GlobalContext } from 'context/GlobalStateProvider'
import { COMMON, PARAMS, PHY_RSK_HZRD } from 'constant/APIconstants'
import { ResearchLineService } from 'services'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import axios from 'axios'
import Box from '@material-ui/core/Box'
import { Card, CardInfoSection, Legend, PrimaryLink } from 'components'
import Error from 'utils/error'
import Grid from '@material-ui/core/Grid'
import LoadingMask from 'utils/loadingMask'
import { makeStyles } from '@material-ui/core/styles'
import numberData from 'utils/numberFormat'
import OverViewText from '../../PortfolioAnalysisOverViewText.json'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import PhysicalRiskColumns from './../PhysicalRiskColumns.json'
import getColor from 'utils/color'

const portfolioDistributionColumns = JSON.parse(
  JSON.stringify(
    PhysicalRiskColumns.physicalRisk_Portfolio_Distribution_Columns
  )
)

const cardInfoContentInfo = JSON.parse(
  JSON.stringify(PhysicalRiskColumns.physicalrisk_ESG_score_new)
)

const outerColor = themeColorPalette.palette.sys_trad.bg.main

const overViewText = {
  ...textTheme.typography.p3,
  color: themeColorPalette.palette.sys_trad.text.main,
  marginTop: '12px',
  marginBottom: '12px'
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    background: '#ffff',
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.14)',
    borderRadius: '2px'
  },
  verticalHeader: {
    ...textTheme.typography.h2,
    fontSize: '16px !important',
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    textAlign: 'right',
    paddingBottom: '16px',
    marginRight: '-12px'
  },
  paper: {
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  benchmarkGrid: {
    paddingBottom: 6,
    marginLeft: -8
  },
  grid: {
    textAlign: 'left',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  portfolioCoverageHeaders: {
    ...textTheme.typography.p4,
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.text2.main,
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    padding: '4px',
    marginBottom: '4px',
    minWidth: (props) => props.coverageHeaderWidth || '100%',
    width: (props) => props.coverageHeaderWidth || '100%'
  },
  portfolioCoverageHeadersRight: {
    ...textTheme.typography.p4,
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.text2.main,
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    marginLeft: '4px',
    marginBottom: '4px',
    padding: '4px',
    minWidth: (props) => props.coverageHeaderWidth || '100%',
    width: (props) => props.coverageHeaderWidth || '100%'
  },
  portfolioCoverageHeadersSmall: {
    ...textTheme.typography.p4,
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.text2.main,
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    marginBottom: '4px',
    padding: '4px',
    minWidth: '49%',
    width: '49%'
  },
  portfolioTable: {
    paddingRight: '24px',
    marginBottom: '22px',
    paddingBottom: '0px',
    width: '100%',
    minWidth: '290px'
  },
  benchmarkTable: {
    paddingRight: '24px',
    paddingBottom: '16px',
    width: '100%',
    minWidth: '290px'
  },
  coverageBenchmarkGrid: {
    paddingLeft: '8px',
    marginLeft: 0
  },
  fourBoxContainerStyle: {
    paddingLeft: '36px',
    paddingRight: '16px',
    paddingTop: '16px',
    paddingBottom: '0px'
  },

  cardInfoFiller: {
    minHeight: '148px',
    borderRadius: '2px',
    padding: '0px',
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main
  },
  chartBoxFiller: {
    minHeight: '182px',
    maxWidth: '282px',
    background: themeColorPalette.palette.sys_trad.bg.main
  },
  historyBoxFiller: {
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.sys_trad.text.main,
    minHeight: '190px',
    maxWidth: '282px',
    textAlign: 'left',
    background: themeColorPalette.palette.sys_trad.bg.main
  },
  historyLabel: {
    padding: '4px 4px 8px',
    boxDecorationBreak: 'clone'
  },

  distributionBoxFiller: {
    minHeight: '129px',
    background: themeColorPalette.palette.sys_trad.bg.main
  },

  benchmarkBoxStyle: {
    minHeight: '32px',
    maxWidth: '1148px',
    marginTop: '24px',
    marginBottom: '24px',
    padding: '0px',
    display: 'table',
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main
  },
  phyHazardStyle: {
    ...textTheme.typography.p3,
    fontSize: '10px',
    lineHeight: '16px',
    color: themeColorPalette.palette.sys_trad.text.main
  },
  phyHazardStyleLarge: {
    ...textTheme.typography.h1,
    fontSize: '20px',
    lineHeight: '16px',
    color: themeColorPalette.palette.sys_trad.text.main
  },
  phyHazardStyleRisk: {
    ...textTheme.typography.h1,
    fontSize: '14px',
    lineHeight: '16px',
    color: themeColorPalette.palette.white.main
  },
  benchmarkTextStyle: {
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.sys_trad.texthelp.main,
    verticalAlign: 'middle',
    textAlign: 'center',
    display: 'table-cell'
  },
  tempStyle: {
    ...textTheme.typography.h2,
    fontSize: '12px',
    lineHeight: '16px'
  },
  infoBoxStyle: {
    padding: '0px',
    margin: '4px 0 16px'
  },
  scoreCardStyle: {
    margin: '12px',
    marginBottom: '0px'
  }
}))

const PhysicalRiskHazardsOverView = (props) => {
  const classes = useStyles(props)
  const [filterState] = useContext(GlobalContext).globalFilter
  const pageState = props.pageState
  const pageDispatch = props.pageDispatch
  let apiCallId = props.apiCallId
  const pageConstant = props.pageConstant
  const baseUrl = props.baseUrl

  const [state, setState] = useState({
    smallResize: false
  })

  const { smallResize } = state

  const isFilterStateAvailable =
    filterState.filterData.regionsList.length > 0 ||
    filterState.filterData.sectorsList.length > 0

  const getHistoryData = async (sectors, regions, isErrorReload = false) => {
    if (pageState.portfolioHistory.dataStatus !== '' && isErrorReload === false)
      return

    pageDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_HISTORY
    })
    let history = {}

    try {
      const { data } = isFilterStateAvailable
        ? await axios.post(
            COMMON.BASE_URL +
              filterState.globalPortfolioFilter.portfolio +
              `${baseUrl}` +
              COMMON.ENTITY_SCORE_HISTORY,
            {
              [PARAMS.REGION]: regions,
              [PARAMS.SECTOR]: sectors,
              [PARAMS.MONTH]:
                filterState.globalPortfolioFilter.asOfDate.slice(4),
              [PARAMS.YEAR]: filterState.globalPortfolioFilter.asOfDate.slice(
                0,
                4
              ),
              [PARAMS.BENCHMARK_ID]: filterState.globalPortfolioFilter.benchmark
            },
            { cancelToken: pageState.cancelToken.token }
          )
        : []

      history = data[0]

      pageDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_HISTORY,
        data: history,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        pageDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_HISTORY,
          apiCallId
        })
      }
    }
  }

  const getESGScoreData = async (sectors, regions, isErrorReload = false) => {
    if (
      (pageState.portfolioESGScore.data.score.length > 0 &&
        pageState.portfolioESGScore.data.coverage.length > 0 &&
        pageState.portfolioESGScore.data.distribution.length > 0) ||
      (pageState.portfolioESGScore.dataStatus !== '' && isErrorReload === false)
    )
      return

    pageDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_ESGSCORE
    })
    let ESGScore = { score: [], distribution: [], coverage: {} }
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter

    try {
      if (isFilterStateAvailable) {
        const [score, distribution, coverage] = await axios.all([
          ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            researchLine: PHY_RSK_HZRD.PAGE_URL,
            token: pageState.cancelToken.token,
            endPoint: COMMON.SCORE
          }),
          ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            researchLine: pageConstant,
            token: pageState.cancelToken.token,
            endPoint: COMMON.DISTRIBUTION
          }),
          ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            researchLine: pageConstant,
            token: pageState.cancelToken.token,
            endPoint: COMMON.COVERAGE
          })
        ])

        ESGScore.score = Object.keys(score.data).length > 0 ? score.data[0] : []
        ESGScore.distribution =
          distribution.data.length > 0 ? distribution.data[0] : []
        ESGScore.coverage = coverage.data.length > 0 ? coverage.data[0] : []
      }

      pageDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_ESGSCORE,
        data: ESGScore,
        apiCallId
      })

      pageDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_SCORE,
        data: ESGScore.score,
        apiCallId
      })
      pageDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_DISTRIBUTION,
        data: ESGScore.distribution,
        apiCallId
      })
      pageDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_COVERAGE,
        data: ESGScore.coverage,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        pageDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_ESGSCORE,
          apiCallId
        })
      } else console.log(err)
    }
  }

  const reloadComponent = (errorOccuredOn) => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions

    switch (errorOccuredOn) {
      case PAGE_CON.PORTFOLIO_ESGSCORE_TITLE:
      case PAGE_CON.BENCHMARK_ESG_TITLE:
        getESGScoreData(sectors, regions, true)
        break
      case PAGE_CON.PORTFOLIO_HISTORY_TITLE:
        getHistoryData(sectors, regions, true)
        break
      default:
        break
    }
  }

  const getOperationRiskData = useCallback(async () => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    if (filters.portfolio !== '' && !filterState.isLoading) {
      getESGScoreData(sectors, regions)
      getHistoryData(sectors, regions)
    }
  }, [filterState.actionData])

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      getOperationRiskData()
    }
    const setResponsiveness = () => {
      return window.innerWidth < 1050
        ? setState((prevState) => ({ ...prevState, smallResize: true }))
        : setState((prevState) => ({ ...prevState, smallResize: false }))
    }
    setResponsiveness()

    window.addEventListener('resize', setResponsiveness, true)
    return () => window.removeEventListener('resize', setResponsiveness, true)
  }, [filterState.actionData])

  const openAboutLinkOpen = () => {
    let aboutPDF =
      process.env.PUBLIC_URL +
      '/assets/Methodology_Corporate Physical Climate Risk  Operations Risk (Jan 2022).pdf'
    window.open(aboutPDF, '_blank')
  }

  const colorLabel = () => {
    let legendData = []

    if (pageState.portfolioESGScore.dataStatus === PAGE_CON.SUCCESS) {
      let distributionData = []
      if (
        (pageState?.portfolioESGScore?.data?.distribution
          ?.portfolio_distribution?.length || 0) > 0
      )
        distributionData =
          pageState.portfolioESGScore.data.distribution.portfolio_distribution
      else if (
        (pageState?.portfolioESGScore?.data?.distribution
          ?.benchmark_distribution?.length || 0) > 0
      )
        distributionData =
          pageState.portfolioESGScore.data.distribution.benchmark_distribution

      distributionData.length > 0 &&
        distributionData.map((Cdata) => {
          return legendData.push(Cdata.score_range)
        })
    }

    return legendData
  }

  const isDistributionHasData = () => {
    let hasData = false
    if (
      (pageState?.portfolioESGScore?.data?.distribution?.portfolio_distribution
        ?.length || 0) > 0
    )
      hasData = true
    else if (
      (pageState?.portfolioESGScore?.data?.distribution?.benchmark_distribution
        ?.length || 0) > 0
    )
      hasData = true

    return hasData
  }

  let colorGroup =
    portfolioDistributionColumns[0].DataField[0].categoryColor.colorGroup
  let legendColor = colorLabel()

  const scoreCardESGData = (hazard, pct_facilities, coverage, id) => {
    coverage.investment =
      Number(coverage.investment) >= 1
        ? `${coverage.investment}`
        : Number(coverage.investment) === 0
        ? `${coverage.investment}`
        : '<1'

    const scoreBackgroundColor = getColor(
      'RISK',
      id === 'PortfolioScoreESG-test-id'
        ? pageState.portfolioESGScore.data.score.hrh_risk_category
        : pageState.portfolioESGScore.data.score.benchmark_hrh_risk_category
    )

    let comp = [
      <Grid
        key="grid-id-1"
        container
        direction="column"
        spacing={2}
        className={classes.tempStyle}
      >
        <Grid
          item
          xs={12}
          className={classes.scoreCardStyle}
          style={{ backgroundColor: scoreBackgroundColor }}
        >
          <span
            className={classes.phyHazardStyleRisk}
          >{`Highest Risk Hazard: ${hazard}`}</span>
        </Grid>
      </Grid>,
      <Grid
        key="grid-id-2"
        container
        direction="column"
        spacing={2}
        className={classes.tempStyle}
      >
        <Grid
          item
          xs={12}
          className={classes.scoreCardStyle}
          style={{
            marginBottom: '8px',
            backgroundColor: themeColorPalette.palette.genrang_b.five.main
          }}
        >
          <span className={classes.phyHazardStyleLarge}>
            <b>{`${numberData(pct_facilities, {
              isLessThanOneFormat: true,
              isPercentage: true
            })} FACILITIES EXPOSED`}</b>{' '}
            <br />{' '}
            <span className={classes.phyHazardStyle}>
              {' '}
              to High Risk and Red Flag Risk
            </span>
          </span>
        </Grid>
      </Grid>,
      <CardInfoSection
        key={'phy-risk-mgm-test-id'}
        id={'phy-risk-mgm-test-id-from-overview-'}
        contentInfo={cardInfoContentInfo}
        data={coverage}
        scoreTable={true}
        minWidthCoverage={255}
        outerBackgroundColor={outerColor}
      />
    ]
    return comp
  }

  const portfolio_score =
    pageState?.portfolioESGScore?.data?.score?.hrh_risk_category
  const benchmark_score =
    pageState?.portfolioESGScore?.data?.score?.benchmark_hrh_risk_category

  return (
    <div className={classes.root}>
      <Grid
        id={props.id}
        container
        direction={'column'}
        spacing={2}
        className={classes.paper}
        style={{
          paddingLeft: '20px',
          paddingRight: '16px',
          paddingTop: '16px',
          paddingBottom: '0px',
          marginBottom: '12px'
        }}
      >
        <Grid
          id="portfolio_4_box"
          container
          item
          direction={'row'}
          spacing={3}
          style={{
            paddingLeft: '16px',
            paddingRight: '16px',
            paddingTop: '4px',
            paddingBottom: '0px'
          }}
        >
          <span className={classes.verticalHeader}> Portfolio </span>
          <Grid
            item
            id="cardInfo_box"
            sm={1}
            md={3}
            style={{ minWidth: '300px' }}
          >
            {pageState.portfolioESGScore.isLoading ? (
              <LoadingMask />
            ) : pageState.portfolioESGScore.dataStatus === PAGE_CON.ERROR ||
              (pageState.portfolioESGScore.dataStatus === PAGE_CON.SUCCESS &&
                // eslint-disable-next-line no-prototype-builtins
                !pageState.portfolioESGScore?.data?.score?.hasOwnProperty(
                  'highest_risk_hazard'
                ) &&
                // eslint-disable-next-line no-prototype-builtins
                !pageState.portfolioESGScore?.data?.score?.hasOwnProperty(
                  'facilities_exposed'
                )) ||
              (pageState.portfolioESGScore.dataStatus === PAGE_CON.SUCCESS &&
                pageState.portfolioESGScore.data.coverage &&
                pageState.portfolioESGScore.data.coverage.length === 0) ? (
              <Error
                id={'distributionError'}
                dataStatus={pageState.portfolioESGScore.dataStatus}
                onErrorClick={reloadComponent}
                errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
              />
            ) : (
              <Card
                components={scoreCardESGData(
                  pageState.portfolioESGScore.data.score.highest_risk_hazard,
                  pageState.portfolioESGScore.data.score.facilities_exposed,
                  pageState.portfolioESGScore.data.coverage.portfolio_coverage,
                  'PortfolioScoreESG-test-id'
                )}
                id="PortfolioScoreESG-test-id"
                selectionType="none"
                key="PortfolioScoreESG-test-id"
              ></Card>
            )}
          </Grid>

          {!props.showBenchMark || (props.showBenchMark && !smallResize) ? (
            <Grid
              item
              id="legend_summary_link_box"
              sm={smallResize ? 8 : 5}
              className={classes.infoBoxStyle}
              style={{ marginLeft: smallResize ? '12px' : '0px' }}
            >
              {portfolio_score || benchmark_score ? (
                <div id="legend_box" style={{ textAlign: 'left' }}>
                  {pageState.portfolioESGScore.isLoading ? (
                    <LoadingMask />
                  ) : pageState.portfolioESGScore.dataStatus ===
                      PAGE_CON.ERROR ||
                    (pageState.portfolioESGScore.dataStatus ===
                      PAGE_CON.SUCCESS &&
                      !isDistributionHasData()) ? (
                    <Error
                      id={'distributionError'}
                      dataStatus={pageState.portfolioESGScore.dataStatus}
                      onErrorClick={reloadComponent}
                      errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
                    />
                  ) : (
                    <Legend
                      colorOptions={legendColor}
                      colorGroup={colorGroup}
                      legendTitle={`${props.pageTitle}: Operations Risk`}
                      boldTitle={true}
                    />
                  )}
                </div>
              ) : null}

              <div
                id="summary_box"
                className={classes.grid}
                style={overViewText}
              >
                {OverViewText.OverViewText[props.overViewText]}
                <br></br>
                <br></br>
                {OverViewText.OverViewText.additionalNote}
              </div>

              <div id="link_box" style={{ textAlign: 'left' }}>
                <PrimaryLink
                  id="link-link-test-id-3"
                  linkText={
                    'Read more about Corporate Physical Climate Risk methodology'
                  }
                  onClick={openAboutLinkOpen}
                  linkClass="linkInline"
                  linkColorChange={true}
                />
              </div>
            </Grid>
          ) : null}
        </Grid>
        {props.showBenchMark ? (
          <Grid
            id="benchmark_4_box"
            container
            item
            direction={'row'}
            spacing={3}
            style={{
              paddingLeft: '16px',
              paddingRight: '16px',
              paddingTop: '0px',
              paddingBottom: '0px',
              marginTop: '12px',
              marginBottom: '20px'
            }}
          >
            <span className={classes.verticalHeader}> Benchmark </span>
            <Grid
              item
              id="cardInfo_box"
              sm={1}
              md={3}
              style={{ minWidth: '300px' }}
            >
              {pageState.portfolioESGScore.isLoading ? (
                <LoadingMask />
              ) : pageState.portfolioESGScore.dataStatus === PAGE_CON.ERROR ||
                (pageState.portfolioESGScore.dataStatus === PAGE_CON.SUCCESS &&
                  // eslint-disable-next-line no-prototype-builtins
                  !pageState.portfolioESGScore.data.score.hasOwnProperty(
                    'benchmark_highest_risk_hazard'
                  ) &&
                  // eslint-disable-next-line no-prototype-builtins
                  !pageState.portfolioESGScore.data.score.hasOwnProperty(
                    'benchmark_facilities_exposed'
                  )) ||
                (pageState.portfolioESGScore.dataStatus === PAGE_CON.SUCCESS &&
                  pageState.portfolioESGScore.data.coverage &&
                  pageState.portfolioESGScore.data.coverage.length === 0) ? (
                <Error
                  id={'distributionError'}
                  dataStatus={pageState.portfolioESGScore.dataStatus}
                  onErrorClick={reloadComponent}
                  errorOccuredOn={PAGE_CON.BENCHMARK_ESG_TITLE}
                />
              ) : (
                <Card
                  components={scoreCardESGData(
                    pageState.portfolioESGScore.data.score
                      .benchmark_highest_risk_hazard,
                    pageState.portfolioESGScore.data.score
                      .benchmark_facilities_exposed,
                    pageState.portfolioESGScore.data.coverage
                      .benchmark_coverage,
                    'BenchmarkScoreESG-test-id'
                  )}
                  id="BenchmarkScoreESG-test-id"
                  selectionType="none"
                  key="BenchmarkScoreESG-test-id"
                ></Card>
              )}
            </Grid>

            {smallResize ? (
              <Grid
                item
                id="legend_summary_link_box"
                sm={9}
                className={classes.infoBoxStyle}
                style={{
                  padding: '0px',
                  paddingLeft: '12px',
                  marginLeft: '12px'
                }}
              >
                {portfolio_score || benchmark_score ? (
                  <div id="legend_box" style={{ textAlign: 'left' }}>
                    {pageState.portfolioESGScore.isLoading ? (
                      <LoadingMask />
                    ) : pageState.portfolioESGScore.dataStatus ===
                        PAGE_CON.ERROR ||
                      (pageState.portfolioESGScore.dataStatus ===
                        PAGE_CON.SUCCESS &&
                        !isDistributionHasData()) ? (
                      <Error
                        id={'distributionError'}
                        dataStatus={pageState.portfolioESGScore.dataStatus}
                        onErrorClick={reloadComponent}
                        errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
                      />
                    ) : (
                      <Legend
                        colorOptions={legendColor}
                        colorGroup={colorGroup}
                        legendTitle={`${props.pageTitle} Score:`}
                        boldTitle={true}
                      />
                    )}
                  </div>
                ) : null}
                <div
                  id="summary_box"
                  className={classes.grid}
                  style={overViewText}
                >
                  {OverViewText.OverViewText[props.overViewText]}
                  <br></br>
                  <br></br>
                  {OverViewText.OverViewText.additionalNote}
                </div>

                <div id="link_box" style={{ textAlign: 'left' }}>
                  <PrimaryLink
                    id="link-link-test-id-3"
                    linkText={`Read more about ${props.pageTitle} assessment methodology`}
                    onClick={openAboutLinkOpen}
                    linkClass="linkInline"
                    linkColorChange={true}
                  />
                </div>
              </Grid>
            ) : null}
          </Grid>
        ) : (
          <Grid
            item
            id="benchmark_box"
            className={classes.benchmarkBoxStyle}
            style={{ marginLeft: '28px', marginRight: '8px', padding: '0px' }}
            md={6}
            lg={12}
          >
            <Box className={classes.benchmarkTextStyle}>
              No benchmark selected. Please select a benchmark
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
export default PhysicalRiskHazardsOverView
