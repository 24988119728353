import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Checkbox as MuiCheckbox } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import SvgIcon from '@material-ui/core/SvgIcon'
import { useStyles } from './styles'

/**
 * @visibleName Checkbox
 */
const Checkbox = (props) => {
  const classes = useStyles(props)

  const [isSelected, setIsSelected] = useState(props.checked)

  const CustomUncheckedIcon = (props) => (
    <SvgIcon {...props}>
      <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
      <path fill="none" className="innerBox" d="M19 5v14H5V5h14" />
    </SvgIcon>
  )

  const onSelectionChange = (event) => {
    setIsSelected(event.target.checked)
    if (props.onChange)
      props.onChange({ id: props.id, checked: event.target.checked })
  }

  return (
    <div className={classes.container}>
      <FormControlLabel
        id={`fctrl-${props.id}`}
        control={
          <MuiCheckbox
            key={props.id}
            disableRipple
            className={classes.backgroundColorOnInnerBoxOfCustomIcon}
            disabled={props.disabled}
            checked={props.checked}
            icon={<CustomUncheckedIcon />}
            size={props.size}
            onChange={props.customOnChange || onSelectionChange}
          />
        }
        label={
          <span
            title={props.label}
            className={
              props.customStyle
                ? classes[props.customStyle]
                : isSelected
                ? classes.selectedLabelStyle
                : classes[props.labelClass]
            }
          >
            {props.label}
          </span>
        }
      />
    </div>
  )
}

Checkbox.propTypes = {
  /** checkbox id */
  id: PropTypes.string,
  /** checkbox text */
  label: PropTypes.string,
  /** checkbox checked */
  checked: PropTypes.bool,
  /** Gets called when the user clicks on the checkbox */
  onChange: PropTypes.func,
  /** checkbox disable property*/
  disabled: PropTypes.bool,
  /** checkbox size property ["medium", "small"]*/
  size: PropTypes.oneOf(['medium', 'small']),
  /** checkbox label width */
  labelWidth: PropTypes.string,
  /** checkbox class for label*/
  labelClass: PropTypes.string,
  /** custom checkbox class for label*/
  customStyle: PropTypes.string,
  /** Custom onChange handler called when the user clicks on the checkbox */
  customOnChange: PropTypes.func
}

Checkbox.defaultProps = {
  disabled: false,
  size: 'small',
  labelClass: 'labelClass'
}

export default Checkbox
