import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'
import { IconCopy } from 'components'
import Error from 'utils/error'
import { PAGE_CON } from 'constant/constants'
import * as S from './styles'

const ListSourceDocument = ({ list, dataStatus, reloadComponent }) => {
  const [dataList, setDataList] = useState(list)
  const [isShown, setIsShown] = useState(false)
  const classes = S.useStyles()

  const copyText = (element, index) => {
    Object.keys(dataList).forEach((key) => {
      dataList[key].copied = false
    })
    let newList = [...dataList]
    newList[index].copied = true
    setDataList(newList)
    window.navigator.clipboard.writeText(element.document_url)
  }
  const hoverText = (element, index, flag) => {
    Object.keys(dataList).forEach((key) => {
      dataList[key].hovered = false
    })
    let newList = [...dataList]
    newList[index].hovered = true
    setDataList(newList)
    setIsShown(flag)
  }

  const reloadSourceDocuments = () => {
    reloadComponent()
  }

  if (dataStatus === PAGE_CON.ERROR) {
    return (
      <Error
        id={'sourceDocsError'}
        dataStatus={PAGE_CON.ERROR}
        onErrorClick={reloadSourceDocuments}
        errorClass={'noMargin'}
      />
    )
  } else if (dataStatus === PAGE_CON.SUCCESS) {
    return (
      <>
        {dataList?.length > 0 ? (
          dataList?.map((e, i) => (
            <div key={`sourceDocs${i}`} className={classes.cardContent}>
              <div
                data-testid="document-name"
                aria-hidden={true}
                style={{
                  ...textTheme.typography.p2,
                  width: '100%',
                  cursor: 'pointer'
                }}
                id={`link-document-${i}`}
                onClick={() => window.open(e?.document_url, '_blank')}
              >
                {e?.document_name}
              </div>
              {isShown && e.hovered && (
                <div
                  style={{
                    ...textTheme.typography.p2,
                    cursor: 'pointer',
                    width: '80px',
                    color: themeColorPalette.palette.sys_trad.selected.main
                  }}
                >
                  Copy URL
                </div>
              )}
              <IconCopy
                onMouseEnter={() => hoverText(e?.document_url, i, true)}
                onMouseLeave={() => hoverText(e?.document_url, i, false)}
                style={{ cursor: 'pointer' }}
                onClick={() => copyText(e, i)}
              />
            </div>
          ))
        ) : (
          <Grid
            container
            alignItems={'center'}
            direction="column"
            id={'sourceDocsEmpty'}
          >
            <Error
              errorClass={'noMargin'}
              width={'102%'}
              margin={0}
              id={'sourceDocErrors'}
              topMargin={'0px'}
              dataStatus={'success'}
              hideBoxShadow={true}
              errorOccuredOn={PAGE_CON.ISSUER_SOURCE_DOCUMENTS}
            />
          </Grid>
        )}
      </>
    )
  }
  return null
}
export default ListSourceDocument
