import textTheme from 'theme/theme'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  drawerFooter: {
    display: 'flex',
    marginBottom: '20px',
    marginLeft: '20px'
  },

  subLegendColor: {
    backgroundColor: textTheme.palette.proxy.proxy.main,
    height: '20px',
    width: '20px',
    marginRight: '5px'
  },
  subLegendText: {
    ...textTheme.typography.p3,
    color: textTheme.palette.sys_trad.text2.main
  },
  panelComponentWrapperCss: {
    display: 'flex',
    flexDirection: 'column'
  },
  tableCss: {
    display: 'flex',
    flexGrow: '1'
  },
  root: {
    flexGrow: 1
  }
}))
