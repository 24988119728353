import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import getColor from 'utils/color'
import { multiColumnSort } from 'utils/Sort'
import Error from 'utils/error'
import LoadingMask from 'utils/loadingMask'
import PropTypes from 'prop-types'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import { Donut, Panel, RegularTable } from 'components'
import { GlobalContext } from 'context/GlobalStateProvider'
import { getMonthFromFilter, getYearFromFilter } from 'utils/Date'
import { COMMON, OPR_RSK_MGM } from 'constant/APIconstants'
import { ResearchLineService } from 'services'

const borderColor = themeColorPalette.palette.sys_trad.border.main

const useStyles = makeStyles({
  tableContainer: {
    overflowX: 'initial',
    paddingBottom: (props) => props.tablePadding,
    paddingLeft: (props) => props.tablePadding,
    paddingRight: (props) => props.tablePadding
  },
  table: {
    width: '100%',
    cursor: 'default',
    borderColor: borderColor
  },
  tableHead: {
    borderTop: `1px solid ${borderColor}`,
    borderBottom: `1px solid ${borderColor}`
  },
  headerCell: {
    ...textTheme.typography.h2,
    borderBottom: 'none',
    padding: '10px',
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    minHeight: 24,
    color: textTheme.palette.sys_trad.text.main,
    '&:first-child': {
      paddingLeft: '20px'
    }
  },
  dividerCell: {
    borderLeft: `1px solid ${borderColor}`,
    height: '96px',
    width: '1px'
  },
  rowCell: {
    ...textTheme.typography.p2,
    color: textTheme.palette.sys_trad.text.main,
    padding: '4px 10px',
    minHeight: 24,
    '&:first-child': {
      paddingLeft: '20px'
    }
  },
  label: {
    whiteSpace: 'nowrap',
    padding: '8px 10px 7px'
  },
  dataCell: {
    textAlign: 'left',
    paddingLeft: '25px',
    paddingRight: '25px'
  },
  lastDataCell: {
    paddingRight: '25px',
    paddingLeft: '21px'
  },
  colorCodedCell: {
    padding: '4px',
    borderRadius: '2px',
    cursor: 'pointer'
  },
  categoryLabel: {
    ...textTheme.typography.p5,
    color: textTheme.palette.sys_trad.text.main,
    lineHeight: '12px',
    textAlign: 'center',
    borderRadius: '2px'
  },
  disabledText: {
    color: themeColorPalette.palette.sys_trad.texthelp.main
  }
})

const colInfo = [
  {
    HeaderLabel: 'Company',
    HideLabel: false,
    Alignment: 'left',
    DataField: [
      {
        Name: 'company_name',
        Alignment: 'left',
        isEllipsis: true,
        width: 120,
        onClick: {
          clickType: 'entityName',
          showUnderline: true
        }
      }
    ]
  },
  {
    HeaderLabel: '% Facilities Exposed',
    HideLabel: false,
    Alignment: 'right',
    DataField: [
      {
        Name: 'fac_exposed',
        Alignment: 'right',
        NumberType: {
          isPercentage: true
        }
      }
    ]
  },
  {
    HeaderLabel: '% Investment',
    HideLabel: false,
    Alignment: 'right',
    DataField: [
      {
        Name: 'investment_pct',
        Alignment: 'right',
        width: 100,
        NumberType: {
          isDecimal: true,
          isPercentage: true
        }
      }
    ]
  }
]

const MetricsTable = (props) => {
  const {
    id,
    headerLabel,
    underlyingDataMetrics,
    maxValues,
    colorGroup,
    hasDivider,
    showDonut,
    dataKey
  } = props
  const [open, setOpen] = React.useState(false)
  const [opRisk, setOpRisk] = React.useState([])
  const [isLoading, setLoading] = React.useState(false)
  const [isError, setError] = React.useState(false)
  const [titleDrawer, setTitleDrawer] = React.useState('')
  const context = React.useContext(GlobalContext)
  const [filterState] = context.globalFilter
  const { portfolio, sectors, asOfDate, regions } =
    filterState.globalPortfolioFilter
  const [dataPhyRisk, setDataPhyrisk] = React.useState({})

  const openDrawer = () => {
    setOpen(true)
  }
  const handleCloseDrawer = () => {
    setOpen(false)
  }

  const openOperationRisk = async ({
    portfolio = '00000000-0000-0000-0000-000000000000',
    regions = 'all',
    sectors = 'all',
    asOfDate = '202207',
    riskLevel = '',
    riskCategory = '',
    type = ''
  }) => {
    const month = getMonthFromFilter(asOfDate)
    const year = getYearFromFilter(asOfDate)
    setTitleDrawer(`${riskLevel} to ${riskCategory}`)
    openDrawer()
    setLoading(true)
    setError(false)
    setDataPhyrisk({
      portfolio,
      regions,
      sectors,
      month,
      year,
      riskLevel,
      riskCategory,
      type
    })
    try {
      const { asOfDate, benchmark } = filterState.globalPortfolioFilter
      const { data } = await ResearchLineService.retrieveResearchLineApi({
        portfolio,
        regions,
        sectors,
        asOfDate,
        benchmark,
        riskLevel,
        riskCategory,
        checkForEntitlements: false,
        isEntitled: false,
        researchLine: OPR_RSK_MGM.PAGE_URL,
        endPoint: COMMON.DATA_METRICS_DETAILS
      })
      setTitleDrawer(`${riskLevel} to ${riskCategory}`)
      setLoading(false)
      if (data && Object.keys(data)?.length > 0) {
        let dataSelected =
          type === 'investment_pct' ? data?.investment_pct : data?.fac_exposed
        setOpRisk(dataSelected)
      }
    } catch (err) {
      console.error(err)
      setLoading(false)
      setError(true)
    }
  }

  const classes = useStyles()
  let pristineCols

  let columns = [
    {
      HeaderLabel: headerLabel,
      DataField: [
        {
          ChartType: 'Pie',
          ColorGroup: 'risk'
        }
      ]
    }
  ]

  const divider = {
    HideLabel: true,
    DataField: [
      {
        IsDivider: true
      }
    ]
  }

  let updatedCols = [...columns]

  ;(() => {
    let categoryArr = []
    pristineCols = [...columns]
    hasDivider && pristineCols.unshift(divider)
    underlyingDataMetrics?.length > 0 &&
      underlyingDataMetrics[0].data[0] &&
      underlyingDataMetrics[0].data[0].forEach((c) => {
        categoryArr.push({
          HideLabel: true,
          Alignment: 'right',
          DataField: [
            {
              ChartType: 'Donut',
              Title: c.name,
              ColorGroup: 'risk',
              Value: c[dataKey][0]
            }
          ]
        })
      })
    updatedCols.push(...categoryArr)
  })()

  const getDonutData = (categoryData, field) => {
    let donutValue = { data: [], colors: [], label: '' }
    let chartType = field.ChartType && field.ChartType.toUpperCase()
    if (Object.keys(categoryData).length > 0) {
      switch (chartType) {
        case 'PIE': {
          const result =
            categoryData &&
            categoryData.reduce((total, c, index) => {
              donutValue.data.push([index + '', c[dataKey][0]])
              donutValue.colors.push(getColor(field.ColorGroup, c.name))
              return total + c[dataKey][0]
            }, 0)
          donutValue.colors.push(borderColor)
          donutValue.data.push([categoryData.length + '', 100 - result])
          break
        }
        case 'DONUT': {
          let donutData = [
            ['0', field.Value],
            ['1', 100 - field.Value]
          ]
          let donutColor = [
            getColor(field.ColorGroup, field.Title),
            borderColor
          ]
          donutValue.data.push(...donutData)
          donutValue.colors.push(...donutColor)
          donutValue.label =
            field.Title.toUpperCase() !== 'NO RISK'
              ? field.Title.replace(' Risk', '')
              : field.Title
          break
        }
        default:
          break
      }
      return donutValue
    }
  }

  const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null
  }

  const sortData = (data, dataKey) => {
    let returnData
    if (dataKey === 'investment_pct') {
      returnData = multiColumnSort(data, [
        ['investment_pct', 'DESC'],
        ['fac_exposed', 'DESC'],
        ['company_name', 'ASC', 'string', true]
      ])
    } else {
      returnData = multiColumnSort(data, [
        ['fac_exposed', 'DESC'],
        ['investment_pct', 'DESC'],
        ['company_name', 'ASC', 'string', true]
      ])
    }
    return returnData
  }

  return (
    <TableContainer className={clsx(classes.tableContainer)}>
      <Table id={id} className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow key="headrow1">
            {pristineCols &&
              pristineCols.length > 0 &&
              pristineCols.map((column, hIndex) => {
                let colLabel = column.HideLabel ? '' : column.HeaderLabel
                let showDivider = column.DataField[0].IsDivider && hasDivider
                return showDivider ? (
                  <TableCell
                    rowSpan="2"
                    key={`dividercell${hIndex}`}
                    className={classes.headerCell}
                  >
                    <div className={classes.dividerCell} />
                  </TableCell>
                ) : (
                  <TableCell
                    key={`headrowcell${hIndex}`}
                    className={classes.headerCell}
                    colSpan={
                      colLabel === 'Facilities Exposed' ||
                      colLabel === 'Investments'
                        ? updatedCols.length + 1
                        : 0
                    }
                  >
                    {colLabel}
                  </TableCell>
                )
              })}
          </TableRow>
          <TableRow key="headrow2">
            {updatedCols &&
              updatedCols.length > 0 &&
              updatedCols.map(
                (column, hIndex) =>
                  column.DataField &&
                  column.DataField.map((field, fIndex) => {
                    let donutData = getDonutData(
                      underlyingDataMetrics?.length > 0
                        ? underlyingDataMetrics[hIndex].data[0]
                        : {},
                      field
                    )
                    return showDonut && field.ChartType === 'Pie' ? (
                      <TableCell
                        className={classes.headerCell}
                        key={`headrowcell${hIndex}${fIndex}`}
                      >
                        <Donut
                          donutChartDiameter="60px"
                          donutData={donutData.data}
                          donutChartHeight={70}
                          donutChartWidth={60}
                          donutBorderWidth={0.5}
                          donutMarginTop="-30px"
                          donutInnerSize="20%"
                          donutSpacingTop={0}
                          donutColors={donutData.colors}
                        />
                      </TableCell>
                    ) : field.ChartType === 'Donut' ? (
                      <TableCell
                        className={classes.headerCell}
                        key={`headrowcell${hIndex}${fIndex}`}
                        colSpan={hIndex === updatedCols.length - 1 ? 2 : 1}
                      >
                        <div style={{ marginTop: '-10px' }}>
                          {showDonut && (
                            <Donut
                              donutDataLabel={`${field.Value}%`}
                              donutChartDiameter="25px"
                              donutDataLabelPosition="left"
                              donutDataLabelXCoord={7}
                              donutData={donutData.data}
                              donutColors={donutData.colors}
                              donutChartHeight={70}
                              donutChartWidth={60}
                              donutBorderWidth={0.2}
                              donutSpacingTop={0}
                              donutInnerSize="20%"
                            />
                          )}
                          <span
                            className={classes.categoryLabel}
                            style={{
                              paddingLeft:
                                donutData.label.length < 5 ? '15px' : '10px'
                            }}
                          >
                            {donutData.label}
                          </span>
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell
                        className={classes.headerCell}
                        colSpan={1}
                        key={`headrowcell${hIndex}${fIndex}`}
                      />
                    )
                  })
              )}
          </TableRow>
        </TableHead>
        <TableBody>
          {underlyingDataMetrics &&
            underlyingDataMetrics.length > 0 &&
            underlyingDataMetrics.map((row, rIndex) => (
              <TableRow key={`bodyrow${rIndex}`}>
                <TableCell
                  className={classes.rowCell}
                  colSpan={hasDivider ? 2 : 1}
                />
                {row &&
                  row.data &&
                  row.data.map((data, index) =>
                    data.map((rowData, dIndex) => {
                      let cellBgColor = hexToRgb(
                        getColor(colorGroup, rowData.name)
                      )
                      let order =
                        rowData[dataKey][0] &&
                        maxValues[dIndex][0].includes(rowData[dataKey][0])
                          ? maxValues[dIndex][0].indexOf(rowData[dataKey][0])
                          : null
                      let opacity =
                        order === 0
                          ? 0.75
                          : order === 1
                          ? 0.5
                          : order === 2
                          ? 0.25
                          : 0
                      let dataCellStyle = rowData.isColorCoded
                        ? classes.lastDataCell
                        : classes.dataCell
                      let dataLabel = rowData[dataKey][0]
                        .toFixed(1)
                        .replace(/.0$/, '')
                      data.isColorCoded = opacity !== 0 ? true : false

                      const dataOpRisk = {
                        portfolio: portfolio,
                        regions: regions === '' ? 'all' : regions,
                        sectors: sectors === '' ? 'all' : sectors,
                        asOfDate: asOfDate,
                        riskLevel: rowData.name,
                        riskCategory: row.title,
                        type: dataKey
                      }

                      return data.isColorCoded ? (
                        <TableCell
                          className={clsx(
                            classes.rowCell,
                            dataCellStyle,
                            classes.label
                          )}
                          key={`bodyrowcell${rIndex}${index}${dIndex}`}
                        >
                          <span
                            className={clsx(
                              classes.colorCodedCell,
                              classes.label
                            )}
                            aria-hidden={true}
                            onClick={() => openOperationRisk(dataOpRisk)}
                            style={{
                              backgroundColor: `rgba(${cellBgColor.r}, ${cellBgColor.g}, ${cellBgColor.b}, ${opacity})`
                            }}
                          >
                            {`${dataLabel}%`}
                          </span>
                        </TableCell>
                      ) : (
                        <TableCell
                          className={clsx(
                            classes.rowCell,
                            classes.label,
                            dataCellStyle,
                            dataLabel === '0' ? classes.disabledText : null
                          )}
                          key={`bodyrowcell${rIndex}${index}${dIndex}`}
                        >
                          <span
                            style={{
                              cursor: dataLabel !== '0' ? 'pointer' : 'default'
                            }}
                            aria-hidden={true}
                            onClick={() =>
                              dataLabel !== '0'
                                ? openOperationRisk(dataOpRisk)
                                : null
                            }
                          >
                            {`${dataLabel}%`}
                          </span>
                        </TableCell>
                      )
                    })
                  )}
              </TableRow>
            ))}
          <Panel
            title={titleDrawer}
            openPanel={open}
            titlePadding="12px"
            onPanelClose={handleCloseDrawer}
            panelContentComponent={[
              isLoading ? (
                <div
                  style={{ width: '405px' }}
                  key={`PhyRiskHazardsdrilldownLoading-${id}`}
                >
                  <LoadingMask />
                </div>
              ) : isError ? (
                <Error
                  errorClass={'noMargin'}
                  id={'driverError'}
                  topMargin={'0px'}
                  dataStatus={'error'}
                  onErrorClick={() => openOperationRisk(dataPhyRisk)}
                />
              ) : (
                <div key={`PhyRiskHazardsdrilldown-${id}`}>
                  <RegularTable
                    id="table-id"
                    heap_id="phyriskop"
                    columns={colInfo}
                    totalData={sortData(opRisk, dataKey)}
                    horScroll="hidden"
                    tablePadding="24px"
                    tableEditable={false}
                    inPanel={true}
                  ></RegularTable>
                </div>
              )
            ]}
          ></Panel>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

MetricsTable.propTypes = {
  /** Table id */
  id: PropTypes.string,
  /** Table Header Label */
  headerLabel: PropTypes.string,
  /** Data for underlying data metrics */
  underlyingDataMetrics: PropTypes.any,
  /** Data key */
  dataKey: PropTypes.string,
  /** Color group from which colors will be picked, either 'risk' or 'category' */
  colorGroup: PropTypes.string,
  /** Whether data should be separated with a divider */
  hasDivider: PropTypes.bool,
  /** Whether to show/hide Donut in the table */
  showDonut: PropTypes.bool,
  /** Max values for each column */
  maxValues: PropTypes.object
}

MetricsTable.defaultProps = {
  colorGroup: 'risk',
  showDonut: true
}

export default MetricsTable
