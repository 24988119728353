import { makeStyles } from '@material-ui/core'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const toolbarStyle = {
  borderBottom: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
  minHeight: '48px',
  display: 'flex',
  justifyContent: 'space-between'
}

export const issuerToolBarStyle = {
  minHeight: '48px',
  display: 'flex',
  justifyContent: 'space-between'
}

export const useStyles = makeStyles(() => ({
  drawerContainer: {
    padding: '0px 8px 0px 8px'
  },
  listItemIcon: {
    padding: '9px 0px 0px 0px',
    marginRight: 13,
    minWidth: '0px'
  },
  drawerIcon: {
    marginTop: 6
  },
  header: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxShadow: 'none',
    '@media (max-width: 1100px)': {
      paddingLeft: 0
    }
  },
  menuButton: {
    border: 'none',
    borderRadius: 0,
    ...textTheme.typography.h2,
    height: '36px',
    textTransform: 'none',
    marginLeft: '20px',
    color: themeColorPalette.palette.white.main,
    '&:hover': {
      borderBottom: '4px solid white',
      color: 'white'
    },
    '&.Mui-selected': {
      borderBottom: '4px solid white',
      color: 'white'
    }
  },
  menuButtonSelected: {
    border: 'none',
    borderRadius: 0,
    ...textTheme.typography.h2,
    height: '36px',
    textTransform: 'none',
    marginLeft: '20px',
    borderBottom: '4px solid white',
    color: 'white'
  },
  subheader: {
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    ...textTheme.typography.p3,
    marginBottom: '4px',
    color: themeColorPalette.palette.sys_trad.text2.main,
    whiteSpace: 'normal',
    float: 'left',
    width: '100%',
    height: 'auto',
    wordWrap: 'break-word',
    lineHeight: 'normal',
    cursor: 'auto',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-selected': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover2.main
      }
    }
  },
  menuItem: {
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    '&.MuiListItem-gutters': {
      paddingLeft: 8
    },
    ...textTheme.typography.p3,
    borderRadius: '2px',
    height: '32px',
    color: themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      borderRadius: '2px',
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main
    },
    '&.Mui-selected': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      borderRadius: '2px',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
        borderRadius: '2px'
      }
    }
  },
  dialogTitle: {
    display: 'flex'
  },
  menuTitle: {
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    marginTop: 10,
    marginBottom: 10,
    fontSize: '12px !important',
    fontWeight: '400',
    ...textTheme.typography.p3,
    fontFamily: 'WhitneyNarrMedium',
    color: themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    cursor: 'auto',
    whiteSpace: 'break-spaces',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 0
  },
  calcDrawerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '15px !important',
    ...textTheme.typography.p3,
    color: themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    borderBottom: '1px solid ' + themeColorPalette.palette.sys_trad.border.main,
    cursor: 'auto'
  },
  toolbar: {
    minHeight: '48px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  globalHeaderDrawer: {
    overflowX: 'hidden',
    width: '218px'
  },
  calcDrawer: {
    overflowX: 'hidden',
    width: '400px',
    minHeight: '500px',
    overflowY: 'auto'
  },
  portfolioDrawer: {
    overflowX: 'hidden'
  },
  drawerToggleIssuer: {
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    height: '32px',
    borderRadius: '2px',
    marginLeft: 8,
    paddingLeft: 8,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    '&.MuiListItem-button': {
      fontSize: '14px !important'
    },
    ...textTheme.typography.p3,
    fontFamily: 'WhitneyNarrSemiBold',
    color: themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'auto'
    }
  },
  entityTempPage: {
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    height: '32px',
    borderRadius: '2px',
    fontSize: '13px !important',
    marginLeft: 8,
    paddingLeft: 8,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    '&.MuiListItem-button': {
      fontSize: '13px !important'
    },
    ...textTheme.typography.p3,
    color: themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      backgroundColor: 'transparent' //themeColorPalette.palette.sys_trad.hover2.main,
    }
  },
  drawerToggleSearch: {
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    height: '32px',
    borderRadius: '2px',
    fontSize: '14px !important',
    marginLeft: 8,
    paddingLeft: 8,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    '&.MuiListItem-button': {
      fontSize: '14px !important'
    },
    ...textTheme.typography.p3,
    fontFamily: 'WhitneyNarrSemiBold',
    color: themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      backgroundColor: 'transparent' //themeColorPalette.palette.sys_trad.hover2.main,
    }
  },
  drawerToggle: {
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    height: '32px',
    borderRadius: '2px',
    marginLeft: 8,
    paddingLeft: 8,
    paddingRight: 12,
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: '14px !important',
    '&.MuiListItem-button': {
      fontSize: '14px !important'
    },
    ...textTheme.typography.p3,
    fontFamily: 'WhitneyNarrSemiBold',
    color: themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main
    }
  },
  borderStyle: {
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 8,
    paddingBottom: 8,
    borderBottom: '1px solid ' + themeColorPalette.palette.sys_trad.border.main
  },
  subDrawerHeader: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '120%',
    color: '#333333'
  },
  subDrawerLeftIcon: {
    cursor: 'pointer',
    marginTop: 4,
    marginLeft: 0
  },
  subDrawerSubHeader: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '120%',
    color: '#333333'
  },
  subDrawerSubHeaderInfo: {
    ...textTheme.typography.p3,
    color: '#333333'
  },
  subDrawerSubHeaderSubTitle: {
    ...textTheme.typography.p2,
    margin: '0'
  },
  link: {
    ...textTheme.typography.p1,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  menuSubTitle: {
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    },
    cursor: 'auto',
    paddingBottom: 0
  },
  labelColor: {
    color: '#333333'
  },
  fw600: {
    fontWeight: '600'
  },
  checkboxContainer: {
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '5px',
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.2)',
    cursor: 'auto',
    '& fieldset label .MuiFormControlLabel-label>span': {
      ...textTheme.typography.p2,
      fontWeight: 375,
      color: textTheme.palette.sys_trad.text.main
    }
  },
  mb0: { marginBottom: 0 },
  mt0: { marginTop: 0 },
  retry: {
    paddingLeft: '0.5em',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  titleContainer: {
    marginLeft: 5
  },
  menuDivision: {
    marginTop: '24px'
  },
  menuItemAlignment: {
    marginLeft: '8px',
    marginRight: '8px'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    flexDirection: 'column'
  },
  footerToolbar: {
    minHeight: '48px',
    justifyContent: 'center',
    borderTop: '1px solid ' + themeColorPalette.palette.sys_trad.border.main
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 48
  },
  avitar: {
    ...textTheme.typography.p3,
    marginRight: '8px',
    border: '1px solid ' + themeColorPalette.palette.sys_trad.main.main,
    backgroundColor: themeColorPalette.palette.white.main,
    color: themeColorPalette.palette.sys_trad.main.main,
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  boxWidth: {
    width: '400px'
  },
  checkboxStyle: {
    paddingLeft: 0,
    color: themeColorPalette.palette.sys_trad.main.main
  }
}))
