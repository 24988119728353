import { AppBar } from '@material-ui/core'
import { Container, Grid, makeStyles } from '@material-ui/core'
import { GlobalContext } from 'context/GlobalStateProvider'
import { COMMON, PARAMS, TEMP_ALMT } from 'constant/APIconstants'
import { ResearchLineService } from 'services'
import {
  PAGE_CON,
  REDUCER_STATE,
  REDUCER_TYPE,
  filterOptions
} from 'constant/constants'
import axios from 'axios'
import Error from 'utils/error'
import LoadingMask from 'utils/loadingMask'
import TemperatureAlignmentOverview from './TemperatureAlignmentOverview'
import React, { useCallback, useContext, useEffect } from 'react'
import Regions from '../../Regions'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import { DropdownField, IconArrowDown, Page } from 'components'
import Impact from '../../Impact'
import getColor from 'utils/color'
import TemperatureAlignmentColumns from './TemperatureAlignmentColumns.json'

const colInfo = JSON.parse(
  JSON.stringify(TemperatureAlignmentColumns.temp_align_Portfolio_Score_Columns)
)
const mimTabColInfo = JSON.parse(
  JSON.stringify(
    TemperatureAlignmentColumns.region_Sector_Minimal_Table_Columns
  )
)
const panelTabColInfo =
  TemperatureAlignmentColumns.temp_align_region_Sector_Panel_Columns
// cardInfoContentInfo is for regions
const cardInfoContentInfo =
  TemperatureAlignmentColumns.card_Info_Section_Columns
const cardInfoContentInfoSector =
  TemperatureAlignmentColumns.card_Info_Section_Columns_Sector
const impactColumns = JSON.parse(
  JSON.stringify(TemperatureAlignmentColumns.impactColumns)
)
const impactDrawerColumns = JSON.parse(
  JSON.stringify(TemperatureAlignmentColumns.impactDrawerColumns)
)

const drawerWidth = PAGE_CON.PAGE_LEFT_MARGIN

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  content: {
    flexGrow: 1
  },
  container: {
    marginLeft: drawerWidth - 20,
    paddingRight: 0,
    paddingLeft: '24px',
    marginTop: '22px'
  },
  drawerHeader: {
    display: 'flex',
    ...textTheme.typography.h1,
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start'
  },
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  },
  tableTitle: {
    paddingBottom: '5px',
    ...textTheme.typography.h1
  },
  stickyHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    zIndex: 1,
    boxShadow: 'none'
  },
  overviewstickyHeader: {
    backgroundColor: themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: 'normal',
    zIndex: 1,
    boxShadow: 'none'
  },
  containerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 10px 16px',
    marginTop: '24px'
  },
  impactContainerWrapper: {
    boxShadow: '0 0 36px rgba(38, 64, 95, 0.12)',
    borderRadius: '4px',
    backgroundColor: '#fff',
    padding: '0px 0px 16px',
    marginTop: '24px',
    paddingLeft: '16px'
  },
  impactDropdown: {
    marginTop: '16px',
    marginBottom: '12px'
  }
}))

const overviewLinkPadding = {
  marginTop: '16px',
  marginLeft: '-4px'
}

const linkPaddingRegionIndustry = {
  marginTop: '8px',
  marginLeft: '-4px'
}

const linkStyle = {
  ...textTheme.typography.p1,
  color: themeColorPalette.palette.sys_trad.link.main,
  lineHeight: '16px',
  verticalAlign: 'top'
}

const regionStyle = {
  paddingBottom: '10px',
  marginTop: '18px'
}

const regionTitleStyle = {
  top: '16px'
}

const updatesStyle = {
  paddingLeft: '2px',
  marginTop: '16px'
}

const TemperatureAlignment = (props) => {
  const classes = useStyles()
  const [selectedCardId, setSelectedCardId] = React.useState(null)
  const [filterState] = useContext(GlobalContext).globalFilter

  const isFilterStateAvailable =
    filterState.filterData.regionsList.length > 0 ||
    filterState.filterData.sectorsList.length > 0

  const [temperatureAlignmentState, temperatureAlignmentDispatch] =
    useContext(GlobalContext).globalTemperatureAlignment
  let apiCallId = temperatureAlignmentState.apiCallId
  const pageConstant = TEMP_ALMT.PAGE_URL

  let showBenchMark =
    filterState.globalPortfolioFilter.benchmark !== '' ? true : false

  let benchmarckCol = colInfo.filter(
    (col) => col.HeaderLabel === PAGE_CON.BENCHMARK
  )
  benchmarckCol[0].HideColumn = !showBenchMark

  let benchmarkPctCol = mimTabColInfo.filter(
    (col) => col.HeaderLabel === PAGE_CON.BENCHMARK_PERC
  )
  benchmarkPctCol[0].HideColumn = !showBenchMark

  function selectedCard(selectCardInfo) {
    setSelectedCardId(selectCardInfo.cardId)
  }

  const onImpactFilterChange = (event) => {
    temperatureAlignmentDispatch({
      type: REDUCER_TYPE.UPDATE_IMPACT_FILTER,
      data: event.target.value
    })
  }

  const isRegionHasData = () => {
    let hasData = false
    if (
      temperatureAlignmentState.portfolioRegions.data.summary.length > 0 &&
      temperatureAlignmentState.portfolioRegions.data.details.length > 0
    )
      hasData = true
    return hasData
  }

  const getRegionsData = async (sectors, regions, isErrorReload = false) => {
    if (
      (temperatureAlignmentState.portfolioRegions.data.summary.length > 0 &&
        temperatureAlignmentState.portfolioRegions.data.details.length > 0 &&
        temperatureAlignmentState.portfolioSectors.data.summary.length > 0 &&
        temperatureAlignmentState.portfolioSectors.data.details.length > 0) ||
      (temperatureAlignmentState.portfolioRegions.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    temperatureAlignmentDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_REGIONS
    })
    let regionsData = {
      summary: [],
      details: [],
      sectorsummary: [],
      sectordetails: []
    }
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter

    try {
      if (isFilterStateAvailable) {
        const [summary, details, sectorsummary, sectordetails] =
          await axios.all([
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: TEMP_ALMT.PAGE_URL,
              token: temperatureAlignmentState.cancelToken.token,
              endPoint: COMMON.REGION_SUMMARY
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: TEMP_ALMT.PAGE_URL,
              token: temperatureAlignmentState.cancelToken.token,
              endPoint: COMMON.REGION_DETAILS
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: TEMP_ALMT.PAGE_URL,
              token: temperatureAlignmentState.cancelToken.token,
              endPoint: COMMON.SECTOR_SUMMARY
            }),
            ResearchLineService.retrieveResearchLineApi({
              portfolio,
              regions,
              sectors,
              asOfDate,
              benchmark,
              checkForEntitlements: false,
              isEntitled: false,
              researchLine: TEMP_ALMT.PAGE_URL,
              token: temperatureAlignmentState.cancelToken.token,
              endPoint: COMMON.SECTOR_DETAILS
            })
          ])

        regionsData.summary = addNewInvestmentCol(summary.data)
        regionsData.details = details.data
        regionsData.sectorsummary = addNewInvestmentCol(sectorsummary.data)
        regionsData.sectordetails = sectordetails.data
      }

      temperatureAlignmentDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_REGIONS,
        data: regionsData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        temperatureAlignmentDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_REGIONS,
          apiCallId
        })
      } else console.log(err)
    }
  }

  const addNewInvestmentCol = (data) => {
    if (data?.length > 0) {
      data.forEach((cat) => {
        if (cat?.portfolio_distribution?.length > 0) {
          cat.portfolio_distribution.forEach((pd) => {
            if (pd.category.toUpperCase() === 'NO INFO')
              pd.new_investment_pct = 0
            else pd.new_investment_pct = pd.investment_pct
          })
        }
      })
    }

    return data
  }

  const getImpactCall = (filter, regions, sectors, month, year) => {
    return isFilterStateAvailable
      ? axios.post(
          COMMON.BASE_URL +
            filterState.globalPortfolioFilter.portfolio +
            TEMP_ALMT.PAGE_URL +
            COMMON.IMPACT,
          {
            [PARAMS.IMPACT_FILTER]: filter,
            [PARAMS.REGION]: regions,
            [PARAMS.SECTOR]: sectors,
            [PARAMS.MONTH]: month,
            [PARAMS.YEAR]: year
          },
          { cancelToken: temperatureAlignmentState.cancelToken.token }
        )
      : {}
  }

  const getImpactData = async (sectors, regions, isErrorReload = false) => {
    if (
      (temperatureAlignmentState.portfolioImpact.data &&
        temperatureAlignmentState.portfolioImpact.data.length > 0) ||
      (temperatureAlignmentState.portfolioImpact.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    temperatureAlignmentDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_IMPACT
    })
    let impactData = {}
    try {
      const month = filterState.globalPortfolioFilter.asOfDate.slice(4)
      const year = filterState.globalPortfolioFilter.asOfDate.slice(0, 4)

      const [top5, top10, bottom5, bottom10] = await axios.all([
        getImpactCall(PAGE_CON.IMPACT_KEY_TOP_5, regions, sectors, month, year),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_TOP_10,
          regions,
          sectors,
          month,
          year
        ),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_BOTTOM_5,
          regions,
          sectors,
          month,
          year
        ),
        getImpactCall(
          PAGE_CON.IMPACT_KEY_BOTTOM_10,
          regions,
          sectors,
          month,
          year
        )
      ])

      impactData[PAGE_CON.IMPACT_KEY_TOP_5] = top5.data[0]
      impactData[PAGE_CON.IMPACT_KEY_TOP_10] = top10.data[0]
      impactData[PAGE_CON.IMPACT_KEY_BOTTOM_5] = bottom5.data[0]
      impactData[PAGE_CON.IMPACT_KEY_BOTTOM_10] = bottom10.data[0]

      temperatureAlignmentDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_IMPACT,
        data: impactData,
        apiCallId
      })
    } catch (err) {
      if (!axios.isCancel(err)) {
        temperatureAlignmentDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_IMPACT,
          apiCallId
        })
      }
    }
  }

  const reloadComponent = (errorOccuredOn) => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    switch (errorOccuredOn) {
      case PAGE_CON.REGIONS_TITLE:
        getRegionsData(sectors, regions, true)
        break
      case PAGE_CON.UPDATESIN_TITLE:
        //eslint-disable-next-line no-undef
        getUpdatesData(sectors, regions, true)
        break
      case PAGE_CON.IMPACT_TITLE:
        getImpactData(sectors, regions, true)
        break
      default:
        break
    }
  }

  const canShowImpact = () => {
    let showImpact = false
    if (
      temperatureAlignmentState.portfolioImpact.dataStatus === PAGE_CON.ERROR ||
      (temperatureAlignmentState.portfolioImpact.dataStatus ===
        PAGE_CON.SUCCESS &&
        filterOptions.some(
          (item) =>
            temperatureAlignmentState.portfolioImpact.data[item.key]
              ?.positive_impact?.investment_and_score?.length > 0 ||
            temperatureAlignmentState.portfolioImpact.data[item.key]
              ?.negative_impact?.investment_and_score?.length > 0
        ))
    ) {
      showImpact = true
    }

    return showImpact
  }

  const getTemperatureAlignmentData = useCallback(async () => {
    let filters = filterState.globalPortfolioFilter
    let sectors = filters.sectors === '' ? PAGE_CON.ALL_FILTER : filters.sectors
    let regions = filters.regions === '' ? PAGE_CON.ALL_FILTER : filters.regions
    if (filters.portfolio !== '' && !filterState.isLoading) {
      getRegionsData(sectors, regions)
      getImpactData(sectors, regions)
    }
  }, [filterState.actionData])

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      getTemperatureAlignmentData()
    }
  }, [filterState.actionData])

  const getPositiveBarData = () => {
    const positiveData = [
      {
        name: 'total',
        data: [],
        opacity: 0.6
      },
      {
        name: 'selection',
        data: [],
        opacity: 1
      }
    ]
    temperatureAlignmentState.portfolioImpact.data[
      temperatureAlignmentState.portfolioImpact.filter
    ]?.positive_impact?.distribution?.map((item) => {
      positiveData[0].data.push(item.total - item.selection)
      positiveData[1].data.push(item.selection)
      return ''
    })

    return positiveData
  }

  const getPositiveBarColor = () => {
    const positiveColor = []

    temperatureAlignmentState.portfolioImpact.data[
      temperatureAlignmentState.portfolioImpact.filter
    ]?.positive_impact?.distribution?.map((item) => {
      positiveColor.push(getColor('ta_category', item.category))
      return ''
    })

    return positiveColor
  }

  const getNegativeBarData = () => {
    const negativeData = [
      {
        name: 'total',
        data: [],
        opacity: 0.6
      },
      {
        name: 'selection',
        data: [],
        opacity: 1
      }
    ]
    temperatureAlignmentState.portfolioImpact.data[
      temperatureAlignmentState.portfolioImpact.filter
    ]?.negative_impact?.distribution?.map((item) => {
      negativeData[0].data.push(item.total - item.selection)
      negativeData[1].data.push(item.selection)
      return ''
    })

    return negativeData
  }

  const getNegativeBarColor = () => {
    const negativeColor = []

    temperatureAlignmentState.portfolioImpact.data[
      temperatureAlignmentState.portfolioImpact.filter
    ]?.negative_impact?.distribution?.map((item) => {
      negativeColor.push(getColor('ta_category', item.category))
      return ''
    })

    return negativeColor
  }

  const canShowRegionComp = () => {
    let showRegionComp = false
    if (
      temperatureAlignmentState.portfolioRegions.isLoading ||
      temperatureAlignmentState.portfolioRegions.dataStatus ===
        PAGE_CON.ERROR ||
      (temperatureAlignmentState.portfolioRegions.dataStatus ===
        PAGE_CON.SUCCESS &&
        temperatureAlignmentState.portfolioRegions.data.summary.length > 0 &&
        temperatureAlignmentState.portfolioRegions.data.details.length > 0)
    )
      showRegionComp = true
    return showRegionComp
  }

  return (
    <Page className={classes.root} title={PAGE_CON.PAGE_TITLE}>
      <main className={classes.content}>
        <Container className={classes.container} maxWidth={false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TemperatureAlignmentOverview
                showBenchMark={showBenchMark}
                colInfo={colInfo}
                pageTitle={props.pageHeaderTitle}
                overViewText="temperatureAlignment"
                coverageHeaderWidth={showBenchMark ? '49%' : '47%'}
                pageState={temperatureAlignmentState}
                pageDispatch={temperatureAlignmentDispatch}
                apiCallId={apiCallId}
                pageConstant={pageConstant}
                baseUrl={TEMP_ALMT.BASE_URL}
              />
            </Grid>
            {temperatureAlignmentState.portfolioImpact.isLoading ||
            canShowImpact() ? (
              <>
                <Grid item container>
                  <a
                    href={'#' + PAGE_CON.UPDATES_AND_CURRENT_LEADERS_LAGGARDS}
                    style={overviewLinkPadding}
                  >
                    <IconArrowDown />
                    <span style={linkStyle}>{PAGE_CON.IMPACT_TITLE}</span>
                  </a>
                </Grid>

                <Grid item container>
                  <AppBar
                    id={props.id}
                    position="sticky"
                    className={classes.stickyHeader}
                    style={{ top: '30px' }}
                  >
                    <div
                      id={PAGE_CON.UPDATES_AND_CURRENT_LEADERS_LAGGARDS}
                      style={{ paddingTop: '90px' }}
                    >
                      <div className={classes.tableTitle} style={updatesStyle}>
                        {' '}
                        {PAGE_CON.IMPACT_TITLE}
                      </div>
                    </div>
                  </AppBar>
                  {temperatureAlignmentState.portfolioImpact.isLoading ||
                  canShowImpact() ? (
                    <Grid
                      item
                      container
                      className={classes.impactContainerWrapper}
                      style={{ paddingLeft: '16px', paddingRight: '16px' }}
                    >
                      {temperatureAlignmentState.portfolioImpact.isLoading ? (
                        <LoadingMask />
                      ) : temperatureAlignmentState.portfolioImpact
                          .dataStatus === PAGE_CON.ERROR ||
                        (temperatureAlignmentState.portfolioImpact
                          .dataStatus === PAGE_CON.SUCCESS &&
                          temperatureAlignmentState.portfolioImpact.data
                            .length === 0) ? (
                        <Error
                          id={'impactError'}
                          dataStatus={
                            isFilterStateAvailable
                              ? temperatureAlignmentState.portfolioImpact
                                  .dataStatus
                              : PAGE_CON.SUCCESS
                          }
                          onErrorClick={reloadComponent}
                          errorOccuredOn={PAGE_CON.IMPACT_TITLE}
                          topMargin="5px"
                        />
                      ) : (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            className={classes.impactDropdown}
                          >
                            <span
                              style={{
                                backgroundColor:
                                  themeColorPalette.palette.sys_trad.selected2
                                    .main,
                                marginBottom: '12px',
                                paddingTop: 4
                              }}
                            >
                              <DropdownField
                                id="impact-filter"
                                dropdownDefaultSelction={
                                  temperatureAlignmentState.portfolioImpact
                                    .filter
                                }
                                saveValueUsingExternalState={true}
                                dropdownOptions={filterOptions}
                                onChange={onImpactFilterChange}
                                labelTextDisabled={true}
                                labelText=""
                                alternateLabelText="Top 5"
                                defaultData={''}
                                impactColor={
                                  themeColorPalette.palette.sys_trad.selected2
                                    .main
                                }
                              />
                            </span>
                          </Grid>
                          <Impact
                            positiveImpactData={
                              temperatureAlignmentState.portfolioImpact.data[
                                temperatureAlignmentState.portfolioImpact.filter
                              ]?.positive_impact?.investment_and_score || []
                            }
                            posBarChartData={getPositiveBarData()}
                            posBarColors={getPositiveBarColor()}
                            posBarTitle={`Total vs. ${
                              filterOptions.find(
                                (item) =>
                                  item.key ===
                                  temperatureAlignmentState.portfolioImpact
                                    .filter
                              ).displayText
                            }`}
                            negativeImpactData={
                              temperatureAlignmentState.portfolioImpact.data[
                                temperatureAlignmentState.portfolioImpact.filter
                              ]?.negative_impact?.investment_and_score || []
                            }
                            negBarChartData={getNegativeBarData()}
                            negBarColors={getNegativeBarColor()}
                            negBarTitle={`Total vs. ${
                              filterOptions.find(
                                (item) =>
                                  item.key ===
                                  temperatureAlignmentState.portfolioImpact
                                    .filter
                              ).displayText
                            }`}
                            impactColumns={impactColumns}
                            impactDrawerColumns={impactDrawerColumns}
                            impactFilter={
                              temperatureAlignmentState.portfolioImpact.filter
                            }
                          />
                        </>
                      )}
                    </Grid>
                  ) : null}
                </Grid>
              </>
            ) : null}
            {canShowRegionComp() ? (
              <>
                <Grid item container>
                  <a
                    href={'#' + PAGE_CON.REGIONS_INDUSTRIES}
                    style={linkPaddingRegionIndustry}
                  >
                    <IconArrowDown />
                    <span style={linkStyle}>
                      {PAGE_CON.REGIONS_TITLE} & {PAGE_CON.SECTORS_TITLE}
                    </span>
                  </a>
                </Grid>

                <Grid item container>
                  <AppBar
                    id={props.id}
                    position="sticky"
                    className={classes.stickyHeader}
                    style={regionTitleStyle}
                  >
                    <div
                      id={PAGE_CON.REGIONS_INDUSTRIES}
                      style={{ paddingTop: '80px' }}
                    >
                      <div
                        className={classes.tableTitle}
                        style={{ marginTop: '36px' }}
                      >
                        {' '}
                        {PAGE_CON.REGIONS_TITLE} & {PAGE_CON.SECTORS_TITLE}{' '}
                      </div>
                    </div>
                  </AppBar>
                  <Grid item xs={12} style={regionStyle}>
                    {temperatureAlignmentState.portfolioRegions.isLoading ? (
                      <LoadingMask />
                    ) : temperatureAlignmentState.portfolioRegions
                        .dataStatus === PAGE_CON.ERROR ||
                      (temperatureAlignmentState.portfolioRegions.dataStatus ===
                        PAGE_CON.SUCCESS &&
                        !isRegionHasData()) ? (
                      <Error
                        id={'regionsError'}
                        dataStatus={
                          isFilterStateAvailable
                            ? temperatureAlignmentState.portfolioRegions
                                .dataStatus
                            : PAGE_CON.SUCCESS
                        }
                        onErrorClick={reloadComponent}
                        errorOccuredOn={PAGE_CON.REGIONS_TITLE}
                      />
                    ) : (
                      <Regions
                        key={'RegionKey'}
                        data={temperatureAlignmentState.portfolioRegions.data}
                        displayRegions={[]}
                        selectedCardId={selectedCardId}
                        onRegionSelect={selectedCard}
                        mimTabColInfo={mimTabColInfo}
                        panelTabColInfo={panelTabColInfo}
                        cardInfoContentInfo={cardInfoContentInfo}
                        cardInfoContentInfoSector={cardInfoContentInfoSector}
                        hideMap={true}
                        currentPageState={'globalTemperatureAlignment'}
                        stateData={true}
                        hideChangeCompanies={true}
                      />
                    )}
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Container>
      </main>
    </Page>
  )
}

export default TemperatureAlignment
