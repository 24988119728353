import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: (props) => (props.maxWidth ? props.maxWidth : 'auto'),
    padding: '4px'
  },
  outerBox: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  textStyle: {
    ...textTheme.typography.p3,
    lineHeight: '16px',
    color: textTheme.palette.sys_trad.text.main,
    paddingLeft: '24px'
  },
  portfolio: {
    ...textTheme.typography.p3,
    lineHeight: '16px',
    fontWeight: 400,
    color: textTheme.palette.sys_trad.text.main,
    flex: 1,
    width: '342px',
    maxWidth: '542px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  entityName: {
    '&.Wrapper': {
      display: 'flex'
    },
    '&.CompanyName': {
      maxWidth: '50%',
      minWidth: '4em'
    },
    '&.ellipsis': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      float: 'left',
      paddingRight: '5px'
    }
  }
})
