import Logo from './mlogo.svg'
import {
  REACT_APP_OKTA_BASE_URL_PROD,
  REACT_APP_OKTA_CLIENT_ID_PROD,
  REACT_APP_OKTA_ISSUER_ID_PROD
} from './OktaProd'
import {
  REACT_APP_OKTA_BASE_URL_NONPROD,
  REACT_APP_OKTA_CLIENT_ID_DEMO_NONPROD,
  REACT_APP_OKTA_CLIENT_ID_NONPROD,
  REACT_APP_OKTA_CLIENT_ID_PERF_NONPROD,
  REACT_APP_OKTA_CLIENT_ID_UAT_NONPROD,
  REACT_APP_OKTA_ISSUER_ID_NONPROD
} from './OktaNonProd'
import { PAGE_CON } from '../../constant/constants'
const okta = { base_url: '', client_id: '', issuer_id: '' }
;(() => {
  if (!PAGE_CON.PRODUCTION_ENABLED) {
    okta.base_url = REACT_APP_OKTA_BASE_URL_NONPROD
    okta.issuer_id = REACT_APP_OKTA_ISSUER_ID_NONPROD
    if (PAGE_CON.UAT_ENV) {
      okta.client_id = REACT_APP_OKTA_CLIENT_ID_UAT_NONPROD
    } else if (PAGE_CON.PERF_ENV) {
      okta.client_id = REACT_APP_OKTA_CLIENT_ID_PERF_NONPROD
    } else if (PAGE_CON.DEMO_ENV) {
      okta.client_id = REACT_APP_OKTA_CLIENT_ID_DEMO_NONPROD
    } else {
      okta.client_id = REACT_APP_OKTA_CLIENT_ID_NONPROD
    }
  } else {
    okta.base_url = REACT_APP_OKTA_BASE_URL_PROD
    okta.client_id = REACT_APP_OKTA_CLIENT_ID_PROD
    okta.issuer_id = REACT_APP_OKTA_ISSUER_ID_PROD
  }
})()

const oktaAuthConfig = {
  issuer: okta.issuer_id,
  clientId: okta.client_id,
  redirectUri: `${window.location.origin}/callback`,
  pkce: true
}

const oktaSignInConfig = {
  baseUrl: okta.issuer_id,
  clientId: okta.client_id,
  redirectUri: `${window.location.origin}/callback`,
  logo: Logo,
  features: {
    idpDiscovery: true
  },
  idpDiscovery: {
    requestContext: `${window.location.origin}/login`
  },
  helpLinks: {
    help: `${window.location.origin}/help`
  },
  authParams: {
    issuer: okta.issuer_id,
    scopes: ['openid', 'profile', 'email'],
    responseType: ['token', 'id_token'],
    pkce: true
  },
  useInteractionCodeFlow: false
}

export { oktaAuthConfig, oktaSignInConfig }
