import React from 'react'
import PropTypes from 'prop-types'
import { GroupButton } from 'components'
import * as S from './styles'

const CompaniesAndInvestmentsHeader = ({
  portfolioName,
  handleCompaniesViewByClick,
  companiesViewByValue,
  contentInfo,
  label
}) => {
  const onChange = (event) => {
    handleCompaniesViewByClick(event)
  }

  return (
    <div style={{ margin: '13px 0 0 7px' }}>
      <S.PerformanceTableTitleSidePanel>
        Companies in {portfolioName}
      </S.PerformanceTableTitleSidePanel>
      <GroupButton
        id="button-button-test-id-1"
        heap_id="view-panel"
        label={label}
        isViewPanel={false}
        buttonClass="buttonPrimary"
        groupButtonContentComponent={contentInfo}
        defaultButton={companiesViewByValue}
        groupButtonWidth="320px"
        onChange={onChange}
      />
    </div>
  )
}

CompaniesAndInvestmentsHeader.propTypes = {
  portfolioName: PropTypes.string
}

export default CompaniesAndInvestmentsHeader
