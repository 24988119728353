import React from 'react'
// eslint-disable-next-line import/namespace
import CarbonFootPrint from './views/portfolioanalysis/transitionrisk/carbonfootprint/CarbonFootPrint'
import MarketRisk from './views/portfolioanalysis/physicalrisk/marketrisk/MarketRisk'
import OperationsRisk from './views/portfolioanalysis/physicalrisk/operationsrisk/OperationsRisk'
import PhysicalRiskManagement from './views/portfolioanalysis/physicalrisk/physicalriskmanagement/PhysicalRiskManagement'
import PhysicalRiskHazards from './views/portfolioanalysis/physicalrisk/physicalriskhazards/PhysicalRiskHazards'
import SupplyChainRisk from './views/portfolioanalysis/physicalrisk/supplychainrisk/SupplyChainRisk'
import PortfolioAnalysis from './views/portfolioanalysis/PortfolioAnalysis'
import ReportTemplate from './views/portfolioanalysis/ReportTemplate'
import GenericPage from './views/genericpage/GenericPage'
import ComingSoon from './views/portfolioanalysis/ComingSoon'
import { Redirect } from 'react-router-dom'
import NotFoundView from './views/error/NotFoundView'
import TcfdStrategy from './views/portfolioanalysis/climategovernancestrategy/tcfdstrategy/TcfdStrategy'
import EnergyTransitionManagement from './views/portfolioanalysis/transitionrisk/energytransitionmanagement/EnergyTransitionManagement'
import BrownShareAssessment from './views/portfolioanalysis/transitionrisk/brownshareassessment/BrownShareAssessment'
import GreenShareAssessment from './views/portfolioanalysis/climategovernancestrategy/greenshareassessment/GreenShareAssessment'
import TemperatureAlignment from './views/portfolioanalysis/transitionrisk/temperaturealignment/TemperatureAlignment'
import P2LandingPage from './views/issuer/P2LandingPage'
import P3LandingPage from './views/issuer/P3LandingPage'
import { Dashboard } from 'views'
import getEntitlements from 'entitlements'
import EsgAssessments from 'views/portfolioanalysis/esg/esgassessments/EsgAssessments'
import ReportingService from 'views/reportingService/ReportingService'
import ReportingStatus from 'views/reportingService/ReportingStatus'
import ReportingHistory from 'views/reportingService/ReportingHistory'
import Terms from 'views/terms/Terms'
import { ROUTE_PATHS } from 'constant/constants'
import InvalidEntitlement from 'views/error/InvalidEntitlement'

const getSubEntitlement = (obj) => {
  for (const key in obj) {
    if (
      obj[key] &&
      key !== 'physicalrisk' &&
      key !== 'transitionrisk' &&
      key !== 'climategovernancestrategy' &&
      key !== 'economicquantification'
    )
      return key
  }
  return '404'
}

//should default to dashboard
const subRoutes = (authState, apiEntitlements, contentTypeEntitlements) => {
  const entitlements = getEntitlements(
    authState,
    apiEntitlements,
    contentTypeEntitlements
  )

  // operations, market, supply chain risk not avalible on portfolio analysis
  const portfolioAnalaysisHide = true

  const defaultRoute = () => {
    if (!entitlements['issuer']) {
      if (entitlements.ReportingService.isReportingOnlyEnabled) {
        return <Redirect to={'/reportingservice'} />
      } else {
        return <Redirect to={'/dashboard'} />
      }
    } else {
      return <Redirect to={'/issuerworkspace'} />
    }
  }

  return [
    {
      path: '/',
      exact: true,
      component: defaultRoute()
    },
    {
      path: '/terms',
      exact: true,
      component: <ReportTemplate report={<Terms />} />
    },
    {
      path: '/dashboard',
      exact: true,
      component:
        entitlements['dashboard'] && !entitlements['issuer'] ? (
          <ReportTemplate report={<Dashboard entitlements={entitlements} />} />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/scenarios',
      exact: true,
      component:
        entitlements['scenarios'] && !entitlements['issuer'] ? (
          <GenericPage title="Scenario Analysis"></GenericPage>
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/ondemandscores',
      exact: true,
      component:
        entitlements['ondemandscores'] && !entitlements['issuer'] ? (
          <GenericPage title="On Demand Scores"></GenericPage>
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/screening',
      exact: true,
      component:
        entitlements['screening'] && !entitlements['issuer'] ? (
          <GenericPage title="Advanced Screening"></GenericPage>
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/issuerworkspace',
      exact: true,
      component: entitlements['issuer'] ? (
        <ReportTemplate //TODO: Requires tweaking once login functionality is figured out
          report={
            entitlements['issuerp3'] ? (
              <P3LandingPage entitlements={entitlements} />
            ) : (
              <P2LandingPage entitlements={entitlements} />
            )
          }
          maxWidth={'1280px'}
        />
      ) : (
        <Redirect to={ROUTE_PATHS.REDIRECT_404} />
      )
    },
    {
      path: '/sector',
      exact: true,
      hideTopBar: true,
      component: entitlements['issuerp3'] ? (
        <ReportTemplate
          report={<P2LandingPage entitlements={entitlements} />}
        />
      ) : (
        <Redirect to={ROUTE_PATHS.REDIRECT_404} />
      )
    },
    {
      path: '/reportingservice',
      exact: true,
      component: entitlements['reportingservice'] ? (
        <ReportTemplate
          loadPortfolio={true}
          report={<ReportingService entitlements={entitlements} />}
        />
      ) : (
        <Redirect to={ROUTE_PATHS.REDIRECT_404} />
      )
    },
    {
      path: ROUTE_PATHS.REGULATORY_REPORTING_STATUS,
      exact: true,
      component: entitlements['reportingservice'] ? (
        <ReportTemplate
          loadPortfolio={true}
          report={
            <ReportingStatus
              entitlements={entitlements}
              reportingType={'Regulatory'}
            />
          }
        />
      ) : (
        <Redirect to={ROUTE_PATHS.REDIRECT_404} />
      )
    },
    {
      path: '/reportingservice/history',
      exact: true,
      component: entitlements['reportingservice'] ? (
        <ReportTemplate
          loadPortfolio={true}
          report={<ReportingHistory entitlements={entitlements} />}
        />
      ) : (
        <Redirect to={ROUTE_PATHS.REDIRECT_404} />
      )
    },
    {
      path: ROUTE_PATHS.PORTFOLIO_ANALYSIS_STATUS,
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['transitionrisk'] &&
        entitlements['PortfolioAnalysis']['transitionriskChildren'][
          'carbonfootprint'
        ] &&
        !entitlements['issuer'] ? (
          <ReportTemplate
            loadPortfolio={true}
            report={
              <ReportingStatus
                entitlements={entitlements}
                reportingType={'Portfolio'}
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/operationsrisk',
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['physicalrisk'] &&
        entitlements['PortfolioAnalysis']['physicalriskChildren'][
          'operationsrisk'
        ] &&
        !entitlements['issuer'] &&
        !portfolioAnalaysisHide ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="Operations Risk"
                pageHeaderSection="Physical Risk"
                page={
                  <OperationsRisk
                    pageHeaderTitle="Operations Risk"
                    pageHeaderSection="Physical Risk"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/marketrisk',
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['physicalrisk'] &&
        entitlements['PortfolioAnalysis']['physicalriskChildren'][
          'marketrisk'
        ] &&
        !entitlements['issuer'] &&
        !portfolioAnalaysisHide ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="Market Risk"
                pageHeaderSection="Physical Risk"
                page={
                  <MarketRisk
                    pageHeaderTitle="Market Risk"
                    pageHeaderSection="Physical Risk"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/supplychainrisk',
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['physicalrisk'] &&
        entitlements['PortfolioAnalysis']['physicalriskChildren'][
          'supplychainrisk'
        ] &&
        !entitlements['issuer'] &&
        !portfolioAnalaysisHide ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="Supply Chain Risk"
                pageHeaderSection="Physical Risk"
                page={
                  <SupplyChainRisk
                    pageHeaderTitle="Supply Chain Risk"
                    pageHeaderSection="Physical Risk"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/physicalriskmanagement',
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['physicalrisk'] &&
        entitlements['PortfolioAnalysis']['physicalriskChildren'][
          'physicalriskmanagement'
        ] &&
        !entitlements['issuer'] ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="Physical Risk Management"
                pageHeaderSection="Physical Risk"
                page={
                  <PhysicalRiskManagement
                    pageHeaderTitle="Physical Risk Management"
                    pageHeaderSection="Physical Risk"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/physicalriskhazards',
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['physicalrisk'] &&
        entitlements['PortfolioAnalysis']['physicalriskChildren'][
          'physicalriskhazards'
        ] &&
        !entitlements['issuer'] ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="Physical Risk Hazards"
                pageHeaderSection="Physical Risk"
                page={
                  <PhysicalRiskHazards
                    pageHeaderTitle="Physical Risk Hazards"
                    pageHeaderSection="Physical Risk"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/temperaturealignment',
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['transitionrisk'] &&
        entitlements['PortfolioAnalysis']['transitionriskChildren'][
          'temperaturealignment'
        ] &&
        !entitlements['issuer'] ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="Temperature Alignment"
                pageHeaderSection="Transition Risk"
                page={
                  <TemperatureAlignment
                    pageHeaderTitle="Temperature Alignment"
                    pageHeaderSection="Transition Risk"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/carbonfootprint',
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['transitionrisk'] &&
        entitlements['PortfolioAnalysis']['transitionriskChildren'][
          'carbonfootprint'
        ] &&
        !entitlements['issuer'] ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="Carbon Footprint"
                pageHeaderSection="Transition Risk"
                page={
                  <CarbonFootPrint
                    pageHeaderTitle="Carbon Footprint"
                    pageHeaderSection="Transition Risk"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/greenshareassessment',
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['transitionrisk'] &&
        entitlements['PortfolioAnalysis']['transitionriskChildren'][
          'greenshareassessment'
        ] &&
        !entitlements['issuer'] ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="Green Share Assessment"
                pageHeaderSection="Transition Risk"
                page={
                  <GreenShareAssessment
                    pageHeaderTitle="Green Share Assessment"
                    pageHeaderSection="Transition Risk"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/brownshareassessment',
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['transitionrisk'] &&
        entitlements['PortfolioAnalysis']['transitionriskChildren'][
          'brownshareassessment'
        ] &&
        !entitlements['issuer'] ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="Brown Share Assessment"
                pageHeaderSection="Transition Risk"
                page={
                  <BrownShareAssessment
                    pageHeaderTitle="Brown Share Assessment"
                    pageHeaderSection="Transition Risk"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/energytransitionmanagement',
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['transitionrisk'] &&
        entitlements['PortfolioAnalysis']['transitionriskChildren'][
          'energytransitionmanagement'
        ] &&
        !entitlements['issuer'] ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="Energy Transition Management"
                pageHeaderSection="Transition Risk"
                page={
                  <EnergyTransitionManagement
                    pageHeaderTitle="Energy Transition Management"
                    pageHeaderSection="Transition Risk"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/tcfdstrategy',
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['climategovernancestrategy'] &&
        entitlements['PortfolioAnalysis']['climategovernancestrategyChildren'][
          'tcfdstrategy'
        ] &&
        !entitlements['issuer'] ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="TCFD Strategy"
                pageHeaderSection="Climate Governance & Strategy"
                page={
                  <TcfdStrategy
                    pageHeaderTitle="TCFD Strategy"
                    pageHeaderSection="Climate Governance & Strategy"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/eutaxonomy',
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['climategovernancestrategy'] &&
        entitlements['PortfolioAnalysis']['climategovernancestrategyChildren'][
          'eutaxonomy'
        ] &&
        !entitlements['issuer'] ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="EU Taxonomy"
                pageHeaderSection="Climate Governance & Strategy"
                page={
                  <ComingSoon
                    pageHeaderTitle="EU Taxonomy"
                    pageHeaderSection="Climate Governance & Strategy"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/climatecontroversies',
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['climategovernancestrategy'] &&
        entitlements['PortfolioAnalysis']['climategovernancestrategyChildren'][
          'climatecontroversies'
        ] &&
        !entitlements['issuer'] ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="Climate Controversies"
                pageHeaderSection="Climate Governance & Strategy"
                page={
                  <ComingSoon
                    pageHeaderTitle="Climate Controversies"
                    pageHeaderSection="Climate Governance & Strategy"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/physicalclimatestressedpds',
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['economicquantification'] &&
        entitlements['PortfolioAnalysis']['economicquantificationChildren'][
          'physicalclimatestressedpds'
        ] &&
        !entitlements['issuer'] ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="Physical Climate Stressed PDs"
                pageHeaderSection="Economic Quantification"
                page={
                  <ComingSoon
                    pageHeaderTitle="Physical Climate Stressed PDs"
                    pageHeaderSection="Economic Quantification"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/transitionclimateriskstressedpds',
      exact: true,
      component:
        entitlements['PortfolioAnalysis']['economicquantification'] &&
        entitlements['PortfolioAnalysis']['economicquantificationChildren'][
          'transitionclimateriskstressedpds'
        ] &&
        !entitlements['issuer'] ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="Transition Climate Risk Stressed PDs"
                pageHeaderSection="Economic Quantification"
                page={
                  <ComingSoon
                    pageHeaderTitle="Transition Climate Risk Stressed PDs"
                    pageHeaderSection="Economic Quantification"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis/esgassessments',
      exact: true,
      component:
        !entitlements['ReportingService']['isReportingOnlyEnabled'] &&
        entitlements['PortfolioAnalysis']['esg'] &&
        entitlements['PortfolioAnalysis']['esgChildren']['esgassessments'] &&
        !entitlements['issuer'] ? (
          <ReportTemplate
            report={
              <PortfolioAnalysis
                section={'PortfolioAnalysis'}
                entitlements={entitlements}
                pageHeaderTitle="ESG Assessments"
                pageHeaderSection="ESG"
                page={
                  <EsgAssessments
                    pageHeaderTitle="ESG Assessments"
                    pageHeaderSection="ESG"
                  />
                }
              />
            }
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: '/portfolioanalysis',
      exact: true,
      component:
        entitlements['PortfolioAnalysis'] &&
        entitlements['PortfolioAnalysis']['physicalrisk'] &&
        !entitlements['issuer'] ? (
          <Redirect to={'/portfolioanalysis/physicalriskhazards'} />
        ) : entitlements['PortfolioAnalysis'] &&
          entitlements['PortfolioAnalysis']['transitionrisk'] &&
          !entitlements['issuer'] ? (
          <Redirect
            to={`/portfolioanalysis/${getSubEntitlement(
              entitlements.PortfolioAnalysis.transitionriskChildren
            )}`}
          />
        ) : entitlements['PortfolioAnalysis'] &&
          entitlements['PortfolioAnalysis']['climategovernancestrategy'] &&
          !entitlements['issuer'] ? (
          <Redirect
            to={`/portfolioanalysis/${getSubEntitlement(
              entitlements.PortfolioAnalysis.climategovernancestrategyChildren
            )}`}
          />
        ) : entitlements['PortfolioAnalysis'] &&
          entitlements['PortfolioAnalysis']['economicquantification'] &&
          !entitlements['issuer'] ? (
          <Redirect
            to={`/portfolioanalysis/${getSubEntitlement(
              entitlements.PortfolioAnalysis.economicquantificationChildren
            )}`}
          />
        ) : entitlements['PortfolioAnalysis'] &&
          entitlements['PortfolioAnalysis']['esg'] &&
          !entitlements['issuer'] ? (
          <Redirect
            to={`/portfolioanalysis/${getSubEntitlement(
              entitlements.PortfolioAnalysis.esgChildren
            )}`}
          />
        ) : (
          <Redirect to={ROUTE_PATHS.REDIRECT_404} />
        )
    },
    {
      path: ROUTE_PATHS.REDIRECT_404,
      component: !entitlements['validEntitlement'] ? (
        <InvalidEntitlement />
      ) : (
        <NotFoundView isEntitled={true} entitlements={entitlements} />
      )
    },
    {
      component: <Redirect to={ROUTE_PATHS.REDIRECT_404} />
    }
  ]
}

const routes = {
  subRoutes
}
export default routes
