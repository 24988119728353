import { Box, Grid, makeStyles } from '@material-ui/core'
import { COMMON, ESG_ASMNT } from 'constant/APIconstants'
import { ResearchLineService } from 'services'
import { GlobalContext } from 'context/GlobalStateProvider'
import { multiColumnSort } from 'utils/Sort'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import axios from 'axios'
import clsx from 'clsx'
import Error from 'utils/error'
import EsgAssessmentsColumns from './EsgAssessmentsColumns.json'
import {
  Legend,
  MinimalTable,
  Panel,
  PrimaryLink,
  RegularTable
} from 'components'
import LoadingMask from 'utils/loadingMask'
import OverViewText from '../../PortfolioAnalysisOverViewText.json'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import textTheme from 'theme/theme'
import getColor from 'utils/color'
import numberData from 'utils/numberFormat'
import themeColorPalette from 'theme/theme'

const coveragePanelColumns = JSON.parse(
  JSON.stringify(EsgAssessmentsColumns.esgAsmnt_Coverage_Panel_Columns)
)

const portfolioDistributionColumns = JSON.parse(
  JSON.stringify(EsgAssessmentsColumns.esgAsmnt_Portfolio_Distribution_Columns)
)

const cardInfoContentInfo = JSON.parse(
  JSON.stringify(EsgAssessmentsColumns.esgAsmnt_Score)
)

const scoreHeader = themeColorPalette.palette.sys_trad.white.main

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    background: '#ffff',
    boxShadow: '0px 0px 36px rgba(38, 65, 94, 0.14)',
    borderRadius: '2px'
  },
  paper: {
    textAlign: 'center',
    color: themeColorPalette.palette.sys_trad.main.main,
    padding: '16px 16px 0 20px',
    marginBottom: '12px'
  },
  scoreGrid: {
    padding: '4px 16px 0 16px'
  },
  verticalHeader: {
    ...textTheme.typography.h2,
    fontSize: '16px !important',
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
    textAlign: 'right',
    paddingBottom: '16px',
    marginRight: '-12px'
  },
  scoreCardInfo: {
    marginLeft: '5px',
    paddingRight: '0px',
    flexBasis: 'auto',
    minWidth: '288px'
  },
  esgScoreTitle: {
    textAlign: 'initial',
    color: themeColorPalette.palette.sys_trad.text.main,
    ...textTheme.typography.p2,
    paddingBottom: '2px'
  },
  esgCoverageBenchmark: {
    textAlign: 'initial',
    marginTop: '-27px'
  },
  esgCoveragePortfolio: {
    textAlign: 'initial',
    marginTop: '-27px',
    marginLeft: '5px'
  },
  benchmarkBoxStyle: {
    backgroundColor: themeColorPalette.palette.sys_trad.bg.main,
    margin: '24px 8px 24px 20px'
  },
  benchmarkTextStyle: {
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.sys_trad.texthelp.main
  },
  title: {
    ...textTheme.typography.p2,
    color: themeColorPalette.palette.sys_trad.text.main,
    textAlign: 'left'
  },
  infoBoxStyle: {
    padding: '0px',
    marginBottom: '16px',
    paddingBottom: '12px',
    textAlign: 'left'
  },
  overViewText: {
    ...textTheme.typography.p3,
    color: themeColorPalette.palette.sys_trad.text.main,
    marginTop: '12px',
    marginBottom: '12px'
  },

  esgStyle: {
    ...textTheme.typography.h1,
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '18px',
    height: '24px',
    color: themeColorPalette.palette.white.main
  },
  scoreCardStyle: {
    margin: '12px',
    marginBottom: '30px',
    height: '24px'
  },
  tempStyle: {
    ...textTheme.typography.h2,
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '16px',
    height: '76px'
  }
}))

const EsgAssessmentsOverview = (props) => {
  const classes = useStyles(props)
  const [portfolioOpen, setportfolioOpen] = React.useState(false)
  const [benchmarkOpen, setbenchmarkOpen] = React.useState(false)
  const [filterState] = useContext(GlobalContext).globalFilter
  const [scoreQualityState] = useContext(GlobalContext).globalFilter
  const [esgAssessmentsState, esgAssessmentsDispatch] =
    useContext(GlobalContext).globalEsgAssessments
  const [state, setState] = useState({ smallResize: false })
  const { smallResize } = state
  let apiCallId = esgAssessmentsState.apiCallId

  const isFilterStateAvailable =
    filterState.filterData.regionsList.length > 0 ||
    filterState.filterData.sectorsList.length > 0

  const getESGScoreData = async (sectors, regions, isErrorReload = false) => {
    if (
      (esgAssessmentsState.portfolioESGScore.data.score.length > 0 &&
        esgAssessmentsState.portfolioESGScore.data.coverage.length > 0 &&
        esgAssessmentsState.portfolioESGScore.data.distribution.length > 0) ||
      (esgAssessmentsState.portfolioESGScore.dataStatus !== '' &&
        isErrorReload === false)
    )
      return

    esgAssessmentsDispatch({
      type: REDUCER_TYPE.LOAD_DATA_START,
      sectionName: REDUCER_STATE.PORTFOLIO_ESGSCORE
    })
    let ESGScore = { score: [], distribution: [], coverage: {} }
    const { portfolio, asOfDate, benchmark } = filterState.globalPortfolioFilter
    try {
      if (isFilterStateAvailable) {
        let [score, distribution, coverageDetails, coverage] = await axios.all([
          ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: ESG_ASMNT.PAGE_URL,
            token: esgAssessmentsState.cancelToken.token,
            endPoint: COMMON.SCORE
          }),
          ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: ESG_ASMNT.PAGE_URL,
            token: esgAssessmentsState.cancelToken.token,
            endPoint: COMMON.DISTRIBUTION
          }),
          ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: ESG_ASMNT.PAGE_URL,
            token: esgAssessmentsState.cancelToken.token,
            endPoint: COMMON.COVERAGE_DETAILS
          }),
          ResearchLineService.retrieveResearchLineApi({
            portfolio,
            regions,
            sectors,
            asOfDate,
            benchmark,
            checkForEntitlements: false,
            isEntitled: false,
            researchLine: ESG_ASMNT.PAGE_URL,
            token: esgAssessmentsState.cancelToken.token,
            endPoint: COMMON.COVERAGE
          })
        ])

        ESGScore.score = score.data.length > 0 ? score.data[0] : []
        ESGScore.coverageDetails =
          coverageDetails.data.length > 0 ? coverageDetails.data[0] : []
        ESGScore.coverageDetails['portfolio_coverage_details'] = sortByCategory(
          ESGScore.coverageDetails['portfolio_coverage_details']
        )

        ESGScore.distribution =
          distribution.data.length > 0 ? distribution.data[0] : []
        ESGScore.coverage = coverage.data.length > 0 ? coverage.data[0] : []
      }
      esgAssessmentsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_ESGSCORE,
        data: ESGScore,
        apiCallId
      })

      esgAssessmentsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_SCORE,
        data: ESGScore.score,
        apiCallId
      })
      esgAssessmentsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_DISTRIBUTION,
        data: ESGScore.distribution,
        apiCallId
      })
      esgAssessmentsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_COVERAGE_DETAILS,
        data: ESGScore.coverageDetails,
        apiCallId
      })
      esgAssessmentsDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.PORTFOLIO_COVERAGE,
        data: ESGScore.coverage,
        apiCallId
      })
    } catch (err) {
      console.log(err)
      if (!axios.isCancel(err)) {
        esgAssessmentsDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.PORTFOLIO_ESGSCORE,
          apiCallId
        })
      } else console.log(err)
    }
  }

  const reloadComponent = (errorOccuredOn) => {
    let filters = filterState.globalPortfolioFilter
    let sectors =
      filters?.sectors === '' ? PAGE_CON.ALL_FILTER : filters?.sectors
    let regions =
      filters?.regions === '' ? PAGE_CON.ALL_FILTER : filters?.regions

    switch (errorOccuredOn) {
      case PAGE_CON.PORTFOLIO_ESGSCORE_TITLE:
      case PAGE_CON.BENCHMARK_ESG_TITLE:
        getESGScoreData(sectors, regions, true)
        break
      default:
        break
    }
  }

  const getESGAssessmentsData = useCallback(async () => {
    let filters = filterState.globalPortfolioFilter
    let sectors =
      filters?.sectors === '' ? PAGE_CON.ALL_FILTER : filters?.sectors
    let regions =
      filters?.regions === '' ? PAGE_CON.ALL_FILTER : filters?.regions
    if (filters?.portfolio !== '') {
      getESGScoreData(sectors, regions)
    }
  }, [filterState.actionData])

  useEffect(() => {
    if (filterState.actionData !== PAGE_CON.INITIAL_DATA) {
      getESGAssessmentsData()
    }
    const setResponsiveness = () => {
      return window.innerWidth < 1150
        ? setState((prevState) => ({ ...prevState, smallResize: true }))
        : setState((prevState) => ({ ...prevState, smallResize: false }))
    }
    setResponsiveness()
    window.addEventListener('resize', setResponsiveness, true)
    return () => window.removeEventListener('resize', setResponsiveness, true)
  }, [filterState.actionData])

  const openLink = (key) => {
    let pdf = ''
    switch (key) {
      case 'methodology1':
        pdf =
          process.env.PUBLIC_URL + '/assets/Methodology 1.0 ESG Assessment.pdf'
        break
      case 'methodology2':
        pdf =
          process.env.PUBLIC_URL + '/assets/Methodology 2.0 ESG Assessment.pdf'
        break
      case 'about':
        pdf =
          process.env.PUBLIC_URL +
          '/assets/Methodology Controversy Risk Assessment.pdf'
        break
      default:
        break
    }

    if (pdf !== '') {
      window.open(pdf, '_blank')
    }
  }
  const getText = (selectedkey) => {
    let filterResult = filterState?.filterData?.portfolioList?.filter(
      (x) => x.key === selectedkey
    )
    if (filterResult && filterResult.length > 0)
      return [
        filterResult[0][PAGE_CON.DROPDOWN_DISPLAY_COLUMN],
        filterResult[0][PAGE_CON.UPDATED_DATE]
      ]
    return ''
  }
  const openPortfolioDrawer = () => {
    setportfolioOpen(true)
  }
  const handlePortfolioCloseDrawer = () => {
    setportfolioOpen(false)
  }
  const openBenchmarkDrawer = () => {
    setbenchmarkOpen(true)
  }
  const handleBenchmarkCloseDrawer = () => {
    setbenchmarkOpen(false)
  }

  //combine the segragated category

  function sortCoveragePanelData(data) {
    let sortByCols = [
      ['ranking', 'ASC'],
      ['investment_pct', 'DESC'],
      ['entity', 'ASC', 'string', true]
    ]

    let returnData = multiColumnSort(data, sortByCols)

    return returnData
  }

  const sortByCategory = (data) => {
    //segregate based on esg category
    let advanced = []
    let robust = []
    let limited = []
    let weak = []
    if (data && data.length > 0) {
      advanced = data.filter(
        (coverageDetails) => coverageDetails.qualifier === 'Advanced'
      )
      robust = data.filter(
        (coverageDetails) => coverageDetails.qualifier === 'Robust'
      )
      limited = data.filter(
        (coverageDetails) => coverageDetails.qualifier === 'Limited'
      )
      weak = data.filter(
        (coverageDetails) => coverageDetails.qualifier === 'Weak'
      )
      return [
        ...sortCoveragePanelData(advanced),
        ...sortCoveragePanelData(robust),
        ...sortCoveragePanelData(limited),
        ...sortCoveragePanelData(weak)
      ]
    } else {
      return []
    }
  }
  const isPredictorScoreExist = (data) => {
    if (data && data.length > 0) {
      const predictorScore = data.filter(
        (coverageDetails) =>
          coverageDetails['score_quality'] === PAGE_CON.PREDICTED
      )
      return predictorScore.length > 0
    } else {
      return 0
    }
  }

  const esgLegendData = {
    title: 'ESG Assessment',
    type: 'portfolio_score',
    selector: 'ESG_CATEGORY',
    group: ['Weak', 'Limited', 'Robust', 'Advanced']
  }
  const benchmarkLegendData = {
    title: 'ESG Assessment',
    type: 'benchmark_score',
    selector: 'ESG_CATEGORY',
    group: ['Weak', 'Limited', 'Robust', 'Advanced']
  }

  const colorLabel = () => {
    let legendData = []

    if (esgAssessmentsState.portfolioESGScore.dataStatus === PAGE_CON.SUCCESS) {
      let distributionData = []
      if (
        (esgAssessmentsState?.portfolioESGScore?.data?.distribution
          ?.portfolio_distribution?.length || 0) > 0
      )
        distributionData =
          esgAssessmentsState.portfolioESGScore.data.distribution
            .portfolio_distribution
      else if (
        (esgAssessmentsState?.portfolioESGScore?.data?.distribution
          ?.benchmark_distribution?.length || 0) > 0
      )
        distributionData =
          esgAssessmentsState.portfolioESGScore.data.distribution
            .benchmark_distribution

      distributionData.length > 0 &&
        distributionData.map((Cdata) => {
          return legendData.push(Cdata.category)
        })
    }

    return legendData
  }

  const isDistributionHasData = () => {
    let hasData = false
    if (
      (esgAssessmentsState?.portfolioESGScore?.data?.distribution
        ?.portfolio_distribution?.length || 0) > 0
    )
      hasData = true
    else if (
      (esgAssessmentsState?.portfolioESGScore?.data?.distribution
        ?.benchmark_distribution?.length || 0) > 0
    )
      hasData = true

    return hasData
  }

  let colorGroup = cardInfoContentInfo[0].bgColor.colorGroup
  let legendColor = colorLabel()
  const proxyColorGroup = 'PROXY'
  const legendLabel = ['Predicted Score']
  const portfolio_score =
    esgAssessmentsState?.portfolioESGScore?.data?.score?.portfolio_score
  const benchmark_score =
    esgAssessmentsState?.portfolioESGScore?.data?.score?.benchmark_score

  return (
    <div className={classes.root}>
      <Grid
        id={props.id}
        container
        direction={'column'}
        spacing={2}
        className={classes.paper}
      >
        <Grid
          id="portfolio_box"
          container
          item
          direction={'row'}
          spacing={3}
          className={classes.scoreGrid}
        >
          <span className={classes.verticalHeader}>Portfolio</span>
          <Grid
            item
            id="cardInfo_box"
            xs={3}
            md={3}
            className={classes.scoreCardInfo}
          >
            {esgAssessmentsState.portfolioESGScore.isLoading ? (
              <LoadingMask />
            ) : esgAssessmentsState.portfolioESGScore.dataStatus ===
                PAGE_CON.ERROR ||
              (esgAssessmentsState.portfolioESGScore.dataStatus ===
                PAGE_CON.SUCCESS &&
                (esgAssessmentsState?.portfolioESGScore?.data?.score
                  ?.portfolio_score?.length || 0) === 0) ? (
              <Error
                id={'scoreError'}
                dataStatus={
                  isFilterStateAvailable
                    ? esgAssessmentsState.portfolioESGScore.dataStatus
                    : PAGE_CON.SUCCESS
                }
                onErrorClick={reloadComponent}
                errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
                width={'266px'}
              />
            ) : (
              <>
                <Grid
                  container
                  direction="column"
                  spacing={2}
                  className={classes.tempStyle}
                >
                  <Grid
                    item
                    xs={12}
                    className={classes.scoreCardStyle}
                    style={{
                      backgroundColor: getColor(
                        esgLegendData.selector,
                        esgAssessmentsState?.portfolioESGScore?.data?.score[
                          esgLegendData.type
                        ][0]?.category
                      )
                    }}
                  >
                    <span className={classes.esgStyle}>
                      {
                        esgAssessmentsState?.portfolioESGScore?.data?.score[
                          esgLegendData.type
                        ][0]?.category
                      }
                    </span>
                  </Grid>
                </Grid>

                <div className={classes.esgCoveragePortfolio}>
                  <PrimaryLink
                    linkText={`Coverage: ${
                      esgAssessmentsState?.portfolioESGScore?.data?.coverage?.portfolio_coverage?.companies?.split(
                        '/'
                      )[0]
                    } companies; ${numberData(
                      esgAssessmentsState?.portfolioESGScore?.data?.coverage
                        ?.portfolio_coverage?.investment,
                      { isPercentage: true, isLessThanOneFormat: true }
                    )} investments`}
                    onClick={openPortfolioDrawer}
                    linkClass="linkInlineEsgCoverage"
                  />
                </div>
              </>
            )}
            <Panel
              title={`ESG in ${
                getText(filterState?.globalPortfolioFilter?.portfolio)[0]
              }`}
              openPanel={portfolioOpen}
              titlePadding="12px"
              onPanelClose={handlePortfolioCloseDrawer}
              panelContentComponent={[
                <div key="coveragedrilldown-id">
                  <RegularTable
                    id="portfolio-table-id"
                    heap_id="coverage"
                    columns={coveragePanelColumns}
                    totalData={
                      esgAssessmentsState?.portfolioESGScore?.data
                        ?.coverageDetails?.portfolio_coverage_details || []
                    }
                    horScroll="hidden"
                    tablePadding="24px"
                    tableEditable={false}
                    inPanel={true}
                    isRowHighlighted={true}
                    showScoreQuality={scoreQualityState.enableScoreQuality}
                  ></RegularTable>
                  {isPredictorScoreExist(
                    esgAssessmentsState?.portfolioESGScore?.data
                      ?.coverageDetails?.portfolio_coverage_details
                  ) ? (
                    <Legend
                      colorOptions={legendLabel}
                      colorGroup={proxyColorGroup}
                      disableBackgroundColor={true}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              ]}
            ></Panel>
          </Grid>
          <Grid item id="distribution_box" style={{ minWidth: '266px' }}>
            <div className={classes.title} style={{ paddingLeft: '4px' }}>
              ESG Score Distribution
            </div>
            {esgAssessmentsState.portfolioESGScore.isLoading ? (
              <LoadingMask />
            ) : esgAssessmentsState.portfolioESGScore.dataStatus ===
                PAGE_CON.ERROR ||
              (esgAssessmentsState.portfolioESGScore.dataStatus ===
                PAGE_CON.SUCCESS &&
                (esgAssessmentsState.portfolioESGScore.data.distribution
                  ?.portfolio_distribution?.length || 0) === 0) ? (
              <Error
                id={'distributionError'}
                dataStatus={
                  isFilterStateAvailable
                    ? esgAssessmentsState.portfolioESGScore.dataStatus
                    : PAGE_CON.SUCCESS
                }
                onErrorClick={reloadComponent}
                errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
                width={'266px'}
              />
            ) : (
              <MinimalTable
                id="esg-assessments-portfolio-distribution-minimaltable-test-id"
                columns={portfolioDistributionColumns}
                headerBgColor={scoreHeader}
                data={
                  esgAssessmentsState?.portfolioESGScore?.data?.distribution
                    .portfolio_distribution
                }
                hideBenchmark={true}
                cellBarColor={themeColorPalette.palette.sys_trad.main.main}
                ESGAssessment={true}
                tableMarginTop={'-5px'}
              />
            )}
          </Grid>
          {!props.showBenchMark || (props.showBenchMark && !smallResize) ? (
            <Grid
              item
              id="legend_summary_link_box"
              sm={smallResize ? 8 : 5}
              className={classes.infoBoxStyle}
              style={{ marginLeft: smallResize ? '12px' : '0px' }}
            >
              {portfolio_score?.length > 0 || benchmark_score?.length > 0 ? (
                <div id="legend_box" style={{ textAlign: 'left' }}>
                  {esgAssessmentsState.portfolioESGScore.isLoading ? (
                    <LoadingMask />
                  ) : esgAssessmentsState.portfolioESGScore.dataStatus ===
                      PAGE_CON.ERROR ||
                    (esgAssessmentsState.portfolioESGScore.dataStatus ===
                      PAGE_CON.SUCCESS &&
                      !isDistributionHasData()) ? (
                    <Error
                      id={'distributionError'}
                      dataStatus={
                        isFilterStateAvailable
                          ? esgAssessmentsState.portfolioESGScore.dataStatus
                          : PAGE_CON.SUCCESS
                      }
                      onErrorClick={reloadComponent}
                      errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
                    />
                  ) : (
                    <Legend
                      colorOptions={legendColor}
                      colorGroup={colorGroup}
                      legendTitle={` ${PAGE_CON.ESG_ASM_TITLE} Score:`}
                      boldTitle={true}
                    />
                  )}
                </div>
              ) : null}
              <div
                id="summary_box"
                className={clsx(classes.grid, classes.overViewText)}
              >
                {OverViewText.OverViewText['esgAssessments']}
                <br></br>
                <br></br>
                {OverViewText.OverViewText['esgAssessments2']}
                <br></br>
                <br></br>
                {OverViewText.OverViewText['esgAssessments3']}
              </div>

              <div id="link_box" style={{ textAlign: 'left' }}>
                <PrimaryLink
                  id="link-link-test-id-3"
                  linkText={'Read more about Methodology ESG Assessment 1.0'}
                  onClick={() => openLink('methodology1')}
                  linkClass="linkInline"
                  linkColorChange={true}
                />{' '}
                <br />
                <PrimaryLink
                  id="link-link-test-id-3"
                  linkText={'Read more about Methodology ESG Assessment 2.0'}
                  onClick={() => openLink('methodology2')}
                  linkClass="linkInline"
                  linkColorChange={true}
                />{' '}
                <br />
                <PrimaryLink
                  id="link-link-test-id-3"
                  linkText={
                    'Read more about Methodology Controversy Risk Assessment'
                  }
                  onClick={() => openLink('about')}
                  linkClass="linkInline"
                  linkColorChange={true}
                />
              </div>
            </Grid>
          ) : null}
        </Grid>
        {props.showBenchMark ? (
          <Grid
            id="benchmark_box"
            container
            item
            direction={'row'}
            spacing={3}
            className={classes.scoreGrid}
            style={{ marginTop: '12px', marginBottom: '20px' }}
          >
            <span className={classes.verticalHeader}>Benchmark</span>
            <Grid
              item
              id="cardInfo_box"
              md={4}
              className={classes.scoreCardInfo}
            >
              {esgAssessmentsState.portfolioESGScore.isLoading ? (
                <LoadingMask />
              ) : esgAssessmentsState.portfolioESGScore.dataStatus ===
                  PAGE_CON.ERROR ||
                (esgAssessmentsState.portfolioESGScore.dataStatus ===
                  PAGE_CON.SUCCESS &&
                  (esgAssessmentsState?.portfolioESGScore?.data?.score
                    ?.benchmark_score?.length || 0) === 0) ? (
                <Error
                  id={'scoreError'}
                  dataStatus={
                    isFilterStateAvailable
                      ? esgAssessmentsState.portfolioESGScore.dataStatus
                      : PAGE_CON.SUCCESS
                  }
                  onErrorClick={reloadComponent}
                  errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
                  width={'266px'}
                />
              ) : (
                <>
                  <Grid
                    container
                    direction="column"
                    spacing={2}
                    className={classes.tempStyle}
                  >
                    <Grid
                      item
                      xs={12}
                      className={classes.scoreCardStyle}
                      style={{
                        backgroundColor: getColor(
                          benchmarkLegendData.selector,
                          esgAssessmentsState?.portfolioESGScore?.data?.score[
                            benchmarkLegendData.type
                          ][0]?.category
                        )
                      }}
                    >
                      <span className={classes.esgStyle}>
                        {
                          esgAssessmentsState?.portfolioESGScore?.data?.score[
                            benchmarkLegendData.type
                          ][0]?.category
                        }
                      </span>
                    </Grid>
                  </Grid>
                  <div className={classes.esgCoverageBenchmark}>
                    <PrimaryLink
                      linkText={`Coverage: ${
                        esgAssessmentsState?.portfolioESGScore?.data?.coverage?.benchmark_coverage?.companies?.split(
                          '/'
                        )[0]
                      } companies; ${Math.round(
                        esgAssessmentsState?.portfolioESGScore?.data?.coverage
                          ?.benchmark_coverage.investment
                      )}% investments`}
                      onClick={openBenchmarkDrawer}
                      linkClass="linkInlineEsgCoverage"
                    />
                  </div>
                </>
              )}
              <Panel
                title={`ESG in ${
                  getText(filterState.globalPortfolioFilter.benchmark)[0]
                }`}
                openPanel={benchmarkOpen}
                titlePadding="12px"
                onPanelClose={handleBenchmarkCloseDrawer}
                panelContentComponent={[
                  <div key="coveragedrilldown-id">
                    <RegularTable
                      id="benchmark-table-id"
                      heap_id="coverage"
                      columns={coveragePanelColumns}
                      totalData={
                        sortByCategory(
                          esgAssessmentsState?.portfolioESGScore?.data
                            ?.coverageDetails?.benchmark_coverage
                        ) || []
                      }
                      horScroll="hidden"
                      tablePadding="24px"
                      tableEditable={false}
                      inPanel={true}
                      isRowHighlighted={true}
                      showScoreQuality={scoreQualityState.enableScoreQuality}
                    ></RegularTable>
                    {isPredictorScoreExist(
                      esgAssessmentsState?.portfolioESGScore?.data
                        ?.coverageDetails?.benchmark_coverage
                    ) ? (
                      <Legend
                        colorOptions={legendLabel}
                        colorGroup={proxyColorGroup}
                        disableBackgroundColor={true}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ]}
              ></Panel>
            </Grid>
            <Grid
              item
              id="distribution_box"
              style={{ minWidth: '266px', marginBottom: '16px' }}
            >
              <div className={classes.title} style={{ paddingLeft: '4px' }}>
                {PAGE_CON.BENCHMARK_DISTRIBUTION_TITLE}
              </div>
              {esgAssessmentsState.portfolioESGScore.isLoading ? (
                <LoadingMask />
              ) : esgAssessmentsState.portfolioESGScore.dataStatus ===
                  PAGE_CON.ERROR ||
                (esgAssessmentsState.portfolioESGScore.dataStatus ===
                  PAGE_CON.SUCCESS &&
                  (esgAssessmentsState.portfolioESGScore.data.distribution
                    ?.benchmark_distribution?.length || 0) === 0) ? (
                <Error
                  id={'distributionError'}
                  dataStatus={
                    isFilterStateAvailable
                      ? esgAssessmentsState.portfolioESGScore.dataStatus
                      : PAGE_CON.SUCCESS
                  }
                  onErrorClick={reloadComponent}
                  errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
                  width={'266px'}
                />
              ) : (
                <MinimalTable
                  id="esg-assessments-benchmark-distribution-minimaltable-test-id"
                  columns={portfolioDistributionColumns}
                  headerBgColor={scoreHeader}
                  data={
                    esgAssessmentsState?.portfolioESGScore?.data?.distribution
                      .benchmark_distribution
                  }
                  hideBenchmark={true}
                  cellBarColor={themeColorPalette.palette.sys_trad.main.main}
                  ESGAssessment={true}
                  tableMarginTop={'-5px'}
                />
              )}
            </Grid>
            {smallResize ? (
              <Grid
                item
                id="legend_summary_link_box"
                sm={9}
                className={classes.infoBoxStyle}
                style={{
                  padding: '0px',
                  paddingLeft: '12px',
                  marginLeft: '12px'
                }}
              >
                {portfolio_score?.length > 0 || benchmark_score?.length > 0 ? (
                  <div id="legend_box" style={{ textAlign: 'left' }}>
                    {esgAssessmentsState.portfolioESGScore.isLoading ? (
                      <LoadingMask />
                    ) : esgAssessmentsState.portfolioESGScore.dataStatus ===
                        PAGE_CON.ERROR ||
                      (esgAssessmentsState.portfolioESGScore.dataStatus ===
                        PAGE_CON.SUCCESS &&
                        !isDistributionHasData()) ? (
                      <Error
                        id={'distributionError'}
                        dataStatus={
                          isFilterStateAvailable
                            ? esgAssessmentsState.portfolioESGScore.dataStatus
                            : PAGE_CON.SUCCESS
                        }
                        onErrorClick={reloadComponent}
                        errorOccuredOn={PAGE_CON.PORTFOLIO_ESGSCORE_TITLE}
                      />
                    ) : (
                      <Legend
                        colorOptions={legendColor}
                        colorGroup={colorGroup}
                        legendTitle={` ${PAGE_CON.ESG_ASM_TITLE} Score:`}
                        boldTitle={true}
                      />
                    )}
                  </div>
                ) : null}
                <div
                  id="summary_box"
                  className={clsx(classes.grid, classes.overViewText)}
                >
                  {OverViewText.OverViewText['esgAssessments']}
                  <br></br>
                  <br></br>
                  {OverViewText.OverViewText['esgAssessments2']}
                  <br></br>
                  <br></br>
                  {OverViewText.OverViewText['esgAssessments3']}
                </div>

                <div id="link_box" style={{ textAlign: 'left' }}>
                  <PrimaryLink
                    id="link-link-test-id-3"
                    linkText={'Read more about Methodology ESG Assessment 1.0'}
                    onClick={() => openLink('methodology1')}
                    linkClass="linkInline"
                    linkColorChange={true}
                  />{' '}
                  <br />
                  <PrimaryLink
                    id="link-link-test-id-3"
                    linkText={'Read more about Methodology ESG Assessment 2.0'}
                    onClick={() => openLink('methodology2')}
                    linkClass="linkInline"
                    linkColorChange={true}
                  />{' '}
                  <br />
                  <PrimaryLink
                    id="link-link-test-id-3"
                    linkText={
                      'Read more about Methodology Controversy Risk Assessment'
                    }
                    onClick={() => openLink('about')}
                    linkClass="linkInline"
                    linkColorChange={true}
                  />
                </div>
              </Grid>
            ) : null}
          </Grid>
        ) : (
          <Grid
            item
            id="no_benchmark_box"
            className={classes.benchmarkBoxStyle}
            lg={12}
          >
            <Box className={classes.benchmarkTextStyle}>
              No benchmark selected. Please select a benchmark
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default EsgAssessmentsOverview
