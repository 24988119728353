import React, { useContext, useEffect, useState } from 'react'
import { EntityProfileService } from 'services'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { MenuItem, Toolbar } from '@material-ui/core'
import useMediaQuery from '@mui/material/useMediaQuery'
import { GlobalContext } from 'context/GlobalStateProvider'
import themeColorPalette from 'theme/theme'
import ReportTemplate from 'views/portfolioanalysis/ReportTemplate'
import ClimateProfile from 'views/entityProfile/climate/ClimateProfile'
import PropTypes from 'prop-types'
import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'
import {
  IconClose,
  IconDownload,
  IconInformationCircle,
  PrimaryButton,
  PrimaryModal,
  PrimaryPopover
} from 'components'
import fileCollection from 'resources/fileCollection'
import { theme, useStyles } from 'views/entityProfile/entity/style'

import {
  checkForEntitlements,
  checkForPDFEntitlements,
  exportSourcesDocumentsModalContent,
  getFullFileName,
  handleKeyDown,
  init,
  loadDocs,
  methodologiesModalContent
} from './helper'

const entityheaderStyle = {
  minHeight: '60px',
  display: 'flex',
  justifyContent: 'center',
  position: 'sticky',
  top: '0',
  backgroundColor: '#fff',
  zIndex: '1',
  borderBottom: '1px solid rgb(38 65 94 / 10%)'
}

const EntityModal = (props) => {
  const [entityHeaderState] = useContext(GlobalContext).globalEntityHeader
  const [climateState, climateDispatch] =
    useContext(GlobalContext).globalClimate
  const [showMethodologyModal, setShowMethodologyModal] = useState(false)
  const [showExportSourcesModal, setShowExportSourcesModal] = useState(false)
  const [filterState, filterDispatch] =
    React.useContext(GlobalContext).globalFilter
  const [isPDFEnabled, setIsPDFEnabled] = React.useState(true)
  const [popoverErrorOpen, setPopoverErrorOpen] = React.useState(false)
  const [popoverExportOpen, setPopoverExportOpen] = React.useState(false)
  const [isPageLoading, setIsPageLoading] = React.useState(true)
  const [pdfDownloadClicked, setPdfDownloadClicked] = React.useState(false)
  const [openCompanyInfo, setOpenCompanyInfo] = React.useState(false)

  const { esg } = props.entitlements.PortfolioAnalysis
  const { controversy } = props.entitlements.Dashboard
  const { data } = climateState?.entitySourceDocuments
  const exportEntitlements = props.entitlements.export
  const confidenceLevelEntitlements =
    props.entitlements.ondemandassessment || esg
  const conditionalStyles = {
    isPDFButtonDisplayed: checkForPDFEntitlements(),
    isExprtSrcButtonDisplayed: esg
  }
  const classes = useStyles(conditionalStyles)

  let climateAPICallId = climateState.apiCallId

  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesMd = useMediaQuery(theme.breakpoints.down('md'))

  const handleMethodologyModalClose = () => {
    setShowMethodologyModal(false)
  }

  const handleExportSourcesDocModalClose = () => {
    setShowExportSourcesModal(false)
  }

  const handleExprtModalKeyDown = (x) => {
    if (x.keyCode === 27) handleExportSourcesDocModalClose()
  }

  const handleMethodologyModalKeyDown = (x) => {
    if (x.keyCode === 27) handleMethodologyModalClose()
  }

  const onEntityPopupClose = () => {
    if (props.handleEntityPopupClose) props.handleEntityPopupClose()
    setPopoverErrorOpen(false)
    setIsPDFEnabled(false)
  }

  const openMethodologyModal = () => {
    setShowMethodologyModal(true)
  }

  const openExportSourcesModal = () => {
    setShowExportSourcesModal(true)
  }

  const pdfGeneration = async () => {
    setPopoverExportOpen(true)
    setTimeout(() => setPopoverExportOpen(false), 30000)

    try {
      let downloadFileName = getFullFileName()
      const { data } = await EntityProfileService.getPDFExport(
        props.orbisId,
        downloadFileName
      )

      if (data?.statusCode === 200 && data?.body?.pdf_link !== '') {
        setTimeout(
          () => downloadFile(data?.body?.pdf_link, downloadFileName),
          120000
        )
      } else {
        setPopoverExportOpen(false)
        setPopoverErrorOpen(true)
        filterDispatch({
          type: REDUCER_TYPE.ENTITY_DOWNLOAD_COMPLETE,
          orbis_id: props.orbisId
        })
      }
    } catch (err) {
      console.log(err)
      setPopoverExportOpen(false)
      setPopoverErrorOpen(true)
      filterDispatch({
        type: REDUCER_TYPE.ENTITY_DOWNLOAD_COMPLETE,
        orbis_id: props.orbisId
      })
    }
    setPdfDownloadClicked(false)
  }

  const downloadFile = (pdf_link, downloadFileName) => {
    filterDispatch({
      type: REDUCER_TYPE.ENTITY_DOWNLOAD_COMPLETE,
      orbis_id: props.orbisId
    })
    const link = document.createElement('a')
    if (link.download !== undefined) {
      link.setAttribute('href', pdf_link)
      link.setAttribute('download', `${downloadFileName}.pdf`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
    setPopoverExportOpen(false)
  }

  useEffect(() => {
    if (!pdfDownloadClicked) return

    filterDispatch({
      type: REDUCER_TYPE.ENTITY_DOWNLOAD_START,
      orbis_id: props.orbisId
    })
    pdfGeneration()
  }, [pdfDownloadClicked])

  const handleExport = () => {
    setShowExportSourcesModal(false)
    setPdfDownloadClicked(true)
  }

  const onPopoverErrorClose = () => {
    setPopoverErrorOpen(false)
  }

  const onPopoverExportClose = () => {
    setPopoverExportOpen(false)
  }

  useEffect(() => {
    init(
      props,
      entityHeaderState,
      climateState,
      climateDispatch,
      climateAPICallId
    )
    if (entityHeaderState.globalEntityHeader.orbis_id !== '') {
      esg &&
        loadDocs(
          false,
          entityHeaderState,
          climateState,
          climateDispatch,
          climateAPICallId
        )
    }
  }, [
    props,
    entityHeaderState?.globalEntityHeader?.orbis_id,
    esg,
    climateAPICallId,
    climateDispatch,
    climateState,
    entityHeaderState
  ])

  useEffect(() => {
    if (
      entityHeaderState.globalEntityHeader.orbis_id !== '' &&
      props.entityOpen
    ) {
      let statesInLoading = true

      let hasAnyAPICallStarted = Object.keys(climateState).some((key) => {
        return (climateState[key]?.dataStatus || '') !== ''
      })
      if (hasAnyAPICallStarted) {
        statesInLoading = Object.keys(climateState).some((key) => {
          return (
            (climateState[key]?.isLoading || false) === true &&
            (climateState[key]?.dataStatus || '') !== ''
          )
        })
        setIsPageLoading(statesInLoading)
      }

      if (filterState.entityExport[props.orbisId]) {
        setIsPDFEnabled(false)
      } else {
        setIsPDFEnabled(true)
      }
    }
  }, [
    props,
    filterState.entityExport,
    entityHeaderState?.globalEntityHeader?.orbis_id
  ])

  const openDrawer = () => {
    setOpenCompanyInfo(true)
  }
  const handleCloseDrawer = () => {
    setOpenCompanyInfo(false)
  }

  const handleEntityClick = async () => {
    handleCloseDrawer()
  }

  const showConLevel =
    confidenceLevelEntitlements &&
    entityHeaderState.globalEntityHeader.confidence_level

  const confidenceLevelText = (
    <span
      data-testid="confidence_level_test_id"
      className={classes.confidenceLevelStyle}
    >
      {'Confidence Level: '}
      <b>{entityHeaderState.globalEntityHeader.confidence_level}</b>
      {entityHeaderState.globalEntityHeader.score && ';'}
    </span>
  )

  return (
    <>
      {
        <>
          {showMethodologyModal && (
            <PrimaryModal
              id="methodologies_modal"
              modalOpen={showMethodologyModal}
              modalClass="minorModal"
              modalTitle={PAGE_CON.METHODOLOGIES_MODAL_HEADER_TEXT}
              modalHeaderContentComponent={<></>}
              modalContentComponent={methodologiesModalContent(
                fileCollection(props.entitlements),
                esg,
                controversy,
                showMethodologyModal,
                classes
              )}
              onModalClose={handleMethodologyModalClose}
              showCloseIcon={true}
              onKeyDown={handleMethodologyModalKeyDown}
              modalWidth="600px"
              modalMaxWidth={false}
              modalMinWidth={false}
              modalMinHeight="251px"
            ></PrimaryModal>
          )}
          {showExportSourcesModal && (
            <PrimaryModal
              id="export_sources_documents_modal"
              modalOpen={showExportSourcesModal}
              modalClass={esg ? 'minorModal' : 'smallModal'}
              modalWidth={esg ? 'auto' : '600px'}
              modalTitle={entityHeaderState?.globalEntityHeader?.company_name}
              modalHeaderContentComponent={
                checkForPDFEntitlements() && (
                  <PrimaryButton
                    id="export_pdf"
                    buttonText={'PDF'}
                    buttonClass="buttonPrimary"
                    buttonIcon={
                      <IconDownload
                        fillcolor={
                          themeColorPalette.palette.sys_trad.white.main
                        }
                        style={{ marginTop: '6px' }}
                      />
                    }
                    buttonIconPosition="end"
                    onClick={() => handleExport()}
                    buttonDisabled={!isPDFEnabled}
                  />
                )
              }
              modalContentComponent={
                esg
                  ? exportSourcesDocumentsModalContent(
                      data,
                      classes,
                      matchesSm,
                      matchesMd,
                      climateState
                    )
                  : undefined
              }
              onModalClose={handleExportSourcesDocModalClose}
              showCloseIcon={true}
              onKeyDown={handleExprtModalKeyDown}
              modalMaxWidth={false}
            ></PrimaryModal>
          )}
        </>
      }
      <Dialog
        open={props.entityOpen}
        onClose={onEntityPopupClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.cookieDialog }}
        fullWidth={true}
        fullScreen={true}
        maxWidth={'xl'}
      >
        <Toolbar
          disableGutters
          style={entityheaderStyle}
          className={classes.toolbar}
        >
          <div className={classes.headerStyleItems}>
            <div className={classes.menuItems}>
              <div className={classes.headerStyleText}>
                <MenuItem
                  disableGutters
                  className={classes.companyNameTitle}
                  onClick={openDrawer}
                >
                  <div className={classes.companyNameWrapper}>
                    <span>
                      {entityHeaderState.globalEntityHeader.company_name}
                    </span>
                    <IconInformationCircle />
                  </div>
                  <div className={classes.subHeaderStyleText}>
                    {showConLevel && confidenceLevelText}
                    {entityHeaderState.globalEntityHeader.score && (
                      <span>
                        {'Overall Disclosure Ratio: '}
                        <b>{entityHeaderState.globalEntityHeader.score}%</b>
                      </span>
                    )}
                  </div>
                </MenuItem>
              </div>
              <PrimaryPopover
                id="export-popover-error-test-id"
                alertError={true}
                alertHeight="20%"
                alertText={
                  'Export failed because of an issue, please try again later.'
                }
                alertOpen={popoverErrorOpen}
                onAlertClose={onPopoverErrorClose}
                alertPosition="center"
                alertTop="55px"
                marginLeft="16px"
              />
              <PrimaryPopover
                id="export-popover-confirmation"
                alertText={'Download in progress!'}
                alertOpen={popoverExportOpen}
                onAlertClose={onPopoverExportClose}
                alertPosition="right"
                alertTop="100px"
                alertRight="48px"
                marginLeft="16px"
                hideClose={false}
                hideBackdrop={true}
                uploadError={true}
                alertWidth={'250px'}
                popoverClass={'downloadStyle'}
              />
              {(exportEntitlements || esg) && (
                <div>
                  <PrimaryButton
                    id="export_sources_doc_button"
                    buttonText={PAGE_CON.EXPRT_SRCS_DOC_BTN_TEXT}
                    buttonClass="buttonSlim"
                    buttonWidth="95px"
                    buttonHeight="97%"
                    buttonPadding="4px"
                    tooltipText="Please wait until page is fully loaded"
                    showTitle={isPageLoading}
                    onClick={() => openExportSourcesModal()}
                    buttonDisabled={isPageLoading}
                  />
                </div>
              )}
              {checkForEntitlements(esg, controversy) && (
                <div>
                  <PrimaryButton
                    id="ref_Meth_button"
                    buttonText={PAGE_CON.REF_METH_BUTTON_TEXT}
                    buttonClass="buttonSlim"
                    buttonWidth="95px"
                    buttonHeight="97%"
                    buttonPadding="4px"
                    onClick={() => openMethodologyModal()}
                  />
                </div>
              )}
            </div>
            <div
              onClick={onEntityPopupClose}
              className={classes.closeIconWrapper}
              aria-hidden={true}
            >
              <span className={classes.closeIconStyleText}>{'ESC'} </span>
              <IconClose />
            </div>
          </div>
        </Toolbar>

        <DialogContent className={classes.dialogContent} id="div-mainlayout">
          <ReportTemplate
            report={
              <ClimateProfile
                orbis_id={props.orbisId}
                onKeyDown={handleKeyDown}
                entitlements={props.entitlements}
                onEntityPopupClose={onEntityPopupClose}
                openDrawer={openDrawer}
                handleCloseDrawer={handleCloseDrawer}
                handleEntityClick={handleEntityClick}
                openCompanyInfo={openCompanyInfo}
              />
            }
            spanFullPageWidth={true}
            maxWidth={'1280px'}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

EntityModal.prototype = {
  /** it will trigger dialog to show or hide modal */
  entityOpen: PropTypes.bool,
  /** entity orbis id */
  orbisId: PropTypes.string,
  /** parent function to be called when dialog is actioned to be close */
  handleEntityPopupClose: PropTypes.func
}

export default EntityModal
