import { Box, makeStyles } from '@material-ui/core'
import React, { useContext, useEffect } from 'react'
import portfolioAnalysisNavItems from './ReportNavigationPortfolioAnalysis.json'
import { GlobalContext } from 'context/GlobalStateProvider'
import GlobalFilter from './GlobalFilter'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import Upload from '../upload/UploadComponent'
import {
  FEATURE_FLAGS,
  PAGE_CON,
  REDUCER_STATE,
  REDUCER_TYPE,
  carbonFootPrintCalculationOptions
} from 'constant/constants'
import BackdropComp from 'views/globalComponent/BackdropComp'
import { getPromise } from 'utils/helper'
import { DashboardService } from 'services'
import { Page, PageHeader, PrimaryLink } from 'components'

const pageHeaderStyleText = {
  ...textTheme.typography.p3,
  color: themeColorPalette.palette.sys_trad.text.main,
  lineHeight: '16px'
}
const subHeaderStyleText = {
  ...textTheme.typography.p1,
  color: themeColorPalette.palette.sys_trad.text2.main,
  lineHeight: '16px'
}

const widthOffset = PAGE_CON.PAGE_LEFT_MARGIN + 62

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3)
  },
  pageHeaderStyleTextTitle: {
    ...textTheme.typography.h1,
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: '24px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    [theme.breakpoints.up(500)]: {
      width: `${500 - widthOffset}px`
    },
    [theme.breakpoints.up(600)]: {
      width: `${600 - widthOffset}px`
    },
    [theme.breakpoints.up(700)]: {
      width: `${700 - widthOffset}px`
    },
    [theme.breakpoints.up(800)]: {
      width: `${800 - widthOffset}px`
    },
    [theme.breakpoints.up(900)]: {
      width: `${900 - widthOffset}px`
    },
    [theme.breakpoints.up(1000)]: {
      width: `${1000 - widthOffset}px`
    },
    [theme.breakpoints.up(1100)]: {
      width: `${1100 - widthOffset}px`
    },
    [theme.breakpoints.up(1200)]: {
      width: `${1200 - widthOffset}px`
    },
    [theme.breakpoints.up(1250)]: {
      width: `${1250 - widthOffset}px`
    }
  },
  backdrop: {
    zIndex: 1200 + 1,
    color: '#fff'
  },
  backdropVisible: {
    display: 'block'
  },
  backdropHidden: {
    display: 'none'
  }
}))

const drawerWidth = PAGE_CON.PAGE_LEFT_MARGIN

const PortfolioAnalysis = (props) => {
  const classes = useStyles()
  const context = useContext(GlobalContext)
  const [filterState, filterDispatch] = context.globalFilter
  const [dashboardState, dashboardDispatch] = context.globalDashboard
  const [userSettingState, userSettingDispatch] =
    useContext(GlobalContext).userSettingService
  const apiCallId = dashboardState.apiCallId

  const onCloseUpload = () => {
    filterDispatch({
      type: REDUCER_TYPE.UPLOAD_PORTFOLIO_MODAL,
      payload: false,
      uploadType: filterState.uploadType,
      uploadInitiatedFrom: filterState.uploadInitiatedFrom
    })
  }

  const getText = (selectedkey) => {
    let filterResult = filterState.filterData.portfolioList.filter(
      (x) => x.key === selectedkey
    )
    if (filterResult && filterResult.length > 0)
      return [
        filterResult[0][PAGE_CON.DROPDOWN_DISPLAY_COLUMN],
        filterResult[0][PAGE_CON.UPLOAD_DATE_UTC]
      ]
    return ''
  }

  const openCalculationLink = () => {
    userSettingDispatch({
      type: REDUCER_TYPE.USER_SETTING_MANAGEMENT,
      openCalculation: true
    })
  }

  const getCarbonFootPrintText = () => {
    let text = ''

    if (
      props.pageHeaderTitle === PAGE_CON.CRBN_FT_PRT_TITLE &&
      FEATURE_FLAGS.CARBON_FOOTPRINT_WAC
    ) {
      let weightedAverageCalculations =
        userSettingState?.userSetting?.data?.weighted_average_calculations
          ?.carbonfootprint || 'investment_value'
      let weightedAverageCalculationsLabel =
        carbonFootPrintCalculationOptions.find(
          (x) => x.id === weightedAverageCalculations
        )?.pageLabel
      text = (
        <span>
          Aggregate calculations are based on {weightedAverageCalculationsLabel}{' '}
          values. To change calculation logic, go to
          <PrimaryLink
            id="calculation-link-test-id-3"
            linkText={' Portfolio Calculation Settings.'}
            onClick={openCalculationLink}
            linkClass="linkInline"
            linkColorChange={true}
          />
        </span>
      )
    }

    return text
  }

  const portfolio = getText(filterState.globalPortfolioFilter.portfolio)[0]
  const portfolioDate = new Date(
    getText(filterState.globalPortfolioFilter.portfolio)[1]
  ).toUTCString()
  const formattedUTCtime = new Date(
    portfolioDate.substring(0, portfolioDate.length - 13).slice(5)
  )
  const uploadedDate = formattedUTCtime.toLocaleString('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  })

  const carbonFootPrintText = getCarbonFootPrintText()

  const getCoverageData = async () => {
    try {
      const filters = filterState.globalPortfolioFilter
      if (filters.asOfDate) {
        const data =
          (await getPromise(DashboardService.getDashboardCoverage, filters)) ||
          {}

        dashboardDispatch({
          type: REDUCER_TYPE.RELOAD_DASHBOARD_COVERAGE,
          sectionName: REDUCER_STATE.DASHBOARD_RESEARCH_LINES,
          data,
          apiCallId
        })
      }
    } catch (e) {
      dashboardDispatch({
        type: REDUCER_TYPE.LOAD_DATA_ERROR,
        sectionName: REDUCER_STATE.DASHBOARD_RESEARCH_LINES,
        apiCallId
      })
    }
  }

  useEffect(() => {
    getCoverageData()
  }, [filterState.actionData])

  return (
    <Page className={classes.root}>
      <Upload
        modalOpen={filterState.isUploadOpen}
        uploadType={filterState.uploadType}
        onClose={onCloseUpload}
      >
        {' '}
      </Upload>
      <BackdropComp />
      <PageHeader
        pageHeaderMarginLeft={drawerWidth}
        pageHeaderMarginBottom="2px"
        pageHeaderContentComponent={
          <Box>
            <br />
            <div style={pageHeaderStyleText}>
              {' '}
              <GlobalFilter
                pageTitle={props.pageHeaderTitle}
                sideBarItems={portfolioAnalysisNavItems.PortfolioAnalysis}
                section={props.section}
                entitlements={props.entitlements}
              ></GlobalFilter>
            </div>
            <br />
            {portfolio === '' || portfolio === undefined ? (
              <p>Loading...</p>
            ) : (
              <div
                style={{ display: 'flex' }}
                className={classes.pageHeaderStyleTextTitle}
              >
                {' '}
                <div
                  style={{ maxWidth: 'fit-content' }}
                  className={classes.pageHeaderStyleTextTitle}
                  title={portfolio}
                >
                  {portfolio}
                  {''}
                </div>
                : {props.pageHeaderTitle}
              </div>
            )}
            {uploadedDate === '' || uploadedDate === 'Invalid Date' ? (
              <p></p>
            ) : (
              <div style={subHeaderStyleText}>
                {' '}
                {PAGE_CON.UPLOAD_DATE_SUBTITLE} {uploadedDate}{' '}
                {carbonFootPrintText}
              </div>
            )}
            <br />
          </Box>
        }
        scrollWithPage={true}
      ></PageHeader>
      {props.page}
    </Page>
  )
}

export default PortfolioAnalysis
