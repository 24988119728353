import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  list: {
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main,
      borderRadius: 1
    },
    '& .MuiListItem-root:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main
    },
    '& .MuiListItem-root': {
      paddingTop: '4px',
      paddingBottom: '4px'
    },
    '& .MuiList-padding': {
      paddingTop: '0px',
      paddingBottom: '0px'
    },
    '& .MuiTypography-body1': {
      ...textTheme.typography.p3,
      lineHeight: '16px'
    },
    '& .MuiListItem-gutters': {
      paddingLeft: '4px',
      paddingRight: '4px'
    },
    width: (props) => (props.width ? props.width : 'auto'),
    overflowY: 'auto',
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  ripple: {
    opacity: 0
  },
  title: {
    ...textTheme.typography.p3,
    lineHeight: '16px',
    color: themeColorPalette.palette.sys_trad.text2.main,
    width: (props) => (props.width ? props.width : 'auto'),
    paddingLeft: '4px'
  },
  loadingDivStyle: {
    marginLeft: '-10px',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  formCtrlStyle: {
    height: (props) => (props.height ? props.height : '336px')
  }
}))
