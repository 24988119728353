import { makeStyles, withStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  copyIcon: {
    marginRight: '-6px',
    marginBottom: '-12px'
  },
  tooltip: {
    cursor: 'pointer'
  },
  documents: {
    marginLeft: '4px',
    paddingBottom: '8px',
    minWidth: '134px'
  }
}))

export const DocumentTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    fontSize: 12,
    ...theme.typography.p3,
    color: themeColorPalette.palette.sys_trad.main.main,
    marginBottom: '6px',
    cursor: 'pointer !important',
    padding: '4px 4px'
  }
}))(Tooltip)
