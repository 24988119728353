import theme from 'theme/theme'

export const defaultTableTabs = [
  {
    label: 'Largest Holdings',
    key: 'largest_holdings',
    active: true,
    order: 'Top'
  },
  {
    label: 'Leaders',
    key: 'leaders',
    active: false,
    order: 'Top'
  },
  {
    label: 'Laggards',
    key: 'laggards',
    active: false,
    order: 'Bottom'
  }
]

export const mapRange = [
  { color: theme.palette?.genrang_b?.one.main, from: 0, to: 9 },
  { color: theme.palette?.genrang_b?.two.main, from: 10, to: 19 },
  { color: theme.palette?.genrang_b?.three.main, from: 20, to: 29 },
  { color: theme.palette?.genrang_b?.four.main, from: 30, to: 39 },
  { color: theme.palette?.genrang_b?.five.main, from: 40, to: 49 },
  { color: theme.palette?.genrang_b?.six.main, from: 50, to: 59 },
  { color: theme.palette?.genrang_b?.seven.main, from: 60, to: 69 },
  { color: theme.palette?.genrang_b?.eight.main, from: 70, to: 79 },
  { color: theme.palette?.genrang_b?.nine.main, from: 80, to: 89 },
  { color: theme.palette?.genrang_b?.ten.main, from: 90, to: 100 }
]

export const controversiesRowsPerPage = 15

export const performanceTableRanges = [10, 25, 50, 75, 100]

export const getResearchLines = (entitlements) => [
  {
    title: 'Overall ESG Score',
    display_heatmap_label: 'Overall ESG Score',
    entitled: 'esg',
    isEntitled: entitlements.PortfolioAnalysis.esg,
    key: 'esgasmt',
    underscore: 'esgasmt',
    route: 'esgasmt',
    geographicRisk: false,
    heatMap: true
  },
  {
    title: 'Controversies',
    display_heatmap_label: 'Controversy Risk Mitigation',
    entitled: 'controversy',
    isEntitled: entitlements.Dashboard.controversy,
    key: 'controversies',
    underscore: 'controversies',
    route: 'controversies',
    geographicRisk: true,
    heatMap: false
  },
  {
    title: 'Physical Risk',
    header: true,
    entitled: 'physicalrisk',
    isEntitled: entitlements.PortfolioAnalysis.physicalrisk,
    geographicRisk: true,
    heatMap: true
  },
  {
    title: 'Operations Risk',
    entitled: 'operationsrisk',
    display_heatmap_label: 'Physical Risk: Operations Risk',
    isEntitled:
      entitlements.PortfolioAnalysis.physicalriskChildren.operationsrisk,
    key: 'operationsrisk',
    underscore: 'operations_risk',
    route: 'operationsrisk',
    geographicRisk: true,
    heatMap: true
  },
  {
    title: 'Market Risk',
    entitled: 'marketrisk',
    display_heatmap_label: 'Physical Risk: Market Risk',
    isEntitled: entitlements.PortfolioAnalysis.physicalriskChildren.marketrisk,
    key: 'marketrisk',
    underscore: 'market_risk',
    route: 'marketrisk',
    geographicRisk: true,
    heatMap: true
  },
  {
    title: 'Supply Chain Risk',
    entitled: 'supplychainrisk',
    display_heatmap_label: 'Physical Risk: Supply Chain Risk',
    isEntitled:
      entitlements.PortfolioAnalysis.physicalriskChildren.supplychainrisk,
    key: 'supplychainrisk',
    underscore: 'supply_chain_risk',
    route: 'supplychainrisk',
    geographicRisk: true,
    heatMap: true
  },
  {
    title: 'Physical Risk Management',
    entitled: 'physicalriskmanagement',
    display_heatmap_label: 'Physical Risk Management',
    isEntitled:
      entitlements.PortfolioAnalysis.physicalriskChildren
        .physicalriskmanagement,
    key: 'physicalriskmgmt',
    underscore: 'physical_risk_management',
    route: 'physicalriskmanagement',
    geographicRisk: true,
    heatMap: true
  },
  {
    title: 'Transition Risk',
    header: true,
    entitled: 'transitionrisk',
    isEntitled: entitlements.PortfolioAnalysis.transitionrisk,
    key: 'transitionrisk',
    geographicRisk: true,
    heatMap: true
  },
  {
    title: 'Temperature Alignment',
    entitled: 'temperaturealgmt',
    display_heatmap_label: 'Temperature Alignment',
    isEntitled: entitlements.PortfolioAnalysis.transitionrisk,
    key: 'temperaturealgmt',
    underscore: 'temperature_alignment',
    route: 'temperaturealignment',
    geographicRisk: true,
    heatMap: true
  },
  {
    title: 'Carbon Footprint',
    entitled: 'carbonfootprint',
    display_heatmap_label: 'Carbon Footprint',
    isEntitled:
      entitlements.PortfolioAnalysis.transitionriskChildren.carbonfootprint,
    key: 'carbonfootprint',
    underscore: 'carbon_footprint',
    route: 'carbonfootprint',
    geographicRisk: true,
    heatMap: true
  },
  {
    title: 'Green Share Assessment',
    entitled: 'greenshareassessment',
    display_heatmap_label: 'Green Share Assessment',
    isEntitled:
      entitlements.PortfolioAnalysis.transitionriskChildren
        .greenshareassessment,
    key: 'greenshareasmt',
    underscore: 'green_share',
    route: 'greenshareassessment',
    geographicRisk: true,
    heatMap: true
  },
  {
    title: 'Brown Share Assessment',
    entitled: 'brownshareassessment',
    display_heatmap_label: 'Brown Share Assessment',
    isEntitled:
      entitlements.PortfolioAnalysis.transitionriskChildren
        .brownshareassessment,
    key: 'brownshareasmt',
    underscore: 'brown_share',
    route: 'brownshareassessment',
    geographicRisk: true,
    heatMap: true
  }
]

export const researchLines = [
  {
    title: 'Physical Risk',
    header: true,
    entitled: 'physicalrisk'
  },
  {
    title: 'Operations Risk',
    entitled: 'operationsrisk',
    key: 'operationsrisk',
    underscore: 'operations_risk'
  },
  {
    title: 'Market Risk',
    entitled: 'marketrisk',
    key: 'marketrisk',
    underscore: 'market_risk'
  },
  {
    title: 'Supply Chain Risk',
    entitled: 'supplychainrisk',
    key: 'supplychainrisk',
    underscore: 'supply_chain_risk'
  },
  {
    title: 'Physical Risk Management',
    entitled: 'physicalriskmanagement',
    lastItemInSection: true,
    key: 'physicalriskmgmt',
    underscore: 'physical_risk_management'
  },
  {
    title: 'Transition Risk',
    header: true,
    entitled: 'transitionrisk'
  },
  {
    title: 'Carbon Footprint',
    entitled: 'carbonfootprint',
    key: 'carbonfootprint',
    underscore: 'carbon_footprint'
  },
  {
    title: 'Green Share Assessment',
    entitled: 'greenshareassessment',
    key: 'greenshareasmt',
    underscore: 'green_share'
  },
  {
    title: 'Brown Share Assessment',
    entitled: 'brownshareassessment',
    key: 'brownshareasmt',
    underscore: 'brown_share'
  },
  {
    title: 'Energy Transition Management',
    entitled: 'energytransitionmanagement',
    key: 'energytransmgmt',
    underscore: 'energy_transition'
  },
  {
    title: 'Climate Governance & Strategy',
    header: true,
    entitled: 'climategovernancestrategy'
  },
  {
    title: 'TCFD Strategy',
    entitled: 'tcfdstrategy',
    key: 'tcfdstrategy',
    underscore: 'tcfd_strategy'
  }
]

export const getTableHeaders = (entitlements) => [
  {
    label: 'Company',
    columnStyles: {},
    sortable: false,
    entitled: true,
    key: 'company_name',
    dataKey: 'COMPANY_NAME',
    width: 200
  },
  {
    label: '% Investment',
    columnStyles: { backgroundColor: '#D7EDFA', textAlign: 'right' },
    sortable: false,
    entitled: true,
    key: 'percent_holdings',
    dataKey: 'PERCENT_HOLDINGS'
  },
  {
    label: 'Overall ESG Score',
    columnStyles: {},
    sortable: true,
    entitled: entitlements.PortfolioAnalysis.esg,
    key: 'esgasmt',
    dataKey: 'SCORE_CATEGORY_ESG_ASSESSMENT'
  },
  {
    label: 'Total Critical ESG Incidents',
    columnStyles: {},
    sortable: false,
    entitled: entitlements.Dashboard.controversy,
    key: 'critical_controversies',
    dataKey: 'CURR_CRITICAL_CONTROVERSIES'
  },
  {
    label: 'Highest Risk Hazard',
    columnStyles: {},
    sortable: false,
    entitled: entitlements.PortfolioAnalysis.physicalrisk,
    key: 'physicalriskhazard_hrh',
    dataKey: 'PHYSICAL_RISK_HAZARD_CATEGORY_NAME'
  },
  {
    label: 'Facilities Exposed to High Risk/Red Flag',
    columnStyles: {},
    sortable: true,
    entitled: entitlements.PortfolioAnalysis.physicalrisk,
    key: 'physicalriskhazard',
    dataKey: 'PHYSICAL_RISK_HAZARD_PERCENT_FACILITIES_EXPOSED'
  },
  {
    label: 'Physical Risk Management',
    columnStyles: {},
    sortable: true,
    entitled: entitlements.PortfolioAnalysis.physicalrisk,
    key: 'physicalriskmgmt',
    dataKey: 'SCORE_CATEGORY_PHYSICAL_RISK_MANAGEMENT'
  },
  {
    label: 'Temperature Alignment',
    columnStyles: {},
    sortable: true,
    entitled:
      entitlements.PortfolioAnalysis.transitionriskChildren
        .temperaturealignment,
    key: 'temperaturealgmt',
    dataKey: 'SCORE_CATEGORY_TEMPERATURE_ALIGNMENT'
  },
  {
    label: 'Carbon Footprint (tCO2eq)',
    columnStyles: {},
    sortable: true,
    entitled:
      entitlements.PortfolioAnalysis.transitionriskChildren.carbonfootprint,
    key: 'carbon_footprint',
    dataKey: 'CURR_SCORE_CARBON_FOOTPRINT'
  },
  {
    label: 'Green Share Assessment',
    sortable: true,
    entitled:
      entitlements.PortfolioAnalysis.transitionriskChildren
        .greenshareassessment,
    key: 'green_share',
    dataKey: 'CURR_SCORE_GREEN_SHARE'
  },
  {
    label: 'Brown Share Assessment',
    sortable: true,
    entitled:
      entitlements.PortfolioAnalysis.transitionriskChildren
        .brownshareassessment,
    key: 'brown_share',
    dataKey: 'CURR_SCORE_BROWN_SHARE'
  }
]
