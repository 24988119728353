import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'
import { createTheme } from '@mui/material/styles'
import { makeStyles } from '@material-ui/core'

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 1000,
      lg: 1200,
      xl: 1536
    }
  }
})

export const useStyles = makeStyles(() => ({
  exprtModalList: {
    columnCount: '2',
    columnGap: '50px',
    ...textTheme.typography.p4,
    '& li': {
      '& a': {
        whiteSpace: 'pre'
      }
    }
  },
  exprtModalListSm: {
    columnCount: '1',
    ...textTheme.typography.p4
  },
  exprtModalListMd: {
    columnCount: '1',
    ...textTheme.typography.p4
  },
  modalLoading: {
    display: 'flex'
  },
  exprtSrcLoader: {
    padding: '100px 240px'
  },
  exprtReLoader: {
    padding: '100px 136px'
  },
  exprtReLoaderSm: {
    padding: '20px'
  },
  exprtDocError: {
    minWidth: '600px',
    padding: '90px'
  },
  exprtDocErrorMd: {
    minWidth: 'auto'
  },
  exprtSrcDocMdlSubHdr: {
    ...textTheme.typography.h2,
    paddingBottom: '5px'
  },
  documentListLinks: {
    listStyleType: 'none',
    '& li': {
      padding: '3px 0',
      '& a': {
        color: themeColorPalette.palette.sys_trad.text.main,
        textDecoration: 'underline'
      }
    }
  },
  menuItems: {
    display: 'flex',
    flex: '1 1 auto',
    '& button': {
      whiteSpace: 'break-spaces'
    },
    '& #ref_Meth_button': {
      marginLeft: (conditionalStyles) =>
        conditionalStyles.isExprtSrcButtonDisplayed ||
        conditionalStyles.isPDFButtonDisplayed
          ? '12px'
          : '',
      fontSize: '10px'
    },
    '& #export_sources_doc_button': {
      marginLeft: (conditionalStyles) =>
        conditionalStyles.isPDFButtonDisplayed ? '12px' : '',
      fontSize: '10px'
    }
  },
  menuBtn: {
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: 'transparent' //themeColorPalette.palette.sys_trad.hover2.main,
    },
    padding: '0 20px 0 2px'
  },
  companyName: {
    '& .MuiTouchRipple-root': {
      display: 'none'
    },
    minHeight: 'auto',
    borderRadius: '2px',
    fontSize: '13px !important',
    marginLeft: 8,
    marginTop: '-13px',
    paddingRight: '12px',
    paddingBottom: '8px',
    paddingLeft: '8px',
    '&.MuiListItem-button': {
      fontSize: '13px !important'
    },
    ...textTheme.typography.p3,
    color: themeColorPalette.palette.sys_trad.main.main,
    '&:hover': {
      backgroundColor: 'transparent' //themeColorPalette.palette.sys_trad.hover2.main,
    }
  },
  escapeStyle: {
    fontSize: '13px !important',
    paddingRight: 7.5,
    ...textTheme.typography.p3,
    color: themeColorPalette.palette.sys_trad.main.main
  },
  dialogContent: {
    padding: 0
  },
  dialogContentStyle: {
    overflowY: 'hidden'
  },
  headerStyleItems: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '1'
  },
  companyNameTitle: {
    fontFamily: ['WhitneyNarrSemiBold', 'Roboto', 'Arial', 'sans-serif'],
    color: themeColorPalette.palette.sys_trad.text.main,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '19px',
    letterSpacing: '0em',
    textAlign: 'left',
    marginTop: '-5px',
    marginLeft: '8px',
    paddingLeft: '8px',
    paddingRight: '8px',
    display: 'flex',
    flexFlow: 'row wrap',
    borderRadius: '3px',
    '& svg': {
      marginLeft: '5px',
      fontSize: '15px'
    },
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main
    }
  },
  subHeaderStyleText: {
    ...textTheme.typography.p1,
    color: themeColorPalette.palette.sys_trad.text.main,
    lineHeight: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    flex: '1',
    flexBasis: '100%'
  },
  companyNameWrapper: {
    flex: '1',
    flexBasis: '100%',
    paddingBottom: '5px'
  },
  subHeaderWrapper: {},
  headerStyleText: {
    height: '40px',
    width: 'min-content'
  },
  closeIconWrapper: {
    display: 'inline-block',
    cursor: 'pointer',
    marginRight: '14px',
    marginTop: '0px',
    padding: '12px 4px 0 14px',
    transition: 'all .2s linear',
    borderRadius: '3px',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main
    }
  },
  closeIconStyleText: {
    ...textTheme.typography.p1,
    cursor: 'pointer',
    paddingRight: '3px',
    fontSize: '12px',
    verticalAlign: 'top'
  },
  confidenceLevelStyle: {
    fontFamily: ['WhitneyNarrBook', 'Roboto', 'Arial', 'sans-serif'],
    fontSize: '12px',
    fontWeight: '400',
    '& > b': {
      fontFamily: ['WhitneyNarrSemiBold', 'Roboto', 'Arial', 'sans-serif']
    }
  }
}))
