import { Container, Grid, makeStyles } from '@material-ui/core'
import { Page } from 'components'
import React from 'react'
import textTheme from 'theme/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    ...textTheme.typography.h1,
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const GenericPage = (props) => {
  const classes = useStyles()

  return (
    <Page
      className={classes.root}
      title={props.title}
      id="genericpage-page-test-id"
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xl={3} id="genericpage-grid-test-id">
            {props.title}
            {props.element}
            {props.element2}
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default GenericPage
