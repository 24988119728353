import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import EsgAssessmentsColumns from 'views/portfolioanalysis/esg/esgassessments/EsgAssessmentsColumns.json'

const portfolioESGScore = {
  title: PAGE_CON.PORTFOLIO_ESGSCORE_TITLE,
  isLoading: true,
  dataStatus: '',
  data: { score: [], distribution: [], coverage: {}, coverageDetails: [] }
}

const portfolioScore = {
  title: PAGE_CON.PORTFOLIO_SCORE_TITLE,
  isLoading: true,
  dataStatus: '',
  data: []
}

const portfolioDistribution = {
  title: PAGE_CON.PORTFOLIO_DISTRIBUTION_TITLE,
  columns: EsgAssessmentsColumns.esgAsmnt_Portfolio_Distribution_Columns,
  isLoading: true,
  dataStatus: '',
  data: []
}

const portfolioCoverage = {
  title: PAGE_CON.PORTFOLIO_COVERAGE_TITLE,
  isLoading: true,
  dataStatus: '',
  data: {}
}

const portfolioCoverageDetails = {
  title: PAGE_CON.PORTFOLIO_COVERAGE_TITLE,
  isLoading: true,
  dataStatus: '',
  data: []
}
const portfolioLeaderLaggard = {
  title: `${PAGE_CON.LEADERS_TITLE} and ${PAGE_CON.LAGGARDS_TITLE}`,
  isLoading: true,
  dataStatus: '',
  data: {
    leaders: [],
    laggards: []
  }
}

const esgControversiesMap = {
  isLoading: true,
  dataStatus: '',
  data: []
}

const esgEntityList = {
  isLoading: false,
  dataStatus: '',
  data: {}
}

const esgSectorTreeMap = {
  isLoading: true,
  dataStatus: '',
  data: { summary: [], details: [], coverage: [] }
}

export function EsgAssessmentsInitialState() {
  let obj = {}
  obj.apiCallId = uuidv4()
  obj.cancelToken = axios.CancelToken.source()
  obj.portfolioESGScore = Object.assign(
    {},
    {
      ...portfolioESGScore,
      data: { score: [], distribution: [], coverage: {}, coverageDetails: [] }
    }
  )
  obj.portfolioScore = Object.assign({}, { ...portfolioScore })
  obj.portfolioDistribution = Object.assign({}, { ...portfolioDistribution })
  obj.portfolioCoverage = Object.assign({}, { ...portfolioCoverage, data: {} })
  obj.portfolioCoverageDetails = Object.assign(
    {},
    { ...portfolioCoverageDetails, data: [] }
  )
  obj.portfolioLeaderLaggard = Object.assign(
    {},
    { ...portfolioLeaderLaggard, data: { leaders: [], laggards: [] } }
  )
  obj.esgControversiesMap = Object.assign({}, { ...esgControversiesMap })
  obj.esgEntityList = Object.assign({}, { ...esgEntityList })
  obj.esgSectorTreeMap = Object.assign(
    {},
    { ...esgSectorTreeMap, data: { summary: [], details: [], coverage: [] } }
  )
  return obj
}

const EsgAssessmentsReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.LOAD_DATA_START:
      currentState[action.sectionName].isLoading = true
      currentState[action.sectionName].dataStatus = PAGE_CON.LOADING
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_ERROR:
      if (action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.ERROR
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_COMPLETED:
      if (action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState[action.sectionName].data = action.data
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.SUCCESS
      return { ...currentState }
    case REDUCER_TYPE.UPDATE_IMPACT_FILTER:
      currentState.portfolioImpact.filter = action.data
      return { ...currentState }
    case REDUCER_TYPE.BENCHMARK_CHANGED:
    case REDUCER_TYPE.RESET:
      currentState.cancelToken.cancel('cancelling operation')
      return EsgAssessmentsInitialState()
    default:
      return currentState
  }
}

export default EsgAssessmentsReducer
