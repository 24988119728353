import React from 'react'
import { Button, Link, List, ListSubheader } from '@material-ui/core'
import clsx from 'clsx'
import * as S from './styles'

export const CountryListContainer = ({ id, children }) => {
  const classes = S.useStyles()
  return (
    <div id={id} className={classes.container}>
      {children}
    </div>
  )
}

export const CoverageTitle = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.title}>{children}</div>
}

export const CoverageContent = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.coverageTitle}>{children}</div>
}

export const SectionTitle = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.sectionTitle}>{children}</div>
}

export const ChicletsContainer = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.chicletsContainer}>{children}</div>
}

export const CriticalContent = ({ children }) => {
  const classes = S.useStyles()
  return <span className={classes.critical}>{children}</span>
}

export const ListCellContainer = ({ children, className, onClick, title }) => {
  const classes = S.useStyles()
  return (
    <span
      className={`${classes.listCellContainer} ${className}`}
      onClick={onClick}
      title={title}
      aria-hidden={true}
    >
      {children}
    </span>
  )
}

export const ModalTitle = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.modalTitle}>{children}</div>
}

export const ModalSubTitle = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.modalSubTitle}>{children}</div>
}

export const ModalDate = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.modalDate}>{children}</div>
}

export const ModalContentParagraphContent = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.modalContentParagraphContent}>{children}</div>
}

export const ModalContentParagraph = ({ children, className }) => {
  const classes = S.useStyles()
  return (
    <p className={`${classes.modalContentParagraph} ${className}`}>
      {children}
    </p>
  )
}

export const ModalContentContainer = ({ children, className }) => {
  const classes = S.useStyles()
  return (
    <div className={`${classes.modalContentParagraph} ${className}`}>
      {children}
    </div>
  )
}

export const FiltersContainer = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.filtersContainer}>{children}</div>
}

export const PanelContainer = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.panelContainer}>{children}</div>
}

export const HeaderContainer = ({ children, minify }) => {
  const classes = S.useStyles({ minify })
  return <div className={classes.headerContainer}>{children}</div>
}

export const PerformanceTableTitle = ({ children }) => {
  const classes = S.useStyles()
  return (
    <div className={`${classes.title} ${classes.performanceTableTitle}`}>
      {children}
    </div>
  )
}

export const PerformanceTableTitleSidePanel = ({ children }) => {
  const classes = S.useStyles()
  return (
    <div
      className={`${classes.titleSidePanel} ${classes.performanceTableTitle}`}
    >
      {children}
    </div>
  )
}

export const PerformanceTableDropdown = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.performanceTableDropdown}>{children}</div>
}

export const CellContainer = ({ label, value, customClass }) => {
  const classes = S.useStyles()
  return (
    <div className={clsx(classes.cellContainer, customClass)}>
      {label && <div className="label">{label}</div>}
      {value && <div className="value">{value}</div>}
    </div>
  )
}

export const ArrowContainer = ({ children }) => {
  const classes = S.useStyles()
  return <span className={classes.arrowContainer}>{children}</span>
}

export const ResearchLinesList = ({ children }) => {
  const classes = S.useStyles()
  return <List className={classes.list}>{children}</List>
}

export const ListButtonItem = ({ children, active }) => {
  const classes = S.useStyles()
  return (
    <Button
      component={Link}
      disableRipple
      className={`${classes.listButton} ${active && classes.listButtonActive}`}
    >
      {children}
    </Button>
  )
}

export const ListSubheaderItem = ({ children, itemKey }) => {
  const classes = S.useStyles()
  return (
    <ListSubheader key={itemKey} className={classes.listSubheader}>
      {children}
    </ListSubheader>
  )
}

export const HeatmapSummary = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.heatmapSummary}>{children}</div>
}

export const HeatmapSelector = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.heatmapSelector}>{children}</div>
}

export const BoxContainer = ({ children, minHeight, padding }) => {
  const classes = S.useStyles({ minHeight, padding })
  return <div className={classes.boxContainer}>{children}</div>
}

export const HeatmapEntityContainer = ({ children }) => {
  const classes = S.useStyles()
  return (
    <div
      className={classes.heatmapEntityContainer}
      onClick={(event) => event.stopPropagation()}
      aria-hidden={true}
    >
      {children}
    </div>
  )
}

export const HeatmapEntityItem = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.heatmapEntityItem}>{children}</div>
}

export const HeatmapEntityHeader = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.heatmapEntityHeader}>{children}</div>
}

export const HeatmapHeader = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.heatmapHeader}>{children}</div>
}

export const Backdrop = () => {
  const classes = S.useStyles()
  return classes['backdrop']
}

export const LoaderBackdrop = ({ children, visible }) => {
  const classes = S.useStyles({ visible })
  return <div className={classes.loaderBackdrop}>{children}</div>
}

export const ButtonContainer = ({ children }) => {
  const classes = S.useStyles()
  return <div className={classes.buttonContainer}>{children}</div>
}

export const ListContainer = ({ children }) => {
  const classes = S.useStyles()
  return <ul className={classes.listContainer}>{children}</ul>
}

export const GeographicRiskTitleContainer = ({ children }) => {
  const classes = S.useStyles()
  return <ul className={classes.geographicRiskTitleContainer}>{children}</ul>
}

export const PortfolioAnalysisLink = ({ children, onClick }) => {
  const classes = S.useStyles()
  return (
    <div
      onClick={onClick}
      className={classes.portfolioAnalysisLink}
      aria-hidden={true}
    >
      {children}
    </div>
  )
}
