import { makeStyles } from '@material-ui/core'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  grid: {
    textAlign: 'left',
    color: themeColorPalette.palette.sys_trad.main.main
  },
  text: {
    ...textTheme.typography.h3,
    lineHeight: '16px',
    fontSize: '12px',
    color: themeColorPalette.palette.sys_trad.text.main
  },
  innerText: {
    ...textTheme.typography.h4,
    lineHeight: '16px',
    fontStyle: 'normal',
    fontSize: '12px',
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingTop: '4px'
  },
  data: {
    ...textTheme.typography.h3,
    lineHeight: '16px',
    fontSize: '12px'
  },
  divider: {
    backgroundColor: themeColorPalette.palette.sys_trad.border.main,
    marginTop: 8,
    marginBottom: 8
  },
  firstDivider: {
    backgroundColor: themeColorPalette.palette.sys_trad.border.main,
    marginTop: 8,
    marginBottom: 16
  },
  titleLeft: {
    ...textTheme.typography.h2,
    lineHeight: '24px',
    fontSize: 20,
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingBottom: 8
  },
  linkStyle: {
    color: themeColorPalette.palette.sys_trad.main.main,
    textDecoration: 'underline'
  },
  gridStyle: {
    minWidth: 397,
    paddingLeft: 75,
    paddingRight: 75,
    '&.MuiGrid-spacing-xs-3': {
      marginLeft: '-12px',
      marginRight: '-12px',
      marginTop: '0px',
      marginBottom: '0px'
    }
  },
  divRoot: {
    flexGrow: 1
  },
  subHeader: {
    ...textTheme.typography.h4,
    lineHeight: '16px',
    fontStyle: 'normal',
    fontSize: '12px',
    color: themeColorPalette.palette.sys_trad.main.main,
    paddingBottom: '24px',
    cursor: 'pointer'
  },
  sourceDocStyle: {
    paddingLeft: '11px'
  },
  sourceDocTopStyle: {
    paddingTop: 11
  },
  sourceDocMiddleStyle: {
    paddingTop: 8
  },
  sourceDocLastStyle: {
    paddingBottom: 11
  },
  errorStyle: {
    borderRadius: '2px',
    marginLeft: -75,
    marginRight: -75,
    marginTop: -10,
    height: 370
  },
  title: {
    ...textTheme.typography.h2,
    lineHeight: '16px',
    fontSize: 16,
    color: themeColorPalette.palette.sys_trad.text.main,
    paddingBottom: 8
  },
  subTitle: {
    marginTop: '4px',
    marginBottom: '4px'
  },
  dataColor: {
    color: themeColorPalette.palette.sys_trad.status.main
  },
  divider_trend: {
    backgroundColor: themeColorPalette.palette.sys_trad.border.main,
    marginTop: 16,
    marginBottom: 8
  }
}))
