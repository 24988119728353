import { Container, Divider, Grid } from '@material-ui/core'
import { GlobalContext } from 'context/GlobalStateProvider'
import { PAGE_CON, REDUCER_STATE, REDUCER_TYPE } from 'constant/constants'
import {
  SectorSubCagetory,
  SectorTables,
  SectorWeights
} from './sector/sectorInfoData.json'
import Error from 'utils/error'
import { IconRedirect, Page, PrimaryLink, PrimaryModal } from 'components'
import React, { useContext } from 'react'
import SectorDrivers from './sector/SectorDriver'
import SectorInfo from './sector/SectorInfo'
import LoadingMask from 'utils/loadingMask'
import { useOktaAuth } from '@okta/okta-react'
import ListSourceDocument from './ListSourceDocument'
import { EntityService } from 'services'
import * as S from './styles'

const gridPadding = {
  paddingLeft: '12px'
}

const P2LandingPage = (props) => {
  const classes = S.useStyles()
  const [entityHeaderState, entityHeaderDispatch] =
    useContext(GlobalContext)?.globalEntityHeader
  const [issuerSourceDocumentState, issuerSourceDocumentDispatch] =
    useContext(GlobalContext)?.issuerSourceDocument
  const [driverState, driverDispatch] =
    useContext(GlobalContext)?.globalSectorInfoState
  const [, IssuerUrlDispatch] = useContext(GlobalContext)?.globalIssuerUrl
  const { authState } = useOktaAuth()

  const loadHeaderData = async (isErrorReload = false) => {
    if (entityHeaderState?.dataStatus === '' || isErrorReload === true) {
      try {
        entityHeaderDispatch({ type: REDUCER_TYPE.LOAD_DATA_START })

        const data = await EntityService.getHeaderInfo()

        let company_name = data?.company_name || ''
        let country = data?.country || ''
        let lei = data?.lei || ''
        let primary_isin = data?.primary_isin || ''
        let orbis_id = data?.orbis_id || ''
        let region = data?.region || ''
        let mesg_sector = data?.mesg_sector || ''
        let sector_l2 = data?.sector_l2 || ''
        let mesg_sector_detail_description =
          data?.mesg_sector_detail_description?.replace('This sector is', '') ||
          ''

        entityHeaderDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          headerItems: {
            company_name,
            country,
            lei,
            primary_isin,
            orbis_id,
            region,
            mesg_sector,
            sector_l2,
            mesg_sector_detail_description
          }
        })

        let userId = authState.idToken.claims.preferred_username
        IssuerUrlDispatch({
          type: REDUCER_TYPE.UPDATE_URL_INFO,
          data: {
            userId,
            company_name,
            orbis_id,
            mesg_sector,
            sector_l2,
            mesg_sector_detail_description
          }
        })
      } catch (err) {
        entityHeaderDispatch({ type: REDUCER_TYPE.LOAD_DATA_ERROR })
      }
    }
  }
  const loadSourceDoc = async (isErrorReload = false) => {
    if (
      issuerSourceDocumentState?.dataStatus === '' ||
      isErrorReload === true
    ) {
      try {
        issuerSourceDocumentDispatch({ type: REDUCER_TYPE.LOAD_DATA_START })
        const list = await EntityService.getSourceDocuments()
        issuerSourceDocumentDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          SourceDocItems: { list }
        })
      } catch (err) {
        issuerSourceDocumentDispatch({ type: REDUCER_TYPE.LOAD_DATA_ERROR })
      }
    }
  }
  const reloadComponent = () => {
    loadSourceDoc(true)
  }

  React.useEffect(() => {
    loadHeaderData()
    loadSourceDoc()
  }, [])

  const errorDialogClicked = () => {
    entityHeaderDispatch({ type: REDUCER_TYPE.RESET_LOAD_DATA_ERROR })
    loadHeaderData(true)
  }
  const openFAQGuide = () => {
    let FAQGuide =
      process.env.PUBLIC_URL + '/assets/FAQ_Moodys ESG Assessment_July 2022.pdf'
    window.open(FAQGuide, '_blank')
  }
  const openSeeMethodology = () => {
    let seeMethodology =
      process.env.PUBLIC_URL + '/assets/Methodology 2.0 ESG Assessment.pdf'
    window.open(seeMethodology, '_blank')
  }
  const openSubCatMethodology = () => {
    let subCategoryMethodology =
      process.env.PUBLIC_URL +
      '/assets/ESG Assessment_Subcategory Definitions_FINAL 14 FEB22_AN.pdf'
    window.open(subCategoryMethodology, '_blank')
  }
  const openMetricDefinitionMethodology = () => {
    let metricDefinitionMethodology =
      process.env.PUBLIC_URL +
      '/assets/ESGAssessmentMetrics_DefinitionsHandbook_AN.pdf'
    window.open(metricDefinitionMethodology, '_blank')
  }
  const openControveryMethodology = () => {
    let controveryMethodology =
      process.env.PUBLIC_URL +
      '/assets/Controversy Risk Assessment - Methodology June 2021.pdf'
    window.open(controveryMethodology, '_blank')
  }

  const loadDriverCriteriaData = async (isErrorReload = false) => {
    if (
      entityHeaderState ||
      driverState.driverData.dataStatus === '' ||
      isErrorReload === true
    ) {
      driverDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName: REDUCER_STATE.DRIVER_DATA
      })
      try {
        driverDispatch({
          type: REDUCER_TYPE.LOAD_DATA_START,
          sectionName: REDUCER_STATE.DRIVER_DATA
        })
        const data = await EntityService.getDriverCriteria()

        let driver_criteria = data?.driver_details || []
        let driver_count = data?.driver_count || 0
        let sector_peers = data?.sector_peers || []

        driverDispatch({
          type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
          sectionName: REDUCER_STATE.DRIVER_DATA,
          data: { driver_criteria, driver_count, sector_peers }
        })
      } catch (err) {
        driverDispatch({
          type: REDUCER_TYPE.LOAD_DATA_ERROR,
          sectionName: REDUCER_STATE.DRIVER_DATA
        })
      }
    }
  }

  return (
    <Page className={classes.root_p2_landing_page} id={props.id}>
      <PrimaryModal
        id="functionality-error-test-id"
        modalOpen={
          entityHeaderState?.dataStatus === PAGE_CON.ERROR ? true : false
        }
        modalClass="errorModal"
        modalTitle=""
        modalContentComponent={
          <Error
            id={'entityHeaderError'}
            dataStatus={PAGE_CON.ERROR}
            onErrorClick={errorDialogClicked}
            errorClass={'noMargin'}
          />
        }
        modalNoCloseOnClick={true}
        showCloseIcon={false}
      />
      <main className={classes.content}>
        <Container className={classes.container} maxWidth={false}>
          <Grid container spacing={0}>
            <Grid container style={gridPadding}>
              <Grid item xs={12}>
                <SectorInfo />
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={6}>
                <div className={classes.headerTitle}>
                  {entityHeaderState?.globalEntityHeader?.mesg_sector} –
                  Assessment Framework
                </div>
                <div className={classes.headerSubText}>
                  Below are the different environmental, social and governance
                  sub-categories assessed for this sector as well as their level
                  of materiality.
                </div>
                <div className={classes.headerSubTextSmall}>
                  Please click the sub-category titles below to see the data
                  metrics we have collected from the source documents obtained.
                </div>
                <Grid item xs={12} sm={6} className={classes.SectorDriverStyle}>
                  {driverState?.driverData?.isLoading ? (
                    <LoadingMask />
                  ) : driverState?.driverData?.dataStatus === PAGE_CON.ERROR ||
                    (driverState?.driverData?.dataStatus === PAGE_CON.SUCCESS &&
                      !driverState?.driverData?.data?.driver_criteria?.length >
                        0) ? (
                    <div className={classes.errorContainer}>
                      <Error
                        errorClass={'noMargin'}
                        width={'100%'}
                        margin={0}
                        id={'driverError'}
                        topMargin={'0px'}
                        dataStatus={driverState.driverData.dataStatus}
                        onErrorClick={() => loadDriverCriteriaData(true)}
                        errorOccuredOn={PAGE_CON.ENTITY_DRIVER_SUMMARY}
                      />
                    </div>
                  ) : (
                    <SectorDrivers
                      SectorData={
                        driverState?.driverData?.data?.driver_criteria
                      }
                      SectorSubCagetory={SectorSubCagetory}
                      SectorWeights={SectorWeights}
                      SectorTables={SectorTables}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: '12px' }}>
                <div className={classes.headerTitle}>All Source Documents</div>
                {issuerSourceDocumentState?.dataStatus === '' ||
                issuerSourceDocumentState?.isLoading ? (
                  <LoadingMask />
                ) : (
                  <div className={classes.card}>
                    <div className={classes.cardStacked}>
                      <div className={classes.cardReport}>
                        <ListSourceDocument
                          list={
                            issuerSourceDocumentState?.issuerSourceDocument
                              ?.list
                          }
                          dataStatus={issuerSourceDocumentState?.dataStatus}
                          reloadComponent={reloadComponent}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={classes.headerTitle}
                  style={{ display: 'flex' }}
                >
                  <PrimaryLink
                    id="link-link-test-id-3"
                    linkText={'See Methodology Guide'}
                    onClick={openSeeMethodology}
                    linkClass="linkIssuerPDF"
                    linkColorChange={true}
                    linkMargin={'14px'}
                  />
                  <IconRedirect
                    onClick={openSeeMethodology}
                    className={classes.iconRedirectDocs}
                  />
                </div>
                <div
                  className={classes.headerTitle}
                  style={{ display: 'flex', paddingTop: '10px' }}
                >
                  <PrimaryLink
                    id="link-link-test-id-3"
                    linkText={'See Controversy Methodology'}
                    onClick={openControveryMethodology}
                    linkClass="linkIssuerPDF"
                    linkColorChange={true}
                    linkMargin={'14px'}
                  />
                  <IconRedirect
                    onClick={openControveryMethodology}
                    className={classes.iconRedirectDocs}
                  />
                </div>
                <div
                  className={classes.headerTitle}
                  style={{ display: 'flex', paddingTop: '10px' }}
                >
                  <PrimaryLink
                    id="link-link-test-id-3"
                    linkText={'See Subcategory Definitions'}
                    onClick={openSubCatMethodology}
                    linkClass="linkIssuerPDF"
                    linkColorChange={true}
                    linkMargin={'14px'}
                  />
                  <IconRedirect
                    onClick={openSubCatMethodology}
                    className={classes.iconRedirectDocs}
                  />
                </div>
                <div
                  className={classes.headerTitle}
                  style={{ display: 'flex', paddingTop: '10px' }}
                >
                  <PrimaryLink
                    id="link-link-test-id-3"
                    linkText={'See ESG Metric Definitions'}
                    onClick={openMetricDefinitionMethodology}
                    linkClass="linkIssuerPDF"
                    linkColorChange={true}
                    linkMargin={'14px'}
                  />
                  <IconRedirect
                    onClick={openMetricDefinitionMethodology}
                    className={classes.iconRedirectDocs}
                  />
                </div>
                <div
                  className={classes.headerTitle}
                  style={{ display: 'flex', paddingTop: '10px' }}
                >
                  <PrimaryLink
                    id="link-link-test-id-3"
                    linkText={'See FAQ'}
                    onClick={openFAQGuide}
                    linkClass="linkIssuerPDF"
                    linkColorChange={true}
                    linkMargin={'14px'}
                  />
                  <IconRedirect
                    onClick={openFAQGuide}
                    className={classes.iconRedirectDocs}
                  />
                </div>

                <div className={classes.headerSubText}></div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </main>
    </Page>
  )
}

export default P2LandingPage
