import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { PAGE_CON, REDUCER_TYPE } from '../../constant/constants'

const filterDataList = {
  portfolioList: [],
  regionsList: [],
  sectorsList: [],
  asOfDateList: [],
  benchmarkList: [],
  userList: []
}

const defaultFilter = {
  portfolio: '',
  regions: '',
  sectors: '',
  asOfDate: '',
  asOfDateDefaultVal: '',
  benchmark: '',
  user: ''
}

const entityExport = {}

export function globalFilterInitialState() {
  let obj = {}
  obj.apiCallId = uuidv4()
  obj.cancelToken = axios.CancelToken.source()
  obj.isLoading = false
  obj.isPortfolioLoading = false
  obj.isUploadOpen = false
  obj.openBackdrop = false
  obj.backdropText = ''
  obj.uploadType = 'portfolio'
  obj.uploadInitiatedFrom = ''
  obj.actionPerformedOn = ''
  obj.actionData = PAGE_CON.INITIAL_DATA
  obj.dataStatus = ''
  obj.errorOccuredOn = ''
  obj.updatePortfolioFromComponent = 'none'
  obj.userInfo = null
  obj.globalPortfolioFilter = Object.assign({}, { ...defaultFilter })
  obj.filterData = Object.assign({}, { ...filterDataList })
  obj.entityExport = Object.assign({}, { ...entityExport })
  obj.enableScoreQuality = false
  return obj
}

const GlobalFilterReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.LOAD_DATA_START:
      currentState.isLoading = true
      currentState.dataStatus = PAGE_CON.LOADING
      if (
        Object.keys(action).filter((key) => key === 'isPortfolioLoading')
          .length > 0
      )
        currentState.isPortfolioLoading = action.isPortfolioLoading
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_END:
      currentState.isLoading = false
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_COMPLETED:
      if (action.apiCallId && action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      action.listData &&
        Object.keys(action.listData).map((key) => {
          return (currentState.filterData[key] = action.listData[key])
        })
      action.filterVal &&
        Object.keys(action.filterVal).map((key) => {
          return (currentState.globalPortfolioFilter[key] =
            action.filterVal[key])
        })
      currentState.dataStatus = PAGE_CON.SUCCESS
      if (
        Object.keys(action).filter((key) => key === 'isPortfolioLoading')
          .length > 0
      )
        currentState.isPortfolioLoading = action.isPortfolioLoading
      if (action.asOfDateMessage && action.asOfDateMessage !== '') {
        console.log(action.asOfDateMessage)
      }
      currentState.isLoading = false
      currentState.actionPerformedOn = ''
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_ERROR:
      if (action.apiCallId && action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState.isLoading = false
      currentState.dataStatus = PAGE_CON.ERROR
      if (action.errorOccuredOn)
        currentState.errorOccuredOn = action.errorOccuredOn
      return { ...currentState }
    case REDUCER_TYPE.RESET_LOAD_DATA_ERROR:
      currentState.dataStatus = ''
      return { ...currentState }
    case REDUCER_TYPE.UPDATE_PORTFOLIO_COMPLETED:
      currentState.filterData.portfolioList = action.portfolioList
      currentState.filterData.benchmarkList = action.portfolioList
      currentState.isLoading = false
      return { ...currentState }
    case REDUCER_TYPE.UPDATE_FILTER:
      currentState.globalPortfolioFilter[action.name] = action.payload
      currentState.actionPerformedOn = action.actionPerformedOn

      switch (action.name) {
        case PAGE_CON.FILTER_PORTFOLIO:
        case PAGE_CON.FILTER_REGIONS:
        case PAGE_CON.FILTER_SECTORS:
          currentState.cancelToken.cancel('cancelling operation')
          currentState.apiCallId = uuidv4()
          currentState.cancelToken = axios.CancelToken.source()
          if (action.name === PAGE_CON.FILTER_PORTFOLIO) {
            currentState.globalPortfolioFilter.regions = ''
            currentState.globalPortfolioFilter.sectors = ''
            currentState.globalPortfolioFilter.asOfDate = ''
            currentState.globalPortfolioFilter.asOfDateDefaultVal = ''
            currentState.globalPortfolioFilter.benchmark = ''
          }
          break
        default:
          break
      }

      return { ...currentState }
    case REDUCER_TYPE.UPLOAD_PORTFOLIO_MODAL:
      currentState.isUploadOpen = action.payload
      currentState.uploadType = action.uploadType
      currentState.uploadInitiatedFrom = action.uploadInitiatedFrom
      return { ...currentState }
    case REDUCER_TYPE.SCORE_QUALITY_LEVEL:
      currentState.enableScoreQuality = action.payload
      return { ...currentState }
    case REDUCER_TYPE.UPDATED_ACTION:
      if (action.apiCallId && action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState.actionData = action.actionData
      return { ...currentState }
    case REDUCER_TYPE.UPDATE_PORTFOLIO:
      currentState.updatePortfolioFromComponent = action.component
      currentState.filterData.portfolioList = action.portfolioList
      currentState.filterData.benchmarkList = action.portfolioList
      currentState.globalPortfolioFilter.portfolio = action.portfolio
      currentState.globalPortfolioFilter.regions = ''
      currentState.globalPortfolioFilter.sectors = ''
      currentState.globalPortfolioFilter.asOfDate = ''
      currentState.globalPortfolioFilter.asOfDateDefaultVal = ''
      currentState.globalPortfolioFilter.benchmark = ''
      currentState.isLoading = false
      return { ...currentState }
    case REDUCER_TYPE.UPDATE_BENCHMARK:
      currentState.updatePortfolioFromComponent = action.component
      currentState.filterData.portfolioList = action.portfolioList
      currentState.filterData.benchmarkList = action.portfolioList
      currentState.globalPortfolioFilter.benchmark = action.benchmark
      currentState.isLoading = false
      return { ...currentState }
    case REDUCER_TYPE.RESET_UPDATE_PORTFOLIO_FROM_COMPONENT:
      currentState.updatePortfolioFromComponent = 'none'
      return { ...currentState }
    case 'USER_INFO':
      currentState.userInfo = action.userPayload
      return { ...currentState }
    case REDUCER_TYPE.OPEN_BACKDROP:
      currentState.openBackdrop = true
      currentState.backdropText = action.backdropText
      return { ...currentState }
    case REDUCER_TYPE.CLOSE_BACKDROP:
      currentState.openBackdrop = false
      currentState.backdropText = ''
      return { ...currentState }
    case REDUCER_TYPE.ENTITY_DOWNLOAD_START:
      currentState.entityExport[action.orbis_id] = {
        downloadStatus: PAGE_CON.LOADING
      }
      return { ...currentState }
    case REDUCER_TYPE.ENTITY_DOWNLOAD_COMPLETE:
      delete currentState.entityExport[action.orbis_id]
      return { ...currentState }
    default:
      return currentState
  }
}

export default GlobalFilterReducer
