import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Constant from 'constant'
import * as S from './styles'
import { colorMix, hexToRgb, rgbToHex } from 'utils/colorMix'

const HeatMap = ({
  entitlements,
  researchLines,
  data,
  handleSelectItem,
  selectedHeatmapTable,
  cellsDisabled
}) => {
  const [cellSelected, setCellSelected] = useState(['', ''])

  const searchResearchLine = (researchLine) =>
    Constant.Dashboard.getResearchLines(entitlements).find(
      ({ key }) => key === researchLine
    )

  const { researchLineScoreByLabel, scores } = Constant.Scores

  const isHeatmap = true

  const getResearchLinesScoreColor = (researchLine, label) =>
    researchLineScoreByLabel(researchLine, label, isHeatmap).color

  const researchLinesUnderscore = [
    searchResearchLine(researchLines[0]).underscore,
    searchResearchLine(researchLines[1]).underscore
  ]

  const colorBlend = (
    researchLine1,
    researchLine2,
    researchLine1Label,
    researchLine2Label
  ) => {
    if (researchLine1 !== null && researchLine2 !== null) {
      const color1 = hexToRgb(
        getResearchLinesScoreColor(researchLine1, researchLine1Label)
      )
      const color2 = hexToRgb(
        getResearchLinesScoreColor(researchLine2, researchLine2Label)
      )
      const mix = colorMix(color1, color2)
      return rgbToHex(mix)
    } else {
      return getResearchLinesScoreColor(researchLine1, researchLine1Label)
    }
  }

  const getResearchLineScores = (researchLine) => {
    return scores(researchLine, isHeatmap)?.map(({ score }) => score)
  }

  const researchLine1Scores = getResearchLineScores(
    researchLinesUnderscore[0]
  )?.reverse()
  const researchLine2Scores = getResearchLineScores(researchLinesUnderscore[1])

  const handleCellClick = (event, score1, score2) => {
    if (!cellsDisabled) {
      if (score1 && score2) {
        const newCellSelected = [score1.key, score2.key]
        const newCellSelectedObject = [score1, score2]
        setCellSelected(newCellSelected)
        handleSelectItem(newCellSelectedObject)
      } else {
        const newCellSelected = [score1.key, null]
        const newCellSelectedObject = [score1, null]
        setCellSelected(newCellSelected)
        handleSelectItem(newCellSelectedObject)
      }
    }
    event.stopPropagation()
  }

  const isSelected = (score1, score2) =>
    score2
      ? score1 === cellSelected[0] && score2 === cellSelected[1]
      : score1 === cellSelected[0]

  const defaultValue = '0%'

  const filteredResearchLines = researchLines.filter(
    (researchLine) => researchLine
  )

  const oneRlHandleClick = (event, score, data) => {
    if (data) {
      const findResearchLine = data.researchLine1.find(
        ({ research_line_1_score_category, research_line_1_score_range }) =>
          research_line_1_score_category === score ||
          research_line_1_score_range === score
      )
      if (findResearchLine) {
        handleCellClick(event, {
          key: findResearchLine.research_line_1_score_category,
          label: findResearchLine.research_line_1_score_range
        })
      }
    }
  }

  const researchLine1Title =
    researchLines[0]?.toUpperCase() === 'CONTROVERSIES'
      ? searchResearchLine(researchLines[0])?.display_heatmap_label
      : searchResearchLine(researchLines[0])?.title
  const researchLine2Title =
    researchLines[1]?.toUpperCase() === 'CONTROVERSIES'
      ? searchResearchLine(researchLines[1])?.display_heatmap_label
      : searchResearchLine(researchLines[1])?.title

  return (
    <S.Container showBorder={false}>
      <S.TableBoxContainer maxWidth={filteredResearchLines.length === 1 && 240}>
        {researchLines[0] && (
          <S.TableBox columns={1}>
            <S.Table maxWidth={filteredResearchLines.length === 1 && 240}>
              <thead>
                <tr>
                  <S.TableHead>{researchLine1Title || ''}</S.TableHead>
                </tr>
              </thead>
              <tbody>
                {researchLine1Scores.map((score) => {
                  const findByScore =
                    data &&
                    data.researchLine1?.find(
                      ({
                        research_line_1_score_category,
                        research_line_1_score_range
                      }) =>
                        research_line_1_score_category === score ||
                        research_line_1_score_range === score
                    )
                  return (
                    <tr key={`researchLine1-${score}`}>
                      {findByScore ? (
                        <S.TableCell
                          value={findByScore?.total_investment || 0}
                          label={findByScore.research_line_1_score_range}
                          color={getResearchLinesScoreColor(
                            researchLinesUnderscore[0],
                            score
                          )}
                          isValueHide={true}
                        />
                      ) : (
                        <S.TableCell
                          value={defaultValue}
                          label={score}
                          color={getResearchLinesScoreColor(
                            researchLinesUnderscore[0],
                            score
                          )}
                          isValueHide={true}
                        />
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </S.Table>
          </S.TableBox>
        )}
        {researchLines[0] && (
          <S.TableBox
            columns={filteredResearchLines.length > 1 ? 4 : undefined}
          >
            <S.Table>
              <thead>
                <tr>
                  <S.TableHead></S.TableHead>
                </tr>
              </thead>
              <tbody>
                {researchLine1Scores.map((score1) => {
                  return (
                    <tr key={`dataRow-${score1}`}>
                      {researchLine2Scores ? (
                        researchLine2Scores.map((score2) => {
                          const findBlendScore =
                            data &&
                            data.table &&
                            data.table.find(
                              ({
                                research_line_1_score_category,
                                research_line_1_score_range,
                                research_line_2_score_category,
                                research_line_2_score_range
                              }) =>
                                (research_line_1_score_category === score1 ||
                                  research_line_1_score_range === score1) &&
                                (research_line_2_score_category === score2 ||
                                  research_line_2_score_range === score2)
                            )

                          if (findBlendScore) {
                            const blendClickObject1 = {
                              key: findBlendScore.research_line_1_score_category,
                              label: findBlendScore.research_line_1_score_range
                            }
                            const blendClickObject2 = {
                              key: findBlendScore.research_line_2_score_category,
                              label: findBlendScore.research_line_2_score_range
                            }
                            return (
                              <S.TableCell
                                disabled={cellsDisabled}
                                key={`dataCell-${score1}-${score2}`}
                                value={findBlendScore?.perc_investment}
                                color={colorBlend(
                                  researchLinesUnderscore[0],
                                  researchLinesUnderscore[1],
                                  score1,
                                  score2
                                )}
                                onClick={(event) =>
                                  handleCellClick(
                                    event,
                                    blendClickObject1,
                                    blendClickObject2
                                  )
                                }
                                selected={
                                  selectedHeatmapTable?.length &&
                                  isSelected(
                                    findBlendScore.research_line_1_score_category,
                                    findBlendScore.research_line_2_score_category
                                  )
                                }
                              />
                            )
                          } else {
                            return (
                              <S.TableCell
                                disabled={cellsDisabled}
                                key={`dataCell-${score1}-${score2}`}
                                value={defaultValue}
                                onClick={(event) => event.stopPropagation()}
                                color={colorBlend(
                                  researchLinesUnderscore[0],
                                  researchLinesUnderscore[1],
                                  score1,
                                  score2
                                )}
                              />
                            )
                          }
                        })
                      ) : (
                        <S.TableCell
                          disabled={cellsDisabled}
                          key={`dataCell-${score1}`}
                          value={
                            (data &&
                              data.researchLine1.find(
                                ({
                                  research_line_1_score_category,
                                  research_line_1_score_range
                                }) =>
                                  research_line_1_score_category === score1 ||
                                  research_line_1_score_range === score1
                              )?.total_investment) ||
                            defaultValue
                          }
                          color={colorBlend(
                            researchLinesUnderscore[0],
                            null,
                            score1,
                            null
                          )}
                          onClick={(event) =>
                            oneRlHandleClick(event, score1, data)
                          }
                          selected={
                            selectedHeatmapTable?.length &&
                            isSelected(
                              data &&
                                data.researchLine1.find(
                                  ({
                                    research_line_1_score_category,
                                    research_line_1_score_range
                                  }) =>
                                    research_line_1_score_category === score1 ||
                                    research_line_1_score_range === score1
                                )?.research_line_1_score_category
                            )
                          }
                        />
                      )}
                    </tr>
                  )
                })}
              </tbody>
            </S.Table>
          </S.TableBox>
        )}
      </S.TableBoxContainer>
      {researchLines[1] && (
        <S.TableBoxContainer>
          <S.TableBox columns={5}>
            <S.Table horizontal={true} columns={4}>
              <thead>
                <tr>
                  <S.TableHead>{researchLine2Title || ''}</S.TableHead>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {researchLine2Scores.map((score) => {
                    const findByScore =
                      data &&
                      data.researchLine2?.find(
                        ({
                          research_line_2_score_category,
                          research_line_2_score_range
                        }) =>
                          research_line_2_score_category === score ||
                          research_line_2_score_range === score
                      )
                    return findByScore ? (
                      <S.TableCell
                        key={`researchLine2-${score}`}
                        value={findByScore.total_investment || 0}
                        label={findByScore.research_line_2_score_range}
                        color={getResearchLinesScoreColor(
                          researchLinesUnderscore[1],
                          score
                        )}
                        isValueHide={true}
                      />
                    ) : (
                      <S.TableCell
                        key={`researchLine2-${score}`}
                        value={defaultValue}
                        label={score}
                        color={getResearchLinesScoreColor(
                          researchLinesUnderscore[1],
                          score
                        )}
                        isValueHide={true}
                      />
                    )
                  })}
                </tr>
              </tbody>
            </S.Table>
          </S.TableBox>
        </S.TableBoxContainer>
      )}
    </S.Container>
  )
}

HeatMap.propTypes = {
  entitlements: PropTypes.object,
  researchLines: PropTypes.array,
  data: PropTypes.object,
  cellsDisabled: PropTypes.bool
}

export default HeatMap
