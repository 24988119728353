import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '48px',
    background: '#ffffff',
    borderBottom: `1px solid ${theme.palette?.sys_trad?.border?.main}`
  },
  smallContainer: {
    height: '20px'
  },
  smallButton: {
    padding: 0,
    marginRight: '24px',
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: (tabs) => (tabs.length > 1 ? 'pointer' : 'auto')
    }
  }
}))
