import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  alertTextBox: {
    width: '100%',
    paddingTop: (props) =>
      props.alertTextTopPadding ? props.alertTextTopPadding : '3px',
    paddingBottom: (props) =>
      props.paddingBottom ? props.paddingBottom : '6px',
    overflow: 'hidden',
    paddingLeft: (props) => (props.alertError ? '24px' : '0px'),
    wordBreak: 'break-word',
    '& span.ellipsis': {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'inline-block'
    }
  },
  alertIcon: {
    marginBottom: '-7px'
  },
  closeIcon: {
    marginLeft: (props) => (props.marginLeft ? props.marginLeft : '0px'),
    marginRight: '0px'
  },
  errorIcon: {
    paddingTop: '4px'
  },
  popover: {
    overflowY: (props) => (props.overflowY ? props.overflowY : 'auto'),
    borderRadius: '2px',
    backgroundColor: (props) =>
      props.popoverBgColor
        ? props.popoverBgColor
        : themeColorPalette.palette.white.main,
    boxSizing: 'border-box',
    boxShadow: (props) =>
      props.popoverShadow ? '0px 2px 8px 0px rgba(38, 65, 94, 0.2)' : 'none',
    color: themeColorPalette.palette.sys_trad.text.main,
    textAlign: 'left',
    height: (props) => props.alertHeight,
    lineHeight: 'normal',
    ...textTheme.typography.p1,
    '& .MuiAlert-message': {
      height: '100%',
      width: (props) => (props.alertWidth ? props.alertWidth : '345px'),
      paddingTop: (props) => (props.paddingTop ? props.paddingTop : '8px'),
      paddingBottom: (props) =>
        props.paddingBottom ? props.paddingBottom : '8px'
    },
    '&.MuiAlert-message': {
      width: '200px'
    },
    width: (props) => (props.alertWidth ? props.alertWidth : '345px'),
    paddingTop: (props) =>
      props.alertError ? '16px' : props.paddingTop ? props.paddingTop : '',
    paddingBottom: (props) =>
      props.alertError
        ? '16px'
        : props.paddingBottom
        ? props.paddingBottom
        : '',
    paddingLeft: (props) =>
      props.alertError ? '0px' : props.paddingLeft ? props.paddingLeft : '16px',
    paddingRight: (props) =>
      props.alertError
        ? '0px'
        : props.paddingRight
        ? props.paddingRight
        : '16px',
    maxHeight: '500px'
  },
  popoverIcon: {
    paddingTop: (props) => (props.paddingIconTop ? props.paddingIconTop : ''),
    paddingBottom: (props) =>
      props.paddingIconBottom ? props.paddingIconBottom : '',
    height: 24
  },
  popoverCloseIcon: {
    padding: '2px',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.white.main
    }
  },
  downloadStyle: {
    textAlign: 'center !important'
  },
  messageAlert: {
    width: '100%'
  },
  successIcon: {
    paddingTop: '4px'
  },
  snackbar: {
    top: (props) => props.alertTop,
    right: (props) => props.alertRight,
    left: (props) => props.alertLeft,
    zIndex: (props) => (props.uploadError ? 1300 + 1 : 0)
  },
  backdrop: {
    zIndex: 1300 + 1,
    '&.MuiBackdrop-root': {
      backgroundColor: (props) =>
        props.alertTop === null
          ? 'fff'
          : props.uploadError
          ? 'transparent'
          : props.duplicates !== undefined && props.alertTop !== undefined
          ? 'transparent'
          : 'fff'
    }
  }
}))
