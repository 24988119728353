import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { PAGE_CON, REDUCER_TYPE } from 'constant/constants'

const userSetting = {
  isLoading: false,
  dataStatus: '',
  data: {},
  changed: false
}

export function userSettingInitialState() {
  let obj = {}
  obj.apiCallId = uuidv4()
  obj.cancelToken = axios.CancelToken.source()
  obj.openCalculation = false
  obj.userSetting = Object.assign(
    {},
    { ...userSetting, data: {}, changed: false }
  )
  return obj
}

const UserSettingReducer = (currentState, action) => {
  switch (action.type) {
    case REDUCER_TYPE.LOAD_DATA_START:
      currentState[action.sectionName].isLoading = true
      currentState[action.sectionName].dataStatus = PAGE_CON.LOADING
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_ERROR:
      if (action.apiCallId && action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.ERROR
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_COMPLETE_ERROR:
      if (action.apiCallId && action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.SUCCESS
      return { ...currentState }
    case REDUCER_TYPE.LOAD_DATA_COMPLETED:
      if (action.apiCallId && action.apiCallId !== currentState.apiCallId)
        return { ...currentState }
      currentState[action.sectionName].data = action.data
      currentState[action.sectionName].isLoading = false
      currentState[action.sectionName].dataStatus = PAGE_CON.SUCCESS
      currentState[action.sectionName].changed = action.changed || false
      return { ...currentState }
    case REDUCER_TYPE.USER_SETTING_MANAGEMENT:
      currentState.openCalculation = action.openCalculation
      return { ...currentState }
    case REDUCER_TYPE.USER_SETTING_CHANGED:
      currentState[action.sectionName].changed = action.changed || false
      return { ...currentState }
    case REDUCER_TYPE.USER_SETTING_RESET:
      currentState.cancelToken.cancel('cancelling operation')
      return userSettingInitialState()
    default:
      return currentState
  }
}

export default UserSettingReducer
