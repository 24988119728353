import bullet from 'highcharts/modules/bullet.js'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import React from 'react'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

bullet(Highcharts)

const Stacked = ({ title, data, width, barColors, notPercent, sortToltip }) => {
  const options = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'bar',
      width: width || 330,
      height: 80,
      style: {
        ...textTheme.typography.p4
      }
    },
    title: {
      text: title,
      align: 'left',
      style:
        title === '.'
          ? {
              color: themeColorPalette.palette.white.main,
              ...textTheme.typography.p2
            }
          : {
              ...textTheme.typography.p2,
              fontSize: '10 !important'
            }
    },
    xAxis: {
      categories: ['xAxis1'],
      visible: false
    },
    yAxis: {
      min: 0,
      visible: false,
      title: {
        text: 'Y Axis'
      }
    },
    legend: {
      reversed: true,
      enabled: false
    },
    tooltip: {
      outside: true,
      formatter: function () {
        const orderCategories = [
          '0%',
          '0-10%',
          '10-20%',
          '20-33%',
          '33-50%',
          '>=50%',
          'Not Covered'
        ]
        const { range, name, count } = this.series.userOptions
        let tooltipRanges = range || [{ name, count, data: [this.y] }]

        if (sortToltip) {
          tooltipRanges = tooltipRanges
            .map((range) => ({
              ...range,
              order: orderCategories.indexOf(range.name)
            }))
            .sort((a, b) => a.order - b.order)
        }

        const msg = tooltipRanges
          .map((item) => {
            if (item.type === 'range') {
              return `<b>Score Range: ${item.name}<br />${
                notPercent
                  ? `Number of Companies: ${item.count}`
                  : `% Investment: ${item.data[0].toFixed(0)}%`
              }</b><br />`
            }

            return `<b>${item.name}<br /> ${
              notPercent ? item.count : `${this.y.toFixed(0)}%`
            }</b>`
          })
          .join('')

        return msg
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          y: 15,
          overflow: 'none',
          crop: false,
          enabled: true,
          color: textTheme.palette.sys_trad.text.main,
          align: 'left',
          padding: 0,
          style: {
            ...textTheme.typography.p5
          },
          formatter: function () {
            var val = this.y
            if (val === 0) {
              return ''
            }
            if (notPercent) {
              if (this.series.userOptions.name !== 'Not Covered') {
                return this.series.userOptions.count
              }
            } else {
              if (this.series.userOptions.name !== 'Not Covered') {
                return val.toFixed(0) + '%'
              }
            }
          }
        },
        stacking: 'normal',
        enableMouseTracking: true
      }
    },
    colors: barColors,
    series: data
  }
  return <HighchartsReact highcharts={Highcharts} options={options} />
}

Stacked.propTypes = {
  /** Title of the stacked bar */
  title: PropTypes.string,

  /** Data to be displayed */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.number)
    })
  ),

  /** color code to be filled in stacked bar */
  barColors: PropTypes.arrayOf(PropTypes.string),

  /** no percent for tooltip and visual chart label */
  notPercent: PropTypes.bool,

  /** visual sort of tooltips  */
  sortToltip: PropTypes.bool
}

export default React.memo(Stacked)
