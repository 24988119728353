import { AppBar } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Toolbar } from '@material-ui/core'
import themeColorPalette from 'theme/theme'
import { useStyles } from './styles'

const PageHeader = (props) => {
  const [border, setBorder] = useState(
    '1px solid ' + themeColorPalette.palette.white.main
  )
  const classes = useStyles(props)
  const listenScrollEvent = (event) => {
    if (event.target.id !== 'div-mainlayout') return
    if (event.target.scrollTop === 0)
      setBorder('1px solid ' + themeColorPalette.palette.white.main)
    else
      setBorder('1px solid ' + themeColorPalette.palette.sys_trad.border.main)
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent, true)
    return () => {
      window.removeEventListener('scroll', listenScrollEvent, true)
    }
  })
  return (
    <AppBar
      id={props.id}
      position={props.scrollWithPage ? 'sticky' : 'static'}
      className={classes.pageHeader}
      style={{ borderBottom: props.showPageHeaderBorder ? border : 'none' }}
    >
      <Toolbar disableGutters className={classes.toolbarStyle}>
        {props.pageHeaderContentComponent}
      </Toolbar>
    </AppBar>
  )
}

PageHeader.propTypes = {
  /** pageHeader id */
  id: PropTypes.string,
  /** Determines if pageHeader is fixed at top of page and can scroll or not */
  scrollWithPage: PropTypes.bool,
  /** Determines pageHeader width */
  pageHeaderWidth: PropTypes.string,
  /** Determines pageHeader margin bottom */
  pageHeaderMarginBottom: PropTypes.string,
  /** Determines pageHeader margin left */
  pageHeaderMarginLeft: PropTypes.number,
  /** Determines pageHeader margin right */
  pageHeaderMarginRight: PropTypes.string,
  /** Additional component to include in header*/
  pageHeaderContentComponent: PropTypes.any,
  /** optional margin top for toolbar*/
  toolbarMarginTop: PropTypes.string,
  /** optional margin left for toolbar*/
  toolbarMarginLeft: PropTypes.string,
  /** Determines pageHeader height */
  pageHeaderHeight: PropTypes.string,
  /** Determines pageHeader minified version */
  minify: PropTypes.bool,
  /** optional border for header*/
  showPageHeaderBorder: PropTypes.bool
}

PageHeader.defaultProps = {
  showPageHeaderBorder: true
}

export default PageHeader
