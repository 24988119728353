import { makeStyles } from '@material-ui/core/styles'
import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'

export const useStyles = makeStyles(() => ({
  autocompleteStyle: {
    width: (props) => (props.width ? props.width : 'auto'),
    height: (props) => (props.height ? props.height : 'auto'),
    '&.MuiAutocomplete-root[aria-expanded=\'true\']': {
      '& .MuiInput-underline': {
        '&:before': {
          borderBottom:
            '1px solid ' + themeColorPalette.palette.sys_trad.selected.main
        },
        '&:after': {
          borderBottom:
            '1px solid ' + themeColorPalette.palette.sys_trad.selected.main
        },
        '& svg': {
          fill: themeColorPalette.palette.sys_trad.selected.main
        }
      }
    },
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiInput-underline.Mui-disabled': {
      '&:before': {
        borderBottom:
          '1px solid ' + themeColorPalette.palette.sys_trad.border.main
      },
      '& svg': {
        fill: themeColorPalette.palette.sys_trad.border.main
      }
    },
    '& .MuiInput-underline:not(.Mui-disabled)': {
      '&:before': {
        borderBottom:
          '1px solid ' + themeColorPalette.palette.sys_trad.border.main
      },
      '&:after': {
        borderBottom:
          '1px solid ' + themeColorPalette.palette.sys_trad.border.main
      },
      '& svg': {
        fill: themeColorPalette.palette.sys_trad.text.main
      }
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled)': {
      '&:before': {
        borderBottom:
          '1px solid ' + themeColorPalette.palette.sys_trad.hover.main
      },
      '&:after': {
        borderBottom:
          '1px solid ' + themeColorPalette.palette.sys_trad.hover.main
      },
      '& svg': {
        fill: themeColorPalette.palette.sys_trad.hover.main
      }
    },

    '& .MuiSvgIcon-root': {
      fontSize: '16px'
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'auto'
    },
    '& .MuiTouchRipple-rippleVisible': {
      opacity: 0
    }
  },
  popupIndicator: {
    transform: 'none'
  },
  root: {
    '& .MuiPaper-root': {
      boxShadow:
        '0px 0px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
    },
    '& .MuiAutocomplete-listbox': {
      border: 'none',
      ...textTheme.typography.p3,
      lineHeight: '16px',
      '& li': {
        padding: 8
      }
    }
  },
  option: {
    '&[data-focus="true"]': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main
    },
    '&[aria-selected="true"]': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected2.main
    }
  },
  noOptions: {
    ...textTheme.typography.p3,
    lineHeight: '16px',
    padding: 8
  },
  textStyle: {
    '& .MuiInputBase-input.MuiAutocomplete-input': {
      ...textTheme.typography.p3,
      lineHeight: '16px'
    }
  },
  portfolioTextStyle: {
    '& .MuiInputBase-input.MuiAutocomplete-input': {
      ...textTheme.typography.p3,
      fontSize: '14px',
      fontWeight: 325
    },
    '& .MuiInputBase-input.MuiAutocomplete-input:not(.Mui-disabled)': {
      ...textTheme.typography.p2,
      fontSize: '14px'
    }
  }
}))
