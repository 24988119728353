import { makeStyles } from '@material-ui/core/styles'
import textTheme from 'theme/theme'
import themeColorPalette from 'theme/theme'

export const useStyles = makeStyles(() => ({
  button: {
    ...textTheme.typography.p2,
    padding: '0px 8px 0px 8px',
    borderRadius: '2px',
    boxShadow: 'none',
    height: '24px',
    boxSizing: 'border-box',
    textAlign: 'center',
    lineHeight: '16px',
    textTransform: 'none',
    outline: 'none',
    marginLeft: (props) => props.buttonMarginLeft,
    marginTop: (props) => props.buttonMarginTop,
    marginBottom: (props) => props.buttonMarginBotom,
    width: (props) => (props.buttonWidth ? props.buttonWidth : '210px'),
    maxWidth: (props) => props.buttonMaxWidth && props.buttonMaxWidth,
    '&:focus': {
      outline: '2px solid ' + themeColorPalette.palette.sys_trad.focus.main,
      backgroundColor: themeColorPalette.palette.white.main,
      color: themeColorPalette.palette.sys_trad.main.main,
      boxShadow: 'none'
    }
  },
  buttonPrimaryClicked: {
    backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
    color: themeColorPalette.palette.white.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover2.main,
      color: themeColorPalette.palette.sys_trad.hover.main,
      outline: 'none',
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      color: themeColorPalette.palette.white.main,
      boxShadow: 'none'
    }
  },
  buttonPrimary: {
    backgroundColor: themeColorPalette.palette.sys_trad.main.main,
    color: themeColorPalette.palette.white.main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: themeColorPalette.palette.sys_trad.hover.main,
      outline: 'none',
      boxShadow: 'none'
    },
    '&:focus': {
      backgroundColor: themeColorPalette.palette.sys_trad.main.main,
      color: themeColorPalette.palette.white.main,
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover.main,
        lineHeight: 'normal',
        outline: 'none',
        boxShadow: 'none'
      }
    },
    '&.Mui-selected': {
      backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.hover.main,
        lineHeight: 'normal',
        outline: 'none',
        boxShadow: 'none'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.selected.main,
        boxShadow: 'none'
      }
    },
    '&.Mui-disabled': {
      backgroundColor: themeColorPalette.palette.sys_trad.disabled.main,
      color: themeColorPalette.palette.white.main,
      boxShadow: 'none',
      lineHeight: 'normal',
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover': {
        backgroundColor: themeColorPalette.palette.sys_trad.disabled.main,
        lineHeight: 'normal',
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
      '&:focus': {
        backgroundColor: themeColorPalette.palette.sys_trad.disabled.main,
        lineHeight: 'normal',
        boxShadow: 'none',
        cursor: 'not-allowed',
        pointerEvents: 'auto',
        outline: 'none'
      }
    }
  },
  textEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}))
