import React, { useContext } from 'react'
import { GlobalContext } from 'context/GlobalStateProvider'
import { makeStyles } from '@material-ui/core/styles'
import { PAGE_CON } from 'constant/constants'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { ListComp } from 'components'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  listGrid: {},
  dividerGrid: {
    margin: '8px'
  }
}))

// eslint-disable-next-line no-unused-vars
const RegionSectorDateFilter = React.forwardRef((props, ref) => {
  const classes = useStyles()

  const context = useContext(GlobalContext)
  const [filterState] = context.globalFilter
  const [regionsData, setRegionsData] = React.useState([])
  const [sectorsData, setSectorsData] = React.useState([])
  const [asOfDateData, setAsOfDateData] = React.useState([])
  const [selectedRegion, setRegions] = React.useState('')
  const [selectedSector, setSectors] = React.useState('')
  const [selectedAsOfDate, setAsOfDate] = React.useState('')

  React.useEffect(() => {
    if (!filterState.isLoading) {
      setRegionsData(filterState.filterData.regionsList)
      setSectorsData(filterState.filterData.sectorsList)
      setAsOfDateData(filterState.filterData.asOfDateList)

      setRegions(filterState.globalPortfolioFilter.regions)
      setSectors(filterState.globalPortfolioFilter.sectors)
      setAsOfDate(filterState.globalPortfolioFilter.asOfDate)
    }
  }, [filterState.isLoading])

  const onRegionChange = (val) => {
    if (props.onChange) props.onChange(PAGE_CON.FILTER_REGIONS, val)
  }

  const onSectorChange = (val) => {
    if (props.onChange) props.onChange(PAGE_CON.FILTER_SECTORS, val)
  }

  const onAsOfDateChange = (val) => {
    if (props.onChange) props.onChange(PAGE_CON.FILTER_ASOFDATE, val)
  }

  return (
    <Grid container direction="row">
      <Grid item className={classes.listGrid}>
        <ListComp
          id="list-region"
          listLabel="region"
          listDefaultSelection={selectedRegion}
          onChange={onRegionChange}
          listOptions={regionsData}
          labelText={PAGE_CON.ALL_REGIONS}
          labelTextKey={''}
          titleText={'Regions'}
          height={'100%'}
          isLoading={filterState.isLoading}
        />
      </Grid>
      <Grid item className={classes.dividerGrid}>
        <Divider orientation="vertical"></Divider>
      </Grid>
      <Grid item className={classes.listGrid}>
        <ListComp
          id="list-sector"
          listLabel="sector"
          listDefaultSelection={selectedSector}
          onChange={onSectorChange}
          listOptions={sectorsData}
          labelText={PAGE_CON.ALL_SECTORS}
          labelTextKey={''}
          titleText={'Industry Sectors'}
          height={'100%'}
          isLoading={filterState.isLoading}
        />
      </Grid>
      <Grid item className={classes.dividerGrid}>
        <Divider orientation="vertical"></Divider>
      </Grid>
      <Grid item className={classes.listGrid}>
        <ListComp
          id="list-asOfDate"
          listLabel="month"
          isFilter={true}
          listDefaultSelection={selectedAsOfDate}
          onChange={onAsOfDateChange}
          listOptions={asOfDateData}
          titleText={'As Of Date'}
          height={'100%'}
          isLoading={filterState.isLoading}
        />
      </Grid>
    </Grid>
  )
})

RegionSectorDateFilter.prototype = {
  /** Region Data */
  regionData: PropTypes.any,
  /** Sector Data */
  sectorData: PropTypes.any,
  /** As of Date Data */
  asOfDateData: PropTypes.any,
  /** parent container onChange function */
  onChange: PropTypes.func
}

RegionSectorDateFilter.displayName = 'RegionSectorDateFilter'

export default RegionSectorDateFilter
