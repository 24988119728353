import { IconDownload, PrimaryButton, PrimaryModal } from 'components'
import { methodologiesModalContent } from 'views/entityProfile/entity/helper'
import { useStyles } from './styles'
import methodologiesFileCollection from 'resources/methodologiesFileColletion'

const ReportingMethologiesModal = ({ showModal, handleModalVisivility }) => {
  const classes = useStyles()

  const handleMethodologyModalClose = () => {
    handleModalVisivility(false)
  }

  const handleMethodologyModalKeyDown = (x) => {
    if (x.keyCode === 27) handleMethodologyModalClose()
  }

  const modalFooter = () => {
    return (
      <div className={classes.metodologiesModalFooter}>
        <a
          href="/assets/methodologies/methodologies.zip"
          download
          title="download text"
        >
          <PrimaryButton
            id="button-button-test-id-1"
            buttonText="Download All .ZIP"
            buttonClass="buttonPrimary"
            buttonIconPosition="end"
            buttonIcon={
              <IconDownload
                className={classes.downloadZipIcon}
                fillcolor="white"
                viewBox="0 0 15 15"
              />
            }
          />
        </a>
      </div>
    )
  }

  return (
    <PrimaryModal
      id="methodologies_modal"
      modalOpen={showModal}
      modalClass="minorModal"
      modalPosition="absolute"
      topPosition="110px"
      modalTitle={'ESG Methodology'}
      modalHeaderContentComponent={<></>}
      modalContentComponent={methodologiesModalContent(
        methodologiesFileCollection,
        true,
        false,
        showModal,
        classes,
        true
      )}
      onModalClose={handleMethodologyModalClose}
      showCloseIcon={true}
      onKeyDown={handleMethodologyModalKeyDown}
      modalWidth="792px"
      modalMinHeight="251psx"
      showDivider={true}
      modalMaxWidth={false}
      showFooterDivider={true}
      dialogActionCustomStyle={classes.dialogActionCustomStyle}
      modalActionComponent={modalFooter()}
    />
  )
}

export default ReportingMethologiesModal
