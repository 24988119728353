import { makeStyles } from '@material-ui/core'
import theme from 'theme'

export const useStyles = makeStyles(() => ({
  ESGScores: {
    display: 'inline-block',
    margin: '0 30px 10px 0',
    '&>span': {
      ...theme.typography.p3,
      ...theme.typography.bold,
      color: theme.palette.sys_trad.texthelp.main
    },
    '&>div.scores': {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '5px',
      '&>div.score': {
        flexGrow: '1',
        width: '29%',
        margin: '0 3px',
        textAlign: 'center',
        '&>span': {
          display: 'block',
          padding: '0px 3px',
          color: 'rgb(179, 23, 23)',
          backgroundColor: 'rgba(179, 23, 23, .18)',
          borderRadius: '3px'
        },
        '&>p': {
          ...theme.typography.p3,
          color: '#333333',
          marginTop: '3px',
          fontWeight: 'regular'
        }
      }
    }
  }
}))
