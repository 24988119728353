import React, { useEffect, useRef } from 'react'
import OktaSignIn from '@okta/okta-signin-widget'
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css'
import { useHistory } from 'react-router-dom'
import { toRelativeUrl } from '@okta/okta-auth-js'

const OktaSignInWidget = ({ config, onSuccess, onError }) => {
  const widgetRef = useRef()
  const history = useHistory()

  useEffect(() => {
    if (!widgetRef.current) return false
    const widget = new OktaSignIn({
      ...config,
      restoreOriginalUri: async (_oktaAuth, originalUri) =>
        history.replace(toRelativeUrl(originalUri, window.location.origin))
    })

    widget.authClient.session.get().then((res) => {
      if (res.status === 'ACTIVE') {
        widget.authClient.token
          .getWithoutPrompt({ scopes: config.authParams.scopes })
          .then((tokens) => {
            widget.authClient.tokenManager.setTokens(tokens.tokens)
            widget.authClient.handleLoginRedirect(tokens.tokens)
          })
          .catch((error) => console.error(error))
        return
      }
      if (res.status === 'INACTIVE') {
        widget.authClient.tokenManager.clear()
      }
      if (widgetRef.current) {
        widget.renderEl(
          { el: widgetRef.current },
          (res) => widget.authClient.handleLoginRedirect(res.tokens),
          (error) => console.error(error)
        )
      }
    })

    return () => widget.remove()
  }, [config, onSuccess, onError, history])

  return <div ref={widgetRef} />
}

export default OktaSignInWidget
