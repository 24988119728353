import Grid from '@material-ui/core/Grid';
import numberData from 'utils/numberFormat'
import PropTypes from 'prop-types'
import React from 'react'
import { multiColumnSort } from 'utils/Sort'
import { Panel, PrimaryLink, RegularTable } from 'components'
import { PAGE_CON } from 'constant/constants'
import { useStyles } from './styles';
import Legend from './Legend.jsx'


const Laggards = (props) => {
  const classes = useStyles(props);

  let recordLength = props.totalData && (props.totalData.length - props.tableLength);

  let totalData = props.totalData

  const [open, setOpen] = React.useState(false)

  const openDrawer = () => {
    setOpen(true)
  }
  const handleCloseDrawer = () => {
    setOpen(false)
  }

  const isPredictorScoreExist = (data) => {
    if (data && data.length > 0) {
      return data.some(
        (coverageDetails) =>
          coverageDetails['score_quality'] === PAGE_CON.PREDICTED
      )
    } else {
      return false
    }
  }

  function sortData(data) {
    if (props.isTempAlign) {
      let returnData = multiColumnSort(data, [
        ['rank', 'DESC'],
        ['score', 'DESC'],
        ['investment_pct', 'ASC'],
        ['company_name', 'DESC', 'string', false]
      ])
      return returnData
    } else {
      let returnData = multiColumnSort(data, [
        ['rank', 'DESC'],
        ['grade', 'DESC'],
        ['investment_pct', 'ASC'],
        ['company_name', 'DESC', 'string', false]
      ])
      return returnData
    }
  }

  return (
    <div className={classes.root}>
      <Grid id={props.id} container spacing={2}>
        <Grid item xs={12}>
          <div
            style={{ paddingBottom: recordLength > 0 ? '8px' : '8px' }}
            // eslint-disable-next-line react/no-unknown-property
            heap_id="leadlag"
            // eslint-disable-next-line react/no-unknown-property
            heap_leadlag_id="laggards"
          >
            <RegularTable
              id="table-id"
              heap_id="leadlag"
              tableEditable={props.tableEditable}
              columns={props.colInfo}
              totalData={totalData}
              tableLength={props.tableLength}
              showScoreQuality={props.showScoreQuality}
              isRowHighlighted={true}
            ></RegularTable>
          </div>
          {recordLength > 0 ? (
            <PrimaryLink
              isLeadLagTable={true}
              linkText={
                numberData(recordLength, { isDecimal: false }) +
                ' more companies ranked in bottom 10'
              }
              linkClass="linkInline"
              onClick={openDrawer}
            ></PrimaryLink>
          ) : null}
          <Panel
            title="Laggards"
            openPanel={open}
            titlePadding="12px"
            onPanelClose={handleCloseDrawer}
            panelContentComponent={[
              <div
              key="laggardsdrilldown-id"
              className={classes.panelComponentWrapperCss}
            >
              <div className={classes.tableCss}>
                <RegularTable
                  id="table-id"
                  heap_id="leadlag"
                  columns={props.colInfo}
                  totalData={sortData(totalData)}
                  horScroll="hidden"
                  tablePadding="24px"
                  tableEditable={false}
                  inPanel={true}
                  showScoreQuality={props.showScoreQuality}
                  isRowHighlighted={props.noRowHighlight ? false: true}
                ></RegularTable>
              </div>
                {props.research_line === PAGE_CON.ESG_ASSESSMENT && (isPredictorScoreExist(totalData)) ?
                    <Legend legendLableName="Predicted Score" />: null}
            </div>
            ]}  >
          </Panel>
        </Grid>
      </Grid>
    </div>
  )
}

Laggards.propTypes = {
  /** Laggards id */
  id: PropTypes.string,
  /** Number of records to display */
  tableLength: PropTypes.number,
  research_line: PropTypes.string,
  noRowHighlight: PropTypes.bool
};

export default Laggards
