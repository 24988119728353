import React from 'react'
import { FEATURE_FLAGS, PAGE_CON } from 'constant/constants'
import {
  CustomTooltip,
  IconScoreQuality1,
  IconScoreQuality2,
  IconScoreQuality3,
  IconScoreQuality4
} from 'components'

export const ScoreQualityLevel = (props) => {
  const { level } = props
  let component = <></>
  let levelText = ''

  const renderLevel = () => {
    switch (level) {
      case 'Analytical':
        //ESGCA-12592: We use this flag only for the realease of March once done it should be removed
        if (!FEATURE_FLAGS.ESG_SCORE_ENHANCEMENT) {
          PAGE_CON.SCORE_QUALITY.LEVEL1.TITLE =
            PAGE_CON.SCORE_QUALITY.LEVEL1.TITLE.replace('ESG', '')
        }
        levelText = (
          <div>
            <p>
              <strong>{PAGE_CON.SCORE_QUALITY.LEVEL1.TITLE}</strong>
            </p>
            <p>{PAGE_CON.SCORE_QUALITY.LEVEL1.DESCRIPTION}</p>
          </div>
        )
        component = <IconScoreQuality1 />
        break
      case 'Subsidiary':
        levelText = (
          <div>
            <p>
              <strong>{PAGE_CON.SCORE_QUALITY.LEVEL2.TITLE}</strong>
            </p>
            <p>{PAGE_CON.SCORE_QUALITY.LEVEL2.DESCRIPTION}</p>
          </div>
        )
        component = <IconScoreQuality2 />
        break
      case 'on-demand':
        if (FEATURE_FLAGS.SME_ON_DEMAND) {
          levelText = (
            <div>
              <p>
                <strong>{PAGE_CON.SCORE_QUALITY.LEVEL3.TITLE}</strong>
              </p>
              <p>{PAGE_CON.SCORE_QUALITY.LEVEL3.DESCRIPTION}</p>
            </div>
          )
          component = <IconScoreQuality3 />
        }
        break
      case 'on_demand':
        levelText = (
          <div>
            <p>
              <strong>{PAGE_CON.SCORE_QUALITY.LEVEL3.TITLE}</strong>
            </p>
            <p>{PAGE_CON.SCORE_QUALITY.LEVEL3.DESCRIPTION}</p>
          </div>
        )
        component = <IconScoreQuality3 />
        break
      case 'Predicted':
        levelText = (
          <div>
            <p>
              <strong>{PAGE_CON.SCORE_QUALITY.LEVEL4.TITLE}</strong>
            </p>
            <p>{PAGE_CON.SCORE_QUALITY.LEVEL4.DESCRIPTION}</p>
          </div>
        )
        component = <IconScoreQuality4 />
        break
      default:
        levelText = <div></div>
        component = <></>
        break
    }
  }

  renderLevel()

  return (
    <div
      style={{
        display: 'inline-block',
        height: '0',
        verticalAlign: 'top',
        pointerEvents: 'auto'
      }}
    >
      <CustomTooltip title={levelText} placement="top-end">
        <div>{component}</div>
      </CustomTooltip>
    </div>
  )
}

export const predictedScoreHighlight = (predictedScore) => {
  if (typeof predictedScore === 'undefined') return false
  return predictedScore === 'Predicted'
}
