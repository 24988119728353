import React from 'react'
import * as S from './styles'
import PropTypes from 'prop-types'

const ChicletBox = ({ children, type }) => {
  return <S.Container className={type}>{children}</S.Container>
}

ChicletBox.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string
}

export default ChicletBox
