import themeColorPalette from 'theme/theme'
import textTheme from 'theme/theme'
import theme from 'theme/theme'
import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
      {
        outline: 'none !important'
      }
  },
  hideRightSeparator: {
    '& > .MuiDataGrid-columnSeparator': {
      visibility: 'hidden'
    }
  },
  urlInput2: {
    width: (props) => (props.textboxWidth ? props.textboxWidth : '100%'),
    marginLeft: (props) => props.textboxMargin,
    '& .MuiInputBase-input': {
      ...textTheme.typography.p3,
      lineHeight: '16px',
      padding: 0
    },
    backgroundColor: themeColorPalette.palette.white.main,
    boxShadow: '0px 0px 12px rgba(38, 65, 94, 0.1)',
    borderRadius: '2px 2px 0px 0px',
    ':focus': {
      border: '2px solid ' + themeColorPalette.palette.sys_trad.selected.main
    }
  },
  textboxInput: {
    height: (props) => props.textboxHeight,
    minWidth: (props) => (props.showDisclosureYear ? '75px' : '150px'),
    fontSize: '12px',
    color: themeColorPalette.palette.sys_trad.text.main,
    '&.MuiInput-underline:before': {
      borderBottom:
        '1px solid ' + themeColorPalette.palette.sys_trad.border.main
    },
    '&:hover': {
      '&.MuiInput-underline:before': {
        borderBottom:
          '1px solid ' + themeColorPalette.palette.sys_trad.selected.main
      }
    },
    '&.MuiInput-underline:after': {
      height: '1px',
      borderBottom:
        '1px solid ' + themeColorPalette.palette.sys_trad.selected.main
    },
    '&.Mui-focused': {
      color: '2px solid ' + themeColorPalette.palette.sys_trad.focus.main
    },
    '&.MuiInput-underline:focus': {
      border: '2px solid ' + themeColorPalette.palette.sys_trad.focus.main
    },
    '&.Mui-error': {
      '&.MuiInput-underline:after': {
        height: '1px',
        borderBottomColor: themeColorPalette.palette.sys_trad.error.main
      },
      '&.MuiInput-underline:focus': {
        border: '2px solid ' + themeColorPalette.palette.sys_trad.focus.main
      }
    },
    '& input:-internal-autofill-selected': {
      backgroundColor: 'yellow !important'
    }
  },
  inputLabelClass: {
    height: '14px',
    backgroundColor: 'rgba(255, 255, 255, 0)',
    boxSizing: 'border-box',
    fontWeight: 650,
    color: themeColorPalette.palette.sys_trad.text2.main,
    textAlign: 'left',
    lineHeight: '14px',
    fontSize: '13px',
    margin: (props) =>
      props.textboxClass === 'textboxDefaultAlt' ? '8px' : '0px',
    '&.Mui-focused': {
      color: themeColorPalette.palette.sys_trad.text2.main
    },
    '&.Mui-error': {
      color: themeColorPalette.palette.sys_trad.text2.main
    }
  },
  docTypeStyle: {
    width: '160px',
    [theme.breakpoints.down(1300)]: {
      width: 'auto',
      minWidth: '150px'
    }
  }
})
