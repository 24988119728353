import { Box, Checkbox, Drawer, MenuItem } from '@material-ui/core'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  FILES,
  PAGE_CON,
  REDUCER_STATE,
  REDUCER_TYPE,
  carbonFootPrintCalculationOptions,
  predictedScoresCalculationOptions
} from 'constant/constants'
import themeColorPalette from 'theme/theme'
import {
  IconClose,
  IconError,
  IconLeftArrow,
  PrimaryPopover,
  RadioButton
} from 'components'
import { UserService } from 'services'
import { GlobalContext } from 'context/GlobalStateProvider'
import {
  clearErrorPopover,
  showErrorPopover,
  updateInitialUserSettingsDefaultFactory
} from './helpers'
import Error from 'utils/error'

const PortfolioCalculations = ({
  handleCalcDrawerClose,
  calcDrawerOpen,
  useStyles,
  isPredictedScore,
  isTransitionRisk,
  onMovePrevClick,
  getUserSettings
}) => {
  const [userSettingState, userSettingDispatch] =
    useContext(GlobalContext).userSettingService

  let apiCallId = userSettingState.apiCallId

  const classes = useStyles()
  const { menuItem, menuTitle, calcDrawerTitle, calcDrawer, mb0, mt0 } =
    useStyles()

  const [carbonFootprintOptions, setCarbonFootprintOptions] = useState(
    carbonFootPrintCalculationOptions
  )
  const [predictedScoresOptions, setPredictedScoresOptions] = useState(
    predictedScoresCalculationOptions
  )
  const [errorPopoverMessage, setErrorPopoverMessage] = useState(null)

  const clearTimerRef = useRef()

  const saveWAC = async (selected) => {
    try {
      userSettingDispatch({
        type: REDUCER_TYPE.LOAD_DATA_START,
        sectionName: REDUCER_STATE.USER_SETTING,
        apiCallId
      })

      const data = await UserService.updateUserSettings({
        carbon_footprint_weighted_avg: selected
      })

      showErrorPopover(
        {
          type: 'success',
          message: <span>Changes Saved</span>
        },
        setErrorPopoverMessage,
        clearTimerRef
      )

      userSettingDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETED,
        sectionName: REDUCER_STATE.USER_SETTING,
        data: data.settings,
        changed: true,
        apiCallId
      })
      return true
    } catch (error) {
      console.log('error', error)
      userSettingDispatch({
        type: REDUCER_TYPE.LOAD_DATA_COMPLETE_ERROR,
        sectionName: REDUCER_STATE.USER_SETTING,
        apiCallId
      })
      showErrorPopover(
        {
          type: 'error',
          message: (
            <>
              <span>Auto-save error</span>
              <span
                className={classes.retry}
                onClick={() => saveOnFail(selected)}
                aria-hidden={true}
              >
                try again
              </span>
            </>
          )
        },
        setErrorPopoverMessage,
        clearTimerRef
      )
      return false
    }
  }

  const saveOnFail = (selected) => {
    if (selected) {
      const selectedObj = { id: selected, checked: true }
      onChangeWAC(selectedObj)
    }
  }

  const onChangeWAC = async (selected) => {
    clearErrorPopover(setErrorPopoverMessage, clearTimerRef)
    const selectedFromState = carbonFootprintOptions.find(
      ({ checked }) => checked
    )
    const newOptions = [...carbonFootprintOptions].map((item) => ({
      ...item,
      checked: selected.id === item.id ? true : false
    }))
    setCarbonFootprintOptions(newOptions)
    const saveResponse = await saveWAC(selected.id)
    if (!saveResponse) {
      const newOptionsFail = [...carbonFootprintOptions].map((item) => ({
        ...item,
        checked: selectedFromState.id === item.id ? true : false
      }))
      setCarbonFootprintOptions(newOptionsFail)
    }
  }

  const onOpenLink = (link) => {
    window.open(link, '_blank')
  }

  const onChagePredictedScores = (event) => {
    if (event.target.id) {
      const newOptions = [...predictedScoresOptions].map((item) => ({
        ...item,
        checked:
          `pridicted_score_${item.id}` === event.target.id
            ? event.target.checked
            : item.checked
      }))
      setPredictedScoresOptions(newOptions)
    }
  }

  const updateInitialUserSettingsDefault = useCallback(
    updateInitialUserSettingsDefaultFactory(
      apiCallId,
      userSettingState,
      carbonFootPrintCalculationOptions,
      predictedScoresCalculationOptions,
      setCarbonFootprintOptions,
      setPredictedScoresOptions,
      userSettingDispatch
    ),
    []
  )

  useEffect(() => {
    if (
      !userSettingState.userSetting.isLoading &&
      userSettingState.userSetting.dataStatus === PAGE_CON.SUCCESS
    ) {
      updateInitialUserSettingsDefault()
    }
  }, [updateInitialUserSettingsDefault, userSettingState])

  return (
    <div>
      <Drawer
        {...{
          anchor: 'left',
          open: calcDrawerOpen,
          onClose: () => handleCalcDrawerClose(),
          id: 'calc-drawer-test-id',
          PaperProps: { className: calcDrawer },
          BackdropProps: {
            style: {
              backgroundColor: themeColorPalette.palette.sys_trad.main.main,
              opacity: 0.2
            }
          }
        }}
      >
        <MenuItem className={calcDrawerTitle}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: '800'
            }}
          >
            <span style={{ marginTop: '9px', marginLeft: '0px' }}>
              <IconLeftArrow
                className={classes.subDrawerLeftIcon}
                onClick={() => onMovePrevClick()}
              />
            </span>
            <span className={classes.subDrawerHeader}>
              {PAGE_CON.CACULATIONS_DRAWER_TITLE}
            </span>
          </div>
          <span style={{ marginTop: '9px' }}>
            <IconClose
              style={{ cursor: 'pointer', marginTop: 4 }}
              onClick={() => handleCalcDrawerClose()}
            />
          </span>
        </MenuItem>
        {!userSettingState.userSetting.isLoading &&
          userSettingState.userSetting.dataStatus === PAGE_CON.ERROR && (
            <Error
              id={'calculations-drawer'}
              dataStatus={PAGE_CON.ERROR}
              onError
              onErrorClick={() => getUserSettings()}
            />
          )}
        {userSettingState.userSetting.dataStatus !== PAGE_CON.ERROR &&
          isTransitionRisk && (
            <>
              <div className={classes.titleContainer}>
                <MenuItem className={`${menuTitle} ${mb0}`}>
                  <span className={classes.subDrawerSubHeader}>
                    {PAGE_CON.WEIGHTED_AVERAGE_CALCULATIONS_TITLE}
                  </span>
                </MenuItem>
                <MenuItem className={classes.menuSubTitle}>
                  <span className={classes.subDrawerSubHeaderSubTitle}>
                    Carbon Footprint
                  </span>
                </MenuItem>
                <MenuItem className={`${classes.menuTitle} ${mt0}`}>
                  <span className={classes.subDrawerSubHeaderInfo}>
                    In alignment with the{' '}
                    <span
                      className={classes.link}
                      onClick={() => onOpenLink(FILES.PCAF_GLOBAL_GHG_STANDARD)}
                      aria-hidden={true}
                    >
                      Partnership for Carbon Accounting Financials (PCAF)
                    </span>{' '}
                    recommends we offer the below options to calculation
                    portfolio aggregations for Carbon Footprint.
                  </span>
                </MenuItem>
              </div>
              {carbonFootprintOptions.map(({ id, label, checked }) => (
                <MenuItem
                  className={`${menuItem} ${classes.checkboxContainer}`}
                  key={id}
                >
                  <Box>
                    <RadioButton
                      id={id}
                      isChecked={checked}
                      isDisabled={userSettingState.userSetting.isLoading}
                      radioLabelText={label}
                      handleRadioChange={onChangeWAC}
                    />
                  </Box>
                </MenuItem>
              ))}
            </>
          )}
        {userSettingState.userSetting.dataStatus !== PAGE_CON.ERROR &&
          isPredictedScore && (
            <>
              <div className={classes.titleContainer}>
                <MenuItem className={menuTitle}>
                  <span className={classes.subDrawerSubHeader}>
                    {PAGE_CON.PREDICTED_SCORE_AND_DATA_TITLE}
                  </span>
                </MenuItem>
              </div>
              {predictedScoresOptions
                .filter((item) => item.display)
                .map(({ id, label, checked }) => (
                  <MenuItem
                    className={`${menuItem} ${classes.checkboxContainer}`}
                    key={id}
                  >
                    <Box className={classes.boxWidth}>
                      <Checkbox
                        id={`pridicted_score_${id}`}
                        className={classes.checkboxStyle}
                        onChange={onChagePredictedScores}
                        checked={checked}
                        disabled={userSettingState.userSetting.isLoading}
                      />
                      <label
                        htmlFor={`pridicted_score_${id}`}
                        className={`${classes.fw600} ${classes.labelColor}`}
                      >
                        {label}
                      </label>
                    </Box>
                  </MenuItem>
                ))}
            </>
          )}
        <PrimaryPopover
          alertOpen={errorPopoverMessage !== null}
          alertIcon={
            {
              error: (
                <IconError
                  colorprimary={themeColorPalette.palette.sys_trad.text.main}
                />
              ),
              success: undefined
            }[errorPopoverMessage?.type]
          }
          alertText={errorPopoverMessage?.message}
          alertPosition="center"
          alertWidth={'auto'}
          alertTop="2px"
          alertLeft="180px"
          alertTextTopPadding={'1px'}
          paddingTop="4px"
          paddingBottom="4px"
          paddingLeft="8px"
          paddingRight="8px"
          overflowY="hidden"
          hideBackdrop={true}
          hideClose={true}
          popoverShadow={false}
          popoverBgColor={
            {
              error: themeColorPalette.palette.sys_trad.warning.main,
              success: themeColorPalette.palette.sys_trad.success.main
            }[errorPopoverMessage?.type]
          }
        />
      </Drawer>
    </div>
  )
}

export default PortfolioCalculations
